import React, { useState, useEffect, useRef, useMemo, useCallback, Suspense } from 'react';
import { Modal } from 'react-bootstrap';
import { Button, StepLabel, Stepper, TextField } from '@mui/material';
import SignaturePad from 'react-signature-canvas';
import StepContent from '@mui/material/StepContent';
import Step from '@mui/material/Step';
import HTMLReader from './file-detail/HTMLReader';
import SignatureForm from './e-sign-elements/SignatureForm';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';
import FatcaStep1 from './e-sign-elements/FatcaStep1';
import FatcaStep2 from './e-sign-elements/FatcaStep2';
import FatcaStep3 from './e-sign-elements/FatcaStep3';
import {
    fetchCountries,
    fetchFileTemplate,
} from '../../../../_redux/email-signature/emailSignatureActions';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import _, { isEmpty } from 'lodash';
import { dataURLtoFile } from '../../../../components/helper/image';
import './DocumentSignature.scss';
import { ImageCropDialog } from '../../../../components/image-crop-dialog/ImageCropDialog';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CaymanQuestion from './e-sign-elements/CaymanQuestion';

const caymanInitial = {
    option_cayman_question_nine: '113',
    value_text_question_nine: '',
    option_cayman_question_ten: '115',
    value_text_question_ten: '',
    option_cayman_question_elevent_a: '',
    option_cayman_question_elevent_b: '',
    option_cayman_question_elevent_c: '',
    option_cayman_question_elevent_d: '',
};

const caymanSchema = Yup.object().shape({
    option_cayman_question_nine: Yup.string().nullable().required('This field is required!'),
    value_text_question_nine: Yup.string().when('option_cayman_question_nine', {
        is: '113',
        then: Yup.string().required('This field is required!'),
    }),
    option_cayman_question_ten: Yup.string().nullable().required('This field is required!'),
    value_text_question_ten: Yup.string().when('option_cayman_question_ten', {
        is: '115',
        then: Yup.string().required('This field is required!'),
    }),
    option_cayman_question_elevent_a: Yup.string().nullable().required('This field is required!'),
    option_cayman_question_elevent_b: Yup.string().nullable().required('This field is required!'),
    option_cayman_question_elevent_c: Yup.string().nullable().required('This field is required!'),
    option_cayman_question_elevent_d: Yup.string().nullable().required('This field is required!'),
});

const CDDForm = React.lazy(() => import('./components/CDDForm'));

export default function DocumentSignature() {
    /**
     * --------------------------------------------------------------------------------------------------
     * Variables ----------------------------------------------------------------------------------------
     * --------------------------------------------------------------------------------------------------
     */
    const dispatch = useDispatch();
    const [fetched, setFetched] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disableSignBtn, setDisableSignBtn] = useState(false);
    // const { replace, location, push } = useHistory();
    let navigate = useNavigate();
    let location = useLocation();
    const [isSigned, setIsSigned] = useState(false);
    const [hasFatcaInput, setHasFatcaInput] = useState(false);
    const [signatureShow, setSignatureShow] =
        useState(false); /* Biến hiển thị state show/hide của popup */
    const sigPad = useRef({});
    const [fatcaActiveStep, setFatcaActiveStep] = useState(0);
    const [dataStep1, setDataStep1] = useState(null);
    const [dataStep2, setDataStep2] = useState(null);
    const [dataStep3, setDataStep3] = useState(null);
    const [usPersonCheck, setUsPersonCheck] = useState(1);
    const [signFullName, setSignFullName] =
        useState(
            '',
        ); /* state này dùng để re-render và check disable button accept and sign khi user ký tên hoặc clear chữ ký */

    const [submitSuccess, setSubmitSuccess] = useState(false);
    const inputFile = useRef(null);

    const [isUploadSignature, setIsUploadSignature] = useState(false);
    const [fileSignatureUpload, setFileSignatureUpload] = useState(null);

    const [upImg, setUpImg] = useState();
    const [showDrop, setShowDrop] = useState(false);
    const [formData, setFormData] = useState({
        isValid: true,
        values: {
            name: '',
            262: '',
            radio_group_1: null,
            radio_group_2: '',
            342: '',
            343: '',
            267: '',
            268: '',
            269: '',
        },
    });

    const initialValues = useMemo(() => signatureShow && formData.values, [signatureShow]);

    const form = useForm({
        resolver: yupResolver(caymanSchema),
        mode: 'all',
    });

    const formRef = useRef(false);

    useEffect(() => {
        form.reset(caymanInitial);
    }, []);

    const fatcaInitValueStep1 = dataStep1 || {
        holder_name: '',
        holder_country: null,
        holder_country_id: null,
        holder_birthday: null,
        resident_address: '',
        resident_country: null,
        resident_country_id: null,
        resident_city: '',
        resident_state: '',
        resident_postal_code: '',
        mailing_address: '',
        mailing_country: null,
        mailing_country_id: null,
        mailing_city: '',
        mailing_state: '',
        mailing_postal_code: '',
    };
    const fatcaInitValueStep2 = dataStep2 || {
        option_declaration_US_person: 1,
        taxpayer_id_number: '',
    };
    const fatcaInitValueStep3 = dataStep3 || {
        DeclarationTaxes: [
            {
                country: null,
                country_id: null,
                tax_reference_type: '',
                tax_reference_number: '',
            },
        ],
    };

    const isCountryHasFatca = (countryId) => {
        const fatcaCountry = [205]; // 205: St. Kitts & Nevis
        return fatcaCountry.includes(countryId);
    };

    /**
     * --------------------------------------------------------------------------------------------------
     * Hooks --------------------------------------------------------------------------------------------
     * --------------------------------------------------------------------------------------------------
     */
    const handleError = (response) => {
        setLoading(false);
        setDisableSignBtn(false);
        switch (response.status) {
            case 401:
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Token Invalid',
                    showConfirmButton: false,
                    position: 'top-end',
                    toast: true,
                    timer: 1000,
                });
                setTimeout((_) => navigate('/404'), 1000);
                break;
            default:
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: response.statusText,
                    showConfirmButton: false,
                    position: 'top-end',
                    toast: true,
                    timer: 1000,
                });
                break;
        }
    };
    const { countries, fileTemplate } = useSelector((state) => {
        const countries = state.emailSignature.countries;
        const fileTemplate = state.emailSignature.fileTemplate;
        return { countries, fileTemplate };
    }, shallowEqual);

    const getQueryParams = useCallback(() => {
        let queries = location.search;
        let objStr = queries.replaceAll('?', '').replaceAll('&', ',').replaceAll('=', ':');
        const arr = objStr.split(',');
        let result = {};
        arr.forEach((item) => {
            const temp = item.split(':');
            result[temp[0]] = temp[1];
        });
        return result;
    }, [location.search]);

    const queryParams = useMemo(() => getQueryParams(), [getQueryParams]);

    useEffect(() => {
        if (!countries) {
            async function fetchData() {
                const actionCountries = await dispatch(fetchCountries(queryParams.token, navigate));
                const actionFileTemplate = await dispatch(
                    fetchFileTemplate(queryParams.token, navigate),
                );
                setFetched(true);
            }
            fetchData().then();
        }
    }, [countries, dispatch, queryParams.token, navigate, fileTemplate]);

    useEffect(() => {
        if (fileTemplate?.data?.is_fatca_only_US) {
            setHasFatcaInput(true);
        }
        if (fileTemplate?.data?.full_name) {
            setSignFullName(fileTemplate?.data?.full_name);
            setFormData((prev) => ({
                ...prev,
                values: { ...prev.values, name: fileTemplate?.data?.full_name },
            }));
        }
        if (fileTemplate?.data?.fatca) {
            const dataForStep1 = {
                holder_name: fileTemplate.data.fatca.holder_name,
                holder_country: countries.filter(
                    (country) => country.id === fileTemplate.data.fatca.holder_country_id,
                )[0],
                holder_country_id: fileTemplate.data.fatca.holder_country_id,
                holder_birthday: fileTemplate.data.fatca.holder_birthday,
                resident_address: fileTemplate.data.fatca.resident_address,
                resident_country: countries.filter(
                    (country) => country.id === fileTemplate.data.fatca.resident_country_id,
                )[0],
                resident_country_id: fileTemplate.data.fatca.resident_country_id,
                resident_city: fileTemplate.data.fatca.resident_city,
                resident_state: fileTemplate.data.fatca.resident_state,
                resident_postal_code: fileTemplate.data.fatca.resident_postal_code,
                mailing_address: fileTemplate.data.fatca.mailing_address,
                mailing_country: countries.filter(
                    (country) => country.id === fileTemplate.data.fatca.mailing_country_id,
                )[0],
                mailing_country_id: fileTemplate.data.fatca.mailing_country_id,
                mailing_city: fileTemplate.data.fatca.mailing_city,
                mailing_state: fileTemplate.data.fatca.mailing_state,
                mailing_postal_code: fileTemplate.data.fatca.mailing_postal_code,
            };
            setDataStep1(dataForStep1);
            setDataStep2({
                option_declaration_US_person: fileTemplate.data.fatca.option_declaration_US_person,
                taxpayer_id_number: fileTemplate.data.fatca.taxpayer_id_number,
            });
            setUsPersonCheck(fileTemplate.data.fatca.option_declaration_US_person);
            let dataStep3 = {
                DeclarationTaxes: [
                    {
                        country: null,
                        country_id: null,
                        tax_reference_type: '',
                        tax_reference_number: '',
                    },
                ],
            };
            if (
                fileTemplate.data.fatca.DeclarationTaxes &&
                Array.isArray(fileTemplate.data.fatca.DeclarationTaxes) &&
                fileTemplate.data.fatca.DeclarationTaxes.length
            ) {
                let DeclarationTaxes = _.cloneDeep(fileTemplate.data.fatca.DeclarationTaxes);
                DeclarationTaxes.forEach((item, index) => {
                    if (item.country_id) {
                        DeclarationTaxes[index].country = countries.filter(
                            (country) => country.id === item.country_id,
                        )[0];
                    }
                    delete DeclarationTaxes[index].id;
                    delete DeclarationTaxes[index].is_deleted;
                    delete DeclarationTaxes[index].fatca_id;
                });
                dataStep3 = { DeclarationTaxes };
            }
            setDataStep3(dataStep3);
        }
    }, [fileTemplate]);

    useEffect(() => {
        const docContainer = document.getElementById('doc-content-container');
        if (docContainer) {
            docContainer.style.height = '100vh';
        }
    }, []);

    useEffect(() => {
        /* Resize canvase khi popup signature show và
          ( không có fatca input hoặc ( có fatca input và input phải là step3 ) */
        if (signatureShow && (!hasFatcaInput || (hasFatcaInput && fatcaActiveStep === 3))) {
            sigPad.current._resizeCanvas && sigPad.current._resizeCanvas();
        }
    }, [signatureShow]);

    const acceptSignature = async () => {
        if (fileTemplate?.data?.file_template_id === 115) {
            form.trigger();
            const errors = form.formState.errors;
            if (!isEmpty(errors)) return;
        }

        let imgFile;
        if (isUploadSignature) {
            imgFile = fileSignatureUpload;
        } else {
            // let dataURL = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
            let dataURL = sigPad.current?.toDataURL('image/png');
            imgFile = dataURLtoFile(dataURL);
        }
        const fetchOptions = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${queryParams.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                files: [{ filename: 'e-signature.png', content_type: 'image' }],
            }),
        };
        let filename = null;

        setLoading(true);
        setDisableSignBtn(true);
        fetch(process.env.REACT_APP_API_URL + '/public_task/signed-url-s3/put', fetchOptions).then(
            (res) => {
                if (res.status !== 200) {
                    handleError(res);
                } else {
                    res.json().then((res) => {
                        let { data } = res;
                        const urlPushS3 = data[0].url;
                        filename = data[0].filename;
                        fetch(urlPushS3, {
                            method: 'PUT',
                            body: imgFile,
                        }).then((res) => {
                            if (res.status === 200) {
                                const finalData = getDataForSubmit(filename);
                                fetch(process.env.REACT_APP_API_URL + '/public_task/signature', {
                                    method: 'POST',
                                    headers: {
                                        Authorization: `Bearer ${queryParams.token}`,
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify(finalData),
                                })
                                    .then((res) => {
                                        if (res.status === 200) {
                                            res.json().then(() => {
                                                setLoading(false);
                                                setSubmitSuccess(true);
                                                Swal.fire({
                                                    icon: 'success',
                                                    title: 'Success!',
                                                    text: 'Submitted data',
                                                    showConfirmButton: false,
                                                    position: 'top-end',
                                                    toast: true,
                                                });
                                                setTimeout(() => window.location.reload(), 1000);
                                            });
                                        } else {
                                            handleError(res);
                                        }
                                    })
                                    .catch((err) => {
                                        setLoading(false);
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Error!',
                                            text: err?.message,
                                            showConfirmButton: false,
                                            position: 'top-end',
                                            toast: true,
                                        });
                                    });
                            } else {
                                handleError(res);
                            }
                        });
                    });
                }
            },
        );
    };

    const handleSignTextField = (e) => {
        setSignFullName(e.target.value);
    };

    const resetData = () => {
        setSignFullName('');
        if (hasFatcaInput) {
            setFatcaActiveStep(0);
            setDataStep1(null);
            setDataStep2(null);
            setDataStep3(null);
        }
    };

    const closeSignatureModal = () => {
        setSignatureShow(false);
        if (sigPad?.current && typeof sigPad?.current === 'object') {
            if (typeof sigPad.current._resizeCanvas === 'function') {
                sigPad.current._resizeCanvas();
            }
            if (typeof sigPad.current.clear === 'function') {
                sigPad.current.clear();
            }
        }
        setIsSigned(false);
        setIsUploadSignature(false);
        setFileSignatureUpload(null);
        /* Nếu chưa ký thì reset khi close modal */
        /*if (!fileTemplate?.data?.is_signed) {
            resetData();
            return;
        }*/
        if (hasFatcaInput) {
            setFatcaActiveStep(0);
        }
    };

    const openSignatureModal = () => {
        setSignatureShow(true);
    };

    const clearSignature = () => {
        sigPad.current.clear();
        setIsSigned(false);
        setIsUploadSignature(false);
        setFileSignatureUpload(null);
    };

    const handleSignEnd = () => {
        setIsSigned(true);
    };

    const getDataForSubmit = (filename) => {
        let data = {
            full_name: signFullName,
            s3_path: filename,
            mime_type: 'image/png',
        };

        if (hasFatcaInput) {
            let fatcaData = {
                ...dataStep1,
                ...dataStep2,
                ...dataStep3,
            };
            delete fatcaData.holder_country;
            delete fatcaData.mailing_country;
            delete fatcaData.resident_country;
            const keys = Object.keys(fatcaData);
            keys.forEach((key) => {
                if (fatcaData[key] === null || fatcaData[key] === '') {
                    delete fatcaData[key];
                }
            });
            /* Delete redundancy data when submit to be */
            if (fatcaData?.DeclarationTaxes && Array.isArray(fatcaData.DeclarationTaxes)) {
                fatcaData.DeclarationTaxes.forEach((row, index) => {
                    delete fatcaData.DeclarationTaxes[index].country;
                });
            }
            /* End - Delete redundancy data when submit to be */
            data.fatca = fatcaData;
        }

        switch (fileTemplate?.data?.file_template_id) {
            case 115: {
                let dataQ9 = {
                    option_id: +form.getValues('option_cayman_question_nine'),
                };
                if (form.getValues('option_cayman_question_nine') === '113') {
                    dataQ9.value_text = form.getValues('value_text_question_nine');
                }
                let dataQ10 = {
                    option_id: +form.getValues('option_cayman_question_ten'),
                };
                if (form.getValues('option_cayman_question_ten') === '115') {
                    dataQ10.value_text = form.getValues('value_text_question_ten');
                }
                data.answersForCompanyMember = [
                    dataQ9,
                    dataQ10,
                    {
                        option_id: +form.getValues('option_cayman_question_elevent_a'),
                    },
                    {
                        option_id: +form.getValues('option_cayman_question_elevent_b'),
                    },
                    {
                        option_id: +form.getValues('option_cayman_question_elevent_c'),
                    },
                    {
                        option_id: +form.getValues('option_cayman_question_elevent_d'),
                    },
                ];
                break;
            }
            case 133: {
                const {
                    262: ans_262,
                    radio_group_1: ans_263_264,
                    radio_group_2: ans_339_340_341_342_343,
                    342: ans_342,
                    343: ans_343,
                    267: ans_267,
                    268: ans_268,
                    269: ans_269,
                } = formData.values;
                data.answersForCompanyMember = [
                    { option_id: 262, value_text: ans_262 },
                    { option_id: ans_263_264 },
                    { option_id: 267, value_text: ans_267 },
                    { option_id: 268, value_text: ans_268 },
                    { option_id: 269, value_text: ans_269 },
                ];

                if (ans_263_264 === 264) {
                    const answerList = data.answersForCompanyMember.map((ans) => {
                        if ([267, 268, 269].includes(ans.option_id)) {
                            return {
                                ...ans,
                                value_text: '',
                            };
                        } else {
                            return ans;
                        }
                    });

                    data = {
                        ...data,
                        answersForCompanyMember: answerList,
                    };
                }

                ans_263_264 === 263 &&
                    data.answersForCompanyMember.push({
                        option_id: ans_339_340_341_342_343,
                        ...(ans_339_340_341_342_343 === 342 && { value_text: ans_342 }),
                        ...(ans_339_340_341_342_343 === 343 && { value_text: ans_343 }),
                    });
                break;
            }
            default:
                break;
        }

        return data;
    };

    const fatcaHandleSubmitStep1 = (formData) => {
        setFatcaActiveStep(1);
        setDataStep1(formData);
    };

    const fatcaHandleSubmitStep2 = (formData) => {
        setFatcaActiveStep(2);
        setDataStep2(formData);
    };

    const fatcaHandleSubmitStep3 = (formData) => {
        setDataStep3(formData);
        setFatcaActiveStep(3);
    };

    const isNotSign = () => {
        if (isUploadSignature && fileSignatureUpload) {
            return false;
        }

        /* Nếu chưa init được sigPad thì chắc chắn chư ký */
        const sigPadCurrent = sigPad?.current;
        if (!sigPadCurrent) return true;
        /* Chưa init sigPad hoặc init rồi nhưng chưa ký */
        return (
            (sigPad?.current && !Object.keys(sigPad?.current).length) ||
            (sigPad?.current && Object.keys(sigPad?.current).length && sigPad?.current.isEmpty())
        );
    };

    const disableAcceptAndSign = () => {
        if (loading || submitSuccess) {
            return true;
        }

        if (!formData.isValid) {
            return true;
        }

        if (hasFatcaInput) {
            /* Check khác step 4 (activeStep =3) vì khi đến được này
              thì các dữ liệu của step trước đã được validate */
            if (fatcaActiveStep !== 3 || !signFullName || isNotSign()) {
                return true;
            }
        } else {
            if (!signFullName || isNotSign()) {
                return true;
            }
        }

        return false;
    };

    /* ==== */
    function uploadSignature() {
        document.getElementById('update-signature-image').click();
    }

    function onCropImg(fileCropped) {
        setFileSignatureUpload(fileCropped);
        setShowDrop(false);
    }

    function onHidePopupCrop() {
        clearSignature();
        setShowDrop(false);
    }

    function onUploadFile(event) {
        const {
            target: { files },
        } = event;

        if (!files.length) {
            clearSignature();
            return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onloadend = function (e) {
            sigPad.current.clear();
            setFileSignatureUpload(files[0]);
            setIsUploadSignature(true);
            setIsSigned(true);

            setShowDrop(true);
            setUpImg(reader.result);
            document.getElementById('update-signature-image').value = '';
        };
    }

    const downloadPdf = () => {
        const fetchOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${queryParams.token}`,
            },
        };
        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + '/public_task/file_template/download',
            fetchOptions,
        ).then((response) => {
            if (response.status === 200) {
                const composition = response.headers.get('content-disposition');
                response.blob().then((result) => {
                    setLoading(false);
                    const url = URL.createObjectURL(result);

                    if (!composition) {
                        window.open(url);
                    } else {
                        const fileName = decodeURIComponent(
                            composition.split(';')[1].split('=')[1].replace(/"/g, ''),
                        );
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        a.target = '_blank';
                        a.click();
                    }
                });
            } else {
                handleError(response);
            }
        });
    };

    /**
     * --------------------------------------------------------------------------------------------------
     * Return JSX ---------------------------------------------------------------------------------------
     * --------------------------------------------------------------------------------------------------
     */

    const renderSignaturePad = () => (
        <Suspense>
            <div className="position-relative  bg-light">
                <div className={'d-flex justify-content-between'}>
                    <Button className="text-muted text-capitalize" onClick={clearSignature}>
                        Clear
                    </Button>
                    <Button className="text-warning text-capitalize" onClick={uploadSignature}>
                        Upload your Signature here
                    </Button>
                    <input
                        type="file"
                        id={'update-signature-image'}
                        ref={inputFile}
                        className="d-none"
                        accept={['.jpg', '.png', '.jpeg']}
                        onChange={onUploadFile}
                    />
                </div>
                <div
                    className="position-relative shadow m-auto"
                    style={{ height: '200px', width: '320px' }}
                >
                    <SignaturePad
                        canvasProps={{ className: 'w-100 h-100' }}
                        ref={(ref) => {
                            sigPad.current = ref;
                        }}
                        clearOnResize={false}
                        onEnd={handleSignEnd}
                    />
                    {isUploadSignature && (
                        <div className={'signature-previewer-container'}>
                            <img src={URL.createObjectURL(fileSignatureUpload)} alt={''} />
                        </div>
                    )}
                </div>
                <div className="border-top border-secondary py-4 mx-15" />
            </div>
            <div>
                {upImg && (
                    <ImageCropDialog
                        upImg={upImg}
                        inputFile={inputFile}
                        show={showDrop}
                        setShow={setShowDrop}
                        setUpImg={setUpImg}
                        isReUsable={true}
                        onSave={onCropImg}
                        aspect={16 / 10}
                        acceptLabel={'Accept'}
                        onHidePopup={onHidePopupCrop}
                    />
                )}
            </div>

            {/* Seychelles, Belize, BVI thì hiển thị note upload */}
            {[189, 23, 32].includes(fileTemplate?.data?.incorporation_country_id) && (
                <p className={'mb-0 mt-2'} style={{ fontStyle: 'italic', fontSize: '11px' }}>
                    *Please note that your signatures must be identical which one on your passport.{' '}
                    <br />
                    In case you choose to upload the signatures, please ensure that they have a
                    clear background, signatures with a colored background are not appropriate.
                </p>
            )}

            {fileTemplate?.data?.file_template_id === 133 ? (
                <CDDForm
                    initialValues={initialValues}
                    setFormData={setFormData}
                    setSignFullName={setSignFullName}
                />
            ) : (
                <>
                    <div className="mt-5">
                        <div className="row mb-4">
                            <div className="col-6">
                                <TextField
                                    label="Full name"
                                    placeholder="Enter your full name"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={signFullName}
                                    onChange={handleSignTextField}
                                />
                            </div>
                        </div>
                        <p className="m-0 text-justify">
                            I understand that BBCIncorp uses my name, email address and limited
                            information will be used to complete the signature process and to
                            enhance the user experience. To learn more about how BBCIncorp uses
                            information, please see our <a href="#">Privacy Policy</a>. By signing
                            this document with an electronic signature, I agree that such signature
                            will be as valid as handwritten signatures and considered originals to
                            the extent allowed by applicable law.
                        </p>
                    </div>
                </>
            )}
        </Suspense>
    );

    return (
        <div className="vh-75 bg-white overflow-hidden">
            <HTMLReader
                openSignatureModal={openSignatureModal}
                data={fileTemplate}
                download={downloadPdf}
                loading={loading}
                disableSignBtn={disableSignBtn}
                fetched={fetched}
            />
            <Modal
                show={signatureShow}
                onHide={closeSignatureModal}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>
                        {hasFatcaInput ? 'FATCA - Foreign Account Tax Compliance Act' : 'Signature'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {hasFatcaInput ? (
                        <>
                            <div className={'fatca-input-container'}>
                                <Stepper
                                    className="stepper p-0"
                                    nonLinear
                                    activeStep={fatcaActiveStep}
                                    orientation="vertical"
                                >
                                    <Step key={0}>
                                        <StepLabel>
                                            <h6>Section 1: Account Holder Identification</h6>
                                        </StepLabel>
                                        <StepContent>
                                            <FatcaStep1
                                                fatcaHandleSubmitStep1={fatcaHandleSubmitStep1}
                                                fatcaInitValueStep1={fatcaInitValueStep1}
                                                countries={countries}
                                            />
                                        </StepContent>
                                    </Step>
                                    <Step key={1}>
                                        <StepLabel>
                                            <h6>
                                                Section 2: Declaration of United States (U.S.)
                                                Person* for Tax purposes
                                            </h6>
                                        </StepLabel>
                                        <StepContent>
                                            <FatcaStep2
                                                fatcaInitValueStep2={fatcaInitValueStep2}
                                                fatcaHandleSubmitStep2={fatcaHandleSubmitStep2}
                                                usPersonCheck={usPersonCheck}
                                                setUsPersonCheck={setUsPersonCheck}
                                                setFatcaActiveStep={setFatcaActiveStep}
                                                setDataStep2={setDataStep2}
                                            />
                                        </StepContent>
                                    </Step>
                                    <Step key={2}>
                                        <StepLabel>
                                            <h6>
                                                Section 3: Declaration of Tax Residency (other than
                                                U.S.)
                                            </h6>
                                        </StepLabel>
                                        <StepContent>
                                            <FatcaStep3
                                                fatcaHandleSubmitStep3={fatcaHandleSubmitStep3}
                                                fatcaInitValueStep3={fatcaInitValueStep3}
                                                countries={countries}
                                                setDataStep3={setDataStep3}
                                                setFatcaActiveStep={setFatcaActiveStep}
                                            />
                                        </StepContent>
                                    </Step>
                                    <Step key={3}>
                                        <StepLabel>
                                            <h6>Section 4: Declaration and Undertakings</h6>
                                        </StepLabel>
                                        <StepContent>{renderSignaturePad()}</StepContent>
                                    </Step>
                                </Stepper>
                            </div>
                            <br />
                        </>
                    ) : (
                        <>
                            {renderSignaturePad()}
                            {fileTemplate?.data?.file_template_id === 115 && (
                                // <Formik initialValues={caymanInitial}
                                //         validationSchema={caymanSchema}
                                //         innerRef={formikCayman}>
                                //     {({values, errors, handleChange, touched, setFieldValue, setFieldTouched}) => (
                                //     )}
                                // </Formik>
                                <CaymanQuestion form={form} />
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer className="justify-content-between border-0">
                    <Button variant="contained" onClick={closeSignatureModal}>
                        Cancel
                    </Button>
                    <Button
                        disabled={disableAcceptAndSign()}
                        variant="contained"
                        color="primary"
                        className="text-white text-capitalize"
                        onClick={acceptSignature}
                    >
                        Accept and sign{' '}
                        {loading && (
                            <CircularProgress className={'ml-2'} size={20} color={'secondary'} />
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/first */
import { loadStripe } from '@stripe/stripe-js';
import React, { useMemo } from 'react';
import { Dialog } from '@mui/material';
const { Elements } = require('@stripe/react-stripe-js');
const { default: CardForm } = require('./stripeDialog');

import { COUNTRIES_ID } from 'constants/countries';

const getStripeKey = (countryID) => {
    let stripeKey;
    switch (countryID) {
        case COUNTRIES_ID.SINGAPORE:
            stripeKey = process.env.REACT_APP_STRIPE_KEY_SG;
            break;

        default:
            stripeKey = process.env.REACT_APP_STRIPE_KEY;
            break;
    }
    return stripeKey;
};

const ElementsStripe = ({ payloadPay, token, open, setOpenStripe, paidAmount, countryID }) => {
    const stripeKey = countryID ? getStripeKey(countryID) : process.env.REACT_APP_STRIPE_KEY;
    const stripePromise = useMemo(() => {
        return loadStripe(stripeKey);
    }, []);

    return (
        <>
            <Dialog className="payment-dialog" open={open}>
                <Elements stripe={stripePromise}>
                    <CardForm
                        stripe={stripePromise}
                        payloadPay={payloadPay}
                        userToken={token}
                        open={open}
                        setOpenStripe={setOpenStripe}
                        paidAmount={paidAmount}
                        countryID={countryID}
                    />
                </Elements>
            </Dialog>
        </>
    );
};
export default ElementsStripe;

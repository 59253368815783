import React, { useState } from "react";
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { Accordion, AccordionCollapse } from 'react-bootstrap';
import { MEMBER_TYPES, COMPANY_MEMBER_TYPES } from 'app/pages/services/constants';
import { getDefaultNationality } from "app/pages/services/utilities/NationalityHelpers";
import { get, isEqual } from "lodash";

/**
 * recursive to find parent member of one branch.
 * @param {List} members 
 * @param {number} corporation_company_member_id parent member id
 * @returns a list of parent id of one branch.
 */
const recursiveFinder = (members, corporation_company_member_id) => {
    let results = [];
    const member = members.find(item => item.id === corporation_company_member_id);
    if (member) {
        member.CompanyPositions.forEach(pos => {
            results.push(member.id);
            if (pos.corporation_company_member_id) {
                recursiveFinder(members, pos.corporation_company_member_id);
            }
        });
    }
    return results;
}

/**
 * Get all parent member id list for existing member filter.
 * @param {List} members List of members.
 * @param {CompanyMember} data current basic company member data for add or full data to edit.
 * @returns parent member id list.
 */
const getAllParents = (members, data) => {
    let results = [];
    const corpMembers = members.filter(member => member.type_member_id === MEMBER_TYPES.CORPORATION.id && member.id !== data.corporation_company_member_id);
    const parent = members.find(member => member.id === data.corporation_company_member_id);
    // console.log(parent)
    parent && parent.CompanyPositions.forEach((pos) => {
        if (pos.corporation_company_member_id) {
            results = [...results, ...recursiveFinder(corpMembers, pos.corporation_company_member_id)];
        }
    });
    return results;
}

/**
 * Get existing member list.
 * @param {List} members List of members.
 * @param {CompanyMember} data current basic company member data for add or full data to edit.
 * @returns list of existing members.
 */
export const getExistedMembers = (members, data) => {
    let results = [];
    if (Array.isArray(members)) {
        const filteredMembers = members.filter(member => member.id !== data.corporation_company_member_id);
        results = [...filteredMembers.filter(member => member.CompanyPositions.every(position => position.corporation_company_member_id !== data.corporation_company_member_id))];
        filteredMembers.filter(member => member.CompanyPositions.some(pos => pos.corporation_company_member_id === data.corporation_company_member_id)).forEach(member => {
            const filteredPos = member.CompanyPositions.filter(pos => pos.corporation_company_member_id === data.corporation_company_member_id && pos.company_member_type_id === data.company_member_type_id);
            if (filteredPos.length === 0) {
                results.push(member);
            }
        });
    }

    // if UBO => all members are individual.
    if (data.company_member_type_id === COMPANY_MEMBER_TYPES.UBO.id) {
        results = results.filter(item => item.type_member_id === MEMBER_TYPES.INDIVIDUAL.id);
    } else {
        const parentIds = getAllParents(members, data);
        parentIds.forEach(parentId => {
            results = results.filter(item => item.id !== parentId);
        });
    }
    return results;
}

// export default function ExistingMemberList({ data, formik, isLLCType, initMemberData, memberCountry, phoneCountry, setViewedFiles }) {
export default function ExistingMemberList({ data, formik, isLLCType, initMemberData, setViewedFiles, orderInfo }) {
    const [activeKey, setActiveKey] = useState(null);
    const CompanyMembers = orderInfo?.Company?.CompanyMembers || [];
    const existedMembers = getExistedMembers(CompanyMembers, data);
    const chooseExisting = (expand) => {
        setActiveKey(expand ? '0' : null);
    }
    const chooseExistingMember = (member) => {
        
        const memberCountry = getDefaultNationality(member.country_id);
        const phoneCountry = getDefaultNationality(member.phone_country_id);
        const contactPerson = get(member, 'CompanyPositions[0]');
        const is_contact_person = isEqual(get(contactPerson, 'company_member_type_id'), 7);
        
        formik.setValues({
            ...formik.values,
            preData: { ...member, ...data, isLLCType: isLLCType },
            // Individual + Corporation
            type_member: member.type_member_id === MEMBER_TYPES.INDIVIDUAL.id || false,
            country: memberCountry,
            email: member.email || '',
            ...initMemberData(member.type_member_id === MEMBER_TYPES.INDIVIDUAL.id || false, { ...member, ...data, phoneCountry: phoneCountry }),
            number_allotted: member.number_allotted || 0,
            own_percent: member.own_percent || 0,
            // UBO
            percent_of_interest: member.percent_of_interest || 0,
            // Contact person
            is_contact_person
        });
        setViewedFiles(member.Files || []);
    }

    const checkCompanyMemberType = (company_member_type_id, member) => {
        return member.CompanyPositions.filter(pos => pos.company_member_type_id === company_member_type_id).length > 0;
    }

    return <Accordion activeKey={activeKey}>
        <div className="d-flex align-items-center justify-content-between mb-3">
            <p className="mb-0 choose-existing-text" onClick={() => chooseExisting(true)}>Choose existing member</p>
            {activeKey && <Button onClick={() => chooseExisting(false)}>Close</Button>}
        </div>
        <AccordionCollapse eventKey="0">
            <div className="collapse-content py-4 border rounded overflow-auto" style={{ height: 250 }}>
                <div className="row w-100 px-3 m-0">
                    {existedMembers.map((member, index) => <div className="col-12 p-3 mb-3" key={index} style={{ borderBottom: '1px dashed #c8c8c8' }}>
                        <div className="row">
                            <div className="col-1 p-0 text-right">{index + 1}.</div>
                            <div className="col-11">
                                <div className="d-flex justify-content-between">
                                    <p className="mb-0">{member.type_member_id === MEMBER_TYPES.INDIVIDUAL.id ? (member?.full_name || member.first_name) : member.corporation_name}</p>
                                    <Button onClick={() => chooseExistingMember(member)} color="primary" size="small">Choose</Button>
                                </div>
                                <div className="text-muted mb-2">{member.email}</div>
                                <div className="d-flex flex-wrap">
                                    {checkCompanyMemberType(COMPANY_MEMBER_TYPES.CONTACT_PERSON.id, member) ? <Chip label="Contact information" color="primary" className="text-white mr-1" size="small" /> : <></>}
                                    {checkCompanyMemberType(COMPANY_MEMBER_TYPES.DIRECTOR.id, member) ? <Chip label="Director" color="primary" className="text-white mr-1" size="small" /> : <></>}
                                    {checkCompanyMemberType(COMPANY_MEMBER_TYPES.SHAREHOLDER.id, member) ? <Chip label="Shareholder" color="primary" className="text-white mr-1" size="small" /> : <></>}
                                    {checkCompanyMemberType(COMPANY_MEMBER_TYPES.UBO.id, member) ? <Chip label="UBO" color="primary" className="text-white mr-1" size="small" /> : <></>}
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </AccordionCollapse>
    </Accordion>
}
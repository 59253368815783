export const defaultSorted = [{ dataField: 'id', order: 'desc' }];
export const defaultSort = { dataField: 'id', order: 'desc' };
export const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '50', value: 50 },
];
export const initialFilter = {
    filter: {
        created_time: '',
        country_id: '',
        service_type_id: '',
        search: '',
    },
    sortOrder: 'desc', // asc||desc
    sortField: 'id',
    pageNumber: 1,
    pageSize: 10,
};

export function filterProcesses(row) {
    let ProcessFilter = {
        Incorporation: { process_step_type_id: 4, name: 'Incorporation', Processes: [] },
        Banking: { process_step_type_id: 5, name: 'Banking', Processes: [] },
        AdditionalServices: { process_step_type_id: 6, name: 'Additional Services', Processes: [] },
        PostIncorporation: { process_step_type_id: 14, name: 'Post-incorporation', Processes: [] },
        ChangeOfficers: { process_step_type_id: 17, name: 'Change Officers', Processes: [] },
        BookeepingProcess: { process_step_type_id:19, name: 'Annual Renewal', Processes: [] }
    };

    row.Task.Processes &&
        row.Task.Processes.map((item) => {
            if (item.ProcessStep) {
                switch (item.ProcessStep.process_step_type_id) {
                    case 4: // Incorporation
                        ProcessFilter.Incorporation.Processes.push(item);
                        break;
                    case 5: // Banking
                        ProcessFilter.Banking.Processes.push(item);
                        break;
                    case 6: // Additional service
                        ProcessFilter.AdditionalServices.Processes.push(item);
                        break;
                    case 14: // Post-incorporation
                        ProcessFilter.PostIncorporation.Processes.push(item);
                        break;
                    case 16: // Change Officers
                    case 17: // Change Officers
                        ProcessFilter.ChangeOfficers.Processes.push(item);
                        break;
                    case 19: //Bookeeping Process
                        ProcessFilter.BookeepingProcess.Processes.push(item);
                        break;
                    default:
                        break;
                }
            }
        });
    return ProcessFilter;
}

// Note 10-11-2021: process Finish lấy từ process của CRM, khác các step trong process của Dashboard (From Thức BE).
export function filterProcessesFinished(row) {
    let ProcessesFinished = {
        Incorporation: { process_step_type_id: 1, name: 'Incorporation', FinishedProcesses: null },
        Banking: { process_step_type_id: 2, name: 'Banking', FinishedProcesses: null },
        AdditionalServices: {
            process_step_type_id: 3,
            name: 'AdditionalServices',
            FinishedProcesses: [],
        },
    };
    row.Task.FinishedProcesses &&
        row.Task.FinishedProcesses.length > 0 &&
        row.Task.FinishedProcesses.map((item) => {
            if (item.ProcessStep) {
                switch (item.ProcessStep.process_step_type_id) {
                    case 1: // Incorporation
                        ProcessesFinished.Incorporation.FinishedProcesses = item;
                        break;
                    case 2: // Banking
                        ProcessesFinished.Banking.FinishedProcesses = item;
                        break;
                    case 3: // AdditionalService
                        ProcessesFinished.AdditionalServices.FinishedProcesses.push(item);
                        break;

                    default:
                        break;
                }
            }
        });
    return ProcessesFinished;
}

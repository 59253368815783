import { createSlice } from '@reduxjs/toolkit';

const initState = {
    actionsLoading: false,
    entities: null,
    lastError: null,
    error: null
};
export const callTypes = {
    action: 'action',
};

export const proposedNameSlice = createSlice({
    name: 'proposedName',
    initialState: initState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        proposedNameFetched: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = data;
        },
        proposedNameAdd: (state, action) => {
            // const { data } = action.payload;
            state.error = null;
            state.actionsLoading = false;
            // state.entities = data;
        },
        proposedNameUpdated: (state, action) => {
            // const { data } = action.payload;
            state.error = null;
            state.actionsLoading = false;
            // state.entities = data;
        },
        proposedNameDeleted: (state, action) => {
            // const { data } = action.payload;
            state.error = null;
            state.actionsLoading = false;
            // state.entities = data;
        },
    },
});

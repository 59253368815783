import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import NOMINEE_MEMBER from '../../../../../images/nominee-member.svg';
import * as Yup from 'yup';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from 'components/form-controls/InputField';

export default function Nominee({
    name = 'reason',
    onSubmit,
    body,
    disabled,
    initialValue = get(body, 'Task.reason_use_nominee_director'),
    handleBack,
    handleNext,
    companyMemberType,
    hideAction,
}) {
    const [title, setTitle] = useState('');

    const validationSchema = Yup.object().shape({
        [name]: Yup.string().nullable().required('Reason to use Nominee is required'),
    });

    const initialValues = {
        [name]: '',
    };

    const form = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        try {
            let titleTemp = '';
            switch (companyMemberType) {
                case 1:
                    titleTemp = body && body.isLLC ? 'Manager' : 'Director';
                    break;
                case 2:
                    titleTemp = body && body.isLLC ? 'Member' : 'Shareholder';
                    break;
            }
            setTitle(titleTemp);
            form.setValue(name, initialValue);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const handleSubmit = (values) => {
        onSubmit && onSubmit(values);
    };

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="nominee">
                <p className="fw-500 mb-2">Nominee {title}</p>
                <div style={{ width: '192px' }}>
                    <img src={NOMINEE_MEMBER} style={{ maxWidth: '100%' }} alt="nominee-member" />
                </div>
                {onSubmit && (
                    <div
                        className="mt-5 ml-2 input-line"
                        style={{ width: '392px', maxWidth: '100%' }}
                    >
                        <label>Reason to use Nominee</label>
                        <InputField
                            form={form}
                            name={name}
                            placeholder="Note reason to use Nominee"
                            multiline
                            rows={5}
                            disabled={disabled}
                            hideSuccessHelper
                            showIcon
                        />
                    </div>
                )}
                {!hideAction && (
                    <div className="mt-7">
                        <div>
                            {/* {activeStep > 0 && ( */}
                            <Button
                                size="large"
                                variant="contained"
                                disableElevation
                                onClick={handleBack}
                                className="mr-2"
                            >
                                Back
                            </Button>
                            {/* )} */}
                            {/* {activeStep < 3 && ( */}
                            <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                style={{ color: '#fff' }}
                                type={onSubmit ? 'submit' : 'button'}
                                onClick={handleNext}
                            >
                                Next
                            </Button>
                            {/* )} */}
                        </div>
                    </div>
                )}
            </div>
        </form>
    );
}

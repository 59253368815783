import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import './MeetingSchedule.scss';
import { Button } from '@mui/material';
import DatePicker from 'react-datepicker';
import timezones from 'timezones-list';
import Moment from 'react-moment';
import { generateTimesOptions, isWeekday } from './Utils';
import 'moment-timezone';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getHolidayAction } from '../../../../../_redux/banking/asyncThunk';
import { toast } from 'react-toastify';
import NoteFromCs from '../application-set/components/NoteFromCs';
import { downloadFileS3 } from '../../../../../_redux/files/filesCrud';
import { Modal } from 'react-bootstrap';
import NativeReader from '../../../order-form/document-controller/file-detail/NativeReader';
import { saveAs } from 'file-saver';


const SINGAPORE_TZCODE = 'Asia/Singapore';
const HONG_KONG_TZCODE = 'Asia/Hong_Kong';
const today = new Date();
const timeOptions = generateTimesOptions(9, 17, 15);
const lastDateNextYear = new Date(new Date().getUTCFullYear() + 1, 12, 0); // Lấy ngày cuối cùng của năm

export function MeetingSchedule({ onNext }) {
    /* ====================
    *         Hook
    * ===================== */
    const [disableSetDayBtn, setDisableSetDayBtn] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(timeOptions[0]);
    const [startDateTime, setStartDateTime] = useState(null); // Được gom lại từ startDate and startTime, sử dụng để submit data
    const [excludeDates, setExcludeDates] = useState(null);
    const [selectedTimeZoneConvert, setSelectedTimeZoneConvert] = useState(null);
    const [isSingapore, setIsSingapore] = useState(false);
    const [defaultTimeZone, setDefaultTimeZone] = useState(null);
    const dispatch = useDispatch();
    const { holidaysMap, loadingGetHoliday, holidays } = useSelector(state => state.banking);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [includeTimeOptions, setIncludeTimeOptions] = useState(timeOptions);

    const { geoLooking } = useSelector((state) => ({
        geoLooking: state.enum.geoLooking,
    }), shallowEqual);
    const { activeProcess, orderInfo } = useSelector(state => state.banking);

    const loadHolidays = async () => {
        const currentYear = new Date().getUTCFullYear();
        const params = {
            filter: {
                "date,>=": `${currentYear}-01-01`,
                "date,<=": `${lastDateNextYear.getUTCFullYear()}-${lastDateNextYear.getMonth() + 1}-${lastDateNextYear.getDate()}`,
            }
        };
        /* Start - Xử lý show error khi gọi tới be thất bại */
        const resultAction = await dispatch(getHolidayAction({ params }));
        if (!getHolidayAction.fulfilled.match(resultAction)) {
            toast.error(resultAction.payload.data || resultAction.error.message || 'Something went wrong!');
        }
        /* End - Xử lý show error khi gọi tới be thất bại */
    };

    useEffect(() => {
        if (!orderInfo) return;
        if (orderInfo.Task?.BankingProcesses?.[0]?.Bank?.Country?.id === 191) setIsSingapore(true);
    }, [orderInfo]);

    /* Gọi api lấy dữ liệu holiday */

    useEffect(() => {
        void loadHolidays();
    }, []);

    /* Effect to set default time zone bank, and default time zone converter */
    useEffect(() => {
        let defaultTimezoneTmp;
        if (isSingapore) {
            defaultTimezoneTmp = timezones.find(timezone => timezone.tzCode === SINGAPORE_TZCODE);
        } else {
            defaultTimezoneTmp = timezones.find(timezone => timezone.tzCode === HONG_KONG_TZCODE);
        }
        setDefaultTimeZone(defaultTimezoneTmp);

        if (geoLooking) {
            setSelectedTimeZoneConvert(timezones.find(timezone => timezone.tzCode === geoLooking.timezone));
        } else {
            setSelectedTimeZoneConvert(defaultTimezoneTmp);
        }

    }, [isSingapore, geoLooking]);


    /* Effect to set convert date and time*/
    useEffect(() => {
        /* Init date string từ date được chọn, giờ được chọn, và timezone bank */
        const selectedDateTimeString = `${startDate.getUTCFullYear()}/${(startDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${startDate.getUTCDate().toString().padStart(2, '0')} ${startTime.value} ${defaultTimeZone?.utc || ''}`;

        /* Init date từ date string, date lúc này sẽ có timezone đúng với timezone bank */
        const selectedDateTime = new Date(selectedDateTimeString);

        /* Set startDateTime */
        setStartDateTime(selectedDateTime);
    }, [startDate, startTime, selectedTimeZoneConvert, defaultTimeZone]);

    /** Effect xử lý set options time dựa vào proposed time */
    useEffect(() => {
        if (!orderInfo?.Task?.BankingProcesses?.[0]?.ProposedTimes?.length || !startDate || !defaultTimeZone) {
            setIncludeTimeOptions(timeOptions);
            return;
        }

        const seletedDateString = `${startDate.getUTCFullYear()}/${(startDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${startDate.getUTCDate().toString().padStart(2, '0')}`;

        let proposedTimeMapped = orderInfo.Task.BankingProcesses[0].ProposedTimes.filter(item => {
            const fromDate = new Date(item.from_time);
            const fromDateString = `${fromDate.getUTCFullYear()}/${(fromDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${fromDate.getUTCDate().toString().padStart(2, '0')}`;
            return seletedDateString === fromDateString;
        });


        let convertedProposedTime = proposedTimeMapped.map(item => {
            let fromTime = new Date(item.from_time);
            let toTime = new Date(item.to_time);
            return [fromTime, toTime];
        });

        const newTimeOptions = timeOptions.map(timeOption => {
            const timeDate = new Date(`${seletedDateString} ${timeOption.value} ${defaultTimeZone.utc}`);
            timeOption.disabledOption = true;
            convertedProposedTime.forEach(([fromTime, toTime]) => {
                if (timeDate.getTime() >= fromTime.getTime() && timeDate.getTime() <= toTime.getTime()) {
                    timeOption.disabledOption = false;
                }
            })
            return timeOption;
        });

        setStartTime(newTimeOptions.find(item => !item.disabledOption) ?? timeOptions[0]);
        setIncludeTimeOptions(newTimeOptions);
    }, [orderInfo, startDate, defaultTimeZone]);

    const selectedDateFormated = useMemo(() => {
        if (!startDateTime) return null;
        const date = startDateTime.getDate().toString().padStart(2, '0');
        const month = (startDateTime.getMonth() + 1).toString().padStart(2, '0');
        const year = startDateTime.getFullYear();
        const dateFull = `${year}-${month}-${date}`;
        return dateFull;
    }, [startDateTime]);

    const highlightDays = useMemo(() => {
        const holidaysHighlight = [];
        const highLightObj = [{ 'react-datepicker__day--highlighted-custom': [today] }];
        holidays.forEach(holiday => {
            holidaysHighlight.push(new Date(holiday.date));
        });
        if (holidaysHighlight.length) {
            highLightObj.push({ 'react-datepicker__day--highlighted-holiday': holidaysHighlight });
        }
        return highLightObj;
    }, [holidays]);

    const includeDates = useMemo(() => {
        if (!orderInfo?.Task?.BankingProcesses?.[0].ProposedTimes?.length) return null;
        const proposedTime = orderInfo?.Task?.BankingProcesses?.[0].ProposedTimes || [];
        proposedTime[0] && setStartDate(new Date(proposedTime[0].from_time));
        return proposedTime.map(time => {
            return new Date(time.from_time)
        });
    }, [orderInfo]);

    const process = useMemo(() => {
        if (!orderInfo) return null;
        const { Task: { Processes } } = orderInfo;
        const [process] = Processes;
        return process;
    }, [orderInfo]);

    /* ====================
    *         Function
    * ===================== */

    const onClickView = async doc => {
        try {
            const { data: { data } } = await downloadFileS3(doc.s3_path);

            if (['doc', 'docx'].includes(doc.s3_path.split('.')[1].toLowerCase())) {
                saveAs(data, doc.s3_path);
                return;
            }

            setShowModal(true);
            setModalData({ file: { name: doc.s3_path }, file_url: data });
        } catch (e) {
            toast.error('Somethings when wrong!');
        }
    };

    const downloadNativePDF = () => {
        return new Promise((resolve, reject) => {
            saveAs(modalData.file_url, modalData.file.name);
            resolve(true);
        })
    };

    function isInProposedTime() {
        if (!orderInfo?.Task?.BankingProcesses?.[0].ProposedTimes?.length) return true;

        /* Kiểm tra nếu date chọn nằm trong proposed time */
        return orderInfo.Task.BankingProcesses[0].ProposedTimes.some(time => {
            if (!defaultTimeZone) return true;
            const fromTime = new Date(time.from_time);
            const toTime = new Date(time.to_time);
            return startDateTime?.getTime() >= fromTime?.getTime() && startDateTime?.getTime() <= toTime?.getTime()
        })
    }

    function checkValid() {
        return selectedDateFormated && !holidaysMap[selectedDateFormated] && isInProposedTime() && isSelectedDateGteToday();
    }

    function isSelectedDateGteToday() {
        if (!startDateTime) return false;

        const today = new Date();
        today.setUTCHours(0,0,0,0);

        const startDateClone = new Date(startDateTime.getTime());
        startDateClone.setUTCHours(0,0,0,0);

        return startDateClone.getTime() >= today.getTime();
    }

    function handleNext() {
        if (!checkValid()) return;

        onNext && onNext({client_time: startDateTime?.getTime()});
    }

    return (<>
        <div className='row mb-4'>
            <div className='col-12 col-md-6 banking-inline-datepicker'>
                <div className='d-flex align-items-center'>
                    <div className={'bank-logo-container mb-4'}>
                        {orderInfo?.Task?.BankingProcesses[0]?.Bank?.image
                        && <img src={'https://bbcincorp.com' + orderInfo?.Task?.BankingProcesses[0]?.Bank?.image}
                                className=''
                                alt='' />}
                    </div>
                    <p className={'ml-3'}>{orderInfo?.Task?.BankingProcesses[0]?.Bank?.name}</p>
                </div>

                <DatePicker
                    selected={startDate}
                    onChange={setStartDate}
                    inline
                    showMonthDropdown
                    showYearDropdown
                    highlightDates={highlightDays}
                    includeDates={includeDates}
                    excludeDates={excludeDates}
                    filterDate={isWeekday}
                    calendarStartDay={1}
                    disabledKeyboardNavigation
                    minDate={today}
                    maxDate={lastDateNextYear}
                />
                {holidaysMap[selectedDateFormated] ?
                    <p className='text-danger'>{selectedDateFormated}: {holidaysMap[selectedDateFormated].name}</p> : ''}

            </div>
            <div className='col-12 col-md-6'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label className='text-black-50'>Time zone (Bank)</label>
                            <p className=''>
                                {defaultTimeZone?.name}
                            </p>
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <div className='form-group'>
                            <label className='text-black-50'>Meeting date</label>
                            <p className=''>
                                <Moment date={startDate}
                                        format='DD/MM/YYYY' />
                            </p>
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <div className='form-group'>
                            <label className='text-black-50'>Content</label>
                            <p className=''>
                                Video call meeting content
                            </p>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='form-group input-line'>
                            <label className='text-black-50'>Time (24h)</label>
                            <Select
                                value={startTime}
                                onChange={setStartTime}
                                className='react-select'
                                options={includeTimeOptions}
                                isOptionDisabled={(option) => option.disabledOption} />
                        </div>
                    </div>
                </div>

                <div className='card bg-light mb-4'>
                    <div className='card-body'>
                        <h6>Time zone converter</h6>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='form-group input-line'>
                                    <label className='text-black-50'>Time</label>
                                    <Select
                                        id={'timezone-converter'}
                                        className='react-select'
                                        options={timezones}
                                        value={selectedTimeZoneConvert}
                                        getOptionLabel={timeZone => timeZone.name}
                                        isOptionSelected={option => selectedTimeZoneConvert?.tzCode === option?.tzCode}
                                        onChange={setSelectedTimeZoneConvert} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='form-group'>
                                    <label className='text-black-50'>Meeting date</label>
                                    <p className=''>
                                        <Moment tz={selectedTimeZoneConvert?.tzCode || ''}
                                                date={startDateTime}
                                                format='DD/MM/YYYY' />
                                    </p>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='form-group'>
                                    <label className='text-black-50'>Time (24h)</label>
                                    <p className=''>
                                        <Moment tz={selectedTimeZoneConvert?.tzCode || ''}
                                                date={startDateTime}
                                                format='HH:mm' />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<Button size='large'
                        className='mr-2'>Back</Button>*/}
                <Button size='large'
                        variant='contained'
                        color='primary'
                        className='text-white mr-2'
                        onClick={handleNext}
                        disabled={!checkValid()}>SET DAY</Button>

            </div>
        </div>

        {process?.ProcessNote[0]?.is_belong_to_client
            ? <NoteFromCs note={process?.ProcessNote[0].description}
                          files={process?.ProcessNote[0].Files}
                          onFileClick={onClickView}/>
            : ''}


        <Modal show={showModal}
               onHide={() => setShowModal(false)}
               size='xl'
               centered>
            <Modal.Body>
                {modalData && (
                    <>
                        {/*{modal.data.html && (
                                <HTMLReader
                                    closeDetailModal={closeModal}
                                    data={modal.data}
                                    download={downloadHTMLAsPDF}
                                    downloading={htmlDownloading}
                                    fetched={true}
                                />
                            )}*/}
                        {modalData && (
                            <NativeReader
                                closeDetailModal={() => setShowModal(false)}
                                data={modalData}
                                download={downloadNativePDF}
                            />
                        )}
                    </>
                )}
            </Modal.Body>
        </Modal>
    </>);
}

import { Box, Chip, Grid, Typography } from '@mui/material';
import React, { useMemo, useEffect, useRef } from 'react';
import { get } from 'lodash';

function SelectFullNameHint({
    form,
    fullNameField,
    firstNameField,
    middleNameField,
    lastNameField,
    disabled,
}) {
    const firstName = form.watch(firstNameField)?.trim();
    const middleName = form.watch(middleNameField)?.trim();
    const lastName = form.watch(lastNameField)?.trim();

    const fullNames = useMemo(() => {
        // name 1: Last name + Middle name + First name
        // name 2: First name + Middle name + Last name
        return middleName
            ? [`${lastName} ${middleName} ${firstName}`, `${firstName} ${middleName} ${lastName}`]
            : [`${lastName} ${firstName}`, `${firstName} ${lastName}`];
    }, [firstName, middleName, lastName]);

    return (
        <>
            {firstName && lastName && !disabled && (
                <div className="col-12 mb-7 input-line">
                    <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                        <Typography variant="caption" sx={{ mr: 1 }}>
                            Select your full name
                        </Typography>
                        {fullNames.map((fullName) => (
                            <Chip
                                sx={{ mr: 1, my: 0.5 }}
                                label={fullName}
                                disabled={disabled}
                                onClick={() => {
                                    form.setValue(fullNameField, fullName);
                                    const errorPath = get(form.formState.errors, fullNameField);
                                    if (errorPath) {
                                        form.clearErrors(fullNameField);
                                    }
                                }}
                            />
                        ))}
                    </Box>
                </div>
            )}
        </>
    );
}

export default SelectFullNameHint;

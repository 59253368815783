/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetAccAndFiling } from 'app/pages/_redux/incorporation/incorporationActions';

const ID_SERVICE_YEARLY_ACCOUNTING_70 = 1939;
const ID_SERVICE_MONTHLY_ACCOUNTING_70 = 1946;
const ID_SERVICE_ACCOUNTING_COMPLIANCE_REVIEW = 1955;
const ID_PACKAGES_TYPE_LOCAL = [86, 87];
const ID_PACKAGES_TYPE_FOREIGNER = [88, 89];

const QUESTIONS_TAX_FILLING_SERVICE = [
    {
        id_packages: ID_PACKAGES_TYPE_LOCAL,
        question:
            'The accounting fee for basic package with yearly turnover of less than S$70,000 is US$42/month (billed yearly) for yearly package, and US$91/month (billed yearly) for monthly package.',
        answers: [
            {
                idService: ID_SERVICE_YEARLY_ACCOUNTING_70,
                hasShowNote: true,
                label: 'Yes, please add basic package of accounting and filing tax (yearly)',
            },
            {
                idService: ID_SERVICE_MONTHLY_ACCOUNTING_70,
                hasShowNote: true,
                label: 'Yes, please add basic package of accounting and filing tax (monthly)',
            },
            {
                idService: 'no',
                hasShowNote: false,
                label: 'No, I can do it ourselves',
            },
        ],
    },
    {
        id_packages: ID_PACKAGES_TYPE_FOREIGNER,
        question:
            'The accounting fee for basic package with yearly turnover of less than S$70,000 is US$91 per month or US$1,092 yearly.',
        answers: [
            {
                idService: ID_SERVICE_MONTHLY_ACCOUNTING_70,
                hasShowNote: true,
                label: 'Yes, please add basic package of accounting and filing tax',
            },
            {
                idService: ID_SERVICE_ACCOUNTING_COMPLIANCE_REVIEW,
                hasShowNote: false,
                label: `I'd like to add Company Review service`,
            },
        ],
    },
];

function Note() {
    return (
        <Box mt={2} mb={2}>
            <strong>Note:</strong> Accounting fee is depended on client company’s yearly revenue.
            You may take a look at{' '}
            <a
                rel="stylesheet"
                target={'_blank'}
                href={`${process.env.REACT_APP_BBCINCORP_HOST}/sg/accounting-and-auditing`}
            >
                our price range
            </a>
            . If you want to customize this package, please contact our customer service team for
            assistance.
        </Box>
    );
}

const TaxFillingServiceSingapore = ({ body, setServices, setSelectedRadio, category }) => {
    const dispatch = useDispatch();
    const incorporationState = useSelector((state) => state.incorporation);
    const questionTaxFillingService = QUESTIONS_TAX_FILLING_SERVICE.find((questionService) =>
        questionService.id_packages.includes(body.packageIncorp.id),
    );
    useEffect(() => {
        if (incorporationState.accAndFiling) {
            setSelectedRadio(true);
        }
    }, []);

    const handleChange = (event) => {
        dispatch(actionSetAccAndFiling(event.target.value));
        setSelectedRadio(true);

        if (
            body.packageIncorp.ServiceAccounting &&
            body.packageIncorp.ServiceAccounting.length > 0
        ) {
            if (parseInt(event.target.value)) {
                const idServiceSelected = parseInt(event.target.value);
                let service = body.packageIncorp.ServiceAccounting.find(
                    (e) => e.id === idServiceSelected,
                );
                console.log(idServiceSelected)
                setServices([
                    {
                        ...service,
                        quantity: 1,
                        disable: true,
                    },
                    ...body?.services.filter((item) => item.disable !== true),
                ]);
            } else {
                setServices(body?.services.filter((item) => item.disable !== true));
            }
        }
    };

    const hasShowNote = () => {
        if (incorporationState.accAndFiling && document.getElementById('add-service')) {
            const serviceSelected = questionTaxFillingService.answers.find(
                (answer) => answer.idService == incorporationState.accAndFiling,
            );
            if (serviceSelected) {
                return serviceSelected.hasShowNote;
            } else {
                return false;
            }
        }
        return false;
    };

    return (
        <div className="mb-6">
            <p>{questionTaxFillingService.question}</p>
            {hasShowNote() &&
                ReactDOM.createPortal(<Note />, document.getElementById('add-service'))}
            <RadioGroup onChange={handleChange} value={incorporationState.accAndFiling}>
                {questionTaxFillingService.answers.map((answer) => (
                    <FormControlLabel
                        key={answer.idService}
                        value={answer.idService}
                        control={<Radio />}
                        label={answer.label}
                    />
                ))}
            </RadioGroup>
        </div>
    );
};

export default TaxFillingServiceSingapore;

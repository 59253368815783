import React, { useEffect, useState, useRef, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import {
    getDefaultNationality,
    getDefaultPhonePrefix,
} from 'app/pages/services/utilities/NationalityHelpers';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import { get, isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import * as actionsOrderForm from '../../../../../_redux/order-form/orderFormActions';
import { formatCompanyMembers } from './step3/step3-helper/helpers';
import { BVI_ID, PANAMA_ID } from 'constants/country';
import SelectFullNameHint from 'app/components/SelectFullNameHint';

export default function Step1({
    activeStep,
    setActiveStep,
    isLoading,
    setIsLoading = () => {},
    body,
    setBody,
    disabled,
    hideAction,
    validateStepsRef = {},
    isOrderFormIsFilled,
    activeStepRef = {},
    index,
    hasShowSelectFullName,
}) {
    const dispatch = useDispatch();

    const contactSchema = Yup.object().shape({
        first_name:
            hasShowSelectFullName && Yup.string().nullable().required('First name is required!'),
        middle_name: hasShowSelectFullName && Yup.string().nullable(),
        last_name:
            hasShowSelectFullName && Yup.string().nullable().required('Last name is required!'),
        full_name: Yup.string()
            .nullable()
            .required(
                hasShowSelectFullName ? 'Please select your full name!' : 'Full name is required!',
            ),
        email: Yup.string()
            .nullable()
            .email('Please enter valid email!')
            .required('Email is required!'),
        phone_number: Yup.string()
            .nullable()
            .matches(/^[0-9]*$/, 'Please enter number!')
            .required('Phone number is required!'),
        phone_country_id: Yup.string().nullable(),
        address: Yup.string().nullable().required('Mailing Address is required!'),
        address_country_id: Yup.string().nullable(),
        city: Yup.string().nullable().required('City is required!'),
    });

    const form = useForm({
        defaultValues: {
            first_name: '',
            middle_name: '',
            last_name: '',
            full_name: '',
            email: '',
            phone_number: '',
            address: '',
            phone_country_id: null,
            address_country_id: null,
            state: '',
            city: '',
            postal_code: '',
        },
        resolver: yupResolver(contactSchema),
    });
    const [country, setCountry] = useState('');
    const [phonePrefix, setPhonePrefix] = useState('');
    const { countriesCooperationOptions, phonePrefixCountriesCooperation } = useSelector(
        (state) => state.enum,
    );

    useEffect(() => {
        try {
            setIsLoading(true);
            if (body && body.contactInfo) {
                // set nationality, phone prefix
                let country_reset, phone_prefix;
                country_reset = getDefaultNationality(
                    body.contactInfo.address_country_id,
                    countriesCooperationOptions,
                );
                phone_prefix = getDefaultPhonePrefix(
                    body.contactInfo.phone_country_id,
                    phonePrefixCountriesCooperation,
                );

                !isEmpty(body?.contactInfo?.country)
                    ? setCountry(body?.contactInfo?.country)
                    : setCountry(country_reset);
                !isEmpty(body?.contactInfo?.phone_prefix)
                    ? setPhonePrefix(body?.contactInfo?.phone_prefix)
                    : setPhonePrefix(phone_prefix);

                let full_name = '';
                body.Customer.first_name && (full_name += body.Customer.first_name);
                body.Customer.last_name && (full_name += ' ' + body.Customer.last_name);

                const defaultValue = {
                    first_name: body?.contactInfo?.first_name || full_name,
                    middle_name: body?.contactInfo?.middle_name,
                    last_name: body?.contactInfo?.last_name,
                    full_name: body?.contactInfo?.full_name,
                    email: body?.contactInfo?.email || body?.Customer?.email,
                    phone_number: body?.contactInfo?.phone_number,
                    phone_country_id: phone_prefix && phone_prefix.id,
                    address: body?.contactInfo?.address,
                    address_country_id: country_reset && country_reset.id,
                    city: body?.contactInfo?.city,
                    state: body?.contactInfo?.state,
                    postal_code: body?.contactInfo?.postal_code,
                };
                form.reset(defaultValue);

                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }, [body]);

    function onSubmitForm(index, values) {
        try {
            setIsLoading(true);
            let data = {
                contactInformationUpdate: {
                    ...values,
                    phone_number: values.phone_number.toString(),
                    company_id: body.companyInfo.company_id,
                    address_country_id: Number(values.address_country_id),
                    phone_country_id: Number(values.phone_country_id),
                    // các quốc gia không có first name && middle name && last name => cho first name = full name
                    first_name: hasShowSelectFullName ? values?.first_name : values?.full_name,
                },
            };
            dispatch(actionsOrderForm.updateOrderForm(body.Task.id, data)).then((res) => {
                try {
                    if (res && res.state) {
                        const currentBody = {
                            ...body,
                            Company: {
                                ...body.Company,
                                CompanyMembers: get(res, 'data.Company.CompanyMembers'),
                            },
                            contactInfo: {
                                id: get(res, 'data.Company.CompanyMembers[0].id'),
                                ...values,
                                address_country_id: Number(values.address_country_id),
                                phone_country_id: Number(values.phone_country_id),
                                country: country,
                                phone_prefix: phonePrefix,
                            },
                        };

                        // format CompanyMembers from data api
                        const companyStructureFormat = formatCompanyMembers(currentBody);

                        setBody({ ...currentBody, companyStructure: companyStructureFormat });
                        setIsLoading(false);
                        if (typeof activeStepRef.current !== 'number') {
                            setActiveStep(index);
                        }
                        activeStepRef.current = null;
                    } else {
                        setIsLoading(false);
                    }
                } catch (error) {
                    setIsLoading(false);
                    console.log(error);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = (values) => {
        onSubmitForm(1, values);
    };

    // truyền form validate cho file stepper
    validateStepsRef.current = {
        ...validateStepsRef.current,
        STEP_1: {
            isValid: form.formState.isValid,
            onSubmit: () => form.handleSubmit(handleSubmit)(),
        },
    };

    return (
        <div className="step1">
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <div className="row">
                    {hasShowSelectFullName && (
                        <>
                            <div className="col-md-4 mb-7 input-line">
                                <label>First name</label>
                                <InputField
                                    form={form}
                                    name={'first_name'}
                                    placeholder="First name"
                                    showIcon
                                    hideSuccessHelper
                                    disabled={disabled}
                                    onChange={(e) => {
                                        form.setValue('first_name', e.target.value);
                                        if (form.getValues('full_name')) {
                                            form.setValue('full_name', '');
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-md-4 mb-7 input-line">
                                <label>Middle name</label>
                                <InputField
                                    form={form}
                                    name={'middle_name'}
                                    placeholder="Middle name"
                                    showIcon
                                    hideSuccessHelper
                                    disabled={disabled}
                                />
                            </div>
                            <div className="col-md-4 mb-7 input-line">
                                <label>Last name</label>
                                <InputField
                                    form={form}
                                    name={'last_name'}
                                    placeholder="Last name"
                                    showIcon
                                    hideSuccessHelper
                                    disabled={disabled}
                                    onChange={(e) => {
                                        form.setValue('last_name', e.target.value);
                                        if (form.getValues('full_name')) {
                                            form.setValue('full_name', '');
                                        }
                                    }}
                                />
                            </div>
                        </>
                    )}
                    <div className="col-md-6 mb-7 input-line">
                        <label>Enter your full name</label>
                        <InputField
                            form={form}
                            name={'full_name'}
                            placeholder="Full name"
                            showIcon
                            hideSuccessHelper
                            disabled={disabled || hasShowSelectFullName}
                            multiline={hasShowSelectFullName}
                        />
                    </div>
                    <div className="col-md-6 mb-7 input-line">
                        <label>Email address</label>
                        <InputField
                            form={form}
                            name="email"
                            placeholder="Enter email address"
                            showIcon
                            hideSuccessHelper
                            disabled={disabled}
                        />
                    </div>
                    {hasShowSelectFullName && (
                        <SelectFullNameHint
                            form={form}
                            fullNameField={'full_name'}
                            firstNameField={'first_name'}
                            middleNameField={'middle_name'}
                            lastNameField={'last_name'}
                            disabled={disabled}
                        />
                    )}
                    <div className="col-md-6 mb-7 input-line">
                        <label>Phone number</label>
                        <div className="phone-group">
                            <div className="phone-code" style={{ zIndex: 1 }}>
                                <SelectField
                                    form={form}
                                    className="fw-500"
                                    label={''}
                                    name="phone_country_id"
                                    id="phone-prefix-select"
                                    options={phonePrefixCountriesCooperation || []}
                                    value={phonePrefix && phonePrefix}
                                    onChange={(value) => {
                                        if (phonePrefix?.id != value.id) {
                                            setPhonePrefix(value);
                                        }
                                    }}
                                    customStyle={{
                                        '.MuiInputBase-root:before, .MuiInputBase-root:after, .MuiInput-underline:hover:not(.Mui-disabled):before':
                                            {
                                                borderBottom: '0 !important',
                                            },
                                    }}
                                    hideInputHelperText
                                    hideSuccessHelper
                                    disabled={disabled}
                                />
                            </div>
                            <div className="input-line inp-phone">
                                <InputField
                                    form={form}
                                    name="phone_number"
                                    placeholder="Phone number"
                                    showIcon
                                    hideSuccessHelper
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6"></div>
                    <div className="col-md-6 mb-7 input-line">
                        <label>Mailing Address</label>
                        <InputField
                            form={form}
                            name="address"
                            placeholder="Your Mailing Address"
                            showIcon
                            hideSuccessHelper
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-md-6"></div>
                    {/* address group */}
                    <div className="col-md-3 col-sm-6 mb-7 input-line">
                        <label>Country</label>
                        <SelectField
                            form={form}
                            className="fw-500"
                            label={''}
                            name="address_country_id"
                            id="country-select"
                            options={countriesCooperationOptions || []}
                            value={country && country}
                            onChange={(value) => {
                                if (country?.id != value.id) {
                                    setCountry(value);
                                }
                            }}
                            hideInputHelperText
                            hideSuccessHelper
                            autoComplete="off"
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-md-3 col-sm-6 mb-7 input-line mb-7">
                        <label>City</label>
                        <InputField
                            form={form}
                            name="city"
                            placeholder="Your City"
                            showIcon
                            hideSuccessHelper
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-md-3 col-sm-6 mb-7 input-line">
                        <label>State/Province</label>
                        <InputField
                            form={form}
                            name="state"
                            placeholder="Your State/Province"
                            showIcon
                            hideSuccessHelper
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-md-3 col-sm-6 mb-7 input-line">
                        <label>ZIP/Postal code</label>
                        <InputField
                            form={form}
                            name="postal_code"
                            placeholder="Your ZIP/Postal code"
                            showIcon
                            hideSuccessHelper
                            disabled={disabled}
                        />
                    </div>
                </div>

                {!hideAction && (
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                        style={{ color: '#fff' }}
                        disabled={isLoading || disabled}
                        onClick={() => (activeStepRef.current = null)}
                    >
                        Next
                    </Button>
                )}
            </form>
        </div>
    );
}

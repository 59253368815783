import React from 'react';
import { ArrowRight, CheckCircle } from '@mui/icons-material';

const isAllSigned = (titles) => {
    return titles.every((title) => {
        return title.belong_to_company_member_ids.every((member) => member.is_signed);
    });
};

const isAllWebInkSigned = (template) => {
    if (template) {
        if (template.is_not_signed || (template.titles && template.titles.length === 0) || (template.Files && template.titles &&
            template.Files.length > 0)) {
            return true;
        }
    }
    return false;
}

export default function TemplateList({
    fileTemplates,
    currentTemplate,
    setCurrentTemplate,
    disabled,
}) {
    // filter e-sign templates
    const eSignTemplates = fileTemplates?.filter((template) => template.is_esign && template.titles.length > 0) || [];
    // web-ink download templates
    const webInkTempaltes = fileTemplates?.filter((template) => !template.is_esign && template.titles.length > 0) || [];

    /**
     * ---------------------------------------------------------------------------------------
     * JSX return ----------------------------------------------------------------------------
     * ---------------------------------------------------------------------------------------
     */
    return (
        <>
            {/* <h6 className="text-uppercase font-weight-bolder px-3">DOCUMENT LIST</h6> */}
            <div className="types-container px-3">
                <ul className="list-unstyled pl-5">
                    <li className="mb-5">
                        <p className="font-weight-bold text-uppercase mb-2">
                            {eSignTemplates && eSignTemplates.length > 0 ? 'E-SIGNATURE' : ''}
                        </p>
                        <ul className="list-unstyled text-black-50 file-type-list">
                            {eSignTemplates.map((template, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={`row mx-0 mb-2${currentTemplate &&
                                            currentTemplate.id === template.id &&
                                            !disabled
                                            ? ' text-dark'
                                            : ''
                                            }`}
                                        onClick={
                                            !disabled
                                                ? () => setCurrentTemplate(template)
                                                : () => { }
                                        }
                                    >
                                        <div
                                            className={`col-1 p-0${disabled ? ' text-muted' : ''}`}
                                        >
                                            {currentTemplate &&
                                                currentTemplate.id === template.id && (
                                                    <ArrowRight fontSize="small" />
                                                )}
                                        </div>
                                        <div
                                            className={`col-11 p-0${disabled ? ' text-muted' : ''}`}
                                        >
                                            {index + 1}. {template.name}
                                            {template &&
                                                template.titles &&
                                                isAllSigned(template.titles) ? (
                                                <CheckCircle
                                                    color="primary"
                                                    className="d-inline ml-2"
                                                    style={{ fontSize: '0.9rem', marginBottom: 2 }}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                    <li className="mb-5">
                        <p className="font-weight-bold text-uppercase mb-2">
                            {webInkTempaltes && webInkTempaltes.length > 0
                                ? 'Sign Docs Download'
                                : ''}
                        </p>
                        <ul className="list-unstyled text-black-50 file-type-list">
                            {webInkTempaltes.map((template, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={`row mx-0 mb-2${currentTemplate &&
                                            currentTemplate.id === template.id &&
                                            !disabled
                                            ? ' text-dark'
                                            : ''
                                            }`}
                                        onClick={() => setCurrentTemplate(template)}
                                    >
                                        <div
                                            className={`col-1 p-0${disabled ? ' text-muted' : ''}`}
                                        >
                                            {currentTemplate &&
                                                currentTemplate.id === template.id && (
                                                    <ArrowRight fontSize="small" />
                                                )}
                                        </div>
                                        <div
                                            className={`col-11 p-0${disabled ? ' text-muted' : ''}`}
                                        >
                                            {index + 1}. {template.name}
                                            {isAllWebInkSigned(template) ? (
                                                <CheckCircle
                                                    color="primary"
                                                    className="d-inline ml-2"
                                                    style={{ fontSize: '0.9rem', marginBottom: 2 }}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                </ul>
            </div>
        </>
    );
}

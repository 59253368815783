import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    link: {
        cursor: 'pointer',
        '&:hover, &:focus': {
            textDecoration: 'underline',
        },
    },
}));

const FileList = ({ currentTemplate, currentTitle, openNativePdf, openHTMLFile }) => {
    const classes = useStyles();
    const openFileDetail = (title) => {
        if (currentTemplate.is_cs_uploaded) {
            openNativePdf(title);
        } else {
            const titleParam =
                title && title.company_member_id
                    ? {
                          company_member_id: title.company_member_id,
                          to_company_member_id: title.to_company_member_id,
                      }
                    : {};
            const companyEventParam = title && title.company_event_id ? {
                company_event_id: title.company_event_id
            } : {};
            openHTMLFile({
                templateParams: { file_template_id: currentTemplate.id, ...titleParam, ...companyEventParam },
                file_name: title.file_name,
            });
        }
    };

    return (
        <>
            <div className="file-elements-container px-5">
                {currentTitle && ![279, 278,289].includes(currentTitle?.file_template_id) ? (
                    <div className="row m-0">
                        <div className="col-12">
                            <div className="px-2 my-2 d-inline-block">
                                <div
                                    className={`rounded-sm border border-secondary p-2 h-100 d-flex align-items-center file-element ${classes.link}`}
                                    onClick={() => openFileDetail(currentTitle)}
                                >
                                    <img
                                        src="/media/svg/files/file.svg"
                                        height={15}
                                        width={15}
                                        className="mr-2"
                                        alt="file ico"
                                    />
                                    <div className="caption">{currentTitle.file_name}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default FileList;

import { yupResolver } from '@hookform/resolvers/yup';
import ContactInfoForm from 'app/pages/components/forms/ContactInfoForm';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { format } from 'date-fns';
import { DATE_DB_FORMAT } from 'constants/index';
import { getContactInfoAllowFields } from '../../helpers';

const contactInfoSchema = ({ hasShowSelectFullName }) => {
    return yup
        .object({
            first_name:
                hasShowSelectFullName &&
                yup.string().nullable().required('First name is required!'),
            middle_name: hasShowSelectFullName && yup.string().nullable(),
            last_name:
                hasShowSelectFullName && yup.string().nullable().required('Last name is required!'),
            full_name: yup
                .string()
                .nullable()
                .required(
                    hasShowSelectFullName
                        ? 'Please select your full name!'
                        : 'Please enter Full name',
                ),
            email: yup.string().nullable().email('Invalid email').required('Please enter email'),
            phone_country_id: yup.number().nullable().required('Please enter phone code'),
            phone_number: yup
                .string()
                .nullable()
                .required('Please enter phone number')
                .matches(/^[0-9]*$/, 'Please enter number!'),
            address: yup.string().nullable().required('Please enter address'),
            address_country_id: yup
                .string()
                .nullable('Please enter')
                .required('Please enter country'),
            city: yup.string().nullable().required('Please enter city'),
            state: yup.string().nullable(),
            postal_code: yup.string().nullable(),
            birthday: yup.date().nullable().required('Please enter Birthday'),
            country_id: yup.string().nullable().required('Please enter Nationality'),
            passport: yup.string().nullable().required('Please enter Passport'),
            passport_expire_date: yup
                .date()
                .nullable()
                .required('Please enter Date of expire passport'),
        })
        .required();
};

const ContactInfo = ({ task, handleNext, onSubmit, hasShowSelectFullName = false }) => {
    const { countriesCooperationOptions, phonePrefixCountriesCooperation } = useSelector(
        (state) => state.enum,
    );
    const { comparedCompany } = useSelector((state) => state.companies);
    const form = useForm({
        defaultValues: {
            first_name: '',
            middle_name: '',
            last_name: '',
            full_name: '',
            email: '',
            phone_number: '',
            address: '',
            phone_country_id: null,
            address_country_id: null,
            state: '',
            city: '',
            postal_code: '',
            birthday: null,
            country_id: null,
            passport: '',
            passport_expire_date: null
        },
        resolver: yupResolver(contactInfoSchema(hasShowSelectFullName)),
    });

    useEffect(() => {
        if (contactMemberInfo) {
            const defaultValue = {
                first_name: contactMemberInfo?.first_name || '',
                middle_name: contactMemberInfo?.middle_name || '',
                last_name: contactMemberInfo?.last_name || '',
                full_name: contactMemberInfo?.full_name || '',
                email: contactMemberInfo?.email || '',
                phone_country_id: contactMemberInfo?.phone_country_id || null,
                phone_number: contactMemberInfo?.phone_number || '',
                address: contactMemberInfo?.address || '',
                address_country_id: contactMemberInfo?.address_country_id || null,
                city: contactMemberInfo?.city || '',
                state: contactMemberInfo?.state || '',
                postal_code: contactMemberInfo?.postal_code || '',
                birthday: contactMemberInfo?.birthday || null,
                passport: contactMemberInfo?.passport || '',
                passport_expire_date: contactMemberInfo?.passport_expire_date || null,
                country_id: contactMemberInfo?.country_id || null,
            };

            form.reset(defaultValue);
        }
    }, [contactMemberInfo]);

    const contactMemberInfo = useMemo(() => {
        if (!task) return null;
        return task.Company.CompanyMembers.find((member) => {
            return member.CompanyPositions.find((position) => {
                return position?.company_member_type_id === 7;
            });
        });
    }, [task]);

    const allowFields = useMemo(() => {
        let fields = {}
        if(contactMemberInfo && comparedCompany) {
            const comparedData = comparedCompany?.Company?.CompanyMembers?.find(member => member.id === contactMemberInfo.id)
            fields = getContactInfoAllowFields(contactMemberInfo, comparedData);
        }
        return fields
    }, [contactMemberInfo, comparedCompany]);

    const handleSubmit = (values) => {
        const submitData = {
            ...values,
            birthday: format(values.birthday, DATE_DB_FORMAT),
            passport_expire_date: format(values.passport_expire_date, DATE_DB_FORMAT)
        }
        const payload = {
            contactInformationUpdate: submitData,
        };
        if (Object.keys(form.formState.dirtyFields).length === 0) {
            handleNext();
        } else {
            onSubmit && onSubmit(payload);
        }
    };

    return (
        <ContactInfoForm
            form={form}
            hasShowSelectFullName={hasShowSelectFullName}
            countryOptions={countriesCooperationOptions}
            phoneCountryOptions={phonePrefixCountriesCooperation}
            onSubmit={handleSubmit}
            allowFields={allowFields}
        />
    );
};

export default ContactInfo;

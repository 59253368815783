import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { Button } from '@mui/material';
import DocumentTitleList, { isUploaded } from '../common/DocumentTitleList';
import NativeReader from '../../order-form/document-controller/file-detail/NativeReader';
import HTMLReader from '../../order-form/document-controller/file-detail/HTMLReader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { Modal } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import {
    updateOrderInfo,
    updateLoadingUploadFileAction,
} from '../../../../_redux/additional-service/slice';
import { getProcessDetail, createRecordFile } from '../../../../_redux/additional-service/api';
import { deleteFileAction } from '../../../../_redux/additional-service/asyncThunk';
import * as ActionsOrder from 'app/pages/_redux/order-form/orderFormActions';
import { getSignedUrlS3, downloadFileS3 } from '../../../../_redux/files/filesCrud';
import UploadBox from 'app/components/UploadBox';
import DocumentMiniList from 'app/components/DocumentMiniList';
import FileList from '../common/FileList';
import NoteFromCs from '../../banking/banking-process/application-set/components/NoteFromCs';

function SignForm({ onSubmit, isUploadAllFile, isContentNote, isCSNote }) {
    let [activeTitle, setActiveTitle] = useState(null);
    let [activeCate, setActiveCate] = useState(null);
    let [showModal, setShowModal] = useState(false);
    let [modalData, setModalData] = useState(null);
    const TINServiceId = 1851;
    const [htmlDownloading, setHtmlDownloading] = useState(false);
    const { loadingUploadFile, loadingDeleteFile, activeProcess, orderInfo } = useSelector(
        (state) => state.additionalService,
    );
    const uploadBoxRef = useRef(null);
    const dispatch = useDispatch();

    const process = useMemo(() => {
        return orderInfo?.Task?.Processes?.[0];
    }, [orderInfo]);

    useEffect(() => {
        if (!orderInfo) return;
        //update data from api when upload or delete file
        if (activeCate) {
            const newestActiveCate = [
                ...(orderInfo?.Task?.Processes?.[0]?.ProcessStep?.FileTemplates || []),
            ].find((file) => activeCate.id === file.id);
            setActiveCate(cloneDeep(newestActiveCate));
        }
    }, [orderInfo]);

    const onSelectTitle = useCallback(([cate, file]) => {
        // console.log('cate', cate)
        setActiveCate(cate);
        setActiveTitle(file);
    }, []);

    const renderNote = () => {
        return orderInfo?.Note?.[0] &&
            (orderInfo?.Note[0].description || orderInfo?.Note[0].Files.length > 0) ? (
            <div>
                <NoteFromCs
                    note={orderInfo?.Note[0].description}
                    files={orderInfo?.Note[0].Files}
                    onFileClick={onClickView}
                />
            </div>
        ) : (
            ''
        );
    };

    const getNoteContent = (country_id, isTINService) => {
        switch (country_id) {
            //ANGUILLA
            case 8:
                return (
                    <div className="px-10">
                        <div>Note:</div>
                        <p>
                            More information in:{' '}
                            <a
                                target="_blank"
                                href="https://bbcincorp.com/offshore/articles/anguilla-economic-substance-requirements"
                            >
                                HERE
                            </a>
                        </p>
                    </div>
                );

            //BAHAMAS
            case 17:
                return (
                    <div className="px-10">
                        <div>Note:</div>
                        <ul>
                            <li>
                                Form A: Company that provides outsourcing services to an included
                                entity
                            </li>
                            <li>
                                Form B: Company that is engaged in a relevant activity provided and
                                it is not a tax resident in a jurisdiction outside the Bahamas
                            </li>
                            <li>Form C: Company that is a holding company</li>
                            <li>
                                Form D: Company that is not engaged in a relevant activity or is a
                                tax resident in a jurisdiction outside the Bahamas.
                            </li>
                        </ul>
                        <div>
                            If the client is a non-included entity (submit Form D), please provide
                            additional "Evidence of tax resident in another jurisdiction" - except
                            for the company does not fall into the scope of relevant activities
                        </div>
                        <p>
                            More information in:{' '}
                            <a
                                target="_blank"
                                href="https://bbcincorp.com/offshore/articles/bahamas-economic-substance-requirements"
                            >
                                HERE
                            </a>
                        </p>
                    </div>
                );

            //BELIZE
            case 23:
                if (isTINService) {
                    return (
                        <div className="px-10">
                            <div>Note:</div>
                            <p>
                                Belize company must have a Tax Identification Number (“TIN”) in
                                order to submit all the annual compliances of your company.
                            </p>
                        </div>
                    );
                } else {
                    return (
                        <div className="px-10">
                            <div>Note:</div>
                            <ul>
                                <li>
                                    Form B: Company that is engaged in a relevant activity provided
                                    and is not a tax resident in a jurisdiction outside Belize
                                </li>
                                <li>Form C: Company that is a holding company</li>
                                <li>
                                    Form D: Company that is not engaged in a relevant activity or is
                                    a tax resident in a jurisdiction outside Belize
                                </li>
                            </ul>
                            <div>
                                If the client is a non-included entity (submit Form D), please
                                provide additional "Evidence of tax resident in another
                                jurisdiction" - except for the company does not fall into the scope
                                of relevant activities
                            </div>
                            <p>
                                More information in:{' '}
                                <a
                                    target="_blank"
                                    href="https://bbcincorp.com/offshore/news/belize-economic-substance-requirement"
                                >
                                    HERE
                                </a>
                            </p>
                        </div>
                    );
                }

            //BVI
            case 32:
                return (
                    <div className="px-10">
                        <div>Note:</div>
                        <div className="mb-2">
                            ALL COMPANIES are required to file the Declaration of The Beneficial
                            Owner form and ONE of the below documents.
                        </div>
                        <ul className="mb-2">
                            <li>
                                Resolution for Category A: Company that is not engaged in a relevant
                                activity
                            </li>
                            <li>
                                Resolution for Category B: Company that is engaged in a relevant
                                activity and is not a tax resident in a jurisdiction outside BVI
                            </li>
                            <li>
                                Resolution for Category C: Company that is engaged in a relevant
                                activity and is a tax resident in a jurisdiction outside BVI
                            </li>
                        </ul>
                        <div>
                            If the client submits Category C, please provide additional "Evidence of
                            tax resident in another jurisdiction"
                        </div>
                        <p>
                            More information in:{' '}
                            <a
                                target="_blank"
                                href="https://bbcincorp.com/offshore/articles/a-guide-to-bvi-economic-substance-requirements"
                            >
                                HERE
                            </a>
                        </p>
                    </div>
                );

            //CAYMAN ISLAND
            case 41:
                return (
                    <div className="px-10">
                        <p>
                            More information in:{' '}
                            <a
                                target="_blank"
                                href="https://bbcincorp.com/offshore/articles/cayman-islands-economic-substance-requirements"
                            >
                                HERE
                            </a>
                        </p>
                    </div>
                );

            //MARSHALL
            case 136:
                return <div></div>;

            //RAK
            case 236:
                return (
                    <div className="px-10">
                        <div>Note:</div>
                        <div>
                            If the company that is controlled and managed outside of RAK and is a
                            tax resident in a jurisdiction other than RAK, please provide evidence
                            of tax-resident in another jurisdiction
                        </div>
                        <p>
                            More information in:{' '}
                            <a
                                target="_blank"
                                href="https://bbcincorp.com/offshore/articles/uae-economic-substance-regulations"
                            >
                                HERE
                            </a>
                        </p>
                    </div>
                );
        }
    };

    const onUploadFile = async (files) => {
        if (!files.length || !activeTitle || !process) return;
        dispatch(updateLoadingUploadFileAction('loading'));
        const promisesPutFile = [];
        const promisesCreateRecordFile = [];
        const params = [];
        for (let i = 0; i < files.length; i++) {
            params.push({ filename: files[i].name, content_type: files[i].type });
        }
        try {
            /* gen s3 signed url */
            const {
                data: { data: signedUrls },
            } = await getSignedUrlS3(params);
            for (let i = 0; i < signedUrls.length; i++) {
                promisesPutFile.push(axios.put(signedUrls[i].url, files[i], {}));
            }
            /* Call tất cả các hàm push file */
            await Promise.all(promisesPutFile);
            /* Xử lý create record file */
            for (let i = 0; i < signedUrls.length; i++) {
                let payload;
                payload = {
                    file_type_id: activeTitle.file_type_id,
                };
                if (activeTitle.company_member_id) {
                    let member = {
                        file_template_id: activeTitle.file_template_id,
                        name: signedUrls[i].filename,
                        s3_path: signedUrls[i].filename,
                        mime_type: files[i].type,
                        task_id: orderInfo.Task.id,
                        file_type_id: activeTitle.file_type_id,
                        company_id: orderInfo?.company_id,
                        company_member_id: activeTitle.company_member_id,
                        to_company_member_id: activeTitle?.to_company_member_id,
                    };
                    payload.member = member;
                } else {
                    let file_template = {
                        file_template_id: activeTitle.file_template_id,
                        name: signedUrls[i].filename,
                        s3_path: signedUrls[i].filename,
                        mime_type: files[i].type,
                        task_id: orderInfo.Task.id,
                        file_type_id: activeTitle.file_type_id,
                        company_id: orderInfo?.company_id,
                    };
                    payload.file_template = file_template;
                }
                // let file_template = {
                //     file_template_id: activeTitle.file_template_id,
                //     name: signedUrls[i].filename,
                //     s3_path: signedUrls[i].filename,
                //     mime_type: files[i].type,
                //     task_id: orderInfo.Task.id,
                //     company_id: orderInfo?.company_id,
                // };
                // payload.file_template = file_template;

                // console.log(payload, process)
                promisesCreateRecordFile.push(createRecordFile(payload));
            }
            await Promise.all(promisesCreateRecordFile);
            /* Get lại process detail data để hiển thị lại các file đã upload */
            const {
                data: { data },
            } = await getProcessDetail(activeProcess.Processes[0].id);
            dispatch(updateOrderInfo(data));
            dispatch(updateLoadingUploadFileAction('idle'));
            /* reset value of input */
            uploadBoxRef.current.value = '';
        } catch (e) {
            console.log('error upload: ', e);
            dispatch(updateLoadingUploadFileAction('error'));
            toast.error('Somethings went wrong!');
            /* reset value of input */
            uploadBoxRef.current.value = '';
        }
    };

    const uploadList = useMemo(() => {
        return activeTitle?.files?.length > 0
            ? activeTitle.files
            : activeCate?.Files.filter((file) =>
                  /* không thể check === vì giá trị có thể undefined hoặc null */
                  file?.to_company_member_id
                      ? file?.to_company_member_id == activeTitle?.to_company_member_id
                      : file?.company_member_id
                      ? file?.company_member_id == activeTitle?.company_member_id
                      : file?.file_template_id == activeTitle?.file_template_id,
              ) || [];
    }, [activeCate, activeTitle]);

    const onClickView = async (doc) => {
        try {
            const {
                data: { data },
            } = await downloadFileS3(doc.s3_path);
            if (['doc', 'docx'].includes(doc.s3_path.split('.')[1].toLowerCase())) {
                saveAs(data, doc.s3_path);
                return;
            }
            setShowModal(true);
            setModalData({ file: { name: doc.s3_path }, file_url: data });
        } catch (e) {
            toast.error('Somethings when wrong!');
        }
    };

    const onClickDelete = async (doc) => {
        const resultAction = await dispatch(deleteFileAction(doc.id));
        if (deleteFileAction.fulfilled.match(resultAction)) {
            /* Get lại process detail data để hiển thị lại các file đã upload */
            const {
                data: { data },
            } = await getProcessDetail(activeProcess.Processes[0].id);
            dispatch(updateOrderInfo(data));
        } else {
            toast.error('Somethings went wrong!');
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const downloadNativePDF = () => {
        return new Promise((resolve, reject) => {
            saveAs(modalData.file_url, modalData.file.name);
            resolve(true);
        });
    };

    const isCheckValid = () => {
        if (isUploadAllFile) {
            return isHasUploadAll();
        } else {
            return isHasUploaded();
        }
    };

    const isHasUploaded = () => {
        return process?.ProcessStep?.FileTemplates && process?.ProcessStep?.FileTemplates.length > 0
            ? process?.ProcessStep.FileTemplates.some((template) => {
                  return template?.titles?.some((title) => {
                      return isUploaded(title, template);
                  });
              })
            : false;
    };

    const isHasUploadAll = () => {
        return process?.ProcessStep?.FileTemplates && process?.ProcessStep?.FileTemplates.length > 0
            ? process?.ProcessStep.FileTemplates.every((template) => {
                  return template?.titles?.every((title) => {
                      return isUploaded(title, template);
                  });
              })
            : false;
    };

    const handleNext = async () => {
        if (!isCheckValid()) return;
        onSubmit && onSubmit();
    };

    const openHTMLFile = (params) => {
        if (orderInfo?.Task && orderInfo?.Task?.id && params) {
            // setActionLoading(true);
            dispatch(
                ActionsOrder.fetchTemplateHTML({
                    task_id: orderInfo.Task.id,
                    ...params.templateParams,
                }),
            )
                .then((response) => {
                    if (response && response.data && response.data.html) {
                        setShowModal(true);
                        setModalData({
                            html: response.data.html,
                            templateParams: params.templateParams,
                            file_name: params.file_name,
                        });
                    }
                })
                .finally(() => {
                    // setActionLoading(false);
                });
        }
    };
    const downloadHTMLAsPDF = () => {
        if (orderInfo?.Task && orderInfo?.Task.id && modalData && modalData.templateParams) {
            setHtmlDownloading(true);
            dispatch(
                ActionsOrder.downloadTemplateHTML({
                    task_id: orderInfo?.Task.id,
                    ...modalData.templateParams,
                }),
            )
                .then((response) => {
                    saveAs(
                        response,
                        `${modalData.file_name.split('.')[0]} - ${orderInfo?.id}.${
                            modalData.file_name.split('.')[1]
                        }`,
                    );
                    setHtmlDownloading(false);
                })
                .catch(() => {
                    setHtmlDownloading(false);
                });
        }
    };

    return (
        <>
            <div className={'row shadow-sm rounded mb-5'}>
                <div className={'col-12 col-md-3 bg-light p-0'}>
                    <h6 className={'text-center border-bottom p-5'}>DOCUMENT LIST</h6>
                    <div className="px-5">
                        <DocumentTitleList
                            filesTemplate={process?.ProcessStep?.FileTemplates || []}
                            onSelectTitle={onSelectTitle}
                            autoSelectFirstTitle={true}
                        />
                    </div>
                </div>

                <div
                    className={
                        'col-12 col-md-6 border-right p-0 d-flex flex-column justify-content-between'
                    }
                >
                    <div>
                        <h6 className={'text-center border-bottom p-5'}>FILES DETAILS</h6>
                        <FileList
                            currentTemplate={activeCate}
                            currentTitle={activeTitle}
                            openHTMLFile={openHTMLFile}
                            orderInfo={orderInfo}
                        />
                    </div>
                    {isCSNote && renderNote()}
                    {isContentNote &&
                        getNoteContent(
                            orderInfo?.Company?.country_id,
                            orderInfo?.OrderItems?.some(
                                (item) => item?.Service?.id === TINServiceId,
                            ),
                        )}
                </div>

                {activeTitle && (
                    <div className={'col-12 col-md-3 p-0 '}>
                        <h6 className={'text-center border-bottom p-5'}>SIGNING STATUS</h6>
                        <div className={'p-3'}>
                            <UploadBox
                                onUploadFile={onUploadFile}
                                ref={uploadBoxRef}
                                loading={loadingUploadFile === 'loading'}
                            />
                            <DocumentMiniList
                                documentList={uploadList}
                                onClickView={onClickView}
                                onClickDelete={onClickDelete}
                                loadingMap={loadingDeleteFile}
                                panelTitle={'Your wet-link documents'}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className={'d-flex justify-content-end'}>
                <Button
                    variant="contained"
                    disabled={!isCheckValid()}
                    className="text-white"
                    onClick={handleNext}
                    color="primary"
                >
                    Next
                </Button>
            </div>

            <Modal show={showModal} onHide={closeModal} size="xl" centered>
                <Modal.Body>
                    {modalData && (
                        <>
                            {modalData?.html && (
                                <HTMLReader
                                    closeDetailModal={closeModal}
                                    data={modalData}
                                    download={downloadHTMLAsPDF}
                                    downloading={htmlDownloading}
                                    fetched={true}
                                />
                            )}
                            {modalData?.file_url && (
                                <NativeReader
                                    closeDetailModal={closeModal}
                                    data={modalData}
                                    download={downloadNativePDF}
                                />
                            )}
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default SignForm;

import { createSlice } from '@reduxjs/toolkit';

const initialMessageState = {
    actionsLoading: false,
    messageForEdit: undefined,
    s: undefined,
    lastError: null,
};
export const callTypes = {
    list: 'list',
    action: 'action',
};

export const messagesSlice = createSlice({
    name: 'messages',
    initialState: initialMessageState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        messageFetched: (state, action) => {
            const { count, rows } = action.payload;
            state.listLoading = false;
            state.messageForEdit = action.payload.messageForEdit;
            state.error = null;
            state.entities = rows;
            state.totalCount = count;
        },
    },
});

import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import RadioField from 'components/form-controls/RadioField';
import SelectField from 'components/form-controls/SelectField';
import { getQuestionAnswerOptions } from 'app/pages/services/order-management/bookeeping-process/helpers';

const NatureOfControl = ({ task, form, index, disabled, IS_UK_LLP }) => {
    const questionsAnswerOptions = useMemo(() => {
        if (!task) return [];
        return getQuestionAnswerOptions(task.Task.IncorporationCountry.Questions);
    }, [task]);

    const wNature_ownership = form.watch(`members.${index}.nature_ownership`);
    const wNature_voting_rights = form.watch(`members.${index}.nature_voting_rights`);
    const wNature_appoint_majority = form.watch(`members.${index}.nature_appoint_majority`);
    const wNature_other_significant_control = form.watch(
        `members.${index}.nature_other_significant_control`,
    );
    const wFirm = form.watch(`members.${index}.firm`);
    const wTrust = form.watch(`members.${index}.trust`);

    return (
        <Box sx={{ marginTop: '32px' }}>
            <Typography fontWeight={'medium'} mb={2}>
                NATURE OF CONTROL
            </Typography>
            <Grid container spacing={2}>
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>
                        {IS_UK_LLP ? 'Right to earn surplus assets' : 'Ownership of shares'}
                    </label>
                    <SelectField
                        form={form}
                        name={`members.${index}.nature_ownership`}
                        options={questionsAnswerOptions?.nature_ownership || []}
                        getOptionLabel={(option) => option.content}
                        // onChange={(value) => onChangeQuestion1(value)}
                        disabled={disabled}
                        placeholder={
                            IS_UK_LLP
                                ? 'Choose Right to earn surplus assets'
                                : 'Choose Ownership of shares'
                        }
                    />
                </Grid>
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Voting Rights</label>
                    <SelectField
                        form={form}
                        name={`members.${index}.nature_voting_rights`}
                        options={questionsAnswerOptions?.nature_voting_rights || []}
                        getOptionLabel={(option) => option.content}
                        placeholder={'Choose Voting Rights'}
                        // onChange={(value) => onChangeQuestion1(value)}
                        disabled={disabled}
                    />
                </Grid>
                <Grid className="input-line" item xs={12}>
                    <div>Appoint or remove the majority of the board of directors</div>
                    <RadioField
                        form={form}
                        name={`members.${index}.nature_appoint_majority`}
                        options={questionsAnswerOptions?.nature_appoint_majority || []}
                        renderLabel={(option) => option.content}
                        radioGroupProps={{ sx: { flexDirection: 'row' } }}
                        disabled={disabled}
                    />
                </Grid>
                <Grid className="input-line" item xs={12}>
                    <div>Other Significant influences or control</div>
                    <RadioField
                        form={form}
                        name={`members.${index}.nature_other_significant_control`}
                        options={questionsAnswerOptions?.nature_other_significant_control || []}
                        renderLabel={(option) => option.content}
                        radioGroupProps={{ sx: { flexDirection: 'row' } }}
                        disabled={disabled}
                    />
                </Grid>
                {wNature_ownership == 47 &&
                    wNature_voting_rights == 51 &&
                    wNature_appoint_majority == 56 &&
                    wNature_other_significant_control == 58 && (
                        <>
                            <Grid item sm={6} xs={12}>
                                <Box
                                    sx={{
                                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                        padding: '16px',
                                    }}
                                >
                                    <div style={{ marginBottom: '16px' }}>
                                        <div>
                                            Does this officer have the right to exercise significant
                                            influence or control over a firm(s) which also has a
                                            controlling influence in this company?
                                        </div>
                                        <RadioField
                                            form={form}
                                            name={`members.${index}.firm`}
                                            options={questionsAnswerOptions?.firm || []}
                                            renderLabel={(option) => option.content}
                                            radioGroupProps={{ sx: { flexDirection: 'row' } }}
                                            disabled={disabled}
                                        />
                                    </div>
                                    {wFirm == 59 && (
                                        <>
                                            <Typography
                                                fontWeight={'medium'}
                                                sx={{ marginBottom: '16px' }}
                                            >
                                                TYPES OF CONTROL WITHIN THE FIRM(S)
                                            </Typography>
                                            <div
                                                className="input-line"
                                                style={{ marginBottom: '16px' }}
                                            >
                                                <label>Ownership of shares</label>
                                                <SelectField
                                                    form={form}
                                                    name={`members.${index}.firm_ownership`}
                                                    options={
                                                        questionsAnswerOptions?.firm_ownership || []
                                                    }
                                                    getOptionLabel={(option) => option.content}
                                                    placeholder={'Choose Ownership of shares'}
                                                    // onChange={(value) => onChangeQuestion1(value)}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div
                                                className="input-line"
                                                style={{ marginBottom: '16px' }}
                                            >
                                                <label>Voting Rights</label>
                                                <SelectField
                                                    form={form}
                                                    name={`members.${index}.firm_voting_rights`}
                                                    options={
                                                        questionsAnswerOptions?.firm_voting_rights ||
                                                        []
                                                    }
                                                    getOptionLabel={(option) => option.content}
                                                    placeholder={'Choose Voting Rights'}
                                                    // onChange={(value) => onChangeQuestion1(value)}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div style={{ marginBottom: '16px' }}>
                                                <div>
                                                    Appoint or remove the majority of the board of
                                                    directors
                                                </div>
                                                <RadioField
                                                    form={form}
                                                    name={`members.${index}.firm_appoint_majority`}
                                                    options={
                                                        questionsAnswerOptions?.firm_appoint_majority ||
                                                        []
                                                    }
                                                    renderLabel={(option) => option.content}
                                                    radioGroupProps={{
                                                        sx: { flexDirection: 'row' },
                                                    }}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div>
                                                <div>Other Significant influences or control</div>
                                                <RadioField
                                                    form={form}
                                                    name={`members.${index}.firm_other_significant_control`}
                                                    options={
                                                        questionsAnswerOptions?.firm_other_significant_control ||
                                                        []
                                                    }
                                                    renderLabel={(option) => option.content}
                                                    radioGroupProps={{
                                                        sx: { flexDirection: 'row' },
                                                    }}
                                                    disabled={disabled}
                                                />
                                            </div>
                                        </>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Box
                                    sx={{
                                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                        padding: '16px',
                                    }}
                                >
                                    <div style={{ marginBottom: '16px' }}>
                                        <div>
                                            Does this officer have the right to exercise significant
                                            influence or control over a trust(s) which has a
                                            controlling interest in this company?
                                        </div>
                                        <RadioField
                                            form={form}
                                            name={`members.${index}.trust`}
                                            options={questionsAnswerOptions?.trust || []}
                                            renderLabel={(option) => option.content}
                                            radioGroupProps={{ sx: { flexDirection: 'row' } }}
                                            disabled={disabled}
                                        />
                                    </div>
                                    {wTrust == 61 && (
                                        <>
                                            <Typography
                                                fontWeight={'medium'}
                                                sx={{ marginBottom: '16px' }}
                                            >
                                                TYPES OF CONTROL WITHIN THE TRUST(S)
                                            </Typography>
                                            <div
                                                className="input-line"
                                                style={{ marginBottom: '16px' }}
                                            >
                                                <label>Ownership of shares</label>
                                                <SelectField
                                                    form={form}
                                                    name={`members.${index}.trust_ownership`}
                                                    options={
                                                        questionsAnswerOptions?.trust_ownership ||
                                                        []
                                                    }
                                                    getOptionLabel={(option) => option.content}
                                                    placeholder={'Choose Ownership of shares'}
                                                    // onChange={(value) => onChangeQuestion1(value)}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div
                                                className="input-line"
                                                style={{ marginBottom: '16px' }}
                                            >
                                                <label>Voting Rights</label>
                                                <SelectField
                                                    form={form}
                                                    name={`members.${index}.trust_voting_rights`}
                                                    options={
                                                        questionsAnswerOptions?.trust_voting_rights ||
                                                        []
                                                    }
                                                    getOptionLabel={(option) => option.content}
                                                    placeholder={'Choose Voting Rights'}
                                                    // onChange={(value) => onChangeQuestion1(value)}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div style={{ marginBottom: '16px' }}>
                                                <div>
                                                    Appoint or remove the majority of the board of
                                                    directors
                                                </div>
                                                <RadioField
                                                    form={form}
                                                    name={`members.${index}.trust_appoint_majority`}
                                                    options={
                                                        questionsAnswerOptions?.trust_appoint_majority ||
                                                        []
                                                    }
                                                    renderLabel={(option) => option.content}
                                                    radioGroupProps={{
                                                        sx: { flexDirection: 'row' },
                                                    }}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div>
                                                <div>Other Significant influences or control</div>
                                                <RadioField
                                                    form={form}
                                                    name={`members.${index}.trust_other_significant_control`}
                                                    options={
                                                        questionsAnswerOptions?.trust_other_significant_control ||
                                                        []
                                                    }
                                                    renderLabel={(option) => option.content}
                                                    radioGroupProps={{
                                                        sx: { flexDirection: 'row' },
                                                    }}
                                                    disabled={disabled}
                                                />
                                            </div>
                                        </>
                                    )}
                                </Box>
                            </Grid>
                        </>
                    )}
            </Grid>
        </Box>
    );
};
export default NatureOfControl;

import { createSlice } from '@reduxjs/toolkit';

const initialNotificationsState = {
    listLoading: false,
    actionsLoading: false,
    totalNewCount: [],
    entitiesNews: null,
    entitiesMessage: null,
    entitiesMoreNews: null,
    entitiesMoreMessage: null,
    lastError: null,
};
export const callTypes = {
    list: 'list',
    action: 'action',
};

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: initialNotificationsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        notificationsNewsFetched: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entitiesNews = data;
        },
        notificationsMessageFetched: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entitiesMessage = data;
        },
        notificationsMoreNewsFetched: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entitiesMoreNews = data;
        },
        notificationsMoreMessageFetched: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entitiesMoreMessage = data;
        },
        notificationsNewCount: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.totalNewCount = data;
        },
        notificationRead: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
    },
});

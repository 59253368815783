
import React from 'react';
import InputField from 'components/form-controls/InputField';

export default function Suppliers({ form }) {
    return (
        <>
            <p className="fw-500">Top 3 names of your main suppliers</p>
            <div className="row">
                <div className="col-md-4 mb-7 input-line">
                    <label>Supplier 1</label>
                    <InputField
                        form={form}
                        name="suplier1.name"
                        placeholder="Enter full name"
                        showIcon
                        hideSuccessHelper
                    />
                </div>
                <div className="col-md-4 mb-7 input-line">
                    <label>Supplier 2</label>
                    <InputField
                        form={form}
                        name="suplier2.name"
                        placeholder="Enter full name"
                        showIcon
                        hideSuccessHelper
                    />
                </div>
                <div className="col-md-4 mb-7 input-line">
                    <label>Supplier 3</label>
                    <InputField
                        form={form}
                        name="suplier3.name"
                        placeholder="Enter full name"
                        showIcon
                        hideSuccessHelper
                    />
                </div>
            </div>
        </>
    )
}

import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import SeeGuide from '../guide/Guide';
import WaitingScreenBankHasProcess from './waiting-screen-bank-has-process';
import WaitingScreenBankHasNoProcess from './waiting-screen-bank-has-no-process';

export const mappingBankToLink = {
    29: 'https://revolutbusiness.ngih.net/DV3jqj',
    25: 'https://dashboard.neatcommerce.com/affiliate?ref=bbcincorp',
    30: 'http://tracking.payoneer.com/SH2qL',
    31: 'https://airwallex.grsm.io/bbcincorp',
    32: 'https://mercury.com/partner/bbcincorp',
    28: 'https://forms.aspireapp.com/team/onboarding/account-opening-application?partnerUtm=bbcincorp',
    26: 'https://app.statrys.com/signup',
    35: 'https://www.anext.com.sg/get-started',
};
export const ukLink = {
    'GPD': 'https://wise.prf.hn/click/camref:1101livhT/destination:https%3A%2F%2Fwise.com%2Fpartner%2Fbbcincorp',
    'USD': 'https://wise.prf.hn/click/camref:1101livi2/destination:https%3A%2F%2Fwise.com%2Fpartner%2Fbbcincorp'
}

const hasProcessBanks = [29, 25, 30, 31, 32, 28, 26, 27, 35];

function BankingProcessStep({onNext}) {
    const {orderInfo} = useSelector(state => state.banking);
    console.log('orderInfo', orderInfo);


    return (
        <>
            {hasProcessBanks.includes(orderInfo?.Task?.BankingProcesses?.[0]?.Bank?.id) && <WaitingScreenBankHasProcess orderInfo={orderInfo}/>}

            {!hasProcessBanks.includes(orderInfo?.Task?.BankingProcesses?.[0]?.Bank?.id) && <WaitingScreenBankHasNoProcess orderInfo={orderInfo}/>}
        </>
    );
}

export default BankingProcessStep;

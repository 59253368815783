import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Card, CardBody } from '_metronic/_partials/controls';
import ChangeCompanyName from 'app/components/ChangeCompanyName';
import { KYCUIProvider } from '../../order-form/kyc/KYCUIContext';
import KYCGraph1 from 'app/pages/components/kyc/KYCGraph1';
import { EDIT_OFFICERS_SHARE_CAPITAL } from 'constants/kyc_graph_type';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import MemberResignModal from 'app/pages/company/company-detail/components/MemberResign/MemberResignModal';
import MemberDetailModal from 'app/pages/company/company-detail/components/MemberDetail/MemberDetailModal';
import { isEmpty, get, last, cloneDeep } from 'lodash';
import { Spinner } from 'react-bootstrap';
import {
    getProposedName,
    createChangeRequest,
    updateChangeRequest,
    submitApprovedName,
    submitRepresented,
    deleteChangeRequest,
} from 'app/pages/_redux/order-form/orderFormCrud';
import ModalAddMember from 'app/pages/company/company-detail/components/MemberAdd/MemberAddModal';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import SelectField from 'components/form-controls/SelectField';
import { COMPANY_MEMBER_TYPES, isLLC, isUK_LLP } from 'app/pages/services/constants';
import { SERVICE_TYPES } from 'constants/service_type';
import WaitingScreen from 'app/components/WaitingScreen';
import { BVI_ID, PANAMA_ID } from 'constants/country';

function ProposedChange({ onSubmit, onNeedRefresh }) {
    const { orderInfo } = useSelector((state) => state.additionalService);
    const [companyList, setCompanyList] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const IS_LLC = isLLC(orderInfo?.Company?.entity_type_id, orderInfo?.Company?.country_id);
    const isPanama = orderInfo?.Company?.country_id === 169;

    const form = useForm({
        defaultValues: {
            proposed_id: null,
        },
    });

    const hasShowSelectFullName = useMemo(() => {
        return [BVI_ID, PANAMA_ID].includes(orderInfo?.Company?.country_id);
    }, [orderInfo]);

    const companyMembers = useMemo(() => {
        let companyMembers = [];
        if (orderInfo) {
            companyMembers = cloneDeep(orderInfo?.Company?.CompanyMembers);
            let companyPositionIds = [];
            orderInfo.Task.ChangeRequests.forEach((request) => {
                //bắt ra những member có trong màn hình này
                if (request?.change_request_status_id === 1) {
                    companyPositionIds.push(request.company_position_id);
                }
            });
            companyMembers.forEach((member, index) => {
                if (member.is_approved === 0) {
                    if (!companyPositionIds.includes(member?.CompanyPositions?.[0]?.id)) {
                        delete companyMembers[index];
                    }
                }
            });
        }

        return companyMembers;
    }, [orderInfo]);

    const shareholders = useMemo(() => {
        let result = [];
        companyMembers.forEach((member) => {
            member.CompanyPositions.forEach((position) => {
                if (
                    !position.corporation_company_member_id &&
                    position.company_member_type_id === COMPANY_MEMBER_TYPES.SHAREHOLDER.id
                ) {
                    if (!IS_LLC) {
                        let tempMember = cloneDeep(member);
                        tempMember.CompanyPositions = [position];
                        if (orderInfo) {
                            orderInfo.Task.ChangeRequests.forEach((changeRequest) => {
                                if (changeRequest?.change_request_status_id === 4) {
                                    if (
                                        changeRequest?.ChangeRequestItems[0]?.model ===
                                        'CompanyShare'
                                    ) {
                                        if (changeRequest.to_company_member_id === tempMember.id) {
                                            let companyMemberTransfer = companyMembers.filter(
                                                (member) =>
                                                    member.id === changeRequest.company_member_id,
                                            );
                                            let originShare = [];
                                            if (companyMemberTransfer[0].CompanyShares) {
                                                companyMemberTransfer[0].CompanyShares.forEach(
                                                    (share) => {
                                                        if (
                                                            share.id ===
                                                            changeRequest?.ChangeRequestItems[0]
                                                                ?.target_id
                                                        ) {
                                                            originShare.push(share);
                                                        }
                                                    },
                                                );
                                            }
                                            const companySharesReceived = {
                                                id: changeRequest?.ChangeRequestItems[0]?.target_id,
                                                start_number: Number(
                                                    changeRequest?.ChangeRequestItems[1]?.value,
                                                ),
                                                end_number: Number(
                                                    changeRequest?.ChangeRequestItems[2]?.value,
                                                ),
                                                value: Number(
                                                    changeRequest?.ChangeRequestItems[0]?.value,
                                                ),
                                                no: Number(
                                                    changeRequest?.ChangeRequestItems[3]?.value,
                                                ),
                                                created_time: originShare?.[0]?.created_time,
                                                is_received: true,
                                                is_approved: true,
                                                is_valid: true,
                                            };
                                            tempMember.CompanyShares.push(companySharesReceived);
                                            tempMember.CompanyShares.sort(function (a, b) {
                                                return a.start_number - b.start_number;
                                            });
                                        }
                                        if (changeRequest.company_member_id === tempMember.id) {
                                            const dataChangeRequest = {
                                                transfer_id:
                                                    changeRequest?.ChangeRequestItems[0]?.target_id,
                                                start_number: Number(
                                                    changeRequest?.ChangeRequestItems[1]?.value,
                                                ),
                                                end_number: Number(
                                                    changeRequest?.ChangeRequestItems[2]?.value,
                                                ),
                                                value: Number(
                                                    changeRequest?.ChangeRequestItems[0]?.value,
                                                ),
                                                no: Number(
                                                    changeRequest?.ChangeRequestItems[3]?.value,
                                                ),
                                                created_time:
                                                    changeRequest?.ChangeRequestItems[0]
                                                        ?.created_time,
                                            };
                                            tempMember.CompanyShares.forEach((share, index) => {
                                                if (
                                                    share.is_approved &&
                                                    share.is_valid &&
                                                    !share.corporation_company_member_id
                                                ) {
                                                    if (
                                                        dataChangeRequest?.start_number >=
                                                            share.start_number &&
                                                        dataChangeRequest?.start_number <=
                                                            share.end_number &&
                                                        dataChangeRequest?.end_number >=
                                                            share.start_number &&
                                                        dataChangeRequest?.end_number <=
                                                            share.end_number
                                                    ) {
                                                        const shareMatch = share;
                                                        delete tempMember.CompanyShares[index];
                                                        if (
                                                            dataChangeRequest.start_number ===
                                                                shareMatch.start_number &&
                                                            dataChangeRequest.end_number !==
                                                                shareMatch.end_number
                                                        ) {
                                                            let shareAfter = {
                                                                id: shareMatch.id,
                                                                start_number:
                                                                    dataChangeRequest.end_number +
                                                                    1,
                                                                end_number: shareMatch.end_number,
                                                                value:
                                                                    shareMatch.end_number -
                                                                    dataChangeRequest.end_number,
                                                                no: shareMatch.no,
                                                                created_time:
                                                                    shareMatch?.created_time,
                                                                is_approved: true,
                                                                is_valid: true,
                                                            };
                                                            tempMember.CompanyShares.push(
                                                                shareAfter,
                                                            );
                                                        }

                                                        if (
                                                            dataChangeRequest.start_number !==
                                                                shareMatch.start_number &&
                                                            dataChangeRequest.end_number ===
                                                                shareMatch.end_number
                                                        ) {
                                                            let shareAfter = {
                                                                id: shareMatch.id,
                                                                start_number:
                                                                    shareMatch.start_number,
                                                                end_number:
                                                                    dataChangeRequest.start_number -
                                                                    1,
                                                                value:
                                                                    dataChangeRequest.start_number -
                                                                    shareMatch.start_number,
                                                                no: shareMatch.no,
                                                                created_time:
                                                                    shareMatch?.created_time,
                                                                is_approved: true,
                                                                is_valid: true,
                                                            };
                                                            tempMember.CompanyShares.push(
                                                                shareAfter,
                                                            );
                                                        }

                                                        if (
                                                            dataChangeRequest.start_number !==
                                                                shareMatch.start_number &&
                                                            dataChangeRequest.end_number !==
                                                                shareMatch.end_number
                                                        ) {
                                                            let shareAfter_1 = {
                                                                id: shareMatch.id,
                                                                start_number:
                                                                    shareMatch.start_number,
                                                                end_number:
                                                                    dataChangeRequest.start_number -
                                                                    1,
                                                                value:
                                                                    dataChangeRequest.start_number -
                                                                    shareMatch.start_number,
                                                                no: shareMatch.no,
                                                                created_time:
                                                                    shareMatch?.created_time,
                                                                is_approved: true,
                                                                is_valid: true,
                                                            };
                                                            tempMember.CompanyShares.push(
                                                                shareAfter_1,
                                                            );
                                                            let shareAfter_2 = {
                                                                id: shareMatch.id,
                                                                start_number:
                                                                    dataChangeRequest.end_number +
                                                                    1,
                                                                end_number: shareMatch.end_number,
                                                                value:
                                                                    shareMatch.end_number -
                                                                    dataChangeRequest.end_number,
                                                                no: shareMatch.no,
                                                                created_time:
                                                                    shareMatch?.created_time,
                                                                is_approved: true,
                                                                is_valid: true,
                                                            };
                                                            tempMember.CompanyShares.push(
                                                                shareAfter_2,
                                                            );
                                                        }
                                                    }
                                                }
                                            });
                                            tempMember.CompanyShares.sort(function (a, b) {
                                                return a.start_number - b.start_number;
                                            });
                                        }
                                    }
                                }
                            });
                        }

                        result.push(tempMember);
                    } else {
                        let tempMember = cloneDeep(member);
                        tempMember.CompanyPositions = [position];
                        if (orderInfo) {
                            orderInfo.Task.ChangeRequests.forEach((changeRequest) => {
                                if (changeRequest?.change_request_status_id === 4) {
                                    if (
                                        changeRequest?.ChangeRequestItems[0]?.model ===
                                        'CompanyOwnership'
                                    ) {
                                        if (changeRequest.to_company_member_id === tempMember.id) {
                                            const share = {
                                                id: changeRequest.ChangeRequestItems[0].id,
                                                value: Number(
                                                    changeRequest.ChangeRequestItems[0].value,
                                                ),
                                                is_received: true,
                                                is_approved: true,
                                                is_valid: true,
                                            };
                                            tempMember.CompanyOwnerships.push(share);
                                        }
                                        if (changeRequest.company_member_id === tempMember.id) {
                                            tempMember.CompanyOwnerships.find(
                                                (item) =>
                                                    !item.corporation_company_member_id &&
                                                    item.is_valid &&
                                                    item.is_approved,
                                            ).value -= Number(
                                                changeRequest?.ChangeRequestItems[0]?.value,
                                            );
                                        }
                                    }
                                }
                            });
                        }
                        result.push(tempMember);
                    }
                }
            });
        });

        return result;
    }, [orderInfo]);

    const ubos = useMemo(() => {
        let result = [];
        companyMembers.forEach((member) => {
            member.CompanyPositions.forEach((position) => {
                if (
                    !position.corporation_company_member_id &&
                    position.company_member_type_id === COMPANY_MEMBER_TYPES.UBO.id
                ) {
                    let tempMember = cloneDeep(member);
                    tempMember.CompanyPositions = [position];
                    if (orderInfo) {
                        orderInfo.Task.ChangeRequests.forEach((changeRequest) => {
                            if (changeRequest?.change_request_status_id === 4) {
                                if (
                                    changeRequest?.ChangeRequestItems[0]?.model ===
                                    'CompanyInterest'
                                ) {
                                    if (changeRequest.to_company_member_id === tempMember.id) {
                                        const share = {
                                            id: changeRequest.ChangeRequestItems[0].id,
                                            value: Number(
                                                changeRequest.ChangeRequestItems[0].value,
                                            ),
                                            is_received: true,
                                            is_approved: true,
                                            is_valid: true,
                                        };
                                        tempMember.CompanyInterests.push(share);
                                    }
                                    if (changeRequest.company_member_id === tempMember.id) {
                                        tempMember.CompanyInterests.find(
                                            (item) =>
                                                !item.corporation_company_member_id &&
                                                item.is_valid &&
                                                item.is_approved,
                                        ).value -= Number(
                                            changeRequest?.ChangeRequestItems[0]?.value,
                                        );
                                    }
                                }
                            }
                        });
                    }
                    result.push(tempMember);
                }
            });
        });

        // loại bỏ member đã được resign để validate có tồn tại chữ ký không
        if (orderInfo) {
            orderInfo.Task.ChangeRequests.forEach((request) => {
                result.forEach((ubo, indexUbo) => {
                    if (request.change_request_status_id === 3) {
                        if (request?.company_position_id === ubo?.CompanyPositions?.[0]?.id) {
                            result.splice(indexUbo, 1);
                        }
                    }
                });
            });
        }

        return result;
    }, [orderInfo]);

    const directors = useMemo(() => {
        let result = [];
        if (isPanama) {
            companyMembers.forEach((member) => {
                if (
                    member.CompanyPositions?.some(
                        (position) =>
                            !position.corporation_company_member_id &&
                            position.company_member_type_id === COMPANY_MEMBER_TYPES.DIRECTOR.id,
                    )
                ) {
                    let tempMember = cloneDeep(member);
                    tempMember.CompanyPositions = tempMember.CompanyPositions.filter(
                        (position) =>
                            !position.corporation_company_member_id &&
                            [1, 4, 5, 6].includes(position.company_member_type_id),
                    );
                    result.push(tempMember);
                }
            });
        } else {
            companyMembers.forEach((member) => {
                member.CompanyPositions.forEach((position) => {
                    if (
                        !position.corporation_company_member_id &&
                        position.company_member_type_id === COMPANY_MEMBER_TYPES.DIRECTOR.id
                    ) {
                        let tempMember = cloneDeep(member);
                        tempMember.CompanyPositions = [position];
                        result.push(tempMember);
                    }
                });
            });
        }

        // loại bỏ member đã được resign để validate có tồn tại chữ ký không
        if (orderInfo) {
            orderInfo.Task.ChangeRequests.forEach((request) => {
                result.forEach((director, indexDirector) => {
                    if (request.change_request_status_id === 3) {
                        if (request?.company_position_id === director?.CompanyPositions?.[0]?.id) {
                            result.splice(indexDirector, 1);
                        }
                    }
                });
            });
        }

        return result;
    }, [orderInfo]);

    const info = useMemo(() => {
        let info = {};
        if (orderInfo) {
            const DeleteChangeRequests =
                orderInfo?.Task?.ChangeRequests?.filter(
                    (ChangeRequest) => ChangeRequest.change_request_status_id === 3,
                ) || [];
            info = {
                ...orderInfo,
                Company: {
                    ...orderInfo?.Company,
                    ChangeRequests: orderInfo?.Task.ChangeRequests,
                    CompanyMembers: orderInfo?.Company?.CompanyMembers.map((companyMember) => {
                        const _companyMember = cloneDeep(companyMember);
                        const shareholder = shareholders.find(
                            (shareholder) => shareholder.id === companyMember.id,
                        );
                        const ubo = ubos.find((ubo) => ubo.id === companyMember.id);
                        if (shareholder) {
                            _companyMember.CompanyShares = shareholder.CompanyShares;
                            _companyMember.CompanyOwnerships = shareholder.CompanyOwnerships;
                        }
                        if (ubo) {
                            _companyMember.CompanyInterests = ubo.CompanyInterests;
                        }

                        // check has resign
                        _companyMember.CompanyPositions = _companyMember.CompanyPositions.map(
                            (position) => ({
                                ...position,
                                is_resigned: !isEmpty(DeleteChangeRequests)
                                    ? DeleteChangeRequests.some(
                                          (request) =>
                                              request.company_member_id === companyMember.id &&
                                              request.company_position_id === position.id,
                                      )
                                    : false,
                            }),
                        );

                        return _companyMember;
                    }),
                },
                OrderItems: [...Object.values(orderInfo?.OrderItems)],
            };
        }

        return info;
    }, [orderInfo, shareholders, ubos]);

    const isHasSignatoryDirector = useMemo(() => {
        let result = false;
        if (directors) {
            if (isPanama) {
                let hasSignatureSecretary = false;
                let hasSignaturePresident = false;
                let hasSignatureTreasurer = false;
                directors.forEach((director) => {
                    director.CompanyPositions.forEach((position) => {
                        if (
                            !position.corporation_company_member_id &&
                            position.company_member_type_id === 4 &&
                            position.is_appointed
                        ) {
                            hasSignatureSecretary = true;
                        }
                        if (
                            !position.corporation_company_member_id &&
                            position.company_member_type_id === 5 &&
                            position.is_appointed
                        ) {
                            hasSignaturePresident = true;
                        }
                        if (
                            !position.corporation_company_member_id &&
                            position.company_member_type_id === 6 &&
                            position.is_appointed
                        ) {
                            hasSignatureTreasurer = true;
                        }
                    });
                });
                if (hasSignatureSecretary && hasSignaturePresident && hasSignatureTreasurer) {
                    result = true;
                }
            } else {
                directors.forEach((director) => {
                    if (director.CompanyPositions[0].is_appointed === 1) {
                        result = true;
                    }
                });
            }
        }

        return result;
    }, [orderInfo]);

    const isHasSignatoryUbo = useMemo(() => {
        let result = false;
        if (ubos) {
            ubos.forEach((ubo) => {
                if (ubo.CompanyPositions[0].is_appointed === 1) {
                    result = true;
                }
            });
        }

        return result;
    }, [orderInfo]);

    const isCorporationMemberHasAllPosition = useMemo(() => {
        let result = true;
        let membersCorporationNew = [];
        if (orderInfo) {
            orderInfo.Company.CompanyMembers.forEach((member) => {
                let tempMember = cloneDeep(member);
                member.CompanyPositions.forEach((position) => {
                    if (!position.is_approved && member.type_member_id === 2) {
                        membersCorporationNew.push(tempMember);
                    }
                });
            });
        }

        membersCorporationNew.forEach((memberCorporationNew) => {
            // xác định xem members corporation có tồn tại đủ (1Director, 1Shareholder, 1Ubo)
            let isHasDirector = false;
            let isHasShareholder = false;
            let isHasUbo = false;
            let isHasAllPosition = false;
            orderInfo.Company.CompanyMembers.forEach((member) => {
                member.CompanyPositions.forEach((position) => {
                    if (
                        position.company_member_type_id === COMPANY_MEMBER_TYPES.DIRECTOR.id &&
                        position.corporation_company_member_id === memberCorporationNew.id
                    ) {
                        isHasDirector = true;
                    }
                    if (
                        position.company_member_type_id === COMPANY_MEMBER_TYPES.SHAREHOLDER.id &&
                        position.corporation_company_member_id === memberCorporationNew.id
                    ) {
                        isHasShareholder = true;
                    }
                    if (
                        position.company_member_type_id === COMPANY_MEMBER_TYPES.UBO.id &&
                        position.corporation_company_member_id === memberCorporationNew.id
                    ) {
                        isHasUbo = true;
                    }
                });
            });

            if (isHasDirector && isHasShareholder && isHasUbo) {
                isHasAllPosition = true;
            }
            memberCorporationNew.isHasAllPosition = isHasAllPosition;
        });

        membersCorporationNew.forEach((memberCorporationNew) => {
            if (!memberCorporationNew.isHasAllPosition) {
                result = false;
            }
        });

        return result;
    }, [orderInfo]);

    useEffect(() => {
        void handleGetProposedNameList();
    }, []);

    const handleGetProposedNameList = async () => {
        if (orderInfo) {
            const { data } = await getProposedName(orderInfo?.Task?.id);
            if (data.state) {
                setCompanyList(data.data);
            }
        }
    };

    const approvedCompanyName = useMemo(() => {
        let result = [];
        if (!isEmpty(companyList)) {
            companyList.forEach((company) => {
                if (company.is_approve) {
                    result.push(company);
                }
                if (company.is_confirmed) {
                    form.setValue('proposed_id', company.id);
                }
            });
        }

        return result;
    }, [companyList]);

    const isChangeNameService = useMemo(() => {
        let result = false;
        if (orderInfo) {
            orderInfo.OrderItems.forEach((item) => {
                if ([SERVICE_TYPES.CHANGE_NAME_TYPE_ID].includes(item.Service.service_type_id)) {
                    result = true;
                }
            });
        }

        return result;
    }, [orderInfo]);

    const isChangeOfficerService = useMemo(() => {
        let result = false;
        if (orderInfo) {
            orderInfo.OrderItems.forEach((item) => {
                if ([SERVICE_TYPES.CHANGE_MEMBER_TYPE_ID].includes(item.Service.service_type_id)) {
                    result = true;
                }
            });
        }

        return result;
    }, [orderInfo]);

    const isWaiting = useMemo(() => {
        let result = false;
        if (companyList && companyList.every((item) => item.is_approve === null)) {
            result = true;
        }

        return result;
    }, [companyList]);

    const isAllowSubmit = useMemo(() => {
        let result = false;
        if (companyList && approvedCompanyName.length > 1 && form.watch('proposed_id') !== null) {
            result = true;
        }

        if (companyList && approvedCompanyName.length === 1) {
            result = true;
        }

        return result;
    }, [companyList]);

    const handleDeleteRequest = async (payload = []) => {
        try {
            if (!isEmpty(payload)) {
                const { data } = await deleteChangeRequest({ deleteChangeRequest: payload });
                if (data.state) {
                    onNeedRefresh();
                }
                return data;
            }
        } catch (error) {
            const message = get(error, 'response.data.data', 'Something went wrong!');
            toast.error(message);
        }
    };

    const handleAddMember = async (member) => {
        if (!member) return;

        try {
            // create new member & getDetail
            const submitData = {
                ...member,
                task_id: orderInfo?.Task?.id,
            };
            const { data } = await createChangeRequest(submitData);
            if (data.state) {
                toast.success(get(data, 'message', 'Create member successfully!'));
            }
            onNeedRefresh();
            return data;
        } catch (error) {
            const message = get(error, 'response.data.data', 'Error!');
            toast.error(message);
        }
    };

    const handleChangeRequestMember = async (data) => {
        try {
            const call = [];
            data.forEach(({ payload, changeRequestId }) => {
                call.push(
                    changeRequestId
                        ? handleUpdateMember(changeRequestId, payload)
                        : createChangeRequest(payload),
                );
            });
            toast.success(get(data, 'message', 'Success!'));
            onNeedRefresh();
        } catch (error) {
            const message = get(error, 'response.data.message', 'Error!');
            toast.error(message);
        }
    };

    const handleUpdateMember = async (changeRequestId, payload) => {
        try {
            if (changeRequestId) {
                const { data } = await updateChangeRequest(changeRequestId, payload);
                if (data.state) {
                    onNeedRefresh();
                }
                return data;
            }
        } catch (error) {
            const message = get(error, 'response.data.message', 'Error!');
            toast.error(message);
        }
    };

    const handleDeleteMember = async (values) => {
        try {
            const { data } = await createChangeRequest(values);
            if (data.state) {
                onNeedRefresh();
            }
            return data;
        } catch (error) {
            const message = get(error, 'response.data.message', 'Error!');
            toast.error(message);
        }
    };

    const submitName = async (payload, reason) => {
        try {
            let submitData = {};
            if (reason == 'clear') {
                payload = approvedCompanyName.filter((name) => name.is_confirmed == 1)?.[0]?.id;
                submitData = {
                    is_confirmed: 0,
                    task_id: orderInfo?.Task?.id,
                };
            } else {
                submitData = {
                    is_confirmed: 1,
                    task_id: orderInfo?.Task?.id,
                };
            }
            const { data } = await submitApprovedName(payload, submitData);
            if (data.state) {
                handleGetProposedNameList();
                toast.success(get(data, 'message', 'Success!'));
            }
        } catch (error) {
            const message = get(error, 'response.data.message', 'Error!');
            toast.error(message);
        }
    };

    approvedCompanyName.length === 1 &&
        approvedCompanyName?.[0]?.is_confirmed === 0 &&
        submitName(approvedCompanyName?.[0]?.id);

    const handleSubmit = (values) => {
        onSubmit && onSubmit();
    };

    const isAllMemberUploaded = (memberTemps) => {
        let result = memberTemps.length > 0;
        for (const member of memberTemps) {
            const fileTemps = Array.isArray(member.data.value.Files)
                ? member.data.value.Files
                : Object.values(member.data.value.Files || {});
            if (fileTemps.length === 0) {
                result = false;
                break;
            }
        }
        setDisableSubmit(result);
    };

    const IS_UK_LLP = isUK_LLP(info?.Company?.entity_type_id, info?.Company?.country_id);
    const isSVG = info?.Company?.country_id === 209;
    const isUK = info?.Company?.country_id === 237;

    const props = useMemo(() => {
        return {
            company: info.Company,
            IS_LLC,
            IS_UK_LLP,
            isSVG,
            isPanama,
            isUK,
            orderInfo,
        };
    }, [info]);

    if (isEmpty(orderInfo))
        return (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        );

    if (isWaiting && isChangeNameService) {
        return (
            <WaitingScreen
                children={
                    <p style={{ color: '#007EFF' }}>
                        Your documents are being processed. We will keep you posted as soon as
                        possible.
                    </p>
                }
            />
        );
    }

    return (
        <>
            <KYCUIProvider>
                <Card>
                    <CardBody>
                        {isChangeNameService && (
                            <ChangeCompanyName
                                companyDetail={info?.Company}
                                companyList={companyList}
                                companyId={info.Company.id}
                                page="order_form"
                                handleGetProposedNameList={handleGetProposedNameList}
                            />
                        )}
                        {isChangeNameService && approvedCompanyName.length !== 1 && (
                            <SelectField
                                label="Selected company"
                                className="w-50 my-5"
                                name="proposed_id"
                                form={form}
                                options={approvedCompanyName || []}
                                getOptionLabel={(option) => {
                                    return option?.CompanySuffix
                                        ? option?.CompanySuffix?.is_prefix
                                            ? option?.CompanySuffix?.name + ' ' + option?.name
                                            : option?.name + ' ' + option?.CompanySuffix?.name
                                        : option?.name;
                                }}
                                onChange={(value, reason) =>
                                    submitName(value ? value.id : value, reason)
                                }
                            />
                        )}
                        {approvedCompanyName.length === 1 && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ marginTop: '15px', height: '27px' }}>
                                    Selected Company: {approvedCompanyName[0]?.name}
                                </Typography>
                            </Box>
                        )}
                        {approvedCompanyName.length === 0 && isChangeNameService && (
                            <em style={{ color: '#ff0000' }}>
                                * Kindly note that you can only proceed with the submission once one
                                or more names are approved. Please await our verification, and we
                                will notify you via email
                            </em>
                        )}
                        {isChangeOfficerService && (
                            <>
                                <Box mt={3}>
                                    <KYCGraph1
                                        type={EDIT_OFFICERS_SHARE_CAPITAL}
                                        info={info}
                                        isAllMemberUploaded={isAllMemberUploaded}
                                    />
                                    <ModalAddMember
                                        onSubmit={handleAddMember}
                                        page={'order-form'}
                                        {...props}
                                    />
                                    <MemberDetailModal
                                        onSubmit={handleChangeRequestMember}
                                        allowNewMember
                                        allowGetChangeRequest
                                        allowShowRepresent
                                        page={'order-form'}
                                        hasShowSelectFullName={hasShowSelectFullName}
                                        {...props}
                                    />
                                    <MemberResignModal
                                        onChangeRequest={handleDeleteMember}
                                        page={'order-form'}
                                        onDeleteRequest={handleDeleteRequest}
                                        {...props}
                                    />
                                </Box>
                                <Box mt={2}>
                                    {!isHasSignatoryDirector &&
                                        orderInfo?.represented_type_ids?.includes(
                                            COMPANY_MEMBER_TYPES.DIRECTOR.id,
                                        ) && (
                                            <Typography sx={{ color: '#ff0000' }}>
                                                Director must have signature
                                            </Typography>
                                        )}
                                    {!isHasSignatoryUbo &&
                                        orderInfo?.represented_type_ids?.includes(
                                            COMPANY_MEMBER_TYPES.UBO.id,
                                        ) && (
                                            <Typography sx={{ color: '#ff0000' }} mt={1}>
                                                Ultimate Beneficial Owner must have signature
                                            </Typography>
                                        )}
                                    {!disableSubmit && (
                                        <Typography sx={{ color: '#ff0000' }} mt={1}>
                                            All member must upload file
                                        </Typography>
                                    )}
                                    {!isCorporationMemberHasAllPosition && (
                                        <Typography sx={{ color: '#ff0000' }} mt={1}>
                                            All members corporation must have all roles
                                        </Typography>
                                    )}
                                </Box>
                            </>
                        )}
                        <Box
                            display={'flex'}
                            justifyContent={'flex-end'}
                            mt={2}
                            alignItems={'center'}
                        >
                            <Button
                                className="text-white"
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={form.handleSubmit(handleSubmit)}
                                disabled={
                                    form.formState.isSubmitting ||
                                    !disableSubmit ||
                                    (isChangeOfficerService &&
                                        !isHasSignatoryDirector &&
                                        orderInfo?.represented_type_ids?.includes(
                                            COMPANY_MEMBER_TYPES.DIRECTOR.id,
                                        )) ||
                                    (isChangeOfficerService &&
                                        !isHasSignatoryUbo &&
                                        orderInfo?.represented_type_ids?.includes(
                                            COMPANY_MEMBER_TYPES.UBO.id,
                                        )) ||
                                    !isCorporationMemberHasAllPosition ||
                                    (isChangeNameService && !isAllowSubmit)
                                }
                            >
                                Submit
                            </Button>
                        </Box>
                    </CardBody>
                </Card>
            </KYCUIProvider>
        </>
    );
}

export default ProposedChange;

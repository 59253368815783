import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { debounce } from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, Input, InputLabel, FormControl, FormHelperText, InputAdornment } from '@mui/material';
import './style.scss';
import MaskedInput from 'react-text-mask';
import { CalendarToday } from '@mui/icons-material';
import { MASK_DATE_FORMAT } from '../../../constants';
import moment from 'moment';

const SUCCESS_COLOR = '#1bc5bd';
const FAILURE_COLOR = '#f64e60';

const getDateWithFormat = (date) => {
    if (typeof date === 'string') {
        if (
            date.match(/^\d{4}-\d{2}-\d{2}$/) ||
            date.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)
        ) {
            return new Date(date.replaceAll('-', '/'));
        } else {
            return null;
        }
    }

    return date;
};

const TextInput = forwardRef(({ ...other }, ref) => {
    return <MaskedInput {...other} />;
});

const CustomInput = forwardRef(({ mask, wrapperStyle, helperText, ...props }, ref) => {
    return (
        <Box
            sx={{
                position: 'relative',
                top: '-3px',
                '& .MuiInputLabel-shrink.label-custom': {
                    transform: 'translate(14px, 0px) scale(0.75)',
                },
                '& .MuiFormLabel-filled': {
                    left: '-14px',
                },
                '& .MuiInputLabel-formControl': {
                    left: '-14px',
                },
                '& .MuiFormHelperText-root': {
                    marginLeft: 0,
                    marginRight: 0,
                },
                '.MuiInputBase-root.Mui-error:before': {
                    borderBottomColor: FAILURE_COLOR,
                },
                '.MuiInputBase-root.Mui-error:after': {
                    borderBottomColor: FAILURE_COLOR,
                },
                '& .MuiInputBase-input': {
                    padding: '.65rem 1rem .65rem 0',
                },
                ...wrapperStyle,
                ...props?.customStyle,
            }}
        >
            <FormControl fullWidth>
                {props.label && (
                    <InputLabel
                        className="label-custom"
                        sx={{
                            color: props.error ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)',
                        }}
                        shrink={props.value ? true : false}
                    >
                        {props.label}
                    </InputLabel>
                )}
                {props.iconPosition === 'start' ? (
                    <Input
                        inputComponent={TextInput}
                        startAdornment={
                            <InputAdornment position={'start'}>
                                <CalendarToday fontSize="small" />
                            </InputAdornment>
                        }
                        inputProps={{
                            mask,
                        }}
                        placeholder={props.placeholder}
                        {...props}
                    />
                ) : (
                    <Input
                        inputComponent={TextInput}
                        endAdornment={
                            <InputAdornment position={'end'}>
                                <CalendarToday fontSize="small" />
                            </InputAdornment>
                        }
                        inputProps={{
                            mask,
                        }}
                        placeholder={props.placeholder}
                        {...props}
                    />
                )}
                {props?.error && (
                    <FormHelperText
                        sx={{
                            color: '#f64e60',
                            fontSize: '.9rem',
                        }}
                    >
                        {props.message}
                    </FormHelperText>
                )}

                {!props.error && !props.isDirty && helperText ? (
                    <FormHelperText
                        sx={{
                            color: 'rgba(0, 0, 0, 0.6)',
                            fontSize: '.75rem',
                        }}
                    >
                        Please enter <b>{helperText}</b>
                    </FormHelperText>
                ) : (
                    ''
                )}

                {!props.error && props.isDirty && helperText ? (
                    <FormHelperText
                        sx={{
                            color: '#1bc5bd',
                            fontSize: '.9rem',
                        }}
                    >
                        {helperText} was entered correct
                    </FormHelperText>
                ) : (
                    ''
                )}
            </FormControl>
        </Box>
    );
});

export default function DateField({
    name,
    form,
    label,
    debounceTime = 0,
    onChange,
    iconPosition = 'end', // start || end
    mask = MASK_DATE_FORMAT,
    inputProps = {},
    helperText,
    placeholder = '',
    ...props
}) {
    const callDebounce = debounce((e) => {
        onChange(e);
    }, debounceTime);

    return (
        <Controller
            name={name}
            control={form.control}
            render={({ field, fieldState: { isDirty, invalid, isTouched, error } }) => {
                return (
                    <Box
                        sx={{
                            '& .react-datepicker-popper': {
                                zIndex: 99,
                            },
                        }}
                    >
                        <DatePicker
                            selected={field?.value ? getDateWithFormat(field.value) : null}
                            dateFormat="dd/MM/yyyy"
                            calendarStartDay={1}
                            customInput={
                                <CustomInput
                                    label={label}
                                    error={invalid}
                                    message={error?.message || ''}
                                    mask={mask}
                                    {...inputProps}
                                    iconPosition={iconPosition}
                                    customStyle={props.customStyle}
                                    helperText={helperText}
                                    isDirty={isDirty}
                                    // placeholder={placeholder}
                                />
                            }
                            onChange={(e) => {
                                field.onChange(e);
                                onChange && callDebounce(e);
                            }}
                            placeholderText={placeholder}
                            {...props}
                        />
                    </Box>
                );
            }}
        />
    );
}

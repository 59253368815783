import { Step, StepButton, StepContent, Stepper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useRef, useState } from 'react';
import DocumentList from './DocumentList';
import CertifiedAddressProof from './CertifiedAddressProof';
import ConfirmationForm from './ConfirmationForm';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

const steps = ['Confirmation Form', 'Document List', 'Certified address proof '];

const ConfirmConfirmationForm = ({ orderInfo, onNeedRefresh, onSubmit }) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const validateStepsRef = useRef({
        STEP_1: {
            isValid: true,
            onSubmit: () => {},
        },
        STEP_2: {
            isValid: true,
            onSubmit: () => {},
        },
    });

    const handleActiveStep = (newStep) => {
        if (activeStep === newStep) return;
        if (activeStep > newStep) setActiveStep(newStep);

        const currentValidateStep = validateStepsRef.current[`STEP_${activeStep + 1}`];
        if (currentValidateStep) {
            currentValidateStep.onSubmit();
            if (currentValidateStep.isValid) {
                setActiveStep(newStep);
            }
        } else {
            setActiveStep(newStep);
        }
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <ConfirmationForm
                        task={orderInfo}
                        onNeedRefresh={onNeedRefresh}
                        onNext={handleNext}
                    />
                );
            case 1:
                return (
                    <DocumentList orderInfo={orderInfo} handleNext={handleNext} handleBack={handleBack}/>
                );
            case 2:
                return (
                    <CertifiedAddressProof task={orderInfo} onSubmit={onSubmit} handleBack={handleBack}/>
                )
        }
    };
    console.log('test');

    return (
        <div className={'wrap-stepper ' + classes.root}>
            <Stepper
                className="stepper p-0"
                nonLinear
                activeStep={activeStep}
                orientation="vertical"
            >
                {steps.map((label, index) => {
                    return (
                        <Step key={label}>
                            <StepButton
                                className="step-title no-click"
                                disableTouchRipple
                                // onClick={() => handleActiveStep(index)}
                            >
                                {label}
                            </StepButton>
                            <StepContent>{getStepContent(index)}</StepContent>
                        </Step>
                    );
                })}
            </Stepper>
        </div>
    );
};

export default ConfirmConfirmationForm;

import React from 'react';
// import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

export const ServiceFn = ({
    classes,
    record,
    setRecord,
    services,
    setServices,
    category,
    servicesChange,
    body,
    setSelectedAnnualFee,
}) => {
    const addService = () => {
        setRecord(record + 1);
        let tempArr = JSON.parse(JSON.stringify(services));
        tempArr = tempArr.concat([{ ...category[0].Services[0], quantity: 1 }]);
        setServices([...tempArr]);
    };

    const removeAll = () => {
        const serviceTemp = [];
        let isAnnualRenewService = false;
        services.forEach((service) => {
            if (service?.disable === true && service?.service_type_id == 18)
                isAnnualRenewService = true;
            if (service?.disable === true && service.id !== body.service_nominee_id)
                serviceTemp.push(service);
            if (isAnnualRenewService && service.id === body.service_nominee_id && service?.disable === true)
                serviceTemp.push(service);
        });
        setServices(serviceTemp);
        setSelectedAnnualFee && setSelectedAnnualFee(false);
    };

    return (
        <div className="text-left text-md-center mt-5">
            {/* <Fab size="small" color="secondary" aria-label="Add" className={classes.margin} onClick={addService}>
                <AddIcon />
            </Fab>
            <Fab size="small" color="primary" aria-label="Delete" className={classes.margin + " ml-5"} onClick={removeAll}>
                <DeleteIcon />
            </Fab> */}

            <Button
                variant="contained"
                color="secondary"
                className={classes.margin}
                startIcon={<AddIcon />}
                onClick={addService}
            >
                Add
            </Button>
            <Button
                variant="contained"
                sx={{ ml: 2 }}
                color={'inherit'}
                startIcon={<DeleteIcon />}
                onClick={removeAll}
            >
                Remove all
            </Button>
        </div>
    );
};

import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import Director from './Director';
import { DIRECTOR_TYPE_ID, SHAREHOLDER_TYPE_ID, UBO_TYPE_ID } from 'constants/member-type';
import Shareholder from './Shareholder';
import UBO from './UBO';
import ArticleOfOrganization from './ArticleOfOrganization';
import ShareCapital from './ShareCapital';
import { COMPANY_MEMBER_TYPES } from 'app/pages/services/constants';

const CompanyStructure = (
    {
        task,
        IS_LLC,
        IS_UK_LLP,
        IS_BELIZE_LLC,
        onSubmit,
        onBack,
        onNext,
        onDeleteMember,
        countryAll,
        countryHasService,
        countryCooperative,
        loadingNext,
        disabled,
        hasShowSelectFullName,
    },
    ref,
) => {
    const [activeStep, setActiveStep] = useState(0);

    const IS_BELIZE_LLC_MEMBER_MANAGED =
        IS_BELIZE_LLC && task?.Company?.articles_of_organization === 1;

    useImperativeHandle(ref, () => ({
        handleNext: () => {
            handleNext();
        },
    }));

    const handleNext = () => {
        if (activeStep + 1 === steps.length) {
            onNext();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep - 1 < 0) {
            onBack();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const isNomineeDirector = useMemo(() => {
        if (!task) return false;
        if (
            task?.Company?.CompanyMembers?.find(
                (member) =>
                    member?.is_nominee &&
                    member?.CompanyPositions.find(
                        (position) =>
                            position?.company_member_type_id === COMPANY_MEMBER_TYPES.DIRECTOR.id,
                    ),
            )
        ) {
            return true;
        } else {
            return false;
        }
    }, [task]);

    const isNomineeShareholder = useMemo(() => {
        if (!task) return false;
        if (
            task?.Company?.CompanyMembers?.find(
                (member) =>
                    member?.is_nominee &&
                    member?.CompanyPositions.find(
                        (position) =>
                            position?.company_member_type_id === COMPANY_MEMBER_TYPES.SHAREHOLDER.id,
                    ),
            )
        ) {
            return true;
        } else {
            return false;
        }
    }, [task]);

    const steps = useMemo(() => {
        // if (!task) return [];
        const conditionComponents = [];

        if (!IS_UK_LLP) {
            conditionComponents.push({
                label: IS_LLC ? 'Contributed Capital' : 'Share Capital',
                step: 'share-capital',
                content: (
                    <ShareCapital
                        companyInfo={task?.Company}
                        onSubmit={onSubmit}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        disabled={true}
                        IS_LLC={IS_LLC}
                    />
                ),
            });
        }
        if (IS_BELIZE_LLC) {
            conditionComponents.push({
                label: 'Article of organization',
                step: 'article-of-organization',
                content: (
                    <ArticleOfOrganization
                        task={task}
                        disabled={true}
                        onSubmit={onSubmit}
                        handleNext={handleNext}
                        handleBack={handleBack}
                    />
                ),
            });
        }
        if (!IS_BELIZE_LLC_MEMBER_MANAGED && !IS_UK_LLP) {
            conditionComponents.push({
                label: IS_LLC ? 'Manager' : 'Director',
                step: 'director',
                content: (
                    <Director
                        task={task}
                        disabled={disabled}
                        onSubmit={onSubmit}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        isRepresented={task?.represented_type_ids.includes(DIRECTOR_TYPE_ID)}
                        hasShowSelectFullName={hasShowSelectFullName}
                        isNominee={isNomineeDirector}
                    />
                ),
            });
        }

        const fixedComponents = [
            {
                label: IS_LLC || IS_UK_LLP ? 'Member' : 'Shareholder',
                step: 'shareholder',
                content: (
                    <Shareholder
                        task={task}
                        disabled={disabled}
                        onSubmit={onSubmit}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        IS_LLC={IS_LLC}
                        IS_UK_LLP={IS_UK_LLP}
                        isRepresented={task?.represented_type_ids.includes(SHAREHOLDER_TYPE_ID)}
                        hasShowSelectFullName={hasShowSelectFullName}
                        isNominee={isNomineeShareholder}
                    />
                ),
            },
            {
                label: 'Ultimate Beneficial Owners',
                step: 'ubo',
                content: (
                    <UBO
                        task={task}
                        disabled={disabled}
                        onSubmit={onSubmit}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        IS_UK_LLP={IS_UK_LLP}
                        isRepresented={task?.represented_type_ids.includes(UBO_TYPE_ID)}
                        hasShowSelectFullName={hasShowSelectFullName}
                    />
                ),
            },
        ];
        return conditionComponents.concat(fixedComponents);
    }, [
        task,
        countryAll,
        countryHasService,
        countryCooperative,
        loadingNext,
        disabled,
        IS_LLC,
        IS_UK_LLP,
        IS_BELIZE_LLC,
        onSubmit,
        onBack,
        onNext,
        onDeleteMember,
        IS_BELIZE_LLC_MEMBER_MANAGED,
        activeStep,
        isNomineeDirector,
    ]);

    return (
        <Stepper className="stepper p-0" activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
                <Step key={step.label} completed={false}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>{step.content}</StepContent>
                </Step>
            ))}
        </Stepper>
    );
};

export default forwardRef(CompanyStructure);

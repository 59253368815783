import React from 'react';
import SeeGuide from '../guide/Guide';
import { Box, Button } from '@mui/material';
import { mappingBankToLink, ukLink } from './banking-process-step';
import ToggleTawkTo from '../../../../../../components/ToggleTawkTo';
import { REMOVED_PUBLISHED_TRACKING_LINK_BANKS } from '../../constant';

function WaitingScreenBankHasProcess({ orderInfo }) {
    const bankId = orderInfo?.Task.BankingProcesses?.[0]?.Bank?.id;
    const countryId = orderInfo?.Task.BankingProcesses?.[0]?.Bank?.Country?.id;
    const removedPublishedTrackingLinkBank = REMOVED_PUBLISHED_TRACKING_LINK_BANKS.find(
        (bank) => bank.id === bankId && bank.Country.id === countryId,
    );

    return (
        <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ minHeight: 470 }}
        >
            <img
                src="/media/order-form/approving.svg"
                className="img-fluid mb-5"
                width={220}
                alt="Your proposed meeting time was sent to the bank for acceptance."
            />

            {(!!orderInfo?.BankingGuide?.length && !removedPublishedTrackingLinkBank) && (
                <p className={'text-center mt-2'}>
                    {orderInfo?.BankingGuide?.length > 0 && (
                        <SeeGuide fileGuide={orderInfo?.BankingGuide[0]} />
                    )}
                </p>
            )} 
            <div style={{ maxWidth: '435px' }}>
                {removedPublishedTrackingLinkBank ? (
                    <Box>
                        <p className={'text-center'} style={{ color: '#007EFF' }}>
                            Your documents are being processed. It will take around 2-3 days.
                        </p>
                    </Box>
                ) : (
                    <Box>
                        <p className={'text-center'} style={{ color: '#007EFF' }}>
                            Simply follow the instructions and you can register your{' '}
                            <b>{orderInfo?.Task?.BankingProcesses?.[0]?.Bank?.name}</b> account with
                            ease via our Referral
                            {orderInfo?.Task.BankingProcesses?.[0].Bank.id !== 27 && (
                                <a
                                    href={
                                        mappingBankToLink[
                                            orderInfo?.Task?.BankingProcesses?.[0]?.Bank?.id
                                        ]
                                    }
                                    className={'font-weight-bold'}
                                    target={'_blank'}
                                >
                                    {' '}
                                    <u>Link</u>
                                </a>
                            )}
                            {orderInfo?.Task.BankingProcesses?.[0].Bank.id === 27 && (
                                <>
                                    <a
                                        className={'font-weight-bold'}
                                        href={ukLink['GPD']}
                                        target={'_blank'}
                                    >
                                        {' '}
                                        <u>GBP - UK,</u>{' '}
                                    </a>
                                    <a
                                        className={'font-weight-bold'}
                                        href={ukLink['USD']}
                                        target={'_blank'}
                                    >
                                        <u>USD - US</u>
                                    </a>
                                </>
                            )}
                            {orderInfo?.Task.BankingProcesses?.[0].Bank.id === 35 &&
                                ` (Remember to fill in the code AOBBCI)`}
                            :{' '}
                        </p>
                        <p className={'text-center'} style={{ color: '#007EFF' }}>
                            If you need any assistance, please do not hesitate to contact us.
                        </p>
                        <p className={'text-center'}>
                            <ToggleTawkTo text={'Consult your dedicated manager'} />
                        </p>
                    </Box>
                )}
            </div>
        </div>
    );
}

export default WaitingScreenBankHasProcess;

import { toast } from 'react-toastify';
import FileSaver from 'file-saver';
import { createBlobUrl } from './image';

export const download = (url_s3, name) => {
    fetch(url_s3)
        .then((response) => {
            if (response && response.status === 200) {
                return response.blob()
            } else {
                if (response && response.statusText) {
                    toast.error(response.statusText);
                    throw response.statusText
                }
            }
        })
        .then((blob) => {
            if (blob) {
                FileSaver(blob, name);
            }
        })
}

export const viewImageS3 = async (url_s3) => {
    const blob = await fetch(url_s3)
        .then((response) => {
            if (response && response.status === 200) {
                return response.blob()
            } else {
                if (response && response.statusText) {
                    // toast.error(response.statusText); // không show error của image
                    throw response.statusText
                }
            }
        });
    return createBlobUrl(blob)
}

import React from 'react';
import { Accordion, AccordionToggle, AccordionCollapse } from 'react-bootstrap';

const ExampleCollapse = () => {
    return (
        <Accordion>
            <AccordionToggle as="p" eventKey="0" className="text-center">
                Example of how certified identity documents looks like
            </AccordionToggle>
            <AccordionCollapse eventKey="0">
                <div className="collapse-content">
                    <img
                        src="/media/svg/icons/Order/example.png"
                        style={{ maxWidth: 640 }}
                        className="w-100 h-auto"
                        alt="example"
                    />
                </div>
            </AccordionCollapse>
        </Accordion>
    );
};

export default ExampleCollapse;

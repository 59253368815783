import * as yup from 'yup';
import { UBO_TYPE_ID } from 'constants/member-type';
import { BVI_ID, PANAMA_ID } from 'constants/country';
import { validateRepresentedMember, validateTotalSourceOfFund } from './custom';
import { calculateNumbers } from 'helper';

yup.addMethod(yup.mixed, 'validateTotalSourceOfFund', validateTotalSourceOfFund);
yup.addMethod(yup.mixed, 'validateRepresentedMember', validateRepresentedMember);
yup.addMethod(yup.mixed, 'validatePercentageOfInterest', function () {
    return this.test(`validatePercentageOfInterest`, '', function (value) {
        const { createError } = this;
        let newPath = `validatePercentageOfInterest`;
        const arrayPercent = [];

        if (
            value.__notValidatePercentageOfInterest === true ||
            (!value.__isChangeOfficerFlow &&
                value.members[0].CompanyPositions[0].corporation_company_member_id)
            // Nếu không phải luồng CO và member từ lv2 trở đi thì không validate percentage
        )
            return true;

        value.members.forEach((member) => {
            arrayPercent.push(member.CompanyPositions[0].percent_of_interest);
        });

        let totalPercent = calculateNumbers(...arrayPercent);

        return (
            totalPercent === 100 ||
            createError({
                path: newPath,
                message: `Total Percentage of interest equal to 100% current is ${totalPercent}%`,
            })
        );
    });
});

export const uboSchema = ({ hasShowSelectFullName }) => {
    return yup
        .object({
            members: yup
                .array()
                .of(
                    yup.object({
                        /* Individual */
                        first_name:
                            hasShowSelectFullName &&
                            yup
                                .string()
                                .nullable()
                                .when('type_member_id', {
                                    is: 1,
                                    then: yup.string().required('First name is required!'),
                                }),
                        middle_name: hasShowSelectFullName && yup.string().nullable(),
                        last_name:
                            hasShowSelectFullName &&
                            yup
                                .string()
                                .nullable()
                                .when('type_member_id', {
                                    is: 1,
                                    then: yup
                                        .string()
                                        .nullable()
                                        .required('Last name is required!'),
                                }),
                        full_name: yup
                            .string()
                            .nullable()
                            .when('type_member_id', {
                                is: 1,
                                then: yup
                                    .string()
                                    .nullable()
                                    .required(
                                        hasShowSelectFullName
                                            ? 'Please select your full name!'
                                            : 'Please enter Name',
                                    ),
                            }),
                        birthday: yup
                            .mixed()
                            .nullable()
                            .when('type_member_id', {
                                is: 1,
                                then: yup
                                    .date()
                                    .nullable()
                                    .required('Please enter Birthday')
                                    .typeError('Invalid date'),
                            }),
                        major: yup
                            .string()
                            .nullable()
                            .when('type_member_id', {
                                is: 1,
                                then: yup.string().required('Please enter Major'),
                            }),
                        passport: yup
                            .string()
                            .nullable()
                            .when('type_member_id', {
                                is: 1,
                                then: yup.string().required('Please enter Passport'),
                            }),
                        passport_expire_date: yup
                            .mixed()
                            .nullable()
                            .when('type_member_id', {
                                is: 1,
                                then: yup
                                    .date()
                                    .nullable()
                                    .required('Please enter Date of expire passport')
                                    .typeError('Invalid date'),
                            })
                            .when(
                                ['passport_issue_date', 'type_member_id'],
                                (passport_issue_date, type_member_id, schema, context) => {
                                    if (type_member_id !== 1 || !passport_issue_date)
                                        return yup.mixed();
                                    return yup
                                        .date()
                                        .nullable()
                                        .min(
                                            yup.ref('passport_issue_date'),
                                            'Passport expired date must be greater than Passport issue date',
                                        )
                                        .required('Please enter Date of expire passport')
                                        .typeError('Invalid date');
                                },
                            ),
                        passport_issue_date: yup
                            .mixed()
                            .nullable()
                            .when('type_member_id', {
                                is: 1,
                                then: yup
                                    .date()
                                    .nullable()
                                    .required('Please enter Date of issue passport')
                                    .typeError('Invalid date'),
                            }),

                        asset_source_fund_cast_in_bank: yup
                            .number()
                            .typeError('Please enter a number')
                            .nullable(),
                        asset_source_fund_other_money: yup
                            .number()
                            .typeError('Please enter a number')
                            .nullable(),
                        asset_source_fund_real_estate: yup
                            .number()
                            .typeError('Please enter a number')
                            .nullable(),
                        income_source_fund_investment: yup
                            .number()
                            .typeError('Please enter a number')
                            .nullable(),
                        income_source_fund_other_money: yup
                            .number()
                            .typeError('Please enter a number')
                            .nullable(),
                        income_source_fund_self_employed: yup
                            .number()
                            .typeError('Please enter a number')
                            .nullable(),

                        address: yup.string().nullable().required('Please enter Address'),
                        // service_address: yup.string().nullable().required('Please enter Address'),
                        email: yup
                            .string()
                            .nullable()
                            .email('Invalid email')
                            .required('Please enter Email'),
                        country_id: yup.number().nullable().required('Please enter Country'),
                        phone_country_id: yup
                            .number()
                            .nullable()
                            .required('Please enter Phone code'),
                        phone_number: yup.string().nullable().required('Please enter Phone number'),

                        CompanyPositions: yup.array().of(
                            yup.object({
                                percent_of_interest: yup
                                    .number()
                                    .typeError('Please enter a number')
                                    .nullable()
                                    .required('Please enter Percentage of interest'),
                                /*date_of_appointment: yup.mixed().when()
                            .date()
                            .nullable()
                            .required('Please enter this field')
                            .typeError('Invalid date')*/
                            }),
                        ),

                        CompanyMemberReferences: yup.array().when('__company_country_id', {
                            is: PANAMA_ID,
                            then: yup.array().of(
                                yup.object({
                                    name: yup.string().nullable().required('Please enter Name'),
                                    phone_number: yup
                                        .string()
                                        .nullable()
                                        .required('Please enter Phone number'),
                                    email: yup
                                        .string()
                                        .email('Invalid email')
                                        .nullable()
                                        .required('Please enter Email'),
                                    address: yup
                                        .string()
                                        .nullable()
                                        .required('Please enter Address'),
                                    company_member_issuse_reference_id: yup
                                        .string()
                                        .nullable()
                                        .required('Please enter this field'),
                                    phone_country_id: yup
                                        .number()
                                        .nullable()
                                        .required('Please enter Phone code'),
                                }),
                            ),
                        }),
                        tax: yup.string().when('__company_country_id', {
                            is: PANAMA_ID,
                            then: yup.string().nullable().required('Please enter your answer'),
                        }),
                    }),
                )
                .validateTotalSourceOfFund(false),
        })
        .validateRepresentedMember(
            'validateRepresentedUBO',
            UBO_TYPE_ID,
            'Must have at least one authorized signatory',
        )
        .validatePercentageOfInterest();
};

export const ArticlesOfOrganizationOptions = [
    {
        "id": 1,
        "title": "Member-managed",
        // "tooltip": "The management of the company is reserved for the members. (If this option is selected, kindly complete section 4 below. Section 5 will not be required.)",
        // "tooltip": "The management of the company is reserved for the members. (If this option is selected, kindly complete section Members below. Section Managers will not be required.)",
        "tooltip": "The management of the company is reserved for the members.",
    },
    {
        "id": 2,
        "title": "Manager-managed",
        // "tooltip": "The management of the company is carried on by a manager or managers. (If this option is selected, kindly complete both sections 4 and 5 below)",
        // "tooltip": "The management of the company is carried on by a manager or managers. (If this option is selected, kindly complete both sections Members and Managers below)",
        "tooltip": "The management of the company is carried on by a manager or managers.",
    }
]
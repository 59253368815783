export const CAYMAN_ID = 41;
export const RAK_ID = 236;
export const UK_ID = 237;
export const SG_ID = 191;
export const US_ID = 238;
export const BVI_ID = 32;
export const PANAMA_ID = 169;
export const BELIZE_ID = 23;
export const ST_KIT_ID = 205;
export const HONG_KONG_ID = 98;
export const DELAWARE_ID = 250;
export const SVG_ID = 209;
export const BAHAMAS_ID = 17;
export const SEYCHELLES_ID = 189;
export const SAMAO_ID = 183;
export const MARSHALL_ID = 136;
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import SelectField from 'components/form-controls/SelectField';
import InputField from 'components/form-controls/InputField';
import Swal from 'sweetalert2';

const SecurityQuestion = ({task, form, index, disabled, questionsAnswerOptions }) => {
    const [security_question_1, set_security_question_1] = useState({
        id: 0,
        content: '',
        question_id: null,
        Answers: [],
    });
    const [security_question_2, set_security_question_2] = useState({
        id: 0,
        content: '',
        question_id: null,
        Answers: [],
    });
    const [security_question_3, set_security_question_3] = useState({
        id: 0,
        content: '',
        question_id: null,
        Answers: [],
    });
    const [option1, setOption1] = useState([]);
    const [option2, setOption2] = useState([]);
    const [option3, setOption3] = useState([]);

    const onChangeQuestion1 = (value) => {
        if (
            value.content === security_question_2?.content ||
            value.content === security_question_3?.content
        ) {
            Swal.fire({
                icon: 'warning',
                text: 'Could not choose the same question',
                // timer: 2000,
            });

            if (security_question_1.id) {
                form.setValue(`members.${index}.security_question_1`, security_question_1.id);
            } else {
                form.setValue(`members.${index}.security_question_1`, undefined);
            }

            return;
        }

        if (security_question_1 && security_question_1.id != value.id) {
            set_security_question_1(value);
            form.setValue(`members.${index}.security_question_1`, value.id);
        }
    };

    const onChangeQuestion2 = (value) => {
        if (
            value.content === security_question_1?.content ||
            value.content === security_question_3?.content
        ) {
            Swal.fire({
                icon: 'warning',
                text: 'Could not choose the same question',
                // timer: 2000,
            });

            if (security_question_2.id) {
                form.setValue(`members.${index}.security_question_2`, security_question_2.id);
            } else {
                form.setValue(`members.${index}.security_question_2`, undefined);
            }

            return;
        }

        if (security_question_2 && security_question_2.id != value.id) {
            set_security_question_2(value);
            form.setValue(`members.${index}.security_question_2`, value.id);
        }
    };

    const onChangeQuestion3 = (value) => {
        if (
            value.content === security_question_1?.content ||
            value.content === security_question_2?.content
        ) {
            Swal.fire({
                icon: 'warning',
                text: 'Could not choose the same question',
                // timer: 2000,
            });

            if (security_question_3.id) {
                form.setValue(`members.${index}.security_question_3`, security_question_3.id);
            } else {
                form.setValue(`members.${index}.security_question_3`, undefined);
            }

            return;
        }

        if (security_question_3 && security_question_3.id != value.id) {
            set_security_question_3(value);
            form.setValue(`members.${index}.security_question_3`, value.id);
        }
    };

    useEffect(() => {
        const currentMember = form.getValues(`members.[index]`);
        if (!currentMember?.answer_question_1) {
            set_security_question_1({
                id: 0,
                content: '',
                question_id: null,
                Answers: [],
            })
        }
        if (!currentMember?.answer_question_2) {
            set_security_question_2({
                id: 0,
                content: '',
                question_id: null,
                Answers: [],
            })
        }
        if (!currentMember?.answer_question_3) {
            set_security_question_3({
                id: 0,
                content: '',
                question_id: null,
                Answers: [],
            })
        }
    }, [form.watch(`members.${index}.type_member_id`)]);

    useEffect(() => {
        const id = form.getValues(`members.${index}.id`);
        console.log(id)
        if (
            task?.Task.IncorporationCountry.Questions.find((q) => q.id === 17).Options.find((op) => {
                return op.Answers.find((ans) => ans.company_member_id === id);
            })
        ) {
            set_security_question_1(
                task?.Task.IncorporationCountry.Questions.find((q) => q.id === 17).Options.find(
                    (op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    },
                ),
            );
            form.setValue(
                `members.${index}.security_question_1`,
                task?.Task.IncorporationCountry.Questions.find((q) => q.id === 17).Options.find(
                    (op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    },
                )?.id,
            );
            console.log(task?.Task.IncorporationCountry.Questions.find((q) => q.id === 17)
            .Options.find((op) => {
                return op.Answers.find((ans) => ans.company_member_id === id);
            })
            .Answers.find((ans) => ans.company_member_id === id)?.value_text)
            form.setValue(
                `members.${index}.security_answer_1`,
                task?.Task.IncorporationCountry.Questions.find((q) => q.id === 17)
                    .Options.find((op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    })
                    .Answers.find((ans) => ans.company_member_id === id)?.value_text,
            );
        }
        if (
            task?.Task.IncorporationCountry.Questions.find((q) => q.id === 18).Options.find((op) => {
                return op.Answers.find((ans) => ans.company_member_id === id);
            })
        ) {
            set_security_question_2(
                task?.Task.IncorporationCountry.Questions.find((q) => q.id === 18).Options.find(
                    (op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    },
                ),
            );
            form.setValue(
                `members.${index}.security_question_2`,
                task?.Task.IncorporationCountry.Questions.find((q) => q.id === 18).Options.find(
                    (op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    },
                )?.id,
            );
            form.setValue(
                `members.${index}.security_answer_2`,
                task?.Task.IncorporationCountry.Questions.find((q) => q.id === 18)
                    .Options.find((op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    })
                    .Answers.find((ans) => ans.company_member_id === id)?.value_text,
            );
        }
        if (
            task?.Task.IncorporationCountry.Questions.find((q) => q.id === 19).Options.find((op) => {
                return op.Answers.find((ans) => ans.company_member_id === id);
            })
        ) {
            set_security_question_3(
                task?.Task.IncorporationCountry.Questions.find((q) => q.id === 19).Options.find(
                    (op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    },
                ),
            );
            form.setValue(
                `members.${index}.security_question_3`,
                task?.Task.IncorporationCountry.Questions.find((q) => q.id === 19).Options.find(
                    (op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    },
                )?.id,
            );
            form.setValue(
                `members.${index}.security_answer_3`,
                task?.Task.IncorporationCountry.Questions.find((q) => q.id === 19)
                    .Options.find((op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    })
                    .Answers.find((ans) => ans.company_member_id === id)?.value_text,
            );
        }

        setOption1(task?.Task.IncorporationCountry.Questions.find((q) => q.id === 17).Options);
        setOption2(task?.Task.IncorporationCountry.Questions.find((q) => q.id === 18).Options);
        setOption3(task?.Task.IncorporationCountry.Questions.find((q) => q.id === 19).Options);
    }, [task]);

    return (
        <Box sx={{ marginTop: '32px' }}>
            <Typography fontWeight={'medium'} mb={2}>
                SECURITY QUESTIONS
            </Typography>
            <Grid container spacing={2}>
                <Grid className='input-line' item xs={6}>
                    <label>Question 1</label>
                    <SelectField
                        form={form}
                        name={`members.${index}.security_question_1`}
                        options={option1 || []}
                        getOptionLabel={(option) => option.content}
                        onChange={value => onChangeQuestion1(value)}
                        disabled={disabled}
                    />
                </Grid>
                <Grid className='input-line' item xs={6}>
                    <label>Answer 1</label>
                    <InputField
                        form={form}
                        name={`members.${index}.security_answer_1`}
                        inputProps={{
                            maxLength: 3,
                        }}
                        disabled={disabled}
                    />
                </Grid>
                <Grid className='input-line' item xs={6}>
                    <label>Question 2</label>
                    <SelectField
                        form={form}
                        name={`members.${index}.security_question_2`}
                        options={option2 || []}
                        getOptionLabel={(option) => option.content}
                        onChange={value => onChangeQuestion2(value)}
                        disabled={disabled}
                    />
                </Grid>
                <Grid className='input-line' item xs={6}>
                    <label>Answer 2</label>
                    <InputField
                        form={form}
                        name={`members.${index}.security_answer_2`}
                        inputProps={{
                            maxLength: 3,
                        }}
                        disabled={disabled}
                    />
                </Grid>
                <Grid className='input-line' item xs={6}>
                    <label>Question 3</label>
                    <SelectField
                        form={form}
                        name={`members.${index}.security_question_3`}
                        options={option3 || []}
                        getOptionLabel={(option) => option.content}
                        onChange={value => onChangeQuestion3(value)}
                        disabled={disabled}
                    />
                </Grid>
                <Grid className='input-line' item xs={6}>
                    <label>Answer 3</label>
                    <InputField
                        form={form}
                        name={`members.${index}.security_answer_3`}
                        inputProps={{
                            maxLength: 3,
                        }}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default SecurityQuestion;

import { Button, Tab } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { getCompanyMembersAllowFields, getMembers, getSubmitDataMember } from '../../../helpers';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DirectorForm from 'app/pages/components/forms/DirectorForm';
import { useSelector } from 'react-redux';
import { PANAMA_ID, SVG_ID } from 'constants/country';
import { yupResolver } from '@hookform/resolvers/yup';
import { directorSchema } from 'app/pages/components/schemas/director';
import NomineeForm from 'app/pages/components/forms/NomineeForm';
import { COMPANY_MEMBER_TYPES } from 'app/pages/services/constants';

const Director = ({
    task,
    isLoading,
    disabled,
    isHideAction,
    onSubmit,
    handleNext,
    handleBack,
    hasShowSelectFullName,
    isRepresented,
    isNominee,
}) => {
    const [tabValue, setTabValue] = useState(0);
    const [isDeletedMember, setDeletedMember] = useState(false);
    const [initValues, setInitValues] = useState(false);

    const { countriesOptions, countriesCooperationOptions } = useSelector((state) => state.enum);
    const { comparedCompany } = useSelector((state) => state.companies);

    const isPanama = useMemo(() => task?.Company?.country_id === PANAMA_ID, [task]);
    const isSVG = useMemo(() => task?.Company?.country_id === SVG_ID, [task]);

    const form = useForm({
        defaultValues: {
            members: [],
        },
        resolver: yupResolver(directorSchema({ isNominee: isNominee, hasShowSelectFullName })),
    });

    const { fields } = useFieldArray({
        control: form.control,
        name: 'members',
        keyName: 'customId',
    });

    useEffect(() => {
        if (!task) return;
        if (!isDeletedMember && !isNominee) {
            handleResetForm(
                getMembers({
                    companyMembers: task.Company.CompanyMembers,
                    typeMember: 'director',
                    companyCountryId: task.Company.country_id,
                }),
            );
        }
        if (isNominee) {
            form.reset({
                reason: task.Task.reason_use_nominee_director || '',
            });
        }
        setDeletedMember(false);
    }, [task]);

    const handleResetForm = (members) => {
        setInitValues(members);
        form.reset({
            members,
        });
    };

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleSubmit = (values) => {
        if (Object.keys(form.formState.dirtyFields).length === 0) {
            handleNext();
        } else {
            if (isNominee) {
                const submitData = {
                    reason_use_nominee_director: {
                        is_has_director: true,
                        reason_use_nominee_director: values.reason,
                    },
                };
                onSubmit && onSubmit(submitData, true);
            } else {
                const submitData = {
                    answersForTask: [],
                    companyStructureCreate: [],
                    companyStructureUpdate: [],
                    companyStructureDelete: {},
                };
                values.members.forEach((member) => {
                    const newMember = getSubmitDataMember(member);
                    delete newMember?.CompanyPositions?.[0]?.relationship_with_company;
                    delete newMember?.CompanyPositions?.[0]?.is_approved;
                    delete newMember?.CompanyPositions?.[0]?.CompanyMemberType;
                    if (member.id) {
                        newMember.id = newMember.member_id;
                        delete newMember.member_id;

                        if (newMember?.answersForTask?.length > 0) {
                            newMember.answersForTask.forEach((ans) => {
                                const answer = {
                                    ...ans,
                                    company_member_id: member.id,
                                };
                                submitData.answersForTask.push(answer);
                            });
                            delete newMember.answersForTask;
                        }

                        submitData.companyStructureUpdate.push(newMember);
                    }
                });
                onSubmit && onSubmit(submitData, true);
            }
        }
    };

    const allowFields = useMemo(() => {
        let fields = [];
        if (initValues && comparedCompany?.Company?.CompanyMembers) {
            fields = getCompanyMembersAllowFields(
                initValues,
                comparedCompany.Company.CompanyMembers,
            );
        }
        return fields;
    }, [initValues, comparedCompany]);
    return (
        <>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                {task && isNominee ? (
                    <NomineeForm
                        name={'reason'}
                        form={form}
                        disabled={disabled}
                        allowField={
                            task.Task.reason_use_nominee_director &&
                            !comparedCompany?.reason_use_nominee_director ? false : true
                        }
                        companyMemberType={1}
                    />
                ) : (
                    <TabContext value={tabValue}>
                        <div className="control-tab mb-5">
                            <div className="tab-menu">
                                <TabList onChange={handleChangeTab}>
                                    {fields?.map((item, index) => {
                                        return (
                                            <Tab
                                                key={item.id}
                                                className="item"
                                                label={index + 1}
                                                id={item.id}
                                            />
                                        );
                                    })}
                                </TabList>
                            </div>
                        </div>
                        {fields.map((field, index) => (
                            <TabPanel key={index} value={index} sx={{ padding: '0px 0px 32px' }}>
                                <DirectorForm
                                    form={form}
                                    index={index}
                                    disabled={disabled}
                                    countriesOptions={countriesOptions}
                                    countriesCooperationOptions={countriesCooperationOptions}
                                    isSVG={isSVG}
                                    isPanama={isPanama}
                                    isRepresented={isRepresented}
                                    onCheckRepresented={(indexC, checked, fieldSignName) => {
                                        if (checked) {
                                            fields.forEach((field, indexF) => {
                                                if (indexC === indexF) {
                                                    return form.setValue(
                                                        `members.${indexF}.CompanyPositions.0.is_appointed`,
                                                        1,
                                                    );
                                                }
                                                const tempFieldName = fieldSignName
                                                    ? `members.${indexF}.${fieldSignName}`
                                                    : `members.${indexF}.CompanyPositions.0.is_appointed`;
                                                form.setValue(tempFieldName, 0);
                                            });
                                        }
                                    }}
                                    hasShowSelectFullName={hasShowSelectFullName}
                                    allowFields={allowFields}
                                />
                            </TabPanel>
                        ))}
                    </TabContext>
                )}
                {!isHideAction && (
                    <>
                        <Button
                            size="large"
                            variant="contained"
                            disableElevation
                            disabled={isLoading || form.isSubmitting}
                            onClick={handleBack}
                            className={'mr-2'}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            type="submit"
                            style={{ color: '#fff' }}
                            disabled={isLoading}
                        >
                            Next
                        </Button>
                    </>
                )}
            </form>
        </>
    );
};

export default Director;

import React, { Suspense } from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
// import { OrderFormIncorporationPage } from './order-form/instruction-incorporation/OrderFormIncorporationPage';
import { OrderDetail } from './order-form/OrderDetail';
import { BankingProcess } from './banking/banking-process/BankingProcess';
import { OrderListPage } from './orders-list/OrderListPage';
import { LayoutSplashScreen, ContentRoute } from '_metronic/layout';
import { getLinkContentRoute } from './OrderManagermentUIHelpers';
import { LINK } from 'app/pages/services/constants/banking_constants';
import './order-management.scss';
import BankingToolPage from './banking/banking-tool/banking-tool-page';
import AdditionalService from './additional-service/AdditionalService';

export default function OrderManagementPage() {
    return (
        <div className="order-management ">
            <Suspense fallback={<LayoutSplashScreen />}>
                <Routes>
                    {
                        /* Redirect from eCommerce root URL to /order-management */
                        // <Redirect exact={true} from="/" to="/" />
                    }
                    <Route exact path="/" element={<OrderListPage/>} />

                    {/* <ContentRoute path="/order-management/order/:id/incorporation" element={<OrderDetail/>} /> */}
                    <Route path={getLinkContentRoute(LINK.INCORPORATION, ':id').replace('/order-management', '')} element={<OrderDetail/>} />
                    <Route path={getLinkContentRoute(LINK.BANKING_TOOL, ':id').replace('/order-management', '')} element={<BankingToolPage/>} />
                    <Route path={getLinkContentRoute(LINK.BANKING, ':id').replace('/order-management', '')} element={<BankingProcess/>} />
                    <Route path={getLinkContentRoute(LINK.ADDITIONAL_SERVICE, ':id').replace('/order-management', '')} element={<AdditionalService/>} />
                    <Route path="*" element={<Navigate replace to={''}/>}/>
                </Routes>
            </Suspense>
        </div>
    );
}

import React, { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ImageCrop from './ImageCrop';
import { ImageCropPreview } from './ImageCropPreview';
import * as actionsFiles from '../../_redux/files/filesActions';
export const ImageCropDialog = ({ upImg, inputFile, setUpImg, show, setShow, isReUsable = false, onSave, onHidePopup,aspect = 1, acceptLabel = 'Submit', cancelLabel = 'Cancel' }) => {
    const previewCanvasRef = useRef(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [cropBlob, setCropBlob] = useState();
    const dispatch = useDispatch();
    const onClose = () => {
        setShow(false);
        setUpImg(undefined);
        inputFile.current.value = '';
        onHidePopup && onHidePopup();
    };
    const { isLoading } = useSelector(
        (state) => ({ isLoading: state.files.actionsLoading }),
        shallowEqual,
    );

    const save = () => {
        // const image = new FormData();
        // image.append('file', cropBlob, 'new_avatar');
        // dispatch(actionsFiles.uploadImage(image)).then(() => {
        //     onClose();
        // });
        if (inputFile.current && inputFile.current.files && inputFile.current.files.length > 0) {
            dispatch(actionsFiles.genSignedUrlS3([
                { "filename": inputFile.current.files[0].name, "content_type": inputFile.current.files[0].type }
            ])).then(response => {
                if (response && response.data && response.data.length > 0) {
                    dispatch(actionsFiles.uploadImageS3(response.data[0], cropBlob)).then(
                        data => {
                            onClose();
                        }
                    )
                }
            })
        }
    };

    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={onClose}
                aria-labelledby="modal-sizes-title-lg"
                dialogClassName="modal-avatar"
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="modal-sizes-title-lg">Crop Image!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ImageCropPreview
                        completedCrop={completedCrop}
                        previewCanvasRef={previewCanvasRef}
                    />
                    <div className="image-crop m-3">
                        <ImageCrop
                            previewCanvasRef={previewCanvasRef}
                            upImg={upImg}
                            completedCrop={completedCrop}
                            setCompletedCrop={setCompletedCrop}
                            setCropBlob={setCropBlob}
                            aspect={aspect}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button
                            type="button"
                            onClick={onClose}
                            disabled={isLoading}
                            className="btn btn-light btn-elevate"
                        >
                            {cancelLabel}
                        </button>
                        <> </>
                        <button
                            type="button"
                            onClick={() => {
                                if (isReUsable) {
                                    onSave && onSave(cropBlob);
                                } else {
                                    save();
                                }
                            }}
                            disabled={isLoading}
                            className="btn btn-primary btn-elevate"
                        >
                            {acceptLabel}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

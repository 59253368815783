import React, { useEffect, useState } from 'react';
import {
    getDefaultNationality,
    getDefaultPhonePrefix,
} from '../../../../../../utilities/NationalityHelpers';
import { Suggestion } from '../suggestion/suggestion';
import { useSelector } from 'react-redux';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import SelectFullNameHint from 'app/components/SelectFullNameHint';

export default function DirectorIndividual({
    isUsedContactInformation,
    index,
    body,
    values,
    disabled = false,
    form,
    hasShowSelectFullName,
}) {
    const [phonePrefix, setPhonePrefix] = useState('');
    const [country, setCountry] = useState('');
    const { countriesCooperationOptions, phonePrefixCountriesCooperation } = useSelector(
        (state) => state.enum,
    );

    useEffect(() => {
        let timeout;
        try {
            if (!countriesCooperationOptions?.length || !phonePrefixCountriesCooperation?.length)
                return;

            let phonePrefixDefault = getDefaultPhonePrefix(
                body.companyStructure.Directors[index]?.phone_code,
                phonePrefixCountriesCooperation,
            );
            setPhonePrefix(phonePrefixDefault);

            let nationality = getDefaultNationality(
                body.companyStructure.Directors[index]?.country_id,
                countriesCooperationOptions,
            );
            setCountry(nationality);

            if (form.getValues(`members[${index}]`)) {
                // resolve error: create member -> next step -> back step -> delete member -> fill info -> next step -> ERROR
                timeout = setTimeout(() => {
                    form.setValue(`members.${index}.phone_code`, phonePrefixDefault.id);
                    form.setValue(`members.${index}.country_id`, nationality.id);
                }, 1);
            }
        } catch (error) {
            console.log(error);
        }
        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [body, countriesCooperationOptions, phonePrefixCountriesCooperation]);

    return (
        <>
            {(!values[index].phone || !values[index].email) && !isUsedContactInformation && (
                <Suggestion
                    index={index}
                    body={body}
                    countriesCooperation={countriesCooperationOptions}
                    phonePrefixList={phonePrefixCountriesCooperation}
                    setCountry={setCountry}
                    setPhonePrefix={setPhonePrefix}
                    form={form}
                    hasShowSelectFullName={hasShowSelectFullName}
                />
            )}

            <div className="row">
                {hasShowSelectFullName && (
                    <>
                        <div className="col-md-4 mb-7 input-line">
                            <label>First name</label>
                            <InputField
                                form={form}
                                name={`members.${index}.first_name`}
                                placeholder="First name"
                                showIcon
                                hideSuccessHelper
                                disabled={disabled}
                                onChange={(e) => {
                                    form.setValue(`members.${index}.first_name`, e.target.value);
                                    if (form.getValues(`members.${index}.full_name`)) {
                                        form.setValue(`members.${index}.full_name`, '');
                                    }
                                }}
                            />
                        </div>
                        <div className="col-md-4 mb-7 input-line">
                            <label>Middle name</label>
                            <InputField
                                form={form}
                                name={`members.${index}.middle_name`}
                                placeholder="Middle name"
                                showIcon
                                hideSuccessHelper
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-md-4 mb-7 input-line">
                            <label>Last name</label>
                            <InputField
                                form={form}
                                name={`members.${index}.last_name`}
                                placeholder="Last name"
                                showIcon
                                hideSuccessHelper
                                disabled={disabled}
                                onChange={(e) => {
                                    form.setValue(`members.${index}.last_name`, e.target.value);
                                    if (form.getValues(`members.${index}.full_name`)) {
                                        form.setValue(`members.${index}.full_name`, '');
                                    }
                                }}
                            />
                        </div>
                    </>
                )}
                <div className="col-md-6 mb-7 input-line">
                    <label>Full name</label>
                    <InputField
                        type="text"
                        name={`members.${index}.full_name`}
                        form={form}
                        placeholder="Enter full name"
                        disabled={disabled || hasShowSelectFullName}
                        hideSuccessHelper
                        multiline={hasShowSelectFullName}
                    />
                </div>
                <div className="col-md-6 mb-7 input-line">
                    <label>Occupation</label>
                    <InputField
                        type="text"
                        name={`members.${index}.occupation`}
                        form={form}
                        placeholder="Enter occupation"
                        hideSuccessHelper
                        disabled={disabled}
                    />
                </div>
                {hasShowSelectFullName && (
                    <SelectFullNameHint
                        form={form}
                        fullNameField={`members.${index}.full_name`}
                        firstNameField={`members.${index}.first_name`}
                        middleNameField={`members.${index}.middle_name`}
                        lastNameField={`members.${index}.last_name`}
                        disabled={disabled}
                    />
                )}
                <div className="col-md-6 mb-7 input-line">
                    <label>Phone number</label>
                    <div className="phone-group">
                        <div className="phone-code" style={{ zIndex: 1 }}>
                            <SelectField
                                value={phonePrefix}
                                form={form}
                                className="fw-500"
                                label=""
                                name={`members.${index}.phone_code`}
                                placeholder="Select..."
                                options={phonePrefixCountriesCooperation}
                                hideSuccessHelper
                                hideHelperText
                                customStyle={{
                                    '.MuiInputBase-root:before, .MuiInputBase-root:after, .MuiInput-underline:hover:not(.Mui-disabled):before':
                                        {
                                            borderBottom: '0 !important',
                                        },
                                }}
                                disableClearable
                                onChange={(e) => {
                                    if (phonePrefix?.id != e.id) {
                                        setPhonePrefix(e);
                                        form.setValue(`members.${index}.phone_code`, e);
                                    }
                                }}
                                disabled={disabled}
                            />
                        </div>
                        <div className="input-line inp-phone">
                            <InputField
                                name={`members.${index}.phone`}
                                form={form}
                                placeholder="Enter phone number"
                                hideSuccessHelper
                                type="text"
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-6 mb-7 input-line">
                    <label>Email address</label>
                    <InputField
                        type="text"
                        name={`members.${index}.email`}
                        form={form}
                        placeholder="Enter email address"
                        disabled={disabled}
                        hideSuccessHelper
                    />
                </div>
                <div className="col-md-6 col-sm-6 mb-7 input-line">
                    <label>Nationality</label>
                    <SelectField
                        form={form}
                        className="fw-500"
                        label=""
                        name={`members.${index}.country_id`}
                        placeholder="Choose country"
                        options={countriesCooperationOptions}
                        hideHelperText={true}
                        value={country}
                        onChange={(value) => {
                            if (country?.id != value.id) {
                                setCountry(value);
                                form.setValue(`members.${index}.country_id`, value.id);
                            }
                        }}
                        disabled={disabled}
                    />
                </div>
            </div>
        </>
    );
}

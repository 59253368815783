import { Grid, Switch } from '@mui/material';
import SelectFullNameHint from 'app/components/SelectFullNameHint';
import DateField from 'components/form-controls/DateField';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import React from 'react';

const IndividualForm = ({
    form,
    index,
    hasShowSelectFullName,
    disabled,
    phoneCountryOptions,
    countriesCooperationOptions,
    onSwitchTypeMember,
    hideMemberTypeSwitch = false,
    allowFields,
}) => {
    return (
        <Grid container rowSpacing={2} columnSpacing={4}>
            {!hideMemberTypeSwitch && (
                <Grid item xs={12}>
                    <span>Corporation</span>
                    <Switch
                        checked={form.watch(`members.${index}.type_member_id`) === 1}
                        onChange={(e) => onSwitchTypeMember(e, index)}
                        name={`members.${index}.individual`}
                        disabled
                    />
                    <span>Individual</span>
                </Grid>
            )}
            {hasShowSelectFullName && (
                <>
                    <Grid item sm={4} xs={12} className="input-line">
                        <label>First name</label>
                        <InputField
                            form={form}
                            name={`members.${index}.first_name`}
                            placeholder="First name"
                            showIcon
                            hideSuccessHelper
                            disabled={disabled || !allowFields?.[index]?.first_name}
                            customStyle={{
                                '.MuiInputBase-root': {
                                    backgroundColor:
                                        allowFields?.[index]?.first_name &&
                                        form.getValues(`members.${index}.first_name`) !== ''
                                            ? 'rgba(176, 236, 209, 0.5)'
                                            : 'inherit',
                                },
                            }}
                            onChange={(e) => {
                                form.setValue(`members.${index}.first_name`, e.target.value);
                                if (form.getValues(`members.${index}.full_name`)) {
                                    form.setValue(`members.${index}.full_name`, '');
                                }
                            }}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12} className="input-line">
                        <label>Middle name</label>
                        <InputField
                            form={form}
                            name={`members.${index}.middle_name`}
                            placeholder="Middle name"
                            showIcon
                            hideSuccessHelper
                            disabled={disabled || !allowFields?.[index]?.middle_name}
                            customStyle={{
                                '.MuiInputBase-root': {
                                    backgroundColor:
                                        allowFields?.[index]?.middle_name &&
                                        form.getValues(`members.${index}.middle_name`) !== ''
                                            ? 'rgba(176, 236, 209, 0.5)'
                                            : 'inherit',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12} className="input-line">
                        <label>Last name</label>
                        <InputField
                            form={form}
                            name={`members.${index}.last_name`}
                            placeholder="Last name"
                            showIcon
                            hideSuccessHelper
                            disabled={disabled || !allowFields?.[index]?.last_name}
                            customStyle={{
                                '.MuiInputBase-root': {
                                    backgroundColor:
                                        allowFields?.[index]?.last_name &&
                                        form.getValues(`members.${index}.last_name`) !== ''
                                            ? 'rgba(176, 236, 209, 0.5)'
                                            : 'inherit',
                                },
                            }}
                            onChange={(e) => {
                                form.setValue(`members.${index}.last_name`, e.target.value);
                                if (form.getValues(`members.${index}.full_name`)) {
                                    form.setValue(`members.${index}.full_name`, '');
                                }
                            }}
                        />
                    </Grid>
                </>
            )}
            <Grid item sm={6} xs={12} className="input-line">
                <label>Full name</label>
                <InputField
                    type="text"
                    name={`members.${index}.full_name`}
                    form={form}
                    placeholder="Enter full name"
                    disabled={disabled || hasShowSelectFullName || !allowFields?.[index]?.full_name}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.full_name &&
                                form.getValues(`members.${index}.full_name`) !== ''
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    hideSuccessHelper
                    multiline={hasShowSelectFullName}
                />
            </Grid>
            <Grid item sm={6} xs={12} className="input-line">
                <label>Occupation</label>
                <InputField
                    type="text"
                    name={`members.${index}.major`}
                    form={form}
                    placeholder="Enter occupation"
                    hideSuccessHelper
                    disabled={disabled || !allowFields?.[index]?.major}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.major &&
                                form.getValues(`members.${index}.major`) !== ''
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                />
            </Grid>
            {hasShowSelectFullName && (
                <Grid item xs={12} sx={{
                    margin: '0 -12.5px'
                }}>
                    <SelectFullNameHint
                        form={form}
                        fullNameField={`members.${index}.full_name`}
                        firstNameField={`members.${index}.first_name`}
                        middleNameField={`members.${index}.middle_name`}
                        lastNameField={`members.${index}.last_name`}
                        disabled={disabled || !allowFields?.[index]?.full_name}
                    />
                </Grid>
            )}

            <Grid className="input-line" item sm={6} xs={12}>
                <label>Email</label>
                <InputField
                    type="text"
                    name={`members.${index}.email`}
                    form={form}
                    placeholder="Enter email address"
                    disabled={disabled || !allowFields?.[index]?.email}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.email &&
                                form.getValues(`members.${index}.email`) !== ''
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    hideSuccessHelper
                />
            </Grid>
            <Grid className="input-line" item sm={6} xs={12}>
                <label>Phone number</label>
                <div className="phone-group">
                    <div className="phone-code" style={{ zIndex: 1 }}>
                        <SelectField
                            form={form}
                            className="fw-500"
                            name={`members.${index}.phone_country_id`}
                            placeholder="Select..."
                            options={phoneCountryOptions}
                            hideSuccessHelper
                            hideHelperText
                            disableClearable
                            disabled={disabled || !allowFields?.[index]?.phone_country_id}
                            customStyle={{
                                '.MuiInputBase-root': {
                                    backgroundColor:
                                        allowFields?.[index]?.phone_country_id &&
                                        form.getValues(`members.${index}.phone_country_id`) !== null
                                            ? 'rgba(176, 236, 209, 0.5)'
                                            : 'inherit',
                                },
                                '.MuiInputBase-root:before, .MuiInputBase-root:after, .MuiInput-underline:hover:not(.Mui-disabled):before':
                                    {
                                        borderBottom: '0 !important',
                                    },
                            }}
                        />
                    </div>
                    <div className="input-line inp-phone">
                        <InputField
                            name={`members.${index}.phone_number`}
                            form={form}
                            placeholder="Enter phone number"
                            hideSuccessHelper
                            type="text"
                            disabled={disabled || !allowFields?.[index]?.phone_number}
                            customStyle={{
                                '.MuiInputBase-root': {
                                    backgroundColor:
                                        allowFields?.[index]?.phone_number &&
                                        form.getValues(`members.${index}.phone_number`) !== ''
                                            ? 'rgba(176, 236, 209, 0.5)'
                                            : 'inherit',
                                },
                            }}
                        />
                    </div>
                </div>
            </Grid>
            <Grid className="input-line" item sm={6} xs={12}>
                <label>Nationality</label>
                <SelectField
                    form={form}
                    className="fw-500"
                    name={`members.${index}.country_id`}
                    placeholder="Choose country"
                    options={countriesCooperationOptions}
                    hideHelperText={true}
                    disabled={disabled || !allowFields?.[index]?.country_id}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.country_id &&
                                form.getValues(`members.${index}.country_id`) !== null
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                />
            </Grid>
            <Grid className="input-line" item sm={6} xs={12}>
                <label>Passport number</label>
                <InputField
                    type="text"
                    name={`members.${index}.passport`}
                    form={form}
                    placeholder="Enter passport number"
                    disabled={disabled || !allowFields?.[index]?.passport}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.passport &&
                                form.getValues(`members.${index}.passport`) !== ''
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    hideSuccessHelper
                />
            </Grid>

            <Grid className="input-line" item sm={6} xs={12}>
                <label>Date of birth</label>
                <DateField
                    name={`members.${index}.birthday`}
                    form={form}
                    placeholder="Enter date of birth"
                    disabled={disabled || !allowFields?.[index]?.birthday}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.birthday &&
                                form.getValues(`members.${index}.birthday`) !== null
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    hideSuccessHelper
                />
            </Grid>

            <Grid className="input-line" item sm={6} xs={12}>
                <label>Date of issue</label>
                <DateField
                    type="text"
                    name={`members.${index}.passport_issue_date`}
                    form={form}
                    placeholder="Enter date of issue"
                    disabled={disabled || !allowFields?.[index]?.passport_issue_date}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.passport_issue_date &&
                                form.getValues(`members.${index}.passport_issue_date`) !== null
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    hideSuccessHelper
                />
            </Grid>

            <Grid className="input-line" item sm={6} xs={12}>
                <label>Residential address</label>
                <InputField
                    type="text"
                    name={`members.${index}.address`}
                    form={form}
                    placeholder="Enter residential address"
                    disabled={disabled || !allowFields?.[index]?.address}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.address &&
                                form.getValues(`members.${index}.address`) !== ''
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    hideSuccessHelper
                />
            </Grid>
            <Grid className="input-line" item sm={6} xs={12}>
                <label>Date of expiration</label>
                <DateField
                    name={`members.${index}.passport_expire_date`}
                    form={form}
                    placeholder="Enter date of expiration"
                    disabled={disabled || !allowFields?.[index]?.passport_expire_date}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.passport_expire_date &&
                                form.getValues(`members.${index}.passport_expire_date`) !== null
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    hideSuccessHelper
                />
            </Grid>
        </Grid>
    );
};

export default IndividualForm;

import React, { useState, useMemo, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { useFormik, Formik } from 'formik';
import TextField from '@mui/material/TextField';
import AntSwitch from './AntSwitch';
import FileUpload from './FileUpload';
import withStyles from '@mui/styles/withStyles';
import {
    MEMBER_TYPES,
    COMPANY_MEMBER_TYPES,
    isLLC,
    getExtension,
} from 'app/pages/services/constants';
import * as ActionsFile from 'app/pages/_redux/files/filesActions';
// import * as ActionsNode from 'app/pages/_redux/order-form/orderFormActions';
import { validateForm } from './validation';
import Swal from 'sweetalert2';

import CircularProgress from '@mui/material/CircularProgress';
import IndividualItemList from './IndividualItemList';
import CorporationItemList from './CorporationItemList';
import ExistingMemberList from './ExistingMemberList';
import { getDefaultNationality } from 'app/pages/services/utilities/NationalityHelpers';
import ReferenceItem from './ReferenceItem';
import {
    EmptyMemberCommon,
    formatCompanyMembers,
} from 'app/pages/services/order-management/order-form/instruction-incorporation/component/step3/step3-helper/helpers';
import { COUNTRIES_NOT_DISABLED_BANKING_FORM } from '../../order-management/banking/constant';
import SwitchFormikShareholderCorp from 'app/components/SwitchFormikShareholderCorp';
import { useEffect } from 'react';
import { cloneDeep, get, head, isEmpty, uniq } from 'lodash';
import { InputAdornment, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@mui/system';
import { BVI_ID, CAYMAN_ID, PANAMA_ID, RAK_ID, UK_ID } from 'constants/country';
import * as actionsEnum from '../../../_redux/enum/enumActions';
import SelectFullNameHint from 'app/components/SelectFullNameHint';
import SelectFullNameHintFormik from 'app/components/SelectFullNameHintFormik';

const SelectCode = withStyles(() => ({
    root: {
        width: 150,
        border: 'none',
        '& .MuiInput-underline:before': {
            border: 'none',
        },
        '& .Mui-focused:after': {
            border: 'none',
        },
    },
}))(TextField);

const initMemberData = (isIndividual, data) => {
    if (isIndividual) {
        return {
            first_name: `${data?.first_name || ''}`,
            middle_name: `${data?.middle_name || ''}`,
            last_name: `${data?.last_name || ''}`,
            full_name: `${data?.full_name || ''}`,
            major: data?.major || '',
            phone_country: data.phoneCountry,
            phone_number: data?.phone_number || '',
            corporation_name: '',
            corporation_number: '',
            address: '',
            corporation_date: '',
        };
    } else {
        return {
            corporation_name: data?.corporation_name || '',
            corporation_number: data?.corporation_number || '',
            address: data?.address || '',
            corporation_date: data?.corporation_date || '',
            full_name: '',
            major: '',
            phone_country: data.phoneCountry,
            phone_number: data?.phone_number || '',
            business_activity_array: data?.BusinessActivities || [],
            business_activity_detail: data?.business_activity_detail || '',
            country_operations: data?.CompanyMemberOperationCountries || [],
        };
    }
};

const DetailForm = ({
    data,
    closeModal,
    updateGraphStructure,
    graphLoading,
    addGraphStructure,
    setGraphElement,
    //isLevelOneCloned,
    orderInfo,
    updateCompanyMemberList,
    isBankingOutSide,
    flow,
    body,
    setBody,
}) => {
    /**
     * -----------------------------------------------------------------------------------------------------------------
     * Variables -------------------------------------------------------------------------------------------------------
     * -----------------------------------------------------------------------------------------------------------------
     */
    const { countriesCooperation, countries } = useSelector((state) => state.enum, shallowEqual);
    const { businessActivityWithCountry } = useSelector(
        (state) => ({
            businessActivityWithCountry: state.enum.businessActivityWithCountry,
        }),
        shallowEqual,
    );
    const dispatch = useDispatch();
    const [addedFiles, setAddedFiles] = useState([]);
    const [deletedFiles, setDeletedFiles] = useState([]);
    const [fileLoading, setFileLoading] = useState(false);
    const [viewedFiles, setViewedFiles] = useState([]);
    const [isInValid, setIsInValid] = useState(false);
    const [isPercent, setIsPercent] = useState(() => {
        if (
            data.company_member_type_id === COMPANY_MEMBER_TYPES.SHAREHOLDER.id &&
            data.corporation_company_member_id
        ) {
            return !!data.own_percent;
        }
        return false;
    });
    const isBankingDisabled =
        flow === 'banking' &&
        !isBankingOutSide &&
        !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(orderInfo?.Company?.country_id);
    const isContactPerson = useMemo(() => {
        return !!data.CompanyPositions?.find(
            (companyPosition) =>
                companyPosition?.company_member_type_id === 7 && companyPosition?.is_contact_person,
        );
    }, [data]);
    const firstUpdate = useRef(true);
    const formikRef = useRef(null);

    const [businessActivity, setBusinessActivity] = useState(null);
    const [businessActivityOptions, setBusinessActivityOptions] = useState([]); // list business attivity option
    // const uboChecker = useMemo(() => {
    //     let result = {
    //         text: '',
    //         percentage: 0,
    //         percentageCurrent: 0,
    //     };
    //     if (data && data.parent_id && data.company_member_type_id) {
    //         const members = orderForm?.orderInfo?.Company?.CompanyMembers || [];
    //         const uboList = members.filter(
    //             (el) =>
    //                 el.is_deleted === 0 &&
    //                 el.parent_id === data.parent_id &&
    //                 el.company_member_type_id === COMPANY_MEMBER_TYPES.UBO.id,
    //         );

    //         uboList.forEach((ubo) => {
    //             result.percentage += Number(ubo.percentage_interest);
    //             if (data.id && ubo.id === data.id) {
    //                 result.percentageCurrent = Number(ubo.percentage_interest);
    //             }
    //         });

    //         if (result.percentage < 100) {
    //             result.text = 'You have to add more UBO if your percentage is less than 100';
    //         }
    //     }
    //     return result;
    // }, [data, orderForm.orderInfo.Company.CompanyMembers]);
    // order form country
    const orderCountryId = useMemo(() => {
        return orderInfo?.Company?.country_id || -1;
    }, [orderInfo.Company.country_id]);
    // llc checker
    const checkIsLLC = useMemo(() => {
        const typeTemp = orderInfo?.Company?.EntityType || {};
        const company_country_id = orderInfo?.Company?.country_id || -1;
        return isLLC(typeTemp.id, company_country_id);
    }, [orderInfo.Company.EntityType, orderInfo.Company.country_id]);
    // get country for phone number
    const phoneCountry = useMemo(() => {
        let result = countriesCooperation[0] || {};
        result = getDefaultNationality(data.phone_country_id, countriesCooperation);
        return result;
    }, [countriesCooperation, data.phone_country_id]);
    const phoneCountryCorporation = useMemo(() => {
        let result = countries[0] || {};
        result = getDefaultNationality(data.phone_country_id, countries);
        return result;
    }, [countries, data.phone_country_id]);
    // get country of member
    const memberCountry = useMemo(() => {
        let result = countriesCooperation[0] || {};
        result = getDefaultNationality(data.country_id, countriesCooperation);
        return result;
    }, [countriesCooperation, data.country_id]);
    const memberCountryCorporation = useMemo(() => {
        let result = countries[0] || {};
        result = getDefaultNationality(data.country_id, countries);
        return result;
    }, [countries, data.country_id]);

    const hasShowSelectFullName = useMemo(() => {
        return [BVI_ID, PANAMA_ID].includes(orderInfo?.Company?.country_id);
    }, [orderInfo]);

    const initialValues = useMemo(() => {
        return {
            preData: { ...data, isLLCType: checkIsLLC },
            // Individual + Corporation
            type_member: data?.type_member_id === MEMBER_TYPES.INDIVIDUAL.id || false,
            country:
                data?.type_member_id === MEMBER_TYPES.INDIVIDUAL.id
                    ? memberCountry
                    : memberCountryCorporation,
            email: data?.email || '',
            ...initMemberData(data?.type_member_id === MEMBER_TYPES.INDIVIDUAL.id || false, {
                ...data,
                phoneCountry:
                    data?.type_member_id === MEMBER_TYPES.INDIVIDUAL.id
                        ? phoneCountry
                        : phoneCountryCorporation,
            }),
            ...(data.corporation_company_member_id
                ? {
                      number_allotted: !isPercent ? data?.number_allotted || 0 : 0,
                      own_percent: isPercent ? data?.own_percent || 0 : 0,
                  }
                : {
                      number_allotted: data?.number_allotted || 0,
                      own_percent: data?.own_percent || 0,
                  }),
            business_activity_array: data?.BusinessActivities || [],
            business_activity_detail: data?.business_activity_detail || '',
            country_operations: data?.CompanyMemberOperationCountries || [],

            number_allotted: data?.number_allotted || 0,
            own_percent: data?.own_percent || 0,
            // UBO
            percent_of_interest: data?.percent_of_interest || 0,

            // Panama reference
            CompanyMemberReferences:
                orderCountryId === 169 && !data.corporation_company_member_id
                    ? [
                          // check is Panama && is member level 1
                          {
                              id:
                                  data?.CompanyMemberReferences[0]?.id ||
                                  EmptyMemberCommon.references[0].id,
                              name:
                                  data?.CompanyMemberReferences[0]?.name ||
                                  EmptyMemberCommon.references[0].name,
                              phone_number:
                                  data?.CompanyMemberReferences[0]?.phone_number ||
                                  EmptyMemberCommon.references[0].phone_number,
                              phone_country_id:
                                  data?.CompanyMemberReferences[0]?.phone_country_id ||
                                  EmptyMemberCommon.references[0].phone_country_id,
                              fax:
                                  data?.CompanyMemberReferences[0]?.fax ||
                                  EmptyMemberCommon.references[0].fax,
                              email:
                                  data?.CompanyMemberReferences[0]?.email ||
                                  EmptyMemberCommon.references[0].email,
                              address:
                                  data?.CompanyMemberReferences[0]?.address ||
                                  EmptyMemberCommon.references[0].address,
                              company_member_id:
                                  data?.CompanyMemberReferences[0]?.company_member_id ||
                                  EmptyMemberCommon.references[0].company_member_id,
                              company_member_issuse_reference_id:
                                  data?.CompanyMemberReferences[0]
                                      ?.company_member_issuse_reference_id ||
                                  EmptyMemberCommon.references[0]
                                      .company_member_issuse_reference_id,
                          },
                          {
                              id:
                                  data?.CompanyMemberReferences[1]?.id ||
                                  EmptyMemberCommon.references[1].id,
                              name:
                                  data?.CompanyMemberReferences[1]?.name ||
                                  EmptyMemberCommon.references[1].name,
                              phone_number:
                                  data?.CompanyMemberReferences[1]?.phone_number ||
                                  EmptyMemberCommon.references[1].phone_number,
                              phone_country_id:
                                  data?.CompanyMemberReferences[1]?.phone_country_id ||
                                  EmptyMemberCommon.references[1].phone_country_id,
                              fax:
                                  data?.CompanyMemberReferences[1]?.fax ||
                                  EmptyMemberCommon.references[1].fax,
                              email:
                                  data?.CompanyMemberReferences[1]?.email ||
                                  EmptyMemberCommon.references[1].email,
                              address:
                                  data?.CompanyMemberReferences[1]?.address ||
                                  EmptyMemberCommon.references[1].address,
                              company_member_id:
                                  data?.CompanyMemberReferences[1]?.company_member_id ||
                                  EmptyMemberCommon.references[1].company_member_id,
                              company_member_issuse_reference_id:
                                  data?.CompanyMemberReferences[1]
                                      ?.company_member_issuse_reference_id ||
                                  EmptyMemberCommon.references[1]
                                      .company_member_issuse_reference_id,
                          },
                      ]
                    : undefined,
        };
    }, []);

    // create formik
    const formik = useFormik({
        initialValues,
        validate: (values) =>
            validateForm(values, {
                isContactPerson,
                CorporationPercent: isPercent,
                hasShowSelectFullName,
                orderInfo,
            }),
        onSubmit: (values) => {
            if (values.preData.id) {
                // edit || update.
                handleUpdate(values);
            } else {
                // add new
                handleAdd(values);
            }
        },
        innerRef: formikRef,
    });

    useEffect(() => {
        const countryIds = [formik?.values?.country?.id];
        let countryCorporationIds = [];

        if (countryIds?.length) {
            countryIds.forEach((countryId) => {
                const normalListCountry = countryCorporationIds.filter(
                    (item) => ![CAYMAN_ID, UK_ID, RAK_ID].includes(item),
                );

                if (
                    ![CAYMAN_ID, UK_ID, RAK_ID].includes(countryId) &&
                    !normalListCountry?.length &&
                    !businessActivityWithCountry?.normalList?.length
                ) {
                    countryCorporationIds.push(countryId);
                }

                if (countryId === CAYMAN_ID && !businessActivityWithCountry?.caymanList?.length) {
                    countryCorporationIds.push(CAYMAN_ID);
                }
                if (countryId === UK_ID && !businessActivityWithCountry?.ukList?.length) {
                    countryCorporationIds.push(UK_ID);
                }
                if (countryId === RAK_ID && !businessActivityWithCountry?.rakList?.length) {
                    countryCorporationIds.push(RAK_ID);
                }
            });
        }

        countryCorporationIds.forEach((countryId) => {
            dispatch(actionsEnum.fetchBusinessActivity(countryId, true));
        });
    }, [formik?.values?.country?.id]);

    useEffect(() => {
        const emptyMember = {
            preData: { ...data, isLLCType: checkIsLLC },
            // Individual + Corporation
            type_member: data?.type_member_id === 1 ? false : true,
            country:
                data?.type_member_id === MEMBER_TYPES.INDIVIDUAL.id
                    ? memberCountry
                    : memberCountryCorporation,
            email: '',
            ...initMemberData(data?.type_member_id === MEMBER_TYPES.INDIVIDUAL.id || false, {
                ...data,
                phoneCountry:
                    data?.type_member_id === MEMBER_TYPES.INDIVIDUAL.id
                        ? phoneCountry
                        : phoneCountryCorporation,
            }),
            ...(data.corporation_company_member_id
                ? {
                      number_allotted: !0,
                      own_percent: 0,
                  }
                : {
                      number_allotted: 0,
                      own_percent: 0,
                  }),
            business_activity_array: data?.BusinessActivities || [],
            business_activity_detail: '',
            country_operations: data?.CompanyMemberOperationCountries || [],

            number_allotted: data?.number_allotted || 0,
            own_percent: data?.own_percent || 0,
            // UBO
            percent_of_interest: data?.percent_of_interest || 0,
            phone_number: '',
            full_name: '',
            major: '',

            // Panama reference
            CompanyMemberReferences:
                orderCountryId === 169 && !data.corporation_company_member_id
                    ? [
                          // check is Panama && is member level 1
                          {
                              id:
                                  data?.CompanyMemberReferences[0]?.id ||
                                  EmptyMemberCommon.references[0].id,
                              name:
                                  data?.CompanyMemberReferences[0]?.name ||
                                  EmptyMemberCommon.references[0].name,
                              phone_number:
                                  data?.CompanyMemberReferences[0]?.phone_number ||
                                  EmptyMemberCommon.references[0].phone_number,
                              phone_country_id:
                                  data?.CompanyMemberReferences[0]?.phone_country_id ||
                                  EmptyMemberCommon.references[0].phone_country_id,
                              fax:
                                  data?.CompanyMemberReferences[0]?.fax ||
                                  EmptyMemberCommon.references[0].fax,
                              email:
                                  data?.CompanyMemberReferences[0]?.email ||
                                  EmptyMemberCommon.references[0].email,
                              address:
                                  data?.CompanyMemberReferences[0]?.address ||
                                  EmptyMemberCommon.references[0].address,
                              company_member_id:
                                  data?.CompanyMemberReferences[0]?.company_member_id ||
                                  EmptyMemberCommon.references[0].company_member_id,
                              company_member_issuse_reference_id:
                                  data?.CompanyMemberReferences[0]
                                      ?.company_member_issuse_reference_id ||
                                  EmptyMemberCommon.references[0]
                                      .company_member_issuse_reference_id,
                          },
                          {
                              id:
                                  data?.CompanyMemberReferences[1]?.id ||
                                  EmptyMemberCommon.references[1].id,
                              name:
                                  data?.CompanyMemberReferences[1]?.name ||
                                  EmptyMemberCommon.references[1].name,
                              phone_number:
                                  data?.CompanyMemberReferences[1]?.phone_number ||
                                  EmptyMemberCommon.references[1].phone_number,
                              phone_country_id:
                                  data?.CompanyMemberReferences[1]?.phone_country_id ||
                                  EmptyMemberCommon.references[1].phone_country_id,
                              fax:
                                  data?.CompanyMemberReferences[1]?.fax ||
                                  EmptyMemberCommon.references[1].fax,
                              email:
                                  data?.CompanyMemberReferences[1]?.email ||
                                  EmptyMemberCommon.references[1].email,
                              address:
                                  data?.CompanyMemberReferences[1]?.address ||
                                  EmptyMemberCommon.references[1].address,
                              company_member_id:
                                  data?.CompanyMemberReferences[1]?.company_member_id ||
                                  EmptyMemberCommon.references[1].company_member_id,
                              company_member_issuse_reference_id:
                                  data?.CompanyMemberReferences[1]
                                      ?.company_member_issuse_reference_id ||
                                  EmptyMemberCommon.references[1]
                                      .company_member_issuse_reference_id,
                          },
                      ]
                    : undefined,
        };

        // xử lý switch type member => back về lại vẫn giữ nguyên thông tin
        if ((formik?.values?.type_member ? 1 : 2) !== formik?.values?.preData?.type_member_id) {
            // nếu type khác type ban đầu thì clear data member
            formik.resetForm({
                values: {
                    ...emptyMember,
                },
            });
        } else {
            // nếu type ban đầu thì giữ nguyên thông tin init member
            formik.resetForm({
                values: initialValues,
            });
        }
    }, [formik?.values?.type_member]);

    const getBusinessActivityOfCountry = (countryId) => {
        if (!countryId) return [];
        if (businessActivityWithCountry) {
            if (countryId === CAYMAN_ID) {
                if (businessActivityWithCountry.caymanList.length) {
                    return businessActivityWithCountry.caymanList;
                }
            }
            if (countryId === UK_ID) {
                if (businessActivityWithCountry.ukList.length) {
                    return businessActivityWithCountry.ukList;
                }
            }
            if (countryId === RAK_ID) {
                if (businessActivityWithCountry.rakList.length) {
                    return businessActivityWithCountry.rakList;
                }
            }

            if (![CAYMAN_ID, UK_ID, RAK_ID].includes(countryId)) {
                if (businessActivityWithCountry.normalList.length) {
                    return businessActivityWithCountry.normalList;
                }
            }
        } else {
            return [];
        }
    };

    const deleteMemberFiles = () =>
        new Promise((resolve, reject) => {
            if (deletedFiles.length > 0) {
                let PromisedRemove = [];
                deletedFiles.forEach((item) => {
                    PromisedRemove.push(ActionsFile.deleteFileRequest(item));
                });
                Promise.all(PromisedRemove)
                    .then((removedFiles) => {
                        setDeletedFiles([]);
                        resolve(deletedFiles);
                    })
                    .catch((error) => {
                        setDeletedFiles([]);
                        if (deletedFiles.length > 0) {
                            let originFile = [...viewedFiles];
                            deletedFiles.forEach((item) => originFile.push(item));
                            setViewedFiles(originFile);
                        }
                        reject(error);
                    });
                // .finally(() => {
                //     resolve();
                // });
            } else {
                resolve();
            }
        });

    const uploadFiles = (memberId) =>
        new Promise((resolve, reject) => {
            if (addedFiles.length > 0) {
                const body = addedFiles.map((item) => {
                    const fileType = item.type === '' ? '.' + getExtension(item.name) : item.type;
                    return { filename: item.name, content_type: fileType };
                });

                dispatch(ActionsFile.genSignedUrlS3(body)).then((res) => {
                    if (res && res.data && res.data.length > 0) {
                        let Promised = [];
                        let PromisedBucket = [];

                        res.data.forEach((item, index) => {
                            PromisedBucket.push(
                                dispatch(ActionsFile.uploadS3(item, addedFiles[index])),
                            );
                        });

                        Promise.all(PromisedBucket)
                            .then(() => {
                                res.data.forEach((item, index) => {
                                    const fileType =
                                        addedFiles[index].type === ''
                                            ? '.' + getExtension(addedFiles[index].name)
                                            : addedFiles[index].type;
                                    let param = {
                                        member: {
                                            company_member_id: memberId,
                                            name: addedFiles[index].name,
                                            mime_type: fileType,
                                            s3_path: item.filename,
                                            task_id: orderInfo?.Task?.id ?? undefined
                                        },
                                    };
                                    Promised.push(dispatch(ActionsFile.createFilesDbS3(param)));
                                });
                                Promise.all(Promised)
                                    .then((uploadedFiles) => {
                                        setAddedFiles([]);
                                        resolve(uploadedFiles);
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    }
                });
                // .catch((error) => {
                //     reject(error);
                // })
                // .finally(() => {
                //     resolve();
                // });
            } else {
                resolve();
            }
        });

    const handleAdd = (values) => {
        let memberCase = {};
        let shareholderCase = {};
        let uboCase = {};
        let companyPositionsCase = { CompanyPositions: [] };

        if (values.type_member) {
            memberCase = {
                first_name: hasShowSelectFullName // các quốc gia không có first name && middle name && last name => cho first name = full name
                    ? values?.first_name || ''
                    : values?.full_name || '',
                middle_name: values?.middle_name || '',
                last_name: values?.last_name || '',
                full_name: values?.full_name || '',
                major: values.major,
                phone_country_id: values.phone_country.id,
                phone_number: values.phone_number,
            };
            isContactPerson && delete memberCase.major;
        } else {
            const objPhoneNumber =
                values.phone_number && values.phone_number !== ''
                    ? { phone_number: values.phone_number }
                    : {};
            memberCase = {
                corporation_name: values.corporation_name,
                corporation_number: values.corporation_number,
                corporation_date: new Date(values.corporation_date),
                address: values.address,
                phone_country_id: values.phone_country.id,
                business_activity_array: values?.business_activity_array?.length
                    ? values.business_activity_array.map((business) => business?.id)
                    : [],
                business_activity_detail: values?.business_activity_detail ?? '',
                OperationCountries: values?.country_operations?.length
                    ? values.country_operations.map((country) => country?.id)
                    : [],
                ...objPhoneNumber,
            };
        }
        if (data.company_member_type_id === COMPANY_MEMBER_TYPES.SHAREHOLDER.id && data.parent_id) {
            if (isPercent) {
                shareholderCase = {
                    own_percent: values.own_percent,
                };
            } else {
                shareholderCase = {
                    number_allotted: values.number_allotted,
                };
            }
        }
        if (data.company_member_type_id === COMPANY_MEMBER_TYPES.UBO.id) {
            uboCase = { percent_of_interest: values.percent_of_interest };
        }
        companyPositionsCase.CompanyPositions.push({
            company_member_type_id: values.preData.company_member_type_id,
            corporation_company_member_id: values.preData.corporation_company_member_id,
            ...shareholderCase,
            ...uboCase,
        });
        const template = {
            companyStructureCreate: [
                {
                    // Individual + Corporation
                    ...companyPositionsCase,
                    country_id: values.country.id,
                    email: values.email,
                    type_member_id: values.type_member
                        ? MEMBER_TYPES.INDIVIDUAL.id
                        : MEMBER_TYPES.CORPORATION.id,
                    ...memberCase,
                },
            ],
        };

        addGraphStructure(template, orderInfo).then(({ preMembers, newMembers }) => {
            let newTemps = [...newMembers];
            setFileLoading(true);
            preMembers.forEach((preMem) => {
                let checker = {};
                for (const temp of newTemps) {
                    if (temp.id === preMem.id) {
                        checker = temp;
                        break;
                    }
                }
                newTemps = [...newTemps.filter((el) => el.id !== checker.id)];
            });
            const newMember = newTemps[0] || {};
            uploadFiles(newMember.id)
                .then((uploadedFiles) => {
                    newMember.Files = uploadedFiles;
                    const newOrderInfo = JSON.parse(JSON.stringify(orderInfo));
                    newOrderInfo.Company.CompanyMembers.push(newMember);
                    dispatch(updateCompanyMemberList(newOrderInfo));
                    setGraphElement(null);
                    setGraphElement(newOrderInfo);
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Successful!',
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(() => {
                        closeModal();
                    });
                })
                .catch((_) => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Somethings went wrong!',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                })
                .finally(() => {
                    setFileLoading(false);
                });
        });
    };

    const handleUpdate = (values) => {
        setFileLoading(true);
        deleteMemberFiles()
            .then(() => {
                uploadFiles(values.preData.id)
                    .then(() => {
                        let memberCase = {};
                        let shareholderCase = {};
                        let uboCase = {};
                        let companyPositionsCase = {};
                        let isCloseModal = false;
                        if (values.type_member) {
                            memberCase = {
                                first_name: hasShowSelectFullName // các quốc gia không có first name && middle name && last name => cho first name = full name
                                    ? values?.first_name || ''
                                    : values?.full_name || '',
                                middle_name: values?.middle_name || '',
                                last_name: values?.last_name || '',
                                full_name: values?.full_name || '',
                                major: values.major,
                                phone_country_id: values.phone_country.id,
                                phone_number: values.phone_number,
                            };
                            isContactPerson && delete memberCase.major;
                        } else {
                            const objPhoneNumber =
                                values.phone_number && values.phone_number !== ''
                                    ? { phone_number: values.phone_number }
                                    : {};
                            memberCase = {
                                corporation_name: values.corporation_name,
                                corporation_number: values.corporation_number,
                                corporation_date: new Date(values.corporation_date),
                                address: values.address,
                                phone_country_id: values.phone_country.id,
                                business_activity_array: values?.business_activity_array?.length
                                    ? values.business_activity_array.map((business) => business?.id)
                                    : [],
                                business_activity_detail: values?.business_activity_detail ?? '',
                                OperationCountries: values?.country_operations?.length
                                    ? values.country_operations.map((country) => country?.id)
                                    : [],
                                ...objPhoneNumber,
                            };
                        }
                        if (
                            data.company_member_type_id === COMPANY_MEMBER_TYPES.SHAREHOLDER.id &&
                            data.corporation_company_member_id
                        ) {
                            if (isPercent) {
                                shareholderCase = {
                                    own_percent: values.own_percent,
                                    number_allotted: 0,
                                };
                            } else {
                                shareholderCase = {
                                    own_percent: 0,
                                    number_allotted: values.number_allotted,
                                };
                            }
                        }
                        if (data.company_member_type_id === COMPANY_MEMBER_TYPES.UBO.id) {
                            uboCase = { percent_of_interest: values.percent_of_interest };
                        }
                        if (
                            values.preData.CompanyPositions &&
                            values.preData.CompanyPositions.length > 0
                        ) {
                            companyPositionsCase = { CompanyPositions: [] };
                            if (!values.preData.pos_id) {
                                isCloseModal = true;
                                companyPositionsCase.CompanyPositions.push({
                                    company_member_type_id: values.preData.company_member_type_id,
                                    corporation_company_member_id:
                                        values.preData.corporation_company_member_id,
                                    ...(Number(values.is_contact_person) === 1 && {
                                        is_contact_person: 1,
                                    }),
                                    number_allotted: values?.number_allotted || 0,
                                    own_percent: values?.own_percent || 0,
                                    percent_of_interest: values?.percent_of_interest || 0,
                                    ...shareholderCase,
                                    ...uboCase,
                                });
                            } else {
                                values.preData.CompanyPositions.forEach((position) => {
                                    if (position.id === values.preData.pos_id) {
                                        companyPositionsCase.CompanyPositions.push({
                                            id: position.id,
                                            ...(Number(values.is_contact_person) === 1 && {
                                                is_contact_person: 1,
                                            }),
                                            number_allotted: values?.number_allotted || 0,
                                            own_percent: values?.own_percent || 0,
                                            percent_of_interest: values?.percent_of_interest || 0,
                                            ...shareholderCase,
                                            ...uboCase,
                                        });
                                    }
                                });
                            }
                        }
                        let template = {
                            companyStructureUpdate: [
                                {
                                    // Individual + Corporation
                                    id: values.preData.id,
                                    country_id: values.country.id,
                                    email: values.email,
                                    type_member_id: values.type_member
                                        ? MEMBER_TYPES.INDIVIDUAL.id
                                        : MEMBER_TYPES.CORPORATION.id,
                                    ...memberCase,
                                    ...companyPositionsCase,
                                },
                            ],
                            companyStructureCreate: [],
                            companyStructureDelete: { company_position_ids: [] },
                        };

                        if (!values?.type_member) {
                            // Corporation
                            const currentMemberPositions = values?.preData?.CompanyPositions;
                            const uboPositionIds = currentMemberPositions
                                ?.filter((position) => position?.company_member_type_id === 3)
                                ?.map((position) => position?.id);
                            const oldMember = values?.preData;
                            const hasContactMember = currentMemberPositions?.some(
                                (position) => position?.company_member_type_id === 7,
                            );

                            let currentCompanyStructureCreate = cloneDeep(
                                template.companyStructureUpdate,
                            );
                            currentCompanyStructureCreate[0].CompanyPositions[0].company_member_type_id =
                                values?.preData?.company_member_type_id;

                            delete currentCompanyStructureCreate[0].id;
                            delete currentCompanyStructureCreate[0].CompanyPositions[0].id;
                            delete currentCompanyStructureCreate[0].CompanyPositions[0]
                                .is_contact_person;

                            if (
                                hasContactMember &&
                                oldMember.type_member_id !== (values?.type_member ? 1 : 2)
                            ) {
                                template.companyStructureDelete.company_position_ids = uniq([
                                    ...(template?.companyStructureDelete?.company_position_ids ||
                                        []),
                                    oldMember?.pos_id,
                                ]);

                                template.companyStructureUpdate =
                                    template.companyStructureUpdate.filter(
                                        (member) => member?.id !== oldMember?.id,
                                    );
                            }

                            // xóa tất cả position UBO nếu type member là Corporation
                            if (uboPositionIds.length) {
                                template.companyStructureDelete.company_position_ids = uniq([
                                    ...(template?.companyStructureDelete?.company_position_ids ||
                                        []),
                                    ...uboPositionIds,
                                ]);
                            }

                            if (oldMember?.level !== 1) {
                                const corporationCompanyMemberId = currentMemberPositions?.find(
                                    (position) => position?.id === oldMember?.pos_id,
                                )?.corporation_company_member_id;
                                if (corporationCompanyMemberId) {
                                    currentCompanyStructureCreate[0].CompanyPositions[0].corporation_company_member_id =
                                        corporationCompanyMemberId;
                                }
                            }

                            if (
                                hasContactMember &&
                                oldMember.type_member_id !== (values?.type_member ? 1 : 2)
                            ) {
                                template.companyStructureCreate = currentCompanyStructureCreate;
                            }
                        }

                        updateGraphStructure(template, orderInfo, isCloseModal).then(
                            ({ preMembers, newMembers }) => {
                                const newOrderInfo = JSON.parse(JSON.stringify(orderInfo));
                                newOrderInfo.Company.CompanyMembers = newMembers;
                                dispatch(updateCompanyMemberList(newOrderInfo));

                                // update info member in preview-modal
                                const memberUpdate = formatCompanyMembers({
                                    Company: newOrderInfo.Company,
                                });
                                const contactInfoTemp = {
                                    ...body.contactInfo,
                                    email: memberUpdate?.ContactPerson?.[0]?.email || '',
                                    first_name: memberUpdate?.ContactPerson?.[0]?.full_name || '',
                                    phone_country_id: memberUpdate?.ContactPerson?.[0]?.phone_code,
                                    phone_number: memberUpdate?.ContactPerson?.[0]?.phone || '',
                                };
                                setBody({
                                    ...body,
                                    contactInfo: contactInfoTemp,
                                    companyStructure: { ...memberUpdate },
                                });
                            },
                        );
                    })
                    .catch(() => {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Somethings went wrong!',
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        setFileLoading(false);
                    })
                    .finally(() => {
                        setFileLoading(false);
                    });
            })
            .catch(() => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Somethings went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                });
                setFileLoading(false);
            });
    };

    const isSaveDisable = () => {
        if (addedFiles && addedFiles.length > 0) {
            return false;
        }
        if (deletedFiles && deletedFiles.length > 0) {
            return false;
        }
        if (!data || !data.id) {
            // add new kyc node
            return false;
        }
        if (formik && formik.values && data && data.id) {
            const values = formik.values;
            // Individual + Corporation
            if (values.type_member !== (data.type_member_id === MEMBER_TYPES.INDIVIDUAL.id)) {
                return false;
            }
            if (values.country.id !== data.country_id) {
                return false;
            }
            if (values.email !== data.email) {
                return false;
            }
            if (values.phone_country.id !== data.phone_country_id) {
                return false;
            }
            if (values.phone_number !== `${data?.phone_number || ''}`) {
                return false;
            }
            // Individual
            if (values.type_member) {
                // if (values.full_name !== `${data?.first_name || ''}`) {
                if (values.full_name !== `${data?.full_name || ''}`) {
                    return false;
                }
                if (values.major !== `${data?.major || ''}`) {
                    return false;
                }
                if (hasShowSelectFullName) {
                    if (values.first_name !== `${data?.first_name || ''}`) {
                        return false;
                    }

                    if (values.last_name !== `${data?.last_name || ''}`) {
                        return false;
                    }
                }
            }
            // Corporation
            if (!values.type_member) {
                const valueBusinessActivity =
                    values?.business_activity_array?.map((business) => business?.id) || [];
                const dataBusinessActivity =
                    data?.BusinessActivities?.map((business) => business?.id) || [];

                const valueCountryOperation =
                    values?.country_operations?.map((country) => country?.id) || [];
                const dataCountryOperation =
                    data?.CompanyMemberOperationCountries?.map((country) => country?.id) || [];

                if (values.corporation_name !== `${data?.corporation_name || ''}`) {
                    return false;
                }
                if (values.corporation_number !== `${data?.corporation_number || ''}`) {
                    return false;
                }
                if (values.address !== `${data.address || ''}`) {
                    return false;
                }
                if (values.corporation_date !== `${data?.corporation_date || ''}`) {
                    return false;
                }
                if (
                    valueBusinessActivity?.sort().join(',') !==
                    dataBusinessActivity?.sort().join(',')
                ) {
                    return false;
                }
                if (
                    valueCountryOperation?.sort().join(',') !==
                    dataCountryOperation?.sort().join(',')
                ) {
                    return false;
                }
                if (
                    values?.business_activity_detail !== `${data?.business_activity_detail || ''}`
                ) {
                    return false;
                }
            }
            // Shareholder
            if (`${values.number_allotted}` !== `${data.number_allotted || 0}`) {
                return false;
            }
            if (`${values.own_percent}` !== `${data.own_percent || 0}`) {
                return false;
            }
            // UBO
            if (`${values.percent_of_interest}` !== `${data.percent_of_interest || 0}`) {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {
        setIsInValid(viewedFiles.find(item => item.isValid === false));
    }, [viewedFiles]);

    return (
        <div className="container">
            {data && !data.id && (
                <div className="row mb-5">
                    <div className="col-12 col-sm-12 col-lg-6">
                        {/* <ExistingMemberList data={data} formik={formik} isLLCType={checkIsLLC} initMemberData={initMemberData} memberCountry={memberCountry} phoneCountry={phoneCountry} setViewedFiles={setViewedFiles} /> */}
                        <ExistingMemberList
                            data={data}
                            formik={formik}
                            isLLCType={checkIsLLC}
                            initMemberData={initMemberData}
                            setViewedFiles={setViewedFiles}
                            orderInfo={orderInfo}
                        />
                    </div>
                </div>
            )}

            <form onSubmit={formik.handleSubmit}>
                {/* {data?.company_member_type_id !== COMPANY_MEMBER_TYPES.UBO.id ? ( */}
                {![COMPANY_MEMBER_TYPES.UBO.id, COMPANY_MEMBER_TYPES.CONTACT_PERSON.id].includes(
                    data?.company_member_type_id,
                ) ? (
                    <div className="d-flex">
                        Corporation{' '}
                        <AntSwitch
                            id="type_member"
                            name="type_member"
                            checked={formik.values.type_member}
                            onChange={formik.handleChange}
                            disabled={
                                //isLevelOneCloned(formik.values.preData) ||
                                graphLoading || fileLoading || isBankingDisabled
                            }
                        />{' '}
                        Individual
                    </div>
                ) : (
                    <></>
                )}
                <div className="row">
                    {formik.values.type_member && hasShowSelectFullName && (
                        <>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 kyc-frm-control">
                                <TextField
                                    fullWidth
                                    id="first_name"
                                    name="first_name"
                                    label={`Enter your first name`}
                                    placeholder={`Your first name`}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={
                                        //isLevelOneCloned(formik.values.preData) ||
                                        graphLoading || fileLoading || isBankingDisabled
                                    }
                                    value={formik.values?.first_name || ''}
                                    onChange={(e) => {
                                        formik.setFieldValue('first_name', e.target.value);
                                        if (formik.values.full_name) {
                                            formik.setFieldValue(`full_name`, '');
                                        }
                                    }}
                                    error={
                                        formik.touched.first_name &&
                                        Boolean(formik.errors.first_name)
                                    }
                                    helperText={
                                        formik.touched.first_name && formik.errors.first_name
                                    }
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 kyc-frm-control">
                                <TextField
                                    fullWidth
                                    id="middle_name"
                                    name="middle_name"
                                    label={`Enter your middle name`}
                                    placeholder={`Your middle name`}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={
                                        //isLevelOneCloned(formik.values.preData) ||
                                        graphLoading || fileLoading || isBankingDisabled
                                    }
                                    value={formik.values?.middle_name || ''}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.middle_name &&
                                        Boolean(formik.errors.middle_name)
                                    }
                                    helperText={
                                        formik.touched.middle_name && formik.errors.middle_name
                                    }
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 kyc-frm-control">
                                <TextField
                                    fullWidth
                                    id="last_name"
                                    name="last_name"
                                    label={`Enter your last name`}
                                    placeholder={`Your last name`}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={
                                        //isLevelOneCloned(formik.values.preData) ||
                                        graphLoading || fileLoading || isBankingDisabled
                                    }
                                    value={formik.values?.last_name || ''}
                                    onChange={(e) => {
                                        formik.setFieldValue('last_name', e.target.value);
                                        if (formik.values.full_name) {
                                            formik.setFieldValue(`full_name`, '');
                                        }
                                    }}
                                    error={
                                        formik.touched.last_name && Boolean(formik.errors.last_name)
                                    }
                                    helperText={formik.touched.last_name && formik.errors.last_name}
                                />
                            </div>
                        </>
                    )}
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                        {formik.values.type_member ? (
                            <>
                                <TextField
                                    fullWidth
                                    id="full_name"
                                    name="full_name"
                                    label={`Enter your full name`}
                                    placeholder={`Your name`}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={
                                        //isLevelOneCloned(formik.values.preData) ||
                                        graphLoading ||
                                        fileLoading ||
                                        isBankingDisabled ||
                                        hasShowSelectFullName
                                    }
                                    value={formik.values.full_name}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.full_name && Boolean(formik.errors.full_name)
                                    }
                                    helperText={formik.touched.full_name && formik.errors.full_name}
                                    multiline={hasShowSelectFullName}
                                />
                            </>
                        ) : (
                            <TextField
                                fullWidth
                                id="corporation_name"
                                name="corporation_name"
                                label={`Enter your company name`}
                                placeholder={`Company name`}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={
                                    //isLevelOneCloned(formik.values.preData) ||
                                    graphLoading || fileLoading || isBankingDisabled
                                }
                                value={formik.values.corporation_name}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.corporation_name &&
                                    Boolean(formik.errors.corporation_name)
                                }
                                helperText={
                                    formik.touched.corporation_name &&
                                    formik.errors.corporation_name
                                }
                            />
                        )}
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                        {formik.values.type_member ? (
                            !isContactPerson && (
                                <TextField
                                    fullWidth
                                    id="major"
                                    name="major"
                                    label="Your Occupation"
                                    placeholder="Occupation"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={
                                        //isLevelOneCloned(formik.values.preData) &&
                                        // !formik.values.is_contact_person ||
                                        graphLoading || fileLoading || isBankingDisabled
                                    }
                                    value={formik.values.major}
                                    onChange={formik.handleChange}
                                    error={formik.touched.major && Boolean(formik.errors.major)}
                                    helperText={formik.touched.major && formik.errors.major}
                                />
                            )
                        ) : (
                            <TextField
                                fullWidth
                                id="corporation_number"
                                name="corporation_number"
                                label="Company number"
                                placeholder="Enter your company number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={
                                    //isLevelOneCloned(formik.values.preData) ||
                                    graphLoading || fileLoading || isBankingDisabled
                                }
                                value={formik.values.corporation_number}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.corporation_number &&
                                    Boolean(formik.errors.corporation_number)
                                }
                                helperText={
                                    formik.touched.corporation_number &&
                                    formik.errors.corporation_number
                                }
                            />
                        )}
                    </div>
                </div>
                <div className="col-12 kyc-frm-control p-0">
                    {formik.values.type_member && hasShowSelectFullName && (
                        <SelectFullNameHintFormik
                            formik={formik}
                            fullNameField={'full_name'}
                            firstNameField={'first_name'}
                            middleNameField={'middle_name'}
                            lastNameField={'last_name'}
                            disabled={isBankingDisabled}
                            loading={graphLoading || fileLoading}
                        />
                    )}
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                        {formik.values.type_member ? (
                            <TextField
                                fullWidth
                                type="text"
                                id="phone_number"
                                name="phone_number"
                                label="Your phone number"
                                placeholder="Phone number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <Autocomplete
                                            id="phone_country"
                                            name="phone_country"
                                            disableClearable
                                            options={countriesCooperation || []}
                                            getOptionLabel={(option) =>
                                                `${option.country_code} +${option.phone_code}`
                                            }
                                            value={formik.values.phone_country}
                                            onChange={(_, value) =>
                                                formik.setFieldValue('phone_country', value)
                                            }
                                            onBlur={() =>
                                                formik.setTouched({ phone_country: true })
                                            }
                                            disabled={
                                                //isLevelOneCloned(formik.values.preData) ||
                                                graphLoading || fileLoading || isBankingDisabled
                                            }
                                            renderInput={(params) => (
                                                <SelectCode
                                                    {...params}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={
                                                        formik.touched.phone_country &&
                                                        Boolean(formik.errors.phone_country)
                                                    }
                                                    helperText={
                                                        formik.touched.phone_country &&
                                                        formik.errors.phone_country
                                                    }
                                                />
                                            )}
                                        />
                                    ),
                                }}
                                disabled={
                                    //isLevelOneCloned(formik.values.preData) ||
                                    graphLoading || fileLoading || isBankingDisabled
                                }
                                value={formik.values.phone_number}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.phone_number &&
                                    Boolean(formik.errors.phone_number)
                                }
                                helperText={
                                    formik.touched.phone_number && formik.errors.phone_number
                                }
                            />
                        ) : (
                            <Autocomplete
                                id="country"
                                name="country"
                                disableClearable
                                options={countries || []}
                                getOptionLabel={(option) => option.name}
                                value={formik.values.country}
                                onChange={(_, value) => {
                                    formik.setFieldValue('country', value);
                                    formik.setFieldValue('business_activity_array', []);
                                }}
                                onBlur={() => formik.setTouched({ country: true })}
                                disabled={
                                    //isLevelOneCloned(formik.values.preData) ||
                                    graphLoading || fileLoading || isBankingDisabled
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Country of Incorporation"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={
                                            formik.touched.country && Boolean(formik.errors.country)
                                        }
                                        helperText={formik.touched.country && formik.errors.country}
                                    />
                                )}
                            />
                        )}
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                        {formik.values.type_member ? (
                            <TextField
                                fullWidth
                                id="email"
                                name="email"
                                type="email"
                                label="Your Email address"
                                placeholder="Email address"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={
                                    //isLevelOneCloned(formik.values.preData) ||
                                    graphLoading || fileLoading || isBankingDisabled
                                }
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        ) : (
                            <TextField
                                fullWidth
                                id="address"
                                name="address"
                                label="Registered address"
                                placeholder="Enter address"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={
                                    //isLevelOneCloned(formik.values.preData) ||
                                    graphLoading || fileLoading || isBankingDisabled
                                }
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                helperText={formik.touched.address && formik.errors.address}
                            />
                        )}
                    </div>
                </div>

                {/* ----------------NEW */}
                <div className="row">
                    {formik.values.type_member ? (
                        <></>
                    ) : (
                        <>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 kyc-frm-control">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                                        <Autocomplete
                                            multiple
                                            id="business_activity_array"
                                            name="business_activity_array"
                                            disableClearable
                                            options={
                                                getBusinessActivityOfCountry(
                                                    formik?.values?.country?.id,
                                                ) || []
                                            }
                                            getOptionLabel={(option) => option.description}
                                            value={formik.values.business_activity_array}
                                            onChange={(_, value) => {
                                                const set = new Set();

                                                if (Array.isArray(value)) {
                                                    const isDuplicate = value.some(
                                                        (obj) =>
                                                            set.size ===
                                                            (set.add(obj.id), set.size),
                                                    );
                                                    if (isDuplicate) {
                                                        Swal.fire({
                                                            icon: 'warning',
                                                            title: 'Warning!',
                                                            text: 'This business activity is already selected!',
                                                            confirmButtonColor: '#17c191',
                                                        });
                                                        return;
                                                    }
                                                    formik.setFieldValue(
                                                        'business_activity_array',
                                                        value,
                                                    );
                                                }
                                            }}
                                            onBlur={() => formik.setTouched({ country: true })}
                                            disabled={
                                                //isLevelOneCloned(formik.values.preData) ||
                                                graphLoading || fileLoading || isBankingDisabled
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={
                                                        <Box display={'flex'}>
                                                            <Typography mr={1}>
                                                                Business activities
                                                            </Typography>
                                                            <Tooltip
                                                                title={
                                                                    'Please give specific business activities within 20-30 words. General description of business such "Trading company", "Holding company", or "Investment company" is not sufficient.'
                                                                }
                                                                placement={'top'}
                                                            >
                                                                <InfoIcon color={'primary'} />
                                                            </Tooltip>
                                                        </Box>
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={
                                                        formik.touched.business_activity_array &&
                                                        Boolean(
                                                            formik.errors.business_activity_array,
                                                        )
                                                    }
                                                    helperText={
                                                        formik.touched.business_activity_array &&
                                                        formik.errors.business_activity_array
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                                        <Autocomplete
                                            multiple
                                            id="country_operations"
                                            name="country_operations"
                                            disableClearable
                                            options={countries || []}
                                            getOptionLabel={(option) => option.name}
                                            value={formik.values.country_operations}
                                            onChange={(_, value) => {
                                                const set = new Set();

                                                if (Array.isArray(value)) {
                                                    const isDuplicate = value.some(
                                                        (obj) =>
                                                            set.size ===
                                                            (set.add(obj.id), set.size),
                                                    );
                                                    if (isDuplicate) {
                                                        Swal.fire({
                                                            icon: 'warning',
                                                            title: 'Warning!',
                                                            text: 'This country is already selected!',
                                                            confirmButtonColor: '#17c191',
                                                        });
                                                        return;
                                                    }
                                                    formik.setFieldValue(
                                                        'country_operations',
                                                        value,
                                                    );
                                                }
                                            }}
                                            onBlur={() => formik.setTouched({ country: true })}
                                            disabled={
                                                //isLevelOneCloned(formik.values.preData) ||
                                                graphLoading || fileLoading || isBankingDisabled
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    // label="Country/countries of operation"
                                                    label={
                                                        <Box display={'flex'}>
                                                            <Typography mr={1}>
                                                                Country/countries of operation
                                                            </Typography>
                                                            <Tooltip
                                                                title={
                                                                    'Please provide countries which your company will be managed/ controlled and countries of your partners'
                                                                }
                                                                placement={'top'}
                                                            >
                                                                <InfoIcon color={'primary'} />
                                                            </Tooltip>
                                                        </Box>
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={
                                                        formik.touched.country_operations &&
                                                        Boolean(formik.errors.country_operations)
                                                    }
                                                    helperText={
                                                        formik.touched.country_operations &&
                                                        formik.errors.country_operations
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            {formik.values.business_activity_array &&
                                Boolean(formik.values.business_activity_array.length) && (
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                                        <TextField
                                            fullWidth
                                            id="business_activity_detail"
                                            name="business_activity_detail"
                                            // label="Details of Business activities"
                                            label={
                                                <Box display={'flex'}>
                                                    <Typography mr={1}>
                                                        Details of Business activities
                                                    </Typography>
                                                    <Tooltip
                                                        title={
                                                            'Please describe your business activities in this field as details as possible to process your order smoothy'
                                                        }
                                                        placement={'top'}
                                                    >
                                                        <InfoIcon color={'primary'} />
                                                    </Tooltip>
                                                </Box>
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={
                                                //isLevelOneCloned(formik.values.preData) ||
                                                graphLoading || fileLoading || isBankingDisabled
                                            }
                                            value={formik.values.business_activity_detail}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.business_activity_detail &&
                                                Boolean(formik.errors.business_activity_detail)
                                            }
                                            helperText={
                                                formik.touched.business_activity_detail &&
                                                formik.errors.business_activity_detail
                                            }
                                            multiline
                                            maxRows={5}
                                        />
                                    </div>
                                )}
                        </>
                    )}
                </div>
                {/* -------------------- */}

                <div className="row">
                    {formik.values.type_member ? (
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                            <Autocomplete
                                id="country"
                                name="country"
                                disableClearable
                                options={countriesCooperation || []}
                                getOptionLabel={(option) => option.name}
                                value={formik.values.country}
                                onChange={(_, value) => formik.setFieldValue('country', value)}
                                onBlur={() => formik.setTouched({ country: true })}
                                disabled={
                                    //isLevelOneCloned(formik.values.preData) ||
                                    graphLoading || fileLoading || isBankingDisabled
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Nationality"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={
                                            formik.touched.country && Boolean(formik.errors.country)
                                        }
                                        helperText={formik.touched.country && formik.errors.country}
                                    />
                                )}
                            />
                        </div>
                    ) : (
                        <>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 kyc-frm-control">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            id="phone_number"
                                            name="phone_number"
                                            label="Your phone number"
                                            placeholder="Phone number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <Autocomplete
                                                        id="phone_country"
                                                        name="phone_country"
                                                        disableClearable
                                                        options={countries || []}
                                                        getOptionLabel={(option) =>
                                                            `${option.country_code} +${option.phone_code}`
                                                        }
                                                        value={formik.values.phone_country}
                                                        onChange={(_, value) =>
                                                            formik.setFieldValue(
                                                                'phone_country',
                                                                value,
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            formik.setTouched({
                                                                phone_country: true,
                                                            })
                                                        }
                                                        disabled={
                                                            //isLevelOneCloned(
                                                            //     formik.values.preData,
                                                            // ) ||
                                                            graphLoading ||
                                                            fileLoading ||
                                                            isBankingDisabled
                                                        }
                                                        renderInput={(params) => (
                                                            <SelectCode
                                                                {...params}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                error={
                                                                    formik.touched.phone_country &&
                                                                    Boolean(
                                                                        formik.errors.phone_country,
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik.touched.phone_country &&
                                                                    formik.errors.phone_country
                                                                }
                                                            />
                                                        )}
                                                    />
                                                ),
                                            }}
                                            disabled={
                                                //isLevelOneCloned(formik.values.preData) ||
                                                graphLoading || fileLoading || isBankingDisabled
                                            }
                                            value={formik.values.phone_number}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.phone_number &&
                                                Boolean(formik.errors.phone_number)
                                            }
                                            helperText={
                                                formik.touched.phone_number &&
                                                formik.errors.phone_number
                                            }
                                        />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                                        <TextField
                                            fullWidth
                                            id="email"
                                            name="email"
                                            type="email"
                                            label="Email Address of Designated Legal Representative"
                                            placeholder="Enter Email address"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={
                                                //isLevelOneCloned(formik.values.preData) ||
                                                graphLoading || fileLoading || isBankingDisabled
                                            }
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.email && Boolean(formik.errors.email)
                                            }
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 kyc-frm-control">
                                <TextField
                                    fullWidth
                                    id="corporation_date"
                                    label="Date of incorporation"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={
                                        //isLevelOneCloned(formik.values.preData) ||
                                        graphLoading || fileLoading || isBankingDisabled
                                    }
                                    value={formik.values.corporation_date.substring(0, 10)}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.corporation_date &&
                                        Boolean(formik.errors.corporation_date)
                                    }
                                    helperText={
                                        formik.touched.corporation_date &&
                                        formik.errors.corporation_date
                                    }
                                />
                            </div>
                        </>
                    )}
                </div>
                {data?.company_member_type_id === COMPANY_MEMBER_TYPES.SHAREHOLDER.id ? (
                    <div className="row">
                        {data && data.corporation_company_member_id ? (
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 kyc-frm-control">
                                <SwitchFormikShareholderCorp
                                    formik={formik}
                                    disabled={graphLoading || fileLoading || isBankingDisabled}
                                    checked={isPercent}
                                    setChecked={setIsPercent}
                                />
                            </div>
                        ) : (
                            <>
                                {checkIsLLC ? (
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-1 kyc-frm-control">
                                        <TextField
                                            fullWidth
                                            id="own_percent"
                                            name="own_percent"
                                            type="number"
                                            label="Percentage of ownership"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                endAdornment: <div style={{ width: 20 }}>%</div>,
                                            }}
                                            disabled={
                                                !data.corporation_company_member_id ||
                                                graphLoading ||
                                                fileLoading ||
                                                isBankingDisabled
                                            }
                                            value={formik.values.own_percent}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.own_percent &&
                                                Boolean(formik.errors.own_percent)
                                            }
                                            helperText={
                                                formik.touched.own_percent &&
                                                formik.errors.own_percent
                                            }
                                        />
                                    </div>
                                ) : (
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 kyc-frm-control">
                                        <TextField
                                            fullWidth
                                            id="number_allotted"
                                            name="number_allotted"
                                            type="number"
                                            label="Number of shares to be alloted"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <div style={{ width: 70 }}>shares</div>
                                                ),
                                            }}
                                            disabled={
                                                //isLevelOneCloned(formik.values.preData) ||
                                                graphLoading || fileLoading || isBankingDisabled
                                            }
                                            value={formik.values.number_allotted}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.number_allotted &&
                                                Boolean(formik.errors.number_allotted)
                                            }
                                            helperText={
                                                formik.touched.number_allotted &&
                                                formik.errors.number_allotted
                                            }
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                ) : (
                    <></>
                )}
                <div className="row">
                    {data?.company_member_type_id === COMPANY_MEMBER_TYPES.UBO.id &&
                    formik.values.type_member ? (
                        <>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-1 kyc-frm-control">
                                <TextField
                                    fullWidth
                                    id="percent_of_interest"
                                    name="percent_of_interest"
                                    type="number"
                                    label="Percentage of interest"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: <div>%</div>,
                                    }}
                                    inputProps={{
                                        min: '0',
                                        // max:
                                        //     100 -
                                        //     uboChecker.percentage +
                                        //     uboChecker.percentageCurrent,
                                    }}
                                    disabled={
                                        !data.corporation_company_member_id ||
                                        graphLoading ||
                                        fileLoading ||
                                        isBankingDisabled
                                    }
                                    value={formik.values.percent_of_interest}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.percent_of_interest &&
                                        Boolean(formik.errors.percent_of_interest)
                                    }
                                    helperText={
                                        formik.touched.percent_of_interest &&
                                        formik.errors.percent_of_interest
                                    }
                                />
                            </div>
                            {/* <div className="col-12 text-danger">
                                {uboChecker.percentage -
                                    uboChecker.percentageCurrent +
                                    Number(formik.values.percentage_interest) ===
                                100
                                    ? ''
                                    : uboChecker.text}
                            </div> */}
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                {
                    // Panama Reference: Check is Panama && is member level 1
                    !isContactPerson &&
                        orderCountryId === 169 &&
                        !data.corporation_company_member_id &&
                        data.CompanyMemberReferences && (
                            <div className="reference mb-5">
                                <hr style={{ border: '1px dashed #ebedf3' }} />
                                <div className="fw-500 mt-7 mb-3">Reference 1:</div>
                                <ReferenceItem data={data} formik={formik} referenceIndex={0} />

                                <div className="fw-500 mt-7 mb-3">Reference 2:</div>
                                <ReferenceItem data={data} formik={formik} referenceIndex={1} />
                            </div>
                        )
                }
                <div className="row">
                    <FileUpload
                        data={data}
                        addedFiles={addedFiles}
                        deletedFiles={deletedFiles}
                        setAddedFiles={setAddedFiles}
                        setDeletedFiles={setDeletedFiles}
                        viewedFiles={viewedFiles}
                        setViewedFiles={setViewedFiles}
                        disabled={graphLoading || fileLoading}
                        isBankingDisabled={isBankingDisabled}
                    />
                    <div className="col-lg-6 kyc-frm-control" style={{
                        height: '222px', // cùng height với khối upload
                        overflowY: 'auto'
                    }}>
                        {formik.values.type_member ? (
                            <IndividualItemList
                                country_id={orderCountryId}
                                parent_id={data?.corporation_company_member_id}
                                flow={flow}
                                bankingProcess={orderInfo?.Task?.BankingProcesses?.[0] || {}}
                            />
                        ) : (
                            <CorporationItemList
                                country_id={orderCountryId}
                                flow={flow}
                                bankingProcess={orderInfo?.Task?.BankingProcesses?.[0] || {}}
                            />
                        )}
                    </div>
                    <div className="col-12 kyc-frm-control kyc-button-list d-flex align-items-center">
                        <Button
                            variant="contained"
                            onClick={closeModal}
                            disabled={graphLoading || fileLoading}
                        >
                            CLOSE
                        </Button>

                        <Button
                            className="text-white"
                            variant="contained"
                            color="primary"
                            type="submit"
                            // onClick={() => formik.setTouched({business_activity_array: true})}
                            onClick={() => {
                                if (!formik?.values?.type_member) {
                                    // Corporation
                                    if (!formik?.values?.business_activity_array?.length) {
                                        formik
                                            .setFieldValue('business_activity_array', [])
                                            .then(() => {
                                                formik.setFieldTouched('business_activity_array');
                                            });
                                    }
                                    if (!formik?.values?.country_operations?.length) {
                                        formik.setFieldValue('country_operations', []).then(() => {
                                            formik.setFieldTouched('country_operations');
                                        });
                                    }
                                } else {
                                    // Individual
                                    if (!formik?.values?.first_name) {
                                        formik.setFieldValue('first_name', '').then(() => {
                                            formik.setFieldTouched('first_name');
                                        });
                                    }

                                    if (!formik?.values?.last_name) {
                                        formik.setFieldValue('last_name', '').then(() => {
                                            formik.setFieldTouched('last_name');
                                        });
                                    }
                                }
                            }}
                            disabled={graphLoading || fileLoading || isSaveDisable() || isInValid}
                        >
                            SAVE
                        </Button>
                        {graphLoading || fileLoading ? (
                            <CircularProgress size={20} color="primary" />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default DetailForm;

import React from 'react';

const IMAGE_DEFAULT_SRC = "/media/bank-process/waiting/waiting.svg";

/**
 * use default: <ProcessWaiting />
 * use with param: <ProcessWaiting imageSrc={image src value} />
*/

export function ProcessWaiting({imageSrc}) {
    return (
        <>
       
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ minHeight: imageSrc ? 'unset' : 470 }}
            >
                <img
                    src={imageSrc || IMAGE_DEFAULT_SRC}
                    className="img-fluid"
                    alt="Waiting"
                />
            </div>
        </>
    )
}
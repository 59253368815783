import React from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import { Button } from '@mui/material';

function ApplicationFormsContent({onClickSheet, s3Path}) {
    // console.log(s3Path);
    return (
        <>
            <h6 className={'pb-3'}>Application forms - Personal information sheet</h6>

            <Button variant='outlined' onClick={() => onClickSheet && onClickSheet({s3_path: s3Path})}>
                <DescriptionIcon color={'action'} />
                <span className={'ml-2'}>Corporate account application form</span>
            </Button>
            <br/><br/>
        </>
    );
}

export default ApplicationFormsContent;

import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { MAX_SCALE, JUMP_SCALE, MIN_SCALE } from './common-variables';

export default function HTMLReader({
    closeDetailModal,
    openSignatureModal,
    data,
    download,
    loading,
    disableSignBtn,
    downloading,
    fetched
}) {
    /**
     * ----------------------------------------------------------------------------------------
     * Variables ------------------------------------------------------------------------------
     * ----------------------------------------------------------------------------------------
     */
    const [scale, setScale] = useState(1.0);
    const [pdfBody, setPdfBody] = useState(null);
    const [styleLoading, setStyleLoading] = useState(true);
    /**
     * ----------------------------------------------------------------------------------------
     * Hooks ----------------------------------------------------------------------------------
     * ----------------------------------------------------------------------------------------
     */
    useEffect(() => {
        const frameDoc = document.getElementById('frame-doc');

        frameDoc.addEventListener('load', (ev) => {
            // ev.target.contentWindow.document.body.style.paddingTop = '6.25rem';
            ev.target.contentWindow.document.body.style.paddingBottom = '3.75rem';
            ev.target.contentWindow.document.body.style.transform = `translateY(0) scale(1)`;
            const pageContainer = ev.target.contentWindow.document.body.getElementsByClassName("page-container");
            if (pageContainer) {
                for (let containIndex = 0; containIndex < pageContainer.length; containIndex++) {
                    pageContainer[containIndex].style.width = "100%";
                    pageContainer[containIndex].style.margin = '0px';
                    pageContainer[containIndex].style.zoom = '';
                }
            }
            // const parser = new DOMParser();
            const bodyChildTemp = `<div>${ev.target.contentWindow.document.body.innerHTML}</div>`;
            ev.target.contentWindow.document.body.innerHTML = bodyChildTemp;
            const bodyChild = ev.target.contentWindow.document.body.firstElementChild;
            if (bodyChild.style.zoom) {
                bodyChild.style.zoom = '';
            }

            const imgSigns = bodyChild.getElementsByClassName("sign-img");
            if (imgSigns) {
                for (let imgIndex = 0; imgIndex < imgSigns.length; imgIndex++) {
                    if (imgSigns[imgIndex].currentSrc === "") {
                        imgSigns[imgIndex].style.visibility = "hidden";
                    }
                }
            }

            const new_style_element = document.createElement('style');
            new_style_element.textContent = `
                body {
                  margin: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                }
                ::-webkit-scrollbar {
                    width: 10px;
                }
                /* Track */
                ::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }
                /* Handle */
                ::-webkit-scrollbar-thumb {
                    background: #888;
                }
                /* Handle on hover */
                ::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
                *::selection {
                    background: rgba(0, 255, 0, 0.3) !important;
                }
                *::-moz-selection {
                    background: rgba(0, 255, 0, 0.3) !important;
                }
              `;
            ev.target.contentDocument.head.appendChild(new_style_element);

            setPdfBody({
                width: ev.target.contentWindow.document.body.offsetWidth,
                height: ev.target.contentWindow.document.body.offsetHeight,
            });
            setStyleLoading(false);
        });
    }, []);
    /**
     * ----------------------------------------------------------------------------------------
     * Functions ------------------------------------------------------------------------------
     * ----------------------------------------------------------------------------------------
     */

    const zoomIn = () => {
        const pdfBodyTemp =
            document.getElementById('frame-doc').contentWindow.document.body || null;
        if (pdfBodyTemp && pdfBody && pdfBodyTemp.firstElementChild && scale < MAX_SCALE) {
            const newScale = scale + JUMP_SCALE;
            if (newScale >= 1) {
                pdfBodyTemp.style.width = `${pdfBody.width * newScale}px`;
                pdfBodyTemp.style.height = `${pdfBody.height * newScale}px`;
                pdfBodyTemp.firstElementChild.style.transform = `translateY(0) scale(${newScale})`;
            } else {
                pdfBodyTemp.firstElementChild.style.transform = `translateY(${(pdfBody.height / 2) * (newScale - 1)
                    }px) scale(${newScale})`;
                // if (pdfBody.height > 1500) {
                //     pdfBodyTemp.firstElementChild.style.transform = `translateY(${(pdfBody.height / 2) * (newScale - 1)
                //         }px) scale(${newScale})`;
                // } else {
                //     pdfBodyTemp.firstElementChild.style.transform = `translateY(${(pdfBody.height / 4) * (newScale - 1)
                //         }px) scale(${newScale})`;
                // }
            }
            setScale(newScale);
        }
    };

    const zoomOut = () => {
        const pdfBodyTemp =
            document.getElementById('frame-doc').contentWindow.document.body || null;
        if (
            pdfBodyTemp &&
            pdfBody &&
            pdfBodyTemp.firstElementChild &&
            scale > MIN_SCALE + JUMP_SCALE
        ) {
            const newScale = scale - JUMP_SCALE;
            if (newScale >= 1) {
                pdfBodyTemp.style.width = `${pdfBody.width * newScale}px`;
                pdfBodyTemp.style.height = `${pdfBody.height * newScale}px`;
                pdfBodyTemp.firstElementChild.style.transform = `translateY(0) scale(${newScale})`;
            } else {
                pdfBodyTemp.firstElementChild.style.transform = `translateY(${(pdfBody.height / 2) * (newScale - 1)
                    }px) scale(${newScale})`;
                // if (pdfBody.height > 1500) {
                //     pdfBodyTemp.firstElementChild.style.transform = `translateY(${(pdfBody.height / 2) * (newScale - 1)
                //         }px) scale(${newScale})`;
                // } else {
                //     pdfBodyTemp.firstElementChild.style.transform = `translateY(${(pdfBody.height / 4) * (newScale - 1)
                //         }px) scale(${newScale})`;
                // }
            }
            setScale(newScale);
        }
    };

    /**
     * ----------------------------------------------------------------------------------------
     * JSX return -----------------------------------------------------------------------------
     * ----------------------------------------------------------------------------------------
     */
    return (
        <div className="position-relative" id="pdf-file-detail">
            {!fetched && (<div className={'text-center pt-5'}>
                <CircularProgress className={'mt-5'} size={40} color={'secondary'} />
            </div>)}
            <div className="position-absolute d-flex align-items-start control-panel">

                {loading && styleLoading ? <CircularProgress color={'secondary'} /> : <></>}

                {closeDetailModal && (
                    <Button
                        onClick={closeDetailModal}
                        variant="contained"
                        className="bg-white mx-2"
                        style={{ color: '#17c191' }}
                    >
                        Close
                    </Button>
                )}
                {openSignatureModal ? (
                    <>
                        { data?.data?.is_signed === false && (
                            <Button
                                className="text-white mx-2"
                                variant="contained"
                                color="primary"
                                onClick={openSignatureModal}
                                disabled={disableSignBtn}
                            >
                                Sign here
                            </Button>) }
                        <Paper elevation={2} className="mx-3">
                            <Button disableRipple style={{ minWidth: 'unset' }} onClick={download}>
                                <img src="/media/svg/icons/Order/export.svg" alt="Download" />
                            </Button>
                        </Paper>
                    </>
                ) : (
                    <Button
                        className="text-white mx-2"
                        variant="contained"
                        color="primary"
                        onClick={download}
                        disabled={downloading}
                    >
                        {downloading ? (
                            <div
                                className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
                                style={{ top: 1, left: 1 }}
                            >
                                <CircularProgress size={15} />
                            </div>
                        ) : (
                            <></>
                        )}
                        Download
                    </Button>
                )}
                <Paper
                    elevation={2}
                    className="d-inline-flex flex-column align-items-center justify-content-between overflow-hidden mx-2"
                >
                    <Button disableRipple style={{ minWidth: 'unset' }} onClick={zoomIn}>
                        <AddIcon />
                    </Button>
                    <div className="border-top border-bottom py-2" style={{ fontSize: 11 }}>
                        {Math.round(scale * 100)}%
                    </div>
                    <Button disableRipple style={{ minWidth: 'unset' }} onClick={zoomOut}>
                        <RemoveIcon />
                    </Button>
                </Paper>
            </div>
            <section
                id="doc-content-container"
                className="position-relative d-flex justify-content-center overflow-auto pt-35"
                style={{ height: 600 }}
            >
                <iframe
                    title="html pdf form"
                    srcDoc={data?.html}
                    width="100%"
                    height="100%"
                    className="border-0"
                    id="frame-doc"
                />
            </section>
        </div>
    );
}

import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';

export default function ExistingMember({
    mainMemberIndex,
    body,
    setBody,
    fieldAction,
    handleCloseDialog,
    isLoading,
    setIsLoading,
    index,
    member,
    setShowExistingdMember,
    companyMemberType,
    stateSwitch,
    setStateSwitch = () => {},
    bindArrayHelper,
}) {
    const [tagNames, setTagNames] = useState({
        1: 'Director',
        2: 'Shareholder',
        3: 'UBO',
        7: 'Contact Information',
    });
    useEffect(() => {
        try {
            if (body.isLLC) {
                setTagNames({ 1: 'Manager', 2: 'Member', 3: 'UBO', 7: 'Contact Information' });
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    function handleChoose() {
        try {
            setIsLoading(true);

            let newMember = {
                ...member,
                isAfterChooseExistingMember: true,
                company_member_type_id: companyMemberType,
                company_position_id: null,
                is_represented: false,
                company_member_type_ids: null,
            };

            switch (companyMemberType) {
                case 2:
                    let currentState = [...stateSwitch];
                    currentState[mainMemberIndex] = member.individual;
                    setStateSwitch(currentState);
                    break;
                case 3:
                    newMember = {
                        ...newMember,
                        individual: true,
                        percentage: 100,
                    };
                    break;
                case 7:
                    newMember = {
                        ...newMember,
                        country_id: body.contactInfo.address_country_id,
                    };
                    break;
            }
            bindArrayHelper && bindArrayHelper.insert(mainMemberIndex, newMember);
            fieldAction && fieldAction.update(mainMemberIndex, newMember);

            setIsLoading(false);
            handleCloseDialog();
            setShowExistingdMember(false);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="existing-member d-flex">
                <span className="index">{index + 1}.</span>
                <div className="body">
                    <div className="wrap-title d-flex justify-content-between">
                        <p className="mb-0">
                            {member.individual
                                ? member.full_name
                                    ? member.full_name
                                    : member.first_name + ' ' + member?.last_name
                                : member.company_name}
                        </p>
                        <button
                            className="fw-500"
                            color="primary"
                            disabled={isLoading}
                            onClick={handleChoose}
                        >
                            Choose
                        </button>
                    </div>
                    <div className="">
                        <p className="email mb-0">{member.email}</p>
                        <div className="tag">
                            {sortBy(member.company_member_type_ids)
                                .reverse()
                                .map((id, index) => (
                                    <span key={index}>{tagNames[id]}</span>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

import React, { useMemo, useEffect } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const corporationList = [
    {
        country_id: 23,
        name: 'Belize',
        items: [
            { text: 'Scan of Notarized/ Certified True Copy of Passport', type: 'li' },
            {
                text: 'Item 2: Cer. of Incumbency/Cer. of Good Standing dated in 6 months (If any)',
                type: 'li',
            },
            { text: 'Item 3: Memorandum and Articles of Association', type: 'li' },
            { text: 'Item 4: Registers of Directors/Shareholders/UBOs', type: 'li' },
        ],
        items2: [],
    },
    {
        country_id: 189,
        name: 'Seychelles',
        items: [
            { text: 'Item 1: Certificate of Incorporation', type: 'li' },
            {
                text: 'Item 2: Cer. of Incumbency/Cer. of Good Standing dated in 6 months (If any)',
                type: 'li',
            },
            { text: 'Item 3: Memorandum and Articles of Association', type: 'li' },
            { text: 'Item 4: Registers of Directors/Shareholders/UBOs', type: 'li' },
        ],
        items2: [],
    },
    {
        country_id: 183,
        name: 'Samoa',
        items: [
            { text: 'Item 1: Certificate of Incorporation', type: 'li' },
            {
                text: 'Item 2: Cer. of Incumbency/Cer. of Good Standing dated in 6 months (If any)',
                type: 'li',
            },
            { text: 'Item 3: Memorandum and Articles of Association', type: 'li' },
            { text: 'Item 4: Registers of Directors/Shareholders/UBOs', type: 'li' },
        ],
        items2: [],
    },
    {
        country_id: 8,
        name: 'Anguilla',
        items: [
            { text: 'Item 1: Certificate of Incorporation', type: 'li' },
            {
                text: 'Item 2: Cer. of Incumbency/Cer. of Good Standing dated in 6 months (If any)',
                type: 'li',
            },
            { text: 'Item 3: Memorandum and Articles of Association', type: 'li' },
            { text: 'Item 4: Registers of Directors/Shareholders/UBOs', type: 'li' },
        ],
        items2: [],
    },
    {
        country_id: 32,
        name: 'British Virgin Islands',
        items: [
            { text: 'Item 1: Certificate of Incorporation', type: 'li' },
            {
                text: 'Item 2: Cer. of Incumbency/Cer. of Good Standing dated in 6 months (If any)',
                type: 'li',
            },
            { text: 'Item 3: Memorandum and Articles of Association', type: 'li' },
            { text: 'Item 4: Registers of Directors/Shareholders/UBOs', type: 'li' },
        ],
        items2: [],
    },
    {
        country_id: 98,
        name: 'Hong Kong',
        items: [
            { text: 'Item 1: Scan of Certificate of Incorporation', type: 'li' },
            {
                text: 'Item 2: Cer. of Incumbency/Cer. of Good Standing dated in 6 months (If any)',
                type: 'li',
            },
            { text: 'Item 3: Memorandum and Articles of Association', type: 'li' },
            { text: 'Item 4: Registers of Directors/Shareholders/UBOs', type: 'li' },
        ],
        items2: [],
    },
    {
        country_id: 209,
        name: 'St. Vincent & Grenadines',
        items: [
            { text: 'Item 1: Scan of Certificate of Incorporation', type: 'li' },
            {
                text: 'Item 2: Cer. of Incumbency/Cer. of Good Standing dated in 6 months (If any)',
                type: 'li',
            },
            { text: 'Item 3: Memorandum and Articles of Association', type: 'li' },
            { text: 'Item 4: Registers of Directors/Shareholders/UBOs', type: 'li' },
        ],
        items2: [],
    },
    {
        country_id: 237,
        name: 'UK',
        items: [
            { text: 'Item 1: Scan of Certificate of Incorporation', type: 'li' },
            {
                text: 'Item 2: Cer. of Incumbency/Cer. of Good Standing dated in 6 months (If any)',
                type: 'li',
            },
            { text: 'Item 3: Memorandum and Articles of Association', type: 'li' },
            { text: 'Item 4: Registers of Directors/Shareholders/UBOs', type: 'li' },
            {
                text: 'Note: All of the above documents must be duly certified before submitted.',
                type: 'p',
            },
        ],
        items2: [],
    },
    {
        country_id: 136,
        name: 'Marshall Islands',
        items: [
            { text: 'Item 1: Scan of Certificate of Incorporation', type: 'li' },
            {
                text: 'Item 2: Cer. of Incumbency/Cer. of Good Standing dated in 6 months (If any)',
                type: 'li',
            },
            { text: 'Item 3: Memorandum and Articles of Association', type: 'li' },
            { text: 'Item 4: Registers of Directors/Shareholders/UBOs', type: 'li' },
            {
                text: 'Note: All of the above documents must be duly certified before submitted.',
                type: 'p',
            },
        ],
        items2: [],
    },
    {
        country_id: 250,
        name: 'Delaware',
        items: [
            { text: 'Item 1: Scan of Certificate of Incorporation', type: 'li' },
            {
                text: 'Item 2: Cer. of Incumbency/Cer. of Good Standing dated in 6 months (If any)',
                type: 'li',
            },
            { text: 'Item 3: Memorandum and Articles of Association', type: 'li' },
            { text: 'Item 4: Registers of Directors/Shareholders/UBOs', type: 'li' },
            {
                text: 'Note: All of the above documents must be duly certified before submitted.',
                type: 'p',
            },
        ],
        items2: [],
    },
    {
        country_id: 41,
        name: 'Cayman Islands',
        items: [
            { text: 'Item 1: Scan of Certificate of Incorporation', type: 'li' },
            {
                text: 'Item 2: Cer. of Incumbency/Cer. of Good Standing dated in 6 months (If any)',
                type: 'li',
            },
            { text: 'Item 3: Memorandum and Articles of Association', type: 'li' },
            { text: 'Item 4: Registers of Directors/Shareholders/UBOs', type: 'li' },
            {
                text: 'Note: All of the above documents must be duly certified before submitted.',
                type: 'p',
            },
        ],
        items2: [],
    },
    {
        country_id: 17,
        name: 'Bahamas',
        items: [
            { text: 'Item 1: Certificate of Incorporation', type: 'li' },
            {
                text: 'Item 2: Cer. of Incumbency/Cer. of Good Standing dated in 6 months (If any)',
                type: 'li',
            },
            { text: 'Item 3: Memorandum and Articles of Association', type: 'li' },
            { text: 'Item 4: Registers of Directors/Shareholders/UBOs', type: 'li' },
            {
                text: 'Note: All of the above documents must be duly certified before submitted.',
                type: 'p',
            },
        ],
        items2: [],
    },
    {
        country_id: 205,
        name: 'Saint Kitts Nevis',
        items: [
            { text: 'Item 1: Certificate of Incorporation', type: 'li' },
            {
                text: 'Item 2: Cer. of Incumbency/Cer. of Good Standing dated in 6 months (If any)',
                type: 'li',
            },
            { text: 'Item 3: Memorandum and Articles of Association', type: 'li' },
            { text: 'Item 4: Registers of Directors/Shareholders/UBOs', type: 'li' },
            {
                text: 'Note: All of the above documents must be duly certified before submitted.',
                type: 'p',
            },
        ],
        items2: [],
    },
    {
        country_id: 236,
        name: 'United Arab Emirates',
        items: [
            { text: 'Item 1: Certificate of Incorporation', type: 'li' },
            {
                text: 'Item 2: Cer. of Incumbency/Cer. of Good Standing dated in 6 months (If any)',
                type: 'li',
            },
            { text: 'Item 3: Memorandum and Articles of Association', type: 'li' },
            { text: 'Item 4: Registers of Directors/Shareholders/UBOs', type: 'li' },
            {
                text: 'Note: All of the above documents must be duly certified before submitted.',
                type: 'p',
            },
        ],
        items2: [],
    },
    {
        country_id: 169,
        name: 'Panama',
        items: [
            { text: 'Item 1: Notarized copy of passports of all members', type: 'li' },
            {
                text: 'Item 2: Certified Copy of a utility bill or other proof of address not older than three (3) months',
                type: 'li',
            },
            {
                text: 'Item 3: Reference Letter from a bank or lawyer or accountant. The letter should contain all the contact details of the issuer and not be older than three (3) months and must indicate the length of the relationship, which shall not be less than two (2) years',
                type: 'li',
            },
        ],
        items2: [],
    },
    {
        country_id: 139,
        name: 'Mauritius',
        items: [
            { text: 'Item 1: Certificate of Incorporation', type: 'li' },
            {
                text: 'Item 2: Cer. of Incumbency/Cer. of Good Standing dated in 6 months (If any)',
                type: 'li',
            },
            { text: 'Item 3: Memorandum and Articles of Association', type: 'li' },
            { text: 'Item 4: Registers of Directors/Shareholders/UBOs', type: 'li' },
            {
                text: 'Note: All of the above documents must be duly certified before submitted.',
                type: 'p',
            },
        ],
        items2: [],
    },
    {
        country_id: 244,
        name: 'Vietnam',
        items: [
            { text: 'Item 1: Certificate of Incorporation', type: 'li' },
            {
                text: 'Item 2: Cer. of Incumbency/Cer. of Good Standing dated in 6 months (If any)',
                type: 'li',
            },
            { text: 'Item 3: Memorandum and Articles of Association', type: 'li' },
            { text: 'Item 4: Registers of Directors/Shareholders/UBOs', type: 'li' },
            {
                text: 'Note: All of the above documents must be duly certified before submitted.',
                type: 'p',
            },
        ],
        items2: [],
    },
];

const corporationContent = {
    incorporation: [
        {
            id: 1,
            required_documents: [
                {
                    text: `To establish the identity of a corporation or entity, it is necessary to provide company documents and proofs of its members.`,
                    type: 'p',
                },
                {
                    text: `Company documents`,
                    type: 'title',
                },
                {
                    text: `Please provide us with certified true copy (scanned version) of the following company documents:`,
                    type: 'p',
                    className: 'mb-2',
                },
                {
                    text: `- Certificate of Incorporation;`,
                    type: 'p',
                },
                {
                    text: `- Memorandum and Articles of Association / Constitution;`,
                    type: 'p',
                },
                {
                    text: `- Register of Director;`,
                    type: 'p',
                },
                {
                    text: `- Register of Shareholder / UBO;`,
                    type: 'p',
                },
                {
                    text: `- Extract of the company’s details from the Registrar of Companies, which can include any of the following: Business Profile / Certificate of Incumbency / Certificate of Good standing (valid for within 6 months if any).`,
                    type: 'p',
                },
                {
                    text: '',
                    text1: `Note:`,
                    text2: ` in case the company documents can be certified via official government site, there is no need to submit certified copies.`,
                    type: 'multi',
                    className: 'mt-3 font-italic',
                    style1: {
                        fontSize: '13px',
                        fontWeight: 600,
                    }
                },
                {
                    text: `Company members`,
                    type: 'title',
                },
                {
                    text: `All members of the corporation, including Directors (or Managers for LLCs), Shareholders (or Members for LLCs), Ultimate Beneficial Owners (UBOs), and Contact persons, must provide identity and address proofs.`,
                    type: 'p',
                },
                {
                    text1: `Please refer to the`,
                    text2: ` Individual`,
                    text3: ` section for a complete list of required documents.`,
                    type: 'multi',
                    className: 'mt-3',
                    style2: {
                        fontSize: '13px',
                        fontWeight: 600,
                    }
                },
            ],
        },
    ],
    banking: [
        {
            id: 1,
            bank_ids: [1],
            bank_name: 'Caye International Bank Ltd',
            require_documents: [
                { text: 'Item 1: Personal information sheet', type: 'li' },
                { text: 'Item 2: C.V (Resumé)', type: 'li' },
                { text: 'Item 3: Notarized copy of passports', type: 'li' },
                { text: 'Item 4: Notarized copy of address Proofs', type: 'li' },
                {
                    text: 'Item 5: Bank reference letter with at least 2-year relationship',
                    type: 'li',
                },
                { text: 'Note:', type: 'div' },
                {
                    text: 'BBCIncorp will provide template for clients signing',
                    type: 'p',
                },
                {
                    text: 'Address Proofs can be below document in English',
                    type: 'p',
                },
                {
                    text: '- Utility Bill (Electricity Bill, Water Bill, Cable Bill or Internet Bill) OR',
                    type: 'p',
                },
                {
                    text: '- Bank statement (within 3 months)',
                    type: 'p',
                },
                {
                    text: 'Include Contact Information and/or Business Card of Issuer',
                    type: 'p',
                },
                {
                    text: 'The Letter can be issued by your Bank, Credit Union, Investment Firm or Brokerage. No system generated Letters',
                    type: 'p',
                },
            ],
        },
        {
            id: 2,
            bank_ids: [23],
            bank_name: 'CIM Banque',
            require_documents: [
                { text: 'Item 1: Notarized copy of passports', type: 'li' },
                { text: 'Item 2: Notarized copy of address Proofs', type: 'li' },
                { text: 'Item 3: C.V (Resumé)', type: 'li' },
            ],
        },
        {
            id: 3,
            bank_ids: [5, 6, 7, 8, 9, 10, 11, 18, 19, 22, 25, 26, 31],
            bank_name: 'DBS, OCBC and HK Bank',
            require_documents: [
                { text: 'Item 1: Passports of all members', type: 'li' },
                { text: 'Item 2: Address Proofs of all member', type: 'li' },
                { text: 'Item 3: C.V (Resumé)', type: 'li' },
                { text: 'Note:', type: 'div' },
                {
                    text: 'Address Proofs can be below document in English',
                    type: 'p',
                },
                {
                    text: '- Bank statement (Most Recent) OR',
                    type: 'p',
                },
                {
                    text: '- Bank Reference Letter (must have account opening date)',
                    type: 'p',
                },
            ],
        },
        {
            id: 4,
            bank_ids: [16],
            bank_name: 'Euro Pacific Bank',
            require_documents: [
                { text: 'Item 1: Notarized * copy of passports', type: 'li' },
                { text: 'Item 2: Notarized* copy of address Proofs', type: 'li' },
                {
                    text: 'Item 3: Bank Statements of 6 months or Bank reference letter within 3 months',
                    type: 'li',
                },
                { text: 'Note:', type: 'div' },
                {
                    text: 'Can be below document in English',
                    type: 'p',
                },
                {
                    text: '- Passport OR',
                    type: 'p',
                },
                {
                    text: '- Identify Card',
                    type: 'p',
                },
                {
                    text: 'Address Proofs can be below document in English',
                    type: 'p',
                },
                {
                    text: '- Utility Bill (Electricity Bill, Water Bill, Cable Bill or Internet Bill) OR',
                    type: 'p',
                },
                {
                    text: '- Bank statement (Most Recent) OR',
                    type: 'p',
                },
                {
                    text: '- Bank Reference Letter (must have account opening date) OR',
                    type: 'p',
                },
                {
                    text: '- Government Issued ID OR',
                    type: 'p',
                },
                {
                    text: '- Tax Document or Municipality Bill',
                    type: 'p',
                },
            ],
        },
        {
            id: 5,
            bank_ids: [13],
            bank_name: 'Bank One Limited',
            require_documents: [
                { text: 'Item 1: Notarized copy of passports', type: 'li' },
                { text: 'Item 2: Notarized copy of address Proofs', type: 'li' },
                { text: 'Item 3: C.V (Resumé)', type: 'li' },
                { text: 'Note:', type: 'div' },
                {
                    text: 'Address Proofs can be below document in English:',
                    type: 'p',
                },
                {
                    text: '- Utility Bill (Electricity Bill, Water Bill, Cable Bill or Internet Bill) OR',
                    type: 'p',
                },
                {
                    text: '- Bank statement (Most Recent) OR',
                    type: 'p',
                },
                {
                    text: '- Bank Reference Letter (must have account opening date) OR',
                    type: 'p',
                },
                {
                    text: '- Government Issued ID',
                    type: 'p',
                },
            ],
        },
    ],
};

export default function CorporationItemList({
    country_id,
    flow = 'incorporation',
    bankingProcess,
}) {
    const contents = useMemo(() => {
        let temps = [
            {
                text: 'Incorporation:',
                type: 'p2',
            },
            ...corporationContent['incorporation'][0].required_documents,
        ];
        if (flow === 'banking' && bankingProcess && bankingProcess.Bank) {
            const currBanking = corporationContent['banking'].find((item) =>
                item.bank_ids.some((bankId) => bankId === bankingProcess.Bank.id),
            );
            if (currBanking) {
                temps = [
                    ...temps,
                    {
                        text: 'Banking:',
                        type: 'p2',
                    },
                    ...currBanking.require_documents,
                ];
            }
        }

        if (temps) {
            return temps;
        }
        return [];
    }, [country_id, bankingProcess]);

    // const contents = corporationContent;
    return (
        <ul id="corp-item-list" className="kyc-package-list">
            {contents.map((content, index) => {
                switch (content.type) {
                    case 'li':
                        return (
                            <li className="required-item" key={index}>
                                {content.text}
                            </li>
                        );
                    case 'p': // for item note
                        return (
                            <p
                                key={index}
                                className={`required-item mb-0 ml-7-negative ${content?.className}`}
                                style={content.style}
                            >
                                {content.text}
                            </p>
                        );
                    case 'p2':
                        return (
                            <p
                                className="required-item mb-0 ml-7-negative"
                                key={index}
                                style={{ fontSize: '13px', fontWeight: 600, ...content?.style }}
                            >
                                {content.text}
                            </p>
                        );
                    case 'title': // for list note title
                        return (
                            <div key={index} className="required-item mt-3 ml-7-negative">
                                <p
                                    className="mb-0"
                                    style={{ fontSize: '13px', fontWeight: 600, ...content?.style }}
                                >
                                    {content.text}
                                </p>
                            </div>
                        );
                    case 'url':
                        return (
                            <div key={index}>
                                <a
                                    target="_blank"
                                    href={content?.url}
                                    className="required-item mb-0 ml-7-negative"
                                >
                                    <u>{content.text}</u>
                                    <ArrowForwardIcon />
                                </a>
                            </div>
                        );
                    case 'multi':
                        return (
                            <div
                                key={index}
                                className={`required-item ml-7-negative ${content?.className}`}
                            >
                                <span className={content?.className1} style={content?.style1}>{content.text1 ?? ''}</span>
                                <span className={content?.className2} style={content?.style2}>{content?.text2 ?? ''}</span>
                                <span className={content?.className3} style={content?.style3}>{content?.text3 ?? ''}</span>
                            </div>
                        );
                    default:
                        return (
                            <p className="required-item mb-0 ml-7-negative" key={index}>
                                {content.text}
                            </p>
                        );
                }
            })}
        </ul>
    );
}

import React, { useMemo } from 'react';
import DocumentForYear from './components/DocumentForYear';
import { useParams } from 'react-router-dom';

const ChooseYears = ({ orderInfo, onSubmit }) => {
    const ES_SERVICE = [1841, 1931];
    const AR_SERVICE = [1994, 1993];
    const { id } = useParams();

    const quantity = useMemo(() => {
        return (
            orderInfo?.OrderItems?.find(
                (item) =>
                    ES_SERVICE.includes(item.service_id) || AR_SERVICE.includes(item.service_id),
            )?.quantity || 0
        );
    }, [orderInfo]);

    return (
        <>
            <h6>Document for year</h6>
            <DocumentForYear
                orderId={id}
                quantity={quantity}
                eventYears={orderInfo?.EventYears}
                onSubmit={onSubmit}
            />
        </>
    );
};

export default ChooseYears;

import InputField from 'components/form-controls/InputField';
import React, { Fragment } from 'react';

const SourceForFunds = ({ index, body, form, disabled = false }) => {
    return (
        <Fragment>
            <div className="source_of_funds container-fluid p-0 m-0">
                <h5 className="col-md-12 mb-7 pl-0 input-line">Source of funds</h5>
                <div className="row justify-content-between">
                    <div
                        className="p-4"
                        style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', width: '49%' }}
                    >
                        <p className="text-center">ASSET</p>
                        <div className="col-12 mb-7 input-line has-unit">
                            <label>Cash in bank</label>
                            <InputField
                                type="number"
                                label=""
                                name={`members.${index}.cash_in_bank_asset_1`}
                                placeholder="0"
                                form={form}
                                disabled={
                                    form.getValues(
                                        `members.${index}.isAfterChooseExistingMember`,
                                    ) || disabled
                                }
                                onChange={(e) => {
                                    form.setValue(
                                        `members.${index}.total_asset`,
                                        Number(e.target.value) +
                                            Number(form.getValues(`members.${index}.real_estate`)) +
                                            Number(form.getValues(`members.${index}.other_money`)),
                                    );
                                }}
                                hideSuccessHelper
                            />
                            <span className="unit">{body.currency_unit}</span>
                        </div>
                        <div className="col-12 mb-7 input-line has-unit">
                            <label>Real Estate</label>
                            <InputField
                                type="number"
                                label=""
                                name={`members.${index}.real_estate`}
                                placeholder="0"
                                form={form}
                                disabled={
                                    form.getValues(
                                        `members.${index}.isAfterChooseExistingMember`,
                                    ) || disabled
                                }
                                onChange={(e) => {
                                    form.setValue(
                                        `members.${index}.total_asset`,
                                        Number(e.target.value) +
                                            Number(
                                                form.getValues(
                                                    `members.${index}.cash_in_bank_asset_1`,
                                                ),
                                            ) +
                                            Number(form.getValues(`members.${index}.other_money`)),
                                    );
                                }}
                                hideSuccessHelper
                            />
                            <span className="unit">{body.currency_unit}</span>
                        </div>

                        <p className="asset__others--title pl-4">Others</p>
                        <div className="col-12 mb-7 input-line">
                            <InputField
                                name={`members.${index}.other_asset`}
                                placeholder="Please provide specific details"
                                form={form}
                                disabled={
                                    form.getValues(
                                        `members.${index}.isAfterChooseExistingMember`,
                                    ) || disabled
                                }
                                hideSuccessHelper
                            />
                        </div>

                        <div className="col-12 mb-7 input-line has-unit">
                            <InputField
                                type="number"
                                name={`members.${index}.other_money`}
                                placeholder="0"
                                form={form}
                                disabled={
                                    form.getValues(
                                        `members.${index}.isAfterChooseExistingMember`,
                                    ) || disabled
                                }
                                onChange={(e) => {
                                    form.setValue(
                                        `members.${index}.total_asset`,
                                        Number(e.target.value) +
                                            Number(
                                                form.getValues(
                                                    `members.${index}.cash_in_bank_asset_1`,
                                                ),
                                            ) +
                                            Number(form.getValues(`members.${index}.real_estate`)),
                                    );
                                    form.setValue(`members.${index}.showOther`, true);
                                    if (!e.target.value) {
                                        form.setValue(`members.${index}.showOther`, false);
                                    }
                                }}
                                hideSuccessHelper
                            />
                            <span className="unit">{body.currency_unit}</span>
                        </div>

                        <div className="asset__total col-12 mb-7 no-underline">
                            <span className="pr-5 money-title" style={{ whiteSpace:'nowrap' }}>TOTAL:</span>
                            <InputField
                                type="number"
                                name={`members.${index}.total_asset`}
                                placeholder="0"
                                form={form}
                                readOnly
                                hideSuccessHelper
                            />
                            <span className="money-unit">{body.currency_unit}</span>
                            <span className="rest-grow"></span>
                        </div>
                    </div>

                    <div
                        className="p-4"
                        style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', width: '49%' }}
                    >
                        <p className="text-center">INCOME</p>
                        <div className="col-12 mb-7 input-line has-unit">
                            <label>Investment Income</label>
                            <InputField
                                type="number"
                                name={`members.${index}.investment_income`}
                                placeholder="0"
                                label=""
                                form={form}
                                disabled={
                                    form.getValues(
                                        `members.${index}.isAfterChooseExistingMember`,
                                    ) || disabled
                                }
                                onChange={(e) => {
                                    form.setValue(
                                        `members.${index}.total_income`,
                                        Number(e.target.value) +
                                            Number(
                                                form.getValues(`members.${index}.self_employed`),
                                            ) +
                                            Number(
                                                form.getValues(
                                                    `members.${index}.other_money_income`,
                                                ),
                                            ),
                                    );
                                }}
                                hideSuccessHelper
                            />
                            <span className="unit">{body.currency_unit}</span>
                        </div>
                        <div className="col-12 mb-7 input-line has-unit">
                            <label>Self-Employed</label>
                            <InputField
                                type="number"
                                name={`members.${index}.self_employed`}
                                placeholder="0"
                                label=""
                                form={form}
                                disabled={
                                    form.getValues(
                                        `members.${index}.isAfterChooseExistingMember`,
                                    ) || disabled
                                }
                                onChange={(e) => {
                                    form.setValue(
                                        `members.${index}.total_income`,
                                        Number(e.target.value) +
                                            Number(
                                                form.getValues(
                                                    `members.${[index]}.investment_income`,
                                                ),
                                            ) +
                                            Number(
                                                form.getValues(
                                                    `members.${[index]}.other_money_income`,
                                                ),
                                            ),
                                    );
                                }}
                                hideSuccessHelper
                            />
                            <span className="unit">{body.currency_unit}</span>
                        </div>

                        <p className="asset__others--title pl-4">Others</p>
                        <div className="col-12 mb-7 input-line">
                            <InputField
                                name={`members.${index}.other_detail_income`}
                                placeholder="Please provide specific details"
                                label=""
                                form={form}
                                disabled={
                                    form.getValues(
                                        `members.${index}.isAfterChooseExistingMember`,
                                    ) || disabled
                                }
                                hideSuccessHelper
                            />
                        </div>

                        <div className="col-12 mb-7 input-line has-unit">
                            <InputField
                                type="number"
                                name={`members.${index}.other_money_income`}
                                placeholder="0"
                                label=""
                                form={form}
                                disabled={
                                    form.getValues(
                                        `members.${index}.isAfterChooseExistingMember`,
                                    ) || disabled
                                }
                                onChange={(e) => {
                                    form.setValue(
                                        `members.${index}.total_income`,
                                        Number(e.target.value) +
                                            Number(
                                                form.getValues(`members.${index}.self_employed`),
                                            ) +
                                            Number(
                                                form.getValues(
                                                    `members.${index}.investment_income`,
                                                ),
                                            ),
                                    );
                                    form.setValue(`members.${index}.showOtherIncome`, true);

                                    if (!e.target.value) {
                                        form.setValue(`members.${index}.showOtherIncome`, false);
                                    }
                                }}
                                hideSuccessHelper
                            />
                            <span className="unit">{body.currency_unit}</span>
                        </div>

                        <div className="asset__total col-12 mb-7 no-underline">
                            <span className="pr-5 money-title" style={{ whiteSpace:'nowrap' }}>TOTAL:</span>
                            <InputField
                                type="number"
                                name={`members.${index}.total_income`}
                                placeholder="0"
                                label=""
                                form={form}
                                readOnly
                                hideSuccessHelper
                            />
                            <span className="money-unit">{body.currency_unit}</span>
                            <span className="rest-grow"></span>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SourceForFunds;

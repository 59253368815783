import React, { useEffect, useRef, useState } from 'react';
import Icon from '@mui/material/Icon';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {
    getDefaultNationality,
    getDefaultPhonePrefix,
} from '../../../../../../utilities/NationalityHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import * as actionsEnum from '../../../../../../../_redux/enum/enumActions';
import { CAYMAN_ID, RAK_ID, UK_ID } from 'constants/country';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import { Controller } from 'react-hook-form';

const DatepickerInput = ({ ...props }, ref) => <input type="text" {...props} readOnly />;

const useStyles = makeStyles((theme) => ({
    // tooltip
    arrow: {
        color: '#3783e7',
    },
    tooltip: {
        fontSize: '12px',
        fontWeight: 300,
        letterSpacing: '0.3px',
        backgroundColor: '#3783e7',
    },
}));

export default function ShareholderCorporation({
    index,
    isHongkong,
    form,
    body,
    values,
    checkNumShare,
    disabled = false,
    businessActivity,
    orderInfo,
    countryOperation,
    businessActivityWithCountry,
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [country, setCountry] = useState('');
    const [phonePrefix, setPhonePrefix] = useState('');
    const { countriesOptions, phonePrefixCountries } = useSelector((state) => state.enum);
    const checkFirstTime = useRef(true);
    let nationality = getDefaultNationality(
        body.companyStructure.Shareholders[index]?.country_of_incorporation,
        countriesOptions,
    );
    const [membersWithBusinessActivity, setMembersWithBusinessActivity] = useState([]);

    const handleGetBusinessActivityWithCountry = (countryId) => {
        dispatch(actionsEnum.fetchBusinessActivity(countryId, true));
    };

    useEffect(() => {
        if (!values?.[index]?.country_of_incorporation) {
            handleChangeCountryOfCorporation(nationality?.id);
        }
    }, []);

    const handleChangeCountryOfCorporation = (countryId) => {
        if (
            (countryId === CAYMAN_ID && !businessActivityWithCountry.caymanList.length) ||
            (countryId === RAK_ID && !businessActivityWithCountry.rakList.length) ||
            (countryId === UK_ID && !businessActivityWithCountry.ukList.length) ||
            (![CAYMAN_ID, UK_ID, RAK_ID].includes(countryId) &&
                !businessActivityWithCountry.normalList.length)
        ) {
            handleGetBusinessActivityWithCountry(countryId);
        }
    };

    const getBusinessActivityOfCountry = (countryId) => {
        if (businessActivityWithCountry) {
            if (countryId === CAYMAN_ID) {
                if (businessActivityWithCountry.caymanList.length) {
                    return businessActivityWithCountry.caymanList;
                }
            }
            if (countryId === UK_ID) {
                if (businessActivityWithCountry.ukList.length) {
                    return businessActivityWithCountry.ukList;
                }
            }
            if (countryId === RAK_ID) {
                if (businessActivityWithCountry.rakList.length) {
                    return businessActivityWithCountry.rakList;
                }
            }

            if (![CAYMAN_ID, UK_ID, RAK_ID].includes(countryId)) {
                if (businessActivityWithCountry.normalList.length) {
                    return businessActivityWithCountry.normalList;
                }
            }
        } else {
            return [];
        }
    };

    useEffect(() => {
        if (values?.length) {
            const tempMembers = values.map((member) => {
                if (!member?.individual) {
                    return {
                        ...member,
                        businessActivityOptions: getBusinessActivityOfCountry(
                            member.country_of_incorporation,
                        ),
                    };
                } else {
                    return member;
                }
            });
            setMembersWithBusinessActivity(tempMembers);
        }
    }, [values?.length, businessActivityWithCountry, values?.[index]?.country_of_incorporation]);

    useEffect(() => {
        // if (checkFirstTime) {
        //     checkFirstTime.current = false;
        //     return;
        // }

        try {
            let phonePrefixDefault = getDefaultPhonePrefix(
                body.companyStructure.Shareholders[index]?.phone_code,
                phonePrefixCountries,
            );
            setPhonePrefix(phonePrefixDefault);

            setCountry(nationality);

            if (form.watch('members')) {
                form.setValue(`members.${index}.phone_code`, phonePrefixDefault.id);
                form.setValue(`members.${index}.country_of_incorporation`, nationality.id);
            }
        } catch (error) {
            console.log(error);
        }
    }, [body]);

    useEffect(() => {
        try {
            if (values?.[index].phone_code) {
                let phone_country_id;
                values?.[index].phone_code.id // phone_code type object
                    ? (phone_country_id = values?.[index].phone_code.id)
                    : (phone_country_id = values?.[index].phone_code);

                const phone_prefix =
                    phonePrefixCountries &&
                    phonePrefixCountries.find((e) => e.id === phone_country_id);
                phone_prefix
                    ? setPhonePrefix(phone_prefix)
                    : setPhonePrefix(getDefaultPhonePrefix());
            }
        } catch (error) {
            console.log(error);
        }
    }, [values?.[index].phone_code]);

    useEffect(() => {
        try {
            if (values?.[index].country_of_incorporation) {
                const currCountry =
                    countriesOptions &&
                    countriesOptions.find((e) => e.id === values?.[index].country_of_incorporation);
                currCountry ? setCountry(currCountry) : setCountry(getDefaultNationality());
            }
        } catch (error) {
            console.log(error);
        }
    }, [values?.[index].country_of_incorporation]);

    return (
        <>
            {isHongkong && (
                <p className="warning">
                    If your company has a corporate shareholder, there must be one representative
                    holding at least 25% of shares in the corporation to fulfil form NNC1. The
                    original signed form must then be sent to our Hong Kong office.
                    <br />
                    Please note that your company will be established in 5 - 7 working days instead
                    of 1 day
                </p>
            )}
            <div className="row">
                <div className="col-md-6 mb-7 input-line">
                    <label>Company name</label>
                    <InputField
                        type="text"
                        name={`members.${index}.company_name`}
                        form={form}
                        placeholder="Enter company name"
                        hideSuccessHelper
                        disabled={
                            form.watch(`members[${index}].isAfterChooseExistingMember`) || disabled
                        }
                    />
                </div>
                <div className="col-md-6 mb-7 input-line">
                    <label>Company number</label>
                    <InputField
                        type="text"
                        name={`members.${index}.company_number`}
                        form={form}
                        placeholder="Enter company number"
                        disabled={
                            form.watch(`members[${index}].isAfterChooseExistingMember`) || disabled
                        }
                        hideSuccessHelper
                    />
                </div>
                <div className="col-md-6 col-sm-6 mb-7 input-line">
                    <label>Country of Incorporation</label>
                    <SelectField
                        form={form}
                        className="fw-500"
                        label=""
                        name={`members.${index}.country_of_incorporation`}
                        placeholder="Choose country"
                        options={countriesOptions}
                        hideHelperText={true}
                        value={country}
                        onChange={(value) => {
                            if (country?.id != value.id) {
                                handleChangeCountryOfCorporation(value.id);
                                setCountry(value);
                                form.setValue(
                                    `members.${index}.country_of_incorporation`,
                                    value.id,
                                );
                                form.setValue(`members.${index}.business_activity_array`, []);
                            }
                        }}
                        disabled={
                            form.watch(`members[${index}].isAfterChooseExistingMember`) || disabled
                        }
                        disableClearable
                    />
                </div>
                <div className="col-md-6 mb-7 input-line">
                    <label>Registered address</label>
                    <InputField
                        type="text"
                        name={`members.${index}.registered_address`}
                        form={form}
                        placeholder="Enter address"
                        disabled={
                            form.watch(`members[${index}].isAfterChooseExistingMember`) || disabled
                        }
                        hideSuccessHelper
                    />
                </div>

                {/* --------------------- NEW */}
                <div className="col-md-6 mb-7 input-line">
                    <div className="wrap-label-has-tooltip">
                        <label>Business activities</label>
                        <div className="wrap-tooltip d-inline ml-2">
                            <Tooltip
                                title='Please give specific business activities within 20-30 words. General description of business such "Trading company", "Holding company", or "Investment company" is not sufficient.'
                                placement="top"
                                arrow
                                classes={classes}
                            >
                                <InfoIcon className="icon" />
                            </Tooltip>
                        </div>
                    </div>
                    <SelectField
                        form={form}
                        id="business_activity_array"
                        className="fw-500"
                        label=""
                        name={`members.${index}.business_activity_array`}
                        placeholder={'Please select specific business activities.'}
                        options={
                            values?.[index]?.country_of_incorporation
                                ? membersWithBusinessActivity[index]?.businessActivityOptions
                                : []
                        }
                        getOptionLabel={option => option?.description}
                        disabled={
                            form.watch(`members[${index}].isAfterChooseExistingMember`) || disabled
                        }
                        multiple
                        hideHelperText
                        hideInputHelperText
                    />
                    {form.formState.errors?.members?.[index]?.business_activity_array && (
                        <div
                            className="text-danger"
                            style={{ fontSize: '0.9rem', marginTop: '5px' }}
                        >
                            Business activities is required!
                        </div>
                    )}
                </div>
                <div className="col-md-6 mb-7 input-line">
                    <div className="wrap-label-has-tooltip">
                        <label>Country/countries of operation</label>
                        <div className="wrap-tooltip d-inline ml-2">
                            <Tooltip
                                title="Please provide countries which your company will be managed/ controlled and countries of your partners"
                                placement="top"
                                arrow
                                // open={true}
                                classes={classes}
                            >
                                <InfoIcon className="icon" />
                            </Tooltip>
                        </div>
                    </div>
                    <SelectField
                        form={form}
                        id="country_operations"
                        className="fw-500"
                        label=""
                        name={`members.${index}.country_operations`}
                        placeholder={'Choose country/countries of operation'}
                        options={countriesOptions?.length && countriesOptions}
                        disabled={
                            form.watch(`members[${index}].isAfterChooseExistingMember`) || disabled
                        }
                        multiple
                        hideHelperText
                        hideInputHelperText
                    />
                    {form.formState.errors?.members?.[index]?.country_operations && (
                        <div
                            className="text-danger"
                            style={{ fontSize: '0.9rem', marginTop: '5px' }}
                        >
                            Countries operation is required!
                        </div>
                    )}
                </div>
                {values?.[index]?.business_activity_array &&
                    values?.[index]?.business_activity_array.length > 0 && (
                        <>
                            <div className="col-md-6 mb-7 input-line">
                                <div className="wrap-label-has-tooltip">
                                    <label>Details of Business activities</label>
                                    <div className="wrap-tooltip d-inline ml-2">
                                        <Tooltip
                                            title="Please describe your business activities in this field as details as possible to process your order smoothy"
                                            placement="top"
                                            arrow
                                            classes={classes}
                                        >
                                            <InfoIcon className="icon" />
                                        </Tooltip>
                                    </div>
                                </div>
                                <InputField
                                    multiline
                                    maxRows={5}
                                    type="text"
                                    name={`members.${index}.business_activity_detail`}
                                    form={form}
                                    disabled={
                                        form.watch(
                                            `members[${index}].isAfterChooseExistingMember`,
                                        ) || disabled
                                    }
                                    hideSuccessHelper
                                />
                            </div>
                            <div className="col-md-6 mb-7 input-line"></div>
                        </>
                    )}
                {/* ----------------------------*/}

                <div className="col-md-6 mb-7 input-line">
                    <label>Phone number</label>
                    <div className="phone-group">
                        <div className="phone-code" style={{ zIndex: 1 }}>
                            <SelectField
                                id="phone-prefix-select"
                                value={phonePrefix}
                                form={form}
                                className="fw-500"
                                label=""
                                name={`members.${index}.phone_code`}
                                placeholder="Select..."
                                options={phonePrefixCountries}
                                customStyle={{
                                    '.MuiInputBase-root:before, .MuiInputBase-root:after, .MuiInput-underline:hover:not(.Mui-disabled):before':
                                        {
                                            borderBottom: '0 !important',
                                        },
                                }}
                                hideHelperText={true}
                                disabled={
                                    form.watch(`members[${index}].isAfterChooseExistingMember`) ||
                                    disabled
                                }
                                disableClearable
                                onChange={(value) => {
                                    if (phonePrefix && phonePrefix.id != value.id) {
                                        setPhonePrefix(value);
                                        form.setValue(`members.${index}.phone_code`, value);
                                    }
                                }}
                            />
                        </div>
                        <div className="input-line inp-phone">
                            <InputField
                                name={`members.${index}.phone`}
                                form={form}
                                placeholder="Enter phone number"
                                type="text"
                                disabled={
                                    form.watch(`members[${index}].isAfterChooseExistingMember`) ||
                                    disabled
                                }
                                hideSuccessHelper
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mb-7 input-line">
                    <label>Email Address of Designated Legal Representative</label>
                    <InputField
                        type="text"
                        name={`members.${index}.email`}
                        form={form}
                        placeholder="Enter email address"
                        disabled={
                            form.watch(`members[${index}].isAfterChooseExistingMember`) || disabled
                        }
                        hideSuccessHelper
                    />
                </div>
                <div className="col-md-6 mb-7">
                    <p style={{ fontSize: '10px', color: 'grey' }}>Date of Incorporation</p>

                    <div className="with_icon input-line" style={{ width: '120px' }}>
                        <Controller
                            control={form.control}
                            name={`members.${index}.date_of_incorporation`}
                            render={({ field }) => (
                                <>
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        maxDate={Date.now()}
                                        showMonthDropdown
                                        showYearDropdown
                                        id={`members.${index}.date_of_incorporation`}
                                        selected={new Date(values[index].date_of_incorporation)}
                                        dropdownMode="select"
                                        onChange={(e) => {
                                            form.setValue(
                                                `members.${index}.date_of_incorporation`,
                                                e ? moment(e).format('YYYY-MM-DD') : null,
                                            );
                                        }}
                                        customInput={React.createElement(
                                            React.forwardRef(DatepickerInput),
                                        )}
                                        disabled={
                                            form.watch(
                                                `members[${index}].isAfterChooseExistingMember`,
                                            ) || disabled
                                        }
                                    />
                                    <Icon className="icon far fa-calendar" />
                                </>
                            )}
                        />
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 mb-7 input-line"></div>
                {body && body.isLLC ? null : (
                    <div className="col-md-6 col-sm-6  mb-7 input-line">
                        <label>Number of shares to be alloted</label>
                        <div className="has-unit" style={{ width: '100px' }}>
                            <InputField
                                type="number"
                                name={`members.${index}.number_share`}
                                form={form}
                                onChange={(e) => {
                                    form.setValue(`members.${index}.number_share`, e.target.value);
                                    checkNumShare(e.target.value, index, values);
                                }}
                                disabled={disabled}
                                hideSuccessHelper
                                inputProps={{ min: 1 }}
                            />
                            <span className="unit" style={{ top: '7px', right: 0 }}>
                                {body && body.isLLC ? '%' : 'Shares'}
                            </span>
                        </div>
                    </div>
                )}

                {body && body.isLLC ? (
                    <div className="col-md-12 mb-7 input-line">
                        <label>Percentage of ownership</label>
                        <div className="has-unit" style={{ width: '100px' }}>
                            <InputField
                                type="number"
                                name={`members.${index}.own_percent`}
                                form={form}
                                onChange={(e) => {
                                    form.setValue(`members.${index}.own_percent`, e.target.value);
                                    checkNumShare(e.target.value, index, values);
                                }}
                                disabled={disabled}
                                hideSuccessHelper
                                inputProps={{ min: 1 }}
                            />
                            <span className="unit" style={{ top: '7px', right: 0 }}>
                                %
                            </span>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
}

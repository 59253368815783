import { DATE_DB_FORMAT, ENTITY_TYPES, SPECIAL_LLC_COUNTRIES } from 'constants/index';
import { BELIZE_ID, BVI_ID, PANAMA_ID, UK_ID } from 'constants/country';
import {
    DIRECTOR_TYPE_ID,
    PAYER_TYPE_ID,
    PRESIDENT_TYPE_ID,
    SECRETARY_TYPE_ID,
    SHAREHOLDER_TYPE_ID,
    TREASURER_TYPE_ID,
    UBO_TYPE_ID,
} from 'constants/member-type';
import { cloneDeep, isNil, sortBy } from 'lodash';
import { format } from 'date-fns';

export const getMembers = (props) => {
    const {
        companyMembers = [],
        typeMember,
        companyCountryId,
        hideSourceOfFund = false,
        questionsAnswerOptions,
        isLLC,
        changeRequests,
        isFinishFlow,
    } = props;

    const tempMembers = [];
    const mapTypeMemberToArray = {
        director: [DIRECTOR_TYPE_ID, SECRETARY_TYPE_ID, PRESIDENT_TYPE_ID, TREASURER_TYPE_ID],
        shareholder: [SHAREHOLDER_TYPE_ID],
        ubo: [UBO_TYPE_ID],
        payer: [PAYER_TYPE_ID],
    };

    companyMembers.forEach((member) => {
        let positionId = null;
        const CompanyPositions =
            member.CompanyPositions.length > 1
                ? sortBy(member.CompanyPositions, 'company_member_type_id')
                : member.CompanyPositions;

        positionId = CompanyPositions.find(
            (position) =>
                mapTypeMemberToArray[typeMember].includes(position.company_member_type_id) &&
                !position.corporation_company_member_id,
        );

        if (!positionId) return;
        tempMembers.push(
            getMember({
                member: {
                    ...member,
                    member_id: member.id,
                },
                typeMember,
                companyCountryId,
                defaultPosition: positionId,
                hideSourceOfFund,
                questionsAnswerOptions,
                changeRequests,
                isFinishFlow,
            }),
        );
    });

    if (!tempMembers.length) {
        tempMembers.push(
            getMember({
                member: null,
                typeMember,
                companyCountryId,
                defaultPosition: null,
                hideSourceOfFund,
                questionsAnswerOptions,
                isLLC,
                changeRequests,
                isFinishFlow,
            }),
        );
    }
    return tempMembers;
};

export const getMember = (props) => {
    const {
        member = null,
        typeMember,
        companyCountryId,
        defaultPosition,
        hideSourceOfFund = false,
        positionId = null,
        questionsAnswerOptions,
        isLLC,
        needValidate = true,
        changeRequests, // để đồng bộ số shares với Change Request ở luồng legal
        isFinishFlow,
    } = props;

    const contactPerson = member?.CompanyPositions?.find((position) => position.is_contact_person);

    const mapTypeMemberToId = {
        director: DIRECTOR_TYPE_ID,
        shareholder: SHAREHOLDER_TYPE_ID,
        ubo: UBO_TYPE_ID,
        payer: PAYER_TYPE_ID,
    };
    const defaultPercentOfInterest = 0;
    const defaultOwnPercent = 0;

    let companyPosition = {
        is_appointed: 0,
        company_member_type_id: mapTypeMemberToId[typeMember],
        number_allotted: 0,
        percent_of_interest: defaultPercentOfInterest,
        own_percent: defaultOwnPercent,
        date_of_appointment: null,
        corporation_company_member_id: null,
    };

    let companyPositionMember = null;

    // Trường hợp có defaultPosition thì lấy default Position không lấy position của member (case choose existing member để tạo mới)
    if (defaultPosition) {
        companyPosition = { ...companyPosition, ...defaultPosition };
    } else {
        companyPositionMember = !positionId
            ? member?.CompanyPositions?.find((position) =>
                  [mapTypeMemberToId[typeMember]].includes(position.company_member_type_id),
              ) || null
            : member?.CompanyPositions?.find((position) => position.id === positionId);
    }

    if (!changeRequests) {
        console.log(1);
        // luồng Incorporation
        if (!member?.id) {
            if (defaultPosition?.corporation_company_member_id) {
                companyPosition.switch_percent = !!companyPositionMember?.own_percent;
            }
        } else {
            if (companyPositionMember?.id) {
                companyPosition.id = companyPositionMember.id;
            }
            companyPosition.is_appointed = defaultPosition
                ? companyPosition?.is_appointed
                : companyPositionMember?.is_appointed;
            companyPosition.number_allotted =
                member?.CompanyShares?.find(
                    (item) =>
                        !item.corporation_company_member_id && item.is_valid && item.is_approved,
                )?.value ?? 0;
            companyPosition.percent_of_interest =
                member?.CompanyInterests?.find(
                    (item) =>
                        !item.corporation_company_member_id && item.is_valid && item.is_approved,
                )?.value ?? defaultPercentOfInterest;
            companyPosition.own_percent =
                member?.CompanyOwnerships?.find(
                    (item) =>
                        !item.corporation_company_member_id && item.is_valid && item.is_approved,
                )?.value ?? defaultOwnPercent;
            companyPosition.date_of_appointment = defaultPosition
                ? companyPosition?.date_of_appointment
                : companyPositionMember?.date_of_appointment;

            if (companyPositionMember?.corporation_company_member_id) {
                companyPosition.corporation_company_member_id =
                    companyPositionMember?.corporation_company_member_id ?? null;
                companyPosition.switch_percent = !!member?.CompanyOwnerships?.length > 0;
                companyPosition.number_allotted =
                    member?.CompanyShares?.find(
                        (item) =>
                            item.corporation_company_member_id ===
                            companyPositionMember?.corporation_company_member_id,
                    )?.value ?? 0;
                companyPosition.own_percent =
                    member?.CompanyOwnerships?.find(
                        (item) =>
                            item.corporation_company_member_id ===
                            companyPositionMember?.corporation_company_member_id,
                    )?.value ?? 0;
                companyPosition.percent_of_interest =
                    member?.CompanyInterests?.find(
                        (item) =>
                            item.corporation_company_member_id ===
                            companyPositionMember?.corporation_company_member_id,
                    )?.value ?? 0;
            }
        }
    } else {
        console.log(2);
        // luồng Change-officer
        if (!member?.id) {
            if (defaultPosition?.corporation_company_member_id) {
                companyPosition.switch_percent = !!companyPositionMember?.own_percent;
            }
        } else {
            if (companyPositionMember?.id) {
                companyPosition.id = companyPositionMember.id;
            }
            companyPosition.is_appointed = defaultPosition
                ? companyPosition?.is_appointed
                : companyPositionMember?.is_appointed;
            companyPosition.number_allotted = isFinishFlow
                ? member?.CompanyShares?.reduce((acc, cur) => {
                      if (
                          !cur.corporation_company_member_id &&
                          cur.is_approved &&
                          cur.is_valid &&
                          !cur.is_received
                      ) {
                          return acc + cur.value;
                      }
                      return acc;
                  }, 0)
                : member?.CompanyShares?.reduce((acc, cur) => {
                      if (!cur.corporation_company_member_id && cur.is_approved && cur.is_valid) {
                          return acc + cur.value;
                      }
                      return acc;
                  }, 0);
            companyPosition.percent_of_interest = isFinishFlow
                ? member?.CompanyInterests?.reduce((acc, cur) => {
                      if (
                          !cur.corporation_company_member_id &&
                          cur.is_approved &&
                          cur.is_valid &&
                          !cur.is_received
                      ) {
                          return acc + cur.value;
                      }
                      return acc;
                  }, 0)
                : member?.CompanyInterests?.reduce((acc, cur) => {
                      if (!cur.corporation_company_member_id && cur.is_approved && cur.is_valid) {
                          return acc + cur.value;
                      }
                      return acc;
                  }, 0);
            companyPosition.own_percent = isFinishFlow
                ? member?.CompanyOwnerships?.reduce((acc, cur) => {
                      if (
                          !cur.corporation_company_member_id &&
                          cur.is_approved &&
                          cur.is_valid &&
                          !cur.is_received
                      ) {
                          return acc + cur.value;
                      }
                      return acc;
                  }, 0)
                : member?.CompanyOwnerships?.reduce((acc, cur) => {
                      if (!cur.corporation_company_member_id && cur.is_approved && cur.is_valid) {
                          return acc + cur.value;
                      }
                      return acc;
                  }, 0);
            companyPosition.date_of_appointment = defaultPosition
                ? companyPosition?.date_of_appointment
                : companyPositionMember?.date_of_appointment;
            companyPosition.corporation_company_member_id =
                defaultPosition?.corporation_company_member_id || null;

            if (companyPositionMember?.corporation_company_member_id) {
                companyPosition.corporation_company_member_id =
                    companyPositionMember?.corporation_company_member_id ?? null;
                companyPosition.switch_percent = !!member?.CompanyOwnerships?.length > 0;
                companyPosition.number_allotted =
                    member?.CompanyShares?.find(
                        (item) =>
                            item.corporation_company_member_id ===
                            companyPositionMember?.corporation_company_member_id,
                    )?.value ?? 0;
                companyPosition.own_percent =
                    member?.CompanyOwnerships?.find(
                        (item) =>
                            item.corporation_company_member_id ===
                            companyPositionMember?.corporation_company_member_id,
                    )?.value ?? 0;
                companyPosition.percent_of_interest =
                    member?.CompanyInterests?.find(
                        (item) =>
                            item.corporation_company_member_id ===
                            companyPositionMember?.corporation_company_member_id,
                    )?.value ?? 0;
            }
        }
    }

    if (contactPerson) {
        companyPosition = {
            ...companyPosition,
            is_contact_person: 1,
        };
    }

    /* Set Security question */
    const security_question1 = questionsAnswerOptions?.security_question1?.find((opt) =>
        opt.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
    );
    const security_question2 = questionsAnswerOptions?.security_question2?.find((opt) =>
        opt.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
    );
    const security_question3 = questionsAnswerOptions?.security_question3?.find((opt) =>
        opt.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
    );

    /* Nature of control */
    // nature_ownership
    const nature_ownership =
        questionsAnswerOptions?.nature_ownership?.find((op) =>
            op.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
        ) || questionsAnswerOptions?.nature_ownership?.[0];

    // nature_voting_rights
    const nature_voting_rights =
        questionsAnswerOptions?.nature_voting_rights?.find((op) =>
            op.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
        ) || questionsAnswerOptions?.nature_voting_rights?.[0];

    // nature_appoint_majority
    const nature_appoint_majority =
        questionsAnswerOptions?.nature_appoint_majority?.find((op) =>
            op.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
        ) || questionsAnswerOptions?.nature_appoint_majority?.[1];

    // nature_other_significant_control
    const nature_other_significant_control =
        questionsAnswerOptions?.nature_other_significant_control?.find((op) =>
            op.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
        ) || questionsAnswerOptions?.nature_other_significant_control?.[1];

    // firm
    const firm =
        questionsAnswerOptions?.firm?.find((op) =>
            op.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
        ) || questionsAnswerOptions?.firm?.[1];

    // firm_ownership
    const firm_ownership =
        questionsAnswerOptions?.firm_ownership?.find((op) =>
            op.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
        ) || questionsAnswerOptions?.firm_ownership?.[0];

    // firm_voting_rights
    const firm_voting_rights =
        questionsAnswerOptions?.firm_voting_rights?.find((op) =>
            op.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
        ) || questionsAnswerOptions?.firm_voting_rights?.[0];

    // firm_appoint_majority
    const firm_appoint_majority =
        questionsAnswerOptions?.firm_appoint_majority?.find((op) =>
            op.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
        ) || questionsAnswerOptions?.firm_appoint_majority?.[1];

    // firm_other_significant_control
    const firm_other_significant_control =
        questionsAnswerOptions?.firm_other_significant_control?.find((op) =>
            op.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
        ) || questionsAnswerOptions?.firm_other_significant_control?.[1];

    // trust
    const trust =
        questionsAnswerOptions?.trust?.find((op) =>
            op.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
        ) || questionsAnswerOptions?.trust?.[1];

    // trust_ownership
    const trust_ownership =
        questionsAnswerOptions?.trust_ownership?.find((op) =>
            op.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
        ) || questionsAnswerOptions?.trust_ownership?.[0];

    // trust_voting_rights
    const trust_voting_rights =
        questionsAnswerOptions?.trust_voting_rights?.find((op) =>
            op.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
        ) || questionsAnswerOptions?.trust_voting_rights?.[0];

    // trust_appoint_majority
    const trust_appoint_majority =
        questionsAnswerOptions?.trust_appoint_majority?.find((op) =>
            op.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
        ) || questionsAnswerOptions?.trust_appoint_majority?.[1];

    // trust_other_significant_control
    const trust_other_significant_control =
        questionsAnswerOptions?.trust_other_significant_control?.find((op) =>
            op.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
        ) || questionsAnswerOptions?.trust_other_significant_control?.[1];
    //Panama tax id
    const tax = questionsAnswerOptions?.tax?.find((opt) =>
        opt.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id)),
    );

    let returnMember = {
        id: member?.id || null,
        member_id: member?.id || null, // Field dùng tạm vì field trong react hook form tự động lấy id string
        __needValidate: needValidate, // Field dùng tạm để validate form tiện hơn, không dùng cho submit
        __company_country_id: companyCountryId, // Field dùng tạm để validate form tiện hơn, không dùng cho submit
        __notValidateSourceOfFund: hideSourceOfFund, // Field dùng tạm để validate form tiện hơn, không dùng cho submit
        type_member_id: member?.type_member_id || 1, // Individual || Corporation

        address: member?.address || '',
        // service_address: member?.service_address || '',
        email: member?.email || '',
        country_id: member?.country_id || null,
        phone_country_id: member?.phone_country_id || null,
        phone_number: member?.phone_number || '',
        CompanyPositions: [companyPosition],
        CompanyMemberReferences: [],
        is_approved: member?.is_approved,

        /* Individual */
        first_name: member?.first_name || '',
        middle_name: member?.middle_name || '',
        last_name: member?.last_name || '',
        full_name: member?.full_name || '',
        major: member?.major || '',
        passport: member?.passport || '',
        passport_expire_date: member?.passport_expire_date || null,
        passport_issue_date: member?.passport_issue_date || null,
        birthday: member?.birthday || null,
        address: member?.address || '',

        /* Corporation */
        corporation_date: member?.corporation_date || null,
        corporation_name: member?.corporation_name || '',
        corporation_number: member?.corporation_number || '',
        operation_address: isNil(member?.operation_address) ? '' : member?.operation_address,
        nature_business: isNil(member?.nature_business) ? '' : member?.nature_business,
        business_activity_array: member?.BusinessActivities?.map((item) => item.id) || [],
        OperationCountries: member?.CompanyMemberOperationCountries?.map((item) => item.id) || [],
        business_activity_detail: member?.business_activity_detail,

        /* Source of fund */
        asset_source_fund_cast_in_bank: member?.asset_source_fund_cast_in_bank || 0,
        asset_source_fund_other_money: member?.asset_source_fund_other_money || 0,
        asset_source_fund_real_estate: member?.asset_source_fund_real_estate || 0,
        asset_source_fund_other_detail: member?.asset_source_fund_other_detail || '',

        income_source_fund_investment: member?.income_source_fund_investment || 0,
        income_source_fund_other_money: member?.income_source_fund_other_money || 0,
        income_source_fund_self_employed: member?.income_source_fund_self_employed || 0,
        income_source_fund_other_detail: member?.income_source_fund_other_detail || '',

        /* Security Questions */
        security_question_1: security_question1?.id || null,
        security_answer_1:
            security_question1?.Answers.find(
                (ans) => ans.company_member_id === (member?.member_id || member?.id),
            )?.value_text || '',
        security_question_2: security_question2?.id || null,
        security_answer_2:
            security_question2?.Answers.find(
                (ans) => ans.company_member_id === (member?.member_id || member?.id),
            )?.value_text || '',
        security_question_3: security_question3?.id || null,
        security_answer_3:
            security_question3?.Answers.find(
                (ans) => ans.company_member_id === (member?.member_id || member?.id),
            )?.value_text || '',
        /* Nature of control */
        nature_ownership: nature_ownership?.id || null,
        nature_voting_rights: nature_voting_rights?.id || null,
        nature_appoint_majority: nature_appoint_majority?.id || null,
        nature_other_significant_control: nature_other_significant_control?.id || null,
        firm: firm?.id || null,
        firm_ownership: firm_ownership?.id || null,
        firm_voting_rights: firm_voting_rights?.id || null,
        firm_appoint_majority: firm_appoint_majority?.id || null,
        firm_other_significant_control: firm_other_significant_control?.id || null,
        trust: trust?.id || null,
        trust_ownership: trust_ownership?.id || null,
        trust_voting_rights: trust_voting_rights?.id || null,
        trust_appoint_majority: trust_appoint_majority?.id || null,
        trust_other_significant_control: trust_other_significant_control?.id || null,

        tax:
            tax?.Answers.find((ans) => ans.company_member_id === (member?.member_id || member?.id))
                ?.value_text || '',

        secretary: false,
        secretary_id: null,
        president: false,
        president_id: null,
        treasurer: false,
        treasurer_id: null,
    };

    if (companyCountryId === BVI_ID) {
        returnMember.birth_country_id = member?.birth_country_id || null;
    }

    if (companyCountryId === PANAMA_ID) {
        /* Xử lý cho reference */
        for (let i = 0; i < 2; i++) {
            let temp = {
                name: member?.CompanyMemberReferences?.[i]?.name || '',
                phone_number: member?.CompanyMemberReferences?.[i]?.phone_number || '',
                email: member?.CompanyMemberReferences?.[i]?.email || '',
                address: member?.CompanyMemberReferences?.[i]?.address || '',
                company_member_issuse_reference_id:
                    member?.CompanyMemberReferences?.[i]?.company_member_issuse_reference_id || '',
                phone_country_id: member?.CompanyMemberReferences?.[i]?.phone_country_id || null,
                fax: member?.CompanyMemberReferences?.[i]?.fax || '',
            };
            if (member?.CompanyMemberReferences?.[i]?.id) {
                temp.id = member?.CompanyMemberReferences?.[i]?.id;
            }
            returnMember.CompanyMemberReferences.push(temp);
        }

        if (typeMember === 'director') {
            /* Xử lý cho ký đại diện */
            const result = member?.CompanyPositions?.forEach((position) => {
                switch (position.company_member_type_id) {
                    case SECRETARY_TYPE_ID:
                        returnMember.secretary = true;
                        returnMember.secretary_id = position.id;
                        break;
                    case PRESIDENT_TYPE_ID:
                        returnMember.president = true;
                        returnMember.president_id = position.id;
                        break;
                    case TREASURER_TYPE_ID:
                        returnMember.treasurer = true;
                        returnMember.treasurer_id = position.id;
                        break;
                }
            });
        }
    }

    return returnMember;
};

export const getSubmitDataMember = (member, questionsAnswerOptions) => {
    let newMember = cloneDeep(member);

    if (newMember.type_member_id === 1) {
        /* Delete field dư thừa */
        delete newMember.corporation_date;
        delete newMember.corporation_name;
        delete newMember.corporation_number;

        /* Convert date */
        newMember.passport_expire_date = newMember.passport_expire_date
            ? format(newMember.passport_expire_date, DATE_DB_FORMAT)
            : newMember.passport_expire_date;
        newMember.passport_issue_date = newMember.passport_issue_date
            ? format(newMember.passport_issue_date, DATE_DB_FORMAT)
            : newMember.passport_issue_date;
        newMember.birthday = newMember.birthday
            ? format(newMember.birthday, DATE_DB_FORMAT)
            : newMember.birthday;
    } else {
        /* Delete field dư thừa */
        delete newMember.first_name;
        delete newMember.major;
        delete newMember.passport;
        delete newMember.passport_expire_date;
        delete newMember.passport_issue_date;
        delete newMember.birthday;

        /* Convert date */
        newMember.corporation_date = newMember.corporation_date
            ? format(newMember.corporation_date, DATE_DB_FORMAT)
            : newMember.corporation_date;
    }

    newMember.CompanyPositions.forEach((position, index) => {
        let newPosition = cloneDeep(position);
        if (position.date_of_appointment) {
            newPosition.date_of_appointment = format(
                new Date(newPosition.date_of_appointment),
                DATE_DB_FORMAT,
            );
            newMember.CompanyPositions[index] = newPosition;
        } else {
            delete position.date_of_appointment;
        }
        if (typeof newPosition.switch_percent === 'boolean') {
            if (newPosition.switch_percent) {
                newMember.CompanyPositions[index].number_allotted = 0;
            } else {
                newMember.CompanyPositions[index].own_percent = 0;
            }
            delete newMember.CompanyPositions[index].switch_percent;
        }
        newMember.CompanyPositions[index].is_appointed =
            Number(newMember.CompanyPositions[index]?.is_appointed) || 0;

        //👇 xóa field không được gửi lên BE
        delete newMember.CompanyPositions[index].CompanyMemberType;
        delete newMember.CompanyPositions[index].is_approved;
    });

    /* Xử lý Security Question */
    const security_question1 = {
        option_id: newMember.security_question_1,
        value_text: newMember.security_answer_1,
        is_chosen: 1,
    };
    const security_question2 = {
        option_id: newMember.security_question_2,
        value_text: newMember.security_answer_2,
        is_chosen: 1,
    };
    const security_question3 = {
        option_id: newMember.security_question_3,
        value_text: newMember.security_answer_3,
        is_chosen: 1,
    };
    const tax = {
        option_id: 764,
        value_text: newMember.tax,
        is_chosen: 1,
    };
    newMember.answersForTask = [];
    if (newMember.security_question_1) {
        newMember.answersForTask.push(security_question1);
    }
    if (newMember.security_question_2) {
        newMember.answersForTask.push(security_question2);
    }
    if (newMember.security_question_3) {
        newMember.answersForTask.push(security_question3);
    }
    if (newMember.tax) {
        newMember.answersForTask.push(tax);
    }
    /* Xử lý Nature of control */
    const questionNameNatures = [
        'nature_ownership',
        'nature_voting_rights',
        'nature_appoint_majority',
        'nature_other_significant_control',
    ];
    const questionNameFirms = [
        'firm_ownership',
        'firm_voting_rights',
        'firm_appoint_majority',
        'firm_other_significant_control',
    ];
    const questionNameTrusts = [
        'trust_ownership',
        'trust_voting_rights',
        'trust_appoint_majority',
        'trust_other_significant_control',
    ];
    const answerShowFirmTrust = {
        nature_ownership: 47,
        nature_voting_rights: 51,
        nature_appoint_majority: 56,
        nature_other_significant_control: 58,
    };
    let hasFirmTrust = true;
    questionNameNatures.map((questionName) => {
        let arrAnswer = getAnswersForTaskOneQuestion(questionsAnswerOptions, member, questionName);
        newMember.answersForTask = newMember.answersForTask.concat(arrAnswer);
        if (member[questionName] != answerShowFirmTrust[questionName]) {
            hasFirmTrust = false;
        }
    });
    if (hasFirmTrust) {
        let arrAnswer_firm = getAnswersForTaskOneQuestion(questionsAnswerOptions, member, 'firm');
        let arrAnswer_trust = getAnswersForTaskOneQuestion(questionsAnswerOptions, member, 'trust');
        newMember.answersForTask = newMember.answersForTask.concat(arrAnswer_firm, arrAnswer_trust);
        if (member.firm == 59) {
            // firm: 59-Yes
            questionNameFirms.map((questionName) => {
                let arrAnswer = getAnswersForTaskOneQuestion(
                    questionsAnswerOptions,
                    member,
                    questionName,
                );
                newMember.answersForTask = newMember.answersForTask.concat(arrAnswer);
            });
        }
        if (member.trust == 61) {
            // trust: 61-Yes
            questionNameTrusts.map((questionName) => {
                let arrAnswer = getAnswersForTaskOneQuestion(
                    questionsAnswerOptions,
                    member,
                    questionName,
                );
                newMember.answersForTask = newMember.answersForTask.concat(arrAnswer);
            });
        }
    } else {
        let arrTurnOffAnswer_firm = getTurnOffAnswers(questionsAnswerOptions, member, 'firm');
        let arrTurnOffAnswer_trust = getTurnOffAnswers(questionsAnswerOptions, member, 'trust');
        newMember.answersForTask = newMember.answersForTask.concat(
            arrTurnOffAnswer_firm,
            arrTurnOffAnswer_trust,
        );
        questionNameFirms.map((questionName) => {
            let arrTurnOffAnswer = getTurnOffAnswers(questionsAnswerOptions, member, questionName);
            newMember.answersForTask = newMember.answersForTask.concat(arrTurnOffAnswer);
        });
        questionNameTrusts.map((questionName) => {
            let arrTurnOffAnswer = getTurnOffAnswers(questionsAnswerOptions, member, questionName);
            newMember.answersForTask = newMember.answersForTask.concat(arrTurnOffAnswer);
        });
    }
    if (!newMember?.answersForTask?.length) delete newMember.answersForTask;
    delete newMember.security_question_1;
    delete newMember.security_answer_1;
    delete newMember.security_question_2;
    delete newMember.security_answer_2;
    delete newMember.security_question_3;
    delete newMember.security_answer_3;
    delete newMember.nature_ownership;
    delete newMember.nature_voting_rights;
    delete newMember.nature_appoint_majority;
    delete newMember.nature_other_significant_control;
    delete newMember.firm;
    delete newMember.firm_ownership;
    delete newMember.firm_voting_rights;
    delete newMember.firm_appoint_majority;
    delete newMember.firm_other_significant_control;
    delete newMember.trust;
    delete newMember.trust_ownership;
    delete newMember.trust_voting_rights;
    delete newMember.trust_appoint_majority;
    delete newMember.trust_other_significant_control;

    delete newMember.tax;

    delete newMember.secretary;
    delete newMember.secretary_id;
    delete newMember.president;
    delete newMember.president_id;
    delete newMember.treasurer;
    delete newMember.treasurer_id;

    delete newMember.__needValidate;
    delete newMember.is_approved;
    delete newMember.__company_country_id;
    delete newMember.__notValidateSourceOfFund;

    /* Xử lý ký cho panama */
    if (member.president) {
        const tempP = {
            company_member_type_id: PRESIDENT_TYPE_ID,
            is_appointed: 1,
        };
        if (member.president_id) tempP.id = member.president_id;
        newMember.CompanyPositions.push(tempP);
    }
    if (member.secretary) {
        const tempS = {
            company_member_type_id: SECRETARY_TYPE_ID,
            is_appointed: 1,
        };
        if (member.secretary_id) tempS.id = member.secretary_id;
        newMember.CompanyPositions.push(tempS);
    }
    if (member.treasurer) {
        const tempT = {
            company_member_type_id: TREASURER_TYPE_ID,
            is_appointed: 1,
        };
        if (member.treasurer_id) tempT.id = member.treasurer_id;
        newMember.CompanyPositions.push(tempT);
    }

    return newMember;
};

const getAnswersForTaskOneQuestion = (questionsAnswerOptions, member, questionName) => {
    let answersForTask = [];
    if (!member[questionName]) return [];
    try {
        // clear old selection of this question if has
        let old_answers = getTurnOffAnswers(questionsAnswerOptions, member, questionName, true);
        answersForTask = answersForTask.concat(old_answers);

        let q = {
            option_id: parseInt(member[questionName]),
            is_chosen: 1,
            // company_member_id: member.id
        };
        answersForTask.push(q);
    } catch (error) {
        console.log(error);
    }
    return answersForTask;
};

const getTurnOffAnswers = (questionsAnswerOptions, member, questionName, passExisting = false) => {
    let answersForTask = [];
    try {
        // clear old selection of this question if has
        let old_answers = questionsAnswerOptions?.[questionName]?.filter((op) =>
            op.Answers.find((ans) => ans.company_member_id === member.id),
        );
        if (old_answers && old_answers.length > 0) {
            old_answers.map((opt) => {
                if (passExisting) {
                    if (opt.id != member[questionName]) {
                        let turnOff = {
                            option_id: parseInt(opt.id),
                            is_chosen: 0,
                            // company_member_id: member.id
                        };
                        answersForTask.push(turnOff);
                    }
                } else {
                    let turnOff = {
                        option_id: parseInt(opt.id),
                        is_chosen: 0,
                        // company_member_id: member.id
                    };
                    answersForTask.push(turnOff);
                }
            });
        }
    } catch (error) {
        console.log(error);
    }
    return answersForTask;
};

const updateSelectableFlag = (node) => {
    if (node.children && node.children.length > 0) {
        node.selectable = false;
        node.children.forEach((child) => updateSelectableFlag(child));
    } else {
        node.selectable = true;
    }
};

export const buildTree = (data) => {
    const map = {};
    let tree = [];

    data.forEach((item) => {
        const newItem = { ...item };
        newItem.title = `${item.sic_code}-${item.name}`;
        newItem.value = `${item.sic_code}-${item.name}`;
        map[newItem.id] = { ...newItem, children: [] };
    });

    data.forEach((item) => {
        const current = map[item.id];
        const parentItem = map[item.parent_id];

        if (parentItem) {
            parentItem.children.push(current);
        } else {
            tree.push(current);
        }
        map[item.id] = current;
    });

    // Recursively set selectable for children
    tree.forEach((child) => updateSelectableFlag(child));

    return tree;
};

export const isLLC = (entity_id, company_country_id) => {
    if (SPECIAL_LLC_COUNTRIES.some((country) => country.id === company_country_id)) {
        if (entity_id === ENTITY_TYPES.LLC.id) {
            return true;
        }
    }
    return false;
};
export const isBelizeLLC = (entity_id, company_country_id) => {
    return entity_id === ENTITY_TYPES.LLC.id && company_country_id === BELIZE_ID;
};
export const isUK_LLP = (entity_id, company_country_id) => {
    return entity_id === ENTITY_TYPES.LLP.id && company_country_id === UK_ID;
};
export const getQuestionAnswerOptions = (questions) => {
    let Questions = {};
    questions.forEach((question) => {
        let item = question.Options;
        switch (question.id) {
            case 17:
                Questions = {
                    ...Questions,
                    security_question1: item,
                };
                break;
            case 18:
                Questions = {
                    ...Questions,
                    security_question2: item,
                };
                break;
            case 19:
                Questions = {
                    ...Questions,
                    security_question3: item,
                };
                break;
            case 20:
                Questions = {
                    ...Questions,
                    nature_ownership: item,
                };
                break;
            case 21:
                Questions = { ...Questions, nature_voting_rights: item };
                break;
            case 22:
                Questions = { ...Questions, nature_appoint_majority: item };
                break;
            case 23:
                Questions = { ...Questions, nature_other_significant_control: item };
                break;
            case 24:
                Questions = { ...Questions, firm: item };
                break;
            case 25:
                Questions = { ...Questions, trust: item };
                break;
            case 26:
                Questions = { ...Questions, firm_ownership: item };
                break;
            case 27:
                Questions = { ...Questions, firm_voting_rights: item };
                break;
            case 28:
                Questions = { ...Questions, firm_appoint_majority: item };
                break;
            case 29:
                Questions = { ...Questions, firm_other_significant_control: item };
                break;
            case 30:
                Questions = { ...Questions, trust_ownership: item };
                break;
            case 31:
                Questions = { ...Questions, trust_voting_rights: item };
                break;
            case 32:
                Questions = { ...Questions, trust_appoint_majority: item };
                break;
            case 33:
                Questions = { ...Questions, trust_other_significant_control: item };
                break;
            case 179:
                Questions = { ...Questions, tax: item };
        }
    });
    return Questions;
};

const isFieldInvalid = (data, key) => {
    if (!data) return true;
    if (data?.hasOwnProperty(key)) {
        if (!data?.[key]) {
            return false;
        }
    }
    return true;
};

export const getContactInfoAllowFields = (contactInfo, comparedData) => {
    const fields = {
        first_name:
            contactInfo?.first_name && isFieldInvalid(comparedData, 'first_name') ? false : true,
        middle_name:
            contactInfo?.middle_name && isFieldInvalid(comparedData, 'middle_name') ? false : true,
        last_name:
            contactInfo?.last_name && isFieldInvalid(comparedData, 'last_name') ? false : true,
        full_name:
            contactInfo?.full_name && isFieldInvalid(comparedData, 'full_name') ? false : true,
        email: contactInfo?.email && isFieldInvalid(comparedData, 'email') ? false : true,
        phone_country_id:
            contactInfo?.phone_country_id && isFieldInvalid(comparedData, 'phone_country_id')
                ? false
                : true,
        phone_number:
            contactInfo?.phone_number && isFieldInvalid(comparedData, 'phone_number')
                ? false
                : true,
        address: contactInfo?.address && isFieldInvalid(comparedData, 'address') ? false : true,
        address_country_id:
            contactInfo?.address_country_id && isFieldInvalid(comparedData, 'address_country_id')
                ? false
                : true,
        city: contactInfo?.city && isFieldInvalid(comparedData, 'city') ? false : true,
        state: contactInfo?.state && isFieldInvalid(comparedData, 'state') ? false : true,
        postal_code:
            contactInfo?.postal_code && isFieldInvalid(comparedData, 'postal_code') ? false : true,
        birthday: contactInfo?.birthday && isFieldInvalid(comparedData, 'birthday') ? false : true,
        passport: contactInfo?.passport && isFieldInvalid(comparedData, 'passport') ? false : true,
        passport_expire_date:
            contactInfo?.passport_expire_date &&
            isFieldInvalid(comparedData, 'passport_expire_date')
                ? false
                : true,
        country_id:
            contactInfo?.country_id && isFieldInvalid(comparedData, 'country_id') ? false : true,
    };
    return fields;
};
export const getCompanyInfoAllowFields = (initValues, comparedData) => {
    const fields = {
        business_activity_array:
            initValues?.business_activity_array?.length > 0 &&
            comparedData?.hasOwnProperty('CompanyHasBusinessActivity') &&
            comparedData?.CompanyHasBusinessActivity?.length != 0
                ? false
                : true,
        business_activity_detail:
            initValues?.business_activity_detail &&
            isFieldInvalid(comparedData, 'business_activity_detail')
                ? false
                : true,
        source_fund:
            initValues?.source_fund && isFieldInvalid(comparedData, 'source_fund') ? false : true,
        country_operations:
            initValues?.country_operations?.length > 0 &&
            comparedData?.hasOwnProperty('OperationCountries') &&
            comparedData?.OperationCountries?.length != 0
                ? false
                : true,
        address: initValues?.address && isFieldInvalid(comparedData, 'address') ? false : true,
        operating_country_id:
            initValues?.operating_country_id && isFieldInvalid(comparedData, 'operating_country_id')
                ? false
                : true,
        city: initValues?.city && isFieldInvalid(comparedData, 'operating_city') ? false : true,
        postal_code:
            initValues?.postal_code && isFieldInvalid(comparedData, 'operating_postal_code')
                ? false
                : true,
        state: initValues?.state && isFieldInvalid(comparedData, 'operating_state') ? false : true,
        business_annual_report:
            initValues?.business_annual_report?.length > 0 &&
            comparedData?.hasOwnProperty('business_annual_report') &&
            comparedData?.business_annual_report?.length != 0
                ? false
                : true,
    };
    return fields;
};
export const getCompanyMembersAllowFields = (initValues, comparedData) => {
    const fields = initValues.map((member) => ({
        //individual
        first_name:
            member?.first_name &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'first_name',
            )
                ? false
                : true,
        middle_name:
            member?.middle_name &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'middle_name',
            )
                ? false
                : true,
        last_name:
            member?.last_name &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'last_name',
            )
                ? false
                : true,
        full_name:
            member?.full_name &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'full_name',
            )
                ? false
                : true,
        major:
            member?.major &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'major',
            )
                ? false
                : true,
        email:
            member?.email &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'email',
            )
                ? false
                : true,
        phone_country_id:
            member?.phone_country_id &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'phone_country_id',
            )
                ? false
                : true,
        phone_number:
            member?.phone_number &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'phone_number',
            )
                ? false
                : true,
        country_id:
            member?.country_id &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'country_id',
            )
                ? false
                : true,
        passport:
            member?.passport &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'passport',
            )
                ? false
                : true,
        birthday:
            member?.birthday &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'birthday',
            )
                ? false
                : true,
        passport_issue_date:
            member?.passport_issue_date &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'passport_issue_date',
            )
                ? false
                : true,
        address:
            member?.address &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'address',
            ) &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'address',
            )
                ? false
                : true,
        passport_expire_date:
            member?.passport_expire_date &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'passport_expire_date',
            )
                ? false
                : true,
        //corporation
        corporation_name:
            member?.corporation_name &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'corporation_name',
            )
                ? false
                : true,
        corporation_number:
            member?.corporation_number &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'corporation_number',
            )
                ? false
                : true,
        nature_business:
            member?.nature_business &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'nature_business',
            )
                ? false
                : true,
        operation_address:
            member?.operation_address &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'operation_address',
            )
                ? false
                : true,
        business_activity_array:
            member?.business_activity_array?.length > 0 &&
            comparedData
                ?.find((item) => item.id === member.id)
                ?.hasOwnProperty('BusinessActivities') &&
            comparedData?.find((item) => item.id === member.id)?.BusinessActivities?.length != 0
                ? false
                : true,
        business_activity_detail:
            member?.business_activity_detail &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'business_activity_detail',
            )
                ? false
                : true,
        OperationCountries:
            member?.OperationCountries?.length > 0 &&
            comparedData
                ?.find((item) => item.id === member.id)
                ?.hasOwnProperty('CompanyMemberOperationCountries') &&
            comparedData?.find((item) => item.id === member.id)?.CompanyMemberOperationCountries
                ?.length != 0
                ? false
                : true,
        corporation_date:
            member?.corporation_date &&
            isFieldInvalid(
                comparedData?.find((item) => item.id === member.id),
                'corporation_date',
            )
                ? false
                : true,
        CompanyMemberReferences: member?.CompanyMemberReferences?.map((reference) => ({
            name:
                reference?.name &&
                isFieldInvalid(
                    comparedData
                        ?.find((item) => member.id === item.id)
                        ?.CompanyMemberReferences?.find((i) => i.id === reference.id),
                    'name',
                )
                    ? false
                    : true,
            phone_country_id:
                reference?.phone_country_id &&
                isFieldInvalid(
                    comparedData
                        ?.find((item) => member.id === item.id)
                        ?.CompanyMemberReferences?.find((i) => i.id === reference.id),
                    'phone_country_id',
                )
                    ? false
                    : true,
            phone_number:
                reference?.phone_number &&
                isFieldInvalid(
                    comparedData
                        ?.find((item) => member.id === item.id)
                        ?.CompanyMemberReferences?.find((i) => i.id === reference.id),
                    'phone_number',
                )
                    ? false
                    : true,
            fax:
                reference?.fax &&
                isFieldInvalid(
                    comparedData
                        ?.find((item) => member.id === item.id)
                        ?.CompanyMemberReferences?.find((i) => i.id === reference.id),
                    'fax',
                )
                    ? false
                    : true,
            email:
                reference?.email &&
                isFieldInvalid(
                    comparedData
                        ?.find((item) => member.id === item.id)
                        ?.CompanyMemberReferences?.find((i) => i.id === reference.id),
                    'email',
                )
                    ? false
                    : true,
            address:
                reference?.address &&
                isFieldInvalid(
                    comparedData
                        ?.find((item) => member.id === item.id)
                        ?.CompanyMemberReferences?.find((i) => i.id === reference.id),
                    'address',
                )
                    ? false
                    : true,
        })),
    }));
    return fields;
};
export const getBusinessInfoAllowFields = (initValues, comparedData, questionData) => {
    const fields = {
        business_full_name:
            initValues?.business_full_name && isFieldInvalid(comparedData, 'full_name')
                ? false
                : true,
        business_address:
            initValues?.business_address && isFieldInvalid(comparedData, 'address') ? false : true,
        business_city:
            initValues?.business_city && isFieldInvalid(comparedData, 'city') ? false : true,
        business_country_id:
            initValues?.business_country_id && isFieldInvalid(comparedData, 'country_id')
                ? false
                : true,
        business_month_end:
            initValues?.business_month_end && isFieldInvalid(comparedData, 'month_end')
                ? false
                : true,
        business_postal_code:
            initValues?.business_postal_code && isFieldInvalid(comparedData, 'postal_code')
                ? false
                : true,
        business_state:
            initValues?.business_state && isFieldInvalid(comparedData, 'state') ? false : true,
        bahamas_question_1:
            initValues?.bahamas_question_1 && !questionData?.find((q) => q.id == 9)?.isHighlight
                ? false
                : true,
        bahamas_question_2:
            initValues?.bahamas_question_2 && !questionData?.find((q) => q.id == 10)?.isHighlight
                ? false
                : true,
        bahamas_question_3:
            initValues?.bahamas_question_3 && !questionData?.find((q) => q.id == 11)?.isHighlight
                ? false
                : true,
        regulated_by_1:
            initValues?.regulated_by_1 && !questionData?.find((q) => q.id == 11)?.isHighlight
                ? false
                : true,
        regulated_by_2:
            initValues?.regulated_by_2 && !questionData?.find((q) => q.id == 11)?.isHighlight
                ? false
                : true,
        regulated_by_3:
            initValues?.regulated_by_3 && !questionData?.find((q) => q.id == 11)?.isHighlight
                ? false
                : true,
        bahamas_question_4:
            initValues?.bahamas_question_3 && !questionData?.find((q) => q.id == 13)?.isHighlight
                ? false
                : true,
        name_of_license:
            initValues?.name_of_license && !questionData?.find((q) => q.id == 13)?.isHighlight
                ? false
                : true,
        bahamas_question_5:
            initValues?.bahamas_question_5 && !questionData?.find((q) => q.id == 15)?.isHighlight
                ? false
                : true,
        name_of_foreign_auth:
            initValues?.name_of_foreign_auth && !questionData?.find((q) => q.id == 15)?.isHighlight
                ? false
                : true,
        country_of_foreign_auth:
            initValues?.country_of_foreign_auth &&
            !questionData?.find((q) => q.id == 15)?.isHighlight
                ? false
                : true,
        delaware_question_1:
            initValues?.delaware_question_1 && !questionData?.find((q) => q.id == 7)?.isHighlight
                ? false
                : true,
        number_of_employees:
            initValues?.number_of_employees && !questionData?.find((q) => q.id == 8)?.isHighlight
                ? false
                : true,
        svg_question_1:
            initValues?.svg_question_1 && !questionData?.find((q) => q.id == 3)?.isHighlight
                ? false
                : true,
        website_url:
            initValues?.website_url && !questionData?.find((q) => q.id == 3)?.isHighlight
                ? false
                : true,
        svg_question_2:
            initValues?.svg_question_2 && !questionData?.find((q) => q.id == 5)?.isHighlight
                ? false
                : true,
        svg_website_ip:
            initValues?.svg_website_ip && !questionData?.find((q) => q.id == 5)?.isHighlight
                ? false
                : true,
        business_volume:
            initValues?.business_volume && !questionData?.find((q) => q.id == 181)?.isHighlight
                ? false
                : true,
        business_value:
            initValues?.business_value && !questionData?.find((q) => q.id == 180)?.isHighlight
                ? false
                : true,
    };
    return fields;
};

import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import CompanyStuctureStepper from './step3/stepper';
import InstructionIncorporationStep3 from './step3/stepper';
import { emphasize } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import PageLoadingEffect from '../../../common/PageLoadingEffect';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // height: 250,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export default function Step3({
    setActiveStep,
    activeStep,
    step4Back,
    setStep4Back,
    isHongkong,
    isDelaware,
    isSVG,
    body,
    setBody,
    hasShowSelectFullName,
}) {
    // console.log(body);
    const classes = useStyles();
    const [isActiveStep, setIsActiveStep] = useState({ back: false, next: false });

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        if (isActiveStep.back) {
            // child step 1 back
            // setActiveStep((prevActiveStep) => prevActiveStep - 1);
            setActiveStep(1);
        }
        if (isActiveStep.next) {
            // child step 4 next
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }, [isActiveStep]);
    return (
        <div>
            <div className={classes.root + ' position-relative'}>
                <InstructionIncorporationStep3
                    setIsActiveParentStep={setIsActiveStep}
                    setActiveParentStep={setActiveStep}
                    activeParentStep={activeStep}
                    step4Back={step4Back}
                    setStep4Back={setStep4Back}
                    isHongkong={isHongkong}
                    isDelaware={isDelaware}
                    isSVG={isSVG}
                    body={body}
                    setBody={setBody}
                    hasShowSelectFullName={hasShowSelectFullName}
                />
            </div>

            {/* <div className="d-none">
                {activeStep > 0 && (
                    <Button size="large" onClick={handleBack} className={classes.button}>
                        Back
                    </Button>
                )}
                {activeStep < 3 &&  (
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        // disabled={body.packageIncorp ? false : true}
                        onClick={() => {
                            // preParam();
                            handleNext();
                        }}
                        className={classes.button}
                        style={{ color: '#fff' }}
                    >
                        Next
                    </Button>
                )}
            </div> */}
        </div>
    );
}

export const processSteps = [
    { process_step_id: 17, step: 0, name: "Step 1" },
    { process_step_id: 18, step: 1, name: "Step 2" },
    { process_step_id: 19, step: 2, name: "Step 3" },
    { process_step_id: 20, step: 3, name: "Step 4" },
];

export function getStep(process_step_id){
    let step = { process_step_id: '', step: '', name: "Unknown step" };
    processSteps.some(item => {
        if (process_step_id === item.process_step_id) {
            step = item;
            return true;
        }
        // process_step_id === item.process_step_id ? (step = item) : null;
        
    });
    return step;
}

export function getLinkContentRoute(linkObj, id) {
    return '/order-management/order/'+ id + linkObj.content;
}
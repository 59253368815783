import { createSlice } from '@reduxjs/toolkit';
import {
    fetchOrderInfo,
    getHolidayAction,
    updateBusinessPlanFormAction,
    setBankingIdAction,
    getListBanking,
    getBankingProcessTreeAction, approveProcessAction, deleteFileAction, getProcessDetailById,
} from './asyncThunk';

export const initState = {
    orderInfo: null,
    loadOrderInfo: 'idle', // loading, error

    activeProcess: null,

    errorUpdateContactInfo: null,

    // reject banking
    listBanking: [],

    /* States cho holiday */
    holidays: [],
    holidaysMap: {},
    loadingGetHoliday: 'idle', // loading, error
    errorGetHoliday: null,

    /* Banking process tree */
    processTree: null,
    loadingProcessTree: 'idle',
    errorProcessTree: null,

    /* Approve process state */
    loadingApproveProcess: 'idle',
    errorApproveProcess: null,

    /* State for upload file */
    loadingUploadFile: 'idle',
    errorUploadFile: null,

    /* State for delete file */
    loadingDeleteFile: {},

    /* Sau khi approve process BE trả về process mới lấy id để get detail step ở step banking tool approve */
    currentProcessID: null
};

export const bankingSlice = createSlice({
    name: 'banking',
    initialState: initState,
    reducers: {
        resetBankingState: (state,action) => {
            Object.entries(action.payload).map(([key,value]) => {
                state[key] = value
            })
        },
        updateOrderInfo: (state, action) => {
            state.orderInfo = action.payload;
        },
        updateLoadOrderInfo: (state, action) => {
            state.loadOrderInfo = action.payload;
        },
        updateActiveProcess: (state, action) => {
            console.log({action})
            state.activeProcess = action.payload;
        },
        updateLoadingUploadFileAction: (state, action) => {
            state.loadingUploadFile = action.payload;
        },
        updateErrorUploadFileAction: (state, action) => {
            state.errorUploadFile = action.payload;
        },
    },
    extraReducers: (builder) => {
        // getListBanking
        builder.addCase(getProcessDetailById.pending, (state, action) => {
            state.loadOrderInfo = 'loading';
        });
        builder.addCase(getProcessDetailById.fulfilled, (state, action) => {
            state.loadOrderInfo = 'idle';
            state.orderInfo = action.payload;
        });
        builder.addCase(getProcessDetailById.rejected, (state, action) => {
            state.loadOrderInfo = 'error';
        });

        // getListBanking
        builder.addCase(getListBanking.pending, (state, action) => {
        });
        builder.addCase(getListBanking.fulfilled, (state, action) => {
            state.listBanking = action.payload;
        });
        builder.addCase(getListBanking.rejected, (state, action) => {
        });
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(setBankingIdAction.pending, (state, action) => {
        });
        builder.addCase(setBankingIdAction.fulfilled, (state, action) => {
            state.orderInfo = action.payload;
        });
        builder.addCase(setBankingIdAction.rejected, (state, action) => {
        });
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchOrderInfo.pending, (state, action) => {
            state.loadOrderInfo = 'loading';
        });
        builder.addCase(fetchOrderInfo.fulfilled, (state, action) => {
            state.orderInfo = action.payload;
            state.loadOrderInfo = 'idle';
        });
        builder.addCase(fetchOrderInfo.rejected, (state, action) => {
            state.loadOrderInfo = 'error';
        });
        // update contact information step 1
        builder.addCase(updateBusinessPlanFormAction.pending, (state, action) => {
        });
        builder.addCase(updateBusinessPlanFormAction.fulfilled, (state, action) => {
            // console.log('after update', action.payload);
            state.orderInfo = action.payload.data.data;
        });
        builder.addCase(updateBusinessPlanFormAction.rejected, (state, action) => {
            if (action.payload) {
                state.errorUpdateContactInfo = action.payload
            } else {
                state.errorUpdateContactInfo = action.error
            }
        });

        // Get holiday action
        builder.addCase(getHolidayAction.pending, (state, action) => {
            state.loadingGetHoliday = 'loading';
        });
        builder.addCase(getHolidayAction.fulfilled, (state, action) => {
            state.holidays = action.payload;
            const map = {};
            state.holidays.forEach(holiday => {
                map[holiday.date] = holiday;
            });
            state.holidaysMap = map;
            state.loadingGetHoliday = 'idle';
        });
        builder.addCase(getHolidayAction.rejected, (state, action) => {
            state.loadingGetHoliday = 'error';
            if (action.payload) {
                state.errorGetHoliday = action.payload
            } else {
                state.errorGetHoliday = action.error
            }
        });

        // Banking process tree
        builder.addCase(getBankingProcessTreeAction.pending, (state, action) => {
            state.loadingProcessTree = 'loading';
        });
        builder.addCase(getBankingProcessTreeAction.fulfilled, (state, action) => {

            state.processTree = action.payload?.filter(process => process.id !== 38) || [];
            state.loadingProcessTree = 'idle';
        });
        builder.addCase(getBankingProcessTreeAction.rejected, (state, action) => {
            state.loadingProcessTree = 'error';
            if (action.payload) {
                state.errorProcessTree = action.payload
            } else {
                state.errorProcessTree = action.error
            }
        });



        // Approve process
        builder.addCase(approveProcessAction.pending, (state, action) => {
            state.loadingApproveProcess = 'loading';
        });
        builder.addCase(approveProcessAction.fulfilled, (state, action) => {
            state.loadingApproveProcess = 'idle';
            console.log(action)
            if(action?.payload){
                state.currentProcessID = action.payload[0].id
            }
        });
        builder.addCase(approveProcessAction.rejected, (state, action) => {
            state.loadingApproveProcess = 'error';
            if (action.payload) {
                state.errorApproveProcess = action.payload
            } else {
                state.errorApproveProcess = action.error
            }
        });

        // Delet file
        builder.addCase(deleteFileAction.pending, (state, action) => {
            state.loadingDeleteFile[action.meta.arg] = 'loading';
        });
        builder.addCase(deleteFileAction.fulfilled, (state, action) => {
            state.loadingDeleteFile[action.meta.arg] = 'idle';
        });
        builder.addCase(deleteFileAction.rejected, (state, action) => {
            state.loadingDeleteFile[action.meta.arg] = 'error';
        });

    },

});

const { actions, reducer } = bankingSlice;
export const {
    updateOrderInfo,
    updateLoadOrderInfo,
    updateActiveProcess,
    updateLoadingUploadFileAction,
    updateErrorUploadFileAction,
    resetBankingState
} = actions
export default reducer

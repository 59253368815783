import React from 'react';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { toAbsoluteUrl } from '_metronic/_helpers';

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export function ErrorPage() {
    const navigate = useNavigate();
    const classes = useStyles();
    const handleNext = () => {
        navigate('/');
    };
    return (
        <div className="d-flex flex-column flex-root">
            <div
                className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
                style={{
                    backgroundImage: `url(${toAbsoluteUrl('/media/error/bg1.jpg')})`,
                }}
            >
                <h1
                    className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
                    style={{ fontSize: '120px' }}
                >
                    404
                </h1>
                <p className="font-size-h3 font-weight-light">OOPS! Something went wrong here</p>
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    style={{ width: '280px', color: '#fff' }}
                >
                    Go home
                </Button>
            </div>
        </div>
    );
}

export const IMG_EXTENSION = [
    '.jpg', '.jpeg', '.png'
];
export const DOCS_EXTENSION = [
    '.pdf', '.doc', '.docx'
];
export const VIDEO_EXTENSION = [
    '.mov', '.mp4', '.avi', '.wmv'
];

const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']

export const ALL_ACCEPT_EXTENSION = [...IMG_EXTENSION, ...DOCS_EXTENSION, ...VIDEO_EXTENSION];

export const isFilesAccept = (files, extensions) => {
    if (!files?.length) {
        return true;
    }

    for (let i = 0; i < files.length; i++) {
        const platform = window.navigator?.userAgentData?.platform || window.navigator.platform;
        const  fileExt = '.' + files[i].name.split('.').pop().toLowerCase();
        if(fileExt === '.mkv' && windowsPlatforms.includes(platform)) {
            continue;
        }
        if (!extensions.includes(fileExt)) {
            return false
        }
    }

    return true;
}

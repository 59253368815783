import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import InputField from 'components/form-controls/InputField';

export default function FatcaStep2({
    fatcaInitValueStep2,
    fatcaHandleSubmitStep2,
    usPersonCheck,
    setUsPersonCheck,
    setFatcaActiveStep,
    setDataStep2,
}) {
    const form = useForm({
        defaultValues: fatcaInitValueStep2,
    });

    return (
        <>
            <h6 className={'mb-4'}>
                Please tick either (a) or (b) or (c) and complete as appropriate.{' '}
            </h6>
            <form onSubmit={form.handleSubmit(fatcaHandleSubmitStep2)}>
                <RadioGroup
                    row
                    aria-label="option_declaration_US_person"
                    name="option_declaration_US_person"
                    onChange={(e, value) => {
                        form.setValue('option_declaration_US_person', value);
                        setUsPersonCheck(value);
                    }}
                >
                    <FormControlLabel
                        checked={usPersonCheck == 1}
                        value={1}
                        control={<Radio color="secondary" />}
                        label={
                            '(a) I confirm that I am a U.S. Person for tax purposes (green card holder or resident under the substantial presence test) and my U.S. federal taxpayer identifying number (U.S. TIN) is as follows: '
                        }
                    />
                    <div className="ml-5 pl-5 input-line">
                        <InputField
                            form={form}
                            name="taxpayer_id_number"
                            placeholder="Enter your U.S. TIN"
                            onChange={() => {
                                setDataStep2(form.getValues());
                            }}
                            hideSuccessHelper
                        />
                    </div>
                    <FormControlLabel
                        checked={usPersonCheck == 2}
                        value={2}
                        control={<Radio color="secondary" />}
                        label={
                            '(b) I confirm that I was born in the U.S. (or a U.S. territory) but am no longer a U.S. citizen as I have voluntarily surrendered my citizenship as evidenced by the attached documents.'
                        }
                    />
                    <FormControlLabel
                        checked={usPersonCheck == 3}
                        value={3}
                        control={<Radio color="secondary" />}
                        label={'(c) I confirm that I am not a U.S. Person for tax purposes.'}
                    />
                </RadioGroup>
                <Button
                    variant="contained"
                    size="large"
                    type="button"
                    onClick={() => setFatcaActiveStep(0)}
                    className={'mr-2'}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    style={{ color: '#fff' }}
                >
                    Next
                </Button>
            </form>
        </>
    );
}

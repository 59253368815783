import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from 'yup';
import { useFieldArray, useForm } from 'react-hook-form';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import { yupResolver } from '@hookform/resolvers/yup';

export default function FatcaStep3({
    fatcaInitValueStep3,
    fatcaHandleSubmitStep3,
    countries,
    setDataStep3,
    setFatcaActiveStep,
}) {
    const fatcaStep3Schema = Yup.object().shape({
        DeclarationTaxes: Yup.array()
            .of(
                Yup.object().shape({
                    country_id: Yup.number().required('Country is required'),
                    tax_reference_type: Yup.string().required(
                        'Tax reference number type is required',
                    ),
                    tax_reference_number: Yup.string().required('Tax reference number is required'),
                }),
            )
            .min(1, 'at least 1')
            .required('required'),
    });

    const form = useForm({
        defaultValues: fatcaInitValueStep3,
        resolver: yupResolver(fatcaStep3Schema),
        mode: 'onTouched',
    });
    const fieldArray = useFieldArray({
        control: form.control,
        name: 'DeclarationTaxes',
    });

    const handleSkip = () => {
        setDataStep3(null);
        setFatcaActiveStep(3);
    };

    return (
        <>
            <h6 style={{ color: '#F2994A' }}>
                Complete section 3 if you have non - U.S. tax residencies.
            </h6>
            <h6 className={'mb-4'}>
                I hereby confirm that I am, for the purposes of that country´s tax system, resident
                in the following countries (indicate the tax country).{' '}
            </h6>
            <form onSubmit={form.handleSubmit(fatcaHandleSubmitStep3)}>
                <div>
                    <div>
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{ color: 'white' }}
                            className={'mb-2'}
                            type="button"
                            startIcon={<AddIcon />}
                            disabled={!(fieldArray.fields.length < 3)}
                            onClick={() => {
                                fieldArray.append({
                                    country_id: null,
                                    tax_reference_type: '',
                                    tax_reference_number: '',
                                });
                            }}
                        >
                            Add
                        </Button>
                    </div>
                    <div>
                        {fieldArray.fields.map((field, index) => {
                            return (
                                <div key={field.id} className={'row'}>
                                    <div className="col-md-3 input-line">
                                        <label>Country</label>
                                        <SelectField
                                            form={form}
                                            name={`DeclarationTaxes[${index}].country_id`}
                                            options={countries}
                                            placeholder="Choose country"
                                            onChange={(value) => {
                                                form.setValue(
                                                    `DeclarationTaxes[${index}].country_id`,
                                                    value?.id,
                                                );
                                                form.setValue(
                                                    `DeclarationTaxes[${index}].country`,
                                                    value,
                                                );
                                                setDataStep3(form.getValues());
                                            }}
                                            hideHelperText
                                            hideInputHelperText
                                        />
                                        {form.formState.errors?.DeclarationTaxes?.[index]
                                            ?.country_id && (
                                            <div className="invalid-feedback d-block">
                                                Country is require!
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-3 input-line">
                                        <label>Tax reference number type</label>
                                        <InputField
                                            form={form}
                                            name={`DeclarationTaxes[${index}].tax_reference_type`}
                                            placeholder="Type"
                                            onChange={() => {
                                                setDataStep3(form.getValues());
                                            }}
                                            hideSuccessHelper
                                        />
                                    </div>
                                    <div className="col-md-3 input-line">
                                        <label>Tax reference number</label>
                                        <InputField
                                            form={form}
                                            name={`DeclarationTaxes[${index}].tax_reference_number`}
                                            placeholder="Number"
                                            onChange={() => {
                                                setDataStep3(form.getValues());
                                            }}
                                            hideSuccessHelper
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        {index !== 0 && (
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => fieldArray.remove(index)}
                                                size="large"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <br />
                <Button
                    variant="contained"
                    size="large"
                    type="button"
                    onClick={() => setFatcaActiveStep(1)}
                    className={'mr-2'}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    style={{ color: '#fff' }}
                    className={'mr-2'}
                >
                    Next
                </Button>
                <Button size="large" color="primary" type="button" onClick={handleSkip}>
                    Skip
                </Button>
            </form>
        </>
    );
}

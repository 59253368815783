import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';

const SecurityQuestion = ({ index, body, values, form, disabled = false }) => {
    const [security_question_1, set_security_question_1] = useState({
        id: 0,
        content: '',
        question_id: null,
        Answers: [],
    });
    const [security_question_2, set_security_question_2] = useState({
        id: 0,
        content: '',
        question_id: null,
        Answers: [],
    });
    const [security_question_3, set_security_question_3] = useState({
        id: 0,
        content: '',
        question_id: null,
        Answers: [],
    });
    const [option1, setOption1] = useState([]);
    const [option2, setOption2] = useState([]);
    const [option3, setOption3] = useState([]);

    const onChangeQuestion1 = (value) => {
        if (
            value.content === security_question_2?.content ||
            value.content === security_question_3?.content
        ) {
            Swal.fire({
                icon: 'warning',
                text: 'Could not choose the same question',
                // timer: 2000,
            });

            if (security_question_1.id) {
                form.setValue(`members.${index}.security_question_1`, security_question_1.id);
            } else {
                form.setValue(`members.${index}.security_question_1`, undefined);
            }

            return;
        }

        if (security_question_1 && security_question_1.id != value.id) {
            set_security_question_1(value);
            form.setValue(`members.${index}.security_question_1`, value.id);
        }
    };

    const onChangeQuestion2 = (value) => {
        if (
            value.content === security_question_1?.content ||
            value.content === security_question_3?.content
        ) {
            Swal.fire({
                icon: 'warning',
                text: 'Could not choose the same question',
                // timer: 2000,
            });

            if (security_question_2.id) {
                form.setValue(`members.${index}.security_question_2`, security_question_2.id);
            } else {
                form.setValue(`members.${index}.security_question_2`, undefined);
            }

            return;
        }

        if (security_question_2 && security_question_2.id != value.id) {
            set_security_question_2(value);
            form.setValue(`members.${index}.security_question_2`, value.id);
        }
    };

    const onChangeQuestion3 = (value) => {
        if (
            value.content === security_question_1?.content ||
            value.content === security_question_2?.content
        ) {
            Swal.fire({
                icon: 'warning',
                text: 'Could not choose the same question',
                // timer: 2000,
            });

            if (security_question_3.id) {
                form.setValue(`members.${index}.security_question_3`, security_question_3.id);
            } else {
                form.setValue(`members.${index}.security_question_3`, undefined);
            }

            return;
        }

        if (security_question_3 && security_question_3.id != value.id) {
            set_security_question_3(value);
            form.setValue(`members.${index}.security_question_3`, value.id);
        }
    };

    useEffect(() => {
        const currentMember = form.getValues(`members.[index]`);
        if (!currentMember?.answer_question_1) {
            set_security_question_1({
                id: 0,
                content: '',
                question_id: null,
                Answers: [],
            })
        }
        if (!currentMember?.answer_question_2) {
            set_security_question_2({
                id: 0,
                content: '',
                question_id: null,
                Answers: [],
            })
        }
        if (!currentMember?.answer_question_3) {
            set_security_question_3({
                id: 0,
                content: '',
                question_id: null,
                Answers: [],
            })
        }
    }, [form.watch(`members.${index}.individual`)]);

    useEffect(() => {
        const id = values?.[index].member_id;
        if (
            body.Task.IncorporationCountry.Questions.find((q) => q.id === 17).Options.find((op) => {
                return op.Answers.find((ans) => ans.company_member_id === id);
            })
        ) {
            set_security_question_1(
                body.Task.IncorporationCountry.Questions.find((q) => q.id === 17).Options.find(
                    (op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    },
                ),
            );
            form.setValue(
                `members.${index}.security_question_1`,
                body.Task.IncorporationCountry.Questions.find((q) => q.id === 17).Options.find(
                    (op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    },
                )?.id,
            );
            form.setValue(
                `members.${index}.answer_question_1`,
                body.Task.IncorporationCountry.Questions.find((q) => q.id === 17)
                    .Options.find((op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    })
                    .Answers.find((ans) => ans.company_member_id === id)?.value_text,
            );
        }
        if (
            body.Task.IncorporationCountry.Questions.find((q) => q.id === 18).Options.find((op) => {
                return op.Answers.find((ans) => ans.company_member_id === id);
            })
        ) {
            set_security_question_2(
                body.Task.IncorporationCountry.Questions.find((q) => q.id === 18).Options.find(
                    (op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    },
                ),
            );
            form.setValue(
                `members.${index}.security_question_2`,
                body.Task.IncorporationCountry.Questions.find((q) => q.id === 18).Options.find(
                    (op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    },
                )?.id,
            );
            form.setValue(
                `members.${index}.answer_question_2`,
                body.Task.IncorporationCountry.Questions.find((q) => q.id === 18)
                    .Options.find((op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    })
                    .Answers.find((ans) => ans.company_member_id === id)?.value_text,
            );
        }
        if (
            body.Task.IncorporationCountry.Questions.find((q) => q.id === 19).Options.find((op) => {
                return op.Answers.find((ans) => ans.company_member_id === id);
            })
        ) {
            set_security_question_3(
                body.Task.IncorporationCountry.Questions.find((q) => q.id === 19).Options.find(
                    (op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    },
                ),
            );
            form.setValue(
                `members.${index}.security_question_3`,
                body.Task.IncorporationCountry.Questions.find((q) => q.id === 19).Options.find(
                    (op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    },
                )?.id,
            );
            form.setValue(
                `members.${index}.answer_question_3`,
                body.Task.IncorporationCountry.Questions.find((q) => q.id === 19)
                    .Options.find((op) => {
                        return op.Answers.find((ans) => ans.company_member_id === id);
                    })
                    .Answers.find((ans) => ans.company_member_id === id)?.value_text,
            );
        }

        setOption1(body.Task.IncorporationCountry.Questions.find((q) => q.id === 17).Options);
        setOption2(body.Task.IncorporationCountry.Questions.find((q) => q.id === 18).Options);
        setOption3(body.Task.IncorporationCountry.Questions.find((q) => q.id === 19).Options);
    }, [body, values?.[index]?.member_id, values?.[index]?.id]);

    return (
        <>
            <div className="source_of_funds container-fluid p-0 m-0">
                <p style={{ fontSize: '10px' }} className="col-md-12 mb-7 pl-0">
                    SECURITIES QUESTIONS
                </p>
                <div className="row justify-content-between">
                    <div style={{ width: '49%' }}>
                        <div className="col-12 mb-7 input-line has-unit">
                            <label>
                                {
                                    body.Task.IncorporationCountry.Questions.find(
                                        (q) => q.id === 17,
                                    ).content
                                }
                            </label>
                            <SelectField
                                form={form}
                                className="fw-500"
                                name={`members.${index}.security_question_1`}
                                placeholder="Choose question"
                                options={option1 || []}
                                hideInputHelperText
                                hideSuccessHelper
                                value={security_question_1}
                                onChange={onChangeQuestion1}
                                disabled={disabled}
                                getOptionLabel={(option) => option?.content || ''}
                            />
                        </div>
                        <div className="col-12 mb-7 input-line has-unit">
                            <label>
                                {
                                    body.Task.IncorporationCountry.Questions.find(
                                        (q) => q.id === 18,
                                    ).content
                                }
                            </label>
                            <SelectField
                                form={form}
                                className="fw-500"
                                name={`members.${index}.security_question_2`}
                                placeholder="Choose question"
                                options={option2 || []}
                                hideInputHelperText
                                hideSuccessHelper
                                value={security_question_2}
                                onChange={onChangeQuestion2}
                                disabled={disabled}
                                getOptionLabel={(option) => option?.content || ''}
                            />
                        </div>

                        <div className="col-12 mb-7 input-line has-unit">
                            <label>
                                {
                                    body.Task.IncorporationCountry.Questions.find(
                                        (q) => q.id === 19,
                                    ).content
                                }
                            </label>
                            <SelectField
                                form={form}
                                className="fw-500"
                                name={`members.${index}.security_question_3`}
                                placeholder="Choose question"
                                options={option3 || []}
                                hideInputHelperText
                                hideSuccessHelper
                                value={security_question_3}
                                onChange={onChangeQuestion3}
                                disabled={disabled}
                                getOptionLabel={(option) => option?.content || ''}
                            />
                        </div>
                    </div>

                    <div style={{ width: '49%' }}>
                        <div className="col-12 mb-7 input-line has-unit">
                            <label>Answer 1</label>
                            <InputField
                                type="text"
                                name={`members.${index}.answer_question_1`}
                                form={form}
                                placeholder="Type first of 3 letters"
                                onChange={(e) => {
                                    form.setValue(
                                        `members.${index}.answer_question_1`,
                                        e.target.value,
                                    );
                                }}
                                disabled={disabled}
                                hideSuccessHelper
                                inputProps={{ maxLength: 3 }}
                            />
                        </div>
                        <div className="col-12 mb-7 input-line has-unit">
                            <label>Answer 2</label>
                            <InputField
                                type="text"
                                name={`members.${index}.answer_question_2`}
                                form={form}
                                placeholder="Type first of 3 letters"
                                onChange={(e) => {
                                    form.setValue(
                                        `members.${index}.answer_question_2`,
                                        e.target.value,
                                    );
                                }}
                                disabled={disabled}
                                hideSuccessHelper
                                inputProps={{ maxLength: 3 }}
                            />
                        </div>
                        <div className="col-12 mb-7 input-line">
                            <label>Answer 3</label>
                            <InputField
                                type="text"
                                name={`members.${index}.answer_question_3`}
                                form={form}
                                placeholder="Type first of 3 letters"
                                onChange={(e) => {
                                    form.setValue(
                                        `members.${index}.answer_question_3`,
                                        e.target.value,
                                    );
                                }}
                                disabled={disabled}
                                hideSuccessHelper
                                inputProps={{ maxLength: 3 }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SecurityQuestion;

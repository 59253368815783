import React, { useEffect, useState, useMemo } from 'react';
import { useOrdersUIContext } from '../OrdersUIContext';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import * as actionsEnum from 'app/pages/_redux/enum/enumActions';
import { Icon } from '@mui/material';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import ReactSelectField from 'components/form-controls/ReactSelectField';
import MetronicInputField from 'components/form-controls/MetronicInputField';
import DatePickerHookField from 'components/form-controls/DatePickerHookField';

const valueServiceType = [
    { id: '', label: 'All' },
    { id: '1', label: 'Incorporation' },
    { id: '2', label: 'Banking' },
];

export function OrdersFilter() {
    const OrdersUIContext = useOrdersUIContext();
    const [countryList, setCountryList] = useState([]);
    const dispatch = useDispatch();

    const form = useForm({
        defaultValues: {
            country_id: '',
            service_type_id: '1',
            created_time: null,
            search: '',
        },
    });

    const ordersUIProps = useMemo(() => {
        return {
            setQueryParams: OrdersUIContext.setQueryParams,
            queryParams: OrdersUIContext.queryParams,
            createParamsUrl: OrdersUIContext.createParamsUrl,
        };
    }, [OrdersUIContext]);

    useEffect(() => {
        dispatch(actionsEnum.fetchCountriesHasService());
    }, []);

    const { countriesHasService } = useSelector(
        (state) => ({
            countriesHasService: state.enum.countriesHasService,
        }),
        shallowEqual,
    );

    useEffect(() => {
        form.setValue('service_type_id', ordersUIProps.queryParams.filter?.service_type_id || '');

        ordersUIProps.queryParams.filter?.created_time &&
            form.setValue(
                'created_time_temp',
                moment(ordersUIProps?.queryParams.filter?.created_time),
            );

        ordersUIProps.queryParams.filter?.search !== ''
            ? form.setValue('search', ordersUIProps.queryParams.filter.search)
            : form.setValue('search', '');
    }, [ordersUIProps.queryParams.filter]);

    useEffect(() => {
        form.setValue('country_id', countryList && ordersUIProps.queryParams.filter?.country_id);
    }, [countryList, ordersUIProps.queryParams.filter]);

    useEffect(() => {
        if (countriesHasService?.length) {
            setCountryList(countriesHasService.map((obj) => ({ ...obj, value: obj.id })));
        }
    }, [countriesHasService]);

    const onSubmit = form.handleSubmit(ordersUIProps.createParamsUrl);

    return (
        <div className="orders-filter">
            <form className="form form-label-right">
                <div className="row mb-4">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-4">
                        <ReactSelectField
                            form={form}
                            name="country_id"
                            placeholder="Select Country"
                            options={countryList}
                            onChange={onSubmit}
                            multiple
                            helperText={
                                <small className="form-text text-muted">
                                    <b>Select</b> Country
                                </small>
                            }
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-4">
                        <ReactSelectField
                            form={form}
                            name="service_type_id"
                            placeholder="All"
                            options={valueServiceType}
                            onChange={onSubmit}
                            getOptionLabel={(option) => option?.label || ''}
                            disableClearable={false}
                            helperText={
                                <small className="form-text text-muted">
                                    <b>Select</b> Service Type
                                </small>
                            }
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-4">
                        <div className="has-icon">
                            <DatePickerHookField
                                form={form}
                                name="created_time_temp"
                                placeholderText="Order created date"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                maxDate={Date.now()}
                                isClearable
                                dropdownMode="select"
                                hideHelperText
                                onChange={(value) => {
                                    form.setValue(
                                        'created_time',
                                        value ? moment(value).format('YYYY-MM-DD') : null,
                                    );
                                    onSubmit();
                                }}
                            />
                            <Icon className="icon far fa-calendar" />
                        </div>
                        <small className="form-text text-muted">
                            <b>Select</b> Order created date
                        </small>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-4">
                        <MetronicInputField
                            form={form}
                            name="search"
                            placeholder="Search"
                            onChange={onSubmit}
                            helperText={
                                <small className="form-text text-muted">
                                    <b>Search</b> Order ID
                                </small>
                            }
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}

import axios from 'axios';

export const INVOICE_URL = '/customer_account/invoice';

export const INVOICE_ORDER_URL = '/customer_account/order/refund';

export function getAllInvoices() {
    return axios.get(INVOICE_URL);
}

export function findInvoices(queryParams) {
    return axios.get(`${INVOICE_URL}`, { params: queryParams });
}

export function deleteInvoice(id) {
    return axios.delete(`${INVOICE_URL}/${id}`);
}

export function refundInvoice(id, data) {
    return axios.post(`${INVOICE_ORDER_URL}/${id}`, data);
}

import { useLocation } from 'react-router-dom';
import {useMemo} from 'react';

/**
 *
 * @returns {URLSearchParams}
 */
export function useQueryParams() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

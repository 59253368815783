import React from 'react';
// import { categoryColor } from '../../../../../../../src/_metronic/_helpers';
/*
    categoryColor, ServiceType id:
    New Incorporp: 1, 50
    Banking Assistance: 2, 41

    Left, right:
    Additional Services: left: 17, right: 36.
*/

export const TypeFormatter = (cell, row, rowIndex, formatExtraData) => {
    let incorporation = cell.some((item) => item.Service.ServiceType.id === 1 || item.Service.ServiceType.id === 50);
    let banking = cell.some((item) => item.Service.ServiceType.id === 2 || item.Service.ServiceType.id === 41);
    let postIncorporation = cell.some((item) => [34, 39, 24].includes(item.Service.ServiceType.id));
    let additionalServices = cell.some(
        (item) => ![1, 2, 18, 19, 34, 50, 41, 39, 24].includes(item.Service.ServiceType.id),
    );
    let bookeepingProcess = cell.some(
        (item) => [18].includes(item.Service.ServiceType.id),
    );
    return (
        <div className="group-tag">
            {incorporation && (
                <span
                    className="order-tag"
                    // style={{ backgroundColor: '#A160FC'}} // màu tím theo Figma
                    style={{ backgroundColor: '#27AE60' }}
                >
                    Incorporation
                </span>
            )}
            {banking && (
                <span className="order-tag" style={{ backgroundColor: '#FFA800' }}>
                    Banking
                </span>
            )}
            {postIncorporation && (
                <span className="order-tag" style={{ backgroundColor: '#905d32' }}>
                    Post-incorporation
                </span>
            )}

            {additionalServices && (
                <span className="order-tag" style={{ backgroundColor: '#905d32' }}>
                    Additional Services
                </span>
            )}
            {bookeepingProcess && (
                <span className="order-tag" style={{ backgroundColor: '#00D4FF' }}>
                    Annual Renewal
                </span>
            )}
        </div>
    );
};

import React from 'react';

const mapImgType = {
    'yoga-man': '/media/waiting-screen/yoga-man.svg',
    'office-man': '/media/waiting-screen/office-man.svg',
    'running-man': '/media/waiting-screen/running-man.svg',
};

/**
 *
 * @param imgType string: yoga-man | office-man | running-man
 * @param secondScreen
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function WaitingScreen({ imgType = 'yoga-man', secondScreen, children }) {
    return (
        <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ minHeight: 470 }}
        >
            <img
                src={mapImgType[imgType] || mapImgType['yoga-man']}
                className="img-fluid mb-5"
                width={220}
                alt="Your proposed meeting time was sent to the bank for acceptance."
            />

            <div style={{ maxWidth: '550px' }}>
                {children ? (
                    children
                ) : secondScreen ? (
                    <>
                        <p style={{ color: '#007EFF' }}>
                            Your proposed meeting time was sent to the bank for acceptance.
                        </p>
                        <p>
                            After coming to an agreed-upon date & time, you will receive a
                            notification about the upcoming video call.
                        </p>
                        <p>
                            Throughout the meeting, you will be asked to sign an application form
                            and some sort of onboarding papers should the bank pre-approve your
                            application
                        </p>
                    </>
                ) : (
                    <p style={{ color: '#007EFF' }}>
                        Your documents are being processed. It will take around 2-3 days.
                    </p>
                )}
            </div>
        </div>
    );
}

export default WaitingScreen;

import { Radio } from '@mui/material';
import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { AccordionLayout } from './section-filter-left';
import Button from '@mui/material/Button';
import {useNavigate, useParams} from 'react-router-dom';
import { getLinkContentRoute } from '../../OrderManagermentUIHelpers';
import { LINK } from 'app/pages/services/constants/banking_constants';
import {
    setBankingIdAction,
    fetchOrderInfo,
    getListBanking,
    approveProcessAction,
} from 'app/pages/_redux/banking/asyncThunk';
import {toast} from 'react-toastify'
import { updateActiveProcess } from '../../../../_redux/banking/slice';



const BankItem = ({ isShowCheck, bank, chooseBank, callbackAction }) => {
    return (
        <div className='mb-3 ml-10'>
            {
                isShowCheck ?
                    <Radio onClick={() => console.log(bank.id)} value={bank.id} onChange={(e, val) => callbackAction(+e.target.value)} checked={chooseBank === bank.id} name='select-country' /> : null
            }
            <img src={'https://bbcincorp.com' + bank.image} width='50' height='50' />
            <p className="m-0 ml-3 d-inline-block">{bank.name || bank.Bank.name}</p>
        </div>
    )
}

const showRejectedBankList = (listRejectedBank) => {
    if (listRejectedBank.length > 0) {
        return listRejectedBank.map((bank, i) => {
            return <BankItem key={i} bank={bank} />
        })
    }
    return 'None..'
}

const isHasRejectedBank = (listRejectedBank, bankID) => {
    return listRejectedBank.some(rejectBank => rejectBank.bank_id === bankID)
}

const showListBankByCountry = (groupCountries, listBank, listRejectedBank, chooseBank, setChooseBank) => {
    if (listBank && Object.entries(groupCountries).length > 0) {
        return Object.entries(groupCountries).map(([key, value], i) => {
            return (
                <div className='mt-10' key={i}>
                    <h6>{value}</h6>
                    <div style={{ borderBottom: '1px solid #ccc', marginBottom: '10px' }}></div>
                    {
                        listBank.filter(bank => !isHasRejectedBank(listRejectedBank, bank.id) && bank.Country.id === +key).map((bank, i) => {
                            return (
                                <BankItem key={i} isShowCheck={true} bank={bank} chooseBank={chooseBank} callbackAction={(idBank) => {
                                    console.log('Bank ID: ', idBank)
                                    setChooseBank(idBank)
                                }} />
                            )
                        })
                    }
                </div>
            )
        })
    }
    return 'None..'
}

const groupCountry = (listBank, selectedFilterCountry) => {
    let groupBankByCountry = {}
    if (listBank) {
        if (selectedFilterCountry && selectedFilterCountry.id !== -1) {
            const filteredBank = listBank.find(bank => bank.Country.id === selectedFilterCountry.id)
            if (filteredBank) {
                groupBankByCountry[filteredBank.Country.id] = filteredBank.Country.name
            }
            return groupBankByCountry
        }
        listBank.map(bank => {
            groupBankByCountry[bank.Country.id] = bank.Country.name
        })
    }
    return groupBankByCountry
}

const addMoreItemCountry = (countries) => {
    if (countries && countries.length > 0) {
        return [{ id: -1, name: 'All' }, ...countries]
    }
    return countries
}

const SectionFilterResult = ({ countries, filterResult, isLoading, isRefresh }) => {
    const { id } = useParams();
    const [chooseBank, setChooseBank] = useState(null);
    const [selectedFilterCountry, setSelectedFilterCountry] = useState({ id: -1, name: 'All' })
    const [listBankReject, setListBankReject] = useState([])
    const {orderInfo,listBanking, activeProcess} = useSelector(state => state.banking)

    const groupCountries = groupCountry(filterResult, selectedFilterCountry);
    const countriesFiltered = filterResult.map(item => item.country_id);
    let countriesOptions = countries.filter(item => {
        return countriesFiltered.includes(item.id)
    });
    countriesOptions = addMoreItemCountry(countriesOptions);

    const dispatch = useDispatch()

    useEffect(() => {
        /*
            data tu fetchOrderInfo ko giong voi data ProcessTree nen ko the lay dung field activeProcess.Processes[0].id
        */
        // ( async () => {
        //     const resultAction = await dispatch(fetchOrderInfo(id));
        //     if (fetchOrderInfo.fulfilled.match(resultAction)) {
        //         const {payload: { Task: { Processes } }} = resultAction;
        //         const [process] = Processes;
        //         if (!process) return;
        //         dispatch(updateActiveProcess(process));
        //     }
        // })()
    }, [])

    useEffect(() => {
        (async () => {
                // console.log('order id: ', id)
                try{
                    const listBank = await dispatch(getListBanking(id))
                    const rejectedBank = listBank.payload.filter(e => e.status === 2)
                    setListBankReject(rejectedBank)
                }catch(err){}
        })()
    }, [activeProcess])


    // reset chooseBank when filter result changed
    useEffect(() => {
        setChooseBank(null)
    }, [filterResult]);

    let navigate = useNavigate();

    const handleNext = async () => {
        /* Xử lý approve step sau khi set bank */
        const resultActionApprove = await dispatch(approveProcessAction({id: activeProcess?.Processes[0]?.id, bank_id: chooseBank}));
        if (!approveProcessAction.fulfilled.match(resultActionApprove)) {
            toast.error(resultActionApprove?.payload?.data || resultActionApprove?.error?.message || 'Something went wrong!');
            return;
        }
        // redirect step kế tiếp
        if(resultActionApprove.payload){
            return navigate(getLinkContentRoute(LINK.BANKING, id));
        }
        // refetch để hiện thị màn hình chờ
        else {
            isRefresh()
        }
    }

    return (
        <>

            {/* REJECTED BANK */}
            <AccordionLayout title={'Rejected'} disabledIcon={true} expanded={true} styles={{
                'box-shadow': 'none',
                'border-left': '1px solid rgba(95, 95, 95, 0.15)',
                'border-bottom': '1px solid rgba(95, 95, 95, 0.15)',
            }}>
                <div className='listRejectedBank'>
                    {
                        showRejectedBankList(listBankReject.map(item => item.Bank))
                    }
                </div>
            </AccordionLayout>

            {/* FILTER COUNTRY */}
            <div className="d-flex justify-content-end mt-10 mb-6">
                <div className='mr-5' style={{ width: '200px' }}>
                    <Select
                        className="react-select"
                        name="country_id"
                        id="country-select"
                        options={countriesOptions}
                        isLoading={isLoading}
                        isDisabled={isLoading}
                        isSearchable
                        isClearable={false}
                        placeholder={'Choose country'}
                        getOptionLabel={(option) => option.name}
                        onChange={(value) => {
                            setChooseBank(null)
                            setSelectedFilterCountry(value)
                        }}
                        isOptionSelected={(option) => {
                            return selectedFilterCountry && selectedFilterCountry.id === option.id;
                            }
                        }
                    >
                    </Select>
                </div>
            </div>

            {/* RESULT FILTER */}

            <div className='mr-5'>
                <div className='listCountry mb-10'>
                    {/* <h6>Country Name</h6> */}
                    <div className='listBank'>
                        {
                            !isLoading ?
                                showListBankByCountry(groupCountries, filterResult, listBankReject, chooseBank, setChooseBank) :
                                'Loading...'
                        }
                    </div>
                </div>
            </div>

            <div className='text-right'>
                <Button
                    className="mr-5 mt-5 mb-5"
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    style={{ color: '#fff', position: 'absolute', bottom: '0', right: '0' }}
                    disabled={!chooseBank}
                    onClick={handleNext}
                >
                    Next
                </Button>
            </div>


        </>
    );
};

export default SectionFilterResult;


import React from 'react';
import InFlow from './transactions_frequency/inflow';
import OutFlow from './transactions_frequency/outflow';

export default function TransactionsFrequency({ form }) {
    return (
        <>
            <p className="mb-3 fw-500">Amount & frequency of expected transactions (monthly):</p>
            <div className="row">
                <div className="col-xl-4 col-sm-6 col-12 mb-7">
                    <div className="rad-8 box-shadow-3 p-5">
                        <p className="text-uppercase text-center fw-500">INFLOW</p>
                        <InFlow form={form} />
                    </div>
                </div>
                <div className="col-xl-4 col-sm-6 col-12 mb-7">
                    <div className="rad-8 box-shadow-3 p-5">
                        <p className="text-uppercase text-center fw-500">OUTFLOW</p>
                        <OutFlow form={form} />
                    </div>
                </div>
            </div>
        </>
    )
}
import React, { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';
import Switch from '@mui/material/Switch';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import moment from 'moment';
import Swal from 'sweetalert2';
import References from '../../../../../order-form/instruction-incorporation/component/step3/references/References';
import DirectorCorporation from '../../../../../order-form/instruction-incorporation/component/step3/step3-helper/director-corporation';
import DirectorIndividual from '../../../../../order-form/instruction-incorporation/component/step3/step3-helper/director-individual';
import Nominee from '../../../../../order-form/instruction-incorporation/component/step3/step3-helper/nominee';
import Represented from '../../../../../order-form/instruction-incorporation/component/step3/step3-helper/represented';
import {
    EmptyMemberCommon,
    memberCommonYupValidate,
} from '../../../../../order-form/instruction-incorporation/component/step3/step3-helper/helpers';
import SourseForFunds from '../../../../../order-form/instruction-incorporation/component/step3/step3-helper/sourse-for-funds';
import PageLoadingEffect from '../../../../common/PageLoadingEffect';
import { updateBusinessPlanFormAction } from '../../../../../../../_redux/banking/asyncThunk';
import * as actionsEnum from '../../../../../../../_redux/enum/enumActions';
import { toast } from 'react-toastify';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { COUNTRIES_NOT_DISABLED_BANKING_FORM } from '../../../../constant';
import { cloneDeep, get, uniq } from 'lodash';
import { CAYMAN_ID, RAK_ID, UK_ID } from 'constants/country';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchOrderForm } from 'app/pages/_redux/order-form/orderFormActions';
import { useParams } from 'react-router-dom/dist';

const fieldArraySchema = ({ hasShowSelectFullName }) => {
    return Yup.object({
        members: Yup.array().of(
            Yup.object().shape({
                full_name: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: true,
                        then: Yup.string()
                            .nullable()
                            .required(
                                hasShowSelectFullName
                                    ? 'Please select your full name!'
                                    : 'Full name is required!',
                            ),
                    }),
                first_name:
                    hasShowSelectFullName &&
                    Yup.string()
                        .nullable()
                        .when('individual', {
                            is: true,
                            then: Yup.string().nullable().required('First name is required!'),
                        }),
                middle_name: hasShowSelectFullName && Yup.string().nullable(),
                last_name:
                    hasShowSelectFullName &&
                    Yup.string()
                        .nullable()
                        .when('individual', {
                            is: true,
                            then: Yup.string().nullable().required('Last name is required!'),
                        }),
                occupation: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: true,
                        then: Yup.string().nullable().required('Occupation is required!'),
                    }),
                phone: Yup.string()
                    .nullable()
                    .matches(/^[0-9]*$/, 'Please enter number!')
                    .required('Phone number is required!'),
                email: Yup.string()
                    .nullable()
                    .required('Email is required!')
                    .email('Please enter valid email!'),
                company_name: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string().required('Company name is required!'),
                    }),
                company_number: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string().required('Company number is required'),
                    }),
                country_of_incorporation: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string().required('Country of Incorporation is required!'),
                    }),
                registered_address: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string().required('Registered Address is required!'),
                    }),
                date_of_incorporation: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string().required('Date of incorporation is required!'),
                    }),
                business_activity_array: Yup.array()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.array()
                            .nullable()
                            .min(1, 'Business activities is required!')
                            .required('Business activities is required!'),
                    }),
                business_activity_detail: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string()
                            .nullable()
                            .when('business_activity_detail', (value, schema) => {
                                return Yup.string()
                                    .nullable()
                                    .test(
                                        'required',
                                        'Please write a complete description of your business activities at least 10 words',
                                        (val) => val && val.trim().split(/\s+/).length >= 10,
                                    );
                            }),
                    }),
                country_operations: Yup.array()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.array()
                            .nullable()
                            .min(1, 'Countries operation is required!')
                            .required('Countries operation is required!'),
                    }),
                other_asset: Yup.string()
                    .nullable()
                    .when('showOther', {
                        is: true,
                        then: Yup.string().required("Please provide other asset's name."),
                    }),
                other_money: Yup.number()
                    .transform((value) =>
                        isNaN(value) || value === undefined || value === null ? 0 : value,
                    )
                    .nullable()
                    .when('other_asset', {
                        is: (value) => value && value.length > 0,
                        then: Yup.number().min(1, `Please provide other asset's amount`),
                    }),
                other_detail_income: Yup.string()
                    .nullable()
                    .when('showOtherIncome', {
                        is: true,
                        then: Yup.string().required("Please provide other income's name"),
                    }),
                other_money_income: Yup.number()
                    .transform((value) =>
                        isNaN(value) || value === undefined || value === null ? 0 : value,
                    )
                    .nullable()
                    .when('other_detail_income', {
                        is: (value) => value && value.length > 0,
                        then: Yup.number().min(1, `Please provide other asset's amount`),
                    }),

                total_asset: Yup.number()
                    .nullable()
                    .when('isSVG', {
                        is: true,
                        then: Yup.number()
                            .moreThan(0)
                            .required('Please fill up at least one fields!'),
                    }),

                total_income: Yup.number()
                    .nullable()
                    .when('isSVG', {
                        is: true,
                        then: Yup.number()
                            .moreThan(0)
                            .required('Please fill up at least one fields!'),
                    }),

                is_represented: Yup.boolean(),

                // yup validate references
                ...memberCommonYupValidate,
            }),
        ),
    });
};

export default function Step2({
    setActiveStep,
    activeStep,
    isHongkong,
    isSVG,
    body,
    setBody,
    isLoading,
    setIsLoading,
    onSubmit,
    hasShowSelectFullName,
}) {
    const { id } = useParams();
    const { orderInfo, businessActivityWithCountry } = useSelector(
        (state) => ({
            orderInfo: state.orderForm.orderInfo,
            businessActivityWithCountry: state.enum.businessActivityWithCountry,
        }),
        shallowEqual,
    );
    const { countriesOptions } = useSelector((state) => state.enum);
    const dispatch = useDispatch();
    const initForm = {
        members: body.companyStructure?.Directors,
    };
    const [countryOperation, setCountryOperation] = useState([]);
    const [tabValue, setTabValue] = React.useState(0);

    const form = useForm({
        defaultValues: {
            members: initForm.members,
        },
        resolver: yupResolver(fieldArraySchema({ hasShowSelectFullName })),
    });

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'members',
        keyName: 'customId',
    });

    const isInCorpCompany = useMemo(() => {
        return !body?.Company.is_outside_company;
    }, [body]);

    // switch
    const [stateSwitch, setStateSwitch] = useState(
        body.companyStructure?.director_type_member_list ?? [true],
    );

    const handleChangeSwitch = (event, index) => {
        let currentState = [...stateSwitch];
        currentState[index] = event.target.checked;
        setStateSwitch(currentState);

        // xử lý switch type member => back về lại vẫn giữ nguyên thông tin
        const currentMember = fields[index];
        if (event.target.checked !== currentMember?.individual) {
            // nếu type khác type ban đầu thì clear data member
            form.setValue(`members.${index}`, {
                ...initFieldObject,
                country_id: currentMember?.country_id,
                country_of_incorporation: fields[index]?.country_of_incorporation,
                is_represented: currentMember?.is_represented,
                type_member_id: currentMember?.individual ? 1 : 2,
                individual: !currentMember?.individual,
                id: currentMember?.id || null,
                company_position_id: currentMember?.company_position_id
            });
        } else {
            // nếu type ban đầu thì giữ nguyên thông tin init member
            if (body.companyStructure?.Directors?.[index]) {
                form.setValue(`members.${index}`, body.companyStructure?.Directors?.[index]);
            } else {
                form.setValue(`members.${index}`, initFieldObject);
            }
        }
    };

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        form.reset(initForm);
    }, [body.companyStructure?.Directors]);

    useEffect(() => {
        const countryIds = form
            .watch('members')
            ?.filter((member) => member.country_of_incorporation !== 0)
            ?.map((member) => member?.country_of_incorporation);

        let countryCorporationIds = [];

        if (countryIds?.length) {
            countryIds.forEach((countryId) => {
                const normalListCountry = countryCorporationIds.filter(
                    (item) => ![CAYMAN_ID, UK_ID, RAK_ID].includes(item),
                );

                if (
                    ![CAYMAN_ID, UK_ID, RAK_ID].includes(countryId) &&
                    !normalListCountry?.length &&
                    !businessActivityWithCountry?.normalList?.length
                ) {
                    countryCorporationIds.push(countryId);
                }

                if (countryId === CAYMAN_ID && !businessActivityWithCountry?.caymanList?.length) {
                    countryCorporationIds.push(CAYMAN_ID);
                }
                if (countryId === UK_ID && !businessActivityWithCountry?.ukList?.length) {
                    countryCorporationIds.push(UK_ID);
                }
                if (countryId === RAK_ID && !businessActivityWithCountry?.rakList?.length) {
                    countryCorporationIds.push(RAK_ID);
                }
            });
        }

        countryCorporationIds
            .filter((countryCorporationId) => countryCorporationId > 0)
            .forEach((countryId) => {
                dispatch(actionsEnum.fetchBusinessActivity(countryId, true));
            });

        if (!countriesOptions?.length) {
            dispatch(actionsEnum.fetchCountries());
        }
    }, []);

    useEffect(() => {
        if (body && countriesOptions.length) {
            const tempCountryOperations = body.companyInfo.country_operations?.map((item) => ({
                ...item,
                value: item.id,
                label: item.name,
            }));
            setCountryOperation(tempCountryOperations);
        }
    }, [countriesOptions]);

    // handle step
    const handleNext = (values) => {
        try {
            let checkLogic = true;
            if (
                body.represented_director &&
                values.members.find((e) => e.is_represented === true) === undefined
            ) {
                checkLogic = false;
                Swal.fire({
                    icon: 'warning',
                    title: 'Warning!',
                    text: body.Represented.Director.errorMessage,
                    confirmButtonColor: '#17c191',
                    confirmButtonText: 'OK',
                });
            }

            if (checkLogic) {
                setIsLoading(true);

                let finalData = {
                    companyStructureCreate: [],
                    companyStructureUpdate: [],
                };
                values.members.map((item, index) => {
                    let newItem = {};

                    if (item.individual) {
                        newItem = {
                            country_id: item.country_id || 0,
                            type_member_id: 1,
                            first_name: hasShowSelectFullName // các quốc gia không có first name && middle name && last name => cho first name = full name
                                ? item.first_name || ''
                                : item.full_name || '',
                            middle_name: item?.middle_name || '',
                            last_name: item?.last_name || '',
                            full_name: item?.full_name || '',
                            major: item.occupation,
                            phone_country_id: item.phone_code.id
                                ? item.phone_code.id
                                : item.phone_code,
                            phone_number: item.phone || null,
                            email: item.email || null,
                            id: item.id || null,
                        };
                    } else {
                        const objPhoneNumber = item.phone ? { phone_number: item.phone } : {};
                        newItem = {
                            country_id: item.country_of_incorporation || 0,
                            type_member_id: 2,
                            company_member_type_id: 1,
                            email: item.email || null,
                            // company_id: item.company_id || null,
                            id: item.id || null,
                            // birthday: '',
                            address: item.registered_address,
                            phone_country_id: item.phone_code.id
                                ? item.phone_code.id
                                : item.phone_code,
                            ...objPhoneNumber,

                            corporation_name: item.company_name || '',
                            corporation_number: item.company_number || '0',
                            corporation_date:
                                item.date_of_incorporation ||
                                moment(new Date()).format('MM/DD/YYYY'),
                            business_activity_array: item?.business_activity_array?.length
                                ? item.business_activity_array
                                : [],
                            business_activity_detail: item?.business_activity_detail ?? '',
                            OperationCountries: item?.country_operations?.length
                                ? item.country_operations
                                : [],
                        };
                    }

                    if (isSVG) {
                        newItem = {
                            ...newItem,
                            asset_source_fund_cast_in_bank: item.cash_in_bank_asset_1
                                ? parseInt(item.cash_in_bank_asset_1)
                                : 0,
                            asset_source_fund_real_estate: item.real_estate
                                ? parseInt(item.real_estate)
                                : 0,
                            asset_source_fund_other_detail: item.other_asset || '',
                            asset_source_fund_other_money: item.other_money
                                ? parseInt(item.other_money)
                                : 0,

                            income_source_fund_investment: item.investment_income
                                ? parseInt(item.investment_income)
                                : 0,
                            income_source_fund_self_employed: item.self_employed
                                ? parseInt(item.self_employed)
                                : 0,
                            income_source_fund_other_detail: item.other_detail_income
                                ? item.other_detail_income
                                : '',
                            income_source_fund_other_money: item.other_money_income
                                ? parseInt(item.other_money_income)
                                : 0,
                        };
                    }

                    newItem = {
                        ...newItem,
                        CompanyPositions: [
                            {
                                id: undefined,
                                company_member_type_id: 1,
                                // is_appointed: item.is_represented,
                            },
                        ],
                        CompanyMemberReferences: body.isPanama ? item.references : undefined,
                    };

                    delete newItem.company_member_type_id;

                    if (newItem.id) {
                        if (item.company_position_id) {
                            newItem.CompanyPositions[0].id = item.company_position_id;
                        }
                        finalData.companyStructureUpdate.push(newItem);

                        if (newItem.type_member_id === 2) {
                            const memberOld = body.Company.CompanyMembers.find(
                                (member) => member.id === item.id,
                            );
                            const currentMemberPositions = memberOld?.CompanyPositions || [];
                            const uboPositionIds = currentMemberPositions
                                ?.filter((position) => position?.company_member_type_id === 3)
                                ?.map((position) => position?.id);
                            const hasContactMember = !!currentMemberPositions.find(
                                (position) => position?.company_member_type_id === 7,
                            );

                            if (
                                hasContactMember &&
                                memberOld.type_member_id !== newItem.type_member_id
                            ) {
                                const newMemberTemp = cloneDeep(newItem);
                                delete newMemberTemp.id;
                                delete newMemberTemp.CompanyPositions[0].id;
                                delete newMemberTemp.CompanyPositions[0].is_contact_person;

                                finalData.companyStructureDelete.company_position_ids = uniq([
                                    ...(finalData?.companyStructureDelete?.company_position_ids ||
                                        []),
                                    // newItem?.CompanyPositions?.[0]?.id
                                    currentMemberPositions?.find(
                                        (position) => position?.company_member_type_id === 1,
                                    )?.id,
                                ]).filter((id) => id);

                                finalData.companyStructureCreate.push(newMemberTemp);
                                finalData.companyStructureUpdate =
                                    finalData.companyStructureUpdate.filter(
                                        (member) => member.id !== newItem.id,
                                    );
                            }

                            // xóa tất cả position UBO nếu type member là Corporation
                            if (uboPositionIds.length) {
                                finalData.companyStructureDelete.company_position_ids = uniq([
                                    ...(finalData?.companyStructureDelete?.company_position_ids ||
                                        []),
                                    ...uboPositionIds,
                                ]).filter((id) => id);
                            }
                        }
                    } else {
                        delete newItem.id;
                        finalData.companyStructureCreate.push(newItem);
                    }
                });

                onSubmit &&
                    onSubmit({
                        data: finalData,
                        isFinalStep: false,
                        withoutSave:
                            isInCorpCompany &&
                            !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(
                                body?.Company?.country_id,
                            ),
                    });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if (
            !(
                isInCorpCompany &&
                !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(body?.Company?.country_id)
            )
        ) {
            dispatch(fetchOrderForm(id));
        }
    };

    const deleteHandler = (item, index, values, firstMember) => {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
            }).then(async (result) => {
                setIsLoading(true);
                if (result.isConfirmed) {
                    if (item.member_id) {
                        const delData = {
                            companyStructureDelete: {
                                // company_id: item.company_id,
                                // company_member_id: item.id
                                company_position_ids: [item.company_position_id],
                            },
                        };

                        /* Start - Xử lý show error khi gọi tới be thất bại */
                        const resultAction = await dispatch(
                            updateBusinessPlanFormAction({ id: body?.Task.id, params: delData }),
                        );
                        if (updateBusinessPlanFormAction.fulfilled.match(resultAction)) {
                            toast.success('Successfully');
                            setIsLoading(false);
                            if (index === 0) {
                                setTabValue(0);
                            } else {
                                setTabValue(index - 1);
                            }
                            remove(index);
                            if (values.length === 1) {
                                setStateSwitch([true]);
                                append({
                                    ...initFieldObject,
                                    country_id: firstMember?.country_id,
                                    phone_code: firstMember?.phone_code,
                                });
                                setTabValue(0);
                            }
                        } else {
                            setIsLoading(false);
                            toast.error(
                                (typeof resultAction?.payload?.data === 'string'
                                    ? resultAction?.payload?.data
                                    : null) ||
                                    resultAction?.error?.message ||
                                    'Something went wrong!',
                            );
                        }
                    } else {
                        let newTabValue = index === 0 ? 0 : index - 1;
                        remove(index);
                        setTabValue(newTabValue);
                        let currentState = [...stateSwitch];
                        currentState.splice(index, 1);
                        setStateSwitch(currentState);
                        if (values.length === 1) {
                            setStateSwitch([true]);
                            append({
                                ...initFieldObject,
                                country_id: firstMember?.country_id,
                                phone_code: firstMember?.phone_code,
                            });
                            setTabValue(0);
                        }

                        setIsLoading(false);
                    }
                } else {
                    setIsLoading(false);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    // init form values
    const initFieldObject = {
        isSVG: isSVG,
        isPanama: body?.isPanama,
        showOtherIncome: false,
        showOther: false,
        individual: true,
        full_name: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        occupation: '',
        phone_code: 0,
        phone: '',
        email: '',
        company_name: '',
        company_number: '',
        country_of_incorporation: 0,
        registered_address: '',
        date_of_incorporation: moment(new Date()).format('MM/DD/YYYY'),
        // company_member_type_id: "",
        cash_in_bank_asset_1: null,
        real_estate: null,
        other_asset: '',
        other_money: null,
        investment_income: null,
        self_employed: null,
        other_detail_income: '',
        other_money_income: null,
        total_asset: null,
        total_income: null,
        type_member_id: 1,
        isCreate: true,
        company_id: body.Company.id,
        company_member_type_id: 1,
        country_id: 0,
        is_represented: false,
        business_activity_array: [],
        business_activity_detail: '',
        country_operations: [],

        ...EmptyMemberCommon,
    };

    useEffect(() => {
        /* sử dụng setTimeout để fix lỗi không set được value sau khi xóa member 1 */
        // setTimeout(() => {
        //     setInitForm({
        //         members: body.companyStructure?.Directors
        //     });

        // }, 100);

        setStateSwitch(form.watch('members')?.map((item) => item.individual) ?? [true]);
    }, [body]);

    function handleNextNoSubmit() {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    function handleNextNominee() {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    return (
        <div className="step2 position-relative">
            <PageLoadingEffect loading={isLoading} />

            {body && body.is_has_director === true ? (
                <Nominee
                    body={body}
                    companyMemberType={1}
                    handleBack={handleBack}
                    // handleNext={handleNextNoSubmit}
                    initialValue={get(body, 'Task.reason_use_nominee_director')}
                    onSubmit={handleNextNominee}
                    disabled={
                        isInCorpCompany &&
                        !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(body?.Company?.country_id)
                    }
                />
            ) : (
                <>
                    <form onSubmit={form.handleSubmit(handleNext)}>
                        <div className="control-tab mb-5">
                            <div className="tab-menu">
                                <Tabs value={tabValue} onChange={handleChangeTab} aria-label="tabs">
                                    {fields?.map((item, index) => {
                                        return (
                                            <Tab
                                                key={item.id}
                                                className="item"
                                                label={index + 1}
                                                id={item.id}
                                                aria-controls={`tabpanel-${index}`}
                                            />
                                        );
                                    })}
                                </Tabs>
                            </div>
                            {!(
                                isInCorpCompany &&
                                !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(
                                    body?.Company?.country_id,
                                )
                            ) && (
                                <button
                                    type="button"
                                    className="btn btn-add"
                                    onClick={(event) => {
                                        append(initFieldObject);

                                        setTabValue(fields?.length);
                                        let currentState = stateSwitch;
                                        currentState.push(true);

                                        setStateSwitch(currentState);
                                    }}
                                >
                                    <AddIcon />
                                </button>
                            )}
                        </div>
                        <div>
                            {fields?.map((item, index) => {
                                return (
                                    // TabPanel
                                    <div key={item.id} className="tab-panel">
                                        <div
                                            role="tabpanel"
                                            hidden={tabValue !== index}
                                            id={`tabpanel-${index}`}
                                            aria-labelledby={`simple-tab-${index}`}
                                        >
                                            {!isHongkong && (
                                                <div className="ant-switch">
                                                    <span>Corporation</span>
                                                    <Switch
                                                        // checked={stateSwitch[index]}
                                                        checked={form.watch(
                                                            `members.${index}.individual`,
                                                        )}
                                                        onChange={(e) =>
                                                            handleChangeSwitch(e, index)
                                                        }
                                                        name={`members.${index}.individual`}
                                                        disabled={
                                                            isInCorpCompany &&
                                                            !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(
                                                                body?.Company?.country_id,
                                                            )
                                                        }
                                                    />
                                                    <span>Individual</span>
                                                </div>
                                            )}

                                            {isHongkong ? (
                                                <DirectorIndividual
                                                    index={index}
                                                    memberValue={item}
                                                    values={form.watch('members')}
                                                    form={form}
                                                    body={body}
                                                    disabled={
                                                        isInCorpCompany &&
                                                        !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(
                                                            body?.Company?.country_id,
                                                        )
                                                    }
                                                    hasShowSelectFullName={hasShowSelectFullName}
                                                />
                                            ) : form.watch(`members.${index}.individual`) !==
                                                  null &&
                                              form.watch(`members.${index}.individual`) ? (
                                                // "Individual"
                                                <DirectorIndividual
                                                    index={index}
                                                    values={form.watch('members')}
                                                    body={body}
                                                    form={form}
                                                    disabled={
                                                        isInCorpCompany &&
                                                        !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(
                                                            body?.Company?.country_id,
                                                        )
                                                    }
                                                    hasShowSelectFullName={hasShowSelectFullName}
                                                />
                                            ) : (
                                                <DirectorCorporation
                                                    index={index}
                                                    body={body}
                                                    form={form}
                                                    values={form.watch('members')}
                                                    disabled={
                                                        isInCorpCompany &&
                                                        !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(
                                                            body?.Company?.country_id,
                                                        )
                                                    }
                                                    orderInfo={orderInfo}
                                                    countryOperation={countryOperation}
                                                    businessActivityWithCountry={
                                                        businessActivityWithCountry
                                                    }
                                                />
                                            )}
                                            {isSVG ? (
                                                <SourseForFunds
                                                    index={index}
                                                    body={body}
                                                    values={form.watch('members')}
                                                    form={form}
                                                    disabled={
                                                        isInCorpCompany &&
                                                        !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(
                                                            body?.Company?.country_id,
                                                        )
                                                    }
                                                />
                                            ) : null}

                                            <References
                                                index={index}
                                                body={body}
                                                values={form.watch('members')}
                                                form={form}
                                                disabled={
                                                    isInCorpCompany &&
                                                    !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(
                                                        body?.Company?.country_id,
                                                    )
                                                }
                                            />

                                            {
                                                // body.companyStructure.directors.is_represented
                                                // Responsible Party Signing the ss4 Form: is_represented
                                                body && body.represented_director && (
                                                    <Represented
                                                        index={index}
                                                        body={body}
                                                        form={form}
                                                        values={form.watch('members')}
                                                        representedLable={
                                                            body.Represented.Director.label
                                                        }
                                                        disabled={
                                                            isInCorpCompany &&
                                                            !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(
                                                                body?.Company?.country_id,
                                                            )
                                                        }
                                                    />
                                                )
                                            }
                                            <div className="wrap-btn text-md-right mt-7">
                                                {!(
                                                    isInCorpCompany &&
                                                    !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(
                                                        body?.Company?.country_id,
                                                    )
                                                ) && (
                                                    <Button
                                                        name={'btn_delete_' + index}
                                                        // variant="contained"
                                                        // color="secondary"
                                                        // disableElevation
                                                        className=" m-0 px-0 btn-delete btn-text"
                                                        // startIcon={<RemoveIcon />}
                                                        disabled={isLoading}
                                                        onClick={(event) => {
                                                            const firstMember =
                                                                form.getValues('members.0');
                                                            deleteHandler(
                                                                item,
                                                                index,
                                                                fields,
                                                                firstMember,
                                                            );
                                                        }}
                                                        style={{
                                                            color: '#EB5757',
                                                            background: '#fff',
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="mt-7">
                            <div>
                                {activeStep > 0 && (
                                    <Button
                                        size="large"
                                        variant="contained"
                                        disableElevation
                                        onClick={handleBack}
                                        className="mr-2"
                                    >
                                        Back
                                    </Button>
                                )}
                                {activeStep < 3 && (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        style={{ color: '#fff' }}
                                    >
                                        Next
                                    </Button>
                                )}
                            </div>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
}

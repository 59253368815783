import React, { useMemo } from 'react';
import { Button } from '@mui/material';

import './video-call.styles.scss'
import { useSelector } from 'react-redux';
import NoteFromCs from '../application-set/components/NoteFromCs';

const VideoCall = ({ onNext }) => {

    const { activeProcess, orderInfo } = useSelector(state => state.banking);

    const process = useMemo(() => {
        return orderInfo?.Task?.Processes[0];
   }, [orderInfo]);

    const chooseDate = () => {
        window.open(orderInfo?.Task.BankingProcesses[0].url_video)
    }

    const onClickNext = () => {
        return onNext && onNext()
    }

    const renderNote = () => {
        if(process.ProcessNote){
            return process?.ProcessNote[0] && (process?.ProcessNote[0].description || process?.ProcessNote[0].Files?.length > 0)
            ? <NoteFromCs note={process?.ProcessNote[0].description}
                files={process?.ProcessNote[0].Files}
                // onFileClick={onClickView}
                />
            : ''
        }
        return ''
    }

    // console.log(orderInfo)

    return (
        <section id="meeting-schedule-video-call" className="meeting-schedule-video-call mt-10">

            <div style={{textAlign:'center', marginTop:'10px', marginBottom:'10px'}}>
                <img src={'https://bbcincorp.com' + orderInfo?.Task?.BankingProcesses[0]?.Bank?.image} alt="" />

                <p className='title'>Schedule a video call with { orderInfo?.Task.BankingProcesses[0].Bank.name }</p>

                <Button size='large'
                        variant='contained'
                        color='primary'
                        className='text-white mb-10'
                        disabled={!orderInfo?.Task.BankingProcesses[0].url_video}
                        onClick={chooseDate}> CHOOSE DATE </Button>
            </div>

            {renderNote()}

            <div style={{textAlign:'center', marginTop:'10px'}}>
                <Button size='large'
                        variant='contained'
                        color='primary'
                        className='text-white'
                        onClick={onClickNext}> FINISH THIS STEP </Button>
            </div>



        </section>
    );
}

export default VideoCall;

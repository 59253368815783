import _ from 'lodash';

export default function setupAxios(axios, store) {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    let isExpired = true;

    axios.interceptors.request.use(
        (config) => {
            /* Call to s3 no need authentication */
            if (
                config.url.startsWith('https://s3.amazonaws.com') ||
                config.url.startsWith(process.env.REACT_APP_SCAN_FILE_API_URL)
            ) {
                return config;
            }

            const {
                auth: { authToken },
            } = store.getState();

            if (authToken && authToken.access_token && authToken.access_token_exp) {
                // console.log('access_token_exp: ', authToken.access_token_exp);

                // if (localStorage.getItem('persist:bbcincorp')) {}

                isExpired =
                    parseInt(authToken.access_token_exp) <= Math.floor(Date.now() / 1000)
                        ? true
                        : false;
                if (!isExpired) {
                    // config.headers.Authorization = `Bearer ${localStorageAuthToken.access_token}`;
                    config.headers.Authorization = `Bearer ${authToken.access_token}`;

                    const map = {
                        sortOrder: 'order_sort',
                        sortField: 'order_by',
                        pageNumber: 'offset',
                        pageSize: 'limit',
                    };

                    let params = {};

                    _.each(config.params, (value, key) => {
                        key = map[key] || key;
                        if (key === 'offset') {
                            if (value === 1) {
                                params[key] = value - 1;
                            } else {
                                params[key] = (value - 1) * config.params.pageSize;
                            }
                        } else {
                            params[key] = value;
                        }
                    });

                    config.params = params;
                }
            }

            return config;
        },
        (error) => {
            console.log(error);
        },
    );

    axios.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        function (error) {
            if (error.response) {
                // Request made and server responded
                // console.log('1-error.response.data: ', error.response.data);
                // console.log('1-error.response.status: ',error.response.status);
                // console.log('1-error.response.headers: ',error.response.headers);
                if (error.response.status === 401) {
                    // if (window.location.pathname !== '/auth/login') {
                    store.dispatch({ type: '[Logout] Action' });
                    window.location.href = '/';
                    // window.location.reload();
                }
            } else if (error.request) {
                // The request was made but no response was received
                // console.log('2-error.request: ', error.request);
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                // console.log('3-error.message: ', error.message);
                console.log(error.message);
            }

            return Promise.reject(error);
        },
    );
}

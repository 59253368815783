import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';

import * as actionsEnum from 'app/pages/_redux/enum/enumActions';


import { BANK_GUIDE } from 'app/pages/services/constants/banking_constants';

import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import { findBankData } from '../helpers';
import { BankingProcessStepper } from './BankingProcessStepper';
import SeeGuide from './guide/Guide';
import {
    approveProcessAction,
    getBankingProcessTreeAction,
    getProcessDetailById,
} from 'app/pages/_redux/banking/asyncThunk';
import {
    updateActiveProcess,
} from 'app/pages/_redux/banking/slice';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { REMOVED_PUBLISHED_TRACKING_LINK_BANKS } from '../constant';


export function BankingProcess() {
    const { id } = useParams();
    const dispatch = useDispatch();
    // const [fileGuide, setFileGuide] = useState(null);
    const { countries, bankingInfo } = useSelector((state) => ({
        countries: state?.enum?.countriesCooperation,
        bankingInfo: state.banking.orderInfo
    }), shallowEqual );
    const { loadingProcessTree, processTree, loadOrderInfo, activeProcess, orderInfo } = useSelector((state) => state.banking);
    // console.log('banking guide info',bankingInfo);
    // SEE GUIDE: gán tạm
    const country_id = 139; // set tạm chờ data api
    const bank_id = 13; // set tạm chờ data api
    const fileGuide = findBankData(BANK_GUIDE, country_id, bank_id);

    const bankId = orderInfo?.Task.BankingProcesses?.[0]?.Bank?.id;
    const countryId = orderInfo?.Task.BankingProcesses?.[0]?.Bank?.Country?.id;
    const removedPublishedTrackingLinkBank = REMOVED_PUBLISHED_TRACKING_LINK_BANKS.find(
        (bank) => bank.id === bankId && bank.Country.id === countryId,
    );

    useEffect(() => {
        dispatch(actionsEnum.fetchCountriesNonRestricted());
        // dispatch(actionsOrderForm.fetchOrderFormBanking(id));

        dispatch(actionsEnum.getCurrenciesAction());
        dispatch(actionsEnum.fetchGeoLooking());

        void getProcessTree();

        // dispatch(actionsOrderForm.fetchOrderForm('14644')); // account: testcustomer@gleads.vn
        // dispatch(actionsOrderForm.fetchOrderForm('14785')); // account:
    }, []);

    useEffect(() => {
        if (!activeProcess?.Processes?.[0]?.id) return;
        dispatch(getProcessDetailById(activeProcess.Processes[0].id));
    }, [activeProcess]);

    const getProcessTree = async () => {
        const resultAction = await dispatch(getBankingProcessTreeAction(id));
        if (getBankingProcessTreeAction.fulfilled.match(resultAction)) {
            const {payload: processes } = resultAction;

            /* Lấy process của step */
            let newActiveProcess = null;
            processes.forEach(processItem => {
                const {Processes} = processItem;
                if (!Processes[0]) return;
                newActiveProcess = processItem;
            })

            if (!newActiveProcess) return;
            // console.log('banking process: ', activeProcess.Processes[0].id)
            dispatch(updateActiveProcess(newActiveProcess));
            return;
        }
        toast.error('Somethings went wrong!');
    }

    const onStepNext = async (params = {}) => {
        try {
            /* 1. Approve step */
            console.log(activeProcess)
            const approveResult = await dispatch(
                approveProcessAction({ id: activeProcess.Processes[0].id, ...params }),
            );
            if (!approveProcessAction.fulfilled.match(approveResult)) {
                toast.error('Somethings went wrong!');
                return;
            }
            toast.success('Successfully');

            /* 2. Gọi lại process tree để next step dựa vào process tree */
            void getProcessTree();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Card>
                <CardHeader title={'Order Details INC.' + id}>
                    { (bankingInfo?.BankingGuide?.length > 0 && !removedPublishedTrackingLinkBank) && <SeeGuide fileGuide={bankingInfo?.BankingGuide[0]} /> }
                </CardHeader>
                <CardBody>
                    {loadOrderInfo === 'loading' && <div className={'p-5 d-flex align-items-center justify-content-center'}>
                        <CircularProgress/>
                    </div>}
                    {loadOrderInfo === 'idle' && <div className='banking'>
                        {loadingProcessTree === 'loading' &&  <div className={'p-5 d-flex align-items-center justify-content-center'}>
                            <CircularProgress/>
                        </div>}
                        {loadingProcessTree === 'idle' && orderInfo?.Task?.Processes?.[0]?.id === activeProcess?.Processes?.[0]?.id && <BankingProcessStepper onStepNext={onStepNext} />}
                        {loadingProcessTree === 'error' && <span className={'text-danger'}>Somethings went wrong!</span>}
                    </div>}
                    {loadOrderInfo === 'error' && <span className={'text-danger'}>Somethings went wrong!</span>}
                </CardBody>
            </Card>
        </>
    )
}

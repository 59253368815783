import axios from 'axios';

export const LOGIN_URL = '/auth';
export const REGISTER_URL = 'auth/register';
export const REQUEST_PASSWORD_URL = 'auth/forgot-password';
export const RESET_PASSWORD_URL = 'auth/reset-password';
export const CONFIRM_2FA = '/auth/confirm';
export const SOCIAL_LOGIN = '/auth/social_login';
export const RESEND_2FA_URL = 'auth/resend';

export function login(username, password, reCaptchaToken) {
    axios.defaults.headers.post['captcha-response'] = reCaptchaToken;
    return axios.post(LOGIN_URL, { username, password });
}

export function register(params_create, reCaptchaToken) {
    axios.defaults.headers.post['captcha-response'] = reCaptchaToken;
    return axios.post(REGISTER_URL, params_create);
}

export function requestPassword(email, reCaptchaToken) {
    axios.defaults.headers.post['captcha-response'] = reCaptchaToken;
    return axios.post(REQUEST_PASSWORD_URL, { username: email });
}

export function resetPassword(reset_password_token, new_password, reCaptchaToken) {
    axios.defaults.headers.post['captcha-response'] = reCaptchaToken;
    return axios.post(RESET_PASSWORD_URL, { reset_password_token, new_password });
}

export function confirm(username, auth2FAToken, reCaptchaToken) {
    axios.defaults.headers.post['captcha-response'] = reCaptchaToken;
    return axios.post(CONFIRM_2FA, { username, auth2FAToken });
}

export function social_login(authToken) {
    return axios.post(SOCIAL_LOGIN, authToken);
}

export function resend_verification_code(username, reCaptchaToken) {
    axios.defaults.headers.post['captcha-response'] = reCaptchaToken;
    return axios.post(RESEND_2FA_URL, { username });
}

import * as requestFromServer from './companiesCrud';
import { companiesSlice, callTypes } from './companiesSlice';
import { toast } from 'react-toastify';
const { actions } = companiesSlice;

export const fetchCompanies = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .findCompanies(queryParams)
        .then((response) => {
            const { count, rows } = response.data.data;
            dispatch(actions.companiesFetched({ count, rows }));
        })
        .catch((error) => {
            error.clientMessage = "Can't find companies";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            toast.error('Something went wrong!');
        });
};

export const fetchMyCompanies = (queryparams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getAllMyCompanies(queryparams)
        .then((response) => {
            const data = response.data.data;
            let custom_data = {};
            if (queryparams.is_outside_company) {
                custom_data.outsideCompany = data.rows;
            } else {
                custom_data.insideCompany = data.rows;
            }
            dispatch(actions.myCompaniesFetched({ data: data, ...custom_data }));
        })
        .catch((error) => {
            error.clientMessage = "Can't find your company";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            toast.error('Something went wrong!');
        });
};

export const fetchDocuments = (queryParams, id) => (dispatch) => {
    // get list document
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getDocuments(queryParams, id)
        .then((response) => {
            const { count, rows } = response.data.data;
            dispatch(actions.documentsFetched({ count, rows }));
            return response.data.data;
        })
        .catch((error) => {
            error.clientMessage = "Can't find documents";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchCompletedEvents = (queryParams, id) => (dispatch) => {
    // get list document
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getCompletedEventById(queryParams, id)
        .then((response) => {
            const { count, rows } = response.data.data;
            dispatch(actions.completedEventsFetched({ count, rows }));
            return response.data.data;
        })
        .catch((error) => {
            error.clientMessage = "Can't find completed events";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchTransactionEvents = (queryParams, id) => (dispatch) => {
    // get list document
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getTransactionEventById(queryParams, id)
        .then((response) => {
            const { count, rows } = response.data.data;
            dispatch(actions.transactionEventsFetched({ count, rows }));
            return response.data.data;
        })
        .catch((error) => {
            error.clientMessage = "Can't find transaction events";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchComparedcompany = (id) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getComparedCompanyById(id)
        .then((response) => {
            const data = response.data.data;
            dispatch(actions.comparedCompanyFetched({ data: data }));
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't find compare company";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            //error not
        });
};
import { createSlice } from '@reduxjs/toolkit';

const initialSupportsState = {
    listLoading: false,
    actionsLoading: false,
    entities: null, // list
    totalCount: 0, // list
    subject: null, // subject support case
    message: null, // all message
    rating: null, // point rating
    sendMessage: null,
    sendMessageCount: 0,
    messageCount: 0,
    lastError: null,
};
export const callTypes = {
    list: 'list',
    action: 'action',
};

export const supportsSlice = createSlice({
    name: 'supports',
    initialState: initialSupportsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        supportFetched: (state, action) => {
            state.actionsLoading = false;
            state.supportCaseForEdit = action.payload.supportCaseForEdit;
            state.error = null;
        },
        supportsFetched: (state, action) => {
            const { count, rows } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = rows;
            state.totalCount = count;
        },
        supportCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
        supportUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
        supportSubjectFetched: (state, action) => {
            const { subject } = action.payload;
            state.actionsLoading = false;
            state.subject = subject;
            state.error = null;
        },
        messageFetched: (state, action) => {
            const { count, rows } = action.payload;
            state.actionsLoading = false;
            state.message = rows;
            state.messageCount = count;
            state.error = null;
        },
        supportRatingFetched: (state, action) => {
            const { rating } = action.payload;
            state.actionsLoading = false;
            state.rating = rating;
            state.error = null;
        },
        sendMessage: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.sendMessage = data;
            if (state.message && state.message.length > 0) {
                state.message = [...state.message, data];
            }
            state.error = null;
        },
        sendRating: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            // state.sendMessage = data;
            state.error = null;
        },
    },
});

import React, { useState } from 'react';
import RadioField from 'components/form-controls/RadioField';
import InputField from 'components/form-controls/InputField';


function CaymanQuestion({form}) {

    const [questionNine, setQuestionNine] = useState('113');
    const [questionTen, setQuestionTen] = useState('115');

    return (
        
        <form>
            <br />
            <p className={'font-weight-bold mb-0'}>
                9. Have you at any time been convicted of any offence
                (except a traffic offence, if any) or been subject to
                sanctions by a judicial, government, professional or other
                regulatory body? If so, give full particulars:
            </p>
            <RadioField
                form={form}
                aria-label="option_cayman_question_nine"
                name="option_cayman_question_nine"
                radioGroupProps={{
                    defaultValue: questionNine,
                    sx: { flexDirection: 'row' },
                }}
                options={[
                    { id: '113', name: 'Yes' },
                    { id: '112', name: 'No' },
                ]}
                onChange={(e) => {
                    setQuestionNine(e);
                }}
            />
            {questionNine == '113' && (
                <div className="input-line">
                    <InputField
                        form={form}
                        type="text"
                        name="value_text_question_nine"
                        placeholder="Your answer"
                        helperText={true}
                        // onChange={(e) => {
                        //     handleChange(e);
                        // }}
                    />
                </div>
            )}
            <p className={'font-weight-bold mb-0'}>
                10. In carrying out your duties will you be acting on the
                directions or instructions of any other person? If so, give
                full particulars of such person and the nature of their
                involvement:
            </p>
            <RadioField
                form={form}
                aria-label="option_cayman_question_ten"
                name="option_cayman_question_ten"
                radioGroupProps={{
                    defaultValue: questionTen,
                    sx: { flexDirection: 'row' },
                }}
                options={[
                    { id: '115', name: 'Yes' },
                    { id: '114', name: 'No' },
                ]}
                onChange={(e) => {
                    setQuestionTen(e);
                }}
            />
            {questionTen == '115' && (
                <div className="input-line">
                    <InputField
                        form={form}
                        name="value_text_question_ten"
                        placeholder="Your answer"
                        helperText={true}
                        // onChange={(e) => {
                        //     handleChange(e);
                        // }}
                    />
                </div>
            )}
            <br />
            <p className={'font-weight-bold'}>11. Are you:</p>
            <div className={'pl-2'}>
                <p className={'font-weight-bold mb-0'}>
                    a) current or former senior official in the executive,
                    legislative, administrative, military or judicial
                    branches of a domestic or foreign government (whether
                    elected or not); or
                </p>
                <RadioField
                    form={form}
                    aria-label="option_cayman_question_elevent_a"
                    name="option_cayman_question_elevent_a"
                    radioGroupProps={{
                        sx: { flexDirection: 'row' },
                    }}
                    options={[
                        { id: '116', name: 'Yes' },
                        { id: '117', name: 'No' },
                    ]}
                />
                {/* {errors?.option_cayman_question_elevent_a &&
                    touched?.option_cayman_question_elevent_a && (
                        <div
                            className="text-danger"
                            style={{ fontSize: '0.9rem' }}
                        >
                            This field is required!
                        </div>
                    )} */}

                <p className={'font-weight-bold mb-0'}>
                    b) a senior official of a major domestic or foreign
                    political party, or a senior executive of a domestic or
                    foreign government-owned commercial enterprise; or
                </p>
                <RadioField
                    form={form}
                    aria-label="option_cayman_question_elevent_b"
                    name="option_cayman_question_elevent_b"
                    radioGroupProps={{
                        sx: { flexDirection: 'row' },
                    }}
                    options={[
                        { id: '118', name: 'Yes' },
                        { id: '119', name: 'No' },
                    ]}
                />
                {/* {errors?.option_cayman_question_elevent_b &&
                    touched?.option_cayman_question_elevent_b && (
                        <div
                            className="text-danger"
                            style={{ fontSize: '0.9rem' }}
                        >
                            This field is required!
                        </div>
                    )} */}

                <p className={'font-weight-bold mb-0'}>
                    c) a member of a corporation, business; or other entity
                    formed by or for the benefit of any such individual; or
                </p>
                <RadioField
                    form={form}
                    aria-label="option_cayman_question_elevent_c"
                    name="option_cayman_question_elevent_c"
                    radioGroupProps={{
                        sx: { flexDirection: 'row' },
                    }}
                    options={[
                        { id: '120', name: 'Yes' },
                        { id: '121', name: 'No' },
                    ]}
                />
                {/* {errors?.option_cayman_question_elevent_c &&
                    touched?.option_cayman_question_elevent_c && (
                        <div
                            className="text-danger"
                            style={{ fontSize: '0.9rem' }}
                        >
                            This field is required!
                        </div>
                    )} */}

                <p className={'font-weight-bold mb-0'}>
                    d) an immediate family member or a close personal or
                    business associate of such an individual?
                </p>
                <RadioField
                    form={form}
                    aria-label="option_cayman_question_elevent_d"
                    name="option_cayman_question_elevent_d"
                    radioGroupProps={{
                        sx: { flexDirection: 'row' },
                    }}
                    options={[
                        { id: '122', name: 'Yes' },
                        { id: '123', name: 'No' },
                    ]}
                />
                {/* {errors?.option_cayman_question_elevent_d &&
                    touched?.option_cayman_question_elevent_d && (
                        <div
                            className="text-danger"
                            style={{ fontSize: '0.9rem' }}
                        >
                            This field is required!
                        </div>
                    )} */}
            </div>
        </form>
    );
}

export default CaymanQuestion;

export const validateForm = (
    values,
    { isContactPerson, CorporationPercent, hasShowSelectFullName, orderInfo },
) => {
    const errors = {};
    if (values['type_member']) {
        //full name
        if (!values['full_name']) {
            errors['full_name'] = hasShowSelectFullName
                ? 'Please select your full name!'
                : 'Required!';
        }

        if (hasShowSelectFullName) {
            if (!values['first_name']) {
                errors['first_name'] = 'First name is required!';
            }
            if (!values['last_name']) {
                errors['last_name'] = 'Last name is required!';
            }
        }
        // major
        if (!values['major'] && !isContactPerson) {
            errors['major'] = 'Required!';
        }
        // phone
        if (!values['phone_number'] || /[A-Z]/gi.test(values['phone_number'])) {
            errors['phone_number'] = 'Phone Number not valid!';
        }
        // if (!values['phone_number'] || !(/^[0-9]*$/gm.test(values['phone_number']))) {
        //     errors['phone_number'] = 'Phone Number not valid!';
        // }

        // Percentage of interest -- UBO
        if (values['preData']['company_member_type_id'] === 3) {
            if (
                values['percent_of_interest'] < 0 ||
                /[A-Z]/gi.test(values['percent_of_interest'])
            ) {
                errors['percent_of_interest'] = 'Percentage of interest is invalid!';
            }

            let currentPositions = [];

            const arrayPercent = [];
            const calculateNumbers = (...arg) => {
                return [...arg].reduce((acc, cur) => {
                    return acc + (isNaN(parseFloat(cur)) ? 0 : parseFloat(cur));
                }, 0);
            };

            const positionsTemp = [];

            for (let member of orderInfo?.Company?.CompanyMembers) {
                for (let position of member.CompanyPositions) {
                    if (
                        position?.company_member_type_id === 3 &&
                        position.corporation_company_member_id ===
                            values.preData?.corporation_company_member_id
                    ) {
                        positionsTemp.push(position);
                    }
                }
            }

            if (values?.preData?.pos_id) {
                // update
                currentPositions = positionsTemp.map((position) => {
                    if (position.id === values?.preData?.pos_id) {
                        return {
                            ...position,
                            percent_of_interest: values.percent_of_interest,
                        };
                    } else {
                        return {
                            ...position,
                        };
                    }
                });
            } else {
                // create
                positionsTemp.push({
                    percent_of_interest: values?.percent_of_interest,
                });

                currentPositions = positionsTemp;
            }

            currentPositions.forEach((position) => {
                arrayPercent.push(position.percent_of_interest);
            });

            let totalPercent = calculateNumbers(...arrayPercent);

            if (totalPercent < 100 || totalPercent > 100) {
                errors[
                    'percent_of_interest'
                ] = `Total Percentage of interest equal to 100% current is ${totalPercent}%`;
            }
        }
    } else {
        // corporation name
        if (!values['corporation_name']) {
            errors['corporation_name'] = 'Required!';
        }
        // corporation number
        if (!values['corporation_number']) {
            errors['corporation_number'] = 'Required!';
        }
        // Registered address
        if (!values['address']) {
            errors['address'] = 'Required!';
        }
        // corporation date
        if (!values['corporation_date']) {
            errors['corporation_date'] = 'Required!';
        } else {
            const dateTemp = new Date(values['corporation_date']);
            const dateNow = Date.now();
            if (
                // (!values.preData.id && dateTemp - dateNow <= 0) ||
                dateTemp - dateNow >= 0 ||
                dateTemp.toString() === 'Invalid Date'
            ) {
                errors['corporation_date'] = 'Invalid Date!';
            }
        }
        // business_activity_array
        if (!values['business_activity_array']?.length) {
            errors['business_activity_array'] = 'Required!';
        }

        // business_activity_detail
        if (values['business_activity_detail']?.trim().split(/\s+/).length < 10) {
            errors['business_activity_detail'] =
                'Please write a complete description of your business activities at least 10 words';
        }

        // country_operations
        if (!values['country_operations']?.length) {
            errors['country_operations'] = 'Required!';
        }

        if (!values['phone_number'] || /[A-Z]/gi.test(values['phone_number'])) {
            errors['phone_number'] = 'Phone Number not valid!';
        }
    }
    // email
    if (!values['email']) {
        errors['email'] = 'Required!';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(values['email'])) {
        errors['email'] = 'Invalid email address!';
    }
    // Number of shares to be alloted --- shareholder
    if (values['preData']['company_member_type_id'] === 2) {
        const fieldName = values.preData.isLLCType ? 'own_percent' : 'number_allotted';
        if (values[fieldName] < 0 || /[A-Z]/gi.test(values[fieldName])) {
            errors[fieldName] = 'Invalid value!';
        }
        if (values['preData']['corporation_company_member_id']) {
            const fieldName = CorporationPercent ? 'own_percent' : 'number_allotted';
            if (!values[fieldName]) {
                errors[fieldName] = 'Required!';
            }
        }
    }

    return errors;
};

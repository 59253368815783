import SwitchHookFormShareholderCorp from 'app/components/SwitchHookFormShareholderCorp';
import AntSwitch from 'app/pages/services/components/kyc-elements/AntSwitch';
import { MEMBER_TYPES } from 'app/pages/services/constants';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import SelectFullNameHint from 'app/components/SelectFullNameHint';

const FormFill = (props) => {
    const {
        disabled,
        form,
        isUBO,
        isShareholder,
        isCorporationChild,
        isPercent,
        setIsPercent,
        hasShowSelectFullName,
    } = props;
    const type_member_id = form.watch('type_member_id');

    return (
        <div>
            {!isUBO && (
                <div className="mt-5 d-flex mb-2">
                    Corporation{' '}
                    <AntSwitch
                        name="type_member_id"
                        checked={type_member_id === MEMBER_TYPES.INDIVIDUAL.id}
                        onChange={(e) => {
                            form.setValue(
                                'type_member_id',
                                e.target.checked
                                    ? MEMBER_TYPES.INDIVIDUAL.id
                                    : MEMBER_TYPES.CORPORATION.id,
                            );
                            form.clearErrors();
                        }}
                        disabled={disabled}
                    />{' '}
                    Individual
                </div>
            )}

            {type_member_id === MEMBER_TYPES.INDIVIDUAL.id ? (
                <Individual {...props} hasShowSelectFullName={hasShowSelectFullName} />
            ) : (
                <Corporation {...props} />
            )}

            {isCorporationChild && (
                // level 2 - n
                <>
                    {/* SHAREHOLDER */}
                    {isShareholder && (
                        <div className="mt-2 row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                                <SwitchHookFormShareholderCorp
                                    form={form}
                                    checked={isPercent}
                                    setChecked={setIsPercent}
                                />
                            </div>
                        </div>
                    )}

                    {/* UBO */}
                    {isUBO && (
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                                <InputField
                                    type="number"
                                    name="percent_of_interest"
                                    label="Percentage of interest"
                                    form={form}
                                    InputProps={{ endAdornment: '%' }}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default FormFill;

const Individual = ({ disabled, form, hasShowSelectFullName }) => {
    const { countries } = useSelector(
        (state) => ({
            countries: state.enum.countries,
        }),
        shallowEqual,
    );

    return (
        <>
            <div className="row individual">
                {hasShowSelectFullName && (
                    <>
                        <div className="col-md-4 mb-7 input-line">
                            <label>First name</label>
                            <InputField
                                form={form}
                                name={'first_name'}
                                placeholder="First name"
                                hideSuccessHelper
                                disabled={disabled}
                                onChange={(e) => {
                                    form.setValue('first_name', e.target.value);
                                    if (form.getValues('full_name')) {
                                        form.setValue('full_name', '');
                                    }
                                }}
                            />
                        </div>
                        <div className="col-md-4 mb-7 input-line">
                            <label>Middle name</label>
                            <InputField
                                form={form}
                                name={'middle_name'}
                                placeholder="Middle name"
                                hideSuccessHelper
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-md-4 mb-7 input-line">
                            <label>Last name</label>
                            <InputField
                                form={form}
                                name={'last_name'}
                                placeholder="Last name"
                                hideSuccessHelper
                                disabled={disabled}
                                onChange={(e) => {
                                    form.setValue('last_name', e.target.value);
                                    if (form.getValues('full_name')) {
                                        form.setValue('full_name', '');
                                    }
                                }}
                            />
                        </div>
                    </>
                )}
                <div className="col-lg-6 mb-2 input-line">
                    <label
                        style={{
                            marginBottom: '0.2rem',
                        }}
                    >
                        Full name
                    </label>
                    <InputField
                        label=""
                        name="full_name"
                        placeholder="Enter your name"
                        form={form}
                        disabled={disabled || hasShowSelectFullName}
                        multiline={hasShowSelectFullName}
                        hideSuccessHelper
                    />
                </div>
                <div className="col-lg-6 mb-2 input-line">
                    <label>Nationality</label>
                    <SelectField
                        name="country_id"
                        form={form}
                        options={countries || []}
                        getOptionLabel={(option) => option?.name || ''}
                        disabled={disabled}
                        hideSuccessHelper
                        hideInputHelperText
                        autoComplete="off"
                    />
                </div>
                {hasShowSelectFullName && (
                    <SelectFullNameHint
                        form={form}
                        fullNameField={'full_name'}
                        firstNameField={'first_name'}
                        middleNameField={'middle_name'}
                        lastNameField={'last_name'}
                        disabled={disabled}
                    />
                )}
            </div>
        </>
    );
};

const Corporation = ({ disabled, form }) => {
    const { countries } = useSelector(
        (state) => ({
            countries: state.enum.countries,
        }),
        shallowEqual,
    );

    return (
        <div className="row corporation">
            <div className="col-lg-6 mb-2 input-line">
                <label
                    style={{
                        marginBottom: '0.2rem',
                    }}
                >
                    Company name
                </label>
                <InputField
                    name="corporation_name"
                    placeholder="Enter company name"
                    form={form}
                    disabled={disabled}
                    hideSuccessHelper
                />
            </div>
            <div className="col-lg-6 mb-2 input-line">
                <label>Country of Incorporation</label>
                <SelectField
                    name="country_id"
                    form={form}
                    options={countries || []}
                    getOptionLabel={(option) => option?.name || ''}
                    disabled={disabled}
                    hideSuccessHelper
                    hideInputHelperText
                    autoComplete="off"
                />
            </div>
        </div>
    );
};

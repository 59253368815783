import React from 'react';
import { useSubheader } from '_metronic/layout';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';

export const NotificationPayment = () => {
    const suhbeader = useSubheader();
    const navigate = useNavigate();
    const location = useLocation();
    suhbeader.setTitle(location.success);
    document.body.style.overflow = 'auto';
    const handleRedirect = () => {
        navigate('/company/invoices');
        // document.body.style.overflow = 'auto';
    };
    return (
        <div>
            {location.state.is_success && (
                <div>
                    <div className="p-md-10 py-5 text-center">
                        <h3 className="mb-5">Successful Order</h3>
                        <h5 className="fw-400 mb-4">
                            Thank you for your order/payment. Your account manager will contact you
                            shortly to proceed further.
                        </h5>
                        <h4 className="h5">Invoice number: INC{location.state.order_id}</h4>
                    </div>
                    <div>
                        <p>Remittance/ Swift Transfer:</p>
                        {/* <ul>
                            <li>
                                <b>Account Name:</b> BBCIncorp Limited
                            </li>
                            <li>
                                <b>USD Account Number:</b> 0003037796018022
                            </li>
                            <li>
                                <b>Bank Name:</b> DBS Bank Limited
                            </li>
                            <li>
                                <b>Bank Address:</b> 12 Marina Boulevard, DBS Asia Central, Marina
                                Bay Financial Centre Tower 3, Singapore 018982
                            </li>
                            <li>
                                <b>SwiftCode:</b> DBSSSGSG
                            </li>
                        </ul> */}
                        <p className="pl-7">
                            You can refer more options in our payment guideline at
                            <a
                                href="https://bbcincorp.com/payment-guideline"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {' '}
                                here
                            </a>
                        </p>
                        <p>
                            {' '}
                            Please arrange your payment for the service charge according to one of
                            the methods listed above. If you have paid, please send us your payment
                            slip and confirm with us via email
                            <a href="mailto:service@bbcincorp.com" target="_blank">
                                {' '}
                                service@bbcincorp.com
                            </a>
                        </p>
                    </div>
                    <div className="text-center p-md-8 py-5">
                        <Button
                            size="large"
                            variant="contained"
                            style={{ color: '#fff' }}
                            type="button"
                            color="primary"
                            onClick={handleRedirect}
                        >
                            MANAGE INVOICE
                        </Button>
                    </div>
                </div>
            )}
            {!location.state.is_success && (
                <div>
                    <div className="p-md-10 py-5 text-center">
                        <h3 className="mb-5">Failed Payment</h3>
                        <h5 className="fw-400 mb-4">
                            We are sorry that your payment was not successful yet
                        </h5>
                        <h4 className="h5">Invoice number: INC{location.state.order_id}</h4>
                    </div>
                    <div className="text-center p-md-8 py-5">
                        <Button
                            size="large"
                            variant="contained"
                            style={{ color: '#fff' }}
                            type="button"
                            color="primary"
                            onClick={handleRedirect}
                        >
                            MANAGE INVOICE
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

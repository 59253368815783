import axios from 'axios';

export const COMPANY_URL = '/customer_account/company';

export function getAllCompanies() {
    return axios.get(COMPANY_URL);
}

export function findCompanies(queryParams) {
    return axios.get(`${COMPANY_URL}`, { params: queryParams });
}

export function getAllMyCompanies(query) {
    return axios.get(COMPANY_URL + '/ver_2', { params: query });
}

export function getCompanyDetailById(id) {
    return axios.get(COMPANY_URL + '/' + id + '/detail');
}

export function getCompanyDocumentById(id) {
    return axios.get(COMPANY_URL + '/' + id + '/document');
}

export function getCompanyShareOwnInterestById(id) {
    return axios.get(COMPANY_URL + '/' + id + '/share_own_interest');
}

export function getCompanyEventById(id) {
    return axios.get(COMPANY_URL + '/' + id + '/event');
}

export function getCompletedEventById(queryParams, id) {
    return axios.get(COMPANY_URL + '/' + id + '/completed_event', { params: queryParams });
}

export function getTransactionEventById(queryParams, id) {
    return axios.get(COMPANY_URL + '/' + id + '/transaction_event', { params: queryParams });
}

export function deleteChangeRequest(payload) {
    return axios.delete(COMPANY_URL + '/change_request', {
        data: payload,
    });
}

export function createChangeRequest(payload) {
    return axios.post(COMPANY_URL + `/change_request`, payload);
}

export function updateChangeRequest(changeRequestId, payload) {
    return axios.put(COMPANY_URL + `/change_request/${changeRequestId}`, payload);
}

export function transferRequest(payload) {
    return axios.post(COMPANY_URL + '/change_request/transfer_share', payload);
}

export function getProposedName(id) {
    return axios.get(COMPANY_URL + `/${id}/proposed_name`);
}

export function putProposedName(payload) {
    return axios.put(COMPANY_URL + '/proposed_name', payload);
}

export function getChangeServices(id) {
    return axios.get(COMPANY_URL + `/${id}/review_complete`);
}

export function getDocuments(queryParams, id) {
    return axios.get(COMPANY_URL + `/${id}/document`, { params: queryParams });
}

export function getHistoryChangeById(id) {
    return axios.get(COMPANY_URL + '/' + id + '/history_changed');
}
export function getComparedCompanyById(id) {
    return axios.get('/customer_account/task/' + id + '/compare_company_info');
}
import withStyles from '@mui/styles/withStyles';
import Switch from '@mui/material/Switch';

const AntSwitch = withStyles(() => ({
  root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
      margin: '0 10px',
  },
  switchBase: {
      padding: 2,
      color: '#FFF',
      '&$checked': {
          transform: 'translateX(12px)',
          color: '#FFF',
          '& + $track': {
              opacity: 1,
              backgroundColor: '#007EFF !important',
              borderColor: '#007EFF !important',
          },
          "&.Mui-disabled": {
            '& + $track': {
                opacity: 1,
                backgroundColor: '#e1e1e1 !important',
                borderColor: '#e1e1e1 !important',
            }
          }
      },
  },
  thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
      backgroundColor: '#FFF',
  },
  track: {
      border: `1px solid #007EFF`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#007EFF',
  },
  checked: {},
}))(Switch);

export default AntSwitch;
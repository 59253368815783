import { toast } from 'react-toastify';
import * as requestFromServer from './billingsCrud';
import { billingsSlice, callTypes } from './billingsSlice';

const { actions } = billingsSlice;

export const fetchBillings = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .findBillings(queryParams)
        .then((response) => {
            const { count, rows } = response.data.data;
            dispatch(actions.billingsFetched({ count, rows }));
        })
        .catch((error) => {
            error.clientMessage = "Can't find billings";
            toast.error('Something went wrong!');
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchBilling = (id) => (dispatch) => {
    if (!id) {
        return dispatch(actions.billingFetched({ billingOrForEdit: undefined }));
    }

    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getBillingById(id)
        .then((response) => {
            dispatch(actions.billingFetched({ billingForEdit: response.data.data }));
        })
        .catch((error) => {
            error.clientMessage = "Can't find billing";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error('Something went wrong!');
        });
};

export const deleteBilling = (id) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .deleteBilling(id)
        .then((response) => {
            dispatch(actions.billingDeleted({ id }));
            toast.success(`Billing Deleted Successfully!`);
        })
        .catch((error) => {
            error.clientMessage = "Can't delete billing";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error('Something went wrong!');
        });
};

export const createBilling = (billingForCreation) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .createBilling(billingForCreation)
        .then((response) => {
            dispatch(actions.billingCreated());
            toast.success(`Billing Created  Successfully!`);
        })
        .catch((error) => {
            error.clientMessage = "Can't create billing";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error('Something went wrong!');
        });
};

export const updateBilling = (billing) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .updateBilling(billing)
        .then((response) => {
            dispatch(actions.billingUpdated({ billing }));
            toast.success(`Billing No.${response.data.data.id} Updated Successfully!`);
        })
        .catch((error) => {
            error.clientMessage = "Can't update billing";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error('Something went wrong!');
        });
};

export const deleteBillings = (ids) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .deleteBillings(ids)
        .then(() => {
            dispatch(actions.billingsDeleted({ ids }));
            toast.success(`Billings Deleted Successfully!`);
        })
        .catch((error) => {
            error.clientMessage = "Can't delete billings";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error('Something went wrong!');
        });
};

import { createSlice } from '@reduxjs/toolkit';
import {
    getCompanyDetail,
    getCompanyDocument,
    getCompanyShareOwnInterest,
    getCompanyEvent,
    getCompletedEvent,
    getTransactionEvent
} from './companiesThunk';

const initialCompaniesState = {
    listLoading: false,
    documentListLoading: false,
    actionsLoading: false,
    totalCount: 0,
    documentTotalCount: 0,
    insideCompany: null,
    outsideCompany: null,
    entities: null,
    documentEntities: null,
    lastError: null,

    companyDetail: null,
    loadingCompanyDetail: 'idle',

    companyDocument: null,
    loadingCompanyDocument: 'idle',

    companyShareOwnInterest: null,
    loadingCompanyShareOwnInterest: 'idle',

    companyEvent: null,
    loadingCompanyEvent: 'idle',

    completedEvents: null,
    completedEventsTotalCount: 0,
    loadingCompletedEvents: 'idle',

    transactionEvents: null,
    transactionEventsTotalCount: 0,
    loadingTransactionEvents: 'idle',

    comparedCompany: null,
};
export const callTypes = {
    list: 'list',
    action: 'action',
};

export const companiesSlice = createSlice({
    name: 'companies',
    initialState: initialCompaniesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        companiesFetched: (state, action) => {
            const { count, rows } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = rows;
            state.totalCount = count;
        },
        myCompaniesFetched: (state, action) => {
            const { data, insideCompany, outsideCompany } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.myCompanies = data;
            if (insideCompany) state.insideCompany = insideCompany;
            if (outsideCompany) state.outsideCompany = outsideCompany;
        },
        documentsFetched: (state, action) => {
            // get list documents
            const { count, rows } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.documentEntities = rows;
            state.documentTotalCount = count;
        },
        completedEventsFetched: (state, action) => {
            const { count, rows } = action.payload;
            state.completedEvents = rows;
            state.completedEventsTotalCount = count;
        },
        transactionEventsFetched: (state, action) => {
            const { count, rows } = action.payload;
            state.transactionEvents = rows;
            state.transactionEventsTotalCount = count;
        },
        comparedCompanyFetched: (state, action) => {
            const { data } = action.payload;
            state.comparedCompany = data;
        }
    },
    extraReducers: (builder) => {
        // getCompanyDetail
        builder.addCase(getCompanyDetail.pending, (state, action) => {
            state.loadingCompanyDetail = 'loading';
        });
        builder.addCase(getCompanyDetail.fulfilled, (state, action) => {
            state.loadingCompanyDetail = 'idle';
            state.companyDetail = action.payload;
        });
        builder.addCase(getCompanyDetail.rejected, (state, action) => {
            state.loadingCompanyDetail = 'error';
            if (action.payload) {
                state.lastError = action.payload;
            } else {
                state.lastError = action.error;
            }
        });
        // getCompanyDocument
        builder.addCase(getCompanyDocument.pending, (state, action) => {
            state.loadingCompanyDocument = 'loading';
        });
        builder.addCase(getCompanyDocument.fulfilled, (state, action) => {
            state.loadingCompanyDocument = 'idle';
            state.companyDocument = action.payload;
        });
        builder.addCase(getCompanyDocument.rejected, (state, action) => {
            state.loadingCompanyDocument = 'error';
            if (action.payload) {
                state.lastError = action.payload;
            } else {
                state.lastError = action.error;
            }
        });
        // getCompanyShareOwnInterest
        builder.addCase(getCompanyShareOwnInterest.pending, (state, action) => {
            state.loadingCompanyShareOwnInterest = 'loading';
        });
        builder.addCase(getCompanyShareOwnInterest.fulfilled, (state, action) => {
            state.loadingCompanyShareOwnInterest = 'idle';
            state.companyShareOwnInterest = action.payload;
        });
        builder.addCase(getCompanyShareOwnInterest.rejected, (state, action) => {
            state.loadingCompanyShareOwnInterest = 'error';
            if (action.payload) {
                state.lastError = action.payload;
            } else {
                state.lastError = action.error;
            }
        });
        //getCompanyEvent
        builder.addCase(getCompanyEvent.pending, (state, action) => {
            state.loadingCompanyEvent = 'loading';
        });
        builder.addCase(getCompanyEvent.fulfilled, (state, action) => {
            state.loadingCompanyEvent = 'idle';
            state.companyEvent = action.payload;
        });
        builder.addCase(getCompanyEvent.rejected, (state, action) => {
            state.loadingCompanyEvent = 'error';
            if (action.payload) {
                state.lastError = action.payload;
            } else {
                state.lastError = action.error;
            }
        });
        //getCompletedEvent
        builder.addCase(getCompletedEvent.pending, (state, action) => {
            state.loadingCompletedEvents = 'loading';
        });
        builder.addCase(getCompletedEvent.fulfilled, (state, action) => {
            state.loadingCompletedEvents = 'idle';
            state.completedEvents = action.payload;
        });
        builder.addCase(getCompletedEvent.rejected, (state, action) => {
            state.loadingCompletedEvents = 'error';
            if (action.payload) {
                state.lastError = action.payload;
            } else {
                state.lastError = action.error;
            }
        });

        //getTransactionEvent
        builder.addCase(getTransactionEvent.pending, (state, action) => {
            state.loadingTransactionEvents = 'loading';
        });
        builder.addCase(getTransactionEvent.fulfilled, (state, action) => {
            state.loadingTransactionEvents = 'idle';
            state.transactionEvents = action.payload;
        });
        builder.addCase(getTransactionEvent.rejected, (state, action) => {
            state.loadingTransactionEvents = 'error';
            if (action.payload) {
                state.lastError = action.payload;
            } else {
                state.lastError = action.error;
            }
        });
    },
});

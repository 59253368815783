import React, { useState, useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import { FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { BillingForm } from './BillingForm';
// import Select from 'react-select';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));
export function Billing({ body, setBody, newBillingCompanyName, isNewIncorp, setForm, companyId = null, formRef = {} }) {
    const { billings, countriesCooperation } = useSelector(
        (state) => ({
            billings: state.billings.entities,
            countriesCooperation: state.enum.countriesCooperation,
        }),
        shallowEqual,
    );
    const [selectedValue, setSelectedValue] = useState(
        billings && billings.length > 0 ? 'existed' : 'new',
    );
    const [billingID, setBillingID] = useState(null);
    const [billingForm, setBillingForm] = useState(null);
    const [hasBillingInfo, setHasBillingInfo] = useState(true);
    // const classes = useStyles();

    useEffect(() => {
        // console.log('billingID: ', billingID);
        if (billingID) {
            setTimeout(() => {
                if (!companyId) {
                    setBody({ ...body, billing_id: billingID.id });
                }
            }, 1000);
        }
        return () => {};
    }, [billingID]);
    useEffect(() => {
        // console.log(billingForm);
        if (!companyId) {
            setBody({ ...body, billingForm: billingForm });
        }
    }, [billingForm]);
    useEffect(() => {
        if (!body.billing_id && billings && billings.length > 0) {
            // không có illing
            setBillingID(billings[0]);
            // setHasBillingInfo(false);
        } else if (body.billing_id && billings) {
            var billReturn = billings.find((e) => e.id === body.billing_id);
            if (billReturn) {
                // có billing
                setBillingID(billReturn);
            } else {
                //  billing was deleted!
                setHasBillingInfo(false);
                setBillingID(billings[0]);
            }
        } else {
            setHasBillingInfo(false);
        }
        return () => console.log('');
    }, []);
    function handleChange(event) {
        if (event.target.value === 'existed') {
            setBillingForm(null);
            setForm && setForm(null);
        } else {
            setBillingID(billings[0]);
        }
        setSelectedValue(event.target.value);
    }

    const handleBilling = (value) => {
        setBillingID(value);
    };
    return (
        <div className="py-6">
            <h3 className="mt-9 mb-6">Billing Info</h3>
            {!isNewIncorp ? (
                hasBillingInfo ? (
                    body &&
                    body.billing_id &&
                    billings &&
                    billings.map(
                        (item, index) =>
                            item.id === body.billing_id && (
                                <p key={index} className="fw-500">
                                    Existed Billing: {item.name}
                                </p>
                            ),
                    )
                ) : (
                    //  billing was deleted!
                    <>
                        <div className="original-radio">
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="mybillings"
                                    name="mybillings"
                                    className="d-flex flex-row"
                                    value={selectedValue}
                                    onChange={handleChange}
                                >
                                    {billings && billings.length > 0 && (
                                        <FormControlLabel
                                            value="existed"
                                            control={<Radio />}
                                            label="Existed Billings"
                                        />
                                    )}
                                    <FormControlLabel
                                        value="new"
                                        control={<Radio />}
                                        label="New Billing"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>

                        {selectedValue === 'existed' && billings && billings.length > 0 && (
                            <div className="col-xl-3 col-md-4 col-sm-6 col-12 mt-3">
                                <Autocomplete
                                    id="company-type"
                                    options={billings}
                                    disableClearable
                                    value={billingID}
                                    onChange={(event, value) => {
                                        handleBilling(value);
                                    }}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Billing"
                                            variant="outlined"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                                <FormHelperText>Please select Billing</FormHelperText>
                            </div>
                        )}
                        {selectedValue === 'new' && (
                            <BillingForm
                                country={countriesCooperation}
                                setBillingForm={setBillingForm}
                                newBillingCompanyName={newBillingCompanyName}
                                setForm={setForm}
                                formRef={formRef}
                            />
                        )}
                    </>
                )
            ) : (
                <>
                    <div className="original-radio">
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="mybillings"
                                name="mybillings"
                                className="d-flex flex-row"
                                value={selectedValue}
                                onChange={handleChange}
                            >
                                {billings && billings.length > 0 && (
                                    <FormControlLabel
                                        value="existed"
                                        control={<Radio />}
                                        label="Existed Billings"
                                    />
                                )}
                                <FormControlLabel
                                    value="new"
                                    control={<Radio />}
                                    label="New Billing"
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>

                    {selectedValue === 'existed' && billings && billings.length > 0 && (
                        <div className="col-xl-3 col-md-4 col-sm-6 col-12 mt-3">
                            <Autocomplete
                                id="company-type"
                                options={billings}
                                disableClearable
                                value={billingID}
                                onChange={(event, value) => {
                                    handleBilling(value);
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Billing"
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <FormHelperText>Please select Billing</FormHelperText>
                        </div>
                    )}
                    {selectedValue === 'new' && (
                        <BillingForm
                            country={countriesCooperation}
                            setBillingForm={setBillingForm}
                            newBillingCompanyName={newBillingCompanyName}
                            setForm={setForm}
                            formRef={formRef}
                        />
                    )}
                </>
            )}
        </div>
    );
}

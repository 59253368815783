import React, { useEffect, useState, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actionsIncorporation from 'app/pages/_redux/incorporation/incorporationActions';
import * as actionsOrderForm from '../../../../../../_redux/order-form/orderFormActions';
import Button from '@mui/material/Button';

import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import * as Yup from 'yup';
import * as uiHelpers from './step3-helper/Step3UIHelpers';
import { ArticlesOfOrganizationOptions } from '../constants';
import { setArticlesOfOrganizationDefault } from '../helpers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const useStyles = makeStyles((theme) => ({
    // tooltip
    arrow: {
        color: '#3783e7',
    },
    tooltip: {
        fontSize: '12px',
        fontWeight: 300,
        letterSpacing: '0.3px',
        backgroundColor: '#3783e7',
    },
}));

const validateSchema = Yup.object().shape({
    articles_of_organization: Yup.number(),
});

export default function StepArticlesOfOrganization({
    activeStep,
    setActiveStep,
    isLoading,
    setIsLoading,
    body,
    setBody,
    disabled,
    hideAction,
    activeStepRef = {},
    validateStepsRef = {},
    index,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const form = useForm({
        defaultValues: {
            articles_of_organization: body.Company.articles_of_organization,
        },
        resolver: yupResolver(validateSchema),
    });

    const OPTIONS = ArticlesOfOrganizationOptions;

    // const [initForm, setInitForm] = useState({
    //     articles_of_organization: body.Company.articles_of_organization,
    // });

    useEffect(() => {
        try {
            setIsLoading(true);
            if (body && body.isBelize && body.isLLC) {
                let articles_of_organization_default = setArticlesOfOrganizationDefault(body);
                form.reset({
                    articles_of_organization: articles_of_organization_default,
                });
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    function onSubmitForm(value) {
        try {
            setIsLoading(true);
            let data = {
                articleOfOrganizationUpdate: {
                    // Nếu có service Nominee => Mặc định chọn Manager-managed, không thể sửa.
                    articles_of_organization:
                        body.is_has_director || body.is_has_shareholder
                            ? OPTIONS[1].id
                            : value.articles_of_organization,
                },
            };

            dispatch(actionsOrderForm.updateOrderForm(body.Task.id, data)).then((res) => {
                if (res && res.state) {
                    setBody({
                        ...body,
                        Company: {
                            ...body.Company,
                            articles_of_organization: res.data.Company.articles_of_organization,
                        },
                    });

                    /**
                     * Vì cho phép Back các step
                     * -> Phát sinh trường hợp đã chọn Manager-managed và đã nhập thông tin Managers
                     * Sau đó Back, thay đổi lựa chọn sang Member-managed
                     * => Cảnh báo và xóa all Manager đã điền trước đó.
                     * */
                    // if(value.articles_of_organization === OPTIONS[0].id) {
                    //     // Nếu có danh sách Managers
                    //     if(
                    //         body.companyStructure.Directors
                    //         && body.companyStructure.Directors.length > 0
                    //         && body.companyStructure.Directors[0].company_position_id // vì luôn có 1 init member với company_position_id=undefinded nếu chưa có Directors.
                    //     ) {
                    //         Swal.fire({
                    //             title: 'Are you sure?',
                    //             text: `You are chose ${OPTIONS[0].title}. It will be delete all Managers.`,
                    //             icon: 'warning',
                    //             showCancelButton: true,
                    //             confirmButtonColor: '#3085d6',
                    //             cancelButtonColor: '#d33',
                    //             confirmButtonText: 'Yes, delete it!'
                    //         }).then((result) => {
                    //             setIsLoading(true);
                    //             if (result.isConfirmed){
                    //                 body.companyStructure.Directors.map(item=>{
                    //                     if(item.company_position_id) {
                    //                         const delData = {
                    //                             companyStructureDelete: {
                    //                                 company_position_id: item.company_position_id,
                    //                             }

                    //                         }

                    //                         dispatch(actions.updateCompanyStructure(body.Task.id, delData)).then(res => {
                    //                             try {
                    //                                 if (res && res.state) {
                    //                                     Swal.fire({
                    //                                         icon: 'success',
                    //                                         title: 'Deleted!',
                    //                                         text: res.message,
                    //                                     })

                    //                                     // update body data
                    //                                     let bodyData = { ...body, Company: {
                    //                                         ...body.Company,
                    //                                         CompanyMembers: res.data.Company.CompanyMembers,
                    //                                     } }
                    //                                     let companyStructureNew = formatCompanyMembers(bodyData);
                    //                                     setBody({
                    //                                         ...bodyData,
                    //                                         companyStructure: { ...companyStructureNew },
                    //                                         Task: res.data.Task,
                    //                                     });
                    //                                     // setIsLoading(false);
                    //                                 } else {
                    //                                     Swal.fire({
                    //                                         icon: 'error',
                    //                                         title: 'Oops...',
                    //                                         text: 'Something went wrong!',
                    //                                     })
                    //                                     setIsLoading(false);
                    //                                 }
                    //                             } catch (error) {
                    //                                 console.log(error);
                    //                             }
                    //                         })
                    //                     }
                    //                 })
                    //             }
                    //         })
                    //     }

                    // }

                    setIsLoading(false);
                    if (typeof activeStepRef.current !== 'number') {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }
                    activeStepRef.current = null;
                } else {
                    setIsLoading(false);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // truyền form validate cho file stepper
    validateStepsRef.current = {
        ...validateStepsRef.current,
        [`STEP_${index + 1}`]: {
            isValid: form.formState.isValid,
            onSubmit: () => form.handleSubmit(onSubmitForm)(),
        },
    };

    return (
        <div className="step1">
            <form onSubmit={form.handleSubmit(onSubmitForm)}>
                <div className="mb-7">
                    <RadioGroup
                        row
                        aria-label={'articles_of_organization'}
                        name={'articles_of_organization'}
                        onChange={(e) => {
                            form.setValue('articles_of_organization', parseInt(e.target.value));
                            setBody({
                                ...body,
                                Company: {
                                    ...body.Company,
                                    articles_of_organization: parseInt(e.target.value),
                                },
                            });
                        }}
                    >
                        <div className="d-flex align-items-center mr-md-20">
                            <FormControlLabel
                                value={OPTIONS[0].id}
                                checked={
                                    form.getValues('articles_of_organization') === OPTIONS[0].id
                                }
                                control={<Radio color="secondary" />}
                                label={OPTIONS[0].title}
                                className=" mr-0 mb-0"
                                disabled={
                                    (body && body.is_has_director) ||
                                    (body && body.is_has_shareholder) ||
                                    disabled
                                }
                            />
                            <div className="wrap-label-has-tooltip">
                                <div className="wrap-tooltip d-inline ml-1">
                                    <Tooltip
                                        title={OPTIONS[0].tooltip}
                                        placement="top"
                                        arrow
                                        classes={classes}
                                    >
                                        <InfoIcon className="icon" />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            <FormControlLabel
                                value={OPTIONS[1].id}
                                checked={
                                    form.getValues('articles_of_organization') === OPTIONS[1].id
                                }
                                control={<Radio color="secondary" />}
                                label={OPTIONS[1].title}
                                className=" mr-0 mb-0"
                                disabled={
                                    (body && body.is_has_director) ||
                                    (body && body.is_has_shareholder) ||
                                    disabled
                                }
                            />
                            <div className="wrap-label-has-tooltip">
                                <div className="wrap-tooltip d-inline ml-1">
                                    <Tooltip
                                        title={OPTIONS[1].tooltip}
                                        placement="top"
                                        arrow
                                        classes={classes}
                                    >
                                        <InfoIcon className="icon" />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </RadioGroup>
                </div>
                {!hideAction && (
                    <div>
                        <Button
                            size="large"
                            variant="contained"
                            disableElevation
                            onClick={handleBack}
                            disabled={isLoading}
                            className={'mr-2 ' + classes.button}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            type="submit"
                            className={classes.button}
                            style={{ color: '#fff' }}
                            disabled={isLoading}
                            onClick={() => (activeStepRef.current = null)}
                        >
                            Next
                        </Button>
                    </div>
                )}
            </form>
        </div>
    );
}

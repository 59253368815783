import { createAsyncThunk } from "@reduxjs/toolkit";
import * as requestFromServer from './personalCrud';


export const updateLoginFirst = createAsyncThunk(
    'personal/updateLoginFirst',
    async (data, { rejectWithValue }) => {
        try {
            const response = await requestFromServer.updatePersonal({ is_first_login: 1 });
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)
import React, { useEffect } from 'react';
import {
    getDefaultNationality,
    getDefaultPhonePrefix,
} from '../../../../../../utilities/NationalityHelpers';
import { useSelector } from 'react-redux';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import { InputAdornment } from '@mui/material';
import SelectFullNameHint from 'app/components/SelectFullNameHint';

export default function UBO({
    form,
    name = 'members',
    members,
    currentMember,
    index,
    body,
    checkNumShare,
    disabled = false,
    hasShowSelectFullName,
}) {
    const { countriesCooperationOptions, phonePrefixCountriesCooperation } = useSelector(
        (state) => state.enum,
    );

    useEffect(() => {
        try {
            let phonePrefixDefault = getDefaultPhonePrefix(
                body.companyStructure.UBO[index]?.phone_code,
                phonePrefixCountriesCooperation,
            );
            let nationality = getDefaultNationality(
                body.companyStructure.UBO[index]?.country_id,
                countriesCooperationOptions,
            );

            if (currentMember) {
                form.setValue(`${name}.${index}.phone_code`, phonePrefixDefault.id);
                form.setValue(`${name}.${index}.country_id`, nationality.id);
            }
        } catch (error) {
            console.log(error);
        }
    }, [body]);

    useEffect(() => {
        try {
            if (currentMember.phone_code) {
                let phone_country_id;
                currentMember.phone_code.id // phone_code type object
                    ? (phone_country_id = currentMember.phone_code.id)
                    : (phone_country_id = currentMember.phone_code);

                const phone_prefix =
                    phonePrefixCountriesCooperation &&
                    phonePrefixCountriesCooperation.find((e) => e.id === phone_country_id);
                phone_prefix
                    ? form.setValue(`${name}.${index}.phone_code`, phone_prefix?.id)
                    : form.setValue(
                          `${name}.${index}.phone_code`,
                          phonePrefixCountriesCooperation[0]?.id,
                      );
            }
        } catch (error) {
            console.log(error);
        }
    }, [currentMember.phone_code]);

    useEffect(() => {
        try {
            if (currentMember.country_id) {
                const currCountry =
                    countriesCooperationOptions &&
                    countriesCooperationOptions.find((e) => e.id === currentMember.country_id);
                currCountry
                    ? form.setValue(`${name}.${index}.country_id`, currCountry?.id)
                    : form.setValue(
                          `${name}.${index}.country_id`,
                          countriesCooperationOptions[0]?.id,
                      );
            }
        } catch (error) {
            console.log(error);
        }
    }, [currentMember.country_id]);

    return (
        <>
            <div className="row">
                {hasShowSelectFullName && (
                    <>
                        <div className="col-md-4 mb-7 input-line">
                            <label>First name</label>
                            <InputField
                                form={form}
                                name={`${name}.${index}.first_name`}
                                placeholder="First name"
                                showIcon
                                hideSuccessHelper
                                disabled={disabled}
                                onChange={(e) => {
                                    form.setValue(`${name}.${index}.first_name`, e.target.value);
                                    if (form.getValues(`${name}.${index}.full_name`)) {
                                        form.setValue(`${name}.${index}.full_name`, '');
                                    }
                                }}
                            />
                        </div>
                        <div className="col-md-4 mb-7 input-line">
                            <label>Middle name</label>
                            <InputField
                                form={form}
                                name={`${name}.${index}.middle_name`}
                                placeholder="Middle name"
                                showIcon
                                hideSuccessHelper
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-md-4 mb-7 input-line">
                            <label>Last name</label>
                            <InputField
                                form={form}
                                name={`${name}.${index}.last_name`}
                                placeholder="Last name"
                                showIcon
                                hideSuccessHelper
                                disabled={disabled}
                                onChange={(e) => {
                                    form.setValue(`${name}.${index}.last_name`, e.target.value);
                                    if (form.getValues(`${name}.${index}.full_name`)) {
                                        form.setValue(`${name}.${index}.full_name`, '');
                                    }
                                }}
                            />
                        </div>
                    </>
                )}
                <div className="col-md-6 mb-7 input-line">
                    <label>Full name</label>
                    <InputField
                        form={form}
                        name={`members.${index}.full_name`}
                        placeholder="Enter full name"
                        disabled={disabled || hasShowSelectFullName}
                        hideSuccessHelper
                        multiline={hasShowSelectFullName}
                    />
                </div>
                <div className="col-md-6 mb-7 input-line">
                    <label>Occupation</label>
                    <InputField
                        form={form}
                        name={`${name}.${index}.occupation`}
                        placeholder="Enter occupation"
                        disabled={disabled}
                        hideSuccessHelper
                    />
                </div>
                {hasShowSelectFullName && (
                    <SelectFullNameHint
                        form={form}
                        fullNameField={`members.${index}.full_name`}
                        firstNameField={`members.${index}.first_name`}
                        middleNameField={`members.${index}.middle_name`}
                        lastNameField={`members.${index}.last_name`}
                        disabled={disabled}
                    />
                )}
                <div className="col-md-6 mb-7 input-line">
                    <label>Phone number</label>
                    <div className="phone-group">
                        <div className="phone-code" style={{ zIndex: 1 }}>
                            <SelectField
                                form={form}
                                className="fw-500"
                                name={`${name}.${index}.phone_code`}
                                id={`${name}.${index}.phone_code`}
                                options={phonePrefixCountriesCooperation || []}
                                disabled={disabled}
                                customStyle={{
                                    '.MuiInputBase-root:before, .MuiInputBase-root:after, .MuiInput-underline:hover:not(.Mui-disabled):before':
                                        {
                                            borderBottom: '0 !important',
                                        },
                                }}
                                hideHelperText
                            />
                        </div>
                        <div className="input-line inp-phone">
                            <InputField
                                form={form}
                                name={`${name}.${index}.phone`}
                                placeholder="Enter phone number"
                                disabled={disabled}
                                hideSuccessHelper
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-6 mb-7 input-line">
                    <label>Email address</label>
                    <InputField
                        form={form}
                        name={`${name}.${index}.email`}
                        placeholder="Enter email address"
                        disabled={disabled}
                        hideSuccessHelper
                    />
                </div>
                <div className="col-md-6 col-sm-6 mb-7 input-line">
                    <label>Nationality</label>
                    <SelectField
                        form={form}
                        name={`${name}.${index}.country_id`}
                        id={`${name}.${index}.country_id`}
                        options={countriesCooperationOptions || []}
                        disabled={disabled}
                        hideHelperText
                    />
                </div>
                <div className="col-md-12 mb-7 input-line">
                    <div style={{ width: '120px' }}>
                        <label>Percentage of interest</label>
                        <InputField
                            type="number"
                            form={form}
                            name={`${name}.${index}.percentage`}
                            placeholder="100"
                            disabled={disabled}
                            onChange={(e) => {
                                checkNumShare(e.target.value, index, form.getValues());
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            hideSuccessHelper
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

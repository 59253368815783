import React, { useState, useMemo } from 'react';

// === table
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// === material
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
//
import { Quantity } from '../../components/Quantity';
import displayUnit from '../../utilities/display-unit-service';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const ANNUAL_RENEWAL_SERVICE_TYPE = 18;

export const CompanyServiceItem = ({
    index,
    classes,
    setServices,
    category,
    services,
    setRecord,
    record,
    disabled,
    nomineeServiceId,
    nomineeService,
}) => {
    const COMPANY_DETAIL_SERVICES_ID = [24, 38, 39];
    const [categroyIsSelected, setCategroyIsSelected] = useState([]);
    const categoryOptions = useMemo(() => {
        let categoryOptions = [...category];
        // return category.filter((cate) => ![24, 38, 39].includes(cate.id)); COMMENT FOR COMPANY DETAIL
        categoryOptions = categoryOptions.filter(
            (cate) => ![34, ...COMPANY_DETAIL_SERVICES_ID].includes(cate.id),
        ); // compliance calendar (remove category Post-incorporation)

        // let service = services.find((e) => e.service_type_id === 34);
        // if (service && service.disable === true) return category.filter((e) => e.id !== 34); //check if has service Belize TIN then remove it from category
        return categoryOptions;
    }, [category.length]);

    const onChangeCategory = (event, value, index) => {
        var newarr = categroyIsSelected;
        var oldCategory = categroyIsSelected[index];
        newarr[index] = value;
        setCategroyIsSelected({ ...newarr });
        var tempArr = services;
        tempArr[index] = { ...categroyIsSelected[index].Services[0], quantity: 1 };
        if (value.id === ANNUAL_RENEWAL_SERVICE_TYPE && nomineeServiceId && nomineeService) {
            if (oldCategory?.id !== value.id) {
                setServices([
                    ...tempArr,
                    { ...nomineeService, quantity: 1, disable: true, parent_id: tempArr[index].id },
                ]);
            }
        } else {
            if (oldCategory?.id === ANNUAL_RENEWAL_SERVICE_TYPE && oldCategory?.id !== value.id) {
                tempArr = tempArr.filter((item) => !item?.parent_id);
            }
            setServices([...tempArr]);
        }
    };

    const onChangeService = (event, value, index) => {
        var tempArr = services;
        tempArr[index] = { ...value, quantity: 1 };
        setServices([...tempArr]);
    };

    return (
        <TableRow>
            <TableCell align="center" width="5%" scope="row">
                {index + 1}
            </TableCell>
            <TableCell align="left" width="20%">
                <Autocomplete
                    id={index.toString() + 'select-category'}
                    onChange={(event, value) => {
                        onChangeCategory(event, value, index);
                    }}
                    options={disabled ? category : categoryOptions}
                    getOptionDisabled={(option) =>
                        services.find(
                            (item, i) =>
                                item.service_type_id === ANNUAL_RENEWAL_SERVICE_TYPE && i != index,
                        ) && option.id === ANNUAL_RENEWAL_SERVICE_TYPE
                    }
                    classes={{
                        option: classes.option,
                    }}
                    autoHighlight
                    disableClearable
                    value={
                        category.find((e) => e.id === services[index].ServiceType.id) !== undefined
                            ? category.find((e) => e.id === services[index].ServiceType.id)
                            : services[index].ServiceType
                    }
                    groupBy={(option) => option.parent_name}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => {
                        return (
                            <Box key={option.id} {...props} sx={{ ml: option.parent_id ? 0 : -1 }}>
                                {option.parent_id ? (
                                    <Typography fontSize={'0.5rem'}>●</Typography>
                                ) : (
                                    '-'
                                )}{' '}
                                <Typography ml={'4px'}>{option.name}</Typography>
                            </Box>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'select Category', // disable autocomplete and autofill
                            }}
                        />
                    )}
                    disabled={disabled}
                />
                <FormHelperText>Please select Category</FormHelperText>
            </TableCell>
            <TableCell align="left" width="30%">
                <Autocomplete
                    id={index.toString() + 'select-service'}
                    onChange={(event, value) => {
                        onChangeService(event, value, index);
                    }}
                    options={
                        services[index]
                            ? category.find((e) => e.id === services[index].ServiceType.id)
                                ? category.find((e) => e.id === services[index].ServiceType.id)
                                      .Services
                                : []
                            : categroyIsSelected[index]
                            ? categroyIsSelected[index].Services
                            : []
                    }
                    classes={{
                        option: classes.option,
                    }}
                    autoHighlight
                    disableClearable
                    value={
                        services[index] ? services[index] : categroyIsSelected[index] ? null : null
                    }
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'Service', // disable autocomplete and autofill
                            }}
                        />
                    )}
                    disabled={disabled}
                />
                <FormHelperText>Please select Service</FormHelperText>
            </TableCell>
            <TableCell align="center" width="20%">
                {services[index] ? (
                    <div className="text-nowrap">US${displayUnit(services[index].Fee)}</div>
                ) : (
                    ''
                )}
            </TableCell>
            <TableCell align="center" width="5%" className="wrap-quantity text-nowrap">
                <Quantity
                    index={index}
                    setServices={setServices}
                    services={services}
                    setRecord={setRecord}
                    record={record}
                    disabled={disabled}
                />
            </TableCell>
            <TableCell align="center" width="20%">
                {services[index] ? (
                    <div className="text-nowrap">
                        US${services[index].Fee.value * services[index].quantity}
                    </div>
                ) : (
                    ''
                )}
            </TableCell>
        </TableRow>
    );
};

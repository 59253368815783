import React, { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from '@mui/material';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import makeStyles from '@mui/styles/makeStyles';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import * as actions from '../../../../../_redux/order-form/orderFormActions';
import { getContactInfo } from './helpers';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const validationInterger = function () {
    return this.test(function (value) {
        if ((value + '').split('.').length >= 2) {
            return this.createError({ path: this.path, message: 'Number is integer!' });
        }
        return true;
    });
};
const transformNumber = function () {
    return this.transform(function (value) {
        return isNaN(value) ? undefined : value;
    });
};

Yup.addMethod(Yup.mixed, 'validateInterger', validationInterger);
Yup.addMethod(Yup.mixed, 'transformNumber', transformNumber);

const businessInfoSchema = Yup.object().shape({
    isSVG: Yup.boolean(),
    isBahamas: Yup.boolean(),
    isDelaware: Yup.boolean(),
    full_name: Yup.string().nullable().required('Full name is required!'),
    country_id: Yup.string().nullable(),
    address: Yup.string().nullable().required('Address is required!'),
    city: Yup.string().nullable().required('City is required!'),
    delaware_question_1: Yup.string()
        .nullable()
        .when('isDelaware', {
            is: true,
            then: Yup.string().nullable().required('This fields is required!'),
        }),
    number_of_employees: Yup.number()
        .transformNumber()
        .validateInterger()
        .when('isDelaware', {
            is: true,
            then: Yup.number()
                .transformNumber()
                .validateInterger()
                .required('Number of Employees is required.'),
        }),
    name_of_license: Yup.string()
        .nullable()
        .when('bahamas_question_4', {
            is: 'Yes',
            then: Yup.string().nullable().required('Please enter a name!'),
        }),
    name_of_foreign_auth: Yup.string()
        .nullable()
        .when('bahamas_question_5', {
            is: 'Yes',
            then: Yup.string().nullable().required('Please enter a name!'),
        }),
    country_of_foreign_auth: Yup.number()
        .nullable()
        .when('bahamas_question_5', {
            is: 'Yes',
            then: Yup.number().nullable().required('Choose a country!'),
        }),
    website_url: Yup.string()
        .nullable()
        .when('svg_question_1', {
            is: 'Yes',
            then: Yup.string().nullable().required('Website address is required.'),
        }),
    svg_website_ip: Yup.string()
        .nullable()
        .when('svg_question_2', {
            is: 'Yes',
            then: Yup.string().nullable().required('Website IP is required.'),
        }),
});

const monthsList = [
    { id: 1, label: 'January' },
    { id: 2, label: 'February' },
    { id: 3, label: 'March' },
    { id: 4, label: 'April' },
    { id: 5, label: 'May' },
    { id: 6, label: 'June' },
    { id: 7, label: 'July' },
    { id: 8, label: 'August' },
    { id: 9, label: 'September' },
    { id: 10, label: 'October' },
    { id: 11, label: 'November' },
    { id: 12, label: 'December' },
];

export default function Step4({
    setActiveStep,
    setStep4Back,
    isHongkong,
    body,
    setBody,
    isSVG,
    isDelaware,
    isBahamas,
    isLoading,
    setIsLoading = () => {},
    disabled,
    hideAction,
    activeStepRef = {},
    validateStepsRef = {},
    index,
    hasShowSelectFullName,
}) {
    const dispatch = useDispatch();
    const questions = body.Task.IncorporationCountry?.Questions ?? [];
    const { countriesCooperationOptions } = useSelector((state) => state.enum);

    const classes = useStyles();
    const initState = {
        isSVG: isSVG,
        isDelaware: isDelaware,
        isBahamas: isBahamas,
        full_name:
            body?.Company?.BusinessInformation?.full_name ||
            getContactInfo(body.Company)?.full_name ||
            `${getContactInfo(body.Company)?.first_name || ''} ${
                getContactInfo(body.Company)?.last_name || ''
            }` ||
            (body.contactInfo && body.contactInfo.full_name) ||
            '',
        address:
            body.Company.BusinessInformation.address ||
            (body.companyInfo && body.companyInfo.operation_address) ||
            '',
        country_id:
            body.Company.BusinessInformation.country_id ||
            (body.companyInfo && body.companyInfo.operating_country_id) ||
            '',
        city:
            body.Company.BusinessInformation.city ||
            (body.companyInfo && body.companyInfo.city) ||
            '',
        state:
            body.Company.BusinessInformation.state ||
            (body.companyInfo && body.companyInfo.state) ||
            '',
        postal_code:
            body.Company.BusinessInformation.postal_code ||
            (body.companyInfo && body.companyInfo.postal_code) ||
            '',
        financial_month_end: body?.Company?.BusinessInformation?.month_end || monthsList[11].id,
        delaware_question_1:
            body.Task.IncorporationCountry?.Questions &&
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 7)?.Options.find(
                (opt) => opt.id === 11,
            ).Answers.length === 0
                ? 'No'
                : 'Yes' || null,
        number_of_employees:
            body.Task.IncorporationCountry?.Questions &&
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 8)?.Options.find(
                (opt) => opt.id === 13,
            ).Answers.length > 0
                ? body.Task.IncorporationCountry?.Questions.find((q) => q.id === 8)?.Options.find(
                      (opt) => opt.id === 13,
                  ).Answers[0]?.value_text
                : 0 || null,
        bahamas_question_1:
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 9)?.Options.find(
                (opt) => opt.id === 14,
            ).Answers.length > 0
                ? 'Yes'
                : 'No' || null,
        bahamas_question_2:
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 10)?.Options.find(
                (opt) => opt.id === 16,
            ).Answers.length > 0
                ? 'Yes'
                : 'No' || null,
        bahamas_question_3:
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 11)?.Options.find(
                (opt) => opt.id === 18,
            ).Answers.length > 0
                ? 'Yes'
                : 'No' || null,
        regulated_by_1:
            body.Task.IncorporationCountry?.Questions &&
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 11)
                ?.Children.find((opt) => opt.id === 12)
                .Options.find((opt) => opt.id === 20).Answers.length > 0
                ? ['Yes']
                : [] || null,
        regulated_by_2:
            body.Task.IncorporationCountry?.Questions &&
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 11)
                ?.Children.find((opt) => opt.id === 12)
                .Options.find((opt) => opt.id === 21).Answers.length > 0
                ? ['Yes']
                : [] || null,
        regulated_by_3:
            body.Task.IncorporationCountry?.Questions &&
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 11)
                ?.Children.find((opt) => opt.id === 12)
                .Options.find((opt) => opt.id === 22).Answers.length > 0
                ? ['Yes']
                : [] || null,
        bahamas_question_4:
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 13)?.Options.find(
                (opt) => opt.id === 23,
            ).Answers.length > 0
                ? 'Yes'
                : 'No' || null,
        name_of_license:
            body.Task.IncorporationCountry?.Questions &&
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 13)
                ?.Children.find((opt) => opt.id === 14)
                .Options.find((opt) => opt.id === 25).Answers.length > 0
                ? body.Task.IncorporationCountry?.Questions.find((q) => q.id === 13)
                      ?.Children.find((opt) => opt.id === 14)
                      .Options.find((opt) => opt.id === 25).Answers[0]?.value_text
                : '' || null,
        bahamas_question_5:
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 15)?.Options.find(
                (opt) => opt.id === 26,
            ).Answers.length > 0
                ? 'Yes'
                : 'No' || null,
        name_of_foreign_auth:
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 15)
                ?.Children.find((opt) => opt.id === 16)
                .Options.find((opt) => opt.id === 28).Answers.length > 0
                ? body.Task.IncorporationCountry?.Questions.find((q) => q.id === 15)
                      ?.Children.find((opt) => opt.id === 16)
                      .Options.find((opt) => opt.id === 28).Answers[0]?.value_text
                : '' || null,
        country_of_foreign_auth:
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 15)
                ?.Children.find((opt) => opt.id === 16)
                .Options.find((opt) => opt.id === 28).Answers.length > 0
                ? body.Task.IncorporationCountry?.Questions.find((q) => q.id === 15)
                      ?.Children.find((opt) => opt.id === 16)
                      .Options.find((opt) => opt.id === 28).Answers[0]?.select_country_id
                : 1 || 1,
        svg_question_1:
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 3)?.Options.find(
                (opt) => opt.id === 5,
            ).Answers.length > 0
                ? 'Yes'
                : 'No' || null,
        website_url:
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 3)?.Options.find(
                (opt) => opt.id === 5,
            ).Answers.length > 0
                ? body.Task.IncorporationCountry?.Questions.find((q) => q.id === 3)
                      ?.Children.find((q) => q.id === 4)
                      .Options.find((opt) => opt.id === 7).Answers[0]?.value_text
                : null,
        svg_question_2:
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 5)?.Options.find(
                (opt) => opt.id === 8,
            ).Answers.length > 0
                ? 'Yes'
                : 'No' || null,
        svg_website_ip:
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 5)?.Options.find(
                (opt) => opt.id === 8,
            ).Answers.length > 0
                ? body.Task.IncorporationCountry?.Questions.find((q) => q.id === 5)
                      ?.Children.find((q) => q.id === 6)
                      .Options.find((opt) => opt.id === 10).Answers[0]?.value_text
                : null,
    };

    const [initBusinessInfo, setInitBusinessInfo] = useState(initState);
    /* Set default 30-12 because old code set that */
    const [financialMonthEnd, setFinancialMonthEnd] = useState(monthsList[11]);

    const [country, setCountry] = useState('');
    const [select_country, setSelect_Country] = useState('');

    const form = useForm({
        defaultValues: initBusinessInfo,
        resolver: yupResolver(businessInfoSchema),
    });

    useEffect(() => {
        try {
            let country_id = body.Task.IncorporationCountry?.Questions.find((q) => q.id === 15)
                ?.Children.find((opt) => opt.id === 16)
                .Options.find((opt) => opt.id === 28).Answers[0]?.select_country_id;
            body.Task.IncorporationCountry?.Questions &&
            body.Task.IncorporationCountry?.Questions.find((q) => q.id === 15)
                ?.Children.find((opt) => opt.id === 16)
                .Options.find((opt) => opt.id === 28)?.Answers[0]?.select_country_id === 1
                ? setSelect_Country(countriesCooperationOptions[0])
                : setSelect_Country(countriesCooperationOptions[country_id - 1]);
        } catch (error) {
            console.log(error);
        }
    }, [countriesCooperationOptions]);
    useEffect(() => {
        try {
            setIsLoading(true);
            if (countriesCooperationOptions && body) {
                if (body.Company.BusinessInformation.country_id) {
                    body.Company.BusinessInformation.country_id === 250 // Delaware thuộc US 238
                        ? setCountry(countriesCooperationOptions.find((e) => e.id === 238))
                        : setCountry(
                              countriesCooperationOptions.find(
                                  (e) => e.id === body.Company.BusinessInformation.country_id,
                              ),
                          );
                } else {
                    body.companyInfo.operating_country_id === 250 // Delaware thuộc US 238
                        ? setCountry(countriesCooperationOptions.find((e) => e.id === 238))
                        : setCountry(
                              countriesCooperationOptions.find(
                                  (e) => e.id === body.companyInfo.operating_country_id,
                              ),
                          );
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    }, [countriesCooperationOptions, body]);

    useEffect(() => {
        try {
            setIsLoading(true);
            if (body) {
                if (body?.Company?.BusinessInformation?.month_end) {
                    form.setValue(
                        'financial_month_end',
                        body?.Company?.BusinessInformation?.month_end,
                    );
                    const selectedMonthIndex = monthsList.findIndex(
                        (item) => item.id === body.Company.BusinessInformation.month_end,
                    );
                    setFinancialMonthEnd(monthsList[selectedMonthIndex]);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    const handleBack = () => {
        try {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            setStep4Back(true);
        } catch (error) {
            console.log(error);
        }
    };

    const handleNext = (values) => {
        try {
            setIsLoading(true);

            const businessInformation = {
                business_full_name: values.full_name,
                business_address: values.address,
                business_country_id: values.country_id,
                business_city: values.city,
                business_state: values.state,
                business_postal_code: values.postal_code,
                business_month_end: values.financial_month_end,
            };

            let options = [];
            if (isDelaware) {
                questions.forEach((q, idx) => {
                    switch (q.id) {
                        case 7:
                            q.Options.forEach((opt) => {
                                let option = {
                                    option_id: opt.id,
                                    is_chosen:
                                        values[`delaware_question_1`] === opt.content ? 1 : 0,
                                };
                                options.push(option);
                            });
                            break;
                        case 8:
                            q.Options.forEach((opt) => {
                                let option = {
                                    option_id: opt.id,
                                    value_text: parseInt(
                                        values.number_of_employees?.toString(),
                                    ).toString(),
                                    is_chosen: 1,
                                };
                                options.push(option);
                            });
                            break;
                        default:
                            break;
                    }
                });
            }

            if (isBahamas) {
                questions.forEach((q, idx) => {
                    switch (q.id) {
                        case 9:
                            q.Options.forEach((opt) => {
                                let option = {
                                    option_id: opt.id,
                                    is_chosen: values[`bahamas_question_1`] === opt.content ? 1 : 0,
                                };
                                options.push(option);
                            });
                            break;

                        case 10:
                            q.Options.forEach((opt) => {
                                let option = {
                                    option_id: opt.id,
                                    is_chosen: values[`bahamas_question_2`] === opt.content ? 1 : 0,
                                };
                                options.push(option);
                            });
                            break;
                        case 11:
                            q.Options.forEach((opt) => {
                                let option = {
                                    option_id: opt.id,
                                    is_chosen: values[`bahamas_question_3`] === opt.content ? 1 : 0,
                                };
                                options.push(option);
                            });
                            q.Children[0].Options.forEach((opt, index) => {
                                if (values[`bahamas_question_3`] === 'Yes') {
                                    let option = {
                                        option_id: opt.id,
                                        is_chosen:
                                            values[`regulated_by_${index + 1}`] &&
                                            values[`regulated_by_${index + 1}`][0] === 'Yes'
                                                ? 1
                                                : 0,
                                    };
                                    options.push(option);
                                }
                            });

                            break;
                        case 13:
                            q.Options.forEach((opt) => {
                                if (values[`bahamas_question_4`] === 'Yes') {
                                    let option = {
                                        option_id: opt.id,
                                        is_chosen:
                                            values[`bahamas_question_4`] === opt.content ? 1 : 0,
                                    };
                                    options.push(option);
                                } else {
                                    let option = {
                                        option_id: opt.id,
                                        is_chosen:
                                            values[`bahamas_question_4`] === opt.content ? 1 : 0,
                                    };
                                    options.push(option);
                                }
                            });
                            q.Children[0].Options.forEach((opt) => {
                                if (values[`bahamas_question_4`] === 'Yes') {
                                    let option = {
                                        option_id: opt.id,
                                        value_text: values.name_of_license,
                                        is_chosen: 1,
                                    };
                                    options.push(option);
                                }
                            });
                            break;
                        case 15:
                            q.Options.forEach((opt) => {
                                let option = {
                                    option_id: opt.id,
                                    is_chosen: values[`bahamas_question_5`] === opt.content ? 1 : 0,
                                };
                                options.push(option);
                            });
                            q.Children[0].Options.forEach((opt) => {
                                if (values[`bahamas_question_5`] === 'Yes') {
                                    let option = {
                                        option_id: opt.id,
                                        value_text: values.name_of_foreign_auth,
                                        select_country_id: values.country_of_foreign_auth,
                                        is_chosen: 1,
                                    };
                                    options.push(option);
                                }
                            });

                            break;
                        default:
                            break;
                    }
                });
            }
            if (isSVG) {
                questions.forEach((q, idx) => {
                    switch (q.id) {
                        case 3:
                            q.Options.forEach((opt) => {
                                if (values.svg_question_1 === 'Yes') {
                                    let option = {
                                        option_id: opt.id,
                                        is_chosen: values.svg_question_1 === opt.content ? 1 : 0,
                                    };
                                    options.push(option);
                                } else {
                                    let option = {
                                        option_id: opt.id,
                                        is_chosen: values.svg_question_1 === opt.content ? 1 : 0,
                                    };
                                    options.push(option);
                                }
                            });
                            q.Children[0].Options.forEach((opt) => {
                                if (values.svg_question_1 === 'Yes') {
                                    let option = {
                                        option_id: opt.id,
                                        value_text: values.website_url,
                                        is_chosen: 1,
                                    };
                                    options.push(option);
                                }
                            });
                            break;
                        case 5:
                            q.Options.forEach((opt) => {
                                if (values.svg_question_2 === 'Yes') {
                                    let option = {
                                        option_id: opt.id,
                                        is_chosen: values.svg_question_2 === opt.content ? 1 : 0,
                                    };
                                    options.push(option);
                                } else {
                                    let option = {
                                        option_id: opt.id,
                                        is_chosen: values.svg_question_2 === opt.content ? 1 : 0,
                                    };
                                    options.push(option);
                                }
                            });
                            q.Children[0].Options.forEach((opt) => {
                                if (values.svg_question_2 === 'Yes') {
                                    let option = {
                                        option_id: opt.id,
                                        value_text: values.svg_website_ip,
                                        is_chosen: 1,
                                    };
                                    options.push(option);
                                }
                            });
                            break;
                        default:
                            break;
                    }
                });
            }

            let finalData = {
                businessInformation: businessInformation,
                answersForTask: options,
            };

            dispatch(actions.updateOrderForm(body.Task.id, finalData)).then((res) => {
                try {
                    if (res && res.state) {
                        let IncorporationCountry = res.data.Task.IncorporationCountry;
                        setBody({
                            ...body,
                            Task: { ...body.Task, IncorporationCountry: IncorporationCountry },
                            Company: {
                                ...body.Company,
                                BusinessInformation: res.data.Company.BusinessInformation,
                            },
                        });
                        setTimeout(() => {
                            setIsLoading(false);
                        }, 2000);
                        if (typeof activeStepRef.current !== 'number') {
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        }
                        activeStepRef.current = null;
                    }
                } catch (error) {
                    console.log(error);
                }
            });
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    // for Question 1:
    const handleChangeDelawareQuestion1 = (event) => {
        setIs_delaware_question_1_yes(event.target.value);
    };
    const [is_delaware_question_1_yes, setIs_delaware_question_1_yes] = useState(
        initBusinessInfo.delaware_question_1,
    );
    useEffect(() => {
        form.setValue('delaware_question_1', is_delaware_question_1_yes);
    }, [is_delaware_question_1_yes]);

    // for svg Question 1:
    const handleChangeSVGQuestion1 = (event) => {
        setIs_svg_question_1_yes(event.target.value);
    };
    const [is_svg_question_1_yes, setIs_svg_question_1_yes] = useState(
        initBusinessInfo.svg_question_1,
    );
    useEffect(() => {
        form.setValue('svg_question_1', is_svg_question_1_yes);
    }, [is_svg_question_1_yes]);

    // for svg Question 2:
    const handleChangeSVGQuestion2 = (event) => {
        setIs_svg_question_2_yes(event.target.value);
    };
    const [is_svg_question_2_yes, setIs_svg_question_2_yes] = useState(
        initBusinessInfo.svg_question_2,
    );
    useEffect(() => {
        form.setValue('svg_question_2', is_svg_question_2_yes);
    }, [is_svg_question_2_yes]);

    // Logic belong to BAHAMAS
    // for Question 1:
    const handleChangeQuestion1 = (event) => {
        setIs_bahamas_question_1_yes(event.target.value);
    };
    const [is_bahamas_question_1_yes, setIs_bahamas_question_1_yes] = useState(
        initBusinessInfo.bahamas_question_1,
    );
    useEffect(() => {
        form.setValue('bahamas_question_1', is_bahamas_question_1_yes);
    }, [is_bahamas_question_1_yes]);
    // for Question 2:
    const handleChangeQuestion2 = (event) => {
        setIs_bahamas_question_2_yes(event.target.value);
    };
    const [is_bahamas_question_2_yes, setIs_bahamas_question_2_yes] = useState(
        initBusinessInfo.bahamas_question_2,
    );
    useEffect(() => {
        form.setValue('bahamas_question_2', is_bahamas_question_2_yes);
    }, [is_bahamas_question_2_yes]);
    // for Question 3:
    const handleChangeQuestion3 = (event) => {
        setIs_bahamas_question_3_yes(event.target.value);
    };
    const [is_bahamas_question_3_yes, setIs_bahamas_question_3_yes] = useState(
        initBusinessInfo.bahamas_question_3,
    );
    useEffect(() => {
        form.setValue('bahamas_question_3', is_bahamas_question_3_yes);
    }, [is_bahamas_question_3_yes]);
    // for Question 4:
    const handleChangeQuestion4 = (event) => {
        setIs_bahamas_question_4_yes(event.target.value);
    };
    const [is_bahamas_question_4_yes, setIs_bahamas_question_4_yes] = useState(
        initBusinessInfo.bahamas_question_4,
    );
    useEffect(() => {
        form.setValue('bahamas_question_4', is_bahamas_question_4_yes);
    }, [is_bahamas_question_4_yes]);
    // for Question 5:
    const handleChangeQuestion5 = (event) => {
        setIs_bahamas_question_5_yes(event.target.value);
    };
    const [is_bahamas_question_5_yes, setIs_bahamas_question_5_yes] = useState(
        initBusinessInfo.bahamas_question_5,
    );
    useEffect(() => {
        form.setValue('bahamas_question_5', is_bahamas_question_5_yes);
    }, [is_bahamas_question_5_yes]);

    const handleSubmit = (values) => {
        handleNext(values);
    };

    // truyền form validate cho file stepper
    validateStepsRef.current = {
        ...validateStepsRef.current,
        STEP_4: {
            isValid: form.formState.isValid,
            onSubmit: () => form.handleSubmit(handleSubmit)(),
        },
    };

    return (
        <div>
            <div className="step4">
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <p className="fw-500 mt-2 mb-1">Location of books and records:</p>
                    <p className="mb-2">
                        The indicated address where the company keep its accounting records. Should
                        be one address of company member
                    </p>

                    <div className="row">
                        <div className="col-md-6 mb-7 input-line">
                            <label>Enter full name</label>
                            <InputField
                                form={form}
                                name="full_name"
                                placeholder="Full name"
                                showIcon
                                hideSuccessHelper
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-6 mb-7 input-line">
                            <label>Enter address</label>
                            <InputField
                                form={form}
                                name="address"
                                placeholder="Address"
                                showIcon
                                hideSuccessHelper
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-sm-3 mb-7 input-line">
                            <label>Country</label>
                            <SelectField
                                form={form}
                                name="country_id"
                                id="country-select"
                                placeholder="Choose country"
                                options={countriesCooperationOptions || []}
                                onChange={(value) => {
                                    if (country?.id != value.id) {
                                        setCountry(value);
                                    }
                                }}
                                hideHelperText
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-sm-3 mb-7 input-line">
                            {/* <Field
                                type="text"
                                name="city"
                                component={Input}
                                placeholder="Your city"
                                labelNormal="City"
                            /> */}
                            <label>Your city</label>
                            <InputField
                                form={form}
                                name="city"
                                placeholder="City"
                                showIcon
                                hideSuccessHelper
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-sm-3 mb-7 input-line">
                            <label>State/Province</label>
                            <InputField
                                form={form}
                                name="state"
                                placeholder="Your State/Province"
                                showIcon
                                hideSuccessHelper
                                disabled={disabled}
                            />
                        </div>

                        <div className="col-sm-3 mb-7 input-line">
                            <label>ZIP/Postal code</label>
                            <InputField
                                form={form}
                                name="postal_code"
                                placeholder="Your Zip/Postal code"
                                showIcon
                                hideSuccessHelper
                                disabled={disabled}
                            />
                        </div>

                        <div className="col-12">
                            <p className="fw-500 my-2">Financial Year-End:</p>
                            <div className="row">
                                <div className="col-sm-3 mb-7 input-line">
                                    <label>Month</label>
                                    <SelectField
                                        form={form}
                                        name="financial_month_end"
                                        id="financial-month-end-select"
                                        placeholder="Financial Year-End"
                                        options={monthsList || []}
                                        getOptionLabel={(option) => option.label}
                                        onChange={(value) => {
                                            if (financialMonthEnd.id != value.id) {
                                                setFinancialMonthEnd(value);
                                                form.setValue('financial_month_end', value?.id);
                                            }
                                        }}
                                        hideHelperText
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </div>

                        {isDelaware ? (
                            <div className="notBahamas">
                                <div className="col-12 input-line my-4">
                                    <div id="my-radio-group">
                                        Does the Responsible Party have both a US address and US
                                        SSN? (Yes/No)?
                                    </div>
                                    <RadioGroup
                                        row
                                        aria-label="type"
                                        form={form}
                                        name="svg_question_1"
                                        onChange={handleChangeDelawareQuestion1}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            checked={is_delaware_question_1_yes === 'Yes'}
                                            control={
                                                <Radio color="secondary" disabled={disabled} />
                                            }
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="No"
                                            checked={is_delaware_question_1_yes === 'No'}
                                            control={
                                                <Radio color="secondary" disabled={disabled} />
                                            }
                                            label="No"
                                        />
                                    </RadioGroup>
                                </div>

                                <p className="ml-4">
                                    How Many Employees Are Expected in Next 12 Months for
                                    Forecasting purposes, if any
                                </p>
                                <div className="col-5 mb-7 input-line">
                                    <label>Number Of Employees</label>
                                    <InputField
                                        type="number"
                                        form={form}
                                        name="number_of_employees"
                                        placeholder="10"
                                        defaultValues={form.getValues('number_of_employees')}
                                        hideSuccessHelper
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        ) : isBahamas ? (
                            <div className="Bahamas">
                                <div className="col-12 input-line">
                                    <div id="my-radio-group">
                                        Will the Company’s shares be listed on a regulated
                                        securities exchange? (Yes/No) ?
                                    </div>
                                    <RadioGroup
                                        row
                                        aria-label="type"
                                        form={form}
                                        name="bahamas_question_1"
                                        onChange={handleChangeQuestion1}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            checked={is_bahamas_question_1_yes === 'Yes'}
                                            control={
                                                <Radio color="secondary" disabled={disabled} />
                                            }
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="No"
                                            checked={is_bahamas_question_1_yes === 'No'}
                                            control={
                                                <Radio color="secondary" disabled={disabled} />
                                            }
                                            label="No"
                                        />
                                    </RadioGroup>
                                </div>
                                <div className="col-12 input-line">
                                    <div id="my-radio-group">
                                        Will the Company own/hold real estate in The Bahamas or have
                                        an interest in a company which owns/holds real estate in The
                                        Bahamas?
                                    </div>
                                    <RadioGroup
                                        row
                                        aria-label="type"
                                        form={form}
                                        name="bahamas_question_2"
                                        onChange={handleChangeQuestion2}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            checked={is_bahamas_question_2_yes === 'Yes'}
                                            control={
                                                <Radio color="secondary" disabled={disabled} />
                                            }
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="No"
                                            checked={is_bahamas_question_2_yes === 'No'}
                                            control={
                                                <Radio color="secondary" disabled={disabled} />
                                            }
                                            label="No"
                                        />
                                    </RadioGroup>
                                </div>
                                <div className="col-12 input-line">
                                    <div id="my-radio-group">
                                        Will the Company carry on a business in The Bahamas?
                                        (Yes/No)
                                    </div>
                                    <RadioGroup
                                        row
                                        aria-label="type"
                                        form={form}
                                        name="bahamas_question_3"
                                        onChange={handleChangeQuestion3}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            checked={is_bahamas_question_3_yes === 'Yes'}
                                            control={
                                                <Radio color="secondary" disabled={disabled} />
                                            }
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="No"
                                            checked={is_bahamas_question_3_yes === 'No'}
                                            control={
                                                <Radio color="secondary" disabled={disabled} />
                                            }
                                            label="No"
                                        />
                                    </RadioGroup>
                                </div>
                                {is_bahamas_question_3_yes === 'Yes' ? (
                                    <div className="col-12 input-line">
                                        <p>
                                            If yes, will the business be regulated by any of the
                                            below?
                                        </p>
                                        <div>
                                            <Controller
                                                name="regulated_by_1"
                                                control={form.control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...form.register('regulated_by_1')}
                                                                value="Yes"
                                                                defaultChecked={
                                                                    form.getValues(
                                                                        'regulated_by_1',
                                                                    )?.[0]
                                                                }
                                                                color="secondary"
                                                                m={0}
                                                                disabled={disabled}
                                                            />
                                                        }
                                                        style={{
                                                            marginRight: 0,
                                                        }}
                                                        label="Central Bank of The Bahamas"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <Controller
                                                name="regulated_by_2"
                                                control={form.control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...form.register('regulated_by_2')}
                                                                value="Yes"
                                                                defaultChecked={
                                                                    form.getValues(
                                                                        'regulated_by_2',
                                                                    )?.[0]
                                                                }
                                                                color="secondary"
                                                                m={0}
                                                                disabled={disabled}
                                                            />
                                                        }
                                                        style={{
                                                            marginRight: 0,
                                                        }}
                                                        label="Insurance Commission of The Bahamas"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <Controller
                                                name="regulated_by_3"
                                                control={form.control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...form.register('regulated_by_3')}
                                                                value="Yes"
                                                                defaultChecked={
                                                                    form.getValues(
                                                                        'regulated_by_3',
                                                                    )?.[0]
                                                                }
                                                                color="secondary"
                                                                m={0}
                                                                disabled={disabled}
                                                            />
                                                        }
                                                        style={{
                                                            marginRight: 0,
                                                        }}
                                                        label="Securities Commission of The Bahamas"
                                                    />
                                                )}
                                            />
                                        </div>
                                        {form.formState.errors?.regulated_by ? (
                                            <div className="text-danger">Can not be empty!</div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                ) : (
                                    ''
                                )}

                                <div className="col-12 input-line">
                                    <div id="my-radio-group">
                                        Will the Company be a subsidiary or affiliate of a licensee
                                        of any the above regulators? (Yes/No)
                                    </div>
                                    <RadioGroup
                                        row
                                        aria-label="type"
                                        form={form}
                                        name="bahamas_question_4"
                                        onChange={handleChangeQuestion4}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            checked={is_bahamas_question_4_yes === 'Yes'}
                                            control={
                                                <Radio color="secondary" disabled={disabled} />
                                            }
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="No"
                                            checked={is_bahamas_question_4_yes === 'No'}
                                            control={
                                                <Radio color="secondary" disabled={disabled} />
                                            }
                                            label="No"
                                        />
                                    </RadioGroup>
                                </div>

                                {is_bahamas_question_4_yes === 'Yes' ? (
                                    <div className="col-12 input-line">
                                        <p>If yes, name of licensee:</p>
                                        <label>Enter Name of License</label>
                                        <InputField
                                            form={form}
                                            name="name_of_license"
                                            placeholder="some name"
                                            hideSuccessHelper
                                            disabled={disabled}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}

                                <div className="col-12 mt-3 input-line">
                                    <div id="my-radio-group">
                                        Will the Company be licensed or regulated outside of The
                                        Bahamas? (Yes/No)
                                    </div>
                                    <RadioGroup
                                        row
                                        aria-label="type"
                                        name="bahamas_question_5"
                                        onChange={handleChangeQuestion5}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            checked={is_bahamas_question_5_yes === 'Yes'}
                                            control={
                                                <Radio color="secondary" disabled={disabled} />
                                            }
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="No"
                                            checked={is_bahamas_question_5_yes === 'No'}
                                            control={
                                                <Radio color="secondary" disabled={disabled} />
                                            }
                                            label="No"
                                        />
                                    </RadioGroup>
                                </div>

                                {is_bahamas_question_5_yes === 'Yes' ? (
                                    <div className="col-12 my-4 input-line">
                                        <p>
                                            If yes: name of foreign authority/regulator and country
                                            where foreign authority/regulator is located
                                        </p>
                                        <div className="row">
                                            <div className="col-6">
                                                <label>
                                                    Enter name of foreign authority/regulator
                                                </label>
                                                <InputField
                                                    form={form}
                                                    name="name_of_foreign_auth"
                                                    placeholder="some name"
                                                    hideSuccessHelper
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label>Country</label>
                                                <SelectField
                                                    form={form}
                                                    name="country_of_foreign_auth"
                                                    id="country-select"
                                                    placeholder="Choose country"
                                                    options={countriesCooperationOptions || []}
                                                    onChange={(value) => {
                                                        if (select_country?.id != value?.id) {
                                                            setSelect_Country(value);
                                                            form.setValue(
                                                                'country_of_foreign_auth',
                                                                value.id,
                                                            );
                                                        }
                                                    }}
                                                    hideHelperText
                                                    disabled={disabled}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        ) : (
                            ''
                        )}

                        {isSVG ? (
                            <div>
                                <div className="col-12 input-line">
                                    <div id="my-radio-group">
                                        Does the {body.isLLC ? 'LLC' : 'BC'} own or operate any
                                        websites?
                                    </div>
                                    <RadioGroup
                                        row
                                        aria-label="type"
                                        name="svg_question_1"
                                        onChange={handleChangeSVGQuestion1}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            checked={is_svg_question_1_yes === 'Yes'}
                                            control={
                                                <Radio color="secondary" disabled={disabled} />
                                            }
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="No"
                                            checked={is_svg_question_1_yes === 'No'}
                                            control={
                                                <Radio color="secondary" disabled={disabled} />
                                            }
                                            label="No"
                                        />
                                    </RadioGroup>
                                </div>

                                {is_svg_question_1_yes === 'Yes' ? (
                                    <div className="col-12 input-line">
                                        <p>if so, What are the website address (i.e. urls)?</p>
                                        <label>Website address</label>
                                        <InputField
                                            form={form}
                                            name="website_url"
                                            placeholder="Your URL"
                                            hideSuccessHelper
                                            disabled={disabled}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}

                                {/* SVG QUESTION 2  */}
                                <div className="col-12 input-line">
                                    <div id="my-radio-group">
                                        Does the {body.isLLC ? 'LLC' : 'BC'} own Intellectual
                                        Property (IP)?
                                    </div>
                                    <RadioGroup
                                        row
                                        aria-label="type"
                                        name="svg_question_2"
                                        onChange={handleChangeSVGQuestion2}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            checked={is_svg_question_2_yes === 'Yes'}
                                            control={
                                                <Radio color="secondary" disabled={disabled} />
                                            }
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="No"
                                            checked={is_svg_question_2_yes === 'No'}
                                            control={
                                                <Radio color="secondary" disabled={disabled} />
                                            }
                                            label="No"
                                        />
                                    </RadioGroup>
                                </div>

                                {is_svg_question_2_yes === 'Yes' ? (
                                    <div className="col-12 input-line">
                                        <p>if so, please provide specifics</p>
                                        <label>Intellectual Property (IP)</label>
                                        <InputField
                                            form={form}
                                            name="svg_website_ip"
                                            placeholder="Your IP"
                                            hideSuccessHelper
                                            disabled={disabled}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        ) : null}

                        {/* logic của st.kitt & nevis */}
                        {body.Company.country_id === 205 && (
                            <div className={'col-12 mb-7'}>
                                <p className={'fw-500'}>
                                    *Note: Section 103 of the legislation addresses the subject of
                                    the company’s requirement to keep proper books & records to
                                    enable the financial position of the company to be determined at
                                    any time and to allow financial statements to be prepared.
                                </p>
                            </div>
                        )}
                    </div>

                    {!hideAction && (
                        <div className={classes.actionsContainer}>
                            <div className="mt-3">
                                <Button
                                    size="large"
                                    variant="contained"
                                    disableElevation
                                    onClick={handleBack}
                                    disabled={isLoading}
                                    className="mr-2"
                                >
                                    Back
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    disabled={isLoading}
                                    className={classes.button}
                                    style={{ color: '#fff' }}
                                    onClick={() => (activeStepRef.current = null)}
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
}

import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import InputField from 'components/form-controls/InputField';
import * as _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { toAbsoluteUrl } from '_metronic/_helpers';
import { login } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';
import { debounce } from 'lodash';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import './ForgotPassword.scss';

const initialValues = {
    username: '',
    password: '',
};

function Login(props) {
    const { intl } = props;
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [isHasError, setIsHasError] = useState(false);
    const [hidePassword, setHidePassword] = useState(true);
    const ggCaptchaEl = useRef(null);

    const LoginSchema = Yup.object().shape({
        username: Yup.string()
            .required('Email is required')
            .email('Wrong email format')
            .trim()
            .min(2, 'Minimum 2 symbols')
            .max(50, 'Maximum 50 symbols'),
        password: Yup.string()
            .required('Password is required')
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols'),
    });

    const form = useForm({
        defaultValues: {
            username: '',
            password: '',
        },

        resolver: yupResolver(LoginSchema),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    useEffect(() => {
        // Google Init App
        window['gapi'].load('auth2', (login) => {
            window.gapi.auth2.init({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                scope: 'profile',
            });
        });
        // Facebook Init App
        window.fbAsyncInit = () => {
            window['FB'].init({
                appId: process.env.REACT_APP_FB_CLIENT_ID,
                cookie: true,
                xfbml: true,
                version: 'v3.1',
            });
        };
        (function (d, s, id) {
            let js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/all.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    }, []);

    // Facebook Login handle
    // const fbLogin = () => {
    //     window['FB'].login(
    //         (response) => {
    //             if (response.authResponse && response.status === 'connected') {
    //                 window['FB'].api(
    //                     '/me',
    //                     {
    //                         fields: 'name, email, first_name, last_name',
    //                     },
    //                     (fbProfile) => {
    //                         const checkingData = {
    //                             social_type: 'Facebook',
    //                             facebook_id: fbProfile.id,
    //                             email: fbProfile.email || null,
    //                             first_name: fbProfile.first_name || null,
    //                             last_name: fbProfile.last_name || null,
    //                             name: fbProfile.name || null,
    //                         };
    //                         social_login(checkingData)
    //                             .then((response) => {
    //                                 let dataResponse = _.get(response, 'data.data');
    //                                 if (dataResponse.access_token && dataResponse.refresh_token) {
    //                                     props.login({
    //                                         access_token: dataResponse.access_token,
    //                                         refresh_token: dataResponse.refresh_token,
    //                                     });
    //                                 } else if (
    //                                     dataResponse.is_user === false &&
    //                                     dataResponse.payload
    //                                 ) {
    //                                     payload = {
    //                                         firstname: dataResponse.payload.first_name,
    //                                         lastname: dataResponse.payload.last_name,
    //                                         email: dataResponse.payload.email || '',
    //                                         facebook_id: dataResponse.payload.facebook_id,
    //                                     };
    //                                     setShow(true);
    //                                 }
    //                             })
    //                             .catch((error) => {
    //                                 console.log(error);
    //                             });
    //                     },
    //                 );
    //             }
    //         },
    //         { scope: 'public_profile, email' },
    //     );
    // };

    // Google login handle
    // const googleLogin = () => {
    //     window['gapi'].load('auth2', function () {
    //         const auth2 = window['gapi'].auth2.getAuthInstance();
    //         auth2
    //             .signIn()
    //             .then((response) => {
    //                 const checkingData = {
    //                     social_type: 'Google',
    //                     google_id: response.Ca,
    //                     id_token: response.xc.id_token,
    //                 };
    //                 social_login(checkingData).then((response) => {
    //                     let dataResponse = _.get(response, 'data.data');
    //                     if (dataResponse.access_token && dataResponse.refresh_token) {
    //                         props.login({
    //                             access_token: dataResponse.access_token,
    //                             refresh_token: dataResponse.refresh_token,
    //                         });
    //                     } else if (dataResponse.is_user === false && dataResponse.payload) {
    //                         payload = {
    //                             firstname: dataResponse.payload.given_name || '',
    //                             lastname: dataResponse.payload.family_name || '',
    //                             email: dataResponse.payload.email || '',
    //                             facebook_id: '',
    //                         };
    //                         setShow(true);
    //                     }
    //                 });
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             });
    //     });
    // };

    const handleSubmit = async (values) => {
        enableLoading();
        try {
            const rememberPath = localStorage.getItem('REMEMBER_PATH');
            const token = await ggCaptchaEl.current.executeRecaptcha();
            const response = await login(values.username?.trim(), values.password, token);
            const responseData = _.get(response, 'data.data');
            if (
                responseData &&
                responseData.is_two_factor_authentication &&
                responseData.username
            ) {
                navigate('/auth/confirm', {
                    state: {
                        two_factor: { ...responseData },
                    },
                });
            } else if (responseData.access_token && responseData.refresh_token) {
                props.login({
                    access_token: responseData.access_token,
                    refresh_token: responseData.refresh_token,
                    access_token_exp: responseData.access_token_exp,
                });
                if (rememberPath) {
                    navigate(rememberPath);
                } else {
                    navigate('/');
                }
            }
        } catch (error) {
            setIsHasError(true);
        }
        disableLoading();
    };

    // const formik = useFormik({
    //     initialValues,
    //     validationSchema: LoginSchema,
    //     onSubmit: (values, { setStatus, setSubmitting }) => {
    //         enableLoading();
    //         ggCaptchaEl.current.executeRecaptcha().then((token) => {
    //             login(values.username, values.password, token)
    //                 .then((res) => {
    //                     const responseData = _.get(res, 'data.data');
    //                     disableLoading();
    //                     if (
    //                         responseData &&
    //                         responseData.is_two_factor_authentication &&
    //                         responseData.username
    //                     ) {
    //                         navigate({
    //                             pathname: '/auth/confirm',
    //                             two_factor: { ...responseData },
    //                         });
    //                     } else if (responseData.access_token && responseData.refresh_token) {
    //                         props.login({
    //                             access_token: responseData.access_token,
    //                             refresh_token: responseData.refresh_token,
    //                             access_token_exp: responseData.access_token_exp,
    //                             // refresh_token_exp: responseData.refresh_token_exp
    //                         });
    //                         navigate('/');
    //                     }
    //                 })
    //                 .catch(() => {
    //                     disableLoading();
    //                     setSubmitting(false);
    //                     setStatus(
    //                         intl.formatMessage({
    //                             id: 'AUTH.VALIDATION.INVALID_LOGIN',
    //                         }),
    //                     );
    //                 });
    //         });
    //     },
    // });

    // const { onChange } = formik.getFieldProps('username');

    // const usernameFieldProps = {
    //     ...formik.getFieldProps('username'),
    //     onChange: (e) => {
    //         onChange(e);

    //         const debounceUsername = debounce(() => {
    //             formik.setFieldValue('username', e.target.value.replace(/\s/g, ''));
    //         }, 500);

    //         debounceUsername(e.target.value);
    //     },
    // };

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="logo">
                <img
                    src={toAbsoluteUrl('/media/logos/logo-new.png')}
                    width={177}
                    height={49}
                    alt="BBCIncorp"
                />
            </div>
            <div>
                <h3 className="title d-inline-block">
                    <FormattedMessage id="AUTH.LOGIN.TITLE" />{' '}
                    <span style={{ color: '#007eff' }}>Portal</span>
                </h3>
            </div>
            <div>
                <p className="description d-inline-block">
                    <FormattedMessage id="AUTH.LOGIN.SUBTITLE" />
                </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >
                <div className="fv-plugins-icon-container">
                    {isHasError && (
                        <div
                            style={{
                                padding: '1.5rem 2rem',
                                backgroundColor: '#ffe2e5',
                                border: '1px solid transparent',
                                borderRadius: '0.42rem',
                                marginBottom: '1rem',
                            }}
                        >
                            <div
                                style={{
                                    color: '#f64e60',
                                    alignSelf: 'center',
                                    flexGrow: 1,
                                    fontWeight: 500,
                                }}
                            >
                                The login detail is incorrect
                            </div>
                        </div>
                    )}
                    <div className="group-form">
                        <label>User name</label>
                        <InputField
                            name="username"
                            form={form}
                            placeholder="Enter your user name"
                            hideHelperText
                            customStyle={{
                                '& .MuiInputBase-input': {
                                    padding: '12px 16px',
                                    border: '1px solid #BDC3CA',
                                    borderRadius: '4px',
                                    color: '#0F172A',
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    fontWeight: '400',
                                    height: '24px',
                                    letterSpacing: '-0.35px',
                                },
                                '& .MuiInputBase-input::-webkit-input-placeholder': {
                                    color: '#94A3B8',
                                },
                                '& .MuiInputBase-input:focus': {
                                    border: '1px solid #BDC3CA !important',
                                },
                                '& .MuiInputBase-input:hover': {
                                    border: '1px solid #BDC3CA !important',
                                },
                                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    border: 'none !important',
                                },
                                '& .MuiInput-underline:active:not(.Mui-focused):before': {
                                    border: 'none !important',
                                },
                                '& .MuiInputBase-root:before': {
                                    borderBottom: 'none !important',
                                },
                                '& .MuiInput-root:after': {
                                    borderBottom: 'none !important',
                                },
                            }}
                            onChange={(e) => {
                                debounce(() => {
                                    form.setValue('username', e.target.value.replace(/\s/g, ''));
                                }, 500)();
                            }}
                        />
                        {form.formState.errors.username && (
                            <p style={{ fontSize: '0.75rem', color: '#f64e60', marginTop: '3px' }}>
                                {form.formState.errors.username.message}
                            </p>
                        )}
                    </div>
                    <div className="group-form">
                        <label>Password</label>
                        <InputField
                            type={hidePassword ? 'password' : 'text'}
                            name="password"
                            form={form}
                            placeholder="Enter your password"
                            hideHelperText
                            customStyle={{
                                '& .MuiInputBase-root': {
                                    padding: '12px 16px',
                                    border: '1px solid #BDC3CA',
                                    borderRadius: '4px',
                                },
                                '& .MuiInputBase-input': {
                                    padding: 0,
                                    color: hidePassword ? '#64748B' : '#0F172A',
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    fontWeight: '400',
                                    height: '24px',
                                    letterSpacing: '-0.35px',
                                },
                                '& .MuiInputBase-input::-webkit-input-placeholder': {
                                    color: '#94A3B8',
                                },
                                '& .MuiInputBase-root:focus': {
                                    border: '1px solid #BDC3CA !important',
                                },
                                '& .MuiInputBase-root:hover': {
                                    border: '1px solid #BDC3CA !important',
                                },
                                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    border: 'none !important',
                                },
                                '& .MuiInput-underline:active:not(.Mui-focused):before': {
                                    border: 'none !important',
                                },
                                '& .MuiInputBase-root:before': {
                                    borderBottom: 'none !important',
                                },
                                '& .MuiInput-root:after': {
                                    borderBottom: 'none !important',
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <button
                                        type="button"
                                        style={{
                                            border: 'none',
                                            padding: 0,
                                            background: 'transparent',
                                            color: '#A3A7AD',
                                        }}
                                        onClick={() => setHidePassword((prevState) => !prevState)}
                                    >
                                        {hidePassword ? (
                                            <RemoveRedEyeOutlinedIcon />
                                        ) : (
                                            <RemoveRedEyeIcon />
                                        )}
                                    </button>
                                ),
                            }}
                        />
                        {form.formState.errors.password && (
                            <p style={{ fontSize: '0.75rem', color: '#f64e60', marginTop: '3px' }}>
                                {form.formState.errors.password.message}
                            </p>
                        )}
                    </div>
                </div>
                <Box className="w-100 text-right">
                    <Link
                        to="/auth/forgot-password"
                        className="link-forgot-password"
                        id="kt_login_forgot"
                    >
                        <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />?
                    </Link>
                </Box>
                <div className="d-flex flex-wrap group-button">
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={loading}
                        className={`btn btn-login w-100`}
                    >
                        <span>Start my business</span>
                        {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>
                </div>
                <div>
                    <GoogleReCaptchaProvider
                        ref={ggCaptchaEl}
                        reCaptchaKey={process.env.REACT_APP_RECAPTCHA}
                        language="en"
                        name="recaptcha-token"
                        scriptProps={{
                            async: false, // optional, default to false,
                            defer: false, // optional, default to false
                            appendTo: 'head', // optional, default to "head", can be "head" or "body",
                            nonce: undefined, // optional, default undefined
                        }}
                    >
                        {' '}
                    </GoogleReCaptchaProvider>
                </div>
            </form>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Login));

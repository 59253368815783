import React from 'react';
import {
    COMPANY_MEMBER_TYPES,
    MEMBER_TYPES,
    getCorpChildName,
    isManagerHidden,
    ACTION_TYPES,
    isLLC,
} from 'app/pages/services/constants';
import { cloneDeep, find, get, isEmpty } from 'lodash';
import {
    VIEW_OFFICERS_SHARE_CAPITAL,
    EDIT_OFFICERS_SHARE_CAPITAL,
    PROPOSE_CHANGE,
    FULL_ACTION_OFFICERS_SHARE_CAPITAL,
    UPLOAD_BOOKEEPING,
} from 'constants/kyc_graph_type';
import { BVI_ID, PANAMA_ID } from 'constants/country';

function removeBranchWithCountry() {}

/**
 * Remove duplicate value from an array.
 * @param {Array} members a list of member.
 * @returns an array of parent id
 */
function getUniqueParentIds(members) {
    const uniqueParentIds = [];
    // loop through array
    members.forEach((member) => {
        if (uniqueParentIds.indexOf(member.parent_id) === -1) {
            uniqueParentIds.push(member.parent_id);
        }
    });
    return uniqueParentIds;
}

function checkChangeRequestMember(member, type) {
    if (type === FULL_ACTION_OFFICERS_SHARE_CAPITAL && member?.graph_id.includes('root'))
        return true;
    // if (!member.company_id) return false;
    let result = false;

    const memberPosition = member.CompanyPositions.find(
        (position) => position.id === member.pos_id,
    );
    if (memberPosition.is_approved === 0) {
        result = true;
    }

    return result;
}

function getMembersHaveNoChangeRequest(members, changeRequests, arrLevelOne) {
    const membersTemp = [];
    const newCorporation = [];
    const changeRequestPositionIds = changeRequests.map(
        (changeRequest) => changeRequest.company_position_id,
    );

    members.forEach((member) => {
        member.CompanyPositions.forEach((memberPosition) => {
            if (member.CompanyMemberType.id === memberPosition.company_member_type_id) {
                if (
                    (member.is_approved && !changeRequestPositionIds.includes(memberPosition.id)) ||
                    changeRequestPositionIds.includes(memberPosition.id)
                ) {
                    membersTemp.push(member);
                }
                if (
                    member.type_member_id == 2 &&
                    changeRequestPositionIds.includes(memberPosition.id)
                ) {
                    newCorporation.push(member);
                }
            }
        });
    });

    if (arrLevelOne && arrLevelOne.length > 0) {
        arrLevelOne.forEach((member) => {
            member.CompanyPositions.forEach((memberPosition) => {
                if (
                    member.type_member_id == 2 &&
                    changeRequestPositionIds.includes(memberPosition.id)
                ) {
                    newCorporation.push(member);
                }
            });
        });
        newCorporation.forEach((member) => {
            getAllCorporationMember(member, members, membersTemp);
        });
    }
    return [...membersTemp];
}

const getAllCorporationMember = (parent, memberList, result) => {
    const memberLeft = [...memberList];
    memberList.forEach((member) => {
        member.CompanyPositions.forEach((memberPosition) => {
            if (memberPosition.corporation_company_member_id === parent.id) {
                result.push(member);
                let index = memberLeft.findIndex((e) => e.id === member.id);
                memberLeft.splice(index, 1);
                if (member.type_member_id === 2) {
                    getAllCorporationMember(member, memberLeft, result);
                }
            }
        });
    });
};

/**
 * Type of action.
 */
// const ACTION_TYPES = {
//     ADD: { id: 1, name: 'ADD', icon: '/media/svg/icons/Order/union.svg' },
//     UPLOAD: {
//         id: 2,
//         name: 'UPLOAD',
//         icon: '/media/svg/icons/Order/upload.svg',
//         desc: 'Update Documents',
//     },
//     DELETE: {
//         id: 2,
//         name: 'DELETE',
//         icon: '/media/svg/icons/Order/delete-node.svg',
//         desc: 'Delete member',
//     },
//     VIEW: {
//         id: 4,
//         name: 'VIEW',
//         icon: '/media/svg/icons/Order/delete-node.svg',
//         desc: 'View',
//     },
//     TRANSFER: {
//         id: 5,
//         name: 'TRANSFER',
//         icon: '/media/svg/icons/Order/delete-node.svg',
//         desc: 'Transfer',
//     },
//     EDIT: {
//         id: 6,
//         name: 'EDIT',
//         icon: '/media/svg/icons/Order/delete-node.svg',
//         desc: 'Edit',
//     },
//     RESIGN: {
//         id: 7,
//         name: 'RESIGN',
//         icon: '/media/svg/icons/Order/delete-node.svg',
//         desc: 'Resign',
//     },
// };

/**
 * Create edge between 2 nodes.
 * documents: https://reactflow.dev/docs/api/edges/
 * @param {Object} data
 * @returns {Object} {
 *  {String} id (required) - an id of edge,
 *  {String} label - a text to show on the line,
 *  {String} source (required) - an id of a start node,
 *  {String} target (required) - an id of an end node,
 *  {String} type - 'default' (bezier), 'straight', 'step'
 *               and 'smoothstep' or a custom one depending on your implementation.
 * }
 */
export function createEdge(data) {
    const { source, target, label, type } = data;
    return {
        id: `e${source}-${target}`,
        label: label || '',
        source: source || '',
        target: target || '',
        type: type || 'step',
    };
}

/**
 * Create a node in a of graph.
 * document: https://reactflow.dev/docs/api/nodes/
 * @param {Object} data the member information to check and view node.
 * @returns a node object on the graph.
 */
export function createNode(data, actions, type, isLLC, otherInfo, changeRequests) {
    const {
        id,
        graph_id,
        type_member_id,
        first_name,
        full_name,
        corporation_name,
        Files,
        company_member_type_id,
        level,
        CompanyShares,
        CompanyInterests,
        CompanyOwnerships,
        is_approved,
        is_resigned,
    } = data;

    const { DisabledFileTemplate, company_country_id, task_id } = otherInfo;
    const isChangeName =
        changeRequests?.filter(
            (item) =>
                [2].includes(item?.change_request_status_id) && item?.company_member_id === data.id,
        )?.[0]?.ChangeRequestItems?.[0]?.field_name === 'full_name';
    const updatedName = changeRequests?.filter(
        (item) =>
            [2].includes(item?.change_request_status_id) && item?.company_member_id === data.id,
    )?.[0]?.ChangeRequestItems?.[0]?.value;
    const companyShare = !isEmpty(CompanyShares)
        ? CompanyShares.reduce(function (acc, cur) {
              if (cur && cur.is_approved && cur.is_valid && !cur.corporation_company_member_id)
                  return acc + cur.value;
              else return acc;
          }, 0)
        : 0;
    const isHighlightMember =
        changeRequests
            ?.filter((item) => item?.change_request_status_id === 1)
            ?.some((item) => item?.company_member_id === data.id) ||
        changeRequests
            ?.filter(
                (item) =>
                    item?.change_request_status_id === 2 &&
                    item?.ChangeRequestItems?.some((crItem) => crItem.is_fee),
            )
            ?.some((item) => item?.company_member_id === data.id);
    const companyOwnership = !isEmpty(CompanyOwnerships)
        ? CompanyOwnerships.reduce(function (acc, cur) {
              if (cur && cur.is_approved && cur.is_valid && !cur.corporation_company_member_id)
                  return acc + cur.value;
              else return acc;
          }, 0)
        : 0;
    const companyInterest = !isEmpty(CompanyInterests)
        ? CompanyInterests.reduce(function (acc, cur) {
              if (cur && cur.is_approved && cur.is_valid && !cur.corporation_company_member_id)
                  return acc + cur.value;
              else return acc;
          }, 0)
        : 0;
    // check type member to get icon.
    const memberIcon =
        type_member_id === MEMBER_TYPES.INDIVIDUAL.id
            ? MEMBER_TYPES.INDIVIDUAL.icon
            : MEMBER_TYPES.CORPORATION.icon;
    const hasShowSelectFullName = [BVI_ID, PANAMA_ID].includes(company_country_id);

    // check type member to get title.
    const title =
        type_member_id === MEMBER_TYPES.INDIVIDUAL.id
            ? `${full_name || ''}` || `${first_name || ''}`
            : `${corporation_name || ''}`;
    const typeName =
        type_member_id === MEMBER_TYPES.INDIVIDUAL.id
            ? MEMBER_TYPES.INDIVIDUAL.name
            : MEMBER_TYPES.CORPORATION.name;
    const fileTemps = Array.isArray(Files)
        ? type === UPLOAD_BOOKEEPING
            ? Files.filter((file) => file.task_id === task_id)
            : Files
        : Object.values(Files || {});

    const isDirectorNode = company_member_type_id === COMPANY_MEMBER_TYPES.DIRECTOR.id;
    const isShareholderNode = company_member_type_id === COMPANY_MEMBER_TYPES.SHAREHOLDER.id;
    const isUBONode = company_member_type_id === COMPANY_MEMBER_TYPES.UBO.id;

    // return node object.
    const getLabelNode = (type) => {
        switch (type) {
            case VIEW_OFFICERS_SHARE_CAPITAL:
                return (
                    <div style={{ padding: '10px' }}>
                        <div className="kyc-node d-flex align-items-center justify-content-start mb-2">
                            <img
                                src={memberIcon}
                                className="img-fluid mr-2"
                                width={25}
                                height={25}
                                alt={`node-${id}`}
                            />
                            <div className="text-left">
                                <p className="m-0 kyc-text-one-line" style={{ width: 110 }}>
                                    {title}
                                </p>
                                <p
                                    className="m-0 kyc-text-one-line"
                                    style={{ color: '#677294', width: 110 }}
                                >
                                    {typeName}
                                </p>
                            </div>
                        </div>
                        <div className="kyc-node-handle d-flex align-items-center justify-content-center">
                            <div>
                                {actions?.map(
                                    (action, index) =>
                                        action.name === ACTION_TYPES.VIEW.name && (
                                            <button
                                                key={index}
                                                className="btn py-0 p-2 mx-1 bg-light rounded-0"
                                                style={{ width: 80 }}
                                                onClick={() =>
                                                    action.handler({
                                                        ...data,
                                                        type: ACTION_TYPES.VIEW.name,
                                                        action: ACTION_TYPES.VIEW.name,
                                                    })
                                                }
                                            >
                                                {action.name === ACTION_TYPES.VIEW.name && (
                                                    <div className="btn-text">{action.desc}</div>
                                                )}
                                            </button>
                                        ),
                                )}
                            </div>
                        </div>
                    </div>
                );
            case EDIT_OFFICERS_SHARE_CAPITAL:
                return (
                    <div
                        style={{
                            padding: '10px',
                            background:
                                checkChangeRequestMember(data) || isHighlightMember
                                    ? '#a8d1ef52'
                                    : 'white',
                        }}
                    >
                        <div className="kyc-node d-flex align-items-center justify-content-start">
                            <img
                                src={memberIcon}
                                className="img-fluid mr-2"
                                width={25}
                                height={25}
                                alt={`node-${id}`}
                            />
                            <div className="text-left">
                                <p className="m-0 kyc-text-one-line" style={{ width: 110 }}>
                                    {title.length > 7 ? `${title.slice(0, 7)}...` : title}
                                </p>
                                <p
                                    className="m-0 kyc-text-one-line"
                                    style={{ color: '#677294', width: 110 }}
                                >
                                    {typeName}
                                </p>
                            </div>
                            <p
                                style={{
                                    color: '#27AE60',
                                    fontSize: '10px',
                                    whiteSpace: 'nowrap',
                                    marginLeft: '-35px',
                                }}
                            >
                                {fileTemps.length > 0 ? 'Uploaded' : ''}
                            </p>
                        </div>

                        {checkChangeRequestMember(data) === false && is_resigned ? (
                            <span className="text-danger m-2">Resigned</span>
                        ) : (
                            <div className="kyc-node-handle">
                                <div className="d-flex justify-content-center align-items-center mb-1">
                                    <p
                                        className="m-0 mr-2 btn-text"
                                        style={{ width: 60, whiteSpace: 'nowrap' }}
                                    >
                                        {isShareholderNode &&
                                            level === 1 &&
                                            !isLLC &&
                                            `${companyShare} Shares`}
                                        {isShareholderNode &&
                                            level === 1 &&
                                            isLLC &&
                                            `${companyOwnership}%`}
                                        {isUBONode && level === 1 && `${companyInterest}%`}
                                    </p>
                                </div>
                                <div className="d-flex justify-content-center">
                                    {(isShareholderNode || isUBONode || isDirectorNode) &&
                                        actions?.map(
                                            (action, index) =>
                                                action.name === ACTION_TYPES.EDIT.name && (
                                                    <button
                                                        key={index}
                                                        className="btn py-0 p-1 mx-1 bg-light rounded-0"
                                                        style={{ width: 60 }}
                                                        disabled={
                                                            is_approved &&
                                                            (get(
                                                                DisabledFileTemplate,
                                                                `${company_country_id}.${action.id}`,
                                                                [],
                                                            ).includes(company_member_type_id) ||
                                                                level !== 1)
                                                        }
                                                        onClick={() =>
                                                            action.handler({
                                                                ...data,
                                                                type: ACTION_TYPES.EDIT.name,
                                                                action: ACTION_TYPES.EDIT.name,
                                                            })
                                                        }
                                                    >
                                                        {action.name === ACTION_TYPES.EDIT.name && (
                                                            <div className="btn-text">Edit</div>
                                                        )}
                                                    </button>
                                                ),
                                        )}
                                    {(isShareholderNode || isUBONode || isDirectorNode) &&
                                        level !== 1 &&
                                        actions?.map(
                                            (action, index) =>
                                                action.name === ACTION_TYPES.RESIGN.name &&
                                                company_member_type_id !==
                                                    COMPANY_MEMBER_TYPES.CONTACT_PERSON.id &&
                                                (level === 1 || (level !== 1 && !is_approved)) && (
                                                    <button
                                                        key={index}
                                                        className="btn py-0 p-1 mx-1 bg-light rounded-0"
                                                        style={{ width: 60 }}
                                                        disabled={
                                                            is_approved &&
                                                            get(
                                                                DisabledFileTemplate,
                                                                `${company_country_id}.${action.id}`,
                                                                [],
                                                            ).includes(company_member_type_id)
                                                        }
                                                        onClick={() =>
                                                            action.handler({
                                                                ...data,
                                                                type: ACTION_TYPES.RESIGN.name,
                                                                action: ACTION_TYPES.RESIGN.name,
                                                            })
                                                        }
                                                    >
                                                        {action.name ===
                                                            ACTION_TYPES.RESIGN.name && (
                                                            <div className="btn-text">
                                                                {action.desc}
                                                            </div>
                                                        )}
                                                    </button>
                                                ),
                                        )}
                                </div>
                            </div>
                        )}
                    </div>
                );
            case FULL_ACTION_OFFICERS_SHARE_CAPITAL:
                return (
                    <div
                        style={{
                            padding: '10px',
                            background:
                                checkChangeRequestMember(data) || isHighlightMember
                                    ? '#a8d1ef52'
                                    : 'white',
                        }}
                    >
                        <div className="kyc-node d-flex align-items-center justify-content-start">
                            <img
                                src={memberIcon}
                                className="img-fluid mr-2"
                                width={25}
                                height={25}
                                alt={`node-${id}`}
                            />
                            <div className="text-left">
                                <p className="m-0 kyc-text-one-line" style={{ width: 110 }}>
                                    {isChangeName ? updatedName : title}
                                </p>
                                <p
                                    className="m-0 kyc-text-one-line"
                                    style={{ color: '#677294', width: 110 }}
                                >
                                    {typeName}
                                </p>
                            </div>
                        </div>
                        {checkChangeRequestMember(data) === false && is_resigned ? (
                            <span className="text-danger m-2">Resigned</span>
                        ) : (
                            <div className="kyc-node-handle">
                                <div className="d-flex justify-content-center align-items-center mb-1">
                                    {(isShareholderNode || isUBONode) && level === 1 ? (
                                        <>
                                            <p
                                                className="m-0 mr-2 btn-text"
                                                style={{ width: 60, whiteSpace: 'nowrap' }}
                                            >
                                                {isShareholderNode &&
                                                    !isLLC &&
                                                    `${companyShare} Shares`}
                                                {isShareholderNode &&
                                                    isLLC &&
                                                    `${companyOwnership}%`}
                                                {isUBONode && `${companyInterest}%`}
                                            </p>
                                            {actions?.map(
                                                (action, index) =>
                                                    action.name === ACTION_TYPES.TRANSFER.name && (
                                                        <button
                                                            key={index}
                                                            className="btn py-0 p-1 bg-light rounded-0"
                                                            style={{ width: 60 }}
                                                            disabled={
                                                                get(
                                                                    DisabledFileTemplate,
                                                                    `${company_country_id}.${action.id}`,
                                                                    [],
                                                                ).includes(
                                                                    company_member_type_id,
                                                                ) || !is_approved
                                                            }
                                                            onClick={() =>
                                                                action.handler({
                                                                    ...data,
                                                                    type: ACTION_TYPES.TRANSFER
                                                                        .name,
                                                                    action: ACTION_TYPES.TRANSFER
                                                                        .name,
                                                                })
                                                            }
                                                        >
                                                            {action.name ===
                                                                ACTION_TYPES.TRANSFER.name && (
                                                                <div className="btn-text">
                                                                    {action.desc}
                                                                </div>
                                                            )}
                                                        </button>
                                                    ),
                                            )}
                                        </>
                                    ) : (
                                        <div style={{ height: level === 1 ? 25 : 0 }}></div>
                                    )}
                                </div>
                                <div className="d-flex justify-content-center">
                                    {actions?.map(
                                        (action, index) =>
                                            action.name === ACTION_TYPES.EDIT.name &&
                                            company_member_type_id !==
                                                COMPANY_MEMBER_TYPES.CONTACT_PERSON.id &&
                                            (!is_approved ||
                                                type_member_id === MEMBER_TYPES.INDIVIDUAL.id) && (
                                                <button
                                                    key={index}
                                                    className="btn py-0 p-1 mx-1 bg-light rounded-0"
                                                    style={{ width: 60 }}
                                                    disabled={
                                                        is_approved &&
                                                        (get(
                                                            DisabledFileTemplate,
                                                            `${company_country_id}.${action.id}`,
                                                            [],
                                                        ).includes(company_member_type_id) ||
                                                            level !== 1)
                                                    }
                                                    onClick={() =>
                                                        action.handler({
                                                            ...data,
                                                            type: ACTION_TYPES.EDIT.name,
                                                            action: ACTION_TYPES.EDIT.name,
                                                        })
                                                    }
                                                >
                                                    {action.name === ACTION_TYPES.EDIT.name && (
                                                        <div className="btn-text">
                                                            {action.desc}
                                                        </div>
                                                    )}
                                                </button>
                                            ),
                                    )}
                                    {actions?.map(
                                        (action, index) =>
                                            action.name === ACTION_TYPES.RESIGN.name &&
                                            company_member_type_id !==
                                                COMPANY_MEMBER_TYPES.CONTACT_PERSON.id &&
                                            (level === 1 || (level !== 1 && !is_approved)) && (
                                                <button
                                                    key={index}
                                                    className="btn py-0 p-1 mx-1 bg-light rounded-0"
                                                    style={{ width: 60 }}
                                                    disabled={
                                                        !is_approved ||
                                                        get(
                                                            DisabledFileTemplate,
                                                            `${company_country_id}.${action.id}`,
                                                            [],
                                                        ).includes(company_member_type_id)
                                                    }
                                                    onClick={() =>
                                                        action.handler({
                                                            ...data,
                                                            type: ACTION_TYPES.RESIGN.name,
                                                            action: ACTION_TYPES.RESIGN.name,
                                                        })
                                                    }
                                                >
                                                    {action.name === ACTION_TYPES.RESIGN.name && (
                                                        <div className="btn-text">
                                                            {action.desc}
                                                        </div>
                                                    )}
                                                </button>
                                            ),
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                );
            case UPLOAD_BOOKEEPING:
                return (
                    <div
                        style={{
                            padding: '10px',
                            background: fileTemps.length > 0 ? '#a8d1ef52' : '#fff',
                        }}
                    >
                        <div className="kyc-node d-flex align-items-center justify-content-start mb-2">
                            <img
                                src={memberIcon}
                                className="img-fluid mr-2"
                                width={25}
                                height={25}
                                alt={`node-${id}`}
                            />
                            <div className="text-left">
                                <p className="m-0 kyc-text-one-line" style={{ width: 110 }}>
                                    {title}
                                </p>
                                <p
                                    className="m-0 kyc-text-one-line"
                                    style={{ color: '#677294', width: 110 }}
                                >
                                    {typeName}
                                </p>
                            </div>
                        </div>
                        <div className="kyc-node-handle d-flex align-items-center justify-content-between">
                            <div className="mr-2">
                                <p className="m-0 btn-text" style={{ color: '#007EFF' }}>
                                    {fileTemps.length > 0 ? 'Uploaded' : ''}
                                </p>
                            </div>
                            <div>
                                {actions?.map(
                                    (action, index) =>
                                        action.name === ACTION_TYPES.UPLOAD.name && (
                                            <button
                                                key={index}
                                                className="btn py-0 px-1 mx-1 bg-light rounded-0"
                                                style={{ width: 80 }}
                                                onClick={() =>
                                                    action.handler({
                                                        ...data,
                                                        type: ACTION_TYPES.UPLOAD.name,
                                                        action: ACTION_TYPES.UPLOAD.name,
                                                    })
                                                }
                                                disabled={level !== 1 || type_member_id !== 1}
                                            >
                                                <img src={action.icon} alt="icon" />
                                                {action.name === ACTION_TYPES.UPLOAD.name && (
                                                    <div className="btn-text">{action.desc}</div>
                                                )}
                                            </button>
                                        ),
                                )}
                            </div>
                        </div>
                    </div>
                );
            default:
                return (
                    <div style={{ padding: '10px' }}>
                        <div className="kyc-node d-flex align-items-center justify-content-start mb-2">
                            <img
                                src={memberIcon}
                                className="img-fluid mr-2"
                                width={25}
                                height={25}
                                alt={`node-${id}`}
                            />
                            <div className="text-left">
                                <p className="m-0 kyc-text-one-line" style={{ width: 110 }}>
                                    {title}
                                </p>
                                <p
                                    className="m-0 kyc-text-one-line"
                                    style={{ color: '#677294', width: 110 }}
                                >
                                    {typeName}
                                </p>
                            </div>
                        </div>
                        <div className="kyc-node-handle d-flex align-items-center justify-content-between">
                            <div className="mr-2">
                                <p className="m-0 btn-text" style={{ color: '#27AE60' }}>
                                    {fileTemps.length > 0 ? 'Uploaded' : ''}
                                </p>
                                {actions?.map(
                                    (action, index) =>
                                        action.name === ACTION_TYPES.DELETE.name && (
                                            <button
                                                key={index}
                                                className="btn py-0 px-1 mx-1 bg-light rounded-0"
                                                onClick={
                                                    action.id === ACTION_TYPES.ADD.id
                                                        ? () =>
                                                              action.handler({
                                                                  company_member_type_id:
                                                                      data.company_member_type_id,
                                                                  parent_id: data.parent_id,
                                                                  type_member_id:
                                                                      MEMBER_TYPES.INDIVIDUAL.id,
                                                              })
                                                        : () => action.handler(data)
                                                }
                                            >
                                                <img src={action.icon} alt="icon" />
                                            </button>
                                        ),
                                )}
                            </div>
                            <div>
                                {actions?.map(
                                    (action, index) =>
                                        action.name === ACTION_TYPES.UPLOAD.name && (
                                            <button
                                                key={index}
                                                className="btn py-0 px-1 mx-1 bg-light rounded-0"
                                                style={{ width: 80 }}
                                                onClick={
                                                    action.id === ACTION_TYPES.ADD.id
                                                        ? () =>
                                                              action.handler({
                                                                  company_member_type_id:
                                                                      data.company_member_type_id,
                                                                  parent_id: data.parent_id,
                                                                  type_member_id:
                                                                      MEMBER_TYPES.INDIVIDUAL.id,
                                                              })
                                                        : () => action.handler(data)
                                                }
                                            >
                                                <img src={action.icon} alt="icon" />
                                                {action.name === ACTION_TYPES.UPLOAD.name && (
                                                    <div className="btn-text">{action.desc}</div>
                                                )}
                                            </button>
                                        ),
                                )}
                            </div>
                        </div>
                    </div>
                );
        }
    };

    return {
        id: graph_id,
        is_member_node: true,
        type: 'default',
        style: {
            width: 180,
            overflow: 'hidden',
        },
        data: {
            value: data,
            label: getLabelNode(type),
        },
        position: { x: 0, y: 0 },
    };
}

/**
 * Create a corporation child node (Director | Shareholder | UBO).
 * @param {String} parentId the parent node id.
 * @param {number} entity_type_id one of ENTITY_TYPES.
 * @param {Object} companyMemberType one of COMPANY_MEMBER_TYPES.
 * @param {string|number} company_id the id of root
 * @returns a node with a edge which connecting to parent node.
 */
export function createCorporationChildNode(
    member,
    companyMemberType,
    entity_type_id,
    company_country_id,
    level,
    actions,
    type,
    otherInfo = {},
    disableAction,
) {
    const { DisabledFileTemplate } = otherInfo;

    const getLabelCorporationNode = (type) => {
        switch (type) {
            case VIEW_OFFICERS_SHARE_CAPITAL:
                return (
                    <div
                        style={{ padding: '10px' }}
                        className="kyc-node d-flex align-items-center justify-content-around flex-column"
                    >
                        <p className="mb-0">
                            {getCorpChildName(
                                entity_type_id,
                                company_country_id,
                                companyMemberType,
                            )}
                            &nbsp;
                        </p>
                    </div>
                );
            case EDIT_OFFICERS_SHARE_CAPITAL:
                return (
                    <div
                        style={{ padding: '10px' }}
                        className="kyc-node d-flex align-items-center justify-content-around flex-column"
                    >
                        <p className="mb-0">
                            {getCorpChildName(
                                entity_type_id,
                                company_country_id,
                                companyMemberType,
                            )}
                            &nbsp;
                        </p>
                        {checkChangeRequestMember(member, FULL_ACTION_OFFICERS_SHARE_CAPITAL) && // change request corporation mới được add member
                            companyMemberType.id !== COMPANY_MEMBER_TYPES.CONTACT_PERSON.id &&
                            level !== 1 &&
                            actions?.map((action, index) => (
                                <button
                                    key={index}
                                    className="btn py-0 px-1 mx-1 bg-light rounded-0"
                                    disabled={get(
                                        DisabledFileTemplate,
                                        `${company_country_id}.${ACTION_TYPES.ADD.id}`,
                                        [],
                                    ).includes(companyMemberType.id)}
                                    onClick={() =>
                                        action.handler({
                                            ...member,
                                            company_member_type_id: companyMemberType.id,
                                            parent_id: member.id,
                                            company_id: member.company_id,
                                            corporation_company_member_id: member.id,
                                            type: ACTION_TYPES.ADD.name,
                                        })
                                    }
                                >
                                    <img src={action.icon} alt="" />
                                </button>
                            ))}
                    </div>
                );
            case FULL_ACTION_OFFICERS_SHARE_CAPITAL:
                return (
                    <div
                        style={{ padding: '10px' }}
                        className="kyc-node d-flex align-items-center justify-content-around flex-column"
                    >
                        <p className="mb-0">
                            {getCorpChildName(
                                entity_type_id,
                                company_country_id,
                                companyMemberType,
                            )}
                            &nbsp;
                        </p>
                        {checkChangeRequestMember(member, FULL_ACTION_OFFICERS_SHARE_CAPITAL) &&
                            companyMemberType.id !== COMPANY_MEMBER_TYPES.CONTACT_PERSON.id && ( // change request corporation mới được add member
                                <div>
                                    {actions?.map((action, index) => (
                                        <button
                                            key={index}
                                            className="btn py-0 px-1 mx-1 bg-light rounded-0"
                                            disabled={
                                                get(
                                                    DisabledFileTemplate,
                                                    `${company_country_id}.${ACTION_TYPES.ADD.id}`,
                                                    [],
                                                ).includes(companyMemberType.id) || disableAction
                                            }
                                            onClick={() =>
                                                action.handler({
                                                    company_member_type_id: companyMemberType.id,
                                                    parent_id: member.id,
                                                    type_member_id: MEMBER_TYPES.INDIVIDUAL.id,
                                                    company_id: member.company_id,
                                                    corporation_company_member_id: member.id,
                                                    type: ACTION_TYPES.ADD.name,
                                                })
                                            }
                                        >
                                            <img src={action.icon} alt="" />
                                        </button>
                                    ))}
                                </div>
                            )}
                    </div>
                );
            case UPLOAD_BOOKEEPING:
                return (
                    <div className="kyc-node d-flex align-items-center justify-content-around flex-column p-3">
                        <p className="mb-0">
                            {getCorpChildName(
                                entity_type_id,
                                company_country_id,
                                companyMemberType,
                            )}
                            &nbsp;
                        </p>
                    </div>
                );
            default:
                return (
                    <div className="kyc-node d-flex align-items-center justify-content-around flex-column p-3">
                        <p className="mb-0">
                            {getCorpChildName(
                                entity_type_id,
                                company_country_id,
                                companyMemberType,
                            )}
                            &nbsp;
                        </p>
                        {member?.company_id && (
                            <div>
                                {actions?.map((action, index) => (
                                    <button
                                        key={index}
                                        className="btn py-0 px-1 mx-1 bg-light rounded-0"
                                        onClick={() =>
                                            action.handler({
                                                company_member_type_id: companyMemberType.id,
                                                parent_id: member.id,
                                                type_member_id: MEMBER_TYPES.INDIVIDUAL.id,
                                                company_id: member.company_id,
                                                corporation_company_member_id: member.id,
                                            })
                                        }
                                    >
                                        <img src={action.icon} alt="" />
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                );
        }
    };

    const results = [];
    const id = `${member.graph_id}-cmt${companyMemberType.id}`;
    const node = {
        id: id,
        type: 'default',
        style: {
            width: 180,
            overflow: 'hidden',
        },
        data: {
            label: getLabelCorporationNode(type),
        },
        position: { x: 0, y: 0 },
    };
    const edge = createEdge({ source: member.graph_id, target: id });
    results.push(node);
    results.push(edge);
    return results;
}

/**
 * Create root node. The type of node is INPUT.
 * document: https://reactflow.dev/docs/api/nodes/
 * @param {Object} data the company information to check and view node.
 * @returns {Object} a top fixed node of graph.
 */
export function createRootNode(data) {
    const { id, name } = data;
    return {
        id: id,
        type: 'input',
        style: {
            width: 180,
        },
        data: {
            value: data,
            label: (
                <div style={{ padding: '10px' }} className="kyc-node d-flex align-items-center">
                    <img
                        src={MEMBER_TYPES.CORPORATION.icon}
                        className="img-fluid mr-2"
                        width={25}
                        height={25}
                        alt="root"
                    />
                    <p className="mb-0 kyc-text-one-line" style={{ width: 110 }}>
                        {name}
                    </p>
                </div>
            ),
        },
        position: { x: 0, y: 0 },
    };
}

/**
 * Recursive of depth first search to find children node and config it data.
 * @param {CompanyMember} currItem the current member to add and it children to run next recursive.
 * @param {number} level current level of tree.
 * @returns a flat member list.
 */
const recursiveNodes = (currItem, level) => {
    let results = [];

    currItem.children.forEach((child) => {
        results.push({
            ...child,
            level: level,
            graph_id: `${currItem.graph_id}-${child.id}-${child.pos_id}`,
            parent_id: `${currItem.graph_id}`,
        });
        if (child.type_member_id !== MEMBER_TYPES.INDIVIDUAL.id) {
            results = [
                ...results,
                ...recursiveNodes(
                    { ...child, graph_id: `${currItem.graph_id}-${child.id}-${child.pos_id}` },
                    level + 1,
                ),
            ];
        }
    });

    return results;
};

/**
 * Config member list match with react-flow format.
 * @param {List} array member list
 * @returns new list of members.
 */
function refactorInputArray(array) {
    let results = [];
    let individuals = [];
    let corporations = [];
    if (Array.isArray(array)) {
        const temps = JSON.parse(JSON.stringify(array));
        individuals = temps.filter((temp) => temp.type_member_id === 1);
        corporations = temps
            .filter((temp) => temp.type_member_id === 2)
            .map((temp) => ({ ...temp, children: [] }));
    }

    // add all individual to corporations children.
    // If member level = 0 => add to results.
    individuals.forEach((ind) => {
        ind.CompanyPositions.forEach((pos) => {
            const filteredPos = { ...pos, pos_id: pos.id };
            delete filteredPos.id;
            if (![4, 5, 6].includes(pos.company_member_type_id)) {
                if (pos.corporation_company_member_id) {
                    const corp = corporations.find(
                        (item) => item.id === pos.corporation_company_member_id,
                    );
                    if (corp) {
                        corp.children.push({
                            ...ind,
                            ...filteredPos,
                            graph_id: `${ind.id}-${filteredPos.pos_id}`,
                        });
                    }
                } else {
                    results.push({
                        ...ind,
                        ...filteredPos,
                        level: 1,
                        graph_id: `${ind.id}-${filteredPos.pos_id}`,
                        parent_id: `${pos.corporation_company_member_id}-${pos.company_member_type_id}`,
                    });
                }
            }
        });
    });

    // add all corporation to another corporations children if current corp have parent corp.
    // If member level = 0 => add to results.

    const corpTemps = [...corporations];
    corpTemps.forEach((corpTemp, index) => {
        corpTemp.CompanyPositions.forEach((pos) => {
            const filteredPos = { ...pos, pos_id: pos.id };
            delete filteredPos.id;
            if ([4, 5, 6].includes(pos.company_member_type_id)) return;
            if (pos.corporation_company_member_id) {
                const corp = corporations.find(
                    (item) => item.id === pos.corporation_company_member_id,
                );

                if (corp && corp.id !== corpTemp.id) {
                    corp.children.push({
                        ...corpTemp,
                        ...filteredPos,
                        graph_id: `${corpTemp.id}-${filteredPos.pos_id}`,
                    });
                }
            } else {
                corporations[index] = {
                    ...corpTemp,
                    ...filteredPos,
                    level: 1,
                    graph_id: `${corpTemp.id}-${filteredPos.pos_id}`,
                    parent_id: `${pos.corporation_company_member_id}-${pos.company_member_type_id}`,
                };
                results.push({ ...corporations[index] });
            }
        });
    });

    // filter all corp level 1 which only connect to the root.
    // Use Depth First Search algorithm.
    // Start at level 1 of the tree because level 1 is a top position of member list.
    // root node is created by company info (not member info) => cannot use root to search.
    const corpLevelOnes = results.filter(
        (item) =>
            item.CompanyPositions.some((pos) => !pos.corporation_company_member_id) &&
            item.type_member_id === 2,
    );
    corpLevelOnes.forEach((corpTemp) => {
        // concat all corp only connect to the root and all their children with another member at level 1.
        results = [...results, ...recursiveNodes(corpTemp, 2)];
    });

    // return a list match with react-flow format.
    return results;
}

/**
 * Create graph
 * @param {CompanyInfo} data
 * @param {function} openDetailModal
 * @param {function} funcDeleteNode
 * @param {Object} otherInfo another information for upgrade.
 * @returns
 */
export function createGraphElements(data, externalProps) {
    const { openDetailModal, funcDeleteNode, otherInfo, type } = externalProps;
    const changeRequests = data?.ChangeRequests;

    const is_LLC = isLLC(data.entity_type_id, data.country_id);
    if (!data || !data.id) {
        return [];
    }

    // Remove Branch with country_id + entity_type_id
    let COMPANY_MEMBER_TYPES_FILTER = { ...COMPANY_MEMBER_TYPES };
    if (data.country_id === 237 && data.entity_type_id === 7) {
        delete COMPANY_MEMBER_TYPES_FILTER.DIRECTOR;
    }

    const results = [];

    /* filter khác 4,5,6 là các chức danh của 1 director */
    let members = refactorInputArray(data.CompanyMembers.filter((el) => el.is_deleted === 0))
        .filter((member) => ![4, 5, 6].includes(member.company_member_type_id))
        .filter((member) => member?.CompanyMemberType?.id !== 8);

    if (members && !isEmpty(members)) {
        // Check has contact person
        const isNotContactPerson = members.some((member) => {
            return member?.CompanyPositions?.find(
                (position) =>
                    position?.company_member_type_id === 7 && !position?.is_contact_person,
            );
        });

        if (isNotContactPerson) {
            delete COMPANY_MEMBER_TYPES_FILTER.CONTACT_PERSON;
            members = members?.filter(
                (member) =>
                    (member?.company_member_type_id || member?.CompanyMemberType?.id) !==
                    COMPANY_MEMBER_TYPES.CONTACT_PERSON.id,
            );
        }
    } else {
        delete COMPANY_MEMBER_TYPES_FILTER.CONTACT_PERSON;
    }

    /* -----------------------------------------------------------
     * ---------- Root nodes ---------------
     */
    // filter data for root node to graph.
    const rootId = `root-${data?.id || 'rootId'}`;
    const rootData = {
        id: rootId,
        name: data?.name || '',
    };
    // add root node to graph.
    const rootNode = createRootNode(rootData);
    results.push(rootNode);
    const disableAction = members.length === 0;

    // add company member node of root.
    Object.values(COMPANY_MEMBER_TYPES_FILTER).forEach((companyMemberType) => {
        if (
            (otherInfo?.is_has_director &&
                companyMemberType.id === COMPANY_MEMBER_TYPES_FILTER.DIRECTOR?.id) ||
            (otherInfo?.is_has_shareholder &&
                companyMemberType.id === COMPANY_MEMBER_TYPES_FILTER.SHAREHOLDER?.id) ||
            (isManagerHidden(data) &&
                companyMemberType.id === COMPANY_MEMBER_TYPES_FILTER.DIRECTOR?.id)
        ) {
            return;
        } else {
            if (
                members.find(
                    (member) =>
                        member?.is_nominee &&
                        member?.is_approved &&
                        member?.CompanyPositions?.find(
                            (position) => position?.company_member_type_id === companyMemberType.id,
                        ),
                ) ||
                members.find(
                    (member) =>
                        member?.is_nominee &&
                        member?.is_approved &&
                        member?.CompanyPositions?.find(
                            (position) => position?.company_member_type_id === companyMemberType.id,
                        ),
                )
            ) {
                return;
            } else {
                if (type === EDIT_OFFICERS_SHARE_CAPITAL) {
                    const companyMemberNode = createCorporationChildNode(
                        { graph_id: rootId, company_id: data.company_id },
                        companyMemberType,
                        data.entity_type_id,
                        data.country_id,
                        1,
                        [
                            {
                                ...ACTION_TYPES.ADD,
                                handler: (params) => {
                                    openDetailModal(params);
                                },
                            },
                        ],
                        type,
                        otherInfo,
                        disableAction,
                    );
                    results.push(companyMemberNode[0]);
                    results.push(companyMemberNode[1]);
                } else {
                    const companyMemberNode = createCorporationChildNode(
                        { graph_id: rootId, company_id: data.company_id },
                        companyMemberType,
                        data.entity_type_id,
                        data.country_id,
                        1,
                        [
                            {
                                ...ACTION_TYPES.ADD,
                                handler: (params) => {
                                    openDetailModal(params);
                                },
                            },
                        ],
                        type,
                        otherInfo,
                        disableAction,
                    );
                    results.push(companyMemberNode[0]);
                    results.push(companyMemberNode[1]);
                }
            }
        }
    });

    // Actions
    const CorporationChildSAddActions =
        (otherInfo?.is_banking_flow && !otherInfo?.is_outside_company) ||
        type === VIEW_OFFICERS_SHARE_CAPITAL
            ? []
            : [
                  {
                      ...ACTION_TYPES.ADD,
                      handler: (params) => {
                          openDetailModal(params);
                      },
                  },
              ];

    /* -----------------------------------------------------------
     * ---------- Level 1 nodes -------------
     */
    // all level 1 have parent_id = null.
    // get array data of level 1 node.
    delete COMPANY_MEMBER_TYPES_FILTER.CONTACT_PERSON;
    if (data.country_id === 237 && data.entity_type_id === 7) {
        // make DIRECTOR to top object
        COMPANY_MEMBER_TYPES_FILTER = Object.assign(
            { DIRECTOR: COMPANY_MEMBER_TYPES.DIRECTOR },
            COMPANY_MEMBER_TYPES_FILTER,
        );
    }
    let arrLevelOne = members.filter((el) => !el.corporation_company_member_id);

    const actionsLevelOne = (type, countSameType) => {
        switch (type) {
            case VIEW_OFFICERS_SHARE_CAPITAL:
                return [
                    {
                        ...ACTION_TYPES.VIEW,
                        handler: (params) => {
                            openDetailModal(params);
                        },
                    },
                ];
            case EDIT_OFFICERS_SHARE_CAPITAL:
                if (countSameType && countSameType === 1) {
                    return [
                        {
                            ...ACTION_TYPES.ADD,
                            handler: (params) => {
                                openDetailModal(params);
                            },
                        },
                        {
                            ...ACTION_TYPES.TRANSFER,
                            handler: (params) => {
                                openDetailModal(params);
                            },
                        },
                        {
                            ...ACTION_TYPES.EDIT,
                            handler: (params) => {
                                openDetailModal(params);
                            },
                        },
                    ];
                } else {
                    return [
                        {
                            ...ACTION_TYPES.ADD,
                            handler: (params) => {
                                openDetailModal(params);
                            },
                        },
                        {
                            ...ACTION_TYPES.TRANSFER,
                            handler: (params) => {
                                openDetailModal(params);
                            },
                        },
                        {
                            ...ACTION_TYPES.EDIT,
                            handler: (params) => {
                                openDetailModal(params);
                            },
                        },
                        {
                            ...ACTION_TYPES.RESIGN,
                            handler: (params) => {
                                openDetailModal(params);
                            },
                        },
                    ];
                }
            case FULL_ACTION_OFFICERS_SHARE_CAPITAL:
                if (countSameType && countSameType === 1) {
                    return [
                        {
                            ...ACTION_TYPES.ADD,
                            handler: (params) => {
                                openDetailModal(params);
                            },
                        },
                        {
                            ...ACTION_TYPES.TRANSFER,
                            handler: (params) => {
                                openDetailModal(params);
                            },
                        },
                        {
                            ...ACTION_TYPES.EDIT,
                            handler: (params) => {
                                openDetailModal(params);
                            },
                        },
                    ];
                } else {
                    return [
                        {
                            ...ACTION_TYPES.ADD,
                            handler: (params) => {
                                openDetailModal(params);
                            },
                        },
                        {
                            ...ACTION_TYPES.TRANSFER,
                            handler: (params) => {
                                openDetailModal(params);
                            },
                        },
                        {
                            ...ACTION_TYPES.EDIT,
                            handler: (params) => {
                                openDetailModal(params);
                            },
                        },
                        {
                            ...ACTION_TYPES.RESIGN,
                            handler: (params) => {
                                openDetailModal(params);
                            },
                        },
                    ];
                }
            case UPLOAD_BOOKEEPING:
                return [
                    {
                        ...ACTION_TYPES.UPLOAD,
                        handler: (params) => {
                            openDetailModal(params);
                        },
                    },
                ];
            default:
                return [
                    {
                        ...ACTION_TYPES.UPLOAD,
                        handler: (params) => {
                            openDetailModal(params);
                        },
                    },
                ];
        }
    };
    // draw all level 1 nodes on graph.
    if (type === VIEW_OFFICERS_SHARE_CAPITAL) {
        arrLevelOne = arrLevelOne.filter((member) => member.is_approved);
    }
    if (type === FULL_ACTION_OFFICERS_SHARE_CAPITAL) {
        arrLevelOne = getMembersHaveNoChangeRequest(arrLevelOne, changeRequests);
    }

    //define Shareholder's length, UBO's length and Director's length
    let countShareholderLevelOne = arrLevelOne.filter(
        (member) =>
            member.company_member_type_id === COMPANY_MEMBER_TYPES_FILTER.SHAREHOLDER?.id &&
            changeRequests
                ?.filter((item) => item.change_request_status_id === 3)
                ?.filter(
                    (item) =>
                        item?.CompanyPosition?.company_member_type_id ===
                        member?.company_member_type_id,
                )
                ?.every((item) => item.company_member_id !== member.id),
    ).length;
    let countUBOLevelOne = arrLevelOne.filter(
        (member) =>
            member.company_member_type_id === COMPANY_MEMBER_TYPES_FILTER.UBO?.id &&
            changeRequests
                ?.filter((item) => item.change_request_status_id === 3)
                ?.filter(
                    (item) =>
                        item?.CompanyPosition?.company_member_type_id ===
                        member?.company_member_type_id,
                )
                ?.every((item) => item.company_member_id !== member.id),
    ).length;
    let countDirectorLevelOne = arrLevelOne.filter(
        (member) =>
            member.company_member_type_id === COMPANY_MEMBER_TYPES_FILTER.DIRECTOR?.id &&
            changeRequests
                ?.filter((item) => item.change_request_status_id === 3)
                ?.filter(
                    (item) =>
                        item?.CompanyPosition?.company_member_type_id ===
                        member?.company_member_type_id,
                )
                ?.every((item) => item.company_member_id !== member.id),
    ).length;

    arrLevelOne.forEach((member) => {
        if (
            (otherInfo?.is_has_director &&
                member.company_member_type_id === COMPANY_MEMBER_TYPES_FILTER.DIRECTOR?.id) ||
            (otherInfo?.is_has_shareholder &&
                member.company_member_type_id === COMPANY_MEMBER_TYPES_FILTER.SHAREHOLDER?.id) ||
            (isManagerHidden(data) &&
                member.company_member_type_id === COMPANY_MEMBER_TYPES_FILTER.DIRECTOR?.id)
        ) {
            return;
        } else {
            if (
                (member?.is_nominee &&
                    member?.is_approved &&
                    member.company_member_type_id === COMPANY_MEMBER_TYPES_FILTER.DIRECTOR?.id) ||
                (member?.is_nominee &&
                    member?.is_approved &&
                    member.company_member_type_id === COMPANY_MEMBER_TYPES_FILTER.SHAREHOLDER?.id)
            ) {
                return;
            } else {
                let countSameType;
                switch (member.company_member_type_id) {
                    case 1:
                        countSameType = countDirectorLevelOne;
                        break;
                    case 2:
                        countSameType = countShareholderLevelOne;
                        break;
                    case 3:
                        countSameType = countUBOLevelOne;
                        break;
                    default:
                        countSameType = null;
                        break;
                }
                const node = createNode(
                    member,
                    actionsLevelOne(type, countSameType),
                    type,
                    is_LLC,
                    otherInfo,
                    changeRequests,
                );
                const edge = createEdge({
                    source: `${rootId}-cmt${member.company_member_type_id}`,
                    target: member.graph_id,
                });
                // add new node to element list.
                results.push(node);
                results.push(edge);
                // check member is corporation.
                if (member.type_member_id === MEMBER_TYPES.CORPORATION.id) {
                    Object.values(COMPANY_MEMBER_TYPES_FILTER).forEach((companyMemberType) => {
                        const companyMemberNode = createCorporationChildNode(
                            member,
                            companyMemberType,
                            data.entity_type_id,
                            data.country_id,
                            member.level + 1,
                            CorporationChildSAddActions,
                            type,
                        );
                        results.push(companyMemberNode[0]);
                        results.push(companyMemberNode[1]);
                    });
                }
            }
        }
    });

    /* -----------------------------------------------------------
     * ---------- Level n nodes -------------
     */
    let arrLevelN = members.filter((el) => el.corporation_company_member_id);
    if (type === VIEW_OFFICERS_SHARE_CAPITAL) {
        arrLevelN = arrLevelN.filter((member) => member.is_approved);
    }
    // if (type === FULL_ACTION_OFFICERS_SHARE_CAPITAL) {
    //     arrLevelN = getMembersHaveNoChangeRequest(arrLevelN, changeRequests, arrLevelOne);
    // }

    arrLevelN.forEach((member) => {
        const CorporationChildSDeleteActions =
            otherInfo?.is_banking_flow && !otherInfo?.is_outside_company
                ? []
                : [
                      {
                          ...ACTION_TYPES.ADD,
                          handler: (params) => {
                              openDetailModal(params);
                          },
                      },
                  ];
        const node = createNode(
            member,
            actionsLevelOne(type),
            type,
            is_LLC,
            otherInfo,
            changeRequests,
        );

        // add new node to element list.
        results.push(node);
        // check member is corporation.
        if (member.type_member_id === MEMBER_TYPES.CORPORATION.id) {
            Object.values(COMPANY_MEMBER_TYPES_FILTER).forEach((companyMemberType) => {
                const companyMemberNode = createCorporationChildNode(
                    member,
                    companyMemberType,
                    data.entity_type_id,
                    data.country_id,
                    member.level + 1,
                    CorporationChildSAddActions,
                    type,
                );
                results.push(companyMemberNode[0]);
                results.push(companyMemberNode[1]);
            });
        }
    });
    arrLevelN.forEach((member) => {
        const parentMember = results
            .filter((el) => el.data && el.data.value)
            .find((el) => `${el.id}` === member.parent_id);
        if (parentMember) {
            const edge = createEdge({
                source: `${parentMember.id}-cmt${member.company_member_type_id}`,
                target: member.graph_id,
            });
            results.push(edge);
        }
    });

    /* ------------------------------------------------------------
     * ----return list of elements (nodes and edges)---
     */
    return results;
}

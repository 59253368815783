import React, { useState } from 'react';
import { KYCUIProvider } from './KYCUIContext';
import KYCGraph from './graphs/KYCGraph';
import KYCGraph1 from 'app/pages/components/kyc/KYCGraph1';
import { Card, CardBody } from '_metronic/_partials/controls';
import DetailDialog from './detail-dialog/DetailDialog';
import 'app/pages/services/components/kyc-elements/kyc.scss';
import { useSelector, shallowEqual } from 'react-redux';
import { useEffect } from 'react';
import { isLLC } from 'app/pages/services/constants';
import { getContactInfo } from '../instruction-incorporation/component/helpers';
import { formatCompanyMembers } from '../instruction-incorporation/component/step3/step3-helper/helpers';

export default function KYCPage({ setNewProcessByData, process }) {
    const { orderInfo, countries, phonePrefixCountries } = useSelector((state) => {
        return {
            orderInfo: state.orderForm.orderInfo,
            countries: state.enum.countries,
            phonePrefixCountries: state.enum.phonePrefixCountries,
        };
    }, shallowEqual);
    const [body, setBody] = useState(null);

    useEffect(() => {
        try {
            if (orderInfo && countries) {
                let country,
                    phone_prefix,
                    phone_country_id,
                    country_operations = [];

                let company_country_id;
                if (orderInfo.Company?.country_id === 250) {
                    // Delaware(id 250) thuộc United States(id 238)
                    company_country_id = 238;
                } else {
                    company_country_id = orderInfo.Company.country_id;
                }
                countries.some((item) => {
                    if (item.id === company_country_id) {
                        country = item;
                        return true;
                    }
                });

                if (orderInfo.Task.phone_country_id) {
                    phone_country_id = orderInfo.Task.phone_country_id;
                } else {
                    phone_country_id = company_country_id;
                }
                phonePrefixCountries &&
                    phonePrefixCountries.some((item) => {
                        if (item.id === phone_country_id) {
                            phone_prefix = item;
                            return true;
                        }
                    });

                // == country_operations
                if (orderInfo.Company?.OperationCountries) {
                    country_operations = orderInfo.Company.OperationCountries.map((item) => {
                        let temp = countries && countries.find((e) => e.id === item.id);
                        return { ...temp, value: item.id };
                    });
                } else {
                    country_operations = [];
                }

                let data = {
                    country: country,
                    phone_prefix: phone_prefix,
                    number_of_share_standard: 50000,
                    number_of_share_over: 50000,
                    par_value: 1,
                    currency_unit: 'USD',
                    // is_has_director: true, // show data, will be deleted after finished . @TODO @TODELETE ========================================================
                    // is_has_shareholder: true, // show data, will be deleted after finished . @TODO @TODELETE ========================================================

                    // set default điều kiện cần người ký đại diện
                    represented_director: false,
                    represented_shareholder: false,
                    represented_ubo: false,
                };

                // represented: reset điều kiện cần người ký đại diện theo data api
                if (orderInfo.represented_type_ids && orderInfo.represented_type_ids.length > 0) {
                    orderInfo.represented_type_ids.map((id) => {
                        switch (id) {
                            case 1:
                                data.represented_director = true;
                                break;
                            case 2:
                                data.represented_shareholder = true;
                                break;
                            case 3:
                                data.represented_ubo = true;
                                break;
                        }
                    });
                }

                data = {
                    ...data,
                    Represented: {
                        Director: {
                            label: 'Confirm to act as authorized signatory',
                            errorMessage: 'You must have Confirm to act as authorized signatory!',
                        },
                        // Shareholder: {
                        //     label: "Responsible Party Signing the ss4 Form",
                        //     errorMessage: "You must choose one Responsible party signing the ss4 form!",
                        // },
                        Shareholder: {
                            label: 'Consent to act as authorized signatory',
                            errorMessage: 'You must have Consent to act as authorized signatory!',
                        },
                        UBO: {
                            label: 'Consent to act as authorized signatory',
                            errorMessage: 'You must have Consent to act as authorized signatory!',
                        },
                    },
                };

                if (orderInfo.Company.country_id === 250) {
                    data.Represented.Director = {
                        label: 'Responsible Party Signing the ss4 Form',
                        errorMessage: 'You must choose one Responsible party signing the ss4 form!',
                    };
                }
                const CountriesHasRepresentedType2 = [136, 183, 169, 205]; // Marshall, Samoa, Panama, St. Kitts & Nevis
                if (
                    CountriesHasRepresentedType2.find((id) => id === orderInfo.Company.country_id)
                ) {
                    data.Represented.Director = {
                        label: 'Consent to act as secretary',
                        errorMessage: 'You must have Consent to act as secretary!',
                    };
                }
                if (
                    orderInfo.Company.country_id === 209 &&
                    orderInfo.Company.entity_type_id === 11
                ) {
                    // SVG LLC
                    data.Represented.Director = {
                        label: 'Consent to act as secretary',
                        errorMessage: 'You must have Consent to act as secretary!',
                    };
                }

                if (
                    orderInfo.Company.country_id === 23 &&
                    orderInfo.Company.entity_type_id === 11
                ) {
                    // Belize LLC
                    data.Represented.UBO = {
                        label: 'Consent act as Signatory',
                        errorMessage: 'You must have Consent act as Signatory!',
                    };
                }

                if (orderInfo.Company?.country_id === 98) {
                    // Hong Kong
                    data = {
                        ...data,
                        isHongkong: true,
                        number_of_share_standard: 10000,
                        number_of_share_over: 10000000,
                        currency_unit: 'HKD',
                    };
                }
                if (orderInfo.Company.country_id === 250) {
                    // Delaware
                    data = {
                        ...data,
                        number_of_share_standard: 1000,
                        number_of_share_over: 1000,
                    };
                }
                if (orderInfo.Company.country_id === 169) {
                    // Panama
                    data = {
                        ...data,
                        isPanama: true,
                        number_of_share_standard: 100,
                        number_of_share_over: 10000,
                        par_value: 100,
                    };
                }
                if (orderInfo.Company.country_id === 209) {
                    // SVG
                    data = {
                        ...data,
                        isSVG: true,
                    };
                }
                if (orderInfo.Company.country_id === 17) {
                    // Bahamas id = 17
                    data = {
                        ...data,
                    };
                }
                switch (orderInfo?.Company?.country_id) {
                    case 41:
                        data = { ...data, isCayman: true };
                        break;
                    case 17:
                        data = { ...data, isBahamas: true };
                        break;
                    case 23:
                        data = { ...data, isBelize: true };
                        break;
                    case 32:
                        data = { ...data, isBVI: true };
                        break;
                    case 136:
                        data = { ...data, isMarshall: true };
                        break;
                    default:
                        break;
                }

                // check UK LLP
                if (orderInfo.Company.country_id === 237) {
                    data = {
                        ...data,
                        isUK: true,
                    };
                    if (orderInfo.Company.entity_type_id === 7) {
                        data = {
                            ...data,
                            is_UK_LLP: true,
                        };
                    }
                    if (orderInfo.Company.entity_type_id === 16) {
                        data = {
                            ...data,
                            is_UK_LTD: true,
                        };
                    }
                }

                // LLC all counntry
                let checkLLC = isLLC(
                    orderInfo.Company.entity_type_id,
                    orderInfo.Company.country_id,
                );

                if (checkLLC) {
                    data = {
                        ...data,
                        isLLC: true,
                    };
                    if (orderInfo.Company.country_id === 205) {
                        // St. Kitts & Nevis
                        data.number_of_share_standard = 10000;
                    } else {
                        data.number_of_share_standard = 0;
                    }
                }

                // api data -> default data:
                const contactInfo = getContactInfo(orderInfo.Company);
                const members = formatCompanyMembers({ Company: orderInfo.Company });
                data = {
                    ...data,
                    contactInfo: {
                        first_name: contactInfo.first_name || '',
                        middle_name: contactInfo?.middle_name || '',
                        last_name: contactInfo?.last_name || '',
                        full_name: contactInfo?.full_name || '',
                        email: contactInfo.email || orderInfo.Task.email,
                        phone_number:
                            contactInfo.phone_number ||
                            orderInfo.Task.phone_number ||
                            orderInfo.Customer.phone_number,
                        phone_country_id:
                            contactInfo.phone_country_id ||
                            orderInfo.Task.phone_country_id ||
                            orderInfo.Customer.phone_country_id ||
                            '',
                        address:
                            contactInfo.address ||
                            orderInfo.Task.address ||
                            orderInfo.Customer.address ||
                            '',
                        address_country_id:
                            contactInfo.address_country_id ||
                            orderInfo.Task.country_id ||
                            orderInfo.Customer.country_id ||
                            '',
                        city:
                            contactInfo.city ||
                            orderInfo.Task.city ||
                            orderInfo.Customer.city ||
                            '',
                        state:
                            contactInfo.state ||
                            orderInfo.Task.state ||
                            orderInfo.Customer.state ||
                            '',
                        postal_code:
                            contactInfo.postal_code ||
                            orderInfo.Task.postal_code ||
                            orderInfo.Customer.postal_code ||
                            '',
                    },
                    companyInfo: {
                        company_country: orderInfo.Company.Country.name,
                        company_id: orderInfo.Company.id,
                        company_name: orderInfo.Company.name,
                        company_type: orderInfo.Company.EntityType.name,
                        company_suffix_id: orderInfo.Company.company_suffix_id || '',
                        business_activity_detail: orderInfo.Company.business_activity_detail || '',
                        business_activity_array: orderInfo.Company.business_activity_array || '',
                        business_annual_report: orderInfo.Company.business_annual_report || '',
                        source_fund: orderInfo.Company.source_fund || '',
                        OperationCountries: country_operations || '',
                        country_operations: country_operations || '',
                        operation_address: orderInfo.Company.address || '',
                        operating_country_id: orderInfo.Company.operating_country_id || '',
                        city: orderInfo.Company.operating_city || '',
                        state: orderInfo.Company.operating_state || '',
                        postal_code: orderInfo.Company.operating_postal_code || '',
                        // country: country,
                    },
                    companyStructure: { ...members },
                };

                setBody({ ...orderInfo, ...data });
            }
        } catch (e) {
            console.log(e);
        }
    }, [countries, phonePrefixCountries, orderInfo]);

    return (
        <>
            <KYCUIProvider>
                <DetailDialog body={body} setBody={setBody} />
                <Card>
                    <CardBody>
                        <p className="kyc-note mb-1">
                            <b>Note*:</b>
                            <i>
                                We can support to notarize passport &amp; address proof by CPA in
                                Hong Kong for each member with USD 50.00.
                            </i>
                        </p>
                        <p className="kyc-note">
                            <b>Note**:</b>
                            <i>
                                Address proof can be an Identity Card/ Driver license/ Bank
                                reference/ Bank statement/ Utility bill showing holder's full name
                                and address in English.
                            </i>
                        </p>
                        <KYCGraph
                            setNewProcessByData={setNewProcessByData}
                            process={process}
                            body={body}
                            setBody={setBody}
                        />
                    </CardBody>
                </Card>
            </KYCUIProvider>
        </>
    );
}

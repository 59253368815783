const useInfor = (userInfor) => {
    const user = (userInfor?.first_name ? userInfor?.first_name : '') + (userInfor?.last_name ? " " + userInfor?.last_name : '');

    const welcome = `
    <div class="text-center">
        <div style="padding:20px">
        <img alt="logo" src="/media/logos/logo-dark.png" >
        <div>
        <h4 style="padding: 20px 0 0;">
        Hi ${user}, Welcome to BBCIncorp Portal!
        </h4>
        With our digitizing company management process, you can focus on flourishing growth.
        <div>
        <button class="exit-intro-customer btn btn-sm btn-danger" onClick={document.querySelector('.introjs-skipbutton').click();document.querySelector('#trigger_click_react').click()}>Skip</button>
        <button class="enter-intro-customer btn btn-sm btn-primary" onclick="document.querySelector('.introjs-nextbutton').click()">Ready to get started</button>
        </div>
    </div> 
    `;

    const companyList = `
    <div class="wrap-intro">
        <div class="text-intro">
        <h5>Company List</h5>
        To display information of all companies, regardless of managed or not managed by BBCIncorp, attached to your orders.
        <p></p>
        It is listed by last modified order and includes the status of each submission.
        </div>
        <div class="img-intro">
        <img alt="logo" src="/media/intro/company_list.png"  style="min-height:395px">
        </div>
    <div>
    `;

    const invoices = `
    <div class="wrap-intro">
        <div class="text-intro">
        <h5>Invoice List</h5>
        To show the list of all related invoices attached to your orders.
        <p></p>
        It is listed by last modified order and includes the status of each submission
        </div>
        <div class="img-intro" style="right:-40px">
        <img alt="logo" src="/media/intro/invoice.png" style="min-height:300px">
        </div>
    <div>
    `;

    const newIncorporation = `
    <div class="wrap-intro">
        <div class="text-intro">
        <h5>New Incorporation</h5>
        You can establish a new company with four-step demonstrated detail and easy to follow.
        </div>
        <div class="img-intro">
        <img alt="logo" src="/media/intro/new_incor.png" style="min-height:395px">
        </div>
    <div>
    `;

    const additionalServices = `
    <div class="wrap-intro">
        <div class="text-intro">
        <h5>Additional Services</h5>
        Your up-and-running company needs some extra services? Explore how BBCIncorp offers your company management with great experience.
        <p></p>
        We provide additional services for both Companies under BBCIncorp’s management and Companies NOT managed by BBCIncorp.
        </div>
        <div class="img-intro">
        <img alt="logo" src="/media/intro/additional_service.png" style="min-height:395px">
        </div>
    <div>
    `;

    const orderManagement = `
    <div class="wrap-intro">
        <div class="text-intro">
        <h5>Order Management</h5>
        Skip paperwork and meetups, One stop to kickstart and monitor Your Incorporation and Banking application process
        </div>
        <div class="img-intro">
        <img alt="logo" src="/media/intro/company_list.png" style="min-height:395px">
        </div>
    <div>
    `;

    const accountInfo = `
    <div class="wrap-intro">
        <div class="text-intro">
        <h5>Account info</h5>
        You can easily update your profile information
        </div>
        <div class="img-intro">
        <img alt="logo" src="/media/intro/account.png" style="min-height:382px">
        </div>
    <div>
    `;

    const billingInformation = `
    <div class="wrap-intro">
        <div class="text-intro">
        <h5>Billing Information</h5>
        To display all billings you have saved while using our dashboard
        </div>
        <div class="img-intro">
        <img alt="logo" src="/media/intro/billing.png" style="min-height:382px">
        </div>
    <div>
    `;

    const supportCases = `
    <div class="wrap-intro">
        <div class="text-intro">
        <h5>Support Case </h5>
        If you encounter any problems with your orders, billings, accounts, invoices or sale inquiries, you can create a Support Case in our dashboard
        </div>
        <div class="img-intro">
        <img alt="logo" src="/media/intro/support.png" style="min-height:229px">
        </div>
    <div>
    `;

    const helpCenter = `
    <div class="wrap-intro">
        <div class="text-intro">
        <h5>Help Center</h5>
        Access to Help Center for detailed instructions of all functions and managements of your information, services, company list and invoices.
        </div>
        <div class="img-intro">
        <img alt="logo" src="/media/intro/help.png" style="min-height:382px">
        </div>
    <div>
    `;

    const steps = [{
        intro: welcome,
    },
    {
        element: '#menu_company_list',
        intro: companyList,
        position: 'right'
    },
    {
        element: '#menu_invoices',
        intro: invoices,
        position: 'right'
    },
    {
        element: '#menu_new_incorporation',
        intro: newIncorporation,
        position: 'right'
    },
    {
        element: '#menu_additional_services',
        intro: additionalServices,
        position: 'right'
    },
    {
        element: '#menu_order_management',
        intro: orderManagement,
        position: 'right'
    },
    {
        element: '#menu_account_info',
        intro: accountInfo,
        position: 'right'
    },
    {
        element: '#menu_billing_information',
        intro: billingInformation,
        position: 'right'
    },
    {
        element: '#menu_support_cases',
        intro: supportCases,
        position: 'right'
    },
    {
        element: '#menu_help_center',
        intro: helpCenter,
        position: 'right'
    }
    ];

    return steps;
}


export default useInfor;

import React from 'react';
import InputField from 'components/form-controls/InputField';
import { InputAdornment } from '@mui/material';

export default function FinancialForecast({ form }) {
    return (
        <>
            <p className="fw-500">Financial forecast (in USD):</p>
            <div className="row">
                <div className="col-xl-3 col-md-4 mb-7 input-line">
                    <label>Initial deposit:</label>
                    <InputField
                        type="number"
                        form={form}
                        name="financial_initial_deposit"
                        placeholder="Number"
                        InputProps={{
                            endAdornment: (<InputAdornment position="end">USD</InputAdornment>)
                        }}
                        hideSuccessHelper
                    />
                </div>

                <div className="col-xl-5 col-md-8 mb-7 input-line">
                    <label>Source of fund:</label>
                    <InputField
                        form={form}
                        name="financial_source_fund"
                        placeholder="Please provide specific details"
                        showIcon
                        hideSuccessHelper
                    />
                </div>

                <div className="col-12"></div>

                <div className="col-xl-3 col-md-4 mb-7 input-line">
                    <label>Average balance to be maintained in account:</label>
                    <InputField
                        type="number"
                        form={form}
                        name="financial_average_balance"
                        placeholder="Number"
                        InputProps={{
                            endAdornment: (<InputAdornment position="end">USD</InputAdornment>)
                        }}
                        hideSuccessHelper
                    />
                </div>
            </div>
        </>
    )
}
import axios from 'axios';

export const BILLING_URL = '/personal';

export function getPersonal() {
    return axios.get(`${BILLING_URL}`);
}
export function updatePersonal(body) {
    return axios.put(`${BILLING_URL}`, body);
}

export function changePassword(body) {
    return axios.put(`${BILLING_URL}/password`, body);
}

import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@mui/material';
import { getDefaultNationality } from 'app/pages/services/utilities/NationalityHelpers';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import PageLoadingEffect from '../../../common/PageLoadingEffect';

import { updateBusinessPlanFormAction } from 'app/pages/_redux/banking/asyncThunk';
import { get, head, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import * as actionsEnum from '../../../../../../_redux/enum/enumActions';
import { getCompanyInfo } from '../../../../../../_redux/incorporation/incorporationCrud';
import { COUNTRIES_NOT_DISABLED_BANKING_FORM } from '../../../constant';

import { yupResolver } from '@hookform/resolvers/yup';
import LabelTooltip from 'app/components/LabelTooltip';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import { CAYMAN_ID, RAK_ID, UK_ID } from 'constants/country';
import { useForm } from 'react-hook-form';

const initFormDefault = {
    id: '',
    name: '',
    company_country_id: null,

    company_suffix_id: null,
    entity_type_id: null,

    company_reg_no: '',
    country_operations_ids: [],

    business_activity_array: [],
    business_activity_detail: '',
    source_fund: '',
    business_annual_report: [],

    address: '',
    operating_country_id: null,
    operating_city: '',
    operating_state: '',
    operating_postal_code: '',
};

export default function Step2({ activeStep, setActiveStep }) {
    const dispatch = useDispatch();
    const [isInCorpCompany, setIsInCorpCompany] = useState(false);
    const [initForm, setInitForm] = useState(initFormDefault);
    const [isLoading, setIsLoading] = useState(false);
    const [businessActivityOptions, setBusinessActivityOptions] = useState([]); // list business attivity option
    const [businessAnnualReportOptions, setBusinessAnnualReportOptions] = useState([]); // list business anual report option
    const [companyType, setCompanyType] = useState({ id: 0, name: '' });
    const [companySuffix, setCompanySuffix] = useState({ id: 0, name: '' });
    const [countryInfo, setCountryInfo] = useState(null);
    const { countriesHasService, geoLooking, orderInfo, businessActivityData } = useSelector(
        (state) => ({
            countriesHasService: state.enum.countriesHasService,
            countriesCooperationOptions: state.enum.countriesCooperation,
            geoLooking: state.enum.geoLooking, // use for getDefaultNationality().
            orderInfo: state.banking.orderInfo,
            businessActivityData: state.enum.businessActivity,
        }),
        shallowEqual,
    );
    
    const [showGeneralDescription, setShowGeneralDescription] = useState(false);
    const [addressHint, setAddressHint] = useState(null);

    const { businessActivityWithCountry } = useSelector(
        (state) => ({
            businessActivityWithCountry: state.enum.businessActivityWithCountry,
        }),
        shallowEqual,
    );
    const { countriesOptions } = useSelector((state) => state.enum);

    const disabledStep = useMemo(() => {
        return (!orderInfo?.Company?.is_outside_company && !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(orderInfo?.Company?.country_id));
    }, []);

    Yup.addMethod(Yup.string, 'business_annual_report', function (errorMessage, needRequired) {
        return this.test(`business_annual_report`, errorMessage, function (value) {
            const { path, createError } = this;
            if (!needRequired) return true;
            return (needRequired && value) || createError({ path, message: errorMessage });
        });
    });
    Yup.addMethod(Yup.string, 'source_fund', function (errorMessage, needRequired) {
        return this.test(`source_fund`, errorMessage, function (value) {
            const { path, createError } = this;
            if (!needRequired) return true;
            return (needRequired && value) || createError({ path, message: errorMessage });
        });
    });

    const formValidationSchema = Yup.object().shape({
        name: Yup.string().nullable().required('Company name is required!'),

        company_reg_no: Yup.string().nullable().required('Registration No. is required!'),

        entity_type_id: Yup.string().nullable().required('Country of incorporation is required!'),
        company_suffix_id: Yup.string()
            .nullable()
            .required('Country of incorporation is required!'),

        business_activity_array: Yup.array()
            .nullable()
            .min(1, 'Business activities is required!'),
        business_activity_detail: Yup.string().when('business_activity_array', {
            is: (value) => value?.length > 0,
            then: Yup.string()
                .nullable()
                .required('Details of Business activities is required!')
                .test({
                    message:
                        'Please write a complete description of your business activities at least 10 words',
                    test: (value) => value && value.trim().split(/\s+/).length >= 10,
                }),
        }),
        source_fund: Yup.string()
            .nullable()
            .required('Source of funds is required!'),
        business_annual_report: Yup.array()
            .nullable()
            .min(1,'General description for annual report is required!'),

        address: Yup.string().nullable().required('Operating address is required!'),
        operating_city: Yup.string().nullable().required('City is required!'),

        company_country_id: Yup.string().nullable().required('Country of incorporation is required!'),
        country_operations_ids: Yup.array()
            .nullable()
            .min(1, 'Country of Operation(s) is required!'),
        operating_country_id: Yup.string().nullable().required('Country is required!'),

    });

    const form = useForm({
        defaultValues: initForm,
        resolver: yupResolver(formValidationSchema)
    });

    const handleGetCountry = () => {
        if (!form.watch('company_country_id')) return;
        const countryIds = [form.getValues('company_country_id')];
        let countryCorporationIds = [];

        if (countryIds?.length) {
            countryIds.forEach((countryId) => {
                const normalListCountry = countryCorporationIds.filter(
                    (item) => ![CAYMAN_ID, UK_ID, RAK_ID].includes(item),
                );

                if (
                    ![CAYMAN_ID, UK_ID, RAK_ID].includes(countryId) &&
                    !normalListCountry?.length &&
                    !businessActivityWithCountry?.normalList?.length
                ) {
                    countryCorporationIds.push(countryId);
                }

                if (countryId === CAYMAN_ID && !businessActivityWithCountry?.caymanList?.length) {
                    countryCorporationIds.push(CAYMAN_ID);
                }
                if (countryId === UK_ID && !businessActivityWithCountry?.ukList?.length) {
                    countryCorporationIds.push(UK_ID);
                }
                if (countryId === RAK_ID && !businessActivityWithCountry?.rakList?.length) {
                    countryCorporationIds.push(RAK_ID);
                }
            });
        }

        countryCorporationIds.forEach((countryId) => {
            dispatch(actionsEnum.fetchBusinessActivity(countryId, true));
        });
    }

    const handleGetEntitySuffix = () => {
        if (!form.watch('company_country_id')) return;
        setIsLoading(true);
        getCompanyInfo(form.watch('company_country_id'))
            .then((response) => {
                setIsLoading(false);
                const {
                    data: { data },
                } = response;
                setCountryInfo(data);

                const entityTypeId =
                    orderInfo?.Company?.entity_type_id || data.EntityTypes[0].id;
                const companySuffixId =
                    orderInfo?.Company?.company_suffix_id ||
                    data.EntityTypes[0].CompanySuffixes[0].id;

                let selectedCompanyType = data.EntityTypes.find(
                    (item) => item.id == entityTypeId,
                );
                if (!selectedCompanyType) {
                    selectedCompanyType = data.EntityTypes[0];
                }

                form.setValue('entity_type_id', selectedCompanyType.id);

                const selectedCompanySuffixes = selectedCompanyType.CompanySuffixes.find(
                    (item) => item.id == companySuffixId,
                );
                if (!selectedCompanySuffixes) {
                    form.setValue('company_suffix_id', selectedCompanyType.CompanySuffixes[0].id);
                } else {
                    form.setValue('company_suffix_id', selectedCompanySuffixes.id);
                }
                
                setCompanySuffix(selectedCompanySuffixes);
                setCompanyType(selectedCompanyType);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error('Somethings went wrong!');
            });
    }

    useEffect(() => {
        handleGetCountry();
        handleGetEntitySuffix();
    }, [form.watch('company_country_id')]);

    useEffect(() => {
        if (form.formState.isSubmitted) {
            if (form.getValues('business_activity_array').length === 0) {
                form.trigger('business_activity_array');
            }

            if (form.getValues('business_activity_detail').length === 0) {
                form.trigger('business_activity_detail');
            }
        }
    }, [form.watch('business_activity_array').length]);
    
    useEffect(() => {
        try {
            if (countriesHasService && orderInfo && countriesOptions) {
                const companyCountryFound =
                    countriesHasService.find(
                        (item) => item.id === orderInfo?.Company?.country_id,
                    ) || countriesHasService[0]; // param: company_country_id
                const operatingCountryFound = getDefaultNationality(
                    orderInfo?.Company?.operating_country_id ?? null,
                    countriesOptions,
                ); // param: operating_country_id

                // format Countries Operation option list
                let countriesOperationTemp = countriesOptions.map((item) => {
                    return { ...item, value: item.id };
                });

                // find & set operating_country
                const countryOperationsIds = orderInfo?.Company?.OperationCountries?.map(
                    (country) => country.id,
                );
                let countryOperationsFound = [];
                countryOperationsIds.forEach((id) => {
                    let countryFound = countriesOperationTemp.find((e) => e.id === id);
                    countryFound && countryOperationsFound.push(countryFound);
                });
                if (!orderInfo.Company.is_outside_company) {
                    setIsInCorpCompany(true);
                }

                const business_activity = orderInfo?.Company?.CompanyHasBusinessActivity?.map(item => item?.id) ?? [];
                let business_annual_report;
                try {
                    if (typeof orderInfo?.Company?.business_annual_report === 'string') {
                        business_annual_report = JSON.parse(orderInfo?.Company?.business_annual_report);
                    } else {
                        business_annual_report = orderInfo?.Company?.business_annual_report;
                    }
                } catch (error) {
                    console.error({error})
                }

                /* CAYMAN: show business annual report  */
                if (orderInfo.Company.country_id === CAYMAN_ID) {
                    setShowGeneralDescription(true);
                }

                const initFormData = {
                    ...initFormDefault,
                    id: orderInfo?.Company?.id ?? '',
                    name: orderInfo?.Company?.name ?? '',
                    company_reg_no: orderInfo?.Company?.company_reg_no ?? '',
                    business_activity_array: business_activity,
                    business_activity_detail: orderInfo?.Company?.business_activity_detail ?? '',
                    source_fund: orderInfo?.Company?.source_fund ?? '',
                    business_annual_report: business_annual_report,
                    address: orderInfo?.Company?.address ?? '',
                    operating_city: orderInfo?.Company?.operating_city ?? '',
                    operating_state: orderInfo?.Company?.operating_state ?? '',
                    operating_postal_code: orderInfo?.Company?.operating_postal_code ?? '',

                    company_suffix_id: orderInfo?.Company?.company_suffix_id ?? null,
                    entity_type_id: orderInfo?.Company?.entity_type_id ?? null,

                    company_country_id: companyCountryFound.id ?? null,
                    operating_country_id: operatingCountryFound.id ?? null,
                    country_operations_ids:
                        countryOperationsFound.map((e) => {
                            return e.id;
                        }) ?? [],
                };

                form.reset(initFormData);

                if (orderInfo.Company && !orderInfo?.Company?.address) {
                    let countryTemp =
                        countriesOptions &&
                        countriesOptions.find((e) => e.id === orderInfo.Task.country_id);
                    let addressHintTemp = {
                        address: orderInfo.Task.address,
                        country_id: orderInfo.Task.country_id,
                        city: orderInfo.Task.city,
                        state: orderInfo.Task.state,
                        postal_code: orderInfo.Task.postal_code,
                        country: countryTemp,
                        full_address:
                            orderInfo.Task.address +
                            ', ' +
                            orderInfo.Task.city +
                            ', ' +
                            orderInfo.Task.state +
                            (orderInfo.Task.state ? ', ' : '') +
                            countryTemp.name +
                            (orderInfo.Task.postal_code ? ', ' : '') +
                            orderInfo.Task.postal_code,
                    };
                    setAddressHint(addressHintTemp);
                }

                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }, [countriesHasService, orderInfo, countriesOptions]);

    useEffect(() => {
        if (countriesHasService) return;
        dispatch(actionsEnum.fetchCountriesHasService());
    }, []);

    useEffect(() => {
        try {
            setIsLoading(false);
            if (businessActivityData) {
                const businessActivityDataTemp = [];
                const businessAnnualReportOptionsTemp = [];

                if ([UK_ID, RAK_ID, CAYMAN_ID].includes(orderInfo?.Company?.country_id)) {
                    businessActivityData[0].BusinessActivities.map((item) => {
                        businessActivityDataTemp.push({
                            ...item,
                            value: item.id,
                            label:
                                (item.sic_code ? '[' + item.sic_code + '] ' : '') +
                                item.description,
                        });
                    });
                } else {
                    const businessActivities = get(head(businessActivityData), 'BusinessActivities');
                    if (!isEmpty(businessActivities)) {
                        businessActivities.forEach((item) => {
                            businessActivityDataTemp.push({
                                ...item,
                                value: item.id,
                                label: item.name,
                            });
                        });
                    }
                }

                if ([CAYMAN_ID].includes(orderInfo?.Company?.country_id)) {
                    businessActivityData[1].BusinessActivities.forEach((item) => {
                        businessAnnualReportOptionsTemp.push({
                            ...item,
                            value: item.id,
                            label:
                                (item.sic_code ? '[' + item.sic_code + '] ' : '') +
                                item.description,
                        });
                    });

                    setBusinessAnnualReportOptions(businessAnnualReportOptionsTemp);
                }

                setBusinessActivityOptions(businessActivityDataTemp);
            }
        } catch (error) {
            console.log(error);
        }
    }, [businessActivityData]);

    const handleNext = async (values) => {
        if (disabledStep) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            return;
        }
        setIsLoading(true);

        const companyInformationUpdate = {
            id: values?.id,
            name: values?.name,
            country_id: Number(values?.company_country_id),
            company_reg_no: values?.company_reg_no,
            OperationCountries: values?.country_operations_ids,
            business_activity_array: values?.business_activity_array,
            business_activity_detail: values?.business_activity_detail,
            source_fund: values?.source_fund,
            address: values?.address,
            operating_country_id: Number(values?.operating_country_id),
            operating_city: values?.operating_city,
            operating_state: values?.operating_state,
            operating_postal_code: values?.operating_postal_code,
            company_suffix_id: Number(values?.company_suffix_id),
            entity_type_id: Number(values?.entity_type_id),
        };

        if (values.business_annual_report?.length) {
            companyInformationUpdate.business_annual_report = JSON.stringify(values.business_annual_report);
        }

        for (const key in companyInformationUpdate) {
            if (
                companyInformationUpdate[key] === '' ||
                companyInformationUpdate[key] === undefined ||
                companyInformationUpdate[key] === null
            ) {
                delete companyInformationUpdate[key];
            }
        }

        /* Start - Xử lý show error khi gọi tới be thất bại */
        const resultAction = await dispatch(
            updateBusinessPlanFormAction({
                id: orderInfo.Task.id,
                params: { companyInformationUpdate },
            }),
        );
        if (updateBusinessPlanFormAction.fulfilled.match(resultAction)) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            toast.error(
                resultAction?.payload?.data ||
                    resultAction?.error?.message ||
                    'Something went wrong!',
            );
        }
        /* End - Xử lý show error khi gọi tới be thất bại */
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getBusinessActivityOfCountry = (countryId) => {
        if (!countryId) return [];
        if (businessActivityWithCountry) {
            if (countryId === CAYMAN_ID) {
                if (businessActivityWithCountry.caymanList.length) {
                    return businessActivityWithCountry.caymanList;
                }
            }
            if (countryId === UK_ID) {
                if (businessActivityWithCountry.ukList.length) {
                    return businessActivityWithCountry.ukList;
                }
            }
            if (countryId === RAK_ID) {
                if (businessActivityWithCountry.rakList.length) {
                    return businessActivityWithCountry.rakList;
                }
            }

            if (![CAYMAN_ID, UK_ID, RAK_ID].includes(countryId)) {
                if (businessActivityWithCountry.normalList.length) {
                    return businessActivityWithCountry.normalList;
                }
            }
        } else {
            return [];
        }
    };

    const handleSubmit = (values) => {
        handleNext(values);
    }

    return (
        <>
            <div className="step2 position-relative">
                <PageLoadingEffect loading={isLoading} />
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-7 input-line">
                                <label>Company name</label>
                                <InputField
                                    form={form}
                                    name="name"
                                    placeholder="Your company name"
                                    disabled={disabledStep}
                                    showIcon
                                    hideSuccessHelper
                                />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="mb-7 input-line">
                                <label>Country of incorporation</label>
                                <SelectField
                                    form={form}
                                    className="fw-500"
                                    name="company_country_id"
                                    id="company_country_id"
                                    placeholder={'Choose country'}
                                    options={countriesHasService || []}
                                    disabled={disabledStep}
                                    hideHelperText={true}
                                    autoComplete="off"
                                    onChange={() => {
                                        form.setValue('business_activity_array', []);
                                        form.setValue('entity_type_id', )
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 mb-7 input-line">
                            <label>Company Type</label>
                            <SelectField
                                form={form}
                                id="entity_type_id"
                                name="entity_type_id"
                                options={countryInfo?.EntityTypes || []}
                                onChange={(value) => {
                                    if (orderInfo?.entity_type_id != value.id) {
                                        setCompanyType(value);
                                        form.setValue('entity_type_id', value.id);
                                        setCompanySuffix(value.CompanySuffixes[0]);
                                        form.setValue(
                                            'company_suffix_id',
                                            value.CompanySuffixes[0].id,
                                        );
                                    }
                                }}
                                disabled={isLoading || disabledStep}
                                hideSuccessHelper
                                hideInputHelperText
                                autoComplete="off"
                            />
                        </div>

                        <div className="col-md-6 mb-7 input-line">
                            <label>
                                {companySuffix?.is_prefix
                                    ? 'Company Prefixes'
                                    : 'Company Suffixes'}
                            </label>
                            <SelectField
                                form={form}
                                id="company_suffix_id"
                                name="company_suffix_id"
                                options={companyType?.CompanySuffixes || []}
                                onChange={(value) => {
                                    setCompanySuffix(value);
                                    form.setValue('company_suffix_id', value.id);
                                }}
                                disabled={isLoading || disabledStep}
                                hideSuccessHelper
                                hideInputHelperText
                                autoComplete="off"
                            />
                        </div>

                        <div className="col-md-6">
                            <div className="mb-7 input-line">
                                <label>Registration No.</label>
                                <InputField
                                    form={form}
                                    name="company_reg_no"
                                    placeholder="0000000000"
                                    disabled={disabledStep}
                                    showIcon
                                    hideSuccessHelper
                                />
                            </div>
                        </div>

                        <div className="col-md-6 mb-7 input-line">
                            <LabelTooltip
                                label="Country of Operation(s)"
                                title="Please provide specific details of all countries the company will be
                                    operating. Worldwide, global, continent names are not accepted."
                            />
                            <SelectField
                                multiple
                                form={form}
                                className="fw-500"
                                name="country_operations_ids"
                                id="country_operations_ids"
                                options={countriesOptions || []}
                                disabled={disabledStep}
                                hideSuccessHelper
                                hideInputHelperText
                                autoComplete="off"
                            />
                        </div>
                        <div className="col-md-6 mb-7 input-line">
                            <LabelTooltip 
                                label="Business activities"
                                title={'Please give specific business activities within 20-30 words. General description of business such "Trading company", "Holding company", or "Investment company" is not sufficient.'}
                            />
                            <SelectField
                                multiple
                                form={form}
                                className="fw-500"
                                name="business_activity_array"
                                id="business_activity_array"
                                options={getBusinessActivityOfCountry(
                                            form.watch('company_country_id'),
                                        ) || []}
                                disabled={disabledStep}
                                placeholder={!form.watch('business_activity_array')?.length && 'Please select specific business activities.'}
                                getOptionLabel={option => option?.description}
                                hideSuccessHelper
                                hideInputHelperText
                                autoComplete="off"
                                customOnChange
                                onChange={(value) => {
                                    if (value?.length) {
                                        form.clearErrors('business_activity_array');
                                        if (
                                            orderInfo.Company.country_id === UK_ID &&
                                            value.length > 4
                                        ) {
                                            Swal.fire({
                                                icon: 'warning',
                                                title: 'Warning!',
                                                text: 'Maximum 4 business activities!',
                                                confirmButtonColor: '#17c191',
                                            });
                                            form.setValue('business_activity_array', form.getValues('business_activity_array'))
                                            return;
                                        }
                                        if (
                                            orderInfo.Company.country_id === RAK_ID &&
                                            value.length > 3
                                        ) {
                                            Swal.fire({
                                                icon: 'warning',
                                                title: 'Warning!',
                                                text: 'Maximum 3 business activities!',
                                                confirmButtonColor: '#17c191',
                                            });
                                            form.setValue('business_activity_array', form.getValues('business_activity_array'))
                                            return;
                                        }
                                    }
                                    form.setValue('business_activity_array', value.map(item => item.id || item));
                                }}
                            />
                            {
                                form.watch('business_activity_array')?.length > 0 && (
                                    <div className="mt-7">
                                        <LabelTooltip 
                                            label="Details of Business activities"
                                            title="Please describe your business activities in this field as details as possible to process your order smoothy"
                                        />
                                        <InputField
                                            form={form}
                                            name="business_activity_detail"
                                            disabled={disabledStep}
                                            multiline
                                            maxRows={5}
                                            showIcon
                                            hideSuccessHelper
                                        />
                                    </div>
                                )
                            }
                        </div>

                        {showGeneralDescription && (
                            <div className={'col-md-6 mb-7 input-line'}>
                                <label>General description for annual report</label>
                                <SelectField
                                    multiple
                                    form={form}
                                    className="fw-500"
                                    name="business_annual_report"
                                    id="business_annual_report"
                                    options={businessAnnualReportOptions || []}
                                    getOptionLabel={option => option?.description}
                                    disabled={disabledStep}
                                    hideSuccessHelper
                                    hideInputHelperText
                                    autoComplete="off"
                                />
                            </div>
                        )}
                        <div className="col-md-6 mb-7 input-line">
                            <LabelTooltip
                                label="Source of funds"
                                title="Kindly indicate the source of the Beneficial Owners funds to start the business." 
                            />
                            <InputField
                                form={form}
                                name="source_fund"
                                placeholder="Please provide specific details"
                                disabled={disabledStep}
                                showIcon
                                hideSuccessHelper
                            />
                        </div>
                    </div>

                    {/* group address */}
                    <div className="row">
                        <div className="col-md-6 mb-4 input-line">
                            <LabelTooltip
                                label="Operating address"
                                title={"You have to provide address proof for Operating address(es), can be ID/ Driver license/ Bank reference/ Bank statement/ Utility bill showing holder's full name and address in English."}
                            />
                            <InputField
                                form={form}
                                name="address"
                                placeholder="Please provide specific details"
                                disabled={disabledStep}
                                showIcon
                                hideSuccessHelper
                            />
                        </div>

                        <div className="col-md-6" />
                        {addressHint && (
                            <div className="col-md-12">
                                <div className="address-hint mb-5">
                                    <span>Address hint: </span>
                                    <span
                                        className="order-tag ml-1"
                                        onClick={() => {
                                            form.setValue('address', addressHint?.address);
                                            form.setValue(
                                                'operating_country_id',
                                                addressHint?.country_id,
                                            );
                                            form.setValue(
                                                'operating_city',
                                                addressHint?.city,
                                            );
                                            form.setValue(
                                                'operating_state',
                                                addressHint?.state,
                                            );
                                            form.setValue(
                                                'operating_postal_code',
                                                addressHint?.postal_code,
                                            );
                                            form.setValue(
                                                'operating_country',
                                                addressHint.country,
                                            );

                                            form.setValue(() => {
                                                setAddressHint(null);
                                            }, 0);
                                        }}
                                    >
                                        {addressHint?.full_address}
                                    </span>
                                </div>
                            </div>
                        )}

                        <div className="col-md-3 col-sm-6 mb-7 input-line">
                            <label>Country</label>
                            <SelectField
                                form={form}
                                className="fw-500"
                                name="operating_country_id"
                                id="operating_country_id"
                                options={countriesOptions || []}
                                disabled={disabledStep}
                                placeholder="Choose country"
                                hideSuccessHelper
                                hideInputHelperText
                                autoComplete="off"
                            />
                        </div>
                        <div className="col-md-3 col-sm-6 mb-7 input-line">
                            <label>City</label>
                            <InputField
                                form={form}
                                name="operating_city"
                                placeholder="Your Operating City"
                                disabled={disabledStep}
                                showIcon
                                hideSuccessHelper
                            />
                        </div>
                        <div className="col-md-3 col-sm-6 mb-7 input-line">
                            <label>State/Province</label>
                            <InputField
                                form={form}
                                name="operating_state"
                                placeholder="Your State/Province"
                                disabled={disabledStep}
                                showIcon
                                hideSuccessHelper
                            />
                        </div>
                        <div className="col-md-3 col-sm-6 mb-7 input-line">
                            <label>ZIP/Postal code</label>
                            <InputField
                                form={form}
                                name="operating_postal_code"
                                placeholder="ZIP/Postal code"
                                disabled={disabledStep}
                                showIcon
                                hideSuccessHelper
                            />
                        </div>
                    </div>
                    <div>
                        <Button
                            size="large"
                            variant="contained"
                            disableElevation
                            disabled={isLoading}
                            onClick={handleBack}
                            className="mr-2"
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            color="primary"
                            disabled={isLoading}
                            style={{ color: '#fff' }}
                        >
                            Next
                        </Button>
                    </div>
                </form>
            </div>
        </>
    );
}

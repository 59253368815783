import * as requestFromServer from './invoicesCrud';
import { invoicesSlice, callTypes } from './invoicesSlice';

const { actions } = invoicesSlice;

export const fetchInvoices = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .findInvoices(queryParams)
        .then((response) => {
            const { count, rows } = response.data.data;
            dispatch(actions.invoicesFetched({ count, rows }));
            return response.data.data
        })
        .catch((error) => {
            error.clientMessage = "Can't find invoices";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

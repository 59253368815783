import { Modal } from 'react-bootstrap';
import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import MemberDetailForm from './MemberDetailForm';
import { ACTION_TYPES, COMPANY_MEMBER_TYPES, MEMBER_TYPES } from 'app/pages/services/constants';
import _, {
    cloneDeep,
    filter,
    find,
    get,
    head,
    isEmpty,
    isEqual,
    last,
    map,
    sum,
    sumBy,
    toNumber,
    size,
} from 'lodash';
import Swal from 'sweetalert2';
import { useKYCUIContext } from 'app/pages/services/order-management/order-form/kyc/KYCUIContext';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { dateTimeFormatter } from '../../utilities/format';
import moment from 'moment';
import {
    getAnswersForTaskOneQuestion,
    getTurnOffAnswers,
} from 'app/pages/services/order-management/order-form/instruction-incorporation/component/step3/nature-of-control/helpers';
import { isPassportExpired } from 'app/helpers/utils';

Yup.addMethod(Yup.mixed, 'checkPassportExpiredDate', function (message = 'Passport expired') {
    return this.test('check_passport_expired_date', message, function (value) {
        const { path, createError } = this;
        if (isPassportExpired(value)) {
            return createError({ path, message });
        }
        return true;
    });
});

const MemberDetailModal = ({
    allowNewMember,
    allowGetChangeRequest,
    onSubmit,
    allowShowRepresent,
    page = 'company',
    uploadDisabled = () => {},
    ...props
}) => {
    const { modal, closeModal } = useKYCUIContext();
    const {
        company = {},
        disabled,
        IS_UK_LLP,
        IS_LLC,
        isSVG,
        isPanama,
        isUK,
        orderInfo,
        hasShowSelectFullName,
    } = props;
    const formRef = useRef(null);
    const [isShowNote, setIsShowNote] = useState(false);
    const [isPercent, setIsPercent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isInValid, setIsInValid] = useState(false);
    const flagNewMember = useRef(false);
    const flagOldMember = useRef(false);

    /* --- OPTION --- */
    const options = useMemo(() => {
        if (!company) return { member: {}, position: {} };
        flagNewMember.current = false;
        flagOldMember.current = false;

        const member = { ...cloneDeep(modal.data), allowShowRepresent };
        const isCorporationChild = !!member.corporation_company_member_id;
        const originPosition = cloneDeep(find(member.CompanyPositions, ['id', member.pos_id]));
        let position = find(member.CompanyPositions, ['id', member.pos_id]);
        let appointedRequest = {};

        if (orderInfo) {
            const editChangeRequests = orderInfo?.Task?.ChangeRequests?.filter(
                (item) => item?.change_request_status_id === 2,
            );

            orderInfo.Company.CompanyMembers.forEach((item) => {
                item.CompanyPositions.forEach((p) => {
                    if (
                        !p.corporation_company_member_id &&
                        p.company_member_type_id === originPosition?.company_member_type_id &&
                        !p.is_approved &&
                        p.is_appointed
                    ) {
                        flagNewMember.current = true;
                    }
                });
            });
            editChangeRequests.forEach((request) => {
                if (
                    !request?.CompanyPosition?.corporation_company_member_id &&
                    request?.CompanyPosition?.company_member_type_id ===
                        position?.company_member_type_id &&
                    request?.ChangeRequestItems?.some(
                        (rc) => rc.field_name === 'is_appointed' && rc.value,
                    )
                ) {
                    appointedRequest = request;
                    flagOldMember.current = true;
                }
            });

            if (flagNewMember.current) {
                if (originPosition?.is_approved) {
                    position.disabled_appointed = 1;
                    position.is_appointed = 0;
                }
            }
            if (flagOldMember.current) {
                if (originPosition?.is_approved && originPosition?.is_appointed) {
                    position.is_appointed = 0;
                    position.disabled_appointed = 1;
                }
                if (
                    originPosition?.is_approved &&
                    !originPosition?.is_appointed &&
                    originPosition?.id === appointedRequest?.company_position_id
                ) {
                    position.is_appointed = 1;
                }
            }
        }
        return {
            member,
            position,
            isCorporationChild,
            ...(position && {
                isUBO: position.company_member_type_id === COMPANY_MEMBER_TYPES.UBO.id,
                isShareholder:
                    position.company_member_type_id === COMPANY_MEMBER_TYPES.SHAREHOLDER.id,
                isDirector: position.company_member_type_id === COMPANY_MEMBER_TYPES.DIRECTOR.id,
                isContactPerson:
                    position.company_member_type_id === COMPANY_MEMBER_TYPES.CONTACT_PERSON.id,
            }),
        };
    }, [modal.data, company]);

    const { member, position } = options;
    const isDisabled = typeof disabled === 'function' ? !!disabled(member) : disabled;

    const allowDisable = (member) => {
        const filterPosition = member?.CompanyPositions.find((item) => item?.id === member?.pos_id);
        const remainingPosition = member?.CompanyPositions?.filter(
            (item) => item.id !== filterPosition?.id,
        );

        return remainingPosition.every((item) => item.created_time < filterPosition.created_time);
    };

    /* --- LIST CHANGE REQUESTS --- */
    const changeRequests = useMemo(() => {
        const result = {
            origin: [],
            Member: [],
            Position: [],
            FieldName: {},
            ChangeRequest: {},
        };

        // Just edit mode
        if (!isDisabled || allowGetChangeRequest) {
            // Danh sách change request có change_request_status_id là 2
            if (company && company.ChangeRequests && !isEmpty(company.ChangeRequests)) {
                const origin = filter(company.ChangeRequests, [
                    'change_request_status_id',
                    member.is_approved ? 2 : 1,
                ]);
                result.origin = origin;
            }

            // Danh sách change request của member đó
            if (result.origin && !isEmpty(result.origin) && member) {
                const Member = filter(result.origin, ['company_member_id', member.id]);
                result.Member = Member;
            }

            // Danh sách change request của member tại position
            if (result.Member && !isEmpty(result.Member) && position) {
                const Position = filter(result.Member, ['company_position_id', position.id]);
                result.Position = Position;
            }

            // Change request mới nhất được nằm ở cuối mảng của result.Position
            if (result.Position && !isEmpty(result.Position)) {
                result.ChangeRequest = last(result.Position);
                const FieldName = result.ChangeRequest?.ChangeRequestItems.reduce(
                    (result, item) => ({
                        [item.field_name]: item.value,
                        ...result,
                    }),
                    {},
                );
                result.FieldName = FieldName;
            }
        }

        return result;
    }, [company, member]);

    const editChangeRequests = useMemo(() => {
        let result = [];
        if (orderInfo) {
            result = orderInfo.Task.ChangeRequests.filter(
                (item) => item.change_request_status_id === 2,
            );
        }
        return result;
    }, [orderInfo]);

    /* Disable khi chỉnh sửa member cũ */
    const disabledFields = {
        type_member_id: (member) => {
            return (
                !!member.id &&
                (Boolean(member.is_approved) || size(get(member, 'CompanyPositions', [])) > 1)
            );
        },
        full_name: (member) => {
            return (
                !!member.id &&
                (Boolean(member.is_approved) || size(get(member, 'CompanyPositions', [])) > 1)
            );
        },
        first_name: (member) => {
            return (
                !!member.id &&
                (Boolean(member.is_approved) || size(get(member, 'CompanyPositions', [])) > 1)
            );
        },
        middle_name: (member) => {
            return (
                !!member.id &&
                (Boolean(member.is_approved) || size(get(member, 'CompanyPositions', [])) > 1)
            );
        },
        last_name: (member) => {
            return (
                !!member.id &&
                (Boolean(member.is_approved) || size(get(member, 'CompanyPositions', [])) > 1)
            );
        },
        birthday: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        address: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        major: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        country_id: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        service_address: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        phone_country_id: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        phone_number: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        passport: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        passport_issue_date: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        passport_expire_date: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        email: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        corporation_date: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        corporation_name: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        country_id: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        corporation_number: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        block_source_of_fund: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        block_reference: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        block_nature_of_control: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        block_security_question: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },

        operation_address: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        OperationCountries: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        business_activity_detail: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        business_activity_array: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
        nature_business: (member) => {
            const isDisable = allowDisable(member);
            return (
                editChangeRequests.every((item) => item.company_member_id !== member.id) &&
                !!member.id &&
                member.allowShowRepresent &&
                (Boolean(member.is_approved) ||
                    (size(get(member, 'CompanyPositions', [])) > 1 && isDisable))
            );
        },
    };

    /* --- HOOK FORM --- */
    const initialValues = useMemo(() => {
        const changeFields = changeRequests.FieldName;
        const { isUBO, isShareholder, isCorporationChild } = options;

        return {
            /* --- MEMBER INFORMATION --- */
            // phone number/country & email không tính request change
            // Link (Mục Khối Change Officer's): https://docs.google.com/document/d/1CW7Gq1H5kHDMUKXT66JLQmzWPprVRP2T316kYiUAKFI/edit#
            type_member_id: member.type_member_id ?? MEMBER_TYPES.INDIVIDUAL.id,
            birthday: dateTimeFormatter(changeFields?.birthday) ?? member.birthday,
            major: changeFields?.major ?? member.major ?? '',
            phone_number: member.phone_number ?? '',
            phone_country_id: member.phone_country_id,
            passport: changeFields?.passport ?? member.passport ?? '',
            passport_issue_date:
                dateTimeFormatter(changeFields?.passport_issue_date) ?? member.passport_issue_date,
            passport_expire_date:
                dateTimeFormatter(changeFields?.passport_expire_date) ??
                member.passport_expire_date,
            corporation_name: changeFields?.corporation_name ?? member.corporation_name ?? '',
            corporation_number: changeFields?.corporation_number ?? member.corporation_number ?? '',
            corporation_date:
                dateTimeFormatter(changeFields?.corporation_date) ?? member.corporation_date,
            first_name: changeFields?.first_name ?? member.first_name ?? '',
            last_name: changeFields?.last_name ?? member.last_name ?? '',
            middle_name: changeFields?.middle_name ?? member.middle_name ?? '',
            full_name: changeFields?.full_name ?? member.full_name ?? '',
            country_id: Number(changeFields?.country_id) || member.country_id,
            email: member.email ?? '',
            address: changeFields?.address ?? member.address ?? '',
            position_name: member?.CompanyMemberType?.name ?? '',
            date_of_appointment: member?.date_of_appointment ?? '',
            is_appointed: position?.is_appointed ?? false,
            address_country_id: changeFields?.address_country_id ?? member.address_country_id,
            city: changeFields?.city ?? member.city,
            state: changeFields?.state ?? member.state,
            postal_code: changeFields?.postal_code ?? member.postal_code,
            operation_address: changeFields?.operation_address ?? member.operation_address,
            nature_business: member.nature_business,
            business_activity_array: member?.BusinessActivities?.map((item) => item.id) || [],
            OperationCountries:
                member?.CompanyMemberOperationCountries?.map((item) => item.id) || [],
            business_activity_detail: member.business_activity_detail,
            disabled_appointed: position?.disabled_appointed ?? false,
            is_level_1: position?.corporation_company_member_id ? false : true,

            /* --- OPTIONAL WITH MEMBER TYPE --- */
            ...(isUBO && {
                ...(isCorporationChild
                    ? {
                          percent_of_interest:
                              member?.CompanyInterests?.find(
                                  (item) =>
                                      item?.corporation_company_member_id ===
                                      member?.CompanyPositions?.find(
                                          (item) => item.id === member.pos_id,
                                      )?.corporation_company_member_id,
                              )?.value ?? 0,
                      }
                    : {
                          percent_of_interest:
                              sumBy(member?.CompanyInterests, (item) =>
                                  !item?.corporation_company_member_id &&
                                  item?.is_approved &&
                                  item?.is_valid
                                      ? item?.value
                                      : 0,
                              ) ?? 0,
                      }),
            }),
            ...(isShareholder && {
                ...(isCorporationChild
                    ? {
                          own_percent:
                              member?.CompanyOwnerships?.find(
                                  (item) =>
                                      item?.corporation_company_member_id ===
                                      member?.CompanyPositions?.find(
                                          (item) => item.id === member.pos_id,
                                      )?.corporation_company_member_id,
                              )?.value ?? 0,
                          number_allotted:
                              member?.CompanyShares?.find(
                                  (item) =>
                                      item?.corporation_company_member_id ===
                                      member?.CompanyPositions?.find(
                                          (item) => item.id === member.pos_id,
                                      )?.corporation_company_member_id,
                              )?.value ?? 0,
                      }
                    : {
                          ...(IS_LLC
                              ? {
                                    own_percent:
                                        sumBy(member?.CompanyOwnerships, (item) =>
                                            !item?.corporation_company_member_id &&
                                            item?.is_approved &&
                                            item?.is_valid
                                                ? item?.value
                                                : 0,
                                        ) ?? 0,
                                }
                              : {
                                    number_allotted:
                                        sumBy(member?.CompanyShares, (item) =>
                                            !item?.corporation_company_member_id &&
                                            item?.is_approved &&
                                            item?.is_valid
                                                ? item?.value
                                                : 0,
                                        ) ?? 0,
                                }),
                      }),
            }),

            /* --- OTHER INFORMATION --- */
            members: [
                {
                    /* --- SOURCE FOR FUNDS --- */
                    ...(isSVG && {
                        cash_in_bank_asset_1: member.asset_source_fund_cast_in_bank,
                        total_asset: sum(
                            map(
                                [
                                    member.asset_source_fund_cast_in_bank,
                                    member.asset_source_fund_real_estate,
                                    member.asset_source_fund_other_money,
                                ],
                                toNumber,
                            ),
                        ),
                        real_estate: member.asset_source_fund_real_estate,
                        other_asset: member.asset_source_fund_other_detail,
                        other_money: member.asset_source_fund_other_money,
                        showOther: false,

                        investment_income: member.income_source_fund_investment,
                        total_income: sum(
                            map(
                                [
                                    member.income_source_fund_investment,
                                    member.income_source_fund_self_employed,
                                    member.income_source_fund_other_money,
                                ],
                                toNumber,
                            ),
                        ),
                        self_employed: member.income_source_fund_self_employed,
                        other_detail_income: member.income_source_fund_other_detail,
                        other_money_income: member.income_source_fund_other_money,
                        showOtherIncome: false,
                    }),

                    /* --- REFERENCE --- */
                    ...(isPanama && {
                        references:
                            member?.CompanyMemberReferences?.map((item) => {
                                delete item?.created_time;
                                delete item?.updated_time;
                                delete item?.CompanyMemberIssuseReference;
                                return item;
                            }) ?? null,
                        president:
                            member?.CompanyPositions?.find(
                                (position) => position.company_member_type_id === 5,
                            )?.is_appointed === 1,
                        secretary:
                            member?.CompanyPositions?.find(
                                (position) => position.company_member_type_id === 4,
                            )?.is_appointed === 1,
                        treasurer:
                            member?.CompanyPositions?.find(
                                (position) => position.company_member_type_id === 6,
                            )?.is_appointed === 1,
                        authorized:
                            member?.CompanyPositions?.find(
                                (position) => position.company_member_type_id === 3,
                            )?.is_appointed === 1,
                    }),

                    /* --- SECURITY QUESTION --- */
                    id: member.id,
                },
            ],
        };
    }, [modal]);

    const validationSchema = useMemo(() => {
        const { isDirector, isShareholder, isUBO } = options;
        return Yup.object()
            .shape({
                /* --- MEMBER INFORMATION --- */
                // Individual
                full_name: Yup.string()
                    .nullable()
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.INDIVIDUAL.id,
                        then: Yup.string()
                            .nullable()
                            .required(
                                hasShowSelectFullName
                                    ? 'Please select your full name!'
                                    : 'Full name is required!',
                            ),
                    }),
                first_name:
                    hasShowSelectFullName &&
                    Yup.string()
                        .nullable()
                        .when(['type_member_id'], {
                            is: (value) => value === MEMBER_TYPES.INDIVIDUAL.id,
                            then: Yup.string().required('First name is required'),
                        }),
                middle_name: hasShowSelectFullName && Yup.string().nullable(),
                last_name:
                    hasShowSelectFullName &&
                    Yup.string()
                        .nullable()
                        .when(['type_member_id'], {
                            is: (value) => value === MEMBER_TYPES.INDIVIDUAL.id,
                            then: Yup.string().required('Last name is required'),
                        }),
                birthday: Yup.mixed()
                    .nullable()
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.INDIVIDUAL.id,
                        then: Yup.date().nullable().required('Birthday is required'),
                    }),

                major: Yup.string()
                    .nullable()
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.INDIVIDUAL.id,
                        then: Yup.string().nullable().required('Major is required'),
                    }),
                phone_number: Yup.string()
                    .nullable()
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.INDIVIDUAL.id,
                        then: Yup.string()
                            .nullable()
                            .required('Phone number is required')
                            .matches(/[0-9]/, 'Phone number not valid!'),
                    }),
                phone_country_id: Yup.number()
                    .nullable()
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.INDIVIDUAL.id,
                        then: Yup.number().nullable().required(''),
                    }),
                passport: Yup.string()
                    .nullable()
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.INDIVIDUAL.id,
                        then: Yup.string().nullable().required('Passport is required'),
                    }),
                passport_issue_date: Yup.mixed()
                    .nullable()
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.INDIVIDUAL.id,
                        then: Yup.date().nullable().required('Passport issuance date is required'),
                    }),
                passport_expire_date: Yup.mixed()
                    .nullable()
                    .when(
                        ['passport_issue_date', 'type_member_id'],
                        (passport_issue_date, type_member_id) => {
                            if (type_member_id !== 1 || !passport_issue_date) return Yup.mixed();
                            return Yup.date()
                                .nullable()
                                .checkPassportExpiredDate('Passport expired')
                                .min(
                                    Yup.ref('passport_issue_date'),
                                    'Passport expired date must be greater than Passport issue date',
                                )
                                .required('Please enter Date of expire passport')
                                .typeError('Invalid date');
                        },
                    ),
                date_of_appointment: Yup.mixed()
                    .nullable()
                    .when(['is_level_1'], {
                        is: false,
                        then: Yup.date().nullable().required('Date of appointment is required'),
                    }),

                // Organization
                corporation_name: Yup.string()
                    .nullable()
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.CORPORATION.id,
                        then: Yup.string().nullable().required('Corporation name is required'),
                    }),
                corporation_number: Yup.string()
                    .nullable()
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.CORPORATION.id,
                        then: Yup.string()
                            .nullable()
                            .required('Corporation number is required')
                            .matches(/[0-9]/, 'Phone number not valid!'),
                    }),
                corporation_date: Yup.mixed()
                    .nullable()
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.CORPORATION.id,
                        then: Yup.date().nullable().required('Corporation date is required'),
                    }),
                business_activity_array: Yup.array()
                    .nullable()
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.CORPORATION.id,
                        then: Yup.array()
                            .nullable()
                            .min(1, 'Business activities is required!')
                            .required('Business activities is required!'),
                    }),
                business_activity_detail: Yup.string()
                    .nullable()
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.CORPORATION.id,
                        then: Yup.string()
                            .nullable()
                            .when('business_activity_detail', (value, schema) => {
                                return Yup.string()
                                    .nullable()
                                    .test(
                                        'required',
                                        'Please write a complete description of your business activities at least 10 words',
                                        (val) => val && val.trim().split(/\s+/).length >= 10,
                                    );
                            }),
                    }),
                OperationCountries: Yup.array()
                    .nullable()
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.CORPORATION.id,
                        then: Yup.array()
                            .nullable()
                            .min(1, 'Countries operation is required!')
                            .required('Countries operation is required!'),
                    }),

                // Share
                type_member_id: Yup.number().nullable(),
                country_id: Yup.number().nullable().required('Country is required'),
                email: Yup.string()
                    .nullable()
                    .email('Invalid email!')
                    .required('Email is required'),
                // address: Yup.string().nullable().required('Residential address is required'),
                address: Yup.string()
                    .nullable()
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.CORPORATION.id,
                        then: Yup.string().nullable().required('Registered address is required'),
                    })
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.INDIVIDUAL.id,
                        then: Yup.string().nullable().required('Residential address is required'),
                    }),
                // service_address: Yup.string().nullable().required('Service address is required'),

                /* --- OTHER INFORMATION --- */
                members: Yup.array().of(
                    Yup.object().shape({
                        /* --- SOURCE FOR FUNDS --- */
                        ...(isSVG && {
                            total_asset: Yup.number().nullable().required(),
                            other_asset: Yup.string()
                                .nullable()
                                .required("Please provide other asset's name."),
                            other_money: Yup.number().nullable().required(),

                            total_income: Yup.number().nullable().required(),
                            other_detail_income: Yup.string()
                                .nullable()
                                .required("Please provide other income's name"),
                            other_money_income: Yup.number().nullable().required(),
                        }),

                        /* --- REFERENCE --- */
                        ...(isPanama && {
                            references: Yup.array().of(
                                Yup.object().shape({
                                    name: Yup.string().nullable().required('Name is required'),
                                    phone_number: Yup.string()
                                        .nullable()
                                        .required('Phone number is required'),
                                    phone_country_id: Yup.number()
                                        .nullable()
                                        .required('Phone country is required'),
                                    fax: Yup.string().nullable(),
                                    email: Yup.string().nullable().required('Email is required'),
                                    address: Yup.string()
                                        .nullable()
                                        .required('Address is required'),
                                    company_member_issuse_reference_id: Yup.number()
                                        .nullable()
                                        .required('Company member issuse reference is required'),
                                }),
                            ),
                        }),

                        /* --- SECURITY QUESTION --- */
                        ...(isUK &&
                            ((isUBO && IS_UK_LLP) ||
                                (isShareholder && {
                                    security_question_1: Yup.number().required(
                                        'Security question 1 is required',
                                    ),
                                    security_question_2: Yup.number()
                                        .nullable()
                                        .required('Security question 2 is required'),
                                    security_question_3: Yup.number()
                                        .nullable()
                                        .required('Security question 3 is required'),
                                    answer_question_1: Yup.string()
                                        .nullable()
                                        .required('Answer 1 is required'),
                                    answer_question_2: Yup.string()
                                        .nullable()
                                        .required('Answer 2 is required'),
                                    answer_question_3: Yup.string()
                                        .nullable()
                                        .required('Answer 3 is required'),
                                }))),

                        /* --- NATURE OF CONTROL --- */
                        ...(isUBO &&
                            isUK && {
                                nature_ownership: Yup.string().nullable(),
                                nature_voting_rights: Yup.string().nullable(),
                                nature_appoint_majority: Yup.string().nullable(),
                                nature_other_significant_control: Yup.string().nullable(),
                                firm: Yup.string().nullable(),
                                firm_ownership: Yup.string().nullable(),
                                firm_voting_rights: Yup.string().nullable(),
                                firm_appoint_majority: Yup.string().nullable(),
                                firm_other_significant_control: Yup.string().nullable(),
                                trust: Yup.string().nullable(),
                                trust_ownership: Yup.string().nullable(),
                                trust_voting_rights: Yup.string().nullable(),
                                trust_appoint_majority: Yup.string().nullable(),
                                trust_other_significant_control: Yup.string().nullable(),
                            }),
                    }),
                ),
            })
            .required();
    }, [modal]);

    const form = useForm({
        resolver: yupResolver(validationSchema),
    });

    /* --- QUESTION FOR UK --- */
    const Questions = useMemo(() => {
        try {
            let Questions = {};
            const questions =
                page === 'company'
                    ? get(company, 'Orders[0].Task.IncorporationCountry.Questions')
                    : get(orderInfo, 'Task.IncorporationCountry.Questions');
            if (questions) {
                questions.map((item) => {
                    switch (item.id) {
                        case 20:
                            Questions = { ...Questions, nature_ownership: item };
                            break;
                        case 21:
                            Questions = { ...Questions, nature_voting_rights: item };
                            break;
                        case 22:
                            Questions = { ...Questions, nature_appoint_majority: item };
                            break;
                        case 23:
                            Questions = { ...Questions, nature_other_significant_control: item };
                            break;
                        case 24:
                            Questions = { ...Questions, firm: item };
                            break;
                        case 25:
                            Questions = { ...Questions, trust: item };
                            break;
                        case 26:
                            Questions = { ...Questions, firm_ownership: item };
                            break;
                        case 27:
                            Questions = { ...Questions, firm_voting_rights: item };
                            break;
                        case 28:
                            Questions = { ...Questions, firm_appoint_majority: item };
                            break;
                        case 29:
                            Questions = { ...Questions, firm_other_significant_control: item };
                            break;
                        case 30:
                            Questions = { ...Questions, trust_ownership: item };
                            break;
                        case 31:
                            Questions = { ...Questions, trust_voting_rights: item };
                            break;
                        case 32:
                            Questions = { ...Questions, trust_appoint_majority: item };
                            break;
                        case 33:
                            Questions = { ...Questions, trust_other_significant_control: item };
                            break;
                    }
                });
            }
            return Questions;
        } catch (error) {
            console.log(error);
        }
    }, []);

    /* --- TITLE MODAL --- */
    const title = useMemo(() => {
        const { isDirector, isShareholder, isUBO, isContactPerson } = options;
        const is_LLC_LLP = IS_LLC || IS_UK_LLP;

        switch (Boolean(isDisabled)) {
            case true: {
                if (isDirector) return is_LLC_LLP ? 'View Manager' : 'View Director';
                if (isShareholder) return is_LLC_LLP ? 'View Member' : 'View Shareholder';
                if (isUBO) return 'View UBO';
                if (isContactPerson) return 'View Contact Person';
                return 'View';
            }
            case false: {
                if (isDirector) return is_LLC_LLP ? 'Edit Manager' : 'Edit Director';
                if (isShareholder) return is_LLC_LLP ? 'Edit Member' : 'Edit Shareholder';
                if (isUBO) return 'Edit UBO';
                if (isContactPerson) return 'Edit Contact Person';
                return 'Edit';
            }
            default: {
                return 'Detail';
            }
        }
    }, [options]);

    const excludedFields = ['email', 'phone_country_id', 'phone_number']; // field with no charge
    const watchedFields = form.watch();
    const filteredWatchedFields = Object.keys(watchedFields).filter(
        (fieldName) => !excludedFields.includes(fieldName),
    );

    /* --- SUMMIT ACTION --- */
    const handleSubmit = async (data) => {
        try {
            setLoading(true);
            let result = {};
            const { uploadFile } = formRef.current;
            let { members, ...values } = data;

            // values.birthday = values?.birthday
            //     ? dateTimeFormatter(values?.birthday, 'YYYY-MM-DD')
            //     : null;
            // values.passport_expire_date = values?.passport_expire_date
            //     ? dateTimeFormatter(values?.passport_expire_date, 'YYYY-MM-DD')
            //     : null;
            // values.passport_issue_date = values?.passport_issue_date
            //     ? dateTimeFormatter(values?.passport_issue_date, 'YYYY-MM-DD')
            //     : null;
            values.date_of_appointment = values?.date_of_appointment
                ? dateTimeFormatter(values?.date_of_appointment, 'YYYY-MM-DD')
                : null;
            values.corporation_date = values?.corporation_date
                ? dateTimeFormatter(values?.corporation_date, 'YYYY-MM-DD')
                : null;

            values = clearInformation(values, values.type_member_id);

            if (uploadFile) {
                const update = uploadFile.getData();
                result.uploadFile = await update(member.id);
            }
            values = getRequestChanged(values, member);
            const { isDirector, isUBO, isShareholder } = options;
            const isSubmitRepresented =
                form.watch('is_appointed') != initialValues['is_appointed'] &&
                allowShowRepresent &&
                !member.corporation_company_member_id &&
                ((isDirector &&
                    orderInfo?.represented_type_ids?.includes(COMPANY_MEMBER_TYPES.DIRECTOR.id)) ||
                    (isUBO &&
                        orderInfo?.represented_type_ids?.includes(COMPANY_MEMBER_TYPES.UBO.id)));
            if (isSubmitRepresented) {
                values.CompanyPositions = [
                    {
                        company_member_type_id: position.company_member_type_id,
                        // for corporation
                        ...(member.corporation_company_member_id && {
                            corporation_company_member_id: member.corporation_company_member_id,
                        }),
                        is_appointed: Number(values.is_appointed),
                        company_member_id: member.id,
                        id: position.id,
                    },
                ];
            }
            if (isPanama) {
                values.CompanyPositions = [];
                if (members?.[0]?.president) {
                    values.CompanyPositions.push({
                        company_member_type_id: 1,
                        is_appointed: 1,
                        id: position.id,
                        company_member_id: member.id,
                    });
                    values.CompanyPositions.push({
                        company_member_type_id: 5,
                        is_appointed: 1,
                        id: member?.CompanyPositions?.find(
                            (position) =>
                                position.company_member_type_id === 5 &&
                                !position.corporation_company_member_id,
                        )?.id,
                        company_member_id: member.id,
                    });
                }
                if (members?.[0]?.secretary) {
                    values.CompanyPositions.push({
                        company_member_type_id: 4,
                        is_appointed: 1,
                        id: member?.CompanyPositions?.find(
                            (position) =>
                                position.company_member_type_id === 4 &&
                                !position.corporation_company_member_id,
                        )?.id,
                        company_member_id: member.id,
                    });
                }
                if (members?.[0]?.treasurer) {
                    values.CompanyPositions.push({
                        company_member_type_id: 6,
                        is_appointed: 1,
                        id: member?.CompanyPositions?.find(
                            (position) =>
                                position.company_member_type_id === 6 &&
                                !position.corporation_company_member_id,
                        )?.id,
                        company_member_id: member.id,
                    });
                }
                if (members?.[0]?.authorized) {
                    values.CompanyPositions.push({
                        company_member_type_id: 3,
                        is_appointed: 1,
                        id: member?.CompanyPositions?.find(
                            (position) =>
                                position.company_member_type_id === 3 &&
                                !position.corporation_company_member_id,
                        )?.id,
                        company_member_id: member.id,
                    });
                }
            }
            delete values.is_appointed;

            result.information = values;
            const value = head(members);
            if (isSVG) {
                const {
                    cash_in_bank_asset_1,
                    real_estate,
                    other_asset,
                    other_money,
                    investment_income,
                    self_employed,
                    other_detail_income,
                    other_money_income,
                } = value;
                result.sourceForFunds = {
                    asset_source_fund_cast_in_bank: cash_in_bank_asset_1,
                    asset_source_fund_real_estate: real_estate,
                    asset_source_fund_other_detail: other_asset,
                    asset_source_fund_other_money: other_money,
                    income_source_fund_investment: investment_income,
                    income_source_fund_self_employed: self_employed,
                    income_source_fund_other_detail: other_detail_income,
                    income_source_fund_other_money: other_money_income,
                };
            }
            if (isPanama) {
                const { references } = value;
                result.references = references;
            }

            if (isUK) {
                const {
                    security_question_1,
                    security_question_2,
                    security_question_3,
                    answer_question_1,
                    answer_question_2,
                    answer_question_3,
                    ...nature
                } = value;

                let answersForTask = [];
                let question1;
                let question2;
                let question3;
                question1 = {
                    option_id: security_question_1,
                    value_text: answer_question_1,
                    is_chosen: 1,
                };
                question2 = {
                    option_id: security_question_2,
                    value_text: answer_question_2,
                    is_chosen: 1,
                };
                question3 = {
                    option_id: security_question_3,
                    value_text: answer_question_3,
                    is_chosen: 1,
                };

                answersForTask.push(question1, question2, question3);

                const questionNameNatures = [
                    'nature_ownership',
                    'nature_voting_rights',
                    'nature_appoint_majority',
                    'nature_other_significant_control',
                ];
                const questionNameFirms = [
                    'firm_ownership',
                    'firm_voting_rights',
                    'firm_appoint_majority',
                    'firm_other_significant_control',
                ];
                const questionNameTrusts = [
                    'trust_ownership',
                    'trust_voting_rights',
                    'trust_appoint_majority',
                    'trust_other_significant_control',
                ];
                // question name: firm, trust.

                const answerShowFirmTrust = {
                    nature_ownership: 47,
                    nature_voting_rights: 51,
                    nature_appoint_majority: 56,
                    nature_other_significant_control: 58,
                };
                let hasFirmTrust = true;

                questionNameNatures.forEach((questionName) => {
                    let arrAnswer = getAnswersForTaskOneQuestion(
                        { Questions },
                        nature,
                        questionName,
                    );
                    answersForTask = answersForTask.concat(arrAnswer);

                    if (nature[questionName] !== answerShowFirmTrust[questionName]) {
                        hasFirmTrust = false;
                    }
                });

                if (hasFirmTrust) {
                    let arrAnswer_firm = getAnswersForTaskOneQuestion(
                        { Questions },
                        nature,
                        'firm',
                    );
                    let arrAnswer_trust = getAnswersForTaskOneQuestion(
                        { Questions },
                        nature,
                        'trust',
                    );
                    answersForTask = answersForTask.concat(arrAnswer_firm, arrAnswer_trust);

                    if (nature.firm === 59) {
                        // firm: 59-Yes
                        questionNameFirms.forEach((questionName) => {
                            let arrAnswer = getAnswersForTaskOneQuestion(
                                { Questions },
                                nature,
                                questionName,
                            );
                            answersForTask = answersForTask.concat(arrAnswer);
                        });
                    }

                    if (nature.trust === 61) {
                        // trust: 61-Yes
                        questionNameTrusts.forEach((questionName) => {
                            let arrAnswer = getAnswersForTaskOneQuestion(
                                { Questions },
                                nature,
                                questionName,
                            );
                            answersForTask = answersForTask.concat(arrAnswer);
                        });
                    }
                } else {
                    let arrTurnOffAnswer_firm = getTurnOffAnswers({ Questions }, nature, 'firm');
                    let arrTurnOffAnswer_trust = getTurnOffAnswers({ Questions }, nature, 'trust');
                    answersForTask = answersForTask.concat(
                        arrTurnOffAnswer_firm,
                        arrTurnOffAnswer_trust,
                    );

                    questionNameFirms.forEach((questionName) => {
                        let arrTurnOffAnswer = getTurnOffAnswers(
                            { Questions },
                            nature,
                            questionName,
                        );
                        answersForTask = answersForTask.concat(arrTurnOffAnswer);
                    });

                    questionNameTrusts.forEach((questionName) => {
                        let arrTurnOffAnswer = getTurnOffAnswers(
                            { Questions },
                            nature,
                            questionName,
                        );
                        answersForTask = answersForTask.concat(arrTurnOffAnswer);
                    });
                }

                const questions =
                    page === 'company'
                        ? get(company, 'Orders[0].Task.IncorporationCountry.Questions')
                        : get(orderInfo, 'Task.IncorporationCountry.Questions');

                // clear old selection if has
                let oldCham = questions
                    .find((q) => q.id === 17)
                    .Options.filter((op) => {
                        return op.Answers.find((ans) => ans.company_member_id === member.id);
                    });
                oldCham.forEach((selectedOpt) => {
                    if (selectedOpt.id !== question1.option_id) {
                        let turnOff = {
                            option_id: selectedOpt.id,
                            is_chosen: 0,
                        };
                        answersForTask.push(turnOff);
                    }
                });
                let oldCham2 = questions
                    .find((q) => q.id === 18)
                    .Options.filter((op) => {
                        return op.Answers.find((ans) => ans.company_member_id === member.id);
                    });
                oldCham2.forEach((selectedOpt) => {
                    if (selectedOpt.id !== question2.option_id) {
                        let turnOff = {
                            option_id: selectedOpt.id,
                            is_chosen: 0,
                        };
                        answersForTask.push(turnOff);
                    }
                });
                let oldCham3 = questions
                    .find((q) => q.id === 19)
                    .Options.filter((op) => {
                        return op.Answers.find((ans) => ans.company_member_id === member.id);
                    });
                oldCham3.forEach((selectedOpt) => {
                    if (selectedOpt.id !== question3.option_id) {
                        let turnOff = {
                            option_id: selectedOpt.id,
                            is_chosen: 0,
                        };
                        answersForTask.push(turnOff);
                    }
                });

                result.answersForTask = { answersForTask };
            }

            if (onSubmit) {
                if (result.answersForTask) {
                    result.answersForTask.answersForTask =
                        result.answersForTask.answersForTask.filter((item) => item.option_id);
                }
                if (isEmpty(changeRequests.Member)) {
                    await onSubmit([
                        {
                            payload: {
                                company_member_id: member.id,
                                company_id: company.id,
                                ...result.information,
                                ...result.sourceForFunds,
                                ...(isPanama &&
                                    member.is_approved !== 1 && {
                                        CompanyMemberReferences: [...result.references],
                                    }),
                                ...(!isDirector && { ...result.answersForTask }),
                                ...(member.level !== 1 && {
                                    CompanyPositions: [
                                        {
                                            id: member.pos_id,
                                            company_member_type_id: member.company_member_type_id,
                                            date_of_appointment: data.date_of_appointment,
                                        },
                                    ],
                                }),
                                task_id: orderInfo?.Task?.id,
                            },
                        },
                    ]);
                } else {
                    const rs = [];
                    changeRequests.Member.forEach((cr) => {
                        rs.push({
                            payload: {
                                company_member_id: member.id,
                                company_id: company.id,
                                ...result.information,

                                ...(changeRequests &&
                                    changeRequests.ChangeRequest.id === cr.id && {
                                        ...result.sourceForFunds,
                                        ...(isPanama &&
                                            member.is_approved !== 1 && {
                                                CompanyMemberReferences: [...result.references],
                                            }),
                                        ...(!isDirector && { ...result.answersForTask }),
                                    }),
                                ...(member.level !== 1 && {
                                    CompanyPositions: [
                                        {
                                            id: member.pos_id,
                                            company_member_type_id: member.company_member_type_id,
                                            date_of_appointment: data.date_of_appointment,
                                        },
                                    ],
                                }),
                                ...(member.level !== 1 &&
                                    cr?.CompanyPosition?.company_member_type_id ===
                                        COMPANY_MEMBER_TYPES.SHAREHOLDER.id &&
                                    isShareholder && {
                                        ...(isPercent
                                            ? {
                                                  CompanyOwnership: {
                                                      company_id: company?.id,
                                                      corporation_company_member_id:
                                                          member?.corporation_company_member_id,
                                                      value: Number(data?.own_percent),
                                                  },
                                                  ...(member?.CompanyShares?.find(
                                                      (item) =>
                                                          item?.corporation_company_member_id ===
                                                          member?.corporation_company_member_id,
                                                  )?.id && {
                                                      ids_company_share: [
                                                          member?.CompanyShares?.find(
                                                              (item) =>
                                                                  item?.corporation_company_member_id ===
                                                                  member?.corporation_company_member_id,
                                                          )?.id,
                                                      ],
                                                  }),
                                              }
                                            : {
                                                  CompanyShare: {
                                                      company_id: company?.id,
                                                      corporation_company_member_id:
                                                          member?.corporation_company_member_id,
                                                      value: Number(data?.number_allotted),
                                                  },
                                                  ...(member?.CompanyOwnerships?.find(
                                                      (item) =>
                                                          item?.corporation_company_member_id ===
                                                          member?.corporation_company_member_id,
                                                  )?.id && {
                                                      ids_company_ownership: [
                                                          member?.CompanyOwnerships?.find(
                                                              (item) =>
                                                                  item?.corporation_company_member_id ===
                                                                  member?.corporation_company_member_id,
                                                          )?.id,
                                                      ],
                                                  }),
                                              }),
                                    }),
                                task_id: orderInfo?.Task?.id,
                            },
                            changeRequestId: cr.id,
                        });
                    });
                    await onSubmit(rs);
                }
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Somethings went wrong!',
                showConfirmButton: false,
                timer: 1500,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        form.reset(initialValues);
        setIsPercent(!!form.watch('own_percent'));
    }, [initialValues]);

    useEffect(() => {
        if (modal.show) {
            let isChange = true;
            filteredWatchedFields.forEach((item) => {
                if (!isEqual(form.watch(item), initialValues[item])) {
                    setIsShowNote(true);
                }
            });
            if (
                filteredWatchedFields.every((item) =>
                    isEqual(form.watch(item), initialValues[item]),
                )
            ) {
                isChange = false;
            }
            if (!isChange) {
                setIsShowNote(false);
            }
        }
    }, [form.watch()]);

    useEffect(() => {
        if (!modal.show) {
            setIsShowNote(false);
        }
    }, [modal.show]);

    if (isEmpty(company) || isEmpty(member) || isEmpty(position)) return null;

    return (
        <Modal
            show={
                [ACTION_TYPES.VIEW.name, ACTION_TYPES.EDIT.name, ACTION_TYPES.UPLOAD.name].includes(
                    member.action,
                ) &&
                (member.is_approved || allowNewMember) &&
                (member.hasOwnProperty('is_resigned') ? member.is_resigned === false : true) &&
                modal.show
            }
            backdrop="static"
            size="xl"
            animation={false}
            centered
        >
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <MemberDetailForm
                        ref={formRef}
                        disabledFields={disabledFields}
                        changeFields={changeRequests.FieldName}
                        Questions={Questions}
                        {...options}
                        {...props}
                        disabled={isDisabled || loading}
                        form={form}
                        isViewMode={isDisabled}
                        page={page}
                        hasShowSelectFullName={hasShowSelectFullName}
                        allowShowRepresent={allowShowRepresent}
                        isPercent={isPercent}
                        setIsPercent={setIsPercent}
                        setIsInValid={setIsInValid}
                        uploadDisabled={uploadDisabled}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {loading && <CircularProgress size={20} color="primary" />}
                    <Button
                        type="button"
                        style={{ marginLeft: 15 }}
                        className="btn btn-light btn-sm"
                        onClick={closeModal}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    {!isDisabled && (
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ color: '#fff', marginLeft: 15 }}
                            disabled={loading || isInValid}
                            type="submit"
                        >
                            Save
                        </Button>
                    )}
                </Modal.Footer>
                {!isDisabled && page == 'company' && isShowNote && (
                    <p
                        className="mb-0 text-white p-2"
                        style={{
                            backgroundColor: '#17c191',
                            borderBottomLeftRadius: '0.42rem',
                            borderBottomRightRadius: '0.42rem',
                        }}
                    >
                        <b>
                            The changes to this information may incur additional costs, please
                            review the information in the Review and Complete section after making
                            the adjustments.
                        </b>
                    </p>
                )}
            </form>
        </Modal>
    );
};

export default MemberDetailModal;

const clearInformation = (data, type_member_id) => {
    if (!type_member_id) return null;
    if (data) {
        data = cloneDeep(data);
        delete data.position_name;
        delete data.date_of_appointment;
        delete data.percent_of_interest;
        delete data.own_percent;
        delete data.number_allotted;
        delete data.disabled_appointed;
        delete data.is_level_1;

        switch (type_member_id) {
            case MEMBER_TYPES.INDIVIDUAL.id: {
                delete data.corporation_name;
                delete data.corporation_number;
                delete data.corporation_date;
                delete data.OperationCountries;
                delete data.business_activity_array;
                break;
            }
            case MEMBER_TYPES.CORPORATION.id: {
                delete data.first_name;
                delete data.birthday;
                delete data.major;
                delete data.passport;
                delete data.date_of_appointment;
                delete data.passport_issue_date;
                delete data.passport_expire_date;
                break;
            }
            default:
                break;
        }

        return data;
    }
    return null;
};

const getRequestChanged = (newData, oldData) => {
    const result = {};
    for (const [key, value] of Object.entries(newData)) {
        if (
            !isEqual(
                value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm:ss') : value,
                oldData[key],
            )
        ) {
            result[key] = value;
        }
    }
    return result;
};

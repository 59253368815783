import axios from 'axios';

export const SUPPORTCASE_URL = '/customer_account/support_case';
export const MESSAGE_URL = '/customer_account/message';

export function createSupport(support_case) {
    return axios.post(SUPPORTCASE_URL, support_case);
}

export function getAllSupports() {
    return axios.get(SUPPORTCASE_URL);
}

export function getSupportById(supportId) {
    return axios.get(`${SUPPORTCASE_URL}/${supportId}`);
}

export function findSupports(queryParams) {
    return axios.get(`${SUPPORTCASE_URL}`, { params: queryParams });
}

export function updateSupport(support) {
    return axios.put(`${SUPPORTCASE_URL}/${support.id}`, support);
}

export function subjectSupport(id) {
    return axios.get(`${SUPPORTCASE_URL}/caseDetail/${id}`);
}

export function getMessageById(id) {
    return axios.get(`${MESSAGE_URL}`, { params: { id: id } });
}

export function ratingSupport(id) {
    return axios.get(`${SUPPORTCASE_URL}/${id}`);
}

export function sendMessage(body) {
    return axios.post(`${MESSAGE_URL}`, body);
}

export function sendRating(body) {
    return axios.put(`${SUPPORTCASE_URL}/point`, body);
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getBankingDataByOrderId,
    getHoliday,
    updateBankingBusinessPlanForm,
    setBankingID,
    getListBankingAPI,
    getBankingProcessTree, approveProcess, deleteFile, getProcessDetail
} from './api';

//
export const getListBanking = createAsyncThunk(
    'banking/getListBanking',
    async (taskID,thunkAPI) => {
        try {
            const response = await getListBankingAPI(taskID);
            return response.data.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
)


// async action
export const setBankingIdAction = createAsyncThunk(
    'banking/setBankingId',
    async (body, thunkAPI) => {
        try {
            const response = await setBankingID(body);
            return response.data.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
  )

// async action
export const fetchOrderInfo = createAsyncThunk(
    'banking/fetchOrderInfo',
    async (orderId, thunkAPI) => {
        try {
            const response = await getBankingDataByOrderId(orderId);
            return response.data.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
  )
export const getProcessDetailById = createAsyncThunk(
    'banking/getProcessDetailByProcessOrder',
    async (processID, thunkAPI) => {
        try {
            const response = await getProcessDetail(processID);
            return response.data.data
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
)


// async action
export const updateBusinessPlanFormAction = createAsyncThunk(
    'banking/updateBusinessPlanForm',
    async ({id, params}, thunkAPI) => {
        try {
            const response = await updateBankingBusinessPlanForm(id, params);
            return response;
        } catch(err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
  );


export const getHolidayAction = createAsyncThunk(
    'banking/getHolidayAction',
    async (params, thunkAPI) => {
        try {
            const {data: { data: { rows } }} = await getHoliday(params);
            return rows;
        } catch(err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getBankingProcessTreeAction = createAsyncThunk(
    'banking/getBankingProcessTreeAction',
    async (orderId, thunkAPI) => {
        try {
            const {data: { data }} = await getBankingProcessTree(orderId);
            return data;
        } catch(err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const approveProcessAction = createAsyncThunk(
    'banking/approveProcessAction',
    async (body, thunkAPI) => {
        try {
            const {data: { data }} = await approveProcess(body);
            return data;
        } catch(err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const deleteFileAction = createAsyncThunk(
    'banking/deleteFileAction',
    async (fileId, thunkAPI) => {
        try {
            const {data: { data }} = await deleteFile(fileId);
            return data;
        } catch(err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

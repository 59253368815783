export const hkBankList = [5, 6, 7, 8, 9, 10, 11, 25, 26, 31]
export const sgBankList = [17, 18, 19, 20, 22, 27]


export const CayeBank = 1
export const EpbBank = 16
export const CimBank = 23


export const CORPORATE_DOCUMENTS_TEMPLATE_ID = 'CORPORATE_DOCUMENTS_TEMPLATE_ID';
export const CORPORATE_DOCUMENTS = [
    {
        name: 'Company docs',
        id: CORPORATE_DOCUMENTS_TEMPLATE_ID,
        parent_id: 99,
        titles: [
            { file_type_id: 2, file_name: 'Certification & Constitution', file_template_id: CORPORATE_DOCUMENTS_TEMPLATE_ID},
            { file_type_id: 4, file_name: 'Other Documents', file_template_id: CORPORATE_DOCUMENTS_TEMPLATE_ID }
        ]
    }
];

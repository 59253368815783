import ReferenceItem from './ReferenceItem';
import React from 'react';

export default function References({ form, index, disabled = false }) {
    return (
        <div className="reference">
            <hr style={{ border: '1px dashed #ebedf3' }} />
            <div className="fw-500 mt-7 mb-3">Reference 1:</div>
            <ReferenceItem
                key={0}
                form={form}
                memberIndex={index}
                referenceIndex={0}
                disabled={disabled}
            />

            <div className="fw-500 mt-7 mb-3">Reference 2:</div>
            <ReferenceItem
                key={1}
                form={form}
                memberIndex={index}
                referenceIndex={1}
                disabled={disabled}
            />
        </div>
    );
}

/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { lazy, useMemo } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { Layout } from '_metronic/layout';
import BasePage from './BasePage';
import { Logout, AuthPage } from './modules/Auth';
import { ErrorPage } from 'app/pages/ErrorPage';
import { ToastBar } from '_metronic/layout/components/toast-bar/ToastBar';
import DocumentSignature from './pages/services/order-management/order-form/document-controller/DocumentSignature';
import { DashboardPage } from './pages/DashboardPage';
import VerticalLinearStepper from './pages/services/incorporation/component/stepper';
import ExistingCompanyPage from './pages/services/existing-company/existing-company';
import OrderManagementPage from './pages/services/order-management/order-management';
import { NotificationPayment } from './pages/components/payment/notification-payment';
import { NotificationPaymentStripePaypal } from './pages/components/payment/notification-payment-stripe-paypal';
import { HmacSHA256 } from 'crypto-js';

const AccountPage = lazy(() => import('./pages/account/accountPage'));
const CompanyPage = lazy(() => import('./pages/company/companyPage'));

export function AppRoutes() {
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.authToken != null,
        }),
        shallowEqual,
    );

    return (
        <Routes>
            <Route
                path={'/'}
                element={
                    <RequireAuth>
                        <Layout>
                            <BasePage />
                            <ToastBar />
                        </Layout>
                    </RequireAuth>
                }
            >
                {
                    /* Redirect from root URL to /dashboard. */
                    // <Redirect exact from="/" to="/" />
                }
                <Route exact path="/" element={<DashboardPage />} />
                <Route path="/incorporation" element={<VerticalLinearStepper />} />
                <Route path="/existing-company" element={<ExistingCompanyPage />} />
                <Route path="/order-management/*" element={<OrderManagementPage />} />
                <Route path="/account/*" element={<AccountPage />} />
                <Route path="/company/*" element={<CompanyPage />} />
                <Route path="/notification-payment" element={<NotificationPayment />} />
                <Route
                    path="/notification-payment-stripe-paypal"
                    element={<NotificationPaymentStripePaypal />}
                />
                <Route path="/*/reload" element={null} /> {/* for reload page as react */}
                <Route path="*" element={<Navigate to="error" replace />} />
            </Route>
            <Route path="/404" element={<ErrorPage />} />
            <Route path="/file/signature" exact element={<DocumentSignature />} />
            <Route path="/file/signature/*" element={<Navigate to="/404" replace />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/logout" element={<Logout />} />
            <Route path={'/auth/*'} element={<AuthPage />} />
        </Routes>
    );
}

function RequireAuth({ children }) {
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.authToken != null,
        }),
        shallowEqual,
    );
    const location = useLocation();

    if (!isAuthorized) {
        localStorage.setItem('REMEMBER_PATH', location.pathname + (location.search || ''));
        return <Navigate to={`/auth/login`} replace />;
    }

    return children;
}

import { MEMBER_TYPES } from 'app/pages/services/constants';
import { formatCompanyMembersWithAllLevel } from 'app/pages/services/order-management/order-form/instruction-incorporation/component/step3/step3-helper/helpers';
import { find, get } from 'lodash';
import React, { useMemo } from 'react';
import TransferShareTableNotLLC from '../TransferShareTableNotLLC';
import TransferShareTable from '../TransferShareTable';
import FormFill from './FormFill';
import SelectField from 'components/form-controls/SelectField';
import clsx from 'clsx';
import ChooseExistingMember from '../ChooseExistingMember';
import { useFieldArray } from 'react-hook-form';

const MemberAddForm = (props) => {
    const {
        disabled,
        company,
        member,
        isDirector,
        isUBO,
        isShareholder,
        IS_LLC,
        IS_UK_LLP,
        memberTransferOptions,
        form,
        isCorporationChild,
        hasShowSelectFullName,
        companyMemberIdRef = {},
    } = props;

    const companyMemberStructure = useMemo(
        () => ({
            companyStructure: formatCompanyMembersWithAllLevel({ Company: company }),
            isLLC: IS_LLC || IS_UK_LLP,
            ...(isDirector && {
                contactInfo: find(get(company, 'CompanyMembers', []), (member) =>
                    find(get(member, 'CompanyPositions', []), {
                        company_member_type_id: 7,
                        is_contact_person: true,
                    }),
                ),
            }),
        }),
        [company, member],
    );

    const type_member_id = form.watch('type_member_id');

    const bindArrayHelper = {
        form: {
            values: {
                members: company.CompanyMembers.filter((companyMember) =>
                    find(companyMember.CompanyPositions, [
                        'company_member_type_id',
                        member.company_member_type_id,
                    ]),
                ),
            },
        },
        remove: (index) => {},
        insert: (index, value) => {
            const {
                first_name,
                middle_name,
                last_name,
                full_name,
                company_name,
                individual,
                country_id,
                country_of_incorporation,
                id,
            } = value;

            let val = { ...form.getValues(), id };

            if (individual) {
                val = {
                    ...val,
                    first_name: first_name,
                    middle_name: middle_name,
                    last_name: last_name,
                    full_name: full_name,
                    country_id,
                    type_member_id: MEMBER_TYPES.INDIVIDUAL.id,
                };
            } else {
                val = {
                    ...val,
                    corporation_name: company_name,
                    country_id,
                    type_member_id: MEMBER_TYPES.CORPORATION.id,
                };
            }

            form.reset(val);
        },
    };

    const { update } = useFieldArray({
        control: form.control,
        name: 'members',
    });

    const fieldAction = { update };

    return (
        <div className="p-10" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
            <div>
                <h5 className="pl-0 input-line mb-2">Create new member</h5>
                <div className="border rounded py-8 px-10">
                    <ChooseExistingMember
                        index={0}
                        body={companyMemberStructure}
                        bindArrayHelper={bindArrayHelper}
                        companyMemberType={member?.company_member_type_id}
                        stateSwitch={[type_member_id]}
                        setStateSwitch={([value]) => {
                            form.setValue(
                                'type_member_id',
                                value ? MEMBER_TYPES.INDIVIDUAL.id : MEMBER_TYPES.CORPORATION.id,
                            );
                        }}
                        company={company}
                        member={member}
                        fieldAction={fieldAction}
                    />
                    <FormFill
                        {...props}
                        disabled={disabled || !!form.getValues('id')}
                        hasShowSelectFullName={hasShowSelectFullName}
                    />
                </div>
            </div>

            {!isCorporationChild && (
                <>
                    {/* SHAREHOLDER */}
                    {isShareholder && (
                        <>
                            {IS_LLC ? (
                                <div className="mt-10">
                                    <h5 className="pl-0 input-line mb-2">Membership Percent</h5>
                                    <div className="border rounded py-8 px-10">
                                        <TransferShareTable
                                            transferTitle="Transferor"
                                            transferName="company_member_id"
                                            valueTitle="Percent of Membership has been sent"
                                            options={memberTransferOptions}
                                            form={form}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="mt-10">
                                    <h5 className="pl-0 input-line mb-2">Shares</h5>
                                    <div className="border rounded p-5">
                                        <div
                                            className={clsx(
                                                'd-flex align-items-center px-10 py-8',
                                                {
                                                    'mb-5': form.watch('company_member_id'),
                                                },
                                            )}
                                        >
                                            <span className="flex-shrink-0 mr-10">Transferor</span>
                                            <SelectField
                                                label=""
                                                inputProps={(params) => ({
                                                    ...params,
                                                    placeholder: 'Select Transferor',
                                                })}
                                                className="w-100 kyc-frm-control"
                                                name="company_member_id"
                                                form={form}
                                                options={memberTransferOptions || []}
                                                getOptionLabel={(option) =>
                                                    option?.full_name ||
                                                    option?.corporation_name ||
                                                    ''
                                                }
                                                disabled={disabled}
                                                hideSuccessHelper
                                                hideInputHelperText
                                                autoComplete="off"
                                            />
                                        </div>
                                        {form.watch('company_member_id') && (
                                            <TransferShareTableNotLLC
                                                form={form}
                                                transferor={find(get(company, 'CompanyMembers'), [
                                                    'id',
                                                    form.getValues('company_member_id'),
                                                ])}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {/* UBO */}
                    {isUBO && (
                        <div className="mt-10">
                            <h5 className="pl-0 input-line mb-2">Own Percent</h5>
                            <div className="border rounded py-8 px-10">
                                <TransferShareTable
                                    form={form}
                                    transferTitle="Transferor"
                                    transferName="company_member_id"
                                    valueTitle="Percent of Ownership has been sent"
                                    options={memberTransferOptions}
                                    companyMemberIdRef={companyMemberIdRef}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default MemberAddForm;

import React from 'react';
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { isEmpty } from 'lodash';

function RadioField({
    onChange,
    form,
    name,
    label,
    options,
    renderLabel,
    radioGroupProps = {},
    ...props
}) {
    return (
        <Controller
            name={name}
            control={form.control}
            render={({ field, fieldState: { isDirty, invalid, isTouched, error } }) => (
                <FormControl>
                    {label && <FormLabel>{label}</FormLabel>}
                    <RadioGroup {...field} {...radioGroupProps}>
                        {!isEmpty(options) && options.map((value) => {
                            return (
                                <FormControlLabel
                                    key={value.id}
                                    value={value.id}
                                    sx={{
                                        '&.MuiFormControlLabel-root': {
                                            marginBottom: '0',
                                        },
                                    }}
                                    control={
                                        <Radio
                                            color={invalid ? 'error' : 'default'}
                                            {...props}
                                            onChange={(e) => {
                                                field.onChange(e.target.value);
                                                onChange && onChange(e.target.value);
                                            }}
                                            sx={{
                                                '&.MuiButtonBase-root.Mui-checked > span': {
                                                    color: 'rgb(55, 131, 231)',
                                                },
                                                '&.MuiButtonBase-root.Mui-disabled > span': {
                                                    color: 'rgba(0, 0, 0, 0.26)',
                                                },
                                                '&.MuiRadio-colorError + span': {
                                                    color: '#f64e60',
                                                },
                                            }}
                                        />
                                    }
                                    label={
                                        renderLabel ? (
                                            renderLabel(value)
                                        ) : (
                                            <Typography
                                                variant={'span'}
                                                color={invalid ? 'error' : 'default'}
                                            >
                                                {value.name}
                                            </Typography>
                                        )
                                    }
                                />
                            );
                        })}
                    </RadioGroup>
                    <FormHelperText
                        sx={{
                            '&.Mui-error': {
                                color: '#f64e60',
                                fontSize: '0.9rem',
                                marginLeft: '0',
                            },
                        }}
                        error={invalid}
                    >
                        {error?.message || ''}
                    </FormHelperText>
                </FormControl>
            )}
        />
    );
}

export default RadioField;

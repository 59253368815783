import React, { useEffect, useRef, useState } from 'react';

import { Box, Grid, List, Typography } from '@mui/material';
import { putProposedName } from 'app/pages/_redux/companies/companiesCrud';
import { deleteProposedName, submitProposedName } from 'app/pages/_redux/order-form/orderFormCrud';
import { useDispatch, useSelector } from 'react-redux';

import { NameRestricted } from 'app/pages/components/check-name/check-proposed-name';
import { trim } from 'lodash';
import { useForm } from 'react-hook-form';
import './ChangeCompanyName.scss';
import Axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import ReactSelectField from 'components/form-controls/ReactSelectField';
import { fetchCompanyInfo } from 'app/pages/_redux/incorporation/incorporationActions';
const startupNameGenerator = require('@rstacruz/startup-name-generator');

const handleLength = (str, count) => {
    return trim(str)?.length > count;
};

const isNameRestricted = (name) => {
    if (trim(name).length > 2) {
        const hasRestricted = NameRestricted.find((item) => item.id === 191);
        // let isNameRestricted = false;
        if (hasRestricted) {
            const dataRestricted = hasRestricted.NameRestricted.map((item) => item.toLowerCase());
            const inputName = name.trim().toLowerCase();

            const checkNameRestricted = dataRestricted.some(
                (item) =>
                    item === inputName ||
                    inputName.search(` ${item}`) > -1 ||
                    inputName.search(`${item} `) > -1,
            );

            return checkNameRestricted;
        }
    }
};

const ErrorMessage = ({ form, name, errors }) => {
    // if (name) {
    //     const isNameRestricted = handleRestricted(name);

    //     if (isNameRestricted)
    //         return (
    //             <Typography variant="body2" className="text-danger mt-1">
    //                 Your company name contains restricted words or phrases
    //             </Typography>
    //         );
    // }

    return (
        <div>
            {form.formState.errors.proposed_name && (
                <Typography variant="body2" className="text-danger mt-1">
                    {form.formState.errors.proposed_name.message}
                </Typography>
            )}
        </div>
    );
};

const handleCheckName = (name) =>
    new Promise((resolve, reject) => {
        setTimeout(() => {
            Axios.get(`${process.env.REACT_APP_API_URL}/onboarding/check_name/find_company`, {
                params: { key: trim(name) },
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        }, 500);
    });

function ChangeCompanyName({
    companyDetail,
    countryId,
    companyList,
    companyId,
    refreshChangeRequest,
    handleGetProposedNameList,
    page,
}) {
    const dispatch = useDispatch();
    const { orderInfo } = useSelector((state) => state.additionalService);
    const [isShowAddProposedInput, setShowAddProposedInput] = useState(false);
    const [loading, setLoading] = useState(false);
    const [companySuffix, setCompanySuffix] = useState([]);
    const form = useForm({
        defaultValues: {
            company_suffix_id: null,
        },
    });

    useEffect(() => {
        if (!companyDetail?.country_id) return;

        dispatch(fetchCompanyInfo(companyDetail?.country_id), {
            params: {
                package_group_id: 1,
            },
        }).then((data) => {
            if (data) {
                setCompanySuffix(data?.CompanySuffixes);
                form.reset({
                    company_suffix_id: data?.CompanySuffixes?.[0]?.id,
                });
            }
        });
    }, [companyDetail?.country_id]);

    const resetAll = () => {
        form.reset();
        form.clearErrors();
        setSuggestNameList([]);
        setCompanyName(null);
    };

    const sendName = async (data) => {
        if (
            Object.keys(form.formState.errors).length === 0 &&
            !isNameRestricted(companyName) &&
            handleLength(companyName, 2)
        ) {
            let submitValue = {
                name: data.proposed_name,
                company_id: companyId,
                task_id: orderInfo?.Task?.id,
                company_suffix_id: data.company_suffix_id,
            };

            try {
                page === 'company'
                    ? await putProposedName({
                          new_proposed_name: [submitValue],
                      })
                    : await submitProposedName({
                          proposedNames: [submitValue],
                      });
                handleGetProposedNameList();
                refreshChangeRequest();
                setShowAddProposedInput(false);
                resetAll();
            } catch (error) {}
        }
    };

    const onSubmit = async (data) => {
        setSuggestNameList([]);

        const existsMessage = {
            type: 'manual',
            message: 'This company name already exists',
        };

        if (countryId === 237) {
            setLoading(true);
            let waiting = [];
            waiting.push(handleCheckName(data?.proposed_name));

            Promise.all(waiting)
                .then(async (allRes) => {
                    let check = true;
                    allRes.forEach((item, index) => {
                        if (!item.data.data.useable) {
                            check = false;
                            form.setError('proposed_name', existsMessage);
                        }
                    });

                    if (check) {
                        await sendName(data);
                    } else {
                        form.setError('proposed_name', existsMessage);
                    }
                })
                .catch(() => {
                    form.setError('proposed_name', existsMessage);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            await sendName(data);
            setLoading(false);
        }

        setShowAddProposedInput(false);
    };

    const handleDeleteCompanyName = async (id) => {
        try {
            page === 'company'
                ? await putProposedName({
                      delete_proposed_name: [id],
                  })
                : await deleteProposedName({ task_id: orderInfo?.Task?.id }, id);
            handleGetProposedNameList();
            if (companyList.length === 1) {
                refreshChangeRequest();
            }
        } catch (error) {}
    };

    const [suggestNameList, setSuggestNameList] = useState([]);
    const [companyName, setCompanyName] = useState('');

    const handleSuggestName = (name) => {
        const customName = trim(name);

        if (customName?.length > 2) {
            setSuggestNameList(startupNameGenerator(customName).slice(0, 3));
        } else {
            setSuggestNameList([]);
        }
    };

    useEffect(() => {
        if (companyName?.length > 2) {
            handleSuggestName(companyName);
        } else {
            setSuggestNameList([]);
        }
    }, [companyName]);

    const { id } = useParams();
    const navigate = useNavigate();

    const handleOnClickBack = () => {
        navigate({
            pathname: '/company/companies/' + id + '/detail',
        });
    };

    return (
        <>
            {page === 'company' && (
                <div className="text-right">
                    <button
                        type="button"
                        className="btn btn-primary"
                        style={{ marginBottom: 24 }}
                        onClick={handleOnClickBack}
                    >
                        Back
                    </button>
                </div>
            )}

            <Box>
                <Typography>
                    I would like to change my company name to one of the following options
                </Typography>
                <Typography variant="body2">
                    *Please provide multiple names to check for availability
                </Typography>
                <div className="row mt-4" style={{ rowGap: `12px` }}>
                    {companyList &&
                        companyList.map((company, index) => (
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-7">
                                        <Box
                                            display={'flex'}
                                            height={'100%'}
                                            alignItems={'center'}
                                            justifyContent={'space-between'}
                                        >
                                            <Typography>
                                                {company?.CompanySuffix
                                                    ? company?.CompanySuffix?.is_prefix
                                                        ? company?.CompanySuffix?.name +
                                                          ' ' +
                                                          company?.name
                                                        : company?.name +
                                                          ' ' +
                                                          company?.CompanySuffix?.name
                                                    : company?.name}
                                            </Typography>
                                            <Typography
                                                className="mr-6 pl-2"
                                                style={{ minWidth: 'fit-content' }}
                                            >
                                                <span className="check-item rejected">
                                                    {company.is_approve === false && 'Rejected'}
                                                </span>
                                                <span className="check-item unchecked">
                                                    {company.is_confirmed === false &&
                                                        company.is_approve === null &&
                                                        'Unchecked'}
                                                </span>
                                                <span className="check-item approved">
                                                    {company.is_approve === true && 'Approved'}
                                                </span>
                                            </Typography>
                                        </Box>
                                    </div>
                                    <div className="col-3">
                                        <button
                                            className="btn btn-danger"
                                            disabled={
                                                form.formState.isSubmitting ||
                                                company.is_approve === false ||
                                                company.is_approve
                                            }
                                            onClick={() => handleDeleteCompanyName(company.id)}
                                            style={{
                                                minWidth: 'fit-content',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
                {isShowAddProposedInput && (
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <div className="row mt-4">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-md-4">
                                        <input
                                            className={`form-control ${
                                                Object.keys(form.formState.errors).length > 0
                                                    ? 'is-invalid '
                                                    : ''
                                            }`}
                                            placeholder="Proposed name"
                                            {...form.register(`proposed_name`, {
                                                disabled: loading || form.formState.isSubmitting,
                                                onChange: (e) => {
                                                    setCompanyName(e.target.value);
                                                    //   clearTimeout(timer);
                                                },
                                                onBlur: (e) => {
                                                    setTimeout(() => {
                                                        handleSuggestName('');
                                                    }, 300);
                                                },

                                                validate: (value) => {
                                                    const checkLengthWithSpace =
                                                        trim(value).length > 2;
                                                    const checkRestrictedName = isNameRestricted(
                                                        trim(value),
                                                    );

                                                    if (!checkLengthWithSpace)
                                                        return 'Please fill your business name at least 3 characters';
                                                    if (checkRestrictedName)
                                                        return 'Your company name contains restricted words or phrases';

                                                    return true;
                                                },
                                                required: {
                                                    value: true,
                                                    message:
                                                        'Please fill your business name at least 3 characters',
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message:
                                                        'Please fill your business name at least 3 characters',
                                                },
                                                maxLength: {
                                                    value: 128,
                                                    message:
                                                        'Your business name is too long, maximum is 128 characters',
                                                },
                                            })}
                                        />
                                        <ErrorMessage
                                            form={form}
                                            errors={form.formState.errors}
                                            name={companyName}
                                        />
                                        {suggestNameList.length > 2 &&
                                            !isNameRestricted(companyName) && (
                                                <div className="suggest-wrapper ">
                                                    <div>Name hint: </div>
                                                    <div className="suggest-items">
                                                        {suggestNameList.map((item) => (
                                                            <div
                                                                key={item}
                                                                className={'suggest-name'}
                                                                onClick={() => {
                                                                    form.setValue(
                                                                        'proposed_name',
                                                                        item,
                                                                    );
                                                                    setCompanyName(item);
                                                                }}
                                                            >
                                                                {item}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                    <div className="col-md-3">
                                        <ReactSelectField
                                            form={form}
                                            name="company_suffix_id"
                                            placeholder="Company suffix"
                                            options={companySuffix || []}
                                            styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                                            isClearable={false}
                                            hideHelperText
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <button
                                            className="btn btn-primary"
                                            type="submit"
                                            disabled={loading || form.formState.isSubmitting}
                                            style={{
                                                width: '65px',
                                            }}
                                        >
                                            {loading || form.formState.isSubmitting ? (
                                                <>
                                                    <div
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                    ></div>
                                                </>
                                            ) : (
                                                'Add'
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
                <button
                    className="btn btn-secondary mt-4"
                    onClick={() => {
                        setShowAddProposedInput(!isShowAddProposedInput);
                        resetAll();
                    }}
                    disabled={companyList?.filter((item) => item.is_approve)?.length >= 2}
                >
                    {isShowAddProposedInput ? 'Cancel' : 'Add more name'}
                </button>
            </Box>
        </>
    );
}

export default ChangeCompanyName;

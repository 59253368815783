import { emailSignatureSlice } from "./emailSignatureSlice";

export const fetchCountries = (token, replace) => (dispatch) =>
  new Promise((resolve) => {
    fetch(process.env.REACT_APP_API_URL + "/public_task/enum/country", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response)
      .then((response) => {
        if ([401, 400].includes(response.status)) {
          replace("/404");
        }
        response.json().then((value) => {
          const data = value.data;
          resolve(dispatch(emailSignatureSlice.actions.countries({ data: data })));
        });
      })
      .catch((error) => {
        replace("/404");
      });
  });

export const fetchFileTemplate = (token, replace) => (dispatch) =>
    new Promise((resolve) => {
        fetch(process.env.REACT_APP_API_URL + "/public_task/file_template", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response)
            .then((response) => {
                if ([401, 400].includes(response.status)) {
                    replace("/404");
                }
                response.json().then((value) => {
                    const data = value.data;
                    resolve(dispatch(emailSignatureSlice.actions.fileTemplate({ data: data })));
                });
            })
            .catch((error) => {
                replace("/404");
            });
    });

const VIEW_OFFICERS_SHARE_CAPITAL = 'VIEW_OFFICERS_SHARE_CAPITAL';
const EDIT_OFFICERS_SHARE_CAPITAL = 'EDIT_OFFICERS_SHARE_CAPITAL';
const FULL_ACTION_OFFICERS_SHARE_CAPITAL = 'FULL_ACTION_OFFICERS_SHARE_CAPITAL';
const PROPOSE_CHANGE = 'PROPOSE_CHANGE';
const UPLOAD_BOOKEEPING = 'UPLOAD_BOOKEEPING';

export {
    VIEW_OFFICERS_SHARE_CAPITAL,
    EDIT_OFFICERS_SHARE_CAPITAL,
    PROPOSE_CHANGE,
    FULL_ACTION_OFFICERS_SHARE_CAPITAL,
    UPLOAD_BOOKEEPING
};

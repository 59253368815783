import React from 'react';
import IndividualForm from './IndividualForm';
import CorporationForm from './CorporationForm';
import SourceOfFund from './SourceOfFund';
import Reference from './Reference';
import { Represented } from './Represented';
import { useSelector } from 'react-redux';
import { FormHelperText } from '@mui/material';

const DirectorForm = ({
    form,
    index,
    disabled,
    countriesOptions,
    countriesCooperationOptions,
    hideSourceOfFund,
    isSVG = false,
    isPanama = false,
    onSwitchTypeMember = () => {},
    isRepresented = false,
    onCheckRepresented,
    hasShowSelectFullName,
    allowFields
}) => {
    const wTypeMemberId = form.watch(`members.${index}.type_member_id`);
    const { phonePrefixCountriesCooperation, phonePrefixCountries } = useSelector(state => state.enum);

    return (
        <>
            {wTypeMemberId === 1 ? (
                <IndividualForm
                    form={form}
                    index={index}
                    disabled={disabled}
                    phoneCountryOptions={phonePrefixCountriesCooperation}
                    countriesCooperationOptions={countriesCooperationOptions}
                    onSwitchTypeMember={onSwitchTypeMember}
                    hasShowSelectFullName={hasShowSelectFullName}
                    allowFields={allowFields}
                />
            ) : (
                <CorporationForm
                    form={form}
                    index={index}
                    disabled={disabled}
                    phoneCountryOptions={phonePrefixCountries}
                    countriesOptions={countriesOptions}
                    onSwitchTypeMember={onSwitchTypeMember}
                />
            )}
            {isSVG && !hideSourceOfFund && (
                <SourceOfFund
                    form={form}
                    index={index}
                    disabled
                    // highlightFields={highlightFields}
                    // disabled={disabled || !!disabledFields?.block_source_of_fund?.(directorInfo)}
                />
            )}
            {isPanama && (
                <>
                    <Reference
                        title={'References 1:'}
                        form={form}
                        indexMember={index}
                        indexReference={0}
                        disabled={disabled}
                        countries={
                            wTypeMemberId === 1 ? countriesCooperationOptions : countriesOptions
                        }
                    />
                    <Reference
                        title={'References 2:'}
                        form={form}
                        indexMember={index}
                        indexReference={1}
                        disabled={disabled}
                        countries={
                            wTypeMemberId === 1 ? countriesCooperationOptions : countriesOptions
                        }
                    />
                </>
            )}
            {isRepresented && (
                <Represented
                    form={form}
                    index={index}
                    isPanama={isPanama}
                    disabled
                    // highlightFields={highlightFields}
                    onCheckRepresented={onCheckRepresented}
                />
            )}
            {form.formState.errors?.validateRepresentedDirector && (
                <FormHelperText error={!!form.formState.errors?.validateRepresentedDirector}>
                    {form.formState.errors?.validateRepresentedDirector?.message}
                </FormHelperText>
            )}
        </>
    );
};
export default DirectorForm;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { register } from '../_redux/authCrud';
import InputField from 'components/form-controls/InputField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

const initialValues = {
    fullname: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    acceptTerms: false,
};

function Registration(props) {
    const { intl } = props;
    const [loading, setLoading] = useState(false);
    const [isHasError, setIsHasError] = useState(false);
    const RegistrationSchema = Yup.object().shape({
        fullname: Yup.string()
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                }),
            )
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols'),
        email: Yup.string()
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                }),
            )
            .email('Wrong email format')
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols'),
        username: Yup.string()
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                }),
            )
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols'),
        password: Yup.string()
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                }),
            )
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols'),
        confirmPassword: Yup.string()
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                }),
            )
            .when('password', {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref('password')],
                    "Password and Confirm Password didn't match",
                ),
            }),
        acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
    });

    const form = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(RegistrationSchema),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleSubmitRegister = async (values) => {
        enableLoading();
        try {
            const response = await register(
                values.email,
                values.fullname,
                values.username,
                values.password,
            );
        } catch (error) {
            setIsHasError(true);
        }
        disableLoading();
    };

    return (
        <div className="login-form login-signin" style={{ display: 'block' }}>
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.REGISTER.TITLE" />
                </h3>
                <p className="text-muted font-weight-bold">
                    Enter your details to create your account
                </p>
            </div>

            <form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                onSubmit={form.handleSubmit(handleSubmitRegister)}
            >
                <div className="form-group fv-plugins-icon-container">
                    {isHasError && (
                        <div
                            style={{
                                padding: '1.5rem 2rem',
                                backgroundColor: '#ffe2e5',
                                border: '1px solid transparent',
                                borderRadius: '0.42rem',
                                marginBottom: '2.5rem',
                            }}
                        >
                            <div
                                style={{
                                    color: '#f64e60',
                                    alignSelf: 'center',
                                    flexGrow: 1,
                                    fontWeight: 500,
                                }}
                            >
                                Something went wrong!
                            </div>
                        </div>
                    )}
                    <InputField
                        name="fullname"
                        form={form}
                        placeholder="Full name"
                        hideHelperText
                        customStyle={{
                            '& .MuiInputBase-input': {
                                padding: '1.25rem 1.5rem 1.25rem 1.5rem',
                                backgroundColor: '#f3f6f9',
                                borderColor: '#f3f6f9',
                                borderRadius: '0.42rem',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottom: 'none !important',
                            },
                            '& .MuiInput-root:before': {
                                borderBottom: 'none !important',
                            },
                            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                borderBottom: 'none !important',
                            },
                        }}
                    />
                    {form.formState.errors.fullname && (
                        <p style={{ fontSize: '0.75rem', color: '#f64e60', marginTop: '3px' }}>
                            {form.formState.errors.fullname.message}
                        </p>
                    )}
                </div>

                <div className="form-group fv-plugins-icon-container">
                    <InputField
                        name="email"
                        form={form}
                        placeholder="Email"
                        hideHelperText
                        customStyle={{
                            '& .MuiInputBase-input': {
                                padding: '1.25rem 1.5rem 1.25rem 1.5rem',
                                backgroundColor: '#f3f6f9',
                                borderColor: '#f3f6f9',
                                borderRadius: '0.42rem',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottom: 'none !important',
                            },
                            '& .MuiInput-root:before': {
                                borderBottom: 'none !important',
                            },
                            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                borderBottom: 'none !important',
                            },
                        }}
                    />
                    {form.formState.errors.email && (
                        <p style={{ fontSize: '0.75rem', color: '#f64e60', marginTop: '3px' }}>
                            {form.formState.errors.email.message}
                        </p>
                    )}
                </div>

                <div className="form-group fv-plugins-icon-container">
                    <InputField
                        name="username"
                        form={form}
                        placeholder="User name"
                        hideHelperText
                        customStyle={{
                            '& .MuiInputBase-input': {
                                padding: '1.25rem 1.5rem 1.25rem 1.5rem',
                                backgroundColor: '#f3f6f9',
                                borderColor: '#f3f6f9',
                                borderRadius: '0.42rem',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottom: 'none !important',
                            },
                            '& .MuiInput-root:before': {
                                borderBottom: 'none !important',
                            },
                            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                borderBottom: 'none !important',
                            },
                        }}
                    />
                    {form.formState.errors.username && (
                        <p style={{ fontSize: '0.75rem', color: '#f64e60', marginTop: '3px' }}>
                            {form.formState.errors.username.message}
                        </p>
                    )}
                </div>

                <div className="form-group fv-plugins-icon-container">
                    <InputField
                        type="password"
                        name="password"
                        form={form}
                        placeholder="Password"
                        hideHelperText
                        customStyle={{
                            '& .MuiInputBase-input': {
                                padding: '1.25rem 1.5rem 1.25rem 1.5rem',
                                backgroundColor: '#f3f6f9',
                                borderColor: '#f3f6f9',
                                borderRadius: '0.42rem',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottom: 'none !important',
                            },
                            '& .MuiInput-root:before': {
                                borderBottom: 'none !important',
                            },
                            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                borderBottom: 'none !important',
                            },
                        }}
                    />
                    {form.formState.errors.password && (
                        <p style={{ fontSize: '0.75rem', color: '#f64e60', marginTop: '3px' }}>
                            {form.formState.errors.password.message}
                        </p>
                    )}
                </div>

                <div className="form-group fv-plugins-icon-container">
                    <InputField
                        type="password"
                        name="confirmPassword"
                        form={form}
                        placeholder="Confirm Password"
                        hideHelperText
                        customStyle={{
                            '& .MuiInputBase-input': {
                                padding: '1.25rem 1.5rem 1.25rem 1.5rem',
                                backgroundColor: '#f3f6f9',
                                borderColor: '#f3f6f9',
                                borderRadius: '0.42rem',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottom: 'none !important',
                            },
                            '& .MuiInput-root:before': {
                                borderBottom: 'none !important',
                            },
                            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                borderBottom: 'none !important',
                            },
                        }}
                    />
                    {form.formState.errors.confirmPassword && (
                        <p style={{ fontSize: '0.75rem', color: '#f64e60', marginTop: '3px' }}>
                            {form.formState.errors.confirmPassword.message}
                        </p>
                    )}
                </div>

                <div className="form-group">
                    <label className="checkbox">
                        <input type="checkbox" name="acceptTerms" className="m-1" />
                        <Link
                            to="/terms"
                            target="_blank"
                            className="mr-1"
                            rel="noopener noreferrer"
                        >
                            I agree the Terms & Conditions
                        </Link>
                        <span />
                    </label>
                </div>

                <div className="form-group d-flex flex-wrap flex-center">
                    <button
                        type="submit"
                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                        <span>Submit</span>
                        {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>

                    <Link to="/auth/login">
                        <button
                            type="button"
                            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                        >
                            Cancel
                        </button>
                    </Link>
                </div>
            </form>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Registration));

import React, { useEffect, useMemo, useState } from 'react';
import { TextField, Autocomplete, FormHelperText } from '@mui/material';
import { isArray } from 'lodash';
import { Controller } from 'react-hook-form';

const SUCCESS_COLOR = '#1bc5bd';
const FAILURE_COLOR = '#f64e60';

const SelectField = ({
    form,
    name = 'default',
    multiple = false,
    id,
    options = [],
    defaultValue,
    label = '',
    onChange,
    helperText,
    inputProps = (params) => {
        return { ...params };
    },
    InputProps = (params) => {
        return { ...params };
    },
    disableClearable = true,
    customStyle,
    hideHelperText = false,
    hideInputHelperText = false,
    hideSuccessHelper = false,
    customOnChange = false, // sử dụng khi không muốn xài field.onChange của hook-form
    ...props
}) => {
    const [option, setOption] = useState(form.getValues(name));
    const [isFirst, setFirst] = useState(false);

    useEffect(() => {
        setOption(form.getValues(name));
    }, [form.getValues(name)]);

    useEffect(() => {
        if (options?.length) setFirst(true);
    }, [options]);

    const handleValue = (values) => {
        if (options) {
            if (props.freeSolo) {
                return values;
            }

            if (multiple) {
                if (!values) values = [];
                return options.filter((value) => {
                    if (!isArray(values)) {
                        values = JSON.parse(values);
                    }
                    return values.includes(typeof value === 'object' ? value.id : value);
                });
            } else {
                let value = options.find(
                    (option) => values === (typeof option === 'object' ? option.id : option),
                );
                return typeof value !== 'undefined' ? value : null;
            }
        }
    };

    const value = useMemo(() => {
        return handleValue(option);
    }, [JSON.stringify(option), JSON.stringify(options), isFirst]);

    const handleChange = (newValue, handleChange, reason) => {
        let data;
        if (multiple) {
            data = newValue.map((value) => (typeof value === 'string' ? value : value.id));
        } else {
            data =
                newValue !== null ? (typeof newValue === 'string' ? newValue : newValue.id) : null;
        }
        setOption(data);
        !customOnChange && handleChange(data);
        onChange && onChange(newValue, reason);
    };

    return (
        <Controller
            name={name}
            control={form.control}
            render={({ field, fieldState: { isDirty, invalid, isTouched, error } }) => {
                return (
                    <Autocomplete
                        value={value}
                        onChange={(event, newValue, reason) => {
                            handleChange(newValue, field.onChange, reason);
                        }}
                        multiple={multiple}
                        id={id}
                        options={options}
                        getOptionLabel={(option) => option?.name || ''}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        defaultValue={defaultValue}
                        disableClearable={disableClearable}
                        size="small"
                        sx={{
                            '& .MuiInput-root.MuiInputBase-sizeSmall .MuiInput-input': {
                                padding: '.65rem 1rem .65rem 0',
                            },
                            '.MuiInputBase-root.Mui-error:before': {
                                borderBottomColor: FAILURE_COLOR,
                            },
                            '.MuiInputBase-root.Mui-error:after': {
                                borderBottomColor: FAILURE_COLOR,
                            },
                            ...customStyle,
                        }}
                        renderInput={(params) => {
                            return (
                                <>
                                    <TextField
                                        {...params}
                                        variant={props.variant ?? 'standard'}
                                        label={label}
                                        error={error}
                                        helperText={
                                            (!hideInputHelperText &&
                                                (typeof error?.message === 'string'
                                                    ? error?.message
                                                    : error?.map((er) => er.message).join(', '))) ||
                                            ''
                                        }
                                        {...inputProps({
                                            ...params,
                                            inputProps: {
                                                ...params.inputProps,
                                                autoComplete: 'nope',
                                            },
                                        })}
                                        autoComplete={'new-password'}
                                        placeholder={props.placeholder || ''}
                                    />
                                    {!hideHelperText && (
                                        <FormHelperText
                                            error={error}
                                            className={!error && isDirty ? 'Mui-success' : ''}
                                            sx={{
                                                '&.Mui-error': {
                                                    color: FAILURE_COLOR,
                                                    fontSize: '.9rem',
                                                },
                                                '&.Mui-success': {
                                                    color: '#1bc5bd',
                                                    fontSize: '.9rem',
                                                },
                                            }}
                                        >
                                            {!hideSuccessHelper && !error && isDirty && props.placeholder
                                                ? props.placeholder + '   was selected'
                                                : ''}
                                            {error ? error?.message : ''}
                                            {!error && !isDirty && helperText ? (
                                                <>
                                                    Please select <b>{props.placeholder}</b>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </FormHelperText>
                                    )}
                                </>
                            );
                        }}
                        {...props}
                    />
                );
            }}
        />
    );
};

export default SelectField;

import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Modal } from 'react-bootstrap';
import DetailForm from 'app/pages/services/components/kyc-elements/DetailForm';
import ExampleCollapse from 'app/pages/services/components/kyc-elements/ExampleCollapse';
import { useKYCUIContext } from '../KYCUIContext';
import { getDialogTitle } from 'app/pages/services/constants';
import * as ActionsNode from 'app/pages/_redux/order-form/orderFormActions';

export default function DetailDialog({ body, setBody }) {
    const {
        modal,
        closeModal,
        updateGraphStructure,
        graphLoading,
        addGraphStructure,
        setGraphElement,
    } = useKYCUIContext();
    const memberData = modal?.data || {};
    const { companyData, orderInfo } = useSelector((state) => {
        return {
            companyData: state?.orderForm?.orderInfo?.Company || {},
            orderInfo: state?.orderForm?.orderInfo || {},
        };
    }, shallowEqual);
    const title = useMemo(
        () =>
            getDialogTitle(
                companyData.entity_type_id,
                companyData.country_id,
                memberData.company_member_type_id,
            ),
        [companyData.country_id, companyData.entity_type_id, memberData.company_member_type_id],
    );
    const data = useMemo(() => {
        let result = memberData;
        if (memberData.id && companyData) {
            const memberTemp = companyData.CompanyMembers.find(
                (member) => member.id === memberData.id,
            );
            if (memberTemp) {
                const currPos = {
                    ...(memberTemp.CompanyPositions.find((pos) => pos.id === memberData.pos_id) ||
                        {}),
                };
                if (currPos.id) {
                    delete currPos.id;
                }
                result = { ...memberData, ...memberTemp, ...currPos };
            }
        }
        return result;
    }, [memberData, companyData]);
    const isLevelOneCloned = (currMember) => {
        if (currMember && currMember.id && companyData) {
            const members = companyData.CompanyMembers.filter(
                (member) => member.id === currMember.id,
            );
            for (const member of members) {
                for (const pos of member.CompanyPositions) {
                    if (!pos.corporation_company_member_id) {
                        return true;
                    }
                }
            }
        }
        return false;
    };
    return (
        // <>
        // </>
        <Modal
            show={modal.show}
            onHide={closeModal}
            backdrop="static"
            size="xl"
            animation={true}
            centered
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DetailForm
                    data={data}
                    closeModal={closeModal}
                    updateGraphStructure={updateGraphStructure}
                    graphLoading={graphLoading}
                    addGraphStructure={addGraphStructure}
                    setGraphElement={setGraphElement}
                    isLevelOneCloned={isLevelOneCloned}
                    orderInfo={orderInfo}
                    updateCompanyMemberList={ActionsNode.updateCompanyMemberList}
                    body={body}
                    setBody={setBody}
                />

                <div className="col-12 kyc-example">
                    <ExampleCollapse />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export function getAnswersForTaskOneQuestion(body, item, questionName) {
    let answersForTask = [];
    try {
        let q = {
            option_id: item[questionName],
            is_chosen: 1,
            company_member_id: item.id,
        };
        answersForTask.push(q);
    
        // clear old selection of this question if has
        let old_answers = body.Questions[questionName].Options.filter( op => op.Answers.find((ans) => ans.company_member_id === item.id));
        if(old_answers && old_answers.length > 0) {
            old_answers.map(opt=>{
                if(opt.id !== item[questionName]) {
                    let turnOff = {
                        option_id: opt.id,
                        is_chosen: 0,
                        company_member_id: item.id,
                    };
                    answersForTask.push(turnOff);
                }
            })
        }
    } catch (error) {
        console.log(error);
    }
    return answersForTask;
}

export function getTurnOffAnswers(body, item, questionName) {
    let answersForTask = [];
    try {
        // clear old selection of this question if has
        let old_answers = body.Questions[questionName].Options.filter( op => op.Answers.find((ans) => ans.company_member_id === item.id));
        if(old_answers && old_answers.length > 0) {
            old_answers.map(opt=>{
                let turnOff = {
                    option_id: opt.id,
                    is_chosen: 0,
                    company_member_id: item.id,
                };
                answersForTask.push(turnOff);
            })
        }
    } catch (error) {
        console.log(error);
    }
    return answersForTask;
}

export function clearCompanyMemberId(arr) {
    return arr.map(({ company_member_id, ...data }) => data);
};
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import * as actions from '../../../../../_redux/order-form/orderFormActions';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from 'components/form-controls/InputField';
import { useForm } from 'react-hook-form';
import PreviewOrderForm from './PreviewOrderForm';

const Step5 = ({
    setActiveStep,
    activeStep,
    setActiveParentStep,
    body,
    setBody,
    disabled,
    hideAction,
    openModal,
    setOpenModal,
    activeStepRef = {},
    validateStepsRef = {},
    index,
    onActiveStep = () => {},
    hasMissingMember,
    isOrderFormIsFilled,
    activeParentStep,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [country, setCountry] = useState({ id: 1, name: '' });
    useEffect(() => {
        setCountry(body.Company.Country);
    }, [body]);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const [is__offenseYes, setIs__offenseYes] = useState(null);
    const [currentValue, setCurrentValue] = useState({});

    const questions = body.Task.IncorporationCountry?.Questions ?? [];

    const schema = Yup.object().shape({
        is_politically_exposed_person: Yup.string().nullable().required('Required'),
        is_offenses: Yup.string().nullable().required('Required'),
        is_entity:
            body?.Company?.country_id === 183
                ? Yup.string().nullable().required('Required')
                : Yup.string().nullable(),
        is_committed: Yup.bool().required('Required'),
        offenses: Yup.string()
            .nullable()
            .when('is_offenses', {
                is: 'Yes',
                then: Yup.string().required('Required!'),
            }),
        operating_address:
            body?.Company?.country_id === 183
                ? Yup.string()
                      .nullable()
                      .trim()
                      .required('Please type the country where your Bank Accounts are kept.')
                      .matches(
                          /^(?=.*[a-zA-ZÀ-ỹà-ỳẠ-ỵ])(?!.*\.\.)(?!.*\,\,)([a-zA-Z0-9À-ỹà-ỳẠ-ỵ .,;]+)$/,
                          'Please content does not contain special characters',
                      )
                : Yup.string().nullable(),
    });

    const handleNext = async (values) => {
        try {
            await setIsLoading(true);
            let options = [];
            questions.forEach((q, idx) => {
                switch (q.id) {
                    case 1:
                        q.Options.forEach((opt) => {
                            let option = {
                                option_id: opt.id,
                                is_chosen:
                                    values.is_politically_exposed_person === opt.content ? 1 : 0,
                            };
                            options.push(option);
                        });
                        break;
                    case 2:
                        q.Options.forEach((opt) => {
                            if (values.is_offenses === 'Yes' && opt.id === 3) {
                                let option = {
                                    option_id: opt.id,
                                    is_chosen: values.is_offenses === opt.content ? 1 : 0,
                                    value_text: values.offenses,
                                };
                                options.push(option);
                            } else {
                                let option = {
                                    option_id: opt.id,
                                    is_chosen: values.is_offenses === opt.content ? 1 : 0,
                                };
                                options.push(option);
                            }
                        });
                        break;

                    case 34: // add filed allow_use_information
                        q.Options.forEach((opt) => {
                            let option = {
                                option_id: opt.id,
                                is_chosen: values.allow_use_information ? 1 : 0,
                            };
                            options.push(option);
                        });
                        break;

                    case 167:
                        q.Options.forEach((opt) => {
                            let option = {
                                option_id: opt.id,
                                is_chosen: values.is_entity === opt.content ? 1 : 0,
                            };
                            options.push(option);
                        });
                        break;

                    case 168:
                        q.Options.forEach((opt) => {
                            let option = {
                                option_id: opt.id,
                                is_chosen: values?.operating_address ? 1 : 0,
                                value_text: values?.operating_address,
                            };
                            options.push(option);
                        });
                        break;

                    default:
                        break;
                }
            });
            let finalData = {
                answersForTask: options,
            };

            await dispatch(actions.updateDeclaration(body.Task.id, finalData)).then((res) => {
                try {
                    if (res.data.state) {
                        setActiveParentStep(1);
                    }
                } catch (error) {
                    console.log(error);
                }
            });
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };

    const questionPoliticallyExposedPerson = questions
        ?.find((ques) => ques.id === 1)
        ?.Options?.find((option) => option.Answers.length);
    const questionOffenses = questions
        ?.find((ques) => ques.id === 2)
        ?.Options?.find((option) => option.Answers.length);
    const questionAllowUseInformation = questions
        ?.find((ques) => ques.id === 34)
        ?.Options?.find((option) => option.Answers.length);
    const questionEntity = questions
        ?.find((ques) => ques.id === 167)
        ?.Options?.find((option) => option.Answers.length);

    // declaration information Model
    const [declaration, setDeclaration] = useState({
        is_politically_exposed_person: questionPoliticallyExposedPerson?.content ?? 'No',
        is_offenses: questionOffenses?.content ?? 'No',
        is_entity: questionEntity?.content ?? 'No',
        offenses: questionOffenses?.Answers[0]?.value_text ?? '',
        allow_use_information: !!questionAllowUseInformation,
        is_committed: isOrderFormIsFilled,
    });

    const form = useForm({
        defaultValues: declaration,
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (openModal) {
            setCurrentValue({
                ...form.watch(),
                declaration,
            });
        }
    }, [openModal]);

    useEffect(() => {
        try {
            const declarationInfo = {
                id: body.Task?.Processes.filter((process) => process.process_step_id === 17)[0].id,
                is_politically_exposed_person: questionPoliticallyExposedPerson?.content ?? 'No',
                is_offenses: questionOffenses?.content ?? 'No',
                is_entity: questionEntity?.content ?? 'No',
                offenses: questionOffenses?.Answers[0]?.value_text ?? form.watch('offenses'),
                is_committed: form.watch('is_committed'),
                allow_use_information: !!questionAllowUseInformation,
                operating_address:
                    questions?.find((question) => question?.id === 168)?.Options?.[0]?.Answers?.[0]
                        ?.value_text ?? body?.Company?.operating_address,
            };
            form.reset(declarationInfo);
            setIs__offenseYes(form.watch('is_offenses'));
        } catch (error) {
            console.log(error);
        }
    }, [body]);

    const handleChangeExposedPerson = (event) => {
        setDeclaration({ ...declaration, is_politically_exposed_person: event.target.value });
    };

    const handleChangeOffenses = (event) => {
        setDeclaration({ ...declaration, is_offenses: event.target.value });
    };

    const handleChangeEntity = (event) => {
        setDeclaration({ ...declaration, is_entity: event.target.value });
    };

    const handleSubmit = (values) => {
        if (typeof activeStepRef.current === 'number') {
            activeStepRef.current = null;
            return;
        }

        if (!form.getValues('is_committed')) {
            Swal.fire({
                icon: 'error',
                title: 'Terms is required!',
                text: 'Terms is required!',
            });
            return;
        } else {
            if (isOrderFormIsFilled && typeof hasMissingMember === 'object') {
                onActiveStep(undefined, Object.keys(hasMissingMember)[0]);
            } else {
                handleNext(values);
            }
        }
    };

    useEffect(() => {
        try {
            form.setValue(
                'is_politically_exposed_person',
                declaration.is_politically_exposed_person,
            );
            form.setValue('is_offenses', declaration.is_offenses);
            form.setValue('is_entity', declaration.is_entity);
            setIs__offenseYes(declaration.is_offenses);
        } catch (error) {
            console.log(error);
        }
    }, [declaration]);

    const handleKeydownAddressCountry = (e) => {
        const regex = new RegExp('^[a-zA-Z0-9À-ỹà-ỳẠ-ỵ .,;]+$');

        if (!regex.test(e.key)) {
            // Prevent the character from being typed.
            e.preventDefault();
            return false;
        }
    };

    // truyền form validate cho file stepper
    validateStepsRef.current = {
        ...validateStepsRef.current,
        STEP_5: {
            isValid: form.formState.isValid,
            onSubmit: () => form.handleSubmit(handleSubmit)(),
        },
    };

    return (
        <section className="step5">
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <div className="firstQuestion">
                    <div id="my-radio-group">
                        Are you or any connected persons (Directors, Shareholder, Ultimate
                        Beneficial Owners, Partners), a Politically Exposed Person (PEP)?
                    </div>

                    <RadioGroup
                        row
                        aria-label="type"
                        name="is_politically_exposed_person"
                        onChange={handleChangeExposedPerson}
                    >
                        <FormControlLabel
                            value="Yes"
                            checked={declaration.is_politically_exposed_person === 'Yes'}
                            control={<Radio color="secondary" disabled={disabled} />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="No"
                            checked={declaration.is_politically_exposed_person === 'No'}
                            control={<Radio color="secondary" disabled={disabled} />}
                            label="No"
                        />
                    </RadioGroup>
                </div>
                {declaration.is_politically_exposed_person === 'Yes' ? (
                    <div className="text-primary mb-5">
                        More documents can be required to be supplied or signed.
                    </div>
                ) : null}
                <div className="secondeQuestion">
                    <div id="my-radio-group">
                        Has any of the shareholders, directors, Ultimate Beneficial Owners ever
                        committed any offenses?
                    </div>
                    <RadioGroup
                        row
                        aria-label="type"
                        name="is_offenses"
                        onChange={handleChangeOffenses}
                    >
                        <FormControlLabel
                            value="Yes"
                            checked={declaration.is_offenses === 'Yes'}
                            control={<Radio color="secondary" disabled={disabled} />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="No"
                            checked={declaration.is_offenses === 'No'}
                            control={<Radio color="secondary" disabled={disabled} />}
                            label="No"
                        />
                    </RadioGroup>
                </div>
                {declaration.is_offenses === 'Yes' ? (
                    <div className="col-md-6 mb-7 input-line">
                        <label>Please specify the nature of the offense</label>
                        <InputField
                            form={form}
                            name="offenses"
                            placeholder="Please specify the nature of the offense"
                            showIcon
                            hideSuccessHelper
                            disabled={disabled}
                        />
                    </div>
                ) : null}

                {body?.Company?.country_id === 183 && ( // 183 = Samoa
                    <>
                        <div className="secondeQuestion">
                            <div id="my-radio-group">
                                Is the entity a Financial Institution under CRS?
                            </div>
                            <RadioGroup
                                row
                                aria-label="type"
                                name="is_entity"
                                onChange={handleChangeEntity}
                            >
                                <FormControlLabel
                                    value="Yes"
                                    checked={declaration.is_entity === 'Yes'}
                                    control={<Radio color="secondary" />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="No"
                                    checked={declaration.is_entity === 'No'}
                                    control={<Radio color="secondary" />}
                                    label="No"
                                />
                            </RadioGroup>
                        </div>
                        <div className="col-md-6 mb-7 p-0">
                            <label>Country where Bank Accounts are kept</label>
                            <InputField
                                form={form}
                                name="operating_address"
                                placeholder="Country where Bank Accounts are kept"
                                onKeyDown={(e) => handleKeydownAddressCountry(e)}
                                showIcon
                                hideSuccessHelper
                                multiline
                            />
                        </div>
                    </>
                )}

                <div className="terms">
                    <label className="d-flex align-items-start">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    form={form}
                                    name="is_committed"
                                    color="secondary"
                                    onChange={() =>
                                        form.setValue('is_committed', !form.watch('is_committed'))
                                    }
                                    checked={form.watch('is_committed')}
                                    m={0}
                                    disabled={disabled}
                                />
                            }
                            style={{
                                marginRight: 0,
                            }}
                        />
                        <div>
                            <p>
                                I declare that the information provided in this form is true and
                                correct and that I am requesting your services for lawful purposes.
                                The moneys and/or other assets now or to be introduced to you do not
                                emanate from any activity which is unlawful in their country of
                                origin or in the <strong> {country.name} </strong> and specifically
                                that none of the assets were derived from any of the activities
                                characterized as criminal by any applicable legislation against
                                money laundering or terrorist financing.
                            </p>
                            <p>
                                I am aware that I may be subject to prosecution and criminal
                                sanctions under written law if I am found to have made any false
                                statement which I know to be false or which I do not believe to be
                                true, or if I have intentionally suppressed any material fact.
                            </p>
                        </div>
                    </label>
                </div>

                {body && body.isMarshall && (
                    <div className="terms mb-5">
                        <label className="d-flex align-items-start">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        form={form}
                                        name="allow_use_information"
                                        color="secondary"
                                        onChange={() => {
                                            form.setValue(
                                                'allow_use_information',
                                                !form.watch('allow_use_information'),
                                            );
                                        }}
                                        checked={form.watch('allow_use_information')}
                                        m={0}
                                        disabled={disabled}
                                    />
                                }
                                style={{
                                    marginRight: 0,
                                }}
                            />
                            <div>
                                Please check the box to opt-in to allow your personal information to
                                be used for marketing purposes which includes event invitations,
                                newsletters, and blog, maritime, and corporate updates, among
                                others. <strong> {country.name} </strong> and specifically that none
                                of the assets were derived from any of the activities characterized
                                as criminal by any applicable legislation against money laundering
                                or terrorist financing.
                            </div>
                        </label>
                    </div>
                )}

                <PreviewOrderForm
                    open={openModal}
                    handleClose={() => setOpenModal(false)}
                    body={body}
                    setBody={setBody}
                    currentValueStep5={currentValue}
                    isFromStep5
                />

                {!hideAction && (
                    <div>
                        <div>
                            <Button
                                size="large"
                                variant="contained"
                                disableElevation
                                className="mr-2"
                                onClick={handleBack}
                                disabled={isLoading}
                            >
                                Back
                            </Button>
                            <Button
                                className="text-white mr-2"
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={() => setOpenModal(true)}
                            >
                                Preview
                            </Button>
                            <LoadingButton
                                disabled={
                                    isLoading ||
                                    form.formState.isSubmitting ||
                                    !form.watch('is_committed')
                                }
                                variant="contained"
                                color="primary"
                                type="submit"
                                size="large"
                                style={{ color: !isLoading ? '#fff' : 'transparent' }}
                                onClick={() => {
                                    activeStepRef.current = null;
                                }}
                                loading={isLoading}
                            >
                                Complete
                            </LoadingButton>
                        </div>
                    </div>
                )}
            </form>
        </section>
    );
};

export default Step5;


import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import { useSelector, useDispatch } from 'react-redux';

import * as actionsEnum from 'app/pages/_redux/enum/enumActions';
import SectionFilterLeft from './section-filter-left';
import SectionFilterResult from './section-filter-result';
import * as actionBankingTool from 'app/pages/_redux/banking-tool/bankingToolAction';
import Button from '@mui/material/Button';
import { getBankingProcessTreeAction } from '../../../../_redux/banking/asyncThunk';
import { updateActiveProcess } from '../../../../_redux/banking/slice';
import { toast } from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';
import FinishWaitVideoCall from '../banking-process/meeting-schedule/finish-wait-video-call'
import { getLinkContentRoute } from '../../OrderManagermentUIHelpers';
import { LINK } from 'app/pages/services/constants/banking_constants';


const depositRange = (deposit) => {
    switch (deposit) {
        case 0:
            return 50001;
        case 1:
            return 1000;
        case 2:
            return 10000;
        case 3:
            return 50000;
        case 4:
            return 50001;
        default:
            break;
    }
}

// ================================
const except_type_account = [{
    id: 191, // country
    except_type_account: [{ 19: true }] // idBank - type_account
}]

const BankingToolPage = () => {
    const { id } = useParams();
    let navigate = useNavigate();

    const [processTree, setProcessTree] = useState(null)
    const [isRefresh, setIsRefresh] = useState(null)


    // redux
    const dispatch = useDispatch();
    const { countries } = useSelector(state => ({
        countries: state.enum.countries,
    }));
    const { bankingTool } = useSelector(state => ({
        bankingTool: state.bankingTool
    }));
    const { activeProcess } = useSelector(state => state.banking);

    /*
        Data filter từ filter result API
        Tại s ko lấy result từ API về filter tiếp mà tại ra thêm state ?
        Lý do: để có thể lấy lại result API
    */
    const [dataFilter, setDataFilter] = useState([])
    const [inforFilter, setInforFilter] = useState(null) // lưu lại filter FE

    useEffect(() => {
        dispatch(actionsEnum.fetchCountries());

        (async () => {
            const resultAction = await dispatch(getBankingProcessTreeAction(id));
            if (getBankingProcessTreeAction.fulfilled.match(resultAction)) {
                const {payload: processes } = resultAction;

                /* Lấy process của step */
                setProcessTree(processes)
                let activeProcess = processes.find(item => item.id === 38);
                if (!activeProcess) return;
                dispatch(updateActiveProcess(activeProcess));
                return;
            }
            toast.error('Somethings went wrong!');
        })()
    }, [isRefresh])



    /*
        Nếu đã filter FE. Sau đó filter API thì result filter FE phải lưu lại
    */
    useEffect(() => {
        inforFilter ? fetchFilterFE(inforFilter) : setDataFilter(bankingTool.filterResult)
    }, [bankingTool])


    const fetchFilter = (inforFilter, isFetchAPI) => {
        if (isFetchAPI) {
            return dispatch(actionBankingTool.fetchFilterAPI(inforFilter));
        }
        setInforFilter(inforFilter)
        return fetchFilterFE(inforFilter)
    }

    const fetchFilterFE = (filterInfo) => {
        let tempFilterInfo = {
            initial_deposit: filterInfo.initial_deposit,
            is_remote_opening: filterInfo.is_remote_opening,
            type_business: filterInfo.type_business,
            type_account: filterInfo.type_account,
            is_bookkeeping: filterInfo.is_bookkeeping
        }
        let dataFilter = JSON.parse(JSON.stringify(bankingTool.filterResult))
        for (let [key, value] of Object.entries(tempFilterInfo)) {
            dataFilter = filterByType(tempFilterInfo, key, dataFilter)
        }
        setDataFilter(dataFilter)
    }

    const filterByType = (filterInfo, type, data) => {
        // console.log('data: ',filterInfodata)
        if (filterInfo[type] !== null) {
            switch (type) {
                case 'initial_deposit':
                    return data.filter(e => {
                        if (filterInfo.initial_deposit < 4 && e.initial_deposit <= depositRange(filterInfo.initial_deposit)) {
                            return true
                        }
                        if (filterInfo.initial_deposit === 4 || filterInfo.initial_deposit === 0) {
                            return true
                        }
                        return false
                    })
                case 'is_remote_opening':
                    return data.filter(e => {
                        if (filterInfo.is_remote_opening === true && e.is_remote_opening === filterInfo.is_remote_opening) {
                            return true
                        }
                        if (filterInfo.is_remote_opening === false) {
                            return true
                        }
                    })
                case 'type_business':
                    return data;
                case 'type_account':
                    return data.filter(e => {
                        let flag = true
                        for (let p of except_type_account) {
                            for (let c of p.except_type_account) {
                                if (p.id === e.Country.id && parseInt(Object.keys(c)[0]) === e.id && filterInfo.type_account === Object.values(c)[0]) {
                                    flag = false;
                                    break;
                                }
                            }
                        }
                        return flag === false ? false : true
                    })
                case 'is_bookkeeping':
                    return data;
                default:
                    return data;
            }
        }
        return data
    }


    const renderContent = () => {
        const statusProcess = activeProcess?.Processes[0]
        const case1 = processTree && processTree.length > 1 // Nếu chỉ có step banking tool = 1
        const case2 = (statusProcess?.is_approved !== null || statusProcess?.is_rejected !== null || statusProcess?.is_terminated !== null) ? true : false
        const case2sp = statusProcess?.hasOwnProperty('is_approved') || statusProcess?.hasOwnProperty('is_rejected') || statusProcess?.hasOwnProperty('is_terminated')
        if(case1 && case2){
            return navigate(getLinkContentRoute(LINK.BANKING, id));
        }
        if(case2sp && case2){
            return <FinishWaitVideoCall />
        }
        if(!case2){
            return (
                <div className="row m-0 rounded border shadow-sm" style={{ overflow: 'hidden'}}>
                    <div className="col-12 col-sm-4 col-md-4 p-0" style={{height: '100%', overflow:'auto'}}>
                        <SectionFilterLeft countries={countries || []} filterResult={dataFilter} isLoading={bankingTool.isLoading} callbackValueFilter={(value, isFetchAPI) => fetchFilter(value, isFetchAPI)} />
                    </div>
                    <div className="col-12 col-sm-8 col-md-8 pl-10 pr-0 bg-white" style={{height: '100%', minHeight:'935px' ,overflow:'auto'}}>
                        <SectionFilterResult isRefresh={() => setIsRefresh(isRefresh => isRefresh + 1)} filterResult={dataFilter} isLoading={bankingTool.isLoading} countries={countries || []} />
                    </div>
                </div>
            )
        }
        return 'Loading...'
    }

    return (
        <Card>
            <CardHeader title="Banking Tool"/>
            <CardBody style={{ height: '100%' }}>

                {
                    renderContent()
                }

            </CardBody>
        </Card>
    );
};

export default BankingToolPage;

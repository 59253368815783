import { ArrowRight, CheckCircle } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

export const isOtherUploaded = (currentTemplate) => {
    return (currentTemplate?.Files || []).length > 0;
};

export const isUploaded = (currentTitle, currentTemplate) => {
    if (currentTitle?.children?.length > 0) {
        let companyMemberIds = [];
        currentTitle.children.forEach((item) => {
            item.titles.forEach((member) => companyMemberIds.push(member.id));
        });
        if (companyMemberIds.length > 0) {
            return companyMemberIds.every(
                (id) =>
                    (currentTemplate?.Files?.filter((file) => file?.company_member_id === id) || [])
                        .length > 0,
            );
        }
        return false;
    }
    return (
        (
            currentTemplate?.Files?.filter(
                (file) =>
                    file.file_template_id && file.file_template_id == currentTitle.file_template_id,
            ) || []
        ).length > 0
    );
};

const DocumentTitleList = ({ fileTemplates, onSelectTitle, autoSelectFirstTitle = false }) => {
    let [activeTitle, setActiveTitle] = useState(null);
    let [activeCategory, setActiveCategory] = useState(null);
    let [selectedAuto, setSelectedAuto] = useState(false);

    useEffect(() => {
        if (autoSelectFirstTitle && !selectedAuto) {
            setActiveTitle(fileTemplates?.[0]?.titles?.[0]);
            setActiveCategory(fileTemplates?.[0]);
            onSelectTitle && onSelectTitle([fileTemplates?.[0], fileTemplates?.[0]?.titles?.[0]]);
            setSelectedAuto(true);
        }
    }, []);

    const isActiveTitle = (title) => {
        return title.file_name === activeTitle?.file_name;
    };

    const isActiveCategory = (category) => {
        return category.name === activeCategory?.name;
    };

    const renderTreeList = (tree) => {
        const renderFile = (node) => {
            if (node.id === 2) {
                return (
                    <>
                        {node.titles['Declaration/ Annual Return'].length > 0 && (
                            <>
                                <span
                                    className={
                                        node.titles['Declaration/ Annual Return'].some(
                                            (item) => item.file_name === activeTitle?.file_name,
                                        )
                                            ? 'font-weight-bold'
                                            : 'text-black-50'
                                    }
                                >
                                    2.1. Declaration/ Annual Return
                                </span>
                                {node.titles['Declaration/ Annual Return'].map((title, index) => (
                                    <li
                                        key={index}
                                        className={
                                            'cursor-pointer mb-1 position-relative ' +
                                            (isActiveTitle(title)
                                                ? 'font-weight-bold'
                                                : 'text-black-50')
                                        }
                                        style={{ whiteSpace: 'nowrap' }}
                                        onClick={() => {
                                            setActiveTitle(title);
                                            setActiveCategory(node);
                                            onSelectTitle && onSelectTitle([node, title]);
                                        }}
                                    >
                                        <span>{title.file_name}</span>

                                        {isUploaded(title, node) && (
                                            <CheckCircle
                                                color="primary"
                                                className="d-inline ml-2"
                                                style={{ fontSize: '0.9rem', marginBottom: 2 }}
                                            />
                                        )}

                                        {isActiveTitle(title) && (
                                            <ArrowRight
                                                fontSize="small"
                                                className={'position-absolute'}
                                                style={{
                                                    top: '50%',
                                                    left: '-20px',
                                                    transform: 'translateY(-50%)',
                                                }}
                                            />
                                        )}
                                    </li>
                                ))}
                            </>
                        )}
                        {node.titles['Certificate of Good Standing'].length > 0 && (
                            <>
                                <span
                                    className={
                                        node.titles['Certificate of Good Standing'].some(
                                            (item) => item.file_name === activeTitle?.file_name,
                                        )
                                            ? 'font-weight-bold'
                                            : 'text-black-50'
                                    }
                                >
                                    2.2. Certificate of Good Standing
                                </span>
                                {node.titles['Certificate of Good Standing'].map((title, index) => (
                                    <li
                                        key={index}
                                        className={
                                            'cursor-pointer mb-1 position-relative ' +
                                            (isActiveTitle(title)
                                                ? 'font-weight-bold'
                                                : 'text-black-50')
                                        }
                                        style={{ whiteSpace: 'nowrap' }}
                                        onClick={() => {
                                            setActiveTitle(title);
                                            setActiveCategory(node);
                                            onSelectTitle && onSelectTitle([node, title]);
                                        }}
                                    >
                                        <span>{title.file_name}</span>

                                        {isUploaded(title, node) && (
                                            <CheckCircle
                                                color="primary"
                                                className="d-inline ml-2"
                                                style={{ fontSize: '0.9rem', marginBottom: 2 }}
                                            />
                                        )}

                                        {isActiveTitle(title) && (
                                            <ArrowRight
                                                fontSize="small"
                                                className={'position-absolute'}
                                                style={{
                                                    top: '50%',
                                                    left: '-20px',
                                                    transform: 'translateY(-50%)',
                                                }}
                                            />
                                        )}
                                    </li>
                                ))}
                            </>
                        )}
                    </>
                );
            } else {
                if (node?.titles) {
                    return node.titles.map((title, index) => (
                        <li
                            key={index}
                            className={
                                'cursor-pointer mb-1 position-relative ' +
                                (isActiveTitle(title) ? 'font-weight-bold' : 'text-black-50')
                            }
                            style={{ whiteSpace: 'nowrap' }}
                            onClick={() => {
                                setActiveTitle(title);
                                setActiveCategory(node);
                                onSelectTitle && onSelectTitle([node, title]);
                            }}
                        >
                            <span>{title.file_name}</span>

                            {isUploaded(title, node) && (
                                <CheckCircle
                                    color="primary"
                                    className="d-inline ml-2"
                                    style={{ fontSize: '0.9rem', marginBottom: 2 }}
                                />
                            )}

                            {isActiveTitle(title) && (
                                <ArrowRight
                                    fontSize="small"
                                    className={'position-absolute'}
                                    style={{
                                        top: '50%',
                                        left: '-20px',
                                        transform: 'translateY(-50%)',
                                    }}
                                />
                            )}
                        </li>
                    ));
                }
            }
        };

        return (
            <ol className={'pl-5'}>
                {tree?.map((node) => (
                    <React.Fragment key={node.id}>
                        <li
                            className={
                                'pb-1 position-relative ' +
                                (isActiveCategory(node) ? 'font-weight-bold' : 'text-black-50') +
                                (node.clickable ? ' cursor-pointer' : '')
                            }
                            onClick={() => {
                                if (node.clickable) {
                                    setActiveCategory(node);
                                    setActiveTitle(null);
                                    onSelectTitle && onSelectTitle([node, null]);
                                }
                            }}
                        >
                            <span className="title-name" style={{ textTransform: 'uppercase' }}>
                                {node.name}

                                {node.clickable && isOtherUploaded(node) && (
                                    <CheckCircle
                                        color="primary"
                                        className="d-inline ml-2"
                                        style={{ fontSize: '0.9rem', marginBottom: 2 }}
                                    />
                                )}
                            </span>
                            <ul className={'pl-5'}>{renderFile(node)}</ul>
                        </li>
                    </React.Fragment>
                ))}
            </ol>
        );
    };

    return (
        <div className={'overflow-auto document-title-list-co'}>
            <p>
                <b>DOCUMENT LIST</b>
            </p>
            {renderTreeList(fileTemplates)}
        </div>
    );
};
export default DocumentTitleList;

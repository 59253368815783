import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';
import Switch from '@mui/material/Switch';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ShareholderIndividual from './step3-helper/shareholder-individual';
import ShareholderCorporation from './step3-helper/shareholder-corporation';
import SourseForFunds from './step3-helper/sourse-for-funds';
import Represented from './step3-helper/represented';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../../../_redux/order-form/orderFormActions';
import * as actionsEnum from '../../../../../../_redux/enum/enumActions';
import Nominee from './step3-helper/nominee';
import ExistingMemberDialog from './step3-helper/existing-member/existing-member-dialog';
import { listCountryExceptStep } from './stepper';
import SecurityQuestion from './step3-helper/security-question';
import {
    EmptyMemberCommon,
    formatCompanyMembers,
    memberCommonYupValidate,
} from './step3-helper/helpers';
import References from './references/References';
import { ArticlesOfOrganizationOptions } from '../constants';
import { cloneDeep, get, head, isEmpty, unionBy, uniq, uniqBy } from 'lodash';
import { CAYMAN_ID, RAK_ID, UK_ID } from 'constants/country';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getDefaultPhonePrefix } from 'app/pages/services/utilities/NationalityHelpers';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

// validation schema
const fieldArraySchema = ({ hasShowSelectFullName }) => {
    return Yup.object({
        members: Yup.array().of(
            Yup.object().shape({
                full_name: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: true,
                        then: Yup.string()
                            .nullable()
                            .required(
                                hasShowSelectFullName
                                    ? 'Please select your full name!'
                                    : 'Full name is required!',
                            ),
                    }),
                first_name:
                    hasShowSelectFullName &&
                    Yup.string()
                        .nullable()
                        .when('individual', {
                            is: true,
                            then: Yup.string().nullable().required('First name is required!'),
                        }),
                middle_name: hasShowSelectFullName && Yup.string().nullable(),
                last_name:
                    hasShowSelectFullName &&
                    Yup.string()
                        .nullable()
                        .when('individual', {
                            is: true,
                            then: Yup.string().nullable().required('Last name is required!'),
                        }),
                occupation: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: true,
                        then: Yup.string().nullable().required('Occupation is required!'),
                    }),
                phone: Yup.string()
                    .nullable()
                    .matches(/^[0-9]*$/, 'Please enter number!')
                    .required('Phone number is required!'),
                email: Yup.string()
                    .nullable()
                    .required('Email is required!')
                    .email('Please enter valid email!'),
                number_share: Yup.number()
                    .nullable()
                    .transform((value) =>
                        isNaN(value) || value === undefined || value === null ? 0 : value,
                    ),
                own_percent: Yup.number()
                    .nullable()
                    .transform((value) =>
                        isNaN(value) || value === undefined || value === null ? 0 : value,
                    ),
                company_name: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string().required('Company name is required!'),
                    }),
                company_number: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string().required('Company number is required'),
                    }),
                country_of_incorporation: Yup.number()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.number().test({
                            message: 'Country of Incorporation is required!',
                            test: (value) => {
                                return value;
                            },
                        }),
                    }),
                registered_address: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string().required('Registered Address is required!'),
                    }),
                date_of_incorporation: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string().required('Date of incorporation is required!'),
                    }),
                business_activity_array: Yup.array()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.array()
                            .nullable()
                            .min(1, 'Business activities is required!')
                            .required('Business activities is required!'),
                    }),
                business_activity_detail: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string()
                            .nullable()
                            .when('business_activity_detail', (value, schema) => {
                                return Yup.string()
                                    .nullable()
                                    .test(
                                        'required',
                                        'Please write a complete description of your business activities at least 10 words',
                                        (val) => val && val.trim().split(/\s+/).length >= 10,
                                    );
                            }),
                    }),
                country_operations: Yup.array()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.array()
                            .nullable()
                            .min(1, 'Countries operation is required!')
                            .required('Countries operation is required!'),
                    }),
                other_asset: Yup.string()
                    .nullable()
                    .when('showOther', {
                        is: true,
                        then: Yup.string().required("Please provide other asset's name."),
                    }),
                other_money: Yup.number()
                    .transform((value) =>
                        isNaN(value) || value === undefined || value === null ? 0 : value,
                    )
                    .nullable()
                    .when('other_asset', {
                        is: (value) => value && value.length > 0,
                        then: Yup.number().min(1, `Please provide other asset's amount`),
                    }),
                other_detail_income: Yup.string()
                    .nullable()
                    .when('showOtherIncome', {
                        is: true,
                        then: Yup.string().required("Please provide other income's name"),
                    }),
                other_money_income: Yup.number()
                    .transform((value) =>
                        isNaN(value) || value === undefined || value === null ? 0 : value,
                    )
                    .nullable()
                    .when('other_detail_income', {
                        is: (value) => value && value.length > 0,
                        then: Yup.number().min(1, `Please provide other asset's amount`),
                    }),
                total_asset: Yup.number()
                    .nullable()
                    .when('isSVG', {
                        is: true,
                        then: Yup.number()
                            .moreThan(0)
                            .required('Please fill up at least one fields!'),
                    }),

                total_income: Yup.number()
                    .nullable()
                    .when('isSVG', {
                        is: true,
                        then: Yup.number()
                            .moreThan(0)
                            .required('Please fill up at least one fields!'),
                    }),

                is_represented: Yup.boolean(),
                security_question_1: Yup.mixed()
                    .nullable()
                    .when('is_UK', {
                        is: true,
                        then: Yup.mixed().required('Security question is required'),
                    }),
                security_question_2: Yup.mixed()
                    .nullable()
                    .when('is_UK', {
                        is: true,
                        then: Yup.mixed().required('Security question is required'),
                    }),
                security_question_3: Yup.mixed()
                    .nullable()
                    .when('is_UK', {
                        is: true,
                        then: Yup.mixed().required('Security question is required'),
                    }),
                answer_question_1: Yup.string()
                    .nullable()
                    .when('is_UK', {
                        is: true,
                        then: Yup.string().required('Security answer is required'),
                    }),
                answer_question_2: Yup.string()
                    .nullable()
                    .when('is_UK', {
                        is: true,
                        then: Yup.string().required('Security answer is required'),
                    }),
                answer_question_3: Yup.string()
                    .nullable()
                    .when('is_UK', {
                        is: true,
                        then: Yup.string().required('Security answer is required'),
                    }),

                ...memberCommonYupValidate,
            }),
        ),
    });
};

export default function Step3({
    activeStep,
    setActiveStep,
    isHongkong,
    body,
    setBody,
    isSVG,
    isLoading,
    setIsLoading,
    index,
    setIsActiveParentStep,
    disabled,
    hideAction,
    activeStepRef = {},
    validateStepsRef = {},
    hasShowSelectFullName,
}) {
    const [isShown, setIsShown] = useState(false);
    const [isShownLLC, setIsShownLLC] = useState(false);
    const [countryOperation, setCountryOperation] = useState([]);
    const [initForm, setInitForm] = useState({
        members: body.companyStructure?.Shareholders,
    });
    const [tabValue, setTabValue] = useState(0);
    let state = body.companyStructure?.Shareholders?.map((item) => item.individual) ?? [true];
    const [stateSwitch, setStateSwitch] = useState(state);
    const dispatch = useDispatch();
    const { orderInfo, businessActivityWithCountry, phonePrefixCountriesCooperation } = useSelector(
        (state) => ({
            orderInfo: state.orderForm.orderInfo,
            businessActivityWithCountry: state.enum.businessActivityWithCountry,
            phonePrefixCountriesCooperation: state.enum,
        }),
        shallowEqual,
    );
    const { countriesOptions } = useSelector((state) => state.enum);

    // total number of share
    const totalNumberShare = parseInt(body.Company.ShareCapital.number_share);

    const form = useForm({
        defaultValues: {
            members: initForm.members,
        },
        resolver: yupResolver(fieldArraySchema({ hasShowSelectFullName })),
    });

    const { fields, append, remove, update } = useFieldArray({
        control: form.control,
        name: 'members',
        keyName: 'customId',
    });

    const fieldAction = { update };

    const classes = useStyles();
    let isExceptValidateNumberShare = listCountryExceptStep.some(
        (e) => e.country_id === body.Company.country_id && e.idStep === 0,
    );

    // tab
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        const countryIds = form
            .watch('members')
            ?.filter((member) => member.country_of_incorporation !== 0)
            ?.map((member) => member?.country_of_incorporation);
        let countryCorporationIds = [];

        if (countryIds?.length) {
            countryIds.forEach((countryId) => {
                const normalListCountry = countryCorporationIds.filter(
                    (item) => ![CAYMAN_ID, UK_ID, RAK_ID].includes(item),
                );

                if (
                    ![CAYMAN_ID, UK_ID, RAK_ID].includes(countryId) &&
                    !normalListCountry?.length &&
                    !businessActivityWithCountry?.normalList?.length
                ) {
                    countryCorporationIds.push(countryId);
                }

                if (countryId === CAYMAN_ID && !businessActivityWithCountry?.caymanList?.length) {
                    countryCorporationIds.push(CAYMAN_ID);
                }
                if (countryId === UK_ID && !businessActivityWithCountry?.ukList?.length) {
                    countryCorporationIds.push(UK_ID);
                }
                if (countryId === RAK_ID && !businessActivityWithCountry?.rakList?.length) {
                    countryCorporationIds.push(RAK_ID);
                }
            });
        }

        countryCorporationIds
            .filter((countryCorporationId) => countryCorporationId > 0)
            .forEach((countryId) => {
                dispatch(actionsEnum.fetchBusinessActivity(countryId, true));
            });

        if (!countriesOptions?.length) {
            dispatch(actionsEnum.fetchCountries());
        }
    }, []);

    useEffect(() => {
        if (body && countriesOptions.length) {
            const tempCountryOperations = body.companyInfo.country_operations?.map((item) => ({
                ...item,
                value: item.id,
                label: item.name,
            }));
            setCountryOperation(tempCountryOperations);
        }
    }, [countriesOptions]);

    useEffect(() => {
        setInitForm({
            members: body.companyStructure?.Shareholders,
        });
        setStateSwitch(
            body.companyStructure?.Shareholders?.map((item) => item.individual) ?? [true],
        );
    }, [body]);

    const checkValid = (values) => {
        try {
            if (body.isLLC) {
                let currentPercentage = 0;
                values.members.map((item) => {
                    currentPercentage += parseInt(item.own_percent);
                });
                if (currentPercentage === 100) {
                    // Call api here
                    setIsShownLLC(false);
                    handleNext(values);
                } else {
                    setIsShownLLC(true);
                }
            } else {
                let currentTotalNumberShare = 0;
                values.members.map((item) => {
                    currentTotalNumberShare += parseInt(item.number_share);
                });
                if (isExceptValidateNumberShare) {
                    setIsShown(false);
                    handleNext(values);
                } else {
                    if (currentTotalNumberShare <= totalNumberShare) {
                        // Call api here
                        setIsShown(false);
                        handleNext(values);
                    } else {
                        setIsShown(true);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const checkNumShare = (e, idx, val) => {
        try {
            if (body.isLLC) {
                let currentValue = parseInt(e);
                let newArr = [...val];
                newArr.splice(idx, 1);
                newArr.forEach((s) => {
                    currentValue += parseInt(s.own_percent);
                });

                if (currentValue === 100) {
                    setIsShownLLC(false);
                } else {
                    setIsShownLLC(true);
                }
            } else {
                let currentValue = parseInt(e);
                let newArr = [...val];
                newArr.splice(idx, 1);
                newArr.forEach((s) => {
                    currentValue += parseInt(s.number_share);
                });

                if (isExceptValidateNumberShare) {
                    setIsShown(false);
                } else {
                    if (currentValue <= totalNumberShare) {
                        setIsShown(false);
                    } else {
                        setIsShown(true);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    // init form values
    const initFieldObject = {
        isSVG: isSVG,
        isPanama: body?.isPanama,
        is_UK_LLP: body.is_UK_LLP,
        is_UK: body?.is_UK_LLP ? body?.is_UK_LLP : body?.isUK,
        showOtherIncome: false,
        showOther: false,
        individual: true,
        full_name: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        occupation: '',
        phone_code: 0,
        phone: '',
        email: '',
        company_name: '',
        company_number: '',
        country_of_incorporation: 0,
        registered_address: '',
        date_of_incorporation: moment(new Date()).format('MM/DD/YYYY'),
        cash_in_bank_asset_1: null,
        real_estate: null,
        other_asset: '',
        other_money: null,
        investment_income: null,
        self_employed: null,
        other_detail_income: '',
        other_money_income: null,
        total_asset: null,
        total_income: null,
        type_member_id: 1,
        isCreate: true,
        number_alloted: null,
        company_id: body.Company.id,
        company_member_type_id: 2,
        country_id: 0,
        is_represented: false,
        number_share: 0,
        own_percent: 0,
        percentage: 100,
        answer_question_1: '',
        answer_question_2: '',
        answer_question_3: '',
        security_question_1: null,
        security_question_2: null,
        security_question_3: null,
        business_activity_array: [],
        business_activity_detail: '',
        country_operations: [],

        ...EmptyMemberCommon,
    };

    const handleChangeSwitch = (event, index) => {
        let currentState = [...stateSwitch];
        currentState[index] = event.target.checked;
        setStateSwitch(currentState);

        // xử lý switch type member => back về lại vẫn giữ nguyên thông tin
        const currentMember = fields[index];
        if (event.target.checked !== currentMember?.individual) {
            // nếu type khác type ban đầu thì clear data member
            form.setValue(`members.${index}`, {
                ...initFieldObject,
                country_id: currentMember?.country_id,
                country_of_incorporation: fields[index]?.country_of_incorporation,
                is_represented: currentMember?.is_represented,
                type_member_id: currentMember?.individual ? 1 : 2,
                individual: !currentMember?.individual,
                id: currentMember?.id || null,
                company_position_id: currentMember?.company_position_id,
            });
        } else {
            // nếu type ban đầu thì giữ nguyên thông tin init member
            if (body.companyStructure?.Shareholders?.[index]) {
                form.setValue(`members.${index}`, body.companyStructure?.Shareholders?.[index]);
            } else {
                form.setValue(`members.${index}`, initFieldObject);
            }
        }
    };

    // handle step
    const handleNext = (values) => {
        try {
            let checkLogic = true;
            if (
                body.represented_shareholder &&
                values.members.find((e) => e.is_represented === true) === undefined
            ) {
                checkLogic = false;
                Swal.fire({
                    icon: 'warning',
                    title: 'Warning!',
                    text: body.Represented.Shareholder.errorMessage,
                    confirmButtonColor: '#17c191',
                    confirmButtonText: 'OK',
                });
            }
            if (checkLogic) {
                setIsLoading(true);

                let is_chosen_user = [];

                let finalData = {
                    companyStructureCreate: [],
                    companyStructureUpdate: [],
                    answersForTask: [],
                };

                // flag to check if contact-info-member is up to date => update data in step Contact Information
                let flag = false;
                let contactInfoTemp = {};
                values.members.map((item, index) => {
                    let newItem = {};
                    let answersForTask = [];

                    if (item.is_contact_person === 1) {
                        const phone_prefix = getDefaultPhonePrefix(
                            item?.phone_code,
                            phonePrefixCountriesCooperation,
                        );
                        flag = true;
                        contactInfoTemp = {
                            ...body.contactInfo,
                            email: item.email,
                            first_name: item.first_name || '',
                            middle_name: item.middle_name || '',
                            last_name: item.last_name || '',
                            full_name: item.full_name || '',
                            phone_country_id: item.phone_code,
                            phone_number: item.phone,
                            phone_prefix: phone_prefix,
                        };
                    }

                    if (item.individual) {
                        newItem = {
                            country_id: item?.country_id || 0,
                            type_member_id: 1,
                            company_member_type_id: 2,
                            first_name: hasShowSelectFullName // các quốc gia không có first name && middle name && last name => cho first name = full name
                                ? item.first_name || ''
                                : item.full_name || '',
                            middle_name: item.middle_name || '',
                            last_name: item.last_name || '',
                            full_name: item.full_name || '',
                            major: item.occupation,
                            phone_country_id: item.phone_code?.id
                                ? item.phone_code.id
                                : item.phone_code,
                            phone_number: item.phone || null,
                            email: item.email || null,
                            id: item.id || null,
                        };
                    } else {
                        const objPhoneNumber = item.phone ? { phone_number: item.phone } : {};
                        newItem = {
                            country_id: item.country_of_incorporation || 0,
                            type_member_id: 2,
                            company_member_type_id: 2,
                            email: item.email || null,
                            id: item.id || null,
                            phone_country_id: item.phone_code.id
                                ? item.phone_code.id
                                : item.phone_code,
                            ...objPhoneNumber,
                            address: item.registered_address,
                            corporation_name: item.company_name || '',
                            corporation_number: item.company_number || '0',
                            corporation_date:
                                item.date_of_incorporation ||
                                moment(new Date()).format('MM/DD/YYYY'),
                            business_activity_array: item?.business_activity_array?.length
                                ? item.business_activity_array
                                : [],
                            business_activity_detail: item?.business_activity_detail ?? '',
                            OperationCountries: item?.country_operations?.length
                                ? item.country_operations
                                : [],
                        };
                    }

                    if (item.isAfterChooseExistingMember) {
                        is_chosen_user.push(item.id);
                    }

                    if (isSVG) {
                        newItem = {
                            ...newItem,
                            asset_source_fund_cast_in_bank: item.cash_in_bank_asset_1
                                ? parseInt(item.cash_in_bank_asset_1)
                                : 0,
                            asset_source_fund_real_estate: item.real_estate
                                ? parseInt(item.real_estate)
                                : 0,
                            asset_source_fund_other_detail: item.other_asset || '',
                            asset_source_fund_other_money: item.other_money
                                ? parseInt(item.other_money)
                                : 0,

                            income_source_fund_investment: item.investment_income
                                ? parseInt(item.investment_income)
                                : 0,
                            income_source_fund_self_employed: item.self_employed
                                ? parseInt(item.self_employed)
                                : 0,
                            income_source_fund_other_detail: item.other_detail_income
                                ? item.other_detail_income
                                : '',
                            income_source_fund_other_money: item.other_money_income
                                ? parseInt(item.other_money_income)
                                : 0,
                        };
                    }

                    newItem = {
                        ...newItem,
                        CompanyPositions: [
                            {
                                id: undefined,
                                company_member_type_id: 2,
                                is_appointed: Number(item.is_represented),
                                number_allotted: item.number_share
                                    ? parseInt(item.number_share)
                                    : 0,
                                own_percent: body.isLLC
                                    ? item.own_percent
                                        ? parseInt(item.own_percent)
                                        : 0
                                    : undefined,
                            },
                        ],
                        CompanyMemberReferences: body.isPanama ? item.references : undefined,
                    };

                    delete newItem.isAfterChooseExistingMember;
                    let question1;
                    let question2;
                    let question3;
                    if (body?.isUK) {
                        // find old selected answers

                        question1 = {
                            option_id: item.security_question_1,
                            value_text: item.answer_question_1,
                            is_chosen: 1,
                            company_member_id: item.id,
                        };
                        question2 = {
                            option_id: item.security_question_2,
                            value_text: item.answer_question_2,
                            is_chosen: 1,
                            company_member_id: item.id,
                        };
                        question3 = {
                            option_id: item.security_question_3,
                            value_text: item.answer_question_3,
                            is_chosen: 1,
                            company_member_id: item.id,
                        };
                        answersForTask.push(question1, question2, question3);
                    }

                    if (newItem.id) {
                        if (body.isUK) {
                            // clear old selection if has
                            let oldCham = body.Task.IncorporationCountry.Questions.find(
                                (q) => q.id === 17,
                            ).Options.filter((op) => {
                                return op.Answers.find((ans) => ans.company_member_id === item.id);
                            });
                            oldCham.forEach((selectedOpt) => {
                                if (selectedOpt.id !== question1.option_id) {
                                    let turnOff = {
                                        option_id: selectedOpt.id,
                                        is_chosen: 0,
                                        company_member_id: item.id,
                                    };
                                    answersForTask.push(turnOff);
                                }
                            });
                            let oldCham2 = body.Task.IncorporationCountry.Questions.find(
                                (q) => q.id === 18,
                            ).Options.filter((op) => {
                                return op.Answers.find((ans) => ans.company_member_id === item.id);
                            });
                            oldCham2.forEach((selectedOpt) => {
                                if (selectedOpt.id !== question2.option_id) {
                                    let turnOff = {
                                        option_id: selectedOpt.id,
                                        is_chosen: 0,
                                        company_member_id: item.id,
                                    };
                                    answersForTask.push(turnOff);
                                }
                            });
                            let oldCham3 = body.Task.IncorporationCountry.Questions.find(
                                (q) => q.id === 19,
                            ).Options.filter((op) => {
                                return op.Answers.find((ans) => ans.company_member_id === item.id);
                            });
                            oldCham3.forEach((selectedOpt) => {
                                if (selectedOpt.id !== question3.option_id) {
                                    let turnOff = {
                                        option_id: selectedOpt.id,
                                        is_chosen: 0,
                                        company_member_id: item.id,
                                    };
                                    answersForTask.push(turnOff);
                                }
                            });
                        }

                        if (item.company_position_id) {
                            newItem.CompanyPositions[0].id = item.company_position_id;
                        }
                        if (newItem.type_member_id === 1) {
                            if (item.is_contact_person) {
                                newItem.CompanyPositions[0].is_contact_person =
                                    item.is_contact_person;
                            }
                        }
                        delete newItem.company_member_type_id;
                        finalData.companyStructureUpdate.push(newItem);
                        finalData.answersForTask.push(...answersForTask);

                        if (newItem.type_member_id === 2) {
                            const oldMember = orderInfo?.Company?.CompanyMembers?.find(
                                (companyMember) => companyMember?.id === newItem.id,
                            );
                            const currentMemberPositions = oldMember?.CompanyPositions || [];
                            const uboPositionIds = currentMemberPositions
                                ?.filter((position) => position?.company_member_type_id === 3)
                                ?.map((position) => position?.id);
                            const hasContactMember = !!currentMemberPositions.find(
                                (position) => position?.company_member_type_id === 7,
                            );

                            if (
                                hasContactMember &&
                                oldMember.type_member_id !== newItem.type_member_id
                            ) {
                                const newMemberTemp = cloneDeep(newItem);
                                delete newMemberTemp.id;
                                delete newMemberTemp.CompanyPositions[0].id;
                                delete newMemberTemp.CompanyPositions[0].is_contact_person;

                                finalData.companyStructureDelete.company_position_ids = uniq([
                                    ...(finalData?.companyStructureDelete?.company_position_ids ||
                                        []),
                                    currentMemberPositions?.find(
                                        (position) => position?.company_member_type_id === 2,
                                    )?.id,
                                ]).filter((id) => id);

                                finalData.companyStructureCreate.push(newMemberTemp);
                                finalData.companyStructureUpdate =
                                    finalData.companyStructureUpdate.filter(
                                        (member) => member.id !== newItem.id,
                                    );
                            }

                            // xóa tất cả position UBO nếu type member là Corporation
                            if (uboPositionIds.length) {
                                finalData.companyStructureDelete.company_position_ids = uniq([
                                    ...(finalData?.companyStructureDelete?.company_position_ids ||
                                        []),
                                    ...uboPositionIds,
                                ]).filter((id) => id);
                            }
                        }
                    } else {
                        delete newItem.id;
                        const ans = answersForTask.map(answer => (
                            Object.entries(answer).reduce((a,[k,v]) => (v === null ? a : (a[k]=v, a)), {})
                        ))
                        newItem['answersForTask'] = ans;
                        finalData.companyStructureCreate.push(newItem);
                    }
                });

                finalData.answersForTask = uniqBy(
                    finalData.answersForTask.map((ans) => {
                        return {
                            ...ans,
                            option_id: Number(ans?.option_id),
                        };
                    }),
                    'option_id',
                );

                // API OK. UNCOMMENT IF NEEDED
                dispatch(actions.updateCompanyStructure(body.Task.id, finalData)).then((res) => {
                    try {
                        if (res && res.state) {
                            let bodyData = {
                                ...body,
                                Company: {
                                    ...body.Company,
                                    CompanyMembers: res.data.Company.CompanyMembers,
                                },
                            };

                            let companyStructureNew = formatCompanyMembers(
                                bodyData,
                                is_chosen_user,
                            );

                            setBody({
                                ...bodyData,
                                companyStructure: {
                                    // ...body.companyStructure,
                                    // Shareholders: newValues,
                                    ...companyStructureNew,
                                    shareholder_type_member_list: stateSwitch,
                                },
                                Task: {
                                    ...body.Task,
                                    IncorporationCountry: res.data.Task.IncorporationCountry,
                                },
                            });

                            if (flag) {
                                setBody((prevBody) => ({
                                    ...prevBody,
                                    contactInfo: contactInfoTemp,
                                }));
                            }

                            setIsLoading(false);
                            if (typeof activeStepRef.current !== 'number') {
                                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                            }
                            activeStepRef.current = null;
                        } else {
                            setIsLoading(false);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleBack = (indexStep) => {
        if (indexStep === 0 || activeStep === 0) {
            setIsActiveParentStep({ back: true, next: false });
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const deleteHandler = (item, index, values, firstMember) => {
        try {
            setIsLoading(true);
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    // if (item.id && item.company_position_id) {
                    if (item.company_position_id) {
                        const delData = {
                            companyStructureDelete: {
                                company_position_id: item.company_position_id,
                            },
                        };
                        dispatch(actions.updateCompanyStructure(body.Task.id, delData)).then(
                            (res) => {
                                try {
                                    if (res.state) {
                                        Swal.fire({
                                            icon: 'success',
                                            title: 'Deleted!',
                                            text: res.message,
                                        });

                                        let bodyData = {
                                            ...body,
                                            Company: {
                                                ...body.Company,
                                                CompanyMembers: res.data.Company.CompanyMembers,
                                            },
                                        };
                                        let companyStructureNew = formatCompanyMembers(bodyData);
                                        setBody({
                                            ...bodyData,
                                            Task: res.data.Task,
                                            companyStructure: { ...companyStructureNew },
                                        });

                                        let newTabValue = index === 0 ? 0 : index - 1;
                                        remove(index);
                                        setTabValue(newTabValue);
                                        let currentState = [...stateSwitch];
                                        currentState.splice(index, 1);
                                        setStateSwitch(currentState);
                                        if (values.length === 1) {
                                            setStateSwitch([true]);
                                            form.reset({
                                                members: [
                                                    {
                                                        ...initFieldObject,
                                                        country_id: firstMember?.country_id,
                                                        phone_code: firstMember?.phone_code,
                                                    },
                                                ],
                                            });
                                            setTabValue(0);
                                        }

                                        setIsLoading(false);
                                    } else {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: 'Something went wrong!',
                                        });
                                        setIsLoading(false);
                                    }
                                } catch (error) {
                                    console.log(error);
                                }
                            },
                        );
                    } else {
                        let newTabValue = index === 0 ? 0 : index - 1;
                        remove(index);
                        setTabValue(newTabValue);
                        let currentState = [...stateSwitch];
                        currentState.splice(index, 1);
                        setStateSwitch(currentState);
                        if (values.length === 1) {
                            setStateSwitch([true]);
                            form.reset({
                                members: [
                                    {
                                        ...initFieldObject,
                                        country_id: firstMember?.country_id,
                                        phone_code: firstMember?.phone_code,
                                    },
                                ],
                            });
                            setTabValue(0);
                        }

                        setIsLoading(false);
                    }
                } else {
                    setIsLoading(false);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    async function handleNextNominee({ reason }) {
        setIsLoading(true);
        try {
            const req = {
                reason_use_nominee_shareholder: {
                    is_has_shareholder: body.is_has_shareholder,
                    reason_use_nominee_shareholder: reason,
                },
            };

            const res = await dispatch(actions.updateCompanyStructure(body.Task.id, req));
            if (res && res.state) {
                const { data } = res;
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setBody((prev) => {
                    const newBody = cloneDeep(prev);
                    newBody.Task.reason_use_nominee_shareholder = get(
                        data,
                        'Task.reason_use_nominee_shareholder',
                    );
                    return newBody;
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleSubmit = (values) => checkValid(values);

    // truyền form validate cho file stepper
    validateStepsRef.current = {
        ...validateStepsRef.current,
        [`STEP_${index + 1}`]: {
            isValid: form.formState.isValid,
            onSubmit:
                body && body.is_has_shareholder
                    ? () => handleNextNominee()
                    : () => form.handleSubmit(handleSubmit)(),
        },
    };

    return (
        <div className="step3">
            {body && body.is_has_shareholder === true ? (
                <Nominee
                    name="reason"
                    onSubmit={handleNextNominee}
                    body={body}
                    initialValue={get(body, 'Task.reason_use_nominee_shareholder')}
                    companyMemberType={2}
                    handleBack={handleBack}
                    disabled={disabled}
                    hideAction={hideAction}
                    // handleNext={handleNextNoSubmit}
                />
            ) : (
                <>
                    {isShown ? (
                        <div className="text-danger">
                            The total number of shares owned by members must be less than or equal
                            to the number of shares issued.
                        </div>
                    ) : null}
                    {isShownLLC ? (
                        <div className="text-danger">
                            Total interest percentage of all Members must be 100
                        </div>
                    ) : null}
                    {/* {showNumberShareError ? <div className="text-danger">
                        The total number of shares owned by members must be equal to the number of shares issued.
                    </div> : null} */}
                    <form onSubmit={form.handleSubmit(handleSubmit)}>
                        <div className="control-tab mb-5">
                            <div className="tab-menu">
                                <Tabs value={tabValue} onChange={handleChangeTab} aria-label="tabs">
                                    {fields?.map((item, index) => {
                                        return (
                                            <Tab
                                                key={item.id}
                                                className="item"
                                                label={index + 1}
                                                id={item.id}
                                                aria-controls={`tabpanel-${index}`}
                                            />
                                        );
                                    })}
                                </Tabs>
                            </div>
                            {!hideAction && (
                                <button
                                    type="button"
                                    className="btn btn-add"
                                    onClick={(event) => {
                                        append(initFieldObject);

                                        setTabValue(fields?.length);
                                        let currentState = stateSwitch;
                                        currentState.push(true);

                                        setStateSwitch(currentState);
                                    }}
                                >
                                    <AddIcon />
                                </button>
                            )}
                        </div>

                        <div>
                            {fields?.map((item, index) => {
                                return (
                                    // TabPanel
                                    <div key={item.id} className="tab-panel">
                                        <div
                                            role="tabpanel"
                                            hidden={tabValue !== index}
                                            id={`tabpanel-${index}`}
                                            aria-labelledby={`simple-tab-${index}`}
                                        >
                                            {item?.member_id ? null : (
                                                <ExistingMemberDialog
                                                    index={index}
                                                    body={body}
                                                    setBody={setBody}
                                                    fieldAction={fieldAction}
                                                    companyMemberType={2}
                                                    stateSwitch={stateSwitch}
                                                    setStateSwitch={setStateSwitch}
                                                    form={form}
                                                />
                                            )}

                                            <div className="ant-switch">
                                                <span>Corporation</span>
                                                <Switch
                                                    checked={form.watch(
                                                        `members.${index}.individual`,
                                                    )}
                                                    onChange={(e) => handleChangeSwitch(e, index)}
                                                    name={`members.${index}.individual`}
                                                    disabled={disabled}
                                                />
                                                <span>Individual</span>
                                            </div>

                                            {form.watch(`members.${index}.individual`) !== null &&
                                            form.watch(`members.${index}.individual`) ? (
                                                <ShareholderIndividual
                                                    index={index}
                                                    body={body}
                                                    form={form}
                                                    values={form.watch('members')}
                                                    checkNumShare={checkNumShare}
                                                    disabled={disabled}
                                                    hasShowSelectFullName={hasShowSelectFullName}
                                                />
                                            ) : (
                                                <ShareholderCorporation
                                                    index={index}
                                                    isHongkong={isHongkong}
                                                    body={body}
                                                    setBody={setBody}
                                                    values={form.watch('members')}
                                                    checkNumShare={checkNumShare}
                                                    form={form}
                                                    countryOperation={countryOperation}
                                                    businessActivityWithCountry={
                                                        businessActivityWithCountry
                                                    }
                                                    disabled={disabled}
                                                />
                                            )}

                                            {isSVG ? (
                                                <SourseForFunds
                                                    index={index}
                                                    body={body}
                                                    values={form.watch('members')}
                                                    form={form}
                                                    disabled={disabled}
                                                />
                                            ) : null}
                                            {/* UK LLP  */}
                                            {body.isUK ? (
                                                <SecurityQuestion
                                                    index={index}
                                                    body={body}
                                                    values={form.watch('members')}
                                                    form={form}
                                                    disabled={disabled}
                                                />
                                            ) : null}

                                            <References
                                                index={index}
                                                body={body}
                                                values={form.watch('members')}
                                                form={form}
                                                disabled={disabled}
                                            />

                                            {
                                                // Responsible Party Signing the ss4 Form: is_represented
                                                body && body.represented_shareholder && (
                                                    <Represented
                                                        index={index}
                                                        body={body}
                                                        values={form.watch('members')}
                                                        form={form}
                                                        representedLable={
                                                            body.Represented.Shareholder.label
                                                        }
                                                        disabled={disabled}
                                                    />
                                                )
                                            }

                                            {!hideAction && (
                                                <div className="wrap-btn text-md-right mt-7">
                                                    <Button
                                                        name={'btn_delete_' + index}
                                                        className={
                                                            classes.button +
                                                            ' m-0 px-0 btn-delete btn-text'
                                                        }
                                                        disabled={isLoading}
                                                        onClick={(event) => {
                                                            const firstMember =
                                                                form.getValues('members.0');
                                                            deleteHandler(
                                                                item,
                                                                index,
                                                                fields,
                                                                firstMember,
                                                            );
                                                        }}
                                                        style={{
                                                            color: '#EB5757',
                                                            background: '#fff',
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {!hideAction && (
                            <div className={`mt-7 ${classes.actionsContainer}`}>
                                <div>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        disableElevation
                                        onClick={() => handleBack(index)}
                                        disabled={isLoading}
                                        className={'mr-2 ' + classes.button}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        disabled={isLoading}
                                        className={classes.button}
                                        style={{ color: '#fff' }}
                                        onClick={() => (activeStepRef.current = null)}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </div>
                        )}
                    </form>
                </>
            )}

            {}
        </div>
    );
}

import * as requestFromServer from './incorporationCrud';
import { incorporationSlice, callTypes } from './incorporationSlice';

const { actions } = incorporationSlice;

export const fetchCompanyInfo = (id, queryParams, isOnePackage) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getCompanyInfo(id, queryParams)
        .then((response) => {
            const data = response.data.data;
            if (!isOnePackage) dispatch(actions.entityFetched({ entity: data }));
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't find company";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};
export const fetchSubscription = (queryParams) => (dispatch) => {
    return requestFromServer
        .getSubscription()
        .then((response) => {
            const data = response.data.data;
            if (data.Subscription)
                dispatch(actions.subscriptionFetched({ subscription: data.Subscription }));
        })
        .catch((error) => {
            error.clientMessage = "Can't find subscription";
            throw error;
        });
};
export const fetchMembership = (queryParams) => (dispatch) => {
    return requestFromServer
        .getMembership()
        .then((response) => {
            const data = response.data.data;
            if (data && data.Subscription) {
                let service = data.Subscription.Service;
                // console.log(data.Subscription.Service.Fees[0].value)
                dispatch(
                    actions.discountFetched({
                        serviceDiscount: {
                            ...service,
                            quantity: 1,
                            Fees: [{ value: data.Subscription.Service.Fee.value }],
                        },
                    }),
                );
            }
        })
        .catch((error) => {
            error.clientMessage = "Can't find subscription";
            throw error;
        });
};
export const fetchAllAncillaryServiceOutside = (queryParams) => (dispatch) => {
    return requestFromServer
        .getAllAncillaryServiceOutside(queryParams)
        .then((response) => {
            const data = response.data.data;
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't find company";
            throw error;
        });
};
export const actionSetAccAndFiling = (payload) => (dispatch) => {
    dispatch(actions.setAccAndFiling(payload));
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getCompanyDetailById,
    getCompanyDocumentById,
    getCompanyShareOwnInterestById,
    getCompanyEventById,
    getCompletedEventById,
    getTransactionEventById,
    getComparedCompanyById
} from './companiesCrud';

export const getCompanyDetail = createAsyncThunk(
    'companies/getCompanyDetailById',
    async (id, thunkAPI) => {
        try {
            const response = await getCompanyDetailById(id);
            return response.data.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    },
);
export const getCompanyDocument = createAsyncThunk(
    'companies/getCompanyDocument',
    async (id, thunkAPI) => {
        try {
            const response = await getCompanyDocumentById(id);
            return response.data.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    },
);
export const getCompanyShareOwnInterest = createAsyncThunk(
    'companies/getCompanyShareOwnInterest',
    async (id, thunkAPI) => {
        try {
            const response = await getCompanyShareOwnInterestById(id);
            return response.data.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    },
);
export const getCompanyEvent = createAsyncThunk(
    'companies/getCompanyEvent',
    async (id, thunkAPI) => {
        try {
            const response = await getCompanyEventById(id);
            return response.data.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    },
);

export const getCompletedEvent = createAsyncThunk(
    'companies/getCompletedEvent',
    async (id, thunkAPI) => {
        try {
            const response = await getCompletedEventById(id);
            return response.data.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    },
);

export const getTransactionEvent = createAsyncThunk(
    'companies/getTransactionEvent',
    async (id, thunkAPI) => {
        try {
            const response = await getTransactionEventById(id);
            return response.data.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    },
);
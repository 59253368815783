import React from 'react';
import * as uiHelpers from '../../../OrderManagermentUIHelpers.js';
import { filterProcesses, filterProcessesFinished } from '../../OrdersUIHelpers.js';
import { Tooltip } from '@mui/material';
import { getAsProcessName } from '../../utils';

export const StatusFormatter = (cell, row, rowIndex, formatExtraData) => {
    const { serviceTypeMap } = formatExtraData;
    let ProcessFilter = filterProcesses(row);
    let ProcessesFinishedFilter = filterProcessesFinished(row);

    // Status Incorporation
    let final_step_incorporation, isWaitingIncorporation;
    if (ProcessFilter.Incorporation.Processes.length > 0) {
        let final_step_incorporation_id = Math.max.apply(
            Math,
            ProcessFilter.Incorporation.Processes.map(function (item) {
                return item.process_step_id;
            }),
        );
        final_step_incorporation = ProcessFilter.Incorporation.Processes.find(
            (e) => e.process_step_id === final_step_incorporation_id,
        );
        // let stepName = uiHelpers.getStep(final_process_step_id).name; // gán tạm để test, xong xóa
        isWaitingIncorporation =
            ProcessFilter.Incorporation.Processes.find(
                (e) => e.process_step_id === final_step_incorporation_id,
            ).is_approved === 1; // step cao nhất approved là lúc đang waiting, không có trường hợp Completed.
    }

    // Status Banking
    let final_step_banking, isWaitingBanking;
    if (ProcessFilter.Banking.Processes.length > 0) {
        let final_step_banking_id = Math.max.apply(
            Math,
            ProcessFilter.Banking.Processes.map(function (item) {
                return item.process_step_id;
            }),
        );
        final_step_banking = ProcessFilter.Banking.Processes.find(
            (e) => e.process_step_id === final_step_banking_id,
        );
        isWaitingBanking = final_step_banking?.is_approved === 1;
    }

    let finishAsProcessIds = ProcessesFinishedFilter.AdditionalServices.FinishedProcesses.map(
        (finishItem) => finishItem.addition_process_id,
    );

    return (
        <div className="status">
            {/* Incorporation */}
            {ProcessesFinishedFilter.Incorporation.FinishedProcesses ? ( // Incorporation has step Finished.
                <>
                    <div className="group-tag">
                        <span
                            className="order-tag"
                            style={{ backgroundColor: '#d5e8d4', color: '#555' }}
                        >
                            Incorporation -{' '}
                            {
                                ProcessesFinishedFilter.Incorporation.FinishedProcesses.ProcessStep
                                    .name
                            }
                        </span>
                    </div>
                </>
            ) : (
                <>
                    {ProcessFilter.Incorporation.Processes.length > 0 && (
                        <div className="group-tag">
                            {final_step_incorporation && (
                                <span className="order-tag" style={{ backgroundColor: '#27AE60' }}>
                                    Incorporation - {final_step_incorporation.ProcessStep.name}
                                </span>
                            )}

                            {isWaitingIncorporation && (
                                <span
                                    className="order-tag"
                                    style={{ backgroundColor: '#F2F2F2', color: '#828282' }}
                                >
                                    Incorporation Waiting
                                </span>
                            )}
                        </div>
                    )}
                </>
            )}

            {/* Banking */}
            {ProcessesFinishedFilter.Banking.FinishedProcesses ? (
                <div className="group-tag">
                    <span
                        className="order-tag"
                        style={{ backgroundColor: '#fff2cc', color: '#555' }}
                    >
                        Banking - Finished
                    </span>
                </div>
            ) : (
                ProcessFilter.Banking.Processes.length > 0 && (
                    <div className="group-tag">
                        {final_step_banking && (
                            <span className="order-tag" style={{ backgroundColor: '#ffa800' }}>
                                Banking - {final_step_banking.ProcessStep.name}
                            </span>
                        )}

                        {isWaitingBanking && (
                            <span
                                className="order-tag"
                                style={{ backgroundColor: '#F2F2F2', color: '#828282' }}
                            >
                                Banking Waiting
                            </span>
                        )}
                    </div>
                )
            )}

            {/* Additional Service */}
            {ProcessFilter.AdditionalServices.Processes.map((process) => (
                <span
                    key={process.id}
                    className="order-tag mb-1 mr-1"
                    style={{ backgroundColor: '#905d32' }}
                >
                    <Tooltip title={getAsProcessName(process, serviceTypeMap)}>
                        <span
                            style={{
                                maxWidth: '150px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'inline-block',
                                verticalAlign: 'middle',
                            }}
                        >
                            {getAsProcessName(process, serviceTypeMap)}
                        </span>
                    </Tooltip>
                    :{' '}
                    {finishAsProcessIds.includes(process.addition_process_id)
                        ? 'Finished'
                        : process.ProcessStep.name}
                </span>
            ))}

            {ProcessFilter.PostIncorporation.Processes.map((process) => (
                <span
                    key={process.id}
                    className="order-tag mb-1 mr-1"
                    style={{ backgroundColor: '#905d32' }}
                >
                    <Tooltip title={getAsProcessName(process, serviceTypeMap)}>
                        <span
                            style={{
                                maxWidth: '150px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'inline-block',
                                verticalAlign: 'middle',
                            }}
                        >
                            {getAsProcessName(process, serviceTypeMap)}
                        </span>
                    </Tooltip>
                    :{' '}
                    {finishAsProcessIds.includes(process.addition_process_id)
                        ? 'Finished'
                        : process.ProcessStep.name}
                </span>
            ))}

            {/* Change officers  */}
            {ProcessFilter.ChangeOfficers.Processes.map((process) => (
                <span
                    key={process.id}
                    className="order-tag mb-1 mr-1"
                    style={{ backgroundColor: '#905d32' }}
                >
                    <Tooltip title={getAsProcessName(process, serviceTypeMap)}>
                        <span
                            style={{
                                maxWidth: '150px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'inline-block',
                                verticalAlign: 'middle',
                            }}
                        >
                            {getAsProcessName(process, serviceTypeMap)}
                        </span>
                    </Tooltip>
                    :{' '}
                    {finishAsProcessIds.includes(process.addition_process_id)
                        ? 'Finished'
                        : process.ProcessStep.name}
                </span>
            ))}

            {/* Bookeeping Process  */}
            {ProcessFilter.BookeepingProcess.Processes.map((process) => (
                <span
                    key={process.id}
                    className="order-tag mb-1 mr-1"
                    style={{ backgroundColor: '#00D4FF' }}
                >
                    <Tooltip title={getAsProcessName(process, serviceTypeMap)}>
                        <span
                            style={{
                                maxWidth: '150px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'inline-block',
                                verticalAlign: 'middle',
                            }}
                        >
                            {getAsProcessName(process, serviceTypeMap)}
                        </span>
                    </Tooltip>
                    :{' '}
                    {finishAsProcessIds.includes(process.addition_process_id)
                        ? 'Finished'
                        : process.ProcessStep.name}
                </span>
            ))}
        </div>
    );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { ALL_ACCEPT_EXTENSION, isFilesAccept } from 'constants/extensions_accept';
import Swal from 'sweetalert2';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { downloadFileS3 } from 'app/pages/_redux/files/filesCrud';
import { handleScanFile } from 'app/helpers/scanfile';
import { Divider } from '@mui/material';
import moment from 'moment';

function getUnDuplicateArray(arr) {
    const temps = [];

    for (let i = 0; i < arr.length; i++) {
        let isDuplicated = false;
        for (let j = 0; j < temps.length; j++) {
            if (arr[i].id === temps[j].id) {
                isDuplicated = true;
                break;
            }
        }
        if (!isDuplicated) {
            temps.push(arr[i]);
        }
    }
    return temps;
}

const FileUpload = ({
    data,
    setAddedFiles,
    setDeletedFiles,
    deletedFiles,
    addedFiles,
    disabled,
    viewedFiles,
    setViewedFiles,
    isBankingDisabled,
    isViewMode = false,
    orderInfo = {},
    uploadNote
}) => {
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setViewedFiles(data?.Files || []);
    }, [data]);
    const handleFiles = (arr) => {
        const dataFiles = Array.isArray(data?.Files)
            ? data?.Files
            : Object.values(data?.Files || {});
        // const inputFiles = Array.from(arr);
        const addedTemps = [
            ...addedFiles.filter((item) => item.isValid !== false),
            ...Array.from(arr),
        ];
        // inputFiles.forEach((iFile) => {
        //     if (!dataFiles.some((dFile) => dFile.name === iFile.name)) {
        //         addedTemps.push(iFile);
        //     }
        // });
        setAddedFiles(addedTemps);
        const viewedTemps = [...dataFiles, ...addedTemps];
        const viewedFinal = [];
        viewedTemps.forEach((vFile) => {
            if (!deletedFiles.some((dFile) => dFile.id === vFile.id)) {
                viewedFinal.push(vFile);
            }
        });
        setViewedFiles(viewedFinal);
    };
    const inputOnChange = async (e) => {
        try {
            setIsLoading(true);
            if (!isFilesAccept(e.target.files, ALL_ACCEPT_EXTENSION)) {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Error',
                    text: 'Accept extensions: ' + ALL_ACCEPT_EXTENSION.join(', '),
                    showConfirmButton: false,
                    timer: 3000,
                });
                document.getElementById('file-upload-01').value = '';
                return;
            }
            const { files } = await handleScanFile(e.target.files);
            if (files?.length > 0) {
                handleFiles(files);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };
    const removeFileInList = (index) => {
        const dataFiles = Array.isArray(data?.Files)
            ? data?.Files
            : Object.values(data?.Files || {});
        const deletedTemps = [];
        const addedTemps = [];
        addedFiles.forEach((aFile) => {
            if (aFile.name !== viewedFiles[index].name) {
                addedTemps.push(aFile);
            }
        });
        const viewedTemps = viewedFiles.filter((file, vIndex) => vIndex !== index);
        const checkExisted = dataFiles.some((dFile) => dFile.name === viewedFiles[index].name);
        if (checkExisted) {
            deletedTemps.push(viewedFiles[index]);
        }
        setAddedFiles(addedTemps);
        setViewedFiles(viewedTemps);
        setDeletedFiles(getUnDuplicateArray([...deletedTemps, ...deletedFiles]));
        document.getElementById('file-upload-01').value = '';
    };
    const onDrop = useCallback(
        (acceptedFiles) => {
            handleFiles(acceptedFiles);
        },
        [viewedFiles],
    );

    const handleViewFile = async (file) => {
        try {
            const { data: res } = await downloadFileS3(file?.s3_path);
            if (res.state) {
                window.open(res.data, '_blank');
            }
        } catch (error) {}
    };
    const { getRootProps } = useDropzone({ onDrop });

    return (
        <>
            <div className="col-12 col-sm-12 col-md-5 col-lg-2 col-xl-3 kyc-frm-control kyc-upload-area d-flex">
                <label
                    {...getRootProps()}
                    htmlFor="file-upload-01"
                    className={`upload-panel d-flex ${
                        disabled || isBankingDisabled || isLoading
                            ? ' bg-dark cursor-not-allowed'
                            : ''
                    }`}
                >
                    <img
                        id="upload-area-01"
                        src="/media/svg/icons/Order/upload-file.svg"
                        width="30px"
                        height="30px"
                        alt="upload"
                    />
                    <p className={`${disabled || isBankingDisabled ? ' text-white' : ''}`}>
                        Upload your file here
                    </p>
                </label>
                <input
                    id="file-upload-01"
                    type="file"
                    onChange={inputOnChange}
                    multiple
                    disabled={disabled || isBankingDisabled || isLoading}
                />
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-4 col-xl-3 kyc-frm-control kyc-list-file">
                <div className="list-panel">
                    <h6 className="title">Your documents</h6>
                    <div className="items">
                        <List dense>
                            {viewedFiles.map((file, index) => (
                                <>
                                    <ListItem key={index} disableGutters alignItems="flex-start">
                                        <ListItemText
                                            primary={file.name}
                                            secondary={
                                                file?.created_time
                                                    ? moment(file?.created_time).format(
                                                          'DD MMM yyyy HH:mm:ss',
                                                      )
                                                    : ''
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            {!isBankingDisabled && !isViewMode && (
                                                <IconButton
                                                    onClick={() => removeFileInList(index)}
                                                    disabled={
                                                        disabled ||
                                                        orderInfo?.OrderItems?.find(
                                                            (item) =>
                                                                item?.Service?.service_type_id ===
                                                                39,
                                                        )
                                                    }
                                                    size="large"
                                                >
                                                    <img
                                                        src="/media/svg/icons/Order/delete.svg"
                                                        width="11px"
                                                        height="11px"
                                                        alt="delete"
                                                    />
                                                </IconButton>
                                            )}
                                            {isViewMode && (
                                                <IconButton
                                                    size="small"
                                                    alt="view-file"
                                                    onClick={() => handleViewFile(file)}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            )}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    {file.isValid === false && (
                                        <ListItem disablePadding>
                                            <ListItemText
                                                sx={{
                                                    '.MuiListItemText-primary': {
                                                        color: 'red',
                                                        fontSize: '10px',
                                                        marginTop: 0,
                                                    },
                                                }}
                                            >
                                                Invalid file. Please upload a valid file.
                                            </ListItemText>
                                        </ListItem>
                                    )}
                                    <Divider
                                        component={'li'}
                                        light
                                        sx={{
                                            borderColor:
                                                file.isValid === true
                                                    ? 'green'
                                                    : file.isValid === false && 'red',
                                        }}
                                    />
                                </>
                            ))}
                        </List>
                    </div>
                </div>
            </div>
            {uploadNote && (
                <div style={{ margin: '10px 0' }} className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 kyc-upload-note">
                    {uploadNote}
                </div>
            )}
        </>
    );
};

export default FileUpload;

import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import * as actionsProposedName from '../../../../../_redux/proposed-name/proposedNameActions';
import DeleteIcon from '@mui/icons-material/Delete';

import { RecommendName } from 'app/pages/components/check-name/recommend-name';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';

export default function PreventiveComponent({
    index,
    body,
    companySuffixOptions,
    isLoading,
    isPrefix,
    fields = [],
    form,
    name = 'preventives',
    disabled,
}) {
    const dispatch = useDispatch();
    const [preventiveSuffix, setPreventiveSuffix] = useState({ id: 0, name: '' });
    const [hintInput, setHintInput] = useState('');
    const { fields: fieldList } = fields;
    const disabledProposedName = isLoading || [false, true].includes(fieldList[index]?.is_approve);

    useEffect(() => {
        try {
            if (companySuffixOptions) {
                if (fieldList && fieldList.length > 0) {
                    if (fieldList[index]) {
                        form.setValue(`${name}.${index}.name`, fieldList[index].name);
                        let preventiveReturn = companySuffixOptions.find(
                            (e) => e.id === fieldList[index].company_suffix_id,
                        );
                        if (preventiveReturn) {
                            setPreventiveSuffix(preventiveReturn);
                            form.setValue(
                                `${name}.${index}.company_suffix_id`,
                                preventiveReturn.id,
                            );
                        } else {
                            setPreventiveSuffix(companySuffixOptions[0]);
                            if (companySuffixOptions[0]) {
                                form.setValue(
                                    `${name}.${index}.company_suffix_id`,
                                    companySuffixOptions[0]?.id,
                                );
                            }
                        }
                    }
                } else {
                    if (companySuffixOptions[0]) {
                        setPreventiveSuffix(companySuffixOptions[0]);
                        form.setValue(
                            `${name}.${index}.company_suffix_id`,
                            companySuffixOptions[0].id,
                        );
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [companySuffixOptions]);

    function handleChangePreventiveSuffix(value, index) {
        try {
            setPreventiveSuffix(value);
            form.setValue(`${name}.${index}.company_suffix_id`, value?.id);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div key={index} className="row">
            <div className="col-md-6 col-12 mb-7 has-check-name">
                <div className="input-line">
                    <label>Company name</label>
                    <InputField
                        form={form}
                        name={`${name}.${index}.name`}
                        id="other_name"
                        onKeyUp={(e) => setHintInput(e.target.value)}
                        onBlur={() => {
                            setTimeout(() => {
                                setHintInput('');
                            }, 200);
                        }}
                        placeholder={'Company name'}
                        hideSuccessHelper
                        disabled={disabledProposedName || disabled}
                        hideAutoCompleteBrowser
                    />
                </div>
                {!isPrefix && hintInput.length > 0 && (
                    <RecommendName
                        form={form}
                        input={hintInput}
                        fieldName={`${name}.${index}.name`}
                    />
                )}
            </div>
            <div className="col-md-5 col-10 mb-7 input-line">
                <label>Company Suffixes</label>
                <SelectField
                    form={form}
                    name={`${name}.${index}.company_suffix_id`}
                    id="company-suffixes"
                    options={companySuffixOptions && companySuffixOptions}
                    value={preventiveSuffix && preventiveSuffix}
                    onChange={(value) => {
                        handleChangePreventiveSuffix(value, index);
                    }}
                    disabled={disabledProposedName || disabled}
                />
            </div>
            <div className="col-md-1 col-2 mb-5 p-0">
                <IconButton
                    disabled={disabledProposedName || disabled}
                    onClick={() => {
                        if (fieldList[index]?.id) {
                            dispatch(
                                actionsProposedName.deletedProposedName(fieldList[index]?.id, {
                                    data: { task_id: body.Task?.id },
                                }),
                            ).then((res) => {
                                if (res && res.state) {
                                }
                            });
                        }
                        fields.remove(index);
                    }}
                    size="large"
                >
                    <DeleteIcon />
                </IconButton>
            </div>
            <div className="col-12 mb-7">
                <div className="separator separator-dashed"></div>
            </div>
        </div>
    );
}

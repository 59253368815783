import React, { useRef, useState, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';

const getFieldCSSClasses = (touched, errors) => {
    const classes = ['form-control'];
    if (touched && errors) {
        classes.push('is-invalid');
    }

    if (touched && !errors) {
        classes.push('is-valid');
    }

    return classes.join(' ');
};

const DatepickerInput = React.forwardRef(({ ...props }, ref) => <input type="text" {...props} readOnly />);

export default function DatePickerHookField({ form, name, onChange, hideHelperText = false, ...props }) {
    const { errors, touchedFields } = form.formState;
    const value = form.watch(name) || '';

    return (
        <Controller
            name={name}
            control={form.control}
            render={({
                field,
                fieldState: { isDirty, invalid, isTouched, error },
                formState: { isSubmitted, isValid },
            }) => {
                return (
                    <>
                        {props.label && <label>{props.label}</label>}
                        <DatePicker
                            name={name}
                            form={form}
                            dateFormat="dd/MM/yyyy"
                            className={getFieldCSSClasses(touchedFields[name], errors[name])}
                            style={{ width: '100%' }}
                            {...field}
                            {...props}
                            selected={(value && new Date(value)) || null}
                            onChange={(val) => {
                                form.setValue(name, val);
                                onChange && onChange(val);
                            }}
                            customInput={<DatepickerInput />}
                        />
                        {!hideHelperText && (
                            <>
                                {touchedFields[name] ? (
                                    errors[name] ? (
                                        props.customFeedbackErrorLabel ? (
                                            props.customFeedbackErrorLabel
                                        ) : (
                                            <div className="invalid-feedback">
                                                {errors[name].toString()}
                                            </div>
                                        )
                                    ) : props.customFeedbackValidLabel ? (
                                        props.customFeedbackValidLabel
                                    ) : (
                                        <div className="valid-feedback">
                                            {props.label} was entered correct
                                        </div>
                                    )
                                ) : props.customFeedbackLabel ? (
                                    props.customFeedbackLabel
                                ) : (
                                    <div className="feedback">
                                        Please select <b>{props.label}</b>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                );
            }}
        />
    );
}

/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SelectCompany } from './select-company/SelectCompany';
import CompanyInfo from './company-info/CompanyInfo';
import CompanyService from './company-service/CompanyService';
import { CompanyForm } from '../components/company-form/CompanyForm';

// === redux
import * as actionsBilling from 'app/pages/_redux/billings/billingsActions';
import * as actionsCompany from 'app/pages/_redux/companies/companiesActions';
import * as actionsEnum from 'app/pages/_redux/enum/enumActions';
import { getChangeServices } from 'app/pages/_redux/companies/companiesCrud';
//
import { StepsExistingCompany } from '../components/StepsConfig';
import { existingCompanyStyles } from '../components/UseStyles';
//
import { Review } from '../components/review/Review';
import { Billing } from '../components/billing/Billing';
import { PaymentUI } from '../components/payment-ui/PaymentUI';

import './existing-company.scss';
import * as Yup from 'yup';
import { PaymentFn } from '../components/payment-fn';
import * as actionsIncorporation from '../../_redux/incorporation/incorporationActions';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import Swal from 'sweetalert2';

const AddCompanySchema = Yup.object({
    company_name: Yup.string().required('Company Name is required').min(1),
    country_id: Yup.string().required('Incorporation Country is required'),
    // entity_type: Yup.string(),
    incorporation_date: Yup.date().nullable().required('Incorporation Date is required'),
    company_reg_no: Yup.string().required('Company Reg. No. is required'),
});

const errorsAddCompanyList = [
    {
        type: 'manual',
        name: 'company_name',
        message: 'Company Name is required',
    },
    {
        type: 'manual',
        name: 'incorporation_date',
        message: 'Incorporation Date is required',
    },
    {
        type: 'manual',
        name: 'company_reg_no',
        message: 'Company Reg. No. is required',
    },
];

const ANNUAL_RENEWAL_SERVICE_TYPE_ID = 18;

export default function ExistingCompanyPage() {
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState(new Set());
    const [skipped, setSkipped] = useState(new Set());
    const [isNewIncorp, setIsNewIncorp] = useState(false);
    const [param, setParam] = useState(null);
    const { incorporationState } = useSelector(
        (state) => ({
            incorporationState: state.incorporation,
        }),
        shallowEqual,
    );
    const location = useLocation();
    const companyId = location.state?.company_id;
    const eventCalendarInfo = location.state?.eventCalendarInfo;
    const [isBackToStep1, setBackToStep1] = useState(false);
    const [goToStep2, setGoToStep2] = useState(false);
    const [billingForm, setBillingForm] = useState();
    const [paymentMethod, setPaymentMethod] = useState('stripe');
    const [body, setBody] = useState({ existing: false });
    const formikRef = useRef();
    const [selectedValue, setSelectedValue] = useState('0');
    const [paidAmount, setPaidAmount] = useState(0);
    const { insideCompany } = useSelector(
        (state) => ({
            insideCompany: state.companies.insideCompany,
            outsideCompany: state.companies.outsideCompany,
        }),
        shallowEqual,
    );
    const [servicesChange, setServicesChange] = useState([]);
    const [selectedAnnualFee, setSelectedAnnualFee] = useState(false);
    const [servicesWithoutAnnualFee, setServicesWithoutAnnualFee] = useState([]);
    const annualFeeServices = useMemo(() => {
        if (!body?.packageIncorp?.PackageAnnual?.Services?.length) return [];

        return body?.packageIncorp?.PackageAnnual?.Services?.map((service) => {
            return {
                ...service,
                quantity: service?.quantity || 1,
            };
        });
    }, [body?.packageIncorp?.PackageAnnual]);

    let newBillingCompanyName = '';
    if (body && body.company_name) newBillingCompanyName = body.company_name;

    const dispatch = useDispatch();

    const classes = existingCompanyStyles();
    const { serviceTypeMap } = useSelector((state) => state.enum);

    const form = useForm({
        defaultValues: {
            company_name: '',
            country_id: '',
            incorporation_date: '',
            company_reg_no: '',

            entity_type_id: '',
            company_suffix_id: '',
        },
        mode: 'onChange',
        resolver: yupResolver(AddCompanySchema),
    });
    const { isValid, errors } = form.formState;

    useEffect(() => {
        dispatch(actionsCompany.fetchMyCompanies({ is_outside_company: 0 }));
        dispatch(actionsCompany.fetchMyCompanies({ is_outside_company: 1 }));
        dispatch(actionsBilling.fetchBillings(null));
        dispatch(actionsEnum.getWebsites());
        dispatch(actionsEnum.fetchCountriesNonRestricted());
        dispatch(actionsEnum.fetchCountriesHasService());
        dispatch(actionsEnum.getServiceType());
        dispatch(actionsIncorporation.fetchMembership());
    }, []);

    useEffect(() => {
        if (companyId && !eventCalendarInfo) {
            void handleGetChangeServices();
        }
    }, []);

    const handleGetChangeServices = async () => {
        try {
            const { data } = await getChangeServices(companyId);
            if (data.state) {
                setServicesChange(data.data);
            }
        } catch (error) {}
    };

    const steps = StepsExistingCompany();
    const uri = {
        paypal: {
            create: '/customer_account/incorporation/add_services/paypal',
            approve: '/customer_account/incorporation/paypal/authorize',
        },
        stripe: {
            create: '/customer_account/incorporation/add_services/stripe',
        },
        bank: {
            create: '/customer_account/incorporation/add_services/bank',
        },
        paynow: {
            create: '/customer_account/incorporation/payment_intent/add_services',
        },
    };

    function preParam() {
        try {
            let param = {};
            if (isNewIncorp) {
                param = {
                    ...param,
                    Company: {
                        name:
                            form.getValues('company_name') +
                            ' ' +
                            (body.entity_type ? body.entity_type : ''),
                        country_id: form.getValues('country_id'),
                        company_reg_no: form.getValues('company_reg_no'),
                        incorporation_date: form.getValues('incorporation_date'),
                    },
                };
                if (form.getValues('entity_type_id')) {
                    param.Company.entity_type_id = form.getValues('entity_type_id');
                }
                if (form.getValues('company_suffix_id')) {
                    param.Company.company_suffix_id = form.getValues('company_suffix_id');
                }
            } else {
                param = {
                    ...param,
                    company_id: body.company_id,
                };
                if (body.packageIncorp.id) param = { ...param, package_id: body.packageIncorp.id };
            }
            if (body.services && body.services.length !== 0) {
                param = {
                    ...param,
                    OrderItems: body.services.map((e) => {
                        return { service_id: e.id, quantity: e.quantity };
                    }),
                };
            }

            if (selectedAnnualFee) {
                const annualFeeServicesMap = annualFeeServices?.map((service) => {
                    return { service_id: service?.id, quantity: service?.quantity };
                });

                param = {
                    ...param,
                    OrderItems: [
                        ...servicesWithoutAnnualFee.map((e) => {
                            return { service_id: e.id, quantity: e.quantity };
                        }),
                        ...annualFeeServicesMap,
                    ],
                };
            }

            param = {
                uri: uri,
                payload: param,
            };

            setParam(param);
        } catch (error) {
            console.error(error);
        }
    }

    const selectedInsideCompany = insideCompany?.find((company) => company.id === body.company_id);
    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <div>
                        <SelectCompany
                            form={form}
                            setIsNewIncorp={setIsNewIncorp}
                            isNewIncorp={isNewIncorp}
                            body={body}
                            setBody={setBody}
                            selectedValue={selectedValue}
                            setSelectedValue={setSelectedValue}
                            companyId={companyId}
                            setBackToStep1={setBackToStep1}
                            goToStep2={goToStep2}
                            setSelectedAnnualFee={setSelectedAnnualFee}
                        />
                        {isNewIncorp ? (
                            <div>
                                <CompanyForm form={form} body={body} setBody={setBody} />
                                <CompanyInfo
                                    selectedValue={selectedValue}
                                    body={body}
                                    setBody={setBody}
                                    isNewIncorp={isNewIncorp}
                                    form={form}
                                />
                            </div>
                        ) : (
                            <CompanyInfo
                                selectedValue={selectedValue}
                                body={body}
                                setBody={setBody}
                                isNewIncorp={isNewIncorp}
                                form={form}
                            />
                        )}
                        {body.packageIncorp && (
                            <CompanyService
                                body={body}
                                setBody={setBody}
                                serviceTypeMap={serviceTypeMap}
                                serviceDiscount={
                                    [191, 237, 250].includes(selectedInsideCompany?.country_id)
                                        ? null
                                        : incorporationState.serviceDiscount
                                }
                                // group={group}
                                // category={category}
                                servicesChange={servicesChange}
                                eventCalendarInfo={eventCalendarInfo}
                                companyId={companyId}
                                goToStep2={goToStep2}
                                annualFeeServices={annualFeeServices}
                                selectedAnnualFee={selectedAnnualFee}
                                setSelectedAnnualFee={setSelectedAnnualFee}
                                servicesWithoutAnnualFee={servicesWithoutAnnualFee}
                                setServicesWithoutAnnualFee={setServicesWithoutAnnualFee}
                            />
                        )}
                        <div className="mt-5 text-md-right">
                            {activeStep < steps.length - 1 && (
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    style={{ color: '#fff' }}
                                    onClick={() => {
                                        let showConfirmPopup = false;
                                        if (selectedAnnualFee) {
                                            setBody({
                                                ...body,
                                                services: [...body?.services, ...annualFeeServices],
                                            });
                                        }

                                        errorsAddCompanyList.forEach(({ name, type, message }) => {
                                            if (!form.getValues(name)) {
                                                form.setError(name, { type, message });
                                            }
                                        });

                                        const hasSelectedAnnualRenewalService =
                                            body?.services?.some(
                                                (service) =>
                                                    service.service_type_id ===
                                                    ANNUAL_RENEWAL_SERVICE_TYPE_ID,
                                            );
                                        if (
                                            body?.order_renew_processing?.length &&
                                            hasSelectedAnnualRenewalService
                                        ) {
                                            showConfirmPopup = true;
                                            Swal.fire({
                                                text: 'There is an Annual Renewal service that has not been completed yet, please confirm if you still wish to proceed?',
                                                icon: 'warning',
                                                showCancelButton: true,
                                                cancelButtonText: 'No, Cancel',
                                                confirmButtonText: 'Yes, Confirm',
                                                confirmButtonColor: '#17C191',
                                                reverseButtons: true,
                                                allowOutsideClick: false,
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    if (isNewIncorp) {
                                                        setBody({
                                                            ...body,
                                                            ...form.getValues(),
                                                        });
                                                        if (isValid) {
                                                            preParam();
                                                            handleNext();
                                                            setGoToStep2(true);
                                                        }
                                                    } else {
                                                        preParam();
                                                        handleNext();
                                                        setGoToStep2(true);
                                                    }
                                                }
                                            });
                                        }
                                        if (showConfirmPopup) return;

                                        if (isNewIncorp) {
                                            setBody({
                                                ...body,
                                                ...form.getValues(),
                                            });
                                            if (isValid) {
                                                preParam();
                                                handleNext();
                                                setGoToStep2(true);
                                            }
                                        } else {
                                            preParam();
                                            handleNext();
                                            setGoToStep2(true);
                                        }
                                    }}
                                    disabled={
                                        body.services
                                            ? body.services.length !== 0 || selectedAnnualFee
                                                ? false
                                                : true
                                            : true
                                    }
                                >
                                    Next
                                </Button>
                            )}
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div>
                        <CompanyInfo
                            body={body}
                            setBody={setBody}
                            isNewIncorp={isNewIncorp}
                            form={form}
                        />
                        <Review
                            body={body}
                            services={body.services}
                            packageIncorp={body.packageIncorp}
                            existing={true}
                            setPaidAmount={setPaidAmount}
                            serviceDiscount={
                                [191, 237, 250].includes(selectedInsideCompany?.country_id)
                                    ? null
                                    : incorporationState.serviceDiscount
                            }
                        />
                        <Billing
                            setBody={setBody}
                            body={body}
                            newBillingCompanyName={newBillingCompanyName}
                            isNewIncorp={isNewIncorp}
                            setIsNewIncorp={setIsNewIncorp}
                            setForm={setBillingForm}
                        />

                        <PaymentUI
                            countryID={body?.country_id}
                            paymentMethod={paymentMethod}
                            setPaymentMethod={setPaymentMethod}
                        />
                        <div className="text-center mb-5">
                            <PaymentFn
                                body={body}
                                countryID={body.country_id}
                                param={param}
                                paymentMethod={paymentMethod}
                                isNewIncorp={isNewIncorp}
                                paidAmount={paidAmount}
                                form={billingForm}
                            />
                        </div>
                        <div className="mt-5 text-md-right">
                            <Button size="large" onClick={handleBack} className={classes.button}>
                                Back
                            </Button>
                        </div>
                    </div>
                );
            default:
                return 'Unknown step';
        }
    }

    const totalSteps = () => StepsExistingCompany().length;

    const isStepOptional = (step) => step === 1;

    const skippedSteps = () => skipped.size;

    const completedSteps = () => completed.size;

    const allStepsCompleted = () => completedSteps() === totalSteps() - skippedSteps();

    const isLastStep = () => activeStep === totalSteps() - 1;

    function handleNext() {
        if (activeStep === 1) {
            if (formikRef.current) {
                formikRef.current.handleSubmit();
                if (formikRef.current.isValid && body.company_name) {
                    preParam();
                }
            } else {
                preParam();
            }
        }

        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed
                  // find the first step that has been completed
                  steps.findIndex((step, i) => !completed.has(i))
                : activeStep + 1;

        setActiveStep(newActiveStep);
    }

    const handleBack = () => {
        if (body?.packageIncorp?.PackageAnnual) {
            setBody({
                ...body,
                services: [...servicesWithoutAnnualFee],
            });
        }

        setActiveStep((prevActiveStep) => {
            return prevActiveStep - 1;
        });
    };

    const handleStep = (step) => () => {
        if (activeStep === 1 && step === 1) return;

        if (selectedAnnualFee) {
            setParam({
                ...param,
                payload: {
                    ...param?.payload,
                    OrderItems: [],
                },
            });
        }

        if (activeStep === 0 && step === 1) {
            if (selectedAnnualFee) {
                setBody({
                    ...body,
                    services: [...servicesWithoutAnnualFee, ...annualFeeServices],
                });
            }
            if (formikRef.current) {
                formikRef.current.handleSubmit();
                if (formikRef.current.isValid && body.company_name) {
                    preParam();
                }
            } else {
                preParam();
            }
        }

        if (activeStep === 1 && step === 0) {
            setBody({
                ...body,
                services: [...servicesWithoutAnnualFee],
            });
        }

        if ((body.services && body.services.length !== 0) || selectedAnnualFee) setActiveStep(step);
    };

    function handleReset() {
        setActiveStep(0);
        setCompleted(new Set());
        setSkipped(new Set());
    }

    const isStepSkipped = (step) => skipped.has(step);

    const isStepComplete = (step) => completed.has(step);

    return (
        <div className={classes.root + ' p-8'} style={{ background: '#fff' }}>
            <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const buttonProps = {};
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepButton
                                onClick={handleStep(index)}
                                completed={isStepComplete(index)}
                                {...buttonProps}
                            >
                                {label}
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                {allStepsCompleted() ? (
                    <div>
                        <Typography className={classes.instructions}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </div>
                ) : (
                    <div>
                        <div style={{ minHeight: '40vh' }}>{getStepContent(activeStep)}</div>
                    </div>
                )}
            </div>
        </div>
    );
}

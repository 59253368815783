import axios from 'axios';

export const BILLING_URL = '/customer_account/billing';

export function createBilling(billing) {
    return axios.post(BILLING_URL, billing);
}

export function getAllBillings() {
    return axios.get(BILLING_URL);
}

export function getBillingById(billingId) {
    return axios.get(`${BILLING_URL}/${billingId}`);
}

export function findBillings(queryParams) {
    return axios.get(`${BILLING_URL}`, { params: queryParams });
}

export function updateBilling(billing) {
    return axios.put(`${BILLING_URL}/${billing.id}`, billing);
}

export function deleteBilling(billingId) {
    return axios.delete(`${BILLING_URL}/${billingId}`);
}

// DELETE Customers by ids
export function deleteBillings(ids) {
    return axios.delete(
        `${BILLING_URL}/delete_multi?ids=${encodeURIComponent(JSON.stringify(ids))}`,
    );
}

import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import { COMPANY_MEMBER_TYPES } from 'app/pages/services/constants';

const MemberItem = ({ index, member, handleSelectMember, handleSendOnce, sendSelectedLoading, memberRef }) => {
    const checkCompanyMemberType = (company_member_type_id, member) => {
        return member.CompanyPositions.filter(pos => pos.company_member_type_id === company_member_type_id).length > 0;
    }

    return (
        <>
            {index > 0 && <div className="dashed-divider" />}
            <li className="row m-0">
                <div className="col-1 px-0 mt-1">
                    <input
                        type="checkbox"
                        checked={member?.is_selected}
                        onChange={() => handleSelectMember(member?.id)}
                        disabled={memberRef?.loading || sendSelectedLoading}
                    />
                </div>
                <div className="col-11 pl-2 pr-0">
                    <div className="l-height-22">
                        {`${member?.first_name || ''} ${member?.last_name || ''}${member?.is_represented ? '/ representative' : ''
                            }`}
                    </div>
                    <div className="l-height-22">{(member && member.email && !member.is_nominee) ? member.email : ''}</div>
                    <div className="d-flex flex-wrap my-2">
                        {checkCompanyMemberType(COMPANY_MEMBER_TYPES.DIRECTOR.id, member) ? <Chip label="Director" color="primary" className="text-white mr-1" size="small" /> : <></>}
                        {checkCompanyMemberType(COMPANY_MEMBER_TYPES.SHAREHOLDER.id, member) ? <Chip label="Shareholder" color="primary" className="text-white mr-1" size="small" /> : <></>}
                        {checkCompanyMemberType(COMPANY_MEMBER_TYPES.UBO.id, member) ? <Chip label="UBO" color="primary" className="text-white mr-1" size="small" /> : <></>}
                    </div>
                    {member && member.is_signed ? (
                        <div className="signed l-height-22">Signed</div>
                    ) : (
                        <div className="not-sign l-height-22">Not sign</div>
                    )}
                    <div className="d-flex justify-content-end align-items-center">
                        {memberRef?.loading && (
                            <CircularProgress color="primary" size={13} className="mr-1" />
                        )}
                        <Button
                            color="primary"
                            size="small"
                            onClick={() => handleSendOnce(member || null)}
                            disabled={memberRef?.loading || sendSelectedLoading}
                        >
                            {/* {memberRef && memberRef.is_send ? 'Re-send' : 'Send'} */}
                            Re-send
                        </Button>
                    </div>
                </div>
            </li>
        </>
    );
};

export default function MemberList({
    mailingMembers,
    handleSelectMember,
    handleSelectAll,
    handleDeselectAll,
    sendMails,
    setMailingMembers,
    setMemberLoading,
    memberLoading,
    stepIndex
}) {
    const boxTitle = stepIndex === 2 ? 'SEND ORDER TO GET SIGNATURE' : (stepIndex === 3 ? 'SEND DOCUMENT FOR SIGNATURE' : 'SEND FILE FOR SIGNATURE');

    const [sendSelectedLoading, setSendSelectedLoading] = useState(false);
    const memberRefs = useRef([]);
    useEffect(() => {
        if (mailingMembers) {
            if (memberRefs.current.length === 0) {
                mailingMembers.forEach((member) => {
                    memberRefs.current.push({
                        id: member.id,
                        loading: false,
                        is_send: member.is_send,
                    });
                });
            }

            if (memberLoading !== memberRefs.current.some(member => member.loading)) {
                setMemberLoading(memberRefs.current.some(member => member.loading));
            }
        }
    }, [mailingMembers, memberLoading, setMemberLoading]);

    const handleStatusOnce = (member, status) => {
        if (member) {
            const memberTemps = [...mailingMembers];
            const index = memberTemps.findIndex((memberTemp) => memberTemp.id === member.id);
            memberTemps[index] = { ...memberTemps[index], ...status };
            memberRefs.current[index] = { ...memberRefs.current[index], ...status };
            setMailingMembers(memberTemps);
        }
    };

    const handleSendOnce = (member) => {
        if (member) {
            handleStatusOnce(member, { loading: true });
            sendMails([member.id])
                .then(() => {
                    handleStatusOnce(member, { loading: false, is_send: true });
                })
                .catch(() => {
                    handleStatusOnce(member, { loading: false });
                });
        }
    };

    const handleStatusSelected = (isSuccess) => {
        const memberTemps = [...mailingMembers];
        if (isSuccess) {
            memberTemps.forEach((member) => {
                if (member.is_selected) {
                    member.is_send = true;
                }
            });
            setMailingMembers(memberTemps);
        }
    };
    const handleSendSelected = () => {
        const selectedIdList = [];
        mailingMembers.forEach((member) => {
            if (member.is_selected) {
                selectedIdList.push(member.id);
            }
        });
        setSendSelectedLoading(true);
        sendMails(selectedIdList)
            .then(() => {
                setSendSelectedLoading(false);
                handleStatusSelected(true);
            })
            .catch(() => {
                setSendSelectedLoading(false);
            });
    };

    const isSomeSelected = () => {
        return mailingMembers && mailingMembers.some((member) => member.is_selected);
    };

    return (
        <div className="d-flex flex-column h-100 position-relative">
            {sendSelectedLoading && (
                <div
                    className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
                    style={{
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: 200,
                    }}
                >
                    <CircularProgress color="primary" size={20} className="mr-1" />
                </div>
            )}
            <div className="border-top border-bottom pt-5 pb-2">
                <h6 className="text-uppercase text-center font-weight-bolder mb-3">
                    {boxTitle}
                </h6>
                <div className="px-3">
                    {isSomeSelected() ? (
                        <Button color="primary" onClick={handleDeselectAll}>
                            De-selected
                        </Button>
                    ) : (
                        <Button color="primary" onClick={handleSelectAll}>
                            Select all
                        </Button>
                    )}
                </div>
            </div>
            <div className="member-list p-3 position-relative">
                {/* Director/Manager */}
                <ul className="list-unstyled">
                    {mailingMembers.length > 0 &&
                        mailingMembers.map((member, index) => (
                            <MemberItem
                                key={index}
                                index={index}
                                member={member}
                                handleSelectMember={handleSelectMember}
                                handleSendOnce={handleSendOnce}
                                sendSelectedLoading={sendSelectedLoading}
                                memberRef={memberRefs.current.find((ref) => ref.id === member.id) || null}
                            />
                        ))}
                </ul>
            </div>
            <div className="d-flex justify-content-center p-3 border-top">
                <Button
                    variant="contained"
                    color="primary"
                    className="text-white"
                    size="small"
                    onClick={handleSendSelected}
                    disabled={!isSomeSelected() || sendSelectedLoading}
                >
                    Send selected
                </Button>
            </div>
        </div>
    );
}

import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from 'app/components/LoadingButton';
import {
    getProcessDetail,
    getYearsList,
    saveYearsEvent,
} from 'app/pages/_redux/additional-service/api';
import { updateOrderInfo } from 'app/pages/_redux/additional-service/slice';
import SelectField from 'components/form-controls/SelectField';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

const DocumentForYear = ({
    orderId,
    eventYears,
    quantity,
    setActiveStep,
    validateStepsRef,
    activeProcess,
    onSubmit,
    isARService
}) => {
    const dispatch = useDispatch();

    const [yearsList, setYearsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        years_ids: Yup.array()
            .nullable()
            .length(
                quantity,
                'The number of years you choose must be equal to the number of services you purchase. Please choose again.',
            ),
    });

    const form = useForm({
        defaultValues: {
            years_ids: [],
        },
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (yearsList.length > 0 && quantity > 0) {
            if (eventYears && eventYears?.length > 0) {
                form.setValue('years_ids', eventYears.map(item => item.year));
            } else {
                const years = yearsList.slice(0, quantity);
                form.setValue(
                    'years_ids',
                    years.map((e) => {
                        return e.id;
                    }),
                );
            }
        }
    }, [yearsList]);

    useEffect(() => {
        void handleGetYearsList(orderId);
    }, []);

    const handleGetYearsList = async (orderId) => {
        if (!orderId) return;
        const { data } = await getYearsList(orderId);
        const list =
            data?.data?.year_not_buy_list?.map((item, index) => ({ id: item, name: item })) || [];
        setYearsList(list);
    };

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);
            const payload = {
                year_list: values.years_ids,
            };
            const { data } = await saveYearsEvent(orderId, payload);
            if (data && data.state) {
                if (onSubmit) {
                    onSubmit();
                } else {
                    const {
                        data: { data },
                    } = await getProcessDetail(activeProcess.Processes[0].id);
                    dispatch(updateOrderInfo(data));
                    setActiveStep && setActiveStep(1);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    if (validateStepsRef?.current) {
        validateStepsRef.current = {
            ...validateStepsRef.current,
            STEP_1: {
                isValid: form.formState.isValid,
                onSubmit: () => form.handleSubmit(handleSubmit)(),
            },
        };
    }

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="row">
                <div className="my-5 col-4">
                    <label>Year(s)</label>
                    <SelectField
                        name="years_ids"
                        multiple
                        form={form}
                        options={yearsList}
                        hideSuccessHelper
                        hideHelperText
                        hideInputHelperText
                        filterSelectedOptions
                    />
                    {form.formState.errors.years_ids && (
                        <small className={'text-danger'}>
                            {form.formState.errors.years_ids.message}
                        </small>
                    )}
                </div>
            </div>
            <LoadingButton
                variant="contained"
                type="submit"
                size="large"
                color="primary"
                disabled={isLoading}
                loading={isLoading}
                style={{ color: '#fff' }}
            >
                Next
            </LoadingButton>
        </form>
    );
};

export default DocumentForYear;

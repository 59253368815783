import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { confirm, resend_verification_code } from '../_redux/authCrud';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// import * as _ from 'lodash';
import { get } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import InputField from 'components/form-controls/InputField';
import './ForgotPassword.scss';

const initialValues = {
    auth2FAToken: '',
};

function TwoFactor(props) {
    const { intl } = props;
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingResend, setLoadingResend] = useState(false);
    const ggCaptchaEl = useRef(null);
    const confirmSchema = Yup.object({
        auth2FAToken: Yup.string()
            .test('length', 'Verification code is invalid', (val) => {
                if (val === undefined) {
                    return true;
                }
                return val.length === 0 || val.length === 6;
            })
            // .length(6, 'Verication code is invalid')
            .required(
                intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                }),
            ),
    }).required();
    const navigate = useNavigate();
    const location = useLocation();
    const [isSubmitted, setSubmited] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null);
    const TIME_REMAIN = 30;

    const form = useForm({
        defaultValues: initialValues,
        mode: 'onChange',
        revalidateMode: 'onChange',
        resolver: yupResolver(confirmSchema),
    });
    const { errors, touchedFields } = form.formState;
    const { isSubmitting, isValid } = form.formState;

    useEffect(() => {
        let timeout;
        if (!location?.state?.two_factor?.username) {
            navigate('/auth/login');
        } else {
            const splashScreen = document.getElementById('splash-screen');
            if (!splashScreen.classList.contains('hidden')) {
                // khi loading tắt mới set time
                timeout = setTimeout(() => {
                    setTimeLeft(TIME_REMAIN);
                }, 3000);
            } else {
                setTimeLeft(TIME_REMAIN);
            }
        }

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        if (timeLeft === 0) {
            setTimeLeft(null);
        }

        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (touchedFields[fieldname] && !isValid) {
            return 'is-invalid';
        }

        if (touchedFields[fieldname] && isValid) {
            return 'is-valid';
        }

        if (form.getValues(fieldname).length !== 6 && isSubmitted) {
            return 'is-invalid';
        }

        if (form.getValues(fieldname).length === 6 && isSubmitted) {
            return 'is-valid';
        }

        return '';
    };

    const handleSubmit = (values) => {
        enableLoading();
        if (location.state.two_factor && location.state.two_factor.username) {
            ggCaptchaEl.current.executeRecaptcha().then((token) => {
                confirm(location.state.two_factor.username, values.auth2FAToken, token)
                    .then((response) => {
                        const responseData = get(response, 'data.data');
                        const rememberPath = localStorage.getItem('REMEMBER_PATH');
                        if (responseData.access_token && responseData.refresh_token) {
                            props.login({
                                access_token: responseData.access_token,
                                refresh_token: responseData.refresh_token,
                                access_token_exp: responseData.access_token_exp,
                            });
                            if (rememberPath) {
                                navigate(rememberPath);
                            } else {
                                navigate('/');
                            }
                        }
                    })
                    .catch(() => {
                        disableLoading();
                        setStatus(
                            intl.formatMessage({
                                id: 'AUTH.VALIDATION.INVALID_LOGIN',
                            }),
                        );
                    });
            });
        } else {
            navigate('/auth/login');
        }
    };

    const handleResend = async (time) => {
        setLoadingResend(true);
        try {
            const { username } = location?.state?.two_factor;
            const token = await ggCaptchaEl.current.executeRecaptcha();
            const response = await resend_verification_code(username, token);
            if (response?.data?.state) {
                setTimeLeft(time);
            }
        } catch (error) {
        } finally {
            setLoadingResend(false);
        }
    };

    return (
        <>
            <div className="login-form login-validation" id="kt_login_signin_form">
                <div className="logo">
                    <img
                        src={'/media/logos/logo-new.png'}
                        width={177}
                        height={49}
                        alt="BBCIncorp"
                    />
                </div>
                <div>
                    <h3 className="title d-inline-block">Validation Code?</h3>
                </div>
                <div>
                    <p className="description d-inline-block">
                        Enter the code we've texted to{' '}
                        <span className="mail">{location?.state?.two_factor?.email || ''}</span>{' '}
                        below
                    </p>
                </div>
                <form
                    onSubmit={form.handleSubmit(handleSubmit)}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    {status ? (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{status}</div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                    <div className="fv-plugins-icon-container">
                        <label>Validation code</label>
                        <InputField
                            name="auth2FAToken"
                            form={form}
                            placeholder="Enter your validation code"
                            hideHelperText
                            customStyle={{
                                '& .MuiInputBase-input': {
                                    padding: '12px 16px',
                                    border: '1px solid #BEBEBF',
                                    borderRadius: '4px',
                                    color: '#0F172A',
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    fontWeight: '400',
                                    height: '24px',
                                    letterSpacing: '-0.35px',
                                },
                                '& .MuiInputBase-input::-webkit-input-placeholder': {
                                    color: '#94A3B8',
                                },
                                '& .MuiInputBase-input:focus': {
                                    border: '1px solid #A3A7AD !important',
                                },
                                '& .MuiInputBase-input:hover': {
                                    border: '1px solid #A3A7AD !important',
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottom: 'none !important',
                                },
                                '& .MuiInput-root:before': {
                                    borderBottom: 'none !important',
                                },
                                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottom: 'none !important',
                                },
                            }}
                        />
                        {errors.auth2FAToken ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{errors.auth2FAToken.message}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="d-flex flex-wrap flex-center group-button">
                        <button
                            id="resend-btn"
                            disabled={loadingResend || Boolean(timeLeft) || isSubmitting}
                            onClick={() => handleResend(TIME_REMAIN)}
                            className={`btn btn-resend ${timeLeft !== null ? 'px-6' : 'px-9'}`}
                        >
                            <span>Re-send</span>
                            {loadingResend && <span className="ml-3 spinner spinner-white"></span>}
                            {timeLeft !== null ? (
                                <span className="ml-1">({timeLeft}s)</span>
                            ) : (
                                <></>
                            )}
                        </button>
                        <button
                            id="kt_login_signin_submit"
                            type="submit"
                            disabled={isSubmitting}
                            onClick={() => {
                                form.setFocus('auth2FAToken');
                                setSubmited(true);
                            }}
                            className={`btn btn-submit`}
                        >
                            <span>Submit</span>
                            {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button>
                    </div>
                    <div>
                        <GoogleReCaptchaProvider
                            ref={ggCaptchaEl}
                            reCaptchaKey={process.env.REACT_APP_RECAPTCHA}
                            language="en"
                            name="recaptcha-token"
                            scriptProps={{
                                async: false, // optional, default to false,
                                defer: false, // optional, default to false
                                appendTo: 'head', // optional, default to "head", can be "head" or "body",
                                nonce: undefined, // optional, default undefined
                            }}
                        >
                            {' '}
                        </GoogleReCaptchaProvider>
                    </div>
                </form>
            </div>
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(TwoFactor));

/*
* Structure of DATA use in fuction findBankData():
* DATA: [
    { country_ids: [1,2...], bank_ids: [1,...], ... },
    { country_ids: [3,4...], bank_ids: [1,...], ... },
]
*/
export function findBankData(DATA, country_id, bank_id){
    let result = null;
    try {
        DATA.some(item => {
            let checkCountry = item.country_ids.find(e=>e === country_id);
            let checkBank = item.bank_ids.find(e=>e === bank_id);
            if(checkCountry && checkBank) {
                result = item;
                return true;
            }
        })
    } catch (error) {
        // console.log(error);
    }
    return result;
}
export const MASK_DATE_FORMAT = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
export const DATE_DB_FORMAT = 'yyyy-MM-dd';
export const SPECIAL_LLC_COUNTRIES = [
    { id: 23, name: 'Belize' },
    { id: 205, name: 'St. Kitts & Nevis' },
    { id: 209, name: 'St. Vincent & Grenadines' },
    { id: 250, name: 'Delaware' }
];
export const ENTITY_TYPES = {
    LLC: { id: 11, name: 'Limited Liability Company (LLC)' },
    LLP: { id: 7, name: 'Limited Liability Partnership (LLP)' }
};
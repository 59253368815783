import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Document, Page, pdfjs } from 'react-pdf';
import {isImage, isPDF, getExtension} from "app/pages/services/constants";
import { JUMP_SCALE, MIN_SCALE, MAX_SCALE } from './common-variables';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function NativeReader({ closeDetailModal, openSignatureModal, data, download }) {
    const [scale, setScale] = useState(1);
    const [pages, setPages] = useState([]);
    const [downloadLoading, setDownloadLoading] = useState(false);
    /**
     * handle pdf successful loaded.
     * doc: https://github.com/wojtekmaj/react-pdf
     */
    const onDocumentLoadSuccess = ({ numPages }) => {
        const temps = [];
        for (let idx = 0; idx < numPages; idx++) {
            temps.push(idx + 1);
        }
        setPages(temps);
    };

    /**
     * Handle zoom in feature pdf.
     */
    const zoomIn = () => {
        if (scale < MAX_SCALE) {
            setScale(scale + JUMP_SCALE);
        }
    };

    /**
     * Handle zoom out feature pdf.
     */
    const zoomOut = () => {
        if (scale > MIN_SCALE + JUMP_SCALE) {
            setScale(scale - JUMP_SCALE);
        }
    };
    /**
     * Download
     */
    const handleDownload = () => {
        setDownloadLoading(true);
        download()
            .then(() => {
                setDownloadLoading(false);
            })
            .catch(() => {
                setDownloadLoading(false);
            });
    };

    const getFileView = () => {
        if (data && data.file_url) {
            if (isImage(data?.file?.name || null)) {
                return <div className="w-100 h-100 position-relative mt-40">
                    <img src={data.file_url} className="position-absolute w-100 h-100" style={{ top: 0, left: 0, objectFit: "contain" }} />
                </div>
            }
            if (isPDF(data?.file?.name || null)) {
                return <Document
                    file={data?.file_url || ""}
                    loading={
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ height: 500 }}
                        >
                            <CircularProgress />
                        </div>
                    }
                    error={<div
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{ height: 500 }}
                    >
                        <div>Cannot view file: {data.file.name} on website!</div>
                        <div>Please! Download it.</div>
                    </div>}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="pt-25 pb-15 px-20 mx-auto height-fit-content"
                >
                    {pages.map((pageNumber, index) => (
                        <Paper key={index} className="mb-5 overflow-hidden" elevation={3}>
                            <Page pageNumber={pageNumber} scale={scale} loading="" />
                        </Paper>
                    ))}
                </Document>
            }
        }
        return <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
            <div className="mb-3">Cannot view{`${data && data.file && data.file.name ? "" : " this "}`}file {`: ${data?.file?.name || ""}`} on website!</div>
            <div>Please! Download to view it.</div>
        </div>

    }

    const getDownloadButton = () => {
        if (data && data.file && data.file.name) {
            let isButtonDisabled = false;
            if (isPDF(data.file.name)) {
                isButtonDisabled = pages.length === 0;
            }
            return <Button
                onClick={handleDownload}
                className="text-white mx-2 position-relative"
                variant="contained"
                color="primary"
                disabled={isButtonDisabled || downloadLoading}
            >
                {downloadLoading ? (
                    <div
                        className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
                        style={{ top: 1, left: 1 }}
                    >
                        <CircularProgress size={15} />
                    </div>
                ) : (
                    <></>
                )}
                Download
            </Button>

        }
    }

    return (
        <div className="position-relative" id="pdf-file-detail">
            <div className="position-absolute d-flex align-items-start control-panel">
                {closeDetailModal && (
                    <Button
                        onClick={closeDetailModal}
                        variant="contained"
                        className="bg-white mx-2"
                        style={{ color: '#17c191' }}
                    >
                        Close
                    </Button>
                )}
                {openSignatureModal ? (
                    <>
                        <Button
                            className="text-white mx-2"
                            variant="contained"
                            color="primary"
                            onClick={openSignatureModal}
                            disabled={pages.length === 0}
                        >
                            Sign here
                        </Button>
                        <Paper elevation={2} className="mx-3 position-relative">
                            <Button
                                disableRipple
                                style={{ minWidth: 'unset' }}
                                onClick={handleDownload}
                                disabled={pages.length === 0 || downloadLoading}
                            >
                                {downloadLoading ? (
                                    <div
                                        className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
                                        style={{ top: 1, left: 1 }}
                                    >
                                        <CircularProgress size={15} />
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <img src="/media/svg/icons/Order/export.svg" alt="Download" />
                            </Button>
                        </Paper>
                    </>
                ) : (
                    getDownloadButton()
                )}

                <Paper
                    elevation={2}
                    className="d-inline-flex flex-column align-items-center justify-content-between overflow-hidden mx-2"
                >
                    <Button
                        disableRipple
                        style={{ minWidth: 'unset' }}
                        onClick={zoomIn}
                        disabled={pages.length === 0}
                    >
                        <AddIcon />
                    </Button>
                    <div
                        className={`border-top border-bottom py-2${pages.length === 0 ? ' text-muted' : ''
                            }`}
                        style={{ fontSize: 11 }}
                    >
                        {Math.round(scale * 100)}%
                    </div>
                    <Button
                        disableRipple
                        style={{ minWidth: 'unset' }}
                        onClick={zoomOut}
                        disabled={pages.length === 0}
                    >
                        <RemoveIcon />
                    </Button>
                </Paper>
            </div>
            <section
                id="doc-content-container"
                className="position-relative d-flex overflow-auto"
                style={{ height: 600 }}
            >
                {getFileView()}
            </section>
        </div>
    );
}

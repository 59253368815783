import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { BANK_SUCCESS_NEED_UPLOAD_FILE } from 'app/pages/services/constants/banking_constants';
import { NoticeSuccessNormal } from './notice-success/noticeSuccessNormal';
import { NoticeSuccessUploadFile } from './notice-success/noticeSuccessUploadFile';
import { RateUs } from '../components/rateUs';


export function NoticeSuccess({bank, onSubmit}) {
    // const contentType = findBankData(BANK_SUCCESS_CONTENT_TYPE, country_id, bank_id)?.content_type;

    // const hasUploadFile = BANK_SUCCESS_NEED_UPLOAD_FILE.bank_ids.find(id => id === bank_id);
    const isHasCountry = bank.bank_id === 13 ? true : false // Bank One

    const isHasSubmit = () => {
        console.log()
        if(bank.Task?.Processes?.[0]?.ProcessStep?.FileTemplates?.[0]?.Files?.length > 0 && bank.Task?.Processes?.[0]?.is_approved){
            return (
                <>
                    <div className='text-center'><b> We've received your document. </b></div>
                    <RateUs />
                </>
            )
        }
        return <NoticeSuccessUploadFile bank={bank} onSubmit={onSubmit} />
    }

    return (
        <>
            <h3 className="notice__title success">Thank you for your kind cooperation!</h3>
            <div className="notice__content mt-4">
                { isHasCountry
                    ? isHasSubmit()
                    : <NoticeSuccessNormal bank={bank} />
                }

                {/* {getNoticeContent()}
                { hasUploadFile
                    ? <NoticeSuccessUploadFile />
                    : <RateUs />
                } */}
            </div>
        </>
    )
}

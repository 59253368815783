import React, { useState , useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RunningManWaiting } from '../../common/waiting/runningManWaiting';
import { NoticeFailed } from './notice/noticeFailed';
import { NoticeSuccess } from './notice/noticeSuccess';
import { approveProcessAction } from '../../../../../_redux/banking/asyncThunk';
import { toast } from 'react-toastify';
import { getLinkContentRoute } from '../../../OrderManagermentUIHelpers';
import { LINK } from 'app/pages/services/constants/banking_constants';
import {useNavigate} from 'react-router-dom';

export function ApplicationResult({onNext}) {
    const { processTree, activeProcess, orderInfo } = useSelector(state => state.banking);
    const [activeStep, setActiveStep] = useState(0);
    const [noticeType,setNoticeType] = useState(0) // success: 1, faild: 0
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        // console.log(processTree, activeProcess, orderInfo)
        setNoticeType((orderInfo.Task?.BankingProcesses && orderInfo.Task?.BankingProcesses[0].status) ?? 2)
    }, [orderInfo])

    /**
     *  noticeType check field is_checking_other_bank or status
     */

    /**
     *  Status có 2 loại:
     *
     *  + Success
     *       - HK, SG
     *       - Caye
     *       - EPB
     *       - CIM
     *       - Bank One
     *
     *  + Fail
     *       - Hết lượt nhưng có thể upgrade
     *       - Còn lượt upgrade (Chon bank khac)
     *       - Hết lượt nhưng không thể upgrade
     */

    async function handleChooseAnotherBank() {
        // redirect trực tiếp khi đã approve trước đó
        if (activeProcess.is_approved) {
            navigate(getLinkContentRoute(LINK.BANKING_TOOL, orderInfo.id));
            return;
        }

        const approveResult = await dispatch(approveProcessAction({ id: activeProcess.Processes[0].id}));
        if (!approveProcessAction.fulfilled.match(approveResult)) {
            toast.error('Somethings went wrong!');
            return;
        }

        navigate(getLinkContentRoute(LINK.BANKING_TOOL, orderInfo.id));
    }

    return (
        <div className="application-result waiting-layout d-flex align-items-center justify-content-center">
            <div>
                <RunningManWaiting/>
                {/* <Notice /> */}
                <div className="notice position-relative">
                    {
                        noticeType === 1 ? <NoticeSuccess bank={orderInfo} onSubmit={onNext} /> : <NoticeFailed onChooseAnotherBank={handleChooseAnotherBank} bank={orderInfo} />
                    }
                </div>
            </div>
        </div>
    )
}

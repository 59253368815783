import React from 'react';
import { Button } from '@mui/material';


export function RateUs() {
    return (
        <>
            <div className="d-flex align-items-end justify-content-center flex-wrap">
                If you are happy with our services, kindly spread the word about us by leaving a review on 
                <a href="https://www.trustpilot.com/review/bbcincorp.com" target="_blank" title="RATE US">
                    <img src="/media/logos/logo-trustpilot.png" alt="Logo Trustpilot" />
                </a>
            </div>

            <div className="text-center mt-8">
                <a href="https://www.trustpilot.com/review/bbcincorp.com" target="_blank" title="">
                    <Button variant="contained" size="medium" color="primary" style={{ color: '#fff' }} >
                        RATE US
                    </Button>
                </a>
            </div>
        </>
    )
}
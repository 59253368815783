/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import displayUnit from '../../utilities/display-unit-service';
import { exchange } from 'app/helpers/utils';
import { COUNTRIES_ID } from 'constants/countries';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const getWebsite = (contryID, websites) => {
    let websiteSelected = null;

    switch (contryID) {
        case COUNTRIES_ID.SINGAPORE:
            websiteSelected = websites?.find((item) => item?.id === 2);
            break;

        default:
            websiteSelected = websites?.find((item) => item?.id === 1);
            break;
    }
    return websiteSelected;
};

export function Review({
    packageIncorp,
    serviceDiscount,
    existing,
    services,
    setPaidAmount,
    body,
}) {
    const classes = useStyles();
    const { websites } = useSelector((state) => state.enum);
    const [total, setTotal] = useState(0);
    const [totalConversion, setTotalConversion] = useState(0);
    const [uniqueService, setUniqueService] = useState([]);
    const [reviewPackageIncorp, setReviewPackageIncorp] = useState({ Fee: { value: 0 } });
    var count = 1;

    const website = getWebsite(body?.country_id, websites);
    useEffect(() => {
        try {
            let total_service = 0;
            let totalServiceConversion = 0;
            if (services && services.length > 0) {
                services.forEach((service) => {
                    total_service += service.Fee.value * service.quantity;
                    totalServiceConversion += exchange(
                        website,
                        service.Fee.value,
                        service.quantity,
                    );
                });
            }
            if (
                (packageIncorp || services) &&
                serviceDiscount &&
                isChooseServiceHasDiscountMembership()
            ) {
                total_service += parseInt(serviceDiscount.Fees[0].value);
                totalServiceConversion += exchange(
                    website,
                    serviceDiscount.Fee.value,
                    serviceDiscount.quantity,
                );
            }

            if (packageIncorp && !existing) {
                packageIncorp.Services &&
                    packageIncorp.Services.forEach((service) => {
                        total_service += parseInt(service.Fee.value);
                        totalServiceConversion += exchange(website, service.Fee.value, 1);
                    });
            }

            if (body?.discountCheckout) {
                body.discountCheckout.forEach((item) => {
                    total_service += item.total;
                    totalServiceConversion += exchange(website, item.unit_price, item.quantity);
                })
            }

            setTotal(total_service);
            setTotalConversion(totalServiceConversion);
            // if (setPaidAmount) setPaidAmount(total_service);
            if (setPaidAmount) {
                switch (body?.country_id) {
                    case COUNTRIES_ID.SINGAPORE:
                        setPaidAmount(totalServiceConversion);
                        break;
                    default:
                        setPaidAmount(total_service);
                        break;
                }
            }
        } catch {}
        try {
            if (services && services.length > 0) {
                setUniqueService(
                    services.reduce((accumulator, service) => {
                        let object = accumulator.find((item) => item && item.id === service.id);
                        if (object) {
                            object.quantity = object.quantity + service.quantity;
                            return [...accumulator];
                        } else {
                            return [...accumulator, { ...service }];
                        }
                    }, []),
                );
            }
        } catch {}
    }, [services, packageIncorp]);

    const isChooseServiceHasDiscountMembership = () => {
        return (!existing ? packageIncorp?.Services || [] : [])
            .concat(services || [])
            ?.some((service) => {
                return [1, 18].includes(service?.ServiceType?.id); // 18: type anuual renewal
            });
    };

    const currencyUnit = (amount, countryID) => {
        let unit;
        switch (countryID) {
            case COUNTRIES_ID.SINGAPORE:
                unit = 'SG$';
                break;
            default:
                unit = 'HK$';
                break;
        }
        return `${unit}${amount}`;
    };

    return (
        <div>
            <TableContainer component={Paper} className="mt-5 service">
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">No.</TableCell>
                            <TableCell align="left">Category</TableCell>
                            <TableCell align="left">Service</TableCell>
                            <TableCell align="center">Unit price</TableCell>
                            <TableCell align="center">Quantity</TableCell>
                            <TableCell align="center">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {packageIncorp &&
                            !existing &&
                            packageIncorp.Services &&
                            packageIncorp.Services.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center" width="5%" scope="row">
                                        {count++}
                                    </TableCell>
                                    <TableCell align="left" width="20%">
                                        {item.ServiceType.name}
                                    </TableCell>
                                    <TableCell align="left" width="30%">
                                        {item.name}
                                    </TableCell>
                                    <TableCell align="center" width="20%">
                                        {item.Fee.value >= 0
                                            ? `US$${displayUnit(item?.Fee)}`
                                            : `-US$${displayUnit(item?.Fee)}`}
                                    </TableCell>
                                    <TableCell align="center" width="5%">
                                        {/* {item.quantity} */}1
                                    </TableCell>
                                    <TableCell align="center" width="20%">
                                        {item.Fee.value >= 0
                                            ? `US$${item.Fee.value}`
                                            : `-US$${Math.abs(item.Fee.value)}`}
                                    </TableCell>
                                </TableRow>
                            ))}
                        {uniqueService &&
                            uniqueService.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center" width="5%" scope="row">
                                        {count++}
                                    </TableCell>
                                    <TableCell align="left" width="20%">
                                        {row.ServiceType.name}
                                    </TableCell>
                                    <TableCell align="left" width="30%">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="center" width="20%">
                                        {row.Fee.value >= 0
                                            ? `US$${displayUnit(row?.Fee)}`
                                            : `-US$${displayUnit(row?.Fee)}`}
                                    </TableCell>
                                    <TableCell align="center" width="5%">
                                        {row.quantity}
                                    </TableCell>
                                    <TableCell align="center" width="20%">
                                        {row?.Fee?.value >= 0
                                            ? `US$${row.Fee.value * row.quantity}`
                                            : `-US$${Math.abs(row.Fee.value * row.quantity)}`}
                                    </TableCell>
                                </TableRow>
                            ))}

                        {body?.discountCheckout &&
                            body?.discountCheckout.map((item) => (
                                <TableRow key={'props.subcription.id'}>
                                    <TableCell align="center" width="5%" scope="row">
                                        {count++}
                                    </TableCell>
                                    <TableCell align="left" width="20%">
                                        {item.category_name}
                                    </TableCell>
                                    <TableCell align="left" width="30%">
                                        {item.service_name}
                                    </TableCell>
                                    <TableCell align="center" width="20%">
                                        {item.unit_price >= 0
                                            ? `US$${item.unit_price}`
                                            : `-US$${Math.abs(item.unit_price)}`}
                                    </TableCell>
                                    <TableCell align="center" width="5%">
                                        {item.quantity}
                                    </TableCell>
                                    <TableCell align="center" width="20%">
                                        {item.total >= 0
                                            ? `US$${item.total}`
                                            : `-US$${Math.abs(item.total)}`}
                                    </TableCell>
                                </TableRow>
                            ))}

                        {serviceDiscount &&
                            isChooseServiceHasDiscountMembership() &&
                            packageIncorp && (
                                <TableRow key={'service_discount'}>
                                    <TableCell align="center" width="5%" scope="row">
                                        {count++}
                                    </TableCell>
                                    <TableCell align="left" width="20%">
                                        {serviceDiscount.ServiceType.name}
                                    </TableCell>
                                    <TableCell align="left" width="30%">
                                        {serviceDiscount.name}
                                    </TableCell>
                                    <TableCell align="center" width="20%">
                                        {serviceDiscount.Fee.value >= 0
                                            ? `US$${displayUnit(serviceDiscount?.Fee)}`
                                            : `-US$${displayUnit(serviceDiscount?.Fee)}`}
                                    </TableCell>
                                    <TableCell align="center" width="5%">
                                        {serviceDiscount.quantity}
                                    </TableCell>
                                    <TableCell align="center" width="20%">
                                        {serviceDiscount.Fee.value >= 0
                                            ? `US$${serviceDiscount.Fee.value}`
                                            : `-US$${Math.abs(serviceDiscount.Fee.value)}`}
                                    </TableCell>
                                </TableRow>
                            )}
                        <TableRow key={'total'}>
                            <TableCell colSpan={5} className="h3 text-md-center">
                                <span style={{ position: 'sticky', left: '0' }}>Total</span>
                            </TableCell>
                            <TableCell align="center" className="text-nowrap">
                                US${total}
                            </TableCell>
                        </TableRow>
                        {body?.country_id && (
                            <TableRow key={'total-conversion'}>
                                <TableCell colSpan={6} className="h5" align="right">
                                    You will be charged the equivalent amount{' '}
                                    {currencyUnit(totalConversion, body.country_id)}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

import { isPast, subDays, add } from 'date-fns';
import * as _ from 'lodash';

export function downloadFileFromLink(url, fileName) {
    let a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    a.setAttribute('target', '_blank');
    a.click();
}

export function listToTree(list) {
    let map = {},
        node,
        roots = [];

    for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }

    for (let i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parent_id) {
            // if you have dangling branches check that map[node.parent_id] exists
            list[map[node.parent_id]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
}

export function listToTreeSignForm(list) {
    let map = {},
        node,
        roots = [];

    for (let i = 0; i < list.length; i++) {
        map = { ...list[i].FileType };
        map.children = [];
        roots.push(map);
    }

    roots = _.uniqBy(roots, 'id');

    for (let i = 0; i < list.length; i++) {
        node = list[i];
        node.children = [];
        for (let j = 0; j < roots.length; j++) {
            if (node.file_type_id === roots[j].id) {
                roots[j].children.push(node);
                break;
            }
        }
    }
    return roots;
}

export function treeToList(tree) {
    return tree.reduce(function (acc, o) {
        acc.push(o);

        if (o.children.length) {
            acc = acc.concat(treeToList(o.children));
        }
        return acc;
    }, []);
}

export function formatAMPM(date, inUtc = true) {
    let hours = inUtc ? date.getUTCHours() : date.getHours();
    let minutes = inUtc ? date.getUTCMinutes() : date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours.toString().padStart(2, '0');
    minutes = minutes.toString().padStart(2, '0');
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export function exchange(website, amount, quantity) {
    const exchange_rate = website.Currency.exchange_rate;
    const min_unit = website.min_unit;
    const min_amount = website.min_amount;

    let amount_ex = Math.abs(amount) * exchange_rate;
    amount_ex =
        (Math.floor(amount_ex / min_unit) + Math.round((amount_ex % min_unit) / min_unit)) *
        min_unit;
    if (amount_ex < min_amount) amount_ex = min_amount;
    if (amount === 0) amount_ex = 0;
    if (amount < 0) {
        amount_ex = amount_ex * -1;
    }
    return amount_ex * quantity;
}
/**
 * @param {*} currentDate date object - ngày hiện tại
 * @param {*} subDay number - số ngày từ ngày hiện tại
 */
export const showExpiryDate = (currentDate, subDay) => {
    if (currentDate === null) return false;
    const date = new Date(currentDate);

    const pastDate = subDays(date, subDay);
    if (isPast(pastDate)) {
        return true;
    }

    return false;
};

/**
 *
 * @param {*} currentDate date object - ngày hiện tại
 */
export const isPassportExpired = (currentDate) => {
    if (currentDate === null) return false;
    const date = new Date(currentDate);

    const today = add(date, { days: 1 });
    if (isPast(today)) {
        return true;
    }

    return false;
};

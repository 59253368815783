import AntSwitch from 'app/pages/services/components/kyc-elements/AntSwitch';
import { COMPANY_MEMBER_TYPES, MEMBER_TYPES } from 'app/pages/services/constants';
import React, { useEffect } from 'react';
import Corporation from './Corporation';
import Individual from './Individual';
import './informationForm.scss';
import InputField from 'components/form-controls/InputField';
import SwitchHookFormShareholderCorp from 'app/components/SwitchHookFormShareholderCorp';
import CheckboxField from 'components/form-controls/CheckboxField';

const InformationForm = (props) => {
    const {
        member,
        disabledFields,
        disabled,
        isUBO,
        isShareholder,
        form,
        IS_LLC,
        isCorporationChild,
        isDirector,
        allowShowRepresent,
        orderInfo,
        isContactPerson,
        hasShowSelectFullName,
        isPanama,
        isPercent,
        setIsPercent,
    } = props;
    const type_member_id = form.watch('type_member_id');

    return (
        <>
            <h5 className="mb-7 pl-0 input-line">Information</h5>
            {!isContactPerson && (
                <div className="d-flex">
                    Corporation{' '}
                    <AntSwitch
                        name="type_member_id"
                        checked={type_member_id === MEMBER_TYPES.INDIVIDUAL.id}
                        onChange={(e) => {
                            form.setValue(
                                'type_member_id',
                                e.target.checked
                                    ? MEMBER_TYPES.INDIVIDUAL.id
                                    : MEMBER_TYPES.CORPORATION.id,
                            );
                            form.clearErrors();
                        }}
                        disabled={disabledFields?.['type_member_id']?.(member) || disabled || isUBO}
                    />{' '}
                    Individual
                </div>
            )}

            {type_member_id === MEMBER_TYPES.INDIVIDUAL.id ? (
                <Individual {...props} hasShowSelectFullName={hasShowSelectFullName} />
            ) : (
                <Corporation {...props} />
            )}

            {/* UBO */}
            {isUBO && (
                <div className="row">
                    <div className="col-lg-6 input-line">
                        <label>Percentage of interest</label>
                        <InputField
                            type="number"
                            name="percent_of_interest"
                            form={form}
                            disabled
                            InputProps={{ endAdornment: '%' }}
                            hideSuccessHelper
                            customStyle={{
                                '& .MuiInputBase-input': {
                                    padding: '.6rem 1rem .6rem 0',
                                },
                            }}
                        />
                    </div>
                </div>
            )}

            {/* Shareholder */}
            {isShareholder && (
                <>
                    {type_member_id === MEMBER_TYPES.INDIVIDUAL.id && isCorporationChild ? (
                        <>
                            <div className="row">
                                <div className="col-lg-6 input-line">
                                    <SwitchHookFormShareholderCorp
                                        form={form}
                                        checked={isPercent}
                                        disabled={disabled}
                                        setChecked={setIsPercent}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {IS_LLC ? (
                                <div className="row">
                                    <div className="col-lg-6 input-line">
                                        <label>Percentage of ownership</label>
                                        <InputField
                                            type="number"
                                            name="own_percent"
                                            form={form}
                                            disabled
                                            InputProps={{ endAdornment: '%' }}
                                            hideSuccessHelper
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-lg-6 input-line">
                                        <label>Number to be allotted</label>
                                        <InputField
                                            type="number"
                                            name="number_allotted"
                                            form={form}
                                            disabled
                                            hideSuccessHelper
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
            {!isPanama &&
                isDirector &&
                allowShowRepresent &&
                orderInfo?.represented_type_ids?.includes(COMPANY_MEMBER_TYPES.DIRECTOR.id) &&
                !member.corporation_company_member_id && (
                    <CheckboxField
                        name="is_appointed"
                        form={form}
                        label="Consent to act as secretary"
                        disabled={form.watch('disabled_appointed')}
                    />
                )}

            {!isPanama &&
                isUBO &&
                allowShowRepresent &&
                orderInfo?.represented_type_ids?.includes(COMPANY_MEMBER_TYPES.UBO.id) &&
                !member.corporation_company_member_id && (
                    <CheckboxField
                        name="is_appointed"
                        form={form}
                        label="Consent to act as authorized signatory"
                        disabled={form.watch('disabled_appointed')}
                    />
                )}
        </>
    );
};

export default InformationForm;


import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function RadioGroupYesNo({
    form,
    radioName,
    options,
    stateValue,
    setStateValue,
    setFieldValue,
    disabled,
}) {
    return (
        <>
            <RadioGroup
                row
                aria-label={radioName}
                name={radioName}
                onChange={(e) => {
                    setStateValue(e.target.value);
                    form.setValue(radioName, parseInt(e.target.value));
                }}
            >
                <FormControlLabel
                    value={options && options[0].id.toString()}
                    checked={options && stateValue && stateValue === options[0].id.toString()}
                    control={<Radio color="secondary" />}
                    label={options && options[0].content}
                    disabled={disabled}
                />
                <FormControlLabel
                    value={options && options[1].id.toString()}
                    checked={options && stateValue && stateValue === options[1].id.toString()}
                    control={<Radio color="secondary" />}
                    label={options && options[1].content}
                    className="ml-0"
                    disabled={disabled}
                />
            </RadioGroup>
        </>
    );
}

import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import InputField from 'components/form-controls/InputField';
import RadioField from 'components/form-controls/RadioField';
import SelectField from 'components/form-controls/SelectField';

const options = [
    { id: 1, name: 'Lawyer' },
    { id: 2, name: 'Bank' },
    { id: 3, name: 'CPA' },
    { id: 4, name: 'Business reference' },
];

const Reference = ({
    title,
    form,
    indexMember,
    indexReference,
    countries,
    disabled,
    allowFields,
}) => {
    return (
        <Box sx={{ marginTop: '32px' }}>
            <Grid container rowSpacing={2} columnSpacing={4}>
                <Grid item xs={12}>
                    <Typography fontWeight={'medium'}>{title}</Typography>
                </Grid>
                <Grid className="input-line" item xs={6}>
                    <label>Name</label>
                    <InputField
                        type="text"
                        name={`members.${indexMember}.CompanyMemberReferences.${indexReference}.name`}
                        form={form}
                        placeholder="Enter Name"
                        hideSuccessHelper
                        disabled={
                            disabled ||
                            !allowFields?.[indexMember]?.CompanyMemberReferences?.indexReference
                                ?.name
                        }
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.[indexMember]?.CompanyMemberReferences
                                        ?.indexReference?.name &&
                                    form.getValues(
                                        `members.${indexMember}.CompanyMemberReferences.${indexReference}.name`,
                                    ) !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RadioField
                        form={form}
                        name={`members.${indexMember}.CompanyMemberReferences.${indexReference}.company_member_issuse_reference_id`}
                        options={options}
                        radioGroupProps={{ sx: { flexDirection: 'row' } }}
                        disabled={disabled || true}
                    />
                </Grid>
                <Grid className="input-line" item xs={6}>
                    <label>Tel No.</label>
                    <div className="phone-group">
                        <div className="phone-code" style={{ zIndex: 1 }}>
                            <SelectField
                                form={form}
                                className="fw-500"
                                name={`members.${indexMember}.CompanyMemberReferences.${indexReference}.phone_country_id`}
                                placeholder="Select..."
                                options={countries || []}
                                getOptionLabel={(option) =>
                                    option.country_code + ' +' + option.phone_code
                                }
                                hideHelperText={true}
                                disabled={
                                    disabled ||
                                    !allowFields?.[indexMember]?.CompanyMemberReferences
                                        ?.indexReference?.phone_country_id
                                }
                                customStyle={{
                                    '.MuiInputBase-root': {
                                        backgroundColor:
                                            allowFields?.[indexMember]?.CompanyMemberReferences
                                                ?.indexReference?.phone_country_id &&
                                            form.getValues(
                                                `members.${indexMember}.CompanyMemberReferences.${indexReference}.phone_country_id`,
                                            ) !== null
                                                ? 'rgba(176, 236, 209, 0.5)'
                                                : 'inherit',
                                    },
                                    '.MuiInputBase-root:before, .MuiInputBase-root:after, .MuiInput-underline:hover:not(.Mui-disabled):before':
                                        {
                                            borderBottom: '0 !important',
                                        },
                                }}
                                disableClearable
                                hideInputHelperText
                                hideSuccessHelper
                            />
                        </div>
                        <div className="input-line inp-phone">
                            <InputField
                                name={`members.${indexMember}.CompanyMemberReferences.${indexReference}.phone_number`}
                                form={form}
                                hideSuccessHelper
                                placeholder="Enter phone number"
                                type="text"
                                disabled={
                                    disabled ||
                                    !allowFields?.[indexMember]?.CompanyMemberReferences
                                        ?.indexReference?.phone_number
                                }
                                customStyle={{
                                    '.MuiInputBase-root': {
                                        backgroundColor:
                                            allowFields?.[indexMember]?.CompanyMemberReferences
                                                ?.indexReference?.phone_number &&
                                            form.getValues(
                                                `members.${indexMember}.CompanyMemberReferences.${indexReference}.phone_number`,
                                            ) !== ''
                                                ? 'rgba(176, 236, 209, 0.5)'
                                                : 'inherit',
                                    },
                                }}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid className="input-line" item xs={6}>
                    <label>Fax No.</label>
                    <InputField
                        form={form}
                        name={`members.${indexMember}.CompanyMemberReferences.${indexReference}.fax`}
                        disabled={
                            disabled ||
                            !allowFields?.[indexMember]?.CompanyMemberReferences?.indexReference
                                ?.fax
                        }
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.[indexMember]?.CompanyMemberReferences
                                        ?.indexReference?.fax &&
                                    form.getValues(
                                        `members.${indexMember}.CompanyMemberReferences.${indexReference}.fax`,
                                    ) !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                        placeholder="Enter Fax No."
                        hideSuccessHelper
                    />
                </Grid>
                <Grid className="input-line" item xs={6}>
                    <label>Email Address</label>
                    <InputField
                        type="text"
                        name={`members.${indexMember}.CompanyMemberReferences.${indexReference}.email`}
                        form={form}
                        placeholder="Enter email address"
                        disabled={
                            disabled ||
                            !allowFields?.[indexMember]?.CompanyMemberReferences?.indexReference
                                ?.email
                        }
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.[indexMember]?.CompanyMemberReferences
                                        ?.indexReference?.email &&
                                    form.getValues(
                                        `members.${indexMember}.CompanyMemberReferences.${indexReference}.email`,
                                    ) !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                        hideSuccessHelper
                    />
                </Grid>
                <Grid className="input-line" item xs={6}>
                    <label>Physical address</label>
                    <InputField
                        type="text"
                        name={`members.${indexMember}.CompanyMemberReferences.${indexReference}.address`}
                        form={form}
                        placeholder="Enter Physical address"
                        hideSuccessHelper
                        disabled={
                            disabled ||
                            !allowFields?.[indexMember]?.CompanyMemberReferences?.indexReference
                                ?.address
                        }
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.[indexMember]?.CompanyMemberReferences
                                        ?.indexReference?.address &&
                                    form.getValues(
                                        `members.${indexMember}.CompanyMemberReferences.${indexReference}.address`,
                                    ) !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Reference;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import * as requestFromServer from './personalCrud';
import { personalSlice, callTypes } from './personalSlice';

const { actions } = personalSlice;

export const fetchPersonal = () => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getPersonal()
        .then((response) => {
            dispatch(actions.personalFetched({ personal: response.data.data }));
        })
        .catch((error) => {
            error.clientMessage = "Can't find billing";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error('Something went wrong!');
        });
};

export const updatePersonal = (personal) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .updatePersonal(personal)
        .then(() => {
            dispatch(actions.personalUpdated({ personal }));
            toast.success(`Update Profile Successfully!`);
        })
        .catch((error) => {
            error.clientMessage = "Can't update personal";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error('Something went wrong!');
        });
};

export const changePassword = (password) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .changePassword(password)
        .then(() => {
            dispatch(actions.changePassword());
            toast.success(`Change Password Successfully!`);
        })
        .catch((error) => {
            error.clientMessage = "Can't Change Password";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error('Something went wrong!');
        });
};

export const reset = () => (dispatch) => {
    return dispatch(actions.reset());
}

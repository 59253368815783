import axios from 'axios';


export function getBankingDataByOrderId(id) {
    return axios.get(
        process.env.REACT_APP_API_URL + `/customer_account/order/${id}/banking`
    )
}
export function updateBankingBusinessPlanForm(id, params) {
    return axios.put(
        process.env.REACT_APP_API_URL + `/customer_account/business_plan_form/update_form/${id}`,
        params
    )
}
export function getHoliday(params = {}) {
    return axios.get(
        process.env.REACT_APP_API_URL + `/customer_account/holiday`,
        params
    )
}

export function setBankingID(params = {}){
    return axios.post(
        process.env.REACT_APP_API_URL + `/customer_account/banking`,
        params
    )
}

// status: 0 pending, 1 success, 2 rejected
export function getListBankingAPI(orderID){
    return axios.get(
        process.env.REACT_APP_API_URL + `/customer_account/banking/?order_id=${orderID}`
    )
}

export function getSampleDataForApplicationSet(id){
    return axios.get(
        process.env.REACT_APP_API_URL + `/customer_account/process/${id}`
    )
}

export function getBankingProcessTree(orderId) {
    return axios.get(
        process.env.REACT_APP_API_URL + `/customer_account/order/${orderId}/banking_tree`
    )
}

export function approveProcess(body) {
    return axios.post(
        process.env.REACT_APP_API_URL + `/customer_account/process/approve`,
        body
    )
}

export function createRecordFileBanking(payload) {
    return axios.post(
        process.env.REACT_APP_API_URL + `/customer_account/file/banking`,
        payload
    )
}

export function createRecordFileInCorp(payload) {
    return axios.post(
        process.env.REACT_APP_API_URL + `/file/customer/create`,
        payload
    )
}

export function deleteFile(id) {
    return axios.delete(
        process.env.REACT_APP_API_URL + `/file/customer/${id}`,
    )
}

export function getProcessDetail(id) {
    return axios.get(
        process.env.REACT_APP_API_URL + `/customer_account/process/${id}`,
    )
}

export function triggerMailRemindFile(payload) {
    return axios.put(
        process.env.REACT_APP_API_URL + `/customer_account/order/trigger_email`,
        payload
    )
}

export function sendMailCalendar(payload) {
    return axios.post(
        process.env.REACT_APP_API_URL + `/customer_account/order/mail_to_my_email_meeting_schedule`,
        payload
    )
}

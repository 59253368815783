import React from 'react';

function TradingProofsContent({name = ''}) {
    return (
        <>
            <h6 className={'pb-3'}>Business proofs - {name}</h6>

            <p className={'mb-0'}>Document checklist: </p>
            <ol className={'ml-4 pl-4'}>
                <li>Contract</li>
                <li>Invoices/Receipt</li>
                <li>Others</li>
            </ol>
        </>
    );
}

export default TradingProofsContent;

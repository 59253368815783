import React from 'react';
import ReferenceItem from './ReferenceItem';

/**
 * Panama References
 */

export default function References({ form, index, body, setBody, values, disabled = false }) {
    return (
        <>
            {body.isPanama && (
                <div className="reference">
                    <hr style={{ border: '1px dashed #ebedf3' }} />
                    <div className="fw-500 mt-7 mb-3">Reference 1:</div>
                    <ReferenceItem
                        form={form}
                        key={0}
                        memberIndex={index}
                        referenceIndex={0}
                        body={body}
                        values={values}
                        disabled={disabled}
                    />

                    <div className="fw-500 mt-7 mb-3">Reference 2:</div>
                    <ReferenceItem
                        form={form}
                        key={1}
                        memberIndex={index}
                        referenceIndex={1}
                        body={body}
                        values={values}
                        disabled={disabled}
                    />
                </div>
            )}
        </>
    );
}

export class BankAccountModel {
    bank_acocunt_answers_arr = []
    constructor(option_id, value_text) {
       this.option_id = option_id;
       this.value_text = value_text;
    }

    getResult() {
        switch (this.option_id) {
            case '88':
                const opt1_88 = {
                    option_id: 88,
                    is_chosen: 1
                }
                const opt2_88 = {
                    option_id: 89,
                    is_chosen: 0
                }
                const opt3_88 = {
                    option_id: 90,
                    is_chosen: 0
                }
                const opt4_88 = {
                    option_id: 91,
                    is_chosen: 0
                }
                this.bank_acocunt_answers_arr = [opt1_88, opt2_88, opt3_88, opt4_88];
                break;
            case '89':
                const opt1_89 = {
                    option_id: 88,
                    is_chosen: 0
                }
                const opt2_89 = {
                    option_id: 89,
                    is_chosen: 1
                }
                const opt3_89 = {
                    option_id: 90,
                    is_chosen: 0
                }
                const opt4_89 = {
                    option_id: 91,
                    is_chosen: 0
                }
                this.bank_acocunt_answers_arr = [opt1_89, opt2_89, opt3_89, opt4_89];
                break;

            case '90':
                const opt1_90 = {
                    option_id: 88,
                    is_chosen: 0
                }
                const opt2_90 = {
                    option_id: 89,
                    is_chosen: 0
                }
                const opt3_90 = {
                    option_id: 90,
                    is_chosen: 1
                }
                const opt4_90 = {
                    option_id: 91,
                    is_chosen: 0
                }
                this.bank_acocunt_answers_arr = [opt1_90, opt2_90, opt3_90, opt4_90];
                break;

            case '91':
                const opt1_91 = {
                    option_id: 88,
                    is_chosen: 0
                }
                const opt2_91 = {
                    option_id: 89,
                    is_chosen: 0
                }
                const opt3_91 = {
                    option_id: 90,
                    is_chosen: 0
                }
                const opt4_91 = {
                    option_id: 91,
                    is_chosen: 1,
                    value_text: this.value_text
                }
                this.bank_acocunt_answers_arr = [opt1_91, opt2_91, opt3_91, opt4_91];
                break;

            default:
                break;
        }
        return this.bank_acocunt_answers_arr
    }
}

export class NomineeModel {
    nominee_ans_arr = []
    constructor(use_nominee, opt= null) {
        this.use_nominee = use_nominee;
        this.opt = opt;
    }

    getNonimeeResult() {
        switch (this.use_nominee) {
            case '92':
                const opt1_92 = {
                    option_id: 92, // selected 'YES'
                    is_chosen: 1,
                    value_text: this.opt
                }
                const opt2_92 = {
                    option_id: 93, 
                    is_chosen: 0
                }
                this.nominee_ans_arr =[opt1_92, opt2_92];
                break;
            case '93':
                const opt1_93 = {
                    option_id: 92,
                    is_chosen: 0
                }
                const opt2_93 = {
                    option_id: 93, // selected 'NO'
                    is_chosen: 1
                }
                this.nominee_ans_arr =[opt1_93, opt2_93];
                break;
            default:
                break;
        }
        return this.nominee_ans_arr
    }
}

export class OnlyTextModel {
    result_ans = [];

    constructor(option_id, value_text = null, value_int) {
        this.option_id = option_id;
        this.value_text = value_text;
        this.value_int = value_int;
    }

    getResult() {
        switch (this.option_id) {
            case '94':
            case '96':
            case '97':
            case '98':
            case '99':
            case '100':
            case '101':
            case '105':
            case '106':
                const opt1_94 = {
                    option_id: +this.option_id, // selected 'YES'
                    is_chosen: 1,
                    value_int: this.value_int
                }
                this.result_ans = [opt1_94];
                break;
            case '95':
            case '102':
            case '103':
            case '104':
            case '107':
            case '108':
            case '109':
            case '110':
            case '111':
            case '112':
            case '113':
            case '114':
            case '115':
            case '116':
            case '117':
                const opt1_95 = {
                    option_id: +this.option_id, // selected 'YES'
                    is_chosen: 1,
                    value_text: this.value_text
                }
                this.result_ans = [opt1_95];
                break;
            default:
                break;
        }
        return this.result_ans
    }
}

export class CompanyInformationModel {

    constructor(id, name, country_id, company_reg_no, OperationCountries, banking_business_activities, banking_source_of_funds, address, operating_country_id, operating_city, operating_state, operating_postal_code) {
        this.id = id;
        this.name = name;
        this.country_id = country_id;
        this.company_reg_no = company_reg_no;
        this.OperationCountries = OperationCountries;
        this.banking_business_activities = banking_business_activities;
        this.banking_source_of_funds = banking_source_of_funds;
        this.address = address;
        this.operating_country_id = operating_country_id;
        this.operating_city = operating_city;
        this.operating_state = operating_state;
        this.operating_postal_code = operating_postal_code;
    }
}

export class CompanyPositionsShareholder {
    constructor (id,  number_allotted, company_member_type_id  = null) {
        this.id = id;
        this.number_allotted = number_allotted;
        this.company_member_type_id  = company_member_type_id ;
    }
}
export class CompanyPositionsUbo {
    constructor (id,  percent_of_interest, company_member_type_id = null) {
        this.id = id;
        this.percent_of_interest = percent_of_interest;
        this.company_member_type_id = company_member_type_id;
    }
}
export class CompanyStructUpdate {
    constructor(id, first_name, CompanyPositions, type_member_id = 1 ) {
        this.id = id;
        this.first_name = first_name;
        this.CompanyPositions = CompanyPositions;
        this.type_member_id = type_member_id
    }
}
export class CompanyStructCreate {
    constructor(first_name, CompanyPositions, type_member_id = 1 ) {
        this.first_name = first_name;
        this.CompanyPositions = CompanyPositions;
        this.type_member_id = type_member_id 
    }
}

export class RankingModel {
    constructor(id, name, task_id, ranking, ranking_partner_type_id) {
        this.id =id;
        this.name = name;
        this.task_id = task_id;
        this.ranking = ranking;
        this.ranking_partner_type_id = ranking_partner_type_id;
    }
}


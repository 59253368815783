import React from 'react';
import { TextField, FormHelperText, InputAdornment } from '@mui/material';
import { debounce, get } from 'lodash';
import { Controller } from 'react-hook-form';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckIcon from '@mui/icons-material/Check';

const SUCCESS_COLOR = '#1bc5bd';
const FAILURE_COLOR = '#f64e60';

function InputField({
    form,
    label,
    name,
    disabled = false,
    readOnly = false,
    type = 'text',
    onChange,
    debounceTime = 0,
    helperText,
    customStyle = {},
    hideHelperText = false,
    showIcon = false,
    hideSuccessHelper = false,
    hideAutoCompleteBrowser = false,
    ...props
}) {
    const callDebounce = debounce((e) => {
        onChange(e);
    }, debounceTime);

    return (
        <Controller
            name={name}
            control={form.control}
            render={({
                field,
                fieldState: { isDirty, isTouched, error, invalid },
                formState: { isSubmitted, isValid, dirtyFields },
            }) => {
                let InputProps = {
                    readOnly: readOnly,
                };

                if (showIcon) {
                    // if (isTouched || isDirty || isSubmitted) {
                    if (isSubmitted) {
                        if (error) {
                            InputProps = {
                                ...InputProps,
                                endAdornment: (
                                    <InputAdornment position="end" disablePointerEvents>
                                        <ErrorOutlineIcon sx={{ color: FAILURE_COLOR }} />
                                    </InputAdornment>
                                ),
                            };
                        } else {
                            // if (get(dirtyFields, field.name)) {
                            InputProps = {
                                ...InputProps,
                                endAdornment: (
                                    <InputAdornment position="end" disablePointerEvents>
                                        <CheckIcon
                                            sx={{
                                                stroke: SUCCESS_COLOR,
                                                strokeWidth: 2,
                                                strokeLinejoin: 'round',
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                            };
                            // }
                        }
                    }
                }

                if (props.InputProps) {
                    InputProps = {
                        ...props.InputProps,
                        ...InputProps,
                    };
                }

                if (hideAutoCompleteBrowser) {
                    props.inputProps = {
                        autoComplete: "nope"
                    }
                }

                return (
                    <>
                        <TextField
                            fullWidth={true}
                            {...field}
                            className={!error && isDirty ? 'Mui-success' : ''}
                            error={error}
                            label={label}
                            onChange={(e) => {
                                field.onChange(e);
                                onChange && callDebounce(e);
                            }}
                            disabled={disabled}
                            type={type}
                            value={field.value ?? ''}
                            variant="standard"
                            {...props}
                            InputProps={InputProps}
                            sx={{
                                '& .MuiInputBase-input': {
                                    padding: !props?.multiline ? '.65rem 1rem .65rem 0' : '.65rem 1rem 0 0',
                                },
                                '.MuiInputBase-root.Mui-error:before': {
                                    borderBottomColor: FAILURE_COLOR,
                                },
                                '.MuiInputBase-root.Mui-error:after': {
                                    borderBottomColor: FAILURE_COLOR,
                                },
                                '& .MuiInputBase-root.Mui-error': {
                                    "& fieldset": {
                                        borderColor: FAILURE_COLOR,
                                    }
                                },
                                '&.Mui-success .MuiInputBase-root:after': {
                                    borderBottomWidth: '2px',
                                },
                                '&.Mui-success .MuiInputBase-root:before': {
                                    transform: 'scaleX(1) translateX(0)',
                                    borderBottomColor: showIcon && isSubmitted && SUCCESS_COLOR,
                                },
                                '.MuiInputBase-root.MuiInputBase-colorPrimary:after': {
                                    borderBottomColor: showIcon && !error && SUCCESS_COLOR,
                                },
                                '.MuiInput-root:before': {
                                    borderBottomColor:
                                        showIcon &&
                                        // (isTouched || isDirty || isSubmitted) &&
                                        // dirtyFields[field.name] &&
                                        isSubmitted &&
                                        !error &&
                                        SUCCESS_COLOR,
                                },
                                ...customStyle,
                            }}
                        />
                        {!hideHelperText && (
                            <FormHelperText
                                error={error}
                                className={!error && isDirty ? 'Mui-success' : ''}
                                sx={{
                                    '&.Mui-error': {
                                        color: FAILURE_COLOR,
                                        fontSize: '.9rem',
                                    },
                                    '&.Mui-success': {
                                        color: '#1bc5bd',
                                        fontSize: '.9rem',
                                    },
                                }}
                            >
                                {!hideSuccessHelper && !error && isDirty && props.placeholder
                                    ? props.placeholder + '  was entered correct'
                                    : ''}
                                {error ? error?.message : ''}
                                {!error && !isDirty && helperText ? (
                                    <>
                                        Please enter <b>{props.placeholder}</b>
                                    </>
                                ) : (
                                    ''
                                )}
                            </FormHelperText>
                        )}
                    </>
                );
            }}
        />
    );
}

export default InputField;

export const DATA = {
    "id": 14644,
    "company_id": 11621,
    "Customer": {
        "id": 13786,
        "first_name": "Nguyet",
        "last_name": "Pham",
        "address": "HCM City, Viet Nam",
        "phone": "840978299705",
        "phone_number": "0978299705",
        "phone_country_id": 244,
        "email": "testcustomer@gleads.vn",
        "country_id": 244,
        "city": "HCM",
        "state": "Test state",
        "postal_code": "123456",
        "company_registration_no": null,
        "company_address": null,
        "company_address_country_id": 244,
        "company_address_city": null,
        "company_address_state": null,
        "company_address_postal_code": null,
        "company_name": "Re Bahamas",
        "PhoneCountry": {
            "id": 244,
            "phone_code": "84",
            "country_code": "VN",
            "name": "Vietnam"
        }
    },
    "Task": {
        "id": 1460,
        "email": "testcustomer@gleads.vn",
        "incorporation_country_id": 139,
        "entity_type_id": 1,
        "full_name": "Nguyet Pham",
        "phone": "840978299705",
        "address": "HCM City, Viet Nam",
        "country_id": 244,
        "city": "HCM",
        "state": "Test state",
        "postal_code": "123456",
        "phone_number": "0978299705",
        "phone_country_id": 244,
        "appointed_shareholder_company_member_id": null,
        "appointed_director_company_member_id": 4389,
        "appointed_ubo_company_member_id": null,
        "ProposedNames": [
            {
                "id": 3429,
                "name": "Mau ri name test",
                "company_suffix_id": 1
            }
        ],
        "Files": [],
        "PhoneCountry": {
            "id": 244,
            "phone_code": "84",
            "country_code": "VN",
            "name": "Vietnam"
        },
        "Processes": [
            {
                "id": 9865,
                "process_step_id": 17,
                "is_approved": 1,
                "is_rejected": null,
                "is_terminated": null,
                "status": 0,
                "ProcessStep": {
                    "id": 17,
                    "name": "Fill Order Form",
                    "type": 4,
                    "process_step_type_id": 4,
                    "ProcessStepType": {
                        "name": "Incorporation (Dashboard)"
                    },
                    "FileTemplates": []
                }
            },
            {
                "id": 9866,
                "process_step_id": 18,
                "is_approved": 1,
                "is_rejected": null,
                "is_terminated": null,
                "status": 0,
                "ProcessStep": {
                    "id": 18,
                    "name": "Fill KYC Document",
                    "type": 4,
                    "process_step_type_id": 4,
                    "ProcessStepType": {
                        "name": "Incorporation (Dashboard)"
                    },
                    "FileTemplates": []
                }
            },
            {
                "id": 9886,
                "process_step_id": 19,
                "is_approved": null,
                "is_rejected": null,
                "is_terminated": null,
                "status": 1,
                "ProcessStep": {
                    "id": 19,
                    "name": "Sign Order Form",
                    "type": 4,
                    "process_step_type_id": 4,
                    "ProcessStepType": {
                        "name": "Incorporation (Dashboard)"
                    },
                    "FileTemplates": [
                        {
                            "id": 32,
                            "name": "Order Form",
                            "description": "document of Mauritius",
                            "path_template": null,
                            "is_not_signed": false,
                            "is_esign": true,
                            "is_signed_for_FATCA": false,
                            "is_deleted": false,
                            "process_step_has_file_template": {
                                "file_template_id": 32,
                                "process_step_id": 19
                            },
                            "CountryHasEntityTypes": [
                                {
                                    "id": 11,
                                    "country_id": 139,
                                    "entity_type_id": 1,
                                    "country_has_entity_type_has_file_template": {
                                        "country_has_entity_type_id": 11,
                                        "file_template_id": 32
                                    }
                                }
                            ],
                            "Files": [
                                {
                                    "id": 19129,
                                    "name": "signature of Nguyet Dir 1 Ki",
                                    "s3_path": "e-signature-1633941582883.png",
                                    "is_filled": false,
                                    "file_type_id": null
                                }
                            ],
                            "titles": []
                        },
                        {
                            "id": 33,
                            "name": "Company FATCA",
                            "description": "document of Mauritius",
                            "path_template": null,
                            "is_not_signed": false,
                            "is_esign": false,
                            "is_signed_for_FATCA": false,
                            "is_deleted": false,
                            "process_step_has_file_template": {
                                "file_template_id": 33,
                                "process_step_id": 19
                            },
                            "CountryHasEntityTypes": [
                                {
                                    "id": 11,
                                    "country_id": 139,
                                    "entity_type_id": 1,
                                    "country_has_entity_type_has_file_template": {
                                        "country_has_entity_type_id": 11,
                                        "file_template_id": 33
                                    }
                                }
                            ],
                            "Files": [
                                {
                                    "id": 19130,
                                    "name": "Board resolution.pdf",
                                    "s3_path": "Board-resolution-1633941806787.pdf",
                                    "is_filled": false,
                                    "file_type_id": null
                                }
                            ],
                            "titles": [
                                {
                                    "file_template_id": 33,
                                    "file_name": "Company FATCA.pdf",
                                    "belong_to_company_member_ids": [
                                        {
                                            "id": 4389,
                                            "company_member_type_id": 1,
                                            "is_send": false,
                                            "is_signed": false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 34,
                            "name": "Consent of director",
                            "description": "document of Mauritius",
                            "path_template": null,
                            "is_not_signed": false,
                            "is_esign": false,
                            "is_signed_for_FATCA": false,
                            "is_deleted": false,
                            "process_step_has_file_template": {
                                "file_template_id": 34,
                                "process_step_id": 19
                            },
                            "CountryHasEntityTypes": [
                                {
                                    "id": 11,
                                    "country_id": 139,
                                    "entity_type_id": 1,
                                    "country_has_entity_type_has_file_template": {
                                        "country_has_entity_type_id": 11,
                                        "file_template_id": 34
                                    }
                                }
                            ],
                            "Files": [
                                {
                                    "id": 19131,
                                    "name": "Consent of Shareholder_Nguyet Share 1.pdf",
                                    "s3_path": "Consent-of-Shareholder_Nguyet-Share-1-1633941812990.pdf",
                                    "is_filled": false,
                                    "file_type_id": null
                                }
                            ],
                            "titles": [
                                {
                                    "file_template_id": 34,
                                    "company_member_id": 4389,
                                    "file_name": "Consent of director_Nguyet Dir 1.pdf",
                                    "belong_to_company_member_ids": [
                                        {
                                            "id": 4389,
                                            "company_member_type_id": 1,
                                            "is_send": false,
                                            "is_signed": false
                                        }
                                    ]
                                },
                                {
                                    "file_template_id": 34,
                                    "company_member_id": 4390,
                                    "file_name": "Consent of director_Nguyet Dir 2.pdf",
                                    "belong_to_company_member_ids": [
                                        {
                                            "id": 4390,
                                            "company_member_type_id": 1,
                                            "is_send": false,
                                            "is_signed": false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 35,
                            "name": "Consent of Shareholder",
                            "description": "document of Mauritius",
                            "path_template": null,
                            "is_not_signed": false,
                            "is_esign": false,
                            "is_signed_for_FATCA": false,
                            "is_deleted": false,
                            "process_step_has_file_template": {
                                "file_template_id": 35,
                                "process_step_id": 19
                            },
                            "CountryHasEntityTypes": [
                                {
                                    "id": 11,
                                    "country_id": 139,
                                    "entity_type_id": 1,
                                    "country_has_entity_type_has_file_template": {
                                        "country_has_entity_type_id": 11,
                                        "file_template_id": 35
                                    }
                                }
                            ],
                            "Files": [
                                {
                                    "id": 19132,
                                    "name": "Consent of director_Nguyet Dir 1.pdf",
                                    "s3_path": "Consent-of-director_Nguyet-Dir-1-1633942292582.pdf",
                                    "is_filled": false,
                                    "file_type_id": null
                                }
                            ],
                            "titles": [
                                {
                                    "file_template_id": 35,
                                    "company_member_id": 4391,
                                    "file_name": "Consent of Shareholder_Nguyet Share 1.pdf",
                                    "belong_to_company_member_ids": [
                                        {
                                            "id": 4391,
                                            "company_member_type_id": 2,
                                            "is_send": false,
                                            "is_signed": false
                                        }
                                    ]
                                },
                                {
                                    "file_template_id": 35,
                                    "company_member_id": 4392,
                                    "file_name": "Consent of Shareholder_Nguyet Share 2.pdf",
                                    "belong_to_company_member_ids": [
                                        {
                                            "id": 4392,
                                            "company_member_type_id": 2,
                                            "is_send": false,
                                            "is_signed": false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 36,
                            "name": "Structure chart",
                            "description": "document of Mauritius",
                            "path_template": null,
                            "is_not_signed": false,
                            "is_esign": false,
                            "is_signed_for_FATCA": false,
                            "is_deleted": false,
                            "process_step_has_file_template": {
                                "file_template_id": 36,
                                "process_step_id": 19
                            },
                            "CountryHasEntityTypes": [
                                {
                                    "id": 11,
                                    "country_id": 139,
                                    "entity_type_id": 1,
                                    "country_has_entity_type_has_file_template": {
                                        "country_has_entity_type_id": 11,
                                        "file_template_id": 36
                                    }
                                }
                            ],
                            "Files": [
                                {
                                    "id": 19133,
                                    "name": "Share cert_Nguyet Dir 1.pdf",
                                    "s3_path": "Share-cert_Nguyet-Dir-1-1633942551290.pdf",
                                    "is_filled": false,
                                    "file_type_id": null
                                }
                            ],
                            "titles": [
                                {
                                    "file_template_id": 36,
                                    "file_name": "Structure chart.pdf",
                                    "belong_to_company_member_ids": [
                                        {
                                            "id": 4390,
                                            "company_member_type_id": 3,
                                            "is_send": false,
                                            "is_signed": false
                                        },
                                        {
                                            "id": 4392,
                                            "company_member_type_id": 3,
                                            "is_send": false,
                                            "is_signed": false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 37,
                            "name": "Terms and condition",
                            "description": "document of Mauritius",
                            "path_template": null,
                            "is_not_signed": false,
                            "is_esign": false,
                            "is_signed_for_FATCA": false,
                            "is_deleted": false,
                            "process_step_has_file_template": {
                                "file_template_id": 37,
                                "process_step_id": 19
                            },
                            "CountryHasEntityTypes": [
                                {
                                    "id": 11,
                                    "country_id": 139,
                                    "entity_type_id": 1,
                                    "country_has_entity_type_has_file_template": {
                                        "country_has_entity_type_id": 11,
                                        "file_template_id": 37
                                    }
                                }
                            ],
                            "Files": [],
                            "titles": [
                                {
                                    "file_template_id": 37,
                                    "file_name": "Terms and condition.pdf",
                                    "belong_to_company_member_ids": [
                                        {
                                            "id": 4390,
                                            "company_member_type_id": 3,
                                            "is_send": false,
                                            "is_signed": false
                                        },
                                        {
                                            "id": 4392,
                                            "company_member_type_id": 3,
                                            "is_send": false,
                                            "is_signed": false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 38,
                            "name": "UBO confirmation (AC)",
                            "description": "document of Mauritius",
                            "path_template": null,
                            "is_not_signed": false,
                            "is_esign": false,
                            "is_signed_for_FATCA": false,
                            "is_deleted": false,
                            "process_step_has_file_template": {
                                "file_template_id": 38,
                                "process_step_id": 19
                            },
                            "CountryHasEntityTypes": [
                                {
                                    "id": 11,
                                    "country_id": 139,
                                    "entity_type_id": 1,
                                    "country_has_entity_type_has_file_template": {
                                        "country_has_entity_type_id": 11,
                                        "file_template_id": 38
                                    }
                                }
                            ],
                            "Files": [],
                            "titles": [
                                {
                                    "file_template_id": 38,
                                    "company_member_id": 4390,
                                    "file_name": "UBO confirmation (AC)_Nguyet Dir 2.pdf",
                                    "belong_to_company_member_ids": [
                                        {
                                            "id": 4390,
                                            "company_member_type_id": 3,
                                            "is_send": false,
                                            "is_signed": false
                                        }
                                    ]
                                },
                                {
                                    "file_template_id": 38,
                                    "company_member_id": 4392,
                                    "file_name": "UBO confirmation (AC)_Nguyet Share 2.pdf",
                                    "belong_to_company_member_ids": [
                                        {
                                            "id": 4392,
                                            "company_member_type_id": 3,
                                            "is_send": false,
                                            "is_signed": false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 39,
                            "name": "UBO confirmation",
                            "description": "document of Mauritius",
                            "path_template": null,
                            "is_not_signed": false,
                            "is_esign": false,
                            "is_signed_for_FATCA": false,
                            "is_deleted": false,
                            "process_step_has_file_template": {
                                "file_template_id": 39,
                                "process_step_id": 19
                            },
                            "CountryHasEntityTypes": [
                                {
                                    "id": 11,
                                    "country_id": 139,
                                    "entity_type_id": 1,
                                    "country_has_entity_type_has_file_template": {
                                        "country_has_entity_type_id": 11,
                                        "file_template_id": 39
                                    }
                                }
                            ],
                            "Files": [],
                            "titles": [
                                {
                                    "file_template_id": 39,
                                    "company_member_id": 4390,
                                    "file_name": "UBO confirmation_Nguyet Dir 2.pdf",
                                    "belong_to_company_member_ids": [
                                        {
                                            "id": 4390,
                                            "company_member_type_id": 3,
                                            "is_send": false,
                                            "is_signed": false
                                        }
                                    ]
                                },
                                {
                                    "file_template_id": 39,
                                    "company_member_id": 4392,
                                    "file_name": "UBO confirmation_Nguyet Share 2.pdf",
                                    "belong_to_company_member_ids": [
                                        {
                                            "id": 4392,
                                            "company_member_type_id": 3,
                                            "is_send": false,
                                            "is_signed": false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 40,
                            "name": "Declaration of source of fund",
                            "description": "document of Mauritius",
                            "path_template": null,
                            "is_not_signed": false,
                            "is_esign": false,
                            "is_signed_for_FATCA": false,
                            "is_deleted": false,
                            "process_step_has_file_template": {
                                "file_template_id": 40,
                                "process_step_id": 19
                            },
                            "CountryHasEntityTypes": [
                                {
                                    "id": 11,
                                    "country_id": 139,
                                    "entity_type_id": 1,
                                    "country_has_entity_type_has_file_template": {
                                        "country_has_entity_type_id": 11,
                                        "file_template_id": 40
                                    }
                                }
                            ],
                            "Files": [],
                            "titles": [
                                {
                                    "file_template_id": 40,
                                    "company_member_id": 4391,
                                    "file_name": "Declaration of source of fund_Nguyet Share 1.pdf",
                                    "belong_to_company_member_ids": [
                                        {
                                            "id": 4391,
                                            "company_member_type_id": 2,
                                            "is_send": false,
                                            "is_signed": false
                                        }
                                    ]
                                },
                                {
                                    "file_template_id": 40,
                                    "company_member_id": 4392,
                                    "file_name": "Declaration of source of fund_Nguyet Share 2.pdf",
                                    "belong_to_company_member_ids": [
                                        {
                                            "id": 4392,
                                            "company_member_type_id": 2,
                                            "is_send": false,
                                            "is_signed": false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": 41,
                            "name": "Individual FATCA",
                            "description": "document of Mauritius",
                            "path_template": null,
                            "is_not_signed": false,
                            "is_esign": false,
                            "is_signed_for_FATCA": true,
                            "is_deleted": false,
                            "process_step_has_file_template": {
                                "file_template_id": 41,
                                "process_step_id": 19
                            },
                            "CountryHasEntityTypes": [
                                {
                                    "id": 11,
                                    "country_id": 139,
                                    "entity_type_id": 1,
                                    "country_has_entity_type_has_file_template": {
                                        "country_has_entity_type_id": 11,
                                        "file_template_id": 41
                                    }
                                }
                            ],
                            "Files": [],
                            "titles": [
                                {
                                    "file_template_id": 41,
                                    "file_name": "Individual FATCA.pdf",
                                    "belong_to_company_member_ids": []
                                }
                            ]
                        }
                    ]
                }
            }
        ],
        "IncorporationCountry": {
            "id": 139,
            "name": "Mauritius",
            "Questions": [
                {
                    "id": 1,
                    "content": "Are you or any connected persons (Directors, Shareholders, Ultimate Beneficial Owners, Partners), a Politically Exposed Person (PEP)",
                    "question_parent_id": null,
                    "question_type_id": 1,
                    "is_belong_to_fatca": false,
                    "country_has_question": {
                        "country_id": 139,
                        "question_id": 1
                    },
                    "Options": [
                        {
                            "id": 1,
                            "content": "Yes",
                            "question_id": 1,
                            "Answers": []
                        },
                        {
                            "id": 2,
                            "content": "No",
                            "question_id": 1,
                            "Answers": [
                                {
                                    "id": 1997,
                                    "value_int": null,
                                    "value_text": null,
                                    "option_id": 2,
                                    "task_id": 1460,
                                    "fatca_id": null,
                                    "select_country_id": null,
                                    "company_member_id": null
                                }
                            ]
                        }
                    ]
                },
                {
                    "id": 2,
                    "content": "Has any of the shareholders, directors, Ultimate Beneficial Owners ever committed any offenses?",
                    "question_parent_id": null,
                    "question_type_id": 1,
                    "is_belong_to_fatca": false,
                    "country_has_question": {
                        "country_id": 139,
                        "question_id": 2
                    },
                    "Options": [
                        {
                            "id": 3,
                            "content": "Yes",
                            "question_id": 2,
                            "Answers": []
                        },
                        {
                            "id": 4,
                            "content": "No",
                            "question_id": 2,
                            "Answers": [
                                {
                                    "id": 1998,
                                    "value_int": null,
                                    "value_text": null,
                                    "option_id": 4,
                                    "task_id": 1460,
                                    "fatca_id": null,
                                    "select_country_id": null,
                                    "company_member_id": null
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    },
    "Company": {
        "ShareCapital": {
            "par_value": 1,
            "type_of_share": 2,
            "number_share": 40000,
            "share_capital": 40000,
            "class_of_share": "Ordinary"
        },
        "BusinessInformation": {
            "full_name": "Nguyet Pham",
            "address": "HCM City, Viet Nam",
            "country_id": 244,
            "city": "HCM",
            "state": "Test state",
            "postal_code": "123456",
            "day_end": null,
            "month_end": 12
        },
        "id": 11621,
        "company_code": "2111621",
        "country_id": 139,
        "company_reg_no": null,
        "name": "Mau ri name test",
        "company_status_id": 1,
        "entity_type_id": 1,
        "company_suffix_id": 1,
        "preventive_name": null,
        "address": "HCM City, Viet Nam",
        "tax": null,
        "description": null,
        "note": null,
        "incorporation_date": null,
        "xero_contact_id": null,
        "business_activity": "Business activities hihi",
        "is_outside_company": false,
        "is_deleted": false,
        "renewal_date": null,
        "billing_id": 159,
        "operating_country_id": 244,
        "operating_city": "HCM",
        "operating_state": "Test state",
        "operating_postal_code": "123456",
        "source_fund": "Source of funds hihi",
        "business_full_name": "Nguyet Pham",
        "business_address": "HCM City, Viet Nam",
        "business_country_id": 244,
        "business_city": "HCM",
        "business_state": "Test state",
        "business_postal_code": "123456",
        "business_day_end": null,
        "business_month_end": 12,
        "share_capital_par_value": 1,
        "share_capital_type_of_share": 2,
        "share_capital_number_share": 40000,
        "share_capital": 40000,
        "share_capital_class_of_share": "Ordinary",
        "CompanyMembers": [
            {
                "id": 4389,
                "company_id": 11621,
                "country_id": 1,
                "type_member_id": 1,
                "first_name": "Nguyet Dir 1 ",
                "last_name": null,
                "corporation_name": null,
                "corporation_number": null,
                "corporation_date": null,
                "gender_id": null,
                "major": "QC",
                "phone": "934354235356",
                "email": "testcustomer@gleads.vn",
                "birthday": "2021-10-01",
                "address": "dir 1 add",
                "passport": "12345",
                "passport_issue_date": "2021-10-19",
                "passport_expire_date": "2021-10-31",
                "ssn": null,
                "description": null,
                "is_deleted": 0,
                "phone_country_id": 1,
                "phone_number": "4354235356",
                "asset_source_fund_cast_in_bank": 0,
                "asset_source_fund_real_estate": 0,
                "asset_source_fund_other_detail": null,
                "asset_source_fund_other_money": 0,
                "income_source_fund_investment": 0,
                "income_source_fund_self_employed": 0,
                "income_source_fund_other_detail": null,
                "income_source_fund_other_money": 0,
                "is_nominee": 0,
                "parent_id": null,
                "CompanyPositions": [
                    {
                        "id": 4082,
                        "company_member_id": 4389,
                        "company_member_type_id": 1,
                        "corporation_company_member_id": null,
                        "number_allotted": 0,
                        "percent_of_interest": 0,
                        "own_percent": 0,
                        "created_time": "2021-10-11 15:16:04",
                        "updated_time": "2021-10-11 15:19:03",
                        "is_appointed": true,
                        "is_deleted": false
                    }
                ],
                "Files": [
                    {
                        "id": 19125,
                        "name": "b9b62a27-c94e-430b-a302-3b0c6191d75f.pdf",
                        "s3_path": "b9b62a27-c94e-430b-a302-3b0c6191d75f-1633940339903.pdf"
                    }
                ]
            },
            {
                "id": 4390,
                "company_id": 11621,
                "country_id": 1,
                "type_member_id": 1,
                "first_name": "Nguyet Dir 2 ",
                "last_name": null,
                "corporation_name": null,
                "corporation_number": null,
                "corporation_date": null,
                "gender_id": null,
                "major": "Test",
                "phone": "935645623455",
                "email": "nguyet.pham@gleads.vn",
                "birthday": "2021-10-02",
                "address": "dir 2 add",
                "passport": "23456",
                "passport_issue_date": "2021-10-21",
                "passport_expire_date": "2021-10-31",
                "ssn": null,
                "description": null,
                "is_deleted": 0,
                "phone_country_id": 1,
                "phone_number": "5645623455",
                "asset_source_fund_cast_in_bank": 0,
                "asset_source_fund_real_estate": 0,
                "asset_source_fund_other_detail": null,
                "asset_source_fund_other_money": 0,
                "income_source_fund_investment": 0,
                "income_source_fund_self_employed": 0,
                "income_source_fund_other_detail": null,
                "income_source_fund_other_money": 0,
                "is_nominee": 0,
                "parent_id": null,
                "CompanyPositions": [
                    {
                        "id": 4083,
                        "company_member_id": 4390,
                        "company_member_type_id": 1,
                        "corporation_company_member_id": null,
                        "number_allotted": 0,
                        "percent_of_interest": 0,
                        "own_percent": 0,
                        "created_time": "2021-10-11 15:16:04",
                        "updated_time": "2021-10-11 15:19:15",
                        "is_appointed": false,
                        "is_deleted": false
                    },
                    {
                        "id": 4086,
                        "company_member_id": 4390,
                        "company_member_type_id": 3,
                        "corporation_company_member_id": null,
                        "number_allotted": 0,
                        "percent_of_interest": 55,
                        "own_percent": 0,
                        "created_time": "2021-10-11 15:17:01",
                        "updated_time": "2021-10-11 15:17:01",
                        "is_appointed": false,
                        "is_deleted": false
                    }
                ],
                "Files": [
                    {
                        "id": 19126,
                        "name": "FATCA-(US-passport-only)_Nguyet-Dir-2-1632906642629.pdf",
                        "s3_path": "FATCA-(US-passport-only)_Nguyet-Dir-2-1632906642629-1633940352593.pdf"
                    }
                ]
            },
            {
                "id": 4391,
                "company_id": 11621,
                "country_id": 1,
                "type_member_id": 1,
                "first_name": "Nguyet Share 1 ",
                "last_name": null,
                "corporation_name": null,
                "corporation_number": null,
                "corporation_date": null,
                "gender_id": null,
                "major": "QC",
                "phone": "935645623455",
                "email": "testcustomer@gleads.vn",
                "birthday": "2021-10-03",
                "address": "share 1 add",
                "passport": "34567",
                "passport_issue_date": "2021-10-18",
                "passport_expire_date": "2021-10-25",
                "ssn": null,
                "description": null,
                "is_deleted": 0,
                "phone_country_id": 1,
                "phone_number": "5645623455",
                "asset_source_fund_cast_in_bank": 0,
                "asset_source_fund_real_estate": 0,
                "asset_source_fund_other_detail": null,
                "asset_source_fund_other_money": 0,
                "income_source_fund_investment": 0,
                "income_source_fund_self_employed": 0,
                "income_source_fund_other_detail": null,
                "income_source_fund_other_money": 0,
                "is_nominee": 0,
                "parent_id": null,
                "CompanyPositions": [
                    {
                        "id": 4084,
                        "company_member_id": 4391,
                        "company_member_type_id": 2,
                        "corporation_company_member_id": null,
                        "number_allotted": 25001,
                        "percent_of_interest": 0,
                        "own_percent": 0,
                        "created_time": "2021-10-11 15:16:46",
                        "updated_time": "2021-10-11 15:37:31",
                        "is_appointed": false,
                        "is_deleted": false
                    }
                ],
                "Files": [
                    {
                        "id": 19127,
                        "name": "Order form Pahama.pdf",
                        "s3_path": "Order-form-Pahama-1633940364090.pdf"
                    }
                ]
            },
            {
                "id": 4392,
                "company_id": 11621,
                "country_id": 1,
                "type_member_id": 1,
                "first_name": "Nguyet Share 2  ",
                "last_name": null,
                "corporation_name": null,
                "corporation_number": null,
                "corporation_date": null,
                "gender_id": null,
                "major": "Test",
                "phone": "935435345",
                "email": "nguyet.pham@gleads.vn",
                "birthday": "2021-10-01",
                "address": "share 2 add",
                "passport": "456789",
                "passport_issue_date": "2021-10-24",
                "passport_expire_date": "2021-10-31",
                "ssn": null,
                "description": null,
                "is_deleted": 0,
                "phone_country_id": 1,
                "phone_number": "5435345",
                "asset_source_fund_cast_in_bank": 0,
                "asset_source_fund_real_estate": 0,
                "asset_source_fund_other_detail": null,
                "asset_source_fund_other_money": 0,
                "income_source_fund_investment": 0,
                "income_source_fund_self_employed": 0,
                "income_source_fund_other_detail": null,
                "income_source_fund_other_money": 0,
                "is_nominee": 0,
                "parent_id": null,
                "CompanyPositions": [
                    {
                        "id": 4085,
                        "company_member_id": 4392,
                        "company_member_type_id": 2,
                        "corporation_company_member_id": null,
                        "number_allotted": 14999,
                        "percent_of_interest": 0,
                        "own_percent": 0,
                        "created_time": "2021-10-11 15:16:46",
                        "updated_time": "2021-10-11 15:37:56",
                        "is_appointed": false,
                        "is_deleted": false
                    },
                    {
                        "id": 4087,
                        "company_member_id": 4392,
                        "company_member_type_id": 3,
                        "corporation_company_member_id": null,
                        "number_allotted": 0,
                        "percent_of_interest": 45,
                        "own_percent": 0,
                        "created_time": "2021-10-11 15:17:01",
                        "updated_time": "2021-10-11 15:17:01",
                        "is_appointed": false,
                        "is_deleted": false
                    }
                ],
                "Files": [
                    {
                        "id": 19128,
                        "name": "image.png",
                        "s3_path": "image-1633940376049.png"
                    }
                ]
            }
        ],
        "EntityType": {
            "id": 1,
            "name": "Authorised Company (AC)"
        },
        "Country": {
            "id": 139,
            "name": "Mauritius"
        },
        "OperationCountries": [
            {
                "id": 28,
                "name": "Bosnia & Herzegovina"
            }
        ],
        "OperatingCountry": {
            "id": 244,
            "name": "Vietnam"
        }
    },
    "is_has_director": false,
    "is_has_shareholder": false,
    "represented_type_ids": [
        3,
        1
    ]
}
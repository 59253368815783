import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { Field, ErrorMessage } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';
import Select from 'react-select';
import { shallowEqual, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';

import { Input } from '_metronic/_partials/controls';
import { RecommendName } from 'app/pages/components/check-name/recommend-name';
import InputField from 'components/form-controls/InputField';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export default function PreventiveComponent({
    index,
    body,
    setFieldValue,
    fields,
    companyType,
    isPrefix, values, touched,
    form,
    name = 'preventives'
}) {
    const [preventiveName, setPreventiveName] = useState({ id: 0, name: '' });

    useEffect(() => {
        if (companyType.CompanySuffixes && companyType.CompanySuffixes.length > 0) {
            if (body.preventives && body.preventives.length > 0) {
                if (body.preventives[index]) {
                    form.setValue(`${name}.${index}`, body.preventives[index]);
                    var preventiveReturn = companyType.CompanySuffixes.find(
                        (e) => e.id == body.preventives[index].company_suffix_id,
                    );
                    if (preventiveReturn) {
                        setPreventiveName(preventiveReturn);
                    } else {
                        setPreventiveName(companyType.CompanySuffixes[0])
                    }
                    return;
                }
                setPreventiveName(companyType.CompanySuffixes[0]);
                form.setValue(`${name}.${index}.company_suffix_id`, companyType.CompanySuffixes[0].id);
            } else {
                setPreventiveName(companyType.CompanySuffixes[0]);
                form.setValue(`${name}.${index}.company_suffix_id`, companyType.CompanySuffixes[0].id);
            }
        }
    }, [companyType]);

    function handlePreventiveName(value, index) {
        setPreventiveName(value);
        form.setValue(`${name}.${index}.company_suffix_id`, value.id);
    }

    const [hintInput, setHintInput] = useState('');
    
    return (
        <div key={index} className="row">
            <div className="col-md-6 col-12 mb-7 has-check-name">
                <div className="input-line">
                    <label>Enter Company name</label>
                    <InputField
                        id="other_name"
                        form={form}
                        name={`${name}.${index}.name`}
                        onKeyUp={(e) => setHintInput(e.target.value)}
                        onBlur={() => {
                            setTimeout(() => {
                                setHintInput('')
                            }, 200);
                        }}
                        placeholder={'Company name'}
                        helperText
                    />
                </div>
                {!isPrefix && hintInput.length > 0 &&
                    <RecommendName
                        input={hintInput}
                        fieldName={`${name}.${index}.name`}
                        form={form}
                    />
                }
            </div>
            {
                companyType.CompanySuffixes && companyType.CompanySuffixes.length > 0 &&
                <div className="col-md-5 col-10 mb-7 input-line">
                    <label>Company Suffixes</label>
                    <Select
                        form={form}
                        className="react-select"
                        id="company-suffixes"
                        name={`${name}.${index}.company_suffix_id`}
                        options={companyType.CompanySuffixes}
                        disableClearable
                        defaultValue={preventiveName}
                        value={preventiveName && preventiveName}
                        getOptionLabel={(option) =>
                            `${option.name && option.name.length > 0 ? option.name : ''}`
                        }
                        isOptionSelected={(option) =>
                            preventiveName && option && preventiveName.id === option.id ? true : false
                        }
                        onChange={(value) => {
                            handlePreventiveName(value, index);
                        }}
                    />
                    <FormHelperText>
                        Please select <b>Company Suffixes</b>
                    </FormHelperText>
                </div>
            }
            <div className="col-md-1 col-2 mb-5 p-0">
                <IconButton
                    onClick={() => {
                        fields.remove(index);
                    }}
                    size="large">
                    <DeleteIcon />
                </IconButton>
            </div>
            <div className="col-12 mb-7">
                <div className="separator separator-dashed"></div>
            </div>
        </div>
    );
}


import React, { useEffect, useState } from 'react';
import RadioGroupYesNo
    from '../../../../../../order-form/instruction-incorporation/component/step3/nature-of-control/radio_group';
import SelectField from 'components/form-controls/SelectField';

export default function NatureOfControl({ form, currentMember, name = 'members', index, body, setFieldValue, disabled = false}) {
    const member_id = currentMember.id;

    const [natureOwnership, setNatureOwnership] = useState({});
    const [natureVotingRights, setNatureVotingRights] = useState({});
    const [natureAppointMajority, setNatureAppointMajority] = useState(false);
    const [natureOtherSignificantControl, setNatureOtherSignificantControl] = useState(false);

    const [firm, setFirm] = useState(false);
    const [firmOwnership, setFirmOwnership] = useState({});
    const [firmVotingRights, setFirmVotingRights] = useState({});
    const [firmAppointMajority, setFirmAppointMajority] = useState(false);
    const [firmOtherSignificantControl, setFirmOtherSignificantControl] = useState(false);

    const [trust, setTrust] = useState(false);
    const [trustOwnership, setTrustOwnership] = useState({});
    const [trustVotingRights, setTrustVotingRights] = useState({});
    const [trustAppointMajority, setTrustAppointMajority] = useState(false);
    const [trustOtherSignificantControl, setTrustOtherSignificantControl] = useState(false);

    const [showFirmTrust, setShowFirmTrust] = useState(true);

    useEffect(() => {
        if(currentMember.isAfterChooseExistingMember) {
            setDefaultAnswers();
        }
    },[currentMember.isAfterChooseExistingMember])

    useEffect(() => {
        try {
            if (body.QuestionsIncorp) {
                setDefaultAnswers();
            }
        } catch (error) { console.log(error) }
    }, [body])

    function setDefaultAnswers(){
        try {
                // nature_ownership
                const nature_ownership = body.QuestionsIncorp.nature_ownership.Options.find(
                    op => op.Answers.find((ans) => ans.company_member_id === member_id)) || body.QuestionsIncorp.nature_ownership.Options[0];
                setNatureOwnership(nature_ownership);
                form.setValue(`${name}.${index}.nature_ownership`, nature_ownership.id);

                // nature_voting_rights
                const nature_voting_rights = body.QuestionsIncorp.nature_voting_rights.Options.find(
                    op => op.Answers.find((ans) => ans.company_member_id === member_id)) || body.QuestionsIncorp.nature_voting_rights.Options[0];
                setNatureVotingRights(nature_voting_rights);
                form.setValue(`${name}.${index}.nature_voting_rights`, nature_voting_rights.id);

                // nature_appoint_majority
                const nature_appoint_majority = body.QuestionsIncorp.nature_appoint_majority.Options.find(
                    op => op.Answers.find((ans) => ans.company_member_id === member_id)) || body.QuestionsIncorp.nature_appoint_majority.Options[1];
                setNatureAppointMajority(nature_appoint_majority.id.toString());
                form.setValue(`${name}.${index}.nature_appoint_majority`, nature_appoint_majority.id);

                // nature_other_significant_control
                const nature_other_significant_control = body.QuestionsIncorp.nature_other_significant_control.Options.find(
                    op => op.Answers.find((ans) => ans.company_member_id === member_id)) || body.QuestionsIncorp.nature_other_significant_control.Options[1];
                setNatureOtherSignificantControl(nature_other_significant_control.id.toString());
                form.setValue(`${name}.${index}.nature_other_significant_control`, nature_other_significant_control.id);

                // firm
                const firm = body.QuestionsIncorp.firm.Options.find(
                    op => op.Answers.find((ans) => ans.company_member_id === member_id)) || body.QuestionsIncorp.firm.Options[1];
                setFirm(firm.id.toString());
                form.setValue(`${name}.${index}.firm`, firm.id);

                // firm_ownership
                const firm_ownership = body.QuestionsIncorp.firm_ownership.Options.find(
                    op => op.Answers.find((ans) => ans.company_member_id === member_id)) || body.QuestionsIncorp.firm_ownership.Options[0];
                setFirmOwnership(firm_ownership);
                form.setValue(`${name}.${index}.firm_ownership`, firm_ownership.id);

                // firm_voting_rights
                const firm_voting_rights = body.QuestionsIncorp.firm_voting_rights.Options.find(
                    op => op.Answers.find((ans) => ans.company_member_id === member_id)) || body.QuestionsIncorp.firm_voting_rights.Options[0];
                setFirmVotingRights(firm_voting_rights);
                form.setValue(`${name}.${index}.firm_voting_rights`, firm_voting_rights.id);

                // firm_appoint_majority
                const firm_appoint_majority = body.QuestionsIncorp.firm_appoint_majority.Options.find(
                    op => op.Answers.find((ans) => ans.company_member_id === member_id)) || body.QuestionsIncorp.firm_appoint_majority.Options[1];
                setFirmAppointMajority(firm_appoint_majority.id.toString());
                form.setValue(`${name}.${index}.firm_appoint_majority`, firm_appoint_majority.id);

                // firm_other_significant_control
                const firm_other_significant_control = body.QuestionsIncorp.firm_other_significant_control.Options.find(
                    op => op.Answers.find((ans) => ans.company_member_id === member_id)) || body.QuestionsIncorp.firm_other_significant_control.Options[1];
                setFirmOtherSignificantControl(firm_other_significant_control.id.toString());
                form.setValue(`${name}.${index}.firm_other_significant_control`, firm_other_significant_control.id);

                // trust
                const trust = body.QuestionsIncorp.trust.Options.find(
                    op => op.Answers.find((ans) => ans.company_member_id === member_id)) || body.QuestionsIncorp.trust.Options[1];
                setTrust(trust.id.toString());
                form.setValue(`${name}.${index}.trust`, trust.id);

                // trust_ownership
                const trust_ownership = body.QuestionsIncorp.trust_ownership.Options.find(
                    op => op.Answers.find((ans) => ans.company_member_id === member_id)) || body.QuestionsIncorp.trust_ownership.Options[0];
                setTrustOwnership(trust_ownership);
                form.setValue(`${name}.${index}.trust_ownership`, trust_ownership.id);

                // trust_voting_rights
                const trust_voting_rights = body.QuestionsIncorp.trust_voting_rights.Options.find(
                    op => op.Answers.find((ans) => ans.company_member_id === member_id)) || body.QuestionsIncorp.trust_voting_rights.Options[0];
                setTrustVotingRights(trust_voting_rights);
                form.setValue(`${name}.${index}.trust_voting_rights`, trust_voting_rights.id);

                // trust_appoint_majority
                const trust_appoint_majority = body.QuestionsIncorp.trust_appoint_majority.Options.find(
                    op => op.Answers.find((ans) => ans.company_member_id === member_id)) || body.QuestionsIncorp.trust_appoint_majority.Options[1];
                setTrustAppointMajority(trust_appoint_majority.id.toString());
                form.setValue(`${name}.${index}.trust_appoint_majority`, trust_appoint_majority.id);

                // trust_other_significant_control
                const trust_other_significant_control = body.QuestionsIncorp.trust_other_significant_control.Options.find(
                    op => op.Answers.find((ans) => ans.company_member_id === member_id)) || body.QuestionsIncorp.trust_other_significant_control.Options[1];
                setTrustOtherSignificantControl(trust_other_significant_control.id.toString());
                form.setValue(`${name}.${index}.trust_other_significant_control`, trust_other_significant_control.id);
        } catch (error) { console.log(error) }
    }

    useEffect(() => {
        try {
            if (natureOwnership && natureOwnership.content === "None"
                && natureVotingRights && natureVotingRights.content === "None"
                && natureAppointMajority && natureAppointMajority === "56" // 56-"No"
                && natureOtherSignificantControl && natureOtherSignificantControl === "58") { // 58-"No"
                setShowFirmTrust(true);
            } else {
                setShowFirmTrust(false);
            }
        } catch (error) { console.log(error) }
    }, [natureOwnership, natureVotingRights, natureAppointMajority, natureOtherSignificantControl])

    return (
        <>
            <div className="nature_of_control">
                <p className="text-uppercase fw-500">Nature of Control</p>
                <div className="row">
                    <div className="col-md-6 col-sm-6 mb-7 input-line">
                        <label>{body?.is_UK_LLP ? "Right to earn surplus assets":"Ownership of shares"}</label>
                        <SelectField 
                            form={form}
                            name={`${name}.${index}.nature_ownership`}
                            options={body.QuestionsIncorp && body.QuestionsIncorp.nature_ownership.Options}
                            placeholder={body?.is_UK_LLP ? "Choose Right to earn surplus assets" : "Choose Ownership of shares"}
                            getOptionLabel={(option) => option.content}
                            onChange={(value) => {
                                if (natureOwnership && natureOwnership.id != value.id) {
                                    setNatureOwnership(value);
                                    form.setValue(`${name}.${index}.nature_ownership`, value.id);
                                }
                            }}
                            hideHelperText
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-md-6 col-sm-6 mb-7 input-line">
                        <label>Voting Rights</label>
                        <SelectField 
                            form={form}
                            name={`${name}.${index}.nature_voting_rights`}
                            options={body.QuestionsIncorp && body.QuestionsIncorp.nature_voting_rights.Options}
                            placeholder={'Choose Voting Rights'}
                            getOptionLabel={(option) => option.content}
                            onChange={(value) => {
                                if (natureVotingRights && natureVotingRights.id != value.id) {
                                    setNatureVotingRights(value);
                                    form.setValue(`${name}.${index}.nature_voting_rights`, value.id)
                                }
                            }}
                            hideHelperText
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-12">
                        <div>Appoint or remove the majority of the board of directors</div>
                        <RadioGroupYesNo
                            form={form}
                            radioName={`${name}.${index}.nature_appoint_majority`}
                            options={body.QuestionsIncorp && body.QuestionsIncorp.nature_appoint_majority.Options}
                            stateValue={natureAppointMajority && natureAppointMajority}
                            setStateValue={setNatureAppointMajority}
                            setFieldValue={setFieldValue}
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-12">
                        <div>Other Significant influences or control</div>
                        <RadioGroupYesNo
                            form={form}
                            radioName={`${name}.${index}.nature_other_significant_control`}
                            options={body.QuestionsIncorp && body.QuestionsIncorp.nature_other_significant_control.Options}
                            stateValue={natureOtherSignificantControl && natureOtherSignificantControl}
                            setStateValue={setNatureOtherSignificantControl}
                            setFieldValue={setFieldValue}
                            disabled={disabled}
                        />
                    </div>
                    {
                        showFirmTrust &&
                        <>
                            <div className="col-md-6 col-12 mb-7">
                                <div className="rad-8 box-shadow-2 p-5">
                                    <div className="mb-2">
                                        <div>Does this officer have the right to exercise significant influence or control over a firm(s) which also has a controlling influence in this company?</div>
                                        <RadioGroupYesNo
                                            form={form}
                                            radioName={`${name}.${index}.firm`}
                                            options={body.QuestionsIncorp && body.QuestionsIncorp.firm.Options}
                                            stateValue={firm && firm}
                                            setStateValue={setFirm}
                                            setFieldValue={setFieldValue}
                                            disabled={disabled}
                                        />
                                    </div>
                                    {
                                        body.QuestionsIncorp && body.QuestionsIncorp.firm.Options && firm && firm === body.QuestionsIncorp.firm.Options[0].id.toString() && // Yes
                                        <>
                                            <p className="text-uppercase fw-500">Types of control within the firm(s)</p>
                                            <div className="mb-7 input-line">
                                                <label>Ownership of shares</label>
                                                <SelectField 
                                                    form={form}
                                                    name={`${name}.${index}.firm_ownership`}
                                                    options={body.QuestionsIncorp && body.QuestionsIncorp.firm_ownership.Options}
                                                    placeholder={'Choose Ownership of shares'}
                                                    getOptionLabel={(option) => option.content}
                                                    onChange={(value) => {
                                                        if (firmOwnership && firmOwnership.id != value.id) {
                                                            setFirmOwnership(value);
                                                            form.setValue(`${name}.${index}.firm_ownership`, value.id)
                                                        }
                                                    }}
                                                    hideHelperText
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="mb-7 input-line">
                                                <label>Voting Rights</label>
                                                <SelectField 
                                                    form={form}
                                                    name={`${name}.${index}.firm_voting_rights`}
                                                    options={body.QuestionsIncorp && body.QuestionsIncorp.firm_voting_rights.Options}
                                                    placeholder={'Choose Voting Rights'}
                                                    getOptionLabel={(option) => option.content}
                                                    onChange={(value) => {
                                                        if (firmVotingRights && firmVotingRights.id != value.id) {
                                                            setFirmVotingRights(value);
                                                            form.setValue(`${name}.${index}.firm_voting_rights`, value.id)
                                                        }
                                                    }}
                                                    hideHelperText
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div>
                                                <div>Appoint or remove the majority of the board of directors</div>
                                                <RadioGroupYesNo
                                                    form={form}
                                                    radioName={`${name}.${index}.firm_appoint_majority`}
                                                    options={body.QuestionsIncorp && body.QuestionsIncorp.firm_appoint_majority.Options}
                                                    stateValue={firmAppointMajority && firmAppointMajority}
                                                    setStateValue={setFirmAppointMajority}
                                                    setFieldValue={setFieldValue}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div>
                                                <div>Other Significant influences or control</div>
                                                <RadioGroupYesNo
                                                    form={form}
                                                    radioName={`${name}.${index}.firm_other_significant_control`}
                                                    options={body.QuestionsIncorp && body.QuestionsIncorp.firm_other_significant_control.Options}
                                                    stateValue={firmOtherSignificantControl && firmOtherSignificantControl}
                                                    setStateValue={setFirmOtherSignificantControl}
                                                    setFieldValue={setFieldValue}
                                                    disabled={disabled}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 col-12 mb-7">
                                <div className="rad-8 box-shadow-2 p-5">
                                    <div className="mb-2">
                                        <div>Does this officer have the right to exercise significant influence or control over a trust(s) which has a controlling interest in this company?</div>
                                        <RadioGroupYesNo
                                            form={form}
                                            radioName={`${name}.${index}.trust`}
                                            options={body.QuestionsIncorp && body.QuestionsIncorp.trust.Options}
                                            stateValue={trust && trust}
                                            setStateValue={setTrust}
                                            setFieldValue={setFieldValue}
                                            disabled={disabled}
                                        />
                                    </div>
                                    {
                                        body.QuestionsIncorp && body.QuestionsIncorp.trust.Options && trust && trust === body.QuestionsIncorp.trust.Options[0].id.toString() && // Yes
                                        <>
                                            <p className="text-uppercase fw-500">Types of control within the trust(s)</p>
                                            <div className="mb-7 input-line">
                                                <label>Ownership of shares</label>
                                                <SelectField 
                                                    form={form}
                                                    name={`${name}.${index}.trust_ownership`}
                                                    options={body.QuestionsIncorp && body.QuestionsIncorp.trust_ownership.Options}
                                                    placeholder={'Choose Ownership of shares'}
                                                    getOptionLabel={(option) => option.content}
                                                    onChange={(value) => {
                                                        if (trustOwnership && trustOwnership.id != value.id) {
                                                            setTrustOwnership(value);
                                                            form.setValue(`${name}.${index}.trust_ownership`, value.id)
                                                        }
                                                    }}
                                                    hideHelperText
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="mb-7 input-line">
                                                <label>Voting Rights</label>
                                                <SelectField 
                                                    form={form}
                                                    name={`${name}.${index}.trust_voting_rights`}
                                                    options={body.QuestionsIncorp && body.QuestionsIncorp.trust_voting_rights.Options}
                                                    placeholder={'Choose Voting Rights'}
                                                    getOptionLabel={(option) => option.content}
                                                    onChange={(value) => {
                                                        if (trustVotingRights && trustVotingRights.id != value.id) {
                                                            setTrustVotingRights(value);
                                                            form.setValue(`${name}.${index}.trust_voting_rights`, value.id)
                                                        }
                                                    }}
                                                    hideHelperText
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div>
                                                <div>Appoint or remove the majority of the board of directors</div>
                                                <RadioGroupYesNo
                                                    form={form}
                                                    radioName={`${name}.${index}.trust_appoint_majority`}
                                                    options={body.QuestionsIncorp && body.QuestionsIncorp.trust_appoint_majority.Options}
                                                    stateValue={trustAppointMajority && trustAppointMajority}
                                                    setStateValue={setTrustAppointMajority}
                                                    setFieldValue={setFieldValue}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div>
                                                <div>Other Significant influences or control</div>
                                                <RadioGroupYesNo
                                                    form={form}
                                                    radioName={`${name}.${index}.trust_other_significant_control`}
                                                    options={body.QuestionsIncorp && body.QuestionsIncorp.trust_other_significant_control.Options}
                                                    stateValue={trustOtherSignificantControl && trustOtherSignificantControl}
                                                    setStateValue={setTrustOtherSignificantControl}
                                                    setFieldValue={setFieldValue}
                                                    disabled={disabled}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './customCropImage.scss';
const pixelRatio = window.devicePixelRatio || 1;
export default function ImageCrop({
    previewCanvasRef,
    upImg,
    completedCrop,
    setCompletedCrop,
    setCropBlob,
    aspect = 1
}) {
    const imgRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: aspect });

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const getResizedCanvas = (canvas, newWidth, newHeight) => {
        const tmpCanvas = document.createElement('canvas');
        tmpCanvas.width = newWidth;
        tmpCanvas.height = newHeight;

        const ctx = tmpCanvas.getContext('2d');
        ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, newHeight);

        return tmpCanvas;
    };

    const saveCropImage = (previewCanvasRef, crop) => {
        if (crop.width > 0 && crop.height > 0) {
            const canvas = getResizedCanvas(previewCanvasRef, crop.width, crop.height);
            canvas.toBlob(
                (blob) => {
                    setCropBlob(blob);
                },
                'image/png',
                1,
            );
        }
    };

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );
        saveCropImage(previewCanvasRef.current, completedCrop);
    }, [completedCrop]);

    return (
        <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
        />
    );
}

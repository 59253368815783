import { getToken, scanFile } from 'app/pages/_redux/scan-file/scanFileCrud';
import moment from 'moment';
import { toast } from 'react-toastify';
import React from 'react';

export const handleScanFile = async (files) => {
    try {
        let accessToken, expiresIn;
        let isValid = true;
        const expiredToken = localStorage.getItem('scan_file_exp')
            ? moment().isAfter(localStorage.getItem('scan_file_exp'))
            : null;

        if (
            expiredToken ||
            !localStorage.getItem('scan_file_token') ||
            !localStorage.getItem('scan_file_exp')
        ) {
            const { data } = await getToken();
            accessToken = data?.accessToken;
            expiresIn = data?.expiresIn;
            localStorage.setItem('scan_file_token', accessToken);
            localStorage.setItem('scan_file_exp', moment().add(expiresIn - 60, 'seconds'));
        } else {
            accessToken = localStorage.getItem('scan_file_token');
        }

        if (accessToken) {
            for (let i = 0; i < files.length; i++) {
                const response = await scanFile(files[i], accessToken);
                if (response?.data?.result === 'Clean') {
                    files[i].isValid = true;
                } else {
                    files[i].isValid = false;
                    isValid = false;
                }
            }
            return {
                files: files,
                isValid: isValid,
            };
        }
    } catch (error) {
        console.log(error);
        let message = 'Somethings went wrong!';
        toast.error(
            <div className={'ml-2'}>
                {message && <p className={'mb-0'}>{message}</p>}
            </div>
        );
    }
};

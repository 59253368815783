import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import DocumentTitleListSignForm from '../../additional-service/common/DocumentTitleList';
import FileList from '../../additional-service/common/FileList';
import UploadBox from 'app/components/UploadBox';
import DocumentMiniList from 'app/components/DocumentMiniList';
import { Button } from '@mui/material';
import { Modal } from 'react-bootstrap';
import HTMLReader from '../../order-form/document-controller/file-detail/HTMLReader';
import NativeReader from '../../order-form/document-controller/file-detail/NativeReader';
import { cloneDeep } from 'lodash';
import {
    updateLoadingUploadFileAction,
    updateOrderInfo,
} from 'app/pages/_redux/additional-service/slice';
import { downloadFileS3, getSignedUrlS3 } from 'app/pages/_redux/files/filesCrud';
import { createRecordFile, getProcessDetail } from 'app/pages/_redux/additional-service/api';
import { deleteFileAction } from 'app/pages/_redux/additional-service/asyncThunk';
import { isUploaded } from 'app/components/DocumentTitleListSignForm';
import * as ActionsOrder from 'app/pages/_redux/order-form/orderFormActions';
import LoadingButton from 'app/components/LoadingButton';
import DocumentTitleListARService from './DocumentTitleListARService';

const DocumentList = ({
    onSubmit,
    isUploadAllFile = false,
    setActiveStep,
    isARService = false,
}) => {
    let [activeTitle, setActiveTitle] = useState(null);
    let [activeCate, setActiveCate] = useState(null);
    let [showModal, setShowModal] = useState(false);
    let [modalData, setModalData] = useState(null);
    let [isLoading, setIsLoading] = useState(false);
    const TINServiceId = 1851;
    const [htmlDownloading, setHtmlDownloading] = useState(false);
    const { loadingUploadFile, loadingDeleteFile, activeProcess, orderInfo } = useSelector(
        (state) => state.additionalService,
    );
    const uploadBoxRef = useRef(null);
    const dispatch = useDispatch();

    const process = useMemo(() => {
        return orderInfo?.Task?.Processes?.[0];
    }, [orderInfo]);

    const fileTemplates = useMemo(() => {
        if (isARService) {
            const years = orderInfo?.EventYears || [];
            const types = [
                {
                    id: 1,
                    name: 'DORMANT COMPANY',
                    children: null,
                },
                {
                    id: 2,
                    name: 'PURE - HOLDING COMPANY',
                    children: null,
                },
                {
                    id: 3,
                    name: 'OTHERS/ACTIVE COMPANY',
                    children: null,
                },
            ];
            types.forEach((type) => {
                let tempFileTemplates = [];
                process.ProcessStep.FileTemplates.forEach((template) => {
                    let tempTitles = [];
                    years
                        .filter((item) => item.type == type.id)
                        .forEach((year) => {
                            if (template?.types?.includes(year.type)) {
                                tempTitles = [
                                    ...tempTitles,
                                    ...template?.titles?.filter((item) => item.year === year.year),
                                ];
                            }
                        });
                    if (tempTitles.length > 0) {
                        tempFileTemplates.push({
                            ...template,
                            titles: tempTitles,
                        });
                    }
                });
                type.children = tempFileTemplates;
            });
            return types.filter((type) => type?.children?.length > 0);
        } else {
            console.log(orderInfo);
            return orderInfo?.Task?.Processes?.[0]?.ProcessStep?.FileTemplates || [];
        }
    }, [orderInfo]);

    useEffect(() => {
        if (!orderInfo) return;
        //update data from api when upload or delete file
        if (activeCate) {
            const newestActiveCate = [
                ...(orderInfo?.Task?.Processes?.[0]?.ProcessStep?.FileTemplates || []),
            ].find((file) => activeCate.id === file.id);
            setActiveCate(cloneDeep(newestActiveCate));
        }
    }, [orderInfo]);

    const onSelectTitle = useCallback(([cate, file]) => {
        setActiveCate(cate);
        setActiveTitle(file);
    }, []);

    const getNoteContent = (country_id, isTINService) => {
        switch (country_id) {
            //ANGUILLA
            case 8:
                if (isARService) {
                    //Service Accounting Record
                    return (
                        <div className="px-10">
                            <div>
                                <b>1. If the company is dormant:</b>
                            </div>
                            <div>
                                <p className="mb-0">- Sign a Statement for Dormant company.</p>
                            </div>
                            <div>
                                <b>2. If the company is pure-holding company:</b>
                            </div>
                            <div>
                                <p className="mb-0">
                                    - Sign a Statement for Pure holding company; and
                                </p>
                                <p className="mb-0">
                                    - Submit supporting documents as evidence of underlying assets.
                                </p>
                            </div>
                            <div>
                                <b>3. If the company is active:</b>
                            </div>
                            <div>
                                <p className="mb-0">- Submit all Accounting records; and</p>
                                <p className="mb-0">
                                    - Sign a Statement stating for Active company.
                                </p>
                            </div>
                            <div className="mt-4">
                                <b>Note:</b>
                            </div>
                            <div>
                                1. "Supporting documents as evidence of underlying assets" for
                                pure-holding company to submit can be as following forms: Share
                                certificate, title deeds, etc.
                            </div>
                            <div className="mt-4">
                                2. "Accounting Records" are documents relating to a company's assets
                                and liabilities, the receipts and expenditure, sales, purchases and
                                other transactions, including:
                            </div>
                            <div>
                                <p className="mb-0">(a) Bank statements</p>
                                <p className="mb-0">(b) Receipts</p>
                                <p className="mb-0">(c) Invoices</p>
                                <p className="mb-0">(d) Vouchers</p>
                                <p className="mb-0">(e) Title documents</p>
                                <p className="mb-0">(f) Contracts and agreements</p>
                                <p className="mb-0">(g) Ledgers</p>
                                <p className="mb-0">
                                    (h) Any other documentation underpinning a transaction
                                </p>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="px-10">
                            <div>Note:</div>
                            <p>
                                More information in:{' '}
                                <a
                                    target="_blank"
                                    href="https://bbcincorp.com/offshore/articles/anguilla-economic-substance-requirements"
                                >
                                    HERE
                                </a>
                            </p>
                        </div>
                    );
                }

            //BAHAMAS
            case 17:
                return (
                    <div className="px-10">
                        <div>Note:</div>
                        <ul>
                            <li>
                                Form A: Company that provides outsourcing services to an included
                                entity
                            </li>
                            <li>
                                Form B: Company that is engaged in a relevant activity provided and
                                it is not a tax resident in a jurisdiction outside the Bahamas
                            </li>
                            <li>Form C: Company that is a holding company</li>
                            <li>
                                Form D: Company that is not engaged in a relevant activity or is a
                                tax resident in a jurisdiction outside the Bahamas.
                            </li>
                        </ul>
                        <div>
                            If the client is a non-included entity (submit Form D), please provide
                            additional "Evidence of tax resident in another jurisdiction" - except
                            for the company does not fall into the scope of relevant activities
                        </div>
                        <p>
                            More information in:{' '}
                            <a
                                target="_blank"
                                href="https://bbcincorp.com/offshore/articles/bahamas-economic-substance-requirements"
                            >
                                HERE
                            </a>
                        </p>
                    </div>
                );

            //BELIZE
            case 23:
                if (isTINService) {
                    return (
                        <div className="px-10">
                            <div>Note:</div>
                            <p>
                                Belize company must have a Tax Identification Number (“TIN”) in
                                order to submit all the annual compliances of your company.
                            </p>
                        </div>
                    );
                } else {
                    return (
                        <div className="px-10">
                            <div>Note:</div>
                            <ul>
                                <li>
                                    Form B: Company that is engaged in a relevant activity provided
                                    and is not a tax resident in a jurisdiction outside Belize
                                </li>
                                <li>Form C: Company that is a holding company</li>
                                <li>
                                    Form D: Company that is not engaged in a relevant activity or is
                                    a tax resident in a jurisdiction outside Belize
                                </li>
                            </ul>
                            <div>
                                If the client is a non-included entity (submit Form D), please
                                provide additional "Evidence of tax resident in another
                                jurisdiction" - except for the company does not fall into the scope
                                of relevant activities
                            </div>
                            <p>
                                More information in:{' '}
                                <a
                                    target="_blank"
                                    href="https://bbcincorp.com/offshore/news/belize-economic-substance-requirement"
                                >
                                    HERE
                                </a>
                            </p>
                        </div>
                    );
                }

            //BVI
            case 32:
                return (
                    <div className="px-10">
                        <div>Note:</div>
                        <div className="mb-2">
                            ALL COMPANIES are required to file the Declaration of The Beneficial
                            Owner form and ONE of the below documents.
                        </div>
                        <ul className="mb-2">
                            <li>
                                Resolution for Category A: Company that is not engaged in a relevant
                                activity
                            </li>
                            <li>
                                Resolution for Category B: Company that is engaged in a relevant
                                activity and is not a tax resident in a jurisdiction outside BVI
                            </li>
                            <li>
                                Resolution for Category C: Company that is engaged in a relevant
                                activity and is a tax resident in a jurisdiction outside BVI
                            </li>
                        </ul>
                        <div>
                            If the client submits Category C, please provide additional "Evidence of
                            tax resident in another jurisdiction"
                        </div>
                        <p>
                            More information in:{' '}
                            <a
                                target="_blank"
                                href="https://bbcincorp.com/offshore/articles/a-guide-to-bvi-economic-substance-requirements"
                            >
                                HERE
                            </a>
                        </p>
                    </div>
                );

            //CAYMAN ISLAND
            case 41:
                return (
                    <div className="px-10">
                        <p>
                            More information in:{' '}
                            <a
                                target="_blank"
                                href="https://bbcincorp.com/offshore/articles/cayman-islands-economic-substance-requirements"
                            >
                                HERE
                            </a>
                        </p>
                    </div>
                );

            //MARSHALL
            case 136:
                return <div></div>;

            //RAK
            case 236:
                return (
                    <div className="px-10">
                        <div>Note:</div>
                        <div>
                            If the company that is controlled and managed outside of RAK and is a
                            tax resident in a jurisdiction other than RAK, please provide evidence
                            of tax-resident in another jurisdiction
                        </div>
                        <p>
                            More information in:{' '}
                            <a
                                target="_blank"
                                href="https://bbcincorp.com/offshore/articles/uae-economic-substance-regulations"
                            >
                                HERE
                            </a>
                        </p>
                    </div>
                );

            //PANAMA
            case 169:
                if (isARService) {
                    return (
                        <div className="px-10">
                            <div>
                                <b>1. If the company is dormant:</b>
                            </div>
                            <div>
                                <p className="mb-0">
                                    - Sign Financial Profile (individually with each UBO(s)); and
                                </p>
                                <p className="mb-0">
                                    - Sign Resolution of Beneficial Owners for dormant company.
                                </p>
                            </div>
                            <div>
                                <b>2. If the company is holding:</b>
                            </div>
                            <div>
                                <p className="mb-0">
                                    - Sign Financial Profile (individually with each UBO(s)); and
                                </p>
                                <p className="mb-0">
                                    - Sign Holding declaration - statement value of assets; and
                                </p>
                                <p className="mb-0">- Submit additional documents, including:</p>
                                <p className="mb-0 ml-2">+ Accounting Records (if any);</p>
                                <p className="mb-0 ml-2">
                                    + Documents/Information about the value of the assets held, the
                                    income, and expenses;
                                </p>
                                <p className="mb-0 ml-2">+ Financial Statement.</p>
                            </div>
                            <div>
                                <b>3. If the company is operative:</b>
                            </div>
                            <div>
                                <p className="mb-0">
                                    - Sign Financial Profile (individually with each UBO(s)); and
                                </p>
                                <p className="mb-0">- Submit additional documents:</p>
                                <p className="mb-0 ml-2">+ Accounting Records;</p>
                                <p className="mb-0 ml-2">+ Financial Statement.</p>
                            </div>
                            <div className="mt-4">
                                <b>Note:</b>
                            </div>
                            <div>
                                <b>* Financial Statement requirements:</b>
                            </div>
                            <div>
                                <p className="mb-0 ml-2">
                                    + Following the International Standards of Financial Information
                                    (IFRS); and
                                </p>
                                <p className="ml-2">
                                    + Being endorsed/signed by a CPA; or being accompanied by a
                                    certification signed by a CPA whereby he/she certifies his/her
                                    preparation of Financial Statements.
                                </p>
                            </div>
                            <div>
                                <b>* "Accounting Records"</b> are those that clearly and precisely
                                indicate the operations of the legal entity, its assets, liabilities
                                and equity, those that serve to determine the financial situation
                                with accuraty.
                            </div>
                            <p className="mt-4">
                                More information in:{' '}
                                <a
                                    target="_blank"
                                    href="https://bbcincorp.com/offshore/news/changes-to-accounting-records-requirements-in-panama"
                                >
                                    HERE
                                </a>
                            </p>
                        </div>
                    );
                }

            //SEYCHELLES
            case 189:
                if (isARService) {
                    //Service Accounting Record
                    return (
                        <div className="px-10">
                            <div>
                                <b>1. If the company is dormant:</b>
                            </div>
                            <div>
                                <p className="mb-0">- Sign a Resolution for dormant company</p>
                            </div>
                            <div>
                                <b>2. If the company is pure-holding company:</b>
                            </div>
                            <div>
                                <p className="mb-0">
                                    - Sign a Resolution for pure holding company; and
                                </p>
                                <p className="mb-0">
                                    - Submit supporting documents as evidence of underlying assets.
                                </p>
                            </div>
                            <div>
                                <b>3. If the company is active:</b>
                            </div>
                            <div>
                                <p className="mb-0">- Submit all Accounting Records.</p>
                            </div>
                            <div className="mt-4">
                                <b>Note:</b>
                            </div>
                            <div>
                                1. <b>"Supporting documents as evidence of underlying assets"</b>{' '}
                                for pure-holding company to submit can be as following forms: Share
                                certificate, title deeds, etc.
                            </div>
                            <div className="mt-4">
                                2. <b>"Accounting Records"</b> are documents relating to a company's
                                assets and liabilities, the receipts and expenditure, sales,
                                purchases and other transactions, including: Bank statements;
                                Receipts; Invoices; Contracts and Agreements; etc.
                            </div>
                            <p className="mt-4">
                                More information in:{' '}
                                <a
                                    target="_blank"
                                    href="https://bbcincorp.com/offshore/news/updates-on-seychelles-accounting-records"
                                >
                                    HERE
                                </a>
                            </p>
                        </div>
                    );
                }

            //SVG
            case 209:
                return (
                    <div className="px-10">
                        <div>
                            <p className="mb-0">- ES Form 1: For all companies</p>
                            <p>
                                - ES Form 2: For the company that is engaged in a relevant activity
                            </p>
                        </div>
                        <div>
                            If the client is a tax resident in a jurisdiction outside SVG, please
                            provide additional "Evidence of tax resident in another jurisdiction"
                        </div>
                        <p className="mt-4">
                            More information in:{' '}
                            <a
                                target="_blank"
                                href="https://bbcincorp.com/offshore/articles/svg-economic-substance-rule"
                            >
                                HERE
                            </a>
                        </p>
                    </div>
                );
        }
    };

    const onUploadFile = async (files) => {
        if (!files.length || !activeTitle || !process) return;
        dispatch(updateLoadingUploadFileAction('loading'));
        const promisesPutFile = [];
        const promisesCreateRecordFile = [];
        const params = [];
        for (let i = 0; i < files.length; i++) {
            params.push({ filename: files[i].name, content_type: files[i].type });
        }
        try {
            /* gen s3 signed url */
            const {
                data: { data: signedUrls },
            } = await getSignedUrlS3(params);
            for (let i = 0; i < signedUrls.length; i++) {
                promisesPutFile.push(axios.put(signedUrls[i].url, files[i], {}));
            }
            /* Call tất cả các hàm push file */
            await Promise.all(promisesPutFile);
            /* Xử lý create record file */
            for (let i = 0; i < signedUrls.length; i++) {
                let payload;
                payload = {
                    file_type_id: activeTitle.file_type_id,
                };
                if (activeTitle.company_member_id) {
                    let member = {
                        file_template_id: activeTitle.file_template_id,
                        name: files[i].name,
                        s3_path: signedUrls[i].filename,
                        mime_type: files[i].type,
                        task_id: orderInfo.Task.id,
                        file_type_id: activeTitle.file_type_id,
                        company_id: orderInfo?.company_id,
                        company_member_id: activeTitle.company_member_id,
                    };
                    if (activeTitle.company_event_id) {
                        member = {
                            ...member,
                            company_event_id: activeTitle.company_event_id,
                        };
                    }
                    payload.member = member;
                } else {
                    let file_template = {
                        file_template_id: activeTitle.file_template_id,
                        name: files[i].name,
                        s3_path: signedUrls[i].filename,
                        mime_type: files[i].type,
                        task_id: orderInfo.Task.id,
                        file_type_id: activeTitle.file_type_id,
                        company_id: orderInfo?.company_id,
                    };
                    if (activeTitle.company_event_id) {
                        file_template = {
                            ...file_template,
                            company_event_id: activeTitle.company_event_id,
                        };
                    }
                    payload.file_template = file_template;
                }
                // let file_template = {
                //     file_template_id: activeTitle.file_template_id,
                //     name: signedUrls[i].filename,
                //     s3_path: signedUrls[i].filename,
                //     mime_type: files[i].type,
                //     task_id: orderInfo.Task.id,
                //     company_id: orderInfo?.company_id,
                // };
                // payload.file_template = file_template;

                // console.log(payload, process)
                promisesCreateRecordFile.push(createRecordFile(payload));
            }
            await Promise.all(promisesCreateRecordFile);
            /* Get lại process detail data để hiển thị lại các file đã upload */
            const {
                data: { data },
            } = await getProcessDetail(activeProcess.Processes[0].id);
            dispatch(updateOrderInfo(data));
            dispatch(updateLoadingUploadFileAction('idle'));
            /* reset value of input */
            uploadBoxRef.current.value = '';
        } catch (e) {
            console.log('error upload: ', e);
            dispatch(updateLoadingUploadFileAction('error'));
            toast.error('Somethings went wrong!');
            /* reset value of input */
            uploadBoxRef.current.value = '';
        }
    };

    const uploadList = useMemo(() => {
        return activeTitle?.files?.length > 0
            ? activeTitle.files
            : activeCate?.Files.filter((file) =>
                  /* không thể check === vì giá trị có thể undefined hoặc null */
                  file?.company_event_id
                      ? file?.company_event_id == activeTitle?.company_event_id
                      : file?.company_member_id
                      ? file?.company_member_id == activeTitle?.company_member_id
                      : file?.file_template_id == activeTitle?.file_template_id,
              ) || [];
    }, [activeCate, activeTitle]);

    const onClickView = async (doc) => {
        try {
            const {
                data: { data },
            } = await downloadFileS3(doc.s3_path);
            if (['doc', 'docx'].includes(doc.s3_path.split('.')[1].toLowerCase())) {
                saveAs(data, doc.s3_path);
                return;
            }
            setShowModal(true);
            setModalData({ file: { name: doc.s3_path }, file_url: data });
        } catch (e) {
            toast.error('Somethings when wrong!');
        }
    };

    const onClickDelete = async (doc) => {
        const resultAction = await dispatch(deleteFileAction(doc.id));
        if (deleteFileAction.fulfilled.match(resultAction)) {
            /* Get lại process detail data để hiển thị lại các file đã upload */
            const {
                data: { data },
            } = await getProcessDetail(activeProcess.Processes[0].id);
            dispatch(updateOrderInfo(data));
        } else {
            toast.error('Somethings went wrong!');
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const downloadNativePDF = () => {
        return new Promise((resolve, reject) => {
            saveAs(modalData.file_url, modalData.file.name);
            resolve(true);
        });
    };

    const isCheckValid = () => {
        if (isUploadAllFile) {
            return isHasUploadAll();
        } else {
            return isHasUploaded();
        }
    };

    const isHasUploaded = () => {
        return process?.ProcessStep?.FileTemplates && process?.ProcessStep?.FileTemplates.length > 0
            ? process?.ProcessStep.FileTemplates.some((template) => {
                  return template?.titles?.some((title) => {
                      return isUploaded(title, template);
                  });
              })
            : false;
    };

    const isHasUploadAll = () => {
        return process?.ProcessStep?.FileTemplates && process?.ProcessStep?.FileTemplates.length > 0
            ? process?.ProcessStep.FileTemplates.every((template) => {
                  return template?.titles?.every((title) => {
                      return isUploaded(title, template);
                  });
              })
            : false;
    };

    const handleNext = async () => {
        if (!isCheckValid()) return;
        try {
            setIsLoading(true);
            onSubmit && onSubmit();
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const openHTMLFile = (params) => {
        if (orderInfo?.Task && orderInfo?.Task?.id && params) {
            // setActionLoading(true);
            dispatch(
                ActionsOrder.fetchTemplateHTML({
                    task_id: orderInfo.Task.id,
                    ...params.templateParams,
                }),
            )
                .then((response) => {
                    if (response && response.data && response.data.html) {
                        setShowModal(true);
                        setModalData({
                            html: response.data.html,
                            templateParams: params.templateParams,
                            file_name: params.file_name,
                        });
                    }
                })
                .finally(() => {
                    // setActionLoading(false);
                });
        }
    };
    const downloadHTMLAsPDF = () => {
        if (orderInfo?.Task && orderInfo?.Task.id && modalData && modalData.templateParams) {
            setHtmlDownloading(true);
            dispatch(
                ActionsOrder.downloadTemplateHTML({
                    task_id: orderInfo?.Task.id,
                    ...modalData.templateParams,
                }),
            )
                .then((response) => {
                    saveAs(
                        response,
                        `${modalData.file_name.split('.')[0]} - ${orderInfo?.id}.${
                            modalData.file_name.split('.')[1]
                        }`,
                    );
                    setHtmlDownloading(false);
                })
                .catch(() => {
                    setHtmlDownloading(false);
                });
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <>
            <div className={'row shadow-sm rounded mb-5'}>
                <div className={'col-12 col-md-3 bg-light p-0'}>
                    <h6 className={'text-center border-bottom p-5'}>DOCUMENT LIST</h6>
                    <div className="px-5">
                        {isARService ? (
                            <DocumentTitleListARService
                                filesTemplate={fileTemplates}
                                onSelectTitle={onSelectTitle}
                                autoSelectFirstTitle={true}
                            />
                        ) : (
                            <DocumentTitleListSignForm
                                filesTemplate={fileTemplates}
                                onSelectTitle={onSelectTitle}
                                autoSelectFirstTitle={true}
                            />
                        )}
                    </div>
                </div>

                <div
                    className={
                        'col-12 col-md-6 border-right p-0 d-flex flex-column justify-content-between'
                    }
                >
                    <div>
                        <h6 className={'text-center border-bottom p-5'}>FILES DETAILS</h6>
                        <FileList
                            currentTemplate={activeCate}
                            currentTitle={activeTitle}
                            openHTMLFile={openHTMLFile}
                            orderInfo={orderInfo}
                        />
                    </div>
                    {getNoteContent(
                        orderInfo?.Company?.country_id,
                        orderInfo?.OrderItems?.some((item) => item?.Service?.id === TINServiceId),
                        activeTitle,
                        orderInfo?.EventYears,
                    )}
                </div>

                {activeTitle && (
                    <div className={'col-12 col-md-3 p-0 '}>
                        <h6 className={'text-center border-bottom p-5'}>SIGNING STATUS</h6>
                        <div className={'p-3'}>
                            <UploadBox
                                onUploadFile={onUploadFile}
                                ref={uploadBoxRef}
                                loading={loadingUploadFile === 'loading'}
                            />
                            <DocumentMiniList
                                documentList={uploadList}
                                onClickView={onClickView}
                                onClickDelete={onClickDelete}
                                loadingMap={loadingDeleteFile}
                                panelTitle={'Your wet-link documents'}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div>
                {setActiveStep ? (
                    <>
                        <Button
                            size="large"
                            variant="contained"
                            disableElevation
                            className="mr-2"
                            onClick={handleBack}
                            disabled={isLoading}
                        >
                            Back
                        </Button>
                        <LoadingButton
                            disabled={isLoading || !isCheckValid()}
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="large"
                            style={{ color: !isLoading ? '#fff' : 'transparent' }}
                            loading={isLoading}
                            onClick={handleNext}
                        >
                            Complete
                        </LoadingButton>
                    </>
                ) : (
                    <LoadingButton
                        disabled={isLoading || !isCheckValid()}
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                        style={{ color: !isLoading ? '#fff' : 'transparent' }}
                        loading={isLoading}
                        onClick={handleNext}
                    >
                        Next
                    </LoadingButton>
                )}
            </div>

            <Modal show={showModal} onHide={closeModal} size="xl" centered>
                <Modal.Body>
                    {modalData && (
                        <>
                            {modalData?.html && (
                                <HTMLReader
                                    closeDetailModal={closeModal}
                                    data={modalData}
                                    download={downloadHTMLAsPDF}
                                    downloading={htmlDownloading}
                                    fetched={true}
                                />
                            )}
                            {modalData?.file_url && (
                                <NativeReader
                                    closeDetailModal={closeModal}
                                    data={modalData}
                                    download={downloadNativePDF}
                                />
                            )}
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DocumentList;

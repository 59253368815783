import React, { useState, useEffect } from 'react';
import PaypalDialog from '../../components/payment/paypalDialog';
import ElementsStripe from '../../components/payment/elementsStripe';
import { shallowEqual, useSelector } from 'react-redux';
import { BankPayment } from 'app/pages/components/payment/bankPayment';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
// const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
export const PaymentFn = ({
    body,
    countryID,
    paymentMethod,
    isNewIncorp,
    paidAmount,
    param,
    setParam,
    existOrder,
    form,
    services = [],
    companyId = null,
    formRef = {}
}) => {
    const [openPaypal, setOpenPaypal] = useState(false);
    const [openStripe, setOpenStripe] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [payloadPay, setPayloadPay] = useState(null);
    const [allowedPayment, setAllowedPayment] = useState(false);
    const classes = useStyles();
    const navigate = useNavigate();
    const { authState } = useSelector((state) => ({ authState: state.auth }), shallowEqual);

    async function Payment() {
        let param_temp = { ...param.payload };
        let isValid = existOrder ?? false;
        if (!isValid) {
            if (form) {
                await form.trigger();
                if (form.formState.isValid && body.billingForm) {
                    isValid = true;
                    param_temp = { ...param_temp, Billing: body.billingForm };
                }
            } else if (body.billing_id) {
                isValid = true;
                param_temp = { ...param_temp, billing_id: body.billing_id };
            }
        }
        if (isValid) {
            param_temp = {
                uri: param.uri,
                payload: param_temp,
            };
            setPayloadPay(param_temp);
            if (paymentMethod === 'paypal') {
                setOpenPaypal(true);
            }
            if (paymentMethod === 'stripe') {
                setOpenStripe(true);
            }
            if (paymentMethod === 'bank') {
                setIsloading(true);
                BankPayment(param_temp)
                    .then((res) => {
                        setIsloading(false);
                        return navigate('/notification-payment', {
                            state: res.data.data,
                        });
                    })
                    .catch((error) => {
                        setIsloading(false);
                    });
            }
        }
    }
    function handleClose() {
        setOpenPaypal(false);
        setOpenStripe(false);
    }
    const handleAllowedPayment = (event) => {
        setAllowedPayment(!allowedPayment);
    };
    return (
        <>
            <div className="container">
                <div className="row justify-content-center flex-nowrap mb-4">
                    <FormControlLabel
                        className="mx-0 align-items-start"
                        style={{ marginTop: -9 }}
                        control={
                            <Checkbox
                                checked={allowedPayment}
                                onChange={handleAllowedPayment}
                                name="checkedPayment"
                            />
                        }
                    />
                    <div className="text-left">
                        <span
                            className="mr-1"
                            onClick={handleAllowedPayment}
                            style={{ cursor: 'pointer' }}
                        >
                            I confirm that I have read, understood and agreed to all terms and
                            conditions in
                        </span>
                        <a
                            href="https://bbcincorp.com/#term-and-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms &amp; Policy
                        </a>
                        <span
                            className="mx-1"
                            onClick={handleAllowedPayment}
                            style={{ cursor: 'pointer' }}
                        >
                            and
                        </span>
                        <a
                            href="https://bbcincorp.com/refund-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Refund Policy
                        </a>
                        <span
                            className="ml-1"
                            onClick={handleAllowedPayment}
                            style={{ cursor: 'pointer' }}
                        >
                            at BBCIncorp.com
                        </span>
                    </div>
                </div>
            </div>
            <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={Payment}
                className={classes.button}
                style={{ color: '#fff' }}
                disabled={
                    !companyId
                        ? paymentMethod && !isLoading && allowedPayment
                            ? false
                            : true
                        : paymentMethod && !isLoading && allowedPayment && services.length
                        ? false
                        : true
                }
            >
                {!isLoading && <div>Make Payment</div>}
                {isLoading && (
                    <div>
                        <CircularProgress size={20} />
                    </div>
                )}
            </Button>
            <PaypalDialog
                token={authState.authToken}
                payloadPay={payloadPay}
                open={openPaypal}
                onClose={handleClose}
                setOpenPaypal={setOpenPaypal}
            />
            <ElementsStripe
                body={body}
                countryID={countryID}
                token={authState.authToken}
                payloadPay={payloadPay}
                open={openStripe}
                onClose={handleClose}
                setOpenStripe={setOpenStripe}
                paidAmount={paidAmount}
            />
        </>
    );
};

export const COUNTRIES_NOT_DISABLED_BANKING_FORM = [111, 58, 191]; // JERSEY, CYPRUS, SINGAPORE

export const REMOVED_PUBLISHED_TRACKING_LINK_BANKS = [
    {
        id: 26,
        name: 'Statrys',
        Country: {
            id: 98,
            name: 'Hong Kong',
        },
    },
    {
        id: 28,
        name: 'Aspire',
        Country: {
            id: 191,
            name: 'Singapore',
        },
    },
    {
        id: 2,
        name: 'Heritage Bank',
        Country: {
            id: 23,
            name: 'Belize',
        },
    },
    {
        id: 3,
        name: 'Belize Bank',
        Country: {
            id: 23,
            name: 'Belize',
        },
    },
    {
        id: 4,
        name: 'First Caribbean International Bank',
        Country: {
            id: 32,
            name: 'British Virgin Islands',
        },
    },
    {
        id: 14,
        name: 'Mauritius Commercial Bank',
        Country: {
            id: 139,
            name: 'Mauritius',
        },
    },
    {
        id: 15,
        name: 'Investec Bank',
        Country: {
            id: 139,
            name: 'Mauritius',
        },
    },
    {
        id: 24,
        name: 'RAK Bank',
        Country: {
            id: 236,
            name: 'United Arab Emirates',
        },
    },
    {
        id: 27,
        name: 'Wise',
        Country: {
            id: 237,
            name: 'United Kingdom',
        },
    },
    {
        id: 29,
        name: 'Revolut',
        Country: {
            id: 237,
            name: 'United Kingdom',
        },
    },
    {
        id: 30,
        name: 'Payoneer',
        Country: {
            id: 238,
            name: 'United States',
        },
    },
    {
        id: 32,
        name: 'Mercury',
        Country: {
            id: 238,
            name: 'United States',
        },
    },
];

import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Tooltip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    arrow: {
        color: '#3783e7',
    },
    tooltip: {
        fontSize: '12px',
        fontWeight: 300,
        letterSpacing: '0.3px',
        backgroundColor: '#3783e7',
    },
}));

export function Suggestion({
    index,
    body,
    countriesCooperation,
    phonePrefixList,
    setCountry,
    setPhonePrefix,
    form,
    hasShowSelectFullName,
}) {
    const classesTooltip = useStyles();
    const [suggestionInfo, setSuggestionInfo] = useState(null);

    useEffect(() => {
        try {
            if (body && countriesCooperation) {
                setSuggestionInfo(getSuggestionInfo(body, countriesCooperation));
            }
        } catch (error) {
            console.log(error);
        }
    }, [body, countriesCooperation]);

    function getSuggestionInfo(body, countriesCooperation) {
        let results = null;
        try {
            if (body.contactInfo) {
                let country =
                    (countriesCooperation &&
                        countriesCooperation.find(
                            (e) => e.id === body.contactInfo.address_country_id,
                        )) ||
                    countriesCooperation[0];
                let phonePrefix =
                    (phonePrefixList &&
                        phonePrefixList.find((e) => e.id === body.contactInfo.phone_country_id)) ||
                    phonePrefixList[0];
                const name = body.contactInfo.full_name

                results = {
                    id: body.contactInfo.id,
                    full_name: name,
                    first_name: body?.contactInfo?.first_name,
                    middle_name: body?.contactInfo?.middle_name,
                    last_name: body?.contactInfo?.last_name,
                    country_id: body.contactInfo.country_id || body.contactInfo.address_country_id,
                    country: country,
                    phone_country_id: body.contactInfo.phone_country_id,
                    phone_number: body.contactInfo.phone_number,
                    phonePrefix: phonePrefix,
                    email: body.contactInfo.email,
                    full_info:
                        name +
                        ', ' +
                        '+' +
                        phonePrefix.phone_code +
                        ' ' +
                        body.contactInfo.phone_number +
                        ', ' +
                        body.contactInfo.email +
                        ', ' +
                        country.name,
                };
            }
        } catch (error) {
            console.log(error);
        }
        return results;
    }

    function chooseSuggestionInfo(suggestionInfo) {
        try {
            form.setValue(`members.${index}.id`, suggestionInfo.id);
            form.setValue(`members.${index}.first_name`, suggestionInfo.first_name);
            form.setValue(`members.${index}.middle_name`, suggestionInfo.middle_name);
            form.setValue(`members.${index}.last_name`, suggestionInfo.last_name);
            form.setValue(`members.${index}.full_name`, suggestionInfo.full_name);
            form.setValue(`members.${index}.country_id`, suggestionInfo.country_id);
            form.setValue(`members.${index}.phone_code`, suggestionInfo.phone_country_id);
            form.setValue(`members.${index}.phone`, suggestionInfo.phone_number);
            form.setValue(`members.${index}.email`, suggestionInfo.email);
            form.setValue(`members.${index}.is_contact_person`, 1);
            form.setValue(`members.${index}.isAfterUseContactInfo`, true);

            setCountry(suggestionInfo.country);
            setPhonePrefix(suggestionInfo.phonePrefix);

            setSuggestionInfo(null);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            {suggestionInfo && (
                <div className="suggestion mt-2 mb-3">
                    <Tooltip
                        title={suggestionInfo.full_info}
                        placement="top"
                        arrow
                        classes={classesTooltip}
                    >
                        <Button
                            type="button"
                            variant="contained"
                            size="small"
                            color="secondary"
                            className=""
                            style={{
                                color: '#fff',
                                padding: '0 6px',
                                textTransform: 'lowercase',
                                fontSize: '1rem',
                            }}
                            onClick={() => chooseSuggestionInfo(suggestionInfo)}
                        >
                            Use contact information?
                        </Button>
                    </Tooltip>
                </div>
            )}
        </>
    );
}

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const blueColor = '#3783E7';

export default function LabelTooltip({
    label = '',
    icon,
    title = '',
    placement = 'top',
    arrow = true,
    color = blueColor,
}) {
    const useStyles = makeStyles((theme) => ({
        customTooltip: {
            backgroundColor: color,
        },
        customArrow: {
            color: color,
        },
    }));
    const classes = useStyles();
    let Icon = icon || InfoIcon;

    return (
        <Box display={'flex'} alignItems={'center'}>
            <label style={{ marginRight: '5px' }}>{label}</label>
            <Tooltip
                classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow,
                }}
                title={
                    <Typography sx={{ fontSize: '12.5px', fontWeight: 'light' }}>
                        {title}
                    </Typography>
                }
                placement={placement}
                arrow={arrow}
            >
                <Icon sx={{ fontSize: '18px', fill: color, cursor: 'pointer' }} />
            </Tooltip>
        </Box>
    );
}

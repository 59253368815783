import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';
import Switch from '@mui/material/Switch';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DirectorIndividual from './step3-helper/director-individual';
import DirectorCorporation from './step3-helper/director-corporation';
import SourseForFunds from './step3-helper/sourse-for-funds';
import Represented from './step3-helper/represented';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../../../_redux/order-form/orderFormActions';
import * as actionsEnum from '../../../../../../_redux/enum/enumActions';
import Nominee from './step3-helper/nominee';
import {
    EmptyMemberCommon,
    formatCompanyMembers,
    memberCommonYupValidate,
} from './step3-helper/helpers';
import References from './references/References';
import MemberRoleCheckbox from './step3-helper/member-role-checkbox';
import { cloneDeep, get, head, isEmpty, uniq } from 'lodash';

// import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';
import { CAYMAN_ID, RAK_ID, UK_ID } from 'constants/country';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getDefaultPhonePrefix } from 'app/pages/services/utilities/NationalityHelpers';

const fieldArraySchema = ({ hasShowSelectFullName }) => {
    return Yup.object({
        members: Yup.array().of(
            Yup.object().shape({
                full_name: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: true,
                        then: Yup.string()
                            .nullable()
                            .required(
                                hasShowSelectFullName
                                    ? 'Please select your full name!'
                                    : 'Full name is required!',
                            ),
                    }),
                first_name:
                    hasShowSelectFullName &&
                    Yup.string()
                        .nullable()
                        .when('individual', {
                            is: true,
                            then: Yup.string().nullable().required('First name is required!'),
                        }),
                middle_name: hasShowSelectFullName && Yup.string().nullable(),
                last_name:
                    hasShowSelectFullName &&
                    Yup.string()
                        .nullable()
                        .when('individual', {
                            is: true,
                            then: Yup.string().nullable().required('Last name is required!'),
                        }),
                occupation: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: true,
                        then: Yup.string().nullable().required('Occupation is required!'),
                    }),
                phone: Yup.string()
                    .nullable()
                    .matches(/^[0-9]*$/, 'Please enter number!')
                    .required('Phone number is required!'),
                email: Yup.string()
                    .nullable()
                    .required('Email is required!')
                    .email('Please enter valid email!'),
                company_name: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string().required('Company name is required!'),
                    }),
                company_number: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string().required('Company number is required'),
                    }),
                country_of_incorporation: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string().required('Country of Incorporation is required!'),
                    }),
                registered_address: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string().required('Registered Address is required!'),
                    }),
                date_of_incorporation: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string().required('Date of incorporation is required!'),
                    }),
                business_activity_array: Yup.array()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.array()
                            .nullable()
                            .min(1, 'Business activities is required!')
                            .required('Business activities is required!'),
                    }),
                business_activity_detail: Yup.string()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.string()
                            .nullable()
                            .when('business_activity_detail', (value, schema) => {
                                return Yup.string()
                                    .nullable()
                                    .test(
                                        'required',
                                        'Please write a complete description of your business activities at least 10 words',
                                        (val) => val && val.trim().split(/\s+/).length >= 10,
                                    );
                            }),
                    }),
                country_operations: Yup.array()
                    .nullable()
                    .when('individual', {
                        is: false,
                        then: Yup.array()
                            .nullable()
                            .min(1, 'Countries operation is required!')
                            .required('Countries operation is required!'),
                    }),
                other_asset: Yup.string()
                    .nullable()
                    .when('showOther', {
                        is: true,
                        then: Yup.string().required("Please provide other asset's name."),
                    }),
                other_money: Yup.number()
                    .transform((value) =>
                        isNaN(value) || value === undefined || value === null ? 0 : value,
                    )
                    .nullable()
                    .when('other_asset', {
                        is: (value) => value && value.length > 0,
                        then: Yup.number().min(1, `Please provide other asset's amount`),
                    }),
                other_detail_income: Yup.string()
                    .nullable()
                    .when('showOtherIncome', {
                        is: true,
                        then: Yup.string().required("Please provide other income's name"),
                    }),
                other_money_income: Yup.number()
                    .transform((value) =>
                        isNaN(value) || value === undefined || value === null ? 0 : value,
                    )
                    .nullable()
                    .when('other_detail_income', {
                        is: (value) => value && value.length > 0,
                        then: Yup.number().min(1, `Please provide other asset's amount`),
                    }),

                total_asset: Yup.number()
                    .nullable()
                    .when('isSVG', {
                        is: true,
                        then: Yup.number().min(1, 'Please fill up one of the fields above!'),
                    }),

                total_income: Yup.number()
                    .nullable()
                    .when('isSVG', {
                        is: true,
                        then: Yup.number().min(1, 'Please fill up one of the fields above!'),
                    }),

                is_represented: Yup.boolean(),
                is_contact_person: Yup.number(),

                ...memberCommonYupValidate,
            }),
        ),
    });
};

export default function Step2({
    setActiveStep,
    activeStep,
    isHongkong,
    isSVG,
    body,
    setBody,
    isLoading,
    setIsLoading,
    disabled,
    hideAction,
    activeStepRef = {},
    validateStepsRef = {},
    index,
    hasShowSelectFullName,
}) {
    const { orderInfo, businessActivityWithCountry } = useSelector(
        (state) => ({
            orderInfo: state.orderForm.orderInfo,
            businessActivityWithCountry: state.enum.businessActivityWithCountry,
        }),
        shallowEqual,
    );
    const { countriesOptions, phonePrefixCountriesCooperation } = useSelector(
        (state) => state.enum,
    );
    const dispatch = useDispatch();
    const [countryOperation, setCountryOperation] = useState([]);
    const [initForm, setInitForm] = useState({
        members: body.companyStructure?.Directors,
    });

    const form = useForm({
        defaultValues: {
            members: initForm.members,
        },
        resolver: yupResolver(fieldArraySchema({ hasShowSelectFullName })),
    });

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'members',
        keyName: 'customId',
    });

    const isUsedContactInformation = fields?.some((item) => item.is_contact_person === 1);

    // switch
    const [stateSwitch, setStateSwitch] = useState(
        body.companyStructure?.director_type_member_list ?? [true],
    );

    const handleChangeSwitch = (event, index) => {
        let currentState = [...stateSwitch];
        currentState[index] = event.target.checked;
        setStateSwitch(currentState);

        // xử lý switch type member => back về lại vẫn giữ nguyên thông tin
        const currentMember = fields[index];
        if (event.target.checked !== currentMember?.individual) {
            // nếu type khác type ban đầu thì clear data member
            form.setValue(`members.${index}`, {
                ...initFieldObject,
                country_id: currentMember?.country_id,
                country_of_incorporation: fields[index]?.country_of_incorporation,
                is_represented: currentMember?.is_represented,
                type_member_id: currentMember?.individual ? 1 : 2,
                individual: !currentMember?.individual,
                id: currentMember?.id || null,
                company_position_id: currentMember?.company_position_id
            });
        } else {
            // nếu type ban đầu thì giữ nguyên thông tin init member
            if (body.companyStructure?.Directors?.[index]) {
                form.setValue(`members.${index}`, body.companyStructure?.Directors?.[index]);
            } else {
                form.setValue(`members.${index}`, initFieldObject);
            }
        }
    };

    // tab
    const [tabValue, setTabValue] = React.useState(0);
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        const countryIds = form
            .watch('members')
            ?.filter((member) => member.country_of_incorporation !== 0)
            ?.map((member) => member?.country_of_incorporation);

        let countryCorporationIds = [];
        if (countryIds?.length) {
            countryIds.forEach((countryId) => {
                const normalListCountry = countryCorporationIds.filter(
                    (item) => ![CAYMAN_ID, UK_ID, RAK_ID].includes(item),
                );

                if (
                    ![CAYMAN_ID, UK_ID, RAK_ID].includes(countryId) &&
                    !normalListCountry?.length &&
                    !businessActivityWithCountry?.normalList?.length
                ) {
                    countryCorporationIds.push(countryId);
                }

                if (countryId === CAYMAN_ID && !businessActivityWithCountry?.caymanList?.length) {
                    countryCorporationIds.push(CAYMAN_ID);
                }
                if (countryId === UK_ID && !businessActivityWithCountry?.ukList?.length) {
                    countryCorporationIds.push(UK_ID);
                }
                if (countryId === RAK_ID && !businessActivityWithCountry?.rakList?.length) {
                    countryCorporationIds.push(RAK_ID);
                }
            });
        }

        countryCorporationIds
            .filter((countryCorporationId) => countryCorporationId > 0)
            .forEach((countryId) => {
                dispatch(actionsEnum.fetchBusinessActivity(countryId, true));
            });

        if (!countriesOptions?.length) {
            dispatch(actionsEnum.fetchCountries());
        }
    }, []);

    useEffect(() => {
        if (body && countriesOptions.length) {
            const tempCountryOperations = body.companyInfo.country_operations?.map((item) => ({
                ...item,
                value: item.id,
                label: item.name,
            }));
            setCountryOperation(tempCountryOperations);
        }
    }, [countriesOptions]);

    // handle step
    const handleNext = (values) => {
        try {
            let checkLogic = true;

            if (!body.isPanama) {
                if (
                    body.represented_director &&
                    values.members.find((e) => e.is_represented === true) === undefined
                ) {
                    checkLogic = false;
                    Swal.fire({
                        icon: 'warning',
                        title: 'Warning!',
                        text: body.Represented.Director.errorMessage,
                        confirmButtonColor: '#17c191',
                        confirmButtonText: 'OK',
                    });
                }
            } else {
                if (!form.watch('members').some((member) => member.president)) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Warning!',
                        text: 'Please check at least one predisent',
                        confirmButtonColor: '#17c191',
                        confirmButtonText: 'OK',
                    });
                    return;
                }
                if (!form.watch('members').some((member) => member.secretary)) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Warning!',
                        text: 'Please check at least one secretary',
                        confirmButtonColor: '#17c191',
                        confirmButtonText: 'OK',
                    });
                    return;
                }
                if (!form.watch('members').some((member) => member.treasurer)) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Warning!',
                        text: 'Please check at least one treasurer',
                        confirmButtonColor: '#17c191',
                        confirmButtonText: 'OK',
                    });
                    return;
                }
            }

            if (checkLogic) {
                setIsLoading(true);

                let finalData = {
                    companyStructureCreate: [],
                    companyStructureUpdate: [],
                    companyStructureDelete: {
                        company_position_ids: [],
                    },
                };
                // flag to check if contact-info-member is up to date => update data in step Contact Information
                let flag = false;
                let contactInfoTemp = {};
                values.members.forEach((item, indexMember) => {
                    let newItem = {};

                    if (item.is_contact_person === 1) {
                        const phone_prefix = getDefaultPhonePrefix(
                            item?.phone_code,
                            phonePrefixCountriesCooperation,
                        );
                        flag = true;
                        contactInfoTemp = {
                            ...body.contactInfo,
                            email: item.email,
                            first_name: item.first_name || '',
                            middle_name: item.middle_name || '',
                            last_name: item.last_name || '',
                            full_name: item.full_name || '',
                            phone_country_id: item.phone_code,
                            phone_number: item.phone,
                            phone_prefix: phone_prefix,
                        };
                    }

                    if (item.individual) {
                        newItem = {
                            country_id: item.country_id || 0,
                            type_member_id: 1,
                            company_member_type_id: 1,
                            first_name: hasShowSelectFullName // các quốc gia không có first name && middle name && last name => cho first name = full name
                                ? item.first_name || ''
                                : item.full_name || '',
                            middle_name: item.middle_name || '',
                            last_name: item.last_name || '',
                            full_name: item.full_name || '',
                            major: item.occupation,
                            phone_country_id: item.phone_code.id
                                ? item.phone_code.id
                                : item.phone_code,
                            phone_number: item.phone || null,
                            email: item.email || null,
                            id: item.id || null,
                        };
                    } else {
                        const objPhoneNumber = item.phone ? { phone_number: item.phone } : {};
                        newItem = {
                            country_id: parseInt(item.country_of_incorporation) || 0,
                            type_member_id: 2,
                            company_member_type_id: 1,
                            email: item.email || null,
                            id: item.id || null,
                            address: item.registered_address,
                            phone_country_id: item.phone_code.id
                                ? item.phone_code.id
                                : item.phone_code,
                            ...objPhoneNumber,
                            corporation_name: item.company_name || '',
                            corporation_number: item.company_number || '0',
                            corporation_date:
                                item.date_of_incorporation ||
                                moment(new Date()).format('MM/DD/YYYY'),
                            business_activity_array: item?.business_activity_array?.length
                                ? item.business_activity_array
                                : [],
                            business_activity_detail: item?.business_activity_detail ?? '',
                            OperationCountries: item?.country_operations?.length
                                ? item.country_operations
                                : [],
                        };
                    }

                    if (isSVG) {
                        newItem = {
                            ...newItem,
                            asset_source_fund_cast_in_bank: item.cash_in_bank_asset_1
                                ? parseInt(item.cash_in_bank_asset_1)
                                : 0,
                            asset_source_fund_real_estate: item.real_estate
                                ? parseInt(item.real_estate)
                                : 0,
                            asset_source_fund_other_detail: item.other_asset || '',
                            asset_source_fund_other_money: item.other_money
                                ? parseInt(item.other_money)
                                : 0,

                            income_source_fund_investment: item.investment_income
                                ? parseInt(item.investment_income)
                                : 0,
                            income_source_fund_self_employed: item.self_employed
                                ? parseInt(item.self_employed)
                                : 0,
                            income_source_fund_other_detail: item.other_detail_income
                                ? item.other_detail_income
                                : '',
                            income_source_fund_other_money: item.other_money_income
                                ? parseInt(item.other_money_income)
                                : 0,
                        };
                    }

                    newItem = {
                        ...newItem,
                        CompanyPositions: [
                            {
                                id: undefined,
                                company_member_type_id: 1,
                                is_appointed: Number(item.is_represented),
                            },
                        ],
                        CompanyMemberReferences: body.isPanama ? item.references : undefined,
                    };

                    if (item.president) {
                        const tempP = {
                            company_member_type_id: 5,
                            is_appointed: 1,
                        };
                        if (item.president_id) tempP.id = item.president_id;
                        newItem.CompanyPositions.push(tempP);
                    }
                    if (item.secretary) {
                        const tempS = {
                            company_member_type_id: 4,
                            is_appointed: 1,
                        };
                        if (item.secretary_id) tempS.id = item.secretary_id;
                        newItem.CompanyPositions.push(tempS);
                    }
                    if (item.treasurer) {
                        const tempT = {
                            company_member_type_id: 6,
                            is_appointed: 1,
                        };
                        if (item.treasurer_id) tempT.id = item.treasurer_id;
                        newItem.CompanyPositions.push(tempT);
                    }

                    if (newItem.id) {
                        const indexDirector = newItem.CompanyPositions.findIndex(
                            (position) => position.company_member_type_id === 1,
                        );
                        if (item.company_position_id) {
                            newItem.CompanyPositions[indexDirector].id = item.company_position_id;
                        }
                        if (newItem.type_member_id === 1) {
                            if (item.is_contact_person) {
                                newItem.CompanyPositions[indexDirector].is_contact_person =
                                    item.is_contact_person;
                            }
                        }
                        delete newItem.company_member_type_id;
                        finalData.companyStructureUpdate.push(newItem);

                        /* Xử lý xóa Composition khi data mới không khớp với data cũ */
                        let memberOld = body.Company.CompanyMembers.find(
                            (member) => member.id === item.id,
                        );
                        const newComposition = newItem.CompanyPositions.map(
                            (composition) => composition.id,
                        );

                        memberOld &&
                            memberOld.CompanyPositions.forEach((member) => {
                                // Đây là step director/member nên chỉ check xóa director và các cấp con (xử lý xóa nút delete hoặc select các người ký khác)
                                // Vì thế không xóa member 2, 3, 7 => gây lỗi ở step sau (shareholder, ubo) bị mất data
                                if (
                                    !newComposition.includes(member.id) &&
                                    ![1, 2, 3, 7].includes(member.company_member_type_id)
                                ) {
                                    finalData.companyStructureDelete.company_position_ids.push(
                                        member.id,
                                    );
                                }
                            });

                        if (newItem.type_member_id === 2) {
                            const currentMemberPositions = memberOld?.CompanyPositions || [];
                            const uboPositionIds = currentMemberPositions
                                ?.filter((position) => position?.company_member_type_id === 3)
                                ?.map((position) => position?.id);
                            const hasContactMember = !!currentMemberPositions.find(
                                (position) => position?.company_member_type_id === 7,
                            );

                            if (
                                hasContactMember &&
                                memberOld.type_member_id !== newItem.type_member_id
                            ) {
                                const newMemberTemp = cloneDeep(newItem);
                                delete newMemberTemp.id;
                                delete newMemberTemp.CompanyPositions[0].id;
                                delete newMemberTemp.CompanyPositions[0].is_contact_person;

                                finalData.companyStructureDelete.company_position_ids = uniq([
                                    ...(finalData?.companyStructureDelete?.company_position_ids ||
                                        []),
                                    currentMemberPositions?.find(
                                        (position) => position?.company_member_type_id === 1,
                                    )?.id,
                                ]).filter((id) => id);

                                finalData.companyStructureCreate.push(newMemberTemp);
                                finalData.companyStructureUpdate =
                                    finalData.companyStructureUpdate.filter(
                                        (member) => member.id !== newItem.id,
                                    );
                            }

                            // xóa tất cả position UBO nếu type member là Corporation
                            if (uboPositionIds.length) {
                                finalData.companyStructureDelete.company_position_ids = uniq([
                                    ...(finalData?.companyStructureDelete?.company_position_ids ||
                                        []),
                                    ...uboPositionIds,
                                ]).filter((id) => id);
                            }
                        }
                    } else {
                        delete newItem.id;
                        finalData.companyStructureCreate.push(newItem);
                    }
                });
                dispatch(actions.updateCompanyStructure(body.Task.id, finalData)).then((res) => {
                    try {
                        if (res && res.state) {
                            /*
                             * Vì res.data trả về không có trường "represented_type_ids" quy định loại member ký đại diện
                             * => body kế thừa data cũ, chỉ cập nhật CompanyMembers
                             *
                             */

                            let bodyData = {
                                ...body,
                                Company: {
                                    ...body.Company,
                                    CompanyMembers: res.data.Company.CompanyMembers,
                                },
                            };

                            let companyStructureNew = formatCompanyMembers(bodyData);

                            setBody({
                                ...bodyData,
                                companyStructure: {
                                    ...companyStructureNew,
                                    director_type_member_list: stateSwitch,
                                },
                                Task: res.data.Task,
                            });

                            setStateSwitch([...stateSwitch]);

                            if (flag) {
                                setBody((prevBody) => ({
                                    ...prevBody,
                                    contactInfo: contactInfoTemp,
                                }));
                            }

                            setIsLoading(false);
                            if (typeof activeStepRef.current !== 'number') {
                                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                            }
                            activeStepRef.current = null;
                        } else {
                            setIsLoading(false);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // init form values
    const initFieldObject = {
        isSVG: isSVG,
        isPanama: body?.isPanama,
        showOtherIncome: false,
        showOther: false,
        individual: true,
        full_name: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        occupation: '',
        phone_code: 0,
        phone: '',
        email: '',
        company_name: '',
        company_number: '',
        country_of_incorporation: 0,
        registered_address: '',
        date_of_incorporation: moment(new Date()).format('MM/DD/YYYY'),
        company_member_type_id: '',
        cash_in_bank_asset_1: null,
        real_estate: null,
        other_asset: '',
        other_money: null,
        investment_income: null,
        self_employed: null,
        other_detail_income: '',
        other_money_income: null,
        total_asset: null,
        total_income: null,
        type_member_id: 1,
        isCreate: true,
        company_id: body.Company.id,
        company_member_type_id: 1,
        country_id: 0,
        is_represented: false,
        is_contact_person: 0,
        business_activity_array: [],
        business_activity_detail: '',
        country_operations: [],

        ...EmptyMemberCommon,
    };

    const deleteHandler = (item, index, values, firstMember) => {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
            }).then((result) => {
                setIsLoading(true);
                if (result.isConfirmed) {
                    if (item.id && item?.company_position_id) {
                        const delData = {
                            companyStructureDelete: {
                                company_position_ids: [item.company_position_id],
                            },
                        };
                        if (item.president) {
                            delData.companyStructureDelete.company_position_ids.push(
                                item.president_id,
                            );
                        }
                        if (item.secretary) {
                            delData.companyStructureDelete.company_position_ids.push(
                                item.secretary_id,
                            );
                        }
                        if (item.treasurer) {
                            delData.companyStructureDelete.company_position_ids.push(
                                item.treasurer_id,
                            );
                        }

                        dispatch(actions.updateCompanyStructure(body.Task.id, delData)).then(
                            (res) => {
                                try {
                                    if (res && res.state) {
                                        Swal.fire({
                                            icon: 'success',
                                            title: 'Deleted!',
                                            text: res.message,
                                        });

                                        // update body data
                                        let bodyData = {
                                            ...body,
                                            Company: {
                                                ...body.Company,
                                                CompanyMembers: res.data.Company.CompanyMembers,
                                            },
                                        };
                                        let companyStructureNew = formatCompanyMembers(bodyData);
                                        setBody({
                                            ...bodyData,
                                            Task: res.data.Task,
                                            companyStructure: { ...companyStructureNew },
                                        });

                                        let newTabValue = index === 0 ? 0 : index - 1;
                                        remove(index);
                                        setTabValue(newTabValue);
                                        let currentState = [...stateSwitch];
                                        currentState.splice(index, 1);
                                        setStateSwitch(currentState);
                                        if (values.length === 1) {
                                            setStateSwitch([true]);
                                            form.reset({
                                                members: [
                                                    {
                                                        ...initFieldObject,
                                                        country_id: firstMember?.country_id,
                                                        phone_code: firstMember?.phone_code,
                                                    },
                                                ],
                                            });
                                            setTabValue(0);
                                        }

                                        setIsLoading(false);
                                    } else {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: 'Something went wrong!',
                                        });
                                        setIsLoading(false);
                                    }
                                } catch (error) {
                                    console.log(error);
                                }
                            },
                        );
                    } else {
                        let newTabValue = index === 0 ? 0 : index - 1;
                        remove(index);
                        setTabValue(newTabValue);
                        let currentState = [...stateSwitch];
                        currentState.splice(index, 1);
                        setStateSwitch(currentState);
                        if (values.length === 1) {
                            setStateSwitch([true]);
                            form.reset({
                                members: [
                                    {
                                        ...initFieldObject,
                                        country_id: firstMember?.country_id,
                                        phone_code: firstMember?.phone_code,
                                    },
                                ],
                            });
                            setTabValue(0);
                        }

                        setIsLoading(false);
                    }
                } else {
                    setIsLoading(false);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setInitForm({
            members: body?.companyStructure?.Directors,
        });

        setStateSwitch(body.companyStructure?.director_type_member_list ?? [true]);
    }, [body]);

    async function handleNextNominee({ reason }) {
        setIsLoading(true);
        try {
            const req = {
                reason_use_nominee_director: {
                    is_has_director: body.is_has_director,
                    reason_use_nominee_director: reason,
                },
            };

            const res = await dispatch(actions.updateCompanyStructure(body.Task.id, req));
            if (res && res.state) {
                const { data } = res;
                // setActiveStep((prevActiveStep) => prevActiveStep + 1);
                if (typeof activeStepRef.current !== 'number') {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                activeStepRef.current = null;
                setBody((prev) => {
                    const newBody = cloneDeep(prev);
                    newBody.Task.reason_use_nominee_director = get(
                        data,
                        'Task.reason_use_nominee_director',
                    );
                    return newBody;
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    // truyền form validate cho file stepper
    validateStepsRef.current = {
        ...validateStepsRef.current,
        [`STEP_${index + 1}`]: {
            isValid: form.formState.isValid,
            onSubmit:
                body && body.is_has_director
                    ? () => handleNextNominee()
                    : () => form.handleSubmit(handleNext)(),
        },
    };

    return (
        <div className="step2">
            {body && body.is_has_director === true ? (
                <Nominee
                    name="reason"
                    onSubmit={handleNextNominee}
                    body={body}
                    initialValue={get(body, 'Task.reason_use_nominee_director')}
                    companyMemberType={1}
                    handleBack={handleBack}
                    disabled={disabled}
                    hideAction={hideAction}
                    // handleNext={handleNextNoSubmit}
                />
            ) : (
                <>
                    <form onSubmit={form.handleSubmit(handleNext)}>
                        <div className="control-tab mb-5">
                            <div className="tab-menu">
                                <Tabs value={tabValue} onChange={handleChangeTab} aria-label="tabs">
                                    {fields?.map((item, index) => {
                                        return (
                                            <Tab
                                                key={item.id}
                                                className="item"
                                                label={index + 1}
                                                id={item.id}
                                                aria-controls={`tabpanel-${index}`}
                                            />
                                        );
                                    })}
                                </Tabs>
                            </div>
                            {!hideAction && (
                                <button
                                    type="button"
                                    className="btn btn-add"
                                    onClick={(event) => {
                                        append(initFieldObject);

                                        setTabValue(fields?.length);
                                        let currentState = stateSwitch;
                                        currentState.push(true);

                                        setStateSwitch(currentState);
                                    }}
                                >
                                    <AddIcon />
                                </button>
                            )}
                        </div>

                        <div>
                            {fields?.map((item, index) => {
                                return (
                                    // TabPanel
                                    <div key={item.id} className="tab-panel">
                                        <div
                                            role="tabpanel"
                                            hidden={tabValue !== index}
                                            id={`tabpanel-${index}`}
                                            aria-labelledby={`simple-tab-${index}`}
                                        >
                                            {!isHongkong && (
                                                <div className="ant-switch">
                                                    <span>Corporation</span>
                                                    <Switch
                                                        checked={form.watch(
                                                            `members.${index}.individual`,
                                                        )}
                                                        onChange={(e) =>
                                                            handleChangeSwitch(e, index)
                                                        }
                                                        name={`members.${index}.individual`}
                                                        disabled={disabled}
                                                    />
                                                    <span>Individual</span>
                                                </div>
                                            )}

                                            {isHongkong ? (
                                                <DirectorIndividual
                                                    isUsedContactInformation={
                                                        isUsedContactInformation
                                                    }
                                                    index={index}
                                                    memberValue={item}
                                                    values={form.watch('members')}
                                                    body={body}
                                                    form={form}
                                                    disabled={disabled}
                                                    hasShowSelectFullName={hasShowSelectFullName}
                                                />
                                            ) : form.watch(`members.${index}.individual`) !==
                                                  null &&
                                              form.watch(`members.${index}.individual`) ? (
                                                // "Individual"
                                                <DirectorIndividual
                                                    isUsedContactInformation={
                                                        isUsedContactInformation
                                                    }
                                                    index={index}
                                                    memberValue={item}
                                                    values={form.watch('members')}
                                                    body={body}
                                                    form={form}
                                                    disabled={disabled}
                                                    hasShowSelectFullName={hasShowSelectFullName}
                                                />
                                            ) : (
                                                <DirectorCorporation
                                                    index={index}
                                                    body={body}
                                                    form={form}
                                                    values={form.watch('members')}
                                                    orderInfo={orderInfo}
                                                    countryOperation={countryOperation}
                                                    businessActivityWithCountry={
                                                        businessActivityWithCountry
                                                    }
                                                    disabled={disabled}
                                                />
                                            )}
                                            {isSVG ? (
                                                <SourseForFunds
                                                    index={index}
                                                    body={body}
                                                    values={form.watch('members')}
                                                    form={form}
                                                    disabled={disabled}
                                                />
                                            ) : null}

                                            <References
                                                index={index}
                                                body={body}
                                                values={form.watch('members')}
                                                form={form}
                                                disabled={disabled}
                                            />

                                            {
                                                // body.companyStructure.directors.is_represented
                                                // Responsible Party Signing the ss4 Form: is_represented
                                                body && body.represented_director && (
                                                    <>
                                                        {!body?.isPanama && (
                                                            <Represented
                                                                index={index}
                                                                body={body}
                                                                values={form.watch('members')}
                                                                form={form}
                                                                representedLable={
                                                                    body.Represented.Director.label
                                                                }
                                                                disabled={disabled}
                                                            />
                                                        )}

                                                        {body?.isPanama && (
                                                            <>
                                                                <MemberRoleCheckbox
                                                                    index={index}
                                                                    body={body}
                                                                    values={form.watch('members')}
                                                                    representedLabel={
                                                                        'Consent to act as president'
                                                                    }
                                                                    fieldName={'president'}
                                                                    form={form}
                                                                    disabled={disabled}
                                                                />
                                                                <MemberRoleCheckbox
                                                                    index={index}
                                                                    body={body}
                                                                    values={form.watch('members')}
                                                                    representedLabel={
                                                                        'Consent to act as secretary'
                                                                    }
                                                                    fieldName={'secretary'}
                                                                    form={form}
                                                                    disabled={disabled}
                                                                />
                                                                <MemberRoleCheckbox
                                                                    index={index}
                                                                    body={body}
                                                                    values={form.watch('members')}
                                                                    representedLabel={
                                                                        'Consent to act as treasurer'
                                                                    }
                                                                    fieldName={'treasurer'}
                                                                    form={form}
                                                                    disabled={disabled}
                                                                />
                                                            </>
                                                        )}
                                                    </>
                                                )
                                            }
                                            {!hideAction && (
                                                <div className="wrap-btn text-md-right mt-7">
                                                    <Button
                                                        name={'btn_delete_' + index}
                                                        // variant="contained"
                                                        // color="secondary"
                                                        // disableElevation
                                                        className=" m-0 px-0 btn-delete btn-text"
                                                        // startIcon={<RemoveIcon />}
                                                        disabled={isLoading}
                                                        onClick={(event) => {
                                                            const firstMember =
                                                                form.getValues('members.0');
                                                            deleteHandler(
                                                                item,
                                                                index,
                                                                fields,
                                                                firstMember,
                                                            );
                                                        }}
                                                        style={{
                                                            color: '#EB5757',
                                                            background: '#fff',
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {!hideAction && (
                            <div className="mt-7">
                                <div>
                                    {activeStep > 0 && (
                                        <Button
                                            size="large"
                                            variant="contained"
                                            disableElevation
                                            onClick={handleBack}
                                            disabled={isLoading}
                                            className="mr-2"
                                        >
                                            Back
                                        </Button>
                                    )}
                                    {activeStep < 3 && (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            color="primary"
                                            disabled={isLoading}
                                            style={{ color: '#fff' }}
                                            onClick={() => (activeStepRef.current = null)}
                                        >
                                            Next
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}
                    </form>
                </>
            )}
        </div>
    );
}

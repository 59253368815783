import React, { useEffect, useState, useMemo, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormControlLabel, Switch, Tabs, Tab } from '@mui/material';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { Collapse } from 'reactstrap';
import { ContentDetailPackage } from '../detailPackage/ContentDetailPackage';
import { actionSetAccAndFiling } from 'app/pages/_redux/incorporation/incorporationActions';

// import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return <>{value === index && <>{children}</>}</>;
}

export default function Step2({ body, setBody, setActiveStep, activeStep, setSelectedRadio }) {
    const memoTab = useMemo(() => {
        let result = 0;

        if ([88, 89].includes(body?.packageIncorp?.id)) {
            result = 1;
        }

        return result;
    }, [body]);

    const [value, setValue] = React.useState(memoTab);
    const classes = useStyles();
    const dispatch = useDispatch();
    // const [packageSwitch, setPackageSwitch] = useState(null);

    const { incorporationState } = useSelector(
        (state) => ({ incorporationState: state.incorporation }),
        shallowEqual,
    );

    const [packages, setPackages] = useState([]);
    // const packages = incorporationState.entity.Packages
    //     ? incorporationState.entity.Packages.filter((e) => e.EntityType.id === body.entity_type_id)
    //     : [];

    // const packages = incorporationState.entity.Packages
    //     ? (incorporationState.entity.id == 244 ? incorporationState.entity.Packages : incorporationState.entity.Packages.filter((e) => e.EntityType.id === body.entity_type_id))
    //     : [];

    const [packageSwitch, setPackageSwitch] = useState(
        body.packageIncorp ? body.packageIncorp : null,
    );

    const [selectedValue, setSelectedValue] = useState(
        body.packageIncorp ? body.packageIncorp.id : null,
    );

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const onReset = () => {
        setSelectedValue(null);
        setBody({ ...body, packageIncorp: null, services: [] });
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    function handleChange(event) {
        setSelectedValue(event.target.value);
        dispatch(actionSetAccAndFiling(null));
        var packageIncorp = { ...packages.find((e) => e.id == event.target.value), quantity: 1 };
        setBody({ ...body, packageIncorp, services: [] });
        setSelectedRadio && setSelectedRadio(false);
    }

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const refPackagesContainer = useRef(null);
    const refPackageSwitch = useRef(null);
    const refStep2 = useRef(null);

    const current_entity_type_id = body.entity_type_id;

    useEffect(() => {
        if (incorporationState && incorporationState.entity.Packages && current_entity_type_id) {
            const packagesTemp = incorporationState.entity.Packages.filter(
                (e) => e.EntityType.id === current_entity_type_id,
            );
            setPackages(packagesTemp);

            if (incorporationState.entity.id == 244) {
                refStep2.current.classList.add('vn');

                let hasPackageSwitch =
                    packagesTemp.find((e) => e.PackageType.id == 4) ||
                    packagesTemp.find((e) => e.PackageType.id == 5);
                if (hasPackageSwitch) {
                    setPackageSwitch(
                        body?.packageIncorp || packagesTemp.find((e) => e.PackageType.id == 5),
                    );
                } else {
                    setPackageSwitch(null);
                }
            }
        }
    }, [incorporationState, current_entity_type_id]);

    // useEffect(() => {
    //     if (incorporationState && incorporationState.entity.id == 244) {
    //         refStep2.current.classList.add("vn");
    //         // setTimeout(() => {
    //         //     // document.getElementsByClassName("packages")[0].classList.add("vn");
    //         //     document.getElementById("packages").classList.add("vn");
    //         // }, 0)

    //         // let checkPackageShowSwitch = packages.find((e) => e.PackageType.id == 4) || packages.find((e) => e.PackageType.id == 5);
    //         // if(checkPackageShowSwitch) {
    //         //     // !packageSwitch && setPackageSwitch(packages.find((e) => e.PackageType.id == 5));
    //         //     setPackageSwitch(packages.find((e) => e.PackageType.id == 5));
    //         //     console.log(packages.filter((e) => e.PackageType.id == 5));
    //         // } else {
    //         //     setPackageSwitch(null);
    //         // }

    //         // !packageSwitch && setPackageSwitch(packages.find((e) => e.PackageType.id == 5));
    //     } else {
    //         // console.log('check VN -> Not VN');
    //     }
    // }, [packages])

    function handleChangeSwitch(event) {
        try {
            onReset();

            let packageSwitchTemp = null;
            if (event.target.checked) {
                packageSwitchTemp = packages.find((e) => e.PackageType.id == 4);
            } else {
                packageSwitchTemp = packages.find((e) => e.PackageType.id == 5);
            }
            setPackageSwitch(packageSwitchTemp);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="step2" ref={refStep2}>
            <div className="container">
                <section className="packages" ref={refPackagesContainer}>
                    <div className="packages__container">
                        {packages?.some((item) => [86, 87, 88, 89].includes(item?.id)) && (
                            <div style={{ display: 'grid' }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChangeTab}
                                    aria-label="basic tabs example"
                                    sx={{ marginBottom: 2, justifySelf: 'center' }}
                                >
                                    <Tab label="Locals & Permanent Residents" />
                                    <Tab label="Foreigners" />
                                </Tabs>
                                <TabPanel value={value} index={0}>
                                    <div className="row">
                                        {packages
                                            ?.filter((item) => item?.id === 86 || item?.id === 87)
                                            ?.map((e, index) => (
                                                <div
                                                    key={index}
                                                    data-entity_type_id={e.EntityType.id}
                                                >
                                                    <div className="item">
                                                        <input
                                                            type="radio"
                                                            className="item__radio-button"
                                                            name="radio"
                                                            value={e.id}
                                                            checked={selectedValue == e.id}
                                                            onChange={handleChange}
                                                        />
                                                        <div className="item__content">
                                                            <div
                                                                className={`inner h-100 pt-4 pb-2 d-flex flex-column justify-content-${
                                                                    incorporationState.entity.id ===
                                                                    191
                                                                        ? 'between'
                                                                        : 'around'
                                                                }`}
                                                            >
                                                                <div
                                                                    className="content__package-name"
                                                                    style={{
                                                                        fontSize:
                                                                            incorporationState
                                                                                .entity.id === 191
                                                                                ? '1.5rem'
                                                                                : '2rem',
                                                                    }}
                                                                >
                                                                    {e.PackageType.name}
                                                                </div>
                                                                <div className="content__package-price">
                                                                    <div>
                                                                        {/* <div className="unit">US$</div> */}
                                                                        <span className="price">
                                                                            US$
                                                                            {e.Services &&
                                                                                e.Services.reduce(
                                                                                    (
                                                                                        accumulator,
                                                                                        service,
                                                                                    ) =>
                                                                                        accumulator +
                                                                                        service.Fee
                                                                                            .value,
                                                                                    0,
                                                                                )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <div className="row">
                                        {packages
                                            ?.filter((item) => item?.id === 88 || item?.id === 89)
                                            ?.map((e, index) => (
                                                <div
                                                    key={index}
                                                    data-entity_type_id={e.EntityType.id}
                                                >
                                                    <div className="item">
                                                        <input
                                                            type="radio"
                                                            className="item__radio-button"
                                                            name="radio"
                                                            value={e.id}
                                                            checked={selectedValue == e.id}
                                                            onChange={handleChange}
                                                        />
                                                        <div className="item__content">
                                                            <div
                                                                className={`inner h-100 pt-4 pb-2 d-flex flex-column justify-content-${
                                                                    incorporationState.entity.id ===
                                                                    191
                                                                        ? 'between'
                                                                        : 'around'
                                                                }`}
                                                            >
                                                                <div
                                                                    className="content__package-name"
                                                                    style={{
                                                                        fontSize:
                                                                            incorporationState
                                                                                .entity.id === 191
                                                                                ? '1.5rem'
                                                                                : '2rem',
                                                                    }}
                                                                >
                                                                    {e.PackageType.name}
                                                                </div>
                                                                <div className="content__package-price">
                                                                    <div>
                                                                        {/* <div className="unit">US$</div> */}
                                                                        <span className="price">
                                                                            US$
                                                                            {e.Services &&
                                                                                e.Services.reduce(
                                                                                    (
                                                                                        accumulator,
                                                                                        service,
                                                                                    ) =>
                                                                                        accumulator +
                                                                                        service.Fee
                                                                                            .value,
                                                                                    0,
                                                                                )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </TabPanel>
                            </div>
                        )}
                        <div className="row">
                            {incorporationState &&
                                incorporationState.entity.id == 244 &&
                                packageSwitch && (
                                    // { packages && packages.map((e, index) => (
                                    // (e.PackageType.id == 5) &&
                                    <div data-entity_type_id={packageSwitch.EntityType.id}>
                                        <div
                                            // className={e.PackageType.id == 5 ? "item package-switch" : "item"}
                                            className="item package-switch"
                                            ref={refPackageSwitch}
                                            // ref={e.PackageType.id == 5 ? refPackageSwitch : null}
                                        >
                                            <input
                                                type="radio"
                                                className="item__radio-button"
                                                name="radio"
                                                value={packageSwitch.id}
                                                checked={selectedValue == packageSwitch.id}
                                                onChange={handleChange}
                                            />
                                            <div className="item__content">
                                                <div className="inner">
                                                    <div className="content__package-name">
                                                        {packageSwitch &&
                                                            packageSwitch.PackageType.name}
                                                    </div>
                                                    <div className="content__package-price">
                                                        <div>
                                                            <span className="price">
                                                                US$
                                                                {packageSwitch &&
                                                                    packageSwitch.Services &&
                                                                    packageSwitch.Services.reduce(
                                                                        (accumulator, service) =>
                                                                            accumulator +
                                                                            service.Fee.value,
                                                                        0,
                                                                    )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {e.PackageType.id == 5 && */}
                                        <div className="wrap-switch-change-package">
                                            <div className="switch-change-package">
                                                <div className="package-tooltip">
                                                    <InfoOutlinedIcon className="icon" />
                                                    <span className="tooltiptext">
                                                        The price will change depending on whether
                                                        company member is an individual or a
                                                        corporation.
                                                    </span>
                                                </div>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={
                                                                packageSwitch &&
                                                                packageSwitch.PackageType.id === 4
                                                            }
                                                            onChange={(event) =>
                                                                handleChangeSwitch(event)
                                                            }
                                                            color="primary"
                                                        />
                                                    }
                                                    labelPlacement="start"
                                                    label="Individual"
                                                />
                                            </div>
                                        </div>
                                        {/* } */}
                                    </div>
                                )}

                            {packages.map((e, index) => {
                                if ([86, 87, 88, 89].includes(e?.id)) {
                                    return <></>;
                                }
                                if (e.PackageType.id !== 4 && e.PackageType.id !== 5) {
                                    return (
                                        <div key={index} data-entity_type_id={e.EntityType.id}>
                                            <div className="item">
                                                <input
                                                    type="radio"
                                                    className="item__radio-button"
                                                    name="radio"
                                                    value={e.id}
                                                    checked={selectedValue == e.id}
                                                    onChange={handleChange}
                                                />
                                                <div className="item__content">
                                                    <div
                                                        className={`inner h-100 pt-4 pb-2 d-flex flex-column justify-content-${
                                                            incorporationState.entity.id === 191
                                                                ? 'between'
                                                                : 'around'
                                                        }`}
                                                    >
                                                        <div
                                                            className="content__package-name"
                                                            style={{
                                                                fontSize:
                                                                    incorporationState.entity.id ===
                                                                    191
                                                                        ? '1.5rem'
                                                                        : '2rem',
                                                            }}
                                                        >
                                                            {e.PackageType.name}
                                                        </div>
                                                        <div className="content__package-price">
                                                            <div>
                                                                {/* <div className="unit">US$</div> */}
                                                                <span className="price">
                                                                    US$
                                                                    {e.Services &&
                                                                        e.Services.reduce(
                                                                            (
                                                                                accumulator,
                                                                                service,
                                                                            ) =>
                                                                                accumulator +
                                                                                service.Fee.value,
                                                                            0,
                                                                        )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </section>
            </div>
            <div className="compare-package text-center">
                <Button className="btn-compare" onClick={toggle}>
                    Compare Package
                </Button>
                {/* <Collapse isOpen={isOpen}>
                    <div className="package-detail">
                        <div className="row">
                            <div className="offset-6 col-6">
                                <div className="title">
                                    <h4>basic</h4>
                                    <h4>standard</h4>
                                    <h4>premium</h4>
                                </div>
                            </div>
                        </div>
                        <h6 style={{ marginBottom: "12px" }}>COMPANY FORMATION</h6>
                        <ContentDetailPackage value={incorporationState.entity.id} />
                        <h6 style={{ margin: "15px 0 12px 0" }}>BANK ACCOUNT APPLICATION</h6>
                        <div className="row">
                            <div className="col-6">
                                <p>Assistance in opening Bank account including Cert. of Incumbency if required</p>
                            </div>
                            <div className="col-6 d-flex">
                                <p>None</p>
                                <p>1 bank application</p>
                                <p>Up to 5 bank applications</p>
                            </div>
                        </div>
                    </div>
                </Collapse> */}
                <Collapse isOpen={isOpen}>
                    <ContentDetailPackage
                        value={incorporationState.entity.id}
                        entity_type_id={body.entity_type_id}
                    />
                </Collapse>
            </div>
            <div className={classes.actionsContainer}>
                <div>
                    {activeStep > 0 && (
                        <Button size="large" onClick={handleBack} className={classes.button}>
                            Back
                        </Button>
                    )}
                    {activeStep < 3 && (
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            disabled={body.packageIncorp ? false : true}
                            onClick={handleNext}
                            className={classes.button}
                            style={{ color: '#fff' }}
                        >
                            Next
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}

import axios from 'axios';
export {getProcessDetail, approveProcess, deleteFile} from '../banking/api';


export function getAdditionalProcessTree(orderId, params) {
    return axios.get(
        process.env.REACT_APP_API_URL + `/customer_account/order/${orderId}/addition_tree`,{ params }
    )
}

export function createRecordFile(payload) {
    return axios.post(
        process.env.REACT_APP_API_URL + `/file/customer/create`,
        payload
    )
}

export function createRecordFileBookkeeper(payload) {
    return axios.post(
        process.env.REACT_APP_API_URL + `/customer_account/bookkeeper_process/file/create`,
        payload
    )
}

export function createARServiceOrder(payload) {
    return axios.post(
        process.env.REACT_APP_API_URL + `/customer_account/order/service_ar`,
        payload
    )
}

export function getYearsList(orderId) {
    return axios.get(
        process.env.REACT_APP_API_URL + `/customer_account/order/${orderId}/years_not_buy_event`
    )
}

export function saveYearsEvent(orderId, payload) {
    return axios.post(
        process.env.REACT_APP_API_URL + `/customer_account/order/${orderId}/company_event`, payload
    )
}

export function updateCompanyEventType(payload) {
    return axios.put(process.env.REACT_APP_API_URL + `/customer_account/company_event/types`, payload);
}

//Bookkeeper

export function updateOrderFormBookkeeper(id, body) {
    return axios.put(process.env.REACT_APP_API_URL + '/customer_account/bookkeeper_process/order_form/' + id, body);
}
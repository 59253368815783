import axios from 'axios';

export const COMPANY_URL = '/customer_account/incorporation';

// CREATE =>  POST: add a new product to the server
export function createProduct(product) {
    return axios.post(COMPANY_URL, { product });
}

// READ
export function getCompanyInfo(id, queryParams) {
    return axios.get(COMPANY_URL + '/country/' + id, queryParams);
}

export function getSubscription() {
    return axios.get(COMPANY_URL + '/subscription/');
}
export function getMembership() {
    return axios.get(COMPANY_URL + '/membership/');
}

export function getAllAncillaryServiceOutside(queryParams) {
    return axios.get(COMPANY_URL + '/ancillaryServices_outside', queryParams);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findProducts(queryParams) {
    return axios.post(`${COMPANY_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateProduct(product) {
    return axios.put(`${COMPANY_URL}/${product.id}`, { product });
}

// UPDATE Status
export function updateStatusForProducts(ids, status) {
    return axios.post(`${COMPANY_URL}/updateStatusForProducts`, {
        ids,
        status,
    });
}

// DELETE => delete the product from the server
export function deleteProduct(productId) {
    return axios.delete(`${COMPANY_URL}/${productId}`);
}

// DELETE Products by ids
export function deleteProducts(ids) {
    return axios.post(`${COMPANY_URL}/deleteProducts`, { ids });
}

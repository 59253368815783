import React, { Fragment } from 'react';
import InputField from 'components/form-controls/InputField';
import { InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    customInput: {
        '& .MuiInput-underline:before': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:hover:before': {
            borderBottom: 'none !important',
        },
    },
});

const SourseForFunds = ({ index, body, onChange, values, form, disabled = false }) => {
    const classes = useStyles();

    return (
        <Fragment>
            <div className="source_of_funds container-fluid p-0 m-0">
                <h5 className="col-md-12 mb-7 pl-0 input-line">Source of fund</h5>
                <div className="row justify-content-between">
                    <div className="col-md-6">
                        <div
                            className="p-4 col-12 mb-7"
                            style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
                        >
                            <p className="text-center">ASSET</p>
                            <div className="col-12 mb-7 input-line">
                                <label>Cash in bank</label>
                                <InputField
                                    type="number"
                                    name={`members.${index}.cash_in_bank_asset_1`}
                                    form={form}
                                    placeholder="0"
                                    hideHelperText
                                    onChange={(e) => {
                                        form.setValue(
                                            `members.${index}.cash_in_bank_asset_1`,
                                            e.target.value,
                                        );
                                        form.setValue(
                                            `members.${index}.total_asset`,
                                            Number(e.target.value) +
                                                Number(values[index][`real_estate`]) +
                                                Number(values[index][`other_money`]),
                                        );
                                        form.trigger();
                                    }}
                                    disabled={disabled}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {body.currency_unit}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-7 input-line">
                                <label>Real Estate</label>
                                <InputField
                                    type="number"
                                    name={`members.${index}.real_estate`}
                                    form={form}
                                    placeholder="0"
                                    hideHelperText
                                    disabled={disabled}
                                    onChange={(e) => {
                                        form.setValue(
                                            `members.${index}.real_estate`,
                                            e.target.value,
                                        );
                                        form.setValue(
                                            `members.${index}.total_asset`,
                                            Number(e.target.value) +
                                                Number(values[index][`cash_in_bank_asset_1`]) +
                                                Number(values[index][`other_money`]),
                                        );
                                        form.trigger();
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {body.currency_unit}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            <p className="asset__others--title pl-4">Others</p>
                            <div className="col-12 mb-7 input-line">
                                <InputField
                                    type="text"
                                    name={`members.${index}.other_asset`}
                                    form={form}
                                    placeholder="Please provide specific details"
                                    hideSuccessHelper
                                    onChange={(e) => {
                                        form.setValue(
                                            `members.${index}.other_asset`,
                                            e.target.value,
                                        );
                                        form.trigger();
                                    }}
                                    disabled={disabled}
                                />
                            </div>

                            <div className="col-12 mb-7 input-line">
                                <InputField
                                    type="number"
                                    name={`members.${index}.other_money`}
                                    form={form}
                                    placeholder="0"
                                    hideSuccessHelper
                                    disabled={disabled}
                                    onChange={(e) => {
                                        form.setValue(
                                            `members.${index}.other_money`,
                                            e.target.value,
                                        );
                                        form.setValue(
                                            `members.${index}.total_asset`,
                                            Number(e.target.value) +
                                                Number(values[index][`cash_in_bank_asset_1`]) +
                                                Number(values[index][`real_estate`]),
                                        );
                                        form.setValue(`members.${index}.showOther`, true);
                                        if (!e.target.value) {
                                            form.setValue(`members.${index}.showOther`, false);
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {body.currency_unit}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            <div className="asset__total col-12 mb-7 no-underline">
                                <span className="mr-5 mt-4" style={{ minWidth: 'fit-content' }}>
                                    TOTAL:
                                </span>
                                <InputField
                                    type="number"
                                    name={`members.${index}.total_asset`}
                                    form={form}
                                    label=" "
                                    placeholder="0"
                                    hideSuccessHelper
                                    hideHelperText
                                    readOnly
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {body.currency_unit}
                                            </InputAdornment>
                                        ),
                                    }}
                                    className={classes.customInput}
                                    inputProps={{ style: { width: '30%' } }}
                                />
                            </div>
                            {form.formState.errors?.members?.[index]?.total_asset && (
                                <div className="text-danger">
                                    Please fill up one of the fields above!
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div
                            className="p-4 col-12 mb-7"
                            style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
                        >
                            <p className="text-center">INCOME</p>
                            <div className="col-12 mb-7 input-line">
                                <label>Investment Income</label>
                                <InputField
                                    type="number"
                                    name={`members.${index}.investment_income`}
                                    form={form}
                                    placeholder="0"
                                    hideHelperText
                                    disabled={disabled}
                                    onChange={(e) => {
                                        form.setValue(
                                            `members.${index}.investment_income`,
                                            e.target.value,
                                        );
                                        form.setValue(
                                            `members.${index}.total_income`,
                                            Number(e.target.value) +
                                                Number(values[index][`self_employed`]) +
                                                Number(values[index][`other_money_income`]),
                                        );
                                        form.trigger();
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {body.currency_unit}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-7 input-line">
                                <label>Self-Employed</label>
                                <InputField
                                    type="number"
                                    name={`members.${index}.self_employed`}
                                    form={form}
                                    placeholder="0"
                                    hideHelperText
                                    disabled={disabled}
                                    onChange={(e) => {
                                        form.setValue(
                                            `members.${index}.self_employed`,
                                            e.target.value,
                                        );
                                        form.setValue(
                                            `members.${index}.total_income`,
                                            Number(e.target.value) +
                                                Number(values[index][`investment_income`]) +
                                                Number(values[index][`other_money_income`]),
                                        );
                                        form.trigger();
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {body.currency_unit}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            <p className="asset__others--title pl-4">Others</p>
                            <div className="col-12 mb-7 input-line">
                                <InputField
                                    type="text"
                                    name={`members.${index}.other_detail_income`}
                                    form={form}
                                    label=""
                                    placeholder="Please provide specific details"
                                    hideSuccessHelper
                                    onChange={(e) => {
                                        form.setValue(
                                            `members.${index}.other_detail_income`,
                                            e.target.value,
                                        );
                                        form.trigger();
                                    }}
                                    disabled={disabled}
                                />
                            </div>

                            <div className="col-12 mb-7 input-line">
                                <InputField
                                    type="number"
                                    name={`members.${index}.other_money_income`}
                                    form={form}
                                    placeholder="0"
                                    hideSuccessHelper
                                    disabled={disabled}
                                    onChange={(e) => {
                                        form.setValue(
                                            `members.${index}.other_money_income`,
                                            e.target.value,
                                        );
                                        form.setValue(
                                            `members.${index}.total_income`,
                                            Number(e.target.value) +
                                                Number(values[index][`self_employed`]) +
                                                Number(values[index][`investment_income`]),
                                        );
                                        form.setValue(`members.${index}.showOtherIncome`, true);

                                        if (!e.target.value) {
                                            form.setValue(
                                                `members.${index}.showOtherIncome`,
                                                false,
                                            );
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {body.currency_unit}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            <div className="asset__total col-12 mb-7 no-underline">
                                <span className="mr-5 mt-4" style={{ minWidth: 'fit-content' }}>
                                    TOTAL:
                                </span>
                                <InputField
                                    type="number"
                                    name={`members.${index}.total_income`}
                                    form={form}
                                    label=" "
                                    placeholder="0"
                                    readOnly
                                    hideSuccessHelper
                                    hideHelperText
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {body.currency_unit}
                                            </InputAdornment>
                                        ),
                                    }}
                                    className={classes.customInput}
                                    inputProps={{ style: { width: '30%' } }}
                                />
                            </div>
                            {form.formState.errors?.members?.[index]?.total_income && (
                                <div className="text-danger">
                                    Please fill up one of the fields above!
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SourseForFunds;

import axios from 'axios';

export const ORDER_FORM_URL = '/customer_account/order';
export const FILES_HANDLER_URL = '/file';
export const UPDATE_ORDER_FORM_URL = '/customer_account/order_form';
export const APPROVED_STEP = '/customer_account/process/approve';

export function getAllOrders() {
    return axios.get(ORDER_FORM_URL);
}

export function findOrders(queryParams) {
    // filter orders
    return axios.get(`${ORDER_FORM_URL}`, { params: queryParams });
}

export function getOrderForm(id) {
    return axios.get(ORDER_FORM_URL + '/' + id);
}

export function updateOrderForm(id, body) {
    return axios.put(UPDATE_ORDER_FORM_URL + '/' + id, body);
}

export function updateCompanyStructure(id, body) {
    return axios.put(UPDATE_ORDER_FORM_URL + '/' + id, body);
}

export function getStepApproved(body) {
    return axios.post(APPROVED_STEP, body);
}

export function uploadFile(body) {
    return axios.post(FILES_HANDLER_URL + '/create', body);
}

export function deleteFile(id) {
    return axios.delete(FILES_HANDLER_URL + '/' + id);
}

export function updateKycNode(orderId, body) {
    return axios.put(
        process.env.REACT_APP_API_URL + '/customer_account/order_form/' + orderId,
        body,
    );
}

export function submitOrderFormStep(body) {
    return axios.post(process.env.REACT_APP_API_URL + '/customer_account/process/approve', body);
}

export function rejectOrderFormStep(id) {
    return axios.post(process.env.REACT_APP_API_URL + '/customer_account/process/reject', {
        id: id,
    });
}

export function createChangeRequest(payload) {
    return axios.post(
        process.env.REACT_APP_API_URL + '/customer_account/order_form/change_request',
        payload,
    );
}

export function deleteChangeRequest(payload) {
    return axios.delete(
        process.env.REACT_APP_API_URL + '/customer_account/order_form/change_request',
        {
            data: payload,
        },
    );
}

export function submitRepresented(payload) {
    return axios.put(
        process.env.REACT_APP_API_URL + '/customer_account/order_form/company_member/appoint_sign',
        payload,
    );
}

export function transferRequest(payload) {
    return axios.post(
        process.env.REACT_APP_API_URL +
            '/customer_account/order_form/change_request/transfer_share',
        payload,
    );
}

export function updateChangeRequest(changeRequestId, payload) {
    return axios.put(
        process.env.REACT_APP_API_URL +
            `/customer_account/order_form/change_request/${changeRequestId}`,
        payload,
    );
}

export function submitApprovedName(ProposalNameId, payload) {
    return axios.put(
        process.env.REACT_APP_API_URL +
            `/customer_account/order_form/customer/proposed_name/${ProposalNameId}`,
        payload,
    );
}

export function submitProposedName(payload) {
    return axios.post(
        process.env.REACT_APP_API_URL + '/customer_account/order_form/propose_name/bulk',
        payload,
    );
}

export function getProposedName(taskId) {
    return axios.get(
        process.env.REACT_APP_API_URL + `/customer_account/order_form/propose_name/${taskId}`,
    );
}

export function deleteProposedName(payload, proposed_id) {
    return axios.delete(
        process.env.REACT_APP_API_URL + `/customer_account/order_form/propose_name/${proposed_id}`,
        {
            data: payload,
        },
    );
}

export function getTemplateDetailHTML(queryParams) {
    return axios.get(process.env.REACT_APP_API_URL + `/customer_account/task/file_template`, {
        params: queryParams,
    });
}

export function downloadTemplateDetailHTML(queryParams) {
    return axios.get(
        process.env.REACT_APP_API_URL + `/customer_account/task/file_template/download`,
        { params: queryParams, responseType: 'blob' },
    );
}

export function postSendMails(body) {
    return axios.post(
        process.env.REACT_APP_API_URL + `/customer_account/task/send_document_member`,
        body,
    );
}

// Banking APIs

export function getBankingDataByOrderId(id) {
    return axios.get(process.env.REACT_APP_API_URL + `/customer_account/order/${id}/banking`);
}
export function updateBankingStep1_1_4(id, params) {
    return axios.put(
        process.env.REACT_APP_API_URL + `/banking_process/business_plan_form/update_form/${id}`,
        params,
    );
}

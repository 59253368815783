import React, { createContext, useContext, useEffect, useState } from 'react';
import { createGraphElements } from 'app/pages/services/components/kyc-elements/GraphHandler';
import { useDispatch } from 'react-redux';
import { getLayoutElements } from 'app/pages/services/components/kyc-elements/GraphLayout';
import * as ActionsNode from 'app/pages/_redux/order-form/orderFormActions';
import Swal from 'sweetalert2';
import {
    fetchCountries,
    fetchCountriesHasService,
    fetchCountriesNonRestricted,
} from '../../../../_redux/enum/enumActions';
const KYCUIContext = createContext();

export function useKYCUIContext() {
    return useContext(KYCUIContext);
}

export const KYCUIContextConsumer = KYCUIContext.Consumer;

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger mr-5',
    },
    buttonsStyling: false,
});

export function KYCUIProvider({ children }) {
    const dispatch = useDispatch();
    const [elements, setElements] = useState([]);
    const [graphLoading, setGraphLoading] = useState(false);
    const [modal, setModal] = useState({ show: false, task: {}, data: {} });
    const closeModal = () => {
        setModal({ ...modal, task: {}, show: false, data: {} });
    };
    const openDetailModal = (data) => {
        setModal({ ...modal, show: true, data: data });
    };

    useEffect(() => {
        dispatch(fetchCountriesHasService());
        dispatch(fetchCountries());
        dispatch(fetchCountriesNonRestricted());
    }, []);

    const setGraphElement = (orderInfo, type) => {
        const companyInfo = { ...(orderInfo?.Company || {}) };
        const OrderItems = orderInfo?.OrderItems;
        const DisabledFileTemplate = orderInfo?.DisabledFileTemplate;
        const externalProps = {
            openDetailModal,
            deleteGraphNode: (template) => deleteGraphNode(template, orderInfo),
            otherInfo: {
                is_has_director: orderInfo?.is_has_director ?? orderInfo?.Company?.is_has_director,
                is_has_shareholder: orderInfo?.is_has_shareholder ?? orderInfo?.Company?.is_has_shareholder,
                OrderItems,
                DisabledFileTemplate,
                company_country_id: companyInfo?.country_id,
                task_id: orderInfo?.Task?.id || null
            },
            type,
        };
        const graphEls = createGraphElements(companyInfo, externalProps);
        const layoutGraphEls = getLayoutElements(graphEls, 'TB');
        setElements(layoutGraphEls);
    };

    const addGraphStructure = (template, orderInfo) =>
        new Promise((resolve, reject) => {
            setGraphLoading(true);
            dispatch(ActionsNode.addGraphNode(orderInfo?.Task?.id || null, template))
                .then((data) => {
                    const orderFormEntities = { ...orderInfo };
                    orderFormEntities.Company = {
                        ...orderFormEntities.Company,
                        CompanyMembers: [...data.CompanyMembers],
                    };
                    resolve({
                        preMembers: [...orderInfo.Company.CompanyMembers].filter(
                            (el) => el.is_deleted === 0,
                        ),
                        newMembers: [...data.CompanyMembers],
                    });
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                })
                .finally(() => {
                    setGraphLoading(false);
                });
        });

    const updateGraphStructure = (template, orderInfo, isCloseModal) =>
        new Promise((resolve, reject) => {
            setGraphLoading(true);
            dispatch(ActionsNode.updateGraphNode(orderInfo?.Task?.id || null, template))
                .then((data) => {
                    const orderFormEntities = { ...orderInfo };
                    orderFormEntities.Company = {
                        ...orderFormEntities.Company,
                        CompanyMembers: [...data.CompanyMembers],
                    };
                    setGraphElement(null);
                    setGraphElement(orderFormEntities);
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Successful!',
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(() => {
                        if (isCloseModal) {
                            closeModal();
                        }
                    });
                    resolve({
                        preMembers: [...orderInfo.Company.CompanyMembers].filter(
                            (el) => el.is_deleted === 0,
                        ),
                        newMembers: [...data.CompanyMembers],
                    });
                })
                .catch((error) => {
                    console.error(error);
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Cannot update KYC!',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    reject(error);
                })
                .finally(() => {
                    setGraphLoading(false);
                });
        });

    const deleteGraphNode = (template, orderInfo) => {
        swalWithBootstrapButtons
            .fire({
                title: 'Do you want to delete it?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    setGraphLoading(true);
                    dispatch(ActionsNode.updateGraphNode(orderInfo?.Task?.id || null, template))
                        .then((data) => {
                            const orderFormEntities = JSON.parse(JSON.stringify(orderInfo));
                            orderFormEntities.Company = {
                                ...orderFormEntities.Company,
                                CompanyMembers: JSON.parse(
                                    JSON.stringify(
                                        data.CompanyMembers.filter((el) => el.is_deleted === 0),
                                    ),
                                ),
                            };
                            setGraphElement(null);
                            setGraphElement(orderFormEntities);
                            dispatch(ActionsNode.updateCompanyMemberList(orderFormEntities));
                            swalWithBootstrapButtons.fire(
                                'Deleted!',
                                'The KYC structure has been updated.',
                                'success',
                            );
                        })
                        .catch(() => {
                            swalWithBootstrapButtons.fire('Failed!', 'Cannot delete', 'error');
                        })
                        .finally(() => {
                            setGraphLoading(false);
                        });
                }
            });
    };

    const submitStep = (process) =>
        new Promise((resolve, reject) => {
            dispatch(ActionsNode.submitOrderForm(process.id))
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Cannot submit KYC!',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    reject(error);
                });
        });

    const value = {
        elements: elements,
        setElements: setElements,
        modal: modal,
        closeModal: closeModal,
        openDetailModal: openDetailModal,
        setGraphElement: setGraphElement,
        setGraphLoading: setGraphLoading,
        graphLoading: graphLoading,
        updateGraphStructure: updateGraphStructure,
        deleteGraphNode: deleteGraphNode,
        addGraphStructure: addGraphStructure,
        submitStep: submitStep,
    };
    return <KYCUIContext.Provider value={value}>{children}</KYCUIContext.Provider>;
}

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import { CircularProgress } from '@mui/material';
import ExistingMember from 'app/pages/services/order-management/order-form/instruction-incorporation/component/step3/step3-helper/existing-member/existing-member';
import { COMPANY_MEMBER_TYPES, MEMBER_TYPES } from 'app/pages/services/constants';
import { getExistedMembers } from 'app/pages/services/components/kyc-elements/ExistingMemberList';

export default function ChooseExistingMember({
    index,
    body,
    setBody,
    bindArrayHelper,
    companyMemberType,
    stateSwitch,
    setStateSwitch,
    company,
    member,
}) {
    const [open, setOpen] = useState(false);
    const [showExistingMember, setShowExistingMember] = useState(true);
    const [existingMemberList, setExistingMemberList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleOpen = () => {
        try {
            if (body && body.companyStructure) {
                let existingMemberListTemp = body.companyStructure.ContactPerson;
                if (companyMemberType === 1) {
                    // Director
                    if (body.companyStructure.UBO && body.companyStructure.UBO.length > 0) {
                        existingMemberListTemp = existingMemberListTemp.concat(
                            body.companyStructure.UBO,
                        );
                    }
                    if (
                        body.companyStructure.Shareholders &&
                        body.companyStructure.Shareholders.length > 0
                    ) {
                        existingMemberListTemp = existingMemberListTemp.concat(
                            body.companyStructure.Shareholders,
                        );
                    }
                }

                if (companyMemberType === 2) {
                    // Shareholder
                    existingMemberListTemp = existingMemberListTemp.concat(
                        body.companyStructure.Directors,
                    );
                    if (body.companyStructure.UBO && body.companyStructure.UBO.length > 0) {
                        existingMemberListTemp = existingMemberListTemp.concat(
                            body.companyStructure.UBO,
                        );
                    }
                }
                if (companyMemberType === 3) {
                    // UBO
                    existingMemberListTemp = existingMemberListTemp.concat(
                        body.companyStructure.Directors,
                    );
                    if (
                        body.companyStructure.Shareholders &&
                        body.companyStructure.Shareholders.length > 0
                    ) {
                        existingMemberListTemp = existingMemberListTemp.concat(
                            body.companyStructure.Shareholders,
                        );
                    }
                    existingMemberListTemp = existingMemberListTemp.filter(
                        (e) => e.individual === true,
                    );
                }

                // remove init member (empty member)
                // remove member was resigned (Company Legal Detail)
                existingMemberListTemp = existingMemberListTemp.filter(
                    (e) => e.id && e.is_resigned === false,
                );

                // remove member same possition
                if (bindArrayHelper.form.values.members.length > 0) {
                    bindArrayHelper.form.values.members.map((item) => {
                        item.id &&
                            (existingMemberListTemp = existingMemberListTemp.filter(
                                (e) => e.id !== item.id,
                            ));
                    });
                }

                // group member by company_member_type_id: company_member_type_ids = [id1, id2]
                let uniqueMembers = [];
                if (existingMemberListTemp.length > 0) {
                    uniqueMembers = existingMemberListTemp.reduce((accumulator, member) => {
                        let object = accumulator.find((item) => item && item.id === member.id);
                        if (
                            (object && member.company_member_type_id !== 7) ||
                            object?.company_member_type_ids?.length === 0
                        ) {
                            object.company_member_type_ids.push(member.company_member_type_id);
                            return [...accumulator];
                        } else {
                            return [
                                ...accumulator,
                                {
                                    ...member,
                                    company_member_type_ids:
                                        member.company_member_type_id !== 7
                                            ? [member.company_member_type_id]
                                            : [],
                                },
                            ];
                        }
                    }, []);
                }

                setExistingMemberList(uniqueMembers);

                setOpen(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleOpenCorporation = () => {
        const members = getExistedMembers(company.CompanyMembers, member);
        setExistingMemberList(
            members.map((member) => ({
                ...member,
                company_member_type_ids:
                    member.CompanyPositions?.map((position) => position.company_member_type_id) ??
                    [],
                company_name: member.corporation_name,
                individual: member.type_member_id === 1,
            })),
        );
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {showExistingMember && (
                <div className="wrap-existing-member">
                    <button
                        type="button"
                        className="btn-open-dialog fw-500 mb-2"
                        color="primary"
                        onClick={
                            member.corporation_company_member_id
                                ? handleOpenCorporation
                                : handleOpen
                        }
                    >
                        Choose existing member
                    </button>
                    <Dialog
                        className="existing-member-dialog"
                        aria-labelledby="simple-dialog-title"
                        open={open}
                    >
                        <Card>
                            <CardHeader title="Existing member"></CardHeader>
                            <CardBody>
                                <p>
                                    If you change the information of existing member you select, it
                                    will also affect other positions containing the information.
                                </p>
                                <div className="existing-member-list">
                                    <div className="scroll-hight">
                                        {existingMemberList &&
                                            existingMemberList.length > 0 &&
                                            existingMemberList.map((member, memberIndex) => (
                                                <ExistingMember
                                                    mainMemberIndex={index}
                                                    body={body}
                                                    setBody={setBody}
                                                    bindArrayHelper={bindArrayHelper}
                                                    key={memberIndex}
                                                    handleCloseDialog={handleClose}
                                                    isLoading={isLoading}
                                                    setIsLoading={setIsLoading}
                                                    index={memberIndex}
                                                    member={member}
                                                    setShowExistingdMember={setShowExistingMember}
                                                    companyMemberType={companyMemberType}
                                                    stateSwitch={stateSwitch}
                                                    setStateSwitch={setStateSwitch}
                                                />
                                            ))}
                                        {existingMemberList && existingMemberList.length === 0 && (
                                            <p>No existing member to choose.</p>
                                        )}
                                    </div>
                                    {isLoading && (
                                        <div className="loading-over">
                                            <CircularProgress color="primary" />
                                        </div>
                                    )}
                                </div>
                                <div className="text-center mt-3">
                                    <Button
                                        className="fw-500"
                                        size="large"
                                        variant="contained"
                                        disableElevation
                                        style={{ textTransform: 'none' }}
                                        disabled={isLoading}
                                        onClick={handleClose}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Dialog>
                </div>
            )}
        </>
    );
}

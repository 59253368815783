/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useState, useMemo } from 'react';
import Radio from '@mui/material/Radio';
import { toast } from 'react-toastify';
import Select from 'react-select';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { GroupSelectLabel } from './GroupSelectLabel';
import * as actionsIncorporation from 'app/pages/_redux/incorporation/incorporationActions';

export const SelectCompany = ({
    form,
    setIsNewIncorp,
    isNewIncorp,
    body,
    setBody,
    selectedValue,
    setSelectedValue,
    companyId,
    // selectedCompany,
    // setSelectedCompany,
    setBackToStep1,
    goToStep2,
    setSelectedAnnualFee,
}) => {
    const dispatch = useDispatch();
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [insideList, setInsideList] = useState([]);
    const [outsideList, setOutsideList] = useState([]);
    const [packageIncorp, setPackageIncorp] = useState(null);
    const { insideCompany, outsideCompany } = useSelector(
        (state) => ({
            outsideCompany: state.companies.outsideCompany,
            insideCompany: state.companies.insideCompany,
        }),
        shallowEqual,
    );
    const [defaultSelect, setDefaultSelect] = useState(null);

    useEffect(() => {
        if (insideCompany && insideCompany.length > 0) {
            // === output example : [8,23,17]
            const insideGroupCountryID = [...new Set(insideCompany.map((item) => item.country_id))];
            setInsideList(groupByCountry(insideGroupCountryID, insideCompany));
        }
        let output = [];
        if (outsideCompany && outsideCompany.length > 0) {
            const outsideGroupCountryID = [
                ...new Set(outsideCompany.map((item) => item.country_id)),
            ];
            output = groupByCountry(outsideGroupCountryID, outsideCompany);
            if (!output) output = [];
        }
        output = [{ label: '+Add company', value: 'new_incorp' }].concat(output);
        setOutsideList(output);
    }, [insideCompany, outsideCompany]);
    useEffect(() => {
        if (selectedCompany && packageIncorp) {
            setBody({
                ...body,
                country_id: selectedCompany.country_id,
                billing: selectedCompany.Billing ? selectedCompany.Billing : null,
                billing_id: selectedCompany.Billing ? selectedCompany.Billing.id : null,
                company_id: selectedCompany.id,
                packageIncorp: packageIncorp,
                services: [],
                service_nominee_id: selectedCompany?.service_nominee_id || null,
                order_renew_processing: selectedCompany?.order_renew_processing || null,
            });
        } else {
            setBody({ ...body, company_id: null, packageIncorp: null });
        }
    }, [selectedCompany, packageIncorp]);

    useEffect(() => {
        const companyType = selectedValue === '0' ? insideCompany : outsideCompany;

        if (!companyId) {
            const companySelected = companyType?.find((company) => company.id === body?.company_id);
            if (companySelected) {
                setDefaultSelect({
                    country_id: companySelected?.country_id,
                    label: companySelected?.name,
                    value: companySelected,
                });
            }
        } else {
            const companyCheckoutSelected = insideCompany?.find(
                (company) => company.id === companyId,
            );

            if (goToStep2) {
                const companySelected = companyType?.find(
                    (company) => company.id === body?.company_id,
                );
                setDefaultSelect({
                    country_id: companySelected?.country_id,
                    label: companySelected?.name,
                    value: companySelected,
                });
            } else {
                setDefaultSelect({
                    country_id: companyCheckoutSelected?.country_id,
                    label: companyCheckoutSelected?.name,
                    value: companyCheckoutSelected,
                });
            }
        }
    }, [insideCompany, outsideCompany]);

    const groupByCountry = (countryGroupID, data, type) => {
        let output = countryGroupID.map((item) => {
            const companyList = data.filter((itemObj) => itemObj.country_id === item);
            const country = companyList[0].Country;
            return {
                label: country.name,
                options: companyList.map((itemObj) => ({
                    label: itemObj.name,
                    country_id: country.id,
                    value: itemObj,
                })),
            };
        });
        // if (type === "outside") {
        //   return (output = [{ label: "+Add company", value: "new_incorp" }].concat(
        //     output
        //   ));
        // }
        return output;
    };

    useEffect(() => {
        if (selectedValue == 0) {
            setBody({ ...body, company_id: null, packageIncorp: null, isInside: true });
        } else {
            setBody({ ...body, company_id: null, packageIncorp: null, isInside: false });
        }
    }, [isNewIncorp]);

    useEffect(() => {
        if (!goToStep2 && companyId) {
            const companyCheckoutSelected = insideCompany?.find(
                (company) => company.id === companyId,
            );
            if (companyCheckoutSelected) {
                handleChangeInsideCompany({
                    country_id: companyCheckoutSelected.country_id,
                    label: companyCheckoutSelected.name,
                    value: companyCheckoutSelected,
                });
            }
        }
    }, [insideCompany]);

    function handleChange(event) {
        setDefaultSelect(null);
        setSelectedValue(event.target.value);
        if (event.target.value == 0) {
            setBody({ isInside: true });
        } else {
            setBody({ isInside: false });
        }
        setIsNewIncorp(false);
        if (companyId) {
            setBackToStep1(true);
        }
        // setBody({...body,packageIncorp:})

        form.reset({});
    }

    function handleChangeInsideCompany(data) {
        setSelectedAnnualFee(false);
        var packageIncorp_id;
        var is_one_package = true;
        data.value.Orders.some((order) => {
            if (packageIncorp_id) {
                return true;
            } else {
                packageIncorp_id = order.OrderItems.find((e) => e.package_id !== null)?.package_id;
            }
        });

        if (packageIncorp_id) {
            dispatch(
                actionsIncorporation.fetchCompanyInfo(
                    data.country_id,
                    {
                        params: {
                            package_id: packageIncorp_id,
                            company_id: data.value.id,
                        },
                    },
                    is_one_package,
                ),
            )
                .then((res) => {
                    if (res && res.Packages && res.Packages.length > 0) {
                        setPackageIncorp({ ...res.Packages[0] });
                        setSelectedCompany(data.value);
                    } else {
                        setPackageIncorp(null);
                        setSelectedCompany(null);
                        toast.error('Cannot load the Packages!');
                    }
                })
                .catch(() => {
                    toast.error('Cannot load the Packages!');
                });
        } else {
            setBody({ ...body, company_id: null, packageIncorp: null });
            toast.error('Cannot found package of this company!');
        }
    }

    function handleChangeOutsideCompany(data) {
        setSelectedAnnualFee(false);
        if (data.value === 'new_incorp') {
            setBody({ ...body, company: null, packageIncorp: null });
            setIsNewIncorp(true);
        } else {
            dispatch(
                actionsIncorporation.fetchAllAncillaryServiceOutside({
                    params: {
                        country_id: data.country_id,
                    },
                }),
            ).then((res) => {
                if (res) {
                    setPackageIncorp({ ...res, AdditionalServices: res.Services });
                }
            });
            setSelectedCompany(data.value);
            setIsNewIncorp(false);
        }
    }

    return (
        <div>
            <div className="text-md-center text-left mx-auto mb-2 original-radio">
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="myCompany"
                        name="myCompany"
                        className="d-flex flex-row"
                        value={selectedValue}
                        onChange={handleChange}
                    >
                        <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Company managed by BBCIncorp"
                        />
                        <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Company not managed by BBCIncorp"
                        />
                    </RadioGroup>
                </FormControl>
            </div>

            {selectedValue === '0' && insideList && insideList.length !== 0 && (
                <div className="w-sm-50 mx-auto">
                    <Select
                        onChange={handleChangeInsideCompany}
                        options={insideList}
                        formatGroupLabel={GroupSelectLabel}
                        defaultValue={defaultSelect}
                    />
                </div>
            )}
            {selectedValue === '1' && outsideList && outsideList.length !== 0 && (
                <div className="w-sm-50 mx-auto">
                    <Select
                        onChange={handleChangeOutsideCompany}
                        options={outsideList}
                        formatGroupLabel={GroupSelectLabel}
                        defaultValue={defaultSelect}
                    />
                </div>
            )}
        </div>
    );
};

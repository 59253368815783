import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import HTMLReader from '../../order-form/document-controller/file-detail/HTMLReader';
import { Modal } from 'react-bootstrap';
import UploadBox from 'app/components/UploadBox';
import LoadingButton from 'app/components/LoadingButton';
import { Button, Typography } from '@mui/material';
import DocumentTitleList, { isOtherUploaded, isUploaded } from './components/DocumentTitleList';
import FileList from './components/FileList';
import * as ActionsOrder from 'app/pages/_redux/order-form/orderFormActions';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { downloadFileS3, getSignedUrlS3 } from 'app/pages/_redux/files/filesCrud';
import Axios from 'axios';
import { toast } from 'react-toastify';
import {
    createRecordFileBookkeeper,
    getProcessDetail,
} from 'app/pages/_redux/additional-service/api';
import DocumentCorporateMember from './components/DocumentCorporateMember';
import DocumentMiniList from './components/DocumentMiniList';
import {
    updateLoadingUploadFileAction,
    updateOrderInfo,
} from 'app/pages/_redux/additional-service/slice';
import { cloneDeep } from 'lodash';
import { deleteFileAction } from 'app/pages/_redux/additional-service/asyncThunk';
import NativeReader from '../../order-form/document-controller/file-detail/NativeReader';

const checkIsCompanyHasCorporation = (orderInfo) => {
    let isCompanyHasCorporation = false;
    const result = [
        {
            name: 'Director',
            titles: [],
        },
        {
            name: 'Shareholder',
            titles: [],
        },
    ];
    const corporationDirector = orderInfo?.Company?.CompanyMembers.filter(
        (member) =>
            member.type_member_id == 2 &&
            member.CompanyPositions.find(
                (position) =>
                    position.corporation_company_member_id == null &&
                    position.company_member_type_id == 1,
            ),
    );
    const corporationShareholder = orderInfo?.Company?.CompanyMembers.filter(
        (member) =>
            member.type_member_id == 2 &&
            member.CompanyPositions.find(
                (position) =>
                    position.corporation_company_member_id == null &&
                    position.company_member_type_id == 2,
            ),
    );
    if (corporationDirector.length > 0) {
        result.find((type) => type.name === 'Director').titles = corporationDirector.map(
            (member) => ({
                id: member.id,
                name: member.corporation_name,
            }),
        );
        isCompanyHasCorporation = true;
    }
    if (corporationShareholder.length > 0) {
        result.find((type) => type.name === 'Shareholder').titles = corporationShareholder.map(
            (member) => ({
                id: member.id,
                name: member.corporation_name,
            }),
        );
        isCompanyHasCorporation = true;
    }
    return {
        isCompanyHasCorporation: isCompanyHasCorporation,
        data: result.filter((item) => item.titles.length > 0),
    };
};

const DocumentList = ({ handleNext, handleBack }) => {
    const [activeMember, setActiveMember] = useState(null);
    const [activeTitle, setActiveTitle] = useState(null);
    const [activeCategory, setActiveCategory] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [htmlDownloading, setHtmlDownloading] = useState(false);

    const { loadingUploadFile, loadingDeleteFile, orderInfo } = useSelector(
        (state) => state.additionalService,
    );

    const uploadBoxRef = useRef(null);

    const dispatch = useDispatch();

    const process = useMemo(() => {
        return orderInfo?.Task?.Processes?.[0];
    }, [orderInfo]);

    const fileTemplates = useMemo(() => {
        let category1 = {
            id: 1,
            name: 'Confirmation Form',
            titles: [],
            Files: [],
            clickable: false,
        };
        let category2 = {
            id: 2,
            name: 'Required documents',
            titles: {
                'Declaration/ Annual Return': [],
                'Certificate of Good Standing': [],
            },
            Files: [],
            clickable: false,
        };
        let category3 = {
            id: 3,
            name: 'Other Documents',
            file_type_id: 6,
            Files: [],
            clickable: true,
        };
        if (!process) return [];
        process.ProcessStep.FileTemplates.forEach((fileTemplate) => {
            if (fileTemplate.name.toLowerCase().startsWith('confirmation form')) {
                category1 = {
                    ...category1,
                    titles: fileTemplate.titles,
                    Files: fileTemplate.Files,
                };
            } else {
                category2 = {
                    ...category2,
                    titles: {
                        ...category2.titles,
                        'Declaration/ Annual Return': [
                            ...category2.titles['Declaration/ Annual Return'],
                            ...fileTemplate.titles,
                        ],
                    },
                    Files: [...category2.Files, ...fileTemplate.Files],
                };
            }
        });
        const { isCompanyHasCorporation, data } = checkIsCompanyHasCorporation(orderInfo);
        if (isCompanyHasCorporation) {
            const files =
                orderInfo?.Task?.Files.filter(
                    (file) => file.file_type_id === 6 && file.company_member_id !== null,
                ) || [];
            category2 = {
                ...category2,
                titles: {
                    ...category2.titles,
                    'Certificate of Good Standing': [
                        {
                            file_name: 'Certificate of Good Standing.pdf',
                            file_type_id: 6,
                            children: data,
                            name: 'Certificate of Good Standing',
                        },
                    ],
                },
                Files: [...category2.Files, ...files],
            };
        }
        const otherFiles =
            orderInfo?.Task?.Files.filter(
                (file) => file.file_type_id === 6 && file.company_member_id === null,
            ) || [];
        if (otherFiles.length > 0) {
            category3 = {
                ...category3,
                Files: otherFiles,
            };
        }
        return category2?.titles['Certificate of Good Standing'].length > 0 ||
            category2?.titles['Declaration/ Annual Return'].length > 0
            ? [category1, category2, category3]
            : [category1, category3];
    }, [orderInfo]);

    const onUploadFile = async (files) => {
        if (!files.length || (!activeTitle && activeCategory?.id !== 3) || !process) return;
        dispatch(updateLoadingUploadFileAction('loading'));
        const promisesPutFile = [];
        const promisesCreateRecordFile = [];
        const params = [];
        for (let i = 0; i < files.length; i++) {
            params.push({ filename: files[i].name, content_type: files[i].type });
        }
        try {
            /* gen s3 signed url */
            const {
                data: { data: signedUrls },
            } = await getSignedUrlS3(params);
            for (let i = 0; i < signedUrls.length; i++) {
                promisesPutFile.push(Axios.put(signedUrls[i].url, files[i], {}));
            }
            /* Call tất cả các hàm push file */
            await Promise.all(promisesPutFile);
            /* Xử lý create record file */
            for (let i = 0; i < signedUrls.length; i++) {
                let payload;
                if (activeCategory.id === 3) {
                    payload = {
                        file_type_id: activeCategory.file_type_id,
                        name: files[i].name,
                        s3_path: signedUrls[i].filename,
                        mime_type: files[i].type,
                        task_id: orderInfo.Task.id,
                        company_event_id: orderInfo?.CompanyEvent?.id || null,
                    };
                } else {
                    if (activeMember && !activeTitle?.file_template_id) {
                        payload = {
                            file_type_id: activeTitle.file_type_id,
                            company_member_id: activeMember.id,
                            name: files[i].name,
                            s3_path: signedUrls[i].filename,
                            mime_type: files[i].type,
                            task_id: orderInfo.Task.id,
                        };
                    } else {
                        payload = {
                            file_type_id: activeTitle.file_type_id,
                            file_template_id: activeTitle?.file_template_id,
                            name: files[i].name,
                            s3_path: signedUrls[i].filename,
                            mime_type: files[i].type,
                            task_id: orderInfo.Task.id,
                            company_event_id: orderInfo?.CompanyEvent?.id || null,
                        };
                    }
                }
                promisesCreateRecordFile.push(createRecordFileBookkeeper(payload));
            }
            await Promise.all(promisesCreateRecordFile);
            /* Get lại process detail data để hiển thị lại các file đã upload */
            const {
                data: { data },
            } = await getProcessDetail(process.id);
            dispatch(updateOrderInfo(data));
            dispatch(updateLoadingUploadFileAction('idle'));
            /* reset value of input */
            uploadBoxRef.current.value = '';
        } catch (e) {
            console.log('error upload: ', e);
            dispatch(updateLoadingUploadFileAction('error'));
            toast.error('Somethings went wrong!');
            /* reset value of input */
            uploadBoxRef.current.value = '';
        }
    };

    useEffect(() => {
        if (!fileTemplates) return;
        //update data from api when upload or delete file
        if (activeCategory) {
            const newestActiveCate = [...(fileTemplates || [])].find(
                (category) => activeCategory.id === category.id,
            );
            setActiveCategory(cloneDeep(newestActiveCate));
        }
    }, [fileTemplates]);

    const onSelectTitle = useCallback(([category, title]) => {
        setActiveCategory(category);
        setActiveTitle(title);
    }, []);

    const onSelectMember = useCallback((member) => {
        setActiveMember(member);
    }, []);

    const closeModal = () => {
        setShowModal(false);
    };

    const getNoteContent = (category, title) => {
        if (!category) return;
        switch (category.id) {
            case 1:
                return (
                    <div style={{ margin: '16px 35.25px' }}>
                        <Typography fontWeight={'bold'}>Note:</Typography>
                        <Typography>
                            Confirmation Form is a form recording all up-to-date and accurate
                            information that is declared by the Customer. The Confirmation Form
                            shall be a foundation for BBCIncorp to continue to provide services to
                            the Customer. And BBCIncorp shall not undertake responsibilities for any
                            misleading declaration from Customer.
                        </Typography>
                    </div>
                );
            case 2:
                if (title?.name === 'Certificate of Good Standing') {
                    return (
                        <div style={{ margin: '16px 35.25px' }}>
                            <Typography fontWeight={'bold'}>Note:</Typography>
                            <Typography>
                                A Certificate of Good Standing (CGS)/ A Certificate of Incumbency
                                (CI) is an official document, issued by the Registry of Companies;
                                confirms that a particular company legally exists, has complied with
                                all the administrative requirements as to its continued registration
                                and has paid all government duties, anh, therefore is "in good
                                standing" vis-a-vis the Companies Register as of the date of issue,{' '}
                                <span className="text-danger">
                                    must be the most recent version and dated within the last 12
                                    months
                                </span>
                                .
                            </Typography>
                        </div>
                    );
                } else {
                    return <></>;
                }
            case 3:
                return (
                    <div style={{ margin: '16px 35.25px' }}>
                        <Typography fontWeight={'bold'}>Note:</Typography>
                        <Typography>Please update more supporting documents here.</Typography>
                    </div>
                );
        }
    };

    const openHTMLFile = (params) => {
        if (orderInfo?.Task && orderInfo?.Task?.id && params) {
            // setActionLoading(true);
            dispatch(
                ActionsOrder.fetchTemplateHTML({
                    task_id: orderInfo.Task.id,
                    ...params.templateParams,
                }),
            )
                .then((response) => {
                    if (response && response.data && response.data.html) {
                        setShowModal(true);
                        setModalData({
                            html: response.data.html,
                            templateParams: params.templateParams,
                            file_name: params.file_name,
                        });
                    }
                })
                .finally(() => {
                    // setActionLoading(false);
                });
        }
    };

    const downloadHTMLAsPDF = () => {
        if (orderInfo?.Task && orderInfo?.Task.id && modalData && modalData.templateParams) {
            setHtmlDownloading(true);
            dispatch(
                ActionsOrder.downloadTemplateHTML({
                    task_id: orderInfo?.Task.id,
                    ...modalData.templateParams,
                }),
            )
                .then((response) => {
                    saveAs(
                        response,
                        `${modalData.file_name.split('.')[0]} - ${orderInfo?.Company?.name}.${
                            modalData.file_name.split('.')[1]
                        }`,
                    );
                    setHtmlDownloading(false);
                })
                .catch(() => {
                    setHtmlDownloading(false);
                });
        }
    };

    const uploadList = useMemo(() => {
        if (activeCategory?.id === 3) {
            return activeCategory?.Files;
        }
        return (
            activeCategory?.Files.filter((file) =>
                file?.file_template_id
                    ? file?.file_template_id == activeTitle?.file_template_id
                    : file?.company_member_id == activeMember?.id,
            ) || []
        );
    }, [activeCategory, activeTitle, activeMember]);

    useEffect(() => {
        if (activeTitle?.children?.length > 0) {
            setActiveMember(activeTitle.children[0].titles[0]);
        } else {
            setActiveMember(null);
        }
    }, [activeTitle]);

    const isCheckValid = () => {
        return fileTemplates
            ? fileTemplates.every((template) => {
                  if (template.id === 3) {
                      return true;
                  }
                  if (template.id === 1) {
                      return template?.titles?.every((title) => {
                          return isUploaded(title, template);
                      });
                  }
                  if (template.id === 2) {
                      return template?.titles['Declaration/ Annual Return'].every((title) => {
                          return isUploaded(title, template);
                      });
                  }
              })
            : false;
    };

    const onClickView = async (doc) => {
        try {
            const {
                data: { data },
            } = await downloadFileS3(doc.s3_path);
            if (['doc', 'docx'].includes(doc.s3_path.split('.')[1].toLowerCase())) {
                saveAs(data, doc.s3_path);
                return;
            }
            setShowModal(true);
            setModalData({ file: { name: doc.s3_path }, file_url: data });
        } catch (e) {
            toast.error('Somethings when wrong!');
        }
    };

    const onClickDelete = async (doc) => {
        const resultAction = await dispatch(deleteFileAction(doc.id));
        if (deleteFileAction.fulfilled.match(resultAction)) {
            /* Get lại process detail data để hiển thị lại các file đã upload */
            const {
                data: { data },
            } = await getProcessDetail(process.id);
            dispatch(updateOrderInfo(data));
        } else {
            toast.error('Somethings went wrong!');
        }
    };

    const downloadNativePDF = () => {
        return new Promise((resolve, reject) => {
            saveAs(modalData.file_url, modalData.file.name);
            resolve(true);
        });
    };

    return (
        <>
            <div className={'row shadow-sm rounded mb-5'}>
                <div className={'col-12 col-md-3 bg-light p-0'}>
                    <h6 className={'text-center border-bottom p-5'}>DOCUMENT LIST</h6>
                    <div className="px-5">
                        <DocumentTitleList
                            fileTemplates={fileTemplates}
                            autoSelectFirstTitle={true}
                            onSelectTitle={onSelectTitle}
                            activeMember={activeMember}
                        />
                    </div>
                </div>

                <div
                    className={
                        'col-12 col-md-6 border-right p-0 d-flex flex-column justify-content-between'
                    }
                >
                    <div>
                        <h6 className={'text-center border-bottom p-5'}>FILES DETAILS</h6>
                        <FileList
                            currentTemplate={activeCategory}
                            currentTitle={activeTitle}
                            openHTMLFile={openHTMLFile}
                            // orderInfo={orderInfo}
                        />
                        {activeTitle?.children && (
                            <DocumentCorporateMember
                                companyMemberTypes={activeTitle.children}
                                autoSelectFirstMember={true}
                                onSelectMember={onSelectMember}
                                activeTemplate={activeCategory}
                            />
                        )}
                        {getNoteContent(activeCategory, activeTitle)}
                    </div>
                </div>

                {(activeTitle || activeCategory?.id === 3) && (
                    <div className={'col-12 col-md-3 p-0 '}>
                        <h6 className={'text-center border-bottom p-5'}>SIGNING STATUS</h6>
                        <div className={'p-3'}>
                            <UploadBox
                                onUploadFile={onUploadFile}
                                ref={uploadBoxRef}
                                loading={loadingUploadFile === 'loading'}
                            />
                            <DocumentMiniList
                                documentList={uploadList || []}
                                onClickView={onClickView}
                                onClickDelete={onClickDelete}
                                loadingMap={loadingDeleteFile}
                                panelTitle={'Your wet-link documents'}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div>
                <Button
                    size="large"
                    variant="contained"
                    disableElevation
                    className="mr-2"
                    onClick={handleBack}
                    disabled={isLoading}
                >
                    Back
                </Button>
                <LoadingButton
                    disabled={isLoading || !isCheckValid()}
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    style={{ color: !isLoading ? '#fff' : 'transparent' }}
                    loading={isLoading}
                    onClick={handleNext}
                >
                    Next
                </LoadingButton>
            </div>

            <Modal show={showModal} onHide={closeModal} size="xl" centered>
                <Modal.Body>
                    {modalData && (
                        <>
                            {modalData?.html && (
                                <HTMLReader
                                    closeDetailModal={closeModal}
                                    data={modalData}
                                    download={downloadHTMLAsPDF}
                                    downloading={htmlDownloading}
                                    fetched={true}
                                />
                            )}
                            {modalData?.file_url && (
                                <NativeReader
                                    closeDetailModal={closeModal}
                                    data={modalData}
                                    download={downloadNativePDF}
                                />
                            )}
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};
export default DocumentList;

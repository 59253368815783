import axios from 'axios';

export const NOTIFICATION_URL = '/notification';
export const CHECK_NOTIFICATION_URL = 'notification/check';
export const READ_NOTIFICATION_URL = 'notification/read_detail';

export const getNotification = (queryParams) => {
    return axios.get(NOTIFICATION_URL, { params: queryParams });
};

// export const getNotification = (queryParams) => {
//     return axios.get(NOTIFICATION_URL, {
//         // params: { pageNumber, pageSize, filter },
//         params: queryParams,
//     });
// };

export const checkNotification = () => {
    return axios.get(CHECK_NOTIFICATION_URL);
};

export const readNotification = (notification_id) => {
    return axios.put(READ_NOTIFICATION_URL, { notification_id });
};

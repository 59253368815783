import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';

export default function CompanyInfo({ body, isNewIncorp, form, activeStep, selectedValue }) {
    const { insideCompany, outsideCompany, countriesHasService } = useSelector(
        (state) => ({
            outsideCompany: state.companies.outsideCompany,
            insideCompany: state.companies.insideCompany,
            countriesHasService: state.enum.countriesHasService,
        }),
        shallowEqual,
    );
    const [company, setCompany] = useState(null);
    const companyNewIncorp =
        isNewIncorp &&
        countriesHasService?.find((country) => country.id === form.watch('country_id'));

    useEffect(() => {
        if (isNewIncorp && countriesHasService && body.country_id && body.company_name) {
            var country = countriesHasService.find((e) => e.id === body.country_id);
            setCompany({
                name: body.company_name,
                Country: country,
                incorporation_date: body.incorporation_date,
                company_reg_no: body.company_reg_no,
            });
        } else {
            if (!body.company_id) {
                setCompany(null);
            }
        }
    }, [body]);

    useEffect(() => {
        if (outsideCompany && insideCompany && !isNewIncorp) {
            let company_outside = outsideCompany.find((e) => e.id === body.company_id);
            let company_inside = insideCompany.find((e) => e.id === body.company_id);
            if (company_outside) setCompany(company_outside);
            if (company_inside) setCompany(company_inside);
        }
    }, [body]);

    const getIncorporationDateNewIncorp = (date) => {
        if (!date) return 'None';
        return moment(date).format('DD/MM/YYYY');
    };

    const getCompanyRegNoNewIncorp = (companyReg) => {
        if (!companyReg) return 'None';
        return companyReg;
    };

    if (company != null || (isNewIncorp && companyNewIncorp)) {
        return (
            <div className="py-10">
                <p className="h4 mb-6">Company Details</p>
                <div className="row">
                    <div className="col-lg-6 col-sm-6 col-12 d-flex">
                        <p className="font-weight-bold">Jurisdiction:</p>
                        <p className="font-weight-bold ml-4">
                            {isNewIncorp ? companyNewIncorp?.name : company.Country?.name}
                        </p>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-12 d-flex">
                        <p className="font-weight-bold">Company Reg. No. :</p>
                        <p className="font-weight-bold ml-4">
                            {isNewIncorp
                                ? getCompanyRegNoNewIncorp(body.company_reg_no)
                                : company.company_reg_no
                                ? company.company_reg_no
                                : 'None'}
                        </p>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-12 d-flex">
                        <p className="font-weight-bold">Name:</p>
                        <p className="font-weight-bold ml-4">
                            {isNewIncorp
                                ? `${body.company_name} ${
                                      body?.packageIncorp?.CompanySuffixes?.filter(
                                          (item) => item?.id === form.watch('company_suffix_id'),
                                      )?.[0]?.name
                                  }`
                                : `${company?.name} ${company?.CompanySuffix?.name}`}
                        </p>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-12 d-flex">
                        <p className="font-weight-bold">Incorporation date:</p>
                        <p className="font-weight-bold ml-4">
                            {isNewIncorp
                                ? getIncorporationDateNewIncorp(form.watch('incorporation_date'))
                                : company.incorporation_date
                                ? moment(company.incorporation_date).format('DD/MM/YYYY')
                                : 'None'}
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return <div></div>;
}

import React, { useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import Step2 from './step2';
import Step3 from './step3';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actionsEnum from 'app/pages/_redux/enum/enumActions';
import * as actionsIncorporation from 'app/pages/_redux/incorporation/incorporationActions';

import Step4 from './step4';
import * as actionsBilling from 'app/pages/_redux/billings/billingsActions';
import Step1 from './step1';
import { StepButton } from '@mui/material';
import '../incorporation.scss';
import { COUNTRIES_ID } from 'constants/countries';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: 'white',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));
// const countries = [];

const initBody = {
    company_name: '',
    company_suffix_id: '',
    entity_type_id: '',
    country_id: '',
};

export default function VerticalLinearStepper() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [body, setBody] = useState(initBody);

    const formRef = useRef();
    const formPreventiveRef = useRef();
    const [param, setParam] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    // const { currentState } = useSelector((state) => ({ currentState: state.enum }), shallowEqual);
    const { authState } = useSelector((state) => ({ authState: state.auth }), shallowEqual);
    const { serviceTypeMap } = useSelector((state) => state.enum);
    const [selectedRadio, setSelectedRadio] = useState(false);
    const isSingapore = COUNTRIES_ID.SINGAPORE === body?.country_id;

    const getSteps = () => [
        'Company Info',
        'Company Package',
        'Ancillary Services',
        'Review & Complete',
    ];
    // const { incorporationState } = useSelector(
    //     (state) => ({ incorporationState: state.incorporation }),
    //     shallowEqual,
    // );
    const uri = {
        paypal: {
            create: '/customer_account/incorporation/paypal',
            approve: '/customer_account/incorporation/paypal/authorize',
        },
        stripe: {
            create: '/customer_account/incorporation/stripe',
        },
        bank: {
            create: '/customer_account/incorporation/bank',
        },
        paynow: {
            create: '/customer_account/incorporation/payment_intent',
        },
    };
    // const { totalCount, countriesHasService, listLoading } = currentState;
    // var isFirst = true;
    useEffect(() => {
        dispatch(actionsEnum.fetchCountriesNonRestricted());
        dispatch(actionsEnum.getServiceType());
        dispatch(actionsEnum.getWebsites());
    }, []);
    useEffect(() => {
        // clear selections list
        // productsUIProps.setIds([]);
        // server call by queryParams

        dispatch(actionsIncorporation.fetchMembership());
        dispatch(actionsBilling.fetchBillings(null));
        dispatch(actionsEnum.fetchCountriesHasService());
    }, [dispatch]);

    function onSubmitForm(index) {
        if (formRef.current) {
            formRef.current.handleSubmitForm();
            if (formRef.current.isValid) {
                setActiveStep(index);
            }
        } else {
            setActiveStep(index);
        }
    }
    const steps = getSteps();
    function Payment() {
        let param = {
            Company: {
                country_id: body.country.id,
                company_suffix_id: body.companySuffix.id,
                entity_type_id: body.companyType.id,
                name: body.companyName,
                preventive_name: body.preventiveName || [],
                // preventive_name:
                //     body.preventiveName.length !== 0
                //         ? '[' +
                //         body.preventiveName.map((e) => {
                //             return JSON.stringify({
                //                 name: e.name,
                //                 company_suffix_id: e.companySuffix.id,
                //             });
                //         }) +
                //         ']'
                //         : '[]',
            },
            billing_id: body.billing.id,
            package_id: body.packageIncorp.id,
        };
        if (body.services.length !== 0) {
            param = {
                ...param,
                OrderItems: body.services.map((e) => {
                    return { service_id: e.id, quantity: e.quantity };
                }),
            };
        }
        param = {
            uri: '/customer_account/incorporation/' + body.paymentMethod,
            payload: param,
        };
        setParam(param);
        return param;
    }
    function preParam() {
        let param = {};
        if (body.packageIncorp) {
            param = {
                ...param,
                Company: {
                    country_id: body.country_id,
                    company_suffix_id: body.company_suffix_id,
                    entity_type_id: body.entity_type_id,
                    name: body.company_name,
                    preventive_name: body.preventives || [],
                    // preventive_name:
                    //     body.preventives && body.preventives !== 0
                    //         ? '[' +
                    //         body.preventives.map((e) => {
                    //             return JSON.stringify({ ...e });
                    //         }) +
                    //         ']'
                    //         : '[]',
                },
                package_id: body.packageIncorp.id,
            };
        }

        if (body.services.length !== 0) {
            param = {
                ...param,
                OrderItems: body.services.map((e) => {
                    return { service_id: e.id, quantity: e.quantity };
                }),
            };
        }
        param = {
            uri: uri,
            payload: param,
        };
        setParam(param);
    }
    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <Step1
                        setBody={setBody}
                        body={body}
                        setActiveStep={setActiveStep}
                        activeStep={activeStep}
                        formPreventiveRef={formPreventiveRef}
                        formRef={formRef}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        setSelectedRadio={setSelectedRadio}
                        onSubmitForm={onSubmitForm}
                    />
                );
            case 1:
                return (
                    <Step2
                        body={body}
                        setBody={setBody}
                        setActiveStep={setActiveStep}
                        activeStep={activeStep}
                        setSelectedRadio={setSelectedRadio}
                    />
                );
            case 2:
                return (
                    body.packageIncorp && (
                        <Step3
                            body={body}
                            setBody={setBody}
                            setActiveStep={setActiveStep}
                            activeStep={activeStep}
                            setParam={setParam}
                            preParam={preParam}
                            serviceTypeMap={serviceTypeMap}
                            selectedRadio={selectedRadio}
                            setSelectedRadio={setSelectedRadio}
                        />
                    )
                );
            case 3:
                return (
                    <Step4
                        services={body.services}
                        packageIncorp={body.packageIncorp}
                        body={body}
                        setBody={setBody}
                        param={param}
                        setPram={setParam}
                        setActiveStep={setActiveStep}
                        activeStep={activeStep}
                        token={authState.authToken}
                    />
                );
            default:
                return 'Unknown step';
        }
    }
    // const handleNext = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    // const handleReset = () => {
    //     setActiveStep(0);
    // };
    const jumToStep = (index) => {
        if (index === 0) {
            setActiveStep(index);
        } else {
            if (!isLoading) {
                if (index === 1) {
                    onSubmitForm(index);
                } else if (index === 2) {
                    if (body.packageIncorp) onSubmitForm(index);
                } else if (index === 3) {
                    if (isSingapore && !selectedRadio) return; // nếu chưa chọn checkbox không cho click qua step 4
                    if ((body.services && body.services.length > 0) || body.packageIncorp) {
                        onSubmitForm(index);
                        preParam();
                    }

                    // if (body.services || body.packageIncorp) {
                    //     preParam();
                    // }
                }
            }
        }
    };
    return (
        <div className={classes.root}>
            <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepButton
                            onClick={() => {
                                jumToStep(index);
                            }}
                        >
                            {label}
                        </StepButton>
                        <StepContent>{getStepContent(index)}</StepContent>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}

import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';

import { existingServiceStyles } from '../../components/UseStyles';
import { ServiceFn } from '../../components/ServiceFn';
import { CompanyServiceItem } from './CompanyServiceItem';
import TaxFillingServiceSingapore from '../../incorporation/component/TaxFillingServiceSingapore';
import { Box, Checkbox, FormControlLabel, IconButton, Tooltip, Typography } from '@mui/material';
import { FormControl } from 'react-bootstrap';
import HelpIcon from '@mui/icons-material/Help';
import displayUnit from '../../utilities/display-unit-service';

const ID_PACKAGES_SINGAPORE = [86, 87, 88, 89];
const ANNUAL_RENEWAL_SERVICE_TYPE = 18;

export default function CompanyService({
    body,
    setBody,
    serviceTypeMap,
    serviceDiscount,
    // group,
    // category,
    servicesChange,
    eventCalendarInfo,
    companyId,
    goToStep2,
    setSelectedRadio,
    annualFeeServices = [],
    selectedAnnualFee = false,
    setSelectedAnnualFee,
    servicesWithoutAnnualFee = [],
    setServicesWithoutAnnualFee,
}) {
    const location = useLocation();
    const classes = existingServiceStyles();
    const [record, setRecord] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [services, setServices] = React.useState([]);
    const packageAnnualFeeInfo = useMemo(() => {
        if (!body?.packageIncorp?.PackageAnnual?.Services?.length) return '';
        const info = body?.packageIncorp?.PackageAnnual?.Services?.map((item, index) => {
            return `${++index}. ${item?.name}`;
        }).join('\n');

        return info;
    }, [body?.packageIncorp?.PackageAnnual?.Services]);
    const totalAnnualFee = useMemo(() => {
        if (annualFeeServices?.length) {
            return annualFeeServices.reduce(
                (acc, service) => acc + service?.Fee?.value * service?.quantity,
                0,
            );
        } else {
            return 0;
        }
    }, [body?.packageIncorp?.PackageAnnual]);

    const group =
        body?.packageIncorp?.AdditionalServices?.map((service, index, arr) => {
            return {
                ...service.ServiceType,
                Services: arr.filter((item) => item.ServiceType.id === service.ServiceType.id),
            };
        }) || [];
    const category = [
        ...Array.from(new Set(group.map((e) => e.id))).map((id) => {
            return group.find((item) => item.id === id);
        }),
    ]
        .map((item) => ({ ...item, parent_name: serviceTypeMap?.[item.parent_id]?.name || '' }))
        .sort((a, b) => {
            return (a.parent_id || 0) - (b.parent_id || 0) || (a.order || 0) - (b.order || 0);
        });

    useEffect(() => {
        setBody({ ...body, services: services });
        setServicesWithoutAnnualFee && setServicesWithoutAnnualFee([...services]);
        let total = services.reduce(
            (accumulator, service, currentIndex, array) =>
                accumulator + service.Fee.value * service.quantity,
            0,
        );

        if (isChooseServiceHasDiscountMembership()) {
            total += serviceDiscount?.Fees?.[0]?.value || 0;
        }

        if (body?.discountCheckout?.length > 0) {
            body.discountCheckout.forEach((item) => {
                total += item.total || 0;
            });
        }

        if (selectedAnnualFee && annualFeeServices?.length) {
            total += totalAnnualFee;
        }

        setTotal(total);
        return () => {};
    }, [services]);

    useEffect(() => {
        let totalTemp = total;

        if (!selectedAnnualFee) {
            setBody({
                ...body,
                services: [...servicesWithoutAnnualFee],
            });
        }

        if (selectedAnnualFee) {
            totalTemp += totalAnnualFee;
        } else {
            if (!services.length) {
                totalTemp = 0;

                if (body?.services?.length) {
                    setBody({ ...body, services: [] });
                }
            }
            if (services.length) {
                const totalServices = services.reduce(
                    (accumulator, service, currentIndex, array) =>
                        accumulator + service.Fee.value * service.quantity,
                    0,
                );

                totalTemp = totalServices;
            }
        }
        setTotal(totalTemp);
    }, [selectedAnnualFee]);

    useEffect(() => {
        const servicesTemp = [];

        if (companyId !== body?.company_id) {
            setBody({
                ...body,
                services: [],
            });
            setServicesWithoutAnnualFee && setServicesWithoutAnnualFee([]);
        } else {
            if (!goToStep2 && companyId) {
                if (category.length && servicesChange?.length) {
                    category.forEach((cate) => {
                        return cate?.Services?.forEach((service) => {
                            return servicesChange.forEach((serviceEdit) => {
                                if (serviceEdit.service_id === service.id) {
                                    servicesTemp.unshift({
                                        ...service,
                                        quantity: serviceEdit.quantity,
                                        disable: true,
                                    });
                                }
                            });
                        });
                    });
                }
                if (eventCalendarInfo && category.length) {
                    const serviceList = body?.packageIncorp?.AdditionalServices;
                    const eventCalendarService = serviceList.find(item => item.id === eventCalendarInfo?.service_id)
                    if (eventCalendarService) {
                        let obj = {
                            ...eventCalendarService,
                            quantity: eventCalendarInfo?.year_not_buy_list?.length || 1,
                            disable: true,
                        };
                        if(eventCalendarInfo?.year_not_buy_list?.length > 0) {
                            obj = {
                                ...obj,
                                year_not_buy_list: eventCalendarInfo.year_not_buy_list
                            }
                        }
                        if(eventCalendarService?.ServiceType.id === ANNUAL_RENEWAL_SERVICE_TYPE && body?.service_nominee_id) {
                            let service = serviceList.find(item => item.id === body?.service_nominee_id);
                            servicesTemp.unshift({
                                ...service,
                                quantity: 1,
                                disable: true
                            })
                        }
                        servicesTemp.unshift(obj);
                    }
                }
            }
        }

        if (!goToStep2 && companyId) {
            if (servicesChange.length) {
                const discountCheckout = servicesChange.filter(
                    (service) => [1896, 1897, 1988, 1989, 1991, 1992].includes(service.service_id), // discount from checkout edit company
                );

                if (discountCheckout) {
                    if (companyId === body?.company_id) {
                        setBody({
                            ...body,
                            discountCheckout,
                        });
                    }
                }
            }
        }

        setServices(!goToStep2 && companyId ? servicesTemp : body?.services);
    }, [body.packageIncorp, servicesChange?.length]);

    const isChooseServiceHasDiscountMembership = () => {
        return services?.some((service) => {
            return [1, 18].includes(service?.ServiceType?.id); // 18: type anuual renewal
        });
    };
    useEffect(() => {
        if (
            location?.pathname === '/incorporation' &&
            body?.services?.length === 0 &&
            body?.country_id === 23 &&
            [14, 15, 16].includes(body?.packageIncorp?.id)
        ) {
            // Belize and company type IBC
            if (category && category.length > 0) {
                let service = category.find((e) => e.id === 34)?.Services; // get category Post-incorporation (Compliance Service)
                if (service && service.length > 0) {
                    let tin = service.find((e) => e.id === 1851); // get service TIN Registration in Belize
                    if (tin) {
                        setServices([...services, { ...tin, quantity: 1, disable: true }]);
                    }
                }
            }
        }
    }, []);

    // generate
    if (body.packageIncorp)
        return (
            <>
                {location?.pathname === '/incorporation' &&
                    ID_PACKAGES_SINGAPORE.includes(body.packageIncorp.id) && (
                        <TaxFillingServiceSingapore
                            body={body}
                            setServices={setServices}
                            setSelectedRadio={setSelectedRadio}
                            category={category}
                        />
                    )}
                <div className={classes.root + ' service'}>
                    {/* <h2 className="h3">Add service</h2> */}
                    <p className="h4 mb-6">Add service</p>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">No.</TableCell>
                                    <TableCell align="left">Category</TableCell>
                                    <TableCell align="left">Service</TableCell>
                                    <TableCell align="center">Unit price</TableCell>
                                    <TableCell align="center">Quantity</TableCell>
                                    <TableCell align="center">Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {services.map((e, index) => (
                                    <CompanyServiceItem
                                        key={index}
                                        index={index}
                                        classes={classes}
                                        category={category}
                                        setServices={setServices}
                                        services={services}
                                        setRecord={setRecord}
                                        record={record}
                                        disabled={!!e?.disable}
                                        nomineeServiceId={body.service_nominee_id}
                                        nomineeService={body?.packageIncorp?.AdditionalServices?.find(item => item.id === body?.service_nominee_id)}
                                    />
                                ))}
                                {body?.discountCheckout?.length > 0 &&
                                    body?.discountCheckout.map((item, index) => (
                                        <TableRow key={'props.subcription.id'}>
                                            <TableCell align="center" width="5%" scope="row">
                                                {services.length + 1 + index}
                                            </TableCell>
                                            <TableCell align="left" width="20%">
                                                {item.category_name}
                                            </TableCell>
                                            <TableCell align="left" width="30%">
                                                {item.service_name}
                                            </TableCell>
                                            <TableCell align="center" width="20%">
                                                {item.unit_price >= 0
                                                    ? `US$${item.unit_price}`
                                                    : `-US$${Math.abs(item.unit_price)}`}
                                            </TableCell>
                                            <TableCell align="center" width="5%">
                                                {item.quantity}
                                            </TableCell>
                                            <TableCell align="center" width="20%">
                                                {item.total >= 0
                                                    ? `US$${item.total}`
                                                    : `-US$${Math.abs(item.total)}`}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {serviceDiscount && isChooseServiceHasDiscountMembership() && (
                                    <TableRow key={'props.subcription.id'}>
                                        <TableCell align="center" width="5%" scope="row">
                                            {!body?.discountCheckout
                                                ? services.length + 1
                                                : services.length + body?.discountCheckout?.length + 1}
                                        </TableCell>
                                        <TableCell align="left" width="20%">
                                            {serviceDiscount.ServiceType.name}
                                        </TableCell>
                                        <TableCell align="left" width="30%">
                                            {serviceDiscount.name}
                                        </TableCell>
                                        <TableCell align="center" width="20%">
                                            {serviceDiscount.Fee.value >= 0
                                                ? `US$${displayUnit(serviceDiscount?.Fee)}`
                                                : `-US$${displayUnit(serviceDiscount?.Fee)}`}
                                        </TableCell>
                                        <TableCell align="center" width="5%">
                                            {serviceDiscount.quantity}
                                        </TableCell>
                                        <TableCell align="center" width="20%">
                                            {serviceDiscount.Fee.value >= 0
                                                ? `US$${serviceDiscount.Fee.value}`
                                                : `-US$${Math.abs(serviceDiscount.Fee.value)}`}
                                        </TableCell>
                                    </TableRow>
                                )}

                                {/* ANNUAL FEE */}
                                {body?.packageIncorp?.PackageAnnual && setSelectedAnnualFee && (
                                    <TableRow key={'annual_fee'}>
                                        <TableCell
                                            align="center"
                                            width="5%"
                                            scope="row"
                                        ></TableCell>
                                        <TableCell align="left" width="95%" colSpan={5}>
                                            <Box>
                                                <p className="h6 mb-3">Annual Fee</p>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedAnnualFee}
                                                                onChange={() =>
                                                                    setSelectedAnnualFee(
                                                                        !selectedAnnualFee,
                                                                    )
                                                                }
                                                                name="annual_fee"
                                                                color="secondary"
                                                            />
                                                        }
                                                        label={
                                                            <Box>
                                                                <Typography
                                                                    mr={1}
                                                                    component={'span'}
                                                                >
                                                                    {
                                                                        body?.packageIncorp
                                                                            ?.PackageAnnual?.name
                                                                    }
                                                                </Typography>
                                                                <Tooltip
                                                                    placement="top"
                                                                    title={
                                                                        <span
                                                                            style={{
                                                                                fontSize: '11px',
                                                                                wordBreak:
                                                                                    'break-word',
                                                                                whiteSpace:
                                                                                    'pre-line',
                                                                            }}
                                                                        >
                                                                            {packageAnnualFeeInfo}
                                                                        </span>
                                                                    }
                                                                >
                                                                    <HelpIcon />
                                                                </Tooltip>
                                                            </Box>
                                                        }
                                                    />
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )}

                                <TableRow key={'total'}>
                                    <TableCell colSpan={5} className="h3 text-md-center">
                                        <span style={{ position: 'sticky', left: '0' }}>Total</span>
                                    </TableCell>
                                    <TableCell align="center" className="h4 text-nowrap">
                                        US${total}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ServiceFn
                        classes={classes}
                        setServices={setServices}
                        setRecord={setRecord}
                        record={record}
                        services={services}
                        category={category}
                        servicesChange={servicesChange}
                        body={body}
                        setSelectedAnnualFee={setSelectedAnnualFee}
                    />
                    {location?.pathname === '/incorporation' &&
                        body?.country_id === 23 &&
                        [14, 15, 16].includes(body?.packageIncorp?.id) && (
                            <p className="mt-10 ml-4">
                                <b>Note: </b>
                                Tax Identification Number (TIN) is mandatory for all Belize
                                companies, regardless of whether they are included or non-included
                                entities, to submit the filing of returns and tax payments. If you
                                need further information and assistance, please do not hesitate to
                                contact our customer service team.
                            </p>
                        )}
                </div>
            </>
        );
    return <div></div>;
}

import React from 'react';
import { debounce } from 'lodash';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';

function MetronicInputField({
    form,
    name,
    disabled = false,
    readOnly = false,
    type = 'text',
    onChange,
    debounceTime = 0,
    helperText,
    hideHelperText = false,
    ...props
}) {
    const callDebounce = debounce((e) => {
        onChange(e);
    }, debounceTime);

    return (
        <Controller
            name={name}
            control={form.control}
            render={({ field, fieldState: { isDirty, invalid, isTouched, error } }) => {
                return (
                    <>
                        <input
                            className="form-control"
                            {...field}
                            onChange={(e) => {
                                field.onChange(e);
                                onChange && callDebounce(e);
                            }}
                            readOnly={readOnly}
                            disabled={disabled}
                            type={type}
                            {...props}
                        />
                        {!hideHelperText && (
                            <>
                                {helperText || (
                                    <small
                                        className={clsx('form-text text-muted', {
                                            'text-success': !error && isDirty,
                                            'text-danger': error,
                                        })}
                                    >
                                        {!error && isDirty && props.placeholder
                                            ? props.placeholder + '  was entered correct'
                                            : ''}
                                        {error ? error?.message : ''}
                                        {!error && !isDirty && helperText ? (
                                            <>
                                                Please enter <b>{props.placeholder}</b>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </small>
                                )}
                            </>
                        )}
                    </>
                );
            }}
        />
    );
}

export default MetronicInputField;

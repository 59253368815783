import React from 'react';
import CorporationForm from './CorporationForm';
import IndividualForm from './IndividualForm';
import { FormHelperText, InputAdornment } from '@mui/material';
import InputField from 'components/form-controls/InputField';
import SourceOfFund from './SourceOfFund';
import Reference from './Reference';
import { Represented } from './Represented';
import SecurityQuestion from './SecurityQuestion';
import { useSelector } from 'react-redux';

const ShareholderForm = ({
    form,
    index,
    disabled,
    countriesOptions,
    countriesCooperationOptions,
    hideSourceOfFund,
    isSVG = false,
    isPanama = false,
    isUK = false,
    onSwitchTypeMember = () => {},
    isRepresented = false,
    onCheckRepresented,
    IS_LLC,
    task,
    hasShowSelectFullName,
    allowFields,
}) => {
    const wTypeMemberId = form.watch(`members.${index}.type_member_id`);

    const { phonePrefixCountriesCooperation, phonePrefixCountries } = useSelector(
        (state) => state.enum,
    );

    return (
        <>
            {wTypeMemberId === 1 ? (
                <IndividualForm
                    form={form}
                    index={index}
                    disabled={disabled}
                    phoneCountryOptions={phonePrefixCountriesCooperation}
                    countriesCooperationOptions={countriesCooperationOptions}
                    onSwitchTypeMember={onSwitchTypeMember}
                    hasShowSelectFullName={hasShowSelectFullName}
                    allowFields={allowFields}
                />
            ) : (
                <CorporationForm
                    form={form}
                    index={index}
                    disabled={disabled}
                    phoneCountryOptions={phonePrefixCountries}
                    countriesOptions={countriesOptions}
                    onSwitchTypeMember={onSwitchTypeMember}
                    allowFields={allowFields}
                />
            )}
            {IS_LLC ? (
                <>
                    <div className="input-line">
                        <label>Percentage of ownership</label>
                        <div className="has-unit" style={{ width: '100px' }}>
                            <InputField
                                type="number"
                                name={`members.${index}.CompanyPositions.0.own_percent`}
                                form={form}
                                hideSuccessHelper
                                inputProps={{ min: 1 }}
                                disabled
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                        </div>
                        {form.formState.errors?.validateOwnPercent && (
                            <FormHelperText error={!!form.formState.errors?.validateOwnPercent}>
                                {form.formState.errors?.validateOwnPercent?.message}
                            </FormHelperText>
                        )}
                    </div>
                </>
            ) : (
                <div className="input-line" style={{ paddingTop: '16px' }}>
                    <label>Number of shares to be alloted</label>
                    <div className="has-unit" style={{ width: '100px' }}>
                        <InputField
                            type="number"
                            name={`members.${index}.CompanyPositions.0.number_allotted`}
                            form={form}
                            hideSuccessHelper
                            inputProps={{ min: 1 }}
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">Shares</InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    {form.formState.errors?.validateNumberOfSharesAllotted && (
                        <FormHelperText
                            error={!!form.formState.errors?.validateNumberOfSharesAllotted}
                        >
                            {form.formState.errors?.validateNumberOfSharesAllotted?.message}
                        </FormHelperText>
                    )}
                </div>
            )}
            {isSVG && !hideSourceOfFund && <SourceOfFund form={form} index={index} disabled />}
            {isUK && <SecurityQuestion task={task} form={form} index={index} disabled />}
            {isPanama && (
                <>
                    <Reference
                        title={'References 1:'}
                        form={form}
                        indexMember={index}
                        indexReference={0}
                        disabled={disabled}
                        countries={
                            wTypeMemberId === 1 ? countriesCooperationOptions : countriesOptions
                        }
                        allowFields={allowFields}
                    />
                    <Reference
                        title={'References 2:'}
                        form={form}
                        indexMember={index}
                        indexReference={1}
                        disabled={disabled}
                        countries={
                            wTypeMemberId === 1 ? countriesCooperationOptions : countriesOptions
                        }
                        allowFields={allowFields}
                    />
                </>
            )}
            {isRepresented && (
                <Represented
                    form={form}
                    index={index}
                    isPanama={isPanama}
                    disabled
                    // highlightFields={highlightFields}
                    onCheckRepresented={onCheckRepresented}
                />
            )}
            {form.formState.errors?.validateRepresentedShareHolder && (
                <FormHelperText error={!!form.formState.errors?.validateRepresentedShareHolder}>
                    {form.formState.errors?.validateRepresentedShareHolder?.message}
                </FormHelperText>
            )}
        </>
    );
};

export default ShareholderForm;

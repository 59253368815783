import { Typography } from '@mui/material';
import { sumBy } from 'lodash';
import numeral from 'numeral';
import React, { useMemo } from 'react';
import SelectField from 'components/form-controls/SelectField';

const MemberTransferRemoveForm = ({
    form,
    member,
    isShareholder,
    isUBO,
    IS_LLC,
    memberOptions = [],
    disabled,
    shareOrigin,
}) => {
    const numOfShares = useMemo(() => {
        if (!member) return 0;
        return (
            shareOrigin &&
            sumBy(shareOrigin, (share) =>
                !share.corporation_company_member_id && share.is_valid && share.is_approved
                    ? share.value ?? 0
                    : 0,
            )
        );
    }, [member]);

    const title = useMemo(() => {
        if (isShareholder) {
            return IS_LLC ? 'Percent of shares' : 'Number of shares';
        }
        if (isUBO) {
            return 'Percent of ownership';
        }
    }, [member]);

    return (
        <div className="p-10" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
            <Typography variant="h6" className="text-center mb-5">
                You need to transfer the {title.toLowerCase()} before deleting.
            </Typography>
            <div className="m-5 p-5 border rounded">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4">Transferor</div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                        {member?.full_name || member?.corporation_name}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4">{title}</div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                        {numeral(numOfShares).format()}
                        {isUBO || (isShareholder && IS_LLC) ? '%' : ''}
                    </div>
                </div>
            </div>
            <div className="m-5 p-5 border rounded">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4">Transferee</div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8 kyc-frm-control">
                        <SelectField
                            form={form}
                            sx={{ maxWidth: 250, width: '100%' }}
                            label="Transferee"
                            name="company_member_id"
                            options={memberOptions}
                            getOptionLabel={(option) =>
                                option?.full_name || option?.corporation_name || ''
                            }
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemberTransferRemoveForm;


import React from 'react';
import InputField from 'components/form-controls/InputField';
import { InputAdornment } from '@mui/material';

export default function OutFlow({ form }) {
    return (
        <div>
            <div className="mb-7 input-line">
                <label>Amount:</label>
                <InputField
                    type="number"
                    form={form}
                    name="outflow_amount"
                    placeholder="Number"
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">USD</InputAdornment>)
                    }}
                    hideSuccessHelper
                />
            </div>
            <div className="mb-7 input-line">
                <label>Frequency</label>
                <InputField
                    type="number"
                    form={form}
                    name="outflow_frequency"
                    placeholder="Number"
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">TRANSACTION(S)</InputAdornment>)
                    }}
                    hideSuccessHelper
                />
            </div>
            <div className="mb-7 input-line">
                <label>Destination of funds</label>
                <InputField
                    form={form}
                    name="outflow_destination_fund"
                    placeholder="Please provide details"
                    showIcon
                    hideSuccessHelper
                />
            </div>
            <div className="mb-7 input-line">
                <label>Mode of transaction</label>
                <InputField
                    form={form}
                    name="outflow_mode"
                    placeholder="Please provide details i.e. cash, bank transfer, etc"
                    showIcon
                    hideSuccessHelper
                />
            </div>
            <div className="mb-7 input-line">
                <label>Purpose</label>
                <InputField
                    form={form}
                    name="outflow_purpose"
                    placeholder="Please provide details"
                    showIcon
                    hideSuccessHelper
                />
            </div>
        </div>
    )
}

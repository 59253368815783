import axios from 'axios';

const API_ENDPOINT_FILE = '/file';

// === country has service
// export function uploadImage(files) {
//     return axios.post(API_ENDPOINT_FILE, files);
// }

// Download file
// === queryPamras : path file
export function downloadFile(queryParams) {
    axios.defaults.responseType = 'blob';
    return axios.get(`${API_ENDPOINT_FILE}`, { params: { path: queryParams } });
}

// send file to client
export const sendFilesToCustomer = (queryParams) => {
    return axios.post(`/customer_account/company/sent_company_document`, queryParams)
}

// Download file s3
// === queryPamras : path file
export function downloadFileS3(queryParams) {
    return axios.get(`${API_ENDPOINT_FILE}/signed-url-s3/customer/view`, { params: { filename_s3: queryParams } });
}

export function viewAvatarS3(queryParams) {
    return axios.get(`${API_ENDPOINT_FILE}/signed-url-s3/view-avatar`, { params: { filename_s3: queryParams } });
}

// Download file s3
// === queryPamras : path file
export function getSignedUrlS3(queryParams) {
    return axios.put(`${API_ENDPOINT_FILE}/signed-url-s3/customer/put`, { files: queryParams });
}

// Download file s3
// === queryPamras : path file
export function createFileDbS3(body) {
    return axios.post(`${API_ENDPOINT_FILE}/customer/create`, body);
}

// uploadFileMultiple
// === queryPamras : path file
export function uploadFiles(files, queryParams) {
    const formData = new FormData();
    for (var index = 0; index < files.length; index++) {
        formData.append('file', files[index]);
    }
    if (queryParams['company_id']) formData.append('company_id', queryParams['company_id']);
    if (queryParams['message_id']) formData.append('message_id', queryParams['message_id']);
    return axios.post(`${API_ENDPOINT_FILE}/multiple`, formData);
}

// delete file.
export function deleteFile(id) {
    return axios.delete(`${API_ENDPOINT_FILE}/customer/${id}`);
}

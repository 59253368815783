import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { requestPassword } from '../_redux/authCrud';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import InputField from 'components/form-controls/InputField';
import './ForgotPassword.scss';

const initialValues = {
    username: '',
};

function ForgotPassword(props) {
    const [loading, setLoading] = useState(false);
    const [isHasError, setIsHasError] = useState(false);
    const ggCaptchaEl = useRef(null);
    const navigate = useNavigate();
    const ForgotPasswordSchema = Yup.object().shape({
        username: Yup.string()
            .email('Wrong email format')
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Username is required'),
    });

    const form = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(ForgotPasswordSchema),
    });

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            const token = await ggCaptchaEl.current.executeRecaptcha();
            const response = await requestPassword(values.username?.trim(), token);
            if (response.data.state) {
                navigate('/auth/resent-email', {
                    state: {
                        email: values.username?.trim(),
                    },
                });
            }
        } catch (error) {
            setIsHasError(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://dashboard.bbcincorp.com/" />
            </Helmet>
            <div className="login-form login-forgot" style={{ display: 'block' }}>
                <img
                    src={'/media/logos/logo-new.png'}
                    width={177}
                    height={49}
                    className="logo"
                    alt="BBCIncorp"
                />
                <div>
                    <h3 className="title d-inline-block">Forgotten Password?</h3>
                </div>
                <div>
                    <p className="description d-inline-block">
                        Enter your username to reset your password
                    </p>
                </div>
                <form
                    onSubmit={form.handleSubmit(handleSubmit)}
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                >
                    {isHasError && (
                        <div
                            style={{
                                padding: '1.5rem 2rem',
                                backgroundColor: '#ffe2e5',
                                border: '1px solid transparent',
                                borderRadius: '0.42rem',
                                marginBottom: '2.5rem',
                            }}
                        >
                            <div
                                style={{
                                    color: '#f64e60',
                                    alignSelf: 'center',
                                    flexGrow: 1,
                                    fontWeight: 500,
                                }}
                            >
                                The requested {form.watch('username')} is not found
                            </div>
                        </div>
                    )}
                    <div className="fv-plugins-icon-container">
                        <label>User name</label>
                        <InputField
                            name="username"
                            form={form}
                            placeholder="Enter your user name"
                            hideHelperText
                            customStyle={{
                                '& .MuiInputBase-input': {
                                    padding: '12px 16px',
                                    border: '1px solid #BDC3CA',
                                    borderRadius: '4px',
                                    color: '#0F172A',
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    fontWeight: '400',
                                    height: '24px',
                                    letterSpacing: '-0.35px'
                                },
                                '& .MuiInputBase-input::-webkit-input-placeholder': {
                                    color: '#94A3B8',
                                },
                                '& .MuiInputBase-input:focus': {
                                    border: '1px solid #BDC3CA !important',
                                },
                                '& .MuiInputBase-input:hover': {
                                    border: '1px solid #BDC3CA !important',
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottom: 'none !important',
                                },
                                '& .MuiInput-root:before': {
                                    borderBottom: 'none !important',
                                },
                                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottom: 'none !important',
                                },
                            }}
                            onChange={(e) => {
                                debounce(() => {
                                    form.setValue('username', e.target.value.replace(/\s/g, ''));
                                }, 500)();
                            }}
                        />
                        {form.formState.errors.username && (
                            <p
                                style={{
                                    fontSize: '0.9rem',
                                    color: '#f64e60',
                                    marginTop: '0.25px',
                                }}
                            >
                                {form.formState.errors.username.message}
                            </p>
                        )}
                    </div>
                    <div className="d-flex flex-wrap flex-center group-button">
                        <button
                            type="button"
                            id="kt_login_forgot_cancel"
                            className="btn btn-cancel"
                            onClick={() => navigate('/auth')}
                        >
                            Back
                        </button>
                        <button
                            id="kt_login_forgot_submit"
                            type="submit"
                            className="btn btn-submit"
                            disabled={loading}
                        >
                            Submit
                        </button>
                    </div>
                    <div>
                        <GoogleReCaptchaProvider
                            ref={ggCaptchaEl}
                            reCaptchaKey={process.env.REACT_APP_RECAPTCHA}
                            language="en"
                            name="recaptcha-token"
                            scriptProps={{
                                async: false, // optional, default to false,
                                defer: false, // optional, default to false
                                appendTo: 'head', // optional, default to "head", can be "head" or "body",
                                nonce: undefined, // optional, default undefined
                            }}
                        >
                            {' '}
                        </GoogleReCaptchaProvider>
                    </div>
                </form>
            </div>
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));

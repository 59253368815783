/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useDispatch, shallowEqual, useSelector, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as actions from '../../../../../../app/pages/_redux/notification/notificationActions';
import * as actionsSupport from '../../../../../../app/pages/_redux/supportCase/supportActions';
import * as actionsAccount from '../../../../../../app/pages/_redux/personal/personalActions';
import * as actionsInvoices from '../../../../../../app/pages/_redux/invoices/invoicesActions';
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../../../_core/MetronicLayout';
import { toAbsoluteUrl } from '../../../../../_helpers';
import { DropdownTopbarItemToggler } from '../../../../../_partials/dropdowns';
import socket from '_metronic/_helpers/Socket';
import io from 'socket.io-client';
import * as _ from 'lodash';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// import {NotificationScrollList} from './partical/notificationScrollList';
// import sound from '/public/media/sounds/sound-notification.ogg';
import './user-notification.scss';
import { Howl } from 'howler';
const sound = new Howl({
    src: ['/media/sounds/sound-notification.mp3'],
});

let perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
};

const initialFilterInvoices = {
    filter: {
        search: '',
    },
    sortOrder: 'desc', // asc||desc
    sortField: 'id',
    pageNumber: 1,
    pageSize: 10,
};
// let initialFilterInvoice = {
//     filter: {
//         search: '11091',
//         fields: []
//     },
//     sortOrder: 'desc',
//     sortField: 'id',
//     pageNumber: 1,
//     pageSize: 10,
// };

export function UserNotification() {
    const navigate = useNavigate();
    const [count, setCount] = useState(0);
    const [playSound, setPlaySound] = useState();
    let { current: soundRef } = useRef(null);
    let { current: socketRef } = useRef(null);

    const [scrollTopNews, setScrollTopNews] = useState({
        previous: 0,
        current: 0,
    });
    const [scrollTopMessage, setScrollTopMessage] = useState({
        previous: 0,
        current: 0,
    });

    const [news, setNews] = useState({
        queryParams: {
            pageNumber: 1,
            pageSize: 10,
            filter: { notification_type: 'news' },
        },
        data: null,
        count: 0,
        isDataLoad: true,
        loadMore: false,
    });

    const [message, setMessage] = useState({
        queryParams: {
            pageNumber: 1,
            pageSize: 10,
            filter: { notification_type: 'message' },
        },
        data: null,
        count: 0,
        isDataLoad: true,
        loadMore: false,
    });

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.fetchNotifications(news.queryParams, 'news')).then((response) => {
            if (news.data) {
                if (response && response.length > 0) {
                    if (response.length < 10) {
                        setNews({ ...news, isDataLoad: false, data: news.data.concat(response) });
                    } else {
                        setNews({ ...news, data: news.data.concat(response) });
                    }
                }
            } else if (response && response.length > 0) {
                if (response.length < 10) {
                    setNews({ ...news, isDataLoad: false, data: response });
                } else {
                    setNews({ ...news, data: response });
                }
            }
        });
        dispatch(actions.fetchNotifications(message.queryParams, 'message')).then((response) => {
            if (message.data) {
                if (response && response.length > 0) {
                    if (response.length < 10) {
                        setMessage({
                            ...message,
                            isDataLoad: false,
                            data: message.data.concat(response),
                        });
                    } else {
                        setMessage({ ...message, data: message.data.concat(response) });
                    }
                }
            } else {
                if (response && response.length < 10) {
                    setMessage({ ...message, isDataLoad: false, data: response });
                } else {
                    setMessage({ ...message, data: response });
                }
            }
        });
        dispatch(actions.countNotificationsNew());
    }, []);

    const { countNewNotifications } = useSelector(
        (state) => ({
            countNewNotifications: state.notifications.totalNewCount,
        }),
        shallowEqual,
    );

    useEffect(() => {
        const notificationCountArr = countNewNotifications?.filter((notification) => {
            return notification.type !== 'others';
        }); // notification count total

        if (countNewNotifications) {
            setCount(
                notificationCountArr?.reduce((prevCount, curCount) => {
                    return prevCount + curCount.count;
                }, 0),
            );
        }
    }, [countNewNotifications]);

    const [key, setKey] = useState('news');
    const bgImage = toAbsoluteUrl('/media/misc/bg-1.jpg');

    const uiService = useHtmlClassService();
    const [loadNotification, setLoadNotification] = useState(false);
    const store = useStore();
    const {
        auth: { authToken },
    } = store.getState();

    const layoutProps = useMemo(
        (props) => {
            return {
                offcanvas:
                    objectPath.get(uiService.config, 'extras.notifications.layout') === 'offcanvas',
            };
        },
        [uiService],
    );

    // const audioSrc = '/media/sounds/sound-notification.ogg';
    // const notificationSound = new Audio('/media/sounds/sound-notification.mp3');
    // function playAudio() {
    //     notificationSound.play();
    //     setPlaySound(false);
    // }

    // const [prevSocket, setPrevSocket] = useState(0);

    const [prevSocket, setPrevSocket] = useState({ id: 0, notification: null });
    useEffect(() => {
        socketRef = io(process.env.REACT_APP_SOCKET_URL, {
            transports: ['websocket', 'polling'],
            upgrade: false,
        });

        socketRef.on('connect', () => {
            socketRef.emit('join', {
                authorization: authToken.access_token,
                deviceInfor: navigator.userAgent,
            });
        });

        socketRef.on('received-notification', (data) => {
            if (data) {
                if (prevSocket.id !== data.notification.id) {
                    data.notification = { ...data.notification, ReadNotis: [{ is_read: 0 }] };
                    setPrevSocket({
                        ...prevSocket,
                        id: data.notification.id,
                        notification: data.notification,
                    });
                }
            }
            sound.play();
        });

        // return () => {}
    }, []);

    // has socket effect
    useEffect(() => {
        if (prevSocket.notification) {
            if (prevSocket.notification.type_noty === 'news' && news) {
                if (news.data === null) {
                    setNews({ ...news, isDataLoad: false, data: [prevSocket.notification] });
                } else
                    setNews({
                        ...news,
                        isDataLoad: false,
                        data: [prevSocket.notification].concat(news.data),
                    });
            } else if (prevSocket.notification.type_noty === 'message' && message) {
                if (message.data === null) {
                    setMessage({ ...message, isDataLoad: false, data: [prevSocket.notification] });
                } else
                    setMessage({
                        ...message,
                        isDataLoad: false,
                        data: [prevSocket.notification].concat(message.data),
                    });
            }

            if (count >= 0) setCount(count + 1);

            // if (playSound) {
            //     playAudio();
            // }
            sound.play();
        }
    }, [prevSocket.id]);

    const notification = (notification) => {
        return notification.map((data, index) => {
            let fontWeight = '',
                iconColor = '';
            let isRead = data.ReadNotis[0].is_read;

            if (data.support_case_id) {
                data = {
                    ...data,
                    description: data.description + ' #' + data.support_case_id,
                    notification_type: 'message',
                    linkto: '/account/support-case/' + data.support_case_id,
                };
            } else if (data.order_id) {
                data = { ...data, notification_type: 'news', linkto: '/company/invoices' };
            } else if (data.profile_id) {
                data = { ...data, notification_type: 'news', linkto: '/account/profile' };
            }

            if (
                data?.order_id &&
                data?.process_step_id &&
                (data?.service_type_id === 24 || data?.service_type_id === 39)
            ) {
                // 24: CHANGE-NAME || 39: CHANGE-OFFICER | CHANGE-NAME
                data = {
                    ...data,
                    notification_type: 'news',
                    linkto: `/order-management/order/${data?.order_id}/additional-service?service_type_id=${data?.service_type_id}`,
                };
            }

            if (data?.code === 'ANNUAL_COMPLIANCE_CALENDAR') {
                data = {
                    ...data,
                    notification_type: 'news',
                    linkto: `/company/companies/${data?.company_id}/compliance-calendar`,
                };
            }

            if (isRead === 0) {
                fontWeight = 'font-weight-bold';
                iconColor = 'text-primary';
            } else if (isRead === 1) {
                fontWeight = 'font-weight-light';
                iconColor = 'text-muted';
            }

            return (
                <a
                    key={index}
                    className={'navi-item '}
                    onClick={(e) => {
                        readNotification(e, data);
                    }}
                >
                    <div className="overlay"></div>
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className={'flaticon2-notification ' + iconColor}></i>
                        </div>
                        <div className="navi-text">
                            <div className={'desc ' + fontWeight}>{data.description}</div>
                            <div className="text-muted">{data.created_time}</div>
                        </div>
                    </div>
                </a>
            );
        });
    };

    // refresh page data when click new notification
    const initRefresh = {
        is_refresh: false,
        support_case_id: null,
        order_id: null,
        profile_id: null,
    };
    const [refresh, setRefresh] = useState(initRefresh);
    useEffect(() => {
        if (refresh.is_refresh) {
            if (refresh.support_case_id) {
                dispatch(actionsSupport.fetchMessage(refresh.support_case_id)).then(() => {
                    setRefresh(initRefresh);
                });
            } else if (refresh.order_id) {
                dispatch(actionsInvoices.fetchInvoices(initialFilterInvoices)).then(() => {
                    setRefresh(initRefresh);
                });
            } else if (refresh.profile_id) {
                dispatch(actionsAccount.fetchPersonal()).then(() => {
                    setRefresh(initRefresh);
                });
            }
        }
    }, [refresh]);

    // read notification action
    function readNotification(e, data) {
        let currPath = window.location.pathname;

        if (data.ReadNotis[0].is_read === 0) {
            dispatch(actions.readNotification(data.id)).then(() => {
                switch (data.notification_type) {
                    case 'news':
                        if (news && news.data.length)
                            news.data.map((item, index) => {
                                if (item.id === data.id) {
                                    let updatedData = [...news.data];
                                    updatedData[index] = {
                                        ...item,
                                        ReadNotis: { ...item.ReadNotis, [0]: { is_read: 1 } },
                                    };
                                    let currnews = { ...news, data: updatedData };
                                    setNews(currnews);
                                }
                            });

                        if (currPath === data.linkto) {
                            if (data.order_id) {
                                setRefresh({
                                    ...refresh,
                                    is_refresh: true,
                                    order_id: data.order_id,
                                });
                            } else if (data.profile_id) {
                                setRefresh({
                                    ...refresh,
                                    is_refresh: true,
                                    profile_id: data.profile_id,
                                });
                            }
                        }
                        break;
                    case 'message':
                        if (message && message.data.length)
                            message.data.map((item, index) => {
                                if (item.id === data.id) {
                                    let updatedData = [...message.data];
                                    updatedData[index] = {
                                        ...item,
                                        ReadNotis: { ...item.ReadNotis, [0]: { is_read: 1 } },
                                    };
                                    let currMessage = { ...message, data: updatedData };
                                    setMessage(currMessage);
                                }
                            });
                        if (currPath === data.linkto) {
                            setRefresh({
                                ...refresh,
                                is_refresh: true,
                                support_case_id: data.support_case_id,
                            });
                        }
                        break;
                }
                if (count >= 0) setCount(count - 1);
                // dispatch(actions.countNotificationsNew());
                navigate(data.linkto);
            });
        } else {
            navigate(data.linkto);
        }
    }

    const loadMoreNews = (e) => {
        if (e.scrollTop > scrollTopNews.current) {
            setScrollTopNews({ ...scrollTopNews, current: e.scrollTop });
            const query = { ...news.queryParams, pageNumber: news.queryParams.pageNumber + 1 };
            dispatch(actions.fetchNotifications(query, 'news')).then((response) => {
                if (response && response.length > 0) {
                    if (response.length < 10) {
                        setNews({
                            ...news,
                            isDataLoad: false,
                            data: news.data.concat(response),
                            queryParams: query,
                        });
                    } else {
                        setNews({ ...news, data: news.data.concat(response), queryParams: query });
                    }
                }
            });
        }
    };
    const loadMoreMessage = (e) => {
        if (e.scrollTop > scrollTopMessage.current) {
            setScrollTopMessage({ ...scrollTopMessage, current: e.scrollTop });
            const query = {
                ...message.queryParams,
                pageNumber: message.queryParams.pageNumber + 1,
            };
            dispatch(actions.fetchNotifications(query, 'message')).then((response) => {
                if (response && response.length > 0) {
                    if (response.length < 10) {
                        setMessage({
                            ...message,
                            isDataLoad: false,
                            data: message.data.concat(response),
                            queryParams: query,
                        });
                    } else {
                        setMessage({
                            ...message,
                            data: message.data.concat(response),
                            queryParams: query,
                        });
                    }
                }
            });
        }
    };

    return (
        <>
            {/* <div>
                <audio>
                    <source src="/media/sounds/sound-notification.ogg" />
                </audio>

            </div> */}

            {layoutProps.offcanvas && (
                <div className="topbar-item">
                    <div
                        className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary badge"
                        id="kt_quick_notifications_toggle"
                    >
                        <span className="svg-icon svg-icon-xl svg-icon-primary">
                            <NotificationsNoneIcon />
                        </span>
                        <span className="pulse-ring"></span>
                    </div>
                </div>
            )}
            {!layoutProps.offcanvas && (
                <Dropdown drop="down" alignRight>
                    <Dropdown.Toggle
                        as={DropdownTopbarItemToggler}
                        id="kt_quick_notifications_toggle"
                    >
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="user-notification-tooltip">User Notifications</Tooltip>
                            }
                        >
                            <div
                                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                                id="kt_quick_notifications_toggle"
                            >
                                <span className="svg-icon svg-icon-xl svg-icon-primary position-relative">
                                    <NotificationsNoneIcon color="secondary" />
                                    <span className="notification-count">
                                        {count > 99 ? <span>99+</span> : count}
                                    </span>
                                </span>
                                <span className="pulse-ring"></span>
                                <span className="pulse-ring" />
                            </div>
                        </OverlayTrigger>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
                        <form>
                            {/** Head */}
                            <div
                                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                                style={{ backgroundImage: `url(${bgImage})` }}
                            >
                                <h4 className="d-flex flex-center rounded-top">
                                    <span className="text-white">User Notifications</span>
                                    <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                                        {count} new
                                    </span>
                                </h4>

                                <Tab.Container defaultActiveKey={key}>
                                    <Nav
                                        as="ul"
                                        className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                                        onSelect={(_key) => setKey(_key)}
                                    >
                                        <Nav.Item as="li">
                                            <Nav.Link
                                                eventKey="news"
                                                className={`nav-link show ${
                                                    key === 'news' ? 'active' : ''
                                                }`}
                                            >
                                                News
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="nav-item" as="li">
                                            <Nav.Link
                                                eventKey="message"
                                                className={`nav-link show ${
                                                    key === 'message' ? 'active' : ''
                                                }`}
                                            >
                                                Message
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>

                                    <Tab.Content className="tab-content">
                                        <Tab.Pane eventKey="news" id="topbar_notifications_events">
                                            {news && news.data ? (
                                                <PerfectScrollbar
                                                    options={perfectScrollbarOptions}
                                                    className="navi navi-hover scroll my-4"
                                                    style={{
                                                        maxHeight: '300px',
                                                        position: 'relative',
                                                    }}
                                                    onYReachEnd={loadMoreNews}
                                                >
                                                    {notification(news.data)}
                                                </PerfectScrollbar>
                                            ) : (
                                                <div className="d-flex flex-center text-center text-muted min-h-200px">
                                                    No notifications.
                                                </div>
                                            )}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="message" id="topbar_notifications_logs">
                                            {message && message.data ? (
                                                <PerfectScrollbar
                                                    options={perfectScrollbarOptions}
                                                    className="navi navi-hover scroll my-4"
                                                    style={{
                                                        maxHeight: '300px',
                                                        position: 'relative',
                                                    }}
                                                    onYReachEnd={loadMoreMessage}
                                                >
                                                    {notification(message.data)}
                                                </PerfectScrollbar>
                                            ) : (
                                                <div className="d-flex flex-center text-center text-muted min-h-200px">
                                                    No notifications.
                                                </div>
                                            )}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </form>
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </>
    );
}

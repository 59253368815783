import React, { useEffect, useRef, useState } from 'react';
import * as actionsIncorporation from 'app/pages/_redux/incorporation/incorporationActions';
import DateField from 'components/form-controls/DateField';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export function CompanyForm({ form, body, setBody, formikRef }) {
    const [country, setCountry] = useState({ name: '', id: 0 });
    const [initForm, setInitForm] = useState({
        company_name: '',
        country_id: '',
        // entity_type: '',
        incorporation_date: '',
        company_reg_no: '',

        entity_type_id: '',
        company_suffix_id: '',
    });
    const dispatch = useDispatch();
    const [companyType, setCompanyType] = useState('');
    const [companySuffix, setCompanySuffix] = useState('');
    const [countryInfo, setCountryInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const isGetInfoCountry = true;
    const backToStep1 = useRef(false);

    const { countriesHasService } = useSelector(
        (state) => ({
            countriesHasService: state.enum.countriesHasService,
        }),
        shallowEqual,
    );

    useEffect(() => {
        if (body.company_name && body.country_id) {
            const companyInfo = {
                company_name: body.company_name,
                country_id: body.country_id,
                entity_type: body.entity_type,
                incorporation_date: body.incorporation_date,
                company_reg_no: body.company_reg_no,
            };
            form.reset(companyInfo);
            setInitForm(companyInfo);
        }
        if (countriesHasService && body.country_id) {
            var countryReturn = countriesHasService.find((e) => e.id === body.country_id);
            if (countryReturn) {
                setCountry(countryReturn);
            }
        }
    }, []);

    useEffect(() => {
        setBody({
            ...body,
            ...initForm,
        });
    }, [initForm]);

    /* Xử lý set company suffixes cho */
    useEffect(() => {
        if (!companyType) return;
        if (!backToStep1.current) {
            setCompanySuffix(companyType.CompanySuffixes[0]);
        }
        form.setValue('company_suffix_id', companyType.CompanySuffixes[0].id);
    }, [companyType]);

    useEffect(() => {
        if (country && country.id && country.id !== body.country_id) {
            setIsLoading(true);
            dispatch(
                actionsIncorporation.fetchAllAncillaryServiceOutside({
                    params: {
                        country_id: country.id,
                    },
                }),
            ).then((res) => {
                setIsLoading(false);

                setBody({
                    ...body,
                    services: [],
                    packageIncorp: {
                        ...res,
                        AdditionalServices: res.Services,
                    },
                });

                setCountryInfo(res);

                const entityTypeId = res.EntityTypes[0].id;
                const companySuffixId = res.EntityTypes[0].CompanySuffixes[0].id;

                form.setValue('entity_type_id', entityTypeId);
                form.setValue('company_suffix_id', companySuffixId);

                const selectedCompanyType = res.EntityTypes.find((item) => item.id == entityTypeId);

                setCompanyType(selectedCompanyType);
                setCompanySuffix(
                    selectedCompanyType.CompanySuffixes.find((item) => item.id == companySuffixId),
                );
            });
        }
    }, [country]);

    useEffect(() => {
        const entityTypeId = body?.entity_type_id;
        const entityTypes = body?.packageIncorp?.EntityTypes;
        const companySuffixId = body?.company_suffix_id;
        const companySuffixes = body?.packageIncorp?.CompanySuffixes;

        if (companySuffixId && companySuffixes) {
            const selectedCompanySuffix = companySuffixes.find((item) => item.id == companySuffixId);
            setCompanySuffix(selectedCompanySuffix);
            setCompanyType({ ...companyType, CompanySuffixes: companySuffixes })
            backToStep1.current = true;
        }

        if (entityTypeId && entityTypes) {
            const selectedCompanyType = entityTypes.find((item) => item.id == entityTypeId);
            setCompanyType(selectedCompanyType);
            setCountryInfo({ ...countryInfo, EntityTypes: entityTypes })
            backToStep1.current = true;
        }
    }, [])

    function getInfoByCountry(value) {
        if (value && value.id) {
            setCountry(value);
            // if (countriesHasService !== null) {
            //     // dispatch(actionsIncorporation.fetchCompanyInfo(value.id)).then((data) => {
            //     //     setCountry(value);
            //     // });

            // }
        }
    }

    const handleSubmit = (values) => {
        // setInitForm({
        //     ...form.getValues(),
        // });
        // setBody({
        //     ...body,
        //     ...values,
        // });
    };

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <>
                <div className="row align-items-center mt-5 mb-5">
                    <div className="col-md-6 mb-7 input-line">
                        <label>Select Incorporation Country</label>
                        <SelectField
                            form={form}
                            className="fw-500"
                            name={'country_id'}
                            id={'country_id'}
                            placeholder={'Incorporation Country'}
                            options={countriesHasService}
                            value={country}
                            isOptionSelected={(option) =>
                                country && option && country.id === option.id ? true : false
                            }
                            getOptionLabel={(option) =>
                                `${option.name && option.name.length > 0 ? option.name : ''}`
                            }
                            onChange={(e) => {
                                if (isGetInfoCountry) {
                                    form.setValue('country_id', e.id);
                                    getInfoByCountry(e);
                                }
                                form.setValue('country_id', e.id);
                                setCountry(e);
                            }}
                            helperText={true}
                        />
                    </div>
                    <div className="col-md-6 mb-7 input-line">
                        <label>Company Type</label>
                        <SelectField
                            form={form}
                            className="fw-500"
                            name={'entity_type_id'}
                            id={'entity_type_id'}
                            placeholder={'Company Type'}
                            options={countryInfo?.EntityTypes || []}
                            value={companyType || null}
                            isOptionSelected={(option) => companyType?.id === option?.id}
                            getOptionLabel={(option) => option.name}
                            onChange={(value) => {
                                if (companyType?.id != value.id) {
                                    setCompanyType(value);
                                    setBody({
                                        ...body,
                                        entity_type_id: value,
                                    })
                                    backToStep1.current = false;
                                }
                            }}
                            helperText={true}
                        />
                    </div>
                    <div className="col-md-6 mb-7 input-line">
                        <label style={{ marginBottom: '2px' }}>Enter Company Name</label>
                        <InputField
                            form={form}
                            name={'company_name'}
                            placeholder={'Company Name'}
                            helperText={true}
                            onKeyUp={(e) => {
                                setBody({
                                    ...body,
                                    company_name: e.target.value
                                });
                            }}
                            onBlur={(e) => {
                                setBody({
                                    ...body,
                                    company_name: e.target.value
                                });
                            }}
                        />
                    </div>
                    <div className="col-md-6 mb-7 input-line">
                        <label>
                            {companyType?.CompanySuffixes?.is_prefix
                                ? 'Company Prefixes'
                                : 'Company Suffixes'}
                        </label>
                        <SelectField
                            form={form}
                            className="fw-500"
                            name={'company_suffix_id'}
                            id={'company_suffix_id'}
                            placeholder={
                                companyType?.CompanySuffixes?.is_prefix
                                    ? 'Company Prefixes'
                                    : 'Company Suffixes'
                            }
                            options={companyType?.CompanySuffixes || []}
                            value={companySuffix || null}
                            isOptionSelected={(option) => companyType?.id === option?.id}
                            getOptionLabel={(option) => option.name}
                            onChange={(value) => {
                                setCompanySuffix(value);
                            }}
                            helperText={true}
                        />
                    </div>
                    <div className="col-sm-6 col-md-36 mb-7">
                        <div className="has-icon input-line">
                            <label style={{ marginBottom: '7px' }}>Incorporation Date</label>
                            <DateField
                                name={'incorporation_date'}
                                form={form}
                                label={''}
                                iconPosition={'start'}
                                customStyle={{
                                    '& .MuiInputBase-input': {
                                        padding: '.65rem 1rem .65rem 0',
                                    },
                                    '.MuiInputBase-root.Mui-error:after': {
                                        borderBottomColor: '#f64e60',
                                    },
                                    '&.Mui-success .MuiInputBase-root:after': {
                                        transform: 'scaleX(1) translateX(0)',
                                    },
                                }}
                                helperText={'Incorporation Date'}
                                placeholder={'Incorporation Date'}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-7 input-line">
                        <label style={{ marginBottom: '2px' }}>Enter Company Reg. No.</label>
                        <InputField
                            form={form}
                            name={'company_reg_no'}
                            placeholder={'Company Reg. No.'}
                            helperText={true}
                            onKeyUp={(e) => {
                                setBody({
                                    ...body,
                                    company_reg_no: e.target.value
                                });
                            }}
                            onBlur={(e) => {
                                setBody({
                                    ...body,
                                    company_reg_no: e.target.value
                                });
                            }}
                        />
                    </div>
                </div>
            </>
        </form>
    );
}

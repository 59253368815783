import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Button } from '@mui/material';
import * as fromAsyncThunk from 'app/pages/_redux/banking/asyncThunk';
import { getBankingProcessTreeAction } from 'app/pages/_redux/banking/asyncThunk';
import { updateActiveProcess } from 'app/pages/_redux/banking/slice';
import { toast } from 'react-toastify';
import PageLoadingEffect from '../../../common/PageLoadingEffect';
import BankAccount from './step4/bank_account';
import Clients from './step4/clients';
import Currency from './step4/currency';
import { BankAccountModel, NomineeModel, OnlyTextModel, RankingModel } from './step4/data.model';
import ExpectedTurnover from './step4/expected_turnover';
import FinancialForecast from './step4/financial_forecast';
import Suppliers from './step4/suppliers';
import TransactionsFrequency from './step4/transactions_frequency';
import UseNominee from './step4/use_nominee';

import { yupResolver } from '@hookform/resolvers/yup';
import InputField from 'components/form-controls/InputField';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { fetchOrderForm } from 'app/pages/_redux/order-form/orderFormActions';
import { COUNTRIES_NOT_DISABLED_BANKING_FORM } from '../../../constant';

const initFormDefault = {
    url: '',
    currency: [],

    bank_account: '',
    bank_account_other: '',

    use_nominee: '93',
    use_nominee_reason: null,

    financial_initial_deposit: '',
    financial_source_fund: '',
    financial_average_balance: '',

    expected_year1:  '',
    expected_year2: '',
    expected_year3:  '',

    inflow_amount: '',
    inflow_frequency: '',
    inflow_source_fund: '',
    inflow_mode: '',
    inflow_purpose: '',

    outflow_amount: '',
    outflow_frequency: '',
    outflow_destination_fund: '',
    outflow_mode: '',
    outflow_purpose: '',

    partner: null,

    suplier1: null,
    suplier2: null,
    suplier3: null,

    client1: null,
    client2: null,
    client3: null,
}

const validationInterger = function () {
    return this.test(function (value) {
        if((value + '').split('.').length >= 2){
            return this.createError({ path: this.path, message: 'Number is integer!' })
        }
        return true
    })
}
const transformNumber = function () {
    return this.transform(function (value) {
        return isNaN(value) ? undefined : value;
    })
}

Yup.addMethod(Yup.mixed, 'validateInterger', validationInterger)
Yup.addMethod(Yup.mixed, 'transformNumber', transformNumber)

const formValidationSchema = Yup.object().shape({
    url: Yup.string().nullable(),
    currency: Yup.array().nullable().min(1, 'Currency is required!'),

    bank_account: Yup.string().nullable(),
    bank_account_other: Yup.string().nullable().when('bank_account', {
        is: '91',
        then: Yup.string().nullable().required('Bank account other is required!')
    }),

    use_nominee: Yup.string().nullable(),
    use_nominee_reason: Yup.string().nullable().when('use_nominee', {
        is: '92',
        then: Yup.string().nullable().required('Reason is required!')
    }),

    financial_initial_deposit: Yup.number().transformNumber().validateInterger().moreThan(0,'Value must be greater than 0').required('Initial deposit is required!'),

    financial_source_fund: Yup.string().nullable().required('Source of fund is required!'),
    financial_average_balance: Yup.number().transformNumber().validateInterger().moreThan(0,'Value must be greater than 0').required('Average balance is required!'),

    expected_year1: Yup.number().transformNumber().validateInterger().moreThan(0,'Value must be greater than 0').required('This field is required!'),
    expected_year2: Yup.number().transformNumber().validateInterger().moreThan(0,'Value must be greater than 0').required('This field is required!'),
    expected_year3: Yup.number().transformNumber().validateInterger().moreThan(0,'Value must be greater than 0').required('This field is required!'),

    inflow_amount: Yup.number().transformNumber().validateInterger().moreThan(0,'Value must be greater than 0').required('This field is required!'),
    inflow_frequency: Yup.number().transformNumber().validateInterger().moreThan(0,'Value must be greater than 0').required('This field is required!'),
    inflow_source_fund: Yup.string().nullable().required('This field is required!'),
    inflow_mode: Yup.string().nullable().required('This field is required!'),
    inflow_purpose: Yup.string().nullable().required('This field is required!'),

    outflow_amount: Yup.number().transformNumber().validateInterger().moreThan(0,'Value must be greater than 0').required('This field is required!'),
    outflow_frequency: Yup.number().transformNumber().validateInterger().moreThan(0,'Value must be greater than 0').required('This field is required!'),
    outflow_destination_fund: Yup.string().nullable().required('This field is required!'),
    outflow_mode: Yup.string().nullable().required('This field is required!'),
    outflow_purpose: Yup.string().nullable().required('This field is required!'),

    partner: Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().nullable(),
        task_id: Yup.number().nullable(),
        ranking: Yup.number().nullable(),
        ranking_partner_type_id: Yup.number().nullable(),
    }),

    suplier1: Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().nullable().required('Name is required!'),
        task_id: Yup.number().nullable(),
        ranking: Yup.number().nullable(),
        ranking_partner_type_id: Yup.number().nullable(),
    }),
    suplier2: Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().nullable(),
        task_id: Yup.number().nullable(),
        ranking: Yup.number().nullable(),
        ranking_partner_type_id: Yup.number().nullable(),
    }),
    suplier3: Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().nullable(),
        task_id: Yup.number().nullable(),
        ranking: Yup.number().nullable(),
        ranking_partner_type_id: Yup.number().nullable(),
    }),

    client1: Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().nullable().required('Name is required!'),
        task_id: Yup.number().nullable(),
        ranking: Yup.number().nullable(),
        ranking_partner_type_id: Yup.number().nullable(),
    }),
    client2: Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().nullable(),
        task_id: Yup.number().nullable(),
        ranking: Yup.number().nullable(),
        ranking_partner_type_id: Yup.number().nullable(),
    }),
    client3: Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().nullable(),
        task_id: Yup.number().nullable(),
        ranking: Yup.number().nullable(),
        ranking_partner_type_id: Yup.number().nullable(),
    }),
});

export default function Step4({activeStep, setActiveStep, setStep4Back, body}) {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { currencies, orderInfo} = useSelector((state) => ({
        currencies: state.enum.currencies,
        orderInfo: state.banking.orderInfo,
    }), shallowEqual );
    const {activeProcess} = useSelector(state => state.banking)

    const form = useForm({
        defaultValues: initFormDefault,
        resolver: yupResolver(formValidationSchema),
    });

    useEffect(() => {
        const fillForm = {
            url: orderInfo?.Questions?.find(q => q.id === 54)?.Options.find(opt => opt.id === 111)?.Answers[0]?.value_text,
            currency: orderInfo?.Company?.Currencies && orderInfo?.Company?.Currencies?.map(item => item?.id || item),

            bank_account: orderInfo?.Questions?.find(q => q.id === 35)?.Options.find(opt => opt?.Answers?.length !== 0)?.id.toString() || '88',
            bank_account_other: orderInfo?.Questions?.find(q => q.id === 35)?.Options.find(opt => opt?.Answers?.length !== 0)?.id === 91 ? orderInfo?.Questions?.find(q => q.id === 35)?.Options.find(opt => opt?.Answers?.length !== 0)?.Answers[0]?.value_text : null,

            use_nominee: orderInfo?.Questions?.find(q => q.id === 36)?.Options.find(opt => opt?.Answers?.length !== 0)?.id === 92 ? '92' : '93',
            use_nominee_reason: orderInfo?.Questions?.find(q => q.id === 36)?.Options.find(opt => opt?.Answers?.length !== 0)?.id === 92 ? orderInfo?.Questions?.find(q => q.id === 36)?.Options.find(opt => opt?.Answers?.length !== 0)?.Answers[0].value_text : null,

            financial_initial_deposit: orderInfo?.Questions?.find(q => q.id === 37)?.Options.find(opt => opt.id === 94)?.Answers[0]?.value_int,
            financial_source_fund: orderInfo?.Questions?.find(q => q.id === 38)?.Options.find(opt => opt.id === 95)?.Answers[0]?.value_text,
            financial_average_balance: orderInfo?.Questions?.find(q => q.id === 39)?.Options.find(opt => opt.id === 96)?.Answers[0]?.value_int,

            expected_year1:  orderInfo?.Questions?.find(q => q.id === 40)?.Options.find(opt => opt.id === 97)?.Answers[0]?.value_int,
            expected_year2: orderInfo?.Questions?.find(q => q.id === 41)?.Options.find(opt => opt.id === 98)?.Answers[0]?.value_int,
            expected_year3:  orderInfo?.Questions?.find(q => q.id === 42)?.Options.find(opt => opt.id === 99)?.Answers[0]?.value_int,

            inflow_amount: orderInfo?.Questions?.find(q => q.id === 43)?.Options.find(opt => opt.id === 100)?.Answers[0]?.value_int,
            inflow_frequency: orderInfo?.Questions?.find(q => q.id === 44)?.Options.find(opt => opt.id === 101)?.Answers[0]?.value_int,
            inflow_source_fund: orderInfo?.Questions?.find(q => q.id === 45)?.Options.find(opt => opt.id === 102)?.Answers[0]?.value_text,
            inflow_mode: orderInfo?.Questions?.find(q => q.id === 46)?.Options.find(opt => opt.id === 103)?.Answers[0]?.value_text,
            inflow_purpose: orderInfo?.Questions?.find(q => q.id === 47)?.Options.find(opt => opt.id === 104)?.Answers[0]?.value_text,

            outflow_amount: orderInfo?.Questions?.find(q => q.id === 48)?.Options.find(opt => opt.id === 105)?.Answers[0]?.value_int,
            outflow_frequency: orderInfo?.Questions?.find(q => q.id === 49)?.Options.find(opt => opt.id === 106)?.Answers[0]?.value_int,
            outflow_destination_fund: orderInfo?.Questions?.find(q => q.id === 50)?.Options.find(opt => opt.id === 107)?.Answers[0]?.value_text,
            outflow_mode: orderInfo?.Questions?.find(q => q.id === 51)?.Options.find(opt => opt.id === 108)?.Answers[0]?.value_text,
            outflow_purpose: orderInfo?.Questions?.find(q => q.id === 52)?.Options.find(opt => opt.id === 109)?.Answers[0]?.value_text,

            partner: orderInfo?.Task.RankingPartners?.find(partner => partner.ranking_partner_type_id === 3),

            suplier1: orderInfo?.Task.RankingPartners?.find(partner => partner.ranking_partner_type_id === 1 && partner.ranking === 1),
            suplier2: orderInfo?.Task.RankingPartners?.find(partner => partner.ranking_partner_type_id === 1 && partner.ranking === 2),
            suplier3: orderInfo?.Task.RankingPartners?.find(partner => partner.ranking_partner_type_id === 1 && partner.ranking === 3),

            client1: orderInfo?.Task.RankingPartners?.find(partner => partner.ranking_partner_type_id === 2 && partner.ranking === 1),
            client2: orderInfo?.Task.RankingPartners?.find(partner => partner.ranking_partner_type_id === 2 && partner.ranking === 2),
            client3: orderInfo?.Task.RankingPartners?.find(partner => partner.ranking_partner_type_id === 2 && partner.ranking === 3),
        }

        form.reset(fillForm);
    }, [orderInfo, currencies])

    const handleNext = async (values) => {
        const currency_ids = values?.currency;
        const bank_account_ans = new BankAccountModel(values.bank_account, values.bank_account_other);
        const use_nominee_ans = new NomineeModel(values.use_nominee, values.use_nominee_reason);
        const financial_initial_deposit = new OnlyTextModel('94' , null, values.financial_initial_deposit);
        const financial_source_fund = new OnlyTextModel('95', values.financial_source_fund, null);
        const financial_average_balance = new OnlyTextModel('96', null, values.financial_average_balance);
        const expected_year1_ans = new OnlyTextModel('97', null, values.expected_year1);
        const expected_year2_ans = new OnlyTextModel('98', null, values.expected_year2);
        const expected_year3_ans = new OnlyTextModel('99', null, values.expected_year3);
        const inflow_amount_ans = new OnlyTextModel('100', null, values.inflow_amount);
        const inflow_frequency_ans = new OnlyTextModel('101', null, values.inflow_frequency);
        const inflow_source_fund_ans = new OnlyTextModel('102', values.inflow_source_fund, null);
        const inflow_mode_ans = new OnlyTextModel('103', values.inflow_mode, null);
        const inflow_purpose_ans = new OnlyTextModel('104', values.inflow_purpose, null);
        const outflow_amount_ans = new OnlyTextModel('105', null, values.outflow_amount);
        const outflow_frequency_ans = new OnlyTextModel('106', null, values.outflow_frequency);
        const outflow_source_fund_ans = new OnlyTextModel('107', values.outflow_destination_fund, null);
        const outflow_mode_ans = new OnlyTextModel('108', values.outflow_mode, null);
        const outflow_purpose_ans = new OnlyTextModel('109', values.outflow_purpose, null);
        const partner_ans = new RankingModel(values?.partner?.id, values?.partner?.name, orderInfo?.Task?.id, values?.partner?.ranking ? values?.partner?.ranking : null, values?.partner?.ranking_partner_type_id ? values?.partner?.ranking_partner_type_id : 3);
        const suplier1_ans = new RankingModel(values?.suplier1?.id, values?.suplier1?.name, orderInfo?.Task?.id, values?.suplier1?.ranking ? values?.suplier1?.ranking : 1, values?.suplier1?.ranking_partner_type_id ? values?.suplier1?.ranking_partner_type_id : 1);
        const suplier2_ans = new RankingModel(values?.suplier2?.id, values?.suplier2?.name, orderInfo?.Task?.id, values?.suplier2?.ranking ? values?.suplier2?.ranking : 2, values?.suplier2?.ranking_partner_type_id ? values?.suplier2?.ranking_partner_type_id : 1);
        const suplier3_ans = new RankingModel(values?.suplier3?.id, values?.suplier3?.name, orderInfo?.Task?.id, values?.suplier3?.ranking ? values?.suplier3?.ranking : 3, values?.suplier3?.ranking_partner_type_id ? values?.suplier3?.ranking_partner_type_id : 1);
        const client1_ans = new RankingModel(values?.client1?.id, values?.client1?.name, orderInfo?.Task?.id, values?.client1?.ranking ? values?.client1?.ranking : 1, values?.client1?.ranking_partner_type_id ? values?.client1?.ranking_partner_type_id : 2);
        const client2_ans = new RankingModel(values?.client2?.id, values?.client2?.name, orderInfo?.Task?.id, values?.client2?.ranking ? values?.client2?.ranking : 2, values?.client2?.ranking_partner_type_id ? values?.client2?.ranking_partner_type_id : 2);
        const client3_ans = new RankingModel(values?.client3?.id, values?.client3?.name, orderInfo?.Task?.id, values?.client3?.ranking ? values?.client3?.ranking : 3, values?.client3?.ranking_partner_type_id ? values?.client3?.ranking_partner_type_id : 2);
        const url_ans = new OnlyTextModel('111', values?.url ? values.url : '' , null);
        const answersForTask = [
            ...bank_account_ans.getResult(),
            ...use_nominee_ans.getNonimeeResult(),
            ...financial_initial_deposit.getResult(),
            ...financial_source_fund.getResult(),
            ...financial_average_balance.getResult(),
            ...expected_year1_ans.getResult(),
            ...expected_year2_ans.getResult(),
            ...expected_year3_ans.getResult(),
            ...inflow_amount_ans.getResult(),
            ...inflow_frequency_ans.getResult(),
            ...inflow_source_fund_ans.getResult(),
            ...inflow_mode_ans.getResult(),
            ...inflow_purpose_ans.getResult(),
            ...outflow_amount_ans.getResult(),
            ...outflow_frequency_ans.getResult(),
            ...outflow_source_fund_ans.getResult(),
            ...outflow_mode_ans.getResult(),
            ...outflow_purpose_ans.getResult(),
            ...url_ans.getResult(),
        ]
        const ranking_partner = [
            suplier1_ans,
            client1_ans,
        ];

        if (partner_ans?.name) {
            ranking_partner.push(partner_ans);
        }
        if (suplier2_ans?.name) {
            ranking_partner.push(suplier2_ans);
        }
        if (suplier3_ans?.name) {
            ranking_partner.push(suplier3_ans);
        }
        if (client2_ans?.name) {
            ranking_partner.push(client2_ans);
        }
        if (client3_ans?.name) {
            ranking_partner.push(client3_ans);
        }

        ranking_partner.forEach(rank => {
            for (const key in rank) {
                if (rank[key] === null || rank[key] === undefined || rank[key] === '') {
                    delete rank[key]
                }
            }
        })

         /* Start - Xử lý show error khi gọi tới be thất bại */
         const resultAction = await dispatch(fromAsyncThunk.updateBusinessPlanFormAction({id: orderInfo.Task.id, params: {answersForTask, currency_ids, ranking_partner}}));
         if (!fromAsyncThunk.updateBusinessPlanFormAction.fulfilled.match(resultAction)) {

            setIsLoading(false)
            toast.error(resultAction?.payload?.data || resultAction?.error?.message || 'Something went wrong!');
            return;
         }

         const approveResult = await dispatch(fromAsyncThunk.approveProcessAction({id: activeProcess.Processes[0]?.id}));
         if (!fromAsyncThunk.approveProcessAction.fulfilled.match(approveResult)) {
             setIsLoading(false);
            return;
         }

         /* Gọi lại process tree để next step dựa vào process tree */
        const resultActionTree = await dispatch(getBankingProcessTreeAction(orderInfo.id));
        if (getBankingProcessTreeAction.fulfilled.match(resultActionTree)) {
            const {payload: processes} = resultActionTree;
            /* Lấy process của step */
            let activeProcess = null;
            processes.forEach(processItem => {
                const {Processes} = processItem;
                if (!Processes[0]) return;
                activeProcess = processItem;
            })

            if (!activeProcess) return;
            dispatch(updateActiveProcess(activeProcess));
            return;
        }
    };

    const handleBack = () => {
        setIsLoading(true)
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setStep4Back(true);
        setIsLoading(false)
        if (!(!body?.Company.is_outside_company && !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(body?.Company?.country_id))) {
            dispatch(fetchOrderForm(id));
        }
    };

    const handleSubmit = (values) => {
        handleNext(values);
    }

    return (
        <>
            <div className="step4 position-relative">
                <PageLoadingEffect loading={isLoading} />
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <div>
                        <div className="row">
                            <div className="col-xl-6 mb-7 input-line">
                                <label>Website (if any)</label>
                                <InputField
                                    form={form}
                                    name="url"
                                    placeholder="Your URL"
                                    showIcon
                                    hideSuccessHelper
                                />
                            </div>
                        </div>

                        <Currency form={form} currencies={currencies} excludes={orderInfo?.Task?.BankingProcesses?.[0]?.bank_id === 16 ? [1] : []} />

                        <BankAccount form={form} />

                        <UseNominee form={form} />

                        <FinancialForecast form={form} />

                        <ExpectedTurnover form={form} />

                        <TransactionsFrequency form={form} />

                        <p className="fw-500">Name of some of Your Counterparts/ Sister-Mother company/ Subsidiaries company (Optional)</p>
                        <div className="row">
                            <div className="col-xl-8 mb-7 input-line">
                                <label>Name</label>
                                <InputField
                                    form={form}
                                    name="partner.name"
                                    placeholder="Enter the member"
                                    showIcon
                                    hideSuccessHelper
                                />
                            </div>
                        </div>

                        <Suppliers form={form} />

                        <Clients form={form} />
                    </div>

                    <div>
                        <Button
                            size="large"
                            variant="contained"
                            disableElevation
                            disabled={isLoading}
                            onClick={handleBack}
                            className='mr-2'
                        >
                            Back
                        </Button>
                        <Button
                            size="large"
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={isLoading}
                            style={{ color: '#fff' }}
                        >
                            Next
                        </Button>
                    </div>
                </form>
            </div>
        </>
    )
}
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useStripe } from '@stripe/react-stripe-js';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';

const PaymentPayNow = (props) => {
    const profile = useSelector((state) => state.personal.profile);
    const stripe = useStripe();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [paymentIntent, setPaymentIntent] = useState(null);

    const getClientSecret = async () => {
        if (!props.payloadPay.payload || !props.payloadPay.uri.paynow.create) {
            throw new Error('Get client secret failed!');
        }
        const param = Object.assign(
            {
                name: `${profile?.first_name}${profile?.last_name ? ` ${profile?.last_name}` : ''}`,
                payment_method_type: 'paynow',
            },
            props.payloadPay.payload,
        );
        const response = await axios.post(
            process.env.REACT_APP_API_URL + props.payloadPay.uri.paynow.create,
            param,
        );
        if (response.status === 201 || response.data.message === 'Created successfully!') {
            return response.data.data.client_secret;
        } else {
            throw new Error('Get client secret failed!');
        }
    };

    // "Order INC113" => 113
    const getOrderIdFromDescription = (description) => {
        const splittedDescription = description.split(' ');
        const orderID = splittedDescription[splittedDescription.length - 1].replace(
            /[A-Za-z]/g,
            '',
        );
        if (parseInt(orderID)) {
            return parseInt(orderID);
        } else {
            return false;
        }
    };

    const submit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'paynow',
            });
            let clientSecret = null;

            if (paymentIntent && paymentIntent.status !== 'succeeded') {
                clientSecret = paymentIntent.client_secret;
            } else {
                clientSecret = await getClientSecret();
            }
            if (clientSecret && !error) {
                stripe
                    .confirmPayNowPayment(clientSecret, { payment_method: paymentMethod.id })
                    .then(({ error: errorPayNow, paymentIntent }) => {
                        if (errorPayNow) {
                            throw new Error('Payment failed!');
                        } else if (paymentIntent.status === 'succeeded') {
                            const orderID = getOrderIdFromDescription(paymentIntent.description);
                            if (orderID) {
                                navigate('/notification-payment-stripe-paypal', {
                                    state: { data: { is_success: true, order_id: orderID } },
                                });
                            } else {
                                throw new Error('Not get order id from PaymentIntent!');
                            }
                        } else if (paymentIntent.status === 'requires_payment_method') {
                            setIsLoading(false);
                        } else {
                            setIsLoading(false);
                        }

                        setPaymentIntent(paymentIntent);
                    });
            } else {
                throw new Error('No client secret or stripe!');
            }
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            toast.error('Something went wrong!');
        }
    };

    return (
        <div>
            <form onSubmit={submit} id="stripeDialogPaynow" className="box-paynow">
                <div className="wrapper-icon-paymow">
                    <img
                        src="/media/svg/logos/paynow.svg"
                        className="img-paynow"
                        width={106}
                        height={32}
                        alt="icon paynow"
                    />
                </div>
                <p className="paynow-select">PayNow selected.</p>
                <hr className="line" />
                <div className="redirect-mobile">
                    <div>
                        <img
                            src="/media/svg/logos/redirect-mobile.svg"
                            className="redirect-mobile"
                            width={42}
                            height={42}
                            alt="icon paynow"
                        />
                    </div>
                    <p>
                        After submitting your order, scan the QR code using your preferred banking
                        or payment app.
                    </p>
                </div>
            </form>

            <div
                className="submit text-center p-8 bg-white"
                style={{ position: 'sticky', bottom: '0' }}
            >
                <Button
                    form="stripeDialogPaynow"
                    size="large"
                    variant="contained"
                    color="primary"
                    style={{ color: '#fff' }}
                    type="submit"
                    disabled={!stripe || isLoading}
                >
                    {!isLoading && (
                        <div>
                            Pay&nbsp;
                            {/* <span>{props.paidAmount >= 0 ? props.paidAmount + '$US' : ''}</span> */}
                            <span>{'SG$' + (props.paidAmount >= 0 ? props.paidAmount : '')}</span>
                        </div>
                    )}
                    {isLoading && (
                        <div>
                            <CircularProgress size={20} />
                        </div>
                    )}
                </Button>
            </div>
        </div>
    );
};

export default PaymentPayNow;

import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Step, StepButton, StepContent, Stepper } from '@mui/material';
import DocumentList from './components/DocumentList';
import DocumentForYear from './components/DocumentForYear';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import CompanyStatus from './components/CompanyStatus';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

const steps = ['Document for year', 'Status Company', 'Document List'];
const ES_SERVICE = [1841, 1931];
const TIN_SERVICE = [1851];
const AR_SERVICE = [1994, 1993];

function SignForm({ orderInfo, onSubmit, isUploadAllFile, activeProcess }) {
    const classes = useStyles();
    const { id } = useParams();
    const [activeStep, setActiveStep] = useState(0);
    const validateStepsRef = useRef({
        STEP_1: {
            isValid: true,
            onSubmit: () => {},
        },
        STEP_2: {
            isValid: true,
            onSubmit: () => {},
        },
    });

    const handleActiveStep = (newStep) => {
        if (activeStep === newStep) return;
        if (activeStep > newStep) setActiveStep(newStep);

        const currentValidateStep = validateStepsRef.current[`STEP_${activeStep + 1}`];
        if (currentValidateStep) {
            currentValidateStep.onSubmit();
            if (currentValidateStep.isValid) {
                setActiveStep(newStep);
            }
        } else {
            setActiveStep(newStep);
        }
    };

    const quantity = useMemo(() => {
        return (
            orderInfo?.OrderItems?.find(
                (item) =>
                    ES_SERVICE.includes(item.service_id) || AR_SERVICE.includes(item.service_id),
            )?.quantity || 0
        );
    }, [orderInfo]);

    const isTINService = useMemo(() => {
        return orderInfo?.OrderItems?.some((item) => TIN_SERVICE.includes(item.service_id));
    }, [orderInfo]);

    const isARService = useMemo(() => {
        return orderInfo?.OrderItems?.some((item) => AR_SERVICE.includes(item.service_id));
    }, [orderInfo]);

    useEffect(() => {
        if (orderInfo?.EventYears?.length > 0) {
            if (!isARService) {
                setActiveStep(1);
            } else {
                if (orderInfo.EventYears.find((item) => item?.type == null)) {
                    setActiveStep(1);
                } else {
                    setActiveStep(2);
                }
            }
        }
    }, [isARService]);

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <DocumentForYear
                        orderId={id}
                        eventYears={orderInfo?.EventYears}
                        quantity={quantity}
                        setActiveStep={setActiveStep}
                        validateStepsRef={validateStepsRef}
                        activeProcess={activeProcess}
                        isARService={isARService}
                    />
                );
            case 1:
                return (
                    <CompanyStatus
                        eventYears={orderInfo?.EventYears}
                        setActiveStep={setActiveStep}
                        validateStepsRef={validateStepsRef}
                        activeProcess={activeProcess}
                    />
                );
            case 2:
                return (
                    <DocumentList
                        isARService={isARService}
                        onSubmit={onSubmit}
                        setActiveStep={setActiveStep}
                    />
                );
        }
    };

    return (
        <>
            {isTINService ? (
                <DocumentList onSubmit={onSubmit} />
            ) : (
                <div className={'wrap-stepper ' + classes.root}>
                    <Stepper
                        className="stepper p-0"
                        nonLinear
                        activeStep={activeStep}
                        orientation="vertical"
                    >
                        {steps.map((label, index) => {
                            if (!isARService && index == 1) {
                                return null;
                            }
                            return (
                                <Step key={label}>
                                    <StepButton
                                        className="step-title no-click"
                                        disableTouchRipple
                                        onClick={() => handleActiveStep(index)}
                                    >
                                        {label}
                                    </StepButton>
                                    <StepContent>{getStepContent(index)}</StepContent>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
            )}
        </>
    );
}

export default SignForm;

import React, { useEffect, useState } from 'react';
import { Grid, Switch } from '@mui/material';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import DateField from 'components/form-controls/DateField';
import { fetchBusinessActivity } from 'app/pages/_redux/enum/enumActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CAYMAN_ID, RAK_ID, UK_ID } from 'constants/country';
import moment from 'moment';

const CorporationForm = ({
    form,
    index,
    disabled,
    phoneCountryOptions,
    countriesOptions,
    onSwitchTypeMember,
    allowFields,
}) => {
    const dispatch = useDispatch();
    const [businessActivityOptions, setBusinessActivityOptions] = useState([]);

    const { businessActivityWithCountry } = useSelector(
        (state) => ({
            businessActivityWithCountry: state.enum.businessActivityWithCountry,
        }),
        shallowEqual,
    );

    const handleGetBusinessActivityWithCountry = (countryId) => {
        dispatch(fetchBusinessActivity(countryId, true));
    };

    useEffect(() => {
        if (form.watch(`members.${index}.country_id`)) {
            handleChangeCountryOfCorporation(form.watch(`members.${index}.country_id`));
        }
    }, []);

    const handleChangeCountryOfCorporation = (countryId) => {
        if (
            (countryId === CAYMAN_ID && !businessActivityWithCountry.caymanList.length) ||
            (countryId === RAK_ID && !businessActivityWithCountry.rakList.length) ||
            (countryId === UK_ID && !businessActivityWithCountry.ukList.length) ||
            (![CAYMAN_ID, UK_ID, RAK_ID].includes(countryId) &&
                !businessActivityWithCountry.normalList.length)
        ) {
            handleGetBusinessActivityWithCountry(countryId);
        }
    };

    useEffect(() => {
        {
            const countryId = form.watch(`members.${index}.country_id`);
            if (businessActivityWithCountry) {
                if (countryId === CAYMAN_ID) {
                    if (businessActivityWithCountry.caymanList.length) {
                        setBusinessActivityOptions(businessActivityWithCountry.caymanList);
                    }
                }
                if (countryId === UK_ID) {
                    if (businessActivityWithCountry.ukList.length) {
                        setBusinessActivityOptions(businessActivityWithCountry.ukList);
                    }
                }
                if (countryId === RAK_ID) {
                    if (businessActivityWithCountry.rakList.length) {
                        setBusinessActivityOptions(businessActivityWithCountry.rakList);
                    }
                }

                if (![CAYMAN_ID, UK_ID, RAK_ID].includes(countryId)) {
                    if (businessActivityWithCountry.normalList.length) {
                        setBusinessActivityOptions(businessActivityWithCountry.normalList);
                    }
                }
            }
        }
    }, [businessActivityWithCountry]);

    return (
        <Grid container rowSpacing={2} columnSpacing={4}>
            <Grid item xs={12}>
                <span>Corporation</span>
                <Switch
                    checked={form.watch(`members.${index}.type_member_id`) === 1}
                    onChange={(e) => onSwitchTypeMember(e, index)}
                    name={`members.${index}.individual`}
                    disabled
                />
                <span>Individual</span>
            </Grid>
            <Grid className="input-line" item sm={6} xs={12}>
                <label>Company name</label>
                <InputField
                    type="text"
                    name={`members.${index}.corporation_name`}
                    form={form}
                    placeholder="Enter company name"
                    disabled={disabled || !allowFields?.[index]?.corporation_name}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.corporation_name &&
                                form.getValues(`members.${index}.corporation_name`) !== ''
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    hideSuccessHelper
                />
            </Grid>
            <Grid className="input-line" item sm={6} xs={12}>
                <label>Company number</label>
                <InputField
                    type="text"
                    name={`members.${index}.corporation_number`}
                    form={form}
                    placeholder="Enter company number"
                    disabled={disabled || !allowFields?.[index]?.corporation_number}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.corporation_number &&
                                form.getValues(`members.${index}.corporation_number`) !== ''
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    hideSuccessHelper
                />
            </Grid>
            <Grid className="input-line" item sm={6} xs={12}>
                <label>Country of Incorporation</label>
                <SelectField
                    form={form}
                    // className="fw-500"
                    name={`members.${index}.country_id`}
                    placeholder="Choose country"
                    options={countriesOptions}
                    hideHelperText={true}
                    disabled={disabled || !allowFields?.[index]?.country_id}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.country_id &&
                                form.getValues(`members.${index}.country_id`) !== null
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    disableClearable
                />
            </Grid>
            <Grid className="input-line" item sm={6} xs={12}>
                <label>Nature of business</label>
                <InputField
                    type="text"
                    name={`members.${index}.nature_business`}
                    form={form}
                    placeholder="Enter Nature of business"
                    disabled={disabled || !allowFields?.[index]?.nature_business}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.nature_business &&
                                form.getValues(`members.${index}.nature_business`) !== ''
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    hideSuccessHelper
                />
            </Grid>
            <Grid className="input-line" item sm={6} xs={12}>
                <label>Registered address</label>
                <InputField
                    type="text"
                    name={`members.${index}.address`}
                    form={form}
                    placeholder="Enter address"
                    disabled={disabled || !allowFields?.[index]?.address}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.address &&
                                form.getValues(`members.${index}.address`) !== ''
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    hideSuccessHelper
                />
            </Grid>
            <Grid className="input-line" item sm={6} xs={12}>
                <label>Operating address</label>
                <InputField
                    type="text"
                    name={`members.${index}.operation_address`}
                    form={form}
                    placeholder="Enter Operating address"
                    disabled={disabled || !allowFields?.[index]?.operation_address}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.operation_address &&
                                form.getValues(`members.${index}.operation_address`) !== ''
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    hideSuccessHelper
                />
            </Grid>
            <Grid className="input-line" item sm={6} xs={12}>
                <label>Business activities</label>
                <SelectField
                    form={form}
                    id="business_activity_array"
                    className="fw-500"
                    name={`members.${index}.business_activity_array`}
                    placeholder={
                        form.watch(`members.${index}.business_activity_array`).length
                            ? ''
                            : 'Please select specific business activities.'
                    }
                    options={businessActivityOptions || []}
                    getOptionLabel={(option) => option?.description}
                    disabled={disabled || !allowFields?.[index]?.business_activity_array}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.business_activity_array &&
                                form.getValues(`members.${index}.business_activity_array`) !== null
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    multiple
                    hideInputHelperText
                    hideHelperText
                />
                {form.formState.errors?.members?.[index]?.business_activity_array && (
                    <div className="text-danger" style={{ fontSize: '0.9rem', marginTop: '5px' }}>
                        Business activities is required!
                    </div>
                )}
            </Grid>
            <Grid className="input-line" item sm={6} xs={12}>
                <label>Country/countries of operation</label>
                <SelectField
                    form={form}
                    // className="fw-500"
                    name={`members.${index}.OperationCountries`}
                    placeholder={
                        !form.watch(`members.${index}.OperationCountries`)?.length &&
                        'Choose country/countries of operation'
                    }
                    options={countriesOptions?.length && countriesOptions}
                    disabled={disabled || !allowFields?.[index]?.OperationCountries}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.OperationCountries &&
                                form.getValues(`members.${index}.OperationCountries`) !== null
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    multiple
                    hideHelperText
                    hideInputHelperText
                />
                {form.formState.errors?.members?.[index]?.OperationCountries && (
                    <div className="text-danger" style={{ fontSize: '0.9rem', marginTop: '5px' }}>
                        Countries operation is required!
                    </div>
                )}
            </Grid>
            {form.watch(`members.${index}.business_activity_array`) &&
                form.watch(`members.${index}.business_activity_array`).length > 0 && (
                    <>
                        <Grid className="input-line" item sm={6} xs={12}>
                            <label>Details of Business activities</label>
                            <InputField
                                multiline
                                maxRows={5}
                                type="text"
                                name={`members.${index}.business_activity_detail`}
                                form={form}
                                disabled={
                                    disabled || !allowFields?.[index]?.business_activity_detail
                                }
                                customStyle={{
                                    '.MuiInputBase-root': {
                                        backgroundColor:
                                            allowFields?.[index]?.business_activity_detail &&
                                            form.getValues(
                                                `members.${index}.business_activity_detail`,
                                            ) !== ''
                                                ? 'rgba(176, 236, 209, 0.5)'
                                                : 'inherit',
                                    },
                                }}
                                hideSuccessHelper
                            />
                        </Grid>
                        <Grid className="input-line" item sm={6} xs={12}></Grid>
                    </>
                )}
            <Grid className="input-line" item sm={6} xs={12}>
                <label>Phone number</label>
                <div className="phone-group">
                    <div className="phone-code" style={{ zIndex: 1 }}>
                        <SelectField
                            id="phone-prefix-select"
                            form={form}
                            // className="fw-500"
                            name={`members.${index}.phone_country_id`}
                            placeholder="Select..."
                            options={phoneCountryOptions}
                            hideHelperText={true}
                            disabled={disabled || !allowFields?.[index]?.phone_country_id}
                            customStyle={{
                                '.MuiInputBase-root': {
                                    backgroundColor:
                                        allowFields?.[index]?.phone_country_id &&
                                        form.getValues(`members.${index}.phone_country_id`) !== null
                                            ? 'rgba(176, 236, 209, 0.5)'
                                            : 'inherit',
                                    '.MuiInputBase-root:before, .MuiInputBase-root:after, .MuiInput-underline:hover:not(.Mui-disabled):before':
                                        {
                                            borderBottom: '0 !important',
                                        },
                                },
                            }}
                            disableClearable
                        />
                    </div>
                    <div className="input-line inp-phone">
                        <InputField
                            name={`members.${index}.phone_number`}
                            form={form}
                            placeholder="Enter phone number"
                            type="text"
                            disabled={disabled || !allowFields?.[index]?.phone_number}
                            customStyle={{
                                '.MuiInputBase-root': {
                                    backgroundColor:
                                        allowFields?.[index]?.phone_number &&
                                        form.getValues(`members.${index}.phone_number`) !== ''
                                            ? 'rgba(176, 236, 209, 0.5)'
                                            : 'inherit',
                                },
                            }}
                            hideSuccessHelper
                        />
                    </div>
                </div>
            </Grid>
            <Grid className="input-line" item sm={6} xs={12}>
                <label>Email Address of Designated Legal Representative</label>
                <InputField
                    type="text"
                    name={`members.${index}.email`}
                    form={form}
                    placeholder="Enter email address"
                    disabled={disabled || !allowFields?.[index]?.email}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.email &&
                                form.getValues(`members.${index}.email`) !== ''
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    hideSuccessHelper
                />
            </Grid>
            <Grid className="input-line" item sm={6} xs={12}>
                <label>Date of Incorporation</label>
                <DateField
                    type="text"
                    name={`members.${index}.corporation_date`}
                    form={form}
                    placeholder="Enter date of incorporation"
                    onChange={(e) => {
                        form.setValue(
                            `members.${index}.corporation_date`,
                            e ? moment(e).format('YYYY-MM-DD') : null,
                        );
                    }}
                    disabled={disabled || !allowFields?.[index]?.corporation_date}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowFields?.[index]?.corporation_date &&
                                form.getValues(`members.${index}.corporation_date`) !== null
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                    hideSuccessHelper
                />
            </Grid>
        </Grid>
    );
};

export default CorporationForm;

import { toast } from 'react-toastify';
import * as requestFromServer from './orderFormCrud';
import { orderFormSlice, callTypes } from './orderFormSlice';

const { actions } = orderFormSlice;

// export const fetchOrderForm = () => (dispatch) => {
//     dispatch(actions.startCall({ callType: callTypes.list }));
// };

export const fetchOrders = (queryParams) => (dispatch) => {
    // get list order
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .findOrders(queryParams)
        .then((response) => {
            const { count, rows } = response.data.data;
            dispatch(actions.OrdersFetched({ count, rows }));
            return response.data.data;
        })
        .catch((error) => {
            error.clientMessage = "Can't find orders";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchOrderForm = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getOrderForm(queryParams)
        .then((response) => {
            const { data } = response.data;
            dispatch(actions.orderInfoFetched({ data: data }));
            return response.data;
        })
        .catch((error) => {
            error.clientMessage = "Order has not existed or its was submitted!";
            toast.error("Order has not existed or its was submitted!");
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};



export const updateOrderForm = (queryParams, body) => (dispatch) => {
    dispatch(actions.startCall({}));
    return requestFromServer
        .updateOrderForm(queryParams, body)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            toast.error("Can't update order form!");
            dispatch(actions.catchError({}));
        });
};

export const addNewNode = (orderId, body) => (dispatch) => {
    dispatch(actions.startCall({}));
    return requestFromServer
        .updateKycNode(orderId, body)
        .then((response) => {
            dispatch(actions.orderInfoUpdated({}));
        })
        .catch((error) => {
            toast.error("Can't add new node!");
            dispatch(actions.catchError({}));
        });
};

export const updateDeclaration = (TaskId, body) => (dispatch) => {
    dispatch(actions.startCall({}));
    return requestFromServer
        .updateOrderForm(TaskId, body)
        .then((response) => {
            const params = {
                id: response.data.data.Task.Processes[0].id,
            }
            dispatch(actions.startCall({}));

            return requestFromServer
                .getStepApproved(params)
                .then(res => {
                    return res;
                })
                .catch((error) => {
                    toast.error(error.message);
                    dispatch(actions.catchError({}));
                });
        })
        .catch((error) => {
            toast.error(error.message);
            dispatch(actions.catchError({}));
        });
};

export const requestApproveDeclaration = (body) => (dispatch) => {
    dispatch(actions.startCall({}));
    return requestFromServer
        .getStepApproved(body).then((response) => {
            // console.log(response);
            // return response.data;

        })
        .catch((error) => {
            toast.error(error.message);
            dispatch(actions.catchError({}));
        })
}

export const updateCompanyStructure = (orderID, body) => (dispatch) => {
    dispatch(actions.startCall({}));
    return requestFromServer
        .updateCompanyStructure(orderID, body)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            toast.error("Can't update company structure form!");
            dispatch(actions.catchError({}));
        });
};

export const updateGraphNode = (taskId, body) => (dispatch) =>
    new Promise((resolve, reject) => {
        // dispatch(actions.startCall({}));
        requestFromServer
            .updateKycNode(taskId, body)
            .then((response) => {
                const CompanyMembers = response.data.data.Company.CompanyMembers;
                // dispatch(
                //     actions.updateCompanyMemberList({
                //         data: CompanyMembers,
                //     }),
                // );
                resolve({ CompanyMembers: CompanyMembers });
            })
            .catch((error) => {
                dispatch(actions.catchError({}));
                // toast.error("Can't add new node!");
                reject(error);
            });
    });

export const addGraphNode = (taskId, body) => (dispatch) =>
    new Promise((resolve, reject) => {
        // dispatch(actions.startCall({}));
        requestFromServer
            .updateKycNode(taskId, body)
            .then((response) => {
                const CompanyMembersSimple = response.data.data.Company.CompanyMembers;

                resolve({ CompanyMembers: CompanyMembersSimple });
            })
            .catch((error) => {
                // console.log(error);
                // toast.error("Can't add new node!");
                dispatch(actions.catchError({}));
                reject(error);
            });
    });

export const updateCompanyMemberList = (orderInfo) => (dispatch) => {
    dispatch(
        actions.updateCompanyMemberList({
            data: orderInfo?.Company?.CompanyMembers || [],
        }),
    );
};

export const submitOrderForm = (processId) => (dispatch) => new Promise((resolve, reject) => {
    return requestFromServer
        .submitOrderFormStep({ id: processId })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
            toast.error("Can't find order!");
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
});

export const fetchTemplateHTML = (queryParams) => (dispatch) => {
    return requestFromServer
        .getTemplateDetailHTML(queryParams)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            toast.error("Can't find pdf!");
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const downloadTemplateHTML = (queryParams) => (dispatch) => {
    return requestFromServer
        .downloadTemplateDetailHTML(queryParams)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            toast.error("Can't find pdf!");
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const sendMails = (body) => (dispatch) => {
    return requestFromServer
        .postSendMails(body)
        .then((response) => {
            toast.success("Successfully!");
            return response.data;
        })
        .catch((error) => {
            toast.error("Can't send the document to some members!");
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
}


/// Banking order
export const fetchOrderFormBanking = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getBankingDataByOrderId(queryParams)
        .then((response) => {
            const { data } = response.data;
            dispatch(actions.orderInfoFetched({ data: data }));
            return response.data;
        })
        .catch((error) => {
            error.clientMessage = "Order has not existed or its was submitted!";
            toast.error("Order has not existed or its was submitted!");
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const updateOrderFormStep4Banking = (id, params) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .updateBankingStep1_1_4(id, params)
        .them((response) => {
            // console.log('update result', response);
            return response.data;
        })
}
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FieldFeedbackLabel } from './FieldFeedbackLabel';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
    arrow: {
        color: '#3783e7',
    },
    tooltip: {
        fontSize: '12px',
        fontWeight: 300,
        letterSpacing: '0.3px',
        backgroundColor: '#3783e7',
    },
}));

const getFieldCSSClasses = (touched, errors) => {
    const classes = ['form-control'];
    if (touched && errors) {
        classes.push('is-invalid');
    }

    if (touched && !errors) {
        classes.push('is-valid');
    }

    return classes.join(' ');
};

export function Input({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    labelNormal,
    lableTooltipContent,
    withFeedbackLabel = true,
    customFeedbackLabel,
    type = 'text',
    noPlease = false,
    multiple,
    ...props
}) {
    const classesTooltip = useStyles();
    const Component = multiple ? 'textarea' : 'input';
    return (
        <>
            {label && <label>Enter {label}</label>}
            {labelNormal && (
                <div className="wrap-label-has-tooltip">
                    <label>{labelNormal}</label>
                    {lableTooltipContent && lableTooltipContent.length > 0 && (
                        <div className="wrap-tooltip d-inline ml-2">
                            <Tooltip
                                title={lableTooltipContent}
                                placement="top"
                                arrow
                                // open={true}
                                classes={classesTooltip}
                            >
                                <InfoIcon className="icon" />
                            </Tooltip>
                        </div>
                    )}
                </div>
            )}
            <Component
                type={type}
                className={getFieldCSSClasses(touched[field.name], errors[field.name])}
                // {...field}
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value === null ? '' : field.value}
                {...props}
            />
            {withFeedbackLabel && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    label={label}
                    type={type}
                    customFeedbackLabel={customFeedbackLabel}
                    noPlease={noPlease}
                />
            )}
        </>
    );
}

import React, { Suspense, lazy, useEffect, useState, useMemo } from 'react';
import { useStore } from 'react-redux';
import { Redirect, Switch, Route, Routes, Outlet } from 'react-router-dom';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import { DashboardPage } from './pages/DashboardPage';
import OrderManagementPage from './pages/services/order-management/order-management';
import * as ActionsPersonal from './pages/_redux/personal/personalActions';
import * as actionsEnum from './pages/_redux/enum/enumActions';
import { NotificationPayment } from './pages/components/payment/notification-payment';
import { NotificationPaymentStripePaypal } from './pages/components/payment/notification-payment-stripe-paypal';
import socket from '_metronic/_helpers/Socket';
import VerticalLinearStepper from './pages/services/incorporation/component/stepper';

const AccountPage = lazy(() => import('./pages/account/accountPage'));
const CompanyPage = lazy(() => import('./pages/company/companyPage'));

export default function BasePage() {
    const dispatch = useDispatch();
    const [profileID, setProfileId] = useState(0);
    const store = useStore();
    const {
        auth: { authToken },
    } = store.getState();

    useEffect(() => {
        dispatch(ActionsPersonal.fetchPersonal());
        dispatch(actionsEnum.fetchGeoLooking());
    }, []);

    const { profile } = useSelector(
        (state) => ({
            profile: state.personal.profile,
        }),
        shallowEqual,
    );

    useEffect(() => {
        if (profile && profile.id) {
            setProfileId(profile.id);
            if (profile.id > 0) {
                // const a = renderTawk;
            }
        }
        // socket.emit('join', { authorization: authToken.access_token });
    }, [profile]);

    const renderTawk = useMemo(() => {
        if (profile) {
            let email, first_name, last_name;
            email = profile.email;
            first_name = profile.first_name ? profile.first_name : '';
            last_name = profile.last_name ? ' ' + profile.last_name : '';
            if (!document.getElementById('tawk_to')) {
                const script = document.createElement('script');
                script.setAttribute('id', 'tawk_to');
                script.setAttribute('type', 'text/javascript');
                script.text = `
                    var Tawk_API=Tawk_API||{},
                    Tawk_LoadStart=new Date();
                    Tawk_API.customStyle = {
                        zIndex: 1049,
                        visibility : {
                            desktop : {
                                position : 'br',
                                xOffset : '16px',
                                yOffset : '80px'
                            },
                            mobile : {
                                position : 'br',
                                xOffset : '16px',
                                yOffset : '80px'
                            },
                            bubble : {
                                rotate : '0deg',
                                 xOffset : 0,
                                 yOffset : 0
                            }
                        }
                    };
                    Tawk_API.visitor = {
                        name : ${JSON.stringify(first_name + last_name)},
                        email : ${JSON.stringify(email)},
                        hash : '${hmacSHA256(
                            email,
                            process.env.REACT_APP_TAWKTO_API_KEY,
                        ).toString()}'
                    };
                    (function(){
                    var s1=document.createElement("script"),
                    s0=document.getElementsByTagName("script")[0];
                    s1.id = 'tawk_id';
                    s1.async=true;
                    s1.src='https://embed.tawk.to/${process.env.REACT_APP_TAWKTO_ID}/default';
                    s1.charset='UTF-8';
                    s1.setAttribute('crossorigin','*');
                    s0.parentNode.insertBefore(s1,s0);
                    })();`;
                document.body.appendChild(script);
            }
        }
    }, [profileID, profile]);

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Outlet />
        </Suspense>
    );
}

import React, { useEffect, useMemo, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
    Box,
    CircularProgress,
    Dialog,
    DialogTitle,
    Divider,
    Grid,
    InputAdornment,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material';
import { ToastBar } from '../toastBar';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import './payment-dialog.scss';
import { uri } from 'app/pages/services/order-management/banking/banking-process/application-result/components/upgradeBanking';
import { toast } from 'react-toastify';
import withStyles from '@mui/styles/withStyles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actionsEnum from '../../_redux/enum/enumActions';
import { fetchCountries } from '../../_redux/enum/enumActions';
import ReactSelect from 'react-select';
import PaymentPayNow from './PaymentPayNow';
import { COUNTRIES_ID } from 'constants/countries';

export const PAYMENT_METHOD = {
    CARD: 'CARD',
    PAYNOW: 'PAYNOW',
};

const ReactSelectStyles = {
    container: (provided, state) => ({
        ...provided,
        height: '60px',
    }),
    control: (provided, state) => ({
        ...provided,
        height: '60px',
        borderColor: '#ddd',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        fontSize: '16px',
        fontFamily: 'Source Code Pro, monospace',
        color: '#424770',
        letterSpacing: '0.025em',
    }),
    placeholder: (provided, state) => ({
        ...provided,
        fontSize: '16px',
        fontFamily: 'Source Code Pro, monospace',
        color: '#424770',
        letterSpacing: '0.025em',
        opacity: 0.5,
    }),
};

const CardHolderNameTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: '#424770',
            letterSpacing: '0.025em',
            fontSize: '16px',
            fontFamily: 'Source Code Pro, monospace',
        },
        '& .MuiInputBase-input::placeholder': {
            color: '#424770',
            fontFamily: 'Source Code Pro, monospace',
            // fontWeight: '600'
        },
        '& .MuiInput-underline:after': {
            border: '1px solid #ddd',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: '1px solid #ddd',
                borderRadius: '3px',
            },
            '&:hover fieldset': {
                border: '1px solid #ddd',
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #ddd',
            },
        },
    },
})(TextField);

const useOptions = () => {
    //   const fontSize = useResponsiveFontSize();
    const options = useMemo(
        () => ({
            hidePostalCode: true,
            style: {
                base: {
                    //   fontSize,
                    color: '#424770',
                    letterSpacing: '0.025em',
                    fontSize: '18px',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        }),
        [],
    );

    return options;
};

const CardForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const dispatch = useDispatch();
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const [cardHolderName, setCardHolderName] = useState('');
    const [cardHolderNameError, setCardHolderNameError] = useState(false);
    const navigate = useNavigate();

    const [cardCountry, setCardCountry] = useState('');
    const [cardCountryError, setCardCountryError] = useState(false);

    const [cardCity, setCardCity] = useState('');
    const [cardCityError, setCardCityError] = useState(false);

    const [cardState, setCardState] = useState('');
    const [cardStateError, setCardStateError] = useState(false);

    const [cardZip, setCardZip] = useState('');
    const [cardZipError, setCardZipError] = useState(false);

    const [cardAddressLine1, setCardAddressLine1] = useState('');
    const [cardAddressLine1Error, setCardAddressLine1Error] = useState(false);

    const [cardAddressLine2, setCardAddressLine2] = useState('');
    const [cardAddressLine2Error, setCardAddressLine2Error] = useState(false);

    const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.CARD);

    const { countries } = useSelector((state) => state.enum);
    const { countryID } = props;

    useEffect(() => {
        dispatch(actionsEnum.fetchCountries());
    }, []);

    const submitPaymentCard = async () => {
        let isManualFormValid = true;
        if (!cardHolderName) {
            setCardHolderNameError(true);
            isManualFormValid = false;
        }
        if (!cardCountry) {
            setCardCountryError(true);
            isManualFormValid = false;
        }
        if (!cardCity) {
            setCardCityError(true);
            isManualFormValid = false;
        }
        if (!cardAddressLine1) {
            setCardAddressLine1Error(true);
            isManualFormValid = false;
        }
        /*if (!cardAddressLine2) {
            setCardAddressLine2Error(true);
            isManualFormValid = false;
        }*/
        if (!cardState) {
            setCardStateError(true);
            isManualFormValid = false;
        }
        if (!cardZip) {
            setCardZipError(true);
            isManualFormValid = false;
        }
        if (!isManualFormValid) return;

        if (props.stripe) {
            setIsLoading(true);
            let { token } = await stripe.createToken(elements.getElement(CardElement), {
                auth: props.userToken.access_token,
                name: cardHolderName,
                address_city: cardCountry,
                address_country: cardCity,
                address_line1: cardAddressLine1,
                address_line2: cardAddressLine2,
                address_state: cardState,
                address_zip: cardZip,
            });
            if (token) {
                const param = Object.assign(
                    { id: token.id, cardholder_name: cardHolderName },
                    props.payloadPay.payload,
                );
                setIsLoading(true);
                fetch(process.env.REACT_APP_API_URL + props.payloadPay.uri.stripe.create, {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + props.userToken.access_token,
                        Accept: 'application/json,text/plain, */*',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(param),
                })
                    .then(async (data) => {
                        const datajson = await data.json();
                        // setIsLoading(false);
                        if (data.ok) {
                            props.setOpenStripe(false);
                            setSuccess(true);
                            let params = {
                                sucess: true,
                                data: datajson.data,
                            };

                            if (props.payloadPay.uri.stripe.create === uri.stripe.create) {
                                params.data.isBanking = true;
                                params.data.order_id_current = props.payloadPay.order_id_current;
                            }
                            return navigate('/notification-payment-stripe-paypal', {
                                state: params,
                            });
                        } else {
                            setError(true);
                            return navigate('/notification-payment-stripe-paypal', {
                                state: {
                                    success: true,
                                    data: datajson.data,
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        // setIsLoading(false);
                        setError(true);
                        // return navigate('/notification-payment', {
                        //     success:false,
                        //     // data:datajson.data
                        // });
                    });
            } else {
                setIsLoading(false);
                toast.error('Invalid card information!');
            }
        } else {
        }
    };

    const submit = async (event) => {
        event.preventDefault();
        submitPaymentCard();
    };
    const handleCloseDialog = () => {
        props.setOpenStripe(false);
    };
    const handleClose = () => {
        setError(false);
        setSuccess(false);
    };
    const handleSetCardHolderName = (e) => {
        setCardHolderName(e.target.value);
        if (e.target.value) {
            setCardHolderNameError(false);
        } else {
            setCardHolderNameError(true);
        }
    };
    const handleSetCardCity = (e) => {
        setCardCity(e.target.value);
        if (e.target.value) {
            setCardCityError(false);
        } else {
            setCardCityError(true);
        }
    };
    const handleSetCardState = (e) => {
        setCardState(e.target.value);
        if (e.target.value) {
            setCardStateError(false);
        } else {
            setCardStateError(true);
        }
    };
    const handleSetCardZip = (e) => {
        setCardZip(e.target.value);
        if (e.target.value) {
            setCardZipError(false);
        } else {
            setCardZipError(true);
        }
    };
    const handleSetCardAddressLine1 = (e) => {
        setCardAddressLine1(e.target.value);
        if (e.target.value) {
            setCardAddressLine1Error(false);
        } else {
            setCardAddressLine1Error(true);
        }
    };
    const handleSetCardAddressLine2 = (e) => {
        setCardAddressLine2(e.target.value);
        if (e.target.value) {
            setCardAddressLine2Error(false);
        } else {
            setCardAddressLine2Error(true);
        }
    };

    const showAmount = (amount, country_id) => {
        if (amount >= 0) {
            switch (country_id) {
                case COUNTRIES_ID.SINGAPORE:
                    return `SG$${amount}`;
                default:
                    // return `${amount}$US`;
                    return `US$${amount}`;
            }
        } else {
            return '';
        }
    };

    return (
        <div>
            <div>
                <div>
                    <div className="dialog-header">
                        <div className="logo">
                            <img src="/media/logos/logo.svg" alt="Logo" />
                        </div>
                        {!isLoading && (
                            <IconButton
                                size="medium"
                                aria-label="Close"
                                onClick={handleCloseDialog}
                                className="btn-close-dialog"
                            >
                                <CloseIcon />
                            </IconButton>
                        )}

                        <DialogTitle className="title text-center">
                            BBCIncorp Limited
                            <a href="mailto: service@bbcincorp.com" className="sub-title">
                                service@bbcincorp.com
                            </a>
                        </DialogTitle>
                    </div>
                    {countryID === 191 && (
                        <Grid className="switch-method" container spacing={1}>
                            <Grid item xs={6}>
                                <button
                                    onClick={() => {
                                        setPaymentMethod(PAYMENT_METHOD.CARD);
                                    }}
                                    className={`${
                                        paymentMethod === PAYMENT_METHOD.CARD ? 'active' : ''
                                    } btn-switch`}
                                >
                                    <svg
                                        className="icon-wallet"
                                        role="presentation"
                                        fill="var(--colorIcon)"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 4a2 2 0 012-2h12a2 2 0 012 2H0zm0 2v6a2 2 0 002 2h12a2 2 0 002-2V6H0zm3 5a1 1 0 011-1h1a1 1 0 110 2H4a1 1 0 01-1-1z"
                                        ></path>
                                    </svg>
                                    <p>Card</p>
                                </button>
                            </Grid>
                            <Grid item xs={6}>
                                <button
                                    onClick={() => {
                                        setPaymentMethod(PAYMENT_METHOD.PAYNOW);
                                    }}
                                    className={`${
                                        paymentMethod === PAYMENT_METHOD.PAYNOW ? 'active' : ''
                                    } btn-switch`}
                                >
                                    <img
                                        className={'icon-paynow'}
                                        src="/media/svg/logos/paynow.svg"
                                        alt="icon paynow"
                                        width={55}
                                        height={16}
                                    />
                                    <p>PayNow</p>
                                </button>
                            </Grid>
                        </Grid>
                    )}
                    {paymentMethod === PAYMENT_METHOD.CARD && (
                        <>
                            <form
                                id="stripeDialog"
                                onSubmit={submit}
                                style={{ overflow: 'auto', marginBottom: '16px' }}
                            >
                                <Grid container>
                                    <Grid item xs={12}>
                                        <label className={'pt-4 font-weight-bold'}>
                                            Name on card
                                        </label>
                                        <Box width={'100%'}>
                                            <CardHolderNameTextField
                                                value={cardHolderName}
                                                onChange={handleSetCardHolderName}
                                                style={{ width: '100%', border: 'none' }}
                                                size={'medium'}
                                                variant={'outlined'}
                                                placeholder={'Name on card'}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AccountCircle
                                                                style={{
                                                                    width: '1.5em',
                                                                    height: '1.5em',
                                                                    color: cardHolderNameError
                                                                        ? '#ff000054'
                                                                        : '#cacbd7',
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {cardHolderNameError && (
                                                <Typography
                                                    style={{ color: '#ff5151', marginTop: '4px' }}
                                                >
                                                    Please fill Name on card
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>

                                <label className={'pt-4 mb-0 font-weight-bold'}>Card details</label>
                                <Box py={1}>
                                    <Box border={'1px solid #ddd'} borderRadius={'3px'}>
                                        <CardElement className="p-5" options={options} />
                                    </Box>
                                </Box>

                                <label className={'pt-4 font-weight-bold'}>
                                    Billing information
                                </label>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box width={'100%'}>
                                            <ReactSelect
                                                styles={ReactSelectStyles}
                                                options={countries?.map((country) => ({
                                                    value: country.name,
                                                    label: country.name,
                                                }))}
                                                onChange={(e) => {
                                                    setCardCountry(e?.value);
                                                    setCardCountryError(!e?.value);
                                                }}
                                            />
                                            {cardCountryError && (
                                                <Typography
                                                    style={{ color: '#ff5151', marginTop: '4px' }}
                                                >
                                                    Please fill Country
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box width={'100%'}>
                                            <CardHolderNameTextField
                                                value={cardAddressLine1}
                                                onChange={handleSetCardAddressLine1}
                                                style={{ width: '100%', border: 'none' }}
                                                size={'medium'}
                                                variant={'outlined'}
                                                placeholder={'Address line 1'}
                                            />
                                            {cardAddressLine1Error && (
                                                <Typography
                                                    style={{ color: '#ff5151', marginTop: '4px' }}
                                                >
                                                    Please fill Address Line 1
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box width={'100%'}>
                                            <CardHolderNameTextField
                                                value={cardAddressLine2}
                                                onChange={handleSetCardAddressLine2}
                                                style={{ width: '100%', border: 'none' }}
                                                size={'medium'}
                                                variant={'outlined'}
                                                placeholder={'Address line 2 (optional)'}
                                            />
                                            {/*{cardAddressLine2Error && <Typography style={{color: '#ff5151', marginTop: '4px'}}>Please fill Address Line 2</Typography>}*/}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Box width={'100%'}>
                                            <CardHolderNameTextField
                                                value={cardCity}
                                                onChange={handleSetCardCity}
                                                style={{ width: '100%', border: 'none' }}
                                                size={'medium'}
                                                variant={'outlined'}
                                                placeholder={'City'}
                                            />
                                            {cardCityError && (
                                                <Typography
                                                    style={{ color: '#ff5151', marginTop: '4px' }}
                                                >
                                                    Please fill City
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={6}>
                                        {/*<label className={'px-5 pt-4 font-weight-bold'}>State</label>*/}
                                        <Box width={'100%'}>
                                            <CardHolderNameTextField
                                                value={cardState}
                                                onChange={handleSetCardState}
                                                style={{ width: '100%', border: 'none' }}
                                                size={'medium'}
                                                variant={'outlined'}
                                                placeholder={'State'}
                                            />
                                            {cardStateError && (
                                                <Typography
                                                    style={{ color: '#ff5151', marginTop: '4px' }}
                                                >
                                                    Please fill State
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={6}>
                                        {/*<label className={'px-5 pt-4 font-weight-bold'}>Zip</label>*/}
                                        <Box width={'100%'}>
                                            <CardHolderNameTextField
                                                value={cardZip}
                                                onChange={handleSetCardZip}
                                                style={{ width: '100%', border: 'none' }}
                                                size={'medium'}
                                                variant={'outlined'}
                                                placeholder={'Zip'}
                                            />
                                            {cardZipError && (
                                                <Typography
                                                    style={{ color: '#ff5151', marginTop: '4px' }}
                                                >
                                                    Please fill Zip
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                            <div
                                className="submit text-center p-8 bg-white"
                                style={{ position: 'sticky', bottom: '0' }}
                            >
                                <Button
                                    form="stripeDialog"
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    style={{ color: '#fff' }}
                                    type="submit"
                                    disabled={!stripe || isLoading}
                                >
                                    {!isLoading && (
                                        <div>
                                            Pay&nbsp;
                                            <span>
                                                {/* {props.paidAmount >= 0
                                                    ? props.paidAmount + '$US'
                                                    : ''} */}
                                                {showAmount(props.paidAmount, countryID)}
                                            </span>
                                        </div>
                                    )}
                                    {isLoading && (
                                        <div>
                                            <CircularProgress size={20} />
                                        </div>
                                    )}
                                </Button>
                            </div>
                        </>
                    )}
                    {countryID === 191 && paymentMethod === PAYMENT_METHOD.PAYNOW && (
                        <PaymentPayNow {...props} />
                    )}
                </div>
                {/* ======================================TOAST=========================================== */}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={success}
                    autoHideDuration={5000}
                    onClose={handleClose}
                >
                    <div>
                        <ToastBar
                            onClose={handleClose}
                            variant="success"
                            message="Payment successly!"
                        />
                    </div>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={error}
                    autoHideDuration={5000}
                    onClose={handleClose}
                >
                    <div>
                        <ToastBar onClose={handleClose} variant="error" message="Payment Fail!" />
                    </div>
                </Snackbar>
            </div>
        </div>
    );
};

export default CardForm;

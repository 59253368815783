import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import UploadBox from '../../../../../../components/UploadBox';
import DocumentMiniList from '../../../../../../components/DocumentMiniList';
import DocumentTitleList, { isUploaded } from '../../../../../../components/DocumentTitleList';
import { Button } from '@mui/material';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import DirectorIdentityContent from './components/DirectorIdentityContent';
import BusinessProofsContent from './components/BusinessProofsContent';
import TradingProofsContent from './components/TradingProofsContent';
import ApplicationFormsContent from './components/ApplicationFormsContent';
import mockProcess from './mocks/process.json';
import {
    createRecordFileBanking, getProcessDetail,
    getSampleDataForApplicationSet, createRecordFileInCorp, triggerMailRemindFile,
} from '../../../../../_redux/banking/api';
import {
    createFileDbS3,
    downloadFileS3,
    getSignedUrlS3,
} from '../../../../../_redux/files/filesCrud';
import { Modal } from 'react-bootstrap';
import HTMLReader from '../../../order-form/document-controller/file-detail/HTMLReader';
import NativeReader from '../../../order-form/document-controller/file-detail/NativeReader';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateActiveProcess,
    updateLoadingUploadFileAction, updateOrderInfo,
} from '../../../../../_redux/banking/slice';
import {
    approveProcessAction,
    deleteFileAction,
    getBankingProcessTreeAction, getProcessDetailById,
} from '../../../../../_redux/banking/asyncThunk';
import * as fromAsyncThunk from '../../../../../_redux/banking/asyncThunk';
import NoteFromCs from './components/NoteFromCs';
import {CORPORATE_DOCUMENTS_TEMPLATE_ID, CORPORATE_DOCUMENTS} from 'constants/banking';
import { cloneDeep } from 'lodash';
import DescriptionIcon from '@mui/icons-material/Description';


const ApplicationSet = ({ onBack, onNext }) => {
    /* ====================
    *       State
    *  ====================*/
    let [activeTitle, setActiveTitle] = useState(null);
    let [activeCate, setActiveCate] = useState(null);
    let [showModal, setShowModal] = useState(false);
    let [modalData, setModalData] = useState(null);
    let [templateUploaded , setTemplateUploaded] = useState({}) // [{ template, status}]
    const uploadBoxRef = useRef(null);
    const { loadingUploadFile, loadingDeleteFile, activeProcess, orderInfo } = useSelector(state => state.banking);
    const dispatch = useDispatch();

    /* ====================
    *       Hook
    *  ====================*/


    const process = useMemo(() => {
         return orderInfo?.Task?.Processes[0];
    }, [orderInfo]);

    const cloneCorporateDoctument = useMemo(() => {
        const temp = [...CORPORATE_DOCUMENTS];
        temp[0].Files = [...orderInfo?.Task?.Files] || [];
        return temp;
    }, [orderInfo]);

    useEffect(() => {
        if (!orderInfo) return;
        /* Set lại new activeCate khi order info thay đổi - sau khi upload hoặc delete file */
        if (activeCate) {
            const newestActiveCate = [...cloneCorporateDoctument, ...(orderInfo?.Task?.Processes[0]?.ProcessStep?.FileTemplates || [])].find(file => activeCate.id === file.id);
            setActiveCate(cloneDeep(newestActiveCate));
        }
    }, [orderInfo]);


    /* Effect xử lý trigger mail */
    useEffect(() => {
        (async () => {
            /* Khi upload đủ file và đang remind thì dừng remind */
            if (orderInfo.Task.is_remind_client_review_application_set && isHasUploaded()) {
                const payload = {
                    task_id: orderInfo.Task.id,
                    is_remind_client_review_application_set: 0,
                    process_id: activeProcess.Processes[0].id
                }
                await triggerMailRemindFile(payload);

                /* Get lại process detail data để hiển thị lại các file đã upload */
                const { data: { data } } = await getProcessDetail(activeProcess.Processes[0].id);
                dispatch(updateOrderInfo(data));
            }

            /* Khi upload không đủ file và đang stop remind thì start remind */
            if (!orderInfo.Task.is_remind_client_review_application_set && !isHasUploaded()) {
                const payload = {
                    task_id: orderInfo.Task.id,
                    is_remind_client_review_application_set: 1,
                    process_id: activeProcess.Processes[0].id
                }
                await triggerMailRemindFile(payload);

                /* Get lại process detail data để hiển thị lại các file đã upload */
                const { data: { data } } = await getProcessDetail(activeProcess.Processes[0].id);
                dispatch(updateOrderInfo(data));
            }
        })()
    }, [process]);

    /* ====================
    *       Function
    *  ====================*/
    const onUploadFile = async (files) => {

        if (!files.length || !activeTitle || !process) return;

        dispatch(updateLoadingUploadFileAction('loading'));

        const promisesPutFile = [];
        const promisesCreateRecordFile = [];
        const params = []

        for (let i = 0; i < files.length; i++) {
            params.push({ "filename": files[i].name, "content_type": files[i].type });
        }

        try {
            /* gen s3 signed url */
            const { data: { data: signedUrls } } = await getSignedUrlS3(params);

            for (let i = 0; i < signedUrls.length; i++) {
                promisesPutFile.push(axios.put(signedUrls[i].url, files[i], {}));
            }

            /* Call tất cả các hàm push file */
            await Promise.all(promisesPutFile);

            /* Xử lý create record file */
            for (let i = 0; i < signedUrls.length; i++) {
                let payload;

                if (activeTitle.file_template_id === CORPORATE_DOCUMENTS_TEMPLATE_ID) {
                    payload = {
                        file_type_id: activeTitle.file_type_id,
                        task: {
                            name: signedUrls[i].filename,
                            s3_path: signedUrls[i].filename,
                            mime_type: files[i].type,
                            task_id: orderInfo.Task.id,
                            file_type_id: activeTitle.file_type_id,
                            company_id: orderInfo?.company_id,
                            is_filled: 0
                        }
                    }
                } else {
                    payload = {
                        file_template_id: activeTitle.file_template_id,
                        name: signedUrls[i].filename,
                        s3_path: signedUrls[i].filename,
                        mime_type: files[i].type,
                        task_id: orderInfo.Task.id
                    }
                }

                if (activeTitle.company_member_id) {
                    payload.company_member_id = activeTitle.company_member_id;
                }
                if (activeTitle.ranking_partner_id) {
                    payload.ranking_partner_id = activeTitle.ranking_partner_id;
                }
                // console.log(payload, process)
                if (activeTitle.file_template_id === CORPORATE_DOCUMENTS_TEMPLATE_ID) {
                    promisesCreateRecordFile.push(createRecordFileInCorp(payload));
                } else {
                    promisesCreateRecordFile.push(createRecordFileBanking(payload));
                }
            }

            await Promise.all(promisesCreateRecordFile);

            /* Get lại process detail data để hiển thị lại các file đã upload */
            const { data: { data } } = await getProcessDetail(activeProcess.Processes[0].id);
            dispatch(updateOrderInfo(data));
            dispatch(updateLoadingUploadFileAction('idle'));
            /* reset value of input */
            uploadBoxRef.current.value = '';

        } catch (e) {
            console.log('error upload: ', e);
            dispatch(updateLoadingUploadFileAction('error'));
            toast.error('Somethings went wrong!');
            /* reset value of input */
            uploadBoxRef.current.value = '';
        }
    };
    const onClickView = async doc => {
        try {
            const { data: { data } } = await downloadFileS3(doc.s3_path);

            if (['doc', 'docx'].includes(doc.s3_path.split('.')[1].toLowerCase())) {
                saveAs(data, doc.s3_path);
                return;
            }

            setShowModal(true);
            setModalData({ file: { name: doc.s3_path }, file_url: data });
        } catch (e) {
            toast.error('Somethings when wrong!');
        }
    };

    const onClickDelete = async doc => {
        const resultAction = await dispatch(deleteFileAction(doc.id));
        if (deleteFileAction.fulfilled.match(resultAction)) {
            /* Get lại process detail data để hiển thị lại các file đã upload */
            const { data: { data } } = await getProcessDetail(activeProcess.Processes[0].id);
            dispatch(updateOrderInfo(data));
        } else {
            toast.error('Somethings went wrong!');
        }
    };

    const onSelectTitle = useCallback( ([cate, file]) => {
        setActiveCate(cate);
        setActiveTitle(file);
    },[]);

    const renderContent = () => {
        switch (activeCate?.id) {
            /*case 96:
                return <DirectorIdentityContent title={activeCate?.name + ' - ' + activeTitle?.file_name}
                    note={process?.ProcessNote[0]}
                    onClickSheet={onClickView}
                    s3Path={activeCate?.Files?.find(file => !file.is_filled)?.s3_path}
                    onNoteCsFileClick={onClickView} />;*/
            case 96:
            case 97:
                return <TradingProofsContent name={activeTitle?.file_name} />;
            case 98:
                return <ApplicationFormsContent s3Path={activeCate?.Files?.find(file => !file.is_filled)?.s3_path}
                                                onClickSheet={onClickView}/>;
            case 119:
                return <>
                    <h6 className={'pb-3'}>Business proofs - {activeTitle?.file_name}</h6>
                    <p className={'mb-0'}>Lasted 6 months bank statement (if any)</p>
                    <br/><br/>
                </>;
            case 120:
                return <>
                    <h6 className={'pb-3'}>Business proofs - {activeTitle?.file_name}</h6>
                    <p className={'mb-0'}>
                        Your Counterparts/ Sister-Mother company/ Subsidiaries company - {orderInfo?.Task?.RankingPartners?.find(partner => partner.ranking_partner_type_id === 3)?.name}:
                    </p>
                    <ol className={'ml-4 pl-4'}>
                        <li>Certificate of Incorporation</li>
                        <li>Memorandum of Articles</li>
                        <li>Other documents (if any)</li>
                        <li>Lasted 6 months bank statement (if any)</li>
                    </ol>
                    <br/><br/>
                </>;
            case CORPORATE_DOCUMENTS_TEMPLATE_ID:
                return <BusinessProofsContent name={activeTitle?.file_name} activeTitle={activeTitle}/>;
            default:
                return '';
        }
    }

    const renderNote = () => {
        return process?.ProcessNote[0] && (process?.ProcessNote[0].description || process?.ProcessNote[0].Files.length > 0)
            ? <NoteFromCs note={process?.ProcessNote[0].description}
                files={process?.ProcessNote[0].Files}
                onFileClick={onClickView} />
            : ''
    }

    const uploadList = useMemo(() => {
        if (activeCate?.id === CORPORATE_DOCUMENTS_TEMPLATE_ID) {
            return orderInfo?.Task?.Files?.filter(file => file.file_type_id === activeTitle?.file_type_id) || [];
        }
        return activeCate?.Files.filter((file) => {
            /* không thể check === vì giá trị có thể undefined hoặc null */
            return file.file_template_id == activeTitle.file_template_id
                && file.ranking_partner_id == activeTitle.ranking_partner_id
                && file.company_member_id == activeTitle.company_member_id
                && file.is_filled;
        }) || []
    }, [activeCate, activeTitle]);


    const closeModal = () => {
        setShowModal(false);
    };

    const downloadNativePDF = () => {
        return new Promise((resolve, reject) => {
            saveAs(modalData.file_url, modalData.file.name);
            resolve(true);
        })
    };

    const isCheckValid = () => {
        return true; // bỏ qua điều kiện check đã upload tất cả các file mới được next
        // return isHasUploaded()
    }

    const isHasUploaded = () => {
        return process?.ProcessStep.FileTemplates.every(template => {
            return template.titles.every(title => isUploaded(title, template))
        }) && CORPORATE_DOCUMENTS.every(template => {
            return template.titles.every(title => {
                return (orderInfo?.Task?.Files || []).filter(uploadedFile => {
                    return uploadedFile.file_type_id == title.file_type_id;
                }).length > 0
            })
        })
    }

    const handleNext = async () => {
        if (!isCheckValid()) return;
        onNext && onNext();
    }

    return (
        <>
            <div className={'row shadow-sm rounded mb-5'}>
                <div className={'col-12 col-md-3 bg-light p-5'}>
                    <h6 className={'pb-3'}>REQUIREMENT LIST</h6>

                    <DocumentTitleList filesTemplate={[...cloneCorporateDoctument, ...process?.ProcessStep.FileTemplates] || []}
                        onSelectTitle={onSelectTitle}
                        autoSelectFirstTitle={true} />
                </div>

                <div className={'col-12 col-md-6 border-right p-5'}>
                    {renderContent()}

                    <div>
                        {renderNote()}
                    </div>
                </div>

                {activeTitle && <div className={'col-12 col-md-3 p-0 '}>
                    <h6 className={'text-center border-bottom p-5'}>UPLOAD</h6>
                    <div className={'p-3'}>
                        <UploadBox onUploadFile={onUploadFile}
                            ref={uploadBoxRef}
                            loading={loadingUploadFile === 'loading'} />
                        <DocumentMiniList documentList={uploadList}
                            onClickView={onClickView}
                            onClickDelete={onClickDelete}
                            loadingMap={loadingDeleteFile}
                        />
                    </div>
                </div>}
            </div>
            <div className={'d-flex justify-content-end'}>
                <Button variant='contained'
                    disabled={!isCheckValid()}
                    className='text-white'
                    onClick={handleNext}
                    color='primary'>
                    Next
                </Button>
            </div>

            <Modal show={showModal} onHide={closeModal} size="xl" centered>
                <Modal.Body>
                    {modalData && (
                        <>
                            {modalData && (
                                <NativeReader
                                    closeDetailModal={closeModal}
                                    data={modalData}
                                    download={downloadNativePDF}
                                />
                            )}
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ApplicationSet;

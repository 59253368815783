import store from '../../../../redux/store';


export function getDefaultNationality(api_country_id, countries = null){
    let countryReturn;
    try {
        const state = store.getState();
        const countriesCooperation = state.enum.countriesCooperation;
        const countriesForCheck = countries ? countries : countriesCooperation;
        const geoLooking = state.enum.geoLooking;

        api_country_id && countriesForCheck && (countryReturn = countriesForCheck.find((item) => item.id === api_country_id));
        if (!countryReturn && countriesForCheck) {
            geoLooking && (countryReturn = countriesForCheck.find((item) => item.id === geoLooking.id));
            !countryReturn && (countryReturn = countriesForCheck[0]);
        }
    } catch(error){console.log(error)};
    return countryReturn;
}

export function getDefaultPhonePrefix(api_country_id, ListPhonePrefix){
    let countryReturn;
    try {
        const state = store.getState();
        const geoLooking = state.enum.geoLooking;

        api_country_id && ListPhonePrefix && (countryReturn = ListPhonePrefix.find((item) => item.id === api_country_id));

        if (!countryReturn && ListPhonePrefix) {
            geoLooking && (countryReturn = ListPhonePrefix.find((item) => item.id === geoLooking.id));

            !countryReturn && (countryReturn = ListPhonePrefix[0]);
        }
    } catch(error){console.log(error)};
    return countryReturn;
}

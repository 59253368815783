import { PANAMA_ID, SVG_ID } from "constants/country";
import { DIRECTOR_TYPE_ID } from "constants/member-type";
import { calculateNumbers } from "helper";

export function validateTotalSourceOfFund() {
    return this.test(`validateTotalSourceOfFund`, '', function (value) {
        const { path, createError, parent } = this;
        let newPath = `${path}.validateTotalSourceOfFund`;

        if (value.__company_country_id !== SVG_ID || value.__notValidateSourceOfFund) return true;

        const totalAsset = calculateNumbers(
            value.asset_source_fund_cast_in_bank,
            value.asset_source_fund_other_money,
            value.asset_source_fund_real_estate
        );
        const totalIncome = calculateNumbers(
            value.income_source_fund_investment,
            value.income_source_fund_other_money,
            value.income_source_fund_self_employed
        );

        if (totalAsset <= 0 && totalIncome <= 0)
            return createError({
                path: newPath,
                message: 'Total Asset and Total Income must be greater than 0'
            });

        if (totalAsset <= 0)
            return createError({ path: newPath, message: 'Total Asset must be greater than 0' });
        if (totalIncome <= 0)
            return createError({ path: newPath, message: 'Total Income must be greater than 0' });

        return true;
    });
};

export function validateRepresentedMember(
    path,
    memberTypeId,
    commonMessage,
    flagNewMember,
    flagOldMember
) {
    return this.test(path, '', function (value) {
        const { createError } = this;
        let newPath = path;

        if (value.__isRepresented) {
            if (value.__company_country_id === PANAMA_ID && memberTypeId === DIRECTOR_TYPE_ID) {
                const secretary = value.members.some((member) => member.secretary);
                const president = value.members.some((member) => member.president);
                const treasurer = value.members.some((member) => member.treasurer);

                if (!secretary && !flagNewMember?.current && !flagOldMember?.current)
                    return createError({
                        path: newPath,
                        message: 'Must have at least one Secretary'
                    });
                if (!president && !flagNewMember?.current && !flagOldMember?.current)
                    return createError({
                        path: newPath,
                        message: 'Must have at least one President'
                    });
                if (!treasurer && !flagNewMember?.current && !flagOldMember?.current)
                    return createError({
                        path: newPath,
                        message: 'Must have at least one Treasurer'
                    });
            } else {
                const secretary = value.members.some((member) =>
                    member.CompanyPositions.filter((position) =>
                        [memberTypeId].includes(position.company_member_type_id)
                    ).some((position) => position.is_appointed)
                );

                if (!secretary && !flagNewMember?.current && !flagOldMember?.current)
                    return createError({ path: newPath, message: commonMessage });
            }
        }

        return true;
    });
};
import React, { useMemo } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const individualList = [
    {
        country_id: 23,
        name: 'Belize',
        items: [
            {
                text: `The following proofs are required for all company members Directors (or Managers for LLCs), Shareholders (or Members for LLCs), Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- A certified true copy (scanned version) of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile.`,
                type: 'p',
            },
            {
                text: `See sample of a certified true copy`,
                url: `${process.env.REACT_APP_BBCINCORP_HOST}/files/certified-true-copy-guide.pdf`,
                type: 'url',
            },
            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require certified true copy (scanned version) of address proof, which can include any of the following: Bank reference / Bank statement / Utility bill / Driver license.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Personal information sheet', type: 'li' },
                    { text: 'Item 3: Bank reference letter with at least', type: 'li' },
                    { text: '2-year relationship (*)', type: 'div' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Include Contact Information and/or Business Card of Issuer.',
                        type: 'p',
                    },
                    {
                        text: '- The Letter can be issued by your Bank, Credit Union, Investment Firm or Brokerage. No system generated Letters.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [
                    {
                        text: 'Bank Statements of 6 months or Bank reference letter within 3 months',
                        type: 'p',
                    },
                ],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [{ text: 'Item 1: C.V (Resumé)', type: 'li' }],
            },
        ],
    },
    {
        country_id: 189,
        name: 'Seychelles',
        items: [
            {
                text: `The following proofs are required for all company members Directors, Shareholders, Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- A certified true copy (scanned version) of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile.`,
                type: 'p',
            },
            {
                text: `See sample of a certified true copy`,
                url: `${process.env.REACT_APP_BBCINCORP_HOST}/files/certified-true-copy-guide.pdf`,
                type: 'url',
            },
            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require certified true copy (scanned version) of address proof, which can include any of the following: Bank reference / Bank statement / Utility bill / Driver license.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Personal information sheet', type: 'li' },
                    { text: 'Item 3: Bank reference letter with at least', type: 'li' },
                    { text: '2-year relationship (*)', type: 'div' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Include Contact Information and/or Business Card of Issuer.',
                        type: 'p',
                    },
                    {
                        text: '- The Letter can be issued by your Bank, Credit Union, Investment Firm or Brokerage. No system generated Letters.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [
                    {
                        text: 'Bank Statements of 6 months or Bank reference letter within 3 months',
                        type: 'p',
                    },
                ],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [{ text: 'Item 1: C.V (Resumé)', type: 'li' }],
            },
        ],
    },
    {
        country_id: 183,
        name: 'Samoa',
        items: [
            {
                text: `The following proofs are required for all company members Directors, Shareholders, Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- A certified true copy (scanned version) of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile.`,
                type: 'p',
            },
            {
                text: `See sample of a certified true copy`,
                url: `${process.env.REACT_APP_BBCINCORP_HOST}/files/certified-true-copy-guide.pdf`,
                type: 'url',
            },
            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require certified true copy (scanned version) of address proof, which can include any of the following: Bank reference / Bank statement / Utility bill / Driver license.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Personal information sheet', type: 'li' },
                    { text: 'Item 3: Bank reference letter with at least', type: 'li' },
                    { text: '2-year relationship (*)', type: 'div' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Include Contact Information and/or Business Card of Issuer.',
                        type: 'p',
                    },
                    {
                        text: '- The Letter can be issued by your Bank, Credit Union, Investment Firm or Brokerage. No system generated Letters.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [
                    {
                        text: 'Bank Statements of 6 months or Bank reference letter within 3 months',
                        type: 'p',
                    },
                ],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [{ text: 'Item 1: C.V (Resumé)', type: 'li' }],
            },
        ],
    },
    {
        country_id: 8,
        name: 'Anguilla',
        items: [
            {
                text: `The following proofs are required for all company members Directors, Shareholders, Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- A certified true copy (scanned version) of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile.`,
                type: 'p',
            },
            {
                text: `See sample of a certified true copy`,
                url: `${process.env.REACT_APP_BBCINCORP_HOST}/files/certified-true-copy-guide.pdf`,
                type: 'url',
            },
            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require certified true copy (scanned version) of address proof, which can include any of the following: Bank reference / Bank statement / Utility bill / Driver license.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Personal information sheet', type: 'li' },
                    { text: 'Item 3: Bank reference letter with at least', type: 'li' },
                    { text: '2-year relationship (*)', type: 'div' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Include Contact Information and/or Business Card of Issuer.',
                        type: 'p',
                    },
                    {
                        text: '- The Letter can be issued by your Bank, Credit Union, Investment Firm or Brokerage. No system generated Letters.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [
                    {
                        text: 'Bank Statements of 6 months or Bank reference letter within 3 months',
                        type: 'p',
                    },
                ],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [{ text: 'Item 1: C.V (Resumé)', type: 'li' }],
            },
        ],
    },
    {
        country_id: 32,
        name: 'British Virgin Islands',
        items: [
            {
                text: `The following proofs are required for all company members Directors, Shareholders, Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- A certified true copy (scanned version) of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile.`,
                type: 'p',
            },
            {
                text: `See sample of a certified true copy`,
                url: `${process.env.REACT_APP_BBCINCORP_HOST}/files/certified-true-copy-guide.pdf`,
                type: 'url',
            },
            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require certified true copy (scanned version) of address proof, which can include any of the following: Bank reference / Bank statement / Utility bill / Driver license.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [
                    { text: 'Item 1: Personal information sheet', type: 'li' },
                    { text: 'Item 2: Bank reference letter with at least', type: 'li' },
                    { text: '2-year relationship (*)', type: 'div' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Include Contact Information and/or Business Card of Issuer.',
                        type: 'p',
                    },
                    {
                        text: '- The Letter can be issued by your Bank, Credit Union, Investment Firm or Brokerage. No system generated Letters.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [
                    {
                        text: 'Bank Statements of 6 months or Bank reference letter within 3 months',
                        type: 'p',
                    },
                ],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [],
            },
        ],
    },
    {
        country_id: 98,
        name: 'Hong Kong',
        items: [
            {
                text: `The following proofs are required for all company members Directors, Shareholders, Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- Colored scan of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile;`,
                type: 'p',
            },
            {
                text: `- 10-second selfie video holding his/her passport and speaking their name, passport number, and the service they need.`,
                type: 'p',
            },
            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require scans of address proof, which can include any of the following: Bank reference / Bank statement / Utility bill / Driver license.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Personal information sheet', type: 'li' },
                    { text: 'Item 3: Bank reference letter with at least', type: 'li' },
                    { text: '2-year relationship (*)', type: 'div' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Include Contact Information and/or Business Card of Issuer.',
                        type: 'p',
                    },
                    {
                        text: '- The Letter can be issued by your Bank, Credit Union, Investment Firm or Brokerage. No system generated Letters.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [
                    {
                        text: 'Bank Statements of 6 months or Bank reference letter within 3 months',
                        type: 'p',
                    },
                ],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [{ text: 'Item 1: C.V (Resumé)', type: 'li' }],
            },
        ],
    },
    {
        country_id: 209,
        name: 'St. Vincent & Grenadines',
        items: [
            {
                text: `The following proofs are required for all company members Directors (or Managers for LLCs), Shareholders (or Members for LLCs), Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- A certified true copy (scanned version) of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- A certified true copy (scanned version) of driver's license / national ID;`,
                type: 'p',
            },
            {
                text: `- Bank Reference Letter / Professional Reference Letter, which must be dated within the last 6 months and include the contact details of the issuer;`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile.`,
                type: 'p',
            },
            {
                text: `See sample of a certified true copy`,
                url: `${process.env.REACT_APP_BBCINCORP_HOST}/files/certified-true-copy-guide.pdf`,
                type: 'url',
            },
            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require scans of address proof, which can include any of the following: Bank reference / Bank statement / Utility bill / Driver license.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
            {
                text: 'Proof of fund source',
                type: 'title',
            },
            {
                text: `A bank statement or relevant document confirming the source of funds and wealth.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [{ text: 'Item 1: Personal information sheet', type: 'li' }],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [{ text: 'Item 1: C.V (Resumé)', type: 'li' }],
            },
        ],
    },
    {
        country_id: 237,
        name: 'UK',
        items: [
            {
                text: `The following proofs are required for all company members Directors, Shareholders, Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- A certified true copy (scanned version) of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile.`,
                type: 'p',
            },
            {
                text: `See sample of a certified true copy`,
                url: `${process.env.REACT_APP_BBCINCORP_HOST}/files/certified-true-copy-guide.pdf`,
                type: 'url',
            },
            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require certified true copy (scanned version) of address proof, which can include any of the following: Bank reference / Bank statement / Utility bill / Driver license.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Personal information sheet', type: 'li' },
                    { text: 'Item 3: Bank reference letter with at least', type: 'li' },
                    { text: '2-year relationship (*)', type: 'div' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Include Contact Information and/or Business Card of Issuer.',
                        type: 'p',
                    },
                    {
                        text: '- The Letter can be issued by your Bank, Credit Union, Investment Firm or Brokerage. No system generated Letters.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [
                    {
                        text: 'Bank Statements of 6 months or Bank reference letter within 3 months',
                        type: 'p',
                    },
                ],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [{ text: 'Item 1: C.V (Resumé)', type: 'li' }],
            },
        ],
    },
    {
        country_id: 136,
        name: 'Marshall Islands',
        items: [
            {
                text: `The following proofs are required for all company members Directors, Shareholders, Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- A certified true copy (scanned version) of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile.`,
                type: 'p',
            },
            {
                text: `See sample of a certified true copy`,
                url: `${process.env.REACT_APP_BBCINCORP_HOST}/files/certified-true-copy-guide.pdf`,
                type: 'url',
            },
            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require certified true copy (scanned version) of address proof, which can include any of the following: Bank reference / Bank statement / Utility bill / Driver license.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Personal information sheet', type: 'li' },
                    { text: 'Item 3: Bank reference letter with at least', type: 'li' },
                    { text: '2-year relationship (*)', type: 'div' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Include Contact Information and/or Business Card of Issuer.',
                        type: 'p',
                    },
                    {
                        text: '- The Letter can be issued by your Bank, Credit Union, Investment Firm or Brokerage. No system generated Letters.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [
                    {
                        text: 'Bank Statements of 6 months or Bank reference letter within 3 months',
                        type: 'p',
                    },
                ],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [{ text: 'Item 1: C.V (Resumé)', type: 'li' }],
            },
        ],
    },
    {
        country_id: 250,
        name: 'Delaware',
        items: [
            {
                text: `The following proofs are required for all company members Directors (or Managers for LLCs), Shareholders (or Members for LLCs), Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- A certified true copy (scanned version) of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- Email address and phone number;`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile.`,
                type: 'p',
            },
            {
                text: `See sample of a certified true copy`,
                url: `${process.env.REACT_APP_BBCINCORP_HOST}/files/certified-true-copy-guide.pdf`,
                type: 'url',
            },
            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require certified true copy (scanned version) of address proof, which can include any of the following: Bank reference / Bank statement / Utility bill / Driver license.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Personal information sheet', type: 'li' },
                    { text: 'Item 3: Bank reference letter with at least', type: 'li' },
                    { text: '2-year relationship (*)', type: 'div' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Include Contact Information and/or Business Card of Issuer.',
                        type: 'p',
                    },
                    {
                        text: '- The Letter can be issued by your Bank, Credit Union, Investment Firm or Brokerage. No system generated Letters.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [
                    {
                        text: 'Bank Statements of 6 months or Bank reference letter within 3 months',
                        type: 'p',
                    },
                ],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [{ text: 'Item 1: C.V (Resumé)', type: 'li' }],
            },
        ],
    },
    {
        country_id: 41,
        name: 'Cayman Islands',
        items: [
            {
                text: `The following proofs are required for all company members Directors, Shareholders, Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- A certified true copy (scanned version) of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile.`,
                type: 'p',
            },
            {
                text1: `Note: `,
                text2: `Please ensure the certified true copy (scanned version) of your identity and address proof contains sufficient details and exact wording as the `,
                text3: `sample`,
                url: `${process.env.REACT_APP_BBCINCORP_HOST}/files/cayman/certified-true-copy-guideline-passport.pdf`,
                type: 'multi',
                className: 'mt-3 font-italic',
                style1: {
                    fontSize: '13px',
                    fontWeight: 600,
                },
            },

            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require certified true copy (scanned version) of address proof, which can include any of the following: Bank reference / Bank statement / Utility bill / Driver license.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Personal information sheet', type: 'li' },
                    { text: 'Item 3: Bank reference letter with at least', type: 'li' },
                    { text: '2-year relationship (*)', type: 'div' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Include Contact Information and/or Business Card of Issuer.',
                        type: 'p',
                    },
                    {
                        text: '- The Letter can be issued by your Bank, Credit Union, Investment Firm or Brokerage. No system generated Letters.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [
                    {
                        text: 'Bank Statements of 6 months or Bank reference letter within 3 months',
                        type: 'p',
                    },
                ],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [{ text: 'Item 1: C.V (Resumé)', type: 'li' }],
            },
        ],
    },
    {
        country_id: 17,
        name: 'Bahamas',
        items: [
            {
                text: `The following proofs are required for all company members Directors, Shareholders, Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- A certified true copy (scanned version) of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- A certified true copy (scanned version) of driver's license / national ID;`,
                type: 'p',
            },
            {
                text: `- Bank Reference Letter / Professional Reference Letter (minimum 3-year relationship), must be dated within the last 6 months and include the contact details of the Issuer;`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile.`,
                type: 'p',
            },
            {
                text: `See sample of a certified true copy`,
                url: `${process.env.REACT_APP_BBCINCORP_HOST}/files/certified-true-copy-guide.pdf`,
                type: 'url',
            },
            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require certified true copy (scanned version) of address proof, which can be Local authority tax bill / Utility bill.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Personal information sheet', type: 'li' },
                ],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [{ text: 'Item 1: C.V (Resumé)', type: 'li' }],
            },
        ],
    },
    {
        country_id: 205,
        name: 'Saint Kitts Nevis',
        items: [
            {
                text: `The following proofs are required for all company members Directors, Shareholders, Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- A certified true copy (scanned version) of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- A certified true copy (scanned version) of driver's license / national ID;`,
                type: 'p',
            },
            {
                text: `- Bank Reference Letter & Professional Reference Letter (minimum 1-year relationship), must be dated within the last 6 months and include the contact details of the Issuer;`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile.`,
                type: 'p',
            },
            {
                text: `See sample of a certified true copy`,
                url: `${process.env.REACT_APP_BBCINCORP_HOST}/files/certified-true-copy-guide.pdf`,
                type: 'url',
            },
            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require scans of address proof, which can be Bank statement / Utility bill.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [{ text: 'Item 1: Personal information sheet', type: 'li' }],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [{ text: 'Item 1: C.V (Resumé)', type: 'li' }],
            },
        ],
    },
    {
        country_id: 236,
        name: 'United Arab Emirates',
        items: [
            {
                text: `The following proofs are required for all company members Directors, Shareholders, Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- A certified true copy (scanned version) of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- A certified true copy (scanned version) of the UAE ID or visa if any.`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile.`,
                type: 'p',
            },
            {
                text: `See sample of a certified true copy`,
                url: `${process.env.REACT_APP_BBCINCORP_HOST}/files/certified-true-copy-guide.pdf`,
                type: 'url',
            },
            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require certified true copy (scanned version) of address proof, which can be Bank statement / Utility bill.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
            {
                text: 'Proof of fund source',
                type: 'title',
            },
            {
                text: `A bank statement or relevant document confirming the source of funds and wealth.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Personal information sheet', type: 'li' },
                    { text: 'Item 3: Bank reference letter with at least', type: 'li' },
                    { text: '2-year relationship (*)', type: 'div' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Include Contact Information and/or Business Card of Issuer.',
                        type: 'p',
                    },
                    {
                        text: '- The Letter can be issued by your Bank, Credit Union, Investment Firm or Brokerage. No system generated Letters.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [
                    {
                        text: 'Bank Statements of 6 months or Bank reference letter within 3 months',
                        type: 'p',
                    },
                ],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [{ text: 'Item 1: C.V (Resumé)', type: 'li' }],
            },
        ],
    },
    {
        country_id: 169,
        name: 'Panama',
        items: [
            {
                text: `The following proofs are required for all company members Directors, Shareholders, Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- A certified true copy (scanned version) of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- Personal Tax ID Number;`,
                type: 'p',
            },
            {
                text: `- Bank Reference Letter / Professional Reference Letter (minimum 2-year relationship), must be dated within the last 3 months and include the contact details of the Issuer;`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile.`,
                type: 'p',
            },
            {
                text: `See sample of a certified true copy`,
                url: `${process.env.REACT_APP_BBCINCORP_HOST}/files/certified-true-copy-guide.pdf`,
                type: 'url',
            },
            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require certified true copy (scanned version) of address proof, which can include any of the following: Bank reference / Bank statement / Utility bill / Driver license.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
            {
                text: 'Proof of fund source',
                type: 'title',
            },
            {
                text: `Copies of the last two tax returns, a letter of employment, a letter from accountant certifying your annual income, financial statements, or bank statements from the last 2 years.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [{ text: 'Item 1: Personal information sheet', type: 'li' }],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [],
            },
        ],
    },
    {
        country_id: 139,
        name: 'Mauritius',
        items: [
            {
                text: `The following proofs are required for all company members Directors, Shareholders, Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- A certified true copy (scanned version) of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- Bank Reference Letter (minimum 2-year relationship), must be dated within the last 3 months and include the contact details of the Issuer;`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile.`,
                type: 'p',
            },
            {
                text: `See sample of a certified true copy`,
                url: `${process.env.REACT_APP_BBCINCORP_HOST}/files/certified-true-copy-guide.pdf`,
                type: 'url',
            },
            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require scans of address proof, which can be Utility bill.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [{ text: 'Item 1: C.V (Resumé)', type: 'li' }],
            },
        ],
    },
    {
        country_id: 244,
        name: 'Vietnam',
        items: [
            {
                text: `The following proofs are required for all company members Directors (or Managers for LLCs), Shareholders (or Members for LLCs), Ultimate Beneficial Owners (UBOs), and Contact persons.`,
                type: 'p',
            },
            {
                text: 'Proof of identity',
                type: 'title',
            },
            {
                text: `- Colored scan of the passport (valid for at least 6 months);`,
                type: 'p',
            },
            {
                text: `- 6 scans of the passport (all pages) for all company members, 2 more copies for the bank account opening process;`,
                type: 'p',
            },
            {
                text: `- 2 copies of Personal bank account confirmation letter proving financial capacity for the investment project;`,
                type: 'p',
            },
            {
                text: `- A comprehensive Curriculum Vitae (C.V.), Resumé, or Linkedin profile.`,
                type: 'p',
            },
            {
                text: 'Please note:',
                type: 'title',
            },
            {
                text: `(1) If you are residing abroad, the documents written in foreign languages must be legalized by the Vietnamese Embassy with remaining valid fority of no less than 3 months, and couriered to Vietnam. In Vietnam, they must be translated and notarized to be valid fo use locally.`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `(2) If you are residing in Vietnam with a valid for Visa, the documents must be translated (if in foreign language) and certified true copy in Vietnam. We can support with certification for USD 2.00/page, and certified translation for USD 5.00/page. Consular legalization is required for documents in a foreign language.`,
                type: 'p',
            },
            {
                text: 'Proof of address',
                type: 'title',
            },
            {
                text: 'We require scans of address proof, which can include any of the following: Bank reference / Bank statement / Utility bill / Driver license.',
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `The address proof must clearly show the holder's full name along with physical address written in English (P.O. Box addresses are not accepted).`,
                type: 'p',
                className: 'mb-2',
            },
            {
                text: `Please note that the provided proofs must be the most recent version and dated within the last 3 months.`,
                type: 'p',
            },
        ],
        items2: [
            {
                id: 1,
                bank_id: 1,
                bank_name: 'Caye International Bank Ltd',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Personal information sheet', type: 'li' },
                    { text: 'Item 3: Bank reference letter with at least', type: 'li' },
                    { text: '2-year relationship (*)', type: 'div' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Include Contact Information and/or Business Card of Issuer.',
                        type: 'p',
                    },
                    {
                        text: '- The Letter can be issued by your Bank, Credit Union, Investment Firm or Brokerage. No system generated Letters.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 2,
                bank_id: 16,
                bank_name: 'Euro Pacific Bank',
                require_documents: [
                    {
                        text: 'Bank Statements of 6 months or Bank reference letter within 3 months',
                        type: 'p',
                    },
                ],
            },
            {
                id: 3,
                bank_id: 13,
                bank_name: 'Bank One Limited',
                require_documents: [
                    { text: 'Item 1: C.V (Resumé)', type: 'li' },
                    { text: 'Item 2: Specimen Signature Card (*)', type: 'li' },
                    { text: 'Note:', type: 'div' },
                    {
                        text: '- Must be witnessed by a Notary.',
                        type: 'p',
                    },
                ],
            },
            {
                id: 4,
                bank_id: 'all',
                bank_name: 'Other',
                require_documents: [{ text: 'Item 1: C.V (Resumé)', type: 'li' }],
            },
        ],
    },
];

export default function IndividualItemList({ country_id, parent_id, flow, bankingProcess }) {
    // country_id = 32
    const contents = useMemo(() => {
        const temp = individualList.find((el) => el.country_id === country_id) || null;
        if (temp) {
            // if (parent_id && (country_id === 98 || country_id === 209)) {
            //     return temp.items2;
            // } else {
            //     return temp.items;
            // }
            // console.log('country:', temp.country_id, temp.name);
            let results = [
                {
                    text: 'Incorporation:',
                    type: 'p2',
                },
                ...temp.items,
            ];
            if (flow === 'banking' && bankingProcess && bankingProcess.Bank) {
                results.push({
                    text: 'Banking:',
                    type: 'p2',
                });
                let idTemp = 'all';
                if (
                    bankingProcess.Bank.id === 1 ||
                    bankingProcess.Bank.id === 16 ||
                    bankingProcess.Bank.id === 13
                ) {
                    idTemp = bankingProcess.Bank.id;
                }
                const requireDocuments = temp.items2.find(
                    (item) => item.bank_id === idTemp,
                )?.require_documents;
                results = [...results, ...requireDocuments];
            }
            return results;
        }
        return [];
    }, [country_id, parent_id, bankingProcess]);

    function renderContent(index, content) {
        if (content) {
            switch (content.type) {
                case 'li': // for list item
                    return <li key={index}>{content.text}</li>;
                case 'li2': // for list item lever 2
                    return (
                        <li key={index} className="ml-4" style={{ listStyle: 'circle' }}>
                            {content.text}
                        </li>
                    );
                case 'p': // for item note
                    return (
                        <p
                            key={index}
                            className={`mb-0 ml-7-negative ${content?.className}`}
                            style={content.style}
                        >
                            {content.text}
                        </p>
                    );
                case 'p2':
                    return (
                        <h6
                            key={index}
                            className="mb-0 ml-7-negative"
                            style={{ fontSize: '13px', fontWeight: 600, ...content?.style }}
                        >
                            {content.text}
                        </h6>
                    );
                case 'div': // for list note title
                    return (
                        <div key={index} className="mt-3 ml-7-negative">
                            {content.text}
                        </div>
                    );
                case 'title': // for list note title
                    return (
                        <div key={index} className="mt-3 ml-7-negative">
                            <p
                                className="mb-0"
                                style={{ fontSize: '13px', fontWeight: 600, ...content?.style }}
                            >
                                {content.text}
                            </p>
                        </div>
                    );
                case 'url': // for list note title
                    return (
                        <div key={index}>
                            <a target="_blank" href={content?.url} className="mb-0 ml-7-negative">
                                <u>{content.text}</u>
                                <ArrowForwardIcon />
                            </a>
                        </div>
                    );
                case 'multi':
                    return (
                        <div
                            key={index}
                            className={`required-item ml-7-negative ${content?.className}`}
                        >
                            <span className={content?.className1} style={content?.style1}>
                                {content.text1 ?? ''}
                            </span>
                            <span className={content?.className2} style={content?.style2}>
                                {content.text2 ?? ''}
                            </span>
                            <a target="_blank" href={content?.url} className="required-item mb-0">
                                <u>{content?.text3}</u>
                                <ArrowForwardIcon />
                            </a>
                        </div>
                    );
                default:
                    // for text normal
                    return <div key={index}>{content.text}</div>;
            }
        }
    }

    return (
        <ul className="kyc-package-list">
            {contents.map(
                (content, index) => renderContent(index, content),
                // content.type === "li" ? (
                //     <li key={index}>{content.text}</li>
                // ) : ( <p key={index} className='mb-0'><em>{content.text}</em></p>)
            )}
        </ul>
    );
}

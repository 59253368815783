import React, { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import { Stepper, Step, StepButton, StepContent } from '@mui/material';
import Button from '@mui/material/Button';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

// data test for Banking
import { DATA } from '../../data-test';
import { getProcessDetail } from '../../../../../../_redux/banking/api';
import { updateOrderInfo } from '../../../../../../_redux/banking/slice';
import { isLLC } from '../../../../../constants/index';
import { formatCompanyMembers } from '../../../../order-form/instruction-incorporation/component/step3/step3-helper/helpers';
import {
    fetchCountries,
    fetchCountriesHasService,
    fetchCountriesNonRestricted,
} from '../../../../../../_redux/enum/enumActions';
import { getContactInfo } from 'app/pages/services/order-management/order-form/instruction-incorporation/component/helpers';
import { fetchOrderForm } from '../../../../../../_redux/order-form/orderFormActions';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { BVI_ID, PANAMA_ID } from 'constants/country';
// const orderInfo = DATA;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

const steps = [
    'Contact information',
    'Company information',
    'Company structure',
    'Business information',
];

export default function BusinessPlanFormStepper() {
    const { id } = useParams();
    const classes = useStyles();

    const [activeStepBusinessForm, setActiveStepBusinessForm] = useState(0);
    const [step4Back, setStep4Back] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [body, setBody] = useState(null);
    const formikRef = useRef(null);
    const dispatch = useDispatch();

    const [isHongkong, setIsHongkong] = useState(false);
    const [isDelaware, setIsDelaware] = useState(false);
    const [isBahamas, setIsBahamas] = useState(false);
    const [isSVG, setIsSVG] = useState(false);

    const { orderInfo, orderForm, currencies } = useSelector(
        (state) => ({
            orderForm: state?.orderForm?.orderInfo,
            orderInfo: state?.banking?.orderInfo,
            currencies: state?.enum?.currencies?.data,
        }),
        shallowEqual,
    );

    /* Khi kiểm tra logic, điều kiện sử dụng countries (all) để check nhằm tránh bị sót, còn khi hiển thị thì sử dụng đúng country has service hoặc country cooperation */
    const { countries, phonePrefixCountriesCooperation } = useSelector((state) => state.enum);

    const hasShowSelectFullName = useMemo(() => {
        return [BVI_ID, PANAMA_ID].includes(orderInfo?.Company?.country_id);
    }, []);

    useEffect(() => {
        dispatch(fetchCountriesHasService());
        dispatch(fetchCountries());
        dispatch(fetchCountriesNonRestricted());
        dispatch(fetchOrderForm(id));
    }, []);

    useEffect(() => {
        try {
            setIsLoading(true);
            if (countries && orderInfo) {
                let country,
                    phone_prefix,
                    phone_country_id,
                    country_operations = [];

                let company_country_id;
                if (orderInfo?.Company?.country_id === 250) {
                    // Delaware(id 250) thuộc United States(id 238)
                    company_country_id = 238;
                } else {
                    company_country_id = orderInfo?.Company.country_id;
                }
                countries.some((item) => {
                    if (item.id === company_country_id) {
                        country = item;
                        return true;
                    }
                });

                if (orderInfo?.Task.phone_country_id) {
                    phone_country_id = orderInfo.Task.phone_country_id;
                } else {
                    phone_country_id = company_country_id;
                }
                phonePrefixCountriesCooperation &&
                    phonePrefixCountriesCooperation.some((item) => {
                        if (item.id === phone_country_id) {
                            phone_prefix = item;
                            return true;
                        }
                    });

                // == country_operations
                if (orderInfo.Company?.OperationCountries) {
                    country_operations = orderInfo.Company.OperationCountries.map((item) => {
                        let temp = countries && countries.find((e) => e.id === item.id);
                        return { ...temp, value: item.id };
                    });
                } else {
                    country_operations = [];
                }

                let data = {
                    country: country,
                    phone_prefix: phone_prefix,
                    number_of_share_standard: 50000,
                    number_of_share_over: 50000,
                    par_value: 1,
                    currency_unit: 'USD',
                    // is_has_director: true, // show data, will be deleted after finished . @TODO @TODELETE ========================================================
                    // is_has_shareholder: true, // show data, will be deleted after finished . @TODO @TODELETE ========================================================

                    // set default điều kiện cần người ký đại diện
                    represented_director: false,
                    represented_shareholder: false,
                    represented_ubo: false,
                };

                // represented: reset điều kiện cần người ký đại diện theo data api
                if (orderInfo?.represented_type_ids?.length) {
                    orderInfo.represented_type_ids.map((id) => {
                        switch (id) {
                            case 1:
                                data.represented_director = true;
                                break;
                            case 2:
                                data.represented_shareholder = true;
                                break;
                            case 3:
                                data.represented_ubo = true;
                                break;
                        }
                    });
                }

                data = {
                    ...data,
                    Represented: {
                        Director: {
                            label: 'Confirm to act as authorized signatory',
                            errorMessage: 'You must have Confirm to act as authorized signatory!',
                        },
                        // Shareholder: {
                        //     label: "Responsible Party Signing the ss4 Form",
                        //     errorMessage: "You must choose one Responsible party signing the ss4 form!",
                        // },
                        Shareholder: {
                            label: 'Consent to act as authorized signatory',
                            errorMessage: 'You must have Consent to act as authorized signatory!',
                        },
                        UBO: {
                            label: 'Consent to act as authorized signatory',
                            errorMessage: 'You must have Consent to act as authorized signatory!',
                        },
                    },
                };

                if (orderInfo.Company.country_id === 250) {
                    data.Represented.Director = {
                        label: 'Responsible Party Signing the ss4 Form',
                        errorMessage: 'You must choose one Responsible party signing the ss4 form!',
                    };
                }
                const CountriesHasRepresentedType2 = [136, 183, 169, 205]; // Marshall, Samoa, Panama, St. Kitts & Nevis
                if (
                    CountriesHasRepresentedType2.find((id) => id === orderInfo.Company.country_id)
                ) {
                    data.Represented.Director = {
                        label: 'Consent to act as secretary',
                        errorMessage: 'You must have Consent to act as secretary!',
                    };
                }
                if (
                    orderInfo.Company.country_id === 209 &&
                    orderInfo.Company.entity_type_id === 11
                ) {
                    // SVG LLC
                    data.Represented.Director = {
                        label: 'Consent to act as secretary',
                        errorMessage: 'You must have Consent to act as secretary!',
                    };
                }

                if (
                    orderInfo.Company.country_id === 23 &&
                    orderInfo.Company.entity_type_id === 11
                ) {
                    // Belize LLC
                    data.Represented.UBO = {
                        label: 'Consent act as Signatory',
                        errorMessage: 'You must have Consent act as Signatory!',
                    };
                }

                // const CountriesHasRepresentedType2 = [183, 136, 169] // Samoa, Marshall, Panama
                // if (CountriesHasRepresentedType2.find(id => id === orderInfo.Company.country_id)) {
                //     data = {
                //         ...data,
                //         Represented: {
                //             label: "Consent to act as secretary",
                //             errorMessage: "You must have Consent to act as secretary!",
                //         }
                //     }
                // } else {
                //     data = {
                //         ...data,
                //         Represented: {
                //             label: "Responsible Party Signing the ss4 Form",
                //             errorMessage: "You must choose one Responsible party signing the ss4 form!",
                //         }
                //     }
                // }

                if (orderInfo.Company?.country_id === 98) {
                    // Hong Kong
                    setIsHongkong(true);
                    data = {
                        ...data,
                        isHongkong: true,
                        number_of_share_standard: 10000,
                        number_of_share_over: 10000000,
                        currency_unit: 'HKD',
                    };
                }
                if (orderInfo.Company?.country_id === 250) {
                    // Delaware
                    setIsDelaware(true);
                    data = {
                        ...data,
                        number_of_share_standard: 1000,
                        number_of_share_over: 1000,
                    };
                }
                if (orderInfo.Company?.country_id === 169) {
                    // Panama
                    data = {
                        ...data,
                        isPanama: true,
                        number_of_share_standard: 100,
                        number_of_share_over: 10000,
                        par_value: 100,
                    };
                }
                if (orderInfo.Company?.country_id === 209) {
                    // SVG
                    setIsSVG(true);
                    data = {
                        ...data,
                        isSVG: true,
                    };
                }
                if (orderInfo.Company?.country_id === 17) {
                    // Bahamas id = 17
                    setIsBahamas(true);
                    data = {
                        ...data,
                    };
                }
                switch (orderInfo?.Company?.country_id) {
                    case 41:
                        data = { ...data, isCayman: true };
                        break;
                    case 17:
                        data = { ...data, isBahamas: true };
                        break;
                    case 23:
                        data = { ...data, isBelize: true };
                        break;
                    case 32:
                        data = { ...data, isBVI: true };
                        break;
                    case 136:
                        data = { ...data, isMarshall: true };
                        break;
                    default:
                        break;
                }
                // if (orderInfo?.Company?.country_id === 17 || orderInfo?.Company?.country_id === 23 || orderInfo?.Company?.country_id === 32) {
                //     data = {
                //         ...data,
                //         showNotification: true,
                //     }
                // }

                // check UK LLP
                if (orderInfo.Company?.country_id === 237) {
                    data = {
                        ...data,
                        isUK: true,
                    };
                    if (orderInfo.Company?.entity_type_id === 7) {
                        data = {
                            ...data,
                            is_UK_LLP: true,
                        };
                    }
                    if (orderInfo.Company?.entity_type_id === 16) {
                        data = {
                            ...data,
                            is_UK_LTD: true,
                        };
                    }
                }

                // LLC all counntry
                let checkLLC = isLLC(
                    orderInfo.Company?.entity_type_id,
                    orderInfo.Company?.country_id,
                );

                if (checkLLC) {
                    data = {
                        ...data,
                        isLLC: true,
                    };
                    if (orderInfo.Company?.country_id === 205) {
                        // St. Kitts & Nevis
                        data.number_of_share_standard = 10000;
                    } else {
                        data.number_of_share_standard = 0;
                    }
                }

                const contactInfoTemp = getContactInfo(orderInfo?.Company);
                // api data -> default data:
                data = {
                    ...data,
                    contactInfo: {
                        full_name: contactInfoTemp?.full_name || orderInfo.Task?.full_name,
                        first_name: contactInfoTemp?.first_name || orderInfo.Task?.first_name,
                        middle_name: contactInfoTemp?.middle_name || orderInfo.Task?.middle_name,
                        last_name: contactInfoTemp?.last_name || orderInfo.Task?.last_name,
                        email: contactInfoTemp.email || orderInfo.Task.email,
                        phone_number:
                            contactInfoTemp.phone_number ||
                            orderInfo.Task.phone_number ||
                            orderInfo.Customer.phone_number,
                        phone_country_id:
                            contactInfoTemp.phone_country_id ||
                            orderInfo.Task.phone_country_id ||
                            orderInfo.Customer.phone_country_id ||
                            '',
                        address:
                            contactInfoTemp.address ||
                            orderInfo.Task.address ||
                            orderInfo.Customer.address ||
                            '',
                        country_id:
                            contactInfoTemp.country_id ||
                            orderInfo.Task.country_id ||
                            orderInfo.Customer.country_id ||
                            '',
                        city:
                            contactInfoTemp.city ||
                            orderInfo.Task.city ||
                            orderInfo.Customer.city ||
                            '',
                        state:
                            contactInfoTemp.state ||
                            orderInfo.Task.state ||
                            orderInfo.Customer.state ||
                            '',
                        postal_code:
                            contactInfoTemp.postal_code ||
                            orderInfo.Task.postal_code ||
                            orderInfo.Customer.postal_code ||
                            '',
                    },
                    companyInfo: {
                        company_country: orderInfo.Company?.Country?.name,
                        company_name: orderInfo.Company?.name,
                        company_type: orderInfo.Company?.EntityType?.name,
                        company_suffix_id: orderInfo.Company?.company_suffix_id || '',
                        business_activity_array: orderInfo.Company?.business_activity_array || '',
                        business_activity_detail: orderInfo.Company?.business_activity_detail || '',
                        source_fund: orderInfo.Company?.source_fund || '',
                        OperationCountries: country_operations || '',
                        country_operations: country_operations || '',
                        operation_address: orderInfo.Company?.address || '',
                        operating_country_id: orderInfo.Company?.operating_country_id || '',
                        city: orderInfo.Company?.operating_city || '',
                        state: orderInfo.Company?.operating_state || '',
                        postal_code: orderInfo.Company?.operating_postal_code || '',
                        // country: country,
                    },
                };

                // format CompanyMembers from data api
                let companyStructureFormat = {};

                if (orderInfo?.Company?.CompanyMembers) {
                    const orderInfoTemp = cloneDeep(orderInfo);
                    orderInfoTemp.Company.CompanyMembers = orderForm?.Company?.CompanyMembers || [];

                    companyStructureFormat = formatCompanyMembers(orderInfoTemp);
                }
                // let companyStructureFormat = {};
                // if (orderInfo?.Company.CompanyMembers) {
                //     companyStructureFormat = formatCompanyMembers(orderInfo);
                // }

                let contactInfo = {
                    full_name: contactInfoTemp?.full_name ?? orderInfo.Task?.full_name ?? '',
                    first_name: contactInfoTemp?.first_name ?? orderInfo.Task?.first_name ?? '',
                    middle_name: contactInfoTemp?.middle_name ?? orderInfo.Task?.middle_name ?? '',
                    last_name: contactInfoTemp?.last_name ?? orderInfo.Task?.last_name ?? '',
                    email: contactInfoTemp?.email ?? orderInfo.Task?.email ?? '',
                    phone_number:
                        contactInfoTemp?.phone_number ?? orderInfo.Task?.phone_number ?? '',
                    address: contactInfoTemp?.address ?? orderInfo.Task?.address ?? '',
                    city: contactInfoTemp?.city ?? orderInfo.Task?.city ?? '',
                    state: contactInfoTemp?.state ?? orderInfo.Task?.state ?? '',
                    postal_code: contactInfoTemp?.postal_code ?? orderInfo.Task?.postal_code ?? '',
                    phone_country_id:
                        contactInfoTemp?.phone_country_id ?? orderInfo?.Task?.phone_country_id,
                    country_id: contactInfoTemp?.country_id ?? orderInfo?.Task?.country_id ?? '',
                    country:
                        countries?.find(
                            (item) =>
                                item.id == contactInfoTemp?.country_id ||
                                item.id == orderInfo?.Task?.country_id,
                        ) || {},
                    phonePrefix:
                        countries?.find(
                            (item) =>
                                item.id == contactInfoTemp?.phone_country_id ||
                                item.id == orderInfo?.Task?.phone_country_id,
                        ) || {},
                };

                let Questions = {};
                if (orderInfo?.Task?.IncorporationCountry?.Questions) {
                    orderInfo.Task.IncorporationCountry.Questions.map((item) => {
                        switch (item.id) {
                            case 20:
                                Questions = {
                                    ...Questions,
                                    nature_ownership: item,
                                };
                                break;
                            case 21:
                                Questions = { ...Questions, nature_voting_rights: item };
                                break;
                            case 22:
                                Questions = { ...Questions, nature_appoint_majority: item };
                                break;
                            case 23:
                                Questions = {
                                    ...Questions,
                                    nature_other_significant_control: item,
                                };
                                break;
                            case 24:
                                Questions = { ...Questions, firm: item };
                                break;
                            case 25:
                                Questions = { ...Questions, trust: item };
                                break;
                            case 26:
                                Questions = { ...Questions, firm_ownership: item };
                                break;
                            case 27:
                                Questions = { ...Questions, firm_voting_rights: item };
                                break;
                            case 28:
                                Questions = { ...Questions, firm_appoint_majority: item };
                                break;
                            case 29:
                                Questions = { ...Questions, firm_other_significant_control: item };
                                break;
                            case 30:
                                Questions = { ...Questions, trust_ownership: item };
                                break;
                            case 31:
                                Questions = { ...Questions, trust_voting_rights: item };
                                break;
                            case 32:
                                Questions = { ...Questions, trust_appoint_majority: item };
                                break;
                            case 33:
                                Questions = { ...Questions, trust_other_significant_control: item };
                                break;
                        }
                    });
                }

                const newData = {
                    ...orderInfo,
                    ...data,
                    companyStructure: companyStructureFormat,
                    QuestionsIncorp: Questions,
                    contactInfo,
                };
                setBody(newData);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }, [countries, phonePrefixCountriesCooperation, orderInfo, orderForm]);

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <Step1
                        activeStep={activeStepBusinessForm}
                        setActiveStep={setActiveStepBusinessForm}
                        formikRef={formikRef}
                        hasShowSelectFullName={hasShowSelectFullName}
                    />
                );
            case 1:
                return (
                    <Step2
                        activeStep={activeStepBusinessForm}
                        setActiveStep={setActiveStepBusinessForm}
                        formikRef={formikRef}
                    />
                );
            case 2:
                return (
                    <Step3
                        activeStep={activeStepBusinessForm}
                        setActiveStep={setActiveStepBusinessForm}
                        step4Back={step4Back}
                        setStep4Back={setStep4Back}
                        formikRef={formikRef}
                        isHongkong={isHongkong}
                        isDelaware={isDelaware}
                        isSVG={isSVG}
                        body={body}
                        setBody={setBody}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        hasShowSelectFullName={hasShowSelectFullName}
                    />
                );
            case 3:
                return (
                    <Step4
                        activeStep={activeStepBusinessForm}
                        setActiveStep={setActiveStepBusinessForm}
                        setStep4Back={setStep4Back}
                        formikRef={formikRef}
                        body={body}
                        setBody={setBody}
                        currencies={currencies}
                    />
                );
            default:
                return 'Unknown step';
        }
    }

    // function onSubmitForm(index) {
    //     if (formikPreventiveRef.current && formikRef.current) {
    //         formikPreventiveRef.current.handleSubmit();
    //         formikRef.current.handleSubmit();
    //         if (formikPreventiveRef.current.isValid) {
    //             if (formikRef.current.isValid) {
    //                 setActiveStep(index);
    //             }
    //         }
    //     } else {
    //         if (formikRef.current) {
    //             formikRef.current.handleSubmit();
    //             if (formikRef.current.isValid) {
    //                 setActiveStep(index);
    //             }
    //         } else {
    //             setActiveStep(index);
    //         }
    //     }
    // }

    const handleNext = () => {
        setActiveStepBusinessForm(1);
        // setActiveStepBusinessForm((prevActiveStep) => prevActiveStep + 1);
    };

    // const handleBack = () => {
    //     setActiveStepBusinessForm((prevActiveStep) => prevActiveStep - 1);
    // };

    return (
        <>
            <div className={'wrap-stepper ' + classes.root}>
                <Stepper
                    className="stepper p-0"
                    nonLinear
                    activeStep={activeStepBusinessForm}
                    orientation="vertical"
                >
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepButton
                                className="step-title no-click"
                                disableTouchRipple
                                // onClick={() => { setActiveStepBusinessForm(index) }} // set tạm để click chuyển step trên title, xong sẽ xóa
                            >
                                {label}
                            </StepButton>
                            <StepContent>{getStepContent(index)}</StepContent>
                        </Step>
                    ))}
                </Stepper>
            </div>
        </>
    );
}

import { createSlice } from '@reduxjs/toolkit';
import {
    getAdditionalProcessTreeAction,
    approveProcessAction, getProcessDetailById, deleteFileAction, updateOrderFormBookkeeperAction
} from './asyncThunk';

export const initState = {
    orderInfo: null,
    loadOrderInfo: 'idle', // loading, error

    activeProcess: null,

    /* process tree */
    processTree: null,
    loadingProcessTree: 'idle',
    errorProcessTree: null,

    /* Approve process state */
    loadingApproveProcess: 'idle',
    errorApproveProcess: null,

    /* State for delete file */
    loadingDeleteFile: {},
};

export const additionalServiceSlice = createSlice({
    name: 'additionalService',
    initialState: initState,
    reducers: {
        updateOrderInfo: (state, action) => {
            state.orderInfo = action.payload;
        },
        updateLoadOrderInfo: (state, action) => {
            state.loadOrderInfo = action.payload;
        },
        updateActiveProcess: (state, action) => {
            state.activeProcess = action.payload;
        },
        updateLoadingUploadFileAction: (state, action) => {
            state.loadingUploadFile = action.payload;
        },
        updateErrorUploadFileAction: (state, action) => {
            state.errorUploadFile = action.payload;
        },
        resetState: (state) => {
            state.orderInfo = null;
            state.loadOrderInfo = 'idle';
            state.activeProcess = null;
            state.processTree = null;
            state.loadingProcessTree = 'idle';
            state.errorProcessTree = null;
            state.loadingApproveProcess = 'idle';
            state.errorApproveProcess = null;
        }
    },
    extraReducers: (builder) => {
        // getListBanking
        builder.addCase(getProcessDetailById.pending, (state, action) => {
            state.loadOrderInfo = 'loading';
        });
        builder.addCase(getProcessDetailById.fulfilled, (state, action) => {
            state.loadOrderInfo = 'idle';
            state.orderInfo = action.payload;
        });
        builder.addCase(getProcessDetailById.rejected, (state, action) => {
            state.loadOrderInfo = 'error';
        });

        // Banking process tree
        builder.addCase(getAdditionalProcessTreeAction.pending, (state, action) => {
            state.loadingProcessTree = 'loading';
        });
        builder.addCase(getAdditionalProcessTreeAction.fulfilled, (state, action) => {
            state.processTree = action.payload?.filter(process => process.id !== 38) || [];
            state.loadingProcessTree = 'idle';
        });
        builder.addCase(getAdditionalProcessTreeAction.rejected, (state, action) => {
            state.loadingProcessTree = 'error';
            if (action.payload) {
                state.errorProcessTree = action.payload
            } else {
                state.errorProcessTree = action.error
            }
        });

        // Approve process
        builder.addCase(approveProcessAction.pending, (state, action) => {
            state.loadingApproveProcess = 'loading';
        });
        builder.addCase(approveProcessAction.fulfilled, (state, action) => {
            state.loadingApproveProcess = 'idle';
        });
        builder.addCase(approveProcessAction.rejected, (state, action) => {
            state.loadingApproveProcess = 'error';
            if (action.payload) {
                state.errorApproveProcess = action.payload
            } else {
                state.errorApproveProcess = action.error
            }
        });

        // Delete file
        builder.addCase(deleteFileAction.pending, (state, action) => {
            state.loadingDeleteFile[action.meta.arg] = 'loading';
        });
        builder.addCase(deleteFileAction.fulfilled, (state, action) => {
            state.loadingDeleteFile[action.meta.arg] = 'idle';
        });
        builder.addCase(deleteFileAction.rejected, (state, action) => {
            state.loadingDeleteFile[action.meta.arg] = 'error';
        });

        // update order form bookkeeper
        builder.addCase(updateOrderFormBookkeeperAction.fulfilled, (state, action) => {
            state.loadOrderInfo = 'idle';
            state.orderInfo = action.payload;
        });
        builder.addCase(updateOrderFormBookkeeperAction.rejected, (state, action) => {
            state.loadOrderInfo = 'error';
        });
    },

});

const { actions, reducer } = additionalServiceSlice;
export const {
    updateOrderInfo,
    updateLoadOrderInfo,
    updateActiveProcess,
    updateLoadingUploadFileAction,
    resetState
} = actions
export default reducer

import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../_redux/order-form/orderFormActions';
import {
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
} from '_metronic/_helpers';
import { Pagination } from '_metronic/_partials/controls';
import * as columnFormatters from './column-formatters';
import * as uiHelpers from '../OrdersUIHelpers';
import { useOrdersUIContext } from '../OrdersUIContext';
import * as actionsEnum from '../../../../_redux/enum/enumActions';

export function OrderTable() {
    const navigate = useNavigate();

    // Customers UI Context
    const ordersUIContext = useOrdersUIContext();
    const ordersUIProps = useMemo(() => {
        return {
            ids: ordersUIContext.ids,
            setIds: ordersUIContext.setIds,
            queryParams: ordersUIContext.queryParams,
            setQueryParams: ordersUIContext.setQueryParams,
            onTableChange: ordersUIContext.onTableChange,
            sort: ordersUIContext.sort,
        };
    }, [ordersUIContext]);

    const dispatch = useDispatch();
    const { serviceTypeMap } = useSelector((state) => state.enum);

    useEffect(() => {
        dispatch(actionsEnum.getServiceType());
    }, []);

    useEffect(() => {
        ordersUIProps.setIds([]);
        // dispatch(actions.fetchOrders(ordersUIProps.queryParams));
        // dispatch(actions.fetchOrders());
        // dispatch(actions.fetchOrders(uiHelpers.initialFilter));
    }, [ordersUIProps.queryParams, dispatch]);

    // Getting curret state of order list from store (Redux)
    const { currentState, countriesHasService } = useSelector((state) => {
        return {
            currentState: state.orderForm,
            countriesHasService: state.enum.countriesHasService,
        };
    }, shallowEqual);
    const { totalCount, entities, listLoading } = currentState;

    const columns = [
        {
            dataField: 'id',
            text: 'ORDER ID',
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
            headerStyle: {
                width: '10%'
            }
        },
        {
            dataField: 'created_time',
            text: 'DATE',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.DateFormatter,
            headerStyle: {
                width: '10%'
            }
        },
        {
            dataField: 'Task.IncorporationCountry.name',
            text: 'COUNTRY',
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: (cellContent, row) => (
                <>{row.Company.Country?.name || row.Task?.IncorporationCountry?.name}</>
            ),
            headerStyle: {
                width: '15%'
            }
        },
        {
            dataField: 'OrderItems',
            text: 'TYPE',
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.TypeFormatter,
            headerStyle: {
                width: '15%'
            }
        },
        {
            dataField: 'Task.Processes',
            text: 'STATUS',
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.StatusFormatter,
            formatExtraData: {
                serviceTypeMap,
            },
        },
        {
            dataField: 'detail',
            text: 'DETAIL',
            formatter: columnFormatters.DetailFormatter,
            formatExtraData: {
                navigate,
                dispatch,
                countriesHasService,
                serviceTypeMap,
            },
        },
    ];

    // function handleClickDetail(row){
    //     history.push('/order-management/order/' + row.id + '/incorporation')
    // }

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount, // tổng số order
        sizePerPageList: uiHelpers.sizePerPageList,
        // sizePerPage: 10,
        // page: 1,
        sizePerPage: ordersUIProps.queryParams.pageSize,
        page: ordersUIProps.queryParams.pageNumber,
    };
    return (
        <>
            {/* <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
            >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
            </BootstrapTable> */}
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            // isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                bordered={false}
                                classes="table-auto table-fixed table-head-custom table-vertical-center overflow-hidden order-list-table"
                                bootstrap4
                                remote
                                keyField="id"
                                data={entities === null ? [] : entities}
                                columns={columns}
                                defaultSorted={uiHelpers.defaultSorted}
                                sort={ordersUIProps.sort}
                                onTableChange={ordersUIProps.onTableChange}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities} />
                                <NoRecordsFoundMessage entities={entities} />
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    );
}

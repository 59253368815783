export function categoryColor(id) {
    let color = "";
    switch (id) {
        case 0:
            color = '#b7b7b7'
            break;
        case 1:
            color = '#34bfa3'
            break;
        case 2:
            color = '#5867dd'
            break;
        case 3:
            color = '#fd7e14'
            break;
        case 4:
            color = '#007bff'
            break;
        case 5:
            color = '#6f42c1'
            break;
        case 6:
            color = '#6610f2'
            break;
        case 7:
            color = '#e83e8c'
            break;
        case 8:
            color = '#20c997'
            break;
        case 9:
            color = '#dc3545'
            break;
        case 10:
            color = '#28a745'
            break;
        case 11:
            color = '#17a2b8'
            break;
        case 12:
            color = '#7e8299'
            break;
        case 13:
            color = '#3f4254'
            break;
        case 14:
            color = '#3699ff'
            break;
        case 15:
            color = '#778ce8'
            break;
        case 16:
            color = '#8950fc'
            break;
        case 17:
            color = '#f64e60'
            break;
        case 18:
            color = '#ffa800'
            break;
        case 19:
            color = '#b7b7b7'
            break;
        case 20:
            color = '#a06d06'
            break;
        case 21:
            color = '#00633a'
            break;
        case 22:
            color = '#3c2171'
            break;
        case 23:
            color = '#905d32'
            break;
        case 24:
            color = '#003873'
            break;
        case 25:
            color = '#ea5b5b'
            break;
        case 26:
            color = '#be35c7'
            break;
        case 27:
            color = '#560028'
            break;
        case 28:
            color = '#006b4b'
            break;
        case 29:
            color = '#ff0018'
            break;
        case 30:
            color = '#68826e'
            break;
        default: color = '#b7b7b7' // #b7b7b7
            break;
    }
    return color;
}
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { getDefaultNationality } from 'app/pages/services/utilities/NationalityHelpers';

const SelectCode = withStyles(() => ({
    root: {
        width: 150,
        border: 'none',
        '& .MuiInput-underline:before': {
            border: 'none',
        },
        '& .Mui-focused:after': {
            border: 'none',
        },
    },
}))(TextField);

const ReferenceIssue = [
    { id: 1, title: 'Lawyer' },
    { id: 2, title: 'Bank' },
    { id: 3, title: 'CPA' },
    { id: 4, title: 'Business reference' },
];

/**
 * Panama References
 */

export default function ReferenceItem({ data, formik, referenceIndex }) {
    const [phonePrefix, setPhonePrefix] = useState({ id: 0, name: '' });

    const { countries, geoLooking } = useSelector(
        (state) => ({
            countries: state.enum.countriesCooperation,
            geoLooking: state.enum.geoLooking,
        }),
        shallowEqual,
    );

    useEffect(() => {
        try {
            if (countries && geoLooking) {
                let nationality = getDefaultNationality(
                    formik.values.CompanyMemberReferences[referenceIndex].phone_country_id,
                );

                setPhonePrefix(nationality);
                formik.setFieldValue(
                    `CompanyMemberReferences.${referenceIndex}.phone_country_id`,
                    nationality.id,
                );
            }
        } catch (error) {
            console.log(error);
        }
    }, [countries, geoLooking]);

    return (
        <>
            {data?.CompanyMemberReferences[referenceIndex] && (
                <div className="reference-item">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                            <TextField
                                fullWidth
                                id={`CompanyMemberReferences.${referenceIndex}.name`}
                                name={`CompanyMemberReferences.${referenceIndex}.name`}
                                label="Name"
                                placeholder={`Enter name`}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={true}
                                value={formik.values.CompanyMemberReferences[referenceIndex].name}
                            />
                        </div>
                        <div className="col-12 mb-5 input-line">
                            <RadioGroup
                                row
                                aria-label={`CompanyMemberReferences.${referenceIndex}.company_member_issuse_reference_id`}
                                name={`CompanyMemberReferences.${referenceIndex}.company_member_issuse_reference_id`}
                            >
                                {ReferenceIssue.map((item, key) => {
                                    return (
                                        <FormControlLabel
                                            key={key}
                                            value={item.id}
                                            checked={
                                                formik.values.CompanyMemberReferences[
                                                    referenceIndex
                                                ].company_member_issuse_reference_id === item.id
                                            }
                                            control={<Radio color="secondary" />}
                                            label={item.title}
                                            disabled={true}
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                            <TextField
                                fullWidth
                                type="text"
                                id={`CompanyMemberReferences${referenceIndex}phone_number`}
                                name={`CompanyMemberReferences.${referenceIndex}.phone_number`}
                                label="Tel No."
                                placeholder="Enter phone number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={true}
                                value={
                                    formik.values.CompanyMemberReferences[referenceIndex]
                                        .phone_number
                                }
                                InputProps={{
                                    startAdornment: (
                                        <Autocomplete
                                            id={`CompanyMemberReferences${referenceIndex}phone_country_id`}
                                            name={`CompanyMemberReferences.${referenceIndex}.phone_country_id`}
                                            disableClearable
                                            options={countries || []}
                                            getOptionLabel={(option) =>
                                                `${option.country_code} +${option.phone_code}`
                                            }
                                            value={phonePrefix && phonePrefix}
                                            disabled={true}
                                            renderInput={(params) => (
                                                <SelectCode
                                                    {...params}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    ),
                                }}
                                hideHelperText
                                hideInputHelperText
                                hideSuccessHelper
                            />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                            <TextField
                                fullWidth
                                // id={`CompanyMemberReferences.${referenceIndex}.name`}
                                name={`CompanyMemberReferences.${referenceIndex}.fax`}
                                label="Fax No."
                                placeholder="Enter Fax No."
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={true}
                                value={formik.values.CompanyMemberReferences[referenceIndex].fax}
                            />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                            <TextField
                                fullWidth
                                name={`CompanyMemberReferences.${referenceIndex}.email`}
                                label="Email Address"
                                placeholder="Enter email address"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={true}
                                value={formik.values.CompanyMemberReferences[referenceIndex].email}
                            />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 kyc-frm-control">
                            <TextField
                                fullWidth
                                name={`CompanyMemberReferences.${referenceIndex}.address`}
                                label="Physical address"
                                placeholder="Enter Physical address"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={true}
                                value={
                                    formik.values.CompanyMemberReferences[referenceIndex].address
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import AddToCalendar from './add-to-calendar';
import './singapore-bank.styles.scss'
import { formatAMPM } from '../../../../../../helpers/utils';
import Moment from 'react-moment';


const WaitingAddToCalendar = ({orderInfo}) => {

    const renderBankingAddress = () => {
        if(orderInfo?.Task?.BankingProcesses?.[0].bank_address){
            return (
                <li> {orderInfo?.Task?.BankingProcesses?.[0].bank_address} </li>
            )
        }
        if(orderInfo?.Task?.BankingProcesses?.[0].bank_branch_id){
            return (
                <li> {orderInfo?.Task?.BankingProcesses?.[0].BankBranch.address} </li>
            );
        }
        return null
    }


    const clientTimeDate = useMemo(() => {
        return new Date(orderInfo.Task.BankingProcesses[0].client_time);
    }, [orderInfo])

    return (
        <section id="meeting-schedule-singapore-bank" className="meeting-schedule-singapore-bank mt-5">
            <p className="meeting-schedule-singapore-bank__title">The bank has confirmed the meeting details as follow:</p>
            <ol type="1">
                <li>Time:
                    <ul>
                        <li><Moment tz={'Asia/Singapore'}
                                    date={clientTimeDate}
                                    format='h:mm A' /> Singapore time (GMT+8) on <Moment tz={'Asia/Singapore'}
                                                                                                  date={clientTimeDate}
                                                                                                  format='dddd Do MMMM YYYY' />.</li>
                    </ul>
                </li>

                {
                    orderInfo?.Task?.BankingProcesses?.[0]?.bank_address || orderInfo.Task.BankingProcesses[0].bank_branch_id  ? (
                        <li>Venue:
                            <ul>
                                <li> {orderInfo.Task.BankingProcesses[0].Bank.name} </li>
                                { renderBankingAddress() }
                            </ul>
                        </li>
                    ) : null
                }

                {
                    orderInfo?.Task?.BankingProcesses?.[0]?.content ? (
                        <li>Calling app:
                            <ul>
                                <li> {orderInfo.Task.BankingProcesses[0].content} </li>
                            </ul>
                        </li>
                    ) : null
                }

                <li>Contact person:
                    <ul>
                        <li>{orderInfo.Task.BankingProcesses[0].contact_person}</li>
                        <li>{orderInfo.Task.BankingProcesses[0].title_job}</li>
                        <li>Phone: {orderInfo.Task.BankingProcesses[0].phone} </li>
                    </ul>
                </li>

            </ol>
            <p> The contact person will reach out to you via WhatsApp. So please pay attention to your phone to not miss the incoming call.
            </p>
            <br />
            <div className="actions">
                <AddToCalendar bankingInfo={orderInfo.Task.BankingProcesses[0]} taskId={orderInfo.Task.id}/>

            </div>
        </section>
    );
}

export default WaitingAddToCalendar;

import React, { useEffect, useState } from 'react';
import { emphasize } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import InstructionIncorporationStep3 from './step3/stepper';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // height: 250,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export default function Step3({
    setActiveStep,
    activeStep,
    step4Back,
    setStep4Back,
    isHongkong,
    isDelaware,
    isSVG,
    body,
    setBody,
    validateStepsRef,
    activeStepRef,
    isOrderFormIsFilled,
    validateStepsChildrenRef = {},
    childrenStepRef = {},
    index,
    activeStepChildrenFromParent,
    hasShowSelectFullName
}) {
    const classes = useStyles();
    const [isActiveStep, setIsActiveStep] = useState({ back: false, next: false });

    useEffect(() => {
        if (isActiveStep.back) {
            // child step 1 back
            // setActiveStep((prevActiveStep) => prevActiveStep - 1);
            setActiveStep(1);
        }
        if (isActiveStep.next) {
            // child step 4 next
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }, [isActiveStep]);

    return (
        <div>
            <div className={classes.root}>
                <InstructionIncorporationStep3
                    activeParentStep={activeStep}
                    setIsActiveParentStep={setIsActiveStep}
                    step4Back={step4Back}
                    setStep4Back={setStep4Back}
                    isHongkong={isHongkong}
                    isDelaware={isDelaware}
                    isSVG={isSVG}
                    body={body}
                    setBody={setBody}
                    validateParentStepsRef={validateStepsRef}
                    activeStepParentRef={activeStepRef}
                    isOrderFormIsFilled={isOrderFormIsFilled}
                    validateStepsRef={validateStepsChildrenRef}
                    // childrenStepRef={childrenStepRef}
                    ref={childrenStepRef}
                    activeStepChildrenFromParent={activeStepChildrenFromParent}
                    hasShowSelectFullName={hasShowSelectFullName}
                />
            </div>
        </div>
    );
}

import { find, get } from 'lodash';
import { ArticlesOfOrganizationOptions } from '../constants';

export function setArticlesOfOrganizationDefault(body) {
    return (
        body.Company.articles_of_organization ||
        (body.is_has_director || body.is_has_shareholder
            ? ArticlesOfOrganizationOptions[1].id
            : ArticlesOfOrganizationOptions[0].id)
    );
}

export function getContactInfo(Company) {
    const COMPANY_MEMBER_TYPE_ID = 7;
    const companyMembers = get(Company, 'CompanyMembers');
    const companyMember = find(companyMembers, (companyMember) =>
        find(get(companyMember, 'CompanyPositions', []), [
            'company_member_type_id',
            COMPANY_MEMBER_TYPE_ID,
        ]),
    );
    return companyMember || {};
}

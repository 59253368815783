import React from 'react';
export const ImageCropPreview = ({ completedCrop, previewCanvasRef }) => {
    return (
        <div className="symbol symbol-100 mx-auto m-3">
            <canvas
                ref={previewCanvasRef}
                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0),
                }}
            />
        </div>
    );
};

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Button } from '@mui/material';

import PageLoadingEffect from 'app/pages/services/order-management/banking/common/PageLoadingEffect';
import FileUpload from '../../../../common/upload-file/FileUpload';
import { RateUs } from '../../components/rateUs';
import UploadBox from '../../../../../../../../components/UploadBox';
import DocumentMiniList from '../../../../../../../../components/DocumentMiniList';
import {
    updateLoadingUploadFileAction,
    updateOrderInfo,
} from '../../../../../../../_redux/banking/slice';
import { downloadFileS3, getSignedUrlS3 } from '../../../../../../../_redux/files/filesCrud';
import axios from 'axios';
import { createRecordFileBanking, getProcessDetail } from '../../../../../../../_redux/banking/api';
import { toast } from 'react-toastify';
import { deleteFileAction } from '../../../../../../../_redux/banking/asyncThunk';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import NativeReader from '../../../../../order-form/document-controller/file-detail/NativeReader';
import { saveAs } from 'file-saver';


export function NoticeSuccessUploadFile({onSubmit}) {
    const [addedFiles, setAddedFiles] = useState([]);
    const [deletedFiles, setDeletedFiles] = useState([]);
    const [fileLoading, setFileLoading] = useState(false);
    const [viewedFiles, setViewedFiles] = useState([]);
    const uploadBoxRef = useRef(null);
    let [activeCate, setActiveCate] = useState(null);
    let [showModal, setShowModal] = useState(false);
    let [modalData, setModalData] = useState(null);

    const [submitSuccess, setSubmitSuccess] = useState(false);
    const { orderInfo, loadingUploadFile, loadingDeleteFile, activeProcess } = useSelector(state => state.banking)

    const dispatch = useDispatch();

    const process = useMemo(() => orderInfo?.Task?.Processes?.[0], [orderInfo]);

    useEffect(() => {
        if (!process) return;
        setActiveCate(orderInfo?.Task?.Processes?.[0]?.ProcessStep?.FileTemplates[0]);
    }, [process]);

    function handleSubmit() {
        console.log('cc', onSubmit)
        onSubmit && onSubmit();
    }

    const onUploadFile = async (files) => {
        if (!files.length || !activeCate || !process) return;

        dispatch(updateLoadingUploadFileAction('loading'));

        const promisesPutFile = [];
        const promisesCreateRecordFile = [];
        const params = []

        for (let i = 0; i < files.length; i++) {
            params.push({ "filename": files[i].name, "content_type": files[i].type });
        }

        try {
            /* gen s3 signed url */
            const { data: { data: signedUrls } } = await getSignedUrlS3(params);

            for (let i = 0; i < signedUrls.length; i++) {
                promisesPutFile.push(axios.put(signedUrls[i].url, files[i], {}));
            }

            /* Call tất cả các hàm push file */
            await Promise.all(promisesPutFile);

            /* Xử lý create record file */
            for (let i = 0; i < signedUrls.length; i++) {
                const payload = {
                    file_template_id: activeCate.id,
                    name: signedUrls[i].filename,
                    s3_path: signedUrls[i].filename,
                    mime_type: files[i].type,
                    task_id: orderInfo.Task.id
                }
                promisesCreateRecordFile.push(createRecordFileBanking(payload));
            }
            const finalResult = await Promise.all(promisesCreateRecordFile);

            /* Get lại process detail data để hiển thị lại các file đã upload */
            const { data: { data } } = await getProcessDetail(activeProcess.Processes[0].id);
            dispatch(updateOrderInfo(data));

            dispatch(updateLoadingUploadFileAction('idle'));
            /* reset value of input */
            uploadBoxRef.current.value = '';

        } catch (e) {
            // console.log(e);
            dispatch(updateLoadingUploadFileAction('error'));
            toast.error('Somethings went wrong!');
            /* reset value of input */
            uploadBoxRef.current.value = '';
        }
    };


    const closeModal = () => {
        setShowModal(false);
    };

    const downloadNativePDF = () => {
        return new Promise((resolve, reject) => {
            saveAs(modalData.file_url, modalData.file.name);
            resolve(true);
        })
    };

    const onClickView = async doc => {
        try {
            const { data: { data } } = await downloadFileS3(doc.s3_path);

            if (['doc', 'docx'].includes(doc.s3_path.split('.')[1].toLowerCase())) {
                saveAs(data, doc.s3_path);
                return;
            }

            setShowModal(true);
            setModalData({ file: { name: doc.s3_path }, file_url: data });
        } catch (e) {
            toast.error('Somethings when wrong!');
        }
    };

    const onClickDelete = async doc => {
        const resultAction = await dispatch(deleteFileAction(doc.id));
        if (deleteFileAction.fulfilled.match(resultAction)) {
            /* Get lại process detail data để hiển thị lại các file đã upload */
            const { data: { data } } = await getProcessDetail(activeProcess.Processes[0].id);
            dispatch(updateOrderInfo(data));
        } else {
            toast.error('Somethings went wrong!');
        }
    };

    return (
        <>
        <PageLoadingEffect loading={fileLoading} />

        { submitSuccess // submitSuccess hoặc đã có file: chờ data api
            ? <>
                <p className="text-center">We've received your document.</p>
                <RateUs />
            </>
            : <>
                <span className="fw-500">Congratulations!</span> Your bank account application has been approved. The account notification was sent to your email.
                <br/>
                Here is your next step:
                <ul className="list-type-check">
                    <li>Fund your account with a minimum balance of USD 10,000 within 45 days from the account opening date</li>
                </ul>
                Upon completion, upload a copy of the completed/processed wire confirmation receipt.

                <div className="d-flex mt-2 justify-content-center">
                    {!process.is_approved && <div style={{ width: '40%' }}>
                        <UploadBox onUploadFile={onUploadFile}
                                   ref={uploadBoxRef}
                                   loading={loadingUploadFile === 'loading'}
                                   panelStyle={{ height: '150px' }} />
                    </div>}
                    <div style={{ width: '60%' }} className={'pl-2'}>
                        <DocumentMiniList documentList={orderInfo?.Task?.Processes?.[0]?.ProcessStep?.FileTemplates?.[0]?.Files}
                                          onClickView={onClickView}
                                          maxHeightPanel={'100px'}
                                          onClickDelete={onClickDelete}
                                          loadingMap={loadingDeleteFile}
                                          disabledDelete={process.is_approved}
                        />
                    </div>
                </div>

                <div className="text-center mt-8">
                    <Button type="button"
                            variant="contained"
                            size="medium"
                            disabled={!orderInfo?.Task?.Processes?.[0]?.ProcessStep?.FileTemplates?.[0]?.Files?.length || process.is_approved}
                            color="primary" style={{ color: '#fff' }}
                            onClick={handleSubmit} >
                        Submit
                    </Button>
                </div>


                <Modal show={showModal} onHide={closeModal} size="xl" centered>
                    <Modal.Body>
                        {modalData && (
                            <>
                                {modalData && (
                                    <NativeReader
                                        closeDetailModal={closeModal}
                                        data={modalData}
                                        download={downloadNativePDF}
                                    />
                                )}
                            </>
                        )}
                    </Modal.Body>
                </Modal>
            </>
        }

        </>
    )
}

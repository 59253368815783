import { Box, Chip, Grid, Typography } from '@mui/material';
import React, { useMemo, useEffect, useRef } from 'react';
import { get } from 'lodash';

function SelectFullNameHintFormik({
    formik,
    fullNameField,
    firstNameField,
    middleNameField,
    lastNameField,
    disabled,
    loading
}) {
    const firstName = formik.values[firstNameField]?.trim();
    const middleName = formik.values[middleNameField]?.trim();
    const lastName = formik.values[lastNameField]?.trim();

    const fullNames = useMemo(() => {
        // name 1: Last name + Middle name + First name
        // name 2: First name + Middle name + Last name
        return middleName
            ? [`${lastName} ${middleName} ${firstName}`, `${firstName} ${middleName} ${lastName}`]
            : [`${lastName} ${firstName}`, `${firstName} ${lastName}`];
    }, [firstName, middleName, lastName]);

    return (
        <>
            {firstName && lastName && !disabled && (
                <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'} p={0}>
                    <Typography variant="caption" sx={{ mr: 1 }}>
                        Select your full name
                    </Typography>
                    {fullNames.map((fullName) => (
                        <Chip
                            sx={{ mr: 1, my: 0.5 }}
                            label={fullName}
                            disabled={loading}
                            onClick={() => {
                                formik.setFieldValue(fullNameField, fullName);
                                const errorPath = get(formik.errors, fullNameField);
                                if (errorPath) {
                                    formik.setErrors({ [fullNameField]: '' });
                                }
                            }}
                        />
                    ))}
                </Box>
            )}
        </>
    );
}

export default SelectFullNameHintFormik;

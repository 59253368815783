import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Step, StepLabel, Stepper } from '@mui/material';
import ShippingAddress from './steps/ShippingAddress';
import NotificationResult from './steps/NotificationResult';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountriesNonRestricted } from '../../../_redux/enum/enumActions';
import WaitingScreen from '../../../../components/WaitingScreen';
import {
    approveProcessAction,
    getAdditionalProcessTreeAction,
    getProcessDetailById,
} from '../../../_redux/additional-service/asyncThunk';
import { resetState, updateActiveProcess } from '../../../_redux/additional-service/slice';
import { toast } from 'react-toastify';
import { useQueryParams } from '../../../../custom-hooks';
import ProposedChange from './steps/ProposedChange';
import SignForm from './steps/SignForm';
import SignFormCalendar from '../compliance-calendar/SignForm';
import {
    ORDER_PROCESS_DOCUMENT_RETRIEVAL,
} from 'constants/processes_step';
import ChooseYears from '../compliance-calendar/ChooseYears';
import ConfirmConfirmationForm from '../bookeeping-process/ConfirmConfirmationForm';

function RenderWithLoadingError({ loading, children }) {
    return (
        <>
            {loading === 'idle' && children}
            {loading === 'loading' && (
                <Box
                    display={'flex'}
                    width={'100%'}
                    height={'100%'}
                    className={'p-5'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <CircularProgress />
                </Box>
            )}
            {loading === 'error' && <span className={'text-danger'}>Something went wrong!</span>}
        </>
    );
}

/* Page Additional Service */
function AdditionalService(props) {
    const { id: orderId } = useParams();
    const queryParams = useQueryParams();
    const [activeStep, setActiveStep] = useState(0);
    const dispatch = useDispatch();
    const { countriesCooperation, geoLooking } = useSelector((state) => state.enum);
    const {
        orderInfo,
        loadOrderInfo,
        activeProcess,
        processTree,
        loadingProcessTree,
        errorProcessTree,
        loadingApproveProcess,
        errorApproveProcess,
    } = useSelector((state) => state.additionalService);

    const countriesOptions = useMemo(() => {
        return (countriesCooperation || []).map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
        }));
    }, [countriesCooperation]);

    const phonePrefixOptions = useMemo(() => {
        return (countriesCooperation || []).map((item) => ({
            ...item,
            value: item.id,
            label: `${item.country_code} +${item.phone_code}`,
        }));
    }, [countriesCooperation]);

    useEffect(() => {
        dispatch(fetchCountriesNonRestricted());

        void getProcessTree();

        return () => {
            dispatch(resetState());
        };
    }, []);

    useEffect(() => {
        if (!activeProcess?.Processes?.[0]?.id) return;
        dispatch(getProcessDetailById(activeProcess.Processes[0].id));
    }, [activeProcess]);

    const getRefreshProcess = () => {
        return dispatch(getProcessDetailById(activeProcess.Processes[0].id));
    };

    const getProcessTree = async () => {
        const resultAction = await dispatch(
            getAdditionalProcessTreeAction({
                orderId,
                params: { service_type_id: queryParams.get('service_type_id') },
            }),
        );
        if (getAdditionalProcessTreeAction.fulfilled.match(resultAction)) {
            const { payload: processes } = resultAction;

            /* Lấy process của step */
            let newActiveProcess = null;
            let indexActiveStep = null;
            processes.forEach((processItem, index) => {
                const { Processes } = processItem;
                if (!Processes[0]) return;
                newActiveProcess = processItem;
                indexActiveStep = index;
            });

            if (!newActiveProcess) return;
            dispatch(updateActiveProcess(newActiveProcess));
            if (indexActiveStep !== null) setActiveStep(indexActiveStep);
            return;
        }
        toast.error('Somethings went wrong!');
    };

    const handleNext = async (params) => {
        /* 1. Approve step */
        const approveResult = await dispatch(
            approveProcessAction({ id: activeProcess.Processes[0].id, ...params }),
        );
        if (!approveProcessAction.fulfilled.match(approveResult)) {
            toast.error('Somethings went wrong!');
            return;
        }
        toast.success('Successfully');

        /* 2. Gọi lại process tree để next step dựa vào process tree */
        void (await getProcessTree());
    };

    function getStepContent() {
        if (
            activeProcess?.Processes[0]?.is_approved &&
            ![60, 91, 90, 107, 127].includes(activeProcess?.id)
        )
            return <WaitingScreen />;

        if (activeProcess?.Processes[0]?.is_approved && activeProcess?.id === 90)
            return (
                <WaitingScreen
                    children={
                        <p style={{ color: '#007EFF' }}>
                            Your documents are being processed. We will keep you posted as soon as
                            possible.
                        </p>
                    }
                />
            );

        switch (activeProcess?.id) {
            case 59:
                return (
                    <ShippingAddress
                        onSubmit={handleNext}
                        loading={loadingApproveProcess}
                        countriesOptions={countriesOptions}
                        phonePrefixOptions={phonePrefixOptions}
                    />
                );
            case 115:
            case 116:
            case 117:
                return <ProposedChange onSubmit={handleNext} onNeedRefresh={getRefreshProcess} />;
            case 111:
                return <SignForm onSubmit={handleNext} isUploadAllFile isCSNote />;
            case 90:
                return <SignFormCalendar orderInfo={orderInfo} onSubmit={handleNext} activeProcess={activeProcess}/>;
            case 60:
            case 91:
            case 107:
            case 127:
                return <NotificationResult orderInfo={orderInfo} />;
            case 95:
                return (
                    <ChooseYears orderInfo={orderInfo} onSubmit={handleNext}/>
                );
            //Bookeeping Process
            case 126:
                return (
                    <ConfirmConfirmationForm orderInfo={orderInfo} onNeedRefresh={getRefreshProcess} onSubmit={handleNext}/>
                )
            case ORDER_PROCESS_DOCUMENT_RETRIEVAL:
                return <WaitingScreen />;
            default:
                return 'Unknown stepIndex';
        }
    }

    return (
        <Box position={'relative'}>
            {loadingApproveProcess === 'loading' && (
                <Box
                    position={'absolute'}
                    top={0}
                    left={0}
                    width={'100%'}
                    height={'100%'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    bgcolor={'#0000000a'}
                    zIndex={1}
                >
                    <CircularProgress />
                </Box>
            )}
            <Card>
                <CardHeader title={'Order Details INC. ' + orderId} />
                <CardBody style={{ minHeight: '400px' }}>
                    <RenderWithLoadingError loading={loadingProcessTree}>
                        <Stepper activeStep={activeStep} nonLinear alternativeLabel>
                            {processTree?.map((process) => (
                                <Step key={process.name}>
                                    <StepLabel>{process.name}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <RenderWithLoadingError loading={loadOrderInfo}>
                            <div>{getStepContent()}</div>
                        </RenderWithLoadingError>
                    </RenderWithLoadingError>
                </CardBody>
            </Card>
        </Box>
    );
}

export default AdditionalService;

import { toast } from 'react-toastify';
import * as requestFromServer from './notificationCurd';
import { notificationsSlice, callTypes } from './notificationSlice';

const { actions } = notificationsSlice;

export const fetchNotifications = (queryParams, type) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getNotification(queryParams)
        .then((response) => {
            const data = response.data.data;
            // if (type === 'news')  dispatch(actions.notificationsNewsFetched({ data }));  
            // else if (type === 'message') dispatch(actions.notificationsMessageFetched({ data }));
            switch (type) {
                case 'news': dispatch(actions.notificationsNewsFetched({ data }));
                    break;
                case 'message': dispatch(actions.notificationsMessageFetched({ data }));
                    break;
            }
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't find notifications";
            toast.error('Something went wrong!');
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const countNotificationsNew = () => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .checkNotification()
        .then((response) => {
            const data = response.data.data;
            dispatch(actions.notificationsNewCount({ data }));
        })
        .catch((error) => {
            error.clientMessage = "Can't find new notifications";
            toast.error('Something went wrong!');
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const readNotification = (NotificationId) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .readNotification(NotificationId)
        .then(() => {
            dispatch(actions.notificationRead({ NotificationId }));
            // toast.success(`Notification No.${response.data.data.id} Updated Successfully!`);
        })
        .catch((error) => {
            error.clientMessage = "Can't read notification";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error('Something went wrong!');
        });
};
import { Modal } from 'react-bootstrap';
import { Button, CircularProgress, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { filter, find, get, isEmpty, last, sumBy } from 'lodash';
import { useKYCUIContext } from 'app/pages/services/order-management/order-form/kyc/KYCUIContext';
import { ACTION_TYPES, COMPANY_MEMBER_TYPES } from 'app/pages/services/constants';
import MemberTransferRemoveForm from './MemberTransferRemoveForm';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';

const MemberResignModal = ({
    content = 'Are you sure you want to remove ?',
    page = 'company',
    ...props
}) => {
    const { onChangeRequest, onDeleteRequest, onTransferShare, company, IS_LLC, IS_UK_LLP } = props;
    const { modal, closeModal } = useKYCUIContext();
    const [loading, setLoading] = useState(false);
    const [showTransfer, setShowTransfer] = useState(false);

    /* --- OPTIONS --- */
    const options = useMemo(() => {
        if (!company) {
            return { member: {}, isUBO: false, isShareholder: false, isDirector: false };
        }

        const member = modal.data;
        const position = find(member.CompanyPositions, ['id', member.pos_id]);
        const isUBO = member.company_member_type_id === COMPANY_MEMBER_TYPES.UBO.id;
        const isShareholder = member.company_member_type_id === COMPANY_MEMBER_TYPES.SHAREHOLDER.id;
        const isDirector = member.company_member_type_id === COMPANY_MEMBER_TYPES.DIRECTOR.id;
        const isCorporationChild = !!member.corporation_company_member_id;
        let memberOptions = [];
        let shareOrigin = [];

        // UBO/Shareholder
        if (!isDirector) {
            memberOptions =
                (!isEmpty(company.CompanyMembers) &&
                    company.CompanyMembers.filter(
                        ({ id, CompanyPositions }) =>
                            id !== member.id &&
                            CompanyPositions?.find((p) => {
                                if (p.corporation_company_member_id || p.is_resigned) {
                                    return;
                                }
                                if (isUBO) {
                                    return p.company_member_type_id === COMPANY_MEMBER_TYPES.UBO.id;
                                }
                                if (isShareholder) {
                                    return (
                                        p.company_member_type_id ===
                                        COMPANY_MEMBER_TYPES.SHAREHOLDER.id
                                    );
                                }
                            }),
                    )) ||
                [];

            const share = get(
                member,
                isUBO
                    ? 'CompanyInterests'
                    : isCorporationChild
                    ? !isEmpty(member.CompanyOwnerships)
                        ? 'CompanyOwnerships'
                        : 'CompanyShares'
                    : IS_LLC
                    ? 'CompanyOwnerships'
                    : 'CompanyShares',
                [],
            );

            if (!isEmpty(share)) {
                shareOrigin = share
                    .filter(Boolean)
                    .filter(
                        (companyShare) =>
                            !companyShare.is_received &&
                            !companyShare.corporation_company_member_id &&
                            companyShare.is_approved &&
                            companyShare.is_valid,
                    );
            }
        }

        return {
            member,
            position,
            shareOrigin,
            memberOptions,
            isUBO,
            isShareholder,
            isDirector,
            isCorporationChild,
        };
    }, [modal]);

    const { member, position, isCorporationChild } = options;

    /* --- ACTIONS --- */
    const handleClose = () => {
        closeModal && closeModal();
        showTransfer && setShowTransfer(false);
        form.reset(initialValues);
    };

    const submitData = async () => {
        if (onChangeRequest) {
            await onChangeRequest(
                {
                    company_member_id: member.id,
                    company_id: company.id,
                    DeletePositions: [{ company_position_id: member.pos_id }],
                },
                { isLevel1: !isCorporationChild, pos_id: member.pos_id },
            );
            toast.success('Resign member successfully');
            handleClose();
        }
    };

    /* --- LIST CHANGE REQUESTS --- */
    const changeRequests = useMemo(() => {
        const result = {
            origin: [],
            Member: [],
            Position: [],
            ChangeRequest: {},
        };

        // Danh sách change request có change_request_status_id là 2
        if (company && company.ChangeRequests && !isEmpty(company.ChangeRequests)) {
            const origin = filter(company.ChangeRequests, ['change_request_status_id', 1]);
            result.origin = origin;
        }

        // Danh sách change request của member đó
        if (result.origin && !isEmpty(result.origin) && member) {
            const Member = filter(result.origin, ['company_member_id', member.id]);
            result.Member = Member;
        }

        // Danh sách change request của member tại position
        if (result.Member && !isEmpty(result.Member) && position) {
            const Position = filter(result.Member, ['company_position_id', position.id]);
            result.Position = Position;
        }

        // Change request mới nhất được nằm ở cuối mảng của result.Position
        if (result.Position && !isEmpty(result.Position)) {
            result.ChangeRequest = last(result.Position);
        }

        return result;
    }, [company, member]);

    const handleSubmit = async () => {
        if (onChangeRequest) {
            try {
                setLoading(true);
                const { isDirector, isShareholder, isUBO, shareOrigin } = options;

                const { ChangeRequest } = changeRequests;
                if (!member.is_approved && !isEmpty(ChangeRequest)) {
                    await onDeleteRequest([ChangeRequest.id]);
                    toast.success('Resign member successfully');
                    handleClose();
                    return;
                }

                if (isDirector) {
                    await submitData();
                    return;
                }

                if (isShareholder || isUBO) {
                    if (
                        isEmpty(shareOrigin) ||
                        sumBy(shareOrigin, 'value') === 0 ||
                        isCorporationChild
                    ) {
                        return await submitData();
                    }

                    setShowTransfer(true);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleSubmitTransfer = async (values) => {
        setLoading(true);
        try {
            const companyMemberId = values.company_member_id;
            if (onTransferShare && companyMemberId) {
                const { isShareholder, isUBO, shareOrigin } = options;
                let share = {};
                if (isShareholder) {
                    if (IS_LLC) {
                        share.value = sumBy(shareOrigin, 'value');
                    } else {
                        share.share = shareOrigin.map((companyShare) => ({
                            transfer_id: companyShare?.id,
                            start_number: companyShare?.start_number,
                            end_number: companyShare?.end_number,
                            value: companyShare?.value,
                        }));
                    }
                }

                if (isUBO) {
                    share.value = sumBy(shareOrigin, 'value');
                }

                await Promise.all([
                    onTransferShare({
                        company_member_id: member.id,
                        company_id: company.id,
                        company_member_type_id: member.company_member_type_id,
                        ...share,
                        to_company_member_id: companyMemberId,
                    }),
                ]);
                handleClose();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    /* --- HOOK FORM --- */
    const initialValues = useMemo(() => {
        return {
            company_member_id: null,
        };
    }, []);

    const validationSchema = useMemo(() => {
        return Yup.object()
            .shape({
                company_member_id: Yup.number()
                    .nullable()
                    .when([], {
                        is: () => showTransfer,
                        then: Yup.number().nullable().required('Please select a transferee'),
                    }),
            })
            .required();
    }, [showTransfer]);

    const form = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        form.reset(initialValues);
    }, [initialValues]);

    /* --- TITLE MODAL --- */
    const title = useMemo(() => {
        const { isDirector, isShareholder, isUBO } = options;
        const is_LLC_LLP = IS_LLC || IS_UK_LLP;

        if (isDirector) return is_LLC_LLP ? 'Resign Manager' : 'Resign Director';
        if (isShareholder) return is_LLC_LLP ? 'Resign Member' : 'Resign Shareholder';
        if (isUBO) return 'Resign UBO';
        return 'Resign';
    }, [options]);

    return (
        <>
            <Modal
                show={
                    [ACTION_TYPES.RESIGN.name].includes(member.action) &&
                    member.is_resigned === false &&
                    modal.show
                }
                backdrop="static"
                size="lg"
                animation={false}
                centered
            >
                <form
                    onSubmit={form.handleSubmit(showTransfer ? handleSubmitTransfer : handleSubmit)}
                >
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="py-8 px-4">
                        {showTransfer ? (
                            <MemberTransferRemoveForm
                                form={form}
                                disabled={loading}
                                {...options}
                                {...props}
                            />
                        ) : (
                            <Typography>{content}</Typography>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {loading && <CircularProgress size={20} color="primary" />}
                        {showTransfer ? (
                            <>
                                <Button
                                    className="btn btn-light btn-sm"
                                    disabled={loading}
                                    onClick={handleClose}
                                    style={{ marginLeft: 15 }}
                                    type="button"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{ color: '#fff', marginLeft: 15 }}
                                    type="submit"
                                    disabled={loading}
                                >
                                    Confirm & Delete
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    className="btn btn-light btn-sm"
                                    style={{ marginLeft: 15 }}
                                    type="submit"
                                    disabled={loading}
                                >
                                    Yes, delete it!
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{ color: '#fff', marginLeft: 15 }}
                                    onClick={handleClose}
                                    type="button"
                                    disabled={loading}
                                >
                                    No, keep it!
                                </Button>
                            </>
                        )}
                    </Modal.Footer>
                    {page == 'company' && (
                        <p
                            className="mb-0 text-white p-2"
                            style={{
                                backgroundColor: '#17c191',
                                borderBottomLeftRadius: '0.42rem',
                                borderBottomRightRadius: '0.42rem',
                            }}
                        >
                            <b>
                                The changes to this information may incur additional costs, please
                                review the information in the Review and Complete section after
                                making the adjustments.
                            </b>
                        </p>
                    )}
                </form>
            </Modal>
        </>
    );
};

export default MemberResignModal;

import React, { useState } from 'react';
import KYCGraph1 from 'app/pages/components/kyc/KYCGraph1';
import { KYCUIProvider } from '../../order-form/kyc/KYCUIContext';
import { UPLOAD_BOOKEEPING } from 'constants/kyc_graph_type';
import { Button, Typography } from '@mui/material';
import LoadingButton from 'app/components/LoadingButton';
import MemberDetailModal from './components/MemberDetailModal';
import { RAK_ID, SEYCHELLES_ID } from 'constants/country';
import Swal from 'sweetalert2';

const CertifiedAddressProof = ({ task, onSubmit, handleBack, hasShowSelectFullName }) => {
    const [isLoading, setIsLoading] = useState(false);

    const isAllUploaded = () => {
        if([RAK_ID, SEYCHELLES_ID].includes(task?.Company?.country_id)) {
            let levelOneIndividualArr = task?.Company?.CompanyMembers.filter(
                (el) =>
                    !el?.CompanyPositions?.every(
                        (position) => position.corporation_company_member_id,
                    ) && el.type_member_id === 1 && !el.is_nominee,
            );
            return levelOneIndividualArr?.every(
                (member) =>
                    (member.Files.filter((file) => file.task_id === task?.Task?.id) || []).length > 0,
            );
        }
        return true;
    };

    const handleSubmit = async () => {
        try {
            Swal.fire({
                title: '',
                text: 'Are you sure to finish this step and go to the next one?',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                confirmButtonColor: '#17C191',
                reverseButtons: true,
                allowOutsideClick: false
            }).then(async result => {
                if(result.isConfirmed) {        
                    setIsLoading(true);
                    onSubmit && await onSubmit();
                }
            })
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <KYCUIProvider>
                <KYCGraph1 info={task} type={UPLOAD_BOOKEEPING} />
                <MemberDetailModal
                    disabled={true}
                    company={task?.Company}
                    task={task?.Task}
                    hasShowSelectFullName={hasShowSelectFullName}
                    page="bookeeping"
                    uploadDisabled={(member) => {
                        if (
                            [RAK_ID, SEYCHELLES_ID].includes(task?.Company?.country_id) &&
                            member.type_member_id == 1 &&
                            !!!member.corporation_company_member_id
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    }}
                />
            </KYCUIProvider>
            <Typography sx={{ marginTop: '16px', marginBottom: '4px' }} fontWeight={'bold'}>
                Note:
            </Typography>
            <Typography>
                Address proof can include any of the following: Bank reference/ Bank statement/
                Utility bill/ Driver license.
            </Typography>
            <Typography>
                The address proof must clearly show the holder's full name along with physical
                address written in English, must be the most recent version and dated within the
                last 3 months. (P.O. Box addresses are not accepted).
            </Typography>
            <div style={{ marginTop: '16px' }}>
                <Button
                    size="large"
                    variant="contained"
                    disableElevation
                    className="mr-2"
                    onClick={handleBack}
                    disabled={isLoading}
                >
                    Back
                </Button>
                <LoadingButton
                    disabled={isLoading || !isAllUploaded()}
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    style={{ color: !isLoading ? '#fff' : 'transparent' }}
                    loading={isLoading}
                    onClick={() => handleSubmit()}
                >
                    Next
                </LoadingButton>
            </div>
        </>
    );
};
export default CertifiedAddressProof;

import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actionsIncorporation from 'app/pages/_redux/incorporation/incorporationActions';
import AddIcon from '@mui/icons-material/Add';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import PreventiveComponent from './preventive';

import { checkProposedName } from 'app/pages/components/check-name/check-proposed-name';
import { RecommendName } from 'app/pages/components/check-name/recommend-name';

import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from 'components/form-controls/InputField';
import { isEmpty } from 'lodash';
import SelectField from 'components/form-controls/SelectField';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export default function Step1({
    body,
    setBody,
    setActiveStep,
    formRef,
    formPreventiveRef,
    isLoading,
    setIsLoading,
    setSelectedRadio,
    onSubmitForm,
}) {
    const classes = useStyles();
    const isMounted = useRef(null);
    const [preventiveName, setPreventiveName] = useState([]);

    const [currentCountryData, setCurrentCountryData] = useState(null);
    const [country, setCountry] = useState({ name: null, id: 0 });
    const [companyType, setCompanyType] = useState({ name: '', id: 0 });
    const [companySuffixOptions, setCompanySuffixOptions] = useState([]);
    const [companySuffix, setCompanySuffix] = useState({ name: '', id: 0 });
    const [isPrefix, setIsPrefix] = useState(0);
    const [companyNameLabel, setCompanyNameLabel] = useState('');

    const [isVietnam, setIsVietNam] = useState(false);

    const dispatch = useDispatch();
    const [initPreventiveNames, setInitPreventiveNames] = useState(
        body.preventives ? { preventives: body.preventives } : { preventives: [] },
    );
    const { currentState } = useSelector((state) => ({ currentState: state.enum }), shallowEqual);
    const { countriesHasService } = currentState;
    const { incorporationState } = useSelector(
        (state) => ({ incorporationState: state.incorporation }),
        shallowEqual,
    );
 
    Yup.addMethod(Yup.string, 'checkProposedName', checkProposedName);
   
    const companySchema = Yup.object({
        company_name: Yup.string()
            .nullable()
            .checkProposedName('country_id', companyNameLabel, dispatch),
        company_suffix_id: Yup.string().nullable(),
        entity_type_id: Yup.string().nullable(),
        country_id: Yup.string().nullable(),
        preventives: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().checkProposedName('country_id', 'Company name', dispatch),
                company_suffix_id: Yup.string().required('is required'),
            }),
        ),
    });

    // VietNam validate
    const companySchemaPrefix = Yup.object({
        company_name: Yup.string().nullable().checkProposedName('country_id', companyNameLabel),
        country_id: Yup.string().nullable(),
        preventives: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().nullable(),
                company_suffix_id: Yup.string().nullable(),
            }),
        ),
    });

    const form = useForm({
        defaultValues: {
            company_name: body?.company_name,
            company_suffix_id: body?.company_suffix_id || null,
            entity_type_id: body?.entity_type_id || null,
            country_id: body?.country_id || null,
            ...initPreventiveNames,
        },
        resolver: yupResolver(isPrefix ? companySchemaPrefix : companySchema),
    });

    const preventiveFields = useFieldArray({
        control: form.control,
        name: 'preventives',
        keyName: 'customId'
    });

    useEffect(() => {
        if (body && body.country_id) {
            setCountry(countriesHasService.find((e) => e.id === body.country_id));
            form.setValue('country_id', body.country_id);
        } else if (countriesHasService?.length) {
            setCountry(countriesHasService[0]);
            form.setValue('country_id', countriesHasService[0].id);
        }
        setIsLoading(true);
    }, [dispatch]);

    useEffect(() => {
        if (body && body.country_id) {
            setCountry(countriesHasService.find((e) => e.id === body.country_id));
            form.setValue('country_id', body.country_id);
        } else if (countriesHasService?.length) {
            setCountry(countriesHasService[0]);
            form.setValue('country_id', countriesHasService[0].id);
        }
    }, [countriesHasService]);

    useEffect(() => {
        if (formRef.current) {
            form.setValue('company_suffix_id', companySuffix.id);
            form.setValue('entity_type_id', companyType.id);
        }
    }, [companySuffix, companyType]);

    useEffect(() => {
        isMounted.current = true;
        if (country && country.id !== 0 && body) {
            if (!incorporationState.entity || incorporationState.entity.id !== country.id) {
                setIsLoading(true);
                dispatch(
                    actionsIncorporation.fetchCompanyInfo(country.id, {
                        params: {
                            package_group_id: 1,
                        },
                    }),
                ).then((data) => {
                    if (data) {
                        setCurrentCountryData(data);
                        if (body.company_suffix_id && body.entity_type_id) {
                            let entity_type = data.EntityTypes.find(
                                (e) => e.id === body.entity_type_id,
                            );
                            let company_suffix = data.CompanySuffixes.find(
                                (e) => e.id === body.company_suffix_id,
                            );
                            if (company_suffix) {
                                setCompanySuffix(company_suffix);
                                setIsPrefix(company_suffix.is_prefix);
                            } else {
                                setCompanySuffix(
                                    data.EntityTypes[0].CompanySuffixes.length > 0
                                        ? data.EntityTypes[0].CompanySuffixes[0]
                                        : null,
                                );
                                setIsPrefix(
                                    data.EntityTypes[0].CompanySuffixes.length > 0 &&
                                        data.EntityTypes[0].CompanySuffixes[0].is_prefix,
                                );
                            }
                            if (entity_type) {
                                setCompanyType(entity_type);
                            } else {
                                setCompanyType(
                                    data.EntityTypes.length > 0 ? data.EntityTypes[0] : null,
                                );
                            }
                        } else {
                            setCompanySuffix(
                                data.EntityTypes[0].CompanySuffixes.length > 0
                                    ? data.EntityTypes[0].CompanySuffixes[0]
                                    : null,
                            );
                            setCompanyType(
                                data.EntityTypes.length > 0 ? data.EntityTypes[0] : null,
                            );
                        }
                        setIsLoading(false);
                    }
                    return () => {
                        isMounted.current = false;
                    };
                });
            } else if (incorporationState.entity) {
                if (body.company_suffix_id && body.entity_type_id) {
                    let entity_type = incorporationState.entity.EntityTypes.find(
                        (e) => e.id === body.entity_type_id,
                    );
                    let company_suffix = entity_type.CompanySuffixes.find(
                        (e) => e.id === body.company_suffix_id,
                    );

                    if (company_suffix) {
                        setCompanySuffix(company_suffix);
                        setIsPrefix(company_suffix.is_prefix);
                    } else {
                        setCompanySuffix(
                            incorporationState.entity.EntityTypes[0].CompanySuffixes.length > 0
                                ? incorporationState.entity.EntityTypes[0].CompanySuffixes[0]
                                : null,
                        );
                        setIsPrefix(
                            incorporationState.entity.EntityTypes[0].CompanySuffixes.length > 0 &&
                                incorporationState.entity.EntityTypes[0].CompanySuffixes[0]
                                    .is_prefix,
                        );
                    }
                    if (entity_type) {
                        setCompanyType(entity_type);
                    } else {
                        setCompanyType(
                            incorporationState.entity.EntityTypes.length > 0
                                ? incorporationState.entity.EntityTypes[0]
                                : null,
                        );
                    }
                } else {
                    setCompanySuffix(
                        incorporationState.entity.EntityTypes[0].CompanySuffixes.length > 0
                            ? incorporationState.entity.EntityTypes[0].CompanySuffixes[0]
                            : null,
                    );
                    setCompanyType(
                        incorporationState.entity.EntityTypes.length > 0
                            ? incorporationState.entity.EntityTypes[0]
                            : null,
                    );
                }
                setIsLoading(false);
            }
        }
    }, [country]);

    useEffect(() => {
        if (country.id !== body.country_id) {
            setBody({ ...body, company_name: body.company_name });
        }
    }, [country]);

    useEffect(() => {
        try {
            if (companyType.CompanySuffixes) {
                setCompanySuffixOptions(companyType.CompanySuffixes);
            }
        } catch (error) {
            console.log(error);
        }
    }, [companyType]);

    useEffect(() => {
        try {
            if (isPrefix) {
                preventiveFields.remove();
                setBody({ ...body, preventives: [] });
                setCompanyNameLabel('Parent Company Name');
            } else {
                setCompanyNameLabel('Proposed Company Name');
            }
        } catch (error) {
            console.log(error);
        }
    }, [isPrefix]);

    function getInfoByCountry(value) {
        if (value != null && !countriesHasService !== null) {
            setCountry(value);
        }
    }

    function addCompanyName() {
        var temp = [...preventiveName, { name: '', company_suffix: undefined }];
        setPreventiveName([...temp]);
    }

    const onReset = () => {
        setBody({ ...body, packageIncorp: null, services: [] });
    };

    const handleSubmitForm = (values) => {
        let tempValues = { ...values };
        tempValues['country_id'] = Number(values?.country_id);
        tempValues['company_suffix_id'] = Number(values?.company_suffix_id);
        tempValues['entity_type_id'] = Number(values?.entity_type_id);

        tempValues['preventives'] = values.preventives.map((item) => {
            return {
                ...item,
                company_suffix_id: Number(item.company_suffix_id),
            };
        });
        setInitPreventiveNames(tempValues['preventives']);
        setBody({ ...body, ...tempValues });
    };

    const [hintInput, setHintInput] = useState('');

    useImperativeHandle(formRef, () => ({
        handleSubmitForm() {
            form.handleSubmit(handleSubmitForm)();
        },
        isValid: !!form.formState.isValid,
    }));

    return (
        <div>
            <form onSubmit={form.handleSubmit(handleSubmitForm)}>
                <div>
                    <div className="row mt-5">
                        <div className="col-md-6 mb-7 input-line">
                            <label>Jurisdictions</label>
                            <SelectField
                                form={form}
                                name="country_id"
                                options={countriesHasService || []}
                                disabled={isLoading}
                                onChange={(value) => {
                                    onReset();
                                    getInfoByCountry(value);
                                    form.setValue('country_id', value.id);
                                    setSelectedRadio && setSelectedRadio(false);
                                }}
                                hideInputHelperText
                                hideSuccessHelper
                                autoComplete="off"
                            />
                            <FormHelperText>
                                Please select <b>Country</b>
                            </FormHelperText>
                        </div>
                        <div className="col-md-6 mb-7 input-line">
                            <label>Company Type</label>
                            <SelectField
                                form={form}
                                name="entity_type_id"
                                options={
                                    incorporationState.entity
                                        ? incorporationState.entity.EntityTypes
                                        : []
                                }
                                disabled={isLoading}
                                onChange={(value) => {
                                    if (body && body.entity_type_id != value.id) {
                                        setCompanyType(value);
                                        form.setValue('entity_type_id', value.id);

                                        setIsPrefix(value.CompanySuffixes[0].is_prefix);
                                        setCompanySuffix(value.CompanySuffixes[0]);
                                        form.setValue('company_suffix_id', value.id);

                                        if (body.preventives && body.preventives.length > 0) {
                                            let temp = body.preventives;
                                            temp.length > 0 &&
                                                temp.map(
                                                    (item) =>
                                                        (item.company_suffix_id =
                                                            value.CompanySuffixes[0].id),
                                                );
                                            setBody({
                                                ...body,
                                                entity_type_id: Number(value.id || 0),
                                                company_suffix_id: Number(
                                                    value.CompanySuffixes[0].id || 0,
                                                ),
                                                preventives: temp,
                                            });
                                        } else {
                                            setBody({
                                                ...body,
                                                entity_type_id: Number(value.id || 0),
                                                company_suffix_id: Number(
                                                    value.CompanySuffixes[0].id || 0,
                                                ),
                                            });
                                        }

                                        onReset();
                                    }
                                }}
                                hideInputHelperText
                                hideSuccessHelper
                                autoComplete="off"
                            />
                            <FormHelperText>
                                Please select <b>Company Type</b>
                            </FormHelperText>
                        </div>

                        <div className="col-md-6 mb-7 has-check-name">
                            <div className="input-line">
                                <label>Enter {companyNameLabel}</label>
                                <InputField
                                    form={form}
                                    id="company_name"
                                    name="company_name"
                                    onKeyUp={(e) => {
                                        form.trigger("company_name")
                                        setHintInput(e.target.value)}
                                    }
                                    onBlur={() => {
                                        setTimeout(() => {
                                            setHintInput('');
                                        }, 200);
                                    }}
                                    placeholder={companyNameLabel}
                                    hideSuccessHelper
                                    showIcon
                                />
                                <FormHelperText>
                                    Please enter <b>{companyNameLabel}</b>
                                </FormHelperText>
                            </div>
                            {!isPrefix && hintInput.length > 0 && (
                                <RecommendName
                                    input={hintInput}
                                    fieldName={'company_name'}
                                    form={form}
                                />
                            )}
                        </div>

                        {
                            <div className="col-md-6 mb-7 input-line">
                                <label>
                                    {companySuffix.is_prefix
                                        ? 'Company Prefixes'
                                        : 'Company Suffixes'}
                                </label>
                                <SelectField
                                    form={form}
                                    name="company_suffix_id"
                                    options={companyType.CompanySuffixes || []}
                                    onChange={(value) => {
                                        if (body && body.company_suffix_id != value.id) {
                                            onReset();
                                            setCompanySuffix(value);
                                            form.setValue('company_suffix_id', value.id);
                                        }
                                    }}
                                    disabled={isLoading}
                                    hideInputHelperText
                                    hideSuccessHelper
                                    autoComplete="off"
                                />
                                <FormHelperText>
                                    Please select{' '}
                                    <b>
                                        {companySuffix.is_prefix
                                            ? 'Company Prefixes'
                                            : 'Company Suffixes'}
                                    </b>
                                </FormHelperText>
                            </div>
                        }
                        {!isPrefix && (
                            <div className="col-xl-6 mt-5 d-flex align-items-center">
                                <h3 className="h6" style={{ margin: '0 20px 0 0' }}>
                                    Other Proposed Names
                                </h3>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    startIcon={<AddIcon />}
                                    disabled={!isLoading ? false : true}
                                    onClick={(event) => preventiveFields.append()}
                                    style={{ margin: '0' }}
                                >
                                    Add
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                {!isEmpty(preventiveFields.fields) &&
                    preventiveFields.fields.map((field, index, fields) => (
                        <div key={field.id}>
                            <PreventiveComponent
                                form={form}
                                name="preventives"
                                index={index}
                                body={body}
                                fields={preventiveFields}
                                companyType={companyType}
                                isPrefix={isPrefix}
                            />
                        </div>
                    ))}
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    className={classes.button}
                    style={{ color: '#fff' }}
                    disabled={!isLoading ? false : true}
                    onClick={() => {
                        onSubmitForm(1);
                    }}
                >
                    Next
                </Button>
            </form>
        </div>
    );
}
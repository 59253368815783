import { bankingSlice, initState, updateOrderInfo } from 'app/pages/_redux/banking/slice';
import React, { useLayoutEffect } from 'react';
// import { useSubheader } from '_metronic/layout';
import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import { OrdersFilter } from './orders-filter/ordersFilter';
import { OrderTable } from './orders-table/OrderTable';
import { OrdersUIProvider } from './OrdersUIContext';
import { resetBankingState } from '../../../_redux/banking/slice';
import { useDispatch } from 'react-redux';

export function OrderListPage() {
    // const suhbeader = useSubheader();
    // suhbeader.setTitle('Account');
    const dispatch = useDispatch();
    useLayoutEffect(() => {
        // console.log('a')
        dispatch(resetBankingState(initState));
    }, []);

    return (
        <>
            <OrdersUIProvider>
                <Card>
                    <CardHeader title="Order management"></CardHeader>
                    <CardBody>
                        <OrdersFilter />
                        <OrderTable />
                    </CardBody>
                </Card>
            </OrdersUIProvider>
        </>
    );
}

export const LINK = {
    INCORPORATION: { id: 1, content: '/incoporation'},
    BANKING: {id: 2, content: '/banking'},
    BANKING_TOOL: {id: 3, content: '/banking-tool'},
    ADDITIONAL_SERVICE: {id: 4, content: '/additional-service'},
}

/**
* cùng 1 bank có id khác nhau ở các quốc gia khác nhau => bank_id là duy nhất.
* country_ids [] chỉ dùng để tra cứu.
*/

export const BANK_HAS_PROCCESS = { bank_ids: [1, 5,6,7,8,9,10,11, 13, 16, 17,18,19,20,21,22, 23] }

export const BANK_GUIDE = [
    { country_ids: [23], bank_ids: [1], file_src: '/media/bank-process/guide/Bank_CAYE.pdf' },
    { country_ids: [98], bank_ids: [5,6,7,8,9,10,11], file_src: '/media/bank-process/guide/Bank_HongKong.pdf' }, // Bank_HongKong: All bank in HongKong.
    { country_ids: [139], bank_ids: [13], file_src: '/media/bank-process/guide/Bank_One.pdf' },
    { country_ids: [177], bank_ids: [16], file_src: '/media/bank-process/guide/Bank_EPB.pdf' },
    { country_ids: [191], bank_ids: [18], file_src: '/media/bank-process/guide/Bank_DBS_Singapore.pdf' },
    { country_ids: [191], bank_ids: [19], file_src: '/media/bank-process/guide/Bank_OCBC.pdf' },
    { country_ids: [191], bank_ids: [22], file_src: '/media/bank-process/guide/Bank_UOB.pdf' },
    { country_ids: [215], bank_ids: [23], file_src: '/media/bank-process/guide/Bank_CIM.pdf' },
]

/**
 * BANK_SUCCESS_CONTENT_TYPE:
 * Nội dung tương ứng content_type do FE tự quy định.
*/
export const BANK_SUCCESS_CONTENT_TYPE = [
    { content_type: 1, country_ids: [98, 191], bank_ids: [5,6,7,8,9,10,11, 17,18,19,20,21,22]}, // All bank in HongKong + Singapore.
    // { content_type: 1, country_ids: [98], bank_ids: [5,6,7,8,9,10,11]}, // All bank in HongKong.
    // { content_type: 1, country_ids: [191], bank_ids: [17,18,19,20,21,22]}, // All bank in Singapore.
    { content_type: 2, country_ids: [23], bank_ids: [1]}, // Caye
    { content_type: 3, country_ids: [177], bank_ids: [16]}, // EPB
    { content_type: 4, country_ids: [215], bank_ids: [23]}, // CIM
    // { content_type: 5, country_ids: [139], bank_ids: [13]}, // content_type: 5: Success notice has upload file: Bank One
]

/**
 * BANK_SUCCESS_NEED_UPLOAD_FILE:
 * Danh sách bank có Màn hình mở Tài khoản thành công cùng chức năng upload Payment slip.
*/
export const BANK_SUCCESS_NEED_UPLOAD_FILE = { country_ids: [139], bank_ids: [13] };

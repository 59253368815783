import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import * as _ from 'lodash';

export const actionTypes = {
    Login: '[Login] Action',
    Logout: '[Logout] Action',
    Register: '[Register] Action',
    UserRequested: '[Request User] Action',
    UserLoaded: '[Load User] Auth API',
    TwoFactor: '[TwoFactor] Action',
};

const initialAuthState = {
    user: undefined,
    authToken: undefined,
};

export const reducer = persistReducer(
    { storage, key: 'bbcincorp', whitelist: ['user', 'authToken', 'username'] },
    (state = initialAuthState, action) => {
        switch (action.type) {
            case actionTypes.Login: {
                const { authToken } = action.payload;
                return { authToken };
            }

            case actionTypes.Register: {
                const { authToken } = action.payload;

                return { authToken, user: undefined };
            }

            case actionTypes.Logout: {
                // TODO: Change this code. Actions in reducer aren't allowed.
                return initialAuthState;
            }

            case actionTypes.UserLoaded: {
                const { user } = action.payload;
                return { ...state, user };
            }

            case actionTypes.TwoFactor: {
                const { twoFactor } = action.payload;
                return { twoFactor };
            }

            default:
                return state;
        }
    },
);

export const actions = {
    login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
    register: (authToken) => ({
        type: actionTypes.Register,
        payload: { authToken },
    }),
    logout: () => ({ type: actionTypes.Logout }),
    requestUser: (user) => ({ type: actionTypes.UserRequested, payload: { user } }),
    fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
    two_factor: (twoFactor) => {
        return { type: actionTypes.TwoFactor, payload: { twoFactor } };
    },
};

export function* saga() {
    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.TwoFactor, function* twoFactorSaga(data) {
        // yield put(actions.two_factor(data));
    });
}

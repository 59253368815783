import { createSlice } from '@reduxjs/toolkit';
import { updateLoginFirst } from './personalThunk';

const initialPersonalState = {
    actionsLoading: false,
    profile: undefined,
    user: undefined,
    subscription: undefined,
    roles: undefined,
    lastError: null,
    error: null
};
export const callTypes = {
    action: 'action',
};

export const personalSlice = createSlice({
    name: 'personal',
    initialState: initialPersonalState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        personalFetched: (state, action) => {
            state.actionsLoading = false;
            state.profile = action.payload.personal['Personal'];
            state.roles = action.payload.personal['Roles'];
            state.subscription = action.payload.personal['Subscription'];
            const { Personal, Roles, Subscription, ...user } = action.payload.personal;
            state.user = user;
            state.error = null;
        },
        personalUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        changePassword: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        updateProfile: (state, action) => {
            state.profile[action.payload.name] = action.payload.value;
        },
        reset: (state, action) => {
            state.actionsLoading = false;
            state.profile = undefined;
            state.user = undefined;
            state.subscription = undefined;
            state.roles = undefined;
            state.lastError = null;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateLoginFirst.fulfilled, (state, action) => {
            state.profile.is_first_login = true;
        })
    },
});

import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ContactInfo from './steps/ContactInfo';
import CompanyInfo from './steps/CompanyInfo';
import { updateOrderFormBookkeeperAction } from 'app/pages/_redux/additional-service/asyncThunk';
import CompanyStructure from './steps/CompanyStructure';
import { fetchCountries } from 'app/pages/_redux/enum/enumActions';
import { isBelizeLLC, isLLC, isUK_LLP } from '../helpers';
import BusinessInfo from './steps/BusinessInfo';
import { BVI_ID, PANAMA_ID } from 'constants/country';
import Declaration from './steps/Declaration';
import { fetchComparedcompany } from 'app/pages/_redux/companies/companiesActions';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

const ConfirmationForm = ({ task, onNext }, ref) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const companyStructureRef = useRef(null);

    useEffect(() => {
        dispatch(fetchCountries());
        void handleGetCompareData();
    }, []);

    const hasShowSelectFullName = useMemo(() => {
        return [BVI_ID, PANAMA_ID].includes(task?.Company?.country_id);
    }, [task]);

    const handleGetCompareData = async () => {
        if(!task) return
        dispatch(fetchComparedcompany(task.Task.id));
    }

    const handleNext = () => {
        if (activeStep + 1 === steps.length) {
            onNext();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep - 1 < 0) {
            // onBack();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleSubmit = async (values, isCompanyStructure) => {
        // setLoadingNext(true);
        try {
            dispatch(updateOrderFormBookkeeperAction({ id: task.Task.id, params: values })).then(() => {
                dispatch(fetchComparedcompany(task.Task.id));
            });
            if (isCompanyStructure) {
                companyStructureRef.current.handleNext();
            } else {
                handleNext();
            }
        } catch (e) {
            console.log(e);
        }
    };

    const steps = [
        {
            label: 'Contact information',
            step: 'contact-info',
            content: (
                <ContactInfo
                    task={task}
                    handleNext={handleNext}
                    onSubmit={handleSubmit}
                    hasShowSelectFullName={hasShowSelectFullName}
                />
            ),
        },
        {
            label: 'Company information',
            step: 'company-info',
            content: (
                <CompanyInfo
                    task={task}
                    onSubmit={handleSubmit}
                    handleNext={handleNext}
                    handleBack={handleBack}
                />
            ),
        },
        {
            label: 'Company structure',
            step: 'company-structure',
            content: (
                <CompanyStructure
                    ref={companyStructureRef}
                    task={task}
                    onSubmit={handleSubmit}
                    onNext={handleNext}
                    onBack={handleBack}
                    IS_LLC={isLLC(task?.Company?.entity_type_id, task?.Company?.country_id)}
                    IS_BELIZE_LLC={isBelizeLLC(
                        task?.Company?.entity_type_id,
                        task?.Company?.country_id,
                    )}
                    IS_UK_LLP={isUK_LLP(task?.Company?.entity_type_id, task?.Company?.country_id)}
                    hasShowSelectFullName={hasShowSelectFullName}
                />
            ),
        },
        {
            label: 'Business information',
            step: 'business-info',
            content: (
                <BusinessInfo
                    task={task}
                    IS_LLC={isLLC(task?.Company?.entity_type_id, task?.Company?.country_id)}
                    onSubmit={handleSubmit}
                    handleNext={handleNext}
                    handleBack={handleBack}
                />
            ),
        },
        {
            label: 'Declaration',
            step: 'declaration',
            content: (
                <Declaration task={task} disabled={true} onNext={onNext} handleBack={handleBack} country={task?.Company?.Country}/>
            ),
        },
    ];

    return (
        <>
            <div className={'wrap-stepper ' + classes.root}>
                <Stepper className="stepper p-0" activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={index} completed={false}>
                            <StepLabel>{step.label}</StepLabel>
                            <StepContent>{step.content}</StepContent>
                        </Step>
                    ))}
                </Stepper>
            </div>
        </>
    );
};

export default forwardRef(ConfirmationForm);

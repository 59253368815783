import { FormattedMessage } from 'react-intl';
import React, { useRef, useState } from 'react';
import { toAbsoluteUrl } from '_metronic/_helpers';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useNavigate, useLocation } from 'react-router-dom';
import { requestPassword } from '../_redux/authCrud';

function ResendEmail(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const ggCaptchaEl = useRef(null);
    const [loadingResend, setLoadingResend] = useState(false);

    const resendEmail = async () => {
        try {
            setLoadingResend(true);
            const token = await ggCaptchaEl.current.executeRecaptcha();
            const response = await requestPassword(location.state.email.trim(), token);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingResend(false);
        }
    };

    return (
        <>
            <div className="login-form resend-email" style={{ display: 'block' }}>
                <img
                    src={'/media/logos/logo-new.png'}
                    width={177}
                    height={49}
                    className="logo"
                    alt="BBCIncorp"
                />
                <div>
                    <h3 className="title d-inline-block">
                        <FormattedMessage id="AUTH.EMAIL.TITLE" />
                    </h3>
                </div>
                <div>
                    <p className="description d-inline-block">
                        <FormattedMessage id="AUTH.EMAIL.SUBTITLE" />{' '}
                        <span>{location.state.email}</span>
                    </p>
                </div>
                <div className="d-flex flex-center group-button">
                    <button
                        type="button"
                        id="kt_login_resend_back"
                        className="btn btn-cancel"
                        onClick={() => navigate('/auth')}
                    >
                        Back to login
                    </button>

                    <a className="link-open" href="mailto:name@email.com">
                        <span>Open email</span>
                    </a>
                </div>
                <div className='resend-text'>
                    <p>
                        Didn't receive the email?{' '}
                        <button
                            type="button"
                            style={{
                                color: '#007EFF',
                                border: 'none',
                                background: 'transparent',
                                padding: 0,
                                cursor: loadingResend && 'wait'
                            }}
                            onClick={resendEmail}
                            disabled={loadingResend}
                        >
                            Click to resend
                        </button>
                    </p>
                </div>
                <div>
                    <GoogleReCaptchaProvider
                        ref={ggCaptchaEl}
                        reCaptchaKey={process.env.REACT_APP_RECAPTCHA}
                        language="en"
                        name="recaptcha-token"
                        scriptProps={{
                            async: false, // optional, default to false,
                            defer: false, // optional, default to false
                            appendTo: 'head', // optional, default to "head", can be "head" or "body",
                            nonce: undefined, // optional, default undefined
                        }}
                    >
                        {' '}
                    </GoogleReCaptchaProvider>
                </div>
            </div>
        </>
    );
}

export default ResendEmail;

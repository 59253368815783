import * as call from "./bankingToolCrud";

const { bankingToolSlice } = require("./bankingToolSlice");

const { actions } = bankingToolSlice;


export const fetchFilterAPI = ({...queryParams}) => (dispatch) => {
    dispatch(actions.startCall())
    let paramsAPI = {
        incorporation_country_id: queryParams.country_id ?? null,
        business_field: queryParams.business_field,
        member_country_ids: JSON.stringify(queryParams.member_country_ids?.map(e => e.id)) ?? [],
        partner_country_ids: JSON.stringify(queryParams.partner_country_ids?.map(e => e.id)) ?? [],
        is_merchant_account: queryParams.is_merchant_account !== null ? queryParams.is_merchant_account ? 1 : 0 : null
    }
    return call.fetchFilterAPI(paramsAPI).then(response => {
        dispatch(actions.updateDataFilter({data: response.data.data}))
    })
}


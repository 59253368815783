import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import SelectField from 'components/form-controls/SelectField';
import InputField from 'components/form-controls/InputField';
import { find, get, isEmpty } from 'lodash';
import { Box, Grid } from '@mui/material';

const SecurityQuestion = ({ index, body, form, disabled = false }) => {
    const [security_question_1, set_security_question_1] = useState({
        id: 0,
        content: '',
        question_id: null,
        Answers: [],
    });
    const [security_question_2, set_security_question_2] = useState({
        id: 0,
        content: '',
        question_id: null,
        Answers: [],
    });
    const [security_question_3, set_security_question_3] = useState({
        id: 0,
        content: '',
        question_id: null,
        Answers: [],
    });
    const [option1, setOption1] = useState([]);
    const [option2, setOption2] = useState([]);
    const [option3, setOption3] = useState([]);
    const onChangeQuestion1 = (value) => {
        if (
            value.content === security_question_2?.content ||
            value.content === security_question_3?.content
        ) {
            Swal.fire({
                icon: 'warning',
                text: 'Could not choose the same question',
                // timer: 2000,
            });
            return;
        }

        if (security_question_1 && security_question_1.id != value.id) {
            set_security_question_1(value);
            form.setValue(`members.${index}.security_question_1`, value.id);
        }
    };
    const onChangeQuestion2 = (value) => {
        if (
            value.content === security_question_1?.content ||
            value.content === security_question_3?.content
        ) {
            Swal.fire({
                icon: 'warning',
                text: 'Could not choose the same question',
                // timer: 2000,
            });
            return;
        }

        if (security_question_2 && security_question_2.id != value.id) {
            // onReset();
            set_security_question_2(value);
            form.setValue(`members.${index}.security_question_2`, value.id);
        }
    };
    const onChangeQuestion3 = (value) => {
        if (
            value.content === security_question_1?.content ||
            value.content === security_question_2?.content
        ) {
            Swal.fire({
                icon: 'warning',
                text: 'Could not choose the same question',
                // timer: 2000,
            });
            return;
        }

        if (security_question_3 && security_question_3.id != value.id) {
            // onReset();
            set_security_question_3(value);
            form.setValue(`members.${index}.security_question_3`, value.id);
        }
    };

    useEffect(() => {
        const id = form.getValues(`members.${index}.id`);
        const questions = get(body, 'Task.IncorporationCountry.Questions');

        if (id && !isEmpty(questions)) {
            const checkCompanyMemberId = (ans) => ans.company_member_id === id;
            const findAnswer = (op) => op.Answers.find(checkCompanyMemberId);

            const question1 = questions.find((q) => q.id === 17).Options.find(findAnswer);
            const question2 = questions.find((q) => q.id === 18).Options.find(findAnswer);
            const question3 = questions.find((q) => q.id === 19).Options.find(findAnswer);

            if (question1) {
                set_security_question_1(question1);
                form.setValue(`members.${index}.security_question_1`, question1?.id);
                form.setValue(
                    `members.${index}.answer_question_1`,
                    question1.Answers.find(checkCompanyMemberId)?.value_text,
                );
            }

            if (question2) {
                set_security_question_2(question2);
                form.setValue(`members.${index}.security_question_2`, question2?.id);
                form.setValue(
                    `members.${index}.answer_question_2`,
                    question2.Answers.find(checkCompanyMemberId)?.value_text,
                );
            }

            if (question3) {
                set_security_question_3(question3);
                form.setValue(`members.${index}.security_question_3`, question3?.id);
                form.setValue(
                    `members.${index}.answer_question_3`,
                    question3.Answers.find(checkCompanyMemberId)?.value_text,
                );
            }

            setOption1(questions.find((q) => q.id === 17).Options);
            setOption2(questions.find((q) => q.id === 18).Options);
            setOption3(questions.find((q) => q.id === 19).Options);
        }
    }, [body, form.watch(`members.${index}.id`)]);
    // }, [body]);

    return (
        <>
            <div className="source_of_funds container-fluid p-0 m-0 kyc-frm-control">
                <p style={{ fontSize: '10px' }} className="col-md-12 mb-7 pl-0">
                    SECURITIES QUESTIONS
                </p>

                <Box className="row mb-5">
                    <Box className="col-md-6 input-line">
                        <label>
                            {get(
                                find(get(body, 'Task.IncorporationCountry.Questions'), ['id', 17]),
                                'content',
                            )}
                        </label>

                        <SelectField
                            form={form}
                            name={`members.${index}.security_question_1`}
                            options={option1}
                            placeholder="Choose question"
                            label=""
                            getOptionLabel={(option) => option.content}
                            value={security_question_1}
                            onChange={onChangeQuestion1}
                            disabled={disabled}
                            hideInputHelperText
                            hideSuccessHelper
                        />
                    </Box>
                    <Box className="col-md-6 input-line">
                        <label>Answer 1</label>
                        <InputField
                            name={`members.${index}.answer_question_1`}
                            label=""
                            placeholder="Type first of 3 letters"
                            form={form}
                            disabled={disabled}
                            inputProps={{ maxLength: 3 }}
                            hideSuccessHelper
                        />
                    </Box>
                </Box>

                <Box className="row mb-5">
                    <Box className="col-md-6 input-line">
                        <label>
                            {get(
                                find(get(body, 'Task.IncorporationCountry.Questions'), ['id', 18]),
                                'content',
                            )}
                        </label>
                        <SelectField
                            form={form}
                            name={`members.${index}.security_question_2`}
                            options={option2}
                            placeholder="Choose question"
                            label=""
                            getOptionLabel={(option) => option.content}
                            value={security_question_2}
                            onChange={onChangeQuestion2}
                            disabled={disabled}
                            hideInputHelperText
                            hideSuccessHelper
                        />
                    </Box>
                    <Box className="col-md-6 input-line">
                        <label>Answer 2</label>
                        <InputField
                            name={`members.${index}.answer_question_2`}
                            label=""
                            placeholder="Type first of 3 letters"
                            form={form}
                            disabled={disabled}
                            inputProps={{ maxLength: 3 }}
                            hideSuccessHelper
                        />
                    </Box>
                </Box>

                <Box className="row">
                    <Box className="col-md-6 input-line">
                        <label>
                            {get(
                                find(get(body, 'Task.IncorporationCountry.Questions'), ['id', 19]),
                                'content',
                            )}
                        </label>
                        <SelectField
                            form={form}
                            name={`members.${index}.security_question_3`}
                            options={option3}
                            placeholder="Choose question"
                            label=""
                            getOptionLabel={(option) => option.content}
                            value={security_question_3}
                            onChange={onChangeQuestion3}
                            disabled={disabled}
                            hideInputHelperText
                            hideSuccessHelper
                        />
                    </Box>
                    <Box className="col-md-6 input-line">
                        <label>Answer 3</label>
                        <InputField
                            name={`members.${index}.answer_question_3`}
                            label=""
                            placeholder="Type first of 3 letters"
                            form={form}
                            disabled={disabled}
                            inputProps={{ maxLength: 3 }}
                            hideSuccessHelper
                        />
                    </Box>
                </Box>
            </div>
        </>
    );
};

export default SecurityQuestion;

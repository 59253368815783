import React, { useEffect, useMemo, useState } from 'react';
import { ArrowRight, CheckCircle } from '@mui/icons-material';
import { listToTreeSignForm, treeToList } from '../helpers/utils';
import { cloneDeep } from 'lodash';
import './DocumentTitleList.scss';

export const isUploaded = (currentTitle, currentTemplate) => {
    return (
        (
            currentTemplate?.Files?.filter((file) =>
                file?.company_event_id
                    ? file?.company_event_id == currentTitle.company_event_id
                    : file?.company_member_id
                    ? file.company_member_id == currentTitle.company_member_id
                    : file.file_template_id == currentTitle.file_template_id,
            ) || []
        ).length > 0 || currentTitle?.files?.length > 0
    );
};

function DocumentTitleListSignForm({ filesTemplate, onSelectTitle, autoSelectFirstTitle = false }) {
    let [activeTitle, setActiveTitle] = useState(null);
    let [activeFileTemplate, setActiveFileTemplate] = useState(null);
    let [selectedAuto, setSelectedAuto] = useState(false);

    const tree = useMemo(() => {
        return listToTreeSignForm(cloneDeep(filesTemplate));
    }, [filesTemplate]);

    const listConverted = useMemo(() => {
        return treeToList(cloneDeep(tree));
    }, [tree]);

    useEffect(() => {
        if (autoSelectFirstTitle && !selectedAuto) {
            setActiveTitle(listConverted?.[0]?.children?.[0]?.titles?.[0]);
            setActiveFileTemplate(listConverted?.[0]?.children?.[0]);
            onSelectTitle &&
                onSelectTitle([
                    listConverted?.[0]?.children?.[0],
                    listConverted?.[0]?.children?.[0]?.titles?.[0],
                ]);
            setSelectedAuto(true);
        }
    }, [listConverted]);

    const isActiveTitle = (title) => {
        return (
            title.ranking_partner_id === activeTitle?.ranking_partner_id &&
            title.company_member_id === activeTitle?.company_member_id &&
            title.file_template_id === activeFileTemplate?.id
        );
    };

    function renderTreeList(tree, parent = null) {
        const renderFile = (node) =>
            node?.titles?.map((title, index) => (
                <li
                    key={index}
                    className={
                        'cursor-pointer pb-1 position-relative ' +
                        (isActiveTitle(title) ? 'font-weight-bold' : 'text-black-50')
                    }
                    style={{ whiteSpace: 'nowrap' }}
                    onClick={() => {
                        setActiveTitle(title);
                        setActiveFileTemplate(node);
                        onSelectTitle && onSelectTitle([node, title]);
                    }}
                >
                    <span>{title.file_name}</span>

                    {isUploaded(title, node) && (
                        <CheckCircle
                            color="primary"
                            className="d-inline ml-2"
                            style={{ fontSize: '0.9rem', marginBottom: 2 }}
                        />
                    )}

                    {isActiveTitle(title) ? (
                        <ArrowRight
                            fontSize="small"
                            className={'position-absolute'}
                            style={{ top: '50%', left: '-30px', transform: 'translateY(-50%)' }}
                        />
                    ) : (
                        ''
                    )}
                </li>
            ));

        const Tag = parent ? 'ol' : 'ul';

        return (
            <Tag className={'pl-5'}>
                {tree?.map((node) => (
                    <React.Fragment key={node.id}>
                        <li className={'pb-1 ' + (!node.file_type_id ? 'root' : '')}>
                            <span className={'title-name'}>{node.name}</span>

                            {!node.children.length && (
                                <ol className={'pl-5 pt-1'}>{renderFile(node)}</ol>
                            )}

                            {renderTreeList(node.children, node)}
                        </li>
                    </React.Fragment>
                ))}
            </Tag>
        );
    }

    return <div className={'overflow-auto document-title-list'}>{renderTreeList(tree)}</div>;
}

export default DocumentTitleListSignForm;

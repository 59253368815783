import React from 'react';
import { Button } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';


function NoteFromCs({ note = '', files = [], onFileClick }) {
    return (
        <div className={'card'}>
            <div className='card-body'>
                <p className={'text-danger'}><strong>Note from CS</strong></p>
                <p>{note}</p>

                <p>
                    {files.map((file) => (
                        <Button className={'mr-2'}
                                onClick={() => {
                                    onFileClick && onFileClick(file);
                                }}
                                variant='outlined'
                                key={file.id}>
                            <DescriptionIcon color={'action'} />
                            <span className={'ml-2'}>{file.name}</span>
                        </Button>))}
                </p>
            </div>
        </div>
    );
}

export default NoteFromCs;

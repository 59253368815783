import axios from 'axios';

export const PROPOSED_NAME_URL = '/customer_account/order_form/propose_name';
// GET https://core.dev.bbcincorp.com/api/customer_account/order_form/propose_name/:task_id
// POST: ADD https://core.dev.bbcincorp.com/api/customer_account/order_form/propose_name
// PUT: UPDATED https://core.dev.bbcincorp.com/api/customer_account/order_form/propose_name/:proposed_name_id
// delete: DELETED https://core.dev.bbcincorp.com/api/customer_account/order_form/propose_name/:proposed_name_id
// create, update: POST, PUT https://core.dev.bbcincorp.com/api/customer_account/order_form/propose_name/bulk

export function getProposedName(task_id) {
    return axios.get(PROPOSED_NAME_URL + '/' + task_id);
}

export function addProposedName(body) {
    // return axios.post(PROPOSED_NAME_URL, body);
    return axios.post(PROPOSED_NAME_URL + '/bulk', body);
}

export function updateProposedName(body) {
    return axios.put(PROPOSED_NAME_URL + '/bulk', body);
}

// export function updateProposedName(proposed_name_id, body) {
//     return axios.put(PROPOSED_NAME_URL + '/' + proposed_name_id, body);
// }

export function deleteProposedName(proposed_name_id, body) {
    return axios.delete(PROPOSED_NAME_URL + '/' + proposed_name_id, body)
}
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getDefaultNationality } from 'app/pages/services/utilities/NationalityHelpers';
import RadioField from 'components/form-controls/RadioField';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';

/**
 * Panama References
 */

const ReferenceIssue = [
    { id: 1, title: 'Lawyer' },
    { id: 2, title: 'Bank' },
    { id: 3, title: 'CPA' },
    { id: 4, title: 'Business reference' },
];

export default function ReferenceItem({ form, memberIndex, referenceIndex, disabled = false }) {
    const member = form.watch(`members.${memberIndex}`);
    const [phonePrefix, setPhonePrefix] = useState({ id: 0, name: '' });

    const { countries, geoLooking } = useSelector(
        (state) => ({
            countries: state.enum.countriesCooperation,
            geoLooking: state.enum.geoLooking,
        }),
        shallowEqual,
    );

    useEffect(() => {
        try {
            // set default: api data
            form.setValue(
                `members.${memberIndex}.references.${referenceIndex}.company_member_issuse_reference_id`,
                member.references[referenceIndex].company_member_issuse_reference_id ||
                    ReferenceIssue[0].id,
            );
            if (member.id) {
                form.setValue(
                    `members.${memberIndex}.references.${referenceIndex}.company_member_id`,
                    member.id,
                );
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        try {
            // set PhonePrefix onload component
            if (countries && geoLooking) {
                let nationality = getDefaultNationality(
                    member.references[referenceIndex].phone_country_id,
                );

                setPhonePrefix(nationality);
                form.setValue(
                    `members.${memberIndex}.references.${referenceIndex}.phone_country_id`,
                    nationality.id,
                );

                // set value to change nationality when Choose Existing Member.
                form.setValue(`members.${memberIndex}.stopChangeNationality`, false);
            }
        } catch (error) {
            console.log(error);
        }
    }, [countries, geoLooking]);

    useEffect(() => {
        try {
            // set PhonePrefix AfterChooseExistingMember
            if (member && member.isAfterChooseExistingMember && !member.stopChangeNationality) {
                let nationality = getDefaultNationality(
                    member.references[referenceIndex].phone_country_id,
                );

                setPhonePrefix(nationality);
                form.setValue(
                    `members.${memberIndex}.references.${referenceIndex}.phone_country_id`,
                    nationality.id,
                );

                // set value to stop change nationality -> ko bị reset phonePrefix khi value của member (form) có thay đổi.
                form.setValue(`members.${memberIndex}.stopChangeNationality`, true);
            }
        } catch (error) {
            console.log(error);
        }
    }, [member]);

    return (
        <>
            <div className="reference-item">
                <div className="row">
                    <div className="col-md-6 mb-2 input-line">
                        <label>Name</label>
                        <InputField
                            name={`members.${memberIndex}.references.${referenceIndex}.name`}
                            placeholder="Enter Name"
                            form={form}
                            disabled={
                                form.watch(`members.${memberIndex}.isAfterChooseExistingMember`) ||
                                disabled
                            }
                            hideSuccessHelper
                        />
                    </div>
                    <div className="col-12 mb-5">
                        <RadioField
                            form={form}
                            aria-label={`members.${memberIndex}.references.${referenceIndex}.company_member_issuse_reference_id`}
                            name={`members.${memberIndex}.references.${referenceIndex}.company_member_issuse_reference_id`}
                            options={ReferenceIssue}
                            disabled={disabled}
                            renderLabel={(option) => option.title}
                            radioGroupProps={{ row: true }}
                        />
                    </div>
                    <div className="col-md-6 mb-7 input-line">
                        <label>Tel No.</label>
                        <div className="phone-group">
                            <div className="phone-code" style={{ zIndex: 1 }}>
                                <SelectField
                                    form={form}
                                    label=""
                                    className="fw-500"
                                    name={`members.${memberIndex}.references.${referenceIndex}.phone_country_id`}
                                    options={countries || []}
                                    getOptionLabel={(option) =>
                                        option.country_code + ' +' + option.phone_code
                                    }
                                    disabled={
                                        form.watch(
                                            `members.${memberIndex}.isAfterChooseExistingMember`,
                                        ) || disabled
                                    }
                                    onChange={(value) => {
                                        if (phonePrefix && phonePrefix.id != value.id) {
                                            setPhonePrefix(value);
                                        }
                                    }}
                                    customStyle={{
                                        '.MuiInputBase-root:before, .MuiInputBase-root:after, .MuiInput-underline:hover:not(.Mui-disabled):before':
                                            {
                                                borderBottom: '0 !important',
                                            },
                                        '.MuiInput-root.MuiInputBase-sizeSmall .MuiInput-input': {
                                            padding: '.6rem 1rem .6rem 0',
                                        },
                                    }}
                                    hideHelperText
                                    hideInputHelperText
                                    hideSuccessHelper
                                />
                            </div>
                            <div className="input-line inp-phone">
                                <InputField
                                    name={`members.${memberIndex}.references.${referenceIndex}.phone_number`}
                                    placeholder="Enter phone number"
                                    form={form}
                                    disabled={
                                        form.watch(
                                            `members.${memberIndex}.isAfterChooseExistingMember`,
                                        ) || disabled
                                    }
                                    hideSuccessHelper
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-7 input-line">
                        <label>Fax No.</label>
                        <InputField
                            name={`members.${memberIndex}.references.${referenceIndex}.fax`}
                            placeholder="Enter Fax No."
                            label=""
                            form={form}
                            disabled={
                                form.watch(`members.${memberIndex}.isAfterChooseExistingMember`) ||
                                disabled
                            }
                            hideSuccessHelper
                        />
                    </div>
                    <div className="col-md-6 mb-7 input-line">
                        <label>Email Address</label>
                        <InputField
                            name={`members.${memberIndex}.references.${referenceIndex}.email`}
                            placeholder="Enter email address"
                            label=""
                            form={form}
                            disabled={
                                form.watch(`members.${memberIndex}.isAfterChooseExistingMember`) ||
                                disabled
                            }
                            hideSuccessHelper
                        />
                    </div>

                    <div className="col-md-6 mb-7 input-line">
                        <label>Physical address</label>
                        <InputField
                            name={`members.${memberIndex}.references.${referenceIndex}.address`}
                            placeholder="Enter Physical address"
                            label=""
                            form={form}
                            disabled={
                                form.watch(`members.${memberIndex}.isAfterChooseExistingMember`) ||
                                disabled
                            }
                            hideSuccessHelper
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

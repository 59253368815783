import React from 'react';
import SeeGuide from '../guide/Guide';
import ToggleTawkTo from '../../../../../../components/ToggleTawkTo';
import { REMOVED_PUBLISHED_TRACKING_LINK_BANKS } from '../../constant';

function WaitingScreenBankHasNoProcess({ orderInfo }) {
    const bankId = orderInfo?.Task.BankingProcesses?.[0]?.Bank?.id;
    const countryId = orderInfo?.Task.BankingProcesses?.[0]?.Bank?.Country?.id;
    const removedPublishedTrackingLinkBank = REMOVED_PUBLISHED_TRACKING_LINK_BANKS.find(
        (bank) => bank.id === bankId && bank.Country.id === countryId,
    );

    return (
        <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ minHeight: 470 }}
        >
            <img
                src="/media/order-form/approving.svg"
                className="img-fluid mb-5"
                width={220}
                alt="Your proposed meeting time was sent to the bank for acceptance."
            />

            {removedPublishedTrackingLinkBank ? (
                <div style={{ maxWidth: '435px' }}>
                    <p className={'text-center'} style={{ color: '#007EFF' }}>
                        Your documents are being processed. It will take around 2-3 days.
                    </p>
                </div>
            ) : (
                <>
                    {!!orderInfo?.BankingGuide?.length && (
                        <p className={'text-center mt-2'}>
                            {orderInfo?.BankingGuide?.length > 0 && (
                                <SeeGuide fileGuide={orderInfo?.BankingGuide[0]} />
                            )}
                        </p>
                    )}
                    <div style={{ maxWidth: '435px' }}>
                        <p className={'text-center'} style={{ color: '#007EFF' }}>
                            Your banking application with{' '}
                            <b>{orderInfo?.Task?.BankingProcesses?.[0]?.Bank?.name}</b> are being
                            processed and your dedicated RM will reach you with details via email.
                            If you need any assistance, please do not hesitate to contact us.
                        </p>
                        <p className={'text-center'}>
                            <ToggleTawkTo text={'Consult your dedicated manager'} />
                        </p>
                    </div>
                </>
            )}
        </div>
    );
}

export default WaitingScreenBankHasNoProcess;

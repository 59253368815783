import React, { useEffect } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import './payment-ui.scss';

export const PaymentUI = ({ countryID, setPaymentMethod, paymentMethod, existOrder }) => {
    const handleChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    return (
        <div>
            <h3 className="mt-9">Payment method</h3>
            <div className="payment-list m-ui-card-radio">
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="myCompany"
                        name="myCompany"
                        className="flex-row justify-content-center"
                        value={paymentMethod}
                        onChange={handleChange}
                    >
                        {/* <FormControlLabel value="paypal" control={<Radio />} label="Paypal" /> */}
                        <FormControlLabel
                            value="stripe"
                            control={<Radio />}
                            // label="Debit/Credit Card"
                            /* country_id = 191 -> Singapore */
                            label={
                                countryID === 191
                                    ? 'Debit/Credit Card/Pay Now'
                                    : 'Debit/Credit Card'
                            }
                        />
                        {existOrder ? (
                            <div></div>
                        ) : (
                            <FormControlLabel
                                value="bank"
                                control={<Radio />}
                                label="Bank Transfer"
                            />
                        )}
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    );
};

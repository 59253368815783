import { blue, red } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Share from './Share';
import DateField from 'components/form-controls/DateField';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import { showExpiryDate } from 'app/helpers/utils';
import { Typography } from '@mui/material';
import SelectFullNameHint from 'app/components/SelectFullNameHint';
import {
    getDefaultNationality,
    getDefaultPhonePrefix,
} from 'app/pages/services/utilities/NationalityHelpers';

const Individual = (props) => {
    const {
        disabled,
        changeFields,
        disabledFields,
        member,
        form,
        isShowHelperExpiryDate = true,
        hasShowSelectFullName,
        isContactPerson,
    } = props;
    const { countries, phonePrefixCountries } = useSelector(
        (state) => ({
            countries: state.enum.countries,
            phonePrefixCountries: state.enum.phonePrefixCountries,
        }),
        shallowEqual,
    );
    const wExpiryDate = form.watch('passport_expire_date');

    useEffect(() => {
        let timeout;
        try {
            if (!countries?.length || !phonePrefixCountries?.length) return;

            const phonePrefixDefault = getDefaultPhonePrefix(
                member?.phone_country_id,
                phonePrefixCountries,
            );

            const nationality = getDefaultNationality(member?.country_id, countries);

            if (form.getValues()) {
                // resolve error: create member -> next step -> back step -> delete member -> fill info -> next step -> ERROR
                timeout = setTimeout(() => {
                    if (!member?.phone_country_id) {
                        form.setValue('phone_country_id', phonePrefixDefault?.id);
                    }
                    if (!member?.country_id) {
                        form.setValue('country_id', nationality?.id);
                    }
                }, 1);
            }
        } catch (error) {
            console.log(error);
        }

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [member, countries, phonePrefixCountries]);

    return (
        <>
            <div className="row">
                {hasShowSelectFullName && (
                    <>
                        <div className="col-md-4 mb-7 input-line">
                            <label>First name</label>
                            <InputField
                                form={form}
                                name={'first_name'}
                                placeholder="First name"
                                hideSuccessHelper
                                disabled={disabled || disabledFields?.['first_name']?.(member)}
                                onChange={(e) => {
                                    form.setValue('first_name', e.target.value);
                                    if (form.getValues('full_name')) {
                                        form.setValue('full_name', '');
                                    }
                                }}
                                customStyle={{
                                    '& .MuiInputBase-input': {
                                        backgroundColor: changeFields?.first_name
                                            ? blue[50]
                                            : 'inherit',
                                    },
                                }}
                            />
                        </div>
                        <div className="col-md-4 mb-7 input-line">
                            <label>Middle name</label>
                            <InputField
                                form={form}
                                name={'middle_name'}
                                placeholder="Middle name"
                                hideSuccessHelper
                                disabled={disabled || disabledFields?.['middle_name']?.(member)}
                                customStyle={{
                                    '& .MuiInputBase-input': {
                                        backgroundColor: changeFields?.middle_name
                                            ? blue[50]
                                            : 'inherit',
                                    },
                                }}
                            />
                        </div>
                        <div className="col-md-4 mb-7 input-line">
                            <label>Last name</label>
                            <InputField
                                form={form}
                                name={'last_name'}
                                placeholder="Last name"
                                hideSuccessHelper
                                disabled={disabled || disabledFields?.['last_name']?.(member)}
                                onChange={(e) => {
                                    form.setValue('last_name', e.target.value);
                                    if (form.getValues('full_name')) {
                                        form.setValue('full_name', '');
                                    }
                                }}
                                customStyle={{
                                    '& .MuiInputBase-input': {
                                        backgroundColor: changeFields?.last_name
                                            ? blue[50]
                                            : 'inherit',
                                    },
                                }}
                            />
                        </div>
                    </>
                )}
                <div className="col-lg-6 input-line mb-5">
                    <label>Full name</label>
                    <InputField
                        name="full_name"
                        placeholder="Enter your name"
                        hideSuccessHelper
                        form={form}
                        disabled={
                            disabled ||
                            disabledFields?.['full_name']?.(member) ||
                            hasShowSelectFullName
                        }
                        customStyle={{
                            '& .MuiInputBase-input': {
                                backgroundColor: changeFields?.full_name ? blue[50] : 'inherit',
                                padding: '.6rem 1rem .6rem 0',
                            },
                        }}
                    />
                </div>
                <div className="col-lg-6 input-line mb-5">
                    <label style={{ marginBottom: '5px' }}>Birthday</label>
                    <DateField
                        name="birthday"
                        placeholder="DD/MM/YYYY"
                        form={form}
                        disabled={disabled || disabledFields?.['birthday']?.(member)}
                        inputProps={{
                            wrapperStyle: {
                                bgcolor: changeFields?.birthday ? blue[50] : 'inherit',
                            },
                        }}
                        customStyle={{
                            '& .MuiInputBase-input': {
                                padding: '.6rem 1rem .6rem 0',
                            },
                            '.MuiInputBase-root.Mui-error:after': {
                                borderBottomColor: '#f64e60',
                            },
                            '&.Mui-success .MuiInputBase-root:after': {
                                transform: 'scaleX(1) translateX(0)',
                            },
                        }}
                        maxDate={Date.now()}
                    />
                </div>
                {hasShowSelectFullName && (
                    <SelectFullNameHint
                        form={form}
                        fullNameField={'full_name'}
                        firstNameField={'first_name'}
                        middleNameField={'middle_name'}
                        lastNameField={'last_name'}
                        disabled={disabled || disabledFields?.['full_name']?.(member)}
                    />
                )}

                <div className="col-lg-6 input-line mb-5">
                    <label>Nationality</label>
                    <SelectField
                        label=""
                        form={form}
                        name="country_id"
                        options={countries || []}
                        getOptionLabel={(option) => option?.name || ''}
                        disabled={disabled || disabledFields?.['country_id']?.(member)}
                        hideInputHelperText
                        hideSuccessHelper
                        autoComplete="off"
                        onChange={() => {
                            form.setValue('passport', '');
                            form.setValue('passport_expire_date', null);
                            form.setValue('passport_issue_date', null);
                        }}
                        customStyle={{
                            '& .MuiInputBase-input': {
                                backgroundColor: changeFields?.country_id ? blue[50] : 'inherit',
                                padding: '.6rem 1rem .5rem 0',
                            },
                        }}
                    />
                </div>
                {!isContactPerson && (
                    <div className="col-lg-6 input-line mb-5">
                        <label>Your Major</label>
                        <InputField
                            name="major"
                            placeholder="Your major"
                            form={form}
                            disabled={disabled || disabledFields?.['major']?.(member)}
                            customStyle={{
                                '& .MuiInputBase-input': {
                                    backgroundColor: changeFields?.major ? blue[50] : 'inherit',
                                    padding: '.6rem 1rem .8rem 0',
                                },
                            }}
                            hideSuccessHelper
                        />
                    </div>
                )}

                <div className="col-lg-6 mb-5 input-line" style={{ marginTop: '3px' }}>
                    <label>Phone number</label>
                    <div className="phone-group">
                        <div className="phone-code" style={{ zIndex: 1 }}>
                            <SelectField
                                id="phone-prefix-select"
                                className="fw-500"
                                name="phone_country_id"
                                label=""
                                inputProps={(params) => ({
                                    ...params,
                                    placeholder: 'Phone country',
                                })}
                                form={form}
                                options={phonePrefixCountries || []}
                                getOptionLabel={(option) => option?.name || ''}
                                disabled={
                                    disabled || disabledFields?.['phone_country_id']?.(member)
                                }
                                customStyle={{
                                    '.MuiInputBase-root:before, .MuiInputBase-root:after, .MuiInput-underline:hover:not(.Mui-disabled):before':
                                        {
                                            borderBottom: '0 !important',
                                        },
                                    '.MuiInput-root.MuiInputBase-sizeSmall .MuiInput-input': {
                                        padding: '.6rem 1rem .6rem 0',
                                    },
                                    '& .MuiInputBase-input': {
                                        backgroundColor: changeFields?.phone_country_id
                                            ? blue[50]
                                            : 'inherit',
                                    },
                                }}
                            />
                        </div>
                        <div className="input-line inp-phone">
                            <InputField
                                placeholder="Your phone number"
                                name="phone_number"
                                form={form}
                                disabled={disabled || disabledFields?.['phone_number']?.(member)}
                                customStyle={{
                                    '& .MuiInputBase-input': {
                                        backgroundColor: changeFields?.phone_number
                                            ? blue[50]
                                            : 'inherit',
                                        padding: '.6rem 1rem .6rem 0',
                                    },
                                }}
                                hideSuccessHelper
                            />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 mb-5 input-line">
                    <label>Passport/ID card number</label>
                    <InputField
                        name="passport"
                        placeholder="Enter your passport"
                        form={form}
                        disabled={disabled || disabledFields?.['passport']?.(member)}
                        onChange={() => {
                            if (form.getValues('passport_expire_date')) {
                                form.setValue('passport_expire_date', null);
                            }
                            if (form.getValues('passport_issue_date')) {
                                form.setValue('passport_issue_date', null);
                            }
                        }}
                        customStyle={{
                            '& .MuiInputBase-input': {
                                backgroundColor: changeFields?.passport ? blue[50] : 'inherit',
                                padding: '.6rem 1rem .8rem 0',
                            },
                        }}
                        hideSuccessHelper
                    />
                </div>
                {!isContactPerson && (
                    <div className="col-lg-6 mb-5 input-line">
                        <label style={{ marginBottom: '5px' }}>Date of appointment</label>
                        <DateField
                            name="date_of_appointment"
                            placeholder="DD/MM/YYYY"
                            form={form}
                            disabled={member.level === 1}
                            customStyle={{
                                '& .MuiInputBase-input': {
                                    padding: '.6rem 1rem .6rem 0',
                                },
                                '.MuiInputBase-root.Mui-error:after': {
                                    borderBottomColor: '#f64e60',
                                },
                                '&.Mui-success .MuiInputBase-root:after': {
                                    transform: 'scaleX(1) translateX(0)',
                                },
                            }}
                        />
                    </div>
                )}

                <Share {...props} />
                {!isContactPerson && (
                    <div className="col-lg-6 mb-5 input-line">
                        <label style={{ marginBottom: '5px' }}>Date of issue passport</label>
                        <DateField
                            name="passport_issue_date"
                            placeholder="DD/MM/YYYY"
                            form={form}
                            disabled={disabled || disabledFields?.['passport_issue_date']?.(member)}
                            inputProps={{
                                wrapperStyle: {
                                    bgcolor: changeFields?.passport_issue_date
                                        ? blue[50]
                                        : 'inherit',
                                },
                            }}
                            customStyle={{
                                '& .MuiInputBase-input': {
                                    padding: '.6rem 1rem .6rem 0',
                                },
                                '.MuiInputBase-root.Mui-error:after': {
                                    borderBottomColor: '#f64e60',
                                },
                                '&.Mui-success .MuiInputBase-root:after': {
                                    transform: 'scaleX(1) translateX(0)',
                                },
                            }}
                        />
                    </div>
                )}

                <div className="col-lg-6 mb-5 input-line">
                    <label>
                        {isContactPerson
                            ? 'Expiry date of Identification'
                            : 'Date of expiration passport'}
                    </label>
                    <DateField
                        name="passport_expire_date"
                        placeholder="DD/MM/YYYY"
                        form={form}
                        disabled={disabled || disabledFields?.['passport_expire_date']?.(member)}
                        inputProps={{
                            wrapperStyle: {
                                bgcolor: changeFields?.passport_expire_date ? blue[50] : 'inherit',
                            },
                        }}
                        customStyle={{
                            '& .MuiInputBase-input': {
                                padding: '.7rem 1rem .7rem 0',
                            },
                            '.MuiInputBase-root.Mui-error:after': {
                                borderBottomColor: '#f64e60',
                            },
                            '&.Mui-success .MuiInputBase-root:after': {
                                transform: 'scaleX(1) translateX(0)',
                            },
                        }}
                    />
                    {isShowHelperExpiryDate && showExpiryDate(wExpiryDate, 180) && (
                        <Typography style={{ fontSize: '11px' }} color={red[600]}>
                            Less than 6 months left
                        </Typography>
                    )}
                </div>
                {isContactPerson && (
                    <>
                        <div className="col-lg-3 input-line">
                            <SelectField
                                name={'address_country_id'}
                                label="Country"
                                form={form}
                                options={countries || []}
                                multiple={false}
                                disabled={
                                    disabled || disabledFields?.['address_country_id']?.(member)
                                }
                            />
                        </div>
                        <div className="col-lg-3 input-line">
                            <InputField
                                name={'city'}
                                form={form}
                                label={'City'}
                                size="small"
                                disabled={disabled || disabledFields?.['city']?.(member)}
                            />
                        </div>
                        <div className="col-lg-3 input-line">
                            <InputField
                                name={'state'}
                                form={form}
                                label={'State'}
                                size="small"
                                disabled={disabled || disabledFields?.['state']?.(member)}
                            />
                        </div>
                        <div className="col-lg-3 input-line">
                            <InputField
                                name={'postal_code'}
                                form={form}
                                label={'Postal Code'}
                                size="small"
                                disabled={disabled || disabledFields?.['postal_code']?.(member)}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Individual;

import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';

import { BANK_SUCCESS_CONTENT_TYPE } from 'app/pages/services/constants/banking_constants';
import { findBankData } from 'app/pages/services/order-management/banking/helpers';

import { CayeBank, CimBank, EpbBank, hkBankList, sgBankList } from 'constants/banking';

/**
 * hiển thị nội dung success dựa vào bank id + country id.
 */

export function NoticeSuccessNormal({ bank } = { ...bank }) {
    // const contentType = findBankData(BANK_SUCCESS_CONTENT_TYPE, bank?.country_id, bank.?bank_id)?.content_type;

    let contentType = 1; // gán tạm

    /**
     * 
     *  HK: 5, 6, 7, 8, 9, 10, 11, 25, 26, 31
        SG: 17, 18, 19, 20, 22, 27
        Caye: 1
        EPB: 16
        CIM: 23
        Bank One: 13
     * 
     */

    const hkBanks = hkBankList.includes(bank.bank_id);
    const sgBanks = sgBankList.includes(bank.bank_id);
    const cayeBank = CayeBank === bank.bank_id;
    const epbBank = EpbBank === bank.bank_id;
    const cimBank = CimBank === bank.bank_id;

    if (hkBanks || sgBanks) contentType = 1;
    if (cayeBank) contentType = 2;
    if (epbBank) contentType = 3;
    if (cimBank) contentType = 4;

    function getNoticeContent() {
        switch (contentType) {
            // No uploal file:  Hong Kong Banks, Singapore Banks, Euro Pacific Bank, CIM,  Caye
            case 1: // HK+ Singapore
                return (
                    <>
                        <p>THANK YOU FOR YOUR KIND COOPERATION!</p>
                        <span className="fw-500">Congratulations!</span> Your bank account
                        application has been successfully approved, and the account notification is
                        on its way to your email.
                        <br />
                        Here is your next step:
                        <ul className="list-type-check">
                            <li>
                                To ensure your account remains active, you must make an initial
                                deposit. Once the funds have been credited to your account, you can
                                use them freely.
                            </li>
                        </ul>
                        <span>
                            We would greatly appreciate it if you were satisfied with our services
                            and could spread the word about us by leaving a review on{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/google.png"
                                        alt="google-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            and/or{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.trustpilot.com/review/bbcincorp.com"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/trustpilot.png"
                                        alt="trust-pilot-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            .
                        </span>
                        <p>
                            Your feedback will help us gain valuable insight into our services and
                            motivate our team to strive for even better results in the future.
                        </p>
                        <p>Thank you for your continued support!</p>
                    </>
                );

            case 2: // Caye
                return (
                    <>
                        <p>THANK YOU FOR YOUR KIND COOPERATION!</p>
                        <p>
                            <span className="fw-500">Congratulations!</span> Your bank account
                            application has been successfully approved and the account notification
                            is on its way to your email.
                        </p>
                        <p>
                            We would greatly appreciate it if you were satisfied with our services
                            and could spread the word about us by leaving a review on{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/google.png"
                                        alt="google-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>{' '}
                            and/or{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.trustpilot.com/review/bbcincorp.com"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/trustpilot.png"
                                        alt="trust-pilot-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            .
                        </p>
                        <p>
                            Your feedback will help us gain valuable insight into our services and
                            motivate our team to strive for even better results in the future.
                        </p>
                        <p>Thank you for your continued support!</p>
                    </>
                );

            case 3: // Euro Pacific Bank (EPB):
                return (
                    <>
                        <p>THANK YOU FOR YOUR KIND COOPERATION!</p>
                        <span className="fw-500">Congratulations!</span> Your bank account
                        application has been successfully approved, and the account notification is
                        on its way to your email.
                        <br />
                        Here is your next step:
                        <ul className="list-type-check">
                            <li>
                                Make sure to deposit at least $1,000 into your account in order to
                                keep it active and usable. Once the money has been credited to your
                                account, you can freely access and spend it however you'd like.
                            </li>
                        </ul>
                        <p>
                            We would greatly appreciate it if you were satisfied with our services
                            and could spread the word about us by leaving a review on{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/google.png"
                                        alt="google-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>{' '}
                            and/or{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.trustpilot.com/review/bbcincorp.com"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/trustpilot.png"
                                        alt="trust-pilot-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            .
                        </p>
                        <p>
                            Your feedback will help us gain valuable insight into our services and
                            motivate our team to strive for even better results in the future.
                        </p>
                        <p>Thank you for your continued support!</p>
                    </>
                );

            case 4: // CIM:
                return (
                    <>
                        <p>THANK YOU FOR YOUR KIND COOPERATION!</p>
                        <span className="fw-500">Congratulations!</span> Your bank account
                        application has been successfully approved, and the account notification is
                        on its way to your email.
                        <br />
                        Here is your next step:
                        <ul className="list-type-check">
                            <li>
                                Make sure to deposit at least $1,000 into your account in order to
                                keep it active and usable. Once the money has been credited to your
                                account, you can freely access and spend it however you'd like.
                            </li>
                        </ul>
                        <p>
                            We would greatly appreciate it if you were satisfied with our services
                            and could spread the word about us by leaving a review on{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/google.png"
                                        alt="google-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>{' '}
                            and/or{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.trustpilot.com/review/bbcincorp.com"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/trustpilot.png"
                                        alt="trust-pilot-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            .
                        </p>
                        <p>
                            Your feedback will help us gain valuable insight into our services and
                            motivate our team to strive for even better results in the future.
                        </p>
                        <p>Thank you for your continued support!</p>
                    </>
                );

            default: // set tạm nội dung default, cần confirm lại
                return (
                    <>
                        <span className="fw-500">Congratulations!</span> Your bank account
                        application has been approved. The account notification has been sent to
                        your email.
                        <br />
                        <p>
                            We would greatly appreciate it if you were satisfied with our services
                            and could spread the word about us by leaving a review on{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/google.png"
                                        alt="google-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>{' '}
                            and/or{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.trustpilot.com/review/bbcincorp.com"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/trustpilot.png"
                                        alt="trust-pilot-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            .
                        </p>
                        <p>
                            Your feedback will help us gain valuable insight into our services and
                            motivate our team to strive for even better results in the future.
                        </p>
                        <p>Thank you for your continued support!</p>
                    </>
                );

            // default: // use content of HK+ Singapore
            //     return (<>
            //         <span className="fw-500">Congratulations!</span> Your bank account application has been approved. The account notification has been sent to your email.
            //         <br/>
            //         Here is your next step:
            //         <ul className="list-type-check">
            //             <li>Deposit required initial fund into your account to keep your account active. The deposit could be used freely upon credited into account.</li>
            //         </ul>
            //     </>)
        }
    }

    return <>{getNoticeContent()}</>;
}

import React from 'react';
import IconButton from '@mui/material/IconButton';
import { Visibility } from '@mui/icons-material';
import 'app/components/DocumentMiniList.scss';
import { CircularProgress } from '@mui/material';

const DocumentMiniList = ({
    documentList,
    panelTitle,
    onClickView,
    onClickDelete,
    maxHeightPanel = '300px',
    loadingMap,
}) => {

    return (
        <div className={'rounded document-mini-list'} style={{ backgroundColor: '#f3f3f3' }}>
            <p
                className={
                    'text-center border-bottom font-weight-bold p-3 document-mini-list--title '
                }
            >
                {panelTitle}
            </p>
            <div className={'overflow-auto'} style={{ height: maxHeightPanel }}>
                <ul className={'px-3'}>
                    {!documentList.length ? (
                        <p className={'text-center text-black-50'}>
                            <i className="far fa-folder-open"></i> <br /> empty
                        </p>
                    ) : (
                        documentList.map((document, index) => (
                            <li
                                key={index}
                                className={
                                    'd-flex justify-content-between align-items-center border-bottom pl-2 pb-1'
                                }
                            >
                                <span>{document.name}</span>

                                <div
                                    className={'document-mini-list--actions'}
                                    style={{ minWidth: '60px' }}
                                >
                                    <IconButton
                                        onClick={() => onClickView && onClickView(document)}
                                        className="p-1 mx-1"
                                        size="large"
                                    >
                                        <Visibility fontSize="small" />
                                    </IconButton>
                                    {loadingMap[document.id] !== 'loading' ? (
                                        document?.can_be_deleted && (
                                            <IconButton
                                                className="p-1 mx-1"
                                                onClick={() =>
                                                    onClickDelete && onClickDelete(document)
                                                }
                                                size="large"
                                            >
                                                <img
                                                    src="/media/svg/icons/Order/delete.svg"
                                                    width="11px"
                                                    height="11px"
                                                    alt="delete"
                                                />
                                            </IconButton>
                                        )
                                    ) : (
                                        <CircularProgress className={'mx-1'} size={'10px'} />
                                    )}
                                </div>
                            </li>
                        ))
                    )}
                </ul>
            </div>
        </div>
    );
};

export default DocumentMiniList;

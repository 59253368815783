import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { green } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        display: 'inline-flex'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

function LoadingButton({loading, children, ...props}) {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <Button
                {...props}
            >
                {children}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    );
}

export default LoadingButton;

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import InputField from 'components/form-controls/InputField';
import RadioField from 'components/form-controls/RadioField';
import SelectField from 'components/form-controls/SelectField';
import { BAHAMAS_ID, DELAWARE_ID, ST_KIT_ID, SVG_ID } from 'constants/country';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { getBusinessInfoAllowFields } from '../../helpers';
import { isArray } from 'lodash';

const validationInterger = function () {
    return this.test(function (value) {
        if ((value + '').split('.').length >= 2) {
            return this.createError({ path: this.path, message: 'Number is integer!' });
        }
        return true;
    });
};
const transformNumber = function () {
    return this.transform(function (value) {
        return isNaN(value) ? undefined : value;
    });
};

yup.addMethod(yup.mixed, 'validateInterger', validationInterger);
yup.addMethod(yup.mixed, 'transformNumber', transformNumber);

const businessInfoSchema = ({ isDelaware = false, isSVG = false }) => {
    return yup.object().shape({
        business_full_name: yup.string().nullable().required('Full name is required!'),
        business_country_id: yup.string().nullable(),
        business_address: yup.string().nullable().required('Address is required!'),
        business_city: yup.string().nullable().required('City is required!'),
        delaware_question_1:
            isDelaware && yup.string().nullable().required('This fields is required!'),
        number_of_employees:
            isDelaware &&
            yup
                .number()
                .transformNumber()
                .validateInterger()
                .required('Number of Employees is required.'),
        name_of_license: yup
            .string()
            .nullable()
            .when('bahamas_question_4', {
                is: 'Yes',
                then: yup.string().nullable().required('Please enter a name!'),
            }),
        name_of_foreign_auth: yup
            .string()
            .nullable()
            .when('bahamas_question_5', {
                is: 'Yes',
                then: yup.string().nullable().required('Please enter a name!'),
            }),
        country_of_foreign_auth: yup
            .number()
            .nullable()
            .when('bahamas_question_5', {
                is: 'Yes',
                then: yup.number().nullable().required('Choose a country!'),
            }),
        website_url: yup
            .string()
            .nullable()
            .when('svg_question_1', {
                is: 'Yes',
                then: yup.string().nullable().required('Website address is required.'),
            }),
        svg_website_ip: yup
            .string()
            .nullable()
            .when('svg_question_2', {
                is: 'Yes',
                then: yup.string().nullable().required('Website IP is required.'),
            }),
        business_volume:
            isSVG && yup.string().nullable().required('Volume of Business is required'),
        business_value: isSVG && yup.string().nullable().required('Value of Business is required'),
    });
};

const monthsList = [
    { id: 1, label: 'January' },
    { id: 2, label: 'February' },
    { id: 3, label: 'March' },
    { id: 4, label: 'April' },
    { id: 5, label: 'May' },
    { id: 6, label: 'June' },
    { id: 7, label: 'July' },
    { id: 8, label: 'August' },
    { id: 9, label: 'September' },
    { id: 10, label: 'October' },
    { id: 11, label: 'November' },
    { id: 12, label: 'December' },
];

const BusinessInfo = ({
    task,
    disabled,
    IS_LLC,
    isHideAction,
    isLoading,
    onSubmit,
    handleNext,
    handleBack,
}) => {
    const [businessInfo, setBusinessInfo] = useState(null);

    const { countriesCooperationOptions } = useSelector((state) => state.enum);
    const { comparedCompany } = useSelector((state) => state.companies);

    const isSVG = useMemo(() => task?.Company?.country_id === SVG_ID, [task]);
    const isDelaware = useMemo(() => task?.Company?.country_id === DELAWARE_ID, [task]);
    const isBahamas = useMemo(() => task?.Company?.country_id === BAHAMAS_ID, [task]);
    const isStKit = useMemo(() => task?.Company?.country_id === ST_KIT_ID, [task]);

    const form = useForm({
        defaultValues: {
            business_full_name: '',
            business_address: '',
            business_country_id: null,
            business_state: '',
            business_city: '',
            business_postal_code: '',
            business_month_end: null,
            delaware_question_1: null,
            number_of_employees: null,
            bahamas_question_1: null,
            bahamas_question_2: null,
            bahamas_question_3: null,
            regulated_by_1: null,
            regulated_by_2: null,
            regulated_by_3: null,
            bahamas_question_4: null,
            name_of_license: '',
            bahamas_question_5: null,
            name_of_foreign_auth: '',
            country_of_foreign_auth: null,
            svg_question_1: null,
            website_url: '',
            svg_question_2: null,
            svg_website_ip: '',
            business_volume: '',
            business_value: '',
        },
        resolver: yupResolver(businessInfoSchema({ isDelaware: isDelaware, isSVG: isSVG })),
    });

    useEffect(() => {
        const initBusinessInfo = {
            business_full_name: task?.Company?.BusinessInformation?.full_name || '',
            business_address: task?.Company?.BusinessInformation?.address || '',
            business_country_id: task?.Company?.BusinessInformation?.country_id || null,
            business_state: task?.Company?.BusinessInformation?.state || '',
            business_city: task?.Company?.BusinessInformation?.city || '',
            business_postal_code: task?.Company?.BusinessInformation?.postal_code || '',
            business_month_end: task?.Company?.BusinessInformation?.month_end || null,
            delaware_question_1:
                task?.Task.IncorporationCountry?.Questions &&
                task?.Task.IncorporationCountry?.Questions?.find((q) => q.id === 7)?.Options?.find(
                    (opt) => opt.id === 11,
                )?.Answers?.length === 0
                    ? 'No'
                    : 'Yes' || null,
            number_of_employees:
                task?.Task.IncorporationCountry?.Questions &&
                task?.Task.IncorporationCountry?.Questions?.find((q) => q.id === 8)?.Options?.find(
                    (opt) => opt.id === 13,
                )?.Answers?.length > 0
                    ? task?.Task.IncorporationCountry?.Questions?.find(
                          (q) => q.id === 8,
                      )?.Options?.find((opt) => opt.id === 13)?.Answers[0]?.value_text
                    : 0 || null,
            bahamas_question_1:
                task?.Task?.IncorporationCountry?.Questions &&
                task?.Task?.IncorporationCountry?.Questions.find((q) => q.id === 9)?.Options?.find(
                    (opt) => opt.id === 14,
                )?.Answers?.length > 0
                    ? 'Yes'
                    : task?.Task?.IncorporationCountry?.Questions?.find(
                          (q) => q.id === 9,
                      )?.Options?.find((opt) => opt.id === 15)?.Answers?.length > 0
                    ? 'No'
                    : null,
            bahamas_question_2:
                task?.Task?.IncorporationCountry?.Questions &&
                task?.Task?.IncorporationCountry?.Questions?.find(
                    (q) => q.id === 10,
                )?.Options?.find((opt) => opt.id === 16)?.Answers?.length > 0
                    ? 'Yes'
                    : task?.Task?.IncorporationCountry?.Questions?.find(
                          (q) => q.id === 10,
                      )?.Options?.find((opt) => opt.id === 17)?.Answers?.length > 0
                    ? 'No'
                    : null,
            bahamas_question_3:
                task?.Task?.IncorporationCountry?.Questions &&
                task?.Task?.IncorporationCountry?.Questions?.find(
                    (q) => q.id === 11,
                )?.Options?.find((opt) => opt.id === 18)?.Answers?.length > 0
                    ? 'Yes'
                    : task?.Task?.IncorporationCountry?.Questions?.find(
                          (q) => q.id === 11,
                      )?.Options?.find((opt) => opt.id === 19)?.Answers?.length > 0
                    ? 'No'
                    : null,
            regulated_by_1:
                task?.Task?.IncorporationCountry?.Questions &&
                task?.Task?.IncorporationCountry?.Questions?.find((q) => q.id === 11)
                    ?.Children?.find((opt) => opt.id === 12)
                    ?.Options?.find((opt) => opt.id === 20)?.Answers?.length > 0
                    ? ['Yes']
                    : [] || null,
            regulated_by_2:
                task?.Task?.IncorporationCountry?.Questions &&
                task?.Task?.IncorporationCountry?.Questions?.find((q) => q.id === 11)
                    ?.Children?.find((opt) => opt.id === 12)
                    .Options?.find((opt) => opt.id === 21)?.Answers?.length > 0
                    ? ['Yes']
                    : [] || null,
            regulated_by_3:
                task?.Task?.IncorporationCountry?.Questions &&
                task?.Task?.IncorporationCountry?.Questions?.find((q) => q.id === 11)
                    ?.Children?.find((opt) => opt.id === 12)
                    ?.Options?.find((opt) => opt.id === 22)?.Answers?.length > 0
                    ? ['Yes']
                    : [] || null,
            bahamas_question_4:
                task?.Task?.IncorporationCountry?.Questions?.find(
                    (q) => q.id === 13,
                )?.Options?.find((opt) => opt.id === 23)?.Answers?.length > 0
                    ? 'Yes'
                    : task?.Task?.IncorporationCountry?.Questions?.find(
                          (q) => q.id === 13,
                      )?.Options?.find((opt) => opt.id === 24)?.Answers.length > 0
                    ? 'No'
                    : null,
            name_of_license:
                task?.Task?.IncorporationCountry?.Questions &&
                task?.Task?.IncorporationCountry?.Questions?.find((q) => q.id === 13)
                    ?.Children?.find((opt) => opt.id === 14)
                    ?.Options?.find((opt) => opt.id === 25)?.Answers?.length > 0
                    ? task?.Task?.IncorporationCountry?.Questions?.find((q) => q.id === 13)
                          ?.Children?.find((opt) => opt.id === 14)
                          ?.Options?.find((opt) => opt.id === 25)?.Answers?.[0]?.value_text
                    : '' || null,
            bahamas_question_5:
                task?.Task?.IncorporationCountry?.Questions &&
                task?.Task?.IncorporationCountry?.Questions?.find(
                    (q) => q.id === 15,
                )?.Options?.find((opt) => opt.id === 26)?.Answers?.length > 0
                    ? 'Yes'
                    : task?.Task?.IncorporationCountry?.Questions?.find(
                          (q) => q.id === 15,
                      )?.Options?.find((opt) => opt.id === 27)?.Answers?.length > 0
                    ? 'No'
                    : null,
            name_of_foreign_auth:
                task?.Task?.IncorporationCountry?.Questions &&
                task?.Task?.IncorporationCountry?.Questions?.find((q) => q.id === 15)
                    ?.Children?.find((opt) => opt.id === 16)
                    ?.Options?.find((opt) => opt.id === 28)?.Answers?.length > 0
                    ? task?.Task?.IncorporationCountry?.Questions?.find((q) => q.id === 15)
                          ?.Children?.find((opt) => opt.id === 16)
                          ?.Options?.find((opt) => opt.id === 28)?.Answers?.[0]?.value_text
                    : '' || null,
            country_of_foreign_auth:
                task?.Task?.IncorporationCountry?.Questions &&
                task?.Task?.IncorporationCountry?.Questions?.find((q) => q.id === 15)
                    ?.Children?.find((opt) => opt.id === 16)
                    ?.Options?.find((opt) => opt.id === 28)?.Answers?.length > 0
                    ? task?.Task?.IncorporationCountry?.Questions?.find((q) => q.id === 15)
                          ?.Children?.find((opt) => opt.id === 16)
                          ?.Options?.find((opt) => opt.id === 28)?.Answers?.[0]?.select_country_id
                    : null || null,
            svg_question_1:
                task?.Task?.IncorporationCountry?.Questions &&
                task?.Task?.IncorporationCountry?.Questions?.find((q) => q.id === 3)?.Options?.find(
                    (opt) => opt.id === 5,
                )?.Answers?.length > 0
                    ? 'Yes'
                    : task?.Task?.IncorporationCountry?.Questions?.find(
                          (q) => q.id === 3,
                      )?.Options?.find((opt) => opt.id === 6)?.Answers?.length > 0
                    ? 'No'
                    : null,
            website_url:
                task?.Task?.IncorporationCountry?.Questions &&
                task?.Task?.IncorporationCountry?.Questions?.find((q) => q.id === 3)?.Options?.find(
                    (opt) => opt.id === 5,
                )?.Answers?.length > 0
                    ? task?.Task?.IncorporationCountry?.Questions?.find((q) => q.id === 3)
                          ?.Children?.find((q) => q.id === 4)
                          ?.Options?.find((opt) => opt.id === 7)?.Answers?.[0]?.value_text
                    : '',
            svg_question_2:
                task?.Task?.IncorporationCountry?.Questions &&
                task?.Task?.IncorporationCountry?.Questions?.find((q) => q.id === 5)?.Options?.find(
                    (opt) => opt.id === 8,
                )?.Answers?.length > 0
                    ? 'Yes'
                    : task?.Task?.IncorporationCountry?.Questions?.find(
                          (q) => q.id === 5,
                      )?.Options?.find((opt) => opt.id === 9)?.Answers?.length > 0
                    ? 'No'
                    : null,
            svg_website_ip:
                task?.Task?.IncorporationCountry?.Questions &&
                task?.Task?.IncorporationCountry?.Questions?.find((q) => q.id === 5)?.Options?.find(
                    (opt) => opt.id === 8,
                )?.Answers?.length > 0
                    ? task?.Task?.IncorporationCountry?.Questions?.find((q) => q.id === 5)
                          ?.Children?.find((q) => q.id === 6)
                          ?.Options?.find((opt) => opt.id === 10)?.Answers?.[0]?.value_text
                    : '',
            business_volume:
                (task?.Task?.IncorporationCountry?.Questions &&
                    task?.Task?.IncorporationCountry?.Questions?.find(
                        (q) => q.id === 181,
                    )?.Options?.find((opt) => opt.id === 620)?.Answers?.[0]?.value_text) ||
                '',
            business_value:
                (task?.Task?.IncorporationCountry?.Questions &&
                    task?.Task?.IncorporationCountry?.Questions?.find(
                        (q) => q.id === 180,
                    )?.Options?.find((opt) => opt.id === 619)?.Answers?.[0]?.value_text) ||
                '',
        };
        setBusinessInfo(initBusinessInfo);
        form.reset(initBusinessInfo);
    }, [task]);

    const handleSubmit = (values) => {
        if (Object.keys(form.formState.dirtyFields).length === 0) {
            handleNext();
        } else {
            const businessInformation = {
                business_full_name: values.business_full_name,
                business_address: values.business_address,
                business_country_id: values.business_country_id,
                business_city: values.business_city,
                business_state: values.business_state,
                business_postal_code: values.business_postal_code,
                business_month_end: values.business_month_end,
            };

            let options = [];
            const questions = task.Task.IncorporationCountry?.Questions ?? [];
            if (isDelaware) {
                questions.forEach((q, idx) => {
                    switch (q.id) {
                        case 7:
                            q.Options.forEach((opt) => {
                                let option = {
                                    option_id: opt.id,
                                    is_chosen:
                                        values[`delaware_question_1`] === opt.content ? 1 : 0,
                                };
                                options.push(option);
                            });
                            break;
                        case 8:
                            q.Options.forEach((opt) => {
                                let option = {
                                    option_id: opt.id,
                                    value_text: parseInt(
                                        values.number_of_employees?.toString(),
                                    ).toString(),
                                    is_chosen: 1,
                                };
                                options.push(option);
                            });
                            break;
                        default:
                            break;
                    }
                });
            }

            if (isBahamas) {
                questions.forEach((q, idx) => {
                    switch (q.id) {
                        case 9:
                            q.Options.forEach((opt) => {
                                let option = {
                                    option_id: opt.id,
                                    is_chosen: values[`bahamas_question_1`] === opt.content ? 1 : 0,
                                };
                                options.push(option);
                            });
                            break;

                        case 10:
                            q.Options.forEach((opt) => {
                                let option = {
                                    option_id: opt.id,
                                    is_chosen: values[`bahamas_question_2`] === opt.content ? 1 : 0,
                                };
                                options.push(option);
                            });
                            break;
                        case 11:
                            q.Options.forEach((opt) => {
                                let option = {
                                    option_id: opt.id,
                                    is_chosen: values[`bahamas_question_3`] === opt.content ? 1 : 0,
                                };
                                options.push(option);
                            });
                            q.Children[0].Options.forEach((opt, index) => {
                                if (values[`bahamas_question_3`] === 'Yes') {
                                    let option = {
                                        option_id: opt.id,
                                        is_chosen:
                                            values[`regulated_by_${index + 1}`] &&
                                            values[`regulated_by_${index + 1}`][0] === 'Yes'
                                                ? 1
                                                : 0,
                                    };
                                    options.push(option);
                                }
                            });

                            break;
                        case 13:
                            q.Options.forEach((opt) => {
                                if (values[`bahamas_question_4`] === 'Yes') {
                                    let option = {
                                        option_id: opt.id,
                                        is_chosen:
                                            values[`bahamas_question_4`] === opt.content ? 1 : 0,
                                    };
                                    options.push(option);
                                } else {
                                    let option = {
                                        option_id: opt.id,
                                        is_chosen:
                                            values[`bahamas_question_4`] === opt.content ? 1 : 0,
                                    };
                                    options.push(option);
                                }
                            });
                            q.Children[0].Options.forEach((opt) => {
                                if (values[`bahamas_question_4`] === 'Yes') {
                                    let option = {
                                        option_id: opt.id,
                                        value_text: values.name_of_license,
                                        is_chosen: 1,
                                    };
                                    options.push(option);
                                }
                            });
                            break;
                        case 15:
                            q.Options.forEach((opt) => {
                                let option = {
                                    option_id: opt.id,
                                    is_chosen: values[`bahamas_question_5`] === opt.content ? 1 : 0,
                                };
                                options.push(option);
                            });
                            q.Children[0].Options.forEach((opt) => {
                                if (values[`bahamas_question_5`] === 'Yes') {
                                    let option = {
                                        option_id: opt.id,
                                        value_text: values.name_of_foreign_auth,
                                        select_country_id: values.country_of_foreign_auth,
                                        is_chosen: 1,
                                    };
                                    options.push(option);
                                }
                            });

                            break;
                        default:
                            break;
                    }
                });
            }
            if (isSVG) {
                questions.forEach((q, idx) => {
                    switch (q.id) {
                        case 3:
                            q.Options.forEach((opt) => {
                                if (values.svg_question_1 === 'Yes') {
                                    let option = {
                                        option_id: opt.id,
                                        is_chosen: values.svg_question_1 === opt.content ? 1 : 0,
                                    };
                                    options.push(option);
                                } else {
                                    let option = {
                                        option_id: opt.id,
                                        is_chosen: values.svg_question_1 === opt.content ? 1 : 0,
                                    };
                                    options.push(option);
                                }
                            });
                            q.Children[0].Options.forEach((opt) => {
                                if (values.svg_question_1 === 'Yes') {
                                    let option = {
                                        option_id: opt.id,
                                        value_text: values.website_url,
                                        is_chosen: 1,
                                    };
                                    options.push(option);
                                }
                            });
                            break;
                        case 5:
                            q.Options.forEach((opt) => {
                                if (values.svg_question_2 === 'Yes') {
                                    let option = {
                                        option_id: opt.id,
                                        is_chosen: values.svg_question_2 === opt.content ? 1 : 0,
                                    };
                                    options.push(option);
                                } else {
                                    let option = {
                                        option_id: opt.id,
                                        is_chosen: values.svg_question_2 === opt.content ? 1 : 0,
                                    };
                                    options.push(option);
                                }
                            });
                            q.Children[0].Options.forEach((opt) => {
                                if (values.svg_question_2 === 'Yes') {
                                    let option = {
                                        option_id: opt.id,
                                        value_text: values.svg_website_ip,
                                        is_chosen: 1,
                                    };
                                    options.push(option);
                                }
                            });
                            break;
                        case 180:
                            q.Options.forEach((opt) => {
                                if (values.business_value) {
                                    options.push({
                                        option_id: opt.id,
                                        value_text: values.business_value,
                                        is_chosen: 1,
                                    });
                                }
                            });
                            break;
                        case 181:
                            q.Options.forEach((opt) => {
                                if (values.business_volume) {
                                    options.push({
                                        option_id: opt.id,
                                        value_text: values.business_volume,
                                        is_chosen: 1,
                                    });
                                }
                            });
                            break;
                        default:
                            break;
                    }
                });
            }
            let finalData = {
                businessInformation: businessInformation,
                answersForTask: options,
            };
            onSubmit && onSubmit(finalData);
        }
    };

    const allowFields = useMemo(() => {
        let fields = [];
        if (businessInfo && comparedCompany) {
            fields = getBusinessInfoAllowFields(
                businessInfo,
                comparedCompany?.Company?.BusinessInformation,
                isArray(comparedCompany?.Questions) ? comparedCompany?.Questions : [],
            );
        }
        return fields;
    }, [businessInfo, comparedCompany]);

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className="mb-2">Location of books and records:</Typography>
                    <Typography>
                        The indicated address where the company keep its accounting records. Should
                        be one address of company member
                    </Typography>
                </Grid>
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Enter full name</label>
                    <InputField
                        form={form}
                        name="business_full_name"
                        placeholder="Full name"
                        showIcon
                        hideSuccessHelper
                        disabled={disabled || !allowFields?.business_full_name}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.business_full_name &&
                                    form.getValues('business_full_name') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid item sm={6} xs={12} />
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Enter address</label>
                    <InputField
                        form={form}
                        name="business_address"
                        placeholder="Address"
                        showIcon
                        hideSuccessHelper
                        disabled={disabled || !allowFields?.business_address}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.business_address &&
                                    form.getValues('business_address') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid item sm={6} xs={12} />
                <Grid className="input-line" item sm={3} xs={12}>
                    <label>Country</label>
                    <SelectField
                        form={form}
                        name="business_country_id"
                        placeholder="Choose country"
                        options={countriesCooperationOptions}
                        hideHelperText={true}
                        disabled={disabled || !allowFields?.business_country_id}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.business_country_id &&
                                    form.getValues('business_country_id') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid className="input-line" item sm={3} xs={12}>
                    <label>City</label>
                    <InputField
                        form={form}
                        name="business_city"
                        placeholder="City"
                        showIcon
                        hideSuccessHelper
                        disabled={disabled || !allowFields?.business_city}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.business_city &&
                                    form.getValues('business_city') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>

                <Grid className="input-line" item sm={3} xs={12}>
                    <label>State/Province</label>
                    <InputField
                        form={form}
                        name="business_state"
                        placeholder="Your State/Province"
                        showIcon
                        hideSuccessHelper
                        disabled={disabled || !allowFields?.business_state}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.business_state &&
                                    form.getValues('business_state') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>

                <Grid className="input-line" item sm={3} xs={12}>
                    <label>ZIP/Postal code</label>
                    <InputField
                        form={form}
                        name="business_postal_code"
                        placeholder="Your Zip/Postal code"
                        showIcon
                        hideSuccessHelper
                        disabled={disabled || !allowFields?.business_postal_code}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.business_postal_code &&
                                    form.getValues('business_postal_code') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Financial Year End</label>
                    <SelectField
                        form={form}
                        name={`business_month_end`}
                        placeholder="Choose financial year end"
                        options={monthsList || []}
                        getOptionLabel={(option) => option.label}
                        hideHelperText={true}
                        disabled={disabled || !allowFields?.business_month_end}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.business_month_end &&
                                    form.getValues('business_month_end') !== null
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                {isDelaware && (
                    <>
                        <Grid className="input-line" item xs={12}>
                            <div>
                                Does the Responsible Party have both a US address and US SSN?
                                (Yes/No)?
                            </div>
                            <RadioField
                                form={form}
                                name="delaware_question_1"
                                options={[
                                    {
                                        id: 'Yes',
                                        name: 'Yes',
                                    },
                                    {
                                        id: 'No',
                                        name: 'No',
                                    },
                                ]}
                                radioGroupProps={{ sx: { flexDirection: 'row' } }}
                                disabled={disabled || !allowFields?.delaware_question_1}
                            />
                        </Grid>
                        <Grid className="input-line" item sm={6} xs={12}>
                            <p style={{ marginBottom: '8px' }}>
                                How Many Employees Are Expected in Next 12 Months for Forecasting
                                purposes, if any
                            </p>
                            <label>Number Of Employees</label>
                            <InputField
                                form={form}
                                name="number_of_employees"
                                placeholder="10"
                                hideSuccessHelper
                                disabled={disabled || !allowFields?.number_of_employees}
                            />
                        </Grid>
                        <Grid item sm={6} xs={0} />
                    </>
                )}
                {isBahamas && (
                    <>
                        <Grid className="input-line" item xs={12}>
                            <div>
                                Will the Company’s shares be listed on a regulated securities
                                exchange? (Yes/No) ?
                            </div>
                            <RadioField
                                form={form}
                                name="bahamas_question_1"
                                options={[
                                    {
                                        id: 'Yes',
                                        name: 'Yes',
                                    },
                                    {
                                        id: 'No',
                                        name: 'No',
                                    },
                                ]}
                                radioGroupProps={{ sx: { flexDirection: 'row' } }}
                                disabled={disabled || !allowFields?.bahamas_question_1}
                            />
                        </Grid>
                        <Grid className="input-line" item xs={12}>
                            <div>
                                Will the Company own/hold real estate in The Bahamas or have an
                                interest in a company which owns/holds real estate in The Bahamas?
                            </div>
                            <RadioField
                                form={form}
                                name="bahamas_question_2"
                                options={[
                                    {
                                        id: 'Yes',
                                        name: 'Yes',
                                    },
                                    {
                                        id: 'No',
                                        name: 'No',
                                    },
                                ]}
                                radioGroupProps={{ sx: { flexDirection: 'row' } }}
                                disabled={disabled || !allowFields?.bahamas_question_2}
                            />
                        </Grid>
                        <Grid className="input-line" item xs={12}>
                            <div>Will the Company carry on a business in The Bahamas? (Yes/No)</div>
                            <RadioField
                                form={form}
                                name="bahamas_question_3"
                                options={[
                                    {
                                        id: 'Yes',
                                        name: 'Yes',
                                    },
                                    {
                                        id: 'No',
                                        name: 'No',
                                    },
                                ]}
                                radioGroupProps={{ sx: { flexDirection: 'row' } }}
                                disabled={disabled || !allowFields?.bahamas_question_3}
                            />
                        </Grid>
                        {form.watch('bahamas_question_3') === 'Yes' && (
                            <Grid className="input-line" item xs={12}>
                                <div>
                                    If yes, will the business be regulated by any of the below?
                                </div>
                                <div>
                                    <Controller
                                        name="regulated_by_1"
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...form.register('regulated_by_1')}
                                                        value="Yes"
                                                        defaultChecked={
                                                            form.getValues('regulated_by_1')?.[0]
                                                        }
                                                        color="secondary"
                                                        m={0}
                                                        disabled={
                                                            disabled || !allowFields?.regulated_by_1
                                                        }
                                                    />
                                                }
                                                style={{
                                                    marginRight: 0,
                                                }}
                                                label="Central Bank of The Bahamas"
                                            />
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="regulated_by_2"
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...form.register('regulated_by_2')}
                                                        value="Yes"
                                                        defaultChecked={
                                                            form.getValues('regulated_by_2')?.[0]
                                                        }
                                                        color="secondary"
                                                        m={0}
                                                        disabled={
                                                            disabled || !allowFields?.regulated_by_2
                                                        }
                                                    />
                                                }
                                                style={{
                                                    marginRight: 0,
                                                }}
                                                label="Insurance Commission of The Bahamas"
                                            />
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="regulated_by_3"
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...form.register('regulated_by_3')}
                                                        value="Yes"
                                                        defaultChecked={
                                                            form.getValues('regulated_by_3')?.[0]
                                                        }
                                                        color="secondary"
                                                        m={0}
                                                        disabled={
                                                            disabled || !allowFields?.regulated_by_3
                                                        }
                                                    />
                                                }
                                                style={{
                                                    marginRight: 0,
                                                }}
                                                label="Securities Commission of The Bahamas"
                                            />
                                        )}
                                    />
                                </div>
                            </Grid>
                        )}
                        <Grid className="input-line" item xs={12}>
                            <div>
                                Will the Company be a subsidiary or affiliate of a licensee of any
                                the above regulators? (Yes/No)
                            </div>
                            <RadioField
                                form={form}
                                name="bahamas_question_4"
                                options={[
                                    {
                                        id: 'Yes',
                                        name: 'Yes',
                                    },
                                    {
                                        id: 'No',
                                        name: 'No',
                                    },
                                ]}
                                radioGroupProps={{ sx: { flexDirection: 'row' } }}
                                disabled={disabled || !allowFields?.bahamas_question_4}
                            />
                        </Grid>
                        {form.watch('bahamas_question_4') === 'Yes' && (
                            <>
                                <Grid className="input-line" item sm={6} xs={12}>
                                    <p style={{ marginBottom: '8px' }}>If yes, name of licensee:</p>
                                    <label>Enter Name of License</label>
                                    <InputField
                                        form={form}
                                        name="name_of_license"
                                        placeholder="some name"
                                        hideSuccessHelper
                                        disabled={disabled || !allowFields?.name_of_license}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={0} />
                            </>
                        )}
                        <Grid className="input-line" item xs={12}>
                            <div>
                                Will the Company be licensed or regulated outside of The Bahamas?
                                (Yes/No)
                            </div>
                            <RadioField
                                form={form}
                                name="bahamas_question_5"
                                options={[
                                    {
                                        id: 'Yes',
                                        name: 'Yes',
                                    },
                                    {
                                        id: 'No',
                                        name: 'No',
                                    },
                                ]}
                                radioGroupProps={{ sx: { flexDirection: 'row' } }}
                                disabled={disabled || !allowFields?.bahamas_question_5}
                            />
                        </Grid>
                        {form.watch('bahamas_question_5') === 'Yes' && (
                            <>
                                <Grid container item xs={12} columnSpacing={2}>
                                    <Grid item style={{ marginBottom: '8px' }} xs={12}>
                                        If yes: name of foreign authority/regulator and country
                                        where foreign authority/regulator is located
                                    </Grid>
                                    <Grid className="input-line" item sm={6} xs={12}>
                                        <label>Enter name of foreign authority/regulator</label>
                                        <InputField
                                            form={form}
                                            name="name_of_foreign_auth"
                                            placeholder="some name"
                                            hideSuccessHelper
                                            disabled={
                                                disabled || !allowFields?.name_of_foreign_auth
                                            }
                                        />
                                    </Grid>
                                    <Grid className="input-line" item sm={6} xs={12}>
                                        <label>Country</label>
                                        <SelectField
                                            form={form}
                                            name="country_of_foreign_auth"
                                            placeholder="Choose country"
                                            options={countriesCooperationOptions}
                                            hideHelperText={true}
                                            disabled={
                                                disabled || !allowFields?.country_of_foreign_auth
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </>
                )}
                {isSVG && (
                    <>
                        <Grid className="input-line" item xs={12}>
                            <div>Does the {IS_LLC ? 'LLC' : 'BC'} own or operate any websites?</div>
                            <RadioField
                                form={form}
                                name="svg_question_1"
                                options={[
                                    {
                                        id: 'Yes',
                                        name: 'Yes',
                                    },
                                    {
                                        id: 'No',
                                        name: 'No',
                                    },
                                ]}
                                radioGroupProps={{ sx: { flexDirection: 'row' } }}
                                disabled={disabled || !allowFields?.svg_question_1}
                            />
                        </Grid>
                        {form.watch('svg_question_1') === 'Yes' && (
                            <>
                                <Grid className="input-line" item sm={6} xs={12}>
                                    <p style={{ marginBottom: '8px' }}>
                                        if so, What are the website address (i.e. urls)?
                                    </p>
                                    <label>Website address</label>
                                    <InputField
                                        form={form}
                                        name="website_url"
                                        placeholder="Your URL"
                                        hideSuccessHelper
                                        disabled={disabled || !allowFields?.website_url}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={0} />
                            </>
                        )}
                        <Grid className="input-line" item xs={12}>
                            <div>
                                Does the {IS_LLC ? 'LLC' : 'BC'} own Intellectual Property (IP)?
                            </div>
                            <RadioField
                                form={form}
                                name="svg_question_2"
                                options={[
                                    {
                                        id: 'Yes',
                                        name: 'Yes',
                                    },
                                    {
                                        id: 'No',
                                        name: 'No',
                                    },
                                ]}
                                radioGroupProps={{ sx: { flexDirection: 'row' } }}
                                disabled={disabled || !allowFields?.svg_question_2}
                            />
                        </Grid>
                        {form.watch('svg_question_2') === 'Yes' && (
                            <>
                                <Grid className="input-line" item sm={6} xs={12}>
                                    <p style={{ marginBottom: '8px' }}>
                                        if so, please provide specifics
                                    </p>
                                    <label>Intellectual Property (IP)</label>
                                    <InputField
                                        form={form}
                                        name="svg_website_ip"
                                        placeholder="Your IP"
                                        hideSuccessHelper
                                        disabled={disabled || !allowFields?.svg_website_ip}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={0} />
                            </>
                        )}
                        <Grid className="input-line" item sm={6} xs={12}>
                            <p style={{ marginBottom: '8px' }}>
                                What is the current or expected volume of business (i.e. transaction
                                type and amount of transactions)?
                            </p>
                            <InputField
                                form={form}
                                name="business_volume"
                                placeholder="Please enter your answer here"
                                hideSuccessHelper
                                customStyle={{
                                    '.MuiInputBase-root': {
                                        backgroundColor:
                                            allowFields?.business_value &&
                                            form.getValues('business_volume') !== ''
                                                ? 'rgba(176, 236, 209, 0.5)'
                                                : 'inherit',
                                    },
                                }}
                                disabled={disabled || !allowFields?.business_volume}
                            />
                        </Grid>
                        <Grid item sm={6} xs={0} />
                        <Grid className="input-line" item sm={6} xs={12}>
                            <p style={{ marginBottom: '8px' }}>
                                What is the current or expected value of the business (i.e. annual
                                turnover in $$$)?
                            </p>
                            <InputField
                                form={form}
                                name="business_value"
                                placeholder="Please enter your answer here"
                                hideSuccessHelper
                                customStyle={{
                                    '.MuiInputBase-root': {
                                        backgroundColor:
                                            allowFields?.business_value &&
                                            form.getValues('business_value') !== ''
                                                ? 'rgba(176, 236, 209, 0.5)'
                                                : 'inherit',
                                    },
                                }}
                                disabled={disabled || !allowFields?.business_value}
                            />
                        </Grid>
                        <Grid item sm={6} xs={0} />
                    </>
                )}
                {isStKit && (
                    <Grid item sm={12}>
                        <p>
                            *Note: Section 103 of the legislation addresses the subject of the
                            company’s requirement to keep proper books & records to enable the
                            financial position of the company to be determined at any time and to
                            allow financial statements to be prepared.
                        </p>
                    </Grid>
                )}
                {!isHideAction && (
                    <Grid item xs={12}>
                        <Button
                            size="large"
                            variant="contained"
                            disableElevation
                            disabled={isLoading || form.isSubmitting}
                            onClick={handleBack}
                            className={'mr-2'}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            type="submit"
                            style={{ color: '#fff' }}
                            disabled={isLoading}
                        >
                            Next
                        </Button>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};

export default BusinessInfo;

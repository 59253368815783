import React, { useEffect, useMemo, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Button, Tab } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { getCompanyMembersAllowFields, getMembers, getQuestionAnswerOptions, getSubmitDataMember } from '../../../helpers';
import UBOForm from 'app/pages/components/forms/UBOForm';
import { useSelector } from 'react-redux';
import { PANAMA_ID, SVG_ID, UK_ID } from 'constants/country';
import { uboSchema } from 'app/pages/components/schemas/ubo';
import { yupResolver } from '@hookform/resolvers/yup';

const UBO = ({
    task,
    disabled,
    isHideAction,
    isLoading,
    onSubmit,
    handleNext,
    handleBack,
    isRepresented,
    IS_UK_LLP,
    hasShowSelectFullName
}) => {
    const [tabValue, setTabValue] = useState(0);
    const [isDeletedMember, setDeletedMember] = useState(false);
    const [initValues, setInitValues] = useState(false);

    const { countriesCooperationOptions } = useSelector(
        (state) => state.enum,
    );
    const { comparedCompany } = useSelector((state) => state.companies);

    const isPanama = useMemo(() => task?.Company?.country_id === PANAMA_ID, [task]);
    const isSVG = useMemo(() => task?.Company?.country_id === SVG_ID, [task]);
    const isUK = useMemo(() => task?.Company?.country_id === UK_ID, [task]);

    const form = useForm({
        defaultValues: {
            members: [],
        },
        resolver: yupResolver(uboSchema({ hasShowSelectFullName }))
    });
    const { fields } = useFieldArray({
        control: form.control,
        name: 'members',
    });

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if (!task) return;
        if (!isDeletedMember) {
            handleResetForm(
                getMembers({
                    companyMembers: task?.Company?.CompanyMembers,
                    typeMember: 'ubo',
                    companyCountryId: task?.Company?.country_id,
                    hideSourceOfFund: false,
                    questionsAnswerOptions: getQuestionAnswerOptions(
                        task?.Task?.IncorporationCountry?.Questions || [],
                    ),
                }),
            );
        }
        setDeletedMember(false);
    }, [task]);

    const handleResetForm = (members) => {
        setInitValues(members)
        form.reset({
            members,
            __isRepresented: isRepresented, // Field chỉ dùng do validate
            __company_country_id: task?.Company?.country_id, // Field chỉ dùng do validate
        });
    };

    const handleSubmit = (values) => {
        if (Object.keys(form.formState.dirtyFields).length === 0) {
            handleNext();
        } else {
            const submitData = {
                answersForTask: [],
                companyStructureCreate: [],
                companyStructureUpdate: [],
                companyStructureDelete: {},
            };
            values.members.forEach((member) => {
                const newMember = getSubmitDataMember(member);
                delete newMember?.CompanyPositions?.[0]?.relationship_with_company;
                delete newMember?.CompanyPositions?.[0]?.is_approved;
                delete newMember?.CompanyPositions?.[0]?.CompanyMemberType;
                if (member.id) {
                    newMember.id = newMember.member_id;
                    delete newMember.member_id;

                    if(newMember?.answersForTask?.length > 0) {
                        newMember.answersForTask.forEach(ans => {
                            const answer = {
                                ...ans,
                                company_member_id : member.id
                            };
                            submitData.answersForTask.push(answer);
                        });
                        delete newMember.answersForTask;
                    }

                    submitData.companyStructureUpdate.push(newMember);
                }
            });
            onSubmit && onSubmit(submitData, true);
        }
    };

    const allowFields = useMemo(() => {
        let fields = [];
        if (initValues && comparedCompany?.Company?.CompanyMembers) {
            fields = getCompanyMembersAllowFields(initValues, comparedCompany.Company.CompanyMembers);
        }
        return fields;
    }, [initValues, comparedCompany]);

    return (
        <>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <TabContext value={tabValue}>
                    <div className="control-tab mb-5">
                        <div className="tab-menu">
                            <TabList onChange={handleChangeTab}>
                                {fields?.map((item, index) => {
                                    return (
                                        <Tab
                                            key={item.id}
                                            className="item"
                                            label={index + 1}
                                            id={item.id}
                                        />
                                    );
                                })}
                            </TabList>
                        </div>
                    </div>
                    {fields.map((field, index) => (
                        <TabPanel key={index} value={index} sx={{ padding: '0px 0px 32px' }}>
                            <UBOForm
                                task={task}
                                form={form}
                                index={index}
                                disabled={disabled}
                                countriesCooperationOptions={countriesCooperationOptions}
                                isSVG={isSVG}
                                isPanama={isPanama}
                                isUK={isUK}
                                IS_UK_LLP={IS_UK_LLP}
                                isRepresented={isRepresented}
                                onCheckRepresented={(indexC, checked, fieldSignName) => {
                                    if (checked) {
                                        fields.forEach((field, indexF) => {
                                            if (indexC === indexF) {
                                                return form.setValue(
                                                    `members.${indexF}.CompanyPositions.0.is_appointed`,
                                                    1,
                                                );
                                            }
                                            const tempFieldName = fieldSignName
                                                ? `members.${indexF}.${fieldSignName}`
                                                : `members.${indexF}.CompanyPositions.0.is_appointed`;
                                            form.setValue(tempFieldName, 0);
                                        });
                                    }
                                }}
                                hasShowSelectFullName={hasShowSelectFullName}
                                allowFields={allowFields}
                            />
                        </TabPanel>
                    ))}
                </TabContext>
                {!isHideAction && (
                    <>
                        <Button
                            size="large"
                            variant="contained"
                            disableElevation
                            disabled={isLoading || form.isSubmitting}
                            onClick={handleBack}
                            className={'mr-2'}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            type="submit"
                            style={{ color: '#fff' }}
                            disabled={isLoading}
                        >
                            Next
                        </Button>
                    </>
                )}
            </form>
        </>
    );
};

export default UBO;

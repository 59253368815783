import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { UpgradeBanking } from '../components/upgradeBanking';

export function NoticeFailed({ bank, onChooseAnotherBank } = { ...bank }) {
    const [customerName, setCustomerName] = useState('');
    const [bankName, setBankName] = useState('');

    useEffect(() => {
        setCustomerName(bank?.Task?.Customer?.last_name + ' ' + bank?.Task?.Customer?.first_name);
        setBankName(bank?.Task?.BankingProcesses && bank?.Task.BankingProcesses[0]?.Bank?.name);
    }, [bank]);

    const { id } = useParams();

    function getNoticeContent() {
        console.log('bank', bank);
        let caseHandle = 1;

        // Chọn lại bank
        if (bank.is_checking_other_bank === true) {
            caseHandle = 1;
        }
        // Hết lượt nhưng có thể upgrade
        if (bank.is_checking_other_bank === false && bank?.upgrade_services?.length > 0) {
            caseHandle = 2;
        }
        // Hết lượt nhưng không thể upgrade
        if (bank.is_checking_other_bank === false && bank?.upgrade_services?.length === 0) {
            caseHandle = 3;
        }

        switch (caseHandle) {
            case 1:
                return (
                    <>
                        <p>
                            Hi {customerName}, we're sorry to notify that your bank account
                            application has been rejected by {bankName} for some reasons.
                        </p>
                        <p>
                            But don't worry, please initiate another application process, we'll
                            support your application for up to 5 banks. One great thing is that you
                            don't have to start from the ground up.
                        </p>
                        <p> We'll reuse your forms and information previously provided.</p>
                        <div className="text-center mt-8">
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                style={{ color: '#fff' }}
                                onClick={() => onChooseAnotherBank && onChooseAnotherBank()}
                            >
                                CHOOSE ANOTHER BANK
                            </Button>
                        </div>
                        <div className="text-center mt-2">
                            <Button
                                style={{ color: '#17C191', textTransform: 'capitalize' }}
                                onClick={handleOpenTawkto}
                            >
                                Consult your dedicated manager
                            </Button>
                        </div>
                    </>
                );

            case 2:
                return (
                    <>
                        <p>
                            Hi {customerName}, we're sorry to notify that your bank account
                            application has been rejected by {bankName}
                            for some reasons.
                            <br />
                            But don't worry, you can secure your spot by upgrading to premium
                            banking for only ${bank?.upgrade_services[0].value}.
                        </p>
                        <p>We'll continue to support your application for other 4 banks.</p>
                        <p>
                            One great thing is that you don't have to start from the ground up.
                            We'll reuse your forms and information previously provided.
                        </p>
                        {/* <div className="text-center mt-8">
                        <Button variant="contained" size="medium" color="primary" style={{ color: '#fff' }} onClick={handleUpgrade} >
                            UPGRADE BANKING SERVICE
                        </Button>
                    </div> */}
                        <UpgradeBanking task_id={bank.Task.id} />
                    </>
                );

            default: // contentType = 3
                return (
                    <>
                        <p>
                            Hi {customerName}, we're sorry to notify that your bank account
                            application has been rejected by {bankName} for some reasons.
                        </p>
                        <div className="text-center mt-8">
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                style={{ color: '#fff' }}
                                onClick={handleOpenTawkto}
                            >
                                Consult your dedicated manager
                            </Button>
                        </div>
                    </>
                );
        }
    }

    function handleOpenTawkto() {
        window.Tawk_API.toggle();
    }

    return (
        <>
            <h3 className="notice__title failed">We're sorry</h3>
            <div className="notice__content mt-4">{getNoticeContent()}</div>
        </>
    );
}

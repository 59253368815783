import { isNode } from 'react-flow-renderer';
import dagre from 'dagre';

const nodeWidth = 180;
const nodeHeight = 100;

/**
 * Sort the graph.
 * Document: https://reactflow.dev/examples/layouting/
 * @param {Array} elements list off node and edge.
 * @param {string} direction make the graph horizontal or vertical
 * @returns an graph Array with sorted layout.
 */
export const getLayoutElements = (elements, direction = 'TB') => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    const isHorizontal = direction === 'LR';
    const elementTemps = [...elements];
    dagreGraph.setGraph({ rankdir: direction });
    elementTemps.forEach((el) => {
        if (isNode(el)) {
            dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
        } else {
            dagreGraph.setEdge(el.source, el.target);
        }
    });

    dagre.layout(dagreGraph);

    return elementTemps.map((el) => {
        if (isNode(el)) {
            const nodeWithPosition = dagreGraph.node(el.id);
            el.targetPosition = isHorizontal ? 'left' : 'top';
            el.sourcePosition = isHorizontal ? 'right' : 'bottom';

            // unfortunately we need this little hack to pass a slighltiy different position
            // to notify react flow about the change. More over we are shifting the dagre node position
            // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
            el.position = {
                x: nodeWithPosition.x + nodeWidth / 2,
                y: nodeWithPosition.y - nodeHeight / 2,
            };
        }

        return el;
    });
};

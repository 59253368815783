import axios from 'axios';

export const ENUM_URL = '/enum';
export const COUNTRY = '/country';
export const COUNTRY_HAS_SERVICE = '/country_has_service';
export const COUNTRY_COOPERATIVE = '/country_cooperative';
export const SUPPORT_CASE_PRIORITY = '/support_case_priority';
export const SUPPORT_CASE_TYPE = '/support_case_type';
export const SUPPORT_CASE_STATUS = '/support_case_status';
export const BUSINESS_ACTIVITY_URL = '/enum/business_activity';
export const GEOLOOKING = '/helper/geoLooking';
export const CHECK_NAME_UK = '/customer_account/checkname/uk';
export const SERVICE_TYPE = '/service_type';
export const WEBSITES = '/websites';

// === country has service
export function getAllCountryHasService() {
    return axios.get(ENUM_URL + COUNTRY_HAS_SERVICE);
}

// === country non-restricted
export function getCountriesNonRestricted() {
    return axios.get(ENUM_URL + COUNTRY_COOPERATIVE);
}
// === country non-restricted
export function getCountries() {
    return axios.get(ENUM_URL + COUNTRY);
}

// === support_case_priority
export function getSupportCasePriority() {
    return axios.get(ENUM_URL + SUPPORT_CASE_PRIORITY);
}

// === support_case_type
export function getSupportCaseType() {
    return axios.get(ENUM_URL + SUPPORT_CASE_TYPE);
}

// === support_case_status
export function getSupportCaseStatus() {
    return axios.get(ENUM_URL + SUPPORT_CASE_STATUS);
}

// === business_activity
export function getBusinessActivity(id) {
    return axios.get(BUSINESS_ACTIVITY_URL + '?country_id=' + id);
}

// === business_activity
export function getBusinessActivityWithParams(params) {
    return axios.get(BUSINESS_ACTIVITY_URL, { params });
}

// === geoLooking
export function getGeoLooking() {
    return axios.get(GEOLOOKING);
}

// === check name UK
export function checkNameUK(string) {
    return axios.get(CHECK_NAME_UK + '?key=' + string);
}

export function getCurrencies() {
    return axios.get(ENUM_URL + '/currency');
}

export function getServiceType(params) {
    if(params) return axios.get(ENUM_URL + SERVICE_TYPE, {
        params: params
    })
    return axios.get(ENUM_URL + SERVICE_TYPE);
}

export function getFileType() {
    return axios.get(ENUM_URL + '/file_type', {
        params: { filter: { parent_id: null, id: {'$ne': 11} } },
    });
}
export function getWebsites() {
    return axios.get(ENUM_URL + WEBSITES);
}

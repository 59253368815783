import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Slider,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import * as Yup from 'yup';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { getProcessDetail } from '../../../../_redux/banking/api';
import { toast } from 'react-toastify';
import { updateOrderInfo } from '../../../../_redux/banking/slice';
import { getBusinessActivityWithParams } from '../../../../_redux/enum/enumCrud';
import CheckboxField from 'components/form-controls/CheckboxField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const AccordionLayout = ({ ...props }) => {
    let { children, title, disabledIcon, expanded, styles } = props;
    const classes = useStyles();
    return (
        <Accordion
            expanded={expanded === true ? true : false}
            style={{ boxShadow: 'none' }}
            className="m-0"
            ref={(el) => {
                if (el && styles) {
                    for (let [key, value] of Object.entries(styles)) {
                        el.style.setProperty(key, value, 'important');
                    }
                }
            }}
        >
            <AccordionSummary
                expandIcon={
                    disabledIcon === true || disabledIcon == undefined ? null : (
                        <ExpandMoreIcon className={classes.colorIcon} />
                    )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.bgColorHeader}
            >
                <Typography className={classes.heading}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails
                style={{ borderRight: '1px solid #5f5f5f26', borderBottom: '1px solid #5f5f5f26' }}
            >
                <div style={{ width: '100%' }}>{children}</div>
            </AccordionDetails>
        </Accordion>
    );
};

const useStylesTooltip = makeStyles((theme) => ({
    // tooltip
    arrow: {
        color: '#3783e7',
    },
    tooltip: {
        fontSize: '12px',
        fontWeight: 300,
        letterSpacing: '0.3px',
        backgroundColor: '#3783e7',
    },
}));
const useStyles = makeStyles((theme) => ({
    bgColorHeader: {
        backgroundColor: '#17c191',
        color: 'white',
    },
    colorIcon: {
        color: 'white',
    },
    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: 'bold',
    },
}));

const errorText = {
    errCountry: 'Country field needs to be filled',
    business_field:
        'Sorry, your business activities cannot open bank account as it falls within the general blacklist of most banks.',
    member_country_ids: 'Sorry, we found no banks that support your selected nationalities.',
    partner_country_ids:
        'Sorry, we found no banks that support your selected countries of operation.',
    is_merchant_account: 'Sorry, we cannot find banks which match your requirement.',
    initial_deposit:
        'Sorry, we found no banks that support your acceptable level of initial deposit.',
    is_remote_opening:
        'Sorry, we found no banks that support your need of remote opening given your situation.',
};

const dataSchema = {
    // FILTER FROM API
    country_id: null,
    business_field: null,
    member_country_ids: null,
    partner_country_ids: null,

    is_merchant_account: null,
    // FILTER FROM FE
    initial_deposit: null,
    is_remote_opening: null,

    type_business: null, // false: New , true: Establish
    type_account: null,
    is_bookkeeping: null,
};

const createYupValidation = (filterResult) => {
    const validation = function () {
        return this.test(function (value) {
            if (value != null && !isValidField[this.path] && filterResult.length === 0) {
                return this.createError({ path: this.path, message: errorText[this.path] });
            }
            return true;
        });
    };
    Yup.addMethod(Yup.mixed, 'validateField', validation);
};

const createForm = () => {
    return Yup.object().shape({
        country_id: Yup.string(),
        business_field: Yup.string().nullable(),
        member_country_ids: Yup.array().nullable(),
        partner_country_ids: Yup.array().nullable(),

        is_merchant_account: Yup.boolean().nullable(),
        initial_deposit: Yup.number().nullable(),
        is_remote_opening: Yup.boolean().nullable(),

        type_business: Yup.boolean().nullable(),
        type_account: Yup.boolean().nullable(),
        is_bookkeeping: Yup.boolean().nullable(),
    });
};

const showErrorValidation = (errors, field) => {
    if (errors) {
        return errors[field] ? <p style={{ color: '#f64e60' }}>{errors[field]}</p> : null;
    }
    return null;
};

/* =====================================================>
    Trong createYupValidation, validation check theo  bankingTool.filterResult.length => khi invalid sẽ báo lỗi hết các input
    => Sử dụng cách bên dưới này để khắc phục ok
    id: #1
*/
let isValidField = {};
let currentInputChange = [];
const updateValid = (filterResult) => {
    if (filterResult.length > 0) {
        for (let key of Object.keys(isValidField)) {
            isValidField[key] = true;
        }
    } else {
        isValidField[currentInputChange[0]] = false;
    }
};
// =====================================================>

const SectionFilterLeft = ({ ...props }) => {
    const { countries, callbackValueFilter, filterResult, isLoading } = props;
    const debounceRef = useRef(null);
    const classes = useStylesTooltip();
    const dispatch = useDispatch();

    const [valueFilter, setValueFilter] = useState(dataSchema);

    const { activeProcess, orderInfo } = useSelector((state) => state.banking);

    createYupValidation(filterResult); // create validation
    const bankingToolSchema = createForm(); // create form validation

    const form = useForm({
        resolver: yupResolver(bankingToolSchema),
    });

    useEffect(() => {
        form.reset(valueFilter);
    }, [form]);

    const setValueForm = (propName, value, isFetchAPI) => {
        // gán lại = null để thoả mãn đk check validation
        if (
            (typeof value === 'string' && value === '') ||
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === 'boolean' && value === false)
        ) {
            value = null;
        }
        setValueFilter({ ...valueFilter, [propName]: value });
        // debounce text
        if (typeof value === 'string') {
            if (debounceRef.current) {
                clearTimeout(debounceRef.current);
            }
            debounceRef.current = setTimeout(() => {
                callbackValueFilter({ ...valueFilter, [propName]: value }, isFetchAPI);
            }, 500);
        } else {
            callbackValueFilter({ ...valueFilter, [propName]: value }, isFetchAPI);
        }
        // id: #1
        isValidField = { ...isValidField, [propName]: false };
        currentInputChange[0] = propName;
    };

    useEffect(() => {
        if (activeProcess) {
            getProcessDetail(activeProcess.Processes[0].id)
                .then((res) => {
                    const {
                        data: { data },
                    } = res;
                    dispatch(updateOrderInfo(data));
                })
                .catch((error) => {
                    console.log(error);
                    toast.error('Somethings went wrong!');
                });
        }
    }, [activeProcess]);

    /*
        Validation của formik sẽ chạy lại khi các events xảy ra như: setFieldValue, setValues.
        Sau khi filter gọi api trả về result bankingTool dc cap nhat. => set lại validation
    */
    useEffect(() => {
        updateValid(filterResult); // id: #1
        form.reset(valueFilter);
    }, [filterResult]);

    useEffect(() => {
        if (orderInfo && countries?.length) {
            (async () => {
                const defaultFilter = {};

                /* Xử lý country id */
                if (orderInfo?.Company?.Country?.id) {
                    defaultFilter['country_id'] = orderInfo?.Company?.Country?.id;
                }

                /* Xử lý business acitivity */
                let businessActivities;
                try {
                    businessActivities = JSON.parse(orderInfo?.Company?.business_activity);
                } catch (e) {
                    businessActivities = orderInfo?.Company?.business_activity;
                }
                if (businessActivities) {
                    if (Array.isArray(businessActivities)) {
                        try {
                            let {
                                data: { data },
                            } = await getBusinessActivityWithParams({
                                country_id: orderInfo?.Company?.Country?.id,
                                ids: orderInfo?.Company?.business_activity,
                            });
                            let temp = [];
                            data.forEach((item) => {
                                item.BusinessActivities.forEach((businessActivity) => {
                                    temp.push(businessActivity.description);
                                });
                            });
                            const tempText = temp.join('; ');
                            if (tempText) {
                                defaultFilter['business_field'] = tempText;
                            }
                        } catch (e) {}
                    } else {
                        defaultFilter['business_field'] = businessActivities;
                    }
                }

                /* Xử lý Nationalities of members */
                const tempNationalitesCountry = {};
                if (orderInfo?.Company?.CompanyMembers) {
                    orderInfo.Company.CompanyMembers.forEach((member) => {
                        tempNationalitesCountry[member.country_id] = member.country_id;
                    });
                }
                const tempNationalitesCountryArray = Object.values(tempNationalitesCountry);
                const tempNationalitesCountryArrayFilter = countries.filter((item) =>
                    tempNationalitesCountryArray.includes(item.id),
                );
                if (
                    tempNationalitesCountryArrayFilter &&
                    tempNationalitesCountryArrayFilter.length
                ) {
                    defaultFilter['member_country_ids'] = tempNationalitesCountryArrayFilter;
                }

                /* Xử lý operation country */
                const operationCountry = {};
                if (orderInfo?.Company?.OperationCountries) {
                    orderInfo.Company.OperationCountries.forEach((country) => {
                        operationCountry[country.id] = country.id;
                    });
                }
                const operationCountryArray = Object.values(operationCountry);
                const operationCountryArrayFilter = countries.filter((item) =>
                    operationCountryArray.includes(item.id),
                );
                if (operationCountryArrayFilter && operationCountryArrayFilter.length) {
                    defaultFilter['partner_country_ids'] = operationCountryArrayFilter;
                }

                setValueFilter({ ...valueFilter, ...defaultFilter });
                callbackValueFilter({ ...valueFilter, ...defaultFilter }, true);
            })();
        }
    }, [orderInfo, countries]);

    const countryValue = useMemo(() => {
        return countries?.find((e) => e.id === valueFilter.country_id) ?? '';
    }, [countries, valueFilter]);
    console.log(form.getValues());
    return (
        <form>
            {/* Filter 1 */}
            <AccordionLayout title={'Bank requirement'} disabledIcon={true} expanded={true}>
                <div className="col-md-12 mb-7 input-line">
                    <label>Country of Incorporation</label>
                    <Select
                        className="react-select"
                        name="country_id"
                        id="country-select"
                        options={countries}
                        isLoading={!(!isLoading && countries && countries.length > 0)}
                        isDisabled={
                            !(!isLoading && countries && countries.length > 0) ||
                            orderInfo?.Company?.Country?.id
                        }
                        isSearchable
                        isClearable={false}
                        placeholder={'Choose country'}
                        value={countryValue}
                        isOptionSelected={(option) =>
                            valueFilter?.country_id === option.id ? true : false
                        }
                        getOptionLabel={(option) => option.name}
                        onChange={(value) => setValueForm('country_id', value.id, true)}
                    ></Select>
                </div>

                <div className="col-md-12 mb-7 input-line">
                    <div className="wrap-label-has-tooltip">
                        <label>Business activities</label>
                        <div className="wrap-tooltip d-inline ml-2">
                            <Tooltip
                                title="Please give specific business activities within 20-30 words. General description of business such “Trading company”, “Holding company”, or “Investment company“ is not sufficient."
                                placement="top"
                                arrow
                                classes={classes}
                            >
                                <InfoIcon
                                    style={{ fontSize: '14px', color: '#3783e7' }}
                                    className="icon"
                                />
                            </Tooltip>
                        </div>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Fill business activities"
                        name="business_field"
                        disabled={orderInfo?.Company?.business_activity}
                        value={valueFilter.business_field ?? ''}
                        onChange={(e) => setValueForm('business_field', e.target.value, true)}
                    />
                    {showErrorValidation(form.errors, 'business_field')}
                </div>

                <div className="col-md-12 mb-7 input-line">
                    <label>Nationalities of members</label>
                    <Select
                        className="react-select multi-select"
                        name="member_country_ids"
                        id="country-select"
                        isLoading={!(!isLoading && countries && countries.length > 0)}
                        isDisabled={!(!isLoading && countries && countries.length > 0)}
                        isMulti
                        options={countries}
                        isSearchable
                        isClearable={false}
                        placeholder={'Choose country'}
                        value={valueFilter.member_country_ids}
                        isOptionSelected={(option) => {
                            valueFilter.member_country_ids &&
                                valueFilter.member_country_ids.length > 0 &&
                                option &&
                                valueFilter.member_country_ids.map((item) =>
                                    item.id === option.id ? true : false,
                                );
                        }}
                        getOptionLabel={(option) => option.name}
                        onChange={(value, { action, removedValue }) => {
                            if (action === 'select-option') {
                                setValueForm('member_country_ids', value, true);
                            }
                            if (action === 'remove-value') {
                                setValueForm(
                                    'member_country_ids',
                                    valueFilter.member_country_ids.filter(
                                        (e) => e.id !== removedValue.id,
                                    ),
                                    true,
                                );
                            }
                        }}
                    ></Select>
                    {showErrorValidation(form.errors, 'member_country_ids')}
                </div>

                <div className="col-md-12 mb-7 input-line">
                    <label>Countries of major customers, suppliers and partners</label>
                    <Select
                        className="react-select multi-select"
                        name="partner_country_ids"
                        id="country-select"
                        isLoading={!(!isLoading && countries && countries.length > 0)}
                        isDisabled={!(!isLoading && countries && countries.length > 0)}
                        isMulti
                        options={countries}
                        isSearchable
                        isClearable={false}
                        placeholder={'Choose country'}
                        value={valueFilter.partner_country_ids}
                        isOptionSelected={(option) => {
                            valueFilter.partner_country_ids &&
                                valueFilter.partner_country_ids.length > 0 &&
                                option &&
                                valueFilter.partner_country_ids.map((item) =>
                                    item.id === option.id ? true : false,
                                );
                        }}
                        getOptionLabel={(option) => option.name}
                        onChange={(value, { action, removedValue }) => {
                            if (action === 'select-option') {
                                setValueForm('partner_country_ids', value, true);
                            }
                            if (action === 'remove-value') {
                                setValueFilter({
                                    ...valueFilter,
                                    partner_country_ids: valueFilter.partner_country_ids.filter(
                                        (e) => e.id !== removedValue.id,
                                    ),
                                });
                                setValueForm(
                                    'partner_country_ids',
                                    valueFilter.partner_country_ids.filter(
                                        (e) => e.id !== removedValue.id,
                                    ),
                                    true,
                                );
                            }
                        }}
                    ></Select>
                    {showErrorValidation(form.errors, 'partner_country_ids')}
                </div>
            </AccordionLayout>

            {/* Filter 2 */}
            <AccordionLayout title={'Customer preferences'} disabledIcon={true} expanded={true}>
                <div className="col-md-12 mb-3 input-line">
                    <p className="m-0">Level of acceptable initial deposit</p>
                    <Slider
                        name={'initial_deposit'}
                        defaultValue={0}
                        aria-labelledby="discrete-slider"
                        step={1}
                        marks
                        min={0}
                        max={4}
                        onChangeCommitted={(e, value) =>
                            setValueForm('initial_deposit', value, false)
                        }
                    />
                    <div className="d-flex" style={{ transform: 'translateY(-8px)' }}>
                        <small
                            className="m-0"
                            style={{ width: '24%', transform: 'translateX(-1px)' }}
                        >
                            None
                        </small>
                        <small
                            className="m-0"
                            style={{ width: '36%', transform: 'translateX(-8px)' }}
                        >
                            0 - 1,000
                        </small>
                        <small
                            className="m-0"
                            style={{ width: '28%', transform: 'translateX(-21px)' }}
                        >
                            1,001 - 10,000
                        </small>
                        <small
                            className="m-0"
                            style={{ width: '17%', transform: 'translateX(-6px)' }}
                        >
                            10,001 - 50,000
                        </small>
                        <small
                            className="m-0"
                            style={{ width: '18%', transform: 'translateX(22px)' }}
                        >
                            Above 50,000
                        </small>
                    </div>
                    {showErrorValidation(form.errors, 'initial_deposit')}
                </div>

                <div className="col-md-12 input-line">
                    <div>
                        <CheckboxField
                            form={form}
                            disabled={isLoading}
                            name={'is_merchant_account'}
                            color="primary"
                            inputProps={{ 'aria-label': 'disabled checkbox' }}
                            onChange={(value) => setValueForm('is_merchant_account', value, true)}
                        />
                        <span>Need a merchant account </span>
                    </div>
                    {showErrorValidation(form.errors, 'is_merchant_account')}
                    {form.getValues('is_merchant_account') && filterResult.length === 0 && (
                        <p style={{ color: '#f64e60' }}>
                            Sorry, we cannot find banks which match your requirement.
                        </p>
                    )}

                    <div>
                        <CheckboxField
                            form={form}
                            disabled={isLoading}
                            name={'is_remote_opening'}
                            color="primary"
                            inputProps={{ 'aria-label': 'disabled checkbox' }}
                            onChange={(value) => setValueForm('is_remote_opening', value, false)}
                        />
                        <span>Need remote opening</span>
                    </div>
                    {showErrorValidation(form.errors, 'is_remote_opening')}
                </div>
            </AccordionLayout>

            {/* Filter 3 */}
            <AccordionLayout title={'Supplemental information'} disabledIcon={true} expanded={true}>
                <div className="col-md-12 mb-4 input-line">
                    <p className="m-0">Type of business</p>
                    <div className="">
                        <span>New </span>
                        <Switch
                            checked={valueFilter.type_business}
                            name="type_business"
                            color="primary"
                            onChange={() =>
                                setValueForm('type_business', !valueFilter.type_business)
                            }
                        />
                        <span> Established</span>
                    </div>
                </div>

                <div className="col-md-12 mb-4 input-line">
                    <p className="m-0">Type of account</p>
                    <div className="">
                        <span>Trading </span>
                        <Switch
                            name="type_account"
                            checked={valueFilter.type_account}
                            color="primary"
                            onChange={() => setValueForm('type_account', !valueFilter.type_account)}
                        />
                        <span> Holding</span>
                    </div>
                </div>

                <div className="col-md-12 mb-3 input-line">
                    <div
                        style={{
                            transform: 'translateX(-10px)',
                            textIndent: '-10px',
                            paddingLeft: '10px',
                        }}
                    >
                        <CheckboxField
                            form={form}
                            disabled={isLoading}
                            name="is_bookkeeping"
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onChange={(value) => setValueForm('is_bookkeeping', value)}
                        />
                        <span>
                            Does your company conduct Annual Accounting or Auditing reports?
                        </span>
                    </div>
                </div>
            </AccordionLayout>
        </form>
    );
};

export default SectionFilterLeft;

import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { LayoutSplashScreen } from '_metronic/layout';
import * as auth from '../_redux/authRedux';
import * as actionsPerson from 'app/pages/_redux/personal/personalActions';

const Logout = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actionsPerson.reset())
        props.logout();
    }, [])

    const { hasAuthToken } = props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Navigate to="/auth/login" />;
}

export default connect(
    ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
    auth.actions,
)(Logout);

import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import * as actionsOrderForm from '../../../../../../_redux/order-form/orderFormActions';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import * as uiHelpers from './step3-helper/Step3UIHelpers';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from 'components/form-controls/InputField';
import { InputAdornment } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export default function Step1({
    activeStep,
    setActiveStep,
    isLoading,
    setIsLoading = () => {},
    setIsActiveParentStep,
    isHongkong,
    isDelaware,
    body,
    setBody,
    disabled,
    hideAction,
    activeStepRef = {},
    validateStepsRef = {},
    index,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [initShareCapital, setInitShareCapital] = useState({
        type_of_share: uiHelpers.TypeOfShare[0].id,
        share_capital: '',
        number_share: '',
        par_value: body.par_value,
        contributed_capital: '',
    });

    const shareCapitalSchema = Yup.object().shape({
        type_of_share: Yup.number(),
        // share_capital: Yup.number().when('type_of_share', {
        //     is: 2,
        //     then: Yup.number()
        //         .transform((value) =>
        //             isNaN(value) || value === null || value === undefined ? 0 : value,
        //         )
        //         .moreThan(0, 'Value must be greater than 0')
        //         .integer('Value must be an integer')
        //         .required('Registered Capital is required!'),
        // }),
        share_capital: !body.isLLC
            ? Yup.number().when('type_of_share', {
                  is: 2,
                  then: Yup.number()
                      .transform((value) =>
                          isNaN(value) || value === null || value === undefined ? 0 : value,
                      )
                      .moreThan(0, 'Value must be greater than 0')
                      .integer('Value must be an integer')
                      .test('share_capital', '', (value, context) => {
                          return (
                              value ==
                              parseInt(context?.parent?.number_share) *
                                  parseInt(context?.parent?.par_value)
                          );
                      })
                      .required('Registered Capital is required!'),
              })
            : Yup.number().when('type_of_share', {
                  is: 2,
                  then: Yup.number()
                      .transform((value) =>
                          isNaN(value) || value === null || value === undefined ? 0 : value,
                      )
                      .moreThan(0, 'Value must be greater than 0')
                      .integer('Value must be an integer')
                      .required('Registered Capital is required!'),
              }),
        number_share: Yup.number().when('type_of_share', {
            is: 2,
            then: Yup.number()
                .transform((value) =>
                    isNaN(value) || value === null || value === undefined ? 0 : value,
                )
                .moreThan(0, 'Value must be greater than 0')
                .integer('Value must be an integer')
                .required('Number of share is required!'),
        }),
        par_value: Yup.number()
            .transform((value) =>
                isNaN(value) || value === null || value === undefined ? 0 : value,
            )
            .moreThan(0, 'Value must be greater than 0')
            .integer('Value must be an integer')
            .required('Par value of each share is required!'),
        contributed_capital: Yup.number()
            .moreThan(0, 'Value must be greater than 0')
            .integer('Capital contribution must be an integer')
            .required('Capital contribution is required!'),
    });

    const form = useForm({
        defaultValues: initShareCapital,
        resolver: yupResolver(shareCapitalSchema),
    });
    const [type, setType] = useState(uiHelpers.TypeOfShare[0].id);
    const [capitalInit, setCapitalInit] = useState({
        share_capital: body.number_of_share_standard * body.par_value,
        number_share: body.number_of_share_standard,
    });
    const [capital, setCapital] = useState(capitalInit);
    const parValueInit = body.par_value;
    const [parValue, setParValue] = useState(parValueInit);
    const [checkLogic, setCheckLogic] = useState(true); // Register capital = Number of share * Value of share

    const [warningContent, setWarningContent] = useState('');

    useEffect(() => {
        try {
            setIsLoading(true);
            if (body) {
                if (body.Company.ShareCapital) {
                    if (body.Company.ShareCapital.type_of_share) {
                        setType(body.Company.ShareCapital.type_of_share);
                    }
                    if (
                        body.Company.ShareCapital.share_capital &&
                        body.Company.ShareCapital.number_share
                    ) {
                        setCapital({
                            share_capital: body.Company.ShareCapital.share_capital,
                            number_share: body.Company.ShareCapital.number_share,
                        });
                    }
                    if (body.Company.ShareCapital.par_value) {
                        setParValue(body.Company.ShareCapital.par_value);
                    }
                }

                // form.setValue(
                //     'type_of_share',
                //     body.Company.ShareCapital.type_of_share || uiHelpers.TypeOfShare[0].id,
                // );
                // form.setValue(
                //     'share_capital',
                //     body.Company.ShareCapital.share_capital || capitalInit.share_capital,
                // );
                // form.setValue(
                //     'number_share',
                //     body.Company.ShareCapital.number_share || capitalInit.number_share,
                // );
                // // form.setValue('with_par_value', body.Company.ShareCapital.with_par_value || 'yes');
                // form.setValue('par_value', body.Company.ShareCapital.par_value || parValueInit);
                // form.setValue(
                //     'contributed_capital',
                //     body.Company.ShareCapital.contributed_capital ||
                //         body.Company.ShareCapital.share_capital ||
                //         body.number_of_share_standard,
                // );

                form.reset({
                    type_of_share:
                        body.Company.ShareCapital.type_of_share || uiHelpers.TypeOfShare[0].id,
                    share_capital:
                        body.Company.ShareCapital.share_capital || capitalInit.share_capital,
                    number_share:
                        body.Company.ShareCapital.number_share || capitalInit.number_share,
                    par_value: body.Company.ShareCapital.par_value || parValueInit,
                    contributed_capital:
                        body.Company.ShareCapital.contributed_capital ||
                        body.Company.ShareCapital.share_capital ||
                        body.number_of_share_standard,
                });

                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        if (form.formState.errors?.share_capital) {
            setCheckLogic(false);
        }
    }, [form.formState.errors]);

    useEffect(() => {
        try {
            form.setValue('type_of_share', type);
            form.setValue('share_capital', capital.share_capital);
            form.setValue('number_share', capital.number_share);
            form.setValue('par_value', parValue);

            setCheckLogic(true);
            form.clearErrors('share_capital');
        } catch (error) {
            console.log(error);
        }
    }, [type, capital, parValue]);

    // Check warning content
    useEffect(() => {
        // warning default
        try {
            let warningContentTemp = '';

            if (body.isBVI || body.isBelize || body.isBahamas || body.isMarshall) {
                // warningContentTemp = 'If amount of share capital over 50000; Incorporation fee and annual fee may be higher, kindly confirm then our team shall reach you with details soonest';
                warningContentTemp =
                    'If amount of share capital over 50,000; kindly confirm then our team shall reach you with details soonest';
            }
            if (body.isCayman) {
                warningContentTemp =
                    'Maximum authorized (as opposed to issued) capital for minimum government incorporation and annual filing is US$50,000';
            }
            setWarningContent(warningContentTemp);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        // warning on change "type", "capital"
        try {
            if (body.isHongkong || body.isPanama) {
                let warningContentTemp = '';
                if (type === uiHelpers.TypeOfShare[1].id) {
                    // type: Custom
                    if (body.isHongkong && capital.share_capital > body.number_of_share_over) {
                        // 10,000,000
                        warningContentTemp =
                            'Kindly note founder members are required to pay share capital amount to the corporate bank accounts';
                    }
                    if (body.isPanama) {
                        warningContentTemp =
                            'If amount of share capital over 10,000; kindly confirm then our team shall reach you with details soonest';
                    }
                }
                setWarningContent(warningContentTemp);
            } else {
            }
        } catch (error) {
            console.log(error);
        }
    }, [type, capital]);
    // end Check warning content

    // type
    const handleChangeType = (event) => {
        try {
            if (parseInt(event.target.value) === uiHelpers.TypeOfShare[0].id) {
                setCapital(capitalInit);
                setParValue(parValueInit);
                form.setValue('share_capital', capitalInit.share_capital);
                form.setValue('number_share', capitalInit.number_share);
            }
            setType(parseInt(event.target.value));
        } catch (error) {
            console.log(error);
        }
    };

    // par value
    const handleChangeParValue = (event) => {
        setParValue(event.target.value);
        // setParValue(parseInt(event.target.value));
        form.setValue('par_value', parseInt(event.target.value));
    };

    function onSubmitForm(index, values) {
        try {
            let checkValid = true;
            if (!body.isLLC && parseInt(values.type_of_share) === 2) {
                checkValid =
                    parseInt(values.share_capital) ===
                    parseInt(values.number_share) * parseInt(values.par_value);
                setCheckLogic(checkValid);
            }

            console.log({checkValid})

            if (checkValid) {
                setIsLoading(true);
                let data = { shareCapitalUpdate: {} };
                body.isLLC
                    ? (data.shareCapitalUpdate = {
                          company_id: body.company_id,
                          share_capital: parseInt(values.contributed_capital),
                      })
                    : (data = {
                          shareCapitalUpdate: {
                              // ...values,
                              // with_par_value: undefined

                              company_id: body.company_id,
                              par_value: parseInt(values.par_value),
                              type_of_share: values.type_of_share,
                              number_share: parseInt(values.number_share),
                              share_capital: parseInt(values.share_capital),
                          },
                      });
                dispatch(actionsOrderForm.updateOrderForm(body.Task.id, data)).then((res) => {
                    if (res && res.state) {
                        setIsLoading(false);
                        // setActiveStep(index);
                        if (typeof activeStepRef.current !== 'number') {
                            // setActiveStep(activeStepRef.current)
                            setActiveStep(index);
                        }
                        activeStepRef.current = null;
                    } else {
                        setIsLoading(false);
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleBack = () => {
        setIsActiveParentStep({ back: true, next: false });
    };

    const handleSubmit = (values) => {
        setBody({
            ...body,
            Company: { ...body.Company, ShareCapital: { ...values } },
        });
        onSubmitForm(1, values);
    };

    // truyền form validate cho file stepper
    validateStepsRef.current = {
        ...validateStepsRef.current,
        [`STEP_${index + 1}`]: {
            // isValid: !Object.keys(form.formState.errors).length,
            isValid: form.formState.isValid,
            onSubmit: () => form.handleSubmit(handleSubmit)(),
        },
    };

    return (
        <div className="step1">
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                {body && body.isLLC ? (
                    <div className="row mb-7">
                        <div className="col-sm-6 col-md-3 input-line has-unit">
                            <label>Contributed capital</label>
                            <InputField
                                form={form}
                                type="number"
                                name="contributed_capital"
                                onChange={(event) => {
                                    form.setValue('contributed_capital', event.target.value);
                                }}
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">USD</InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <p className="pb-0">
                            Class of share: <span className="fw-500">Ordinary</span>
                        </p>

                        <div>
                            <div>Type:</div>
                            <RadioGroup
                                row
                                aria-label="type_of_share"
                                name="type_of_share"
                                onChange={handleChangeType}
                            >
                                <FormControlLabel
                                    value={uiHelpers.TypeOfShare[0].id}
                                    checked={type === uiHelpers.TypeOfShare[0].id}
                                    control={<Radio color="secondary" disabled={disabled} />}
                                    label={uiHelpers.TypeOfShare[0].name}
                                />
                                <FormControlLabel
                                    value={uiHelpers.TypeOfShare[1].id}
                                    checked={type === uiHelpers.TypeOfShare[1].id}
                                    control={<Radio color="secondary" disabled={disabled} />}
                                    label={uiHelpers.TypeOfShare[1].name}
                                />
                            </RadioGroup>
                        </div>

                        <div className="row mb-7">
                            <div className={'col-sm-6 col-md-3 mb-3 mb-sm-0 input-line has-unit'}>
                                <InputField
                                    form={form}
                                    type="number"
                                    disabled={type === uiHelpers.TypeOfShare[0].id || disabled}
                                    name="share_capital"
                                    label="Registered Capital"
                                    onChange={(event) => {
                                        setCapital({
                                            ...capital,
                                            share_capital: event.target.value,
                                        });
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">USD</InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className={'col-sm-6 col-md-3 input-line has-unit'}>
                                <InputField
                                    form={form}
                                    type="number"
                                    disabled={type === uiHelpers.TypeOfShare[0].id || disabled}
                                    name="number_share"
                                    label="Number of share"
                                    onChange={(event) => {
                                        setCapital({
                                            ...capital,
                                            number_share: event.target.value,
                                        });
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">Shares</InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="col-12"></div>

                            {warningContent && (
                                <p className="col-12 col-md-6 message warning mt-2">
                                    {warningContent}
                                </p>
                            )}
                        </div>

                        {/* {withParValue === 'yes' && ( */}
                        <div className={'mb-7 input-line has-unit'} style={{ width: '120px' }}>
                            <label>Par value of each share</label>
                            <InputField
                                form={form}
                                type="number"
                                disabled={type === uiHelpers.TypeOfShare[0].id || disabled}
                                name="par_value"
                                label=""
                                onChange={handleChangeParValue}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">USD</InputAdornment>
                                    ),
                                }}
                            />
                            {/* </div> */}
                        </div>
                        {/* )} */}
                    </>
                )}

                {!checkLogic && (
                    <p className="message error mb-4">
                        The numbers you filled in are not qualified according to the formula:
                        Registered capital = Number of share * Par value of share
                    </p>
                )}

                {!hideAction && (
                    <div>
                        <Button
                            size="large"
                            variant="contained"
                            disableElevation
                            onClick={handleBack}
                            disabled={isLoading}
                            className={'mr-2 ' + classes.button}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            type="submit"
                            className={classes.button}
                            style={{ color: '#fff' }}
                            disabled={isLoading}
                            onClick={() => activeStepRef.current = null}
                        >
                            Next
                        </Button>
                    </div>
                )}
            </form>
        </div>
    );
}

// import React from 'react';
import { toast } from 'react-toastify';
import * as requestFromServer from './dashboardCrud';
import { dashboardSlice, callTypes } from './dashboardSlice';

const { actions } = dashboardSlice;

export const fetchDashboard = () => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getDashboard()
        .then((response) => {
            const { data } = response.data;
            dispatch(actions.fetchDashboard({ data: data }));
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't find dashboard information";
            toast.error('Something went wrong!');
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};
import { BVI_ID, PANAMA_ID } from 'constants/country';
import { DIRECTOR_TYPE_ID } from 'constants/member-type';
import * as yup from 'yup';
import { validateRepresentedMember, validateTotalSourceOfFund } from './custom';

yup.addMethod(yup.mixed, 'validateTotalSourceOfFund', validateTotalSourceOfFund);
yup.addMethod(yup.mixed, 'validateRepresentedMember', validateRepresentedMember);
yup.addMethod(yup.mixed, 'businessActivity', function (errorMessage) {
    return this.test(`business_activity_array`, errorMessage, function (value) {
        const { path, createError } = this;

        return value?.length || createError({ path, message: errorMessage });
    });
});
yup.addMethod(yup.mixed, 'countryOperation', function (errorMessage) {
    return this.test(`OperationCountries`, errorMessage, function (value) {
        const { path, createError } = this;

        return value?.length || createError({ path, message: errorMessage });
    });
});
yup.addMethod(yup.string, 'detailBusinessActivity', function (errorMessage, needRequired) {
    return this.test('business_activity_detail', errorMessage, function (value) {
        const { path, createError } = this;
        return (
            value?.trim().split(/\s+/).length >= 10 || createError({ path, message: errorMessage })
        );
    });
});

export const directorSchema = ({ isNominee, hasShowSelectFullName }) => {
    if (isNominee)
        return yup.object().shape({
            reason: yup.string().nullable().required('Reason to use Nominee is required'),
        });
    return yup
        .object({
            members: yup.array().of(
                yup
                    .object({
                        /* Individual */
                        first_name:
                            hasShowSelectFullName &&
                            yup
                                .string()
                                .nullable()
                                .when('type_member_id', {
                                    is: 1,
                                    then: yup.string().required('First name is required!'),
                                }),
                        middle_name: hasShowSelectFullName && yup.string().nullable(),
                        last_name:
                            hasShowSelectFullName &&
                            yup
                                .string()
                                .nullable()
                                .when('type_member_id', {
                                    is: 1,
                                    then: yup
                                        .string()
                                        .nullable()
                                        .required('Last name is required!'),
                                }),
                        full_name: yup
                            .string()
                            .nullable()
                            .when('type_member_id', {
                                is: 1,
                                then: yup
                                    .string()
                                    .nullable()
                                    .required(
                                        hasShowSelectFullName
                                            ? 'Please select your full name!'
                                            : 'Please enter Name',
                                    ),
                            }),
                        birthday: yup
                            .mixed()
                            .nullable()
                            .when('type_member_id', {
                                is: 1,
                                then: yup
                                    .date()
                                    .nullable()
                                    .required('Please enter Birthday')
                                    .typeError('Invalid date'),
                            }),
                        major: yup
                            .string()
                            .nullable()
                            .when('type_member_id', {
                                is: 1,
                                then: yup.string().required('Please enter Major'),
                            }),
                        passport: yup
                            .string()
                            .nullable()
                            .when('type_member_id', {
                                is: 1,
                                then: yup.string().required('Please enter Passport'),
                            }),
                        passport_expire_date: yup
                            .mixed()
                            .nullable()
                            .when('type_member_id', {
                                is: 1,
                                then: yup
                                    .date()
                                    .nullable()
                                    .required('Please enter Date of expire passport')
                                    .typeError('Invalid date'),
                            })
                            .when(
                                ['passport_issue_date', 'type_member_id'],
                                (passport_issue_date, type_member_id, schema, context) => {
                                    if (type_member_id !== 1 || !passport_issue_date)
                                        return yup.mixed();
                                    return yup
                                        .date()
                                        .nullable()
                                        .min(
                                            yup.ref('passport_issue_date'),
                                            'Passport expired date must be greater than Passport issue date',
                                        )
                                        .required('Please enter Date of expire passport')
                                        .typeError('Invalid date');
                                },
                            ),
                        passport_issue_date: yup
                            .mixed()
                            .nullable()
                            .when('type_member_id', {
                                is: 1,
                                then: yup
                                    .date()
                                    .nullable()
                                    .required('Please enter Date of issue passport')
                                    .typeError('Invalid date'),
                            }),

                        /* Corporation */
                        corporation_name: yup
                            .string()
                            .nullable()
                            .when('type_member_id', {
                                is: 2,
                                then: yup.string().required('Please enter Company name'),
                            }),
                        corporation_date: yup
                            .mixed()
                            .nullable()
                            .when('type_member_id', {
                                is: 2,
                                then: yup
                                    .date()
                                    .nullable()
                                    .required('Please enter Date of corporation')
                                    .typeError('Invalid date'),
                            }),
                        corporation_number: yup
                            .string()
                            .nullable()
                            .when('type_member_id', {
                                is: 2,
                                then: yup.string().required('Please enter Company number'),
                            }),
                        operation_address: yup
                            .string()
                            .nullable()
                            .when('type_member_id', {
                                is: 2,
                                then: yup.string().required('Please enter Operating address'),
                            }),
                        nature_business: yup
                            .string()
                            .nullable()
                            .when('type_member_id', {
                                is: 2,
                                then: yup.string().required('Please enter Nature of business'),
                            }),
                        asset_source_fund_cast_in_bank: yup
                            .number()
                            .typeError('Please enter a number')
                            .nullable(),
                        asset_source_fund_other_money: yup
                            .number()
                            .typeError('Please enter a number')
                            .nullable(),
                        asset_source_fund_real_estate: yup
                            .number()
                            .typeError('Please enter a number')
                            .nullable(),
                        income_source_fund_investment: yup
                            .number()
                            .typeError('Please enter a number')
                            .nullable(),
                        income_source_fund_other_money: yup
                            .number()
                            .typeError('Please enter a number')
                            .nullable(),
                        income_source_fund_self_employed: yup
                            .number()
                            .typeError('Please enter a number')
                            .nullable(),

                        address: yup.string().nullable().required('Please enter Address'),
                        email: yup
                            .string()
                            .nullable()
                            .email('Invalid email')
                            .required('Please enter Email'),
                        country_id: yup.number().nullable().required('Please enter Country'),
                        phone_country_id: yup
                            .number()
                            .nullable()
                            .required('Please enter Phone code'),
                        phone_number: yup.string().nullable().required('Please enter Phone number'),

                        CompanyMemberReferences: yup.array().when('__company_country_id', {
                            is: PANAMA_ID,
                            then: yup.array().of(
                                yup.object({
                                    name: yup.string().nullable().required('Please enter Name'),
                                    phone_number: yup
                                        .string()
                                        .nullable()
                                        .required('Please enter Phone number'),
                                    email: yup
                                        .string()
                                        .email('Invalid email')
                                        .nullable()
                                        .required('Please enter Email'),
                                    address: yup
                                        .string()
                                        .nullable()
                                        .required('Please enter Address'),
                                    company_member_issuse_reference_id: yup
                                        .string()
                                        .nullable()
                                        .required('Please enter this field'),
                                    phone_country_id: yup
                                        .number()
                                        .nullable()
                                        .required('Please enter Phone code'),
                                }),
                            ),
                        }),
                        business_activity_array: yup
                            .mixed()
                            .nullable()
                            .when('type_member_id', {
                                is: 2,
                                then: yup
                                    .mixed()
                                    .nullable()
                                    .test(
                                        'required',
                                        'Please enter Business activity',
                                        (val) => val && val.length > 0,
                                    ),
                            }),
                        business_activity_detail: yup
                            .string()
                            .nullable()
                            .when('type_member_id', {
                                is: 2,
                                then: yup
                                    .string()
                                    .nullable()
                                    .required('Please enter Details of Business activities')
                                    .detailBusinessActivity(
                                        'Please write a complete description of your business activities at least 10 words',
                                    ),
                            }),
                        OperationCountries: yup
                            .mixed()
                            .nullable()
                            .when('type_member_id', {
                                is: 2,
                                then: yup
                                    .mixed()
                                    .countryOperation('Please enter country of operation'),
                            }),
                    })
                    .validateTotalSourceOfFund(false),
            ),
        })
        .validateRepresentedMember(
            'validateRepresentedDirector',
            DIRECTOR_TYPE_ID,
            'Must have at least one Secretary',
        );
};

import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import React from 'react';

const TransferShareTable = ({
    transferTitle = 'Transferee',
    transferName = 'to_company_member_id',
    valueTitle = 'Percent of Ownership has been received',
    valueName = 'value',
    options = [],
    form,
    companyMemberIdRef = {},
}) => {
    companyMemberIdRef.current = form.watch(transferName);

    return (
        <>
            <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                    <div className="d-flex h-100 align-items-center">{transferTitle}</div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-8 kyc-frm-control">
                    <SelectField
                        name={transferName}
                        options={options}
                        form={form}
                        label={'Transferee'}
                        getOptionLabel={(option) =>
                            option.type_member_id === 1
                                ? option.full_name || option.first_name
                                : option.corporation_name
                        }
                        hideSuccessHelper
                        hideInputHelperText
                        autoComplete="off"
                    />
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                    <div className="d-flex h-100 align-items-center">{valueTitle}</div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-8 kyc-frm-control">
                    <InputField
                        name={valueName}
                        form={form}
                        label={'Value'}
                        type={'number'}
                        InputProps={{ endAdornment: '%' }}
                    />
                </div>
            </div>
        </>
    );
};

export default TransferShareTable;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    countries: null,
    fileTemplate: null,
};
export const callTypes = {
    list: 'list',
    action: 'action',
};

export const emailSignatureSlice = createSlice({
    name: 'email_signature',
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        countries: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.countries = data;
        },
        fileTemplate: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.fileTemplate = data;
        },
    },
});

import React, { useEffect, useMemo, useState } from 'react';
import { ArrowRight, CheckCircle } from '@mui/icons-material';
import { listToTree, treeToList } from '../helpers/utils';
import { cloneDeep } from 'lodash';
import './DocumentTitleList.scss';
import {CORPORATE_DOCUMENTS_TEMPLATE_ID, CORPORATE_DOCUMENTS} from 'constants/banking';

export const isUploaded = (currentTitle, currentTemplate, indexTitle) => {
    if (currentTemplate.id === CORPORATE_DOCUMENTS_TEMPLATE_ID) {
        return currentTemplate?.Files?.filter(file => file.file_type_id == currentTitle.file_type_id).length > 0;
    }

    return (currentTemplate?.Files?.filter((file) =>
        file.file_template_id == currentTitle.file_template_id
        && file.ranking_partner_id == currentTitle.ranking_partner_id
        && file.company_member_id == currentTitle.company_member_id
        && file.is_filled) || []).length > 0;
}

function DocumentTitleList({ filesTemplate, onSelectTitle, autoSelectFirstTitle = false }) {
    let [activeTitle, setActiveTitle] = useState(null);
    let [activeFileTemplate, setActiveFileTemplate] = useState(null);
    let [selectedAuto, setSelectedAuto] = useState(false);

    const tree = useMemo(() => {
        return listToTree(cloneDeep(filesTemplate));
    }, [filesTemplate]);

    const listConverted = useMemo(() => {
        return treeToList(cloneDeep(tree));
    }, [tree]);


    useEffect(() => {
        if (autoSelectFirstTitle && !selectedAuto) {
            if (listConverted[0]?.titles[0]) {
                setActiveTitle(listConverted[0]?.titles[0]);
                setActiveFileTemplate(listConverted[0]);
                onSelectTitle && onSelectTitle([listConverted[0], listConverted[0]?.titles[0]]);
                setSelectedAuto(true);
            } else if (listConverted[0]?.children?.[0]?.titles?.[0]) {
                setActiveTitle(listConverted[0]?.children?.[0]?.titles[0]);
                setActiveFileTemplate(listConverted[0]?.children[0]);
                onSelectTitle && onSelectTitle([listConverted[0]?.children[0], listConverted[0]?.children[0].titles[0]]);
                setSelectedAuto(true);
            }
        }
    }, [listConverted]);

    const isActiveTitle = (title) => {
        if (title.file_template_id === CORPORATE_DOCUMENTS_TEMPLATE_ID) return title.file_type_id === activeTitle?.file_type_id;

        return title.ranking_partner_id === activeTitle?.ranking_partner_id
                && title.company_member_id === activeTitle?.company_member_id
                && title.file_template_id === activeFileTemplate?.id;
    }



    function renderTreeList(tree, parent = null) {

        const renderFile = (node) => node?.titles.map((title, index) => (
            <li key={index}
                className={'cursor-pointer pb-1 position-relative ' + (isActiveTitle(title) ? 'font-weight-bold' : 'text-black-50')}
                style={{whiteSpace: 'nowrap'}}
                onClick={() => {
                    setActiveTitle(title);
                    setActiveFileTemplate(node);
                    onSelectTitle && onSelectTitle([node, title]);
                }}>
                <span>{title.file_name}</span>

                {isUploaded(title, node, index) &&
                (<CheckCircle
                    color='primary'
                    className='d-inline ml-2'
                    style={{ fontSize: '0.9rem', marginBottom: 2 }}
                />)
                }

                {isActiveTitle(title) ?
                    (<ArrowRight fontSize="small"
                                 className={'position-absolute'}
                                 style={{ top: '50%', left: '-30px', transform: 'translateY(-50%)' }}/> )
                    : ''}
            </li>
        ))

        const Tag = parent ? 'ol' : 'ul';

        return <Tag className={'pl-5'}>
            {tree?.map((node, index) => (
                <React.Fragment key={node.id}>
                    <li
                        className={'pb-1 ' + (!node.parent_id ? 'root' : '')}>
                        <span className={'title-name'}>{node.name}</span>

                        {!node.children.length && <ol className={'pl-5 pt-1'}>
                            {renderFile(node)}
                        </ol>}

                        {renderTreeList(node.children, node)}
                    </li>
                    {parent?.children.length && parent && index === 0 && renderFile(parent)}
                </React.Fragment>
            ))}
        </Tag>
    }

    return (
        <div className={'overflow-auto document-title-list'}>
            {renderTreeList(tree)}
        </div>
    );
}

export default DocumentTitleList;

import { isNil } from 'lodash';
import moment from 'moment';

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const dateTimeFormatter = (string, formatter = DATE_TIME_FORMAT) => {
    if (isNil(string)) return null;

    return moment(string).format(formatter);
};

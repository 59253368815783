import React, { useState } from 'react';
import { Button } from '@mui/material';

import * as ActionsFile from 'app/pages/_redux/files/filesActions';
import { useDispatch } from 'react-redux';

export default function SeeGuide({ fileGuide}) {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const openS3File = (fileGuide) => {
        if (fileGuide && fileGuide.s3_path && fileGuide.s3_path.length > 0) {

            dispatch(ActionsFile.downloadFileS3(fileGuide.s3_path))
                .then((response) => {
                    if (response && response.length > 0) {
                        // console.log('file from s3',response);
                        window.open(response)
                    }
                })
                .finally(() => {
                    // setActionLoading(false);
                });
        }
    };



    return (
        <>
            <div className="d-flex align-items-center">
                <Button
                    onClick={() => {
                        // console.log('you clicked here');
                        openS3File(fileGuide)
                    }}
                    color='primary'
                    className={'text-white'}
                    variant="contained"
                >SEE GUIDE</Button>
            </div>
        </>
    )
}

import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup } from '@mui/material';
import InputField from 'components/form-controls/InputField';
import { DELAWARE_ID, HONG_KONG_ID, PANAMA_ID, ST_KIT_ID } from 'constants/country';
import { dirtyValues, removeEmpty } from 'helper';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const setDefaultShareCapital = (field, countryId, isLLC) => {
    const defaultValue = {
        // default: 50k
        share_capital: {
            [HONG_KONG_ID]: 10000, // HK
            [DELAWARE_ID]: 1000, // Delaware
            [PANAMA_ID]: 10000, // Panama
            [ST_KIT_ID]: 10000, // St Kit (LLC)
        },
        // default: 50k
        number_share: {
            [HONG_KONG_ID]: 10000, // HK
            [DELAWARE_ID]: 1000, // Delaware
            [PANAMA_ID]: 100, // Panama
        },
        // default: 1
        par_value: {
            [PANAMA_ID]: 100,
        },
    };

    // return value or default value (normal || LLC)
    if (defaultValue[field] && field === 'share_capital') {
        if (isLLC) {
            if (countryId === ST_KIT_ID) {
                return defaultValue[field][countryId];
            }
            return 1;
        } else {
            return defaultValue[field][countryId] && countryId !== ST_KIT_ID
                ? defaultValue[field][countryId]
                : 50000;
        }
    }
    if (defaultValue[field] && field === 'number_share') {
        if (isLLC) {
            return 1;
        }
        return defaultValue[field][countryId] ? defaultValue[field][countryId] : 50000;
    }
    if (defaultValue[field] && field === 'par_value') {
        if (isLLC) {
            return 1;
        }
        return defaultValue[field][countryId] ? defaultValue[field][countryId] : 1;
    }

    return 1;
};

yup.addMethod(yup.mixed, 'typeOfShare', function (isLLC = false) {
    return this.test(`type_of_share`, '', function (value) {
        const { path, createError } = this;
        if (isLLC) return true;
        return (
            value ||
            createError({
                path,
                message: 'Please enter type',
            })
        );
    });
});

yup.addMethod(yup.mixed, 'shareCapital', function (isLLC = false) {
    return this.test(`total_share_capital`, '', function (value) {
        const { createError } = this;
        if (isLLC) {
            return (
                value.share_capital ||
                createError({
                    path: 'total_share_capital',
                    message: 'Please enter share capital',
                })
            );
        }
        if (isNaN(value.share_capital) || isNaN(value?.par_value) || isNaN(value?.number_share)) {
            return createError({
                path: 'total_share_capital',
                message: 'Cannot compute value',
            });
        }
        if (value.share_capital !== value?.par_value * value?.number_share) {
            return createError({
                path: 'total_share_capital',
                message: `The numbers you filled in are not qualified according to the formula: Registered capital = Number of share*Par value of share`,
            });
        }
        return true;
    });
});

const typeOfShareOptions = [
    { id: 1, name: 'Standard' },
    { id: 2, name: 'Custom' },
];

const ShareCapital = ({
    companyInfo,
    IS_LLC,
    disabled,
    isHideAction,
    onSubmit,
    handleNext,
    handleBack,
    isLoading,
}) => {
    const [type, setType] = useState(typeOfShareOptions[0].id);

    const schema = yup
        .object({
            type_of_share: yup.number().nullable().typeOfShare(IS_LLC),
            share_capital: yup.number().typeError('Please enter a number').nullable().min(0),
            par_value: yup.number().typeError('Please enter a number').nullable().min(0),
            number_share: yup.number().typeError('Please enter a number').nullable().min(0),
        })
        .shareCapital(IS_LLC);

    const form = useForm({
        defaultValues: {
            class_of_share: 'Ordinary',
            type_of_share: null,
            share_capital: null,
            number_share: null,
            par_value: null,
        },
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        form.reset({
            type_of_share: companyInfo?.ShareCapital?.type_of_share || 1,
            share_capital: companyInfo?.ShareCapital?.share_capital,
            number_share: companyInfo?.ShareCapital?.number_share,
            par_value: companyInfo?.ShareCapital.par_value,
        });
        setType(companyInfo?.ShareCapital?.type_of_share || 1);
    }, [companyInfo]);

    const handleSubmit = (values) => {
        if (Object.keys(form.formState.dirtyFields).length === 0) {
            handleNext();
        } else {
            let payload = {
                shareCapitalUpdate: {
                    company_id: companyInfo.id,
                    ...values,
                },
            };
            onSubmit && onSubmit(payload, true);
        }
    };
    return (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Grid container rowSpacing={2} columnSpacing={4} sm={6} xs={12}>
                {!IS_LLC && (
                    <Grid item xs={12}>
                        <p className="mb-0">
                            Clash of share: <span style={{ fontWeight: '500' }}>Ordinary</span>
                        </p>
                    </Grid>
                )}
                {!IS_LLC && (
                    <Grid className="input-line" item xs={12}>
                        <label>Type:</label>
                        <RadioGroup
                            row
                            aria-label="type_of_share"
                            name="type_of_share"
                            onChange={(event) => {
                                setType(parseInt(event.target.value));
                                if (parseInt(event.target.value) === 1) {
                                    form.setValue(
                                        'share_capital',
                                        setDefaultShareCapital(
                                            'share_capital',
                                            companyInfo?.country_id,
                                            IS_LLC,
                                        ),
                                    );
                                    form.setValue(
                                        'number_share',
                                        setDefaultShareCapital(
                                            'number_share',
                                            companyInfo?.country_id,
                                            IS_LLC,
                                        ),
                                    );
                                    form.setValue(
                                        'par_value',
                                        setDefaultShareCapital(
                                            'par_value',
                                            companyInfo?.country_id,
                                            IS_LLC,
                                        ),
                                    );
                                }
                            }}
                        >
                            {typeOfShareOptions.map((item, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={item.id}
                                    checked={type === item.id}
                                    control={<Radio color="secondary" disabled={disabled} />}
                                    label={item.name}
                                />
                            ))}
                        </RadioGroup>
                    </Grid>
                )}
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>{IS_LLC ? 'Contributed Capital' : 'Registered Capital'}</label>
                    <InputField
                        form={form}
                        type="number"
                        disabled={type === typeOfShareOptions[0].id || disabled}
                        name="share_capital"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">USD</InputAdornment>,
                        }}
                    />
                </Grid>
                {!IS_LLC && (
                    <>
                        <Grid className="input-line" item sm={6} xs={12}>
                            <label>Number of share</label>
                            <InputField
                                form={form}
                                type="number"
                                disabled={type === typeOfShareOptions[0].id || disabled}
                                name="number_share"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">Shares</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid className="input-line" item sm={6} xs={12}>
                            <label>Par value of each share</label>
                            <InputField
                                form={form}
                                type="number"
                                disabled={type === typeOfShareOptions[0].id || disabled}
                                name="par_value"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">USD</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </>
                )}
                {!isHideAction && (
                    <Grid item xs={12}>
                        <Button
                            size="large"
                            variant="contained"
                            disableElevation
                            disabled={isLoading || form.isSubmitting}
                            onClick={handleBack}
                            className={'mr-2'}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            type="submit"
                            style={{ color: '#fff' }}
                            disabled={isLoading}
                        >
                            Next
                        </Button>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};

export default ShareCapital;

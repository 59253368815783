import React, { createContext, useContext, useState, useCallback } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter, defaultSort } from './OrdersUIHelpers';
import {useLocation, useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connectParams, updateQueryParameters } from 'app/helper/queryParamsHelper';
import { useRef } from 'react';
import { useEffect } from 'react';
import * as actions from '../../../_redux/order-form/orderFormActions';

const OrdersUIContext = createContext();

export function useOrdersUIContext() {
    return useContext(OrdersUIContext);
}

export const OrdersUIConsumer = OrdersUIContext.Consumer;

export function OrdersUIProvider({ OrdersUIEvents, children }) {
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const [ids, setIds] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = new URLSearchParams(location.search);
    const [sort, setSort] = useState(defaultSort);
    const ref = useRef(true)

    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const searchParamsQuery = (initialFilter) => {
        const object = {};
        if (params.get("country_id")) {
            object.country_id = JSON.parse(params.get("country_id"));
        }
        if (params.get("service_type_id")) {
            object.service_type_id = (params.get("service_type_id"));
        }
        if (params.get("created_time")) {
            object.created_time = params.get("created_time");
        }
        object.search = params.get("search") ?? '';
        const pageNumber = parseInt(params.get("page")) || 1;
        const pageSize = params.get("page_size") ? parseInt(params.get("page_size")) : initialFilter.pageSize;
        const sortOrder = params.get("sort_order") ? params.get("sort_order") : initialFilter.sortOrder;
        const sortField = params.get("sort_field") ? params.get("sort_field") : initialFilter.sortField;
        const ref = {
            ...initialFilter
            , pageNumber: pageNumber
            , filter: object
            , pageSize: pageSize
            , sortOrder: sortOrder
            , sortField: sortField
        };
        return ref;
    }

    const sortParamsQuery = (initialSort) => {
        const object = {};
        object.order = params.get("sort_order") ? params.get("sort_order") : initialSort.order;
        object.dataField = params.get("sort_field") ? params.get("sort_field") : initialSort.dataField;
        return object;
    }

    const createParamsUrl = (values) => {
        let searchUrl = location.search;
        console.log(values);

        searchUrl = connectParams(searchUrl, values, [
            { name: "country_id", condition: values.country_id && values.country_id.length },
            "service_type_id",
            "created_time",
            "search"])

        searchUrl = updateQueryParameters(searchUrl, 'page', 1);

        navigate({
            pathname: '/order-management',
            search: searchUrl
        });
        setQueryParams(searchParamsQuery(queryParams));
    }

    const createParamsUrlTable = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
        let searchUrl = location.search;
        const pageNumber = page || 1;
        if (ref.current) {
            ref.current = false;
            return false;
        }
        if (type === 'sort') {
            searchUrl = updateQueryParameters(searchUrl, 'sort_order', sortOrder);
            searchUrl = updateQueryParameters(searchUrl, 'sort_field', sortField);
        }
        if (type === 'pagination') {
            searchUrl = updateQueryParameters(searchUrl, 'page', pageNumber);
            searchUrl = updateQueryParameters(searchUrl, 'page_size', sizePerPage);
        }
        navigate({
            pathname: '/order-management',
            search: searchUrl
        });
        setQueryParams((prev) =>
            type === 'sort'
                ? { ...prev, sortOrder, sortField }
                : type === 'pagination'
                    ? { ...prev, pageNumber, pageSize: sizePerPage }
                    : prev,
        );
    }

    const onTableChange = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
        createParamsUrlTable(type, { page, sizePerPage, sortField, sortOrder, data });
    };

    useEffect(() => {
        setQueryParams(searchParamsQuery(queryParams));
        setSort(sortParamsQuery(sort));
        dispatch(actions.fetchOrders(searchParamsQuery(queryParams)));
    }, [location.search])

    const value = {
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams,
        createParamsUrl,
        onTableChange,
        sort
    };

    return <OrdersUIContext.Provider value={value}>{children}</OrdersUIContext.Provider>;
}

import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import moment from 'moment';
import Swal from 'sweetalert2';
import * as actions from '../../../../../../_redux/order-form/orderFormActions';
import { getAnswersForTaskOneQuestion, getTurnOffAnswers } from './nature-of-control/helpers';
import NatureOfControl from './nature-of-control/nature_of_control';
import References from './references/References';
import ExistingMemberDialog from './step3-helper/existing-member/existing-member-dialog';
import {
    EmptyMemberCommon,
    formatCompanyMembers,
    memberCommonYupValidate,
} from './step3-helper/helpers';
import Represented from './step3-helper/represented';
import SecurityQuestion from './step3-helper/security-question';
import SourseForFunds from './step3-helper/sourse-for-funds';
import UBO from './step3-helper/ubo';

import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, uniqBy } from 'lodash';
import { useFieldArray, useForm } from 'react-hook-form';
import { getDefaultPhonePrefix } from 'app/pages/services/utilities/NationalityHelpers';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

const transformNumber = function () {
    return this.transform(function (value) {
        return isNaN(value) ? undefined : value;
    });
};

Yup.addMethod(Yup.mixed, 'transformNumber', transformNumber);
const fieldArraySchema = ({ hasShowSelectFullName }) => {
    return Yup.object({
        members: Yup.array().of(
            Yup.object().shape({
                full_name: Yup.string()
                    .nullable()
                    .required(
                        hasShowSelectFullName
                            ? 'Please select your full name!'
                            : 'Full name is required!',
                    ),
                first_name:
                    hasShowSelectFullName &&
                    Yup.string().nullable().required('First name is required!'),
                middle_name: hasShowSelectFullName && Yup.string().nullable(),
                last_name:
                    hasShowSelectFullName &&
                    Yup.string().nullable().required('Last name is required!'),
                occupation: Yup.string().nullable().required('Occupation is required!'),
                phone: Yup.string()
                    .nullable()
                    .matches(/^[0-9]*$/, 'Please enter number!')
                    .required('Phone number is required!'),
                email: Yup.string()
                    .nullable()
                    .required('Email is required!')
                    .email('Please enter valid email!'),
                country_id: Yup.string().nullable(),

                percentage: Yup.number()
                    .nullable()
                    .transformNumber()
                    .moreThan(0, 'Value must be greater than 0')
                    .integer('Value must be an integer')
                    .required('Number of share is required!'),
                other_asset: Yup.string()
                    .nullable()
                    .when('showOther', {
                        is: true,
                        then: Yup.string()
                            .nullable()
                            .required("Please provide other asset's name."),
                    }),
                other_money: Yup.number()
                    .transform((value) =>
                        isNaN(value) || value === undefined || value === null ? 0 : value,
                    )
                    .nullable()
                    .when('other_asset', {
                        is: (value) => value && value.length > 0,
                        then: Yup.number().min(1, `Please provide other asset's amount`),
                    }),
                other_money_income: Yup.number()
                    .transform((value) =>
                        isNaN(value) || value === undefined || value === null ? 0 : value,
                    )
                    .nullable()
                    .when('other_detail_income', {
                        is: (value) => value && value.length > 0,
                        then: Yup.number().min(1, `Please provide other asset's amount`),
                    }),
                other_detail_income: Yup.string().when('showOtherIncome', {
                    is: true,
                    then: Yup.string().required("Please provide other income's name"),
                }),
                total_asset: Yup.number()
                    .nullable()
                    .transformNumber()
                    .when('isSVG', {
                        is: true,
                        then: Yup.number()
                            .transformNumber()
                            .moreThan(0)
                            .required('Please fillup at least one fields!'),
                    }),
                total_income: Yup.number()
                    .nullable()
                    .transformNumber()
                    .when('isSVG', {
                        is: true,
                        then: Yup.number()
                            .transformNumber()
                            .moreThan(0)
                            .required('Please fillup at least one fields!'),
                    }),

                is_represented: Yup.boolean(),
                security_question_1: Yup.string()
                    .nullable()
                    .when('is_UK_LLP', {
                        is: true,
                        then: Yup.string().required('Security question is required'),
                    }),
                security_question_2: Yup.string()
                    .nullable()
                    .when('is_UK_LLP', {
                        is: true,
                        then: Yup.string().required('Security question is required'),
                    }),
                security_question_3: Yup.string()
                    .nullable()
                    .when('is_UK_LLP', {
                        is: true,
                        then: Yup.string().required('Security question is required'),
                    }),
                answer_question_1: Yup.string()
                    .nullable()
                    .when('is_UK_LLP', {
                        is: true,
                        then: Yup.string().required('Security answer is required'),
                    }),
                answer_question_2: Yup.string()
                    .nullable()
                    .when('is_UK_LLP', {
                        is: true,
                        then: Yup.string().required('Security answer is required'),
                    }),
                answer_question_3: Yup.string()
                    .nullable()
                    .when('is_UK_LLP', {
                        is: true,
                        then: Yup.string().required('Security answer is required'),
                    }),

                // nature of control
                nature_ownership: Yup.string().nullable(),
                nature_voting_rights: Yup.string().nullable(),
                nature_appoint_majority: Yup.string().nullable(),
                nature_other_significant_control: Yup.string().nullable(),
                firm: Yup.string().nullable(),
                firm_ownership: Yup.string().nullable(),
                firm_voting_rights: Yup.string().nullable(),
                firm_appoint_majority: Yup.string().nullable(),
                firm_other_significant_control: Yup.string().nullable(),
                trust: Yup.string().nullable(),
                trust_ownership: Yup.string().nullable(),
                trust_voting_rights: Yup.string().nullable(),
                trust_appoint_majority: Yup.string().nullable(),
                trust_other_significant_control: Yup.string().nullable(),

                ...memberCommonYupValidate,
            }),
        ),
    });
};

export default function Step4({
    setActiveStep,
    activeStep,
    setIsActiveParentStep,
    body,
    isSVG,
    setBody = () => {},
    isLoading,
    setIsLoading,
    onSubmit,
    disabled,
    hideAction,
    activeStepRef = {},
    validateStepsRef = {},
    index,
    hasShowSelectFullName,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [percentage, setPercentage] = useState(100);

    // tab
    const [tabValue, setTabValue] = useState(0);
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };
    const [isShown, setIsShown] = useState(false);
    const { phonePrefixCountriesCooperation } = useSelector((state) => state.enum);

    // init form values
    const initFieldObject = {
        isSVG: isSVG,
        is_UK_LLP: body.is_UK_LLP,
        isPanama: body?.isPanama || false,
        showOtherIncome: false,
        showOther: false,
        individual: true,
        full_name: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        occupation: '',
        phone_code: 0,
        phone: '',
        email: '',
        company_name: '',
        company_number: '',
        country_of_incorporation: 0,
        registered_address: '',
        date_of_incorporation: moment(new Date()).format('MM/DD/YYYY'),
        cash_in_bank_asset_1: '',
        real_estate: '',
        other_asset: '',
        other_money: null,
        investment_income: null,
        self_employed: null,
        other_detail_income: '',
        other_money_income: null,
        total_asset: null,
        total_income: null,
        is_represented: false,
        type_member_id: 1,
        isCreate: true,
        number_alloted: null,
        company_id: body.Company.id,
        company_member_type_id: 3,
        country_id: 0,
        percentage: 100,

        ...EmptyMemberCommon,
    };

    const [initForm, setInitForm] = useState({
        members: body?.companyStructure?.UBO || [],
    });

    const form = useForm({
        defaultValues: initForm,
        resolver: yupResolver(fieldArraySchema({ hasShowSelectFullName })),
    });

    const memberArray = useFieldArray({
        control: form.control,
        name: 'members',
        keyName: 'customId',
    });

    useEffect(() => {
        try {
            let totalPercentage = memberArray.fields.reduce((total, curr) => {
                return (total += Number(curr.percentage));
            }, 0);
            setPercentage(totalPercentage);
        } catch (error) {
            console.log(error);
        }
    }, [memberArray.fields.length]);

    // UK nature of control questions
    useEffect(() => {
        try {
            let Questions = {};
            body.Task.IncorporationCountry.Questions.map((item) => {
                switch (item.id) {
                    case 20:
                        Questions = {
                            ...Questions,
                            nature_ownership: item,
                        };
                        break;
                    case 21:
                        Questions = { ...Questions, nature_voting_rights: item };
                        break;
                    case 22:
                        Questions = { ...Questions, nature_appoint_majority: item };
                        break;
                    case 23:
                        Questions = { ...Questions, nature_other_significant_control: item };
                        break;
                    case 24:
                        Questions = { ...Questions, firm: item };
                        break;
                    case 25:
                        Questions = { ...Questions, trust: item };
                        break;
                    case 26:
                        Questions = { ...Questions, firm_ownership: item };
                        break;
                    case 27:
                        Questions = { ...Questions, firm_voting_rights: item };
                        break;
                    case 28:
                        Questions = { ...Questions, firm_appoint_majority: item };
                        break;
                    case 29:
                        Questions = { ...Questions, firm_other_significant_control: item };
                        break;
                    case 30:
                        Questions = { ...Questions, trust_ownership: item };
                        break;
                    case 31:
                        Questions = { ...Questions, trust_voting_rights: item };
                        break;
                    case 32:
                        Questions = { ...Questions, trust_appoint_majority: item };
                        break;
                    case 33:
                        Questions = { ...Questions, trust_other_significant_control: item };
                        break;
                }
            });
            setBody({ ...body, Questions });
        } catch (error) {
            console.log(error);
        }
    }, [body.Task.IncorporationCountry?.Questions]);

    const checkNumShare = (e, idx, val) => {
        try {
            let currentValue = parseInt(e);
            if (!currentValue) {
                currentValue = 0;
            }
            let newArr = [...val.members];
            newArr.splice(idx, 1);
            newArr.forEach((s) => {
                currentValue += Number(s.percentage);
            });

            if (currentValue === 100) {
                setIsShown(false);
            } else {
                setIsShown(true);
            }

            setPercentage(currentValue);
        } catch (error) {
            console.log(error);
        }
    };

    // handle step
    const handleNext = (values) => {
        try {
            let checkLogic = true;
            if (
                body.represented_ubo &&
                values.members.find((e) => e.is_represented === true) === undefined
            ) {
                checkLogic = false;
                Swal.fire({
                    icon: 'warning',
                    title: 'Warning!',
                    text: body.Represented.UBO.errorMessage,
                    confirmButtonColor: '#17c191',
                    confirmButtonText: 'OK',
                });
            }
            if (checkLogic) {
                setIsLoading(true);
                let finalData = {
                    companyStructureCreate: [],
                    companyStructureUpdate: [],
                    answersForTask: [],
                };

                // flag to check if contact-info-member is up to date => update data in step Contact Information
                let flag = false;
                let contactInfoTemp = {};
                values.members.map((item, index) => {
                    let newItem = {};
                    let answersForTask = [];

                    if (item.is_contact_person === 1) {
                        const phone_prefix = getDefaultPhonePrefix(
                            item?.phone_code,
                            phonePrefixCountriesCooperation,
                        );
                        flag = true;
                        contactInfoTemp = {
                            ...body.contactInfo,
                            email: item.email,
                            first_name: item.first_name || '',
                            middle_name: item.middle_name || '',
                            last_name: item.last_name || '',
                            full_name: item.full_name || '',
                            phone_country_id: item.phone_code,
                            phone_number: item.phone,
                            phone_prefix: phone_prefix,
                        };
                    }

                    newItem = {
                        country_id: Number(item?.country_id) || null,
                        type_member_id: 1,
                        first_name: hasShowSelectFullName // các quốc gia không có first name && middle name && last name => cho first name = full name
                            ? item.first_name || ''
                            : item.full_name || '',
                        middle_name: item.middle_name || '',
                        last_name: item.last_name || '',
                        full_name: item.full_name || '',
                        major: item.occupation,
                        phone_country_id: item.phone_code.id
                            ? Number(item.phone_code.id)
                            : item.phone_code,
                        phone_number: item.phone || null,
                        email: item.email || null,
                        id: item.id || null,
                    };

                    if (isSVG) {
                        newItem = {
                            ...newItem,
                            asset_source_fund_cast_in_bank: item.cash_in_bank_asset_1
                                ? parseInt(item.cash_in_bank_asset_1)
                                : 0,
                            asset_source_fund_real_estate: item.real_estate
                                ? parseInt(item.real_estate)
                                : 0,
                            asset_source_fund_other_detail: item.other_asset || '',
                            asset_source_fund_other_money: item.other_money
                                ? parseInt(item.other_money)
                                : 0,

                            income_source_fund_investment: item.investment_income
                                ? parseInt(item.investment_income)
                                : 0,
                            income_source_fund_self_employed: item.self_employed
                                ? parseInt(item.self_employed)
                                : 0,
                            income_source_fund_other_detail: item.other_detail_income
                                ? item.other_detail_income
                                : '',
                            income_source_fund_other_money: item.other_money_income
                                ? parseInt(item.other_money_income)
                                : 0,
                        };
                    }

                    newItem = {
                        ...newItem,
                        CompanyPositions: [
                            {
                                id: undefined,
                                company_member_type_id: 3,
                                is_appointed: Number(item.is_represented),
                                percent_of_interest:
                                    typeof item.percentage === 'string'
                                        ? item.percentage
                                        : JSON.stringify(item.percentage),
                            },
                        ],

                        CompanyMemberReferences: body.isPanama ? item.references : undefined,
                    };

                    delete newItem.isAfterChooseExistingMember;
                    let question1;
                    let question2;
                    let question3;
                    if (body?.isUK && body?.is_UK_LLP) {
                        question1 = {
                            option_id: item.security_question_1,
                            value_text: item.answer_question_1,
                            is_chosen: 1,
                            company_member_id: item.id,
                        };
                        question2 = {
                            option_id: item.security_question_2,
                            value_text: item.answer_question_2,
                            is_chosen: 1,
                            company_member_id: item.id,
                        };
                        question3 = {
                            option_id: item.security_question_3,
                            value_text: item.answer_question_3,
                            is_chosen: 1,
                            company_member_id: item.id,
                        };

                        answersForTask.push(question1, question2, question3);
                    }

                    const questionNames = [
                        'nature_ownership',
                        'nature_voting_rights',
                        'nature_appoint_majority',
                        'nature_other_significant_control',
                        'firm',
                        'firm_ownership',
                        'firm_voting_rights',
                        'firm_appoint_majority',
                        'firm_other_significant_control',
                        'trust',
                        'trust_ownership',
                        'trust_voting_rights',
                        'trust_appoint_majority',
                        'trust_other_significant_control',
                    ];
                    const questionNameNatures = [
                        'nature_ownership',
                        'nature_voting_rights',
                        'nature_appoint_majority',
                        'nature_other_significant_control',
                    ];
                    const questionNameFirms = [
                        'firm_ownership',
                        'firm_voting_rights',
                        'firm_appoint_majority',
                        'firm_other_significant_control',
                    ];
                    const questionNameTrusts = [
                        'trust_ownership',
                        'trust_voting_rights',
                        'trust_appoint_majority',
                        'trust_other_significant_control',
                    ];

                    if (body?.isUK) {
                        const answerShowFirmTrust = {
                            nature_ownership: 47,
                            nature_voting_rights: 51,
                            nature_appoint_majority: 56,
                            nature_other_significant_control: 58,
                        };
                        let hasFirmTrust = true;

                        questionNameNatures.map((questionName) => {
                            let arrAnswer = getAnswersForTaskOneQuestion(body, item, questionName);
                            answersForTask = answersForTask.concat(arrAnswer);

                            if (item[questionName] !== answerShowFirmTrust[questionName]) {
                                hasFirmTrust = false;
                            }
                        });

                        if (hasFirmTrust) {
                            let arrAnswer_firm = getAnswersForTaskOneQuestion(body, item, 'firm');
                            let arrAnswer_trust = getAnswersForTaskOneQuestion(body, item, 'trust');
                            answersForTask = answersForTask.concat(arrAnswer_firm, arrAnswer_trust);

                            if (item.firm === 59) {
                                // firm: 59-Yes
                                questionNameFirms.map((questionName) => {
                                    let arrAnswer = getAnswersForTaskOneQuestion(
                                        body,
                                        item,
                                        questionName,
                                    );
                                    answersForTask = answersForTask.concat(arrAnswer);
                                });
                            }

                            if (item.trust === 61) {
                                // trust: 61-Yes
                                questionNameTrusts.map((questionName) => {
                                    let arrAnswer = getAnswersForTaskOneQuestion(
                                        body,
                                        item,
                                        questionName,
                                    );
                                    answersForTask = answersForTask.concat(arrAnswer);
                                });
                            }
                        } else {
                            let arrTurnOffAnswer_firm = getTurnOffAnswers(body, item, 'firm');
                            let arrTurnOffAnswer_trust = getTurnOffAnswers(body, item, 'trust');
                            answersForTask = answersForTask.concat(
                                arrTurnOffAnswer_firm,
                                arrTurnOffAnswer_trust,
                            );

                            questionNameFirms.map((questionName) => {
                                let arrTurnOffAnswer = getTurnOffAnswers(body, item, questionName);
                                answersForTask = answersForTask.concat(arrTurnOffAnswer);
                            });

                            questionNameTrusts.map((questionName) => {
                                let arrTurnOffAnswer = getTurnOffAnswers(body, item, questionName);
                                answersForTask = answersForTask.concat(arrTurnOffAnswer);
                            });
                        }
                    }

                    if (newItem.id) {
                        if (body?.isUK && body?.is_UK_LLP) {
                            // clear old selection if has
                            let oldCham = body.Task.IncorporationCountry.Questions.find(
                                (q) => q.id === 17,
                            ).Options.filter((op) => {
                                return op.Answers.find((ans) => ans.company_member_id === item.id);
                            });
                            oldCham.forEach((selectedOpt) => {
                                if (selectedOpt.id !== question1.option_id) {
                                    let turnOff = {
                                        option_id: selectedOpt.id,
                                        is_chosen: 0,
                                        company_member_id: item.id,
                                    };
                                    answersForTask.push(turnOff);
                                }
                            });
                            let oldCham2 = body.Task.IncorporationCountry.Questions.find(
                                (q) => q.id === 18,
                            ).Options.filter((op) => {
                                return op.Answers.find((ans) => ans.company_member_id === item.id);
                            });
                            oldCham2.forEach((selectedOpt) => {
                                if (selectedOpt.id !== question2.option_id) {
                                    let turnOff = {
                                        option_id: selectedOpt.id,
                                        is_chosen: 0,
                                        company_member_id: item.id,
                                    };
                                    answersForTask.push(turnOff);
                                }
                            });
                            let oldCham3 = body.Task.IncorporationCountry.Questions.find(
                                (q) => q.id === 19,
                            ).Options.filter((op) => {
                                return op.Answers.find((ans) => ans.company_member_id === item.id);
                            });
                            oldCham3.forEach((selectedOpt) => {
                                if (selectedOpt.id !== question3.option_id) {
                                    let turnOff = {
                                        option_id: selectedOpt.id,
                                        is_chosen: 0,
                                        company_member_id: item.id,
                                    };
                                    answersForTask.push(turnOff);
                                }
                            });
                        }

                        if (item.company_position_id) {
                            newItem.CompanyPositions[0].id = item.company_position_id;
                        }
                        if (newItem.type_member_id === 1) {
                            if (item.is_contact_person) {
                                newItem.CompanyPositions[0].is_contact_person =
                                    item.is_contact_person;
                            }
                        }
                        delete newItem.company_member_type_id;
                        finalData.companyStructureUpdate.push(newItem);
                        finalData.answersForTask = finalData.answersForTask.concat(answersForTask);
                    } else {
                        delete newItem.id;
                        const ans = answersForTask.map(answer => (
                            Object.entries(answer).reduce((a,[k,v]) => (v === null ? a : (a[k]=v, a)), {})
                        ))
                        newItem['answersForTask'] = ans;
                        finalData.companyStructureCreate.push(newItem);
                    }
                });

                finalData.answersForTask = uniqBy(
                    finalData.answersForTask.map((ans) => {
                        return {
                            ...ans,
                            option_id: Number(ans?.option_id),
                        };
                    }),
                    'option_id',
                );
                // API OK. UNCOMMENT IF NEEDED
                dispatch(actions.updateCompanyStructure(body.Task.id, finalData)).then((res) => {
                    if (res && res.state) {
                        let bodyData = {
                            ...body,
                            Company: {
                                ...body.Company,
                                CompanyMembers: res.data.Company.CompanyMembers,
                            },
                        };

                        let companyStructureNew = formatCompanyMembers(bodyData);

                        setBody({
                            ...bodyData,
                            companyStructure: { ...companyStructureNew },
                            Task: res.data.Task,
                        });

                        if (flag) {
                            setBody((prevBody) => ({
                                ...prevBody,
                                contactInfo: contactInfoTemp,
                            }));
                        }

                        setIsLoading(false);
                        if (typeof activeStepRef.current !== 'number') {
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                            // parent next step
                            setIsActiveParentStep({ back: false, next: true });
                        }
                        activeStepRef.current = null;
                    } else {
                        setIsLoading(false);
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const deleteHandler = (item, index, values) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                let delete_status = false;
                let item_percentage = item.percentage;
                if (item.id && item.company_position_id) {
                    const delData = {
                        companyStructureDelete: {
                            company_position_ids: [item.company_position_id],
                        },
                    };

                    dispatch(actions.updateCompanyStructure(body.Task.id, delData)).then((res) => {
                        if (res.state) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Deleted!',
                                text: res.message,
                            });
                            if (index === 0 && form.getValues('members').length === 1) {
                                memberArray.update(index, initFieldObject);
                                setBody({
                                    ...body,
                                    companyStructure: {
                                        ...body.companyStructure,
                                        UBO: [initFieldObject],
                                    },
                                    Task: res.data.Task,
                                });
                                delete_status = true;
                            } else {
                                if (index === 0) {
                                    setTabValue(0);
                                } else {
                                    setTabValue(index - 1);
                                }

                                memberArray.remove(index);

                                let arrTemp = values.members.filter((e) => e.id !== item.id);
                                setBody({
                                    ...body,
                                    companyStructure: { ...body.companyStructure, UBO: arrTemp },
                                    Task: res.data.Task,
                                });
                                delete_status = true;
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something went wrong!',
                            });
                            delete_status = false;
                        }
                        if (delete_status) {
                            let totalPercentage = percentage - item_percentage;
                            if (index === 0 && form.getValues('members').length === 1) {
                                totalPercentage = 100;
                            }
                            setPercentage(totalPercentage);
                            if (totalPercentage === 100) {
                                setIsShown(false);
                            } else {
                                setIsShown(true);
                            }
                        }
                    });
                } else {
                    if (index === 0 && form.getValues('members').length === 1) {
                        memberArray.update(index, initFieldObject);
                        delete_status = true;
                        form.clearErrors(`members.${index}`);
                    } else {
                        if (index === 0) {
                            setTabValue(0);
                        } else {
                            setTabValue(index - 1);
                        }
                        memberArray.remove(index);
                        delete_status = true;
                    }
                    if (delete_status) {
                        let totalPercentage = percentage - item_percentage;
                        if (index === 0 && form.getValues('members').length === 1) {
                            totalPercentage = 100;
                        }
                        setPercentage(totalPercentage);
                        if (totalPercentage === 100) {
                            setIsShown(false);
                        } else {
                            setIsShown(true);
                        }
                    }
                }
            }
        });
    };

    const checkValid = (values) => {
        try {
            let currentPercentage = 0;
            values.members.map((item) => {
                currentPercentage += Number(item.percentage);
            });
            if (currentPercentage === 100) {
                // Call api here
                setIsShown(false);
                handleNext(values);
            } else {
                setIsShown(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = (values) => {
        checkValid(values);
    };

    // truyền form validate cho file stepper
    validateStepsRef.current = {
        ...validateStepsRef.current,
        [`STEP_${index + 1}`]: {
            isValid: form.formState.isValid && !isShown,
            onSubmit: () => form.handleSubmit(handleSubmit)(),
        },
    };

    return (
        <div className="step4 wrap-step-title-percentage position-relative">
            <div className="step-title-percentage">{percentage}%</div>
            {isShown ? (
                <div className="text-danger">Total interest percentage of all UBOs must be 100</div>
            ) : null}
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <>
                    <div className="control-tab mb-5">
                        <div className="tab-menu">
                            <Tabs value={tabValue} onChange={handleChangeTab} aria-label="tabs">
                                {!isEmpty(memberArray.fields) &&
                                    memberArray.fields.map((member, index, fields) => (
                                        <Tab
                                            key={index}
                                            className="item"
                                            label={index + 1}
                                            id={index}
                                            aria-controls={`tabpanel-${index}`}
                                        />
                                    ))}
                            </Tabs>
                        </div>
                        {!hideAction && (
                            <button
                                type="button"
                                className="btn btn-add"
                                onClick={() => {
                                    memberArray.append(initFieldObject);
                                    setTabValue(memberArray?.fields?.length); // new index = values.members.length
                                    checkNumShare(
                                        initFieldObject.percentage,
                                        memberArray?.fields?.length,
                                        form.watch(),
                                    );
                                }}
                            >
                                <AddIcon />
                            </button>
                        )}
                    </div>

                    <div>
                        {!isEmpty(memberArray.fields) &&
                            memberArray.fields.map((member, index, fields) => (
                                <div key={member.customId} className="tab-panel">
                                    <div
                                        role="tabpanel"
                                        hidden={tabValue !== index}
                                        id={`tabpanel-${index}`}
                                        aria-labelledby={`simple-tab-${index}`}
                                    >
                                        {member.id ? null : (
                                            <ExistingMemberDialog
                                                form={form}
                                                index={index}
                                                body={body}
                                                setBody={setBody}
                                                companyMemberType={3}
                                                fieldAction={memberArray}
                                            />
                                        )}

                                        <UBO
                                            form={form}
                                            members={memberArray}
                                            currentMember={member}
                                            index={index}
                                            body={body}
                                            checkNumShare={checkNumShare}
                                            disabled={disabled}
                                            hasShowSelectFullName={hasShowSelectFullName}
                                        />

                                        {isSVG ? (
                                            <SourseForFunds
                                                form={form}
                                                index={index}
                                                body={body}
                                                values={fields}
                                                disabled={disabled}
                                            />
                                        ) : null}

                                        {body && body.isUK && (
                                            <NatureOfControl
                                                form={form}
                                                currentMember={member}
                                                members={memberArray}
                                                index={index}
                                                body={body}
                                                disabled={disabled}
                                            />
                                        )}

                                        {body.isUK && body.is_UK_LLP ? (
                                            <SecurityQuestion
                                                form={form}
                                                index={index}
                                                body={body}
                                                values={form.watch('members')}
                                                disabled={disabled}
                                            />
                                        ) : null}

                                        <References
                                            form={form}
                                            index={index}
                                            body={body}
                                            values={form.watch('members')}
                                            disabled={disabled}
                                        />

                                        {
                                            // Responsible Party Signing the ss4 Form: is_represented
                                            body && body.represented_ubo && (
                                                <Represented
                                                    form={form}
                                                    index={index}
                                                    body={body}
                                                    values={form.watch('members')}
                                                    representedLable={body.Represented.UBO.label}
                                                    disabled={disabled}
                                                />
                                            )
                                        }

                                        {!hideAction && (
                                            <div className="wrap-btn text-md-right mt-7">
                                                <Button
                                                    name={'btn_delete_' + index}
                                                    className={
                                                        classes.button +
                                                        ' m-0 px-0 btn-delete btn-text'
                                                    }
                                                    disabled={isLoading}
                                                    onClick={(event) => {
                                                        deleteHandler(
                                                            member,
                                                            index,
                                                            form.getValues(),
                                                        );
                                                    }}
                                                    style={{
                                                        color: '#EB5757',
                                                        background: '#fff',
                                                    }}
                                                >
                                                    Delete
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                    </div>
                </>

                {!hideAction && (
                    <div className={`mt-7 ${classes.actionsContainer}`}>
                        <div>
                            {activeStep > 0 && (
                                <Button
                                    size="large"
                                    variant="contained"
                                    disableElevation
                                    onClick={handleBack}
                                    disabled={isLoading}
                                    className={'mr-2 ' + classes.button}
                                >
                                    Back
                                </Button>
                            )}

                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                color="primary"
                                disabled={isLoading}
                                className={classes.button}
                                style={{ color: '#fff' }}
                                onClick={() => (activeStepRef.current = null)}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
}

export default function displayUnit(fee) {
    let tempFee = { ...fee };
    if (tempFee.value < 0) {
        tempFee.value = Math.abs(tempFee.value);
    }
    if (!tempFee.FeeType) return tempFee.value + '/unit';
    switch (tempFee.FeeType.id) {
        case 1:
            return tempFee.value + '/unit';
        case 2:
            return tempFee.value + '/12month';
        case 3:
            return tempFee.value + '/12month';
        case 4:
            return tempFee.value + '/6month';
        case 5:
            return tempFee.value + '/12month';
        case 6:
            return tempFee.value + '/month';
        default:
            return tempFee.value + '/unit';
    }
}

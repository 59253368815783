import axios from 'axios';

const url = process.env.REACT_APP_SCAN_FILE_API_URL;

export const getToken = () => {
    return axios.post(url + '/Token', {
        username: process.env.REACT_APP_SCAN_FILE_API_USERNAME,
        password: process.env.REACT_APP_SCAN_FILE_API_PASSWORD,
    });
};

export const scanFile = (data, token) => {
    return axios.post(url + '/Scan', data, { headers: { Authorization: `Bearer ${token}` } });
};

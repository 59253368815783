import React, { useEffect, useState, useMemo } from 'react';
import Button from '@mui/material/Button';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import * as Yup from 'yup';
import * as uiHelpers from '../../../../../order-form/instruction-incorporation/component/step3/step3-helper/Step3UIHelpers';
import PageLoadingEffect from '../../../../common/PageLoadingEffect';
import { COUNTRIES_NOT_DISABLED_BANKING_FORM } from '../../../../constant';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputAdornment } from '@mui/material';
import InputField from 'components/form-controls/InputField';
import { useForm } from 'react-hook-form';

const transformNumber = function () {
    return this.transform(function (value) {
        return isNaN(value) ? undefined : value;
    })
}

Yup.addMethod(Yup.mixed, 'transformNumber', transformNumber)

const shareCapitalSchema = Yup.object().shape({
    type_of_share: Yup.number().transformNumber(),
    share_capital: Yup.number().transformNumber().when('type_of_share', {
        is: 2,
        then: Yup.number().transformNumber().moreThan(0, 'Value must be greater than 0').integer('Value must be an integer').required('Registered Capital is required!')
    }),
    number_share: Yup.number().transformNumber().when('type_of_share', {
        is: 2,
        then: Yup.number().transformNumber().moreThan(0, 'Value must be greater than 0').integer('Value must be an integer').required('Number of share is required!')
    }),
    par_value: Yup.number().transformNumber().moreThan(0,'Value must be greater than 0').integer('Value must be an integer').required('Par value of each share is required!'),
    contributed_capital: Yup.number().transformNumber().moreThan(0,'Value must be greater than 0').integer('Capital contribution must be an integer').required('Capital contribution is required!'),
});

export default function Step1({
    activeStep,
    setActiveStep,
    isLoading,
    setIsLoading,
    setIsActiveParentStep,
    isHongkong,
    isDelaware,
    body,
    setBody,
    onSubmit
}) {
    const [type, setType] = useState(uiHelpers.TypeOfShare[0].id);
    const [capitalInit, setCapitalInit] = useState({
        // Delaware id = 250
        share_capital: body.number_of_share_standard  * body.par_value,
        number_share: body.number_of_share_standard,
    });
    const [capital, setCapital] = useState(capitalInit);
    const parValueInit = body.par_value;
    const [parValue, setParValue] = useState(parValueInit);
    const [checkLogic, setCheckLogic] = useState(true); // Register capital = Number of share * Value of share

    const [warningContent, setWarningContent] = useState('');
    const isInCorpCompany = useMemo(() => {
        return !body.Company.is_outside_company;
    }, [body]);
    const disabledCountries = useMemo(() => !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(body?.Company?.country_id), [body]);

    const form = useForm({
        defaultValues: {
            type_of_share: uiHelpers.TypeOfShare[0].id,
            share_capital: '',
            number_share: '',
            par_value: body.par_value,
            contributed_capital: ''
        },
        resolver: yupResolver(shareCapitalSchema),
    });

    useEffect(() => {
        try {
            setIsLoading(true);
            if (body) {

                if (body.Company.ShareCapital) {
                    if (body.Company.ShareCapital.type_of_share) {
                        setType(body.Company.ShareCapital.type_of_share);
                    }
                    if (body.Company.ShareCapital.share_capital && body.Company.ShareCapital.number_share) {
                        setCapital({
                            share_capital: body.Company.ShareCapital.share_capital,
                            number_share: body.Company.ShareCapital.number_share
                        });
                    }
                    if (body.Company.ShareCapital.par_value) {
                        setParValue(body.Company.ShareCapital.par_value);
                    }
                }

                const defaultValues = {
                    type_of_share: body.Company.ShareCapital.type_of_share || uiHelpers.TypeOfShare[0].id,
                    share_capital: body.Company.ShareCapital.share_capital || capitalInit.share_capital,
                    number_share: body.Company.ShareCapital.number_share || capitalInit.number_share,
                    par_value: body.Company.ShareCapital.par_value || parValueInit,
                    contributed_capital: body.Company.ShareCapital.contributed_capital || body.Company.ShareCapital.share_capital || body.number_of_share_standard,
                };
                form.reset(defaultValues);

                setIsLoading(false);
            }
        }
        catch (error) { console.log(error) }
    }, [])

    useEffect(() => {
        try {
            if (!checkLogic) {
                setCheckLogic(true);
            }
        } catch (error) { console.log(error) }
    }, [type, capital, parValue]);

    useEffect(() => {
        // warning default
        try {
            let warningContentTemp = '';

            if(body.isBVI || body.isBelize || body.isBahamas || body.isMarshall) {
                warningContentTemp = 'If amount of share capital over 50,000; kindly confirm then our team shall reach you with details soonest';
            }
            if(body.isCayman) {
                warningContentTemp = 'Maximum authorized (as opposed to issued) capital for minimum government incorporation and annual filing is US$50,000';
            }
            setWarningContent(warningContentTemp);
        } catch (error) { console.log(error) }
    }, [])

    useEffect(() => {
        // warning on change "type", "capital"
        try {
            if(body.isHongkong || body.isPanama) {
                let warningContentTemp = '';
                if(type === uiHelpers.TypeOfShare[1].id) { // type: Custom
                    if(body.isHongkong && capital.share_capital > body.number_of_share_over) { // 10,000,000
                        warningContentTemp = 'Kindly note founder members are required to pay share capital amount to the corporate bank accounts';
                    }
                    if(body.isPanama) {
                        warningContentTemp = 'If amount of share capital over 10,000; kindly confirm then our team shall reach you with details soonest';
                    }
                }
                setWarningContent(warningContentTemp);
            }
        } catch (error) { console.log(error) }
    }, [type, form.watch('share_capital'), form.watch('number_share')])
    // end Check warning content

    const handleChangeType = (event) => {
        try {
            if (parseInt(event.target.value) === uiHelpers.TypeOfShare[0].id) {
                setCapital(capitalInit);
                setParValue(parValueInit);
                form.setValue('share_capital', capitalInit.share_capital);
                form.setValue('number_share', capitalInit.number_share);
                form.setValue('par_value', parValueInit)
            }
            setType(parseInt(event.target.value));
            form.setValue('type_of_share', parseInt(event.target.value));
        } catch (error) {
            console.log(error);
        }
    };

    const handleChangeParValue = (event) => {
        setParValue(event.target.value);
    }

    const onSubmitForm = (values) => {
        try {
            let checkValid = true;
            if (!body.isLLC && parseInt(values.type_of_share) === 2) {
                checkValid = (parseInt(values.share_capital) === (parseInt(values.number_share) * parseInt(values.par_value)));
                setCheckLogic(checkValid);
            }
            if (checkValid) {
                setIsLoading(true);
                let data = { shareCapitalUpdate: {} };
                body.isLLC
                    ? data.shareCapitalUpdate = {
                        company_id: body.company_id,
                        "share_capital": parseInt(values.contributed_capital)
                    }
                    : data = {
                        shareCapitalUpdate: {
                            company_id: body.company_id,
                            par_value: parseInt(values.par_value),
                            type_of_share: values.type_of_share,
                            number_share: parseInt(values.number_share),
                            share_capital: parseInt(values.share_capital),
                        }

                    };
                onSubmit && onSubmit({ data, isFinalStep: false, withoutSave: (isInCorpCompany && disabledCountries) });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleBack = () => {
        setIsActiveParentStep({ back: true, next: false });
    };

    const handleSubmit = (values) => {
        onSubmitForm(values);
    };

    return (
        <div className="step1 position-relative">
            <PageLoadingEffect loading={isLoading} />
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                {body && body.isLLC ?
                    <div className="row mb-7">
                        <div
                            className="col-sm-6 col-md-3 input-line has-unit"
                        >
                            <label>Contributed capital</label>
                            <InputField
                                type="number"
                                form={form}
                                name="contributed_capital"
                                disabled={isInCorpCompany && disabledCountries}
                                InputProps={{
                                    endAdornment: (<InputAdornment position="end">{body.currency_unit}</InputAdornment>)
                                }}
                                hideSuccessHelper
                            />
                        </div>
                    </div>
                    : (
                        <>
                            <p className="pb-0">
                                Class of share: <span className="fw-500">Ordinary</span>
                            </p>

                            <div>
                                <div>Type:</div>
                                <RadioGroup 
                                    row 
                                    aria-label="type_of_share"
                                    name="type_of_share"
                                    onChange={handleChangeType}
                                >
                                    <FormControlLabel
                                        value={uiHelpers.TypeOfShare[0].id}
                                        checked={type === uiHelpers.TypeOfShare[0].id}
                                        control={<Radio color="secondary" />}
                                        label={uiHelpers.TypeOfShare[0].name}
                                        disabled={isInCorpCompany && disabledCountries}
                                    />
                                    <FormControlLabel
                                        value={uiHelpers.TypeOfShare[1].id}
                                        checked={type === uiHelpers.TypeOfShare[1].id}
                                        control={<Radio color="secondary" />} 
                                        label={uiHelpers.TypeOfShare[1].name}
                                        disabled={isInCorpCompany && disabledCountries}
                                    />
                                </RadioGroup>
                            </div>

                            <div className="row mb-7">
                                <div
                                    className={`col-sm-6 col-md-3 mb-3 mb-sm-0 input-line has-unit ${type === uiHelpers.TypeOfShare[0].id ? 'input-readonly' : ''
                                        }`}
                                >
                                    <label>Registered Capital</label>
                                    <InputField
                                        type="number"
                                        form={form}
                                        name="share_capital"
                                        disabled={(type === uiHelpers.TypeOfShare[0].id) || (isInCorpCompany && disabledCountries)}
                                        InputProps={{
                                            endAdornment: (<InputAdornment position="end">{body.currency_unit}</InputAdornment>)
                                        }}
                                        hideSuccessHelper
                                        onChange={(event) => {
                                            setCapital({ ...capital, share_capital: event.target.value })
                                        }}
                                    />
                                </div>
                                <div
                                    className={`col-sm-6 col-md-3 input-line has-unit ${type === uiHelpers.TypeOfShare[0].id ? 'input-readonly' : ''
                                        }`}
                                >
                                    <label>Number of share</label>
                                    <InputField
                                        type="number"
                                        form={form}
                                        name="number_share"
                                        disabled={(type === uiHelpers.TypeOfShare[0].id) || (isInCorpCompany && disabledCountries)}
                                        InputProps={{
                                            endAdornment: (<InputAdornment position="end">Shares</InputAdornment>)
                                        }}
                                        hideSuccessHelper
                                        onChange={(event) => {
                                            setCapital({ ...capital, number_share: event.target.value })
                                        }}
                                    />
                                </div>
                                <div className="col-12"></div>

                                { warningContent && <p className="col-12 col-md-6 message warning mt-2">{warningContent}</p> }

                            </div>

                            <div
                                className={`mb-7 input-line has-unit ${type === uiHelpers.TypeOfShare[0].id ? 'input-readonly' : ''}`}
                                style={{ width: '120px' }}
                            >
                                <label>Par value of each share</label>
                                <InputField
                                    type="number"
                                    form={form}
                                    name="par_value"
                                    disabled={(type === uiHelpers.TypeOfShare[0].id) || (isInCorpCompany && disabledCountries)}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">{body.currency_unit}</InputAdornment>)
                                    }}
                                    hideSuccessHelper
                                    onChange={handleChangeParValue}
                                />
                            </div>
                        </>
                    )
                }

                {!checkLogic && <p className="message error mb-4">The numbers you filled in are not qualified according to the formula: Registered capital = Number of share * Par value of share</p>}

                <div>
                    <Button
                        size="large"
                        variant="contained"
                        disableElevation
                        onClick={handleBack}
                        disabled={isLoading}
                        className={'mr-2'}
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                        style={{ color: '#fff' }}
                        disabled={isLoading}
                    >
                        Next
                    </Button>
                </div>
            </form>

        </div>
    );
}

import RadioField from 'components/form-controls/RadioField';
import SelectField from 'components/form-controls/SelectField';
import { find, get } from 'lodash';
import React, { startTransition, useEffect, useRef, useState } from 'react';

// const OPTIONS = [
//     { id: 0, label: 'None'},
//     { id: 1, label: 'More than 25% but not more than 50%'},
//     { id: 2, label: 'More than 50% but less than 75%'},
//     { id: 3, label: '75 % or more'}
// ]

export default function NatureOfControl({ index, body, form, disabled = false }) {
    const member_id = form.watch(`members.${index}.id`);

    const [natureOwnership, setNatureOwnership] = useState({});
    const [natureVotingRights, setNatureVotingRights] = useState({});
    const [natureAppointMajority, setNatureAppointMajority] = useState(false);
    const [natureOtherSignificantControl, setNatureOtherSignificantControl] = useState(false);

    const [firm, setFirm] = useState(false);
    const [trust, setTrust] = useState(false);
    const [showFirmTrust, setShowFirmTrust] = useState(true);

    const isAfterChooseExistingMember = form.watch(`members.${index}.isAfterChooseExistingMember`);
    const firstRender = useRef(false);

    useEffect(() => {
        if (isAfterChooseExistingMember) {
            setDefaultAnswers();
        }
    }, [isAfterChooseExistingMember]);

    useEffect(() => {
        try {
            if (get(body, 'QuestionsIncorp')) {
                startTransition(() => {
                    setTimeout(() => {
                        setDefaultAnswers();
                        firstRender.current = true;
                    }, 0);
                });
            }
        } catch (error) {
            console.log(error);
        }
    }, [body, member_id]);

    const setDefaultAnswers = () => {
        try {
            const getValue = (name) => {
                return (
                    find(get(body, `QuestionsIncorp.${name}.Options`), (op) =>
                        find(
                            get(op, 'Answers'),
                            (ans) => get(ans, 'company_member_id') === member_id,
                        ),
                    ) || get(body, `QuestionsIncorp.${name}.Options[0]`)
                );
            };

            // nature_ownership
            const nature_ownership = getValue('nature_ownership');
            setNatureOwnership(nature_ownership);
            form.setValue(`members.${index}.nature_ownership`, get(nature_ownership, 'id'));

            // nature_voting_rights
            const nature_voting_rights = getValue('nature_voting_rights');
            setNatureVotingRights(nature_voting_rights);
            form.setValue(`members.${index}.nature_voting_rights`, get(nature_voting_rights, 'id'));

            // nature_appoint_majority
            const nature_appoint_majority = getValue('nature_appoint_majority');
            setNatureAppointMajority(get(nature_appoint_majority, 'id')?.toString());
            form.setValue(
                `members.${index}.nature_appoint_majority`,
                get(nature_appoint_majority, 'id'),
            );

            // nature_other_significant_control
            const nature_other_significant_control = getValue('nature_other_significant_control');
            setNatureOtherSignificantControl(
                get(nature_other_significant_control, 'id')?.toString(),
            );
            form.setValue(
                `members.${index}.nature_other_significant_control`,
                get(nature_other_significant_control, 'id'),
            );

            // firm
            const firm = getValue('firm');
            setFirm(get(firm, 'id')?.toString());
            form.setValue(`members.${index}.firm`, get(firm, 'id'));

            // firm_ownership
            const firm_ownership = getValue('firm_ownership');
            form.setValue(`members.${index}.firm_ownership`, get(firm_ownership, 'id'));

            // firm_voting_rights
            const firm_voting_rights = getValue('firm_voting_rights');
            form.setValue(`members.${index}.firm_voting_rights`, get(firm_voting_rights, 'id'));

            // firm_appoint_majority
            const firm_appoint_majority = getValue('firm_appoint_majority');
            form.setValue(
                `members.${index}.firm_appoint_majority`,
                get(firm_appoint_majority, 'id'),
            );

            // firm_other_significant_control
            const firm_other_significant_control = getValue('firm_other_significant_control');
            form.setValue(
                `members.${index}.firm_other_significant_control`,
                get(firm_other_significant_control, 'id'),
            );

            // trust
            const trust = getValue('trust');
            setTrust(get(trust, 'id')?.toString());
            form.setValue(`members.${index}.trust`, get(trust, 'id'));

            // trust_ownership
            const trust_ownership = getValue('trust_ownership');
            form.setValue(`members.${index}.trust_ownership`, get(trust_ownership, 'id'));

            // trust_voting_rights
            const trust_voting_rights = getValue('trust_voting_rights');
            form.setValue(`members.${index}.trust_voting_rights`, get(trust_voting_rights, 'id'));

            // trust_appoint_majority
            const trust_appoint_majority = getValue('trust_appoint_majority');
            form.setValue(
                `members.${index}.trust_appoint_majority`,
                get(trust_appoint_majority, 'id'),
            );

            // trust_other_significant_control
            const trust_other_significant_control = getValue('trust_other_significant_control');
            form.setValue(
                `members.${index}.trust_other_significant_control`,
                get(trust_other_significant_control, 'id'),
            );
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        try {
            if (
                natureOwnership &&
                natureOwnership.content === 'None' &&
                natureVotingRights &&
                natureVotingRights.content === 'None' &&
                natureAppointMajority &&
                natureAppointMajority === '56' && // 56-"No"
                natureOtherSignificantControl &&
                natureOtherSignificantControl === '58'
            ) {
                // 58-"No"
                setShowFirmTrust(true);
            } else {
                setShowFirmTrust(false);
            }
        } catch (error) {
            console.log(error);
        }
    }, [natureOwnership, natureVotingRights, natureAppointMajority, natureOtherSignificantControl]);

    if (!firstRender.current) return null;

    return (
        <>
            <div className="nature_of_control input-line">
                <p className="text-uppercase fw-500">Nature of Control</p>
                <div className="row">
                    <div className="col-md-6 col-sm-6 mb-7 input-line">
                        <label>
                            {body?.is_UK_LLP
                                ? 'Right to earn surplus assets'
                                : 'Ownership of shares'}
                        </label>
                        <SelectField
                            form={form}
                            name={`members.${index}.nature_ownership`}
                            options={get(body, 'QuestionsIncorp.nature_ownership.Options', [])}
                            label=""
                            placeholder={
                                'Choose ' + body?.is_UK_LLP
                                    ? 'Right to earn surplus assets'
                                    : 'Ownership of shares'
                            }
                            getOptionLabel={(option) => option.content}
                            disabled={disabled}
                            onChange={(value) => {
                                if (natureOwnership && natureOwnership.id != value.id) {
                                    setNatureOwnership(value);
                                }
                            }}
                        />
                    </div>
                    <div className="col-md-6 col-sm-6 mb-7 input-line">
                        <label>Voting Rights</label>
                        <SelectField
                            form={form}
                            name={`members.${index}.nature_voting_rights`}
                            options={get(body, 'QuestionsIncorp.nature_voting_rights.Options', [])}
                            label=""
                            placeholder={'Choose Voting Rights'}
                            getOptionLabel={(option) => option.content}
                            disabled={disabled}
                            onChange={(value) => {
                                if (natureVotingRights && natureVotingRights.id != value.id) {
                                    setNatureVotingRights(value);
                                }
                            }}
                        />
                    </div>
                    <div className="col-12 input-line">
                        <div>Appoint or remove the majority of the board of directors</div>
                        <RadioField
                            form={form}
                            aria-label={`members.${index}.nature_appoint_majority`}
                            name={`members.${index}.nature_appoint_majority`}
                            options={get(
                                body,
                                'QuestionsIncorp.nature_appoint_majority.Options',
                                [],
                            )}
                            color="secondary"
                            disabled={disabled}
                            renderLabel={(option) => option && option.content}
                            radioGroupProps={{ sx: { flexDirection: 'row' } }}
                            onChange={(e) => {
                                setNatureAppointMajority(e);
                            }}
                        />
                    </div>
                    <div className="col-12 input-line">
                        <div>Other Significant influences or control</div>
                        <RadioField
                            form={form}
                            aria-label={`members.${index}.nature_other_significant_control`}
                            name={`members.${index}.nature_other_significant_control`}
                            options={get(
                                body,
                                'QuestionsIncorp.nature_other_significant_control.Options',
                                [],
                            )}
                            color="secondary"
                            disabled={disabled}
                            renderLabel={(option) => option && option.content}
                            radioGroupProps={{ sx: { flexDirection: 'row' } }}
                            onChange={(e) => {
                                setNatureOtherSignificantControl(e);
                            }}
                        />
                    </div>

                    {showFirmTrust && (
                        <>
                            <div className="col-md-6 col-12 mb-7">
                                <div className="rad-8 box-shadow-2 p-5">
                                    <div className="mb-2">
                                        <div>
                                            Does this officer have the right to exercise significant
                                            influence or control over a firm(s) which also has a
                                            controlling influence in this company?
                                        </div>
                                        <RadioField
                                            form={form}
                                            aria-label={`members.${index}.firm`}
                                            name={`members.${index}.firm`}
                                            options={get(body, 'QuestionsIncorp.firm.Options', [])}
                                            color="secondary"
                                            disabled={disabled}
                                            renderLabel={(option) => option && option.content}
                                            radioGroupProps={{ sx: { flexDirection: 'row' } }}
                                            onChange={(e) => {
                                                setFirm(e);
                                            }}
                                        />
                                    </div>
                                    {body.QuestionsIncorp &&
                                        body.QuestionsIncorp.firm.Options &&
                                        firm &&
                                        firm ===
                                            body.QuestionsIncorp.firm.Options[0].id.toString() && ( // Yes
                                            <>
                                                <p className="text-uppercase fw-500">
                                                    Types of control within the firm(s)
                                                </p>
                                                <div className="mb-7 input-line">
                                                    <label>Ownership of shares</label>
                                                    <SelectField
                                                        form={form}
                                                        name={`members.${index}.firm_ownership`}
                                                        options={get(
                                                            body,
                                                            'QuestionsIncorp.firm_ownership.Options',
                                                            [],
                                                        )}
                                                        getOptionLabel={(option) => option.content}
                                                        disabled={disabled}
                                                        label=""
                                                        placeholder="Choose Ownership of shares"
                                                    />
                                                </div>
                                                <div className="mb-7 input-line">
                                                    <label>Voting Rights</label>
                                                    <SelectField
                                                        form={form}
                                                        name={`members.${index}.firm_voting_rights`}
                                                        options={get(
                                                            body,
                                                            'QuestionsIncorp.firm_voting_rights.Options',
                                                            [],
                                                        )}
                                                        getOptionLabel={(option) => option.content}
                                                        disabled={disabled}
                                                        label=""
                                                        placeholder="Choose Ownership of shares"
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        Appoint or remove the majority of the board
                                                        of directors
                                                    </div>
                                                    <RadioField
                                                        form={form}
                                                        aria-label={`members.${index}.firm_appoint_majority`}
                                                        name={`members.${index}.firm_appoint_majority`}
                                                        options={get(
                                                            body,
                                                            'QuestionsIncorp.firm_appoint_majority.Options',
                                                            [],
                                                        )}
                                                        color="secondary"
                                                        disabled={disabled}
                                                        renderLabel={(option) =>
                                                            option && option.content
                                                        }
                                                        radioGroupProps={{
                                                            sx: { flexDirection: 'row' },
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        Other Significant influences or control
                                                    </div>
                                                    <RadioField
                                                        form={form}
                                                        aria-label={`members.${index}.firm_other_significant_control`}
                                                        name={`members.${index}.firm_other_significant_control`}
                                                        options={get(
                                                            body,
                                                            'QuestionsIncorp.firm_other_significant_control.Options',
                                                            [],
                                                        )}
                                                        color="secondary"
                                                        disabled={disabled}
                                                        renderLabel={(option) =>
                                                            option && option.content
                                                        }
                                                        radioGroupProps={{
                                                            sx: { flexDirection: 'row' },
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        )}
                                </div>
                            </div>
                            <div className="col-md-6 col-12 mb-7">
                                <div className="rad-8 box-shadow-2 p-5">
                                    <div className="mb-2">
                                        <div>
                                            Does this officer have the right to exercise significant
                                            influence or control over a trust(s) which has a
                                            controlling interest in this company?
                                        </div>
                                        <RadioField
                                            form={form}
                                            aria-label={`members.${index}.trust`}
                                            name={`members.${index}.trust`}
                                            options={get(body, 'QuestionsIncorp.trust.Options', [])}
                                            color="secondary"
                                            disabled={disabled}
                                            renderLabel={(option) => option && option.content}
                                            radioGroupProps={{ sx: { flexDirection: 'row' } }}
                                            onChange={(e) => {
                                                setTrust(e);
                                            }}
                                        />
                                    </div>
                                    {body.QuestionsIncorp &&
                                        body.QuestionsIncorp.trust.Options &&
                                        trust &&
                                        trust ===
                                            body.QuestionsIncorp.trust.Options[0].id.toString() && ( // Yes
                                            <>
                                                <p className="text-uppercase fw-500">
                                                    Types of control within the trust(s)
                                                </p>
                                                <div className="mb-7 input-line">
                                                    <label>Ownership of shares</label>
                                                    <SelectField
                                                        form={form}
                                                        name={`members.${index}.trust_ownership`}
                                                        options={get(
                                                            body,
                                                            'QuestionsIncorp.trust_ownership.Options',
                                                            [],
                                                        )}
                                                        getOptionLabel={(option) => option.content}
                                                        disabled={disabled}
                                                        label=""
                                                        placeholder="Choose Ownership of shares"
                                                    />
                                                </div>
                                                <div className="mb-7 input-line">
                                                    <label>Voting Rights</label>
                                                    <SelectField
                                                        form={form}
                                                        name={`members.${index}.trust_voting_rights`}
                                                        options={get(
                                                            body,
                                                            'QuestionsIncorp.trust_voting_rights.Options',
                                                            [],
                                                        )}
                                                        getOptionLabel={(option) => option.content}
                                                        disabled={disabled}
                                                        label=""
                                                        placeholder="Choose Voting Rights"
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        Appoint or remove the majority of the board
                                                        of directors
                                                    </div>
                                                    <RadioField
                                                        form={form}
                                                        aria-label={`members.${index}.trust_appoint_majority`}
                                                        name={`members.${index}.trust_appoint_majority`}
                                                        options={get(
                                                            body,
                                                            'QuestionsIncorp.trust_appoint_majority.Options',
                                                            [],
                                                        )}
                                                        color="secondary"
                                                        disabled={disabled}
                                                        renderLabel={(option) =>
                                                            option && option.content
                                                        }
                                                        radioGroupProps={{
                                                            sx: { flexDirection: 'row' },
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        Other Significant influences or control
                                                    </div>
                                                    <RadioField
                                                        form={form}
                                                        aria-label={`members.${index}.trust_other_significant_control`}
                                                        name={`members.${index}.trust_other_significant_control`}
                                                        options={get(
                                                            body,
                                                            'QuestionsIncorp.trust_other_significant_control.Options',
                                                            [],
                                                        )}
                                                        color="secondary"
                                                        disabled={disabled}
                                                        renderLabel={(option) =>
                                                            option && option.content
                                                        }
                                                        radioGroupProps={{
                                                            sx: { flexDirection: 'row' },
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

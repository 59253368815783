import React, { useEffect, useMemo, useState } from 'react';
import { ArrowRight, CheckCircle } from '@mui/icons-material';
import 'app/components/DocumentTitleList.scss';

export const isUploaded = (currentTitle, currentTemplate) => {
    return (
        (
            currentTemplate?.Files?.filter((file) =>
                file.company_event_id
                    ? file.company_event_id == currentTitle.company_event_id
                    : file.company_member_id
                    ? file.company_member_id == currentTitle.company_member_id
                    : file.file_template_id == currentTitle.file_template_id,
            ) || []
        ).length > 0 || currentTitle?.files?.length > 0
    );
};

function DocumentTitleListARService({
    filesTemplate,
    onSelectTitle,
    autoSelectFirstTitle = false,
}) {
    let [activeTitle, setActiveTitle] = useState(null);
    let [activeFileTemplate, setActiveFileTemplate] = useState(null);
    let [selectedAuto, setSelectedAuto] = useState(false);

    useEffect(() => {
        if (autoSelectFirstTitle && !selectedAuto) {
            setActiveTitle(filesTemplate?.[0]?.children?.[0]?.titles?.[0]);
            setActiveFileTemplate(filesTemplate?.[0]?.children?.[0]);
            onSelectTitle &&
                onSelectTitle([
                    filesTemplate?.[0]?.children?.[0],
                    filesTemplate?.[0]?.children?.[0]?.titles?.[0],
                ]);
            setSelectedAuto(true);
        }
    }, []);

    const isActiveTitle = (title) => {
        return (
            title.ranking_partner_id === activeTitle?.ranking_partner_id &&
            title.company_member_id === activeTitle?.company_member_id &&
            title.file_template_id === activeFileTemplate?.id &&
            title.company_event_id === activeTitle?.company_event_id
        );
    };

    function renderTreeList(tree) {
        const renderFile = (node) =>
            node?.titles?.map((title, index) => (
                <li
                    key={index}
                    className={
                        'cursor-pointer mb-1 position-relative ' +
                        (isActiveTitle(title) ? 'font-weight-bold' : 'text-black-50')
                    }
                    style={{ whiteSpace: 'nowrap' }}
                    onClick={() => {
                        setActiveTitle(title);
                        setActiveFileTemplate(node);
                        onSelectTitle && onSelectTitle([node, title]);
                    }}
                >
                    <span>{title.file_name}</span>

                    {isUploaded(title, node) && (
                        <CheckCircle
                            color="primary"
                            className="d-inline ml-2"
                            style={{ fontSize: '0.9rem', marginBottom: 2 }}
                        />
                    )}

                    {isActiveTitle(title) ? (
                        <ArrowRight
                            fontSize="small"
                            className={'position-absolute'}
                            style={{ top: '50%', left: '-20px', transform: 'translateY(-50%)' }}
                        />
                    ) : (
                        ''
                    )}
                </li>
            ));

        return (
            <ol className={'pl-5'}>
                {tree?.map((node) => (
                    <React.Fragment key={node.id}>
                        <li className="pb-1">
                            <span className="title-name">{node.name}</span>
                            <ol className={'pl-5'}>{renderFile(node)}</ol>
                        </li>
                    </React.Fragment>
                ))}
            </ol>
        );
    }

    return (
        <div className={'overflow-auto document-title-list-co'}>
            {filesTemplate.map((element, index) => (
                <div key={index}>
                    <h6>{element.name}</h6>
                    {renderTreeList(element.children)}
                </div>
            ))}
        </div>
    );
}

export default DocumentTitleListARService;

import React from 'react';
import { Button, Grid } from '@mui/material';
import SelectFullNameHint from 'app/components/SelectFullNameHint';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import DateField from 'components/form-controls/DateField';

const ContactInfoForm = ({
    form,
    onSubmit,
    disabled,
    hasShowSelectFullName,
    countryOptions,
    phoneCountryOptions,
    isHideAction,
    isLoading,
    allowFields,
}) => {
    return (
        <form onSubmit={onSubmit && form.handleSubmit(onSubmit)}>
            <Grid container rowSpacing={2} columnSpacing={4}>
                {hasShowSelectFullName && (
                    <>
                        <Grid className="input-line" item sm={4} xs={12}>
                            <label>First name</label>
                            <InputField
                                name={'first_name'}
                                form={form}
                                placeholder={'First name'}
                                onChange={(e) => {
                                    form.setValue('first_name', e.target.value);
                                    if (form.getValues('full_name')) {
                                        form.setValue('full_name', '');
                                    }
                                }}
                                showIcon
                                hideSuccessHelper
                                disabled={disabled || !allowFields?.first_name}
                                customStyle={{
                                    '.MuiInputBase-root': {
                                        backgroundColor:
                                            allowFields?.first_name &&
                                            form.getValues('first_name') !== ''
                                                ? 'rgba(176, 236, 209, 0.5)'
                                                : 'inherit',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid className="input-line" item sm={4} xs={12}>
                            <label>Middle name</label>
                            <InputField
                                form={form}
                                name={'middle_name'}
                                placeholder="Middle name"
                                showIcon
                                hideSuccessHelper
                                disabled={disabled || !allowFields?.middle_name}
                                customStyle={{
                                    '.MuiInputBase-root': {
                                        backgroundColor:
                                            allowFields?.middle_name &&
                                            form.getValues('middle_name') !== ''
                                                ? 'rgba(176, 236, 209, 0.5)'
                                                : 'inherit',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid className="input-line" item sm={4} xs={12}>
                            <label>Last name</label>
                            <InputField
                                form={form}
                                name={'last_name'}
                                placeholder="Last name"
                                showIcon
                                hideSuccessHelper
                                disabled={disabled || !allowFields?.last_name}
                                customStyle={{
                                    '.MuiInputBase-root': {
                                        backgroundColor:
                                            allowFields?.last_name &&
                                            form.getValues('last_name') !== ''
                                                ? 'rgba(176, 236, 209, 0.5)'
                                                : 'inherit',
                                    },
                                }}
                                onChange={(e) => {
                                    form.setValue('last_name', e.target.value);
                                    if (form.getValues('full_name')) {
                                        form.setValue('full_name', '');
                                    }
                                }}
                            />
                        </Grid>
                    </>
                )}
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Enter your full name</label>
                    <InputField
                        form={form}
                        name={'full_name'}
                        placeholder="Full name"
                        showIcon
                        hideSuccessHelper
                        disabled={disabled || hasShowSelectFullName || !allowFields?.full_name}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.full_name && form.getValues('full_name') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                        multiline={hasShowSelectFullName}
                    />
                </Grid>
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Birthday</label>
                    <DateField
                        type="text"
                        name={`birthday`}
                        form={form}
                        placeholder="Enter date of birth"
                        disabled={disabled || !allowFields?.birthday}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.birthday && form.getValues('birthday') !== null
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                        hideSuccessHelper
                    />
                </Grid>
                {hasShowSelectFullName && (
                    <Grid
                        item
                        xs={12}
                        sx={{
                            margin: '0 -12.5px',
                        }}
                    >
                        <SelectFullNameHint
                            form={form}
                            fullNameField={'full_name'}
                            firstNameField={'first_name'}
                            middleNameField={'middle_name'}
                            lastNameField={'last_name'}
                            disabled={disabled || !allowFields?.full_name}
                        />
                    </Grid>
                )}
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Phone number</label>
                    <div className="phone-group">
                        <div className="phone-code" style={{ zIndex: 1 }}>
                            <SelectField
                                form={form}
                                className="fw-500"
                                name="phone_country_id"
                                options={phoneCountryOptions || []}
                                hideInputHelperText
                                hideSuccessHelper
                                disabled={disabled || !allowFields?.phone_country_id}
                                customStyle={{
                                    '.MuiInputBase-root': {
                                        backgroundColor:
                                            allowFields?.phone_country_id &&
                                            form.getValues('phone_country_id') !== null
                                                ? 'rgba(176, 236, 209, 0.5)'
                                                : 'inherit',
                                    },
                                    '.MuiInputBase-root:before, .MuiInputBase-root:after, .MuiInput-underline:hover:not(.Mui-disabled):before':
                                        {
                                            borderBottom: '0 !important',
                                        },
                                }}
                            />
                        </div>
                        <div className="input-line inp-phone">
                            <InputField
                                form={form}
                                name="phone_number"
                                placeholder="Phone number"
                                showIcon
                                hideSuccessHelper
                                disabled={disabled || !allowFields?.phone_number}
                                customStyle={{
                                    '.MuiInputBase-root': {
                                        backgroundColor:
                                            allowFields?.phone_number &&
                                            form.getValues('phone_number') !== ''
                                                ? 'rgba(176, 236, 209, 0.5)'
                                                : 'inherit',
                                    },
                                }}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item sm={6} xs={12} />
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Email address</label>
                    <InputField
                        form={form}
                        name="email"
                        placeholder="Enter email address"
                        showIcon
                        hideSuccessHelper
                        disabled={disabled || !allowFields?.email}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.email && form.getValues('email') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid item sm={6} xs={12} />
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Nationality</label>
                    <SelectField
                        form={form}
                        name={`country_id`}
                        placeholder="Choose country"
                        options={countryOptions}
                        hideHelperText={true}
                        disabled={disabled || !allowFields?.country_id}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.country_id && form.getValues('country_id') !== null
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid item sm={6} xs={12} />
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Passport/ID card Number</label>
                    <InputField
                        form={form}
                        name="passport"
                        placeholder="Passport/ID card Number"
                        showIcon
                        hideSuccessHelper
                        disabled={disabled || !allowFields?.passport}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.passport && form.getValues('passport') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Expiry date of Identification</label>
                    <DateField
                        type="text"
                        name={`passport_expire_date`}
                        form={form}
                        placeholder="Enter expiry date of Identification"
                        disabled={disabled || !allowFields?.passport_expire_date}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.passport_expire_date &&
                                    form.getValues('passport_expire_date') !== null
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                        hideSuccessHelper
                    />
                </Grid>
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Address</label>
                    <InputField
                        form={form}
                        name="address"
                        placeholder="Your Address"
                        showIcon
                        hideSuccessHelper
                        disabled={disabled || !allowFields?.address}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.address && form.getValues('address') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid item sm={6} xs={12} />
                <Grid className="input-line" item md={3} sm={6} xs={12}>
                    <label>Country</label>
                    <SelectField
                        form={form}
                        className="fw-500"
                        name="address_country_id"
                        options={countryOptions || []}
                        hideInputHelperText
                        hideSuccessHelper
                        autoComplete="off"
                        disabled={disabled || !allowFields?.address_country_id}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.address_country_id &&
                                    form.getValues('address_country_id') !== null
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid className="input-line" item md={3} sm={6} xs={12}>
                    <label>City</label>
                    <InputField
                        form={form}
                        name="city"
                        placeholder="Your City"
                        showIcon
                        hideSuccessHelper
                        disabled={disabled || !allowFields?.city}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.city && form.getValues('city') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid className="input-line" item md={3} sm={6} xs={12}>
                    <label>State/Province</label>
                    <InputField
                        form={form}
                        name="state"
                        placeholder="Your State/Province"
                        showIcon
                        hideSuccessHelper
                        disabled={disabled || !allowFields?.state}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.state && form.getValues('state') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid className="input-line" item md={3} sm={6} xs={12}>
                    <label>ZIP/Postal code</label>
                    <InputField
                        form={form}
                        name="postal_code"
                        placeholder="Your ZIP/Postal code"
                        showIcon
                        hideSuccessHelper
                        disabled={disabled || !allowFields?.postal_code}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.postal_code && form.getValues('postal_code') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                {!isHideAction && (
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            type="submit"
                            style={{ color: '#fff' }}
                            disabled={isLoading}
                        >
                            Next
                        </Button>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};

export default ContactInfoForm;

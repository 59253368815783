import React from 'react';
import { emphasize } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import CompanyService from '../../existing-company/company-service/CompanyService';
import { COUNTRIES_ID } from 'constants/countries';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // height: 250,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export default function Step3({
    body,
    setBody,
    setActiveStep,
    activeStep,
    setParam,
    preParam,
    serviceTypeMap,
    selectedRadio,
    setSelectedRadio
}) {
    const classes = useStyles();
    // const [selectedRadio, setSelectedRadio] = React.useState(false);
    const isSingapore = COUNTRIES_ID.SINGAPORE === body.country_id;
    // const [services, setServices] = React.useState([]);
    // const [record, setRecord] = React.useState(0);
    // const [services, setServices] = React.useState(body.services ? body.services : []);

    // const [categroyIsSelected, setCategroyIsSelected] = React.useState([]);
    // const group = body.packageIncorp.PackageType.Services.map((service, index, arr) => {
    //     return {
    //         ...service.ServiceType,
    //         Services: arr.filter((item) => item.ServiceType.id === service.ServiceType.id),
    //     };
    // });

    // var category = Array.from(new Set(group.map((e) => e.id)))
    //     .map((id) => {
    //         return group.find((item) => item.id === id);
    //     })
    //     .sort((eFirst, eSecond) => eFirst);
    // useEffect(() => {
    //     setBody({ ...body, services: services });
    // }, [services]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    return (
        <div>
            <div id="add-service" className={classes.root}>
                {body.packageIncorp && (
                    <>
                        <CompanyService
                            body={body}
                            setBody={setBody}
                            serviceTypeMap={serviceTypeMap}
                            setSelectedRadio={setSelectedRadio}
                        />
                    </>
                )}
            </div>
            <div className={classes.actionsContainer}>
                <div>
                    {activeStep > 0 && (
                        <Button size="large" onClick={handleBack} className={classes.button}>
                            Back
                        </Button>
                    )}
                    {activeStep < 3 && (
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            disabled={
                                (isSingapore && !selectedRadio) ||
                                (body.packageIncorp ? false : true)
                            }
                            onClick={() => {
                                preParam();
                                handleNext();
                            }}
                            className={classes.button}
                            style={{ color: '#fff' }}
                        >
                            Next
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}

import React, { useMemo } from 'react';
import {
    XERO_CODE_ROUND_CHOP,
    XERO_CODE_DOCUMENT_RETRIEVAL,
    XERO_CODE_PHYSICAL_SHIPPING,
    XERO_CODE_CHANGE_OFFICER,
    XERO_CODE_CHANGE_COMPANY_NAME,
    XERO_CODE_ES,
    XERO_CODE_TIN,
    XERO_CODE_AR,
    XERO_CODE_ANNUAL_RENEWAL,
} from '../../../../../contants';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

function NotificationResult({ orderInfo }) {
    let navigate = useNavigate();
    const IS_MARSHALL = orderInfo?.Company?.country_id === 136;
    const IS_CAYMAN = orderInfo?.Company?.country_id === 41;
    const IS_RAK = orderInfo?.Company?.country_id === 236;
    const IS_SVG = orderInfo?.Company?.country_id === 209;

    const serviceName = useMemo(() => {
        let result = '';
        const IS_ROUND_CHOP = orderInfo?.OrderItems?.some((item) =>
            XERO_CODE_ROUND_CHOP.includes(item?.Service?.xero_item_code),
        );
        const IS_DOCUMENT_RETRIEVAL = orderInfo?.OrderItems?.some((item) =>
            XERO_CODE_DOCUMENT_RETRIEVAL.includes(item?.Service?.xero_item_code),
        );
        const IS_PHYSICAL_SHIPPING = orderInfo?.OrderItems?.some((item) =>
            XERO_CODE_PHYSICAL_SHIPPING.includes(item?.Service?.xero_item_code),
        );
        const IS_CHANGE_OFFICER =
            orderInfo?.OrderItems?.some((item) =>
                XERO_CODE_CHANGE_OFFICER.includes(item?.Service?.xero_item_code),
            ) &&
            !orderInfo?.OrderItems?.some((item) =>
                XERO_CODE_CHANGE_COMPANY_NAME.includes(item?.Service?.xero_item_code),
            );
        const IS_CHANGE_COMPANY_NAME =
            orderInfo?.OrderItems?.some((item) =>
                XERO_CODE_CHANGE_COMPANY_NAME.includes(item?.Service?.xero_item_code),
            ) &&
            !orderInfo?.OrderItems?.some((item) =>
                XERO_CODE_CHANGE_OFFICER.includes(item?.Service?.xero_item_code),
            );
        const IS_ES = orderInfo?.OrderItems?.some((item) =>
            XERO_CODE_ES.includes(item?.Service?.xero_item_code),
        );
        const IS_TIN = orderInfo?.OrderItems?.some((item) =>
            XERO_CODE_TIN.includes(item?.Service?.xero_item_code),
        );
        const IS_CHANGE_OFFICER_AND_CHANGE_COMPANY_NAME =
            orderInfo?.OrderItems?.some((item) =>
                XERO_CODE_CHANGE_OFFICER.includes(item?.Service?.xero_item_code),
            ) &&
            orderInfo?.OrderItems?.some((item) =>
                XERO_CODE_CHANGE_COMPANY_NAME.includes(item?.Service?.xero_item_code),
            );
        const IS_AR = orderInfo?.OrderItems?.some((item) =>
            XERO_CODE_AR.includes(item?.Service?.xero_item_code),
        );
        const IS_ANNUAL_RENEWAL = orderInfo?.OrderItems?.some((item) =>
            XERO_CODE_ANNUAL_RENEWAL.includes(item?.Service?.xero_item_code),
        );

        const arrayService = [
            { ROUND_CHOP: IS_ROUND_CHOP },
            { DOCUMENT_RETRIEVAL: IS_DOCUMENT_RETRIEVAL },
            { PHYSICAL_SHIPPING: IS_PHYSICAL_SHIPPING },
            { CHANGE_OFFICER: IS_CHANGE_OFFICER },
            { CHANGE_COMPANY_NAME: IS_CHANGE_COMPANY_NAME },
            { ES: IS_ES },
            { TIN: IS_TIN },
            {
                CHANGE_OFFICER_AND_CHANGE_COMPANY_NAME: IS_CHANGE_OFFICER_AND_CHANGE_COMPANY_NAME,
            },
            { AR: IS_AR },
            { ANNUAL_RENEWAL: IS_ANNUAL_RENEWAL },
        ];

        arrayService.forEach((item) => {
            if (Object.values(item)?.[0] === true) {
                result = Object.keys(item)?.[0];
            }
        });

        return result;
    }, [orderInfo]);

    const getStepContent = (serviceName, isNeedESR) => {
        switch (serviceName) {
            case 'DOCUMENT_RETRIEVAL':
                return (
                    <>
                        <p>
                            We are delighted to inform you that your requested service has been
                            successfully completed.
                        </p>
                        <p>
                            You can access those documents in your Company Management tab or click{' '}
                            <NavLink
                                to={`/company/companies?page=1&sort_order=desc&sort_field=order.id&search=${orderInfo?.id}`}
                            >
                                HERE
                            </NavLink>{' '}
                            .
                        </p>
                        <p>
                            Lastly, your opinion matters a great deal to us. If you could take a few
                            moments to leave us a review on{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/google.png"
                                        alt="google-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            and/or
                            <Button variant="text">
                                <a
                                    href="https://www.trustpilot.com/review/bbcincorp.com"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/trustpilot.png"
                                        alt="trust-pilot-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            , it would be immensely appreciated!
                        </p>
                        <p>
                            Your feedback will help us gain valuable insight into our services and
                            motivate our team to strive for even better results in the future.
                        </p>
                        <p>Thank you for your continued support!</p>
                    </>
                );
            case 'ROUND_CHOP':
                return (
                    <>
                        <p>
                            We are delighted to inform you that your requested service has been
                            successfully completed.
                        </p>
                        <p>
                            You can access those documents in your Company Management tab or click{' '}
                            <NavLink
                                to={`/company/companies?page=1&sort_order=desc&sort_field=order.id&search=${orderInfo?.id}`}
                            >
                                HERE
                            </NavLink>{' '}
                            .
                        </p>
                        <div className="mb-3">
                            <p className="mb-0">
                                The below tracking link can be used to track your shipment:
                            </p>
                            {orderInfo?.Task?.Processes?.[0]?.AdditionProcess?.tracking_link && (
                                <a
                                    href={orderInfo.Task.Processes[0].AdditionProcess.tracking_link}
                                    target={'_blank'}
                                >
                                    tracking link
                                </a>
                            )}
                        </div>
                        <p>
                            Lastly, your opinion matters a great deal to us. If you could take a few
                            moments to leave us a review on{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/google.png"
                                        alt="google-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            and/or
                            <Button variant="text">
                                <a
                                    href="https://www.trustpilot.com/review/bbcincorp.com"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/trustpilot.png"
                                        alt="trust-pilot-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            , it would be immensely appreciated!
                        </p>
                        <p>
                            Your feedback will help us gain valuable insight into our services and
                            motivate our team to strive for even better results in the future.
                        </p>
                        <p>Thank you for your continued support!</p>
                    </>
                );
            case 'PHYSICAL_SHIPPING':
                return (
                    <>
                        <p>
                            We are delighted to inform you that your requested service has been
                            successfully completed.
                        </p>
                        <div className="mb-3">
                            <p className="mb-0">
                                The below tracking link can be used to track your shipment:
                            </p>
                            {orderInfo?.Task?.Processes?.[0]?.AdditionProcess?.tracking_link && (
                                <a
                                    href={orderInfo.Task.Processes[0].AdditionProcess.tracking_link}
                                    target={'_blank'}
                                >
                                    tracking link
                                </a>
                            )}
                        </div>
                        <p>
                            Lastly, your opinion matters a great deal to us. If you could take a few
                            moments to leave us a review on{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/google.png"
                                        alt="google-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            and/or
                            <Button variant="text">
                                <a
                                    href="https://www.trustpilot.com/review/bbcincorp.com"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/trustpilot.png"
                                        alt="trust-pilot-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            , it would be immensely appreciated!
                        </p>
                        <p>
                            Your feedback will help us gain valuable insight into our services and
                            motivate our team to strive for even better results in the future.
                        </p>
                        <p>Thank you for your continued support!</p>
                    </>
                );
            case 'CHANGE_COMPANY_NAME':
                return (
                    <>
                        <p>
                            We’re pleased to let you know that your company name has been changed
                            successfully.
                        </p>
                        <p>The relevant form has been filed to the Authority.</p>
                        <p>
                            All related documents was updated accordingly so you can find the newest
                            version in the Company management tab.
                        </p>
                        <p>
                            We would greatly appreciate it if you were satisfied with our services
                            and could spread the word about us by leaving a review on{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/google.png"
                                        alt="google-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            and/or
                            <Button variant="text">
                                <a
                                    href="https://www.trustpilot.com/review/bbcincorp.com"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/trustpilot.png"
                                        alt="trust-pilot-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            .Your feedback will help us gain valuable insight into our services and
                            motivate our team to strive for even better results in the future.
                        </p>
                        <button
                            className="btn"
                            style={{ backgroundColor: '#17c191', color: '#fff' }}
                            onClick={() =>
                                navigate(
                                    `/company/companies/${orderInfo.company_id}/document?file_type_id=0&page=1`,
                                )
                            }
                        >
                            View Document
                        </button>
                    </>
                );
            case 'CHANGE_OFFICER':
                return (
                    <>
                        <p>
                            We're pleased to let you know that the changes to your company's
                            officer(s) has been effected.
                        </p>
                        <p>The relevant form has been filed to the Authority.</p>
                        <p>
                            All related documents was updated accordingly so you can find the newest
                            version in the Company management tab.
                        </p>
                        <p>
                            We would greatly appreciate it if you were satisfied with our services
                            and could spread the word about us by leaving a review on{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/google.png"
                                        alt="google-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            and/or
                            <Button variant="text">
                                <a
                                    href="https://www.trustpilot.com/review/bbcincorp.com"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/trustpilot.png"
                                        alt="trust-pilot-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            .Your feedback will help us gain valuable insight into our services and
                            motivate our team to strive for even better results in the future.
                        </p>
                        <button
                            className="btn"
                            style={{ backgroundColor: '#17c191', color: '#fff' }}
                            onClick={() =>
                                navigate(
                                    `/company/companies/${orderInfo.company_id}/document?file_type_id=0&page=1`,
                                )
                            }
                        >
                            View Document
                        </button>
                    </>
                );
            case 'CHANGE_OFFICER_AND_CHANGE_COMPANY_NAME':
                return (
                    <>
                        <p>
                            We're pleased to let you know that your company name & company's
                            officer(s) has been changed successfully.
                        </p>
                        <p>The relevant form has been filed to the Authority.</p>
                        <p>
                            All related documents was updated accordingly so you can find the newest
                            version in the Company management tab.
                        </p>
                        <p>
                            We would greatly appreciate it if you were satisfied with our services
                            and could spread the word about us by leaving a review on{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/google.png"
                                        alt="google-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            and/or
                            <Button variant="text">
                                <a
                                    href="https://www.trustpilot.com/review/bbcincorp.com"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/trustpilot.png"
                                        alt="trust-pilot-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            .Your feedback will help us gain valuable insight into our services and
                            motivate our team to strive for even better results in the future.
                        </p>
                        <button
                            className="btn"
                            style={{ backgroundColor: '#17c191', color: '#fff' }}
                            onClick={() =>
                                navigate(
                                    `/company/companies/${orderInfo.company_id}/document?file_type_id=0&page=1`,
                                )
                            }
                        >
                            View Document
                        </button>
                    </>
                );
            case 'TIN':
                return (
                    <>
                        <p>
                            We are pleased to let you know that your TIN has been registered
                            successfully.
                        </p>
                        <p>
                            The TIN confirmation was updated accordingly so you can find the
                            relevant document in the Document Tab for your reference.
                        </p>
                        <p>Should you have any concerns, please feel free to chat with us.</p>
                        <p>
                            We would greatly appreciate it if you were satisfied with our services
                            and could spread the word about us by leaving a review on{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/google.png"
                                        alt="google-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>{' '}
                            and/or{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.trustpilot.com/review/bbcincorp.com"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/trustpilot.png"
                                        alt="trust-pilot-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            . Your feedback will help us gain valuable insight into our services and
                            motivate our team to strive for even better results in the future. Thank
                            you for your continued support!
                        </p>
                        <button
                            className="btn"
                            style={{ backgroundColor: '#17c191', color: '#fff' }}
                            onClick={() =>
                                navigate(
                                    `/company/companies/${orderInfo.company_id}/document?file_type_id=2&page=1`,
                                )
                            }
                        >
                            View Document
                        </button>
                    </>
                );
            case 'ES':
                if (isNeedESR && (IS_CAYMAN || IS_RAK)) {
                    return (
                        <>
                            <p>
                                We are pleased to let you know that your self assessment Economic
                                Substance form is assessing.
                            </p>
                            <p>We shall keep you informed when the process is done.</p>
                            <p>Do not hesitate to contact us if you have any concerns.</p>
                            <p>
                                We would greatly appreciate it if you were satisfied with our
                                services and could spread the word about us by leaving a review on{' '}
                                <Button variant="text">
                                    <a
                                        href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                        target={'_blank'}
                                    >
                                        <img
                                            src="/media/logos/google.png"
                                            alt="google-review"
                                            style={{ height: '20px' }}
                                        />
                                    </a>
                                </Button>{' '}
                                and/or{' '}
                                <Button variant="text">
                                    <a
                                        href="https://www.trustpilot.com/review/bbcincorp.com"
                                        target={'_blank'}
                                    >
                                        <img
                                            src="/media/logos/trustpilot.png"
                                            alt="trust-pilot-review"
                                            style={{ height: '20px' }}
                                        />
                                    </a>
                                </Button>
                                . Your feedback will help us gain valuable insight into our services
                                and motivate our team to strive for even better results in the
                                future.
                            </p>
                            <p>Thank you for your patience.</p>
                            <button
                                className="btn"
                                style={{ backgroundColor: '#17c191', color: '#fff' }}
                                onClick={() =>
                                    navigate(
                                        `/company/companies/${orderInfo.company_id}/document?file_type_id=2&page=1`,
                                    )
                                }
                            >
                                View Document
                            </button>
                        </>
                    );
                } else {
                    return (
                        <>
                            <p>
                                We are pleased to let you know that your Economic Substance report
                                has been filed successfully to the{' '}
                                {IS_SVG ? 'local Registered Agent' : 'Authority'}.
                            </p>
                            <p>
                                The confirmation letter of completing the submission was updated
                                accordingly so you can find the relevant document in the Document
                                Tab for your reference.
                            </p>
                            <p>Should you have any concerns, please feel free to chat with us.</p>
                            <p>
                                We would greatly appreciate it if you were satisfied with our
                                services and could spread the word about us by leaving a review on{' '}
                                <Button variant="text">
                                    <a
                                        href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                        target={'_blank'}
                                    >
                                        <img
                                            src="/media/logos/google.png"
                                            alt="google-review"
                                            style={{ height: '20px' }}
                                        />
                                    </a>
                                </Button>{' '}
                                and/or{' '}
                                <Button variant="text">
                                    <a
                                        href="https://www.trustpilot.com/review/bbcincorp.com"
                                        target={'_blank'}
                                    >
                                        <img
                                            src="/media/logos/trustpilot.png"
                                            alt="trust-pilot-review"
                                            style={{ height: '20px' }}
                                        />
                                    </a>
                                </Button>
                                . Your feedback will help us gain valuable insight into our services
                                and motivate our team to strive for even better results in the
                                future. Thank you for your continued support!
                            </p>
                            {IS_MARSHALL && (
                                <p>
                                    More information in:{' '}
                                    <a
                                        target="_blank"
                                        href="https://bbcincorp.com/offshore/articles/how-does-economic-substance-work-in-marshall-islands"
                                    >
                                        HERE
                                    </a>
                                </p>
                            )}
                            <button
                                className="btn"
                                style={{ backgroundColor: '#17c191', color: '#fff' }}
                                onClick={() =>
                                    navigate(
                                        `/company/companies/${orderInfo.company_id}/document?file_type_id=2&page=1`,
                                    )
                                }
                            >
                                View Document
                            </button>
                        </>
                    );
                }
            case 'AR':
                return (
                    <>
                        <p>
                            We are pleased to let you know that your Accounting Records have been
                            filed successfully to the local Registered Agent.
                        </p>
                        <p>
                            The confirmation letter of completing the submission was updated
                            accordingly so you can find the relevant document in the Document Tab
                            for your reference.
                        </p>
                        <p>Should you have any concerns, please feel free to chat with us.</p>
                        <p>
                            We would greatly appreciate it if you were satisfied with our services
                            and could spread the word about us by leaving a review on{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/google.png"
                                        alt="google-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>{' '}
                            and/or{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.trustpilot.com/review/bbcincorp.com"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/trustpilot.png"
                                        alt="trust-pilot-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            . Your feedback will help us gain valuable insight into our services and
                            motivate our team to strive for even better results in the future. Thank
                            you for your continued support!
                        </p>
                        <button
                            className="btn"
                            style={{ backgroundColor: '#17c191', color: '#fff' }}
                            onClick={() =>
                                navigate(
                                    `/company/companies/${orderInfo.company_id}/document?file_type_id=1&page=1`,
                                )
                            }
                        >
                            View Document
                        </button>
                    </>
                );
            case 'ANNUAL_RENEWAL':
                return (
                    <>
                        <h3 className="text-center" style={{ marginBottom: '16px', color: '#000' }}>
                            Thank you for your cooperation!
                        </h3>
                        <p>Thank you for your kind cooperation.</p>
                        <p>
                            Your documents are currently being processed and should be completed
                            within 2-3 days. We will reach out to you with the results soon. If you
                            have any questions or concerns, please don't hesitate to contact us.
                        </p>
                        <p>
                            We would greatly appreciate it if you were satisfied with our services
                            and could spread the word about us by leaving a review on{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/google.png"
                                        alt="google-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>{' '}
                            and/or{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.trustpilot.com/review/bbcincorp.com"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/trustpilot.png"
                                        alt="trust-pilot-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                        </p>
                        <p>
                            Your feedback will help us gain valuable insight into our services and
                            motivate our team to strive for even better results in the future.
                        </p>
                        <p>Thank you for your continued support!</p>
                        <div className="text-center">
                            <button
                                className="btn"
                                style={{ backgroundColor: '#17c191', color: '#fff' }}
                                onClick={() =>
                                    navigate(
                                        `/company/companies/${orderInfo.company_id}/compliance-calendar`,
                                    )
                                }
                            >
                                View Information
                            </button>
                        </div>
                    </>
                );
            default:
                return (
                    <>
                        <p>
                            We are delighted to inform you that your requested service has been
                            successfully completed.
                        </p>
                        <p>
                            Lastly, your opinion matters a great deal to us. If you could take a few
                            moments to leave us a review on{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/google.png"
                                        alt="google-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            and/or
                            <Button variant="text">
                                <a
                                    href="https://www.trustpilot.com/review/bbcincorp.com"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/trustpilot.png"
                                        alt="trust-pilot-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            , it would be immensely appreciated!
                        </p>
                        <p>
                            Your feedback will help us gain valuable insight into our services and
                            motivate our team to strive for even better results in the future.
                        </p>
                        <p>Thank you for your continued support!</p>
                    </>
                );
        }
    };

    return (
        <div className={'p-5 m-auto'} style={{ maxWidth: '580px' }}>
            <div className={'d-flex p-5 justify-content-center'}>
                <img
                    src={'/media/waiting-screen/running-man.svg'}
                    style={{ width: '150px', height: '160px' }}
                    alt={'waiting-img'}
                />
            </div>{' '}
            <br />
            {serviceName !== 'ANNUAL_RENEWAL' && <p>Good day,</p>}
            {getStepContent(serviceName, orderInfo?.Task?.Processes?.[0]?.priority === 2)}
        </div>
    );
}

export default NotificationResult;

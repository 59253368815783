import React from 'react';
import { Button } from '@mui/material';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectField from 'components/form-controls/SelectField';
import DateField from 'components/form-controls/DateField';
import InputField from 'components/form-controls/InputField';

export default function FatcaStep1({ fatcaInitValueStep1, fatcaHandleSubmitStep1, countries }) {
    const fatcaStep1Schema = Yup.object().shape({
        holder_name: Yup.string().required('Account Holder name is required!'),
        holder_country_id: Yup.number().nullable().required('Country is required!'),
        holder_birthday: Yup.date().nullable().required('Birthday is required!'),
        resident_address: Yup.string().required('Number & street is required!'),
        mailing_address: Yup.string().required('Number & street is required!'),
        resident_country_id: Yup.number().nullable().required('Country required!'),
        mailing_country_id: Yup.number().nullable().required('Country required!'),
        resident_city: Yup.string().required('City is required!'),
        mailing_city: Yup.string().required('City is required!'),
        resident_state: Yup.string().required('State/Province is required!'),
        mailing_state: Yup.string().required('State/Province is required!'),
        resident_postal_code: Yup.string().required('ZIP/ Postal code is required!'),
        mailing_postal_code: Yup.string().required('ZIP/ Postal code is required!'),
    });

    const form = useForm({
        defaultValues: fatcaInitValueStep1,
        resolver: yupResolver(fatcaStep1Schema),
        mode: 'all',
    });

    return (
        <form onSubmit={form.handleSubmit(fatcaHandleSubmitStep1)}>
            <div className="row">
                <div className="col-md-6 input-line">
                    <label>Account Holder Name</label>
                    <InputField
                        form={form}
                        name="holder_name"
                        placeholder="Enter name"
                        hideSuccessHelper
                        showIcon
                    />
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3 input-line">
                    <label>Choose country</label>
                    <SelectField
                        form={form}
                        name="holder_country_id"
                        options={countries}
                        placeholder="Choose country"
                        onChange={(value) => {
                            form.setValue('holder_country_id', value?.id);
                            form.setValue('holder_country', value);
                        }}
                        hideHelperText
                        hideInputHelperText
                    />
                    {form.formState.errors?.holder_country_id ? (
                        <div className="invalid-feedback d-block">Country is require!</div>
                    ) : null}
                </div>
                <div className="col-md-3">
                    <div className="has-icon input-line">
                        <label>Date of Birth</label>
                        <DateField
                            form={form}
                            name="holder_birthday"
                            placeholder="DD/MM/YYYY"
                            customStyle={{
                                '& .MuiInput-input': {
                                    padding: '.8rem 1rem .8rem 0',
                                },
                            }}
                        />
                    </div>
                </div>
            </div>

            <br />
            <h6>Permanent Residence Address: </h6>
            <div className="row">
                <div className="col-md-6 input-line">
                    <label>Number & street</label>
                    <InputField
                        form={form}
                        name="resident_address"
                        placeholder="Enter your address"
                        hideSuccessHelper
                        showIcon
                    />
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3 input-line">
                    <label>Choose country</label>
                    <SelectField
                        form={form}
                        name="resident_country_id"
                        options={countries}
                        placeholder="Choose country"
                        onChange={(value) => {
                            form.setValue('resident_country_id', value?.id);
                            form.setValue('resident_country', value);
                        }}
                        hideHelperText
                        hideInputHelperText
                    />
                    {form.formState.errors?.resident_country_id && (
                        <div className="invalid-feedback d-block">Country is require!</div>
                    )}
                </div>
                <div className="col-md-3 input-line">
                    <label>City/Town</label>
                    <InputField
                        form={form}
                        name="resident_city"
                        placeholder="Your city"
                        hideSuccessHelper
                        showIcon
                    />
                </div>
                <div className="col-md-3 input-line">
                    <label>State/Province</label>
                    <InputField
                        form={form}
                        name="resident_state"
                        placeholder="Your state"
                        hideSuccessHelper
                        showIcon
                    />
                </div>
                <div className="col-md-3 input-line">
                    <label>ZIP/ Postal code</label>
                    <InputField
                        form={form}
                        name="resident_postal_code"
                        placeholder="ZIP/ Postal code"
                        hideSuccessHelper
                        showIcon
                    />
                </div>
            </div>
            <br />
            <h6>Mailing address (if different from above):</h6>
            <div className="row">
                <div className="col-md-6 input-line">
                    <label>Number & street</label>
                    <InputField
                        form={form}
                        name="mailing_address"
                        placeholder="Enter your address"
                        hideSuccessHelper
                        showIcon
                    />
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3 input-line">
                    <label>Choose country</label>
                    <SelectField
                        form={form}
                        name="mailing_country_id"
                        options={countries}
                        placeholder="Choose country"
                        onChange={(value) => {
                            form.setValue('mailing_country_id', value?.id);
                            form.setValue('mailing_country', value);
                        }}
                        hideHelperText
                        hideInputHelperText
                    />
                    {form.formState.errors?.mailing_country_id && (
                        <div className="invalid-feedback d-block">Country is require!</div>
                    )}
                </div>
                <div className="col-md-3 input-line">
                    <label>City/Town</label>
                    <InputField
                        form={form}
                        name="mailing_city"
                        placeholder="Your city"
                        hideSuccessHelper
                        showIcon
                    />
                </div>
                <div className="col-md-3 input-line">
                    <label>State/Province</label>
                    <InputField
                        form={form}
                        name="mailing_state"
                        placeholder="Your state"
                        hideSuccessHelper
                        showIcon
                    />
                </div>
                <div className="col-md-3 input-line">
                    <label>ZIP/ Postal code</label>
                    <InputField
                        form={form}
                        name="mailing_postal_code"
                        placeholder="ZIP/ Postal code"
                        hideSuccessHelper
                        showIcon
                    />
                </div>
            </div>
            <Button
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                style={{ color: '#fff', marginTop: '16px' }}
            >
                Next
            </Button>
        </form>
    );
}

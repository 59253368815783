import InputField from 'components/form-controls/InputField';
import React from 'react';
import AntSwitch from '../pages/services/components/kyc-elements/AntSwitch';

const SwitchHookFormShareholderCorp = ({ form, disabled, checked, setChecked }) => {
    return (
        <>
            <div className="d-flex">
                Number to be alloted{' '}
                <AntSwitch
                    checked={checked}
                    onChange={(e) => {
                        setChecked(e.target.checked);
                    }}
                    disabled={disabled}
                />{' '}
                Percentage to be alloted
            </div>
            <div className="mt-2">
                {!!checked && (
                    <InputField
                        form={form}
                        name="own_percent"
                        type="number"
                        label=""
                        InputProps={{ endAdornment: <div style={{ width: 20 }}>%</div> }}
                        disabled={disabled}
                    />
                )}
                {!checked && (
                    <InputField
                        form={form}
                        name="number_allotted"
                        type="number"
                        label=""
                        InputProps={{ endAdornment: <div style={{ width: 70 }}>shares</div> }}
                        disabled={disabled}
                    />
                )}
            </div>
        </>
    );
};

export default SwitchHookFormShareholderCorp;

/* eslint-disable default-case */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actionsEnum from '../../../../../_redux/enum/enumActions';
import * as actionsOrderForm from '../../../../../_redux/order-form/orderFormActions';
import * as uiHelpers from '../../../OrderManagermentUIHelpers.js';

import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { StepButton } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import { isLLC } from 'app/pages/services/constants';
import { ArticlesOfOrganizationOptions } from './constants';
import {
    fetchCountries,
    fetchCountriesHasService,
    fetchCountriesNonRestricted,
} from '../../../../../_redux/enum/enumActions';
import { getContactInfo } from './helpers';
import { formatCompanyMembers } from './step3/step3-helper/helpers';
import { BVI_ID, PANAMA_ID } from 'constants/country';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

const initBody = {
    Contact: { is_completed: false },
    Company: { is_completed: false },
    Structure: {
        Capital: {
            number_of_share: 10000,
            is_completed: false,
        },
        Director: { is_completed: false },
        Shareholder: { is_completed: false },
        UBO: { is_completed: false },
        is_completed: false,
    },
    Business: { is_completed: false },
    is_completed: false,
};

export default function InstructionIncorporationStepper({ setActiveParentStep, setFinalBody }) {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [activeStep, setActiveStep] = useState(0);
    const [activeStepChildrenFromParent, setActiveStepChildrenFromParent] = useState(0);
    const [param, setParam] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [step4Back, setStep4Back] = useState(false);

    const [body, setBody] = useState(null);
    // const [countriesOperation, setCountriesOperation] = useState(null);

    const [isHongkong, setIsHongkong] = useState(false);
    const [isDelaware, setIsDelaware] = useState(false);
    const [isBahamas, setIsBahamas] = useState(false);
    const [isSVG, setIsSVG] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const childrenStepRef = useRef(null);
    const activeStepRef = useRef(null);
    const validateStepsRef = useRef({
        STEP_1: {
            isValid: true,
            onSubmit: () => {},
        },
        STEP_2: {
            isValid: true,
            onSubmit: () => {},
        },
        STEP_3: {
            isValid: true,
            onSubmit: () => {},
        },
        STEP_4: {
            isValid: true,
            onSubmit: () => {},
        },
        STEP_5: {
            isValid: true,
            onSubmit: () => {},
        },
    });

    const validateStepsChildrenRef = useRef({
        STEP_1: {
            isValid: true,
            onSubmit: () => {},
        },
        STEP_2: {
            isValid: true,
            onSubmit: () => {},
        },
        STEP_3: {
            isValid: true,
            onSubmit: () => {},
        },
        STEP_4: {
            isValid: true,
            onSubmit: () => {},
        },
        STEP_5: {
            isValid: true,
            onSubmit: () => {},
        },
    });

    const { countries, orderInfo, phonePrefixCountries } = useSelector(
        (state) => ({
            countries: state.enum.countries,
            countriesCooperation: state.enum.countriesCooperation,
            phonePrefixCountries: state.enum.phonePrefixCountries,
            orderInfo: state.orderForm.orderInfo,
        }),
        shallowEqual,
    );

    const hasShowSelectFullName = useMemo(() => {
        return [BVI_ID, PANAMA_ID].includes(orderInfo?.Company?.country_id);
    }, []);

    const isOrderFormIsFilled = useMemo(() => {
        const orderFormSteps = orderInfo?.Task?.Processes?.filter(
            (process) => process?.process_step_id === 17,
        );
        if (orderFormSteps.length > 1) return true;
    }, [orderInfo]);
    const hasMissingMember = useMemo(() => {
        const membersLevel1 = formatCompanyMembers(body);
        const getMemberExisting = (member) => member?.id;
        let currentMembers = {
            Directors: membersLevel1['Directors'].filter(getMemberExisting),
            Shareholders: membersLevel1['Shareholders'].filter(getMemberExisting),
            UBO: membersLevel1['UBO'].filter(getMemberExisting),
        };
        body?.is_UK_LLP && delete currentMembers['Directors'];
        body?.is_has_director && delete currentMembers['Directors'];
        body?.is_has_shareholder && delete currentMembers['Shareholders'];

        const memberIndex = Object.values(currentMembers).findIndex((members) => !members.length);

        if (memberIndex !== -1) {
            return {
                [Object.keys(currentMembers)[memberIndex]]:
                    Object.values(currentMembers)[memberIndex],
            };
        } else {
            return false;
        }
    }, [body]);

    useEffect(() => {
        dispatch(fetchCountriesHasService());
        dispatch(fetchCountries());
        dispatch(fetchCountriesNonRestricted());
    }, []);

    useEffect(() => {
        try {
            if (orderInfo) {
                let process_step_id =
                    orderInfo.Task.Processes[orderInfo.Task.Processes.length - 1].process_step_id;
                setActiveStep(uiHelpers.getStep(process_step_id).step);
            }
        } catch (error) {
            console.log(error);
        }
    }, [orderInfo]);

    useEffect(() => {
        try {
            if (body) {
                if (!body.companyStructure) {
                    let data = {
                        companyStructure: {
                            Directors: [],
                            Shareholders: [],
                            UBO: [],
                            director_type_member_list: [],
                            shareholder_type_member_list: [],
                        },
                    };

                    // format CompanyMembers from data api
                    if (body.Company.CompanyMembers) {
                        let companyStructureFormat = formatCompanyMembers(body);
                        data = { ...data, companyStructure: companyStructureFormat };
                    }

                    setBody({ ...body, ...data });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [body]);

    useEffect(() => {
        try {
            setIsLoading(true);
            if (orderInfo && countries) {
                let country,
                    phone_prefix,
                    phone_country_id,
                    country_operations = [];

                let company_country_id;
                if (orderInfo.Company?.country_id === 250) {
                    // Delaware(id 250) thuộc United States(id 238)
                    company_country_id = 238;
                } else {
                    company_country_id = orderInfo.Company.country_id;
                }
                countries.some((item) => {
                    if (item.id === company_country_id) {
                        country = item;
                        return true;
                    }
                });

                if (orderInfo.Task.phone_country_id) {
                    phone_country_id = orderInfo.Task.phone_country_id;
                } else {
                    phone_country_id = company_country_id;
                }
                phonePrefixCountries &&
                    phonePrefixCountries.some((item) => {
                        if (item.id === phone_country_id) {
                            phone_prefix = item;
                            return true;
                        }
                    });

                // == country_operations
                if (orderInfo.Company?.OperationCountries) {
                    country_operations = orderInfo.Company.OperationCountries.map((item) => {
                        let temp = countries && countries.find((e) => e.id === item.id);
                        return { ...temp, value: item.id };
                    });
                } else {
                    country_operations = [];
                }

                let data = {
                    country: country,
                    phone_prefix: phone_prefix,
                    number_of_share_standard: 50000,
                    number_of_share_over: 50000,
                    par_value: 1,
                    currency_unit: 'USD',
                    // is_has_director: true, // show data, will be deleted after finished . @TODO @TODELETE ========================================================
                    // is_has_shareholder: true, // show data, will be deleted after finished . @TODO @TODELETE ========================================================

                    // set default điều kiện cần người ký đại diện
                    represented_director: false,
                    represented_shareholder: false,
                    represented_ubo: false,
                };

                // represented: reset điều kiện cần người ký đại diện theo data api
                if (orderInfo.represented_type_ids && orderInfo.represented_type_ids.length > 0) {
                    orderInfo.represented_type_ids.map((id) => {
                        switch (id) {
                            case 1:
                                data.represented_director = true;
                                break;
                            case 2:
                                data.represented_shareholder = true;
                                break;
                            case 3:
                                data.represented_ubo = true;
                                break;
                        }
                    });
                }

                data = {
                    ...data,
                    Represented: {
                        Director: {
                            label: 'Confirm to act as authorized signatory',
                            errorMessage: 'You must have Confirm to act as authorized signatory!',
                        },
                        // Shareholder: {
                        //     label: "Responsible Party Signing the ss4 Form",
                        //     errorMessage: "You must choose one Responsible party signing the ss4 form!",
                        // },
                        Shareholder: {
                            label: 'Consent to act as authorized signatory',
                            errorMessage: 'You must have Consent to act as authorized signatory!',
                        },
                        UBO: {
                            label: 'Consent to act as authorized signatory',
                            errorMessage: 'You must have Consent to act as authorized signatory!',
                        },
                    },
                };

                if (orderInfo.Company.country_id === 250) {
                    data.Represented.Director = {
                        label: 'Responsible Party Signing the ss4 Form',
                        errorMessage: 'You must choose one Responsible party signing the ss4 form!',
                    };
                }
                const CountriesHasRepresentedType2 = [136, 183, 169, 205]; // Marshall, Samoa, Panama, St. Kitts & Nevis
                if (
                    CountriesHasRepresentedType2.find((id) => id === orderInfo.Company.country_id)
                ) {
                    data.Represented.Director = {
                        label: 'Consent to act as secretary',
                        errorMessage: 'You must have Consent to act as secretary!',
                    };
                }
                if (
                    orderInfo.Company.country_id === 209 &&
                    orderInfo.Company.entity_type_id === 11
                ) {
                    // SVG LLC
                    data.Represented.Director = {
                        label: 'Consent to act as secretary',
                        errorMessage: 'You must have Consent to act as secretary!',
                    };
                }

                if (
                    orderInfo.Company.country_id === 23 &&
                    orderInfo.Company.entity_type_id === 11
                ) {
                    // Belize LLC
                    data.Represented.UBO = {
                        label: 'Consent act as Signatory',
                        errorMessage: 'You must have Consent act as Signatory!',
                    };
                }

                if (orderInfo.Company?.country_id === 98) {
                    // Hong Kong
                    setIsHongkong(true);
                    data = {
                        ...data,
                        isHongkong: true,
                        number_of_share_standard: 10000,
                        number_of_share_over: 10000000,
                        currency_unit: 'HKD',
                    };
                }
                if (orderInfo.Company.country_id === 250) {
                    // Delaware
                    setIsDelaware(true);
                    data = {
                        ...data,
                        number_of_share_standard: 1000,
                        number_of_share_over: 1000,
                    };
                }
                if (orderInfo.Company.country_id === 169) {
                    // Panama
                    data = {
                        ...data,
                        isPanama: true,
                        number_of_share_standard: 100,
                        number_of_share_over: 10000,
                        par_value: 100,
                    };
                }
                if (orderInfo.Company.country_id === 209) {
                    // SVG
                    setIsSVG(true);
                    data = {
                        ...data,
                        isSVG: true,
                    };
                }
                if (orderInfo.Company.country_id === 17) {
                    // Bahamas id = 17
                    setIsBahamas(true);
                    data = {
                        ...data,
                    };
                }
                switch (orderInfo?.Company?.country_id) {
                    case 41:
                        data = { ...data, isCayman: true };
                        break;
                    case 17:
                        data = { ...data, isBahamas: true };
                        break;
                    case 23:
                        data = { ...data, isBelize: true };
                        break;
                    case 32:
                        data = { ...data, isBVI: true };
                        break;
                    case 136:
                        data = { ...data, isMarshall: true };
                        break;
                    default:
                        break;
                }

                // check UK LLP
                if (orderInfo.Company.country_id === 237) {
                    data = {
                        ...data,
                        isUK: true,
                    };
                    if (orderInfo.Company.entity_type_id === 7) {
                        data = {
                            ...data,
                            is_UK_LLP: true,
                        };
                    }
                    if (orderInfo.Company.entity_type_id === 16) {
                        data = {
                            ...data,
                            is_UK_LTD: true,
                        };
                    }
                }

                // LLC all counntry
                let checkLLC = isLLC(
                    orderInfo.Company.entity_type_id,
                    orderInfo.Company.country_id,
                );

                if (checkLLC) {
                    data = {
                        ...data,
                        isLLC: true,
                    };
                    if (orderInfo.Company.country_id === 205) {
                        // St. Kitts & Nevis
                        data.number_of_share_standard = 10000;
                    } else {
                        data.number_of_share_standard = 0;
                    }
                }

                // api data -> default data:
                const contactInfo = getContactInfo(orderInfo.Company);
                data = {
                    ...data,
                    contactInfo: {
                        first_name: contactInfo.first_name || '',
                        middle_name: contactInfo?.middle_name || '',
                        last_name: contactInfo.last_name || '',
                        full_name: contactInfo?.full_name || '',
                        email: contactInfo.email || orderInfo.Task.email,
                        phone_number:
                            contactInfo.phone_number ||
                            orderInfo.Task.phone_number ||
                            orderInfo.Customer.phone_number,
                        phone_country_id:
                            contactInfo.phone_country_id ||
                            orderInfo.Task.phone_country_id ||
                            orderInfo.Customer.phone_country_id ||
                            '',
                        address:
                            contactInfo.address ||
                            orderInfo.Task.address ||
                            orderInfo.Customer.address ||
                            '',
                        address_country_id:
                            contactInfo.address_country_id ||
                            orderInfo.Task.country_id ||
                            orderInfo.Customer.country_id ||
                            '',
                        city:
                            contactInfo.city ||
                            orderInfo.Task.city ||
                            orderInfo.Customer.city ||
                            '',
                        state:
                            contactInfo.state ||
                            orderInfo.Task.state ||
                            orderInfo.Customer.state ||
                            '',
                        postal_code:
                            contactInfo.postal_code ||
                            orderInfo.Task.postal_code ||
                            orderInfo.Customer.postal_code ||
                            '',
                    },
                    companyInfo: {
                        company_country: orderInfo.Company.Country.name,
                        company_id: orderInfo.Company.id,
                        company_name: orderInfo.Company.name,
                        company_type: orderInfo.Company.EntityType.name,
                        company_suffix_id: orderInfo.Company.company_suffix_id || '',
                        business_activity_detail: orderInfo.Company.business_activity_detail || '',
                        business_activity_array: orderInfo.Company.business_activity_array || '',
                        business_annual_report: orderInfo.Company.business_annual_report || '',
                        source_fund: orderInfo.Company.source_fund || '',
                        OperationCountries: country_operations || '',
                        country_operations: country_operations || '',
                        operation_address: orderInfo.Company.address || '',
                        operating_country_id: orderInfo.Company.operating_country_id || '',
                        city: orderInfo.Company.operating_city || '',
                        state: orderInfo.Company.operating_state || '',
                        postal_code: orderInfo.Company.operating_postal_code || '',
                        // country: country,
                    },
                };

                setBody({ ...orderInfo, ...data });
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }, [countries, phonePrefixCountries, orderInfo]);

    function onSubmitForm(index) {
        setActiveStep(index);
    }
    const getSteps = () => [
        'Contact information',
        'Company information',
        'Company structure',
        'Business information',
        'Declaration',
    ];
    const steps = getSteps();

    const handleActiveStep = (newStep, stepName) => {
        if (isOrderFormIsFilled) {
            if (stepName) {
                setActiveStep(2);
                let notValidMemberIndex = 0;
                switch (stepName) {
                    case 'Directors':
                        // notValidMemberIndex = body?.Company?.articles_of_organization === 2 ? 2 : 1;
                        if (body?.Company?.articles_of_organization === 2) {
                            // Belize = Member-manage
                            notValidMemberIndex = 2;
                            break;
                        }

                        notValidMemberIndex = 1;
                        break;
                    case 'Shareholders':
                        // notValidMemberIndex = body?.Company?.articles_of_organization === 2 ? 3 : 2;
                        if (body?.Company?.articles_of_organization === 2) {
                            // Belize = Member-manage
                            notValidMemberIndex = 3;
                            break;
                        }

                        if (body?.is_UK_LLP) {
                            // UK - LLP
                            notValidMemberIndex = 0;
                            break;
                        }

                        notValidMemberIndex = 2;
                        break;
                    case 'UBO':
                        notValidMemberIndex = body?.Company?.articles_of_organization === 2 ? 4 : 3;
                        if (body?.Company?.articles_of_organization === 2) {
                            // Belize = Member-manage
                            notValidMemberIndex = 4;
                            break;
                        }

                        if (body?.is_UK_LLP) {
                            // UK - LLP
                            notValidMemberIndex = 1;
                            break;
                        }

                        notValidMemberIndex = 3;
                        break;
                    default:
                        return;
                }

                setActiveStepChildrenFromParent(notValidMemberIndex);
                return;
            }

            const childrenStepNotValidIndex = Object.values(
                validateStepsChildrenRef.current,
            ).findIndex((step) => !step.isValid);

            if (childrenStepNotValidIndex >= 0 && childrenStepRef.current) {
                // childrenStepRef.current.activeStep = newStep;
                childrenStepRef.current.onActiveStep &&
                    childrenStepRef.current.onActiveStep(childrenStepNotValidIndex, true);
                return;
            }

            if (activeStep === newStep) return;

            const currentValidateStep = validateStepsRef.current[`STEP_${activeStep + 1}`];

            activeStepRef.current = newStep;
            currentValidateStep.onSubmit();
            if (currentValidateStep.isValid) {
                setActiveStep(newStep);
                setActiveStepChildrenFromParent(0);
            }
        } else {
            return;
        }
    };

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <Step1
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        body={body}
                        setBody={setBody}
                        validateStepsRef={validateStepsRef}
                        activeStepRef={activeStepRef}
                        hasShowSelectFullName={hasShowSelectFullName}
                    />
                );
            case 1:
                return (
                    <>
                        {
                            <Step2
                                setActiveStep={setActiveStep}
                                activeStep={activeStep}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                isSVG={isSVG}
                                body={body}
                                setBody={setBody}
                                validateStepsRef={validateStepsRef}
                                activeStepRef={activeStepRef}
                            />
                        }
                    </>
                );
            case 2:
                return (
                    <>
                        {
                            <Step3
                                activeStep={activeStep}
                                setActiveStep={setActiveStep}
                                step4Back={step4Back}
                                setStep4Back={setStep4Back}
                                isHongkong={isHongkong}
                                isDelaware={isDelaware}
                                isSVG={isSVG}
                                body={body}
                                setBody={setBody}
                                validateStepsRef={validateStepsRef}
                                activeStepRef={activeStepRef}
                                isOrderFormIsFilled={isOrderFormIsFilled}
                                validateStepsChildrenRef={validateStepsChildrenRef}
                                childrenStepRef={childrenStepRef}
                                activeStepChildrenFromParent={activeStepChildrenFromParent}
                                hasShowSelectFullName={hasShowSelectFullName}
                            />
                        }
                    </>
                );
            case 3:
                return (
                    <>
                        {
                            <Step4
                                activeStep={activeStep}
                                setActiveStep={setActiveStep}
                                setStep4Back={setStep4Back}
                                isHongkong={isHongkong}
                                isDelaware={isDelaware}
                                isBahamas={isBahamas}
                                isSVG={isSVG}
                                body={body}
                                setBody={setBody}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                validateStepsRef={validateStepsRef}
                                activeStepRef={activeStepRef}
                                hasShowSelectFullName={hasShowSelectFullName}
                            />
                        }
                    </>
                );
            case 4:
                return (
                    <React.Fragment>
                        <Step5
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            // setStep4Back={setStep4Back}
                            setActiveParentStep={setActiveParentStep}
                            isHongkong={isHongkong}
                            body={body}
                            setBody={setBody}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                            setFinalBody={setFinalBody}
                            validateStepsRef={validateStepsRef}
                            activeStepRef={activeStepRef}
                            onActiveStep={handleActiveStep}
                            hasMissingMember={hasMissingMember}
                            isOrderFormIsFilled={isOrderFormIsFilled}
                        />
                    </React.Fragment>
                );
            default:
                return 'Unknown step';
        }
    }

    return (
        <>
            <div className={'wrap-stepper ' + classes.root}>
                {/* <Stepper className="p-0" nonLinear activeStep={activeStep} orientation="vertical"> */}
                <Stepper
                    className="stepper p-0"
                    nonLinear
                    activeStep={activeStep}
                    orientation="vertical"
                >
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepButton
                                className="step-title no-click"
                                disableTouchRipple
                                // onClick={() => { setActiveStep(index) }}
                                onClick={() => handleActiveStep(index)}
                            >
                                {label}
                            </StepButton>
                            <StepContent>{getStepContent(index)}</StepContent>
                        </Step>
                    ))}
                </Stepper>
            </div>
            {/* <div className="text-right">
                <Button
                    // disabled={!values.terms}
                    variant="contained"
                    size="large"
                    color="primary"
                    type="button"
                    style={{ color: '#fff' }}
                    onClick={() => navigate('/order-management')}
                // onClick={()=>alert('CLOSE Click')}
                >
                    CLOSE
                </Button>
            </div> */}
        </>
    );
}

import * as actionsEnum from 'app/pages/_redux/enum/enumActions';
import { UK_ID } from 'constants/country';

const NameRestricted = [
    // Singapore
    {
        id: 191,
        NameRestricted: [
            'architect',
            'Real Estate Agent',
            'Real Estate Agency',
            'spot commodity trading',
            'rubber',
            'Military',
            'Defence',
            'school',
            'learning centre',
            'education centre',
            'training centre',
            'Academy',
            'College',
            'Institute',
            'Institution',
            'University',
            'National',
            'Singapore',
            'Association of Southeast Asian Nations',
            'ASEAN',
            'Law',
            'Legal',
            'Counsel',
            'Chamber',
            'Advocate',
            'Solicitor',
            'Law Corporation',
            'LLC',
            'Bank',
            'banking',
            'banque',
            'banco',
            'Bancaire',
            'Banca',
            'Banche',
            'Finance Company',
            'Finance',
            'Futures Exchange',
            'Clearing House',
            'Securities Exchange',
            'stock exchange',
            'clearing corporation',
            'clearing organisation',
            'Professional Engineering',
            'Professional Engineers',
            'PE',
            'Merlion',
            'Accountancy',
            'Accounting',
            'Audit',
            'hospital',
            'medical clinic',
            'medical centre',
            'dental clinic',
            'dental centre',
            'surgery',
            'medical laboratory',
            'clinical laboratory',
            'healthcare establishment',
            'Co-op',
            'Co-operative',
            'temasek',
        ],
    },
    // Hong Kong
    {
        id: 98,
        NameRestricted: [
            'Department',
            'Government',
            'Commission',
            'Bureau',
            'Federation',
            'Council',
            'Authority',
            'Building Society',
            'Chamber of Commerce',
            'Cooperative',
            'Kaifong',
            'Mass Transit',
            'Municipal',
            'Savings',
            'Tourist Association',
            'Trust',
            'Trustee',
            'Underground Railway',
            'Bank',
            'Stock Exchange',
            'Unified Exchange',
            'certified public accountant (practising)',
            'public accountant',
            'CPA (practising)',
            'PA',
        ],
    },
    // Cyprus
    {
        id: 58,
        NameRestricted: [
            'National',
            'International',
            'Republic',
            'European Union',
            'Europe',
            'Euro',
            'co-operative',
            'municipal',
            'privileged',
            'recognised',
            'bank',
            'savings bank',
            'credit institution',
            'cooperative credit institution',
            'university',
            'polytechnic',
            'school',
            'academy',
            'college',
            'insurance company',
            'reinsurance company',
            'casino',
            'radio',
            'television',
            'Payment Institution',
            'Electronic Money Institution',
            'e-money',
            'Bureau de Change',
            'Exchange',
            'Fixed Capital Investment Company',
            'F.C.I.C.',
            'Variable Capital Investment Company',
            'V.C.I.C.',
            'Alternative Investment Fund',
            'AIF',
            'Alternative Investment Fund with Limited Number of Persons',
            'AIF with Limited Number of Persons',
            'AIFLNP',
            'Registered Alternative Investment Fund',
            'RAIF',
        ],
    },
    // RAK
    {
        id: 236,
        NameRestricted: [
            'Accredited',
            'Adjudicator',
            'Ajman',
            'Al Ain',
            'Annuity',
            'Anonyme',
            'Arab',
            'Arbitrage',
            'Association',
            'Assurance',
            'Assurer',
            'Authorized Representative',
            'Banc',
            'Banco',
            'Bancorp',
            'Bank',
            'Banker',
            'Bankrupt',
            'Bankruptcy',
            'Banque',
            'Betting',
            'Bingo',
            'Building Society',
            'Bureau',
            'Captive',
            'Casualty',
            'Chamber of Commerce',
            'Change',
            'Charity / Charitable',
            'Chartered',
            'Church',
            'College',
            'Commission',
            'Companies Registry',
            'Cooperative',
            'Cooperative Society',
            'Council',
            'Credit',
            'Currency',
            'Decree',
            'Dubai',
            'e-bank',
            'E-change',
            'e-commerce',
            'e-gaming',
            'Emirate/s',
            'e-savings',
            'Exchange',
            'Federation',
            'Fidelity',
            'Fiduciaire',
            'Fiduciare',
            'Fiduciary',
            'Financing',
            'Financing Business',
            'Forex',
            'Foundation',
            'Fujairah',
            'Fund',
            'Funding',
            'Gambling',
            'Gaming',
            'Government',
            'Governor',
            'Guarantee',
            'Guaranteed',
            'Gulf',
            'Hedge',
            'Hedge Fund',
            'HMS',
            'i-bank',
            'IBC',
            'ICC',
            'i-financing',
            'i-fund',
            'i-gaming',
            'i-insurance',
            'i-investment',
            'i-money services',
            'Indemnity',
            'Insolvency',
            'Insolvent',
            'Inspectorate',
            'Insurance',
            'Insurer',
            'Int',
            'Intl',
            'i-trust',
            'Khalifa',
            'Law',
            'Lease',
            'Leasing',
            'Life',
            'Limited Partnership',
            'Liquidation',
            'Liquidator',
            'LLC',
            'LLP',
            'Loan',
            'Lottery',
            'LP',
            'Money',
            'Money Services',
            'Mutual',
            'Mutual Fund',
            'National',
            'NTL',
            'Official Liquidator',
            'Official Receiver',
            'Official Trustee',
            'Partnership',
            'Pharmacy',
            'Protected Cell',
            'Provident',
            'Qasimi',
            'RAK',
            'RAK ICC',
            'Ras Al Khaimah',
            'Reassurance',
            'Reassurer',
            'Receiver',
            'Receivership',
            'Registrar',
            'Registry',
            'Regulator',
            'Reinsurance',
            'Re-insurance',
            'Reinsurer',
            'Risk',
            'Saving',
            'Savings and Loans',
            'School',
            'Sharjah',
            'Sheik',
            'Sovereign',
            'Surety',
            'Transmission',
            'Tribunal',
            'Trust',
            'Trust Company',
            'Trust Corporation',
            'Trustee',
            'Trustee Company',
            'UAE',
            'Um Al Quwain',
            'Underwrite',
            'Underwriter',
            'Underwriting',
            'United',
            'United Arab Emirates',
            'University',
            'Zayed',
        ],
    },
    // Anguilla
    {
        id: 8,
        NameRestricted: [
            'Assurance',
            'Assurance Broker',
            'Assurer',
            'Authority',
            'Banc',
            'Banca',
            'Bancaria',
            'Bancaire',
            'Bancario',
            'Banco',
            'Bancomer',
            'Bancorp',
            'Bancos',
            'Bangko',
            'Bank',
            'Banka',
            'Bankas',
            'Bankasi',
            'Banke',
            'Banken',
            'Banker',
            'Bankhaus',
            'Banki',
            'Bankiers',
            'Banking',
            "Bankin'ny",
            'Bankirsky',
            'Bankos',
            'Bankverein',
            'Banky',
            'Banque',
            'Banquier',
            'Banquiers',
            'Betting',
            'British',
            'Building Society',
            'Chamber of Commerce',
            'Charity',
            'Charitable',
            'Co-operative',
            'Co-op',
            'Credit',
            'Creditbank',
            'Credit Union',
            'Creditanstalt',
            'Credito',
            'Discontobank',
            'European',
            'Friendly Society',
            'Fiduciary',
            'Foundation',
            'Fund',
            'Gaming',
            'Gennossenschaftsbank',
            'Girobank',
            'Girozentrale',
            'Government',
            'Great Britain',
            'Handelsbank',
            'Hypothekenbank',
            'Indemnity',
            'Insurance',
            'Insurance Broker',
            'Insurer',
            'Iraq',
            'Iraqi',
            'Kantonalbank',
            'King',
            'Kontrolbank',
            'Kretit',
            'Kredietbank',
            'Landesbank',
            'Libya',
            'Libyan',
            'Lottery',
            'Lotto',
            'Majesty',
            'Mutual',
            'Nationalbank',
            'Pankki',
            'Patent',
            'Patent Office',
            'Police',
            'Post Office',
            'Prince',
            'Princess',
            'Privatbank',
            'Queen',
            'Raiffeisenbank',
            'Re-assurance',
            'Re-assurance Broker',
            'Re-assurer',
            'Red Cross',
            'Re-insurance',
            'Re-insurance Broker',
            'Re-insurer',
            'Royal',
            'Royale',
            'Royalty',
            'Sparbank',
            'Stock Exchange',
            'Trade Union',
            'Trust',
            'Underwriter',
            'Vereinsbank',
            'Volksbank',
            'Windsor',
        ],
    },
    // Bahamas
    {
        id: 17,
        NameRestricted: [
            'Assurance',
            'Bank',
            'Building Society',
            'Chamber of Commerce',
            'Chartered',
            'Cooperative',
            'Imperial',
            'Insurance',
            'Municipal',
            'Royal',
            'Trust',
        ],
    },
    // Belize
    {
        id: 23,
        NameRestricted: [
            'Building',
            'Society',
            'Chamber of Commerce',
            'Chartered',
            'Cooperative',
            'Imperial',
            'Monarchy',
            'Municipal',
            'Royal',
            'Academy',
            'AssetManagement',
            'Assurance',
            'Bank',
            'Brokerage',
            'CreditUnion',
            'Education',
            'Fiduciary',
            'Financial',
            'Foreign Exchange',
            'Forex',
            'Fund',
            'InvestmentManagement',
            'Insurance',
            'Lending',
            'Securities',
            'Trust',
            'University',
            '.com',
            '.org',
            '.net',
            '.bz',
        ],
    },
    // BVI
    {
        id: 32,
        NameRestricted: [
            'Adjuster',
            'Ahorra',
            'Annuity',
            'Anonima',
            'Anonyme',
            'Arbitrage',
            'Asset Management',
            'Association',
            'Assurance',
            'Assurer',
            'Authorised Representative',
            'Banc',
            'Banco',
            'Bancorp',
            'Bank',
            'Banker',
            'Bankrupt',
            'Bankruptcy',
            'Banque',
            'Belegginsfonds',
            '(Belegginsfonds)',
            'Betting',
            'Bingo',
            'British',
            'Broker',
            'Brokerage',
            'Building Society',
            'Bureau',
            'Caja',
            'Capital Markets',
            'Captive',
            'Casualty',
            'Chamber of Commerce',
            'Change',
            'Chartered',
            'Church',
            'College',
            'Companies Registry',
            'Company Registry',
            'Cooperative',
            'Cooperative Society',
            'Credit',
            'Critical Illness',
            'Crown',
            'Currency',
            'Deposit',
            'E-change',
            'Exchange',
            'e-bank',
            'e-commerce',
            'e-Financing',
            'e-Fund',
            'e-gaming',
            'e-Insurance',
            'e-Investment',
            'e-money',
            'e-Money Services',
            'e-savings',
            'e-Trust',
            'Extended Coverage',
            'Extended Warranty',
            'Fidelity',
            'Fiduciaire',
            'Fiduciare',
            'Fiduciary',
            'Financing',
            'Financing Business',
            'Fondo',
            'Fondos Mutude',
            'Fondos Mutuds',
            'Fondos Mutuos',
            'Foreign Exchange',
            'Foreign Insurer',
            'Forex',
            'Foundation',
            'Fund',
            'Funding',
            'FX',
            'Gambling',
            'Gaming',
            'Geldmittl',
            'Government',
            'Governor',
            'Guarantee',
            'Guaranteed',
            'Hedge',
            'Hedge Fund',
            'HMS',
            'IBC',
            'Imperial',
            'Indemnity',
            'Insolvency',
            'Insolvent',
            'Insurance',
            'Insured',
            'Insurer',
            'Insurance Agent',
            'Insurance Broker',
            'Insurance Brokerage',
            'Insurance Consultant',
            'Insurance Manager',
            'Intermediary',
            'Island',
            'i-bank',
            'i-financing',
            'i-Forex',
            'i-fund',
            'i-gaming',
            'i-insurance',
            'i-investment',
            'i-Money',
            'i-Money Services',
            'i-Securities',
            'i-Trust',
            'Law',
            'Lease',
            'Leasing',
            'Liability',
            'Life',
            'Life and Health',
            'Limited Partnership',
            'Liquidation',
            'Liquidator',
            'Litigation Insurance',
            'LLC',
            'LLP',
            'Lloyds',
            'Loan',
            'Loss Adjuster',
            'Lottery',
            'LP',
            'Majesty',
            'Malpractice',
            'Money',
            'Money Services',
            'Mutual',
            'Mutual Fund',
            'Official Liquidator',
            'Official Receiver',
            'Official Trustee',
            'Partnership',
            'Permanent Health',
            'Pharmacy',
            'Portfolio',
            'Property and Casualty',
            'Protected Cells',
            'Provident',
            'Prudential',
            'Reassured',
            'Reassurer',
            'Receiver',
            'Receivership',
            'Registry',
            'Registered Agent',
            'Reinsurance',
            'Reinsured',
            'Reinsurer',
            'Re-assured',
            'Re-Assurer',
            'Re-insurance',
            'Risk',
            'Royal',
            'Saving',
            'Savings and Loans',
            'School',
            'Securities',
            'Sovereign',
            'Surety',
            'Suretyship',
            'Third Party Administrator',
            'Transmission',
            'Trust',
            'Trust Company',
            'Trust Corporation',
            'Trustee',
            'Trustee Company',
            'Underwrite',
            'Underwriter',
            'Underwriting',
            'University',
        ],
    },
    // Cayman Islands
    {
        id: 41,
        NameRestricted: [
            'Chamber of Commerce',
            'building society',
            'Limited Duration Company',
            'LDC',
            'Special Economic Zone Company',
            'SEZC',
            'royal',
            'imperial',
            'empire',
            'municipal',
            'chartered',
            'co-operative',
            'assurance',
            'bank',
            'insurance',
            'gaming',
            'lottery',
        ],
    },
    // Mauritius
    {
        id: 139,
        NameRestricted: [
            'Authority',
            'Corporation',
            'Government',
            'Mauritius',
            'National',
            'President',
            'Presidential',
            'Regional',
            'Republic',
            'State',
            'Municipal',
            'Chartered',
            'co-operative',
            'Chamber of Commerce',
        ],
    },
    // Seychelles
    {
        id: 189,
        NameRestricted: [
            'Bank',
            'Building Society',
            'Chartered',
            'Cooperative',
            'Credit Union',
            'Government',
            'Licensing',
            'Municipal',
            'Parliament',
            'Police',
            'Royal',
            'Tribunal',
            'Stock Exchange',
            'Airline',
            'Assurance',
            'Bitcoin',
            'Bureau de Change',
            'Casino',
            'Charity',
            'College',
            'Council',
            'Foundation',
            'Fund',
            'Gambling',
            'Gaming',
            'Hospital',
            'Insurance',
            'Insurer',
            'Lottery',
            'Military',
            'Mutual Fund',
            'Pharmacy',
            'Polytechnic',
            'Reinsurance',
            'School',
            'Securities',
            'Seychelles',
            'Sovereign',
            'State',
            'Trust',
            'Trustee',
            'Union',
            'University',
        ],
    },
    // St. Kitts & Nevis
    {
        id: 205,
        NameRestricted: [
            'Annuity',
            'Assurance',
            'Assurer',
            'Bronze',
            'Captive',
            'Casualty',
            'Charitable',
            'Charity',
            'Charter',
            'FDN',
            'Fiduciary',
            'Finance',
            'Financial',
            'Foundation',
            'Fund',
            'Funding',
            'Gold',
            'Guarantee',
            'Guaranteed',
            'Hedge',
            'Hedge Fund',
            'Indemnity',
            'Insurance',
            'Insurer',
            'Life',
            'Mutual',
            'Mutual Fund',
            'Nevis',
            'Prudential',
            'Queen',
            'Reassurance',
            'Reassurer',
            'Reinsurance',
            'Reinsurer',
            'Risk',
            'Risk Management',
            'Royal',
            'Silver',
            'Surety',
            'Trade',
            'Trust Company',
            'Trust Corporation',
            'Trustee',
            'Trustee Company',
            'Underwrite',
            'Underwriter',
            'Underwriting',
            '‘Precious Metals and Stones’',
            'Association',
            'Authority',
            'Banc',
            'Banco',
            'Bancorp',
            'Bancshare',
            'Bank',
            'Banker',
            'Banque',
            'Betting',
            'Broker',
            'Brokerage',
            'Building Society',
            'Bureau',
            'Casino',
            'Cash',
            'Change',
            'Church',
            'College',
            'Cooperative',
            'Cooperative Society',
            'Council',
            'Credit',
            'Credit Union',
            'Crown',
            'Companies Registry',
            'Company Registry',
            'Commission',
            'Currency',
            'Deposit',
            'Depository',
            'E - Commerce',
            'Exchange',
            'Equities',
            'Federation',
            'Foreign Exchange',
            'Forex',
            'FX',
            'Gambling',
            'Gaming',
            'God',
            'Government',
            'Governor',
            'HMS',
            'Institution',
            'Lending',
            'Loan',
            'Lotto',
            'Lottery',
            'Majesty',
            'Ministry',
            'Monetary',
            'Money',
            'Moneymaker',
            'Money Services',
            'Mortgage',
            'Municipal',
            'National',
            'Offshore',
            'Onshore',
            'Parliament',
            'Partnership',
            'Payment',
            'Police',
            'Register',
            'Registered',
            'Registrar',
            'Regulator',
            'Regulatory',
            'Savings',
            'Scheme',
            'School',
            'Secured',
            'Securities',
            'Sovereign',
            'St.Christopher',
            'St.Christopher and Nevis',
            'St.Kitts',
            'St.Kitts and Nevis',
            'Treasurer',
            'Treasury',
            'University',
        ],
    },
    // UK
    {
        id: 237,
        NameRestricted: [
            'Accounts Commission',
            'Accounts Commission For Scotland',
            'Accredit',
            'Accreditation',
            'Accredited',
            'Accrediting',
            'Adjudicator',
            'Alba',
            'Albannach',
            'Archwilydd Cyffredinol Cymru',
            'Association',
            'Assurance',
            'Assurer',
            'Audit Commission',
            'Audit Office',
            'Audit Scotland',
            'Audit Wales',
            'Auditor General',
            'Auditor General For Northern Ireland',
            'Auditor General For Scotland',
            'Auditor General For Wales',
            'Bana-Phrionnsa',
            'Bana-Prionnsaichean',
            'Banc',
            'Bank',
            'Banking',
            'Banrigrean',
            'Benevolent',
            'Bhanrigh',
            'Bhanrighrean',
            'Bhreatainn',
            'Bhreatanaich',
            'Breatainn',
            'Breatannach',
            'Breatannaich',
            'Brenhines',
            'Brenhiniaeth',
            'Brenhinol',
            'Brenin',
            'Brifysgol',
            'Britain',
            'British',
            'Brydain',
            'Brydeinig',
            'Charitable',
            'Charitable Trust',
            'Charity',
            'Charter',
            'Chartered',
            'Chartered Accountant',
            'Chartered Certified Accountant',
            'Chartered Management Accountant',
            'Chartered Secretary',
            'Chartered Surveyor',
            'Child Maintenance',
            'Child Support',
            'Choimisean',
            'Choimisein',
            'Chomhairle',
            'Chomhairlean',
            'Chomisiwn',
            'Chymraeg',
            'Chymreig ',
            'Chymru',
            'Chyngor',
            'Coimisean',
            'Coimisein',
            'Comhairle',
            'Comhairlean',
            'Comisiwn',
            'Comisiwn Cynulliad Cenedlaethol Cymru',
            'Comisiwn Y Senedd',
            'Commission',
            'Community Benefit Society',
            'Comptroller And Auditor General',
            'Comptroller And Auditor General For Northern Ireland',
            'Co-Operative',
            'Council',
            'Cymraeg',
            'Cymreig',
            'Cymru',
            'Cyngor',
            'Cynulliad Cenedlaethol Cymru',
            'Ddug',
            'Dduges',
            'Dental',
            'Dental Surgeon',
            'Dentist',
            'Dentistry',
            'Deyrnas Gyfunol',
            'Deyrnas Unedig',
            'Duchess',
            'Dug',
            'Duges',
            'Duke',
            'Dywysog',
            'Dywysoges',
            'Ei Fawrhydi',
            'Ei Mawrhydi',
            'England',
            'English',
            'Family Trust',
            'Federation',
            'Financial Conduct Authority',
            'Financial Reporting Council',
            'Financial Reporting Review Panel',
            'Foundation',
            'Frenhines',
            'Frenhiniaeth',
            'Frenhinol',
            'Frenin',
            'Friendly Society',
            'Fund',
            'Gomisiwn',
            'Gwasanaeth Iechyd',
            'Gymraeg',
            'Gymreig',
            'Gymru',
            'Gyngor',
            'Health And Safety Executive',
            'Health And Social Care Board',
            'Health Centre',
            'Health Service',
            'Health Visitor',
            'Her Majesty',
            'His Majesty',
            'H-Oilthighean',
            'House Of Commons',
            'House Of Lords',
            'Hpss',
            'Hsc',
            'Inspectorate',
            'Institute',
            'Institution',
            'Insurance',
            'Insurer',
            'Investment Trust',
            'Judicial Appointment',
            'King',
            'Law Commission',
            'Llywodraeth',
            'Lywodraeth',
            'Medical Centre',
            'Midwife',
            'Midwifery',
            'Mutual',
            'National Assembly For Wales',
            'National Assembly For Wales Commission',
            'National Audit Office',
            'Nghymru',
            'Nhs',
            'Northern Ireland',
            'Northern Ireland Assembly',
            'Northern Ireland Assembly Commission',
            'Northern Ireland Audit Office',
            'Northern Ireland Executive',
            'Northern Irish',
            'Nurse',
            'Nursing',
            'O Prydain',
            'O Prydain Maw',
            'Of Britain',
            'Of England',
            'Of Great Britain',
            'Of Northern Ireland',
            'Of Wales',
            'Oilthigh',
            'Oilthighean',
            'Ombudsman',
            'Ombwdsmon',
            'Parlamaid',
            'Parlamaidean',
            'Parliament',
            'Parliamentarian',
            'Parliamentary',
            'Patent',
            'Patentee',
            'Pharlamaid',
            'Phrifysgol',
            'Phrionnsa',
            'Phrionnsaichean',
            'Phrydain',
            'Phrydeinig',
            'Police',
            'Polytechnic',
            'Post Office',
            'Prifysgol',
            'Prince',
            'Princess',
            'Prionnsa',
            'Prionnsaichean',
            'Prudential Regulation Authority',
            'Prydain',
            'Prydeinig',
            'Queen',
            'Reassurance',
            'Reassurer',
            'Regional Health And Social Care Board',
            'Registered Society',
            'Registrar',
            'Regulator',
            'Reinsurance',
            'Reinsurer',
            'Riaghaltais',
            'Riaghaltas',
            'Riaghaltasan',
            'Royal',
            'Royalty',
            'School Trust',
            'Scotland',
            'Scottish',
            'Senedd',
            'Senedd Cymru',
            'Sheffield',
            'Siambr Fasnach',
            'Siamrau Masnach',
            'Social Service',
            'Society',
            'Special School',
            'Standards',
            'Stock Exchange',
            'Swyddfa Archwilio Cymru',
            'Teyrnas Gyfunol',
            'Teyrnas Unedig',
            'The Governor And Company Of The Bank Of England',
            'Theyrnas Gyfunol',
            'Theyrnas Unedig',
            'Thywysog',
            'Thywysoges',
            'T-Oilthigh',
            'Trade Union',
            'Tribunal',
            'Trust',
            'Tywysog',
            'Tywysoges',
            'Underwrite',
            'Underwriting',
            'Unit Trust',
            'University',
            'Wales',
            'Wales Audit Office',
            'Wasanaeth Iechyd',
            'Welsh',
            'Welsh Assembly Government',
            'Welsh Government',
            'Welsh Parliament',
            'Accounting Council',
            'Actuarial Council',
            'Advocate General',
            'Agency',
            'Assembly',
            'Audit And Assurance Council',
            'Authority',
            'Border Agency',
            'Border Force',
            'Cabinet Office',
            'Cadw',
            'Care Inspectorate Wales',
            'Comeptition And Markets Authority',
            'Corporate Reporting Council',
            'Court',
            'Crime Squad',
            'Criminal Injuries Compensation Authority',
            'Criminal Intelligence Service',
            'Crown Estate',
            'Cssiw',
            'Employment Medical Advisory Service',
            'Financial Reporting Review Panel',
            'Further Education',
            'Health And Social Care',
            'Higher Education',
            'Home Office',
            'Iechyd Cyhoeddus Cymru',
            'Industrial And Provident Society',
            'Intellectual Property Office',
            'Lord Advocate',
            'National Crime Agency',
            'National Health Service',
            'Notarial',
            'Notary',
            'Notary Public',
            'Office Of The Public Guardian',
            'Ordnance Survey',
            'Public Health England',
            'Public Health Northern Ireland',
            'Public Health Ni',
            'Public Health Scotland',
            'Public Health Wales',
            'Revenue And Customs',
            'Scrivener',
            'Scrivener Notary',
            'Select Committee',
            'Serious Organised Crime',
            'Social Security And Child Support',
            'Uk Government',
            'Ukipo',
            'Welsh Government',
            'Welsh Assembly Government',
            'Anzac',
            'Apothecary',
            'Architect',
            'Arts Therapist',
            'Arts Psychotherapist',
            'Bachelor Of Medicine',
            'Biomedical Scientist',
            'Building Society',
            'Chemist',
            'Chiropodist',
            'Chiropractic',
            'Chiropractic Physician',
            'Chiropractic Practitioner',
            'Chiropractioner',
            'Chiropractor',
            'Chiropractor',
            'Citius Altius Fortius',
            'Clinical Psychologist',
            'Clinical Scientist',
            'Commonhold Association',
            'Community Benefit Society',
            'Counselling Psychologist',
            'Credit Union',
            'Dental Practitioner',
            'Dental Surgeon',
            'Dentist',
            'Diagnostic Radiographer',
            'Dietician',
            'Dietitian',
            'Dispensing Chemist',
            'Dispensing Druggist',
            'Dispensing Optician',
            'Doctor Of Medicine',
            'Drama Therapist',
            'Druggist',
            'Educational Psychologist',
            'Enrolled Optician',
            'Faster',
            'Higher',
            'Stronger',
            'Forensic Psychologist',
            'General Practitioner',
            'Geneva Cross',
            'Health Psychologist',
            'Hearing Aid Dispenser',
            'Industrial And Provident Society',
            'Language Therapist',
            'Licentiate In Medicine And Surgery',
            'Music Therapist',
            'Occupational Psychologist',
            'Occupational Therapist',
            'Olympiad',
            'Olympian',
            'Olympic',
            'Operating Department Practitioner',
            'Opthalmic Optician',
            'Opthalmologist',
            'Optician',
            'Optometrist',
            'Orthoptist',
            'Orthotist',
            'Paralympiad',
            'Paralympian',
            'Paralympic',
            'Paramedic',
            'Pharmaceutical Chemist',
            'Pharmaceutical Druggist',
            'Pharmaceutist',
            'Pharmacist',
            'Pharmacy',
            'Physical Therapist',
            'Physician',
            'Physiotherapist',
            'Podiatrist',
            'Practitioner Psychologist',
            'Prosthetist',
            'Radiographer',
            'Red Crescent',
            'Red Cross',
            'Red Lion And Sun',
            'Registered Optician',
            'Registered Psychologist',
            'Registered Society',
            'Social Worker',
            'Solicitor',
            'Speech And Language Therapist',
            'Speech Therapist',
            'Spirit In Motion',
            'Sport And Exercise Psychologist',
            'Surgeon',
            'Therapeutic Radiographer',
            'Vet',
            'Veterinary',
            'Veterinary Practitioner',
            'Veterinary Surgeon',
        ],
    },
    { id: 250, guid: null, NameRestricted: ['Bank', 'Trust', 'University', 'College'] },
];

export { NameRestricted };

export function checkNameRestricted(string, country_id) {
    let isNameRestricted = false;
    let input = string.trim();
    if (input.length > 0) {
        try {
            NameRestricted.some((country) => {
                if (parseInt(country.id) === parseInt(country_id)) {
                    country.NameRestricted.some((item) => {
                        let indexOf = input.toLocaleLowerCase().indexOf(item.toLocaleLowerCase());
                        if (indexOf >= 0) {
                            if (item.length === input.length) {
                                // input trùng tên cấm
                                isNameRestricted = true;
                                return true;
                            } else {
                                // input chứa tên cấm
                                if (indexOf === 0) {
                                    // tên cấm ở đầu input -> check khoảng trắng sau tên cấm
                                    if (
                                        input
                                            .toLocaleLowerCase()
                                            .indexOf(item.toLocaleLowerCase() + ' ') >= 0
                                    ) {
                                        isNameRestricted = true;
                                        return true;
                                    }
                                } else {
                                    if (indexOf + item.length === input.length) {
                                        // tên cấm ở cuối input -> check khoảng trắng trước tên cấm
                                        if (
                                            input
                                                .toLocaleLowerCase()
                                                .indexOf(' ' + item.toLocaleLowerCase()) >= 0
                                        ) {
                                            isNameRestricted = true;
                                            return true;
                                        }
                                    } else {
                                        // tên cấm ở giữa chuỗi -> check khoảng trắng 2 bên tên cấm
                                        if (
                                            input
                                                .toLocaleLowerCase()
                                                .indexOf(' ' + item.toLocaleLowerCase() + ' ') >= 0
                                        ) {
                                            isNameRestricted = true;
                                            return true;
                                        }
                                    }
                                }
                            }
                        }
                    });
                    return true;
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
    return isNameRestricted;
}

export function checkProposedName(countryName, inpLabel, dispatch, proposedName = [], countryId) {
    try {
        let errorMessage = '';
        return this.test(`check-proposed-name`, errorMessage, async function (value, context) {
            const { path, createError } = this;

            let isValid = true;
            if (!value || value.length === 0) {
                isValid = false;
                errorMessage = inpLabel + ' is required';
            } else if (value.length > 0) {
                let proposedNameFound = proposedName.find((item) => item.name === value);
                // Check nếu đã có approve hoặc reject thì không validate nữa
                if ([true, false].includes(proposedNameFound?.is_approve)) {
                    return true;
                }

                if (
                    (parseInt(context?.parent[countryName]) || parseInt(countryId)) === UK_ID &&
                    (document.activeElement.id === 'company_name' ||
                        document.activeElement.id === 'other_name')
                ) {
                    if (dispatch) {
                        let checkNameUKUsable = await dispatch(actionsEnum.checkNameUK(value)).then(
                            (data) => {
                                if (data && data.state && data.data.useable === false) {
                                    return false;
                                }
                            },
                        );
                        if (checkNameUKUsable === false) {
                            isValid = false;
                            errorMessage = 'This ' + inpLabel + ' already exists';
                        }
                    }
                }
                if (
                    checkNameRestricted(
                        value,
                        parseInt(context?.parent[countryName]) || parseInt(countryId),
                    )
                ) {
                    isValid = false;
                    errorMessage = inpLabel + ' contains restricted words or phrases';
                }
            }
            let result = isValid ? true : createError({ path, message: errorMessage });
            return result;
        });
    } catch (error) {
        console.log(error);
    }
}

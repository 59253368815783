import makeStyles from '@mui/styles/makeStyles';
import { shallowEqual, useSelector } from 'react-redux';
import React, { useRef } from 'react';

// import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Review } from '../../../services/components/review/Review';
import { Billing } from '../../components/billing/Billing';
import { PaymentUI } from '../../components/payment-ui/PaymentUI';
import { PaymentFn } from '../../components/payment-fn';
// import personal from

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function Step4({
    body,
    setBody,
    services,
    packageIncorp,
    setActiveStep,
    param,
    setParam,
}) {
    const classes = useStyles();
    // const [selectedValue, setSelectedValue] = React.useState([0]);
    // const [openPaypal, setOpenPaypal] = React.useState(false);
    const [paymentMethod, setPaymentMethod] = React.useState('stripe');
    const [paidAmount, setPaidAmount] = React.useState(0);
    const [form, setForm] = React.useState();
    const formRef = useRef({
        isValid: false,
    })
    // const history = useHistory();
    // const { billingState } = useSelector(
    //     (state) => ({ billingState: state.billings }),
    //     shallowEqual,
    // );
    const { incorporationState, personalState } = useSelector(
        (state) => ({
            incorporationState: state.incorporation,
            personalState: state.personal,
        }),
        shallowEqual,
    );
    // const { authState } = useSelector((state) => ({ authState: state.auth }), shallowEqual);
    let newBillingCompanyName = '';
    if (personalState && personalState.profile && personalState.profile.first_name) {
        newBillingCompanyName = personalState.profile.first_name;
        personalState.profile.last_name &&
            (newBillingCompanyName += ' ' + personalState.profile.last_name);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    console.log('current state valid:', formRef.current)

    return (
        <div>
            <div className="step4">
                <Review
                    packageIncorp={packageIncorp}
                    serviceDiscount={
                        [191, 237, 250].includes(body?.country_id)
                            ? null
                            : incorporationState.serviceDiscount
                    }
                    services={services}
                    body={body}
                    setPaidAmount={setPaidAmount}
                />
                <Billing
                    setBody={setBody}
                    body={body}
                    newBillingCompanyName={newBillingCompanyName}
                    isNewIncorp={true}
                    setForm={setForm}
                    formRef={formRef}
                />
                <PaymentUI
                    countryID={body?.country_id}
                    paymentMethod={paymentMethod}
                    setPaymentMethod={setPaymentMethod}
                />
                <div className="text-center mb-5">
                    <PaymentFn
                        param={param}
                        setParam={setParam}
                        body={body}
                        countryID={body?.country_id}
                        paymentMethod={paymentMethod}
                        isNewIncorp={true}
                        paidAmount={paidAmount}
                        form={form}
                        formRef={formRef}
                    />
                </div>
                <div className={classes.actionsContainer}>
                    <div>
                        <Button size="large" onClick={handleBack} className={classes.button}>
                            Back
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from './_redux/dashboard/dashboardActions';
// import { Dashboard } from '_metronic/_partials';
// export function DashboardPage() {
//     return <Dashboard />;
//
//
// }
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '_metronic/_helpers';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PersonIcon from '@mui/icons-material/Person';
import { TIER } from '../../constants/tier';

export function DashboardPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchDashboard());
    }, []);

    const { dashboard } = useSelector(
        (state) => ({
            dashboard: state.dashboard.entities,
        }),
        shallowEqual,
    );

    const currencyFormat = (number) => {
        if (number === 0) return 0;
        if (number) return number.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        else return 0;
    };

    const [unPaidAmount, setUnPaidAmount] = useState(0);
    useEffect(() => {
        if (
            dashboard &&
            dashboard.figure &&
            dashboard.figure.amount &&
            dashboard.figure.paid_amount
        ) {
            setUnPaidAmount(dashboard.figure.amount - dashboard.figure.paid_amount);
        }
    }, [dashboard, unPaidAmount]);

    return (
        <>
            <div className="page-dashboard">
                <div className={`card card-custom card-stretch gutter-b`}>
                    {/* Body */}
                    <div className="card-body p-0 position-relative overflow-hidden">
                        <div className="card-spacer mx-auto" style={{ maxWidth: '1000px' }}>
                            <div className="row justify-content-center">
                                <div className="p-4" style={{ width: '300px' }}>
                                    <div className="card-spacer__item border-info">
                                        <div className="svg-icon svg-icon-3x svg-icon-info d-flex align-items-center my-2">
                                            <EqualizerIcon className="text-info" />
                                            <span className="h2 ml-3 mb-0">
                                                {dashboard &&
                                                dashboard.figure &&
                                                dashboard.figure.total_orders
                                                    ? dashboard.figure.total_orders
                                                    : 0}
                                            </span>
                                        </div>
                                        <span className="text-info font-weight-bold font-size-h6">
                                            Total Orders
                                        </span>
                                    </div>
                                </div>
                                <div className="p-4" style={{ width: '300px' }}>
                                    <div className="card-spacer__item border-success">
                                        <div className="svg-icon svg-icon-3x svg-icon-success d-flex align-items-center my-2">
                                            <MonetizationOnIcon className="text-success" />
                                            <span className="h2 ml-3 mb-0">
                                                <span>
                                                    {dashboard &&
                                                    dashboard.figure &&
                                                    dashboard.figure.amount
                                                        ? currencyFormat(dashboard.figure.amount)
                                                        : 0}
                                                </span>
                                                <span className="h6"> $US</span>
                                            </span>
                                        </div>
                                        <Link to="/company/invoices?status=[0,1,2,5]">
                                            <span className="text-success font-weight-bold font-size-h6 mt-2">
                                                Total Amount
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="p-4" style={{ width: '300px' }}>
                                    <div className="card-spacer__item border-primary">
                                        <div className="svg-icon svg-icon-3x svg-icon-primary d-flex align-items-center my-2">
                                            <MonetizationOnIcon className="text-primary" />
                                            <span className="h2 ml-3 mb-0">
                                                <span>
                                                    {dashboard &&
                                                    dashboard.figure &&
                                                    dashboard.figure.paid_amount
                                                        ? currencyFormat(
                                                              dashboard.figure.paid_amount,
                                                          )
                                                        : 0}
                                                </span>
                                                <span className="h6"> $US</span>
                                            </span>
                                        </div>
                                        <Link to="/company/invoices?status=[1,2,5]">
                                            <span className="text-primary font-weight-bold font-size-h6">
                                                Total Amount Paid
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="p-4" style={{ width: '300px' }}>
                                    <div className="card-spacer__item border-danger">
                                        <div className="svg-icon svg-icon-3x svg-icon-danger d-flex align-items-center my-2">
                                            <MonetizationOnOutlinedIcon className="text-danger" />
                                            {/* <span className="h2 ml-3 mb-0"><span>{unPaidAmount ? currencyFormat(unPaidAmount): 0}</span><span className="h6"> $US</span></span> */}
                                            <span className="h2 ml-3 mb-0">
                                                <span>
                                                    {dashboard &&
                                                    dashboard.figure &&
                                                    dashboard.figure.unpaid_amount
                                                        ? currencyFormat(
                                                              dashboard.figure.unpaid_amount,
                                                          )
                                                        : 0}
                                                </span>
                                                <span className="h6"> $US</span>
                                            </span>
                                        </div>
                                        <Link to="/company/invoices?status=[0]">
                                            <span className="text-danger font-weight-bold font-size-h6 mt-2">
                                                Total Unpaid Amount
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="p-4" style={{ width: '300px' }}>
                                    <div className="card-spacer__item border-warning">
                                        {dashboard && dashboard.subscription ? (
                                            <div className="svg-icon svg-icon-3x svg-icon-warning d-flex align-items-center my-2">
                                                <img
                                                    src={TIER[dashboard.subscription.id]}
                                                    alt={dashboard?.subscription?.name}
                                                    style={{ width: '3rem' }}
                                                />
                                                <span className="h2 ml-3 mb-0">
                                                    {dashboard?.subscription?.name
                                                        ? dashboard?.subscription?.name
                                                        : 'none'}
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="svg-icon svg-icon-3x svg-icon-warning d-flex align-items-center my-2">
                                                <PersonIcon className="text-warning" />
                                                <span className="h2 ml-3 mb-0">None</span>
                                            </div>
                                        )}
                                        <span className="text-warning font-weight-bold font-size-h6 mt-2">
                                            Current Tier
                                        </span>
                                    </div>
                                </div>
                                <div className="p-4" style={{ width: '300px' }}>
                                    <div className="card-spacer__item border-danger">
                                        <div className="svg-icon svg-icon-3x svg-icon-danger d-flex align-items-center my-2">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    '/media/svg/icons/Design/Layers.svg',
                                                )}
                                            ></SVG>
                                            <span className="h2 ml-3 mb-0">
                                                <span>
                                                    {dashboard &&
                                                    dashboard.figure &&
                                                    dashboard.figure.credit_amount
                                                        ? currencyFormat(
                                                              dashboard.figure.credit_amount,
                                                          )
                                                        : 0}
                                                </span>
                                                <span className="h6"> $US</span>
                                            </span>
                                        </div>
                                        <span className="text-danger font-weight-bold font-size-h6 mt-2">
                                            Credit Remaining
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

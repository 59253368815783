import React from 'react';
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Grid,
    Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMemo, useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { isEmpty } from 'lodash';
import CheckboxField from 'components/form-controls/CheckboxField';
import InputField from 'components/form-controls/InputField';

const useStyles = makeStyles((theme) => ({
    tableCell: {
        textAlign: 'center',
    },
    table: {
        border: '1px solid rgba(0, 0, 0, 0.42)',
    },
    tableContainer: {
        borderRadius: '0px',
    },
    rowInline: {
        minWidth: 100,
        display: 'inline-block',
        marginTop: '15px',
    },
    rowInlineInput: {
        marginTop: '5px',
    },
}));

function TransferShareTableNotLLC({ form, transferor, isForDelete = false }) {
    const classes = useStyles();

    const transferShare = useFieldArray({
        control: form.control,
        name: 'share',
    });

    useEffect(() => {
        transferShare.replace(shares);
    }, [transferor]);

    const shares = useMemo(() => {
        let result = [];
        if (transferor?.CompanyShares) {
            transferor.CompanyShares.forEach((share) => {
                if (
                    share &&
                    share.is_approved &&
                    share.is_valid &&
                    !share.corporation_company_member_id
                ) {
                    let data = {
                        id: share.id,
                        start: share.start_number,
                        end: share.end_number,
                        value_number: share.value,
                        value: undefined,
                        start_number: undefined,
                        end_number: undefined,
                        checked: isForDelete ? true : false,
                        par_value: transferor.par_value,
                        is_received: share.is_received,
                    };
                    result.push(data);
                }
            });
        }

        if (!isEmpty(result)) {
            result.sort(function (a, b) {
                return a.start - b.start;
            });
        }
        return result;
    }, [transferor]);

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table aria-label="simple table" className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCell} width="2%">
                            Select
                        </TableCell>
                        <TableCell className={classes.tableCell} width="20%">
                            ID
                        </TableCell>
                        <TableCell className={classes.tableCell} width="4%">
                            Quantity
                        </TableCell>
                        <TableCell className={classes.tableCell} width="20%">
                            Quantity to transfer
                        </TableCell>
                        <TableCell
                            className={classes.tableCell}
                            width="40%"
                            maxWidth="40%"
                            colSpan={2}
                        >
                            ID to transfer
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transferShare.fields.map((field, index) => {
                        return (
                            <TableRow
                                key={field.id}
                                className="kyc-frm-control"
                                sx={{
                                    '&.MuiTableRow-root': {
                                        verticalAlign: 'top',
                                    },
                                }}
                            >
                                <TableCell className={classes.tableCell} p={3}>
                                    <CheckboxField
                                        name={`share.${index}.checked`}
                                        form={form}
                                        disabled={isForDelete || field.is_received}
                                        sx={{
                                            marginTop: '5px',
                                        }}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <span
                                        className={classes.rowInline}
                                    >{`From ${field?.start} to ${field?.end}`}</span>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <span className={classes.rowInline}>{field.value_number}</span>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Grid
                                        container
                                        minWidth={100}
                                        className={classes.rowInlineInput}
                                    >
                                        <Grid item>
                                            <InputField
                                                key={field.id}
                                                name={`share.${index}.value`}
                                                form={form}
                                                type={'number'}
                                                disabled={isForDelete || field.is_received}
                                                variant="outlined"
                                                customStyle={{
                                                    '& .MuiInputBase-input': {
                                                        padding: '0.65rem 1rem',
                                                    },
                                                }}
                                                onChange={() => {
                                                    form.setValue(
                                                        `share.${index}.start_number`,
                                                        Number(form.watch(`share.${index}.end`)) -
                                                            Number(
                                                                form.watch(`share.${index}.value`),
                                                            ) +
                                                            1,
                                                    );
                                                    form.setValue(
                                                        `share.${index}.end_number`,
                                                        Number(form.watch(`share.${index}.value`)) +
                                                            Number(
                                                                form.watch(
                                                                    `share.${index}.start_number`,
                                                                ),
                                                            ) -
                                                            1,
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Grid
                                        container
                                        minWidth={100}
                                        className={classes.rowInlineInput}
                                    >
                                        <Grid item>
                                            <InputField
                                                name={`share.${index}.start_number`}
                                                label={'From'}
                                                form={form}
                                                type={'number'}
                                                disabled
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                customStyle={{
                                                    '& .MuiInputBase-input': {
                                                        padding: '0.65rem 1rem',
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Grid
                                        container
                                        minWidth={100}
                                        className={classes.rowInlineInput}
                                    >
                                        <Grid item>
                                            <InputField
                                                name={`share.${index}.end_number`}
                                                label={'To'}
                                                form={form}
                                                type={'number'}
                                                disabled
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                                customStyle={{
                                                    '& .MuiInputBase-input': {
                                                        padding: '0.65rem 1rem',
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TransferShareTableNotLLC;

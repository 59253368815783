import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Field } from 'formik';
import { Input } from '_metronic/_partials/controls';
import Select from 'react-select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import { Controller } from 'react-hook-form';
import { getDefaultNationality } from 'app/pages/services/utilities/NationalityHelpers';
import { COMPANY_MEMBER_TYPES } from 'app/pages/services/constants';

/**
 * Panama References
 */

const ReferenceIssue = [
    { id: 1, title: 'Lawyer' },
    { id: 2, title: 'Bank' },
    { id: 3, title: 'CPA' },
    { id: 4, title: 'Business reference' },
];

export default function ReferenceItem({
    body,
    memberIndex,
    referenceIndex,
    values,
    form,
    disabled = false,
}) {
    const member = values[memberIndex];
    const [phonePrefix, setPhonePrefix] = useState({ id: 0, name: '' });
    const currentMember = form.getValues(`members.${memberIndex}`);

    // Khi member chọn choose existing là Contact Person và chưa có data Reference => Active khối Reference
    const disabledChooseContactPerson = useMemo(
        () =>
            !!body?.Company?.CompanyMembers?.find(
                (member) => currentMember?.is_contact_person && member?.id === currentMember?.id,
            )?.CompanyPositions?.filter(
                (position) =>
                    ![
                        4,
                        5,
                        6,
                        COMPANY_MEMBER_TYPES.CONTACT_PERSON.id,
                        currentMember?.company_member_type_id,
                    ].includes(position?.company_member_type_id),
            )?.length,
        [body, memberIndex],
    );

    const disabledChooseExistingMember =
        !currentMember?.is_contact_person && values[memberIndex].isAfterChooseExistingMember;

    const disabledReferenceItem = disabledChooseContactPerson || disabledChooseExistingMember;

    const { countries, geoLooking } = useSelector(
        (state) => ({
            countries: state.enum.countriesCooperation,
            geoLooking: state.enum.geoLooking,
        }),
        shallowEqual,
    );

    useEffect(() => {
        try {
            // set default: api data
            form.setValue(
                `members.${memberIndex}.references.${referenceIndex}.company_member_issuse_reference_id`,
                member.references[referenceIndex].company_member_issuse_reference_id ||
                    ReferenceIssue[0].id,
            );
            if (member.id) {
                form.setValue(
                    `members.${memberIndex}.references.${referenceIndex}.company_member_id`,
                    member.id,
                );
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        try {
            // set PhonePrefix onload component
            if (countries && geoLooking) {
                let nationality = getDefaultNationality(
                    member.references[referenceIndex].phone_country_id,
                );

                setPhonePrefix(nationality);
                form.setValue(
                    `members.${memberIndex}.references.${referenceIndex}.phone_country_id`,
                    nationality.id,
                );

                // set value to change nationality when Choose Existing Member.
                form.setValue(`members.${memberIndex}.stopChangeNationality`, false);
            }
        } catch (error) {
            console.log(error);
        }
    }, [countries, geoLooking, form.watch(`members.${memberIndex}.individual`)]);

    useEffect(() => {
        try {
            // set PhonePrefix AfterChooseExistingMember
            if (member && member.isAfterChooseExistingMember && !member.stopChangeNationality) {
                let nationality = getDefaultNationality(
                    member.references[referenceIndex].phone_country_id,
                );

                setPhonePrefix(nationality);
                form.setValue(
                    `members.${memberIndex}.references.${referenceIndex}.phone_country_id`,
                    nationality.id,
                );

                // set value to stop change nationality -> ko bị reset phonePrefix khi value của member (form) có thay đổi.
                form.setValue(`members.${memberIndex}.stopChangeNationality`, true);
            }
        } catch (error) {
            console.log(error);
        }
    }, [member]);

    return (
        <>
            <div className="reference-item">
                <div className="row">
                    <div className="col-md-6 mb-2 input-line">
                        <label>Name</label>
                        <InputField
                            type="text"
                            name={`members.${memberIndex}.references.${referenceIndex}.name`}
                            form={form}
                            placeholder="Enter Name"
                            hideSuccessHelper
                            disabled={disabledReferenceItem || disabled}
                        />
                    </div>
                    {/* <div className="col-xl-6 mb-xl-7 mb-5 input-line"> */}
                    <div className="col-12 mb-5 input-line">
                        <Controller
                            control={form.control}
                            name={`members.${memberIndex}.references.${referenceIndex}.company_member_issuse_reference_id`}
                            render={() => (
                                <RadioGroup
                                    row
                                    aria-label={`members.${memberIndex}.references.${referenceIndex}.company_member_issuse_reference_id`}
                                    onChange={(e) => {
                                        form.setValue(
                                            `members.${memberIndex}.references.${referenceIndex}.company_member_issuse_reference_id`,
                                            parseInt(e.target.value),
                                        );
                                    }}
                                >
                                    {ReferenceIssue.map((item, key) => {
                                        return (
                                            <FormControlLabel
                                                key={key}
                                                value={item.id}
                                                checked={
                                                    values[memberIndex].references[referenceIndex]
                                                        .company_member_issuse_reference_id ===
                                                    item.id
                                                }
                                                control={<Radio color="secondary" />}
                                                label={item.title}
                                                disabled={disabledReferenceItem || disabled}
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            )}
                        />
                    </div>
                    <div className="col-md-6 mb-7 input-line">
                        <label>Tel No.</label>
                        <div className="phone-group">
                            <div className="phone-code" style={{ zIndex: 1 }}>
                                <SelectField
                                    value={phonePrefix}
                                    form={form}
                                    className="fw-500"
                                    label=""
                                    name={`members.${memberIndex}.references.${referenceIndex}.phone_country_id`}
                                    placeholder="Select..."
                                    options={countries || []}
                                    customStyle={{
                                        '.MuiInputBase-root:before, .MuiInputBase-root:after, .MuiInput-underline:hover:not(.Mui-disabled):before':
                                            {
                                                borderBottom: '0 !important',
                                            },
                                    }}
                                    getOptionLabel={(option) =>
                                        option.country_code + ' +' + option.phone_code
                                    }
                                    hideHelperText={true}
                                    disabled={disabledReferenceItem || disabled}
                                    disableClearable
                                    onChange={(value) => {
                                        if (phonePrefix && phonePrefix.id != value.id) {
                                            setPhonePrefix(value);
                                            form.setValue(
                                                `members.${memberIndex}.references.${referenceIndex}.phone_country_id`,
                                                value.id,
                                            );
                                        }
                                    }}
                                    hideInputHelperText
                                    hideSuccessHelper
                                />
                            </div>
                            <div className="input-line inp-phone">
                                <InputField
                                    name={`members.${memberIndex}.references.${referenceIndex}.phone_number`}
                                    form={form}
                                    hideSuccessHelper
                                    placeholder="Enter phone number"
                                    type="text"
                                    disabled={disabledReferenceItem || disabled}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-7 input-line">
                        <label>Fax No.</label>
                        <InputField
                            form={form}
                            name={`members.${memberIndex}.references.${referenceIndex}.fax`}
                            disabled={disabledReferenceItem || disabled}
                            placeholder="Enter Fax No."
                            hideSuccessHelper
                        />
                    </div>
                    <div className="col-md-6 mb-7 input-line">
                        <label>Email Address</label>
                        <InputField
                            type="text"
                            name={`members.${memberIndex}.references.${referenceIndex}.email`}
                            form={form}
                            placeholder="Enter email address"
                            disabled={disabledReferenceItem || disabled}
                            hideSuccessHelper
                        />
                    </div>

                    <div className="col-md-6 mb-7 input-line">
                        <label>Physical address</label>
                        <InputField
                            type="text"
                            name={`members.${memberIndex}.references.${referenceIndex}.address`}
                            form={form}
                            placeholder="Enter Physical address"
                            hideSuccessHelper
                            disabled={disabledReferenceItem || disabled}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

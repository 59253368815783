import React, { useEffect, useState } from 'react';
import { useSubheader } from '_metronic/layout';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import { getOrderForm } from '../../_redux/order-form/orderFormCrud';
import { toast } from 'react-toastify';

export const NotificationPaymentStripePaypal = (props) => {
    // console.log(props);
    const suhbeader = useSubheader();
    const navigate = useNavigate();
    suhbeader.setTitle(props.success);
    document.body.style.overflow = 'auto';
    const [orderDetail, setOrderDetail] = useState(null);
    const location = useLocation();


    useEffect(() => {
        void handleGetOrderInfo();
    }, []);

    const handleGetOrderInfo = async () => {
        const orderId = location?.state?.data?.order_id;
        if (!orderId || location?.state?.data?.isBanking) return;

        try {
            const { data: { data } } = await getOrderForm(orderId);
            setOrderDetail(data);
        } catch (e) {
            toast.error('Something went wrong!');
        }
    }

    const handleRedirectBanking = (event, order_id) => {
        navigate(`/order-management/order/${order_id}/banking-tool`);
    }
    const handleRedirect = (event) => {
        // navigate('/company/invoices');
        navigate('/order-management');
        // document.body.style.overflow = 'auto';
    };
    const handleRedirectInvoice = (event) => {
        navigate('/company/invoices');
        // navigate('/order-management');
        document.body.style.overflow = 'auto';
    };
    return (
        <div>
            {location?.state?.data?.is_success && (
                <div>
                    <div className="p-md-10 py-5 text-center">
                        <h3 className="mb-5">Successful Order</h3>
                        <h5 className="fw-400 mb-4">
                            Thank you for your order/payment. Your account manager will contact you
                            shortly to proceed further.
                        </h5>
                        {location.state.data.order_id &&
                            <h4 className="h5">
                                Invoice number: <Link to={`/company/invoices?search=${location.state.data.order_id}&page=1`}> INC{location.state.data.order_id}
                            </Link>
                            </h4>}
                    </div>

                    {/* <div className="text-center p-md-8 py-5">
                        <Button
                            size="large"
                            variant="contained"
                            style={{ color: '#fff' }}
                            type="button"
                            color="primary"
                            onClick={handleRedirect}
                        >
                            MANAGE INVOICE
                        </Button>
                    </div> */}
                    <div className="text-center p-md-8 py-5">
                        {
                            location?.state?.data?.isBanking
                                ? (
                                    <Button
                                        size="large"
                                        variant="contained"
                                        style={{ color: '#fff' }}
                                        type="button"
                                        color="primary"
                                        onClick={(e) => handleRedirectBanking(e, location?.state?.data?.order_id_current)}
                                    >
                                        Choose Banking
                                    </Button>
                                )
                                : (
                                    orderDetail?.Task?.Processes?.length ?
                                    <Button
                                        size="large"
                                        variant="contained"
                                        style={{ color: '#fff' }}
                                        type="button"
                                        color="primary"
                                        onClick={handleRedirect}
                                    >
                                        PROCESS ORDER
                                    </Button> : ''
                                )
                        }
                    </div>
                </div>
            )}
            {!location?.state?.data?.is_success && (
                <div>
                    <div className="p-md-10 py-5 text-center">
                        <h3 className="mb-5">Failed Payment</h3>
                        <h5 className="fw-400 mb-4">
                            We are sorry that your payment was not successful yet
                        </h5>
                        {location?.state?.data?.order_id && <h4 className="h5">
                            Invoice number: INC{location?.state?.data?.order_id}
                        </h4>}
                    </div>
                    <div>
                        <div className="text-center p-md-8 py-5">
                            <Button
                                size="large"
                                variant="contained"
                                style={{ color: '#fff' }}
                                type="button"
                                color="primary"
                                onClick={handleRedirectInvoice}
                            >
                                MANAGE INVOICE
                            </Button>
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, Routes, Navigate, Route } from 'react-router-dom';
import { toAbsoluteUrl } from '_metronic/_helpers';
import { ContentRoute } from '_metronic/layout';
import Login from './Login';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import TwoFactor from './TwoFactor';
import '_metronic/_assets/sass/pages/login/classic/login-1.scss';
import moment from 'moment';
import { Box } from '@mui/system';
import '../style/Auth.scss';
import { Typography } from '@mui/material';
import ResendEmail from './ResendEmail';

export function AuthPage(props) {
    return (
        <Box className="auth-container container-fluid p-0">
            <Box className="row h-100 m-0 align-items-center position-relative">
                <Box className="auth-banner col-lg-7 p-0">
                    <Box className="banner-text">
                        <Typography className="banner-title title">Business Startup</Typography>
                        <Typography className="banner-sub-title title">
                            Welcome to BBCIncorp Client Portal
                        </Typography>
                    </Box>
                    <img className="lucky-money" srcSet="/media/bg/bao-li-xi.png" />
                </Box>
                <Box className="auth-form col-lg-3 offset-lg-1 d-flex flex-column ">
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/registration" element={<Registration />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/confirm" element={<TwoFactor />} />
                        <Route path="/resent-email" element={<ResendEmail />} />
                        <Route path="*" element={<Navigate replace to={'/auth/login'} />} />
                    </Routes>
                </Box>
                <Box className="copyright col-lg-5 offset-lg-7 mb-2 mb-lg-0">
                    <div>
                        <Typography className="copyright-text">
                            &copy; {moment().get('year')} BBCIncorp
                        </Typography>
                    </div>
                    <Box className="copyright-nav">
                        <a
                            href="https://bbcincorp.com/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy
                        </a>
                        <a
                            href="https://bbcincorp.com/legal-of-agreement"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Legal
                        </a>
                        <a
                            href="https://bbcincorp.com/contact-us"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Contact
                        </a>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

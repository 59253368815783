import React, { useEffect } from 'react';
import InputField from 'components/form-controls/InputField';
import RadioField from 'components/form-controls/RadioField';

export default function BankAccount({ form }) {
    useEffect(() => {
        if (form.watch('bank_account') === '91') {
            form.trigger('bank_account_other');
        }
    }, [form.watch('bank_account')]);

    return (
        <>
            <p className="fw-500">What will the bank account be used mostly for?</p>
            <div>
                <RadioField
                    form={form}
                    aria-label="bank_account"
                    name="bank_account"
                    radioGroupProps={{
                        sx: { flexDirection: 'row' },
                    }}
                    options={[
                        { id: '88', name: 'Investments' },
                        { id: '89', name: 'Operational account' },
                        { id: '90', name: 'Holding' },
                        { id: '91', name: 'Others' },
                    ]}
                />

                {form.watch('bank_account') === '91' && (
                    <div className="row">
                        <div className="col-xl-6 mb-7 input-line">
                            <label>Other</label>
                            <InputField
                                form={form}
                                name="bank_account_other"
                                placeholder="Please specify"
                                showIcon
                                hideSuccessHelper
                                onChange={(e) => e.target.value?.length === 1 && form.trigger('bank_account_other')}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

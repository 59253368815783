import { toast } from 'react-toastify';
import * as requestFromServer from './enumCrud';
import { enumSlice, callTypes } from './enumSlice';

const { actions } = enumSlice;

export const fetchCountriesHasService = () => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getAllCountryHasService()
        .then((response) => {
            const data = response.data.data;
            dispatch(actions.countriesHasServiceFetched({ data: data }));
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't find Countries Has Service";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            //error not
        });
};

export const fetchCountriesNonRestricted = () => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getCountriesNonRestricted()
        .then((response) => {
            const data = response.data.data;
            dispatch(actions.countriesCooperation({ data: data }));
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't find Country Cooperation";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            //error not
        });
};

export const fetchCountries = () => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getCountries()
        .then((response) => {
            const data = response.data.data;
            dispatch(actions.countries({ data: data }));
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't find Country";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            //error not
        });
};

export const fetchSupportCasePriority = () => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getSupportCasePriority()
        .then((response) => {
            const data = response.data.data;
            dispatch(actions.supportCasePriority({ data: data }));
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't find support case priority";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            //error not
        });
};

export const fetchSupportCaseStatus = () => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getSupportCaseStatus()
        .then((response) => {
            const data = response.data.data;
            dispatch(actions.supportCaseStatus({ data: data }));
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't find support case status";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            //error not
        });
};

export const fetchSupportCaseType = () => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getSupportCaseType()
        .then((response) => {
            const data = response.data.data;
            dispatch(actions.supportCaseType({ data: data }));
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't find support case type";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            //error not
        });
};

// == business activity
export const fetchBusinessActivity =
    (queryParams, isCorporationMember = false) =>
    (dispatch) => {
        dispatch(actions.startCall({ callType: callTypes.list }));
        return requestFromServer
            .getBusinessActivity(queryParams)
            .then((response) => {
                const { data } = response.data;
                dispatch(
                    actions.businessActivity({
                        data: data,
                        countryId: queryParams,
                        isCorporationMember,
                    }),
                );
                return response.data;
            })
            .catch((error) => {
                error.clientMessage = "Can't find business activities";
                toast.error("Can't find business activities!");
                dispatch(actions.catchError({ error, callType: callTypes.list }));
            });
    };

// getGeoLooking
export const fetchGeoLooking = () => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getGeoLooking()
        .then((response) => {
            const data = response.data.data;
            dispatch(actions.geoLooking({ data: data }));
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't find Geographical location!";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            //error not
        });
};

// checkNameUK
export const checkNameUK = (input) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .checkNameUK(input)
        .then((response) => {
            const data = response.data;
            // dispatch(actions.useableNameUK({ data: data }));
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't check Company name!";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            //error not
        });
};

export const getCurrenciesAction = () => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer.getCurrencies().then((response) => {
        const data = response.data;
        dispatch(actions.currencies({ data: data }));
        return data;
    });
};

export const getServiceType = (params) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getServiceType(params)
        .then((response) => {
            const data = response.data.data;
            dispatch(actions.serviceType({data: data}))
            return data;
        })
}

export const getFileType = () =>(dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getFileType()
        .then((response) => {
            const data = response.data.data;
            dispatch(actions.fileTypes({data: data}))
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't get file type!";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
}
export const getWebsites = () => (dispatch) => {
    return requestFromServer.getWebsites().then((response) => {
        const data = response.data.data;
        dispatch(actions.websites({ data: data }));
    });
};
import React from 'react';
import { Card, CardBody } from '_metronic/_partials/controls';
import { KYCProvider } from './kyc-context';
import KYCGraph from './graphs/KYCGraph';
import DetailDialog from './detail-dialog/DetailDialog';
import 'app/pages/services/components/kyc-elements/kyc.scss';

export default function KYCPage({ onNext }) {
    return (
        <>
            <KYCProvider>
                <DetailDialog />
                <Card>
                    <CardBody>
                        <p className="kyc-note mb-1">
                            <b>Note*:</b>
                            <i>
                                We can support to notarize passport &amp; address proof by CPA in
                                Hong Kong for each member with USD 50.00.
                            </i>
                        </p>
                        <p className="kyc-note">
                            <b>Note**:</b>
                            <i>
                                Address proof can be an Identity Card/ Driver license/ Bank
                                reference/ Bank statement/ Utility bill showing holder's full name
                                and address in English.
                            </i>
                        </p>
                        <KYCGraph onNext={onNext} />
                    </CardBody>
                </Card>
            </KYCProvider>
        </>
    );
}

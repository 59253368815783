import React, { useEffect, useState } from 'react';
import ReactFlow, { Background, ReactFlowProvider } from 'react-flow-renderer';

import { useSelector, shallowEqual } from 'react-redux';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import GraphControl from 'app/pages/services/components/kyc-elements/GraphControl';
import Swal from 'sweetalert2';
import { useKYCContext } from '../kyc-context';

export default function KYCGraph({ onNext }) {
    const { elements, setElements, openDetailModal, setGraphElement } = useKYCContext();
    const [submitting, setSubmitting] = useState(false);
    const [firstLoaded, setFirstLoaded] = useState(false);
    const [viewLoaded, setViewLoaded] = useState(false);
    const { banking } = useSelector((state) => {
        return { banking: state.banking };
    }, shallowEqual);
    useEffect(() => {
        if (banking.orderInfo && elements.length === 0 && !firstLoaded) {
            setGraphElement(banking.orderInfo);
            setFirstLoaded(true);
        }
    }, [
        elements.length,
        firstLoaded,
        openDetailModal,
        banking,
        banking.orderInfo,
        setElements,
        setGraphElement,
    ]);

    /**
     * Check all file uploaded.
     * @returns true if all member's files are uploaded.
     */
    const isAllMemberUploaded = () => {
        const memberTemps = elements.filter((member) => member.is_member_node);
        let result = memberTemps.length > 0;
        for (const member of memberTemps) {
            const fileTemps = Array.isArray(member.data.value.Files)
                ? member.data.value.Files
                : Object.values(member.data.value.Files || {});
            if (fileTemps.length === 0) {
                result = false;
                break;
            }
        }
        return result;
    };

    // const handleSubmit = () => {
    //     setSubmitting(true);
    //     submitStep(process).then(() => {
    //         setNewProcessByData()
    //             .then(() => {
    //                 Swal.fire({
    //                     position: 'center',
    //                     icon: 'success',
    //                     title: 'Successful!',
    //                     showConfirmButton: false,
    //                     timer: 1500,
    //                 });
    //             })
    //             .finally(() => {
    //                 setSubmitting(false);
    //             });
    //     });
    // };
    // console.log(elements)
    return (
        <>
            <div className="kyc-graph">
                {submitting ? (
                    <div
                        className="position-absolute bg-light w-100 h-100"
                        style={{ top: 0, left: 0, zIndex: 100, opacity: 0.5 }}
                    ></div>
                ) : (
                    ''
                )}
                <ReactFlowProvider>
                    <ReactFlow
                        elements={elements}
                        nodesDraggable={false}
                        style={{ position: 'unset' }}
                        minZoom={0}
                        maxZoom={4}
                        id="divToPrint"
                    >
                        <Background
                            style={{ backgroundColor: '#F0F4F7', borderRadius: 8 }}
                            color="#d6d6d6"
                            gap={10}
                            variant="dots"
                            size={1}
                        />
                        <GraphControl
                            viewLoaded={viewLoaded}
                            setViewLoaded={setViewLoaded}
                            companyName={banking?.orderInfo?.Company?.name || 'company'}
                        />
                    </ReactFlow>
                </ReactFlowProvider>
            </div>
            <div className="py-5 d-flex align-items-center justify-content-end">
                {submitting ? <CircularProgress size={20} color="primary" className="mr-5" /> : ''}
                <Button
                    className="text-white"
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => onNext()}
                    disabled={submitting || !isAllMemberUploaded()}
                >
                    Next
                </Button>
            </div>
        </>
    );
}

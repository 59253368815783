import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

const TitlePackageOffshore = [
    'Unlimited Business Name Check',
    'Document Preparation & Filling',
    'Company Formation',
    'Local Company Secretary for 1st year',
    'Local Registered Address for 1st year',
    'Digital corporate documents',
];

const TitlePackageHongKong = [
    'Unlimited Business Name Check',
    'Document Preparation & Filling',
    'Company Formation',
    'All Government fees in first year',
    'Local Company Secretary for first year',
    'Local Registered Address for first year included mails handle',
    'Significant Controller Register and Designated Representative',
    'Digital corporate documents',
    'Digital Seal',
    'One set of documents certified by CPA',
];

const singaporePackages = [
    {
        id: '1',
        label: 'Incorporation',
        groupNames: [
            'COMPANY FORMATION',
            'COMPANY SECRETARY (1 year)',
            'BANKING SUPPORT',
            'WORK PASS',
            'REQUIREMENT FOR NOMINEE SERVICE',
        ],
        body: [
            // Company formation
            {
                id: '0',
                group: 'COMPANY FORMATION',
                title: <p>Unlimited Name Availability Check</p>,
                localLite: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                localStandard: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerBasic: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            {
                id: '1',
                group: 'COMPANY FORMATION',
                title: <p>Document Preparation & Filing</p>,
                localLite: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                localStandard: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerBasic: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            {
                id: '2',
                group: 'COMPANY FORMATION',
                title: <p>Company Incorporation Process</p>,
                localLite: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                localStandard: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerBasic: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            {
                id: '2',
                group: 'COMPANY FORMATION',
                title: <p>Company Name Reservation</p>,
                localLite: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                localStandard: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerBasic: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            {
                id: '3',
                group: 'COMPANY FORMATION',
                title: <p>Government Registration Fee</p>,
                localLite: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                localStandard: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerBasic: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            {
                id: '4',
                group: 'COMPANY FORMATION',
                title: <p>Lifetime Support/ Instant Response</p>,
                localLite: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                localStandard: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerBasic: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            {
                id: '5',
                group: 'COMPANY FORMATION',
                title: <p>Digital Corporate Documents</p>,
                localLite: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                localStandard: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerBasic: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            {
                id: '6',
                group: 'COMPANY SECRETARY (1 year)',
                title: <p>Annual filing and AGM preparation</p>,
                localLite: <p>None</p>,
                localStandard: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerBasic: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            // COMPANY SECRETARY (1 year)
            {
                id: '7',
                group: 'COMPANY SECRETARY (1 year)',
                title: <p>Annual filing government fee</p>,
                localLite: <p>None</p>,
                localStandard: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerBasic: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            {
                id: '8',
                group: 'COMPANY SECRETARY (1 year)',
                title: <p>Local Registered Address with mail handling</p>,
                localLite: <p>None</p>,
                localStandard: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerBasic: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            // NOMINEE DIRECTOR (No deposit required)
            {
                id: '9',
                group: 'COMPANY SECRETARY (1 year)',
                title: (
                    <h6 className="font-weight-bolder">
                        NOMINEE DIRECTOR <span>(No deposit required)</span>
                    </h6>
                ),
                localLite: <p>None</p>,
                localStandard: <p>None</p>,
                foreignerBasic: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            // BANKING SUPPORT
            {
                id: '10',
                group: 'BANKING SUPPORT',
                title: (
                    <p>
                        Bank account opening assistance with
                        <br />
                        Singapore banks in our supported banks list
                    </p>
                ),
                localLite: <p>None</p>,
                localStandard: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerBasic: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            // WORK PASS
            {
                id: '11',
                group: 'WORK PASS',
                title: <p>Application of Employment Pass</p>,
                localLite: <p>None</p>,
                localStandard: <p>None</p>,
                foreignerBasic: <p>None</p>,
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            // REQUIREMENT FOR NOMINEE SERVICE
            {
                id: '12',
                group: 'REQUIREMENT FOR NOMINEE SERVICE',
                title: (
                    <div>
                        <p>* 2 options:</p>
                        <p>1. Nominee review US$24/month billed yearly</p>
                        <p>2. Accounting package from US$91/month billed yearly:</p>
                        <ul>
                            <li>Xero cloud accounting</li>
                            <li>Corppass registration</li>
                            <li>Monthly accounting review</li>
                            <li>Monthly preparation of FSs</li>
                            <li>
                                Annual tax filing (preparation and filing of ECI and Form C/C-S)
                            </li>
                            <li>Annual revenue under S$70k (plan upgradable)</li>
                        </ul>
                    </div>
                ),
                localLite: <p>None</p>,
                localStandard: <p>None</p>,
                foreignerBasic: <p>Required</p>,
                foreignerUltra: <p>None</p>,
            },
        ],
    },
    {
        id: '2',
        label: 'Renewal',
        groupNames: ['COMPANY SECRETARY (1 year)', 'REQUIREMENT FOR NOMINEE SERVICE'],
        body: [
            // Company formation
            {
                id: '0',
                group: 'COMPANY SECRETARY (1 year)',
                title: <p>Annual filing and AGM preparation</p>,
                localLite: <p>None</p>,
                localStandard: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerBasic: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            {
                id: '1',
                group: 'COMPANY SECRETARY (1 year)',
                title: <p>Annual filing government fee</p>,
                localLite: <p>None</p>,
                localStandard: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerBasic: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            {
                id: '2',
                group: 'COMPANY SECRETARY (1 year)',
                title: <p>Local Registered Address with mail handling</p>,
                localLite: <p>None</p>,
                localStandard: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerBasic: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            //NOMINEE DIRECTOR (No deposit required)
            {
                id: '3',
                group: 'COMPANY SECRETARY (1 year)',
                title: (
                    <h6 className="font-weight-bolder">
                        NOMINEE DIRECTOR <span>(No deposit required)</span>
                    </h6>
                ),
                localLite: <p>None</p>,
                localStandard: <p>None</p>,
                foreignerBasic: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
                foreignerUltra: (
                    <p>
                        <i className="fas fa-check-circle"></i>
                    </p>
                ),
            },
            {
                id: '4',
                group: 'REQUIREMENT FOR NOMINEE SERVICE',
                title: (
                    <div>
                        <p>* 2 options:</p>
                        <p>1. Nominee review US$24/month billed yearly</p>
                        <p>2. Accounting package from US$91/month billed yearly:</p>
                        <ul>
                            <li>Xero cloud accounting</li>
                            <li>Corppass registration</li>
                            <li>Monthly accounting review</li>
                            <li>Monthly preparation of FSs</li>
                            <li>
                                Annual tax filing (preparation and filing of ECI and Form C/C-S)
                            </li>
                            <li>Annual revenue under S$70k (plan upgradable)</li>
                        </ul>
                    </div>
                ),
                localLite: <p>None</p>,
                localStandard: <p>None</p>,
                foreignerBasic: <p>Required</p>,
                foreignerUltra: <p>Required</p>,
            },
        ],
    },
];

export function CheckPackageDetail() {
    return {
        __html: '<p><i class="fas fa-check-circle"></i></p><p><i class="fas fa-check-circle"></i></p><p><i class="fas fa-check-circle"></i></p>',
    };
}

export function checkSingaporeSixMonth() {
    return {
        __html: '<p><i class="fas fa-check-circle"></i></p><p><i class="fas fa-check-circle"></i></p><p><i class="fas fa-check-circle"></i><br/>(6 months)</p>',
    };
}

function ComparePackageSingapore() {
    const packageDefault =
        Array.isArray(singaporePackages) && singaporePackages.length > 0
            ? singaporePackages[0]
            : null;
    const [value, setValue] = React.useState(packageDefault?.id);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <TabContext value={value}>
            <Tabs value={value} onChange={handleChange} centered>
                {packageDefault &&
                    singaporePackages.map((singaporePackage) => (
                        <Tab
                            label={singaporePackage.label}
                            key={singaporePackage.id}
                            value={singaporePackage.id}
                        />
                    ))}
            </Tabs>
            {packageDefault &&
                singaporePackages.map((singaporePackage) => (
                    <TabPanel key={singaporePackage.id} value={singaporePackage.id}>
                        <div className="package-detail">
                            <div className="row">
                                <div className="offset-5 col-7">
                                    <div className="wrap-title">
                                        <h4 className="title">Local Lite</h4>
                                        <h4 className="title">Local Standard</h4>
                                        <h4 className="title">Foreigner Basic</h4>
                                        <h4 className="title">Foreigner Ultra</h4>
                                    </div>
                                </div>
                            </div>
                            {singaporePackage.groupNames.map((groupName) => (
                                <div key={groupName}>
                                    <h6
                                        style={{ marginBottom: '12px' }}
                                        className="font-weight-bolder"
                                    >
                                        {groupName}
                                    </h6>
                                    {singaporePackage.body
                                        .filter((item) => item.group === groupName)
                                        .map((singPackage, index) => (
                                            <div key={index} className="row">
                                                <div className="col-5">
                                                    {singPackage.title}
                                                    <ul>
                                                        {singPackage.children &&
                                                            singPackage.children.map(
                                                                (singChild, childIdx) => (
                                                                    <li key={`child-${childIdx}`}>
                                                                        {singChild}
                                                                    </li>
                                                                ),
                                                            )}
                                                    </ul>
                                                </div>
                                                <div className="col-7 d-flex">
                                                    <div className="text-center value">
                                                        {singPackage.localLite}
                                                    </div>
                                                    <div className="text-center value">
                                                        {singPackage.localStandard}
                                                    </div>
                                                    <div className="text-center value">
                                                        {singPackage.foreignerBasic}
                                                    </div>
                                                    <div className="text-center value">
                                                        {singPackage.foreignerUltra}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                ))}
        </TabContext>
    );
}

export function ContentDetailPackage(props) {
    switch (props.value) {
        // hong kong
        case 98:
            return (
                <>
                    <div className="package-detail">
                        <div className="row">
                            <div className="offset-6 col-6">
                                <div className="wrap-title">
                                    <h4 className="title">basic</h4>
                                    <h4 className="title">essential</h4>
                                    <h4 className="title">premium</h4>
                                </div>
                            </div>
                        </div>
                        <h6 style={{ marginBottom: '12px' }}>COMPANY FORMATION</h6>
                        {TitlePackageHongKong.map((title, index) => (
                            <div key={index} className="row">
                                <div className="col-6">
                                    <p>{title}</p>
                                </div>
                                <div
                                    dangerouslySetInnerHTML={CheckPackageDetail()}
                                    className="col-6 d-flex"
                                />
                            </div>
                        ))}
                        <h6 style={{ margin: '15px 0 12px 0' }}>BANK ACCOUNT APPLICATION</h6>
                        <div className="row">
                            <div className="col-6">
                                <p>
                                    Assistance in opening Bank account including Cert. of Incumbency
                                    if required
                                </p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>None</p>
                                <p>Airwallex business account</p>
                                <p>Up to 5 bank applications</p>
                            </div>
                        </div>
                    </div>
                </>
            );
        // singapore
        case 191:
            return <ComparePackageSingapore />;
        // delaware
        case 250:
            return (
                <div className="package-detail">
                    <div className="row">
                        <div className="offset-6 col-6">
                            <div className="wrap-title">
                                <div>
                                    <h4 className="title">Basic</h4>
                                    <p className="small">Delaware company</p>
                                </div>
                                <div>
                                    <h4 className="title">Standard</h4>
                                    <p className="small">
                                        Delaware company w/ supporting documents to open bank
                                        account
                                    </p>
                                </div>
                                <div>
                                    <h4 className="title">Premium</h4>
                                    <p className="small">Delaware company w/ Corporate Kit</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* content COMPANY FORMATION */}
                    <h6 style={{ marginBottom: '12px' }}>COMPANY FORMATION</h6>
                    <div className="group-content">
                        <div className="row">
                            <div className="col-6">
                                <p>Name check and clearance</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Document Preparation & Filing</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>State filing fee</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Certificate of Formation / Incorporation</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>1 year registered agent</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>1 year local registered address</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Digital corporate docs</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Digital company seal</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Operating Agreements/Bylaws</p>
                                <p className="small">Required to open US bank account</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Lifetime support with Dedicated Relationship Manager</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>EIN application (Employer identification number)</p>
                                <p className="small">
                                    Required to open US bank account and employ local labor, bank
                                    account, employ labour, file and pay tax
                                </p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>None</p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Physical LLC/Corporate Kit with:</p>
                                <ul>
                                    <li>Gold Embossed Binder</li>
                                    <li>Company Guidebook</li>
                                    <li>Operating Agreement/By-laws</li>
                                    <li>Meeting Minute Book</li>
                                    <li>Organizational Resolutions</li>
                                    <li>Membership/Stock Certificates</li>
                                    <li>Membership/Stock Ledger</li>
                                    <li>Corporate Seal</li>
                                </ul>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>None</p>
                                <p>None</p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>International Courier fee</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>None</p>
                                <p>None</p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Certified COF/COI with Gold Seal or with Apostille</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>None</p>
                                <p>None</p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Notarized Statement of Authorized Person</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>None</p>
                                <p>None</p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Bank Resolution Form</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>None</p>
                                <p>None</p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>LLC/Corporation Essential Documents</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>None</p>
                                <p>None</p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* content EXPRESS FORMATION */}
                    <h6 style={{ margin: '15px 0 12px 0' }}>EXPRESS FORMATION</h6>
                    <div className="group-content">
                        <div className="row">
                            <div className="col-6">
                                <p>Expedited filing within 24 hours</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>None</p>
                                <p>None</p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* content BUSINESS ACCOUNT */}
                    <h6 style={{ margin: '15px 0 12px 0' }}>BUSINESS ACCOUNT</h6>
                    <div className="group-content">
                        <div className="row">
                            <div className="col-6">
                                <p>Wise/Payoneer/Mercury business banking</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>None</p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* content MERCHANT ACCOUNT */}
                    <h6 style={{ margin: '15px 0 12px 0' }}>MERCHANT ACCOUNT</h6>
                    <div className="group-content">
                        <div className="row">
                            <div className="col-6">
                                <p>Guideline on Applying Merchant Account</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>None</p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* content PRIVILEGES FROM TAXHUB */}
                    <h6 style={{ margin: '15px 0 12px 0' }}>PRIVILEGES FROM TAXHUB</h6>
                    <div className="group-content">
                        <div className="row">
                            <div className="col-6">
                                <ul>
                                    <li>10% discount code</li>
                                    <li>Free 30-min CPA consultation</li>
                                </ul>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        // vietnam
        case 244:
            return (
                <div className="package-detail" data-entity-type-id={props.entity_type_id}>
                    <div className="row">
                        {/* <div className="offset-6 col-6"> */}
                        <div className="offset-5 col-7">
                            <div className="wrap-title">
                                <div>
                                    <h4 className="title">JVC</h4>
                                    <p className="small">
                                        Establishment of a Joint Venture Company
                                    </p>
                                </div>
                                <div>
                                    <h4 className="title">WFOE</h4>
                                    <p className="small">
                                        Establishment of a Wholly-Owned Foreign Enterprise
                                    </p>
                                </div>
                                <div>
                                    <h4 className="title">Branch</h4>
                                    <p className="small">Establishment of a Branch</p>
                                </div>
                                <div>
                                    <h4 className="title">RO</h4>
                                    <p className="small">
                                        Establishment of a Representative Office
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* content COMPANY FORMATION */}
                    {/* <h6 style={{ marginBottom: "12px" }}>COMPANY FORMATION</h6> */}
                    <div className="group-content">
                        <div className="row">
                            <div className="col-6">
                                <p>Name availability check</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Document Preparation & Filing</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Business/Enterprise Registration Certificate (ERC)</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Investment Registration Certificate (IRC)</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>None</p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>None</p>
                                <p>None</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Local representative</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Local registered address for 1st year</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Public announcement of company registration</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Tax registration after incorporation</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p>Company seal</p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                                <p>None</p>
                                <p>
                                    <i className="fas fa-check-circle"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        // UK
        case 237:
            return (
                <>
                    {props.entity_type_id === 7 && ( // LLP
                        <div className="package-detail">
                            <div className="row">
                                <div className="offset-6 col-6">
                                    <div className="wrap-title">
                                        <h4 className="title">basic</h4>
                                        <h4 className="title">standard</h4>
                                    </div>
                                </div>
                            </div>
                            <h6 style={{ marginBottom: '12px' }}>COMPANY FORMATION</h6>
                            <div className="group-content">
                                <div className="row">
                                    <div className="col-6">
                                        <p>Name availability check</p>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>Government filing fee</p>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>01 year registered address</p>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>01 year service address</p>
                                        <span className="small">Applicable to 02 members only</span>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>01 year business address</p>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>None</p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>Digital company docs</p>
                                        <ul>
                                            <li>Copies of COI, M&AA and Share certificates</li>
                                            <li>Copy of company register</li>
                                        </ul>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>Printed company docs</p>
                                        <ul>
                                            <li>Printed COI & share certificates</li>
                                            <li>Printed & Bound M&AA</li>
                                        </ul>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>None</p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>International courier fee</p>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>None</p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <h6 style={{ margin: '15px 0 12px 0' }}>BUSINESS ACCOUNT</h6>
                            <div className="row">
                                <div className="col-6">
                                    <p>Multi-currency business account with Wise/Payoneer</p>
                                </div>
                                {/* <div dangerouslySetInnerHTML={CheckPackageDetail()} className="col-6 d-flex" /> */}

                                <div className="col-6 d-flex align-items-center">
                                    <p>
                                        <i className="fas fa-check-circle"></i>
                                    </p>
                                    <p>
                                        <i className="fas fa-check-circle"></i>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    {props.entity_type_id === 10 && ( // LTD
                        <div className="package-detail">
                            <div className="row">
                                <div className="offset-6 col-6">
                                    <div className="wrap-title">
                                        <h4 className="title">basic</h4>
                                        <h4 className="title">standard</h4>
                                        <h4 className="title">Premium</h4>
                                    </div>
                                </div>
                            </div>
                            <h6 style={{ marginBottom: '12px' }}>COMPANY FORMATION</h6>
                            <div className="group-content">
                                <div className="row">
                                    <div className="col-6">
                                        <p>Name availability check</p>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>Government filing fee</p>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>01 year registered address</p>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>01 year service address</p>
                                        <span className="small">Applicable to 01 members only</span>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>01 year business address</p>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>None</p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>Digital company docs</p>
                                        <ul>
                                            <li>Copies of COI, M&AA and Share certificates</li>
                                            <li>Copy of company register</li>
                                        </ul>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>Printed company docs</p>
                                        <ul>
                                            <li>Printed COI & share certificates</li>
                                            <li>Printed & Bound M&AA</li>
                                        </ul>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>None</p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>International courier fee</p>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>None</p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>VAT registration</p>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>None</p>
                                        <p>None</p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>Free company cancellation within 12 months</p>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <p>None</p>
                                        <p>None</p>
                                        <p>
                                            <i className="fas fa-check-circle"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <h6 style={{ margin: '15px 0 12px 0' }}>BUSINESS ACCOUNT</h6>
                            <div className="row">
                                <div className="col-6">
                                    <p>Multi-currency business account with Wise/Payoneer</p>
                                </div>
                                {/* <div dangerouslySetInnerHTML={CheckPackageDetail()} className="col-6 d-flex" /> */}

                                <div className="col-6 d-flex align-items-center">
                                    <p>
                                        <i className="fas fa-check-circle"></i>
                                    </p>
                                    <p>
                                        <i className="fas fa-check-circle"></i>
                                    </p>
                                    <p>
                                        <i className="fas fa-check-circle"></i>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            );
        // offshore
        default:
            return (
                <>
                    <div className="package-detail">
                        <div className="row">
                            <div className="offset-6 col-6">
                                <div className="wrap-title">
                                    <h4 className="title">basic</h4>
                                    <h4 className="title">standard</h4>
                                    <h4 className="title">premium</h4>
                                </div>
                            </div>
                        </div>
                        <h6 style={{ marginBottom: '12px' }}>COMPANY FORMATION</h6>
                        {TitlePackageOffshore.map((title, index) => (
                            <div key={index} className="row">
                                <div className="col-6">
                                    <p>{title}</p>
                                </div>
                                <div
                                    dangerouslySetInnerHTML={CheckPackageDetail()}
                                    className="col-6 d-flex"
                                />
                            </div>
                        ))}
                        <h6 style={{ margin: '15px 0 12px 0' }}>BANK ACCOUNT APPLICATION</h6>
                        <div className="row">
                            <div className="col-6">
                                <p>
                                    Assistance in opening Bank account including Cert. of Incumbency
                                    if required
                                </p>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                                <p>None</p>
                                <p>1 bank application</p>
                                <p>Up to 5 bank applications</p>
                            </div>
                        </div>
                    </div>
                </>
            );
    }
}

import React, { useEffect, useMemo, useState } from 'react';
import { isArray } from 'lodash';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import clsx from 'clsx';

const ReactSelectField = ({
    form,
    name = 'default',
    multiple = false,
    id,
    idKey = 'id',
    options = [],
    defaultValue,
    label = '',
    onChange,
    helperText,
    disableClearable = true,
    hideHelperText = false,
    loading,
    disabled,
    variant = 'outlined',
    ...props
}) => {
    const [option, setOption] = useState(form.getValues(name));
    const [isFirst, setFirst] = useState(false);

    useEffect(() => {
        setOption(form.getValues(name));
    }, [form.getValues(name)]);

    useEffect(() => {
        if (options?.length) setFirst(true);
    }, [options]);

    const handleValue = (values) => {
        if (options) {
            if (props.freeSolo) {
                return values;
            }

            if (multiple) {
                if (!values) values = [];
                return options.filter((value) => {
                    if (!isArray(values)) {
                        values = JSON.parse(values);
                    }
                    return values.includes(typeof value === 'object' ? value[idKey] : value);
                });
            } else {
                let value = options.find(
                    (option) => values === (typeof option === 'object' ? option[idKey] : option),
                );
                return typeof value !== 'undefined' ? value : null;
            }
        }
    };

    const value = useMemo(() => {
        return handleValue(option);
    }, [JSON.stringify(option), isFirst]);

    const handleChange = ({ value: newValue, option }, fieldChange) => {
        try {
            let data;
            if (multiple) {
                data =
                    newValue?.map((value) => (typeof value === 'string' ? value : value[idKey])) ??
                    [];
            } else {
                data =
                    newValue !== null
                        ? typeof newValue === 'string'
                            ? newValue
                            : newValue[idKey]
                        : null;
            }
            setOption(data);
            fieldChange(data);
            onChange && onChange(newValue, option);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Controller
            name={name}
            control={form.control}
            render={({ field, fieldState: { isDirty, invalid, isTouched, error } }) => {
                return (
                    <>
                        {label && <label>{label}</label>}
                        <Select
                            value={value}
                            className={clsx({ 'react-select': variant === 'standard' })}
                            onChange={(value, { option }) => {
                                handleChange({ value, option }, field.onChange);
                            }}
                            getOptionLabel={(option) => option?.name || ''}
                            getOptionValue={(option) => option?.[idKey] || ''}
                            isOptionSelected={(value) => {
                                return Array.isArray(field.value)
                                    ? field.value.includes(value[idKey])
                                    : value === field.value;
                            }}
                            isSearchable
                            isMulti={multiple}
                            isClearable={disableClearable}
                            isLoading={loading}
                            isDisabled={disabled}
                            defaultValue={defaultValue}
                            closeMenuOnSelect={!multiple}
                            options={options}
                            {...props}
                        />
                        {!hideHelperText && (
                            <>
                                {helperText || (
                                    <small
                                        className={clsx('form-text text-muted', {
                                            'text-success': !error && isDirty,
                                            'text-danger': error,
                                        })}
                                    >
                                        {!error && isDirty && props.placeholder
                                            ? props.placeholder + '   was selected'
                                            : ''}
                                        {error ? error?.message : ''}
                                        {!error && !isDirty && helperText ? (
                                            <>
                                                Please select <b>{props.placeholder}</b>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </small>
                                )}
                            </>
                        )}
                    </>
                );
            }}
        />
    );
};

export default ReactSelectField;

import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { resetPassword } from '../_redux/authCrud';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useLocation } from 'react-router-dom';
import { toAbsoluteUrl } from '_metronic/_helpers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from 'components/form-controls/InputField';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#fff',
    width: '400px',
    height: '336px',
    borderRadius: '8px',
    padding: '40px 24px',
};

const ResetPassword = (props) => {
    const { intl } = props;
    const navigate = useNavigate
    const [isModal, setIsModal] = useState(false);
    const [hidePassword, setHidePassword] = useState({
        new_password: true,
        confirm_password: true,
    });
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const ggCaptchaEl = useRef(null);
    const ResetPasswordSchema = Yup.object().shape({
        new_password: Yup.string()
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&`~#^_\-.])[A-Za-z\d@$!%*?&`~#^_\-.]{10,}$/,
                'Password must contain at least 10 characters, contain uppercase, lowercase, number and special case character in @$!%*?&`~#^_-.',
            )
            .max(320)
            .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('new_password')])
            .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })),
    });
    const form = useForm({
        defaultValues: {
            new_password: '',
            confirm_password: '',
        },

        resolver: yupResolver(ResetPasswordSchema),
    });

    const resetPasswordToken = location?.search?.replace('?reset_password_token=', '');

    const handleSubmit = async (value) => {
        setLoading(true);
        try {
            const token = await ggCaptchaEl.current.executeRecaptcha();
            const response = await resetPassword(resetPasswordToken, value.new_password, token);
            if (response) {
                setIsModal(true);
            }
        } catch (error) {}
        setLoading(false);
    };

    return (
        <>
            <Modal
                open={isModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div
                            style={{
                                width: '80px',
                                height: '80px',
                                backgroundColor: '#E8F9F1',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CheckIcon sx={{ color: '#36B37E', width: '36px', height: '36px' }} />
                        </div>
                        <div
                            style={{
                                marginTop: '32px',
                                fontWeight: 500,
                                lineHeight: '32px',
                                fontSize: '24px',
                                color: '#191A1E',
                                letterSpacing: '-0.6px',
                            }}
                        >
                            Password Changed!
                        </div>
                        <div
                            style={{
                                marginTop: '8px',
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '24px',
                                color: '#55575B',
                                letterSpacing: '-0.35px',
                            }}
                        >
                            Your password has been changed successfully
                        </div>
                        <a
                            href="/auth/login"
                            className='btn-back'
                        >
                            Back to Login
                        </a>
                    </div>
                </Box>
            </Modal>
            <div className="login-form reset-password" style={{ display: 'block' }}>
                <img
                    src={toAbsoluteUrl('/media/logos/logo-dark.png')}
                    width={177}
                    height={49}
                    alt="BBCIncorp"
                    className="logo"
                />
                <div>
                    <h3 className="title d-inline-block">Reset Password?</h3>
                </div>
                <div>
                    <p className="description d-inline-block">
                        Enter your new password to reset your password
                    </p>
                </div>
                <form
                    onSubmit={form.handleSubmit(handleSubmit)}
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                >
                    <div className="group-form">
                        <label>New Password</label>
                        <InputField
                            type={hidePassword.new_password ? 'password' : 'text'}
                            name="new_password"
                            form={form}
                            placeholder="Enter new password"
                            hideHelperText
                            customStyle={{
                                '& .MuiInputBase-root': {
                                    padding: '12px 16px',
                                    border: '1px solid #BDC3CA',
                                    borderRadius: '4px',
                                },
                                '& .MuiInputBase-input': {
                                    padding: 0,
                                    color: hidePassword.new_password ? '#64748B' : '#0F172A',
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    fontWeight: '400',
                                    height: '24px',
                                    letterSpacing: '-0.35px',
                                },
                                '& .MuiInputBase-input::-webkit-input-placeholder': {
                                    color: '#94A3B8',
                                },
                                '& .MuiInputBase-root:focus': {
                                    border: '1px solid #BDC3CA !important',
                                },
                                '& .MuiInputBase-root:hover': {
                                    border: '1px solid #BDC3CA !important',
                                },
                                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    border: 'none !important',
                                },
                                '& .MuiInput-underline:active:not(.Mui-focused):before': {
                                    border: 'none !important',
                                },
                                '& .MuiInputBase-root:before': {
                                    borderBottom: 'none !important',
                                },
                                '& .MuiInput-root:after': {
                                    borderBottom: 'none !important',
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <button
                                        type="button"
                                        style={{
                                            border: 'none',
                                            padding: 0,
                                            background: 'transparent',
                                            color: '#A3A7AD',
                                        }}
                                        onClick={() =>
                                            setHidePassword((prevState) => ({
                                                ...prevState,
                                                new_password: !prevState.new_password,
                                            }))
                                        }
                                    >
                                        {hidePassword.new_password ? (
                                            <RemoveRedEyeOutlinedIcon />
                                        ) : (
                                            <RemoveRedEyeIcon />
                                        )}
                                    </button>
                                ),
                            }}
                        />
                        {form.formState.errors.new_password && (
                            <p
                                style={{
                                    fontSize: '0.75rem',
                                    color: '#f64e60',
                                    marginTop: '3px',
                                }}
                            >
                                {form.formState.errors.new_password.message}
                            </p>
                        )}
                    </div>
                    <div className="group-form">
                        <label>Confirm new password</label>
                        <InputField
                            type={hidePassword.confirm_password ? 'password' : 'text'}
                            name="confirm_password"
                            form={form}
                            placeholder="Re-Enter new password"
                            hideHelperText
                            customStyle={{
                                '& .MuiInputBase-root': {
                                    padding: '12px 16px',
                                    border: '1px solid #BDC3CA',
                                    borderRadius: '4px',
                                },
                                '& .MuiInputBase-input': {
                                    padding: 0,
                                    color: hidePassword.confirm_password ? '#64748B' : '#0F172A',
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    fontWeight: '400',
                                    height: '24px',
                                    letterSpacing: '-0.35px',
                                },
                                '& .MuiInputBase-input::-webkit-input-placeholder': {
                                    color: '#94A3B8',
                                },
                                '& .MuiInputBase-root:focus': {
                                    border: '1px solid #BDC3CA !important',
                                },
                                '& .MuiInputBase-root:hover': {
                                    border: '1px solid #BDC3CA !important',
                                },
                                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    border: 'none !important',
                                },
                                '& .MuiInput-underline:active:not(.Mui-focused):before': {
                                    border: 'none !important',
                                },
                                '& .MuiInputBase-root:before': {
                                    borderBottom: 'none !important',
                                },
                                '& .MuiInput-root:after': {
                                    borderBottom: 'none !important',
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <button
                                        type="button"
                                        style={{
                                            border: 'none',
                                            padding: 0,
                                            background: 'transparent',
                                            color: '#A3A7AD',
                                        }}
                                        onClick={() =>
                                            setHidePassword((prevState) => ({
                                                ...prevState,
                                                confirm_password: !prevState.confirm_password,
                                            }))
                                        }
                                    >
                                        {hidePassword.confirm_password ? (
                                            <RemoveRedEyeOutlinedIcon />
                                        ) : (
                                            <RemoveRedEyeIcon />
                                        )}
                                    </button>
                                ),
                            }}
                        />
                        {form.formState.errors.confirm_password && (
                            <p
                                style={{
                                    fontSize: '0.75rem',
                                    color: '#f64e60',
                                    marginTop: '3px',
                                }}
                            >
                                {form.formState.errors.confirm_password.message}
                            </p>
                        )}
                    </div>
                    <div className="d-flex flex-wrap flex-center group-button">
                        <button
                            type="button"
                            id="kt_login_forgot_cancel"
                            className="btn btn-cancel"
                            onClick={() => navigate('/auth')}
                        >
                            Back
                        </button>
                        <button
                            id="kt_login_forgot_submit"
                            type="submit"
                            className="btn btn-submit"
                            disabled={loading}
                        >
                            Submit
                        </button>
                    </div>
                    <div>
                        <GoogleReCaptchaProvider
                            ref={ggCaptchaEl}
                            reCaptchaKey={process.env.REACT_APP_RECAPTCHA}
                            language="en"
                            name="recaptcha-token"
                            scriptProps={{
                                async: false, // optional, default to false,
                                defer: false, // optional, default to false
                                appendTo: 'head', // optional, default to "head", can be "head" or "body",
                                nonce: undefined, // optional, default undefined
                            }}
                        >
                            {' '}
                        </GoogleReCaptchaProvider>
                    </div>
                </form>
            </div>
        </>
    );
};

export default injectIntl(connect(null, auth.actions)(ResetPassword));

import React from 'react';
import { useMemo } from 'react';

export default function FileList({ currentTemplate, openHTMLFile, openNativePdf, orderForm, stepIndex, fileTemplates }) {

    const message = stepIndex === 2 ? 'Order Form' : (stepIndex === 3 ? 'Incorporation form' : '');

    /**
     * ----------------------------------------------------------------------------------------
     * Functions ------------------------------------------------------------------------------
     * ----------------------------------------------------------------------------------------
     */
    const openFileDetail = (title) => {
        if (currentTemplate.is_cs_uploaded) {
            openNativePdf(title);
        } else {
            const titleParam =
                title && title.company_member_id ? { company_member_id: title.company_member_id } : {};
            openHTMLFile({ templateParams: { file_template_id: currentTemplate.id, ...titleParam }, file_name: title.file_name });
        }
    };
    const templateType = useMemo(() => {
        let hasWetSign = false;
        let hasESign = false;
        if(fileTemplates.length > 0) {
            fileTemplates.forEach((file) => {
                if(file.is_esign == false) hasWetSign = true;
                if(file.is_esign == true) hasESign = true;
            })
        }
        if(hasWetSign && hasESign) {
            return 'both';
        } else if(hasWetSign && !hasESign) {
            return 'wetsign';
        } else if(!hasWetSign && hasESign) {
            return 'esign';
        } else {
            return 'none'
        }
    });
    const renderNote = (type) => {
        switch(type) {
            case 'esign':
                return (
                    <p className="text-center text-muted mb-5 px-5">The {message} INC.{orderForm?.id} has been sent via email to the Chosen Signatory for electronically sign. This Step will only be passed once the order will be signed</p>
                );
            case 'wetsign':
                return (
                    <>
                        <p className="text-muted mb-1 px-5">Please download documents and add your signature with ink.</p>
                        <p className="text-muted mb-5 px-5">Kindly ensure to match the signature same as on your passport and our records.</p>
                    </>
                );
            case 'both':
                return (
                    <>
                        <p className="text-muted mb-1 px-5">Finishing the document(s) is just a couple of steps away.</p>
                        <p className="text-muted mb-1 px-5"><b>In the 'E-Signature' Section:</b></p>
                        <p className="text-muted mb-1 px-5">Simply check your email and give those enclosed document(s) your electronic signature. Your e-signed docs will be automatically updated right here on the Portal.</p>
                        <p className="text-muted mb-1 px-5"><b>Moving on to the 'Sign Docs Download' section:</b></p>
                        <p className="text-muted mb-5 px-5">Kindly download the attached document(s), sign them using wet ink, and upload the signed documents for our review.</p>
                    </>
                )
        }
    }
    /**
     * ----------------------------------------------------------------------------------------
     * JSX return -----------------------------------------------------------------------------
     * ----------------------------------------------------------------------------------------
     */
    return (
        <>
            <h6 className="text-uppercase font-weight-bolder mb-5 px-5">
                {currentTemplate?.name || ''}
            </h6>
            {!currentTemplate ? renderNote(templateType) : <></>}
            <div className="file-elements-container px-5">
                {currentTemplate && currentTemplate.titles ? (
                    <div className="row m-0">
                        {currentTemplate.titles.map((titleChild, index) => {
                            return (
                                <div
                                    key={index}
                                    className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 px-2 my-2"
                                >
                                    <div
                                        className="rounded-sm border border-secondary p-2 h-100 d-flex align-items-center file-element"
                                        onClick={() => openFileDetail(titleChild)}
                                    >
                                        <img
                                            src="/media/svg/files/file.svg"
                                            height={15}
                                            width={15}
                                            className="mr-2"
                                            alt="file ico"
                                        />
                                        <div className="caption">{titleChild.file_name}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}

import { toast } from 'react-toastify';
import * as requestFromServer from './proposedNameCrud';
import { proposedNameSlice, callTypes } from './proposedNameSlice';

const { actions } = proposedNameSlice;

// export const fetchproposedName = () => (dispatch) => {
//     dispatch(actions.startCall({ callType: callTypes.list }));
// };

export const fetchProposedName = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getProposedName(queryParams)
        .then((response) => {
            const { data } = response.data;
            dispatch(actions.proposedNameFetched({ data: data }));
            return response.data;
        })
        .catch((error) => {
            // error.clientMessage = "Can't find proposed name";
            toast.error("Can't find proposed name!");
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const updateProposedName = (body) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .updateProposedName(body)
        .then((response) => {
            dispatch(actions.proposedNameUpdated({ body }));
            return response.data;
        })
        .catch((error) => {
            error.clientMessage = "Can't update proposed name!";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error("Can't update proposed name!");
        });
};

export const addProposedName = (body) => dispatch => {
    dispatch(actions.startCall({}));
    return requestFromServer
        .addProposedName(body)
        .then((response) => {
            dispatch(actions.proposedNameAdd({ body }));
            // toast.success(`Add Proposed Name Successfully!`);
        })
        .catch((error) => {
            toast.error("Can't add new proposed name!");
            dispatch(actions.catchError({}));
        })
}

// export const updateProposedName = (queryParams, body) => (dispatch) => {
//     dispatch(actions.startCall({ callType: callTypes.action }));
//     return requestFromServer
//         .updateProposedName(queryParams, body)
//         .then(() => {
//             dispatch(actions.proposedNameUpdated({ body }));
//         })
//         .catch((error) => {
//             error.clientMessage = "Can't update proposed name!";
//             dispatch(actions.catchError({ error, callType: callTypes.action }));
//             toast.error("Can't update proposed name!");
//         });
// };

export const deletedProposedName = (proposed_name_id, body) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .deleteProposedName(proposed_name_id, body)
        .then(() => {
            // dispatch(actions.proposedNameDeleted());
        })
        .catch((error) => {
            error.clientMessage = "Can't delete proposed name!";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error("Can't delete proposed name!");
        });
};
import { createSlice } from '@reduxjs/toolkit';

const initState = {
    actionsLoading: false,
    entities: null,
    orderInfo: null,
    totalCount: 0,
    lastError: null,
    error: null
};
export const callTypes = {
    action: 'action',
};

export const orderFormSlice = createSlice({
    name: 'orderForm',
    initialState: initState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        OrdersFetched: (state, action) => { // get list order
            const { count, rows } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = rows;
            state.totalCount = count;
        },
        orderInfoFetched: (state, action) => {
            state.actionsLoading = false;
            const { data } = action.payload;
            state.error = null;
            state.orderInfo = data;
        },
        orderInfoUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            // console.log(action.payload);
        },
        updateCompanyMemberList: (state, action) => {
            state.actionsLoading = false;
            const { data } = action.payload;
            state.error = null;
            state.orderInfo.Company.CompanyMembers = data;
        }
    },
});

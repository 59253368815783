import { Grid, Typography } from '@mui/material';
import LoadingButton from 'app/components/LoadingButton';
import { getProcessDetail, updateCompanyEventType } from 'app/pages/_redux/additional-service/api';
import { updateOrderInfo } from 'app/pages/_redux/additional-service/slice';
import SelectField from 'components/form-controls/SelectField';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const companyKindList = [
    {
        id: 1,
        name: 'Dormant Company',
    },
    {
        id: 2,
        name: 'Pure - Holding Company',
    },
    {
        id: 3,
        name: 'Others/Active Company',
    },
];

const schema = yup.object({
    years: yup.array().of(
        yup.object({
            type: yup.number().nullable().required('Please choose status company'),
        }),
    ),
});

const CompanyStatus = ({ eventYears, setActiveStep, validateStepsRef, activeProcess }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const form = useForm({
        defaultValues: {
            years: [],
        },
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        const years = eventYears.map((item) => ({
            id: item.id,
            type: item.type,
        }));
        const obj = {
            years: years,
        };
        form.reset(obj);
    }, [eventYears]);

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);
            const payload = values.years;
            const { data } = await updateCompanyEventType(payload);
            if (data && data.state) {
                const {
                    data: { data },
                } = await getProcessDetail(activeProcess.Processes[0].id);
                dispatch(updateOrderInfo(data));
                setActiveStep && setActiveStep(2);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    if (validateStepsRef?.current) {
        validateStepsRef.current = {
            ...validateStepsRef.current,
            STEP_2: {
                isValid: form.formState.isValid,
                onSubmit: () => form.handleSubmit(handleSubmit)(),
            },
        };
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <>
            <Typography variant="caption" sx={{ marginBottom: '16px' }}>
                Choose status Company
            </Typography>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <div style={{ marginBottom: '16px' }}>
                    {eventYears?.map((year, index) => (
                        <div key={index}>
                            <Grid
                                container
                                xs={12}
                                md={8}
                                xl={6}
                                alignItems={'center'}
                                sx={{ borderBottom: '1px solid' }}
                            >
                                <Grid
                                    px={2}
                                    py={0.5}
                                    item
                                    xs={4}
                                    lg={3}
                                    sx={{ borderRight: '1px solid' }}
                                >
                                    {year?.year}
                                </Grid>
                                <Grid pl={2} py={0.5} item xs>
                                    <SelectField
                                        form={form}
                                        className="fw-500"
                                        label={''}
                                        name={`years.${index}.type`}
                                        placeholder="Select status company"
                                        options={companyKindList}
                                        getOptionLabel={(option) => option.name}
                                        customStyle={{
                                            '.MuiInputBase-root:before, .MuiInputBase-root:after, .MuiInput-underline:hover:not(.Mui-disabled):before':
                                                {
                                                    borderBottom: '0 !important',
                                                },
                                        }}
                                        hideHelperText={true}
                                        hideInputHelperText={true}
                                    />
                                </Grid>
                            </Grid>
                            {form.formState.errors.years?.[index]?.type && (
                                <small className={'text-danger'}>
                                    {form.formState.errors.years?.[index]?.type.message}
                                </small>
                            )}
                        </div>
                    ))}
                </div>
                <div>
                    <p>Note:</p>
                    <p><b>1. Dormant company: </b>a company that has/conducts no business activities and no accounting transaction arising in a period of time.</p>
                    <p><b>2. Pure-holding company: </b>a company that has/conducts no business activities other than solely holding assets (e.g. stocks, immovable property, shares, etc.).</p>
                    <p><b>3. Active company: </b>a company that has/conducts business activities thus having accounting records (e.g. bank statements, receipts, invoices, transactions, etc.) from its activities.</p>
                    <p>*Please kindly choose the appropriate status as per your assessment in alignment with the according period(s).</p>
                </div>
                <Button
                    size="large"
                    variant="contained"
                    disableElevation
                    className="mr-2"
                    onClick={handleBack}
                    disabled={isLoading}
                >
                    Back
                </Button>
                <LoadingButton
                    variant="contained"
                    type="submit"
                    size="large"
                    color="primary"
                    disabled={isLoading}
                    loading={isLoading}
                    style={{ color: '#fff' }}
                >
                    Next
                </LoadingButton>
            </form>
        </>
    );
};

export default CompanyStatus;

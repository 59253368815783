import React from 'react';
import workingPerson from '../../../images/workingPerson.svg';
import "./finish-wait-video-call.styles.scss"

const FinishWaitVideoCall = () => {
    return (
        <section id="video-call" className="video-call">
            <div className="image-container">
                <img src={workingPerson} alt="a person is working" />
            </div>
            <div className="video-call__content mt-5 pt-5">
                <p> Your documents are being processed. It will take around 2-3 days.</p>
            </div>
        </section>

    );
}

export default FinishWaitVideoCall;

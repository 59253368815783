import { createSlice } from '@reduxjs/toolkit';

const initialFilesState = {
    listLoading: false,
    actionsLoading: false,
    avatar: null,
    avatarBlob: null,
    lastError: null,
    file: null,
    fileS3: null,
    filesUpload: null,
    personImage: [], // [{id,image,imageBlob}]
    messageImage: [] // [{id,image,imageBlob}]
};
export const callTypes = {
    action: 'action',
};

export const filesSlice = createSlice({
    name: 'files',
    initialState: initialFilesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        // === countries Has Service
        uploadImage: (state, action) => {
            const { data } = action.payload;
            state.error = null;
            state.avatar = data.name ? data.name : state.avatar;
            state.avatarBlob = data.blob ? data.blob : state.avatarBlob;
            state.actionsLoading = false;
        },
        downloadFileS3: (state, action) => {
            const { data } = action.payload;
            state.error = null;
            state.fileS3 = data;
            state.actionsLoading = false;
        },
        viewAvatarS3: (state, action) => {
            const { data } = action.payload;
            state.error = null;
            state.fileS3 = data;
            state.actionsLoading = false;
        },
        downloadFile: (state, action) => {
            const { data } = action.payload;
            state.error = null;
            state.file = data;
            state.actionsLoading = false;
        },
        uploadFiles: (state, action) => {
            const { data } = action.payload;
            state.error = null;
            state.filesUpload = data;
            state.actionsLoading = false;
        },
        storePersonImage: (state, action) => {
            const { data } = action.payload;
            const dataIds = data.map(item => item.id)
            state.error = null;
            state.personImage = [...state.personImage.filter(item => !dataIds.includes(item.id)), ...data];
            state.actionsLoading = false;
        },

        storeMessageImage: (state, action) => {
            const { data } = action.payload;
            const dataIds = data.map(item => item.id)
            state.error = null;
            state.messageImage = [...state.messageImage.filter(item => !dataIds.includes(item.id)), ...data];
            state.actionsLoading = false;
        }
    },
});

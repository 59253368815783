import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Modal } from 'react-bootstrap';
import DetailForm from 'app/pages/services/components/kyc-elements/DetailForm';
import ExampleCollapse from 'app/pages/services/components/kyc-elements/ExampleCollapse';
import { useKYCContext } from '../kyc-context';
import { getDialogTitle } from 'app/pages/services/constants';
import { updateOrderInfo } from 'app/pages/_redux/banking/slice';

export default function DetailDialog() {
    const {
        modal,
        closeModal,
        updateGraphStructure,
        graphLoading,
        addGraphStructure,
        setGraphElement,
    } = useKYCContext();
    const memberData = modal?.data || {};
    const { companyData, orderInfo } = useSelector((state) => {
        return {
            companyData: state?.banking?.orderInfo?.Company || {},
            orderInfo: state?.banking?.orderInfo || {},
        };
    }, shallowEqual);
    const title = useMemo(
        () =>
            getDialogTitle(
                companyData.entity_type_id,
                companyData.country_id,
                memberData.company_member_type_id,
            ),
        [companyData.country_id, companyData.entity_type_id, memberData.company_member_type_id],
    );
    const data = useMemo(() => {
        let result = memberData;
        if (memberData.id && companyData) {
            const memberTemp = companyData.CompanyMembers.find(
                (member) => member.id === memberData.id,
            );
            if (memberTemp) {
                const currPos = {
                    ...(memberTemp.CompanyPositions.find((pos) => pos.id === memberData.pos_id) ||
                        {}),
                };
                if (currPos.id) {
                    delete currPos.id;
                }
                result = { ...memberData, ...memberTemp, ...currPos };
            }
        }
        return result;
    }, [memberData, companyData]);

    const isBankingOutSide = () => {
        // debugger;
        return companyData.is_outside_company;
    };

    const isLevelOneCloned = (currMember) => {
        if (currMember && currMember.id && companyData) {
            const members = companyData.CompanyMembers.filter(
                (member) => member.id === currMember.id,
            );
            for (const member of members) {
                for (const pos of member.CompanyPositions) {
                    if (!pos.corporation_company_member_id) {
                        return true;
                    }
                }
            }
        }
        return false;
    };
    const updateCompanyMemberList = (newOrderInfo) => updateOrderInfo(newOrderInfo);
    return (
        <Modal
            show={modal.show}
            onHide={closeModal}
            backdrop="static"
            size="xl"
            animation={true}
            centered
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DetailForm
                    data={data}
                    closeModal={closeModal}
                    updateGraphStructure={updateGraphStructure}
                    graphLoading={graphLoading}
                    addGraphStructure={addGraphStructure}
                    setGraphElement={setGraphElement}
                    isLevelOneCloned={isLevelOneCloned}
                    orderInfo={orderInfo}
                    updateCompanyMemberList={updateCompanyMemberList}
                    flow="banking"
                    isBankingOutSide={isBankingOutSide()}
                />

                <div className="col-12 kyc-example">
                    <ExampleCollapse />
                </div>
            </Modal.Body>
        </Modal>
    );
}

import { blue } from '@mui/material/colors';
import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import Share from './Share';
import DateField from 'components/form-controls/DateField';
import SelectField from 'components/form-controls/SelectField';
import InputField from 'components/form-controls/InputField';
import { InputAdornment, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { fetchBusinessActivity } from 'app/pages/_redux/enum/enumActions';
import { CAYMAN_ID, RAK_ID, UK_ID } from 'constants/country';
import {
    getDefaultNationality,
    getDefaultPhonePrefix,
} from 'app/pages/services/utilities/NationalityHelpers';

const Corporation = (props) => {
    const dispatch = useDispatch();
    const { disabled, changeFields, disabledFields, member, form } = props;
    const { businessActivityWithCountry } = useSelector(
        (state) => ({
            businessActivityWithCountry: state.enum.businessActivityWithCountry,
        }),
        shallowEqual,
    );

    const { countries, phonePrefixCountries } = useSelector(
        (state) => ({
            countries: state.enum.countries,
            phonePrefixCountries: state.enum.phonePrefixCountries,
        }),
        shallowEqual,
    );

    useEffect(() => {
        let timeout;
        try {
            if (!countries?.length || !phonePrefixCountries?.length) return;

            const phonePrefixDefault = getDefaultPhonePrefix(
                member?.phone_country_id,
                phonePrefixCountries,
            );

            const nationality = getDefaultNationality(member?.country_id, countries);

            if (form.getValues()) {
                // resolve error: create member -> next step -> back step -> delete member -> fill info -> next step -> ERROR
                timeout = setTimeout(() => {
                    if (!member?.phone_country_id) {
                        form.setValue('phone_country_id', phonePrefixDefault?.id);
                    }

                    if (!member?.country_id) {
                        form.setValue('country_id', nationality?.id);
                    }
                }, 1);
            }
        } catch (error) {
            console.log(error);
        }

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [member, countries, phonePrefixCountries]);

    useEffect(() => {
        const countryIds = [form.watch('country_id')];
        let countryCorporationIds = [];

        if (countryIds?.length) {
            countryIds.forEach((countryId) => {
                const normalListCountry = countryCorporationIds.filter(
                    (item) => ![CAYMAN_ID, UK_ID, RAK_ID].includes(item),
                );

                if (
                    ![CAYMAN_ID, UK_ID, RAK_ID].includes(countryId) &&
                    !normalListCountry?.length &&
                    !businessActivityWithCountry?.normalList?.length
                ) {
                    countryCorporationIds.push(countryId);
                }

                if (countryId === CAYMAN_ID && !businessActivityWithCountry?.caymanList?.length) {
                    countryCorporationIds.push(CAYMAN_ID);
                }
                if (countryId === UK_ID && !businessActivityWithCountry?.ukList?.length) {
                    countryCorporationIds.push(UK_ID);
                }
                if (countryId === RAK_ID && !businessActivityWithCountry?.rakList?.length) {
                    countryCorporationIds.push(RAK_ID);
                }
            });
        }

        countryCorporationIds.forEach((countryId) => {
            dispatch(fetchBusinessActivity(countryId, true));
        });
    }, [form.watch('country_id')]);

    const getBusinessActivityOfCountry = (countryId) => {
        if (!countryId) return [];
        if (businessActivityWithCountry) {
            if (countryId === CAYMAN_ID) {
                if (businessActivityWithCountry.caymanList.length) {
                    return businessActivityWithCountry.caymanList;
                }
            }
            if (countryId === UK_ID) {
                if (businessActivityWithCountry.ukList.length) {
                    return businessActivityWithCountry.ukList;
                }
            }
            if (countryId === RAK_ID) {
                if (businessActivityWithCountry.rakList.length) {
                    return businessActivityWithCountry.rakList;
                }
            }

            if (![CAYMAN_ID, UK_ID, RAK_ID].includes(countryId)) {
                if (businessActivityWithCountry.normalList.length) {
                    return businessActivityWithCountry.normalList;
                }
            }
        } else {
            return [];
        }
    };

    return (
        <div className="row corporation">
            <div className="col-lg-6 input-line mb-5">
                <label>Company name</label>
                <InputField
                    name="corporation_name"
                    placeholder="Enter company name"
                    form={form}
                    disabled={disabled || disabledFields?.['corporation_name']?.(member)}
                    hideSuccessHelper
                    customStyle={{
                        '& .MuiInputBase-input': {
                            backgroundColor: changeFields?.corporation_name ? blue[50] : 'inherit',
                            padding: '.6rem 1rem .6rem 0',
                        },
                    }}
                />
            </div>
            <div className="col-lg-6 input-line mb-5">
                <label>Company number</label>
                <InputField
                    name="corporation_number"
                    placeholder="Enter your company number"
                    form={form}
                    disabled={disabled || disabledFields?.['corporation_name']?.(member)}
                    hideSuccessHelper
                    customStyle={{
                        '& .MuiInputBase-input': {
                            backgroundColor: changeFields?.corporation_number
                                ? blue[50]
                                : 'inherit',
                            padding: '.6rem 1rem .6rem 0',
                        },
                    }}
                />
            </div>
            <div className="col-lg-6 input-line mb-5">
                <label>Country of corporation</label>
                <SelectField
                    form={form}
                    name="country_id"
                    options={countries || []}
                    getOptionLabel={(option) => option?.name || ''}
                    placeholder={'Select country of corporation'}
                    disabled={disabled || disabledFields?.['country_id']?.(member)}
                    hideInputHelperText
                    hideSuccessHelper
                    autoComplete="off"
                    customStyle={{
                        '& .MuiInputBase-input': {
                            backgroundColor: changeFields?.country_id ? blue[50] : 'inherit',
                        },
                        '.MuiInputBase-root': {
                            paddingRight: '0px !important',
                        },
                    }}
                    onChange={(value) => {
                        form.setValue('business_activity_array', []);
                        form.setValue('country_id', value.id);
                    }}
                />
            </div>
            <div className="col-lg-6 mb-5 input-line" style={{ marginTop: '3px' }}>
                <label>Phone number</label>
                <div className="phone-group">
                    <div className="phone-code" style={{ zIndex: 1 }}>
                        <SelectField
                            id="phone-prefix-select"
                            className="fw-500"
                            name="phone_country_id"
                            label=""
                            inputProps={(params) => ({
                                ...params,
                                placeholder: 'Phone country',
                            })}
                            form={form}
                            options={phonePrefixCountries || []}
                            getOptionLabel={(option) => option?.name || ''}
                            disabled={disabled || disabledFields?.['phone_country_id']?.(member)}
                            customStyle={{
                                '.MuiInputBase-root:before, .MuiInputBase-root:after, .MuiInput-underline:hover:not(.Mui-disabled):before':
                                    {
                                        borderBottom: '0 !important',
                                    },
                                '.MuiInput-root.MuiInputBase-sizeSmall .MuiInput-input': {
                                    padding: '.6rem 1rem .6rem 0',
                                },
                                '& .MuiInputBase-input': {
                                    backgroundColor: changeFields?.phone_country_id
                                        ? blue[50]
                                        : 'inherit',
                                },
                            }}
                        />
                    </div>
                    <div className="input-line inp-phone">
                        <InputField
                            placeholder="Your phone number"
                            name="phone_number"
                            form={form}
                            disabled={disabled || disabledFields?.['phone_number']?.(member)}
                            customStyle={{
                                '& .MuiInputBase-input': {
                                    backgroundColor: changeFields?.phone_number
                                        ? blue[50]
                                        : 'inherit',
                                    padding: '.6rem 1rem .6rem 0',
                                },
                            }}
                            hideSuccessHelper
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-6 input-line mb-5">
                <label style={{ marginBottom: '5px' }}>Date of corporation</label>
                <DateField
                    name="corporation_date"
                    placeholder="DD/MM/YYYY"
                    form={form}
                    disabled={disabled || disabledFields?.['corporation_date']?.(member)}
                    inputProps={{
                        wrapperStyle: {
                            bgcolor: changeFields?.corporation_date ? blue[50] : 'inherit',
                        },
                    }}
                    customStyle={{
                        '& .MuiInputBase-input': {
                            padding: '.6rem 1rem .5rem 0',
                        },
                        '.MuiInputBase-root.Mui-error:after': {
                            borderBottomColor: '#f64e60',
                        },
                        '&.Mui-success .MuiInputBase-root:after': {
                            transform: 'scaleX(1) translateX(0)',
                        },
                    }}
                />
            </div>
            <Share isCorporation {...props} />
            <div className="col-lg-6 input-line mb-5">
                <label>Operating address</label>
                <InputField
                    name="operation_address"
                    form={form}
                    disabled={disabled || disabledFields?.['operation_address']?.(member)}
                    hideSuccessHelper
                    customStyle={{
                        '& .MuiInputBase-input': {
                            backgroundColor: changeFields?.operation_address ? blue[50] : 'inherit',
                            padding: '.6rem 1rem .6rem 0',
                        },
                    }}
                    placeholder="Enter operating address"
                />
            </div>
            <div className="col-lg-6 input-line mb-5">
                <label>Nature of business</label>
                <InputField
                    name="nature_business"
                    form={form}
                    disabled={disabled || disabledFields?.['nature_business']?.(member)}
                    hideSuccessHelper
                    customStyle={{
                        '& .MuiInputBase-input': {
                            backgroundColor: changeFields?.nature_business ? blue[50] : 'inherit',
                            padding: '.6rem 1rem .8rem 0',
                        },
                    }}
                    placeholder="Enter nature of business"
                />
            </div>
            <div className="col-lg-6 input-line mb-5">
                <label>Business activities</label>
                <SelectField
                    form={form}
                    name="business_activity_array"
                    options={getBusinessActivityOfCountry(form.watch('country_id')) || []}
                    disabled={disabled || disabledFields?.['business_activity_array']?.(member)}
                    multiple
                    hideInputHelperText
                    hideSuccessHelper
                    autoComplete="off"
                    customStyle={{
                        '.MuiInput-root.MuiInputBase-sizeSmall .MuiInput-input': {
                            padding: '.6rem 1rem .7rem 0',
                        },
                        '& .MuiInputBase-input': {
                            backgroundColor: changeFields?.business_activity_array
                                ? blue[50]
                                : 'inherit',
                        },
                    }}
                    getOptionLabel={(option) => option.description}
                    placeholder="Enter business activities"
                />
            </div>
            <div className="col-lg-6 input-line mb-5">
                <label>Detail of Business activities</label>
                <InputField
                    form={form}
                    name="business_activity_detail"
                    size="small"
                    multiline={true}
                    disabled={disabled || disabledFields?.['business_activity_detail']?.(member)}
                    sx={{ margin: '15px 0' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip
                                    title={
                                        'Please describe your business activities in this field as details as possible to process your order smoothly'
                                    }
                                    placement={'top'}
                                >
                                    <InfoIcon color={'primary'} fontSize={'small'} />
                                </Tooltip>
                            </InputAdornment>
                        ),
                    }}
                    customStyle={{
                        '& .MuiInputBase-input': {
                            backgroundColor: changeFields?.business_activity_detail
                                ? blue[50]
                                : 'inherit',
                            padding: '.6rem 1rem .4rem 0',
                        },
                    }}
                    placeholder="Enter detail of business activities"
                />
            </div>
            <div className="col-lg-6 input-line mb-5">
                <label>Country of Operation</label>
                <SelectField
                    form={form}
                    name="OperationCountries"
                    options={countries || []}
                    multiple
                    disabled={disabled || disabledFields?.['OperationCountries']?.(member)}
                    hideInputHelperText
                    hideSuccessHelper
                    autoComplete="off"
                    customStyle={{
                        '.MuiInput-root.MuiInputBase-sizeSmall .MuiInput-input': {
                            padding: '.6rem 1rem .7rem 0',
                        },
                        '& .MuiInputBase-input': {
                            backgroundColor: changeFields?.OperationCountries
                                ? blue[50]
                                : 'inherit',
                        },
                    }}
                    placeholder="Select country of operation"
                />
            </div>
        </div>
    );
};

export default Corporation;

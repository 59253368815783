import * as requestFromServer from './filesCrud';
import { filesSlice, callTypes } from './filesSlice';
import { toast } from 'react-toastify';
import { createBlobUrl } from 'app/pages/components/helper/image';
const { actions } = filesSlice;

// export const uploadImage = (files) => (dispatch) => {
//     dispatch(actions.startCall({ callType: callTypes.action }));
//     return requestFromServer
//         .uploadImage(files)
//         .then((response) => {
//             const data = response.data.data;
//             dispatch(actions.uploadImage({ data: data }));
//             // toast.success(`Choose Image Successfully!`)
//         })
//         .catch((error) => {
//             error.clientMessage = "Can't Choose Image";
//             dispatch(actions.catchError({ error, callType: callTypes.list }));
//             toast.error('Something went wrong!');
//         });
// };

export const downloadFile = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .downloadFile(queryParams)
        .then((response) => {
            const data = response.data;
            dispatch(actions.downloadFile({ data: data }));
            toast.success(`Download Successfully!`);
        })
        .catch((error) => {
            error.clientMessage = "Can't dowwnload files";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            toast.error('Something went wrong!');
        });
};

export const downloadFileS3 = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .downloadFileS3(queryParams)
        .then((response) => {
            dispatch(actions.downloadFileS3({ data: response.data.data }));
            return response.data.data;
        })
        .catch((error) => {
            error.clientMessage = "Can't dowwnload files";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            toast.error('Something went wrong!');
        });
};

export const viewAvatarS3 = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .viewAvatarS3(queryParams)
        .then((response) => {
            dispatch(actions.viewAvatarS3({ data: response.data.data }));
            return response.data.data;
        })
        .catch((error) => {
            error.clientMessage = "Can't fetch the files";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            toast.error('Something went wrong!');
        });
};

export const uploadFiles = (files, queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .uploadFiles(files, queryParams)
        .then((response) => {
            const data = response.data;
            dispatch(actions.uploadFiles({ data: data }));
            toast.success(`Upload Successfully!`);
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't upload files";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            toast.error('Something went wrong!');
        });
};

export const createFilesDbS3 = (body) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .createFileDbS3(body)
        .then((response) => {
            const data = response.data;
            return data.data;
        })
        .catch((error) => {
            error.clientMessage = "Can't upload files";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            toast.error('Something went wrong!');
        });
};

export const sendFilesToEmail = (param) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .sendFilesToCustomer(param)
        .then((response) => {
            const data = response.data;
            return data;
        })
        .catch((error) => {
            toast.error('Something went wrong!');
        });
};

export const genSignedUrlS3 = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getSignedUrlS3(queryParams)
        .then((response) => {
            const data = response.data;
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't get singedUrl";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            toast.error('Something went wrong!');
        });
};

export const uploadS3 = (signed, files) => (dispatch) => {
    return new Promise((resolve, reject) => {
        fetch(signed.url, {
            method: 'PUT',
            body: files,
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject(response);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const uploadImageS3 = (signed, cropBlob) => (dispatch) => {
    return new Promise((resolve, reject) => {
        fetch(signed.url, {
            method: 'PUT',
            body: cropBlob,
        })
            .then((response) => {
                resolve(response);
                const data = {
                    blob: createBlobUrl(cropBlob),
                    name: signed.filename,
                };
                dispatch(actions.uploadImage({ data: data }));
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const updateAvatar = (blobUrl) => (dispatch) => {
    return dispatch(actions.uploadImage({ data: { blob: blobUrl } }));
};

export const storePersonImage = (objectList) => (dispatch) => {
    return dispatch(actions.storePersonImage({ data: objectList }));
};

export const storeMessageImage = (objectList) => (dispatch) => {
    return dispatch(actions.storeMessageImage({ data: objectList }));
};

export const deleteFileRequest = (file) =>
    new Promise((resolve, reject) => {
        return requestFromServer
            .deleteFile(file.id)
            .then((response) => {
                const data = response.data;
                resolve(data);
                return data;
            })
            .catch((error) => {
                reject(error);
                error.clientMessage = "Can't delete " + file.name;
                toast.error("Can't delete " + file.name);
            });
    });

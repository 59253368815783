import { Modal } from 'react-bootstrap';
import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ACTION_TYPES, COMPANY_MEMBER_TYPES, MEMBER_TYPES } from 'app/pages/services/constants';
import _, { cloneDeep, find, get, isEmpty, isEqual, map, sum, sumBy, toNumber } from 'lodash';
import Swal from 'sweetalert2';
import { useKYCUIContext } from 'app/pages/services/order-management/order-form/kyc/KYCUIContext';
import { useForm } from 'react-hook-form';
import MemberDetailForm from 'app/pages/company/company-detail/components/MemberDetail/MemberDetailForm';
import { getProcessDetail } from 'app/pages/_redux/banking/api';
import { updateOrderInfo } from 'app/pages/_redux/additional-service/slice';
import { useDispatch } from 'react-redux';

const MemberDetailModal = ({
    allowNewMember,
    allowGetChangeRequest,
    onSubmit,
    allowShowRepresent,
    page = 'company',
    uploadDisabled = () => {},
    task,
    ...props
}) => {
    const { modal, closeModal } = useKYCUIContext();
    const {
        company = {},
        disabled,
        IS_UK_LLP,
        IS_LLC,
        isSVG,
        isPanama,
        orderInfo,
        hasShowSelectFullName,
    } = props;
    const formRef = useRef(null);
    const [isShowNote, setIsShowNote] = useState(false);
    const [isPercent, setIsPercent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isInValid, setIsInValid] = useState(false);
    const flagNewMember = useRef(false);
    const flagOldMember = useRef(false);

    const dispatch = useDispatch();

    /* --- OPTION --- */
    const options = useMemo(() => {
        if (!company) return { member: {}, position: {} };
        flagNewMember.current = false;
        flagOldMember.current = false;

        const member = { ...cloneDeep(modal.data), allowShowRepresent };
        const isCorporationChild = !!member.corporation_company_member_id;
        const originPosition = cloneDeep(find(member.CompanyPositions, ['id', member.pos_id]));
        let position = find(member.CompanyPositions, ['id', member.pos_id]);
        let appointedRequest = {};

        if (orderInfo) {
            orderInfo.Company.CompanyMembers.forEach((item) => {
                item.CompanyPositions.forEach((p) => {
                    if (
                        !p.corporation_company_member_id &&
                        p.company_member_type_id === originPosition?.company_member_type_id &&
                        !p.is_approved &&
                        p.is_appointed
                    ) {
                        flagNewMember.current = true;
                    }
                });
            });

            if (flagNewMember.current) {
                if (originPosition?.is_approved) {
                    position.disabled_appointed = 1;
                    position.is_appointed = 0;
                }
            }
            if (flagOldMember.current) {
                if (originPosition?.is_approved && originPosition?.is_appointed) {
                    position.is_appointed = 0;
                    position.disabled_appointed = 1;
                }
                if (
                    originPosition?.is_approved &&
                    !originPosition?.is_appointed &&
                    originPosition?.id === appointedRequest?.company_position_id
                ) {
                    position.is_appointed = 1;
                }
            }
        }
        return {
            member,
            position,
            isCorporationChild,
            ...(position && {
                isUBO: position.company_member_type_id === COMPANY_MEMBER_TYPES.UBO.id,
                isShareholder:
                    position.company_member_type_id === COMPANY_MEMBER_TYPES.SHAREHOLDER.id,
                isDirector: position.company_member_type_id === COMPANY_MEMBER_TYPES.DIRECTOR.id,
                isContactPerson:
                    position.company_member_type_id === COMPANY_MEMBER_TYPES.CONTACT_PERSON.id,
            }),
        };
    }, [modal.data, company]);

    const { member, position } = options;
    const isDisabled = typeof disabled === 'function' ? !!disabled(member) : disabled;

    /* --- HOOK FORM --- */
    const initialValues = useMemo(() => {
        const { isUBO, isShareholder, isCorporationChild } = options;

        return {
            /* --- MEMBER INFORMATION --- */
            type_member_id: member.type_member_id ?? MEMBER_TYPES.INDIVIDUAL.id,
            birthday: member.birthday,
            major: member.major ?? '',
            phone_number: member.phone_number ?? '',
            phone_country_id: member.phone_country_id,
            passport: member.passport ?? '',
            passport_issue_date: member.passport_issue_date,
            passport_expire_date: member.passport_expire_date,
            corporation_name: member.corporation_name ?? '',
            corporation_number: member.corporation_number ?? '',
            corporation_date: member.corporation_date,
            first_name: member.first_name ?? '',
            last_name: member.last_name ?? '',
            middle_name: member.middle_name ?? '',
            full_name: member.full_name ?? '',
            country_id: member.country_id,
            email: member.email ?? '',
            address: member.address ?? '',
            position_name: member?.CompanyMemberType?.name ?? '',
            date_of_appointment: member?.date_of_appointment ?? '',
            is_appointed: position?.is_appointed ?? false,
            address_country_id: member.address_country_id,
            city: member.city,
            state: member.state,
            postal_code: member.postal_code,
            operation_address: member.operation_address,
            nature_business: member.nature_business,
            business_activity_array: member?.BusinessActivities?.map((item) => item.id) || [],
            OperationCountries:
                member?.CompanyMemberOperationCountries?.map((item) => item.id) || [],
            business_activity_detail: member.business_activity_detail,
            disabled_appointed: position?.disabled_appointed ?? false,
            is_level_1: position?.corporation_company_member_id ? false : true,

            /* --- OPTIONAL WITH MEMBER TYPE --- */
            ...(isUBO && {
                ...(isCorporationChild
                    ? {
                          percent_of_interest:
                              member?.CompanyInterests?.find(
                                  (item) =>
                                      item?.corporation_company_member_id ===
                                      member?.CompanyPositions?.find(
                                          (item) => item.id === member.pos_id,
                                      )?.corporation_company_member_id,
                              )?.value ?? 0,
                      }
                    : {
                          percent_of_interest:
                              sumBy(member?.CompanyInterests, (item) =>
                                  !item?.corporation_company_member_id &&
                                  item?.is_approved &&
                                  item?.is_valid
                                      ? item?.value
                                      : 0,
                              ) ?? 0,
                      }),
            }),
            ...(isShareholder && {
                ...(isCorporationChild
                    ? {
                          own_percent:
                              member?.CompanyOwnerships?.find(
                                  (item) =>
                                      item?.corporation_company_member_id ===
                                      member?.CompanyPositions?.find(
                                          (item) => item.id === member.pos_id,
                                      )?.corporation_company_member_id,
                              )?.value ?? 0,
                          number_allotted:
                              member?.CompanyShares?.find(
                                  (item) =>
                                      item?.corporation_company_member_id ===
                                      member?.CompanyPositions?.find(
                                          (item) => item.id === member.pos_id,
                                      )?.corporation_company_member_id,
                              )?.value ?? 0,
                      }
                    : {
                          ...(IS_LLC
                              ? {
                                    own_percent:
                                        sumBy(member?.CompanyOwnerships, (item) =>
                                            !item?.corporation_company_member_id &&
                                            item?.is_approved &&
                                            item?.is_valid
                                                ? item?.value
                                                : 0,
                                        ) ?? 0,
                                }
                              : {
                                    number_allotted:
                                        sumBy(member?.CompanyShares, (item) =>
                                            !item?.corporation_company_member_id &&
                                            item?.is_approved &&
                                            item?.is_valid
                                                ? item?.value
                                                : 0,
                                        ) ?? 0,
                                }),
                      }),
            }),

            /* --- OTHER INFORMATION --- */
            members: [
                {
                    /* --- SOURCE FOR FUNDS --- */
                    ...(isSVG && {
                        cash_in_bank_asset_1: member.asset_source_fund_cast_in_bank,
                        total_asset: sum(
                            map(
                                [
                                    member.asset_source_fund_cast_in_bank,
                                    member.asset_source_fund_real_estate,
                                    member.asset_source_fund_other_money,
                                ],
                                toNumber,
                            ),
                        ),
                        real_estate: member.asset_source_fund_real_estate,
                        other_asset: member.asset_source_fund_other_detail,
                        other_money: member.asset_source_fund_other_money,
                        showOther: false,

                        investment_income: member.income_source_fund_investment,
                        total_income: sum(
                            map(
                                [
                                    member.income_source_fund_investment,
                                    member.income_source_fund_self_employed,
                                    member.income_source_fund_other_money,
                                ],
                                toNumber,
                            ),
                        ),
                        self_employed: member.income_source_fund_self_employed,
                        other_detail_income: member.income_source_fund_other_detail,
                        other_money_income: member.income_source_fund_other_money,
                        showOtherIncome: false,
                    }),

                    /* --- REFERENCE --- */
                    ...(isPanama && {
                        references:
                            member?.CompanyMemberReferences?.map((item) => {
                                delete item?.created_time;
                                delete item?.updated_time;
                                delete item?.CompanyMemberIssuseReference;
                                return item;
                            }) ?? null,
                        president:
                            member?.CompanyPositions?.find(
                                (position) => position.company_member_type_id === 5,
                            )?.is_appointed === 1,
                        secretary:
                            member?.CompanyPositions?.find(
                                (position) => position.company_member_type_id === 4,
                            )?.is_appointed === 1,
                        treasurer:
                            member?.CompanyPositions?.find(
                                (position) => position.company_member_type_id === 6,
                            )?.is_appointed === 1,
                        authorized:
                            member?.CompanyPositions?.find(
                                (position) => position.company_member_type_id === 3,
                            )?.is_appointed === 1,
                    }),

                    /* --- SECURITY QUESTION --- */
                    id: member.id,
                },
            ],
        };
    }, [modal]);

    const form = useForm();

    /* --- QUESTION FOR UK --- */
    const Questions = useMemo(() => {
        try {
            let Questions = {};
            const questions =
                page === 'company'
                    ? get(company, 'Orders[0].Task.IncorporationCountry.Questions')
                    : get(orderInfo, 'Task.IncorporationCountry.Questions');
            if (questions) {
                questions.map((item) => {
                    switch (item.id) {
                        case 20:
                            Questions = { ...Questions, nature_ownership: item };
                            break;
                        case 21:
                            Questions = { ...Questions, nature_voting_rights: item };
                            break;
                        case 22:
                            Questions = { ...Questions, nature_appoint_majority: item };
                            break;
                        case 23:
                            Questions = { ...Questions, nature_other_significant_control: item };
                            break;
                        case 24:
                            Questions = { ...Questions, firm: item };
                            break;
                        case 25:
                            Questions = { ...Questions, trust: item };
                            break;
                        case 26:
                            Questions = { ...Questions, firm_ownership: item };
                            break;
                        case 27:
                            Questions = { ...Questions, firm_voting_rights: item };
                            break;
                        case 28:
                            Questions = { ...Questions, firm_appoint_majority: item };
                            break;
                        case 29:
                            Questions = { ...Questions, firm_other_significant_control: item };
                            break;
                        case 30:
                            Questions = { ...Questions, trust_ownership: item };
                            break;
                        case 31:
                            Questions = { ...Questions, trust_voting_rights: item };
                            break;
                        case 32:
                            Questions = { ...Questions, trust_appoint_majority: item };
                            break;
                        case 33:
                            Questions = { ...Questions, trust_other_significant_control: item };
                            break;
                    }
                });
            }
            return Questions;
        } catch (error) {
            console.log(error);
        }
    }, []);

    /* --- TITLE MODAL --- */
    const title = useMemo(() => {
        const { isDirector, isShareholder, isUBO, isContactPerson } = options;
        const is_LLC_LLP = IS_LLC || IS_UK_LLP;

        switch (Boolean(isDisabled)) {
            case true: {
                if (isDirector) return is_LLC_LLP ? 'View Manager' : 'View Director';
                if (isShareholder) return is_LLC_LLP ? 'View Member' : 'View Shareholder';
                if (isUBO) return 'View UBO';
                if (isContactPerson) return 'View Contact Person';
                return 'View';
            }
            case false: {
                if (isDirector) return is_LLC_LLP ? 'Edit Manager' : 'Edit Director';
                if (isShareholder) return is_LLC_LLP ? 'Edit Member' : 'Edit Shareholder';
                if (isUBO) return 'Edit UBO';
                if (isContactPerson) return 'Edit Contact Person';
                return 'Edit';
            }
            default: {
                return 'Detail';
            }
        }
    }, [options]);

    const excludedFields = ['email', 'phone_country_id', 'phone_number']; // field with no charge
    const watchedFields = form.watch();
    const filteredWatchedFields = Object.keys(watchedFields).filter(
        (fieldName) => !excludedFields.includes(fieldName),
    );

    /* --- SUMMIT ACTION --- */
    const handleSubmit = async () => {
        try {
            setLoading(true);
            let result = {};
            const { uploadFile } = formRef.current;
            if (uploadFile) {
                const update = uploadFile.getData();
                result.uploadFile = await update(member.id, task.id);
                const {
                    data: { data },
                } = await getProcessDetail(task?.Processes?.[0]?.id);
                dispatch(updateOrderInfo(data));
                closeModal();
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Somethings went wrong!',
                showConfirmButton: false,
                timer: 1500,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        form.reset(initialValues);
        setIsPercent(!!form.watch('own_percent'));
    }, [initialValues]);

    useEffect(() => {
        if (modal.show) {
            let isChange = true;
            filteredWatchedFields.forEach((item) => {
                if (!isEqual(form.watch(item), initialValues[item])) {
                    setIsShowNote(true);
                }
            });
            if (
                filteredWatchedFields.every((item) =>
                    isEqual(form.watch(item), initialValues[item]),
                )
            ) {
                isChange = false;
            }
            if (!isChange) {
                setIsShowNote(false);
            }
        }
    }, [form.watch()]);

    useEffect(() => {
        if (!modal.show) {
            setIsShowNote(false);
        }
    }, [modal.show]);

    if (isEmpty(company) || isEmpty(member) || isEmpty(position)) return null;

    return (
        <Modal
            show={
                [ACTION_TYPES.VIEW.name, ACTION_TYPES.EDIT.name, ACTION_TYPES.UPLOAD.name].includes(
                    member.action,
                ) &&
                (member.is_approved || allowNewMember) &&
                (member.hasOwnProperty('is_resigned') ? member.is_resigned === false : true) &&
                modal.show
            }
            backdrop="static"
            size="xl"
            animation={false}
            centered
        >
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <MemberDetailForm
                        ref={formRef}
                        Questions={Questions}
                        {...options}
                        {...props}
                        disabled={isDisabled || loading}
                        form={form}
                        isViewMode={isDisabled}
                        page={page}
                        hasShowSelectFullName={hasShowSelectFullName}
                        allowShowRepresent={allowShowRepresent}
                        isPercent={isPercent}
                        setIsPercent={setIsPercent}
                        setIsInValid={setIsInValid}
                        uploadDisabled={uploadDisabled}
                        uploadNote={
                            <>
                                <p className="mb-1">
                                    <b>Note:</b>
                                </p>
                                <ul className="mb-0 pl-8">
                                    <li className="mb-1">
                                        Address proof can include any of the following: Bank
                                        reference / Bank statement / Utility bill / Driver license.
                                    </li>
                                    <li className="mb-1">
                                        The address proof must clearly show the holder's full name
                                        along with physical address written in English, must be the
                                        most recent version and dated within the last 3 months.
                                        (P.O. Box addresses are not accepted).
                                    </li>
                                    <li className="mb-1">
                                        Certified or notarized by CPA, Notary Public, Power of
                                        Antony or other authorities accepted by Jurisdiction.
                                    </li>
                                </ul>
                                <p className="mb-0">
                                    * We can support notarizing passport & address proof by CPA in
                                    HK for earch member with USD 50.00
                                </p>
                            </>
                        }
                    />
                </Modal.Body>
                <Modal.Footer>
                    {loading && <CircularProgress size={20} color="primary" />}
                    <Button
                        type="button"
                        style={{ marginLeft: 15 }}
                        className="btn btn-light btn-sm"
                        onClick={closeModal}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    {(!isDisabled || page === 'bookeeping') && (
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ color: '#fff', marginLeft: 15 }}
                            disabled={loading || isInValid}
                            type="submit"
                        >
                            Save
                        </Button>
                    )}
                </Modal.Footer>
                {!isDisabled && page == 'company' && isShowNote && (
                    <p
                        className="mb-0 text-white p-2"
                        style={{
                            backgroundColor: '#17c191',
                            borderBottomLeftRadius: '0.42rem',
                            borderBottomRightRadius: '0.42rem',
                        }}
                    >
                        <b>
                            The changes to this information may incur additional costs, please
                            review the information in the Review and Complete section after making
                            the adjustments.
                        </b>
                    </p>
                )}
            </form>
        </Modal>
    );
};

export default MemberDetailModal;

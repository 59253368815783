import React from 'react';

function BusinessProofsContent({name = '', activeTitle}) {
    console.log(activeTitle);
    return (
        <>
            <h6 className={'pb-3'}>Business proofs - Company docs</h6>

            {activeTitle?.file_type_id === 4 &&
                <p>- Certificate of Incumbency (if any) <br/>
                    - Certificate of good standing (if any) <br/>
                    - ROD/ROM/ROB <br/>
                    - Share Certificate <br/>
                    - Other documents.
                </p>}

            {activeTitle?.file_type_id === 4 || <p className={'mb-0'}>{name}</p>}

        </>
    );
}

export default BusinessProofsContent;

export const XERO_CODE_ROUND_CHOP = 'OCOMP';
export const XERO_CODE_DOCUMENT_RETRIEVAL = [
    'TCRA',
    'TCNP',
    'HKCNP',
    'VNPC',
    'VNPI',
    'NOT',
    'CAYCCR',
    'HKCCR',
    'OAP',
    'OAPH',
    'OAPS',
    'DCOI',
    'COIC',
    'COISKN',
    'COIU',
    'COIM',
    'DCCS',
    'CSGD',
    'CGSC',
    'COCR',
    'CCOR',
];
export const XERO_CODE_PHYSICAL_SHIPPING = ['COU', 'PSSKN', 'PSSV'];
export const XERO_CODE_CHANGE_OFFICER = [
    'LCDI',
    'CAYCDI',
    'CDMAU',
    'CMDEL',
    'LCDS',
    'CAYCDS',
    'MAUCDS',
    'COMLG',
    'CUBO',
    'CUBOCI',
    'CUBOMA',
    'CUBODE',
];
export const XERO_CODE_CHANGE_COMPANY_NAME = ['LCCN', 'CONLG', 'LCCNV', 'LCCBA', 'LCCNC'];
export const XERO_CODE_ES = ['ESF', 'ESLA', 'TRFB', 'TRFS', 'TRFSN', 'EINA'];
export const XERO_CODE_TIN = ['TINB', 'TINS'];
export const XERO_CODE_AR = ['ACR', 'ACRA']
export const XERO_CODE_ANNUAL_RENEWAL = ['ADEL', 'AANG', 'ABAH', 'ABH', 'ABELC', 'ABRI', 'ABRIS', 'ACAY', 'ACYP', 'ADEL', 'AHON', 'AMAR', 'AMAU', 'APAN', 'ARAS', 'ASAM', 'ASEY', 'ASKN', 'ASVG', 'AUNI']
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getAdditionalProcessTree, approveProcess, deleteFile, getProcessDetail, updateOrderFormBookkeeper
} from './api';

export const getProcessDetailById = createAsyncThunk(
    'additional-service/getProcessDetailByProcessOrder',
    async (processID, thunkAPI) => {
        try {
            const response = await getProcessDetail(processID);
            return response.data.data
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
)


export const getAdditionalProcessTreeAction = createAsyncThunk(
    'additional-service/getAdditionalProcessTreeAction',
    async ({ orderId, params }, thunkAPI) => {
        try {
            const {data: { data }} = await getAdditionalProcessTree(orderId, params);
            return data;
        } catch(err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const approveProcessAction = createAsyncThunk(
    'additional-service/approveProcessAction',
    async (body, thunkAPI) => {
        try {
            const {data: { data }} = await approveProcess(body);
            return data;
        } catch(err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const deleteFileAction = createAsyncThunk(
    'additional-service/deleteFileAction',
    async (fileId, thunkAPI) => {
        try {
            const {data: { data }} = await deleteFile(fileId);
            return data;
        } catch(err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateOrderFormBookkeeperAction = createAsyncThunk(
    'additional-service/updateOrderFormBookkeeperAction',
    async ({id, params}, thunkAPI) => {
        try {
            const response = await updateOrderFormBookkeeper(id, params);
            return response.data.data;
        } catch(err) {
            let error = err // cast the error for access
            if (!error.response) {
                throw err
            }
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

import React from 'react';
import SelectField from 'components/form-controls/SelectField';

export default function Currency({ form, currencies, excludes = [] }) {
    let currencyOptions = currencies?.data.map( c => {
        let obj = {}
        obj.id = c.id;
        obj.name = c.code;
        obj.value = c.id;
        return obj;
    }).filter(c => !excludes.includes(c.id))
    const currencyValue = form.getValues('currency');

    if (currencyValue?.length > 0) {
        currencyOptions = currencyOptions.filter(c => !currencyValue.some(v => v.value === c.value))
    }

    return (
        <>
            <p className="fw-500">Type of currency accounts to open</p>
            <div className="row">
                <div className="col-xl-4 col-md-6 mb-7 input-line">
                    <label>Currency (multiple select)</label>
                    <SelectField
                        multiple
                        form={form}
                        name="currency"
                        id="currency"
                        options={currencyOptions || []}
                        placeholder={!form.watch('currency')?.length && 'Choose Currency'}
                        hideInputHelperText
                        autoComplete="off"
                    />
                </div>
            </div>
        </>
    )
}

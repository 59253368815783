import { TextField } from '@mui/material';
import React from 'react';
import AntSwitch from '../pages/services/components/kyc-elements/AntSwitch';

const SwitchFormikShareholderCorp = ({ formik, disabled, checked, setChecked }) => {
    return (
        <>
            <div className="d-flex">
                Number to be alloted{' '}
                <AntSwitch
                    name="type_shareholder"
                    checked={checked}
                    onChange={(e) => {
                        setChecked(e.target.checked);
                    }}
                    disabled={disabled}
                />{' '}
                Percentage to be alloted
            </div>
            <div className="mt-2">
                {checked ? (
                    <TextField
                        name="own_percent"
                        type="number"
                        label=""
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ endAdornment: <div style={{ width: 20 }}>%</div> }}
                        disabled={disabled}
                        value={formik.values.own_percent}
                        onChange={formik.handleChange}
                        error={formik.touched.own_percent && Boolean(formik.errors.own_percent)}
                        helperText={formik.touched.own_percent && formik.errors.own_percent}
                    />
                ) : (
                    <TextField
                        name="number_allotted"
                        type="number"
                        label=""
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ endAdornment: <div style={{ width: 70 }}>shares</div> }}
                        disabled={disabled}
                        value={formik.values.number_allotted}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.number_allotted && Boolean(formik.errors.number_allotted)
                        }
                        helperText={formik.touched.number_allotted && formik.errors.number_allotted}
                    />
                )}
            </div>
        </>
    );
};

export default SwitchFormikShareholderCorp;


import React from 'react';
import InputField from 'components/form-controls/InputField';
import { InputAdornment } from '@mui/material';

export default function ExpectedTurnover({ form }) {
    return (
        <>
            <p className="fw-500">Expected turnover in the next 3 years:</p>
            <div className="row">
                <div className="col-xl-3 col-md-4 mb-7 input-line">
                    <div style={{maxWidth: '170px'}}>
                        <label>Year 1</label>
                        <InputField
                            type="number"
                            form={form}
                            name="expected_year1"
                            placeholder="Number"
                            InputProps={{
                                endAdornment: (<InputAdornment position="end">USD</InputAdornment>)
                            }}
                            hideSuccessHelper
                        />
                    </div>
                </div>

                <div className="col-xl-3 col-md-4 mb-7 input-line">
                    <div style={{maxWidth: '170px'}}>
                        <label>Year 2</label>
                        <InputField
                            type="number"
                            form={form}
                            name="expected_year2"
                            placeholder="Number"
                            InputProps={{
                                endAdornment: (<InputAdornment position="end">USD</InputAdornment>)
                            }}
                            hideSuccessHelper
                        />
                    </div>
                </div>

                <div className="col-xl-3 col-md-4 mb-7 input-line">
                    <div style={{maxWidth: '170px'}}>
                        <label>Year 3</label>
                        <InputField
                            type="number"
                            form={form}
                            name="expected_year3"
                            placeholder="Number"
                            InputProps={{
                                endAdornment: (<InputAdornment position="end">USD</InputAdornment>)
                            }}
                            hideSuccessHelper
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export const updateQueryParameters = (uri, key, value) => {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
}

export const removeParam = (key, sourceURL) => {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
}

// *connectParams:
// CASE1: attribute of values is number or string 
// values=[{category:2},{offset:1},...]; 
// searchUrl="" || ?otherParams=1 ; 
// params =["category","offset"]
// return according by searchUrl : '?category=2&offset=1' || '?otherParams=1&category=2&offset=1'

// CASE2: if attribute of values is object or array 
// values=[{category:[2,3,4]},{offset:1},...]; 
// searchUrl="" || ?otherParams=1 ; 
// params =[{name:"category",condition:'condition to parse category'},"offset"]
// return according by searchUrl : '?category=[2,3,4]&offset=1' || '?otherParams=1&category=[2,3,4]&offset=1'

export const connectParams = (searchUrl, values, params) => {
    params.forEach((param) => {
        let name = param, condition = null;
        if (typeof param !== 'string') {
            name = param.name;
            condition = param.condition;
        }
        searchUrl = (condition ?? values[name])
            ? updateQueryParameters(searchUrl, name,
                Array.isArray(values[name])
                    ? JSON.stringify(values[name])
                    : values[name])
            : removeParam(name, searchUrl);
    })

    return searchUrl;
}
import React from 'react';
import { ProcessWaiting } from './waiting';

const IMAGE_SRC = "/media/bank-process/waiting/running-man.svg";

export function RunningManWaiting() {
    return (
        <>
            <ProcessWaiting imageSrc={IMAGE_SRC} />
        </>
    )
}
import React, { useEffect, useState } from 'react';
import './check-name.scss';

const StartupNameGenerator = require('@rstacruz/startup-name-generator');

export function RecommendName({ input, fieldName, setFieldValue, form }) {
    const [recommendNames, setRecommendNames] = useState([]);

    function getRecommendName(input) {
        try {
            let results = [];
            if (input.length > 0) {
                let items = StartupNameGenerator(input);
                items.forEach((item, index) => {
                    if (index < 3) {
                        results.push(item.trim());
                    }
                });
            }
            return results;
        } catch (error) { console.log(error) }
    }

    useEffect(() => {
        try {
            if (input.length > 0) {
                setRecommendNames(getRecommendName(input));
            }
        } catch (error) { console.log(error) }
    }, [input])

    return (
        <>
            {(recommendNames.length > 0) &&
                <div className="recommend-name mt-2">
                    <p>Name hints:</p>
                    <ul className="recommend-list">
                        {recommendNames.map((item, index) =>
                            <li
                                key={index}
                                onClick={() => {
                                    form.trigger(fieldName);
                                    form.setValue(fieldName, item)
                                    setTimeout(() => {
                                        setRecommendNames([]);
                                    }, 0);
                                    if (document.getElementById(fieldName)) {
                                        document.getElementById(fieldName).focus();
                                    }
                                }}
                            >{item}</li>
                        )}
                    </ul>
                </div>
            }
        </>
    )
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {useNavigate, useParams} from 'react-router-dom';

import { CircularProgress, Step, StepButton, Stepper } from '@mui/material';
import { BusinessPlanForm } from './business-plan-form/BusinessPlanForm';
// import { ApplicationForm } from './application-form/ApplicationForm_old';
import Deposit from './deposit/deposit';
import ApplicationSet from './application-set/ApplicationSet';
import ApplicationForm from './application-form/ApplicationForm';
import { updateActiveProcess } from '../../../../_redux/banking/slice';
import { toast } from 'react-toastify';
import { ApplicationResult } from './application-result/ApplicationResult';
import { MeetingSchedule } from './meeting-schedule/MeetingSchedule';
import { WaitForApprove } from './meeting-schedule/WaitForApprove';
import VideoCall from './meeting-schedule/video-call';
import KYCPage from './kyc/KYCPage';
import WaitingAddToCalendar from './meeting-schedule/WaitingAddToCalendar';
import { getLinkContentRoute } from '../../OrderManagermentUIHelpers';
import { LINK } from 'app/pages/services/constants/banking_constants';
import BankingProcessStep from './banking-process/banking-process-step';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

export function BankingProcessStepper({onStepNext}) {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate()

    // const [steps, setSteps] = useState(() => getSteps());
    const [activeStep, setActiveStep] = useState(0);
    const dispatch = useDispatch();
    const [loadingActiveProcess, setLoadingActiveProcess] = useState('idle');

    const { orderInfo } = useSelector((state) => state.banking);

    const { processTree, activeProcess, currentProcessID } = useSelector((state) => state.banking);

    useEffect(() => {
        if (!activeProcess) return;
        if (!processTree.length) return;

        processTree.forEach((process, index) => {
            if (activeProcess.id === process.id) {
                setActiveStep(index);
            }
        });
    }, [processTree, activeProcess]);

    const handleNext = async (params = {}) => {
        onStepNext && onStepNext(params);
    };

    function getStepContent(processStepId) {
        // console.log("131: ",processStepId, activeProcess)
        switch (processStepId) {
            case 39:
                return (
                    <>
                        {/* Check approved to show component waiting */}
                        {activeProcess?.Processes?.[0].is_approved ? (
                            <WaitForApprove secondScreen={false} />
                        ) : (
                            <BusinessPlanForm
                                activeStep={activeStep}
                                setActiveStep={setActiveStep}
                            />
                        )}
                    </>
                );
            case 47:
                return (
                    <>
                        {activeProcess?.Processes?.[0].is_approved ? (
                            <WaitForApprove secondScreen={false} />
                        ) : (
                            <KYCPage onNext={handleNext} />
                        )}
                    </>
                );
            case 40:
                return (
                    <>
                        {activeProcess?.Processes?.[0].is_approved ? (
                            <WaitForApprove secondScreen={false} />
                        ) : (
                            <ApplicationSet onBack={() => setActiveStep(0)} onNext={handleNext} />
                        )}
                    </>
                );
            case 41:
                return (
                    <>
                        {
                            !activeProcess?.Processes?.[0].is_approved && <MeetingSchedule onNext={handleNext} />
                        }
                        {
                            activeProcess?.Processes?.[0].is_approved && orderInfo.Task.Processes?.[0].status === 1 ?
                            <WaitingAddToCalendar orderInfo={orderInfo} /> : null
                        }
                        {
                            activeProcess?.Processes?.[0].is_approved && orderInfo.Task.Processes?.[0].status !== 1 ?
                            <WaitForApprove secondScreen={orderInfo?.Task?.BankingProcesses?.[0]?.Bank.id !== 19} /> : null
                        }
                    </>
                );
            case 42:
                return (
                    <>
                        {activeProcess?.Processes?.[0].is_approved ? (
                            <WaitForApprove secondScreen={false} />
                        ) : (
                            <VideoCall onNext={handleNext} />
                        )}
                    </>
                );
            case 43:
                return (
                    <>
                        {activeProcess?.Processes?.[0].is_approved ? (
                            <WaitForApprove secondScreen={false} />
                        ) : (
                            <ApplicationForm onNext={handleNext} />
                        )}
                    </>
                );
            case 44:
                return (
                    <>
                        {activeProcess?.Processes?.[0].is_approved ? (
                            <WaitForApprove secondScreen={false} />
                        ) : (
                            <Deposit onNext={handleNext} />
                        )}
                    </>
                );
            case 45:
                return (
                    <ApplicationResult onNext={handleNext} />
                );
            case 50:
                return (
                    <BankingProcessStep onNext={handleNext} />
                );
            default:
                return 'Unknown step';
        }
    }


    const conditionalRender = () => {
        if(Array.isArray(processTree) && processTree.length > 0){
            return getStepContent(processTree?.[activeStep]?.id)
        }
        if(Array.isArray(processTree) && processTree.length === 0){
            return navigate(getLinkContentRoute(LINK.BANKING_TOOL, id));
        }
        return 'Loading...'
    }

    return (
        <>
            <div className={classes.root}>
                <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                    {processTree?.map((process, index) => {
                        return (
                            <Step key={process.id}>
                                <StepButton
                                    className="step-title no-click"
                                    disabled={true}
                                    onClick={() => {
                                        if (!process.Processes[0]) {
                                            toast.error('Step do not ready now!');
                                            return;
                                        }
                                        dispatch(updateActiveProcess(process));
                                    }}
                                >
                                    {process.name}
                                </StepButton>
                            </Step>
                        );
                    })}
                </Stepper>
                <div className={classes.instructions}>
                    {loadingActiveProcess === 'loading' ? (
                        <div className={'d-flex align-items-center justify-content-center w-100'}>
                            <CircularProgress />
                        </div>
                    ) : (
                        conditionalRender()
                    )}
                </div>
            </div>
        </>
    );
}

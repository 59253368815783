/* eslint-disable no-restricted-imports */
import React, { useEffect, useState, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useParams, useNavigate } from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import OrderFormIncorporationPage from './instruction-incorporation/OrderFormIncorporationPage';
import KYCPage from './kyc/KYCPage';
// import SignatureTemplate from './signature-template/SignatureTemplate';
import DocumentManagement from './document-controller/DocumentManagement';
import * as actionsEnum from 'app/pages/_redux/enum/enumActions';
import * as actionsOrderForm from 'app/pages/_redux/order-form/orderFormActions';
import { PROCESSES, isIncorporationStepHidden } from 'app/pages/services/constants';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps(signIncorpChecker) {
    if (signIncorpChecker === null) return [];
    if (signIncorpChecker) {
        return ['Order Form', 'KYC Documents', 'Sign order form'];
    } else {
        return ['Order Form', 'KYC Documents', 'Sign order form', 'Sign incorporation form'];
    }
}

export function OrderDetail() {
    const classes = useStyles();
    const { id } = useParams();
    const [process, setProcess] = useState(null);
    // const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState(new Set());
    const [skipped, setSkipped] = useState(new Set());
    const [steps, setSteps] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSignIncorpState, setIsSignIncorpState] = useState(null);
    const [isIncorpEssentialHK, setIsIncorpEssentialHK] = useState(false);

    // useEffect(() => {
    //     try {}
    //     catch (error) { console.log(error) }
    // }, [])
    const activeStep = process?.activeStep || 0;
    const setNewProcessByData = useCallback(
        () =>
            new Promise((resolve, reject) => {
                dispatch(actionsOrderForm.fetchOrderForm(id)).then((data) => {
                    if (!data) {
                        navigate('/order-management', { replace: true });
                    }
                    let currentProcess = PROCESSES[0];
                    const processTemps = JSON.parse(
                        JSON.stringify(data?.data?.Task?.Processes || []),
                    );
                    setIsIncorpEssentialHK(data?.data?.Package?.id === 99);
                    const orderTemp = JSON.parse(JSON.stringify(data?.data || {}));
                    processTemps.forEach((pTemp) => {
                        let checker = {};
                        for (const item of PROCESSES) {
                            if (item.process_step_id === pTemp.process_step_id) {
                                checker = { ...item, ...pTemp };
                                break;
                            }
                        }
                        if (checker.priority > currentProcess.priority) {
                            currentProcess = checker;
                        }
                    });
                    // const Task =
                    // setActiveStep(currentProcess.activeStep);
                    setProcess(currentProcess);
                    setIsSignIncorpState(isIncorporationStepHidden(orderTemp));
                    setSteps(getSteps(isIncorporationStepHidden(orderTemp)));
                    resolve(currentProcess);
                });
            }),
        [dispatch, id],
    );
    useEffect(() => {
        // dispatch(actionsOrderForm.fetchOrderForm('13580'));
        dispatch(actionsEnum.fetchCountriesNonRestricted());
        try {
            // let currentActiveStep = 1;
            // switch(id) {
            //     case '1111': currentActiveStep = 1;
            //         break;
            //     case '2222': currentActiveStep = 2;
            //         break;
            //     case '3333': currentActiveStep = 4;
            //         break;
            //     case '4444': currentActiveStep = 3;
            //         break;
            //     case '5555': currentActiveStep = 0;
            //         break;
            //     default: currentActiveStep = 0;
            //         break;
            // }

            setNewProcessByData();
        } catch (error) {
            console.log(error);
        }
    }, [dispatch, setNewProcessByData]);

    function getStepContent(step) {
        const approving = process?.is_approved || 0;
        switch (step) {
            case 0:
                return (
                    <OrderFormIncorporationPage
                        activeStep={activeStep}
                        setActiveStep={setNewProcessByData}
                    />
                );
            case 1:
                return approving === 1 ? (
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ minHeight: 470 }}
                    >
                        <img
                            src="/media/order-form/approving-kyc.svg"
                            className="img-fluid"
                            width={400}
                            height={300}
                            alt="Your documents are being processed. It will take around 2-3 days."
                        />
                    </div>
                ) : (
                    <KYCPage setNewProcessByData={setNewProcessByData} process={process} />
                );
            case 2:
                return approving === 1 ? (
                    isIncorpEssentialHK ? (
                        <div className="p-5 m-auto" style={{ maxWidth: '1000px' }}>
                            <div
                                className="d-flex justify-content-center"
                                style={{ marginBottom: '24px' }}
                            >
                                <img
                                    src="/media/order-form/incorp-hk.png"
                                    width={192}
                                    height={207}
                                    alt="Your documents are being processed. It will take around 2-3 days."
                                />
                            </div>
                            <p>
                                Congratulations! Your Hong Kong business is successfully registered.
                                Confirmation details will be provided within 2-3 days. Thank you for
                                your patience
                            </p>
                            <p>
                                It would be appreciated if you could take a moment to leave us a
                                review on{' '}
                                <Button variant="text">
                                    <a
                                        href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                        target={'_blank'}
                                    >
                                        <img
                                            src="/media/logos/google.png"
                                            alt="google-review"
                                            style={{ height: '20px' }}
                                        />
                                    </a>
                                </Button>
                                and/or{' '}
                                <Button variant="text">
                                    <a
                                        href="https://www.trustpilot.com/review/bbcincorp.com"
                                        target={'_blank'}
                                    >
                                        <img
                                            src="/media/logos/trustpilot.png"
                                            alt="trust-pilot-review"
                                            style={{ height: '20px' }}
                                        />
                                    </a>
                                </Button>
                                . Your feedback is invaluable to our team and will help motivate us
                                to continue enhancing our services in the future.
                            </p>
                            <p>Have a great day!</p>
                        </div>
                    ) : (
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ minHeight: 470 }}
                        >
                            <img
                                src="/media/order-form/approving-sign-order.svg"
                                className="img-fluid"
                                width={400}
                                height={300}
                                alt="Your documents are being processed. It will take around 2-3 days."
                            />
                        </div>
                    )
                ) : (
                    <DocumentManagement
                        process={process}
                        setNewProcessByData={setNewProcessByData}
                        stepIndex={step}
                    />
                );

            case 3:
                return approving === 1 ? (
                    <div className="p-5 m-auto" style={{ maxWidth: '580px' }}>
                        <img
                            src="/media/order-form/approving-incorporation.svg"
                            className="img-fluid"
                            width={400}
                            height={300}
                            alt="Your documents are being processed. It will take around 2-3 days."
                        />
                        <p>Thank you for your kind cooperation </p>
                        <p>
                            Your documents are currently being processed and should be completed
                            within 2-3 days. We will reach out to you with the results soon. If you
                            have any questions or concerns, please don't hesitate to contact us.
                        </p>
                        <p>
                            We would greatly appreciate it if you were satisfied with our services
                            and could spread the word about us by leaving a review on{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.google.com/maps/place/BBCIncorp+Pte.+Ltd.+-+Singapore+Company+Incorporation/@1.2864388,103.8469513,17z/data=!3m1!5s0x3e574e142056e5a1:0xe9720c3dd5f8c5d2!4m14!1m5!8m4!1e1!2s103575538838412529587!3m1!1e1!3m7!1s0x31da19372db45a45:0x2a24ebaf0a639f6b!8m2!3d1.2864334!4d103.84914!9m1!1b1!16s%2Fg%2F11j0bgv3jn?hl=en"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/google.png"
                                        alt="google-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            and/or{' '}
                            <Button variant="text">
                                <a
                                    href="https://www.trustpilot.com/review/bbcincorp.com"
                                    target={'_blank'}
                                >
                                    <img
                                        src="/media/logos/trustpilot.png"
                                        alt="trust-pilot-review"
                                        style={{ height: '20px' }}
                                    />
                                </a>
                            </Button>
                            .
                        </p>
                        <p>
                            Your feedback will help us gain valuable insight into our services and
                            motivate our team to strive for even better results in the future.
                        </p>
                        <p>Thank you for your continued support!</p>
                    </div>
                ) : (
                    <DocumentManagement
                        process={process}
                        setNewProcessByData={setNewProcessByData}
                        stepIndex={step}
                    />
                );
            default:
                return 'Unknown step';
        }
    }

    const totalSteps = () => {
        return getSteps(isSignIncorpState).length;
    };

    const isStepOptional = (step) => {
        return step === 1;
    };

    // const handleSkip = () => {
    //     if (!isStepOptional(activeStep)) {
    //         // You probably want to guard against something like this
    //         // it should never occur unless someone's actively trying to break something.
    //         throw new Error("You can't skip a step that isn't optional.");
    //     }

    //     setNewProcessByData((prevActiveStep) => prevActiveStep + 1);
    //     setSkipped((prevSkipped) => {
    //         const newSkipped = new Set(prevSkipped.values());
    //         newSkipped.add(activeStep);
    //         return newSkipped;
    //     });
    // };

    const skippedSteps = () => {
        return skipped.size;
    };

    const completedSteps = () => {
        return completed.size;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps() - skippedSteps();
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed
                  // find the first step that has been completed
                  steps.findIndex((step, i) => !completed.has(i))
                : activeStep + 1;

        setNewProcessByData(newActiveStep);
    };

    // const handleBack = () => {
    //     setNewProcessByData((prevActiveStep) => prevActiveStep - 1);
    // };

    // const handleStep = (step) => () => {
    //     setNewProcessByData(step);
    // };

    // const handleComplete = () => {
    //     const newCompleted = new Set(completed);
    //     newCompleted.add(activeStep);
    //     setCompleted(newCompleted);

    //     /**
    //      * Sigh... it would be much nicer to replace the following if conditional with
    //      * `if (!this.allStepsComplete())` however state is not set when we do this,
    //      * thus we have to resort to not being very DRY.
    //      */
    //     if (completed.size !== totalSteps() - skippedSteps()) {
    //         handleNext();
    //     }
    // };

    // const handleReset = () => {
    //     setNewProcessByData(0);
    //     setCompleted(new Set());
    //     setSkipped(new Set());
    // };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    function isStepComplete(step) {
        return completed.has(step);
    }

    return (
        <Card>
            <CardHeader title={'Order Details INC.' + id}></CardHeader>
            <CardBody>
                {process || steps.length > 0 ? (
                    <div className={classes.root}>
                        <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const buttonProps = {};
                                // if (isStepOptional(index)) {
                                //     buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                                // }
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepButton
                                            // onClick={handleStep(index)}
                                            className="step-title no-click"
                                            disableTouchRipple
                                            completed={isStepComplete(index)}
                                            {...buttonProps}
                                        >
                                            {label}
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <div className={classes.instructions}>{getStepContent(activeStep)}</div>

                        {/* <div>
                        {allStepsCompleted() ? (
                            <div>
                                <div className={classes.instructions}>
                                    All steps completed - you&apos;re finished
                                </div>
                                <Button onClick={handleReset}>Reset</Button>
                            </div>
                        ) : (
                            <div>
                                <div className={classes.instructions}>
                                    {getStepContent(activeStep)}
                                </div>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        // className={classes.button}
                                    >
                                        Next
                                    </Button>
                                    {isStepOptional(activeStep) && !completed.has(activeStep) && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSkip}
                                            // className={classes.button}
                                        >
                                            Skip
                                        </Button>
                                    )}

                                    {activeStep !== steps.length &&
                                        (completed.has(activeStep) ? (
                                            <Typography
                                                variant="caption"
                                                className={classes.completed}
                                            >
                                                Step {activeStep + 1} already completed
                                            </Typography>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleComplete}
                                            >
                                                {completedSteps() === totalSteps() - 1
                                                    ? 'Finish'
                                                    : 'Complete Step'}
                                            </Button>
                                        ))}
                                </div>
                            </div>
                        )}
                    </div> */}
                    </div>
                ) : (
                    <div>Loading</div>
                )}
            </CardBody>
        </Card>
    );
}

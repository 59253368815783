import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import Swal from 'sweetalert2';
import { ALL_ACCEPT_EXTENSION, isFilesAccept } from 'constants/extensions_accept';
import { handleScanFile } from 'app/helpers/scanfile';

const progressStyle = {
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgb(255 255 255 / 50%)',
    zIndex: '2',
};

const UploadBox = React.forwardRef(({ onUploadFile, loading, panelStyle = {} }, ref) => {
    const [isLoading, setIsLoading] = useState(false);

    const inputOnChange = async (e) => {
        try {
            setIsLoading(true);
            if (!isFilesAccept(e.target.files, ALL_ACCEPT_EXTENSION)) {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Error',
                    text: 'Accept extensions: ' + ALL_ACCEPT_EXTENSION.join(', '),
                    showConfirmButton: false,
                    timer: 3000,
                });
                document.getElementById('upload-files-input').value = '';
                return;
            }
            const { files } = await handleScanFile(e.target.files);
            const inValidFiles = [...files].filter((file) => file.isValid === false);
            const validFiles = [...files].filter((file) => file.isValid === true);
            onUploadFile && onUploadFile(validFiles);
            if (inValidFiles.length > 0) {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    iconColor: 'red',
                    title: 'Oops... Upload failed.',
                    text:
                        inValidFiles.map((file, index) => `${index > 0 ? ' ' : ''} ${file.name}`) +
                        `${inValidFiles.length > 1 ? ' files are' : ' file is'}` +
                        ' invalid. Please upload an appropriate file.',
                    confirmButtonText: 'Try again',
                    confirmButtonColor: '#3085d6',
                    showCloseButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        document.getElementById('upload-files-input').click();
                    }
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
            document.getElementById('upload-files-input').value = '';
        }
    };

    return (
        <div className={'position-relative'}>
            <label
                htmlFor="upload-files-input"
                style={{
                    border: '1px dashed #c2c2c2',
                    zIndex: '2',
                    ...panelStyle,
                }}
                className={
                    'd-flex align-items-center justify-content-center flex-column rounded cursor-pointer p-5 mb-3'
                }
            >
                <img
                    id="upload-area-01"
                    src="/media/svg/icons/Order/upload-file.svg"
                    width="30px"
                    height="30px"
                    alt="upload"
                />
                <p>Upload your file here</p>
                <input
                    id={'upload-files-input'}
                    type="file"
                    ref={ref}
                    multiple
                    className={'d-none'}
                    disabled={isLoading}
                    onChange={(event) => {
                        inputOnChange(event);
                        // resetInput();
                    }}
                />
            </label>
            {loading ||
                (isLoading && (
                    <div
                        className={
                            'position-absolute d-flex align-items-center justify-content-center'
                        }
                        style={progressStyle}
                    >
                        <CircularProgress />
                    </div>
                ))}
        </div>
    );
});

export default UploadBox;

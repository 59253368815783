import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { CheckCircle } from '@mui/icons-material';

const isUploaded = (currentMember, currentTemplate ) => {
    return (
        (
            currentTemplate?.Files?.filter(
                (file) =>
                    file.company_member_id &&
                    file.company_member_id == currentMember.id,
            ) || []
        ).length > 0
    );
};

const DocumentCorporateMember = ({
    companyMemberTypes,
    onSelectMember,
    autoSelectFirstMember,
    activeTemplate,
}) => {
    const [value, setValue] = useState(0);
    const [activeMember, setActiveMember] = useState(null);
    const [selectedAuto, setSelectedAuto] = useState(false);
    useEffect(() => {
        if (autoSelectFirstMember && !selectedAuto) {
            setActiveMember(companyMemberTypes?.[0]?.titles?.[0]);
            onSelectMember && onSelectMember(companyMemberTypes?.[0]?.titles?.[0]);
            setSelectedAuto(true);
        }
    }, []);

    const isActiveMember = (member) => {
        return member.id === activeMember?.id;
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setActiveMember(companyMemberTypes?.[newValue]?.titles?.[0]);
        onSelectMember && onSelectMember(companyMemberTypes?.[newValue]?.titles?.[0]);
    };

    return (
        <div style={{ margin: '16px 35.25px' }}>
            <Typography variant={'h6'}>Corporate Member</Typography>
            <TabContext value={value}>
                <TabList onChange={handleChange}>
                    {companyMemberTypes.map((item, index) => (
                        <Tab key={index} label={item.name} value={index} />
                    ))}
                </TabList>
                {companyMemberTypes.map((item, index) => (
                    <TabPanel key={index} value={index} index={index}>
                        <ol className="pl-0">
                            {item.titles.map((member) => (
                                <li
                                    className={
                                        'cursor-pointer mb-1 text-uppercase ' +
                                        (isActiveMember(member)
                                            ? 'font-weight-bolder'
                                            : 'text-black-50')
                                    }
                                    onClick={() => {
                                        setActiveMember(member);
                                        onSelectMember(member);
                                    }}
                                >
                                    <span>{member.name}</span>
                                    {isUploaded(member, activeTemplate) && (
                                        <CheckCircle
                                            color="primary"
                                            className="d-inline ml-2"
                                            style={{ fontSize: '0.9rem', marginBottom: 2 }}
                                        />
                                    )}
                                </li>
                            ))}
                        </ol>
                    </TabPanel>
                ))}
            </TabContext>
        </div>
    );
};

export default DocumentCorporateMember;

import React from 'react';
import { Button, Grid } from '@mui/material';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import { CAYMAN_ID, UK_ID, BELIZE_ID } from 'constants/country';
import Swal from 'sweetalert2';
import { TreeSelect } from 'antd';
import { Controller } from 'react-hook-form';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CompanyInfoForm = ({
    form,
    task,
    onSubmit,
    disabled,
    handleBack,
    isLoading,
    isHideAction,
    proposedName,
    businessActivity,
    countriesOptions,
    companySuffixOptions,
    businessActivityOptions,
    businessAnnualReportOptions,
    showGeneralDescription,
    originData,
    allowFields,
}) => {

    return (
        <form onSubmit={onSubmit && form.handleSubmit(onSubmit)}>
            <Grid container rowSpacing={2} columnSpacing={4}>
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Country of incorporation</label>
                    <InputField form={form} name="company_country" disabled />
                </Grid>
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Company Type</label>
                    <InputField form={form} name="company_type" disabled />
                </Grid>
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Proposed company name</label>
                    <InputField
                        form={form}
                        id="company_name"
                        name="company_name"
                        placeholder="Proposed company name"
                        disabled
                        hideSuccessHelper
                        showIcon
                        hideAutoCompleteBrowser
                    />
                </Grid>
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Company suffix</label>
                    <SelectField
                        form={form}
                        name="company_suffix_id"
                        options={companySuffixOptions || []}
                        disabled
                        hideSuccessHelper
                        hideInputHelperText
                        autoComplete="off"
                    />
                </Grid>
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Business activities</label>
                    {task?.Company?.country_id === BELIZE_ID ? (
                        <>
                            <Controller
                                name="business_activity_array"
                                control={form.control}
                                render={({ field }) => (
                                    <TreeSelect
                                        treeData={businessActivityOptions}
                                        style={{
                                            width: '100%',
                                            borderBottom: `${
                                                form.formState.errors.business_activity_array
                                                    ? '1px solid #f64e60'
                                                    : '1px solid #949494'
                                            }`,
                                            marginTop: '4px',
                                            fontFamily: 'Poppins',
                                            backgroundColor:
                                                allowFields?.business_activity_array &&
                                                form.getValues('business_activity_array')?.length >
                                                    0
                                                    ? 'rgba(176, 236, 209, 0.5)'
                                                    : 'inherit',
                                        }}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        placeholder="Business activity"
                                        allowClear
                                        multiple
                                        showSearch
                                        variant="borderless"
                                        treeExpandAction={'click'}
                                        suffixIcon={<ArrowDropDownIcon />}
                                        onChange={(value) => {
                                            field.onChange(value);
                                            let temp = originData
                                                ?.filter((item) => value?.includes(item.title))
                                                ?.map((item) => ({
                                                    id: item?.id,
                                                    parent_id: item?.parent_id,
                                                }));

                                            businessActivityOptions.forEach((business) => {
                                                temp.forEach((item, index) => {
                                                    if (business?.children?.length > 0) {
                                                        business.children.forEach((child) => {
                                                            if (child?.id === item?.parent_id) {
                                                                temp[index].parent_id = business.id;
                                                            }
                                                        });
                                                    }
                                                });
                                            });
                                            const parentId = temp?.map((item) => item.parent_id);
                                            const seen = new Set();
                                            for (const element of parentId) {
                                                if (seen.has(element)) {
                                                    form.setValue(
                                                        'business_activity_array',
                                                        originData
                                                            .filter((data) =>
                                                                temp
                                                                    .filter(
                                                                        (item) =>
                                                                            item?.parent_id !==
                                                                            element,
                                                                    )
                                                                    ?.map((item) => item?.id)
                                                                    .includes(data.id),
                                                            )
                                                            ?.map(
                                                                (item) =>
                                                                    `${item.sic_code}-${item.name}`,
                                                            ),
                                                    );
                                                    return Swal.fire({
                                                        icon: 'warning',
                                                        title: 'Warning!',
                                                        text: "Please don't select 2 elements of the same category",
                                                        confirmButtonColor: '#17c191',
                                                    });
                                                }
                                                seen.add(element);
                                            }
                                        }}
                                        value={field.value}
                                        disabled={disabled || !allowFields?.business_activity_array}
                                    />
                                )}
                            />
                            {form.formState.errors.business_activity_array && (
                                <p
                                    style={{
                                        color: '#f64e60',
                                        marginTop: '3px',
                                        fontSize: '.9rem',
                                    }}
                                >
                                    Business activities is required!
                                </p>
                            )}
                        </>
                    ) : (
                        <SelectField
                            multiple
                            form={form}
                            name="business_activity_array"
                            id="business_activity_array"
                            placeholder={
                                !form.watch('business_activity_array')?.length &&
                                'Please select specific business activities.'
                            }
                            getOptionLabel={(option) => option.description}
                            options={businessActivityOptions || []}
                            hideSuccessHelper
                            hideInputHelperText
                            autoComplete="off"
                            customOnChange
                            onChange={(value) => {
                                if (
                                    value &&
                                    businessActivity &&
                                    value.length > businessActivity.length
                                ) {
                                    form.clearErrors('business_activity_array');
                                    if (task?.Company?.country_id === UK_ID && value.length > 4) {
                                        // UK max 4 item
                                        Swal.fire({
                                            icon: 'warning',
                                            title: 'Warning!',
                                            text: 'Maximum 4 business activities!',
                                            confirmButtonColor: '#17c191',
                                        });
                                        form.setValue(
                                            'business_activity_array',
                                            form.getValues('business_activity_array'),
                                        );
                                        return;
                                    }
                                    if (task?.Company?.country_id === 17 && value.length > 3) {
                                        // RAK max 3 item
                                        Swal.fire({
                                            icon: 'warning',
                                            title: 'Warning!',
                                            text: 'Maximum 3 business activities!',
                                            confirmButtonColor: '#17c191',
                                        });
                                        form.setValue(
                                            'business_activity_array',
                                            form.getValues('business_activity_array'),
                                        );
                                        return;
                                    }
                                }
                                form.setValue(
                                    'business_activity_array',
                                    value.map((item) => item.id || item),
                                    {
                                        shouldDirty: true,
                                    },
                                );
                            }}
                            disabled={disabled || !allowFields?.business_activity_array}
                            customStyle={{
                                '.MuiInputBase-root': {
                                    backgroundColor:
                                        allowFields?.business_activity_array &&
                                        form.getValues('business_activity_array')?.length > 0
                                            ? 'rgba(176, 236, 209, 0.5)'
                                            : 'inherit',
                                },
                            }}
                        />
                    )}
                    {form.watch('business_activity_array')?.length > 0 && (
                        <div className="mt-7">
                            <label>Details of Business activities</label>
                            <InputField
                                form={form}
                                id="business_activity_detail"
                                name="business_activity_detail"
                                multiline
                                maxRows={5}
                                showIcon
                                hideSuccessHelper
                                disabled={disabled || !allowFields?.business_activity_detail}
                                customStyle={{
                                    '.MuiInputBase-root': {
                                        backgroundColor:
                                            allowFields?.business_activity_detail &&
                                            form.getValues('business_activity_detail') !== ''
                                                ? 'rgba(176, 236, 209, 0.5)'
                                                : 'inherit',
                                    },
                                }}
                            />
                        </div>
                    )}
                </Grid>
                {task?.Company?.country_id === CAYMAN_ID && showGeneralDescription && (
                    <>
                        <Grid className="input-line" item sm={6} xs={12}>
                            <label>General description for annual report</label>
                            <SelectField
                                multiple
                                form={form}
                                name="business_annual_report"
                                id="business_annual_report"
                                placeholder={
                                    !form.watch('business_annual_report')?.length &&
                                    'Please select specific General description for annual report'
                                }
                                getOptionLabel={(option) => option.description}
                                options={businessAnnualReportOptions || []}
                                hideSuccessHelper
                                hideInputHelperText
                                autoComplete="off"
                                disabled={disabled || !allowFields?.business_annual_report}
                                customStyle={{
                                    '.MuiInputBase-root': {
                                        backgroundColor:
                                            allowFields?.business_annual_report &&
                                            form.getValues('business_annual_report')?.length > 0
                                                ? 'rgba(176, 236, 209, 0.5)'
                                                : 'inherit',
                                    },
                                }}
                            />
                        </Grid>
                    </>
                )}
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Source of funds</label>
                    <InputField
                        form={form}
                        name="source_fund"
                        placeholder="Please provide specific details"
                        showIcon
                        hideSuccessHelper
                        disabled={disabled || !allowFields?.source_fund}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.source_fund && form.getValues('source_fund') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                {task?.Company?.country_id === CAYMAN_ID && showGeneralDescription && (
                    <Grid item sm={6} xs={0}/>
                )}
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Country/countries of operation</label>
                    <SelectField
                        multiple
                        form={form}
                        name="country_operations"
                        id="country_operations"
                        placeholder={
                            !form.watch('country_operations')?.length &&
                            'Choose country/countries of operation'
                        }
                        getOptionLabel={(option) => option.label}
                        options={countriesOptions || []}
                        hideSuccessHelper
                        hideInputHelperText
                        autoComplete="off"
                        disabled={disabled || !allowFields?.country_operations}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.country_operations &&
                                    form.getValues('country_operations')?.length > 0
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid item sm={6} xs={0} />
                <Grid className="input-line" item sm={6} xs={12}>
                    <label>Operating address</label>
                    <InputField
                        form={form}
                        name="address"
                        placeholder="Please provide specific details"
                        showIcon
                        hideSuccessHelper
                        disabled={disabled || !allowFields?.address}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.address && form.getValues('address') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid item sm={6} xs={12} />
                <Grid className="input-line" md={3} item sm={6} xs={12}>
                    <label>Country</label>
                    <SelectField
                        form={form}
                        name="operating_country_id"
                        id="operating_country_id"
                        placeholder={'Choose country'}
                        options={countriesOptions || []}
                        hideInputHelperText
                        hideSuccessHelper
                        autoComplete="off"
                        disabled={disabled || !allowFields?.operating_country_id}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.operating_country_id &&
                                    form.getValues('operating_country_id') !== null
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid className="input-line" item md={3} sm={6} xs={12}>
                    <label>City</label>
                    <InputField
                        form={form}
                        name="city"
                        placeholder="Your city"
                        showIcon
                        hideSuccessHelper
                        hideAutoCompleteBrowser
                        disabled={disabled || !allowFields?.city}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.city && form.getValues('city') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid className="input-line" item md={3} sm={6} xs={12}>
                    <label>State/Province</label>
                    <InputField
                        form={form}
                        name="state"
                        placeholder="Your State/Province"
                        showIcon
                        hideSuccessHelper
                        hideAutoCompleteBrowser
                        disabled={disabled || !allowFields?.state}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.state && form.getValues('state') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                <Grid className="input-line" item md={3} sm={6} xs={12}>
                    <label>ZIP/Postal code</label>
                    <InputField
                        form={form}
                        name="postal_code"
                        placeholder="Your ZIP/Postal code"
                        showIcon
                        hideSuccessHelper
                        hideAutoCompleteBrowser
                        disabled={disabled || !allowFields?.postal_code}
                        customStyle={{
                            '.MuiInputBase-root': {
                                backgroundColor:
                                    allowFields?.postal_code && form.getValues('postal_code') !== ''
                                        ? 'rgba(176, 236, 209, 0.5)'
                                        : 'inherit',
                            },
                        }}
                    />
                </Grid>
                {!isHideAction && (
                    <Grid item xs={12}>
                        <Button
                            size="large"
                            variant="contained"
                            disableElevation
                            disabled={isLoading || form.isSubmitting}
                            onClick={handleBack}
                            className={'mr-2'}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            type="submit"
                            style={{ color: '#fff' }}
                            disabled={isLoading}
                        >
                            Next
                        </Button>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};

export default CompanyInfoForm;

import InputField from 'components/form-controls/InputField';
import React, { useEffect, useState } from 'react';
import NOMINEE_MEMBER from 'app/pages/services/order-management/images/nominee-member.svg';

const NomineeForm = ({ form, name, disabled, companyMemberType, isLLC = false, allowField }) => {
    const [title, setTitle] = useState('');

    useEffect(() => {
        try {
            let titleTemp = '';
            switch (companyMemberType) {
                case 1:
                    titleTemp = isLLC ? 'Manager' : 'Director';
                    break;
                case 2:
                    titleTemp = isLLC ? 'Member' : 'Shareholder';
                    break;
            }
            setTitle(titleTemp);
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <div className="nominee" style={{ marginBottom: '32px'}}>
            <p className="fw-500 mb-2">Nominee {title}</p>
            <div style={{ width: '192px' }}>
                <img src={NOMINEE_MEMBER} style={{ maxWidth: '100%' }} alt="nominee-member" />
            </div>
            <div className="mt-5 ml-2 input-line" style={{ width: '392px', maxWidth: '100%' }}>
                <label>Reason to use Nominee</label>
                <InputField
                    form={form}
                    name={name}
                    placeholder="Note reason to use Nominee"
                    multiline
                    rows={5}
                    hideSuccessHelper
                    showIcon
                    disabled={disabled || !allowField}
                    customStyle={{
                        '.MuiInputBase-root': {
                            backgroundColor:
                                allowField &&
                                form.getValues(name) !== ''
                                    ? 'rgba(176, 236, 209, 0.5)'
                                    : 'inherit',
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default NomineeForm;

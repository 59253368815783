import { toast } from 'react-toastify';
import * as requestFromServer from './supportCrud';
import { supportsSlice, callTypes } from './supportSlice';

const { actions } = supportsSlice;

export const fetchSupports = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .findSupports(queryParams)
        .then((response) => {
            const { count, rows } = response.data.data;
            dispatch(actions.supportsFetched({ count, rows }));
        })
        .catch((error) => {
            error.clientMessage = "Can't find supports";
            toast.error('Something went wrong!');
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchSupport = (id) => (dispatch) => {
    if (!id) {
        return dispatch(actions.supportFetched({ supportCaseOrForEdit: undefined }));
    }

    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getSupportById(id)
        .then((response) => {
            dispatch(actions.supportFetched({ supportCaseForEdit: response.data.data }));
        })
        .catch((error) => {
            error.clientMessage = "Can't find support";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error('Something went wrong!');
        });
};

export const createSupport = (supportForCreation) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .createSupport(supportForCreation)
        .then((response) => {
            dispatch(actions.supportCreated());
            toast.success(`Support Created  Successfully!`);
        })
        .catch((error) => {
            error.clientMessage = "Can't create support";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error('Something went wrong!');
        });
};

export const updateSupport = (support) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .updateSupport(support)
        .then((response) => {
            dispatch(actions.supportUpdated({ support }));
            toast.success(`Support No.${response.data.data.id} Updated Successfully!`);
        })
        .catch((error) => {
            error.clientMessage = "Can't update support";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error('Something went wrong!');
        });
};

export const subjectSupport = (id) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .subjectSupport(id)
        .then((response) => {
            const subject = response.data.data;
            dispatch(actions.supportSubjectFetched({ subject }));
        })
        .catch((error) => {
            error.clientMessage = "Can't update support";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error('Something went wrong!');
        });
};

export const fetchMessage = (id) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getMessageById(id)
        .then((response) => {
            // const message = response.data.data;
            // dispatch(actions.messageFetched({ message: message.rows, messageCount: message.count }));
            const { count, rows } = response.data.data;
            dispatch(actions.messageFetched({ rows, count }));
        })
        .catch((error) => {
            error.clientMessage = "Can't find message";
            toast.error('Something went wrong!');
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const ratingSupport = (id) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .ratingSupport(id)
        .then((response) => {
            const rating = response.data.data;
            dispatch(actions.supportRatingFetched({ rating }));
        })
        .catch((error) => {
            error.clientMessage = "Can't update support";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error('Something went wrong!');
        });
};

export const sendMessage = (body) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .sendMessage(body)
        .then((response) => {
            const { data } = response.data;
            dispatch(actions.sendMessage({ data }));
            toast.success(`Message send Successfully!`);
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't update support";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error('Something went wrong!');
        });
};

export const sendRating = (body) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .sendRating(body)
        .then((response) => {
            const { data } = response.data;
            dispatch(actions.sendRating({ data }));
            toast.success(`Rating Successfully!`);
            return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't update rating";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
            toast.error('Something went wrong!');
        });
};

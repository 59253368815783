import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';

import { enumSlice } from 'app/pages/_redux/enum/enumSlice';
import { incorporationSlice } from 'app/pages/_redux/incorporation/incorporationSlice';
import * as auth from 'app/modules/Auth/_redux/authRedux';
import { customersSlice } from 'app/modules/ECommerce/_redux/customers/customersSlice';
import { productsSlice } from 'app/modules/ECommerce/_redux/products/productsSlice';
import { remarksSlice } from 'app/modules/ECommerce/_redux/remarks/remarksSlice';
import { specificationsSlice } from 'app/modules/ECommerce/_redux/specifications/specificationsSlice';
import { billingsSlice } from 'app/pages/_redux/billings/billingsSlice';
import { companiesSlice } from 'app/pages/_redux/companies/companiesSlice';
import { personalSlice } from 'app/pages/_redux/personal/personalSlice';
import { invoicesSlice } from 'app/pages/_redux/invoices/invoicesSlice';
import { filesSlice } from 'app/pages/_redux/files/filesSlice';
import { supportsSlice } from 'app/pages/_redux/supportCase/supportSlice';
import { messagesSlice } from 'app/pages/_redux/messages/messageSlice';
import { dashboardSlice } from 'app/pages/_redux/dashboard/dashboardSlice';
import { notificationsSlice } from 'app/pages/_redux/notification/notificationSlice';
import { orderFormSlice } from 'app/pages/_redux/order-form/orderFormSlice';
import { proposedNameSlice } from 'app/pages/_redux/proposed-name/proposedNameSlice';
import {emailSignatureSlice} from "../app/pages/_redux/email-signature/emailSignatureSlice";
import { bankingToolSlice } from 'app/pages/_redux/banking-tool/bankingToolSlice';
import bankingSlice from 'app/pages/_redux/banking/slice';
import additionalServiceSlice from 'app/pages/_redux/additional-service/slice';

export const rootReducer = combineReducers({
    // const appReducer = combineReducers({
    auth: auth.reducer,
    customers: customersSlice.reducer,
    products: productsSlice.reducer,
    remarks: remarksSlice.reducer,
    specifications: specificationsSlice.reducer,
    personal: personalSlice.reducer,
    enum: enumSlice.reducer,
    bankingTool: bankingToolSlice.reducer,
    incorporation: incorporationSlice.reducer,
    billings: billingsSlice.reducer,
    companies: companiesSlice.reducer,
    invoices: invoicesSlice.reducer,
    files: filesSlice.reducer,
    supports: supportsSlice.reducer,
    messages: messagesSlice.reducer,
    dashboard: dashboardSlice.reducer,
    notifications: notificationsSlice.reducer,
    orderForm: orderFormSlice.reducer,
    proposedName: proposedNameSlice.reducer,
    emailSignature: emailSignatureSlice.reducer,
    banking: bankingSlice,
    additionalService: additionalServiceSlice,
});

// for notification
export const actionTypes = {
    getNoti: '[GetNotification] Action',
};

export const reducer = persistReducer(
    { storage, key: 'bbcincorp', whitelist: [] },
    (state, action) => {
        switch (action.type) {
            case actionTypes.getNoti: {
                // console.log(action);
                const { Notification } = action.payload;
                return { Notification };
            }

            default:
                return state;
        }
    },
);
// export const rootReducer = (state, action) => {
//     // if (action.type === '[Logout] Action') {
//     //     if (state) {
//     //         console.log(action)
//     //         console.log(state.personal)
//     //     }
//     //     // return appReducer(undefined, action);
//     // }
//     return appReducer(state, action);
//     // if (action.type !== '[Logout] Action') return rootReducer(state, action);

//     // const newState = appReducer(undefined, {});
//     // newState.router = state.router;
//     // return newState;
// };

export function* rootSaga() {
    yield all([auth.saga()]);
}

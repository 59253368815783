import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import * as actionsIncorporation from 'app/pages/_redux/incorporation/incorporationActions';
import { checkProposedName } from 'app/pages/components/check-name/check-proposed-name';
import { RecommendName } from 'app/pages/components/check-name/recommend-name';
import { get, head, isEmpty } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import * as actionsEnum from '../../../../../_redux/enum/enumActions';
import * as actionsOrderForm from '../../../../../_redux/order-form/orderFormActions';
import * as actionsProposedName from '../../../../../_redux/proposed-name/proposedNameActions';
import PreventiveComponent from './preventive';
import { TreeSelect } from 'antd';

import { yupResolver } from '@hookform/resolvers/yup';
import LabelTooltip from 'app/components/LabelTooltip';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import { CAYMAN_ID, RAK_ID, UK_ID, US_ID, BELIZE_ID } from 'constants/country';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
    // tooltip
    arrow: {
        color: '#3783e7',
    },
    tooltip: {
        fontSize: '12px',
        fontWeight: 300,
        letterSpacing: '0.3px',
        backgroundColor: '#3783e7',
    },
}));

Yup.addMethod(Yup.string, 'checkProposedName', checkProposedName);

function updateSelectableFlag(node) {
    if (node.children && node.children.length > 0) {
        node.selectable = false;
        node.children.forEach((child) => updateSelectableFlag(child));
    } else {
        node.selectable = true;
    }
}

const buildTree = (data) => {
    const map = {};
    let tree = [];

    data.forEach((item) => {
        const newItem = { ...item };
        newItem.title = `${item.sic_code}-${item.name}`;
        newItem.value = `${item.sic_code}-${item.name}`;
        map[newItem.id] = { ...newItem, children: [] };
    });

    data.forEach((item) => {
        const current = map[item.id];
        const parentItem = map[item.parent_id];

        if (parentItem) {
            parentItem.children.push(current);
        } else {
            tree.push(current);
        }
        map[item.id] = current;
    });

    // Recursively set selectable for children
    tree.forEach((child) => updateSelectableFlag(child));

    return tree;
};

export default function Step2({
    setActiveStep,
    activeStep,
    body,
    setBody,
    isSVG,
    disabled,
    hideAction,
    validateStepsRef = {},
    activeStepRef = {},
    index,
}) {
    const classes = useStyles();
    const [initCompanyInfo, setInitCompanyInfo] = useState({
        isSVG: isSVG,
        company_country: '',
        company_name: '',
        company_type: '',
        company_suffix_id: 0,
        preventives: [],
        business_activity_array: [],
        business_activity_detail: '',
        source_fund: '',
        country_operations: [],
        operation_address: '',
        operating_country_id: '',
        city: '',
        state: '',
        postal_code: '',
        business_annual_report: [],
    });
    const [companySuffixOptions, setCompanySuffixOptions] = useState([]);
    const [companySuffix, setCompanySuffix] = useState({ id: 0, name: '' });
    const [country, setCountry] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [businessActivity, setBusinessActivity] = useState([]);
    const [businessActivityOptions, setBusinessActivityOptions] = useState([]); // list business attivity option
    const [businessAnnualReportOptions, setBusinessAnnualReportOptions] = useState([]); // list business anual report option
    const [companyNameLabel, setCompanyNameLabel] = useState('');
    const [hintInput, setHintInput] = useState('');
    const [addressHint, setAddressHint] = useState(null);
    const [showGeneralDescription, setShowGeneralDescription] = useState(false);
    const [originData, setOriginData] = useState([]);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actionsIncorporation.fetchCompanyInfo(body.Company.country_id));
        dispatch(actionsProposedName.fetchProposedName(body.Task.id));
    }, []);

    const { orderInfo, incorporationCountry, businessActivityData, proposedName } = useSelector(
        (state) => ({
            orderInfo: state.orderForm.orderInfo,
            incorporationCountry: state.incorporation.entity,
            businessActivityData: state.enum.businessActivity,
            proposedName: state.proposedName.entities,
        }),
        shallowEqual,
    );
    const { geoLooking, countriesOptions } = useSelector((state) => state.enum);

    Yup.addMethod(Yup.string, 'business_annual_report', function (errorMessage, needRequired) {
        return this.test(`business_annual_report`, errorMessage, function (value) {
            const { path, createError } = this;
            if (!needRequired) return true;
            return (needRequired && value) || createError({ path, message: errorMessage });
        });
    });

    const schema = Yup.object().shape({
        company_name: Yup.string()
            .nullable()
            .checkProposedName(
                null,
                companyNameLabel,
                dispatch,
                proposedName || [],
                body.Company.country_id,
            ),
        company_suffix_id: Yup.string(),
        preventives: Yup.array().of(
            Yup.object().shape({
                name: Yup.string()
                    .nullable()
                    .checkProposedName(
                        null,
                        'Company name',
                        dispatch,
                        proposedName || [],
                        body.Company.country_id,
                    ),
                company_suffix_id: Yup.string(),
            }),
        ),
        business_activity_array: Yup.array()
            .nullable()
            .min(1, 'Business activities is required!')
            .required('Business activities is required!'),
        business_activity_detail: Yup.string().when('business_activity_array', {
            is: (value) => value?.length > 0,
            then: Yup.string()
                .nullable()
                .required('Details of Business activities is required!')
                .test({
                    message:
                        'Please write a complete description of your business activities at least 10 words',
                    test: (value) => value && value.trim().split(/\s+/).length >= 10,
                }),
        }),
        business_annual_report: Yup.array()
            .nullable()
            .when([], {
                is: () => showGeneralDescription,
                then: Yup.array().nullable().min(1, 'Business annual report is required!'),
            }),
        source_fund: Yup.string().nullable().required('Source of funds is required!'),

        country_operations: Yup.array()
            .nullable()
            .min(1, 'Countries operation is required!')
            .required('Countries operation is required!'),

        operation_address: Yup.string().required('Operating address is required!'),
        operating_country_id: Yup.string().required('Operation country is required!'),
        city: Yup.string().required('City is required!'),
    });

    const form = useForm({
        defaultValues: initCompanyInfo,
        resolver: yupResolver(schema),
    });

    const preventiveFields = useFieldArray({
        control: form.control,
        name: 'preventives',
        keyName: 'customId',
    });

    useEffect(() => {
        preventiveFields.remove();
    }, [body.id]);

    useEffect(() => {
        if (form.formState.isSubmitted) {
            if (form.getValues('business_activity_array').length === 0) {
                form.trigger('business_activity_array');
            }

            if (form.getValues('business_activity_detail').length === 0) {
                form.trigger('business_activity_detail');
            }
        }
    }, [form.watch('business_activity_array').length]);

    useEffect(() => {
        try {
            setIsLoading(true);
            if (businessActivityData) {
                let businessActivityDataTemp = [];
                const businessAnnualReportOptionsTemp = [];

                if ([UK_ID, RAK_ID, CAYMAN_ID].includes(orderInfo.Company.country_id)) {
                    businessActivityData[0].BusinessActivities.map((item) => {
                        businessActivityDataTemp.push({
                            ...item,
                            value: item.id,
                            label:
                                (item.sic_code ? '[' + item.sic_code + '] ' : '') +
                                item.description,
                        });
                    });
                }

                if (![UK_ID, RAK_ID, CAYMAN_ID, BELIZE_ID].includes(orderInfo.Company.country_id)) {
                    const businessActivities = get(
                        head(businessActivityData),
                        'BusinessActivities',
                    );
                    if (!isEmpty(businessActivities)) {
                        businessActivities.forEach((item) => {
                            businessActivityDataTemp.push({
                                ...item,
                                value: item.id,
                                label: item.name,
                            });
                        });
                    }
                }

                if (orderInfo.Company.country_id === BELIZE_ID) {
                    const temp = [];
                    businessActivityData.forEach((item) => {
                        temp.push({
                            ...item,
                            title: `${item.sic_code}-${item.name}`,
                        });
                    });
                    setOriginData(temp);
                    let tempData = buildTree(businessActivityData);
                    tempData.forEach((item) => {
                        businessActivityDataTemp.push(item);
                    });
                }

                if ([CAYMAN_ID].includes(orderInfo.Company.country_id)) {
                    businessActivityData[1].BusinessActivities.forEach((item2) => {
                        businessAnnualReportOptionsTemp.push({
                            ...item2,
                            value: item2.id,
                            label:
                                (item2.sic_code ? '[' + item2.sic_code + '] ' : '') +
                                item2.description,
                        });
                    });

                    setBusinessAnnualReportOptions(businessAnnualReportOptionsTemp);
                }

                setBusinessActivityOptions(businessActivityDataTemp);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }, [businessActivityData]);

    useEffect(() => {
        try {
            setIsLoading(true);
            if (body && body.companyInfo && body.contactInfo && countriesOptions?.length) {
                // Country
                let countryId = body.companyInfo.operating_country_id || body.Company.country_id;
                let country_reset = '';
                countryId &&
                    (country_reset = countriesOptions.find((item) => item.id === countryId) || '');
                !country_reset &&
                    geoLooking &&
                    (country_reset =
                        countriesOptions.find((item) => item.id === geoLooking.id) || '');
                country_reset = country_reset
                    ? { ...country_reset, value: country_reset.id, label: country_reset.id }
                    : country_reset;
                setCountry(country_reset);

                // == business_activity
                dispatch(actionsEnum.fetchBusinessActivity(body.Company.country_id));

                /* == business_activity cayman */
                if (body.Company.country_id === CAYMAN_ID) {
                    /* trường hợp nếu chưa có business activity (order mới) hoặc json parse ra object thì hiển thị select */
                    if (!body.Company.CompanyHasBusinessActivity) {
                        setShowGeneralDescription(true);
                    } else {
                        try {
                            let value =
                                typeof body.Company.CompanyHasBusinessActivity === 'string'
                                    ? JSON.parse(body.Company.CompanyHasBusinessActivity)
                                    : body.Company.CompanyHasBusinessActivity;
                            if (Array.isArray(value)) {
                                setShowGeneralDescription(true);
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }

                    /* trường hợp parse không ra thì hiển thị text (data cũ) */
                }

                const tempCountryOperations =
                    body?.companyInfo?.country_operations?.map((item) => item.id || item) || [];
                const business_activity_array =
                    body.Company.country_id === BELIZE_ID
                        ? body?.Company?.CompanyHasBusinessActivity?.map(
                              (item) => item.sic_code_name,
                          ) || []
                        : body?.Company?.CompanyHasBusinessActivity?.map((item) => item.id) || [];
                let business_annual_report;
                try {
                    if (typeof body.companyInfo.business_annual_report === 'string') {
                        business_annual_report = JSON.parse(
                            body.companyInfo.business_annual_report,
                        );
                    } else {
                        business_annual_report = body.companyInfo.business_annual_report;
                    }
                } catch (error) {
                    business_annual_report = [];
                }

                const companyInformation = {
                    company_country: body.companyInfo.company_country,
                    company_type: body.companyInfo.company_type,
                    company_name: body.companyInfo.company_name,
                    company_suffix_id: body.companyInfo.company_suffix_id || 0,
                    preventives: (body.companyInfo && body.companyInfo.preventives) || [],
                    business_activity_array: business_activity_array,
                    business_activity_detail: body.companyInfo.business_activity_detail || '',
                    source_fund: body.companyInfo.source_fund || '',
                    country_operations: tempCountryOperations || [],
                    operation_address: body.companyInfo.operation_address || '',
                    operating_country_id: country_reset?.id || '',
                    city: body.companyInfo.city || '',
                    state: body.companyInfo.state || '',
                    postal_code: body.companyInfo.postal_code || '',
                    business_annual_report: business_annual_report || [],
                };

                form.reset(companyInformation);

                // set address hint
                if (body.companyInfo && !body.companyInfo.operation_address) {
                    let countryTemp =
                        (countriesOptions &&
                            countriesOptions.find(
                                (e) => e.id === body.contactInfo.address_country_id,
                            )) ||
                        '';
                    let addressHintTemp = {
                        address: body.contactInfo.address,
                        country_id: countryTemp?.id || '',
                        city: body.contactInfo.city,
                        state: body.contactInfo.state,
                        postal_code: body.contactInfo.postal_code,
                        country: countryTemp,
                        full_address:
                            body.contactInfo.address +
                            ', ' +
                            body.contactInfo.city +
                            ', ' +
                            body.contactInfo.state +
                            (body.contactInfo.state ? ', ' : '') +
                            (countryTemp?.name || '') +
                            (body.contactInfo.postal_code ? ', ' : '') +
                            body.contactInfo.postal_code,
                    };
                    setAddressHint(addressHintTemp);
                }

                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }, [countriesOptions]);

    useEffect(() => {
        try {
            setIsLoading(true);
            if (incorporationCountry) {
                let EntityType = incorporationCountry.EntityTypes.find(
                    (e) => e.id === body.Company.entity_type_id,
                );
                if (EntityType) {
                    setCompanySuffixOptions(EntityType.CompanySuffixes);

                    let company_suffix = EntityType.CompanySuffixes.find(
                        (e) => e.id === body.companyInfo.company_suffix_id,
                    );

                    if (!company_suffix) {
                        company_suffix = EntityType.CompanySuffixes[0];
                    }

                    setCompanySuffix(company_suffix);
                    form.setValue('company_suffix_id', company_suffix.id);

                    setIsLoading(false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [incorporationCountry]);

    useEffect(() => {
        try {
            setIsLoading(true);
            if (proposedName) {
                let preventives = [];
                if (proposedName.length > 0) {
                    // filter list company name not body.Company.name
                    preventives = proposedName.filter(
                        (e) =>
                            !(
                                e.name === body.Company.name &&
                                e.company_suffix_id === body.Company.company_suffix_id
                            ),
                    );
                    form.setValue('preventives', preventives);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }, [proposedName]);

    const handleNext = (values) => {
        values['operating_country_id'] = Number(values['operating_country_id']);
        values['company_suffix_id'] = Number(values['company_suffix_id']);

        try {
            setIsLoading(true);

            let data = {};

            data = {
                companyInformationUpdate: {
                    id: body.company_id,
                    operating_country_id:
                        values.operating_country_id === 250 ? US_ID : values.operating_country_id,
                    business_activity_detail: values.business_activity_detail,
                    business_activity_array:
                        body.Company.country_id === BELIZE_ID
                            ? originData
                                  ?.filter((item) =>
                                      values.business_activity_array?.includes(item.title),
                                  )
                                  ?.map((item) => item.id)
                            : values.business_activity_array,
                    OperationCountries: values.country_operations,
                    address: values.operation_address,
                    operating_country: values.id,
                    operating_city: values.city,
                    operating_state: values.state,
                    operating_postal_code: values.postal_code,
                    source_fund: values.source_fund || '',
                    name: values.company_name,
                    company_suffix_id: values.company_suffix_id,
                },
            };
            if (values.business_annual_report?.length) {
                data.companyInformationUpdate.business_annual_report = JSON.stringify(
                    values.business_annual_report,
                );
            }

            let Promised = [dispatch(actionsOrderForm.updateOrderForm(body.Task.id, data))];

            if (values.preventives && values.preventives.length > 0) {
                let dataAddProposedName = { proposedNames: [] },
                    dataUpdateProposedName = { proposedNames: [] };
                values.preventives.map((item) => {
                    if (item.id) {
                        // update
                        if (proposedName.find((e) => e === item) === undefined) {
                            dataUpdateProposedName.proposedNames.push({
                                task_id: body.Task.id,
                                id: item.id,
                                name: item.name,
                                company_suffix_id: Number(item.company_suffix_id),
                            });
                        }
                    } else {
                        // add
                        dataAddProposedName.proposedNames.push({
                            task_id: body.Task.id,
                            company_id: body.Company.id,
                            name: item.name,
                            company_suffix_id: Number(item.company_suffix_id),
                        });
                    }
                });
                if (dataAddProposedName.proposedNames.length > 0) {
                    Promised.push(
                        dispatch(actionsProposedName.addProposedName(dataAddProposedName)),
                    );
                }
                if (dataUpdateProposedName.proposedNames.length > 0) {
                    Promised.push(
                        dispatch(actionsProposedName.updateProposedName(dataUpdateProposedName)),
                    );
                }
            }

            Promise.all(Promised).then((res) => {
                if (res && res.length > 0) {
                    setBody({
                        ...body,
                        companyInfo: { ...values },
                        Company: { ...body.Company, ...res?.[0]?.data?.Company },
                    });
                    if (res?.[0]?.state) {
                        // if (typeof activeStepRef.current === 'number') {
                        //     setActiveStep(activeStepRef.current);
                        // } else {
                        //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        // }
                        if (typeof activeStepRef.current !== 'number') {
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        }
                        activeStepRef.current = null;
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            });
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        try {
            if (companySuffix && companySuffix.is_prefix) {
                setCompanyNameLabel('Parent Company Name');
            } else {
                setCompanyNameLabel('Proposed Company Name');
            }
        } catch (error) {
            console.log(error);
        }
    }, [companySuffix]);

    const handleSubmit = (values) => {
        handleNext(values);
    };

    // truyền form validate cho file stepper
    validateStepsRef.current = {
        ...validateStepsRef.current,
        STEP_2: {
            isValid: form.formState.isValid,
            onSubmit: () => form.handleSubmit(handleSubmit)(),
        },
    };

    return (
        <div className="step2">
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-7 input-line input-readonly">
                            <label>Country of incorporation</label>
                            <InputField form={form} name="company_country" disabled />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-7 input-line input-readonly">
                            <label>Company Type</label>
                            <InputField form={form} name="company_type" disabled />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-7 input-line has-check-name">
                        <label>{companyNameLabel}</label>
                        <InputField
                            form={form}
                            id="company_name"
                            name="company_name"
                            placeholder={companyNameLabel}
                            disabled={
                                [false, true].includes(proposedName?.[0]?.is_approve) || disabled
                            }
                            onKeyUp={(e) => setHintInput(e.target.value)}
                            onBlur={() => {
                                setTimeout(() => {
                                    setHintInput('');
                                }, 200);
                            }}
                            hideSuccessHelper
                            showIcon
                            hideAutoCompleteBrowser
                        />
                        {companySuffix && !companySuffix.is_prefix && hintInput.length > 0 && (
                            <RecommendName
                                form={form}
                                input={hintInput}
                                fieldName={'company_name'}
                            />
                        )}
                    </div>
                    <div className="col-md-6 mb-7 input-line">
                        <label>
                            {companySuffix.is_prefix ? 'Company Prefix' : 'Company Suffixes'}
                        </label>
                        <SelectField
                            form={form}
                            id="company-suffixes"
                            name="company_suffix_id"
                            options={companySuffixOptions || []}
                            onChange={(value) => {
                                if (body && body.company_suffix_id != value.id) {
                                    setCompanySuffix(value);
                                    form.setValue('company_suffix_id', value.id);
                                }
                            }}
                            disabled={
                                !companySuffixOptions.length ||
                                [false, true].includes(proposedName?.[0]?.is_approve) ||
                                disabled
                            }
                            hideSuccessHelper
                            hideInputHelperText
                            autoComplete="off"
                        />
                    </div>
                </div>
                {companySuffix.is_prefix ? null : (
                    <div className="row">
                        {!hideAction && (
                            <div className="col-md-12 mb-7 d-flex align-items-center">
                                <h3 className="h6 mr-6">Other Proposed Names</h3>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    startIcon={<AddIcon />}
                                    disabled={isLoading || form.isSubmitting}
                                    onClick={(event) =>
                                        preventiveFields.append({
                                            name: '',
                                            company_suffix_id: '',
                                        })
                                    }
                                    style={{ margin: '0' }}
                                >
                                    Add
                                </Button>
                            </div>
                        )}

                        {/* preventive name */}
                        <div className="col-md-12 wrap-preventive">
                            {!isEmpty(preventiveFields.fields) &&
                                preventiveFields.fields.map((field, index, fields) => (
                                    <div key={field.id}>
                                        <PreventiveComponent
                                            form={form}
                                            name="preventives"
                                            index={index}
                                            body={body}
                                            fields={preventiveFields}
                                            isPrefix={companySuffix?.is_prefix}
                                            isLoading={isLoading || form.isSubmitting}
                                            companySuffixOptions={companySuffixOptions || []}
                                            disabled={disabled}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col-md-6 mb-7 input-line">
                        <LabelTooltip
                            label="Business activities"
                            title='Please give specific business activities within 20-30 words. General description of business such "Trading company", "Holding company", or "Investment company" is not sufficient.'
                        />
                        {body?.Company?.country_id === BELIZE_ID ? (
                            <>
                                <Controller
                                    name="business_activity_array"
                                    control={form.control}
                                    render={({ field }) => (
                                        <TreeSelect
                                            treeData={businessActivityOptions}
                                            style={{
                                                width: '100%',
                                                borderBottom: `${
                                                    form.formState.errors.business_activity_array
                                                        ? '1px solid #f64e60'
                                                        : '1px solid #949494'
                                                }`,
                                                marginTop: '4px',
                                                fontFamily: 'Poppins',
                                            }}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            placeholder="Business activity"
                                            allowClear
                                            multiple
                                            showSearch
                                            variant="borderless"
                                            treeExpandAction={'click'}
                                            suffixIcon={<ArrowDropDownIcon />}
                                            onChange={(value) => {
                                                field.onChange(value);
                                                let temp = originData
                                                    ?.filter((item) => value?.includes(item.title))
                                                    ?.map((item) => ({
                                                        id: item?.id,
                                                        parent_id: item?.parent_id,
                                                    }));

                                                businessActivityOptions.forEach((business) => {
                                                    temp.forEach((item, index) => {
                                                        if (business?.children?.length > 0) {
                                                            business.children.forEach((child) => {
                                                                if (child?.id === item?.parent_id) {
                                                                    temp[index].parent_id =
                                                                        business.id;
                                                                }
                                                            });
                                                        }
                                                    });
                                                });
                                                const parentId = temp?.map(
                                                    (item) => item.parent_id,
                                                );
                                                const seen = new Set();
                                                for (const element of parentId) {
                                                    if (seen.has(element)) {
                                                        form.setValue(
                                                            'business_activity_array',
                                                            originData
                                                                .filter((data) =>
                                                                    temp
                                                                        .filter(
                                                                            (item) =>
                                                                                item?.parent_id !==
                                                                                element,
                                                                        )
                                                                        ?.map((item) => item?.id)
                                                                        .includes(data.id),
                                                                )
                                                                ?.map(
                                                                    (item) =>
                                                                        `${item.sic_code}-${item.name}`,
                                                                ),
                                                        );
                                                        return Swal.fire({
                                                            icon: 'warning',
                                                            title: 'Warning!',
                                                            text: "Please don't select 2 elements of the same category",
                                                            confirmButtonColor: '#17c191',
                                                        });
                                                    }
                                                    seen.add(element);
                                                }
                                            }}
                                            value={field.value}
                                            disabled={disabled}
                                        />
                                    )}
                                />
                                {form.formState.errors.business_activity_array && (
                                    <p
                                        style={{
                                            color: '#f64e60',
                                            marginTop: '3px',
                                            fontSize: '.9rem',
                                        }}
                                    >
                                        Business activities is required!
                                    </p>
                                )}
                            </>
                        ) : (
                            <SelectField
                                multiple
                                form={form}
                                name="business_activity_array"
                                id="business_activity_array"
                                placeholder={
                                    !form.watch('business_activity_array')?.length &&
                                    'Please select specific business activities.'
                                }
                                getOptionLabel={(option) => option.description}
                                options={businessActivityOptions || []}
                                hideSuccessHelper
                                hideInputHelperText
                                autoComplete="off"
                                customOnChange
                                onChange={(value) => {
                                    if (
                                        value &&
                                        businessActivity &&
                                        value.length > businessActivity.length
                                    ) {
                                        form.clearErrors('business_activity_array');
                                        if (body.Company.country_id === UK_ID && value.length > 4) {
                                            // UK max 4 item
                                            Swal.fire({
                                                icon: 'warning',
                                                title: 'Warning!',
                                                text: 'Maximum 4 business activities!',
                                                confirmButtonColor: '#17c191',
                                            });
                                            form.setValue(
                                                'business_activity_array',
                                                form.getValues('business_activity_array'),
                                            );
                                            return;
                                        }
                                        if (
                                            body.Company.country_id === RAK_ID &&
                                            value.length > 3
                                        ) {
                                            // RAK max 3 item
                                            Swal.fire({
                                                icon: 'warning',
                                                title: 'Warning!',
                                                text: 'Maximum 3 business activities!',
                                                confirmButtonColor: '#17c191',
                                            });
                                            form.setValue(
                                                'business_activity_array',
                                                form.getValues('business_activity_array'),
                                            );
                                            return;
                                        }
                                    }
                                    form.setValue(
                                        'business_activity_array',
                                        value.map((item) => item.id || item),
                                    );
                                }}
                                disabled={disabled}
                            />
                        )}
                        {form.watch('business_activity_array')?.length > 0 && (
                            <div className="mt-7">
                                <LabelTooltip
                                    label="Details of Business activities"
                                    title="Please describe your business activities in this field as details as possible to process your order smoothy"
                                />
                                <InputField
                                    form={form}
                                    id="business_activity_detail"
                                    name="business_activity_detail"
                                    multiline
                                    maxRows={5}
                                    showIcon
                                    hideSuccessHelper
                                    disabled={disabled}
                                />
                            </div>
                        )}
                    </div>

                    {body?.Company?.country_id === CAYMAN_ID && showGeneralDescription && (
                        <div className={'col-md-6 mb-7 input-line'}>
                            <div className="wrap-label-has-tooltip">
                                <label>General description for annual report</label>
                            </div>
                            <SelectField
                                multiple
                                form={form}
                                name="business_annual_report"
                                id="business_annual_report"
                                placeholder={
                                    !form.watch('business_annual_report')?.length &&
                                    'Please select specific General description for annual report'
                                }
                                getOptionLabel={(option) => option.description}
                                options={businessAnnualReportOptions || []}
                                hideSuccessHelper
                                hideInputHelperText
                                autoComplete="off"
                                disabled={disabled}
                            />
                        </div>
                    )}

                    <div className="col-md-6 mb-7 input-line">
                        <LabelTooltip
                            label="Source of funds"
                            title="Kindly indicate the source of the Beneficial Owners funds to start the business."
                        />
                        <InputField
                            form={form}
                            name="source_fund"
                            placeholder="Please provide specific details"
                            showIcon
                            hideSuccessHelper
                            disabled={disabled}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-7 input-line">
                        <LabelTooltip
                            label="Country/countries of operation"
                            title="Please provide countries which your company will be managed/ controlled and countries of your partners"
                        />
                        <SelectField
                            multiple
                            form={form}
                            name="country_operations"
                            id="country_operations"
                            placeholder={
                                !form.watch('country_operations').length &&
                                'Choose country/countries of operation'
                            }
                            getOptionLabel={(option) => option.label}
                            options={countriesOptions || []}
                            hideSuccessHelper
                            hideInputHelperText
                            autoComplete="off"
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-md-6"></div>
                </div>

                <div className="row">
                    <div className="col-md-6 mb-4 input-line">
                        <LabelTooltip
                            label="Operating address"
                            title="Please specify the address that your company will be managed or controlled. It can be the address of your company's director/ shareholder/ UBO"
                        />
                        <InputField
                            form={form}
                            name="operation_address"
                            placeholder="Please provide specific details"
                            showIcon
                            hideSuccessHelper
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-md-6"></div>
                </div>

                {addressHint && (
                    <div className="address-hint mb-5">
                        <span>Address hint: </span>
                        <span
                            className="order-tag ml-1"
                            onClick={() => {
                                form.setValue('operation_address', addressHint.address);
                                form.setValue('operating_country_id', addressHint.country_id);
                                form.setValue('city', addressHint.city);
                                form.setValue('state', addressHint.state);
                                form.setValue('postal_code', addressHint.postal_code);

                                setCountry(addressHint.country || '');

                                setTimeout(() => {
                                    setAddressHint(null);
                                }, 0);
                            }}
                        >
                            {addressHint.full_address}
                        </span>
                    </div>
                )}

                <div className="row">
                    {/* group address */}
                    <div className="col-md-3 col-sm-6 mb-7 input-line">
                        <label>Country</label>
                        <SelectField
                            form={form}
                            name="operating_country_id"
                            id="operating_country_id"
                            placeholder={'Choose country'}
                            options={countriesOptions || []}
                            value={country}
                            onChange={(value) => {
                                setCountry(value);
                                form.setValue('operating_country_id', value.id);
                            }}
                            hideInputHelperText
                            hideSuccessHelper
                            autoComplete="off"
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-md-3 col-sm-6 mb-7 input-line">
                        <label>City</label>
                        <InputField
                            form={form}
                            name="city"
                            placeholder="Your city"
                            showIcon
                            hideSuccessHelper
                            hideAutoCompleteBrowser
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-md-3 col-sm-6 mb-7 input-line">
                        <label>State/Province</label>
                        <InputField
                            form={form}
                            name="state"
                            placeholder="Your State/Province"
                            showIcon
                            hideSuccessHelper
                            hideAutoCompleteBrowser
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-md-3 col-sm-6 mb-7 input-line">
                        <label>ZIP/Postal code</label>
                        <InputField
                            form={form}
                            name="postal_code"
                            placeholder="Your ZIP/Postal code"
                            showIcon
                            hideSuccessHelper
                            hideAutoCompleteBrowser
                            disabled={disabled}
                        />
                    </div>
                </div>
                {!hideAction && (
                    <div className={classes.actionsContainer}>
                        <div>
                            {activeStep > 0 && (
                                <Button
                                    size="large"
                                    variant="contained"
                                    disableElevation
                                    disabled={isLoading || form.isSubmitting}
                                    onClick={handleBack}
                                    className={'mr-2 ' + classes.button}
                                >
                                    Back
                                </Button>
                            )}
                            {activeStep < 3 && (
                                <Button
                                    variant="contained"
                                    type="submit"
                                    size="large"
                                    color="primary"
                                    disabled={isLoading || form.isSubmitting}
                                    className={classes.button}
                                    style={{ color: '#fff' }}
                                    onClick={() => (activeStepRef.current = null)}
                                >
                                    Next
                                </Button>
                            )}
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
}

import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';

export default function MemberRoleCheckbox({
    fieldName,
    index,
    body,
    onChange,
    values,
    representedLabel,
    form,
    disabled,
}) {
    function handleChange(e) {
        try {
            if (e.target.checked) {
                if (values && values.length > 0) {
                    values.forEach((item, itemIndex) => {
                        form.setValue(`members.${itemIndex}.${fieldName}`, false);
                    });
                }
            }
            form.setValue(`members.${index}.${fieldName}`, e.target.checked);
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <div className="represented">
                <Controller
                    control={form.control}
                    name={`members.${index}.${fieldName}`}
                    render={() => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values?.[index]?.[fieldName] ?? false}
                                    onChange={(e) => handleChange(e)}
                                    color="primary"
                                    disabled={disabled}
                                />
                            }
                            label={representedLabel}
                        />
                    )}
                />
            </div>
        </>
    );
}

import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import { getDefaultNationality } from 'app/pages/services/utilities/NationalityHelpers';
import * as Yup from 'yup';
import PageLoadingEffect from '../../../common/PageLoadingEffect';

import { updateBusinessPlanFormAction } from 'app/pages/_redux/banking/asyncThunk';
import { getContactInfo } from 'app/pages/services/order-management/order-form/instruction-incorporation/component/helpers';
import { toast } from 'react-toastify';
import { COUNTRIES_NOT_DISABLED_BANKING_FORM } from '../../../constant';

import { yupResolver } from '@hookform/resolvers/yup';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import { useForm } from 'react-hook-form';

const initFormDefault = {
    first_name: '',
    middle_name: '',
    last_name: '',
    full_name: '',
    email: '',
    phone_number: '',
    phone_country_id: '',
    address: '',
    address_country_id: '',
    state: '',
    city: '',
    postal_code: '',
};

const formValidationSchema = ({ hasShowSelectFullName }) => {
    return Yup.object().shape({
        first_name:
            hasShowSelectFullName && Yup.string().nullable().required('First name is required!'),
        middle_name: hasShowSelectFullName && Yup.string().nullable(),
        last_name:
            hasShowSelectFullName && Yup.string().nullable().required('Last name is required!'),
        full_name: Yup.string()
            .nullable()
            .required(
                hasShowSelectFullName ? 'Please select your full name!' : 'Full name is required!',
            ),
        email: Yup.string()
            .nullable()
            .required('Email is required!')
            .email('Please enter valid email!'),
        phone_number: Yup.string()
            .nullable()
            .matches(/^[0-9]*$/, 'Please enter number!')
            .required('Phone number is required!'),
        address: Yup.string().nullable().required('Address is required!'),
        city: Yup.string().nullable().required('City is required!'),
    });
};

export default function Step1({ activeStep, setActiveStep, hasShowSelectFullName }) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const form = useForm({
        defaultValues: initFormDefault,
        resolver: yupResolver(formValidationSchema({ hasShowSelectFullName })),
    });
    const { countries, orderInfo, geoLooking, state } = useSelector(
        (state) => ({
            countries: state.enum.countriesCooperation,
            orderInfo: state.banking.orderInfo,
            geoLooking: state.enum.geoLooking,
            state: state,
        }),
        shallowEqual,
    );

    const disabledStep = useMemo(() => {
        return (
            isLoading ||
            (orderInfo?.Company?.is_outside_company === false &&
                !COUNTRIES_NOT_DISABLED_BANKING_FORM.includes(orderInfo?.Company?.country_id))
        );
    }, [orderInfo]);

    useEffect(() => {
        try {
            if (countries && orderInfo) {
                const contactInfo = getContactInfo(orderInfo?.Company);
                const countryFound = getDefaultNationality(contactInfo?.address_country_id ?? null); // param: country_id
                const phoneCountryFound = getDefaultNationality(
                    contactInfo?.phone_country_id ?? orderInfo?.Task?.phone_country_id ?? null,
                ); // param: phone_country_id
                // setCountry(countryFound);
                // setPhoneCountry(phoneCountryFound)

                const temp = {
                    ...initFormDefault,
                    first_name: contactInfo?.first_name ?? '',
                    full_name: contactInfo?.full_name ?? '',
                    middle_name: contactInfo?.middle_name ?? '',
                    last_name: contactInfo?.last_name ?? '',
                    email: contactInfo?.email ?? '',
                    phone_number: contactInfo?.phone_number ?? '',
                    address: contactInfo?.address ?? '',
                    city: contactInfo?.city ?? '',
                    state: contactInfo?.state ?? '',
                    postal_code: contactInfo?.postal_code ?? '',
                    phone_country_id: contactInfo?.phone_country_id ?? phoneCountryFound.id,
                    address_country_id: contactInfo?.address_country_id ?? countryFound.id,
                };

                form.reset(temp);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }, [countries, orderInfo]);

    const handleNext = async (values) => {
        if (disabledStep) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            return;
        }

        const contactInformationUpdate = {
            first_name: hasShowSelectFullName ? values?.first_name : values?.full_name,
            middle_name: values?.middle_name,
            last_name: values?.last_name,
            full_name: values?.full_name,
            email: values.email,
            address: values.address,
            phone_country_id: values.phone_country_id,
            phone_number: values.phone_number,
            address_country_id: values.address_country_id,
            state: values.state,
            city: values.city,
            postal_code: values.postal_code,
            company_id: orderInfo?.Company?.id,
        };

        for (const key in contactInformationUpdate) {
            if (
                contactInformationUpdate[key] === null ||
                contactInformationUpdate[key] === '' ||
                contactInformationUpdate[key] === undefined
            ) {
                delete contactInformationUpdate[key];
            }
        }

        /* Start - Xử lý show error khi gọi tới be thất bại */
        setIsLoading(true);
        const resultAction = await dispatch(
            updateBusinessPlanFormAction({
                id: orderInfo.Task.id,
                params: { contactInformationUpdate },
            }),
        );
        if (updateBusinessPlanFormAction.fulfilled.match(resultAction)) {
            const data = resultAction.payload;
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            toast.error(
                resultAction?.payload?.data ||
                    resultAction?.error?.message ||
                    'Something went wrong!',
            );
        }
        /* End - Xử lý show error khi gọi tới be thất bại */
    };

    const handleSubmit = (values) => {
        handleNext(values);
    };

    return (
        <>
            <div className="step1 position-relative">
                <PageLoadingEffect loading={isLoading} />
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <div className="row">
                        {hasShowSelectFullName && (
                            <>
                                <div className="col-md-4 mb-7 input-line">
                                    <label>First name</label>
                                    <InputField
                                        form={form}
                                        name={'first_name'}
                                        placeholder="First name"
                                        showIcon
                                        hideSuccessHelper
                                        disabled={disabledStep}
                                        onChange={(e) => {
                                            form.setValue('first_name', e.target.value);
                                            if (form.getValues('full_name')) {
                                                form.setValue('full_name', '');
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-md-4 mb-7 input-line">
                                    <label>Middle name</label>
                                    <InputField
                                        form={form}
                                        name={'middle_name'}
                                        placeholder="Middle name"
                                        showIcon
                                        hideSuccessHelper
                                        disabled={disabledStep}
                                    />
                                </div>
                                <div className="col-md-4 mb-7 input-line">
                                    <label>Last name</label>
                                    <InputField
                                        form={form}
                                        name={'last_name'}
                                        placeholder="Last name"
                                        showIcon
                                        hideSuccessHelper
                                        disabled={disabledStep}
                                        onChange={(e) => {
                                            form.setValue('last_name', e.target.value);
                                            if (form.getValues('full_name')) {
                                                form.setValue('full_name', '');
                                            }
                                        }}
                                    />
                                </div>
                            </>
                        )}
                        <div className="col-md-6 mb-7 input-line">
                            <label>Full name</label>
                            <InputField
                                form={form}
                                name={'full_name'}  
                                placeholder="Enter your full name"
                                disabled={disabledStep || hasShowSelectFullName}
                                showIcon
                                hideSuccessHelper
                                multiline={hasShowSelectFullName}
                            />
                        </div>
                        <div className="col-md-6 mb-7 input-line">
                            <label>Email address</label>
                            <InputField
                                form={form}
                                name="email"
                                placeholder="Enter your email address"
                                disabled={disabledStep}
                                showIcon
                                hideSuccessHelper
                            />
                        </div>
                        <div className="col-md-6 mb-7 input-line">
                            <label>Phone number</label>
                            <div className="phone-group">
                                <div className="phone-code" style={{ zIndex: 1 }}>
                                    <SelectField
                                        form={form}
                                        className="fw-500"
                                        label={''}
                                        name="phone_country_id"
                                        id="phone-country-select"
                                        options={countries || []}
                                        getOptionLabel={(option) =>
                                            option.country_code + ' +' + option.phone_code
                                        }
                                        disabled={disabledStep}
                                        customStyle={{
                                            '.MuiInputBase-root:before, .MuiInputBase-root:after, .MuiInput-underline:hover:not(.Mui-disabled):before':
                                                {
                                                    borderBottom: '0 !important',
                                                },
                                        }}
                                        hideHelperText={true}
                                    />
                                </div>
                                <div className="input-line inp-phone">
                                    <InputField
                                        form={form}
                                        name="phone_number"
                                        placeholder="Phone number"
                                        disabled={disabledStep}
                                        showIcon
                                        hideSuccessHelper
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6" />
                        <div className="col-md-6 mb-7 input-line">
                            <label>Address</label>
                            <InputField
                                form={form}
                                name="address"
                                placeholder="Your Address"
                                disabled={disabledStep}
                                showIcon
                                hideSuccessHelper
                            />
                        </div>
                        <div className="col-md-6" />

                        {/* address group */}
                        <div className="col-md-3 col-sm-6 mb-7 input-line">
                            <label>Country</label>
                            <SelectField
                                form={form}
                                className="fw-500"
                                name="address_country_id"
                                id="address_country_id"
                                options={countries || []}
                                disabled={disabledStep}
                                hideHelperText={true}
                                autoComplete="off"
                            />
                        </div>
                        <div className="col-md-3 col-sm-6 mb-7 input-line mb-7">
                            <label>City</label>
                            <InputField
                                form={form}
                                name="city"
                                placeholder="Your city"
                                disabled={disabledStep}
                                showIcon
                                hideSuccessHelper
                            />
                        </div>
                        <div className="col-md-3 col-sm-6 mb-7 input-line">
                            <label>State/Province</label>
                            <InputField
                                form={form}
                                name="state"
                                placeholder="Your State/Province"
                                disabled={disabledStep}
                                showIcon
                                hideSuccessHelper
                            />
                        </div>
                        <div className="col-md-3 col-sm-6 mb-7 input-line">
                            <label>ZIP/Postal code</label>
                            <InputField
                                form={form}
                                name="postal_code"
                                placeholder="Your ZIP/Postal code"
                                disabled={disabledStep}
                                showIcon
                                hideSuccessHelper
                            />
                        </div>
                    </div>

                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                        style={{ color: '#fff' }}
                        disabled={isLoading}
                    >
                        Next
                    </Button>
                </form>
            </div>
        </>
    );
}

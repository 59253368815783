import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';

import ExistingMember from './existing-member';
import { CircularProgress } from '@mui/material';

export default function ExistingMemberDialog({
    index,
    body,
    setBody,
    fieldAction,
    companyMemberType,
    stateSwitch,
    setStateSwitch,
    form,
}) {
    const [open, setOpen] = useState(false);
    const [showExistingdMember, setShowExistingdMember] = useState(true);
    const [existingMemberList, setExistingMemberList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleOpen = () => {
        try {
            if (body && body.companyStructure) {
                let existingMemberListTemp = [];
                existingMemberListTemp = body.companyStructure.ContactPerson.concat(
                    body.companyStructure.Directors,
                );
                if (companyMemberType === 2) {
                    // Shareholder

                    if (body.companyStructure.UBO && body.companyStructure.UBO.length > 0) {
                        existingMemberListTemp = existingMemberListTemp.concat(
                            body.companyStructure.UBO,
                        );
                    }
                }
                if (companyMemberType === 3) {
                    // UBO
                    if (
                        body.companyStructure.Shareholders &&
                        body.companyStructure.Shareholders.length > 0
                    ) {
                        existingMemberListTemp = existingMemberListTemp.concat(
                            body.companyStructure.Shareholders,
                        );
                    }
                    existingMemberListTemp = existingMemberListTemp.filter(
                        (e) => e.individual === true,
                    );
                }

                // remove init member (empty member)
                existingMemberListTemp = existingMemberListTemp.filter((e) => e.id);

                // remove member same position
                if (form.watch('members').length > 0) {
                    form.watch('members').map((item) => {
                        item.id &&
                            (existingMemberListTemp = existingMemberListTemp.filter(
                                (e) => e.id !== item.id,
                            ));
                    });
                }

                // group member by company_member_type_id: company_member_type_ids = [id1, id2]
                let uniqueMembers = [];
                if (existingMemberListTemp.length > 0) {
                    uniqueMembers = existingMemberListTemp.reduce((accumulator, member) => {
                        let object = accumulator.find((item) => item && item.id === member.id);
                        if (object) {
                            object.company_member_type_ids.push(member.company_member_type_id);
                            return [...accumulator];
                        } else {
                            const company_member_type_ids = [];
                            if (member.company_member_type_id) {
                                company_member_type_ids.push(member.company_member_type_id);
                            }
                            if (member.company_member_type_id === 7) {
                                member.country_id = body?.contactInfo?.address_country_id;
                            }
                            return [...accumulator, { ...member, company_member_type_ids }];
                        }
                    }, []);
                }

                setExistingMemberList(uniqueMembers);

                setOpen(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {showExistingdMember && (
                <div className="wrap-existing-member">
                    <button
                        type="button"
                        className="btn-open-dialog fw-500 mb-2"
                        color="primary"
                        onClick={handleOpen}
                    >
                        Choose existing member
                    </button>
                    <Dialog
                        className="existing-member-dialog"
                        aria-labelledby="simple-dialog-title"
                        open={open}
                    >
                        <Card>
                            <CardHeader title="Existing member"></CardHeader>
                            <CardBody>
                                <p>
                                    If you change the information of existing member you select, it
                                    will also affect other positions containing the information.
                                </p>
                                <div className="existing-member-list">
                                    <div className="scroll-hight">
                                        {existingMemberList &&
                                            existingMemberList.length > 0 &&
                                            existingMemberList.map((member, memberIndex) => (
                                                <ExistingMember
                                                    mainMemberIndex={index}
                                                    body={body}
                                                    setBody={setBody}
                                                    fieldAction={fieldAction}
                                                    key={memberIndex}
                                                    handleCloseDialog={handleClose}
                                                    isLoading={isLoading}
                                                    setIsLoading={setIsLoading}
                                                    index={memberIndex}
                                                    member={member}
                                                    setShowExistingdMember={setShowExistingdMember}
                                                    companyMemberType={companyMemberType}
                                                    stateSwitch={stateSwitch}
                                                    setStateSwitch={setStateSwitch}
                                                />
                                            ))}
                                        {existingMemberList && existingMemberList.length === 0 && (
                                            <p>No existing member to choose.</p>
                                        )}
                                    </div>
                                    {isLoading && (
                                        <div className="loading-over">
                                            <CircularProgress color="primary" />
                                        </div>
                                    )}
                                </div>
                                <div className="text-center mt-3">
                                    <Button
                                        className="fw-500"
                                        size="large"
                                        variant="contained"
                                        disableElevation
                                        style={{ textTransform: 'none' }}
                                        disabled={isLoading}
                                        onClick={handleClose}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Dialog>
                </div>
            )}
        </>
    );
}

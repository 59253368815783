import React, { useState, useMemo } from 'react';
import {
    Modal,
    Collapse,
    Box,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Button,
    IconButton,
} from '@mui/material';
import Step1 from './step1';
import Step2 from './step2';
import Step4 from './step4';
import Step31 from './step3/step1';
import Step32 from './step3/step2';
import Step33 from './step3/step3';
import Step34 from './step3/step4';
import StepArticlesOfOrganization from './step3/step-articles-of-organization';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PreviewOrderFormStep5 from './PreviewOrderFormStep5';
import { isLLC, isUK_LLP } from 'app/pages/services/constants';
import { BVI_ID, PANAMA_ID } from 'constants/country';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflowY: 'scroll',
    maxHeight: '90%',
    paddingTop: 2,
};

function PreviewOrderForm({
    open,
    handleClose,
    body,
    currentValueStep5,
    isFromStep5,
    setBody = () => {},
}) {
    const [expand, setExpand] = useState({
        step1: true,
        step2: true,
        step3: true,
        step4: true,
        step5: true,
    });
    const IS_BELIZE_LLC = body?.isBelize && body?.isLLC;
    const IS_BELIZE_LLC_MEMBER_MANAGED =
        body?.isBelize && body?.isLLC && body?.Company?.articles_of_organization === 1;
    const IS_UK_LLP = isUK_LLP(body?.Company?.entity_type_id, body?.Company?.country_id);
    const IS_LLC = isLLC(body?.Company?.entity_type_id, body?.Company?.country_id);

    const hasShowSelectFullName = useMemo(() => {
        return [BVI_ID, PANAMA_ID].includes(body?.Company?.country_id);
    }, [body]);

    const steps = useMemo(() => {
        if (!body) return [];
        const conditionComponents = [];

        if (!IS_UK_LLP) {
            conditionComponents.push({
                label: IS_LLC ? 'Contributed Capital' : 'Share Capital',
                step: 'share-capital',
                content: <Step31 body={body} hideAction disabled />,
            });
        }
        if (IS_BELIZE_LLC) {
            conditionComponents.push({
                label: 'Article of organization',
                step: 'article-of-organization',
                content: <StepArticlesOfOrganization body={body} hideAction disabled />,
            });
        }
        if (!IS_BELIZE_LLC_MEMBER_MANAGED && !IS_UK_LLP) {
            conditionComponents.push({
                label: IS_LLC ? 'Manager' : 'Director',
                step: 'director',
                content: (
                    <Step32
                        body={body}
                        hideAction
                        disabled
                        hasShowSelectFullName={hasShowSelectFullName}
                    />
                ),
            });
        }
        const fixedComponents = [
            {
                label: IS_LLC || IS_UK_LLP ? 'Member' : 'Shareholder',
                step: 'shareholder',
                content: (
                    <Step33
                        body={body}
                        hideAction
                        disabled
                        hasShowSelectFullName={hasShowSelectFullName}
                    />
                ),
            },
            {
                label: 'Ultimate Beneficial Owners',
                step: 'ubo',
                content: (
                    <Step34
                        body={body}
                        setBody={setBody}
                        hideAction
                        disabled
                        hasShowSelectFullName={hasShowSelectFullName}
                    />
                ),
            },
        ];

        return conditionComponents.concat(fixedComponents);
    }, [body]);

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="preview-order-form"
            >
                <Box sx={style}>
                    <h2 style={{ marginLeft: 30 }}>Preview Order Form</h2>
                    <hr />
                    <h4 style={{ marginLeft: 50 }}>ORDER FORM</h4>
                    <Box sx={{ p: 3, paddingTop: '0px !important' }}>
                        <div>
                            <Stepper orientation="vertical">
                                <Step>
                                    <div className="d-flex justify-content-between">
                                        <StepLabel icon={1}>Contact information</StepLabel>
                                        {expand.step1 ? (
                                            <IconButton
                                                onClick={() =>
                                                    setExpand({ ...expand, step1: false })
                                                }
                                            >
                                                <ExpandLessIcon />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                onClick={() =>
                                                    setExpand({ ...expand, step1: true })
                                                }
                                            >
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                    <Collapse in={expand.step1}>
                                        <StepContent>
                                            <Step1
                                                body={body}
                                                hideAction
                                                disabled
                                                hasShowSelectFullName={hasShowSelectFullName}
                                            />
                                        </StepContent>
                                    </Collapse>
                                </Step>
                            </Stepper>
                        </div>
                        <div>
                            <Stepper orientation="vertical">
                                <Step>
                                    <div className="d-flex justify-content-between">
                                        <StepLabel icon={2}>Company information</StepLabel>
                                        {expand.step2 ? (
                                            <IconButton
                                                onClick={() =>
                                                    setExpand({ ...expand, step2: false })
                                                }
                                            >
                                                <ExpandLessIcon />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                onClick={() =>
                                                    setExpand({ ...expand, step2: true })
                                                }
                                            >
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                    <Collapse in={expand.step2}>
                                        <StepContent>
                                            <Step2
                                                body={body}
                                                isSVG={body?.Company?.country_id === 209}
                                                hideAction
                                                disabled
                                                hasShowSelectFullName={hasShowSelectFullName}
                                            />
                                        </StepContent>
                                    </Collapse>
                                </Step>
                            </Stepper>
                        </div>
                        <div>
                            <div>
                                <Stepper orientation="vertical">
                                    <Step sx={{ p: 0 }}>
                                        <div className="d-flex justify-content-between">
                                            <StepLabel icon={3}>Company structure</StepLabel>
                                            {expand.step3 ? (
                                                <IconButton
                                                    onClick={() =>
                                                        setExpand({ ...expand, step3: false })
                                                    }
                                                >
                                                    <ExpandLessIcon />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    onClick={() =>
                                                        setExpand({ ...expand, step3: true })
                                                    }
                                                >
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                            )}
                                        </div>

                                        <Collapse in={expand.step3}>
                                            <StepContent>
                                                {steps.map((step, index) => (
                                                    <>
                                                        <StepLabel icon={`3.${index + 1}`}>
                                                            {step.label}
                                                        </StepLabel>
                                                        <StepContent>{step.content}</StepContent>
                                                    </>
                                                ))}
                                            </StepContent>
                                        </Collapse>
                                    </Step>
                                </Stepper>
                            </div>
                        </div>
                        <div>
                            <Stepper orientation="vertical">
                                <Step>
                                    <div className="d-flex justify-content-between">
                                        <StepLabel icon={4}>Business information</StepLabel>
                                        {expand.step4 ? (
                                            <IconButton
                                                onClick={() =>
                                                    setExpand({ ...expand, step4: false })
                                                }
                                            >
                                                <ExpandLessIcon />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                onClick={() =>
                                                    setExpand({ ...expand, step4: true })
                                                }
                                            >
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        )}
                                    </div>

                                    <Collapse in={expand.step4}>
                                        <StepContent>
                                            <Step4
                                                body={body}
                                                isHongkong={body?.Company?.country_id === 98}
                                                isBahamas={body?.Company?.country_id === 17}
                                                isDelaware={body?.Company?.country_id === 250}
                                                isSVG={body?.Company?.country_id === 209}
                                                hideAction
                                                disabled
                                                hasShowSelectFullName={hasShowSelectFullName}
                                            />
                                        </StepContent>
                                    </Collapse>
                                </Step>
                            </Stepper>
                        </div>
                        <div>
                            <Stepper orientation="vertical">
                                <Step>
                                    <div className="d-flex justify-content-between">
                                        <StepLabel icon={5}>Declaration</StepLabel>
                                        {expand.step5 ? (
                                            <IconButton
                                                onClick={() =>
                                                    setExpand({ ...expand, step5: false })
                                                }
                                            >
                                                <ExpandLessIcon />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                onClick={() =>
                                                    setExpand({ ...expand, step5: true })
                                                }
                                            >
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        )}
                                    </div>

                                    <Collapse in={expand.step5}>
                                        <StepContent>
                                            <PreviewOrderFormStep5
                                                body={body}
                                                isHongkong={body?.Company?.country_id === 98}
                                                currentValue={currentValueStep5}
                                                isFromStep5={isFromStep5}
                                                disabled
                                            />
                                        </StepContent>
                                    </Collapse>
                                </Step>
                            </Stepper>
                        </div>
                        <Button size="large" variant="contained" onClick={handleClose}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default PreviewOrderForm;

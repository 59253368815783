import React from 'react';

export function CustomerLevel(props) {

    switch(props.value) {

        case 1:
            return (
                <img className="icon-level" src="/media/logos/silver-crown.svg" alt="" />
            );
        case 2:
            return(
                <img className="icon-level" src="/media/logos/golden-crown.svg" alt="" />
            );
        default:
            return null;
    }
};
import React, { useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import LabelTooltip from 'app/components/LabelTooltip';
import { useForm } from 'react-hook-form';
import PageLoadingEffect from '../../../../common/PageLoadingEffect';
import { ArticlesOfOrganizationOptions } from '../constants';
import { setArticlesOfOrganizationDefault } from '../helpers';

export default function StepArticlesOfOrganization({
    activeStep,
    setActiveStep,
    isLoading,
    setIsLoading,
    body,
    setBody, onSubmit
}) {
    const OPTIONS = ArticlesOfOrganizationOptions;
    const isInCorpCompany = useMemo(() => {
        return !body.Company.is_outside_company;
    }, [body])
    const disabledOption = (body && body.is_has_director) || (body && body.is_has_shareholder) || isInCorpCompany || false;
    const form = useForm({
        defaultValues: {
            articles_of_organization: body.Company.articles_of_organization,
        },
    });

    useEffect(() => {
        try {
            setIsLoading(true);
            if (body && body.isBelize && body.isLLC) {
                let articles_of_organization_default = setArticlesOfOrganizationDefault(body);
                form.setValue('articles_of_organization', articles_of_organization_default);
            }
        }
        catch (error) { 
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }, [])

    const onSubmitForm = (values) => {
        try {
            setIsLoading(true);
            let data = {
                articleOfOrganizationUpdate: {
                    // Nếu có service Nominee => Mặc định chọn Manager-managed, không thể sửa.
                    articles_of_organization: (body.is_has_director || body.is_has_shareholder) ? OPTIONS[1].id : values.articles_of_organization
                },
            };

            onSubmit && onSubmit({ data, isFinalStep: false, withoutSave: isInCorpCompany });
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <div className="step1 position-relative">
            <PageLoadingEffect loading={isLoading} />
            <form onSubmit={form.handleSubmit(onSubmitForm)}>
                <div className="mb-7">
                    <RadioGroup
                        row
                        form={form}
                        aria-label={'articles_of_organization'}
                        name={'articles_of_organization'}
                        onChange={(e)=>{
                            form.setValue('articles_of_organization', parseInt(e.target.value));
                            setBody({ ...body, Company: { ...body.Company, articles_of_organization: parseInt(e.target.value) } });
                        }}
                    >
                        <div className="d-flex align-items-center mr-md-20">
                            <FormControlLabel
                                value={OPTIONS[0].id}
                                checked={form.watch('articles_of_organization') === OPTIONS[0].id }
                                control={<Radio color="secondary" />}
                                label={OPTIONS[0].title}
                                className=" mr-0 mb-0"
                                disabled={disabledOption}
                            />
                            <LabelTooltip title={OPTIONS[0].tooltip} />
                        </div>

                        <div className="d-flex align-items-center">
                            <FormControlLabel
                                value={OPTIONS[1].id}
                                checked={form.watch('articles_of_organization') === OPTIONS[1].id }
                                control={<Radio color="secondary" />}
                                label={OPTIONS[1].title}
                                className=" mr-0 mb-0"
                                disabled={disabledOption}
                            />
                            <LabelTooltip title={OPTIONS[1].tooltip} />
                        </div>
                    </RadioGroup>
                </div>
                <div>
                    <Button
                        size="large"
                        variant="contained"
                        disableElevation
                        onClick={handleBack}
                        disabled={isLoading}
                        className={'mr-2'}
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                        style={{ color: '#fff' }}
                        disabled={isLoading}
                    >
                        Next
                    </Button>
                </div>
            </form>

        </div>
    );
}

import { blue } from '@mui/material/colors';
import InputField from 'components/form-controls/InputField';
import React from 'react';

const Share = ({ disabled, changeFields, disabledFields, member, isCorporation, form }) => {
    return (
        <>
            <div className="col-lg-6 mb-5 input-line">
                <label>Email address</label>
                <InputField
                    name="email"
                    type="email"
                    placeholder="Enter Email address"
                    form={form}
                    disabled={disabled || disabledFields?.['email']?.(member)}
                    customStyle={{
                        '& .MuiInputBase-input': {
                            backgroundColor: changeFields?.email ? blue[50] : 'inherit',
                            padding: '.6rem 1rem .6rem 0',
                        },
                    }}
                    hideSuccessHelper
                />
            </div>
            <div className="col-lg-6 mb-5 input-line">
                <label>{form.watch('type_member_id') === 1 ? 'Residential address' : 'Registered address'}</label>
                <InputField
                    name="address"
                    // placeholder="Enter residential address"
                    placeholder={form.watch('type_member_id') === 1 ? 'Enter residential address' : 'Enter registered address'}
                    form={form}
                    disabled={disabled || disabledFields?.['address']?.(member)}
                    customStyle={{
                        '& .MuiInputBase-input': {
                            backgroundColor: changeFields?.address ? blue[50] : 'inherit',
                            padding: '.6rem 1rem .6rem 0',
                        },
                    }}
                    hideSuccessHelper
                />
            </div>
        </>
    );
};
export default Share;

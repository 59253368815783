/**
 * a member have on of 2 type.
 */
export const MEMBER_TYPES = {
    INDIVIDUAL: { id: 1, name: 'Individual', icon: '/media/svg/icons/Order/individual.svg' },
    CORPORATION: { id: 2, name: 'Corporation', icon: '/media/svg/icons/Order/corporation.svg' },
};

/**
 * Difference with MEMBER_TYPES. COMPANY_MEMBER_TYPES has 4 types.
 */
export const COMPANY_MEMBER_TYPES = {
    CONTACT_PERSON: {
        id: 7,
        name: 'Agent',
        llcName: 'Agent',
        llpName: 'Agent',
    },
    DIRECTOR: { id: 1, name: 'Director', llcName: 'Manager', llpName: 'Manager' },
    SHAREHOLDER: { id: 2, name: 'Shareholder', llcName: 'Member', llpName: 'Member' },
    UBO: { id: 3, name: 'UBO', llcName: 'UBO', llpName: 'UBO' },
};

export const ACTION_TYPES = {
    ADD: { id: 1, name: 'ADD', icon: '/media/svg/icons/Order/union.svg' },
    UPLOAD: {
        id: 2,
        name: 'UPLOAD',
        icon: '/media/svg/icons/Order/upload.svg',
        desc: 'Update Documents',
    },
    DELETE: {
        id: 2,
        name: 'DELETE',
        icon: '/media/svg/icons/Order/delete-node.svg',
        desc: 'Delete member',
    },
    VIEW: {
        id: 4,
        name: 'VIEW',
        icon: '/media/svg/icons/Order/delete-node.svg',
        desc: 'View',
    },
    TRANSFER: {
        id: 5,
        name: 'TRANSFER',
        icon: '/media/svg/icons/Order/delete-node.svg',
        desc: 'Transfer',
    },
    EDIT: {
        id: 6,
        name: 'EDIT',
        icon: '/media/svg/icons/Order/delete-node.svg',
        desc: 'Edit',
    },
    RESIGN: {
        id: 7,
        name: 'RESIGN',
        icon: '/media/svg/icons/Order/delete-node.svg',
        desc: 'Resign',
    },
};

export const ENTITY_TYPES = {
    LLC: { id: 11, name: 'Limited Liability Company (LLC)' },
    LLP: { id: 7, name: 'Limited Liability Partnership (LLP)' },
};

export const SPECIAL_LLC_COUNTRIES = [
    { id: 23, name: 'Belize' },
    { id: 205, name: 'St. Kitts & Nevis' },
    { id: 209, name: 'St. Vincent & Grenadines' },
    { id: 250, name: 'Delaware' },
    { id: 237, name: 'UK' },
];

export const COMPANY_MEMBER_TYPES_OBJ_ID = {
    1: 'Director',
    2: 'Shareholder',
    3: 'UBO',
};
/**
 * The processes to handle order steps.
 */
export const PROCESSES = [
    { activeStep: 0, process_step_id: 17, priority: 1, name: 'Fill Order Form' },
    { activeStep: 1, process_step_id: 18, priority: 2, name: 'Fill KYC Document' },
    { activeStep: 2, process_step_id: 19, priority: 3, name: 'Sign Order Form' },
    { activeStep: 3, process_step_id: 20, priority: 4, name: 'Sign Original Document' },
];

export const getProcessByActiveStep = (activeStep) => {
    return PROCESSES.find((process) => process.activeStep === activeStep) || {};
};

const unitOfCountries = [
    { countryId: 23, name: 'Belize', unit: 'US$' },
    { countryId: 189, name: 'Seychelles', unit: 'US$' },
    { countryId: 183, name: 'Samoa', unit: 'US$' },
    { countryId: 8, name: 'Anguilla', unit: 'US$' },
    { countryId: 32, name: 'British Virgin Islands', unit: 'US$' },
    { countryId: 237, name: 'UK', unit: 'US$' },
    { countryId: 169, name: 'Panama', unit: 'US$' },
    { countryId: 139, name: 'Mauritius', unit: 'US$' },
];

/**
 * Check the country by the country id to get the unit.
 * @param {number} countryId the country id
 * @returns the monetary unit.
 */
export const monetaryUnitChecker = (countryId) => {
    let result = 'US$';
    const country = unitOfCountries.find((el) => el.countryId === countryId);
    if (country) {
        result = country.unit;
    }
    return result;
};

/**
 * Check company is LLC or not.
 * @param {number} entity_id
 * @param {number} company_country_id
 * @returns
 */
export const isLLC = (entity_id, company_country_id) => {
    if (SPECIAL_LLC_COUNTRIES.some((country) => country.id === company_country_id)) {
        if (entity_id === ENTITY_TYPES.LLC.id) {
            return true;
        }
    }
    return false;
};

/**
 * Check company is LLC or not.
 * @param {number} entity_id
 * @param {number} company_country_id
 * @returns
 */
export const isUK_LLP = (entity_id, company_country_id) => {
    if (SPECIAL_LLC_COUNTRIES.some((country) => country.id === company_country_id)) {
        if (entity_id === ENTITY_TYPES.LLP.id) {
            return true;
        }
    }
    return false;
};

/**
 * Get Corporation child name by entity.
 * @param {number} entity_id
 * @param {number} company_country_id
 * @param {Object} companyMemberType
 * @returns Corporation child name as a string.
 */
export const getCorpChildName = (entity_id, company_country_id, companyMemberType) => {
    let result = companyMemberType.name;
    if (SPECIAL_LLC_COUNTRIES.some((country) => country.id === company_country_id)) {
        if (entity_id === ENTITY_TYPES.LLC.id) {
            result = companyMemberType.llcName;
        }
        if (entity_id === ENTITY_TYPES.LLP.id) {
            result = companyMemberType.llpName;
        }
    }

    return result;
};

/**
 * get kyc dialog title
 * @param {number} entity_id
 * @param {number} company_country_id
 * @param {number} company_member_type_id
 * @returns
 */
export const getDialogTitle = (entity_id, company_country_id, company_member_type_id) => {
    let result = '';
    // debugger;
    const companyMemberType = Object.values(COMPANY_MEMBER_TYPES).find(
        (el) => el.id === company_member_type_id,
    );
    if (companyMemberType) {
        result = companyMemberType.name;
        if (
            entity_id === ENTITY_TYPES.LLC.id &&
            SPECIAL_LLC_COUNTRIES.some((country) => country.id === company_country_id)
        ) {
            result = companyMemberType.llcName;
        } else if (
            entity_id === ENTITY_TYPES.LLP.id &&
            SPECIAL_LLC_COUNTRIES.some((country) => country.id === company_country_id)
        ) {
            result = companyMemberType.llpName;
        }
    }
    return result;
};

/**
 * Check country and entity to hide incorporation signature.
 * @param {number} country_id
 * @param {number} entity_type_id
 * @returns boolean
 */
export const isIncorporationStepHidden = (orderInfo) => {
    if (orderInfo && orderInfo.Company && orderInfo.Company.country_id) {
        // console.log(orderInfo);
        switch (orderInfo.Company.country_id) {
            case 98: // Hong kong
                return true;
            case 244: // Vietnam
                return true;
            case 41: // Cayman
                if (!orderInfo?.is_has_director && !orderInfo?.is_has_shareholder) return false;
                return true;
            case 237: // UK entity_type_id= 7
                return !orderInfo.is_has_shareholder && !orderInfo.is_has_director && orderInfo; // != nominee
            case 236: // RAK (UAE)
                return true;
            case 139: // mauritius
                return true;
            case 250: // Delaware
                return true;
            default:
                return false;
        }
    }
    return false;
};

/**
 * Get extension of the file.
 * @param {string} filename
 * @returns extension as a string.
 */
export function getExtension(filename) {
    if (filename) {
        const parts = filename.split('.');
        return parts[parts.length - 1];
    }
    return '';
}

/**
 * Check current file is pdf.
 * @param {string} filename
 * @returns a boolean.
 */
export function isPDF(filename) {
    const ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'pdf':
            return true;
        default:
            return false;
    }
}

/**
 * check current file is image or not.
 * @param {string} filename
 * @returns a boolean.
 */
export function isImage(filename) {
    const ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'jpg':
        case 'gif':
        case 'bmp':
        case 'png':
            return true;
        default:
            return false;
    }
}

/**
 * Ẩn nhánh manager ở quốc gia belize llc và member manager
 * @param company
 * @returns {boolean}
 */
export function isManagerHidden(company) {
    // return belize && llc && articles_of_organization === member
    const result =
        (company?.Country?.id === 23 &&
            company?.EntityType?.id === 11 &&
            company.articles_of_organization === 1) ||
        (company?.country_id === 23 &&
            company?.entity_type_id === 11 &&
            company.articles_of_organization === 1);

    return result;
}

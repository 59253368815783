import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import InputField from 'components/form-controls/InputField';
import RadioField from 'components/form-controls/RadioField';
import { MARSHALL_ID, SAMAO_ID } from 'constants/country';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as yup from 'yup';

const Declaration = ({ task, disabled, country, isHideAction, onNext, handleBack, isLoading }) => {
    const navigate = useNavigate();
    const { CompanySuffixes } = useSelector((state) => state.incorporation.entity);
    const fullCompanyName =
        task?.Company?.full_name +
        ' ' +
        CompanySuffixes.find((suffix) => suffix?.id === task?.Company?.company_suffix_id)?.name;

    const IS_SAMAO = useMemo(() => task?.Company?.country_id === SAMAO_ID, [task]);
    const IS_MARSHALL = useMemo(() => task?.Company?.country_id === MARSHALL_ID, [task]);

    const schema = yup.object().shape({
        is_politically_exposed_person: yup.string().nullable().required('Required'),
        is_offenses: yup.string().nullable().required('Required'),
        is_entity: IS_SAMAO
            ? yup.string().nullable().required('Required')
            : yup.string().nullable(),
        is_committed: yup.string().required('Required'),
        offenses: yup
            .string()
            .nullable()
            .when('is_offenses', {
                is: 'Yes',
                then: yup.string().required('Required!'),
            }),
        operating_address: IS_SAMAO
            ? yup
                  .string()
                  .nullable()
                  .trim()
                  .required('Please type the country where your Bank Accounts are kept.')
                  .matches(
                      /^(?=.*[a-zA-ZÀ-ỹà-ỳẠ-ỵ])(?!.*\.\.)(?!.*\,\,)([a-zA-Z0-9À-ỹà-ỳẠ-ỵ .,;]+)$/,
                      'Please content does not contain special characters',
                  )
            : yup.string().nullable(),
    });

    const form = useForm({
        defaultValues: {
            is_politically_exposed_person: null,
            is_offenses: null,
            offenses: '',
            is_entity: null,
            operating_address: '',
            is_committed: null,
            allow_use_information: null,
        },
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        const declarationInfo = {
            is_politically_exposed_person:
                task?.Task?.IncorporationCountry?.Questions.find((q) => q.id === 1)?.Options.find(
                    (opt) => opt.id === 1,
                ).Answers.length > 0
                    ? 'Yes'
                    : 'No' || null,
            is_offenses:
                task?.Task?.IncorporationCountry?.Questions.find((q) => q.id === 2)?.Options.find(
                    (opt) => opt.id === 3,
                ).Answers.length > 0
                    ? 'Yes'
                    : 'No' || null,
            offenses:
                task?.Task?.IncorporationCountry?.Questions?.find((q) => q.id === 2)?.Options.find(
                    (opt) => opt.id === 3,
                )?.Answers?.[0]?.value_text || '',
            is_entity:
                task?.Task?.IncorporationCountry?.Questions.find((q) => q.id === 167)?.Options.find(
                    (opt) => opt.id === 399,
                ).Answers.length > 0
                    ? 'Yes'
                    : 'No' || null,
            operating_address:
                task?.Task?.IncorporationCountry?.Questions.find((q) => q.id === 168)?.Options.find(
                    (opt) => opt.id === 401,
                ).Answers[0]?.value_text ||
                task?.Company?.operating_address ||
                '',
            allow_use_information:
                task?.Task?.IncorporationCountry?.Questions.find((q) => q.id === 34)?.Options.find(
                    (opt) => opt.id === 87,
                ).Answers.length > 0 || null,
        };
        form.reset(declarationInfo);
    }, [task]);

    const handleSubmit = () => {
        if (task?.order_change_officer_processing) {
            Swal.fire({
                title: 'Sure you want to update company information?',
                text: 'Change Officers are currently in progress. Please confirm if you still wish to proceed',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'No, Cancel',
                confirmButtonText: 'Yes, Confirm',
                confirmButtonColor: '#17C191',
                reverseButtons: true,
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    onNext && onNext();
                }
            });
        } else {
            onNext && onNext();
        }
    };

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Grid container spacing={2}>
                <Grid item>
                    <div>
                        Are you or any connected persons (Directors, Shareholder, Ultimate
                        Beneficial Owners, Partners), a Politically Exposed Person (PEP)?
                    </div>
                    <RadioField
                        form={form}
                        name="is_politically_exposed_person"
                        options={[
                            {
                                id: 'Yes',
                                name: 'Yes',
                            },
                            {
                                id: 'No',
                                name: 'No',
                            },
                        ]}
                        radioGroupProps={{ sx: { flexDirection: 'row' } }}
                        disabled={disabled}
                    />
                    {form.watch('is_politically_exposed_person') === 'Yes' && (
                        <div className="text-primary">
                            More documents can be required to be supplied or signed.
                        </div>
                    )}
                </Grid>
                <Grid item>
                    <div>
                        Has any of the shareholders, directors, Ultimate Beneficial Owners ever
                        committed any offenses?
                    </div>
                    <RadioField
                        form={form}
                        name="is_offenses"
                        options={[
                            {
                                id: 'Yes',
                                name: 'Yes',
                            },
                            {
                                id: 'No',
                                name: 'No',
                            },
                        ]}
                        radioGroupProps={{ sx: { flexDirection: 'row' } }}
                        disabled={disabled}
                    />
                </Grid>
                {form.watch('is_offenses') === 'Yes' && (
                    <Grid item sm={6} xs={12} className="input-line">
                        <label>Please specify the nature of the offense</label>
                        <InputField
                            form={form}
                            name="offenses"
                            placeholder="Please specify the nature of the offense"
                            hideSuccessHelper
                            disabled={disabled}
                        />
                    </Grid>
                )}
                {IS_SAMAO && (
                    <>
                        <Grid item xs={12}>
                            <div>Is the entity a Financial Institution under CRS?</div>
                            <RadioField
                                form={form}
                                name="is_entity"
                                options={[
                                    {
                                        id: 'Yes',
                                        name: 'Yes',
                                    },
                                    {
                                        id: 'No',
                                        name: 'No',
                                    },
                                ]}
                                radioGroupProps={{ sx: { flexDirection: 'row' } }}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12} className="input-line">
                            <label>Country where Bank Accounts are kept</label>
                            <InputField
                                form={form}
                                name="operating_address"
                                placeholder="Country where Bank Accounts are kept"
                                showIcon
                                hideSuccessHelper
                                multiline
                                disabled={disabled}
                            />
                        </Grid>
                    </>
                )}
                <Grid item sm={9} xs={12}>
                    <Controller
                        name="is_committed"
                        control={form.control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...form.register('is_committed')}
                                        value="Yes"
                                        checked={form.watch('is_committed')}
                                        color="secondary"
                                        m={0}
                                        // disabled={disabled}
                                    />
                                }
                                style={{
                                    marginRight: 0,
                                }}
                                sx={{ alignItems: 'flex-start', userSelect: 'none' }}
                                label={
                                    <>
                                        <p>
                                            I, who signed the Confirmation Form declare that the
                                            information provided in this form is true and correct to
                                            yearly renew the {fullCompanyName} company (the company)
                                            and that I am requesting your services for lawful
                                            purposes.
                                        </p>
                                        <p>
                                            {`The company's operation in last year and it's moneys and/or other assets now or to be declared to you do not emanate from any activity which is unlawful in their country of origin or in ${country?.name} and specifically that none of the assets were derived from any of the activities characterized as criminal by any applicable legislation against money laundering or terrorist financing. I am aware that I may be subject to prosecution and criminal sanctions under written law if I am found to have made any false statement which I know to be false or which I do not believe to be true, or if I have intentionally suppressed any material fact.`}
                                        </p>
                                        <p className="mb-0">
                                            If I become aware of any changes, or inaccuracies with
                                            the information provided herein, I shall provide the
                                            updated information as soon as this is brought to my
                                            awareness. I will also take responsibility for all and
                                            any liabilities that arise from the misleading or not
                                            timely update of my declaration.
                                        </p>
                                    </>
                                }
                            />
                        )}
                    />
                </Grid>
                {IS_MARSHALL && (
                    <Grid item sm={9} xs={12}>
                        <Controller
                            name="allow_use_information"
                            control={form.control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...form.register('allow_use_information')}
                                            value="Yes"
                                            checked={form.watch('allow_use_information')}
                                            color="secondary"
                                            m={0}
                                            disabled={disabled}
                                        />
                                    }
                                    style={{
                                        marginRight: 0,
                                    }}
                                    sx={{ alignItems: 'flex-start', userSelect: 'none' }}
                                    label={
                                        <>
                                            <p>
                                                Please check the box to opt-in to allow your
                                                personal information to be used for marketing
                                                purposes which includes event invitations,
                                                newsletters, and blog, maritime, and corporate
                                                updates, among others.{' '}
                                                <strong> {country?.name} </strong> and specifically
                                                that none of the assets were derived from any of the
                                                activities characterized as criminal by any
                                                applicable legislation against money laundering or
                                                terrorist financing.
                                            </p>
                                        </>
                                    }
                                />
                            )}
                        />
                    </Grid>
                )}
                {!isHideAction && (
                    <Grid item xs={12}>
                        <Button
                            size="large"
                            variant="contained"
                            disableElevation
                            disabled={isLoading || form.isSubmitting}
                            onClick={handleBack}
                            className={'mr-2'}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            style={{ color: '#fff' }}
                            className={'mr-2'}
                            onClick={() =>
                                navigate(
                                    `/company/companies/${task?.Company.id}/officers-share-capital`,
                                )
                            }
                        >
                            Update Company Information
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            type="submit"
                            style={{ color: '#fff' }}
                            disabled={isLoading || !form.watch('is_committed')}
                        >
                            Complete
                        </Button>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};

export default Declaration;

import moment from 'moment';
import * as Yup from 'yup';

export const EmptyMemberCommon = {
    // CompanyMemberReferences: [
    references: [
        {
            id: undefined,
            name: '',
            // phone: "",
            phone_number: '',
            phone_country_id: null,
            fax: '',
            email: '',
            address: '',
            company_member_id: undefined,
            company_member_issuse_reference_id: 1, // default value: 1
        },
        {
            id: undefined,
            name: '',
            // phone: "",
            phone_number: '',
            phone_country_id: null,
            fax: '',
            email: '',
            address: '',
            company_member_id: undefined,
            company_member_issuse_reference_id: 1, // default value: 1
        },
    ],
};

// export const ReferencesYupValidate = Yup
export const memberCommonYupValidate = {
    references: Yup.array().when(['isPanama'], {
        is: true,
        then: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().nullable().required('Name is required!'),
                phone_number: Yup.string()
                    .nullable()
                    .matches(/^[0-9]*$/, 'Please enter number!')
                    .required('Phone number is required!'),
                email: Yup.string()
                    .nullable()
                    .required('Email is required!')
                    .email('Please enter valid email!'),
                address: Yup.string().nullable().required('Physical address is required!'),
            }),
        ),
    }),
};

export function formatCompanyMembers(body, chosen_users = []) {
    let companyStructure = {
        Directors: [],
        Shareholders: [],
        UBO: [],
        ContactPerson: [],
        director_type_member_list: [],
        shareholder_type_member_list: [],
    };

    try {
        const itemConfig = {
            isSVG: body.isSVG,
            isPanama: body.isPanama,
            is_UK_LLP: body.is_UK_LLP,
            is_UK: body?.is_UK_LLP ? body?.is_UK_LLP : body?.isUK,
            showOtherIncome: false,
            showOther: false,
        };

        body.Company.CompanyMembers &&
            body.Company.CompanyMembers.map((item) => {
                let newItem = {
                    ...itemConfig,
                    is_contact_person: item?.CompanyPositions?.find(
                        (companyPosition) => companyPosition?.is_contact_person,
                    )
                        ? 1
                        : 0,
                    isAfterChooseExistingMember:
                        chosen_users && chosen_users.length && chosen_users.includes(item.id)
                            ? true
                            : false,
                    individual: item.type_member_id === 1 ? true : false,
                    full_name: item.type_member_id === 1 ? item?.full_name : '',
                    first_name: item.type_member_id === 1 ? item?.first_name : '',
                    middle_name: item.type_member_id === 1 ? item?.middle_name : '',
                    last_name: item.type_member_id === 1 ? item?.last_name : '',
                    occupation: item.type_member_id === 1 ? item.major : '',
                    phone_code: item.phone_country_id ? item.phone_country_id : 0,
                    phone: item.phone_number ? item.phone_number : '',
                    email: item.email,
                    company_name: item.type_member_id === 2 ? item.corporation_name : '',
                    company_number: item.type_member_id === 2 ? item.corporation_number : '',
                    country_of_incorporation: item.type_member_id === 2 ? item.country_id : 0,
                    registered_address: item.type_member_id === 2 ? item.address : '',
                    date_of_incorporation: item.corporation_date
                        ? moment(item.corporation_date).format('MM/DD/YYYY')
                        : moment(new Date()).format('MM/DD/YYYY'),
                    business_activity_array: item?.BusinessActivities?.map((item) => item.id) || [],
                    business_activity_detail: item?.business_activity_detail,
                    country_operations: item?.CompanyMemberOperationCountries?.length
                        ? item?.CompanyMemberOperationCountries?.map((item) => item.id)
                        : [],

                    cash_in_bank_asset_1: item.asset_source_fund_cast_in_bank
                        ? item.asset_source_fund_cast_in_bank
                        : 0,
                    real_estate: item.asset_source_fund_real_estate
                        ? item.asset_source_fund_real_estate
                        : 0,
                    other_asset: item.asset_source_fund_other_detail
                        ? item.asset_source_fund_other_detail
                        : '',
                    other_money: item.asset_source_fund_other_money
                        ? item.asset_source_fund_other_money
                        : 0,
                    investment_income: item.income_source_fund_investment
                        ? item.income_source_fund_investment
                        : 0,
                    self_employed: item.income_source_fund_self_employed
                        ? item.income_source_fund_self_employed
                        : 0,
                    other_detail_income: item.income_source_fund_other_detail
                        ? item.income_source_fund_other_detail
                        : '',
                    other_money_income: item.income_source_fund_other_money
                        ? item.income_source_fund_other_money
                        : 0,
                    total_asset:
                        item.asset_source_fund_cast_in_bank +
                        item.asset_source_fund_real_estate +
                        item.asset_source_fund_other_money,
                    total_income:
                        item.income_source_fund_investment +
                        item.income_source_fund_self_employed +
                        item.income_source_fund_other_money,
                    company_id: body.company_id,
                    id: item.id,
                    member_id: item.id,
                    country_id: item.country_id,
                    // number_share: item.number_allotted ? item.number_allotted : 0,
                    // percentage: item.percentage_interest ? item.percentage_interest : 0,
                    // is_represented: item.is_appointed ? true : false,
                    // own_percent: item.own_percent ? item.own_percent : 0,

                    // nature of control
                    nature_ownership: null,
                    nature_voting_rights: null,
                    nature_appoint_majority: null,
                    nature_other_significant_control: null,
                    firm: null,
                    firm_ownership: null,
                    firm_voting_rights: null,
                    firm_appoint_majority: null,
                    firm_other_significant_control: null,
                    trust: null,
                    trust_ownership: null,
                    trust_voting_rights: null,
                    trust_appoint_majority: null,
                    trust_other_significant_control: null,

                    // for security questions
                    security_question_1: '',
                    security_question_2: '',
                    security_question_3: '',
                    answer_question_1: '',
                    answer_question_2: '',
                    answer_question_3: '',

                    // item.CompanyMemberReferences
                    // references: item.CompanyMemberReferences?.length > 0 ? item.CompanyMemberReferences : EmptyMemberCommon.references,
                    references: [
                        {
                            id:
                                item.CompanyMemberReferences[0]?.id ||
                                EmptyMemberCommon.references[0].id,
                            name:
                                item.CompanyMemberReferences[0]?.name ||
                                EmptyMemberCommon.references[0].name,
                            phone_number:
                                item.CompanyMemberReferences[0]?.phone_number ||
                                EmptyMemberCommon.references[0].phone_number,
                            phone_country_id:
                                item.CompanyMemberReferences[0]?.phone_country_id ||
                                EmptyMemberCommon.references[0].phone_country_id,
                            fax:
                                item.CompanyMemberReferences[0]?.fax ||
                                EmptyMemberCommon.references[0].fax,
                            email:
                                item.CompanyMemberReferences[0]?.email ||
                                EmptyMemberCommon.references[0].email,
                            address:
                                item.CompanyMemberReferences[0]?.address ||
                                EmptyMemberCommon.references[0].address,
                            company_member_id:
                                item.CompanyMemberReferences[0]?.company_member_id ||
                                EmptyMemberCommon.references[0].company_member_id,
                            company_member_issuse_reference_id:
                                item.CompanyMemberReferences[0]
                                    ?.company_member_issuse_reference_id ||
                                EmptyMemberCommon.references[0].company_member_issuse_reference_id,
                        },
                        {
                            id:
                                item.CompanyMemberReferences[1]?.id ||
                                EmptyMemberCommon.references[1].id,
                            name:
                                item.CompanyMemberReferences[1]?.name ||
                                EmptyMemberCommon.references[1].name,
                            phone_number:
                                item.CompanyMemberReferences[1]?.phone_number ||
                                EmptyMemberCommon.references[1].phone_number,
                            phone_country_id:
                                item.CompanyMemberReferences[1]?.phone_country_id ||
                                EmptyMemberCommon.references[1].phone_country_id,
                            fax:
                                item.CompanyMemberReferences[1]?.fax ||
                                EmptyMemberCommon.references[1].fax,
                            email:
                                item.CompanyMemberReferences[1]?.email ||
                                EmptyMemberCommon.references[1].email,
                            address:
                                item.CompanyMemberReferences[1]?.address ||
                                EmptyMemberCommon.references[1].address,
                            company_member_id:
                                item.CompanyMemberReferences[1]?.company_member_id ||
                                EmptyMemberCommon.references[1].company_member_id,
                            company_member_issuse_reference_id:
                                item.CompanyMemberReferences[1]
                                    ?.company_member_issuse_reference_id ||
                                EmptyMemberCommon.references[1].company_member_issuse_reference_id,
                        },
                    ],
                };

                item.CompanyPositions &&
                    item.CompanyPositions.length > 0 &&
                    item.CompanyPositions.map((e) => {
                        if (!e.corporation_company_member_id) {
                            newItem = {
                                ...newItem,
                                company_position_id: e.id, // id của record company_positon, cần khi update/delete member.
                                company_member_type_id: e.company_member_type_id,
                                number_share: e.number_allotted ? e.number_allotted : 0,
                                percentage: e.percent_of_interest ? e.percent_of_interest : 0,
                                is_represented: e.is_appointed || false,
                                own_percent: e.own_percent ? e.own_percent : 0,
                                is_resigned: e.is_resigned || false,
                            };

                            switch (e.company_member_type_id) {
                                case 1:
                                    item.CompanyPositions.forEach((tempItem) => {
                                        switch (tempItem.company_member_type_id) {
                                            case 4:
                                                newItem.secretary = true;
                                                newItem.secretary_id = tempItem.id;
                                                break;
                                            case 5:
                                                newItem.president = true;
                                                newItem.president_id = tempItem.id;
                                                break;
                                            case 6:
                                                newItem.treasurer = true;
                                                newItem.treasurer_id = tempItem.id;
                                                break;
                                        }
                                    });
                                    companyStructure.Directors.push(newItem);
                                    companyStructure.director_type_member_list.push(
                                        newItem.individual,
                                    );
                                    break;
                                case 2:
                                    companyStructure.Shareholders.push(newItem);
                                    companyStructure.shareholder_type_member_list.push(
                                        newItem.individual,
                                    );
                                    break;
                                case 3:
                                    companyStructure.UBO.push(newItem);
                                    break;
                                case 7:
                                    companyStructure.ContactPerson.push(newItem);
                                    break;
                            }
                        }
                    });
            });
        // set companyStructure if has type no member:
        let emptyMemberCommon = {
            ...itemConfig,
            ...EmptyMemberCommon,
        };

        let emptyDirector = {
            // isSVG: body.isSVG,
            // is_UK_LLP: body.is_UK_LLP,
            // is_UK: body?.is_UK_LLP ? body?.is_UK_LLP : body?.isUK,
            // showOtherIncome: false,
            // showOther: false,
            individual: true,
            full_name: null,
            occupation: null,
            phone_code: 0,
            phone: null,
            email: null,
            company_name: '',
            company_number: '',
            country_of_incorporation: 0,
            registered_address: '',
            date_of_incorporation: moment(new Date()).format('MM/DD/YYYY'),
            company_member_type_id: 1,
            cash_in_bank_asset_1: 0,
            real_estate: 0,
            other_asset: '',
            other_money: 0,
            investment_income: 0,
            self_employed: 0,
            other_detail_income: '',
            other_money_income: 0,
            total_asset: 0,
            total_income: 0,
            type_member_id: 1,
            company_id: body.Company.id,
            isCreate: true,
            country_id: 0,
            is_represented: false,
            business_activity_array: [],
            business_activity_detail: '',
            country_operations: [],

            ...emptyMemberCommon,
        };
        let emptyShareholder = {
            isSVG: body.isSVG,
            is_UK_LLP: body.is_UK_LLP,
            is_UK: body?.is_UK_LLP ? body?.is_UK_LLP : body?.isUK,
            showOtherIncome: false,
            showOther: false,
            individual: true,
            full_name: null,
            occupation: null,
            phone_code: 0,
            phone: null,
            email: null,
            company_name: '',
            company_number: '',
            country_of_incorporation: 0,
            registered_address: '',
            date_of_incorporation: moment(new Date()).format('MM/DD/YYYY'),
            company_member_type_id: 2,
            cash_in_bank_asset_1: '',
            real_estate: '',
            other_asset: '',
            other_money: '',
            investment_income: '',
            self_employed: '',
            other_detail_income: '',
            other_money_income: '',
            total_asset: 0,
            total_income: 0,
            type_member_id: 1,
            company_id: body.Company.id,
            isCreate: true,
            country_id: 0,
            number_share: 0,
            is_represented: false,
            own_percent: 100,
            business_activity_array: [],
            business_activity_detail: '',
            country_operations: [],

            ...emptyMemberCommon,
        };
        let emptyUBO = {
            isSVG: body.isSVG,
            is_UK_LLP: body.is_UK_LLP,
            is_UK: body?.is_UK_LLP ? body?.is_UK_LLP : body?.isUK,
            showOtherIncome: false,
            showOther: false,
            individual: true,
            full_name: null,
            occupation: null,
            phone_code: 0,
            phone: null,
            email: null,
            company_name: '',
            company_number: '',
            country_of_incorporation: 0,
            registered_address: '',
            date_of_incorporation: moment(new Date()).format('MM/DD/YYYY'),
            company_member_type_id: 3,
            cash_in_bank_asset_1: '',
            real_estate: '',
            other_asset: '',
            other_money: '',
            investment_income: '',
            self_employed: '',
            other_detail_income: '',
            other_money_income: '',
            total_asset: 0,
            total_income: 0,
            is_represented: false,
            type_member_id: 1,
            company_id: body.Company.id,
            isCreate: true,
            country_id: 0,
            percentage: 100,

            ...emptyMemberCommon,

            // nature of control
            // nature_ownership: null,
            // nature_voting_rights: null,
            // nature_appoint_majority: null,
            // nature_other_significant_control: null,
            // firm: null,
            // firm_ownership: null,
            // firm_voting_rights: null,
            // firm_appoint_majority: null,
            // firm_other_significant_control: null,
            // trust: null,
            // trust_ownership: null,
            // trust_voting_rights: null,
            // trust_appoint_majority: null,
            // trust_other_significant_control: null,
        };

        if (companyStructure.Directors.length === 0) {
            if (body.represented_director) {
                emptyDirector = { ...emptyDirector, is_represented: true };
            }
            companyStructure.Directors.push(emptyDirector);
            companyStructure.director_type_member_list.push(true);
        }
        if (companyStructure.Shareholders.length === 0) {
            if (body.represented_shareholder) {
                emptyShareholder = { ...emptyShareholder, is_represented: true };
            }
            companyStructure.Shareholders.push(emptyShareholder);
            companyStructure.shareholder_type_member_list.push(true);
        }
        if (companyStructure.UBO.length === 0) {
            if (body.represented_ubo) {
                emptyUBO = { ...emptyUBO, is_represented: true };
            }
            companyStructure.UBO.push(emptyUBO);
        }
    } catch (error) {
        console.log(error);
    }

    return companyStructure;
}

export function formatCompanyMembersWithAllLevel(body, chosen_users = []) {
    let companyStructure = {
        Directors: [],
        Shareholders: [],
        UBO: [],
        ContactPerson: [],
        director_type_member_list: [],
        shareholder_type_member_list: [],
    };

    try {
        const itemConfig = {
            isSVG: body.isSVG,
            isPanama: body.isPanama,
            is_UK_LLP: body.is_UK_LLP,
            is_UK: body?.is_UK_LLP ? body?.is_UK_LLP : body?.isUK,
            showOtherIncome: false,
            showOther: false,
        };

        body.Company.CompanyMembers &&
            body.Company.CompanyMembers.map((item) => {
                let newItem = {
                    ...itemConfig,
                    is_contact_person: item?.CompanyPositions?.find(
                        (companyPosition) => companyPosition?.is_contact_person,
                    )
                        ? 1
                        : 0,
                    isAfterChooseExistingMember:
                        chosen_users && chosen_users.length && chosen_users.includes(item.id)
                            ? true
                            : false,
                    individual: item.type_member_id === 1 ? true : false,
                    full_name: item.type_member_id === 1 ? item?.full_name : '',
                    first_name: item.type_member_id === 1 ? item?.first_name : '',
                    middle_name: item.type_member_id === 1 ? item?.middle_name : '',
                    last_name: item.type_member_id === 1 ? item?.last_name : '',
                    occupation: item.type_member_id === 1 ? item.major : '',
                    phone_code: item.phone_country_id ? item.phone_country_id : 0,
                    phone: item.phone_number ? item.phone_number : '',
                    email: item.email,
                    company_name: item.type_member_id === 2 ? item.corporation_name : '',
                    company_number: item.type_member_id === 2 ? item.corporation_number : '',
                    country_of_incorporation: item.type_member_id === 2 ? item.country_id : 0,
                    registered_address: item.type_member_id === 2 ? item.address : '',
                    date_of_incorporation: item.corporation_date
                        ? moment(item.corporation_date).format('MM/DD/YYYY')
                        : moment(new Date()).format('MM/DD/YYYY'),
                    business_activity_array: item?.BusinessActivities?.map((item) => item.id) || [],
                    business_activity_detail: item?.business_activity_detail,
                    country_operations: item?.CompanyMemberOperationCountries?.length
                        ? item?.CompanyMemberOperationCountries?.map((item) => item.id)
                        : [],

                    cash_in_bank_asset_1: item.asset_source_fund_cast_in_bank
                        ? item.asset_source_fund_cast_in_bank
                        : 0,
                    real_estate: item.asset_source_fund_real_estate
                        ? item.asset_source_fund_real_estate
                        : 0,
                    other_asset: item.asset_source_fund_other_detail
                        ? item.asset_source_fund_other_detail
                        : '',
                    other_money: item.asset_source_fund_other_money
                        ? item.asset_source_fund_other_money
                        : 0,
                    investment_income: item.income_source_fund_investment
                        ? item.income_source_fund_investment
                        : 0,
                    self_employed: item.income_source_fund_self_employed
                        ? item.income_source_fund_self_employed
                        : 0,
                    other_detail_income: item.income_source_fund_other_detail
                        ? item.income_source_fund_other_detail
                        : '',
                    other_money_income: item.income_source_fund_other_money
                        ? item.income_source_fund_other_money
                        : 0,
                    total_asset:
                        item.asset_source_fund_cast_in_bank +
                        item.asset_source_fund_real_estate +
                        item.asset_source_fund_other_money,
                    total_income:
                        item.income_source_fund_investment +
                        item.income_source_fund_self_employed +
                        item.income_source_fund_other_money,
                    company_id: body.company_id,
                    id: item.id,
                    member_id: item.id,
                    country_id: item.country_id,
                    // number_share: item.number_allotted ? item.number_allotted : 0,
                    // percentage: item.percentage_interest ? item.percentage_interest : 0,
                    // is_represented: item.is_appointed ? true : false,
                    // own_percent: item.own_percent ? item.own_percent : 0,

                    // nature of control
                    nature_ownership: null,
                    nature_voting_rights: null,
                    nature_appoint_majority: null,
                    nature_other_significant_control: null,
                    firm: null,
                    firm_ownership: null,
                    firm_voting_rights: null,
                    firm_appoint_majority: null,
                    firm_other_significant_control: null,
                    trust: null,
                    trust_ownership: null,
                    trust_voting_rights: null,
                    trust_appoint_majority: null,
                    trust_other_significant_control: null,

                    // for security questions
                    security_question_1: '',
                    security_question_2: '',
                    security_question_3: '',
                    answer_question_1: '',
                    answer_question_2: '',
                    answer_question_3: '',

                    // item.CompanyMemberReferences
                    // references: item.CompanyMemberReferences?.length > 0 ? item.CompanyMemberReferences : EmptyMemberCommon.references,
                    references: [
                        {
                            id:
                                item.CompanyMemberReferences[0]?.id ||
                                EmptyMemberCommon.references[0].id,
                            name:
                                item.CompanyMemberReferences[0]?.name ||
                                EmptyMemberCommon.references[0].name,
                            phone_number:
                                item.CompanyMemberReferences[0]?.phone_number ||
                                EmptyMemberCommon.references[0].phone_number,
                            phone_country_id:
                                item.CompanyMemberReferences[0]?.phone_country_id ||
                                EmptyMemberCommon.references[0].phone_country_id,
                            fax:
                                item.CompanyMemberReferences[0]?.fax ||
                                EmptyMemberCommon.references[0].fax,
                            email:
                                item.CompanyMemberReferences[0]?.email ||
                                EmptyMemberCommon.references[0].email,
                            address:
                                item.CompanyMemberReferences[0]?.address ||
                                EmptyMemberCommon.references[0].address,
                            company_member_id:
                                item.CompanyMemberReferences[0]?.company_member_id ||
                                EmptyMemberCommon.references[0].company_member_id,
                            company_member_issuse_reference_id:
                                item.CompanyMemberReferences[0]
                                    ?.company_member_issuse_reference_id ||
                                EmptyMemberCommon.references[0].company_member_issuse_reference_id,
                        },
                        {
                            id:
                                item.CompanyMemberReferences[1]?.id ||
                                EmptyMemberCommon.references[1].id,
                            name:
                                item.CompanyMemberReferences[1]?.name ||
                                EmptyMemberCommon.references[1].name,
                            phone_number:
                                item.CompanyMemberReferences[1]?.phone_number ||
                                EmptyMemberCommon.references[1].phone_number,
                            phone_country_id:
                                item.CompanyMemberReferences[1]?.phone_country_id ||
                                EmptyMemberCommon.references[1].phone_country_id,
                            fax:
                                item.CompanyMemberReferences[1]?.fax ||
                                EmptyMemberCommon.references[1].fax,
                            email:
                                item.CompanyMemberReferences[1]?.email ||
                                EmptyMemberCommon.references[1].email,
                            address:
                                item.CompanyMemberReferences[1]?.address ||
                                EmptyMemberCommon.references[1].address,
                            company_member_id:
                                item.CompanyMemberReferences[1]?.company_member_id ||
                                EmptyMemberCommon.references[1].company_member_id,
                            company_member_issuse_reference_id:
                                item.CompanyMemberReferences[1]
                                    ?.company_member_issuse_reference_id ||
                                EmptyMemberCommon.references[1].company_member_issuse_reference_id,
                        },
                    ],
                };

                item.CompanyPositions &&
                    item.CompanyPositions.length > 0 &&
                    item.CompanyPositions.map((e) => {
                        newItem = {
                            ...newItem,
                            company_position_id: e.id, // id của record company_positon, cần khi update/delete member.
                            company_member_type_id: e.company_member_type_id,
                            number_share: e.number_allotted ? e.number_allotted : 0,
                            percentage: e.percent_of_interest ? e.percent_of_interest : 0,
                            is_represented: e.is_appointed || false,
                            own_percent: e.own_percent ? e.own_percent : 0,
                            is_resigned: e.is_resigned || false,
                        };

                        switch (e.company_member_type_id) {
                            case 1:
                                item.CompanyPositions.forEach((tempItem) => {
                                    switch (tempItem.company_member_type_id) {
                                        case 4:
                                            newItem.secretary = true;
                                            newItem.secretary_id = tempItem.id;
                                            break;
                                        case 5:
                                            newItem.president = true;
                                            newItem.president_id = tempItem.id;
                                            break;
                                        case 6:
                                            newItem.treasurer = true;
                                            newItem.treasurer_id = tempItem.id;
                                            break;
                                    }
                                });
                                companyStructure.Directors.push(newItem);
                                companyStructure.director_type_member_list.push(newItem.individual);
                                break;
                            case 2:
                                companyStructure.Shareholders.push(newItem);
                                companyStructure.shareholder_type_member_list.push(
                                    newItem.individual,
                                );
                                break;
                            case 3:
                                companyStructure.UBO.push(newItem);
                                break;
                            case 7:
                                companyStructure.ContactPerson.push(newItem);
                                break;
                        }
                    });
            });
        // set companyStructure if has type no member:
        let emptyMemberCommon = {
            ...itemConfig,
            ...EmptyMemberCommon,
        };

        let emptyDirector = {
            // isSVG: body.isSVG,
            // is_UK_LLP: body.is_UK_LLP,
            // is_UK: body?.is_UK_LLP ? body?.is_UK_LLP : body?.isUK,
            // showOtherIncome: false,
            // showOther: false,
            individual: true,
            full_name: null,
            occupation: null,
            phone_code: 0,
            phone: null,
            email: null,
            company_name: '',
            company_number: '',
            country_of_incorporation: 0,
            registered_address: '',
            date_of_incorporation: moment(new Date()).format('MM/DD/YYYY'),
            company_member_type_id: 1,
            cash_in_bank_asset_1: 0,
            real_estate: 0,
            other_asset: '',
            other_money: 0,
            investment_income: 0,
            self_employed: 0,
            other_detail_income: '',
            other_money_income: 0,
            total_asset: 0,
            total_income: 0,
            type_member_id: 1,
            company_id: body.Company.id,
            isCreate: true,
            country_id: 0,
            is_represented: false,
            business_activity_array: [],
            business_activity_detail: '',
            country_operations: [],

            ...emptyMemberCommon,
        };
        let emptyShareholder = {
            isSVG: body.isSVG,
            is_UK_LLP: body.is_UK_LLP,
            is_UK: body?.is_UK_LLP ? body?.is_UK_LLP : body?.isUK,
            showOtherIncome: false,
            showOther: false,
            individual: true,
            full_name: null,
            occupation: null,
            phone_code: 0,
            phone: null,
            email: null,
            company_name: '',
            company_number: '',
            country_of_incorporation: 0,
            registered_address: '',
            date_of_incorporation: moment(new Date()).format('MM/DD/YYYY'),
            company_member_type_id: 2,
            cash_in_bank_asset_1: '',
            real_estate: '',
            other_asset: '',
            other_money: '',
            investment_income: '',
            self_employed: '',
            other_detail_income: '',
            other_money_income: '',
            total_asset: 0,
            total_income: 0,
            type_member_id: 1,
            company_id: body.Company.id,
            isCreate: true,
            country_id: 0,
            number_share: 0,
            is_represented: false,
            own_percent: 100,
            business_activity_array: [],
            business_activity_detail: '',
            country_operations: [],

            ...emptyMemberCommon,
        };
        let emptyUBO = {
            isSVG: body.isSVG,
            is_UK_LLP: body.is_UK_LLP,
            is_UK: body?.is_UK_LLP ? body?.is_UK_LLP : body?.isUK,
            showOtherIncome: false,
            showOther: false,
            individual: true,
            full_name: null,
            occupation: null,
            phone_code: 0,
            phone: null,
            email: null,
            company_name: '',
            company_number: '',
            country_of_incorporation: 0,
            registered_address: '',
            date_of_incorporation: moment(new Date()).format('MM/DD/YYYY'),
            company_member_type_id: 3,
            cash_in_bank_asset_1: '',
            real_estate: '',
            other_asset: '',
            other_money: '',
            investment_income: '',
            self_employed: '',
            other_detail_income: '',
            other_money_income: '',
            total_asset: 0,
            total_income: 0,
            is_represented: false,
            type_member_id: 1,
            company_id: body.Company.id,
            isCreate: true,
            country_id: 0,
            percentage: 100,

            ...emptyMemberCommon,

            // nature of control
            // nature_ownership: null,
            // nature_voting_rights: null,
            // nature_appoint_majority: null,
            // nature_other_significant_control: null,
            // firm: null,
            // firm_ownership: null,
            // firm_voting_rights: null,
            // firm_appoint_majority: null,
            // firm_other_significant_control: null,
            // trust: null,
            // trust_ownership: null,
            // trust_voting_rights: null,
            // trust_appoint_majority: null,
            // trust_other_significant_control: null,
        };

        if (companyStructure.Directors.length === 0) {
            if (body.represented_director) {
                emptyDirector = { ...emptyDirector, is_represented: true };
            }
            companyStructure.Directors.push(emptyDirector);
            companyStructure.director_type_member_list.push(true);
        }
        if (companyStructure.Shareholders.length === 0) {
            if (body.represented_shareholder) {
                emptyShareholder = { ...emptyShareholder, is_represented: true };
            }
            companyStructure.Shareholders.push(emptyShareholder);
            companyStructure.shareholder_type_member_list.push(true);
        }
        if (companyStructure.UBO.length === 0) {
            if (body.represented_ubo) {
                emptyUBO = { ...emptyUBO, is_represented: true };
            }
            companyStructure.UBO.push(emptyUBO);
        }
    } catch (error) {
        console.log(error);
    }

    return companyStructure;
}

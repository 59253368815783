import React from 'react';
import IndividualForm from './IndividualForm';
import InputField from 'components/form-controls/InputField';
import { Box, FormHelperText, Grid, InputAdornment } from '@mui/material';
import SourceOfFund from './SourceOfFund';
import Reference from './Reference';
import { Represented } from './Represented';
import NatureOfControl from './NatureOfControl';
import SecurityQuestion from './SecurityQuestion';
import { useSelector } from 'react-redux';

const UBOForm = ({
    task,
    form,
    index,
    disabled,
    countriesCooperationOptions,
    hideSourceOfFund,
    isSVG = false,
    isPanama = false,
    isUK = false,
    IS_UK_LLP = false,
    onSwitchTypeMember = () => {},
    isRepresented = false,
    onCheckRepresented,
    hasShowSelectFullName,
    allowFields,
}) => {
    const { phonePrefixCountriesCooperation } = useSelector((state) => state.enum);

    return (
        <>
            <IndividualForm
                form={form}
                index={index}
                disabled={disabled}
                phoneCountryOptions={phonePrefixCountriesCooperation}
                countriesCooperationOptions={countriesCooperationOptions}
                onSwitchTypeMember={onSwitchTypeMember}
                hideMemberTypeSwitch={true}
                hasShowSelectFullName={hasShowSelectFullName}
                allowFields={allowFields}
            />
            <div className="input-line" style={{ paddingTop: '16px' }}>
                <label>Percentage of interest</label>
                <div className="has-unit" style={{ width: '100px' }}>
                    <InputField
                        type="number"
                        form={form}
                        name={`members.${index}.CompanyPositions.0.percent_of_interest`}
                        placeholder="100"
                        disabled
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        hideSuccessHelper
                    />
                </div>
                {form.formState.errors?.validatePercentageOfInterest && (
                <FormHelperText error={!!form.formState.errors?.validatePercentageOfInterest}>
                    {form.formState.errors?.validatePercentageOfInterest?.message}
                </FormHelperText>
            )}
            </div>
            {isSVG && !hideSourceOfFund && <SourceOfFund form={form} index={index} disabled />}
            {isUK && <NatureOfControl task={task} form={form} index={index} disabled />}
            {IS_UK_LLP && <SecurityQuestion task={task} form={form} index={index} disabled />}
            {isPanama && (
                <>
                    <Reference
                        title={'References 1:'}
                        form={form}
                        indexMember={index}
                        indexReference={0}
                        disabled={disabled}
                        countries={countriesCooperationOptions}
                        allowFields={allowFields}
                    />
                    <Reference
                        title={'References 2:'}
                        form={form}
                        indexMember={index}
                        indexReference={1}
                        disabled={disabled}
                        countries={countriesCooperationOptions}
                        allowFields={allowFields}
                    />
                </>
            )}
            {isRepresented && (
                <Represented
                    form={form}
                    index={index}
                    isPanama={false}
                    disabled
                    // highlightFields={highlightFields}
                    onCheckRepresented={onCheckRepresented}
                    mainLabel={'Consent to act as authorized signatory'}
                />
            )}
            {form.formState.errors?.validateRepresentedUBO && (
                <FormHelperText error={!!form.formState.errors?.validateRepresentedUBO}>
                    {form.formState.errors?.validateRepresentedUBO?.message}
                </FormHelperText>
            )}
            {isPanama && (
                <Box sx={{ marginTop: '16px' }}>
                    <Grid container>
                        <Grid item sm={6} xs={12}>
                            <p style={{ marginBottom: '8px' }}>
                                What is/are the Tax ID number of the Beneficial owner(s) ?
                            </p>
                            <InputField
                                form={form}
                                name={`members.${index}.tax`}
                                placeholder="Please enter your answer here"
                                hideSuccessHelper
                                customStyle={{
                                    '.MuiInputBase-root': {
                                        backgroundColor:
                                            form.getValues(`members.${index}.tax`) !== ''
                                                ? 'rgba(176, 236, 209, 0.5)'
                                                : 'inherit',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};

export default UBOForm;

import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import axios from 'axios';
import { Button } from '@mui/material';
import ElementsStripe from 'app/pages/components/payment/elementsStripe';
import { toast } from 'react-toastify';

export const uri = {
    // paypal: {
    //     create: '/customer_account/incorporation/exist_order/paypal',
    //     approve: '/customer_account/incorporation/paypal/authorize',
    // },
    stripe: {
        create: '/customer_account/banking/upgrade_service/stripe',
    },
    // bank: {
    //     create: '/customer_account/incorporation/exist_order/bank',
    // },
};

export function UpgradeBanking(task) {
    const { id } = useParams();

    const [openStripe, setOpenStripe] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [payloadPay, setPayloadPay] = useState(null);
    // const [allowedPayment, setAllowedPayment] = useState(false);

    const paidAmount = 300; // data gán tạm

    const { authState } = useSelector((state) => ({ authState: state.auth }), shallowEqual);

    // inside company
    // param = {
    //     "id":"tok_1JomnpCPvxOhGb3sUY45k73U",
    //     "company_id":11933,
    //     "package_id":43,
    //     "OrderItems":[{"service_id":88,"quantity":1}],
    //     "billing_id":159
    // }

    useEffect(() => {
        try {
            const params = {
                order_id_current: id,
                uri: uri,
                payload: {
                    task_id: task.task_id,
                    // company_id: 11933,
                    // package_id: 43,
                    // OrderItems: [{"service_id":88,"quantity":1}],
                    // billing_id: 159,
                },
            };
            if (!params.payload.order_id) {
                delete params.payload.order_id;
                params.payload.task_id = task.task_id;
            }
            setPayloadPay(params);
        } catch (error) {
            console.log(error);
        }
    }, []);

    function handleClose() {
        setOpenStripe(false);
    }
    // const handleAllowedPayment = (event) => {
    //     setAllowedPayment(!allowedPayment);
    // };

    async function handleUpgrade() {
        // upgrade banking service
        setOpenStripe(true);
    }

    return (
        <>
            {/* <PaymentFn
            param={param}
            paymentMethod={'stripe'}
            paidAmount={350}
            existOrder={true}
        /> */}
            <div className="text-center mt-8">
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    style={{ color: '#fff' }}
                    onClick={handleUpgrade}
                >
                    UPGRADE BANKING SERVICE
                </Button>
            </div>

            {/* có cần hiển thị popup order detail để khách review thông tin & agree policy trước khi thnah toán??? */}

            <ElementsStripe
                token={authState.authToken}
                payloadPay={payloadPay}
                open={openStripe}
                onClose={handleClose}
                setOpenStripe={setOpenStripe}
                paidAmount={paidAmount}
                upgradeBanking={true}
            />
        </>
    );
}

import React, { useState } from 'react';
import { sendMailCalendar } from '../../../../../_redux/banking/api';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

const AddToCalendar = ({bankingInfo, taskId}) => {
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        setLoading(true);
        try {
            await sendMailCalendar({id: taskId});
            toast.success('Success fully');
        setLoading(false);
        } catch (e) {
            toast.error('Somethings went wrong!');
        setLoading(false);
        }
      }

    return (
        <div className={'d-flex justify-content-center align-items-center'}>
            <button className='btn btn-success mr-2' disabled={loading} onClick={handleClick}>Send to my email </button>
            {loading && <CircularProgress size={'20px'}/>}
        </div>

    );
}

export default AddToCalendar;

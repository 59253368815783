import React, { useEffect, useMemo, useState } from 'react';
import CompanyInfoForm from 'app/pages/components/forms/CompanyInfoForm';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { checkProposedName } from 'app/pages/components/check-name/check-proposed-name';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchCompanyInfo } from 'app/pages/_redux/incorporation/incorporationActions';
import { BELIZE_ID, CAYMAN_ID, RAK_ID, UK_ID, US_ID } from 'constants/country';
import { get, head, isEmpty } from 'lodash';
import { fetchBusinessActivity } from 'app/pages/_redux/enum/enumActions';
import { buildTree, getCompanyInfoAllowFields } from '../../helpers';

yup.addMethod(yup.string, 'checkProposedName', checkProposedName);

const CompanyInfo = ({ task, disabled, onSubmit, handleNext, handleBack }) => {
    const dispatch = useDispatch();
    const [companySuffixOptions, setCompanySuffixOptions] = useState([]);
    const [showGeneralDescription, setShowGeneralDescription] = useState(false);
    const [businessActivityOptions, setBusinessActivityOptions] = useState([]); // list business attivity option
    const [businessAnnualReportOptions, setBusinessAnnualReportOptions] = useState([]); // list business anual report option
    const [originData, setOriginData] = useState([]);
    const [initValues, setInitValues] = useState(null);

    const { countriesOptions } = useSelector((state) => state.enum);
    const { incorporationCountry, businessActivityData, proposedName, comparedCompany } =
        useSelector(
            (state) => ({
                incorporationCountry: state.incorporation.entity,
                businessActivityData: state.enum.businessActivity,
                proposedName: state.proposedName.entities,
                comparedCompany: state.companies.comparedCompany,
            }),
            shallowEqual,
        );

    const schema = yup.object().shape({
        company_name: yup
            .string()
            .nullable()
            .checkProposedName(
                null,
                'Company name',
                dispatch,
                proposedName || [],
                task?.Company.country_id,
            ),
        company_suffix_id: yup.string(),
        business_activity_array: yup
            .array()
            .nullable()
            .min(1, 'Business activities is required!')
            .required('Business activities is required!'),
        business_activity_detail: yup.string().when('business_activity_array', {
            is: (value) => value?.length > 0,
            then: yup
                .string()
                .nullable()
                .required('Details of Business activities is required!')
                .test({
                    message:
                        'Please write a complete description of your business activities at least 10 words',
                    test: (value) => value && value.trim().split(/\s+/).length >= 10,
                }),
        }),
        business_annual_report: yup
            .array()
            .nullable()
            .when([], {
                is: () => showGeneralDescription,
                then: yup.array().nullable().min(1, 'Business annual report is required!'),
            }),
        source_fund: yup.string().nullable().required('Source of funds is required!'),

        country_operations: yup
            .array()
            .nullable()
            .min(1, 'Countries operation is required!')
            .required('Countries operation is required!'),

        address: yup.string().required('Operating address is required!'),
        operating_country_id: yup.string().required('Operation country is required!'),
        city: yup.string().required('City is required!'),
    });

    const form = useForm({
        defaultValues: {
            company_country: '',
            company_name: '',
            company_type: '',
            company_suffix_id: 0,
            business_activity_array: [],
            business_activity_detail: '',
            source_fund: '',
            country_operations: [],
            address: '',
            operating_country_id: '',
            city: '',
            state: '',
            postal_code: '',
            business_annual_report: [],
        },
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (!task) return;
        dispatch(fetchCompanyInfo(task.Company.country_id));
        dispatch(fetchBusinessActivity(task.Company.country_id));
    }, []);

    useEffect(() => {
        if (incorporationCountry) {
            let EntityType = incorporationCountry.EntityTypes.find(
                (e) => e.id === task?.Company.entity_type_id,
            );
            if (EntityType) {
                setCompanySuffixOptions(EntityType.CompanySuffixes);
            }
        }
    }, [incorporationCountry]);

    useEffect(() => {
        if (task) {
            let businessActivity = task.Company?.CompanyHasBusinessActivity || [];
            if (task.Company.country_id === BELIZE_ID) {
                businessActivity = task.Company?.CompanyHasBusinessActivity?.map(
                    (business) => business.sic_code_name,
                );
            } else {
                businessActivity = task.Company?.CompanyHasBusinessActivity?.map(
                    (business) => business.id,
                );
            }

            let businessAnnualReport = JSON.parse(task.Company?.business_annual_report) || [];

            let companyType = incorporationCountry?.EntityTypes?.find(
                (entity) => entity.id == task?.Company.entity_type_id,
            )?.name;

            if (task?.Company.country_id === CAYMAN_ID) {
                /* trường hợp nếu chưa có business activity (order mới) hoặc json parse ra object thì hiển thị select */
                if (!task.Company.CompanyHasBusinessActivity) {
                    setShowGeneralDescription(true);
                } else {
                    try {
                        let value =
                            typeof task.Company.CompanyHasBusinessActivity === 'string'
                                ? JSON.parse(task.Company.CompanyHasBusinessActivity)
                                : task.Company.CompanyHasBusinessActivity;
                        if (Array.isArray(value)) {
                            setShowGeneralDescription(true);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            }

            const defaultValue = {
                id: task.Company.id || null, // company id
                company_country: task.Company.Country.name || null,
                company_name: task.Company.name || '',
                company_type: companyType || '',
                company_suffix_id: task.Company.company_suffix_id || '',
                business_activity_array: businessActivity,
                business_activity_detail: task.Company.business_activity_detail || '',
                source_fund: task.Company.source_fund || '',
                country_operations:
                    task.Company.OperationCountries?.reduce((acc, cur) => [...acc, cur.id], []) ||
                    [],
                address: task.Company.address || '',
                operating_country_id: task.Company.operating_country_id || null,
                city: task.Company.operating_city || '',
                postal_code: task.Company.operating_postal_code || '',
                state: task.Company.operating_state || '',
                business_annual_report: businessAnnualReport,
            };
            setInitValues(defaultValue);
            form.reset(defaultValue);
        }
    }, [task, incorporationCountry]);

    const allowFields = useMemo(() => {
        let fields = {};
        if (initValues && comparedCompany) {
            fields = getCompanyInfoAllowFields(initValues, comparedCompany?.Company);
        }
        return fields;
    }, [initValues, comparedCompany]);

    useEffect(() => {
        try {
            // setIsLoading(true);
            if (businessActivityData) {
                let businessActivityDataTemp = [];
                const businessAnnualReportOptionsTemp = [];

                if ([UK_ID, RAK_ID, CAYMAN_ID].includes(task.Company.country_id)) {
                    businessActivityData[0].BusinessActivities.map((item) => {
                        businessActivityDataTemp.push({
                            ...item,
                            value: item.id,
                            label:
                                (item.sic_code ? '[' + item.sic_code + '] ' : '') +
                                item.description,
                        });
                    });
                }

                if (![UK_ID, RAK_ID, CAYMAN_ID, BELIZE_ID].includes(task.Company.country_id)) {
                    const businessActivities = get(
                        head(businessActivityData),
                        'BusinessActivities',
                    );
                    if (!isEmpty(businessActivities)) {
                        businessActivities.forEach((item) => {
                            businessActivityDataTemp.push({
                                ...item,
                                value: item.id,
                                label: item.name,
                            });
                        });
                    }
                }

                if (task.Company.country_id === BELIZE_ID) {
                    const temp = [];
                    businessActivityData.forEach((item) => {
                        temp.push({
                            ...item,
                            title: `${item.sic_code}-${item.name}`,
                        });
                    });
                    setOriginData(temp);
                    let tempData = buildTree(businessActivityData);
                    tempData.forEach((item) => {
                        businessActivityDataTemp.push(item);
                    });
                }

                if ([CAYMAN_ID].includes(task.Company.country_id)) {
                    businessActivityData[1].BusinessActivities.forEach((item2) => {
                        businessAnnualReportOptionsTemp.push({
                            ...item2,
                            value: item2.id,
                            label:
                                (item2.sic_code ? '[' + item2.sic_code + '] ' : '') +
                                item2.description,
                        });
                    });

                    setBusinessAnnualReportOptions(businessAnnualReportOptionsTemp);
                }

                setBusinessActivityOptions(businessActivityDataTemp);
            }
        } catch (error) {
            console.log(error);
        } finally {
            // setIsLoading(false);
        }
    }, [businessActivityData]);

    const handleSubmit = (values) => {
        const data = {
            business_activity_detail: values.business_activity_detail,
            business_activity_array:
                task.Company.country_id === BELIZE_ID
                    ? originData
                          ?.filter((item) => values.business_activity_array?.includes(item.title))
                          ?.map((item) => item.id)
                    : values.business_activity_array,
            OperationCountries: values.country_operations,
            address: values.operation_address,
            operating_country_id:
                values.operating_country_id === 250 ? US_ID : values.operating_country_id,
            operating_city: values.city,
            operating_state: values.state,
            operating_postal_code: values.postal_code,
            source_fund: values.source_fund,
            name: values.company_name,
            company_suffix_id: values.company_suffix_id,
        };
        let payload = {
            companyInformationUpdate: data,
        };
        if (values.business_annual_report?.length) {
            payload.companyInformationUpdate.business_annual_report = JSON.stringify(
                values.business_annual_report,
            );
        }
        if (Object.keys(form.formState.dirtyFields).length === 0) {
            handleNext();
        } else {
            payload = {
                companyInformationUpdate: {
                    id: task.company_id,
                    ...payload.companyInformationUpdate,
                },
            };
            onSubmit && onSubmit(payload);
        }
    };

    return (
        <CompanyInfoForm
            form={form}
            task={task}
            onSubmit={handleSubmit}
            handleBack={handleBack}
            businessActivity={businessActivityData}
            countriesOptions={countriesOptions}
            companySuffixOptions={companySuffixOptions}
            businessActivityOptions={businessActivityOptions}
            businessAnnualReportOptions={businessAnnualReportOptions}
            showGeneralDescription={showGeneralDescription}
            originData={originData}
            disabled={disabled}
            allowFields={allowFields}
        />
    );
};

export default CompanyInfo;

import React from 'react';
// === icon
// import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ErrorIcon from '@mui/icons-material/Error';
import { Tooltip } from '@mui/material';

const ANNUAL_RENEWAL_SERVICE_TYPE = 18;

export const Quantity = ({ index, setServices, services, setRecord, record, disabled }) => {
    const minusQuantity = (index) => {
        if (services[index]) {
            var quantity = services[index].quantity;
            if (services[index] && quantity > 1) {
                var tempArrGt = services;
                tempArrGt[index].quantity -= 1;
                setServices([...tempArrGt]);
            }
            if (services[index] && quantity === 1) {
                var tempArrEq = JSON.parse(JSON.stringify(services));
                let linkedServiceIndex = tempArrEq.findIndex(item => item?.parent_id === tempArrEq[index].id);
                if(linkedServiceIndex !== -1) {
                    tempArrEq.splice(linkedServiceIndex, 1);
                }
                tempArrEq.splice(index, 1);
                setServices(tempArrEq);
            }
            if (quantity === 1) {
                setRecord(record - 1);
            }
        } else {
            setRecord(record - 1);
            var tempArrNone = JSON.parse(JSON.stringify(services));
            tempArrNone.splice(index, 1);
            setServices(tempArrNone);
        }
    };

    const addQuantity = (index) => {
        if (services[index]) {
            var tempArr = services;
            tempArr[index].quantity += 1;
            setServices([...tempArr]);
        }
    };

    const isMax = () =>{
        if(services[index]?.year_not_buy_list){
            if(services[index].quantity === services[index].year_not_buy_list.length) return true
        }
        return false
    }
    return (
        <>
            <IconButton onClick={(e) => minusQuantity(index)} size="large" disabled={services[index]?.year_not_buy_list ? services[index].quantity === 1 : disabled}>
                {services[index].quantity === 1 && <DeleteIcon disabled={disabled} />}
                {services[index].quantity !== 1 && (
                    <RemoveIcon color="primary" disabled={disabled} />
                )}
            </IconButton>
            {services[index] ? services[index].quantity : 0}
            <IconButton onClick={(e) => addQuantity(index)} size="large" disabled={services[index]?.year_not_buy_list ? isMax() : disabled || services[index].service_type_id === ANNUAL_RENEWAL_SERVICE_TYPE}>
                <AddIcon color="primary" disabled={disabled} />
            </IconButton>
            {services[index]?.year_not_buy_list && services[index]?.service_type_id !== ANNUAL_RENEWAL_SERVICE_TYPE && (
                <Tooltip
                    title={`You have ${services[index]?.year_not_buy_list.map((year, index) =>
                        index > 0 ? ` ${year}` : year,
                    )} left`}
                >
                    <IconButton sx={{ padding: 0 }}>
                        <ErrorIcon />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

import React from 'react';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import InputField from 'components/form-controls/InputField';
import { calculateNumbers } from 'helper';

const SourceOfFund = ({ form, index, disabled, currencyUnit = 'USD' }) => {
    let wCashInBank = form.watch(`members.${index}.asset_source_fund_cast_in_bank`);
    let wRealEstate = form.watch(`members.${index}.asset_source_fund_real_estate`);
    let wAssetMoney = form.watch(`members.${index}.asset_source_fund_other_money`);
    let wInvestmentIncome = form.watch(`members.${index}.income_source_fund_investment`);
    let wSelfEmployed = form.watch(`members.${index}.income_source_fund_self_employed`);
    let wIncomeMoney = form.watch(`members.${index}.income_source_fund_other_money`);

    return (
        <Box sx={{ marginTop: '16px' }}>
            <Grid container rowSpacing={2} columnSpacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h5">Source of fund</Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', padding: '16px' }}>
                        <Typography textAlign={'center'}>ASSET</Typography>
                        <div className="mb-7 input-line">
                            <label>Cash in bank</label>
                            <InputField
                                type="number"
                                name={`members.${index}.asset_source_fund_cast_in_bank`}
                                form={form}
                                placeholder="0"
                                hideHelperText
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {currencyUnit}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className="mb-7 input-line">
                            <label>Real Estate</label>
                            <InputField
                                type="number"
                                name={`members.${index}.asset_source_fund_real_estate`}
                                form={form}
                                placeholder="0"
                                hideHelperText
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {currencyUnit}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        <p>Others</p>
                        <div className="mb-7 input-line">
                            <InputField
                                type="text"
                                name={`members.${index}.asset_source_fund_other_detail`}
                                form={form}
                                placeholder="Please provide specific details"
                                hideSuccessHelper
                                disabled={disabled}
                            />
                        </div>

                        <div className="mb-7 input-line">
                            <InputField
                                type="number"
                                name={`members.${index}.asset_source_fund_other_money`}
                                form={form}
                                placeholder="0"
                                hideSuccessHelper
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {currencyUnit}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        <Typography>
                            Total: {calculateNumbers(wCashInBank, wRealEstate, wAssetMoney)}{' '}
                            {currencyUnit}
                        </Typography>
                        {/* {form.formState.errors?.members?.[index]?.total_asset && (
                        <div className="text-danger">Please fill up one of the fields above!</div>
                    )} */}
                    </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', padding: '16px' }}>
                        <Typography textAlign={'center'}>INCOME</Typography>
                        <div className="mb-7 input-line">
                            <label>Investment Income</label>
                            <InputField
                                type="number"
                                name={`members.${index}.income_source_fund_investment`}
                                form={form}
                                placeholder="0"
                                hideHelperText
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {currencyUnit}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className="mb-7 input-line">
                            <label>Self-Employed</label>
                            <InputField
                                type="number"
                                name={`members.${index}.income_source_fund_self_employed`}
                                form={form}
                                placeholder="0"
                                hideHelperText
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {currencyUnit}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        <p>Others</p>
                        <div className="mb-7 input-line">
                            <InputField
                                type="text"
                                name={`members.${index}.income_source_fund_other_detail`}
                                form={form}
                                placeholder="Please provide specific details"
                                hideSuccessHelper
                                disabled={disabled}
                            />
                        </div>
                        <div className="mb-7 input-line">
                            <InputField
                                type="number"
                                name={`members.${index}.income_source_fund_other_money`}
                                form={form}
                                placeholder="0"
                                hideSuccessHelper
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {currencyUnit}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        <Typography>
                            Total:{' '}
                            {calculateNumbers(wInvestmentIncome, wSelfEmployed, wIncomeMoney)}{' '}
                            {currencyUnit}
                        </Typography>
                        {/* {form.formState.errors?.members?.[index]?.total_income && (
                        <div className="text-danger">Please fill up one of the fields above!</div>
                    )} */}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SourceOfFund;

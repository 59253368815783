import React, { useEffect, useState } from 'react';
import ReactFlow, { Background, ReactFlowProvider } from 'react-flow-renderer';

import { useSelector, shallowEqual } from 'react-redux';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import GraphControl from 'app/pages/services/components/kyc-elements/GraphControl';
import Swal from 'sweetalert2';
import { useKYCUIContext } from 'app/pages/services/order-management/order-form/kyc/KYCUIContext';

export default function KYCGraph1({ info, setNewProcessByData, process, type, isAllMemberUploaded }) {
    const { elements, setElements, openDetailModal, setGraphElement, submitStep } =
        useKYCUIContext();
    const [submitting, setSubmitting] = useState(false);
    const [firstLoaded, setFirstLoaded] = useState(false);
    const [viewLoaded, setViewLoaded] = useState(false);
    const { orderForm } = useSelector((state) => {
        return { orderForm: state.orderForm };
    }, shallowEqual);

    useEffect(() => {
        if (info && elements.length === 0 && !firstLoaded) {
            setGraphElement(info, type);
            setFirstLoaded(true);
        }
    }, [
        info,
        elements.length,
        firstLoaded,
        openDetailModal,
        // orderForm,
        // orderForm.orderInfo,
        setElements,
        setGraphElement,
    ]);

    useEffect(() => {
        if (info) {
            setGraphElement(info, type)
        }
    }, [info])
    const memberTemps = elements.filter((member) => member.is_member_node);
    /**
     * Check all file uploaded.
     * @returns true if all member's files are uploaded.
     */
    useEffect(() => {
        if(isAllMemberUploaded) {
            isAllMemberUploaded(memberTemps);
        }
    }, [memberTemps])

    /**
     * Check all corporation members are filled.
     * @returns true if all corporation members are uploaded.
     */
    const isFilledAllCorporationMember = (memberTemps) => {
        let result = true;

        for (const member of memberTemps) {
            const { children: childrenMember, type_member_id: typeMemberId } = member.data.value;
            let typeCompanyMembers = [];

            if (typeMemberId === 2) {
                for (const children of childrenMember) {
                    if (!typeCompanyMembers.includes(children.company_member_type_id)) {
                        typeCompanyMembers.push(children.company_member_type_id);
                    }
                }

                if (typeCompanyMembers.length < 3) {
                    result = false;
                    return;
                }
            }
        }

        return result;
    };

    const handleSubmit = () => {
        setSubmitting(true);
        submitStep(process).then(() => {
            setNewProcessByData()
                .then(() => {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Successful!',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                });
        });
    };

    return (
        <>
            <div className="kyc-graph">
                {submitting ? (
                    <div
                        className="position-absolute bg-light w-100 h-100"
                        style={{ top: 0, left: 0, zIndex: 100, opacity: 0.5 }}
                    ></div>
                ) : (
                    ''
                )}
                <ReactFlowProvider>
                    <ReactFlow
                        elements={elements}
                        nodesDraggable={false}
                        style={{ position: 'unset' }}
                        minZoom={0}
                        maxZoom={4}
                        id="divToPrint"
                    >
                        <Background
                            style={{ backgroundColor: '#F0F4F7', borderRadius: 8 }}
                            color="#d6d6d6"
                            gap={10}
                            variant="dots"
                            size={1}
                        />
                        <GraphControl
                            viewLoaded={viewLoaded}
                            setViewLoaded={setViewLoaded}
                            companyName={info?.Company?.name || 'company'}
                        />
                    </ReactFlow>
                </ReactFlowProvider>
            </div>
        </>
    );
}

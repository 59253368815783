import React, { useEffect, useRef, useState } from 'react';
import './deposit.styles.scss';
import { Button, IconButton, Popper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DescriptionIcon from '@mui/icons-material/Description';
import { toast } from 'react-toastify';
import { downloadFileS3, getSignedUrlS3 } from 'app/pages/_redux/files/filesCrud';
import { Modal } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { makeStyles } from '@mui/styles';
import NativeReader from '../../../order-form/document-controller/file-detail/NativeReader';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UploadBox from 'app/components/UploadBox';
import DocumentMiniList from 'app/components/DocumentMiniList';
import { updateLoadingUploadFileAction, updateOrderInfo } from 'app/pages/_redux/banking/slice';
import axios from 'axios';
import { createRecordFileBanking, getProcessDetail } from 'app/pages/_redux/banking/api';
import { deleteFileAction } from 'app/pages/_redux/banking/asyncThunk';


const useStyles = makeStyles({
    checkboxFile: {
        padding: '0!important',
    },
    selectedFile: {
        backgroundColor: ' rgba(0, 0, 0, 0.08)!important'
    },
    popover: {
        pointerEvents: 'none',
        backgroundColor: 'transparent!important'
    },
    popoverContent: {
        pointerEvents: 'auto',
        backgroundColor: 'transparent!important'
    },
});


const Deposit = ({ onNext }) => {
    const dispatch = useDispatch();
    const { orderInfo, loadingUploadFile, loadingDeleteFile, activeProcess } = useSelector(state => state.banking)
    /* ====================
    *       State
    *  ====================*/
    let [seletedNameButton, setSeletedNameButton] = useState('Select')
    let [activeTitle, setActiveTitle] = useState(null);
    let [activeCate, setActiveCate] = useState(null);
    let [showModal, setShowModal] = useState(false);
    let [modalData, setModalData] = useState(null);
    const [selectMode, setSelectMode] = useState(false);
    const [showPopover, setShowPopover] = useState(false)
    const popoverAnchor = useRef(null);
    const hoverFileRef = useRef(null);
    const uploadBoxRef = useRef(null);
    const [process, setProcess] = useState(null);

    /* ====================
    *       Hook
    *  ====================*/

    useEffect(() => {
        // console.log('aa')
        try {
            const process = orderInfo?.Task?.Processes[0];
            setProcess(process);

            // console.log(process)
            setActiveCate(process?.ProcessStep?.FileTemplates[0])
            setActiveTitle(process?.ProcessStep?.FileTemplates[0].Files.find(file => !file.is_filled));
        } catch (e) {
            // console.log(e)
        }

    }, [orderInfo]);


    function isHasUploaded(){
        return process?.ProcessStep?.FileTemplates[0]?.Files.filter(e => e.is_filled).length || false;
    }

    function checkValid() {
        return isHasUploaded();
    }

    function handleNext() {
        if (!checkValid()) return;

        onNext && onNext();
    }


    const closeModal = () => {
        setShowModal(false);
    };

    const downloadNativePDF = () => {
        return new Promise((resolve, reject) => {
            saveAs(modalData.file_url, modalData.file.name);
            resolve(true);
        })
    };

    const onUploadFile = async (files) => {
        // console.log(files, activeCate, process)

        if (!files.length || !activeCate || !process) return;

        dispatch(updateLoadingUploadFileAction('loading'));

        const promisesPutFile = [];
        const promisesCreateRecordFile = [];
        const params = []

        for (let i = 0; i < files.length; i++) {
            params.push({ "filename": files[i].name, "content_type": files[i].type });
        }

        try {
            /* gen s3 signed url */
            const { data: { data: signedUrls } } = await getSignedUrlS3(params);

            for (let i = 0; i < signedUrls.length; i++) {
                promisesPutFile.push(axios.put(signedUrls[i].url, files[i], {}));
            }

            /* Call tất cả các hàm push file */
            await Promise.all(promisesPutFile);

            /* Xử lý create record file */
            for (let i = 0; i < signedUrls.length; i++) {
                const payload = {
                    file_template_id: activeCate.id,
                    name: signedUrls[i].filename,
                    s3_path: signedUrls[i].filename,
                    mime_type: files[i].type,
                    task_id: orderInfo.Task.id
                }
                if (activeTitle.company_member_id) {
                    payload.company_member_id = activeTitle.company_member_id;
                }
                if (activeTitle.ranking_partner_id) {
                    payload.company_member_id = activeTitle.ranking_partner_id;
                }
                // console.log(payload, process)
                promisesCreateRecordFile.push(createRecordFileBanking(payload));
            }
            const finalResult = await Promise.all(promisesCreateRecordFile);

            /* Get lại process detail data để hiển thị lại các file đã upload */
            const { data: { data } } = await getProcessDetail(activeProcess.Processes[0].id);
            dispatch(updateOrderInfo(data));
            dispatch(updateLoadingUploadFileAction('idle'));
            /* reset value of input */
            uploadBoxRef.current.value = '';

        } catch (e) {
            // console.log(e);
            dispatch(updateLoadingUploadFileAction('error'));
            toast.error('Somethings went wrong!');
            /* reset value of input */
            uploadBoxRef.current.value = '';
        }
    };

    const onClickView = async doc => {
        // console.log(doc)
        try {
            const { data: { data } } = await downloadFileS3(doc.s3_path);

            if (['doc', 'docx'].includes(doc.s3_path.split('.')[1].toLowerCase())) {
                saveAs(data, doc.s3_path);
                return;
            }

            setShowModal(true);
            setModalData({ file: { name: doc.s3_path }, file_url: data });
        } catch (e) {
            toast.error('Somethings when wrong!');
        }
    };

    const onClickDelete = async doc => {
        const resultAction = await dispatch(deleteFileAction(doc.id));
        if (deleteFileAction.fulfilled.match(resultAction)) {
            /* @todo dispatch get lại process detail */
            // update data file upload
            /* Get lại process detail data để hiển thị lại các file đã upload */
            const { data: { data } } = await getProcessDetail(activeProcess.Processes[0].id);
            dispatch(updateOrderInfo(data));

            toast.success('Delete successfully');
        } else {
            toast.error('Somethings went wrong!');
        }
    };

    const popoverEnter = ({ currentTarget }) => {
        setShowPopover(true);
        popoverAnchor.current = currentTarget;
    };

    const popoverLeave = ({ currentTarget }) => {
        setShowPopover(false);
        popoverAnchor.current = null;
    };

    return (
        <section className="deposit" id="deposit">
            <p>
                Congratulations! Your bank account application has been pre-approved.

            </p>
            <div className="deposit__fees">


                Each account carries the following fees:

                <ul>
                    <li  >
                        $100.00 – Application fee (Onetime)</li>
                    <li>$250.00 – FATCA fee, applies for US citizens only (Onetime)</li>
                    <li>Minimum initial deposit $1,000</li>
                </ul>
            </div>
            <div className="deposit__next-steps">
                <p>
                    Here are your next steps:

                </p>
                Check or Wire transfer of $1,350 as for US citizens or $1,100 as for non-US citizens. Please note the followings:
                <ul>

                    <li><i className="fas fa-check"/>The initial deposit should be derived from a personal account in your name or a company account where you are the beneficial owner, if the latter is chosen, we will require proof of ownership of that company by way of Register of Shares and Shares Certificate(s). </li>
                    <li><i className="fas fa-check"/>ABSOLUTELY NO ACH WIRES ALLOWED – ANY FUNDS SENT VIA THIS METHOD WILL BE REJECTED AND WE WILL REQUIRE WIRE DETAILS OF THE SENDER IN ORDER TO REFUND THE FUNDS
                        <ul>
                            <li> If your account is in the US then you will be processing a “domestic wire transfer”</li>
                            <li>
                                If your account is outside of the US then you will be processing an “international wire transfer” </li>
                            <li>
                                Ensure you are not sending a wire transfer to Belize, Caye International Bank holds an account with its correspondent bank in the US </li>

                        </ul>
                    </li>
                    <li><i className="fas fa-check"/>ENSURE THAT THE COMPLETE “MANDATORY REFERENCE/FFC” INFORMATION IS INPUTTED ON YOUR WIRE, FAILURE TO DO SO WILL RESULT IN THE WIRE NOT BEING RECEIVED </li>
                </ul>
                <p>
                    When you have performed the wire, upload a copy of the completed/processed wire confirmation receipt. Please also fill out and upload the attached Declaration Source Funds along with its supporting documentation as to the source of wealth e.g. Statements, contracts or agreements.
                </p>
            </div>

            <div className="deposit__files">
                <div className="deposit__files--existing">
                    {/* <p><i className="fas fa-file"></i>CIBL-Declaration of Funds</p>
                    <p><i className="fas fa-file"></i>CIBL-Declaration of Funds Instructions</p>
                    <p><i className="fas fa-file"></i>EURO Exchange Wiring Instructions - USD - Blue Wing Ads Ltd.</p> */}
                    <div className={'mb-1'}>
                        {orderInfo?.Task.Processes[0].ProcessStep?.FileTemplates?.map(template => (
                            <span key={template.id}>
                                {template.Files.filter(file => !file.is_filled).map((file, i) => (
                                    <Button variant={'outlined'}
                                        className={'mr-5 mb-1'}
                                        key={file.id}
                                        onClick={() => onClickView(file)}
                                    >
                                        <DescriptionIcon color={'action'} />
                                        <span className={'ml-2'}>{file.name}</span>
                                    </Button>
                                ))}
                            </span>
                        ))}
                    </div>
                </div>
                <div className="deposit__files--upload" style={{ maxHeight: 'none' }}>
                    <div className={'p-3'}>
                        <div className='d-flex justify-content-center'>
                            <div style={{ width: '40%' }}>
                                <UploadBox onUploadFile={onUploadFile}
                                    ref={uploadBoxRef}
                                    loading={loadingUploadFile === 'loading'}
                                    panelStyle={{height: '150px'}}/>
                            </div>
                            <div style={{ width: '60%' }} className={'pl-2'}>
                                <DocumentMiniList documentList={activeCate?.Files.filter(file => file.is_filled)}
                                    onClickView={onClickView}
                                    maxHeightPanel={'100px'}
                                    onClickDelete={onClickDelete}
                                    loadingMap={loadingDeleteFile}
                                />
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div className="deposit__actions">
                <Button variant='contained'
                    className='text-white'
                    onClick={handleNext}
                    disabled={!checkValid()}
                    color='primary'>
                    SUBMIT
                </Button>
            </div>



            <Modal show={showModal} onHide={closeModal} size="xl" centered>
                <Modal.Body>
                    {modalData && (
                        <>
                            {modalData && (
                                <NativeReader
                                    closeDetailModal={closeModal}
                                    data={modalData}
                                    download={downloadNativePDF}
                                />
                            )}
                        </>
                    )}
                </Modal.Body>
            </Modal>
            <Popper open={showPopover}
                anchorEl={popoverAnchor.current}

            >
                <div
                    onMouseEnter={popoverEnter}
                    onMouseLeave={popoverLeave}>
                    <IconButton aria-label="view" size={'small'} onClick={() => {
                        // console.log(hoverFileRef.current);
                        onClickView(hoverFileRef.current);
                    }}>
                        <VisibilityIcon fontSize="inherit" />
                    </IconButton>
                </div>
            </Popper>


        </section>
    );
}

export default Deposit;

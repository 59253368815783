export const getAsProcessName = (process, serviceTypeMap) => {
    return process?.AdditionProcess?.service_type_ids
        ?.map((id) => serviceTypeMap?.[id]?.name) // Map lấy name của service type
        ?.filter((value, index, self) => index === self?.findIndex((t) => t === value)) // Lọc trùng
        ?.join(' & ');
};

export const getAsId = (process) => {
    return Math.max(...(process?.AdditionProcess?.service_type_ids || []));
};

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_helpers';
// import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { CustomerLevel } from '../CustomerLevel';
import * as Actions from 'app/pages/_redux/files/filesActions';
import { viewImageS3 } from 'app/pages/components/helper/s3';

const styleFlex = {
    flex: '2',
};
export function QuickUser() {
    const navigate = useNavigate();

    const [viewImage, setViewImage] = useState()
    const dispatch = useDispatch();

    const logoutClick = () => {
        const toggle = document.getElementById('kt_quick_user_toggle');
        if (toggle) {
            toggle.click();
        }
        navigate('/logout');
    };

    const { profile, avatar, subscription } = useSelector(
        (state) => ({
            profile: state.personal.profile,
            avatar: state.files.avatarBlob,
            subscription: state.personal.subscription,
        }),
        shallowEqual,
    );

    useEffect(() => {
        if (profile && profile?.image && profile?.image.length > 0) {
            dispatch(Actions.viewAvatarS3(profile?.image))
                .then(async (response) => {
                    if (response && response.length > 0) {
                        const blobImg = await viewImageS3(response)
                        setViewImage(blobImg)
                        dispatch(Actions.updateAvatar(blobImg))
                    }
                });
        }
    }, [profile?.image])

    return (
        <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
            <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                <h3 className="font-weight-bold m-0">
                    User Profile
                    {/* <small className="text-muted font-size-sm ml-2">12 messages</small> */}
                </h3>
                <a
                    href="#"
                    className="btn btn-xs btn-icon btn-light btn-hover-primary"
                    id="kt_quick_user_close"
                >
                    <i className="ki ki-close icon-xs text-muted" />
                </a>
            </div>

            <div className="offcanvas-content pr-5 mr-n5">
                <div className="d-flex align-items-center justify-content-between mt-5">
                    <div className="symbol symbol-100 mr-5 bgAvatar">
                        <div
                            className={`symbol-label bgBorder-${subscription?.name}`}
                            style={{
                                backgroundImage: `url(
                                    ${avatar
                                        ? `${avatar}`
                                        : profile?.image
                                            ? `${viewImage}`
                                            : '#F3F6F9'
                                    }
                                )`,
                            }}
                        />
                        {/* <i className="symbol-badge bg-success" /> */}
                        <CustomerLevel value={subscription?.id} />
                    </div>
                    <div className="d-flex flex-column" style={styleFlex}>
                        <Link to="/account/profile" className="navi-item">
                            <span className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
                                {profile?.first_name} {profile?.last_name}
                            </span>
                        </Link>
                        {/* <div className="text-muted mt-1">Application Developer</div> */}
                        <div className="navi mt-2">
                            <span className="navi-item">
                                <span className="navi-link p-0 pb-2">
                                    <span className="navi-icon mr-1">
                                        <span className="svg-icon-lg svg-icon-primary">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    '/media/svg/icons/Communication/Mail-notification.svg',
                                                )}
                                            ></SVG>
                                        </span>
                                    </span>
                                    <span className="navi-text text-muted text-hover-primary">
                                        {profile?.email}
                                    </span>
                                </span>
                            </span>
                        </div>
                        {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
                        <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>
                            Sign out
                        </button>
                    </div>
                </div>

                <div className="separator separator-dashed mt-8 mb-5" />

                <div className="navi navi-spacer-x-0 p-0">
                    <Link to="/account/profile" className="navi-item">
                        <div className="navi-link">
                            <div className="symbol symbol-40 bg-light mr-3">
                                <div className="symbol-label">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                '/media/svg/icons/General/Notification2.svg',
                                            )}
                                        ></SVG>
                                    </span>
                                    {/* <PermContactCalendarIcon style={{fontSize:"2rem"}} /> */}
                                </div>
                            </div>
                            <div className="navi-text">
                                <div className="font-weight-bold">My Profile</div>
                                <div className="text-muted">
                                    Account settings and more{' '}
                                    <span className="label label-light-danger label-inline font-weight-bold">
                                        update
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Link>

                    {/* <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Shopping/Chart-bar1.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Messages</div>
                  <div className="text-muted">Inbox and tasks</div>
                </div>
              </div>
            </a> */}

                    {/* <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Files/Selected-file.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Activities</div>
                  <div className="text-muted">Logs and notifications</div>
                </div>
              </div>
            </a>

            <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail-opened.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Tasks</div>
                  <div className="text-muted">latest tasks and projects</div>
                </div>
              </div>
            </a> */}
                </div>

                <div className="separator separator-dashed my-7"></div>
            </div>
        </div>
    );
}

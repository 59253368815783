import { Modal } from 'react-bootstrap';
import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import MemberAddForm from './MemberAddForm';
import * as Yup from 'yup';
import { ACTION_TYPES, COMPANY_MEMBER_TYPES, MEMBER_TYPES } from 'app/pages/services/constants';
import { cloneDeep, find, get, isEmpty, sumBy } from 'lodash';
import { useKYCUIContext } from 'app/pages/services/order-management/order-form/kyc/KYCUIContext';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

const validateInteger = function () {
    return this.test(function (value) {
        if ((value + '').split('.').length >= 2) {
            return this.createError({
                path: this.path,
                message: 'Please enter a positive natural value',
            });
        }

        return true;
    });
};
const transformNumber = function () {
    return this.transform(function (value) {
        return isNaN(value) ? undefined : value;
    });
};

Yup.addMethod(Yup.number, 'validatePercent', function (companyMembers, IS_LLC) {
    return this.test('validatePercent', null, function (value) {
        const { path, createError, parent } = this;
        if (!parent.company_member_id) return true; // or throw error if want

        const companyMember = find(companyMembers, ['id', parent.company_member_id]);
        const companyMemberType = companyMember?.CompanyPositions?.[0]?.company_member_type_id; // already filtered position
        const shares = get(
            companyMember,
            companyMemberType === COMPANY_MEMBER_TYPES.SHAREHOLDER.id
                ? IS_LLC
                    ? 'CompanyOwnerships'
                    : 'CompanyShares'
                : 'CompanyInterests',
            [],
        );
        const percent = shares?.reduce((acc, cur) => {
            if (!cur.corporation_company_member_id && cur.is_approved && cur.is_valid) {
                return acc + cur.value;
            }
            return acc;
        }, 0);

        return (
            value <= percent ||
            createError({ path, message: `Percent must be less than or equal to ${percent}%` })
        );
    });
});
Yup.addMethod(Yup.mixed, 'validateInteger', validateInteger);
Yup.addMethod(Yup.mixed, 'transformNumber', transformNumber);

const ModalAddMember = ({ onSubmit, page = 'company', ...props }) => {
    const { modal, closeModal } = useKYCUIContext();
    const { company_member_type_id } = modal?.data;
    const { company = {}, IS_LLC, IS_UK_LLP, hasShowSelectFullName } = props;
    const [loading, setLoading] = useState(false);
    const [isPercent, setIsPercent] = useState(false);
    const firstRender = useRef(0);
    const [isShowNote, setIsShowNote] = useState(false);
    const companyMemberIdRef = useRef({});

    /* --- SUMMIT ACTION --- */
    const handleSubmit = async (values) => {
        try {
            if (onSubmit) {
                const { isDirector, isShareholder, isUBO } = options;
                setLoading(true);
                let share = {};
                if (values?.share) {
                    const store = [];
                    values.share.forEach((eachShare) => {
                        let data = {
                            transfer_id: eachShare.id,
                            start_number: Number(eachShare.start_number),
                            value: Number(eachShare.value),
                            end_number:
                                Number(eachShare.start_number) + Number(eachShare.value) - 1, // logic xử lý dưới backend
                        };

                        if (eachShare.checked) {
                            store.push(data);
                        }
                    });
                    if (isEmpty(store)) {
                        toast.warn('Please select the transfer');
                        return;
                    } else {
                        share.share = store;
                    }
                } else {
                    share.value = values.value;
                }

                values = clearInformation(values, values.type_member_id);

                const {
                    id,
                    company_member_id,
                    percent_of_interest,
                    own_percent,
                    number_allotted,
                    ...information
                } = values;

                /* --- Share value from level 2 --- */
                const shareOps = {
                    ...(id && { company_member_id: id }),
                    company_id: company.id,
                    corporation_company_member_id: member.corporation_company_member_id,
                };
                const dataShare = {
                    ...(!isDirector &&
                        isCorporationChild && {
                            ...(isUBO && {
                                CompanyInterest: { ...shareOps, value: percent_of_interest },
                            }),
                            ...(isShareholder && {
                                ...(isPercent
                                    ? { CompanyOwnership: { ...shareOps, value: own_percent } }
                                    : { CompanyShare: { ...shareOps, value: number_allotted } }),
                            }),
                        }),
                };

                const dataMember = {
                    company_id: company.id,
                    CompanyPositions: [
                        {
                            company_member_type_id: member.company_member_type_id,
                            ...(id && { company_member_id: id }),
                            // for corporation
                            corporation_company_member_id:
                                member?.corporation_company_member_id || null,
                        },
                    ],

                    // will be choose existing member if exist id (other case is create new member)
                    ...(id ? { company_member_id: id } : information),
                    ...dataShare,
                };

                const dataTransfer = {
                    MemberToMember: {
                        company_id: company.id,
                        company_member_type_id: member?.company_member_type_id,
                        company_member_id,
                        ...share,
                        ...(id && { to_company_member_id: id }),
                    },
                };

                const optionData = {
                    transfer: company_member_id && dataTransfer,
                    isLevel1: !isCorporationChild,
                };
                await onSubmit(dataMember, optionData);
                setLoading(false);
                closeModal();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    /* --- OPTIONS --- */
    const blockOptions = useMemo(
        () => ({
            changeRequests:
                company.ChangeRequests?.reduce(
                    (res, acc) => {
                        switch (acc.change_request_status_id) {
                            case 1:
                                res.create.push(acc);
                                break;
                            case 2:
                                res.update.push(acc);
                                break;
                            case 3:
                                res.delete.push(acc);
                                break;
                            case 4:
                                res.transfer.push(acc);
                                break;
                        }

                        return res;
                    },
                    { create: [], update: [], delete: [], transfer: [] },
                ) || {},
        }),
        [company],
    );

    const options = useMemo(() => {
        if (!company) {
            return {
                member: {},
                isUBO: false,
                isShareholder: false,
                isDirector: false,
                memberOptions: [],
                memberTransferOptions: [],
            };
        }

        const member = modal.data;
        const isCorporationChild = !!member.corporation_company_member_id;
        const isUBO = member.company_member_type_id === COMPANY_MEMBER_TYPES.UBO.id;
        const isShareholder = member.company_member_type_id === COMPANY_MEMBER_TYPES.SHAREHOLDER.id;
        const isDirector = member.company_member_type_id === COMPANY_MEMBER_TYPES.DIRECTOR.id;
        let memberTransferOptions = [];
        let memberOptions = [];

        // UBO/Shareholder
        if (!isDirector) {
            const allMembers = cloneDeep(company.CompanyMembers);
            memberOptions =
                (allMembers &&
                    allMembers.filter(
                        ({ id, is_approved, CompanyPositions }) =>
                            id !== member.id &&
                            is_approved === 1 && // transferor must be approved
                            CompanyPositions?.find((p) => {
                                if (isUBO) {
                                    return (
                                        p.company_member_type_id === COMPANY_MEMBER_TYPES.UBO.id &&
                                        !p.corporation_company_member_id
                                    );
                                }
                                if (isShareholder) {
                                    return (
                                        p.company_member_type_id ===
                                            COMPANY_MEMBER_TYPES.SHAREHOLDER.id &&
                                        !p.corporation_company_member_id
                                    );
                                }
                            }),
                    )) ||
                [];
            memberOptions = memberOptions?.map((item) => {
                const filterPosition = item?.CompanyPositions?.find((p) => {
                    if (isUBO) {
                        return (
                            p.company_member_type_id === COMPANY_MEMBER_TYPES.UBO.id &&
                            !p.corporation_company_member_id
                        );
                    }
                    if (isShareholder) {
                        return (
                            p.company_member_type_id === COMPANY_MEMBER_TYPES.SHAREHOLDER.id &&
                            !p.corporation_company_member_id
                        );
                    }
                });
                item.CompanyPositions = [filterPosition];
                return {
                    ...item,
                };
            });

            const { changeRequests } = blockOptions;
            memberTransferOptions =
                memberOptions
                    ?.filter((member) => member.change_request_value !== 0)
                    ?.filter((member) => member?.CompanyPositions?.[0]?.is_approved)
                    ?.filter((member) => {
                        const isResignedMember = member?.CompanyPositions.some((position) => {
                            return changeRequests?.delete?.find((deleteMember) => {
                                deleteMember = {
                                    ...deleteMember,
                                    company_member_type_id:
                                        position?.id === deleteMember?.company_position_id
                                            ? position?.company_member_type_id
                                            : null,
                                };

                                return (
                                    position?.id === deleteMember?.company_position_id &&
                                    company_member_type_id === deleteMember.company_member_type_id
                                );
                            });
                        });

                        if (!isResignedMember) return member;
                    }) || [];
        }
        return {
            member,
            isCorporationChild,
            isUBO,
            isShareholder,
            isDirector,
            memberOptions,
            memberTransferOptions,
        };
    }, [modal, JSON.stringify(companyMemberIdRef)]);

    const { member, isCorporationChild } = options;

    /* --- HOOK FORM --- */
    const initialValues = useMemo(() => {
        const { isDirector, isShareholder, isUBO, isCorporationChild } = options;
        return {
            // form fill
            type_member_id: MEMBER_TYPES.INDIVIDUAL.id,
            corporation_name: '',
            full_name: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            country_id: undefined,
            id: null,

            ...(!isDirector &&
                (isCorporationChild
                    ? /* --- Manual input share from level 2 --- */
                      {
                          ...(isUBO && { percent_of_interest: 0 }),
                          ...(isShareholder && { own_percent: 0, number_allotted: 0 }),
                      }
                    : /* share transfer level 1 */
                      {
                          company_member_id: undefined,
                          ...((!isShareholder || IS_LLC || isUBO) && { value: '' }),
                          ...(isShareholder && !IS_LLC && { share: [] }),
                      })),
        };
    }, [options]);

    const validationSchema = useMemo(() => {
        const { isDirector, isShareholder, isUBO, memberTransferOptions } = options;

        return Yup.object().shape({
            // form fill
            // Individual
            full_name: Yup.string()
                .nullable()
                .when(['type_member_id'], {
                    is: (value) => value === MEMBER_TYPES.INDIVIDUAL.id,
                    then: Yup.string()
                        .nullable()
                        .required(
                            hasShowSelectFullName
                                ? 'Please select your full name!'
                                : 'Full name is required!',
                        ),
                }),
            first_name:
                hasShowSelectFullName &&
                Yup.string()
                    .nullable()
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.INDIVIDUAL.id,
                        then: Yup.string().required('First name is required'),
                    }),
            middle_name: hasShowSelectFullName && Yup.string().nullable(),
            last_name:
                hasShowSelectFullName &&
                Yup.string()
                    .nullable()
                    .when(['type_member_id'], {
                        is: (value) => value === MEMBER_TYPES.INDIVIDUAL.id,
                        then: Yup.string().required('Last name is required'),
                    }),

            // Organization
            corporation_name: Yup.string()
                .nullable()
                .when(['type_member_id'], {
                    is: (value) => value === MEMBER_TYPES.CORPORATION.id,
                    then: Yup.string().required('Corporation name is required'),
                }),

            id: Yup.number().nullable(),
            type_member_id: Yup.number().nullable(),
            country_id: Yup.number().nullable().required('Country is required'),

            // transfer share
            ...(!isDirector &&
                (isCorporationChild
                    ? /* --- LEVEL 2 -> n --- */
                      {
                          ...(isUBO && {
                              percent_of_interest: Yup.number()
                                  .nullable()
                                  .min(1, 'Percent of interest must be greater than 0')
                                  .max(100, 'Own percent must be less than 100')
                                  .typeError('Percent of interest is required')
                                  .required('Percent of interest is required'),
                          }),
                          ...(isShareholder && {
                              ...(isPercent
                                  ? {
                                        own_percent: Yup.number()
                                            .nullable()
                                            .min(1, 'Own percent must be greater than 0')
                                            .max(100, 'Own percent must be less than 100')
                                            .typeError('Own percent is required')
                                            .required('Own percent is required'),
                                    }
                                  : {
                                        number_allotted: Yup.number()
                                            .nullable()
                                            .min(1, 'Number allotted must be greater than 0')
                                            .typeError('Number allotted is required')
                                            .required('Number allotted is required'),
                                    }),
                          }),
                      }
                    : /* --- LEVEL 1 --- */
                      {
                          company_member_id: Yup.number()
                              .nullable()
                              .required('Please select a transferor'),
                          value: Yup.number()
                              .nullable()
                              .when([], {
                                  is: () => !isShareholder || IS_LLC || isUBO,
                                  then: Yup.number()
                                      .transformNumber()
                                      .validateInteger()
                                      .nullable()
                                      .required('Enter a percentage')
                                      .moreThan(0, 'Please enter a positive natural value')
                                      .typeError('Value must be number')
                                      .validatePercent(memberTransferOptions, IS_LLC),
                              }),
                          share: Yup.array().when([], {
                              is: () => isShareholder && !IS_LLC,
                              then: Yup.array().of(
                                  Yup.object().shape({
                                      value: Yup.number()
                                          .transformNumber()
                                          .validateInteger()
                                          .nullable()
                                          .min(1, 'The number must be greater than 0')
                                          .typeError('Value must be number')
                                          .when('checked', (checked, field) =>
                                              checked
                                                  ? field
                                                        .transformNumber()
                                                        .validateInteger()
                                                        .min(
                                                            1,
                                                            'Please enter a positive natural value',
                                                        )
                                                        .max(
                                                            Yup.ref('value_number'),
                                                            'Cannot transfer greater than total',
                                                        )
                                                        .required('Required!')
                                                  : field,
                                          ),
                                  }),
                              ),
                          }),
                      })),
        });
    }, [options, isPercent]);

    const form = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        form.reset(initialValues);
    }, [initialValues]);

    /* --- TITLE MODAL --- */
    const title = useMemo(() => {
        const { isDirector, isShareholder, isUBO } = options;
        const is_LLC_LLP = IS_LLC || IS_UK_LLP;

        if (isDirector) return is_LLC_LLP ? 'Add Manager' : 'Add Director';
        if (isShareholder) return is_LLC_LLP ? 'Add Member' : 'Add Shareholder';
        if (isUBO) return 'Add UBO';
        return 'Add';
    }, [options]);

    useEffect(() => {
        if (modal.show) {
            if (firstRender.current < 2) {
                firstRender.current += 1;
            } else {
                setIsShowNote(true);
            }
        }
    }, [form.watch()]);

    useEffect(() => {
        if (!modal.show) {
            setIsShowNote(false);
            firstRender.current = 0;
        }
    }, [modal.show]);

    if (isEmpty(company)) return null;

    return (
        <Modal
            show={[ACTION_TYPES.ADD.name].includes(member.type) && modal.show}
            backdrop="static"
            size="lg"
            animation={false}
            centered
        >
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <MemberAddForm
                        form={form}
                        isPercent={isPercent}
                        setIsPercent={setIsPercent}
                        hasShowSelectFullName={hasShowSelectFullName}
                        companyMemberIdRef={companyMemberIdRef}
                        {...blockOptions}
                        {...options}
                        {...props}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {loading && <CircularProgress size={20} color="primary" />}
                    <Button
                        className="btn btn-light btn-sm"
                        disabled={loading}
                        onClick={closeModal}
                        style={{ marginLeft: 15 }}
                        type="button"
                    >
                        Cancel
                    </Button>

                    <Button
                        color="primary"
                        variant="contained"
                        style={{ color: '#fff', marginLeft: 15 }}
                        type="submit"
                        disabled={loading}
                    >
                        Save
                    </Button>
                </Modal.Footer>
                {isShowNote && page == 'company' && (
                    <p
                        className="mb-0 text-white p-2"
                        style={{
                            backgroundColor: '#17c191',
                            borderBottomLeftRadius: '0.42rem',
                            borderBottomRightRadius: '0.42rem',
                        }}
                    >
                        <b>
                            The changes to this information may incur additional costs, please
                            review the information in the Review and Complete section after making
                            the adjustments.
                        </b>
                    </p>
                )}
            </form>
        </Modal>
    );
};

export default ModalAddMember;

const clearInformation = (data, type_member_id) => {
    if (!type_member_id) return null;
    if (data) {
        data = cloneDeep(data);
        delete data.share;
        delete data.value;

        switch (type_member_id) {
            case MEMBER_TYPES.CORPORATION.id: {
                delete data.first_name;
                break;
            }
            case MEMBER_TYPES.INDIVIDUAL.id: {
                delete data.corporation_name;
                break;
            }
            default:
                break;
        }

        return data;
    }
    return null;
};

import FileUpload from 'app/pages/services/components/kyc-elements/FileUpload';
import { getExtension } from 'app/pages/services/constants';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ActionsFile from 'app/pages/_redux/files/filesActions';
import Swal from 'sweetalert2';

const UploadFileForm = React.forwardRef(
    ({ member, disabled, changeFields, isViewMode = false, setIsInValid, uploadNote, ...props }, ref) => {
        // Files
        const [addedFiles, setAddedFiles] = useState([]);
        const [deletedFiles, setDeletedFiles] = useState([]);
        const [viewedFiles, setViewedFiles] = useState([]);
        const uploadFileRef = useRef(null);
        const dispatch = useDispatch();
        const { orderInfo } = useSelector((state) => state.additionalService);

        useEffect(() => {
            uploadFileRef.current = async (id, taskId) => {
                try {
                    await deleteMemberFiles();
                    const result = await uploadFiles(id, taskId);
                    return result;
                } catch (error) {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Somethings went wrong!',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
            };
        }, [addedFiles, deletedFiles]);

        useImperativeHandle(ref, () => ({ getData: () => uploadFileRef.current }));

        const uploadFiles = (memberId, taskId) => {
            return new Promise((resolve, reject) => {
                let changeRequest = orderInfo?.Task?.ChangeRequests?.find(
                    (item) => item.company_member_id === memberId,
                );
                if (addedFiles.length > 0) {
                    const body = addedFiles.map((item) => {
                        const fileType =
                            item.type === '' ? '.' + getExtension(item.name) : item.type;
                        return { filename: item.name, content_type: fileType };
                    });

                    dispatch(ActionsFile.genSignedUrlS3(body)).then((res) => {
                        if (res && res.data && res.data.length > 0) {
                            let Promised = [];
                            let PromisedBucket = [];

                            res.data.forEach((item, index) => {
                                PromisedBucket.push(
                                    dispatch(ActionsFile.uploadS3(item, addedFiles[index])),
                                );
                            });

                            Promise.all(PromisedBucket)
                                .then(() => {
                                    res.data.forEach((item, index) => {
                                        const fileType =
                                            addedFiles[index].type === ''
                                                ? '.' + getExtension(addedFiles[index].name)
                                                : addedFiles[index].type;
                                        let param = {
                                            member: {
                                                company_member_id: memberId,
                                                name: addedFiles[index].name,
                                                mime_type: fileType,
                                                s3_path: item.filename,
                                            },
                                        };
                                        if(taskId) {
                                            param = {
                                                member: {
                                                    ...param.member,
                                                    task_id: taskId
                                                }
                                            }
                                        }
                                        if (
                                            changeRequest &&
                                            [1, 2].includes(changeRequest.change_request_status_id)
                                        ) {
                                            param = {
                                                member: {
                                                    ...param.member,
                                                    change_request_id: changeRequest.id,
                                                },
                                            };
                                        }
                                        Promised.push(dispatch(ActionsFile.createFilesDbS3(param)));
                                    });
                                    Promise.all(Promised)
                                        .then((uploadedFiles) => {
                                            setAddedFiles([]);
                                            resolve(uploadedFiles);
                                        })
                                        .catch((error) => {
                                            reject(error);
                                        });
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        }
                    });
                } else {
                    resolve();
                }
            });
        };

        const deleteMemberFiles = () =>
            new Promise((resolve, reject) => {
                if (deletedFiles.length > 0) {
                    let PromisedRemove = [];
                    deletedFiles.forEach((item) => {
                        PromisedRemove.push(ActionsFile.deleteFileRequest(item));
                    });
                    Promise.all(PromisedRemove)
                        .then((removedFiles) => {
                            setDeletedFiles([]);
                            resolve(deletedFiles);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                } else {
                    resolve();
                }
            });

        useEffect(() => {
            setIsInValid(viewedFiles.find((item) => item.isValid === false));
        }, [viewedFiles]);

        return (
            <div className="row">
                <FileUpload
                    data={member}
                    addedFiles={addedFiles}
                    deletedFiles={deletedFiles}
                    setAddedFiles={setAddedFiles}
                    setDeletedFiles={setDeletedFiles}
                    viewedFiles={viewedFiles}
                    setViewedFiles={setViewedFiles}
                    disabled={disabled}
                    isViewMode={isViewMode}
                    orderInfo={orderInfo}
                    uploadNote={uploadNote}
                />
            </div>
        );
    },
);

export default UploadFileForm;

import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import UploadBox from '../../../../../../components/UploadBox';
import DocumentMiniList from '../../../../../../components/DocumentMiniList';
import DocumentTitleList from '../../../../../../components/DocumentTitleList';
import { Button, ButtonGroup, IconButton, Popover, Popper } from '@mui/material';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import {
    createRecordFileBanking,
    getSampleDataForApplicationSet,
    getProcessDetail, triggerMailRemindFile,
} from '../../../../../_redux/banking/api';
import { downloadFileS3, getSignedUrlS3 } from '../../../../../_redux/files/filesCrud';
import { Modal } from 'react-bootstrap';
import NativeReader from '../../../order-form/document-controller/file-detail/NativeReader';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import DescriptionIcon from '@mui/icons-material/Description';
import NoteFromCs from '../application-set/components/NoteFromCs';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import { cloneDeep } from 'lodash';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { downloadFileFromLink } from '../../../../../../helpers/utils';
import {
    updateActiveProcess,
    updateLoadingUploadFileAction,
    updateOrderInfo
} from '../../../../../_redux/banking/slice';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFileAction, fetchOrderInfo } from '../../../../../_redux/banking/asyncThunk';
import CheckCircle from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles({
    checkboxFile: {
        padding: '0!important',
    },
    selectedFile: {
        backgroundColor: ' rgba(0, 0, 0, 0.08)!important'
    },
    popover: {
        pointerEvents: 'none',
        backgroundColor: 'transparent!important'
    },
    popoverContent: {
        pointerEvents: 'auto',
        backgroundColor: 'transparent!important'
    },
});

const ApplicationForm = ({ onBack, onNext }) => {
    const classes = useStyles();
    /* ====================
    *       State
    *  ====================*/
    let [seletedNameButton, setSeletedNameButton] = useState('Select')
    let [activeTitle, setActiveTitle] = useState(null);
    let [activeCate, setActiveCate] = useState(null);
    let [showModal, setShowModal] = useState(false);
    let [modalData, setModalData] = useState(null);
    const [selectMode, setSelectMode] = useState(false);
    const [showPopover, setShowPopover] = useState(false)
    const popoverAnchor = useRef(null);
    const hoverFileRef = useRef(null);
    const uploadBoxRef = useRef(null);

    const { loadingUploadFile, loadingDeleteFile, activeProcess, orderInfo } = useSelector(state => state.banking);

    const dispatch = useDispatch();

    const [process, setProcess] = useState(null);

    /* ====================
    *       Hook
    *  ====================*/

    useEffect(() => {
        if (!orderInfo) return;

        const process = orderInfo.Task.Processes[0];
        resetSelectedFile();
        setProcess(process);
        // default file template active
        if (!activeCate) {
            setActiveCate(process.ProcessStep.FileTemplates[0])
        } else {
            /* Loại lại active cate để update upload list theo activeCate */
            setActiveCate(process.ProcessStep.FileTemplates.find(template => template.id === activeCate.id))
        }

        /* Không set active title khi đã chọn */
        if (activeTitle) return;
        setActiveTitle(process.ProcessStep.FileTemplates[0].Files.find(file => !file.is_filled));
    }, [orderInfo]);


    /* ====================
    *       Function
    *  ====================*/
    const popoverEnter = ({ currentTarget }) => {
        setShowPopover(true);
        popoverAnchor.current = currentTarget;
    };

    const popoverLeave = ({ currentTarget }) => {
        setShowPopover(false);
        popoverAnchor.current = null;
    };

    const resetSelectedFile = () => {
        const newProcess = cloneDeep(process);
        // eslint-disable-next-line no-unused-expressions
        newProcess?.ProcessStep?.FileTemplates.forEach(function (template) {
            template.Files.filter(file => !file.is_filled).map(file => {
                file.selected = false;
                return file;
            })
            return template;
        });
        setProcess(newProcess);
    }


    const onUploadFile = async (files) => {
        // console.log(orderInfo)
        if (!files.length || !activeCate || !process) return;

        dispatch(updateLoadingUploadFileAction('loading'));

        const promisesPutFile = [];
        const promisesCreateRecordFile = [];
        const params = []

        for (let i = 0; i < files.length; i++) {
            params.push({ "filename": files[i].name, "content_type": files[i].type });
        }

        try {
            /* gen s3 signed url */
            const { data: { data: signedUrls } } = await getSignedUrlS3(params);

            for (let i = 0; i < signedUrls.length; i++) {
                promisesPutFile.push(axios.put(signedUrls[i].url, files[i], {}));
            }

            /* Call tất cả các hàm push file */
            await Promise.all(promisesPutFile);

            /* Xử lý create record file */
            for (let i = 0; i < signedUrls.length; i++) {
                const payload = {
                    file_template_id: activeCate.id,
                    name: signedUrls[i].filename,
                    s3_path: signedUrls[i].filename,
                    mime_type: files[i].type,
                    task_id: orderInfo.Task.id
                }
                if (activeTitle.company_member_id) {
                    payload.company_member_id = activeTitle.company_member_id;
                }
                if (activeTitle.ranking_partner_id) {
                    payload.ranking_partner_id = activeTitle.ranking_partner_id;
                }
                // console.log('payload: ', payload)
                promisesCreateRecordFile.push(createRecordFileBanking(payload));
            }

            const finalResult = await Promise.all(promisesCreateRecordFile);

            // update file in process
            /* Get lại process detail data để hiển thị lại các file đã upload */
            const { data: { data } } = await getProcessDetail(activeProcess.Processes[0].id);
            dispatch(updateOrderInfo(data));

            dispatch(updateLoadingUploadFileAction('idle'));
            /* reset value of input */
            uploadBoxRef.current.value = '';

            const newProcess = data.Task.Processes[0];

            if (orderInfo.Task.is_remind_client_review_application_form && isUploadedFile(newProcess.ProcessStep.FileTemplates)) {
                const payload = {
                    task_id: orderInfo.Task.id,
                    is_remind_client_review_application_form: 0,
                    process_id: activeProcess.Processes[0].id
                }
                console.log('stop mail');
                await triggerMailRemindFile(payload);

                /* Get lại process detail data để hiển thị lại các file đã upload */
                const { data: { data } } = await getProcessDetail(activeProcess.Processes[0].id);
                dispatch(updateOrderInfo(data));
            }
        } catch (e) {
            // console.log(e);
            dispatch(updateLoadingUploadFileAction('error'));
            toast.error('Somethings went wrong!');
            /* reset value of input */
            uploadBoxRef.current.value = '';
        }
    };

    const onClickView = async doc => {
        try {
            const { data: { data } } = await downloadFileS3(doc.s3_path);

            if (['doc', 'docx'].includes(doc.s3_path.split('.')[1].toLowerCase())) {
                saveAs(data, doc.s3_path);
                return;
            }

            setShowModal(true);
            setModalData({ file: { name: doc.s3_path }, file_url: data });
        } catch (e) {
            toast.error('Somethings when wrong!');
        }
    };

    const onClickDelete = async doc => {
        const resultAction = await dispatch(deleteFileAction(doc.id));
        if (deleteFileAction.fulfilled.match(resultAction)) {
            /* Get lại process detail data để hiển thị lại các file đã upload */
            const { data: { data } } = await getProcessDetail(activeProcess.Processes[0].id);
            dispatch(updateOrderInfo(data));

            const newProcess = data.Task.Processes[0];

            if (!orderInfo.Task.is_remind_client_review_application_form && !isUploadedFile(newProcess.ProcessStep.FileTemplates)) {
                const payload = {
                    task_id: orderInfo.Task.id,
                    is_remind_client_review_application_form: 1,
                    process_id: activeProcess.Processes[0].id
                }
                console.log('start mail');
                await triggerMailRemindFile(payload);

                /* Get lại process detail data để hiển thị lại các file đã upload */
                const { data: { data } } = await getProcessDetail(activeProcess.Processes[0].id);
                dispatch(updateOrderInfo(data));
            }
        } else {
            toast.error('Somethings went wrong!');
        }
    };

    const onSelectTitle = ([cate, file]) => {
        setActiveCate(cate);
        setActiveTitle(file);
    };

    const uploadList = useMemo(() => {
        return activeCate?.Files.filter((file) =>
            file.is_filled && file.file_template_id === activeCate.id) || []
    }, [activeCate, activeTitle]);

    const closeModal = () => {
        setShowModal(false);
    };

    const downloadNativePDF = () => {
        return new Promise((resolve, reject) => {
            saveAs(modalData.file_url, modalData.file.name);
            resolve(true);
        })
    };

    const handleCheckFile = (selectedFile) => {
        const newProcess = cloneDeep(process);
        // eslint-disable-next-line no-unused-expressions
        newProcess?.ProcessStep?.FileTemplates.forEach(function (template, index) {
            template.Files.forEach((file, index1) => {
                if (file.id === selectedFile.id) {
                    newProcess.ProcessStep.FileTemplates[index].Files[index1].selected = !process.ProcessStep.FileTemplates[index].Files[index1].selected;
                }
            })
        });
        setProcess(newProcess);
    }

    const downloadMultipleFile = async () => {
        const downloadFiles = [];
        // eslint-disable-next-line no-unused-expressions
        process?.ProcessStep?.FileTemplates.forEach(function (template) {
            template.Files.forEach((file) => {
                if (file.selected) {
                    downloadFiles.push(file);
                }
            })
        });
        setSelectMode(false);
        for (let i = 0; i < downloadFiles.length; i++) {
            try {
                const { data: { data } } = await downloadFileS3(downloadFiles[i].s3_path);
                saveAs(data, downloadFiles[i].s3_path);
            } catch (e) {
                toast('Somethings when wrong!');
            }
        }
    }

    function renderNote() {
        return process?.ProcessNote[0] && (process?.ProcessNote[0].description || process?.ProcessNote[0].Files.length > 0)
            ? <NoteFromCs note={process?.ProcessNote[0].description}
                files={process?.ProcessNote[0].Files}
                onFileClick={onClickView} />
            : ''
    }

    function isCheckValid() {
        return isUploadedFile(process?.ProcessStep?.FileTemplates);
    }

    function isUploadedFile(files) {
        let err = false;
        if( process ) {
            for (let template of files) {
                if (!isUploaded(template.id, files)) {
                    err = true
                    break;
                }
            }
        }
        return !err
    }

    function isUploaded(templateID, files) {
        const countFileTemplate = files?.find(e => e.id === templateID)?.Files.filter(file => !file.is_filled).length
        const countUploadedFile = files?.find(e => e.id === templateID)?.Files.filter(file => file.is_filled).length
        return countUploadedFile >= countFileTemplate
    }

    function handleNext() {
        if (!isCheckValid()) return;
        onNext && onNext();
    }

    return (
        <>
            <div className={'row shadow-sm rounded mb-5'}>
                <div className={'col-12 col-md-9 border-right p-0'}>
                    <div className={'d-flex justify-content-between p-5'}>
                        <h6 className={''}>APPLICATION FORM</h6>
                        <div className={''}>
                            {selectMode
                                ? <Button className={'mr-2 text-danger'} onClick={() => setSelectMode(false)}>CANCEL</Button>
                                : <Button className={'mr-2'} onClick={() => {
                                    setSelectMode(true);
                                    resetSelectedFile();
                                }}>SELECT</Button>
                            }

                            <Button color={'primary'}
                                disabled={!selectMode}
                                onClick={downloadMultipleFile}
                            >DOWNLOAD</Button>
                        </div>
                    </div>

                    <div className={'px-5'}>
                        <div className={'mb-5'} style={{ minHeight: '277px' }}>
                            {process?.ProcessStep?.FileTemplates?.map(template => (
                                <span key={template.id} style={{whiteSpace: 'nowrap'}}>
                                    {template.Files.filter(file => !file.is_filled).map(file => (
                                        <Fragment key={file.id}>
                                            <ButtonGroup>
                                                <Button variant={'outlined'}
                                                        className={'mb-5 ' + (activeTitle?.id === file.id && !selectMode ? classes.selectedFile : '')}
                                                        key={file.id}
                                                        onClick={(e) => {
                                                            if (selectMode) {
                                                                handleCheckFile(file);
                                                                return;
                                                            }
                                                            setActiveCate(template);
                                                            setActiveTitle(file);
                                                        }}
                                                >
                                                    {selectMode && <Checkbox
                                                        className={classes.checkboxFile}
                                                        checked={file.selected}
                                                        onChange={() => handleCheckFile(file)}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />}

                                                    <DescriptionIcon color={'action'} />
                                                    <span className={'ml-2'}>{file.name}</span>

                                                    {
                                                        isUploaded(template.id, process?.ProcessStep?.FileTemplates) ? (<CheckCircle
                                                            color='primary'
                                                            className='d-inline ml-2'
                                                            style={{ fontSize: '0.9rem', marginBottom: 2 }}
                                                        />) : ''
                                                    }
                                                </Button>
                                                <Button variant={'outlined'}
                                                        className={'mr-5 mb-5 text-lowercase px-2'}
                                                        onClick={() => {
                                                            void onClickView(file);
                                                        }}>view <VisibilityIcon className={'ml-1'} fontSize="inherit" />
                                                </Button>
                                            </ButtonGroup>


                                        </Fragment>
                                    ))}
                                </span>
                            ))}

                        </div>


                        {renderNote()}


                    </div>
                </div>

                <div className={'col-12 col-md-3 p-0 '}>

                    <h6 className={'text-center border-bottom p-5'}>UPLOAD</h6>

                    <div className={'p-3'}>
                        <UploadBox onUploadFile={onUploadFile}
                            loading={loadingUploadFile === 'loading'}
                            ref={uploadBoxRef} />
                        <DocumentMiniList documentList={uploadList}
                            onClickView={onClickView}
                            onClickDelete={onClickDelete}
                            loadingMap={loadingDeleteFile}
                        />
                    </div>
                </div>
            </div>
            <div className={'d-flex justify-content-end'}>
                <Button variant='contained'
                    className='text-white'
                    onClick={handleNext}
                    disabled={!isCheckValid()}
                    color='primary'>
                    Next
                </Button>
            </div>

            <Modal show={showModal} onHide={closeModal} size="xl" centered>
                <Modal.Body>
                    {modalData && (
                        <>
                            {modalData && (
                                <NativeReader
                                    closeDetailModal={closeModal}
                                    data={modalData}
                                    download={downloadNativePDF}
                                />
                            )}
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ApplicationForm;

import React, { useEffect, useState } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { Controller } from 'react-hook-form';

export default function Represented({
    index,
    body,
    onChange,
    values,
    representedLable,
    form,
    name = 'members',
    disabled = false,
}) {
    const handleChange = (e) => {
        try {
            if (e.target.checked) {
                if (values && values.length) {
                    values.map((item, itemIndex) => {
                        if (item.is_represented) {
                            form.setValue(`${name}.${itemIndex}.is_represented`, false);
                        }
                    });
                    form.setValue(`${name}.${index}.is_represented`, true);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="represented">
                <Controller
                    control={form.control}
                    name={`${name}.${index}.is_represented`}
                    render={() => (
                        <FormControlLabel
                        control={
                            <Checkbox
                                    checked={values[index].is_represented}
                                    onChange={(e) => handleChange(e)}
                                    color="primary"
                                    disabled={disabled}
                                />
                            }
                            label={representedLable}
                        />
                    )}
                />
            </div>
        </>
    );
}

import React, { useEffect } from 'react';
import InputField from 'components/form-controls/InputField';
import RadioField from 'components/form-controls/RadioField';

export default function UseNominee({ form }) {
    useEffect(() => {
        if (form.watch('use_nominee') === '92') {
            form.trigger('use_nominee_reason');
        }
    }, [form.watch('use_nominee')]);

    return (
        <>
            <p className="fw-500">Does your company use nominee structure?</p>
            <div>
                <RadioField
                    form={form}
                    aria-label="use_nominee"
                    name="use_nominee"
                    radioGroupProps={{
                        sx: { flexDirection: 'row' },
                    }}
                    options={[
                        { id: '92', name: 'Yes' },
                        { id: '93', name: 'No' },
                    ]}
                />
                {form.watch('use_nominee') === '92' && (
                    <>
                        <p className="fw-500">If yes, please specify reason</p>
                        <div className="row">
                            <div className="col-xl-8 mb-7 input-line">
                                <label>Reason</label>
                                <InputField
                                    form={form}
                                    name="use_nominee_reason"
                                    placeholder="Please specify reason"
                                    showIcon
                                    hideSuccessHelper
                                    onChange={(e) => e.target.value?.length === 1 && form.trigger('use_nominee_reason')}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

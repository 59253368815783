import React from 'react';
import { Link } from 'react-router-dom';
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
import DETAIL_ICON from '../../../images/order-detail-icon.svg';

import { getLinkContentRoute } from '../../../OrderManagermentUIHelpers';
import { BANK_HAS_PROCCESS, LINK } from 'app/pages/services/constants/banking_constants';

import { filterProcesses, filterProcessesFinished } from '../../OrdersUIHelpers.js';
import Button from '@mui/material/Button';
import { fetchOrderInfo, setBankingIdAction } from 'app/pages/_redux/banking/asyncThunk';
import { toast } from 'react-toastify';
import { updateActiveProcess } from 'app/pages/_redux/banking/slice';
import { Tooltip } from '@mui/material';
import { getAsId, getAsProcessName } from '../../utils';

export const DetailFormatter = (cell, row, rowIndex, formatExtraData) => {
    const { serviceTypeMap, countriesHasService } = formatExtraData;
    let ProcessFilter = filterProcesses(row);
    let ProcessesFinishedFilter = filterProcessesFinished(row);

    let hiddenDetailIncorporation = false;
    // Đối với quốc gia Singapore, Jersey và Cyprus sẽ không hiển thị nút Detail.
    if (row.Task.IncorporationCountry) {
        let country_id = row.Task.IncorporationCountry.id;
        if (country_id === 191 || country_id === 111 || country_id === 58) {
            hiddenDetailIncorporation = true;
        } else {
            // đối với HK sẽ không ẩn khi order finished
            if (ProcessesFinishedFilter.Incorporation.FinishedProcesses && country_id !== 98) {
                // Incorporation has step Finished.)
                hiddenDetailIncorporation = true;
            }
        }
    }

    let bank_id = 2; // gán tạm chờ api
    const showDetailBanking = BANK_HAS_PROCCESS.bank_ids.find((id) => id === bank_id);

    const linkto = (linkObj) => {
        return getLinkContentRoute(linkObj, row.id);
    };

    const getBankingLink = () => {
        let final_step_banking_id = Math.max.apply(
            Math,
            row.Task.Processes.map(function (item) {
                return item.process_step_id;
            }),
        );
        const bankingToolProcess = row.Task.Processes.find(
            (process) => process.process_step_id === 38,
        );

        if (final_step_banking_id === 38 || bankingToolProcess) {
            return getLinkContentRoute(LINK.BANKING_TOOL, row.id);
        }
        return getLinkContentRoute(LINK.BANKING, row.id);
    };

    return (
        <div className="detail">
            {/* {
            !hiddenDetailIncorporation && ProcessFilter.Incorporation.Processes.length > 0 &&
                <div>
                    <Tooltip title="Detail">
                        <IconButton onClick={() => formatExtraData.handleClickDetail(row)} >
                            <img src={DETAIL_ICON} width={11} height={12} alt="detail" />
                        </IconButton>
                    </Tooltip>
                </div>
        } */}

            {!hiddenDetailIncorporation && ProcessFilter.Incorporation.Processes.length > 0 && (
                <div>
                    <Link className="btn-detail" to={linkto(LINK.INCORPORATION)}>
                        Incorporation
                        <img src={DETAIL_ICON} width={11} height={12} alt="detail" />
                    </Link>
                </div>
            )}

            {
                /* 1. Nếu có process banking và chưa có company name, entity_type, country thì hiển thị (trường hợp order ở bbcincorp)
                 *  2. Nếu có process banking và country nằm trong 20 nước thì hiển thị.
                 *  */
                // showDetailBanking && ProcessFilter.Banking.Processes.length > 0 && và không có step close (id 51)
                !!(
                    ProcessFilter.Banking.Processes.length &&
                    ProcessesFinishedFilter?.Banking?.FinishedProcesses?.process_step_id !== 51 &&
                    ((!row.Company.name &&
                        !row.Company.country_id &&
                        !row.Company.entity_type_id &&
                        row.Company.is_outside_company) ||
                        countriesHasService?.find(
                            (item) =>
                                item.id ===
                                (row.Company.Country?.id || row.Task?.IncorporationCountry?.id),
                        ))
                ) && (
                    <div>
                        <Link className="btn-detail" to={getBankingLink()}>
                            Banking
                            <img src={DETAIL_ICON} width={11} height={12} alt="detail" />
                        </Link>
                    </div>
                )
            }

            {
                // showDetailBanking && ProcessFilter.Banking.Processes.length > 0 &&
                ProcessFilter.AdditionalServices.Processes.length > 0 &&
                    ProcessFilter.AdditionalServices.Processes.map((asProcess) => (
                        <div>
                            <Link
                                key={asProcess.id}
                                className="btn-detail"
                                to={
                                    getLinkContentRoute(LINK.ADDITIONAL_SERVICE, row.id) +
                                    `?service_type_id=${getAsId(asProcess)}`
                                }
                            >
                                <Tooltip title={getAsProcessName(asProcess, serviceTypeMap)}>
                                    <span
                                        style={{
                                            maxWidth: '250px',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {getAsProcessName(asProcess, serviceTypeMap)}
                                    </span>
                                </Tooltip>
                                <img src={DETAIL_ICON} width={11} height={12} alt="detail" />
                            </Link>
                        </div>
                    ))
            }

            {ProcessFilter.PostIncorporation.Processes.length > 0 &&
                ProcessFilter.PostIncorporation.Processes.map((asProcess) => (
                    <div>
                        <Link
                            key={asProcess.id}
                            className="btn-detail"
                            to={
                                getLinkContentRoute(LINK.ADDITIONAL_SERVICE, row.id) +
                                `?service_type_id=${getAsId(asProcess)}`
                            }
                        >
                            <Tooltip title={getAsProcessName(asProcess, serviceTypeMap)}>
                                <span
                                    style={{
                                        maxWidth: '250px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {getAsProcessName(asProcess, serviceTypeMap)}
                                </span>
                            </Tooltip>
                            <img src={DETAIL_ICON} width={11} height={12} alt="detail" />
                        </Link>
                    </div>
                ))}

            {/* Change officers */}
            {ProcessFilter.ChangeOfficers.Processes.length > 0 &&
                ProcessFilter.ChangeOfficers.Processes.map((asProcess) => (
                    <div>
                        <Link
                            key={asProcess.id}
                            className="btn-detail"
                            to={
                                getLinkContentRoute(LINK.ADDITIONAL_SERVICE, row.id) +
                                `?service_type_id=${getAsId(asProcess)}`
                            }
                        >
                            <Tooltip title={getAsProcessName(asProcess, serviceTypeMap)}>
                                <span
                                    style={{
                                        maxWidth: '250px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {getAsProcessName(asProcess, serviceTypeMap)}
                                </span>
                            </Tooltip>
                            <img src={DETAIL_ICON} width={11} height={12} alt="detail" />
                        </Link>
                    </div>
                ))}

            {/* Bookeeping Process */}
            {ProcessFilter.BookeepingProcess.Processes.length > 0 &&
                ProcessFilter.BookeepingProcess.Processes.map((asProcess) => (
                    <div>
                        <Link
                            key={asProcess.id}
                            className="btn-detail"
                            to={
                                getLinkContentRoute(LINK.ADDITIONAL_SERVICE, row.id) +
                                `?service_type_id=${getAsId(asProcess)}`
                            }
                        >
                            <Tooltip title={getAsProcessName(asProcess, serviceTypeMap)}>
                                <span
                                    style={{
                                        maxWidth: '250px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {getAsProcessName(asProcess, serviceTypeMap)}
                                </span>
                            </Tooltip>
                            <img src={DETAIL_ICON} width={11} height={12} alt="detail" />
                        </Link>
                    </div>
                ))}
        </div>
    );
};

import { Button, FormControlLabel, Grid, Radio, RadioGroup, Tooltip } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles } from "@mui/styles";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";

const options = [
    {
        "id": 1,
        "title": "Member-managed",
        "tooltip": "The management of the company is reserved for the members.",
    },
    {
        "id": 2,
        "title": "Manager-managed",
        "tooltip": "The management of the company is carried on by a manager or managers.",
    }
]

const useStyles = makeStyles((theme) => ({
    arrow: {
        color: '#3783e7',
    },
    tooltip: {
        fontSize: '12px',
        fontWeight: 300,
        letterSpacing: '0.3px',
        backgroundColor: '#3783e7',
    },
}));

const validateSchema = yup.object().shape({
    articles_of_organization: yup.number(),
});

const ArticleOfOrganization = ({ task, disabled, isLoading, isHideAction, onSubmit, handleNext, handleBack }) => {
    const classes = useStyles();

    const form = useForm({
        defaultValues: {
            articles_of_organization: task?.Company?.articles_of_organization,
        },
        resolver: yupResolver(validateSchema)
    });

    const handleSubmit = (values) => {
        if (Object.keys(form.formState.dirtyFields).length === 0) {
            handleNext();
        } else {
            let payload = {
                articleOfOrganizationUpdate: {
                    articles_of_organization:
                        task.is_has_director || task.is_has_shareholder
                            ? options[1].id
                            : values.articles_of_organization
                },
            };
            onSubmit && onSubmit(payload, true);
        }
    };

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
                <div className="mb-7">
                    <RadioGroup
                        row
                        aria-label={'articles_of_organization'}
                        name={'articles_of_organization'}
                        onChange={(e) => {
                            console.log('1')
                            form.setValue('articles_of_organization', parseInt(e.target.value));
                        }}
                    >
                        <div className="d-flex align-items-center mr-md-20">
                            <FormControlLabel
                                value={options[0].id}
                                checked={
                                    form.watch('articles_of_organization') === options[0].id
                                }
                                control={<Radio color="secondary" />}
                                label={options[0].title}
                                className=" mr-0 mb-0"
                                disabled={
                                    (task && task.is_has_director) ||
                                    (task && task.is_has_shareholder) ||
                                    disabled
                                }
                            />
                            <div className="wrap-label-has-tooltip">
                                <div className="wrap-tooltip d-inline ml-1">
                                    <Tooltip
                                        title={options[0].tooltip}
                                        placement="top"
                                        arrow
                                        classes={classes}
                                    >
                                        <InfoIcon className="icon" />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <FormControlLabel
                                value={options[1].id}
                                checked={
                                    form.watch('articles_of_organization') === options[1].id
                                }
                                control={<Radio color="secondary" />}
                                label={options[1].title}
                                className=" mr-0 mb-0"
                                disabled={
                                    (task && task.is_has_director) ||
                                    (task && task.is_has_shareholder) ||
                                    disabled
                                }
                            />
                            <div className="wrap-label-has-tooltip">
                                <div className="wrap-tooltip d-inline ml-1">
                                    <Tooltip
                                        title={options[1].tooltip}
                                        placement="top"
                                        arrow
                                        classes={classes}
                                    >
                                        <InfoIcon className="icon" />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </RadioGroup>
                </div>
                {!isHideAction && (
                    <Grid item xs={12}>
                        <Button
                            size="large"
                            variant="contained"
                            disableElevation
                            disabled={isLoading || form.isSubmitting}
                            onClick={handleBack}
                            className={'mr-2'}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            type="submit"
                            style={{ color: '#fff' }}
                            disabled={isLoading}
                        >
                            Next
                        </Button>
                    </Grid>
                )}
            </form>
    )
};

export default ArticleOfOrganization;
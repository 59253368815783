import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { StepButton } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import StepArticlesOfOrganization from './step-articles-of-organization';
import { ArticlesOfOrganizationOptions } from '../constants';
import { setArticlesOfOrganizationDefault } from '../helpers';
import { formatCompanyMembers } from './step3-helper/helpers';
// import * as actionsOrderForm from '../../../../../../_redux/order-form/orderFormActions';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

// Except step in Company Structure Step
export const listCountryExceptStep = [
    { idStep: 0, country_id: 237, entity_type_id: 7 },
    { idStep: 1, country_id: 237, entity_type_id: 7 },
];

function InstructionIncorporationStep3(
    {
        activeParentStep,
        setIsActiveParentStep,
        step4Back,
        setStep4Back,
        isHongkong,
        isSVG,
        body,
        setBody,
        openParentStep,
        setOpenParentStep,
        validateParentStepsRef = {},
        activeStepParentRef = {},
        isOrderFormIsFilled,
        validateStepsRef = {},
        childrenStepRef = {},
        activeStepChildrenFromParent,
        hasShowSelectFullName
    },
    ref,
) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(activeStepChildrenFromParent ?? 0);
    const formikRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [stepList, setStepList] = useState(null);

    const parentStepIndex = 2;
    const currentStepIndex = useRef(0);
    const activeStepRef = useRef(null);

    useImperativeHandle(ref, () => ({
        onActiveStep: (newStep, isClickToParentStep, activeStepFromParent) => {
            handleActiveStep(newStep, isClickToParentStep, activeStepFromParent);
        },
    }));

    useEffect(() => {
        if (activeStepChildrenFromParent) {
            currentStepIndex.current = activeStepChildrenFromParent;
        }
    }, [activeStepChildrenFromParent]);

    let changeTitleWithCountry = () => {
        let steps = [];
        try {
            if (body && body.isBelize && body.isLLC) {
                // Belize là country đặc biệt trong danh sách country LLC: có step "Articles of Organization"
                steps = [
                    { id: 0, name: 'Capital contribution' },
                    { id: 1, name: 'Articles of Organization' },
                    { id: 2, name: 'Managers' },
                    { id: 3, name: 'Members' },
                    { id: 4, name: 'Ultimate Beneficial Owners' },
                ];
            } else if (body && body.isLLC) {
                // ds country LLC, trong điều kiện bao gồm Belize
                steps = [
                    { id: 0, name: 'Capital contribution' },
                    { id: 1, name: 'Managers' },
                    { id: 2, name: 'Members' },
                    { id: 3, name: 'Ultimate Beneficial Owners' },
                ];
            } else if (body.Company.country_id === 237 && body.Company.entity_type_id === 7) {
                // UK LLP
                steps = [
                    { id: 0, name: 'Share Capital' },
                    { id: 1, name: 'Directors' },
                    { id: 2, name: 'Members' },
                    { id: 3, name: 'Ultimate Beneficial Owners' },
                ];
            } else {
                steps = [
                    { id: 0, name: 'Share Capital' },
                    { id: 1, name: 'Directors' },
                    { id: 2, name: 'Shareholders' },
                    { id: 3, name: 'Ultimate Beneficial Owners' },
                ];
            }
        } catch (error) {
            console.log(error);
        }
        return steps;
    };

    let steps = changeTitleWithCountry();
    // let steps =
    //     body && body.isLLC
    //         ? [
    //             { id: 0, name: 'Capital contribution' },
    //             { id: 1, name: 'Managers' },
    //             { id: 2, name: 'Members' },
    //             { id: 3, name: 'Ultimate Beneficial Owners' },
    //         ]
    //         : changeTitleWithCountry();

    // const steps =
    //     body && body.isLLC
    //         ? ['Capital contribution', 'Managers', 'Members', 'Ultimate Beneficial Owners']
    //         : ['Share Capital', 'Directors', 'Shareholders', 'Ultimate Beneficial Owners'];

    function onSubmitForm(index) {
        setActiveStep(index);
    }

    function getStepContent(step, index) {
        if (body && body.isBelize && body.isLLC) {
            switch (step) {
                case 0:
                    return (
                        <Step1
                            setActiveStep={setActiveStep}
                            activeStep={activeStep}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            formikRef={formikRef}
                            setIsActiveParentStep={setIsActiveParentStep}
                            isHongkong={isHongkong}
                            body={body}
                            setBody={setBody}
                            index={step}
                            activeStepRef={activeStepRef}
                            validateStepsRef={validateStepsRef}
                        />
                    );
                // <StepArticlesOfOrganization />
                case 1:
                    return (
                        <StepArticlesOfOrganization
                            setActiveStep={setActiveStep}
                            activeStep={activeStep}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            formikRef={formikRef}
                            setIsActiveParentStep={setIsActiveParentStep}
                            isHongkong={isHongkong}
                            body={body}
                            setBody={setBody}
                            index={step}
                            activeStepRef={activeStepRef}
                            validateStepsRef={validateStepsRef}
                        />
                    );
                case 2:
                    return (
                        <Step2
                            setActiveStep={setActiveStep}
                            activeStep={activeStep}
                            isHongkong={isHongkong}
                            isSVG={isSVG}
                            formikRef={formikRef}
                            body={body}
                            setBody={setBody}
                            setIsActiveParentStep={setIsActiveParentStep}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            index={step}
                            activeStepRef={activeStepRef}
                            validateStepsRef={validateStepsRef}
                            hasShowSelectFullName={hasShowSelectFullName}
                        />
                    );
                case 3:
                    return (
                        <Step3
                            setActiveStep={setActiveStep}
                            activeStep={activeStep}
                            isHongkong={isHongkong}
                            isSVG={isSVG}
                            formikRef={formikRef}
                            body={body}
                            setBody={setBody}
                            setIsActiveParentStep={setIsActiveParentStep}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            index={step}
                            activeStepRef={activeStepRef}
                            validateStepsRef={validateStepsRef}
                            hasShowSelectFullName={hasShowSelectFullName}
                        />
                    );
                case 4:
                    return (
                        <Step4
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            setIsActiveParentStep={setIsActiveParentStep}
                            body={body}
                            isSVG={isSVG}
                            formikRef={formikRef}
                            setBody={setBody}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            activeStepRef={activeStepRef}
                            validateStepsRef={validateStepsRef}
                            index={step}
                            hasShowSelectFullName={hasShowSelectFullName}
                        />
                    );
                default:
                    return 'Unknown step';
            }
        } else {
            switch (step) {
                case 0:
                    return (
                        <Step1
                            setActiveStep={setActiveStep}
                            activeStep={activeStep}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            formikRef={formikRef}
                            setIsActiveParentStep={setIsActiveParentStep}
                            isHongkong={isHongkong}
                            body={body}
                            setBody={setBody}
                            index={step}
                            activeStepRef={activeStepRef}
                            validateStepsRef={validateStepsRef}
                        />
                    );
                case 1:
                    return (
                        <Step2
                            setActiveStep={setActiveStep}
                            activeStep={activeStep}
                            isHongkong={isHongkong}
                            isSVG={isSVG}
                            formikRef={formikRef}
                            body={body}
                            setBody={setBody}
                            setIsActiveParentStep={setIsActiveParentStep}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            index={step}
                            activeStepRef={activeStepRef}
                            validateStepsRef={validateStepsRef}
                            hasShowSelectFullName={hasShowSelectFullName}
                        />
                    );
                case 2:
                    return (
                        <Step3
                            setActiveStep={setActiveStep}
                            activeStep={activeStep}
                            isHongkong={isHongkong}
                            isSVG={isSVG}
                            formikRef={formikRef}
                            body={body}
                            setBody={setBody}
                            setIsActiveParentStep={setIsActiveParentStep}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            index={step}
                            activeStepRef={activeStepRef}
                            validateStepsRef={validateStepsRef}
                            hasShowSelectFullName={hasShowSelectFullName}
                        />
                    );
                case 3:
                    return (
                        <Step4
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            setIsActiveParentStep={setIsActiveParentStep}
                            body={body}
                            isSVG={isSVG}
                            formikRef={formikRef}
                            setBody={setBody}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            activeStepRef={activeStepRef}
                            validateStepsRef={validateStepsRef}
                            index={step}
                            hasShowSelectFullName={hasShowSelectFullName}
                        />
                    );
                default:
                    return 'Unknown step';
            }
        }
    }

    function isOpenStep(obj) {
        let openStep = true;
        for (let item in obj) {
            if (obj[item] === null || obj[item] === undefined || obj[item] === '') {
                openStep = false;
            }
        }
        return openStep;
    }

    const isCheckEntityType = () => {
        return listCountryExceptStep.some((e) => {
            if (e.entity_type_id === body.Company.entity_type_id) return true;
            if (!e.hasOwnProperty('entity_type_id')) return true;
            return false;
        });
    };

    const exceptStepWithCountry = () => {
        if (
            listCountryExceptStep.some((e) => e.country_id === body.Company.country_id) &&
            isCheckEntityType
        ) {
            return steps.filter((step) => {
                let res = listCountryExceptStep.filter(
                    (e) =>
                        e.country_id === body.Company.country_id &&
                        e.entity_type_id === body.Company.entity_type_id &&
                        e.idStep === step.id,
                );
                return res.length > 0 ? false : true;
            });
        }
        return steps;
    };

    useEffect(() => {
        try {
            let stepsTemp = exceptStepWithCountry();
            if (body && body.isBelize && body.isLLC) {
                let articles_of_organization_default = setArticlesOfOrganizationDefault(body);
                if (articles_of_organization_default === ArticlesOfOrganizationOptions[0].id) {
                    // setHiddenStepBelizeLLC(2) // Chọn Members-manesd -> ấn step Managers
                    stepsTemp = stepsTemp.filter((e) => e.id !== 2);
                }
            }
            setStepList(stepsTemp);

            if (step4Back) {
                setActiveStep(stepsTemp.length - 1);
                setStep4Back(false);
            }
        } catch (error) {
            console.log(error);
        }
    }, [body.Company.articles_of_organization, step4Back]);

    useEffect(() => {
        if (activeParentStep !== parentStepIndex) {
            ref.current.activeStep = currentStepIndex.current;
            handleActiveStep(activeStep, true);
        }
    }, [activeParentStep]);

    function handleActiveStep(newStep, isClickToParentStep, isValid) {
        
        if (isOrderFormIsFilled) {
            if (activeStep === newStep && !isClickToParentStep) return;

            let currentValidateStep = validateStepsRef.current[`STEP_${isClickToParentStep ? newStep + 1 : activeStep + 1}`];
            if (currentValidateStep) {
                activeStepRef.current = newStep;
                currentValidateStep.onSubmit();
                if (currentValidateStep.isValid) {
                    setActiveStep(newStep);
                }
            }
        }
    }

    return (
        <div className={'wrap-stepper wrap-step-3 ' + classes.root}>
            <Stepper
                className="stepper p-0"
                nonLinear
                activeStep={activeStep}
                orientation="vertical"
            >
                {stepList &&
                    stepList.map((step, index) => (
                        <Step key={step.id}>
                            <StepButton
                                className="step-title no-click"
                                disableTouchRipple
                                // onClick={() => { setActiveStep(index) }}
                                onClick={() => {
                                    currentStepIndex.current = index;
                                    handleActiveStep(index);
                                }}
                            >
                                {step.name}
                            </StepButton>
                            <StepContent>{getStepContent(step.id, index)}</StepContent>
                        </Step>
                    ))}
            </Stepper>
        </div>
    );
}

export default forwardRef(InstructionIncorporationStep3);

import React from 'react';
import InputField from 'components/form-controls/InputField';

export default function Clients({ form }) {
    return (
        <>
            <p className="fw-500">Top 3 names of your main clients</p>
            <div className="row">
                <div className="col-md-4 mb-7 input-line">
                    <label>Client 1</label>
                    <InputField
                        form={form}
                        name="client1.name"
                        placeholder="Enter full name"
                        showIcon
                        hideSuccessHelper
                    />
                </div>
                <div className="col-md-4 mb-7 input-line">
                    <label>Client 2</label>
                    <InputField
                        form={form}
                        name="client2.name"
                        placeholder="Enter full name"
                        showIcon
                        hideSuccessHelper
                    />
                </div>
                <div className="col-md-4 mb-7 input-line">
                    <label>Client 3</label>
                    <InputField
                        form={form}
                        name="client3.name"
                        placeholder="Enter full name"
                        showIcon
                        hideSuccessHelper
                    />
                </div>
            </div>
        </>
    );
}

import { createSlice } from '@reduxjs/toolkit';

const initialBillingsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    billingForEdit: undefined,
    lastError: null,
};
export const callTypes = {
    list: 'list',
    action: 'action',
};

export const billingsSlice = createSlice({
    name: 'billings',
    initialState: initialBillingsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        billingFetched: (state, action) => {
            state.actionsLoading = false;
            state.billingForEdit = action.payload.billingForEdit;
            state.error = null;
        },
        billingsFetched: (state, action) => {
            const { count, rows } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = rows;
            state.totalCount = count;
        },
        billingCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
        billingUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
        billingDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter((el) => el.id !== action.payload.id);
        },
        billingsDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter((el) => !action.payload.ids.includes(el.id));
        },
    },
});

import React from 'react';

function UpdateBrowser() {
    return (
        <div className="container" style={{ height: '100%' }}>
            <div
                className="row"
                style={{
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div className="col-lg-8 text-center">
                    <h1
                        style={{
                            color: '#2f80ed',
                            fontSize: '2.25rem',
                            fontWeight: 700,
                            marginBottom: '1rem',
                        }}
                    >
                        Please update your browser
                    </h1>
                    <p style={{ fontSize: '16px', marginBottom: '1rem', lineHeight: '26px' }}>
                        Our website doesn‘t support Internet Explorer (IE). Please change your
                        browser to Google Chrome for a better experience.
                    </p>
                    <a
                        href="https://www.google.com/chrome"
                        style={{
                            padding: '0.5rem 2rem',
                            borderRadius: '9999px',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#f7fafd',
                            boxShadow: '2px 5px #e0e3e6',
                        }}
                    >
                        <img src="/media/logos/google-chrome.png" width={48} />
                        <span
                            style={{
                                fontSize: '1.125rem',
                                lineHeight: '1.75rem',
                                marginLeft: '5px',
                                color: 'black',
                                fontWeight: 700,
                            }}
                        >
                            chrome
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default UpdateBrowser;

import React from 'react';
import { Button } from '@mui/material';


function ToggleTawkTo({text = ''}) {

    return (
        <Button variant="contained"
                size="medium"
                color="primary"
                style={{ color: '#fff' }}
                onClick={() => window.Tawk_API.toggle()}>{text}</Button>
    );
}

export default ToggleTawkTo;

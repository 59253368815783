import React, { useEffect, useState, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import * as ActionsFile from 'app/pages/_redux/files/filesActions';
import * as ActionsOrder from 'app/pages/_redux/order-form/orderFormActions';
import TemplateList from './TemplateList';
import FileList from './FileList';
import UploadBox from './UploadBox';
import MemberList from './MemberList';
import NativeReader from './file-detail/NativeReader';
import HTMLReader from './file-detail/HTMLReader';
import { getExtension } from 'app/pages/services/constants';
import './document.scss';
import io from 'socket.io-client';

var socketApi = process.env.REACT_APP_SOCKET_URL

export default function DocumentManagement({ process, setNewProcessByData, stepIndex }) {
    /**
     * ---------------------------------------------------------------------------------------
     * Hooks ---------------------------------------------------------------------------------
     * ---------------------------------------------------------------------------------------
     */
    const dispatch = useDispatch();
    const [modal, setModal] = useState({ data: null, show: false });
    const [fileTemplates, setFileTemplates] = useState([]);
    const [currentTemplate, setCurrentTemplate] = useState(null);
    const [mailingMembers, setMailingMembers] = useState([]);
    const [memberLoading, setMemberLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [fileUploading, setFileUploading] = useState(false);
    const [fileDeleting, setFileDeleting] = useState(false);
    const [htmlDownloading, setHtmlDownloading] = useState(false);
    const deletedFileRefs = useRef([]);
    const { task, companyMembers, orderForm, order_id, user_id } = useSelector((state) => {
        return {
            task: state.orderForm?.orderInfo?.Task || null,
            companyMembers: state.orderForm?.orderInfo?.Company?.CompanyMembers || null,
            orderForm: state.orderForm?.orderInfo || null,
            order_id: state.orderForm?.orderInfo?.id,
            user_id: state?.personal?.user?.id || null
        };
    }, shallowEqual);
    let { current: socketRef } = useRef(null);
    /**
     * ---------------------------------------------------------------------------------------
     * Effect Controllers --------------------------------------------------------------------
     * ---------------------------------------------------------------------------------------
     */
    useEffect(() => {
        if (process && task) {
            const templateTemps = [...process.ProcessStep.FileTemplates];
            /**
             * If step 4: show document from CS Uploaded in "Others".
             * process.ProcessStep.process_step_type_id === 4: Process Incorporation
             * process.ProcessStep.id === 20: Step 4: Sign incorporation form
             */
            if (process.ProcessStep.id === 20 && task.Files && task.Files.length > 0) {
                const fileTemps = [];
                const titleTemps = [];
                task.Files.forEach((file) => {
                    if (file.is_filled) {
                        fileTemps.push({
                            ...file,
                            file_name: file.name,
                        });
                    } else {
                        titleTemps.push({
                            ...file,
                            file_name: file.name,
                        });
                    }
                });
                const csTemplate = {
                    id: 'template_by_cs',
                    name: 'Others',
                    description: 'document from CS Uploaded',
                    is_cs_uploaded: true,
                    path_template: null,
                    is_not_signed: false,
                    is_esign: false,
                    is_signed_for_FATCA: false,
                    is_deleted: false,
                    process_step_has_file_template: {
                        file_template_id: 'template_by_cs',
                        process_step_id: process.ProcessStep.id,
                    },
                    Files: fileTemps,
                    titles: titleTemps,
                };
                templateTemps.push(csTemplate);
            }
            setFileTemplates(templateTemps);
            // setCurrentTemplate(process.ProcessStep.FileTemplates[0] || null);
        }
    }, [process, task]);

    

    //Socket
    
    useEffect(() => {
        socketRef = io(socketApi, {
            transports: ['websocket', 'polling'],
            upgrade: false,
        })
        socketRef.on('connect', () => {
            socketRef.emit(('join-signed-file'), {
                process_id: process.id,
                user_id: user_id
            });
        })

        socketRef.on(('receive-response-signed-file'), (data) => {
            if(data.status == 200) {
                setNewProcessByData();
            }
        })
    }, [])


    useEffect(() => {
        // Handle Mailing members
        if (currentTemplate && companyMembers && !currentTemplate.is_cs_uploaded) {
            const titleTemps = currentTemplate?.titles || [];
            let memberIds = [];
            // Concat all belong company member array.
            titleTemps.forEach((titleTemp) => {
                const belongMembers = titleTemp.belong_to_company_member_ids;
                memberIds = memberIds.concat(belongMembers);
            });
            // Clear duplicate.
            const duplicateMemberIds = [...memberIds];
            memberIds = [];
            duplicateMemberIds.forEach((duplicateMemberId) => {
                let temp = { ...duplicateMemberId };
                memberIds.forEach((memberId) => {
                    if (memberId.id === duplicateMemberId.id) {
                        temp = null;
                        if (!memberId.is_send || !duplicateMemberId.is_send) {
                            memberId.is_send = false;
                        }
                        if (!memberId.is_signed || !duplicateMemberId.is_signed) {
                            memberId.is_signed = false;
                        }
                    }
                });
                if (temp) {
                    memberIds.push(temp);
                }
            });
            // Mix the id array to member list.
            const result = [];
            memberIds.forEach((memberId) => {
                const temp = companyMembers.find((member) => member.id === memberId.id) || null;
                if (temp) {
                    result.push({
                        ...temp,
                        is_send: memberId.is_send,
                        is_signed: memberId.is_signed,
                        is_selected: false,
                    });
                }
            });
            // Set to component state.
            setMailingMembers(result);
        }
    }, [companyMembers, currentTemplate]);

    /**
     * ---------------------------------------------------------------------------------------
     * Functions & API handling --------------------------------------------------------------
     * ---------------------------------------------------------------------------------------
     */

    const uploadFiles = (files) =>
        new Promise((resolve, reject) => {
            if (files && files.length > 0 && task && task.id) {
                const body = files.map((item) => {
                    const fileType = item.type === '' ? '.' + getExtension(item.name) : item.type;
                    return { filename: item.name, content_type: fileType };
                });
                setFileUploading(true);
                dispatch(ActionsFile.genSignedUrlS3(body))
                    .then((res) => {
                        if (res && res.data && res.data.length > 0) {
                            let Promised = [];
                            let PromisedBucket = [];
                            res.data.forEach(async (item, index) => {
                                PromisedBucket.push(
                                    dispatch(ActionsFile.uploadS3(item, files[index])),
                                );
                            });
                            Promise.all(PromisedBucket)
                                .then(() => {
                                    res.data.forEach((item, index) => {
                                        let param;
                                        const fileType =
                                            files[index].type === ''
                                                ? '.' + getExtension(files[index].name)
                                                : files[index].type;
                                        if (currentTemplate.is_cs_uploaded) {
                                            param = {
                                                task: {
                                                    name: files[index].name,
                                                    mime_type: fileType,
                                                    s3_path: item.filename,
                                                    is_filled: 1,
                                                    task_id: task.id,
                                                },
                                            };
                                        } else {
                                            param = {
                                                file_template: {
                                                    name: files[index].name,
                                                    mime_type: fileType,
                                                    s3_path: item.filename,
                                                    file_template_id: currentTemplate.id,
                                                    task_id: task.id,
                                                    company_id: orderForm.company_id
                                                },
                                            };
                                        }
                                        Promised.push(dispatch(ActionsFile.createFilesDbS3(param)));
                                    });
                                    Promise.all(Promised)
                                        .then((uploadedResponse) => {
                                            if (uploadedResponse && uploadedResponse.length > 0) {
                                                const templateList = [...fileTemplates];
                                                const currentTemp = { ...currentTemplate };
                                                const responseFiles = [];
                                                uploadedResponse.forEach((uploaded) => {
                                                    if (uploaded) {
                                                        responseFiles.push(uploaded);
                                                    }
                                                });
                                                currentTemp.Files = [
                                                    ...currentTemp.Files,
                                                    ...responseFiles,
                                                ];
                                                for (
                                                    let index = 0;
                                                    index < fileTemplates.length;
                                                    index++
                                                ) {
                                                    if (
                                                        fileTemplates[index].id === currentTemp.id
                                                    ) {
                                                        templateList[index] = currentTemp;
                                                        break;
                                                    }
                                                }
                                                setFileTemplates(templateList);
                                                setCurrentTemplate(currentTemp);
                                                resolve(uploadedResponse);
                                                // if (responseFiles.length > 0) {
                                                //     Swal.fire({
                                                //         position: 'center',
                                                //         icon: 'success',
                                                //         title: 'Successful!',
                                                //         showConfirmButton: false,
                                                //         timer: 1500,
                                                //     });
                                                // }
                                            }
                                        })
                                        .finally(() => {
                                            setFileUploading(false);
                                        });
                                })
                                .catch((err) => {
                                    setFileUploading(false);
                                    reject(err);
                                });
                        }
                    })
                    .catch((err) => {
                        setFileUploading(false);
                        reject(err);
                    });
            }
        });

    const deleteUploadedFile = (file) =>
        new Promise((resolve, reject) => {
            setFileDeleting(true);
            ActionsFile.deleteFileRequest(file)
                .then((res) => {
                    deletedFileRefs.current.push(file);
                    const templateList = [...fileTemplates];
                    const currentTemp = { ...currentTemplate };
                    deletedFileRefs.current.forEach((deletedRef) => {
                        currentTemp.Files = currentTemp.Files.filter(
                            (item) => item.id !== deletedRef.id,
                        );
                    });
                    for (let index = 0; index < fileTemplates.length; index++) {
                        if (fileTemplates[index].id === currentTemp.id) {
                            templateList[index] = currentTemp;
                            break;
                        }
                    }
                    setFileTemplates(templateList);
                    setCurrentTemplate(currentTemp);

                    resolve(res);
                })
                .catch((err) => {
                    setFileDeleting(false);
                    reject(err);
                });
        });

    const setCurrentTemplateCustom = (template) => {
        deletedFileRefs.current = [];
        setCurrentTemplate(template);
    };

    const handleSelectAll = () => {
        const newList = [...mailingMembers];
        newList.forEach((member) => {
            member.is_selected = true;
        });
        setMailingMembers(newList);
    };

    const handleDeselectAll = () => {
        const newList = [...mailingMembers];
        newList.forEach((member) => {
            member.is_selected = false;
        });
        setMailingMembers(newList);
    };

    const handleSelectMember = (memberId) => {
        const newList = [...mailingMembers];
        const currIndex = newList.findIndex((member) => member.id === memberId);
        if (currIndex || currIndex === 0) {
            newList[currIndex].is_selected = !newList[currIndex].is_selected;
            setMailingMembers(newList);
        }
    };

    const closeModal = () => {
        setModal({ ...modal, data: null, show: false });
    };

    const openFileDetail = (data) => {
        setModal({
            ...modal,
            data: data,
            show: true,
        });
    };

    const openUploadedFile = (file) => {
        if (file && file.s3_path && file.s3_path.length > 0) {
            setActionLoading(true);
            dispatch(ActionsFile.downloadFileS3(file.s3_path))
                .then((response) => {
                    if (response && response.length > 0) {
                        setModal({
                            ...modal,
                            data: { file_url: response, file: file },
                            show: true,
                        });
                    }
                })
                .finally(() => {
                    setActionLoading(false);
                });
        }
    };
    const openNativePdf = (file) => {
        if (file && file.s3_path && file.s3_path.length > 0) {
            setActionLoading(true);
            dispatch(ActionsFile.downloadFileS3(file.s3_path))
                .then((response) => {
                    if (response && response.length > 0) {
                        setModal({
                            ...modal,
                            data: { file_url: response, file: file },
                            show: true,
                        });
                    }
                })
                .finally(() => {
                    setActionLoading(false);
                });
        }
    };
    const openHTMLFile = (params) => {
        if (task && task.id && params) {
            setActionLoading(true);
            dispatch(ActionsOrder.fetchTemplateHTML({ task_id: task.id, ...params.templateParams }))
                .then((response) => {
                    if (response && response.data && response.data.html) {
                        setModal({
                            ...modal,
                            data: {
                                html: response.data.html,
                                templateParams: params.templateParams,
                                file_name: params.file_name,
                            },
                            show: true,
                        });
                    }
                })
                .finally(() => {
                    setActionLoading(false);
                });
        }
    };

    const downloadHTMLAsPDF = () => {
        if (task && task.id && modal && modal.data && modal.data.templateParams) {
            setHtmlDownloading(true);
            dispatch(
                ActionsOrder.downloadTemplateHTML({
                    task_id: task.id,
                    ...modal.data.templateParams,
                }),
            )
                .then((response) => {
                    saveAs(
                        response,
                        `${modal.data.file_name.split('.')[0]} - ${order_id}.${
                            modal.data.file_name.split('.')[1]
                        }`,
                    );
                    setHtmlDownloading(false);
                })
                .catch(() => {
                    setHtmlDownloading(false);
                });
        }
    };

    const downloadNativePDF = () =>
        new Promise((resolve, reject) => {
            if (modal.data) {
                dispatch(ActionsFile.downloadFileS3(modal.data.file.s3_path)).then((response) => {
                    if (response && response.length > 0) {
                        fetch(response)
                            .then((response) => {
                                if (response && response.status === 200) {
                                    return response.blob();
                                } else {
                                    if (response && response.statusText) {
                                        toast.error(response.statusText);
                                        reject(response.statusText);
                                        throw response.statusText;
                                    }
                                }
                            })
                            .then((blob) => {
                                resolve(blob);
                                if (blob) {
                                    saveAs(blob, modal.data.file.name);
                                }
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    }
                });
            }
            // .finally(() => {
            //     setActionLoading(false);
            // });
        });

    const sendMailToMembers = (memberIdList) =>
        new Promise((resolve, reject) => {
            if (
                task &&
                task.id &&
                currentTemplate &&
                currentTemplate.id &&
                memberIdList &&
                memberIdList.length > 0
            ) {
                const body = {
                    task_id: task.id,
                    file_template_id: currentTemplate.id,
                    for_company_member_list: [...memberIdList],
                };
                dispatch(ActionsOrder.sendMails(body))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });

    const submitProcess = () => {
        setActionLoading(true);
        dispatch(ActionsOrder.submitOrderForm(process.id))
            .then(() => {
                setNewProcessByData().then(() => {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Successful!',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });

                setActionLoading(false);
            })
            .catch((error) => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Cannot submit!',
                    showConfirmButton: false,
                    timer: 1500,
                });
                setActionLoading(false);
            });
    };

    const isSubmitDisabled = () => {
        const templateTemps = fileTemplates.filter((template) => !template.is_not_signed);
        const eSigns = templateTemps.filter((template) => template.is_esign);
        const webInks = templateTemps.filter(
            (template) => !template.is_esign && !template.is_cs_uploaded,
        );
        const webInkCS = templateTemps.filter(
            (template) => !template.is_esign && template.is_cs_uploaded,
        );
        if (eSigns) {
            for (const template of eSigns) {
                if (template.titles) {
                    for (const title of template.titles) {
                        if (
                            title.belong_to_company_member_ids &&
                            title.belong_to_company_member_ids.some((member) => !member.is_signed)
                        ) {
                            return true;
                        }
                    }
                }
            }
        }
        if (webInks) {
            for (const template of webInks) {
                if (template.titles && template.titles.length === 0) {
                    break;
                }
                if (!template.Files || (template.Files && template.Files.length === 0)) {
                    return true;
                }
            }
        }
        if (webInkCS) {
            for (const template of webInkCS) {
                if (template.titles && template.titles.length === 0) {
                    break;
                }
                if (!template.Files || (template.Files && template.Files.length === 0)) {
                    return true;
                }
            }
        }
        return false;
    };
    /**
     * ---------------------------------------------------------------------------------------
     * JSX return ----------------------------------------------------------------------------
     * ---------------------------------------------------------------------------------------
     */
    return (
        <div className="document-controller position-relative">
            {(actionLoading || fileUploading) && (
                <div
                    className="position-absolute d-flex align-items-center justify-content-center w-100 h-100"
                    style={{
                        top: 0,
                        left: 0,
                        zIndex: 200,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    }}
                >
                    <CircularProgress size={50} color="primary" />
                </div>
            )}
            <div className="row m-0 bg-light rounded border shadow-sm overflow-hidden">
                <div className="col-12 col-sm-4 col-md-3 py-5 px-0">
                    <h6 className="text-uppercase font-weight-bolder px-3">DOCUMENT LIST</h6>
                    <TemplateList
                        fileTemplates={fileTemplates}
                        currentTemplate={currentTemplate}
                        setCurrentTemplate={setCurrentTemplateCustom}
                        disabled={fileDeleting || fileUploading || memberLoading}
                    />
                </div>
                <div className="col-12 col-sm-8 col-md-6 py-5 px-0 bg-white">
                    <FileList
                        openFileDetail={openFileDetail}
                        currentTemplate={currentTemplate}
                        openHTMLFile={openHTMLFile}
                        openNativePdf={openNativePdf}
                        orderForm={orderForm}
                        stepIndex={stepIndex}
                        fileTemplates={fileTemplates}
                    />
                </div>
                <div className="col-12 col-sm-12 col-md-3 p-0 bg-white border-left">
                    {currentTemplate && (
                        <>
                            {currentTemplate.is_esign && !currentTemplate.is_cs_uploaded ? (
                                <MemberList
                                    mailingMembers={mailingMembers}
                                    handleSelectMember={handleSelectMember}
                                    handleSelectAll={handleSelectAll}
                                    sendMails={sendMailToMembers}
                                    handleDeselectAll={handleDeselectAll}
                                    setMailingMembers={setMailingMembers}
                                    setMemberLoading={setMemberLoading}
                                    memberLoading={memberLoading}
                                    stepIndex={stepIndex}
                                />
                            ) : (
                                <UploadBox
                                    files={currentTemplate?.Files || []}
                                    uploadFiles={uploadFiles}
                                    deleteUploadedFile={deleteUploadedFile}
                                    disabled={
                                        fileUploading ||
                                        (currentTemplate.titles &&
                                            currentTemplate.titles.length === 0)
                                    }
                                    openUploadedFile={openUploadedFile}
                                    setFileDeleting={setFileDeleting}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className="row m-0 pt-5 justify-content-between align-items-center">
                <div>Note: Take all signature to next</div>
                <div>
                    <Button
                        color="primary"
                        variant="contained"
                        className="text-white"
                        disabled={
                            fileUploading || fileDeleting || actionLoading || isSubmitDisabled()
                        }
                        onClick={submitProcess}
                    >
                        Submit
                    </Button>
                </div>
            </div>
            <Modal show={modal.show} onHide={closeModal} backdrop="static" size="xl" centered>
                <Modal.Body>
                    {modal.data && (
                        <>
                            {modal.data.html && (
                                <HTMLReader
                                    closeDetailModal={closeModal}
                                    data={modal.data}
                                    download={downloadHTMLAsPDF}
                                    downloading={htmlDownloading}
                                    fetched={true}
                                />
                            )}
                            {modal.data.file_url && (
                                <NativeReader
                                    closeDetailModal={closeModal}
                                    data={modal.data}
                                    download={downloadNativePDF}
                                />
                            )}
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
}

import React from 'react';
import { CircularProgress } from '@mui/material';

/*
* PageLoadingEffect position depend on Parent element which has css property "position relative".
*/ 

export default function PageLoadingEffect({loading}) {
    return (
        <>
        {loading && (
                <div
                    className="position-absolute d-flex align-items-center justify-content-center w-100 h-100"
                    style={{
                        top: 0,
                        left: 0,
                        zIndex: 200,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    }}
                >
                    <CircularProgress size={50} color="primary" />
                </div>
            )}
        </>
    )
}
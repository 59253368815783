import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const ToastBar = () => {
    // === style ===
    // === https://fkhadra.github.io/react-toastify/how-to-style
    return (
        <ToastContainer
            position="bottom-left"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    );
};

// === example in billingsActions
// .findBillings(queryParams)
// .then(response => {
//   const { count, rows } = response.data.data;
//   dispatch(actions.billingsFetched({ count, rows }));
//   // toast.success("Successfully!",{
//   toast.success((<>
//     <h1>Successfully</h1>
//   </>));
// })

import { createSlice } from '@reduxjs/toolkit';

const initialBankingToolState = {
    isLoading: false,
    filterResult: [],
    error: false
};


export const bankingToolSlice = createSlice({
    name:'bankingTool',
    initialState: initialBankingToolState,
    reducers: {
        startCall: (state, action) => {
            state.error = null;
            state.isLoading = true;
        },
        updateDataFilter: (state, action) => {
            const { data } = action.payload;
            state.isLoading = false;
            state.error = null;
            state.filterResult = data;
        }
    }
})

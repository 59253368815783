import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from 'components/form-controls/InputField';
import SelectField from 'components/form-controls/SelectField';
import { useSelector, shallowEqual } from 'react-redux';

const BillingEditSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Minimum 3 symbols').required('Company Name is required'),
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    country_id: Yup.string().nullable().required('Country is required'),
    address: Yup.string().required('Address is required'),
    phone_number: Yup.string()
        .nullable()
        .matches(/^[0-9]*$/, 'Please enter number!')
        .required('Phone number is required!'),
    phone_country_id: Yup.string().nullable(),
});

let billing = {
    name: '',
    first_name: '',
    last_name: '',
    country_id: '',
    postal_code: '',
    phone_number: '',
    phone_country_id: '',
    address: '',
    address_1: '',
    tax_id: '',
};

export function BillingForm({
    country,
    setBillingForm,
    billingForm,
    newBillingCompanyName,
    setForm,
    formRef = {}
}) {
    const [phonePrefixList, setPhonePrefixList] = useState(null);
    const [phonePrefix, setPhonePrefix] = useState({ id: 0, name: '' });

    const form = useForm({
        resolver: yupResolver(BillingEditSchema),
        mode: 'onChange',
    });

    useEffect(() => {
        form.reset(billing);
        setForm(form);
    }, []);

    // fetchGeoLooking
    const { geoLooking } = useSelector(
        (state) => ({
            geoLooking: state.enum.geoLooking,
        }),
        shallowEqual,
    );

    // useEffect(() => {
    //     if (country && country.length > 0) {
    //         setCountryOj(country[0]);
    //         billing.country_id = country[0].id;
    //     }
    //     return () => console.log('');
    // }, []);

    billing.name = newBillingCompanyName;

    useEffect(() => {
        try {
            if (country) {
                // Nationality
                let countryReturn;
                if (geoLooking) {
                    countryReturn = country.find((item) => item.id === geoLooking.id);
                }
                if (!countryReturn) {
                    countryReturn = country[0];
                }
                form.setValue('country_id', countryReturn.id);

                // phonePrefix
                let phonePrefixListTamp = [];
                country.map((item) => {
                    phonePrefixListTamp.push({
                        id: item.id,
                        name: item.country_code + ' +' + item.phone_code,
                        country_code: item.country_code,
                        phone_code: item.phone_code,
                    });
                });
                setPhonePrefixList(phonePrefixListTamp);

                let phonePrefixReturn;
                if (geoLooking) {
                    phonePrefixReturn = phonePrefixListTamp.find(
                        (item) => item.id == geoLooking.id,
                    );
                }
                if (!phonePrefixReturn) {
                    phonePrefixReturn = phonePrefixListTamp[0];
                }

                setPhonePrefix(phonePrefixReturn);
                form.setValue('phone_country_id', phonePrefixReturn.id);
            }
        } catch (error) {
            console.log(error);
        }
    }, [country, geoLooking]);

    const handleSubmit = () => {
        const values = form.getValues();
        let remove_fields = [];
        for (var key in values) {
            if (values[key] === '') {
                remove_fields.push(key);
            }
        }
        for (var index in remove_fields) {
            delete values[remove_fields[index]];
        }
        let data = values;
        let phone = '';
        if (values.phone_number) {
            // phone = phonePrefix.name + " " + values.phone_number;
            phonePrefix.id && (phone = phonePrefix.name + ' ');
            phone += values.phone_number;
            data = {
                ...data,
                phone: phone,
                phone_number: values.phone_number.toString(),
            };
        }
        setBillingForm(data);
    };

    formRef.current = {
        isValid: form.formState.isValid
    }

    return (
        <>
            <form className="form form-label-right">
                <div className="form-group row mb-0">
                    {/* name Name */}
                    <div className="col-xl-4 col-md-6 input-line mb-7">
                        <label>Enter Company Name</label>
                        <InputField
                            form={form}
                            name="name"
                            placeholder="Company Name"
                            helperText={true}
                            onKeyUp={() => {
                                handleSubmit();
                            }}
                        />
                    </div>
                    {/* first Name */}
                    <div className="col-xl-4 col-md-6 input-line mb-7">
                        <label>Enter First Name</label>
                        <InputField
                            form={form}
                            name="first_name"
                            placeholder="First Name"
                            helperText={true}
                            onKeyUp={() => {
                                handleSubmit();
                            }}
                        />
                    </div>
                    {/* last Name */}
                    <div className="col-xl-4 col-md-6 input-line mb-7">
                        <label>Enter Last Name</label>
                        <InputField
                            form={form}
                            name="last_name"
                            placeholder="Last Name"
                            helperText={true}
                            onKeyUp={() => {
                                handleSubmit();
                            }}
                        />
                    </div>
                    {/* Country */}
                    <div className="col-xl-4 col-md-6 input-line mb-7">
                        {/* <label>Select Country</label> */}
                        {/* <Select
                                        className="react-select"
                                        id="billing-country-id"
                                        name="country_id"
                                        placeholder="Select Country"
                                        getOptionLabel={(option) =>
                                            `${
                                                option.name && option.name.length > 0
                                                    ? option.name
                                                    : ''
                                            }`
                                        }
                                        options={country}
                                        // value={countryOj}
                                        isSearchable
                                        isClearable={false}
                                        isOptionSelected={(option) =>
                                            countryOj && option && countryOj.id === option.id
                                                ? true
                                                : false
                                        }
                                        onChange={(e) => {
                                            setFieldValue('country_id', e.id);
                                            setCountryOj(e);
                                            handleSubmit();
                                        }}
                                    />
                                    <div className="feedback">
                                        Please select <b>Country</b>
                                    </div> */}
                        <label>Select Country</label>
                        <SelectField
                            form={form}
                            label=""
                            placeholder="Select Country"
                            name="country_id"
                            options={country ? country : []}
                            helperText={true}
                            onChange={() => {
                                handleSubmit();
                            }}
                        />
                    </div>
                    {/* Postal Code */}
                    <div className="col-xl-4 col-md-6 input-line mb-7">
                        <label>Enter Postal Code</label>
                        <InputField
                            form={form}
                            name="postal_code"
                            placeholder="Postal Code"
                            helperText={true}
                            hideHelperText={true}
                            onChange={() => {
                                handleSubmit();
                            }}
                        />
                    </div>
                    {/* Phone */}
                    <div className="col-xl-4 col-md-6 mb-7 input-line">
                        <label>Phone number</label>
                        <div className="phone-group">
                            <div className="phone-code" style={{ zIndex: 1 }}>
                                {/* <label>Phone number</label> */}
                                <SelectField
                                    form={form}
                                    className="fw-500"
                                    name="phone_country_id"
                                    placeholder="Select..."
                                    label=""
                                    options={phonePrefixList ? phonePrefixList : []}
                                    disableClearable={true}
                                    onChange={(value) => {
                                        if (phonePrefix && phonePrefix.id != value.id) {
                                            setPhonePrefix(value);
                                            handleSubmit();
                                        }
                                    }}
                                    customStyle={{
                                        '.MuiInputBase-root:before, .MuiInputBase-root:after, .MuiInput-underline:hover:not(.Mui-disabled):before':
                                            {
                                                borderBottom: '0 !important',
                                            },
                                    }}
                                />
                            </div>
                            <div className="input-line inp-phone">
                                <InputField
                                    form={form}
                                    name="phone_number"
                                    placeholder="Phone number"
                                    helperText={true}
                                    onChange={(e) => {
                                        handleSubmit();
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-xl-4 col-md-6 input-line mb-7">
                                    <label>Enter Phone</label>
                                    <input
                                        className="form-control"
                                        name="phone"
                                        placeholder="Phone"
                                    />
                                    <span className="feedback">Please enter <b>Phone</b></span>
                                </div> */}

                    {/* Address Line 1 */}
                    <div className="col-xl-4 col-md-6 input-line mb-7">
                        <label>Enter Address Line 1</label>
                        <InputField
                            form={form}
                            name="address"
                            placeholder="Address Line 1"
                            helperText={true}
                            onKeyUp={() => {
                                handleSubmit();
                            }}
                        />
                    </div>
                    {/* Address Line 2 */}
                    <div className="col-xl-4 col-md-6 input-line mb-7">
                        <label>Enter Address Line 2</label>
                        <InputField
                            form={form}
                            name="address_1"
                            placeholder="Address Line 2"
                            helperText={true}
                            onChange={() => {
                                handleSubmit();
                            }}
                        />
                    </div>
                    {/* Tax ID */}
                    <div className="col-xl-4 col-md-6 input-line mb-7">
                        <label>Enter Tax ID</label>
                        <InputField
                            form={form}
                            name="tax_id"
                            placeholder="Tax ID"
                            helperText={true}
                            onChange={() => {
                                handleSubmit();
                            }}
                        />
                    </div>
                </div>
                <i style={{ fontSize: '12px' }}>
                    *This billing information will be saved automatically.
                </i>
            </form>
        </>
    );
}

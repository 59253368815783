import React from 'react';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { CircularProgress } from '@mui/material';
// import { ToastBar } from "../toastBar"
// import Spinner from "./Spinner";
//import { withRouter } from 'react-router-dom';
import { ToastBar } from '_metronic/layout/components/toast-bar/ToastBar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './payment-dialog.scss';

// const CLIENT = {
//     sandbox: 'your_sandbox_key',
//     production: 'your_production_key',
// };

// const CLIENT_ID = process.env.NODE_ENV === 'production' ? CLIENT.production : CLIENT.sandbox;

let PayPalButton = null;
let amount_usd = 0;
class PaypalButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showButtons: false,
            loading: true,
            paid: false,
            success: false,
            open: this.props.open,
            error: false,
            isLoading: false,
        };

        window.React = React;
        window.ReactDOM = ReactDOM;
    }

    componentDidMount() {
        const { isScriptLoaded, isScriptLoadSucceed } = this.props;

        if (isScriptLoaded && isScriptLoadSucceed) {
            PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });
            this.setState({ loading: false, showButtons: true });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

        const scriptJustLoaded =
            !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

        if (scriptJustLoaded) {
            if (isScriptLoadSucceed) {
                PayPalButton = window.paypal.Buttons.driver('react', {
                    React,
                    ReactDOM,
                });
                this.setState({ loading: false, showButtons: true });
            }
        }
    }
    createOrder = (data, actions) => {
        return fetch(process.env.REACT_APP_API_URL + this.props.payloadPay.uri.paypal.create, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + this.props.token.access_token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.props.payloadPay.payload),
        })
            .then(function (res) {
                return res.json();
            })
            .then(function (orderData) {
                amount_usd = orderData.data.amount_usd;
                return orderData.data.result.id;
            })
            .catch((error) => {
                this.setState({ error: true });

            });
    };

    onApprove = (data, actions) => {
        const paramAuthorize = {
            id: data.orderID,
            amount_usd: amount_usd,
        };
        this.setState({ isLoading: true });
        return fetch(process.env.REACT_APP_API_URL + this.props.payloadPay.uri.paypal.approve, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + this.props.token.access_token,
                Accept: 'application/json,text/plain, */*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(paramAuthorize),
        })
            .then((res) => {
                return res.json();
            })
            .then((details) => {
                if (details.data && details.data.is_success) {
                    this.setState({ paid: true, success: true, error: false });
                    // this.setState({ paid: true, success: true, error: false, isLoading: false });
                    // this.props.setOpenPaypal(false);
                // }
                // setTimeout(() => {
                    return this.props.history.push('/notification-payment-stripe-paypal', {
                        data: details.data,
                    });
                // }, 2000);
                }
            })
            .catch((error) => {
                // this.setState({ error: true, isLoading: false });
                this.setState({ error: true});
                return this.props.history.push('/notification-payment-stripe-paypal', {
                    data: {
                        is_success: false,
                    },
                });
            });
    };
    handleClose() {

    }
    render() {
        const handleClose = () => {
            this.setState({ error: false, success: true });
            return this.props.history.push('/notification-payment-stripe-paypal', {
                data: {
                    is_success: false,
                },
            });
        };
        const handleCloseDialog = () => {
            this.props.setOpenPaypal(false);
        };
        const { success, isLoading } = this.state;

        return (
            <div>
                <Dialog
                    className="payment-dialog"
                    // onClose={handleCloseDialog}
                    aria-labelledby="simple-dialog-title"
                    open={this.props.open}
                >
                    {/* <div className="logo"><img src="/media/logos/logo.svg" alt="Logo" /></div> */}
                    <div className="dialog-header">
                        <div className="logo">
                            <img src="/media/logos/logo.svg" alt="Logo" />
                        </div>
                        {!isLoading &&
                            <IconButton
                                size="medium"
                                aria-label="Close"
                                onClick={handleCloseDialog}
                                className="btn-close-dialog"
                            >
                                <CloseIcon />
                            </IconButton>
                        }

                        <DialogTitle className="title text-center">
                            BBCIncorp Limited
                            <a href="mailto: service@bbcincorp.com" className="sub-title">
                                service@bbcincorp.com
                            </a>
                        </DialogTitle>
                    </div>
                    <div className="main p-md-9 py-9 px-6">
                        {this.state.showButtons && (
                            <div className="text-center">
                                {!isLoading && !success && (
                                    <PayPalButton
                                        createOrder={(data, actions) =>
                                            this.createOrder(data, actions)
                                        }
                                        onApprove={(data, actions) => this.onApprove(data, actions)}
                                        onCancel={(event) => {
                                            handleClose()
                                        }}


                                    />
                                )}
                                {isLoading && <CircularProgress size={20} />}
                                {/* {success && <CircularProgress size={20} />} */}
                            </div>
                        )}
                    </div>
                </Dialog>
                <ToastBar />
            </div>
        );
    }
}

export default scriptLoader(
    `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENTID}&currency=HKD&intent=capture`,
)(PaypalButton);

import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Visibility } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { ALL_ACCEPT_EXTENSION, isFilesAccept } from '../../../../../../constants/extensions_accept';
import Swal from 'sweetalert2';
import { handleScanFile } from 'app/helpers/scanfile';
import { result } from 'lodash';

export default function UploadBox({
    files,
    uploadFiles,
    deleteUploadedFile,
    disabled,
    openUploadedFile,
    setFileDeleting,
}) {
    /**
     * ----------------------------------------------------------------------------------------
     * Variables ------------------------------------------------------------------------------
     * ----------------------------------------------------------------------------------------
     */
    const [deleteLoadings, setDeleteLoadings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const fileRefs = useRef([]);
    const deletedFileRefs = useRef([]);

    /**
     * ----------------------------------------------------------------------------------------
     * Effect handling ------------------------------------------------------------------------
     * ----------------------------------------------------------------------------------------
     */

    useEffect(() => {
        if (files) {
            let fileTemps = [...files];
            deletedFileRefs.current.forEach((deletedRef) => {
                fileRefs.current = fileRefs.current.filter(
                    (fileRef) => fileRef.id !== deletedRef.id,
                );
                fileTemps = fileTemps.filter((fileRef) => fileRef.id !== deletedRef.id);
            });
            fileTemps.forEach((file) => {
                if (fileRefs.current.every((fileRef) => fileRef.id !== file.id)) {
                    fileRefs.current.push({ ...file, loading: false });
                }
            });
            setFileList(fileTemps);
            setDeleteLoadings(fileTemps);
            setFileDeleting(fileRefs.current.some((item) => item.loading));
        }
    }, [files, setFileDeleting]);

    /**
     * ----------------------------------------------------------------------------------------
     * Functions ------------------------------------------------------------------------------
     * ----------------------------------------------------------------------------------------
     */
    const inputOnChange = async (e) => {
        try {
            setIsLoading(true);
            if (!isFilesAccept(e.target.files, ALL_ACCEPT_EXTENSION)) {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Error',
                    text: 'Accept extensions: ' + ALL_ACCEPT_EXTENSION.join(', '),
                    showConfirmButton: false,
                    timer: 3000,
                });
                document.getElementById('file-upload-01').value = '';
                return;
            }
            const { files } = await handleScanFile(e.target.files);
            const inValidFiles = [...files].filter(file => file.isValid === false);
            const validFiles = [...files].filter(file => file.isValid === true);
            uploadFiles(validFiles);
            if(inValidFiles.length > 0) {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    iconColor: 'red',
                    title: 'Oops... Upload failed.',
                    text: inValidFiles.map((file, index) => `${index > 0 ? ' ' : ''} ${file.name}`) + `${inValidFiles.length > 1 ? ' files are' : ' file is' }` + ' invalid. Please upload an appropriate file.',
                    confirmButtonText: 'Try again',
                    confirmButtonColor: '#3085d6',
                    showCloseButton: true
                }).then(result => {
                    if(result.isConfirmed) {
                        document.getElementById('file-upload-01').click();
                    }
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
            document.getElementById('file-upload-01').value = '';
        }
    };

    const getFileRefIndex = (id) => {
        return fileRefs.current.findIndex((file) => file.id === id);
    };

    const isDeleting = () => fileRefs.current.some((item) => item.loading);

    const setDeletingStatus = (file, status) => {
        const temps = [...deleteLoadings];
        const currIndex = temps.findIndex((temp) => temp.id === file.id);
        temps[currIndex] = { ...file, ...status };
        setDeleteLoadings(temps);
    };

    const deleteFileInList = (file) => {
        const refIdx = getFileRefIndex(file.id);

        setDeletingStatus(file, { loading: true });

        fileRefs.current[refIdx].loading = true;
        deleteUploadedFile(file)
            .then(() => {
                deletedFileRefs.current.push(file);
            })
            .catch((err) => {
                if (fileRefs.current && fileRefs.current[refIdx]) {
                    fileRefs.current[refIdx].loading = false;
                }
                setDeletingStatus(file, { loading: false });
            });
    };

    /**
     * Handle drag and drop
     */
    const onDrop = (acceptedFiles) => {
        uploadFiles(acceptedFiles).then(() => {
            document.getElementById('file-upload-01').value = '';
        });
    };

    const { getRootProps } = useDropzone({ onDrop });

    /**
     * ----------------------------------------------------------------------------------------
     * JSX return -----------------------------------------------------------------------------
     * ----------------------------------------------------------------------------------------
     */
    return (
        <div className="position-relative">
            <h6 className="text-uppercase text-center font-weight-bolder border-top border-bottom py-5">
                Upload
            </h6>
            <div className="row m-0 p-3 mb-2">
                <div className="mb-3 col-12 p-0">
                    <label
                        htmlFor="file-upload-01"
                        {...getRootProps()}
                        className={`upload-panel d-flex flex-column justify-content-center align-items-center rounded-sm p-5${
                            isLoading || disabled || isDeleting() ? ' bg-dark' : ''
                        }`}
                    >
                        <img
                            id="upload-area-01"
                            src="/media/svg/icons/Order/upload-file.svg"
                            width="30px"
                            height="30px"
                            alt="upload"
                        />
                        <p
                            className={`m-0 text-center${
                                isLoading || disabled || isDeleting() ? ' text-white' : ''
                            }`}
                        >
                            Upload your file here
                        </p>
                    </label>
                    <input
                        id="file-upload-01"
                        type="file"
                        className="d-none"
                        onChange={inputOnChange}
                        multiple
                        disabled={isLoading || disabled || isDeleting()}
                    />
                </div>
                <div className="upload-list rounded-sm overflow-hidden col-12 p-0">
                    <div className="text-center font-weight-bold p-2 title">
                        Your wet-ink documents
                    </div>
                    <div className="py-3 px-1 uploaded-files-view">
                        <ul className="list-unstyled">
                            {fileList &&
                                fileList.map((file, index) => {
                                    return (
                                        <li key={index} className="border-bottom border-secondary">
                                            <div className="uploaded-file-name">
                                                {file?.name || ''}
                                            </div>
                                            <div className="d-flex align-items-center">
                                                {fileRefs.current &&
                                                fileRefs.current[getFileRefIndex(file?.id)] &&
                                                fileRefs.current[getFileRefIndex(file?.id)]
                                                    .loading ? (
                                                    <>
                                                        <IconButton
                                                            disabled
                                                            className="p-1 mx-1"
                                                            size="large"
                                                        >
                                                            <Visibility fontSize="small" />
                                                        </IconButton>
                                                        <CircularProgress
                                                            size={15}
                                                            color="secondary"
                                                            className="ml-2 mr-1"
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <IconButton
                                                            onClick={() => openUploadedFile(file)}
                                                            disabled={disabled}
                                                            className="p-1 mx-1"
                                                            size="large"
                                                        >
                                                            <Visibility fontSize="small" />
                                                        </IconButton>
                                                        {file?.can_be_deleted && (
                                                            <IconButton
                                                                onClick={() =>
                                                                    deleteFileInList(file)
                                                                }
                                                                disabled={disabled}
                                                                className="p-1 mx-1"
                                                                size="large"
                                                            >
                                                                <img
                                                                    src="/media/svg/icons/Order/delete.svg"
                                                                    width="11px"
                                                                    height="11px"
                                                                    alt="delete"
                                                                />
                                                            </IconButton>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

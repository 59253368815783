import React from 'react';

export function WaitForApprove({ secondScreen }) {
    return (<div className='d-flex flex-column align-items-center justify-content-center'
                 style={{ minHeight: 470 }}>
            <img
                src='/media/order-form/approving.svg'
                className='img-fluid mb-5'
                width={220}
                alt='Your proposed meeting time was sent to the bank for acceptance.'
            />

        <div style={{maxWidth: '435px'}}>
            {secondScreen ? (
                    <>
                        <p style={{'color': '#007EFF'}}>Your proposed meeting time was sent to the bank for acceptance.</p>
                        <p>After coming to an agreed-upon date & time, you will receive a notification about the
                            upcoming video call.</p>
                        <p>Throughout the meeting, you will be asked to sign an application form and some sort of
                            onboarding papers should the bank pre-approve your application</p>
                    </>
                )
                : (
                    <p style={{'color': '#007EFF'}}>Your documents are being processed. It will take around 2-3 days.</p>
                )}
        </div>
        </div>
    );
}

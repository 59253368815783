import { createSlice } from '@reduxjs/toolkit';
import { CAYMAN_ID, RAK_ID, UK_ID } from 'constants/country';
import { get, head, isEmpty } from 'lodash';

const initialenumsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,

    countriesHasService: null,
    countriesHasServiceOptions: null,
    phonePrefixCountriesHasService: null,

    countriesCooperation: null,
    countriesCooperationOptions: null,
    phonePrefixCountriesCooperation: null,

    countries: null,
    countriesOptions: null,
    phonePrefixCountries: null,

    lastError: null,
    supportCasePriority: null,
    supportCaseStatus: null,
    supportCaseType: null,
    businessActivity: null,
    businessActivityWithCountry: {
        normalList: [],
        caymanList: [],
        rakList: [],
        ukList: [],
    },
    geoLooking: null,
    // useableNameUK: true,
    currencies: null,
    serviceType: [],
    serviceMap: {},
    fileTypes: [],
    websites: [],
};
export const callTypes = {
    list: 'list',
    action: 'action',
};

export const enumSlice = createSlice({
    name: 'enums',
    initialState: initialenumsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // === countries Has Service
        countriesHasServiceFetched: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.countriesHasService = data;

            let phonePrefixListTemp = [];
            let countriesOptionsTemp = [];

            data.forEach((item) => {
                phonePrefixListTemp.push({
                    id: item.id,
                    name: item.country_code + ' +' + item.phone_code,
                    country_code: item.country_code,
                    phone_code: item.phone_code,
                    value: item.id,
                    label: item.country_code + ' +' + item.phone_code,
                });

                const tempItem = { ...item, value: item.id, label: item.name };
                delete tempItem.IncorporationService;
                countriesOptionsTemp.push(tempItem);
            });

            state.phonePrefixCountriesHasService = phonePrefixListTemp;
            state.countriesHasServiceOptions = countriesOptionsTemp;
            // state.countriesHasService = data.filter(item => item.id != 244);
        },
        // === countries Has Service
        countriesCooperation: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.countriesCooperation = data;

            let phonePrefixListTemp = [];
            let countriesOptionsTemp = [];

            data.forEach((item) => {
                phonePrefixListTemp.push({
                    id: item.id,
                    name: item.country_code + ' +' + item.phone_code,
                    country_code: item.country_code,
                    phone_code: item.phone_code,
                    value: item.id,
                    label: item.country_code + ' +' + item.phone_code,
                });

                const tempItem = { ...item, value: item.id, label: item.name };
                delete tempItem.IncorporationService;
                countriesOptionsTemp.push(tempItem);
            });

            state.phonePrefixCountriesCooperation = phonePrefixListTemp;
            state.countriesCooperationOptions = countriesOptionsTemp;
        },
        countries: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.countries = data;

            let phonePrefixListTemp = [];
            let countriesOptionsTemp = [];

            data.forEach((item) => {
                phonePrefixListTemp.push({
                    id: item.id,
                    name: item.country_code + ' +' + item.phone_code,
                    country_code: item.country_code,
                    phone_code: item.phone_code,
                    value: item.id,
                    label: item.country_code + ' +' + item.phone_code,
                });

                const tempItem = { ...item, value: item.id, label: item.name };
                delete tempItem.IncorporationService;
                countriesOptionsTemp.push(tempItem);
            });

            state.phonePrefixCountries = phonePrefixListTemp;
            state.countriesOptions = countriesOptionsTemp;
        },
        supportCasePriority: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.supportCasePriority = data;
        },
        supportCaseStatus: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.supportCaseStatus = data;
        },
        supportCaseType: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.supportCaseType = data;
        },
        // === business ativity
        businessActivity: (state, action) => {
            const { data, countryId, isCorporationMember } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.businessActivity = data;

            if (isCorporationMember && countryId) {
                let businessActivityList = [];

                if ([CAYMAN_ID, RAK_ID, UK_ID].includes(countryId)) {
                    data[0].BusinessActivities.map((item) => {
                        businessActivityList.push({
                            ...item,
                            value: item.id,
                            label:
                                (item.sic_code ? '[' + item.sic_code + '] ' : '') +
                                item.description,
                        });
                    });
                }

                if (![CAYMAN_ID, RAK_ID, UK_ID].includes(countryId)) {
                    const businessActivities = get(head(data), 'BusinessActivities');
                    if (!isEmpty(businessActivities)) {
                        businessActivities.forEach((item) => {
                            businessActivityList.push({
                                ...item,
                                value: item.id,
                                label: item.name,
                            });
                        });
                    }
                }

                switch (countryId) {
                    case CAYMAN_ID:
                        state.businessActivityWithCountry.caymanList = businessActivityList;
                        break;
                    case UK_ID:
                        state.businessActivityWithCountry.ukList = businessActivityList;
                        break;
                    case RAK_ID:
                        state.businessActivityWithCountry.rakList = businessActivityList;
                        break;
                    default:
                        state.businessActivityWithCountry.normalList = businessActivityList;
                        break;
                }
            }
        },
        // === geoLooking
        geoLooking: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.geoLooking = data;
        },
        currencies: (state, action) => {
            // console.log(action.payload);
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.currencies = data;
        },
        serviceType: (state, action) => {
            // console.log(action.payload);
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.serviceType = data;
            state.serviceTypeMap = data.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
        },
        websites: (state, action) => {
            const { data } = action.payload;
            state.websites = data;
        },
        // check name UK
        // useableNameUK: (state, action) => {
        //     const { data } = action.payload;
        //     state.listLoading = false;
        //     state.error = null;
        //     state.useableNameUK = data;
        // },
        fileTypes: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.fileTypes = data;
        },
    },
});

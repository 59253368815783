import CheckboxField from 'components/form-controls/CheckboxField';
import React from 'react';
import { Box } from '@mui/material';

export const Represented = ({
    form,
    index,
    isPanama,
    disabled,
    onCheckRepresented,
    mainLabel = 'Consent to act as secretary'
}) => {
    if (isPanama)
        return (
            <>
                <Box mt={2}>
                    <CheckboxField
                        form={form}
                        name={`members.${index}.secretary`}
                        label={'Consent to act as secretary'}
                        onChange={(value) => {
                            onCheckRepresented(index, value, 'secretary');
                        }}
                        disabled={disabled}
                    />
                </Box>
                <Box>
                    <CheckboxField
                        form={form}
                        name={`members.${index}.president`}
                        label={'Consent to act as President'}
                        onChange={(value) => {
                            onCheckRepresented(index, value, 'president');
                        }}
                        disabled={disabled}
                    />
                </Box>
                <Box>
                    <CheckboxField
                        form={form}
                        name={`members.${index}.treasurer`}
                        label={'Consent to act as Treasurer'}
                        onChange={(value) => {
                            onCheckRepresented(index, value, 'treasurer');
                        }}
                        disabled={disabled}
                    />
                </Box>
            </>
        );

    return (
        <Box mt={2}>
            <CheckboxField
                form={form}
                name={`members.${index}.CompanyPositions.0.is_appointed`}
                label={mainLabel}
                onChange={(value) => {
                    onCheckRepresented(index, value);
                }}
                disabled={
                    form.watch(`members.${index}.CompanyPositions.0.disabled_appointed`) || disabled
                }
            />
        </Box>
    );
};

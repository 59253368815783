import { createSlice } from '@reduxjs/toolkit';

const initialenumsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    entity: undefined,
    lastError: null,
    subscription: null,
    serviceDiscount: null,
    accAndFiling: null,
};
export const callTypes = {
    list: 'list',
    action: 'action',
};

export const incorporationSlice = createSlice({
    name: 'incopration',
    initialState: initialenumsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getenumById
        entityFetched: (state, action) => {
            state.actionsLoading = false;
            state.entity = action.payload.entity;
            state.error = null;
        },
        subscriptionFetched: (state, action) => {
            state.actionsLoading = false;
            state.subscription = action.payload.subscription;
            state.error = null;
        },
        discountFetched: (state, action) => {
            state.actionsLoading = false;
            state.serviceDiscount = action.payload.serviceDiscount;
            state.error = null;
        },
        setAccAndFiling: (state, action) => {
            state.accAndFiling = action.payload;
        },
    },
});

import React, { useEffect } from 'react';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import {
    Controls,
    ControlButton,
    useStoreState,
    useZoomPanHelper,
} from 'react-flow-renderer';
const EXPORT = '/media/svg/icons/Order/export.svg';
const SUBTRACT = '/media/svg/icons/Order/subtract.svg';
const PLUS = '/media/svg/icons/Order/plus.svg';

export default function GraphControl({ viewLoaded, setViewLoaded, companyName }) {
    
    const { maxZoom, minZoom, transform, nodes } = useStoreState(
        ({ maxZoom, minZoom, transform, nodes }) => ({
            maxZoom,
            minZoom,
            transform,
            nodes,
        }),
    );

    useEffect(() => {
        if (nodes.length > 0 && !viewLoaded) {
            zoomPanHelper.fitView();
            setViewLoaded(true);
        }
    }, [viewLoaded, nodes.length, setViewLoaded, zoomPanHelper]);
    const zoomPanHelper = useZoomPanHelper();
    const snapView = () =>
        new Promise((resolve) => {
            resolve(zoomPanHelper.fitView());
        });

    const printDocument = () => {
        snapView().then(() => {
            const diagram = document.getElementsByClassName('react-flow__renderer')[0];
            domtoimage
                .toSvg(diagram)
                .then(function (blob) {
                    saveAs(blob, `${companyName}.svg`);
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });
        });
    };

    return (
        <Controls
            showInteractive={false}
            showFitView={false}
            showZoom={false}
            className="kyc-control-panel"
        >
            <ControlButton
                onClick={zoomPanHelper.zoomIn}
                className="btn-zoom-in p-2"
                disabled={!viewLoaded}
            >
                <img src={PLUS} alt="plus" />
            </ControlButton>
            <div className="kyc-percentage">
                {Math.round(((transform[2] - minZoom) / ((maxZoom - minZoom) / 2)) * 100)}%
            </div>
            <ControlButton
                onClick={zoomPanHelper.zoomOut}
                className="btn-zoom-out p-2"
                disabled={!viewLoaded}
            >
                <img src={SUBTRACT} alt="subtract" />
            </ControlButton>
            <button
                className="kyc-btn-export shadow"
                onClick={printDocument}
                disabled={!viewLoaded}
            >
                <img src={EXPORT} alt="" />
            </button>
        </Controls>
    );
}

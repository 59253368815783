/* Function to check day is weekend
* @param date Date
* */
export const isWeekday = (date) => {
    return date.getDay() !== 0 && date.getDay() !== 6;
};

/* Function to generate Time options
* @param from: 0, 1, 2, ... 23
* @param to: 0, 1, 2, ... 23
* @param interval: 5, 10, 15
* @param endWithTo: add end option is to value or not */
export const generateTimesOptions = (from, to, interval = 5, endWithTo = true) => {
    if (isNaN(from) || from < 0) {
        from = 0;
    }
    if (isNaN(to) || to > 23) {
        to = 23;
    }
    if (to < from) return [];
    const result = [];
    const minutes = [];
    if (isNaN(interval) || interval === 0 || interval > 60) {
        minutes.push('00');
    } else {
        for (let i = 0; i < 60; i += interval) {
            minutes.push(i.toString().padStart(2, '0'));
        }
    }
    for (let i = from; i < to; i++) {
        minutes.forEach(minute => {
            result.push({
                value: i.toString().padStart(2, '0') + ':' + minute,
                label: i.toString().padStart(2, '0') + ':' + minute
            });
        });
    }
    if (endWithTo) {
        result.push({ value: to.toString() + ':00', label: to.toString() + ':00' });
    }
    return result;
}

import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import LoadingButton from '../../../../../components/LoadingButton';
import { cloneDeep } from 'lodash';
import { useForm } from 'react-hook-form';
import InputField from 'components/form-controls/InputField';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectField from 'components/form-controls/SelectField';

const initFormDefault = {
    first_name: '',
    phone_number: '',
    phone_country_id: '',
    phone_country: '',
    address: '',
    address_1: '',
    state: '',
    city: '',
    postal_code: '',
    country_id: null,
    /* Field tạm dùng cho react select - xóa đi khi submit data */
    country: '',
};

const formValidationSchema = Yup.object().shape({
    first_name: Yup.string().nullable(),
    phone_number: Yup.string()
        .nullable()
        .matches(/^[0-9]*$/, 'Please enter number!'),
    phone_country: Yup.object().nullable(),

    country_id: Yup.number().nullable().required('Country is required'),
    address: Yup.string().nullable().required('Address 1 is required'),
    address_1: Yup.string().nullable(),
    state: Yup.string().nullable().required('State is required'),
    city: Yup.string().nullable().required('City is required'),
    postal_code: Yup.string().nullable().required('Postal code is required'),
});

function ShippingAddress({ countriesOptions, onSubmit, loading, phonePrefixOptions }) {
    const form = useForm({ resolver: yupResolver(formValidationSchema), mode: 'all' });

    useEffect(() => {
        form.reset(initFormDefault);
    }, [initFormDefault]);

    const handleSubmit = (values) => {
        let newValue = cloneDeep(values);
        delete newValue.country;
        delete newValue.phone_country;
        onSubmit(newValue);
    };

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className={'m-auto'} style={{ maxWidth: '800px' }}>
                <p>I would like to deliver my document(s) to the following address:</p>
                <div className="form-group row">
                    <div className="col-6 mb-7 input-line">
                        <label>Full name of Receiver</label>
                        <InputField
                            form={form}
                            name="first_name"
                            placeholder="Full name of Receiver"
                            showIcon
                            hideHelperText
                        />
                    </div>
                    <div className="col-lg-6 input-line">
                        <label>Phone number</label>
                        <div className="phone-group">
                            <div className="phone-code" style={{ zIndex: 1 }}>
                                {/* <label>Phone number</label> */}
                                <SelectField
                                    form={form}
                                    className="fw-500"
                                    name="phone_country_id"
                                    id="phone-prefix-select"
                                    placeholder="Select..."
                                    options={phonePrefixOptions}
                                    onChange={(value) => {
                                        form.setValue('phone_country', value || '');
                                        form.setValue('phone_country_id', value?.id || '');
                                    }}
                                    getOptionLabel={(option) => option?.label || ''}
                                    hideHelperText
                                    customStyle={{
                                        '.MuiInputBase-root:before, .MuiInputBase-root:after, .MuiInput-underline:hover:not(.Mui-disabled):before':
                                            {
                                                borderBottom: '0 !important',
                                            },
                                    }}
                                />
                            </div>
                            <div className="input-line inp-phone">
                                <InputField
                                    form={form}
                                    name="phone_number"
                                    placeholder="Phone number"
                                    showIcon
                                    hideHelperText
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-7 input-line">
                        <label>Address line 1</label>
                        <InputField
                            form={form}
                            name="address"
                            placeholder="Address line 1"
                            showIcon
                            hideHelperText
                        />
                        {form.formState.errors?.address && (
                            <small className={'text-danger'}>
                                {form.formState.errors?.address.message}
                            </small>
                        )}
                    </div>
                    <div className="col-12 mb-7 input-line">
                        <label>Address line 2</label>
                        <InputField
                            form={form}
                            name="address_1"
                            placeholder="Address line 2"
                            showIcon
                            hideHelperText
                        />
                    </div>
                    <div className="col-md-3 col-sm-6 mb-7 input-line">
                        <label>Country</label>
                        <SelectField
                            form={form}
                            className="fw-500"
                            name="country_id"
                            id="shipping-address-country-select"
                            options={countriesOptions}
                            placeholder={'Choose country'}
                            hideHelperText
                            hideInputHelperText
                            onChange={(value) => {
                                form.setValue('country', value || '');
                                form.setValue('country_id', value?.id || '');
                            }}
                        />
                        {form.formState.errors?.country_id && (
                            <small className={'text-danger'}>
                                {form.formState.errors?.country_id.message}
                            </small>
                        )}
                    </div>
                    <div className="col-md-3 mb-7 input-line">
                        <label>City</label>
                        <InputField
                            form={form}
                            name="city"
                            placeholder="City"
                            showIcon
                            hideHelperText
                        />
                        {form.formState.errors?.city && (
                            <small className={'text-danger'}>
                                {form.formState.errors?.city.message}
                            </small>
                        )}
                    </div>
                    <div className="col-md-3 mb-7 input-line">
                        <label>State</label>
                        <InputField
                            form={form}
                            name="state"
                            placeholder="State"
                            showIcon
                            hideHelperText
                        />
                        {form.formState.errors?.state && (
                            <small className={'text-danger'}>
                                {form.formState.errors?.state.message}
                            </small>
                        )}
                    </div>
                    <div className="col-md-3 mb-7 input-line">
                        <label>Postal Code</label>
                        <InputField
                            form={form}
                            name="postal_code"
                            placeholder="Postal Code"
                            showIcon
                            hideHelperText
                        />
                        {form.formState.errors?.postal_code && (
                            <small className={'text-danger'}>
                                {form.formState.errors?.postal_code.message}
                            </small>
                        )}
                    </div>
                </div>

                <Box className={'text-right'}>
                    <LoadingButton
                        type="submit"
                        variant={'contained'}
                        color={'primary'}
                        disabled={loading === 'loading'}
                        loading={loading === 'loading'}
                        className={'text-white'}
                    >
                        Submit
                    </LoadingButton>
                </Box>
            </div>
        </form>
    );
}

export default ShippingAddress;

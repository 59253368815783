import { get } from 'lodash';
import React, { useImperativeHandle, useRef } from 'react';
import { useMemo, useEffect } from 'react';
import NatureOfControl from '../../hook-form/NatureOfControl';
import References from '../../hook-form/References';
import SecurityQuestion from '../../hook-form/SecurityQuestion';
import SourceForFunds from '../../hook-form/SourceForFunds';
import InformationForm from './Form/InformationForm/InformationForm';
import UploadFileForm from './Form/UploadFileForm';
import MemberRoleCheckbox from 'app/pages/services/order-management/order-form/instruction-incorporation/component/step3/step3-helper/member-role-checkbox';
import { isEmpty } from 'lodash';

const MemberDetailForm = React.forwardRef((props, ref) => {
    const {
        company,
        member,
        disabled,
        disabledFields,
        isSVG,
        isPanama,
        isUBO,
        IS_UK_LLP,
        isUK,
        form,
        Questions,
        isDirector,
        isContactPerson,
        isShareholder,
        orderInfo,
        page,
        hasShowSelectFullName,
        isPercent,
        setIsPercent,
        setIsInValid,
        changeFields,
        uploadDisabled = () => {},
    } = props;
    const uploadFileRef = useRef();
    const isInsideCompanyDetailPage = page === 'company';

    useImperativeHandle(
        ref,
        () => ({
            uploadFile: uploadFileRef.current,
        }),
        [],
    );

    const natureBodyMemo = useMemo(
        () => ({ QuestionsIncorp: Questions, is_UK_LLP: IS_UK_LLP }),
        [],
    );

    return (
        <div className="p-10" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
            <div className="mb-10">
                <InformationForm
                    {...props}
                    hasShowSelectFullName={hasShowSelectFullName}
                    isPercent={isPercent}
                    setIsPercent={setIsPercent}
                />
            </div>
            {!isContactPerson && (
                <>
                    {/* SVG */}
                    {isSVG && (
                        <div className="mb-10">
                            <SourceForFunds
                                index={0}
                                form={form}
                                body={member}
                                disabled={
                                    disabled ||
                                    disabledFields?.block_source_of_fund?.(member) ||
                                    isInsideCompanyDetailPage
                                }
                            />
                        </div>
                    )}
                    {/* Panama */}
                    {isPanama && (
                        <div className="">
                            <References
                                index={0}
                                form={form}
                                disabled={
                                    disabled ||
                                    disabledFields?.block_reference?.(member) ||
                                    isInsideCompanyDetailPage
                                }
                            />
                        </div>
                    )}
                    {isPanama && isDirector && (
                        <div className="mb-10">
                            <MemberRoleCheckbox
                                index={0}
                                values={form.watch('members')}
                                representedLabel={'Consent to act as president'}
                                fieldName={'president'}
                                form={form}
                                disabled={disabled}
                            />
                            <MemberRoleCheckbox
                                index={0}
                                values={form.watch('members')}
                                representedLabel={'Consent to act as secretary'}
                                fieldName={'secretary'}
                                form={form}
                                disabled={disabled}
                            />
                            <MemberRoleCheckbox
                                index={0}
                                values={form.watch('members')}
                                representedLabel={'Consent to act as treasurer'}
                                fieldName={'treasurer'}
                                form={form}
                                disabled={disabled}
                            />
                        </div>
                    )}
                    {isPanama && isUBO && (
                        <div className="mb-10">
                            <MemberRoleCheckbox
                                index={0}
                                values={form.watch('members')}
                                representedLabel={'Consent to act authorized signatory'}
                                fieldName={'authorized'}
                                form={form}
                                disabled={disabled}
                            />
                        </div>
                    )}

                    {/* UK */}
                    {!isDirector && isUK && (
                        <>
                            {((isUBO && IS_UK_LLP) || isShareholder) && (
                                <div className="mb-10">
                                    <SecurityQuestion
                                        index={0}
                                        body={
                                            page === 'company'
                                                ? get(company, 'Orders[0]')
                                                : orderInfo
                                        }
                                        form={form}
                                        disabled={
                                            disabled ||
                                            disabledFields?.block_security_question?.(member) ||
                                            isInsideCompanyDetailPage
                                        }
                                    />
                                </div>
                            )}
                            {isUBO && (
                                <div className="mb-10">
                                    <NatureOfControl
                                        index={0}
                                        body={natureBodyMemo}
                                        form={form}
                                        disabled={
                                            disabled ||
                                            disabledFields?.block_nature_of_control?.(member) ||
                                            isInsideCompanyDetailPage
                                        }
                                    />
                                </div>
                            )}
                        </>
                    )}
                </>
            )}

            {member.id && (
                <div className="mb-10">
                    <h5 className="mb-7 pl-0 input-line">Upload file</h5>
                    <UploadFileForm
                        ref={uploadFileRef}
                        setIsInValid={setIsInValid}
                        {...props}
                        disabled={(isInsideCompanyDetailPage && isEmpty(changeFields)) || uploadDisabled(member)}
                    />
                </div>
            )}
        </div>
    );
});

export default MemberDetailForm;
